import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {CampaignDialogComponent} from "./campaign-dialog.component";

import {TreeModule} from "@circlon/angular-tree-component";
import {SearchInputModule} from "../search-input/search-input.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {SharedComponentsModule} from "../shared.components.module";
import {CampaignDialogService} from "./service/campaign-dialog.service";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {CampaignViewComponent} from "./components/campaign-view/campaign-view.component";
import {DirectiveModule} from "../../directive/directive.module";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";


@NgModule({
  declarations: [
    CampaignViewComponent,
    CampaignDialogComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        UpgradeModule,
        TreeModule,
        SearchInputModule,
        InfiniteScrollModule,
        SharedComponentsModule,
        NgbDropdownModule,
        DirectiveModule,
        PipesComponentsModule,
    ],
  providers: [
    CampaignDialogService
  ],
  entryComponents:[],
  exports:[
    CampaignDialogComponent
  ]
})
export class CampaignDialogModule {

  constructor() {}

}

//-- LEGACY ---

angular.module('loya.builder')
  .factory('CampaignDialog', downgradeInjectable(CampaignDialogService))
