<ng-container
  *ngIf="!!currentLocationGroup && !loader.isLoading('currentLocationGroup')"
>

  <div class="fixed-tool-panel">


    <button type="button" class="btn btn-sm btn-addon btn-danger pull-left "
            [hidden]="!statesChangerService.hasButton('delete')"
            (click)="confirmActionService.confirm('Вы действительно хотите удалить локацию ' + currentLocationGroup.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
            data-testid="locationGroups.delete()"
    >
      <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
      <i class=" m-r-xs fa fa-remove"></i>
      <span class="btn-name m-l-xs hidden-xxs">
                 {{ 'interface.buttons.remove' | transloco }}
                </span>
    </button>


    <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
            [hidden]="!statesChangerService.hasButton('edit')"
            (click)="statesChangerService.click('edit')"
            data-testid="locationGroups.edit()"
    >

      <i class=" fa fa-pencil">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
                 {{ 'interface.buttons.edit' | transloco }}
              </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('save')"
            (click)="statesChangerService.click('save')"
            [disabled]="locationGroupForm && locationGroupForm.form && locationGroupForm.form.invalid"
            data-testid="locationGroups.save()"
    >
      <i class=" fa fa-check text-primary">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.save' | transloco }}
      </span>
    </button>

    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('cancel')"
            (click)="statesChangerService.click('cancel')"
            data-testid="locationGroups.cancel()">

      <i class=" fa fa-remove text-danger">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.cancel' | transloco }}
      </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
            *ngIf="statesChangerService.hasButton('preview')"
            (click)="statesChangerService.click('preview')"

            [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
            [disabled]="locationGroupForm && locationGroupForm.form && locationGroupForm.form.invalid"
            data-testid="locationGroups.preview()"
    >
      <i class=" fa fa-eye text-success">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.preview' | transloco }}
      </span>
    </button>


  </div>

  <div class="card w-100 no-back b-none"
       *transloco="let t"
  >

    <div class="card-body"
         *ngIf="currentLocationGroup"
    >
      <form name="locationGroupForm" #locationGroupForm="ngForm" novalidate autocomplete="off">

        <grid-container>

          <div class="grid-item"
               grid-item-form = "ID локации"
               grid-item-form-icon = "fa-map-marker"
          >
            <span class="pull-left" data-testid="currentLocationGroup.id">{{ currentLocationGroup.id || '-' }}</span>
          </div>


          <div class="grid-item"
               grid-item-form = "Название"
               grid-item-form-icon = "fa-map-marker"
          >
            <span *ngIf="!statesChangerService.isActive"
                  class="pull-left"
                  [innerHTML]="currentLocationGroup.name"
                  data-testid="currentLocationGroup.name"
            ></span>

            <span class="editable-rec"
                  *ngIf="statesChangerService.isActive"
            >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocationGroup.name"
                data-testid="currentLocationGroup.input.name"
                name="name"
                [placeholder]="'Введите название локации'"
                [required]="true"
                [maxlength]="254"
              >
            </span>
          </div>

          <div class="grid-item"
               grid-item-form = "Партнёр"
               grid-item-form-icon = "fa-map-marker"
          >
            <span class="pull-left" data-testid="currentLocationGroup.partner">
              {{ selectedPartner?.name || '-' }}
            </span>
          </div>

          <div class="grid-item"
               grid-item-form = "Код локации"
               grid-item-form-icon = "fa-map-marker"
          >
            <span *ngIf="!statesChangerService.isActive"
                  class="pull-left"
                  [innerHTML]="currentLocationGroup.code || ''"
                  data-testid="currentLocationGroup.code"
            ></span>

            <span class="editable-rec"
                  *ngIf="statesChangerService.isActive"
            >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocationGroup.code"
                data-testid="currentLocationGroup.input.code"
                name="code"
                [placeholder]="'Введите код локации'"
                [required]="false"
                [maxlength]="254"
              >
            </span>
          </div>


        </grid-container>
      </form>

    </div>

  </div>

  <div class="d-flex">

    <div class="col padder wrapper-sm">
      <search-input
        [model]="searchString"
        (modelChange)="onSearchChange($event)"
        placeholder="Название/Адрес/Описание/Код"
      ></search-input>
    </div>

    <div class="col-auto padder-xs wrapper-sm"
         *ngIf="statesChangerService.isActive"
    >
      <button class="btn  btn-addon btn-success "
              type="button"
              (click)="addLocationToGroup()"
              data-testid="locationGroup.shop.add()"
      >
        <i class=" fa fa-plus "></i>
        <span class="btn-name text-ellipsis">Добавить ТП</span>
      </button>
    </div>

  </div>

  <responsive-table>

    <table class="table m-b-none b-b"
           infiniteScroll
           [infiniteScrollDistance]="1"
           [infiniteScrollThrottle]="50"
           [scrollWindow]="false"
           [infiniteScrollContainer]="'#app-container'"
           [fromRoot]="true"
           (scrolled)="onScrollLocationList()"
    >
      <thead>
        <tr>
            <th class="text-left">

               <sort-view
                 column="name"
                 [params]="locationsService.getListParams"
                 (onChange)="resetLocationList()"
                 data-testid="locationGroup.shop.sort()"
               >
                 Точка продаж
               </sort-view>

            </th>
            <th  class="text-left">

              <sort-view
                column="code"
                [params]="locationsService.getListParams"
                (onChange)="resetLocationList()"
                data-testid="locationGroup.code.sort()"
              >
                Код
               </sort-view>

            </th>
            <th  class="text-left">

              <sort-view
                column="address"
                [params]="locationsService.getListParams"
                (onChange)="resetLocationList()"
                data-testid="locationGroup.address.sort()"
              >
                Адрес
              </sort-view>

            </th>
            <th  class="text-left">
              <sort-view
                column="description"
                [params]="locationsService.getListParams"
                (onChange)="resetLocationList()"
                data-testid="locationGroup.description.sort()"
              >
                Описание
              </sort-view>

            </th>
            <th
              *ngIf="statesChangerService.isActive"
            >
            </th>
        </tr>
      </thead>

      <tbody>

      <ng-container
        *ngIf="locationsListForAdd && !!locationsListForAdd?.length"
      >
        <tr>
          <td colspan="9999"
              class="text-sm bg-light text-muted"
          >
            Точка продаж для добавления
          </td>
        </tr>

        <tr *ngFor="let locationItemRow of locationsListForAdd " data-testid="locationGroup.add.location in list">
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.name"
              [search]="searchString"
              data-testid="locationGroup.shop.name"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.code"
              [search]="searchString"
              data-testid="locationGroup.shop.code"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.address"
              [search]="searchString"
              data-testid="locationGroup.shop.address"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.description"
              [search]="searchString"
              data-testid="locationGroup.shop.description"
            ></text-highlight>

          </td>
          <td class="w-xxs text-right"
              *ngIf="statesChangerService.isActive"
          >

            <button class="btn btn-xs btn-danger"
                    (click)="deleteLocation(locationItemRow)"
                    data-testid="locationGroup.shop.remove()"
            >
              <i class="fa fa-trash"></i>
            </button>

          </td>

         </tr>

        <tr
          *ngIf="locationsList && !!locationsList?.length"
        >
          <td colspan="9999"
              class="text-sm bg-light text-muted"
          >
            Точка продаж в локации
          </td>
        </tr>
      </ng-container>

        <tr *ngFor="let locationItemRow of locationsList | callback: filterDeletedLocations" data-testid="locationGroup.location in list">
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.name"
              [search]="searchString"
              data-testid="locationGroup.shop.name"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.code"
              [search]="searchString"
              data-testid="locationGroup.shop.code"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.address"
              [search]="searchString"
              data-testid="locationGroup.shop.address"
            ></text-highlight>

          </td>
          <td class="overflow-wrap-break text-left">

            <text-highlight
              [text]="locationItemRow?.description"
              [search]="searchString"
              data-testid="locationGroup.shop.description"
            ></text-highlight>

          </td>

          <td class="w-xxs text-right"
              *ngIf="statesChangerService.isActive"
          >

            <button class="btn btn-xs btn-danger"
                    (click)="deleteLocation(locationItemRow)"
                    data-testid="locationGroup.shop.remove()"
            >
              <i class="fa fa-trash"></i>
            </button>

          </td>

        </tr>
      </tbody>
    </table>

    <div class="wrapper-lg text-center" *ngIf="loader.isLoading('locationList')">
      <i class="fa fa-spin fa-spinner text-warning fa-2x"></i>
    </div>

  </responsive-table>

</ng-container>


<div class="wrapper-lg m-t text-center"
     *ngIf="loader.isLoading('currentLocationGroup')">
  <i class="fa fa-spin fa-spinner text-warning fa-2x"></i>
</div>

