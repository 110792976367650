/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TransactionApiConfiguration, TransactionApiConfigurationInterface } from './transaction-api-configuration';

import { TransactionApiService } from './services/transaction-api.service';

/**
 * Provider for all TransactionApi services, plus TransactionApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    TransactionApiConfiguration,
    TransactionApiService
  ],
})
export class TransactionApiModule {
  static forRoot(customParams: TransactionApiConfigurationInterface): ModuleWithProviders<TransactionApiModule> {
    return {
      ngModule: TransactionApiModule,
      providers: [
        {
          provide: TransactionApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
