'use strict';


let mechanicFilters: any  = {
  pager  : { drop: 0, limit: 100 },
};

export default  ['$scope', '$state', 'mechanicsList', 'mechanicsCategoryList',  'savePreviousMechanic',
    function ($scope, $state, mechanicsList, mechanicsCategoryList, savePreviousMechanic) {

        $scope.issetPrevMachanic = savePreviousMechanic;
        $scope.mechanicsCategory = mechanicsCategoryList;
        $scope.mechanicsList = mechanicsList;

        if (!!mechanicFilters?.search && $scope.mechanicsList.$search)
          $scope.mechanicsList.$search.value = mechanicFilters?.search;


        $scope.queryNewPage = function() {

            if ( !$scope.mechanicsList || !$scope.mechanicsList.$pager) {
                return;
            }

            if ( $scope.mechanicsList.$pager.isPending || $scope.mechanicsList.$pager.isFinished ) {
                return;
            }

            $scope.$emit('$startButterbar');

            $scope.mechanicsList
                .$pager
                .next()
                .then( _ => $scope.$emit('$stopButterbar') );

        };

        $scope.$on('$destroy', function () {

          mechanicFilters = $scope.mechanicsList.$handler.getCurrentParams();
          mechanicFilters.pager.drop = 0;

        });

    } ];
