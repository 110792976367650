import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";


import "./system-index-page.legacy";
import {StatesChangerService} from "../../../shared/service/statesChanger/statesChanger.service";
import {SettingsService} from "../../../shared/service/api/Settings/Settings.service";
import {LoaderHandlerClass} from "../../../shared/class/loader-handler.class";
import {NewsletterService} from "../../../shared/service/api/Newsletter/Newsletter.service";
import {FormControl} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {getNg1Service} from "../../../shared/class/utils/angularjs.utils";

@Component({
  selector: 'system-index-page',
  templateUrl: './system-index-page.component.html',
  providers:[
    StatesChangerService,
    SettingsService,
    NewsletterService
  ]
})
export class SystemIndexPageComponent implements OnInit{

  public settingsItem: any = {};
  public settingsItemOriginal: any = {};

  public loader = new LoaderHandlerClass();

  public confirmInfogatePass: string  =  '';
  public gate = {
    success: false,
    message:''
  };

  constructor(
    public statesChangerService: StatesChangerService,
    public settingsService: SettingsService,
    public newsletterService: NewsletterService
  ) {
  }

  ngOnInit() {
    this.bindButtons();
    this.getSettings();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }


  onSave() {

    if(!!this.settingsItem?.['infogate.pass'] && this.settingsItem['infogate.pass']?.length < 40) {
      this.settingsItem['infogate.pass'] = SHA1(this.settingsItem['infogate.pass']).toString();
    }

    if( !!this.settingsItem?.['referrer.bonus.validity'] ) {
      this.settingsItem['referrer.bonus.validity'] = JSON.stringify(this.settingsItem['referrer.bonus.validity'])
    }

    let settingsForUpdate = {};

    Object.keys(this.settingsItem).forEach(key  =>  {
      if (this.settingsItemOriginal[key]?.toString()!== this.settingsItem[key]?.toString())  {
        settingsForUpdate[key] = this.settingsItem[key];
      }
    })


    this.settingsService.update$(settingsForUpdate)
      .pipe(
        this.loader.waitLoading('settings')
      )
      .subscribe(
      result => {
        this.arrayToObject(result);
        this.checkService();
        this.statesChangerService.state = 'view';
      },
      error => {
        console.log(error);
      }
    )

  }

  onCancel() {
    this.getSettings();
    this.statesChangerService.state ='view';
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.confirmInfogatePass = this.settingsItem?.['infogate.pass'] || ''
    this.statesChangerService.state = 'edit';
  }

  YesNoByKey(key:string)  {
    return this.settingsItem?.[key]  === true || this.settingsItem?.[key]  === 'true' ? 'Да' : 'Нет';
  }

  isChecked(key:string)  {
    return this.settingsItem?.[key]  === true || this.settingsItem?.[key]  === 'true'? true : false;
  }

  changeChecked(key:string, value:any)  {
    this.settingsItem[key] = !!value //!!value ? 'true'  : 'false';
  }

  checkIfTrue(key:string, value:any)  {
    this.settingsItem[key] = value ? true :  this.settingsItem[key] //!!value ? 'true'  : 'false';
  }

  hasValue(key:string)   {
    return !!this.settingsItem?.[key] || this.settingsItem?.[key] === 0;
  }

  arrayToObject(data:Array<any>)   {

    this.settingsItem = {};
    data?.forEach(val => {
      this.settingsItem[val.key] = val.value;
    });

    if ( !this.settingsItem?.['credit.allowCreditAccount'] )
      this.settingsItem['credit.allowCreditAccount'] = 'false';

    if ( !this.settingsItem?.['credit.maxCreditValue'] )
      this.settingsItem['credit.maxCreditValue'] = '0';

    if ( !!this.settingsItem?.['referrer.bonus.validity'] && typeof this.settingsItem?.['referrer.bonus.validity'] ===  'string' )
      try  {
        this.settingsItem['referrer.bonus.validity'] = JSON.parse(this.settingsItem?.['referrer.bonus.validity']);
      } catch(e) {
        this.settingsItem['referrer.bonus.validity'] = {};
      }

    if ( getNg1Service('loyaPermissions').isCapabilityEnabled('referrer') ) {
      this.settingsItem['referrer.bonus.validity'] = this.settingsItem?.['referrer.bonus.validity'] || {};
    }

    this.settingsItemOriginal = JSON.parse(JSON.stringify(this.settingsItem));

  }

  getSettings() {
    this.settingsService.query$()
      .pipe(
        this.loader.waitLoading('settings')
      )
      .subscribe(
        {
          next: (result) => {
            this.arrayToObject(result);
            this.checkService();
          }
        }
      )
  }

  checkService()  {
    this.newsletterService.check$()
      .pipe(
        this.loader.waitLoading('gate')
      )
      .subscribe(
        {
          next: (result) => {
            this.gate.success = true;
            this.gate.message  = 'ПОльзователь подключен';
          },
          error: (textError)=>  {
            this.gate.success = false;
            this.gate.message  = textError;
          }
        }
      )
  }

  comparePasswordsValidator = ( newValue: string )  =>  {
      return this.settingsItem?.['infogate.pass'] === newValue;
  }


}
