
import {enableProdMode, NgModule} from '@angular/core';

import "@angular/compiler"
import {DashboardPageComponent} from "./top/dashboard-income-total.component";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {CommonModule} from "@angular/common";
import {DashboardTotalGiftsComponent} from "./top/dashboard-total-gifts.component";
import {DashboardCountBonusesComponent} from "./top/dashboard-count-bonuses.component";
import {DashboardTotalDiscountComponent} from "./top/dashboard-total-discount.component";
import {DashboardCheckCountComponent} from "./top/dashboard-check-count.component";
import {DashboardCampaignBonusChartComponent} from "./charts/dashboard-campaign-bonus-chart.component";
import {HighchartsChartModule} from "highcharts-angular";
import {DashboardCampaignDiscountsChartComponent} from "./charts/dashboard-campaign-discounts-chart.component";
import {DashboardSmsCountComponent} from "./communications/dashboard-sms-count.component";
import {DashboardSmsCostComponent} from "./communications/dashboard-sms-cost.component";
import {DashboardEmailCountComponent} from "./communications/dashboard-email-count.component";
import {DashboardEmailCostComponent} from "./communications/dashboard-email-cost.component";
import {DashboardViberBlockComponent} from "./communications/dashboard-viber-block.component";
import {DashboardReceiptCountComponent} from "./communications/dashboard-receipt-count.component";
import {DashboardCampaignActiveChartComponent} from "./charts/dashboard-campaign-active-chart.component";
import {DashboardClientsChartComponent} from "./charts/dashboard-clients-chart.component";
import {DashboardRevenueChartComponent} from "./charts/dashboard-revenue-chart.component";
import {DashboardAvgChartComponent} from "./charts/dashboard-avg-chart.component";
import {DashboardRequestService} from "./services/dashboard-request.service";
import {NoLocationWarningComponent} from "./system/no-location-warning.component";

@NgModule({
  providers: [
    DashboardRequestService
  ],
  declarations: [
    DashboardPageComponent,
    DashboardTotalGiftsComponent,
    DashboardCountBonusesComponent,
    DashboardTotalDiscountComponent,
    DashboardCheckCountComponent,
    DashboardCampaignBonusChartComponent,
    DashboardCampaignDiscountsChartComponent,
    DashboardSmsCountComponent,
    DashboardSmsCostComponent,
    DashboardEmailCountComponent,
    DashboardEmailCostComponent,
    DashboardViberBlockComponent,
    DashboardReceiptCountComponent,
    DashboardCampaignActiveChartComponent,
    DashboardClientsChartComponent,
    DashboardRevenueChartComponent,
    DashboardAvgChartComponent,
    NoLocationWarningComponent
  ],
  imports: [
    CommonModule,
    PipesComponentsModule,
    DirectiveModule,
    HighchartsChartModule
  ],
  exports: [
    DashboardPageComponent,
    DashboardTotalGiftsComponent,
    DashboardCountBonusesComponent,
    DashboardTotalDiscountComponent,
    DashboardCheckCountComponent,
    DashboardCampaignBonusChartComponent,
    DashboardCampaignDiscountsChartComponent,
    DashboardSmsCountComponent,
    DashboardSmsCostComponent,
    DashboardEmailCountComponent,
    DashboardEmailCostComponent,
    DashboardViberBlockComponent,
    DashboardReceiptCountComponent,
    DashboardCampaignActiveChartComponent,
    DashboardClientsChartComponent,
    DashboardRevenueChartComponent,
    DashboardAvgChartComponent
  ]
})

export class DashboardWidgetsModule {

  constructor() {}

}
