/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ClientTransactionsApiConfiguration as __Configuration } from '../client-transactions-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientTransactionsRawCheckStorage } from '../models/client-transactions-raw-check-storage';
import { ClientTransactionsRawCheckItemStorage } from '../models/client-transactions-raw-check-item-storage';
import { ClientTransactionsRawCheckTotal } from '../models/client-transactions-raw-check-total';
import { ClientTransactionsRawItemResult } from '../models/client-transactions-raw-item-result';
@Injectable({
  providedIn: 'root',
})
class ClientTransactionsApiService extends __BaseService {
  static readonly getClientTransactionsPath = '/clienttransaction/{clientId}';
  static readonly getClientTransactionDetailsPath = '/clienttransactiondetail/{checkId}';
  static readonly getClientTransactionResultsByCheckPath = '/clienttransactionresults/{checkId}';
  static readonly getClientTransactionResultsByItemPath = '/clienttransactionresults/{checkId}/{positionId}';
  static readonly findCheckByIdPath = '/check/{checkId}';
  static readonly getCanceledTransactionsPath = '/clientcanceledtransaction/{clientId}';
  static readonly getCanceledTransactionDetailsPath = '/clientcanceledtransactiondetail/{checkId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка чеков по клиенту
   *
   * Запрос списка чеков по клиенту
   * @param params The `ClientTransactionsApiService.GetClientTransactionsParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `limit`: Кол-во возращаемых записей (по умолчанию 100)
   *
   * - `drop`: Кол-во пропущенных записей от начала (по умолчанию 0)
   *
   * @return Список чеков
   */
  getClientTransactionsResponse(params: ClientTransactionsApiService.GetClientTransactionsParams): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clienttransaction/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>;
      })
    );
  }
  /**
   * Запрос списка чеков по клиенту
   *
   * Запрос списка чеков по клиенту
   * @param params The `ClientTransactionsApiService.GetClientTransactionsParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `limit`: Кол-во возращаемых записей (по умолчанию 100)
   *
   * - `drop`: Кол-во пропущенных записей от начала (по умолчанию 0)
   *
   * @return Список чеков
   */
  getClientTransactions(params: ClientTransactionsApiService.GetClientTransactionsParams): __Observable<Array<ClientTransactionsRawCheckStorage>> {
    return this.getClientTransactionsResponse(params).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawCheckStorage>)
    );
  }

  /**
   * Запрос детализации по чеку
   *
   * Запрос детализации по чеку
   * @param checkId ID чека
   * @return Детализация по чеку
   */
  getClientTransactionDetailsResponse(checkId: number): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawCheckItemStorage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clienttransactiondetail/${encodeURIComponent(String(checkId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawCheckItemStorage>>;
      })
    );
  }
  /**
   * Запрос детализации по чеку
   *
   * Запрос детализации по чеку
   * @param checkId ID чека
   * @return Детализация по чеку
   */
  getClientTransactionDetails(checkId: number): __Observable<Array<ClientTransactionsRawCheckItemStorage>> {
    return this.getClientTransactionDetailsResponse(checkId).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawCheckItemStorage>)
    );
  }

  /**
   * Запрос детализации вознаграждений по чеку
   *
   * Запрос детализации вознаграждений по чеку
   * @param checkId ID чека
   * @return Детализация вознаграждений по чеку
   */
  getClientTransactionResultsByCheckResponse(checkId: number): __Observable<__StrictHttpResponse<ClientTransactionsRawCheckTotal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clienttransactionresults/${encodeURIComponent(String(checkId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientTransactionsRawCheckTotal>;
      })
    );
  }
  /**
   * Запрос детализации вознаграждений по чеку
   *
   * Запрос детализации вознаграждений по чеку
   * @param checkId ID чека
   * @return Детализация вознаграждений по чеку
   */
  getClientTransactionResultsByCheck(checkId: number): __Observable<ClientTransactionsRawCheckTotal> {
    return this.getClientTransactionResultsByCheckResponse(checkId).pipe(
      __map(_r => _r.body as ClientTransactionsRawCheckTotal)
    );
  }

  /**
   * Запрос детализации вознаграждений по позиции чека
   *
   * Запрос детализации вознаграждений по позиции чека
   * @param params The `ClientTransactionsApiService.GetClientTransactionResultsByItemParams` containing the following parameters:
   *
   * - `positionId`: ID позиции
   *
   * - `checkId`: ID чека
   *
   * @return Детализация вознаграждений по позиции чека
   */
  getClientTransactionResultsByItemResponse(params: ClientTransactionsApiService.GetClientTransactionResultsByItemParams): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawItemResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clienttransactionresults/${encodeURIComponent(String(params.checkId))}/${encodeURIComponent(String(params.positionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawItemResult>>;
      })
    );
  }
  /**
   * Запрос детализации вознаграждений по позиции чека
   *
   * Запрос детализации вознаграждений по позиции чека
   * @param params The `ClientTransactionsApiService.GetClientTransactionResultsByItemParams` containing the following parameters:
   *
   * - `positionId`: ID позиции
   *
   * - `checkId`: ID чека
   *
   * @return Детализация вознаграждений по позиции чека
   */
  getClientTransactionResultsByItem(params: ClientTransactionsApiService.GetClientTransactionResultsByItemParams): __Observable<Array<ClientTransactionsRawItemResult>> {
    return this.getClientTransactionResultsByItemResponse(params).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawItemResult>)
    );
  }

  /**
   * Запрос деталей чека по ID
   *
   * Запрос деталей чека по ID
   * @param checkId ID чека
   * @return Детали по чеку
   */
  findCheckByIdResponse(checkId: number): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/check/${encodeURIComponent(String(checkId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>;
      })
    );
  }
  /**
   * Запрос деталей чека по ID
   *
   * Запрос деталей чека по ID
   * @param checkId ID чека
   * @return Детали по чеку
   */
  findCheckById(checkId: number): __Observable<Array<ClientTransactionsRawCheckStorage>> {
    return this.findCheckByIdResponse(checkId).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawCheckStorage>)
    );
  }

  /**
   * Запрос списка аннулированных чеков по клиенту
   *
   * Запрос списка аннулированных чеков по клиенту
   * @param params The `ClientTransactionsApiService.GetCanceledTransactionsParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `limit`: Кол-во возращаемых записей (по умолчанию 100)
   *
   * - `drop`: Кол-во пропущенных записей от начала (по умолчанию 0)
   *
   * @return Список аннулированных чеков
   */
  getCanceledTransactionsResponse(params: ClientTransactionsApiService.GetCanceledTransactionsParams): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clientcanceledtransaction/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawCheckStorage>>;
      })
    );
  }
  /**
   * Запрос списка аннулированных чеков по клиенту
   *
   * Запрос списка аннулированных чеков по клиенту
   * @param params The `ClientTransactionsApiService.GetCanceledTransactionsParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `limit`: Кол-во возращаемых записей (по умолчанию 100)
   *
   * - `drop`: Кол-во пропущенных записей от начала (по умолчанию 0)
   *
   * @return Список аннулированных чеков
   */
  getCanceledTransactions(params: ClientTransactionsApiService.GetCanceledTransactionsParams): __Observable<Array<ClientTransactionsRawCheckStorage>> {
    return this.getCanceledTransactionsResponse(params).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawCheckStorage>)
    );
  }

  /**
   * Запрос детализации по аннулированному чеку
   *
   * Запрос детализации по аннулированному чеку
   * @param checkId ID чека
   * @return Детализация по аннулированному чеку
   */
  getCanceledTransactionDetailsResponse(checkId: number): __Observable<__StrictHttpResponse<Array<ClientTransactionsRawCheckItemStorage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientcanceledtransactiondetail/${encodeURIComponent(String(checkId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientTransactionsRawCheckItemStorage>>;
      })
    );
  }
  /**
   * Запрос детализации по аннулированному чеку
   *
   * Запрос детализации по аннулированному чеку
   * @param checkId ID чека
   * @return Детализация по аннулированному чеку
   */
  getCanceledTransactionDetails(checkId: number): __Observable<Array<ClientTransactionsRawCheckItemStorage>> {
    return this.getCanceledTransactionDetailsResponse(checkId).pipe(
      __map(_r => _r.body as Array<ClientTransactionsRawCheckItemStorage>)
    );
  }
}

module ClientTransactionsApiService {

  /**
   * Parameters for getClientTransactions
   */
  export interface GetClientTransactionsParams {

    /**
     * ID клиента
     */
    clientId: number;

    /**
     * Кол-во возращаемых записей (по умолчанию 100)
     */
    limit?: number;

    /**
     * Кол-во пропущенных записей от начала (по умолчанию 0)
     */
    drop?: number;
  }

  /**
   * Parameters for getClientTransactionResultsByItem
   */
  export interface GetClientTransactionResultsByItemParams {

    /**
     * ID позиции
     */
    positionId: number;

    /**
     * ID чека
     */
    checkId: number;
  }

  /**
   * Parameters for getCanceledTransactions
   */
  export interface GetCanceledTransactionsParams {

    /**
     * ID клиента
     */
    clientId: number;

    /**
     * Кол-во возращаемых записей (по умолчанию 100)
     */
    limit?: number;

    /**
     * Кол-во пропущенных записей от начала (по умолчанию 0)
     */
    drop?: number;
  }
}

export { ClientTransactionsApiService }
