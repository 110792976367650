import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DibsListMechanicApiService} from "../../../../api/DibsListMechanicApi/services";

@Injectable({
  providedIn: "root"
})
export class DibsProfileByClientService {

  constructor(
    private dibsListMechanicApiService:DibsListMechanicApiService,
    private httpClient: HttpClient
  ) {
  }


  public getInfoByCampaign$(campaignId: any){

    return this.httpClient.get(`/dibs/campaign/${campaignId}`, {
      responseType: "json"
    });

  }


}
