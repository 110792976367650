'use strict';

import module from 'frontendModule';

import './directives';
import './factorys';

import 'model/SegmentationModel/resource';
import 'model/GroupModel/resource';
import 'model/Group';
import 'model/ComponentModel/resource';

import './components/groupPaymentLimitation/main';
import * as  deployPopup   from  './views/deploy.popup.html';
import {getParentIdForGroup} from "./utils";
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.segments.groups.view';

module.requires.push('infinite-scroll');
module.requires.push('ui.footable');
module.requires.push('ui.select');

let editState = {
    state : false,
    preparedDraft : true
};

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )
                .url('/:id/:state')

                .template(   () => import( /* webpackChunkName: "assets/pages/segments.groups.view" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/segments.groups.view" */ "./controllers"       ))

                .breadcrumbs(  [ "Группы", "Просмотр", "Инфо о группе"  ] )

                .resolve('groupComplitnes', [ 'GroupModel',  '$stateParams', function ( GroupModel, $stateParams ) {

                    if ( $stateParams.id || $stateParams.id === 0 ) {
                        return GroupModel.getStatesGroupById({
                            id: $stateParams.id
                        }).$promise
                          .catch(() => {
                            return Promise.resolve({groupState: "unknown", stateCompleteness: "unknown"})
                          });
                    }

                    return {
                        groupState        : 'draft',
                        stateCompleteness : 'finished'
                    };
                }] )

                .resolve('groupItem', [ 'GroupModel', '$state', '$stateParams', '$q', function (GroupModel, $state,  $stateParams, $q) {
                    var item,
                        defer = $q.defer();

                    if ( !$stateParams.id && $stateParams.id !==0 ) {
                        item = new GroupModel({ group: {
                                isAutorefresh: false
                            } });
                        item.setDefaults();
                        defer.resolve(item);
                    } else {

                        GroupModel.get($stateParams, function( data ) {
                            data.state = $stateParams.state;

                            defer.resolve( data );
                        }, function() {
                            $state.go( module.name.split('.').slice(0, -1).join('.') + '.index' );
                        });

                    }
                    return defer.promise;
                }] )

                .resolve('parentGroup', [ 'groupItem', '$state', 'GroupModel',
                  function (groupItem, $state, GroupModel) {
                    const parentId = getParentIdForGroup(groupItem);
                    if (!parentId)
                      return false;

                    return GroupModel.get({ id: parentId, 'state': 'deploy' });
                }])

                .resolve('segmentsList', [ 'SegmentationModel', function (SegmentationModel) {
                    return SegmentationModel.query({ "filter": {'state': 'deploy'} }).$promise;
                }] )

                .resolve('groupConditionList', [ 'GroupCondition', 'groupItem',  function (GroupCondition, groupItem ) {

                    if ( groupItem.group.id !== undefined) {
                        return GroupCondition.query({ groupId: groupItem.group.id, state: ( groupItem.group.state == 'refresh' ) ? 'draft' : groupItem.group.state }).$promise;
                    } else {
                        return [];
                    }
                }])

                .resolve('conditionItemsList',[ 'conditionItems', function(conditionItems) {

                    return  conditionItems
                                .conditionsByGroup(true)
                                .then( ( conditionsList : any ) => {

                                    let groupCounters = {};
                                    return conditionsList.map( ( condition : any ) => {

                                        groupCounters[condition.group] = groupCounters[condition.group] ? ++groupCounters[condition.group] : 1;
                                        condition.iconId = ("00000" + groupCounters[condition.group]).slice(-2) ;

                                        switch (condition.componentType.replace(/[0-9]*/gim, '').toLowerCase()) {

                                            case 'social':
                                            case 'preference':
                                                condition.colorId = 'bg-success';
                                                break;

                                            case 'address':
                                            case 'system':

                                                condition.colorId = 'bg-info';
                                                break;

                                            case 'common':
                                            case 'behavior':
                                            default:
                                                condition.colorId =  'bg-primary';
                                        }

                                        return condition;
                                    });

                                });
                } ] )

                .resolve('editState',[ function() { return editState; } ] )


                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
                    redirectTo: 'frontend.nopermissions'
                })

        }]);

