<additional-menu
  [hasFilter]="true"
  [(filterValue)]="searchReport"
>

  <div menuBlock>
    <ul class="nav nav-scroll flex-column">
      <li *ngFor="let report of reportList | callback: filterByName.bind(this)" class="nav-item">
        <a (click)="onSelectReport(report)"
           [ngClass]="{ 'nav-active':selectedReport?.id==report?.id }"
           class="overflow-wrap-break nav-link"
        >
          <text-highlight
            [text]="report?.name"
            [search]="searchReport"
          ></text-highlight>
        </a>
      </li>
    </ul>
  </div>

  <div selectedBlock>

      <span class="h4"
            [innerText]="selectedReport ? selectedReport?.name : '- Выбрать -'">
      </span>

  </div>

  <div contentBlock>

    <ng-container
      [ngSwitch]="selectedReport?.id"
    >
      <discount-by-company-report
        *ngSwitchCase="'discountByCompany'"
      ></discount-by-company-report>

    </ng-container>

  </div>

</additional-menu>
