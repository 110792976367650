/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ThresholdPriceApiConfiguration, ThresholdPriceApiConfigurationInterface } from './threshold-price-api-configuration';

import { ThresholdPriceApiService } from './services/threshold-price-api.service';

/**
 * Provider for all ThresholdPriceApi services, plus ThresholdPriceApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ThresholdPriceApiConfiguration,
    ThresholdPriceApiService
  ],
})
export class ThresholdPriceApiModule {
  static forRoot(customParams: ThresholdPriceApiConfigurationInterface): ModuleWithProviders<ThresholdPriceApiModule> {
    return {
      ngModule: ThresholdPriceApiModule,
      providers: [
        {
          provide: ThresholdPriceApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
