import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LOYA_CONFIG} from "../../../../shared/variables/loya-config.variable";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_ID_BonusId} from "../block-ids.variables";


export const BLOCK_ID = BLOCK_ID_BonusId;

@Component({
  selector: 'dashboard-campaign-bonus-chart',
  template: `


    <div class="card wrapper">

      <div class="col-sm-12">
        <h4 class="font-thin m-t-none m-b-md text-muted">Бонусы</h4>
      </div>

      <div class="row  text-center"
           style="height:  400px"
      >

        <div class="col-md-12"
             *ngIf="!isLoading"
        >

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfig"
            [(update)]="updateFlag"
            class="d-block w-100 h-100"
          ></highcharts-chart>

        </div>

        <div class="d-flex justify-content-center align-items-center w-100 h-100"
             *ngIf="isLoading"
        >
          <div class="text-info-dker ">
              <i class="fa fa-3x fa-spin fa-spinner"></i>
          </div>
        </div>


      </div>
      <no-location-warning
        [params]="params"
      ></no-location-warning>
    </div>

  `,
  providers: [
  ]
})
export class DashboardCampaignBonusChartComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public isLoading = true
  public updateFlag = false;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartConfig = {

    chart: {
      type: 'areaspline',
      animation: {
        duration: 1000
      },
    },

    colors: [
      LOYA_CONFIG.color.success,
      LOYA_CONFIG.color.warning,
      LOYA_CONFIG.color.danger,
      LOYA_CONFIG.color.info,
      LOYA_CONFIG.color.orange
    ],

    plotOptions: {

      areaspline: {
        fillOpacity: 0.5
      },

      series: {
        animation: {
          duration: 2000
        }
      }

    },

    title: {
      text: ''
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
    },

    xAxis: {
      categories: []
    },

    yAxis: {
      title: {
        text: ''
      }

    },
    tooltip: {
      shared: true,
      valueSuffix: ' шт'
    },
    credits: {
      enabled: false
    },

    series: []

  };

  public categories = [];
  public series =  [{
      name: 'Начислено',
      data: []
    }, {
      name: 'Списано',
      data: []
    }, {
      name: 'Сгорело',
      data: []
    }, {
      name: 'Накоплено',
      data: []
    },
    {
      name: 'Всего бонусов',
      data: []
    }];

  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.initChart();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  initChart() {
    this.chartConfig.xAxis.categories = this.categories || [];
    this.chartConfig.series = this.series || [];
  }

  updateValue(params?) {


    this.series.splice(0, this.series.length);
    this.categories.splice(0, this.categories.length);


    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {

          if (!Array.isArray(result))
            return;

          let dates = {}
          result.forEach( (item, index ) => {

            if (!Array.isArray(item.values))
              return;

            item.values.forEach( subItem => {

              dates[subItem.name] = dates[subItem.name]  || {};
              dates[subItem.name][index] = subItem.value;

            })

          });

          Object.keys(dates)
            .sort()
            .forEach( item => this.categories.push(item))

          result.forEach( (item, index ) => {

            let i = {
              name: item?.name,
              data: []
            };
            this.series.push( i );

            this.categories.forEach( date => {
              i.data.push( dates[date][index] || 0 );
            })
          })

/*

            Array.isArray(result) &&
              result.forEach( (item, index ) => {
                let i = {
                  name: item?.name,
                  data: []
                };
                this.series.push( i );

              Array.isArray(item?.values) &&
                item?.values.forEach(   dataItem => {

                  if (index === 0)
                    this.categories.push( dataItem.name );

                  i.data.push( dataItem.value );

                })

            })
*/

          this.isLoading = false;
          this.updateFlag = true;
        },
        error: () => {
          this.isLoading = false;
          this.updateFlag = true;
        }
      })


  }



  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
