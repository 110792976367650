'use strict';

import module from 'frontendModule';

import 'model/Coupon';
import 'model/CouponRule';
import 'model/ClientModel/resource';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.coupons.coupons.index';
module.requires = module.requires.concat([
    ,
    'infinite-scroll',
    'ui.footable'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:clientId/:couponRuleId')

                .template(   () => import( /* webpackChunkName: "assets/pages/coupons.rules.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/coupons.rules.index" */ "./controllers"       ))

                .resolve('clientItem',   [ 'ClientModel', '$stateParams', '$q', 'loyaPermissions',
                    function( ClientModel, $stateParams, $q, loyaPermissions ) {

                        if ( angular.isUndefined( $stateParams.clientId ) || $stateParams.clientId==='' ) {

                            if ( !loyaPermissions.isCapabilityEnabled('coupons.coupons.list') ){
                                loyaPermissions.redirectToNoPermission();
                            }

                            return $q.resolve(false);
                        }

                        return ClientModel.get({ id: $stateParams.clientId }).$promise;

                    }])

                .resolve('couponRuleItem',   [ 'CouponRule', '$stateParams', '$q',
                    function( CouponRule, $stateParams, $q ) {

                        if ( angular.isUndefined( $stateParams.couponRuleId ) || $stateParams.couponRuleId==='' ) {
                            return $q.resolve(false);
                        }

                        return CouponRule.get({ id: $stateParams.couponRuleId }).$promise;

                    }])


                .permissions( {
                    only: [ RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter ],

                })

                .breadcrumbs( [ "Купоны", "Купоны " ]);

        }]);
