'use strict';
import module from 'frontendModule';

export default module
        .factory('getRealCampaignValues', function () {

            var convertToFloats = function( obj ) {

                if ( typeof(obj) == "object") {
                    for (var i in  obj) {
                        obj[i] = convertToFloats(obj[i]);
                    }
                    return obj;
                }
                else
                    try {
                        return parseFloat( obj );
                    } catch( e ) {
                        return 0;
                    }
            };

            var toFixedValues = function( obj, fixedNum? ) {

                fixedNum = fixedNum || 2;

                if ( typeof(obj) == "object") {
                    for (var i in  obj) {
                        obj[i] = toFixedValues(obj[i], fixedNum);
                    }

                    return obj;
                }
                else
                    try {

                        switch( true ) {

                            case angular.isNumber(obj):
                                return parseFloat( obj.toFixed(fixedNum) );

                            case angular.isString(obj) && !isNaN( parseFloat(obj) ) :
                                return parseFloat(obj).toFixed(fixedNum);

                            default:
                                return obj;
                        }

                    } catch( e ) {
                        return obj;
                    }
            };


            return function (CampaignItem, CampaignResources) {

                CampaignItem.planValues = CampaignItem.planValues || {};

                var result: any  = {

                    communications: {
                        email: {
                            factPercent: 0,
                            cost: 0
                        },
                        sms: {
                            factPercent: 0,
                            cost: 0
                        },
                        check: {
                            factPercent: 0,
                            cost: 0
                        },
                        viber: {
                            factPercent: 0,
                            cost: 0
                        },
                        web: {
                            factPercent: 0,
                            cost: 0,
                            delivered: 0,
                            filled: 0
                        }
                    },

                    communicationsCost: {
                        email: {
                            cost: 0,
                            percent: 0
                        },

                        sms: {
                            cost: 0,
                            percent: 0
                        },

                        check: {
                            cost: 0,
                            percent: 0
                        },
                        web: {
                            cost: 0,
                            percent: 0
                        }
                    },

                    receiptCount: 0,
                    clientCount: 0,

                    total: {
                        discount: {
                            plan: 0,
                            real: 0,
                            percent: 0
                        },


                        bonus: {
                            plan: 0,
                            real: 0,
                            percent: 0
                        },


                        gift: {
                            plan: 0,
                            real: 0,
                            percent: 0
                        }
                    },

                    avg: {

                        totalOneClient: {

                            real: 0,
                            plan: 0,
                            percent: 0
                        },

                        sellingOneClient: {

                            real: 0,
                            plan: 0,
                            percent: 0
                        },

                        incomeOneClient: {

                            real: 0,
                            plan: 0,
                            percent: 0


                        }

                    }
                };

                angular.forEach(CampaignItem.communications, function (item) {

                    switch (item.channelType) {
                        case "email":
                            result.communications.email = item;
                            result.communications.email.factPercent = ( item.filled > 0 ) ? ( ( item.delivered / item.filled ) * 100 ).toFixed(2) : 100;

                            if ( angular.isString( result.communications.email.cost ) ) {
                                result.communications.email.cost = parseFloat(result.communications.email.cost);
                            }

                            break;

                        case "sms":
                            result.communications.sms = item;
                            result.communications.sms.factPercent = ( item.filled > 0 ) ? ( ( item.delivered / item.filled ) * 100 ).toFixed(2) : 100;

                            if ( angular.isString( result.communications.sms.cost ) ) {
                                result.communications.sms.cost = parseFloat(result.communications.sms.cost);
                            }

                            break;

                        case "check":
                            result.communications.check = item;
                            result.communications.check.factPercent = ( item.filled > 0 ) ? ( ( item.worked / item.filled ) * 100 ).toFixed(2) : 100;

                            if ( angular.isString( result.communications.check.cost ) ) {
                                result.communications.check.cost = parseFloat(result.communications.check.cost);
                            }

                            break;

                        case "viber":

                            result.communications.viber = item;
                            result.communications.viber.factPercent = ( item.filled > 0 ) ? ( ( item.delivered / item.filled ) * 100 ).toFixed(2) : 100;

                            if ( angular.isString( result.communications.viber.cost ) ) {
                                result.communications.viber.cost = parseFloat(result.communications.viber.cost);
                            }

                            break;

                        case "web":
                            result.communications.web = item;
                            result.communications.web.factPercent = ( item.filled > 0 ) ? ( ( item.delivered / item.filled ) * 100 ).toFixed(2) : 100;

                            if ( angular.isString( result.communications.web.cost ) ) {
                                result.communications.web.cost = parseFloat(result.communications.web.cost);
                            }

                            break;
                    }

                });

                result.resources = {};
                angular.forEach(CampaignItem.resource, function (item) {

                    result.resources[item.resource] = item;
                    /*
                            val bonus = Value(0)
                            val discount = Value(1)
                            val gift = Value(2)
                            val communication = Value(3)
                            val other = Value(99)
                    */

                });

                result.communicationsCost = {

                        email: {
                            cost: result.communications.email.cost,
                            percent: ( result.communications.email.cost + result.communications.sms.cost > 0) ? result.communications.email.cost / (result.communications.email.cost + result.communications.sms.cost) * 100 : 0
                        },

                        sms: {
                            cost: result.communications.sms.cost,
                            percent: ( result.communications.email.cost + result.communications.sms.cost > 0) ? result.communications.sms.cost / ( result.communications.email.cost + result.communications.sms.cost) * 100 : 0
                        }

                    };

                if ( CampaignItem.realValues ) {
                    result.receiptCount = CampaignItem.realValues.receiptCount || 0;
                    result.clientCount = CampaignItem.realValues.clientCount || 0;
                } else {
                    result.receiptCount = 0;
                    result.clientCount  = 0;
                }

/*

                result.total = {
                        discount: {
                            plan: parseFloat(CampaignItem.planValues.discountTotal),
                            real: 0,
                            percent: (parseFloat(CampaignItem.planValues.discountTotal) > 0) ? 0 / parseFloat(CampaignItem.planValues.discountTotal) * 100 : 0
                        },


                        bonus: {
                            plan: CampaignItem.planValues.bonusTotal,
                            real: 0,
                            percent: (CampaignItem.planValues.bonusTotal > 0) ? 0 / (CampaignItem.planValues.bonusTotal) * 100 : 0
                        },


                        gift: {
                            plan: CampaignItem.planValues.giftTotal,
                            real: 0,
                            percent: (CampaignItem.planValues.giftTotal > 0) ? 0 / (CampaignItem.planValues.giftTotal) * 100 : 0
                        }
                    }; */

                result.total = {
                    discount: {
                        plan: ( result.resources.discount && result.resources.discount.planValue ) ?  result.resources.discount.planValue : 0,
                        real: ( result.resources.discount && result.resources.discount.realValue ) ?  result.resources.discount.realValue : 0
                    },


                    bonus: {
                        plan: ( result.resources.bonus && result.resources.bonus.planValue ) ?  result.resources.bonus.planValue : 0,
                        real: ( result.resources.bonus && result.resources.bonus.realValue ) ?  result.resources.bonus.realValue : 0
                    },


                    gift: {
                        plan: ( result.resources.gift && result.resources.gift.planValue ) ?  result.resources.gift.planValue : 0,
                        real: ( result.resources.gift && result.resources.gift.realValue ) ?  result.resources.gift.realValue : 0
                    }

                };

                result.total.discount.percent = result.total.discount.plan !== 0 ? ( result.total.discount.real / result.total.discount.plan * 100 ).toFixed(2) : 100;
                result.total.bonus.percent = result.total.bonus.plan !== 0 ? ( result.total.bonus.real / result.total.bonus.plan * 100 ).toFixed(2) : 100;
                result.total.gift.percent = result.total.gift.plan !== 0 ? ( result.total.gift.real / result.total.gift.plan * 100 ).toFixed(2) : 100;


                result.avg = {

                    totalOneClient: {

                        real: CampaignItem.realValues && CampaignItem.realValues.clientCount > 0 ? CampaignItem.realValues.receiptSelling / CampaignItem.realValues.clientCount : 0,
                        plan: parseFloat(CampaignItem.planValues.clientSelling) || 0,
                        percent: 0
                    },

                    sellingOneClient: {
                        real: 0,
                        plan: 0,
                        percent: 0
                    },

                    incomeOneClient: {
                        real: 0,
                        plan: 0,
                        percent: 0
                    }
                };

                if (result.avg.totalOneClient.plan === 0) {
                    result.avg.totalOneClient.percent = 100;
                } else {
                    result.avg.totalOneClient.percent = toFixedValues(result.avg.totalOneClient.real / result.avg.totalOneClient.plan * 100, 0);
                }

                if (CampaignItem.realValues)
                    convertToFloats(CampaignItem.realValues);

                if (CampaignItem.planValues)
                    convertToFloats(CampaignItem.planValues);


                result.avg.sellingOneClient.real = ( CampaignItem.realValues && parseFloat(CampaignItem.realValues.clientCount) ) ? parseFloat(CampaignItem.realValues.totalCosts) / CampaignItem.realValues.clientCount : 0;
                result.avg.sellingOneClient.plan = parseFloat(CampaignItem.planValues.totalByClient);
                result.avg.sellingOneClient.percent = ( result.avg.sellingOneClient.plan  ) ? (result.avg.sellingOneClient.real ) / result.avg.sellingOneClient.plan * 100 : 100;


                result.avg.incomeOneClient.real = result.avg.totalOneClient.real - result.avg.sellingOneClient.real;
                result.avg.incomeOneClient.plan = parseFloat(  CampaignItem.planValues.clientIncome );
                result.avg.incomeOneClient.percent = ( result.avg.incomeOneClient.plan ) ? (result.avg.incomeOneClient.real ) / result.avg.incomeOneClient.plan * 100 : 0;



                result.total.selling = {};
                result.total.selling.real = ( CampaignItem.realValues &&
                CampaignItem.realValues.receiptSelling ) ?

                    ( parseFloat(CampaignItem.realValues.receiptSelling) ) : 0;

                result.total.selling.plan = ( CampaignItem.planValues && CampaignItem.planValues.clientSellingTotal ) ? parseFloat( CampaignItem.planValues.clientSellingTotal) : 0;
                result.total.selling.percent = ( result.total.selling.plan ) ? ( (result.total.selling.real ) / result.total.selling.plan * 100 ) : 0;

                result.total.income= {};
                result.total.income.real= ( CampaignItem.realValues &&
                                            CampaignItem.realValues.totalCosts &&
                                            CampaignItem.realValues.totalCosts !== 0) ?

                                          ( parseFloat(result.total.selling.real ) - parseFloat( CampaignItem.realValues.totalCosts ) ) : 0;
                result.total.income.plan = ( CampaignItem.planValues && CampaignItem.planValues.clientIncomePercent ) ? parseFloat( CampaignItem.planValues.clientIncomePercent) : 0;
                result.total.income.percent = ( result.total.income.plan ) ? ( (result.total.income.real ) / result.total.income.plan * 100 ) : 0;

                toFixedValues(result.avg);
                toFixedValues(result.total);

                return result;
                };


        });
