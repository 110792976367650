
<div class="fixed-tool-panel">

  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'frontend.triggers.trigger.view({id:'+id+'})'"
  >
    <i class="fa fa-arrow-left "></i>
    <span class="btn-name ">  К цепочке</span>
  </button>

</div>

 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
     <tr>
       <th ><span class="ng-binding">Фамилия</span></th>
       <th ><span class="ng-binding">Имя</span></th>
       <th ><span class="ng-binding">Отчество</span></th>
       <th ><span class="ng-binding">День рождения</span></th>
       <th class="" ><span class="ng-binding">Мобильный телефон</span></th>
       <th class="" ><span class="ng-binding">Email</span></th>
       <th class="" ><span>Номер карты</span></th>
       <th class="" ><span>Время срабатывания</span></th>
     </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listClients ">
          <tr
          >
            <td  class="table-big-link">
              <a href="javascript:void(0)" [innerText]="item.surname || ''" [uiSref]="'frontend.crm.clients.view({id:'+item.id+'})'" ></a>
            </td>
            <td class="table-big-link">
              <a href="javascript:void(0)" [innerText]="item.name || ''" [uiSref]="'frontend.crm.clients.view({id:'+item.id+'})'" ></a>
            </td>
            <td class="table-big-link">
              <a href="javascript:void(0)" [innerText]="item.patronymic || ''" [uiSref]="'frontend.crm.clients.view({id:'+item.id+'})'" ></a>
            </td>
            <td>
              <span [innerText]="(item.birthday | loyaDate )   || ''"></span>
            </td>
            <td>
              <span [innerText]="item.phone  || ''"></span>
            </td>
            <td>
              <span [innerText]="item.email  || ''"></span>
            </td>
            <td>
              <div class="clearfix" *ngFor="let card of item.cards">
                <span  [innerText]="card.number" ></span>
              </div>
            </td>
            <td>
              <span [innerText]="item.createdAt  || '' | loyaDateTime"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
