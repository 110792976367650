/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GoodGroupLinkCategoriesApiConfiguration, GoodGroupLinkCategoriesApiConfigurationInterface } from './good-group-link-categories-api-configuration';

import { GoodGroupLinkCategoriesApiService } from './services/good-group-link-categories-api.service';

/**
 * Provider for all GoodGroupLinkCategoriesApi services, plus GoodGroupLinkCategoriesApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    GoodGroupLinkCategoriesApiConfiguration,
    GoodGroupLinkCategoriesApiService
  ],
})
export class GoodGroupLinkCategoriesApiModule {
  static forRoot(customParams: GoodGroupLinkCategoriesApiConfigurationInterface): ModuleWithProviders<GoodGroupLinkCategoriesApiModule> {
    return {
      ngModule: GoodGroupLinkCategoriesApiModule,
      providers: [
        {
          provide: GoodGroupLinkCategoriesApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
