/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GoodApiConfiguration as __Configuration } from '../good-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Good } from '../models/good';
@Injectable({
  providedIn: 'root',
})
class GoodApiService extends __BaseService {
  static readonly queryPath = '/good';
  static readonly createPath = '/good';
  static readonly querySkusByPartnerPath = '/good/{partnerId}/get/skus';
  static readonly queryByPartnerPath = '/good/{partnerId}';
  static readonly queryByCategoryPath = '/good/{partnerId}/{categoryId}/goods';
  static readonly getPath = '/good/{partnerId}/{sku}';
  static readonly updatePath = '/good/{partnerId}/{sku}';
  static readonly deletePath = '/good/{partnerId}/{sku}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка товаров
   *
   * Запрос списка товаров
   * @param params The `GoodApiService.QueryParams` containing the following parameters:
   *
   * - `sort`: Сортировка
   *
   * - `limit`: Кол-во возращаемых записей, по умолчанию 100
   *
   * - `drop`: Кол-во пропущенных записей от начала (при сортировке по ID), по умолчанию 0
   *
   * @return Список товаров
   */
  queryResponse(params: GoodApiService.QueryParams): __Observable<__StrictHttpResponse<Array<Good>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/good`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Good>>;
      })
    );
  }
  /**
   * Запрос списка товаров
   *
   * Запрос списка товаров
   * @param params The `GoodApiService.QueryParams` containing the following parameters:
   *
   * - `sort`: Сортировка
   *
   * - `limit`: Кол-во возращаемых записей, по умолчанию 100
   *
   * - `drop`: Кол-во пропущенных записей от начала (при сортировке по ID), по умолчанию 0
   *
   * @return Список товаров
   */
  query(params: GoodApiService.QueryParams): __Observable<Array<Good>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<Good>)
    );
  }

  /**
   * Создать товар
   *
   * Создать товар
   * @param good Описание товара
   * @return Товар создан
   */
  createResponse(good: Good): __Observable<__StrictHttpResponse<Good>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = good;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/good`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Good>;
      })
    );
  }
  /**
   * Создать товар
   *
   * Создать товар
   * @param good Описание товара
   * @return Товар создан
   */
  create(good: Good): __Observable<Good> {
    return this.createResponse(good).pipe(
      __map(_r => _r.body as Good)
    );
  }

  /**
   * Список товаров по указанному партнёру
   *
   * Список товаров по указанному партнёру
   * @param params The `GoodApiService.QuerySkusByPartnerParams` containing the following parameters:
   *
   * - `skus`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * @return Список товаров
   */
  querySkusByPartnerResponse(params: GoodApiService.QuerySkusByPartnerParams): __Observable<__StrictHttpResponse<Array<Good>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.skus;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/get/skus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Good>>;
      })
    );
  }
  /**
   * Список товаров по указанному партнёру
   *
   * Список товаров по указанному партнёру
   * @param params The `GoodApiService.QuerySkusByPartnerParams` containing the following parameters:
   *
   * - `skus`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * @return Список товаров
   */
  querySkusByPartner(params: GoodApiService.QuerySkusByPartnerParams): __Observable<Array<Good>> {
    return this.querySkusByPartnerResponse(params).pipe(
      __map(_r => _r.body as Array<Good>)
    );
  }

  /**
   * Список товаров по указанному партнёру
   *
   * Список товаров по указанному партнёру
   * @param partnerId ID партнёра
   * @return Список товаров
   */
  queryByPartnerResponse(partnerId: number): __Observable<__StrictHttpResponse<Array<Good>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/good/${encodeURIComponent(String(partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Good>>;
      })
    );
  }
  /**
   * Список товаров по указанному партнёру
   *
   * Список товаров по указанному партнёру
   * @param partnerId ID партнёра
   * @return Список товаров
   */
  queryByPartner(partnerId: number): __Observable<Array<Good>> {
    return this.queryByPartnerResponse(partnerId).pipe(
      __map(_r => _r.body as Array<Good>)
    );
  }

  /**
   * Список товаров для указанной категории
   *
   * Список товаров для указанной категории
   * @param params The `GoodApiService.QueryByCategoryParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Список товаров
   */
  queryByCategoryResponse(params: GoodApiService.QueryByCategoryParams): __Observable<__StrictHttpResponse<Array<Good>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}/goods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Good>>;
      })
    );
  }
  /**
   * Список товаров для указанной категории
   *
   * Список товаров для указанной категории
   * @param params The `GoodApiService.QueryByCategoryParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Список товаров
   */
  queryByCategory(params: GoodApiService.QueryByCategoryParams): __Observable<Array<Good>> {
    return this.queryByCategoryResponse(params).pipe(
      __map(_r => _r.body as Array<Good>)
    );
  }

  /**
   * Данные по указанному товару
   *
   * Данные по указанному товару
   * @param params The `GoodApiService.GetParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * @return Товар найден
   */
  getResponse(params: GoodApiService.GetParams): __Observable<__StrictHttpResponse<Good>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Good>;
      })
    );
  }
  /**
   * Данные по указанному товару
   *
   * Данные по указанному товару
   * @param params The `GoodApiService.GetParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * @return Товар найден
   */
  get(params: GoodApiService.GetParams): __Observable<Good> {
    return this.getResponse(params).pipe(
      __map(_r => _r.body as Good)
    );
  }

  /**
   * Изменить указанный товар
   *
   * Изменить указанный товар
   * @param params The `GoodApiService.UpdateParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * - `good`: Данные товара
   *
   * @return Товар изменён
   */
  updateResponse(params: GoodApiService.UpdateParams): __Observable<__StrictHttpResponse<Good>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.good;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Good>;
      })
    );
  }
  /**
   * Изменить указанный товар
   *
   * Изменить указанный товар
   * @param params The `GoodApiService.UpdateParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   *
   * - `good`: Данные товара
   *
   * @return Товар изменён
   */
  update(params: GoodApiService.UpdateParams): __Observable<Good> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as Good)
    );
  }

  /**
   * Удалить указанный товар
   *
   * Удалить указанный товар
   * @param params The `GoodApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   */
  deleteResponse(params: GoodApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить указанный товар
   *
   * Удалить указанный товар
   * @param params The `GoodApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнёра
   */
  delete(params: GoodApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GoodApiService {

  /**
   * Parameters for query
   */
  export interface QueryParams {

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Кол-во возращаемых записей, по умолчанию 100
     */
    limit?: number;

    /**
     * Кол-во пропущенных записей от начала (при сортировке по ID), по умолчанию 0
     */
    drop?: number;
  }

  /**
   * Parameters for querySkusByPartner
   */
  export interface QuerySkusByPartnerParams {

    /**
     * SKU товара
     */
    skus: Array<string>;

    /**
     * ID партнёра
     */
    partnerId: number;
  }

  /**
   * Parameters for queryByCategory
   */
  export interface QueryByCategoryParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;
  }

  /**
   * Parameters for get
   */
  export interface GetParams {

    /**
     * SKU товара
     */
    sku: string;

    /**
     * ID партнёра
     */
    partnerId: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * SKU товара
     */
    sku: string;

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * Данные товара
     */
    good: Good;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * SKU товара
     */
    sku: string;

    /**
     * ID партнёра
     */
    partnerId: number;
  }
}

export { GoodApiService }
