/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FavoriteGoodsProfileApiConfiguration, FavoriteGoodsProfileApiConfigurationInterface } from './favorite-goods-profile-api-configuration';

import { FavoriteGoodsProfileApiService } from './services/favorite-goods-profile-api.service';

/**
 * Provider for all FavoriteGoodsProfileApi services, plus FavoriteGoodsProfileApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FavoriteGoodsProfileApiConfiguration,
    FavoriteGoodsProfileApiService
  ],
})
export class FavoriteGoodsProfileApiModule {
  static forRoot(customParams: FavoriteGoodsProfileApiConfigurationInterface): ModuleWithProviders<FavoriteGoodsProfileApiModule> {
    return {
      ngModule: FavoriteGoodsProfileApiModule,
      providers: [
        {
          provide: FavoriteGoodsProfileApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
