import {Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {BrandService} from "../../../../../shared/service/api/Brand/Brand.service";
import {ClientCategory} from "../../../../../api/ClientCategoryApi/models/client-category";
import {Brand} from "../../../../../api/BrandApi/models/brand";
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {BrandGoodLinkService} from "../../../../../shared/service/api/BrandGoodLinkApi/BrandGoodLink.service";
import {result} from "lodash";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {GoodgroupService} from "../../../../../shared/service/api/Goodgroup/Goodgroup.service";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {GoodgroupLinkBrandsService} from "../../../../../shared/service/api/GoodgroupLinkBrands/GoodgroupLinkBrands.service";
import {GoodgroupLinkService} from "../../../../../shared/service/api/GoodgroupLink/GoodgroupLink.service";
import {GoodgroupLinkCategoriesService} from "../../../../../shared/service/api/GoodgroupLinkCategories/GoodgroupLinkCategories.service";


@Component({
  selector: 'goodgroup-view-page',
  templateUrl: './goodgroup-view-page.component.html',
  providers:[
    BrandService,
    StatesChangerService,
    ConfirmActionService,
    BrandGoodLinkService,
    GoodgroupService,
    ngCurrencyL10nService,
    PartnerService,
    GoodgroupLinkBrandsService,
    GoodgroupLinkService,
    GoodgroupLinkCategoriesService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.brand'
    },
  ],
})
export class GoodgroupViewPageComponent implements OnInit{

  @Input() id: number;
  @Input() partnerId: number;

  @ViewChild('goodgroupFrom') goodgroupFrom;

  public currentGoodgroup;
  private uiRouterState;

  public awardTypes;
  public awardTypesDiscount;

  public activeTab;

  public brandList = {
    items : [],
    drop  : 0,
    deletedAll  : false,
    hasSelected : false,
    isLoading   : false,
  }

  public skuList = {
    items : [],
    drop  : 0,
    deletedAll  : false,
    hasSelected : false,
    isLoading   : false,
  }

  public categoryList = {
    items : [],
    drop  : 0,
    deletedAll  : false,
    hasSelected : false,
    isLoading   : false,
  }

  public onSelectBinded: Function;

  constructor(
    public  brandService: BrandService,
    private brandGoodLinkService:BrandGoodLinkService,


    public  statesChangerService:StatesChangerService,
    public  confirmActionService :ConfirmActionService,
    private goodgroupService : GoodgroupService,
    private ngCurrencyL10nService : ngCurrencyL10nService,
    private partnerService :PartnerService,
    private goodgroupLinkBrandsService     : GoodgroupLinkBrandsService,
    private goodgroupLinkService           : GoodgroupLinkService,
    private goodgroupLinkCategoriesService : GoodgroupLinkCategoriesService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.initTypes();
    this.bindButtons();
    this.onSelectBinded = this.onSelect.bind(this); // Для проброса в старый ангуляр
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getGoodgroup(changes.id.currentValue)
        .then( (result:any) => {
          if (changes.id.firstChange) {
            this.statesChangerService.state = !changes.id.currentValue ?
              'edit' :
              'view';
          }
        })
      ;
    }
  }

  getGoodgroup( id:number  ) {

    return this.goodgroupService
      .getGoodgroup$(id, this.partnerId.toString() )
      .toPromise()
      .then( result => {
        this.currentGoodgroup = result
        this.currentGoodgroup.limits = this.currentGoodgroup.limits || {};
      } )
      .then(() => this.getList('sku'))
      .then(() => this.getList('category'))
      .then(() => this.getList('brand'));

  }


  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  onSave() {

    this.goodgroupService
      .saveGoodgroup$(this.currentGoodgroup)
      .toPromise()
      .then(result => this.currentGoodgroup = result )
      .then(() => this.processList('sku') )
      .then(() => this.processList('category') )
      .then(() => this.processList('brand') )
      .then(() =>this.statesChangerService.state = 'view');

  }

  onCancel() {
    if (typeof this.currentGoodgroup.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getGoodgroup(this.id)
      .then(() => this.resetList('sku'))
      .then(() => this.resetList('category'))
      .then(() => this.resetList('brand'))
      .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentGoodgroup.id === "undefined")
      return;

    this.goodgroupService
      .deleteGoodgroup$(this.currentGoodgroup.id, this.currentGoodgroup.partnerId)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  initTypes() {
    const percent =  {
      name    : 'Процент',
      label   : '%',
      min     : 0,
      max     : 100,
      pattern : /^(\d{1,9})(\.\d{1,2})?$/
    }

    const count = {
      name  : 'Количество',
      label :  'шт',
      min     : 0,
      max     : 10000000000,
      pattern : /^((10000000000(.0{1,2})?)|[0-9]{1,10}(.[0-9]{1,2})?)$/
    }

    const fixprice = {
      name   : 'Мин. цена по скидкам',
      label  : undefined,
      min     : 0,
      max     : 10000000000,
      pattern : /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/
    }

    this.awardTypes = {
      'percent' : Object.assign({}, percent),
      'count'   : Object.assign({}, count),
    };

    this.awardTypesDiscount = {
      'percent' : Object.assign({}, percent),
      'count'   : Object.assign({}, count),
      'fixprice': Object.assign({}, fixprice),
    };

    this.ngCurrencyL10nService.getShortName()
      .then( result => {
        this.awardTypesDiscount['count'].label = result;
        this.awardTypesDiscount['fixprice'].label = result;
      })

    if (typeof this.partnerId !== "undefined")
      this.partnerService
        .getBonusPattern(this.partnerId)
        .then(result => {
          this.awardTypes.count.pattern = result;
        });
  }

  initPeriodDates = function(period) {

    if (!period) return ;

    if (typeof period.start === "string") {
      period.start = new Date( Date.parse(`${period.start}`));
    }

    if (typeof period.stop === "string") {
      period.stop = new Date( Date.parse(`${period.stop}`));
    }

  }

  deletePeriod = function( item, index ) {
    if (!item || !Array.isArray( item.periods) )
      return;

    item.periods.splice(index, 1);
    if (!item.periods.length)
      delete item.periods;
  }

  addPeriods = function( item ) {
    item.periods = item.periods || [];
    if (item.periods.length > 50)
      return;
    item.periods.push({});
  };

  filterByDeleted(item) {
    return !item.$isForDelete;
  }

  //---------- таблицы ------------

  public emptyDummy;
  get isEmptyAll() {
    return ! (
      !!this.skuList?.items?.length ||
      !!this.categoryList?.items?.length ||
      !!this.brandList?.items?.length
    )
  }

  getListService(type): any {
    switch (type){
      case 'sku'      : return this.goodgroupLinkService           ; break;
      case 'category' : return this.goodgroupLinkCategoriesService ; break;
      case 'brand'    : return this.goodgroupLinkBrandsService     ; break;
    }
  }

  getCurrentList(): any {
    return this[this.activeTab+'List'];
  }

  resetList(type: string) {
    this[type+'List'] = {
      items : [],
      drop  : 0,
      deletedAll  : false,
      hasSelected : false
    }

    return this.getList(type);
  }

  getList(type: string) {

    const PAGER_LIMIT = 50;

    if (typeof this.currentGoodgroup.id === "undefined") {

      if (!this[type+'List']?.items) {
        this[type+'List'].items = []
      }

      return Promise.resolve();
    }

    if (this[type+'List'].drop == -1 || this[type+'List'].isLoading) {
      return Promise.resolve();
    }

    let sortParams: any = {};
    try {
      sortParams.sortingField = this.getListService(type).getListParams.params.sortingField;
      sortParams.sortingOrder = this.getListService(type).getListParams.params.sortingOrder;
    } catch (e) {

    }

    this[type+'List'].isLoading = true;
    return this.getListService(type).getList$(Object.assign({
      id        : this.currentGoodgroup.id,
      partnerId : this.partnerId,
      pagerDrop : this[type+'List'].drop,
      pagerLimit: PAGER_LIMIT,
    },sortParams )).toPromise()
      .then( result => {
        this[type+'List'].drop = result.length !== PAGER_LIMIT ? -1 : this[type+'List'].drop + result.length;
        this[type+'List'].items = this[type+'List'].items.concat(result);
        this[type+'List'].isLoading = false;
      }, () => {
        this[type+'List'].isLoading = false;
      })
  }

  processList(type: string) {
    let service = this.getListService(type);
    return Promise.resolve()
      .then(() => {
        if (this[type+'List'].deletedAll)
          return service.deleteAll$(this.currentGoodgroup.id, this.partnerId).toPromise();
      })
      .then(() => {
        if (this[type+'List'].items.some(i=> i.$isForDelete))
          return service.deleteList$({
            id         : this.currentGoodgroup.id,
            partnerId  : this.partnerId,
            links  : this[type+'List'].items.filter(i=> i.$isForDelete).map(i=> i)
          }).toPromise();
      })
      .then(() => {
        this[type+'List'].items = this[type+'List'].items.filter(i =>!i.$isForDelete);
      })
      .then(()=> {
        if (this[type+'List'].items.some(i=> i.$isForAdd))
          return service.addList$({
            id         : this.currentGoodgroup.id,
            partnerId  : this.partnerId,
            links      : this[type+'List'].items.filter(i=> i.$isForAdd)
          }).toPromise();
      })
      .then(()=> {
        this[type+'List'].items.forEach( i => {
          delete i.$isForAdd;
          delete i.$isForDelete;
          delete i.$isSelected;
        });
      })

  }

  onDeleteList(type: string) {

    if ( !this[type+'List'].hasSelected ) {
      this[type+'List'].deletedAll = true;
      this[type+'List'].items = [];
      return;
    }

    this[type+'List'].items.forEach( i => {
      if (i.$isSelected) {
        i.$isForDelete = true;
        i.$isSelected = false;
      }
    })
    this[type+'List'].hasSelected = false;
  }

  onChangeSelected($event, type: string) {
    if ($event.target.checked) {
      this[type+'List'].hasSelected = true;
    }

    this[type+'List'].hasSelected = this[type+'List'].items.some( i => i.$isSelected);
  }

  onSelect( addList : any) {

    this[addList.type+'List'].items = this[addList.type+'List'].items || [];
    this.activeTab = addList.type;
    switch (addList.type) {

      case 'sku':
        this.skuList.items = this.skuList.items.concat(
            addList.list
                .filter( i => !this.skuList.items.some( b=> {
                  if ( b.sku !== i.id ||
                     ( b.sku === i.id &&  b.locationId !== -1 ) ||
                     ( b.sku === i.id &&  b.locationId === -1 && !b.$isForAdd )
                  )
                    return false;

                  if (b.$isForDelete) {
                    b.$isForDelete = false;
                    b.$isSelected  = false;
                  }
                  return true;

                }) )
                .map(i=> ({
                  '$isForAdd' : true,
                  'partnerId' : this.partnerId,
                  'sku'       : i.id,
                  'name'      : i.label,
                  'dimension' : i.dimension,
                  'brandId'   : this.currentGoodgroup.id,
                  'quantity'  : i.dimension === 'weight' ? 0.001 : 1,
                  'locationId': -1
                }))
        );
        break;

      case 'category':
        this.categoryList.items = this.categoryList.items.concat(
          addList.list
            .filter( i => !this.categoryList.items.some( b=> {
              if ( b.categoryId !== i.id )
                return false;

              if (b.$isForDelete) {
                b.$isForDelete = false;
                b.$isSelected  = false;
              }
              return true;

            }) )
            .map(i=> ({
              '$isForAdd' : true,
              'partnerId' : this.partnerId,
              'categoryId': i.id,
              'name'      : i.label,
            }))
        );
        break;

      case 'brand':
        this.brandList.items = this.brandList.items.concat(
          addList.list
            .filter( i => !this.brandList.items.some( b=> {
              if ( b.brandId !== i.id )
                return false;

              if (b.$isForDelete) {
                b.$isForDelete = false;
                b.$isSelected  = false;
              }
              return true;

            }) )
            .map(i=> ({
              '$isForAdd' : true,
              'brandId'   : i.id,
              'name'      : i.label,
              'description': i.description,
            }))
        );
        break;
    }


  }

  createLocation($event, item) {
    if (Array.isArray($event.locations) && $event.locations.length ) {
      item.locationId   = $event.locations[0].id;
      item.locationName = $event.locations[0].name;
    }
  }

  callbackImportExcel() {
    this.activeTab = 'sku';
    this.resetList('sku');
  }

}
