'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';
import {RoleType} from "module/permissions/conf/roles";


let routePath = module.name + '.editions.editions.view';
module.requires = module.requires.concat([
]);


export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')


                .template(template)
                .controller(controller)


                .resolve('partnerList', ['PartnerModel', function (PartnerModel) {

                    return PartnerModel.query().$promise.then(function( data ){
                        var result = {};

                        angular.forEach(data, function(item) {
                            result[item.id] = item;
                        });

                        data = null;

                        return result;
                    });

                }] )

                .resolve('editionItem',  ['$stateParams', 'EditionModel', '$q',
                    function ($stateParams, EditionModel, $q ) {

                        if (!$stateParams.id && $stateParams.id !== 0) {

                            var result = new EditionModel({
                              'fixed': true,
                              encrypted: false,
                              period: {},
                              via: 1
                            });

                            return $q.resolve(result);

                        }

                        return EditionModel.get($stateParams).$promise;
                    }])

              .resolve('historyItem',  ['editionItem', 'EditionModel',
                function (editionItem, EditionModel  ) {

                  if (!editionItem || !editionItem.id) {
                    return [];
                  }

                  return EditionModel.queryHistory({id:editionItem.id}).$promise;
                }])



                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter],
                    permit: 'editions'
                })

                .breadcrumbs( ["Подарочные сертификаты", "Инфо о тираже"] );

        }]);
