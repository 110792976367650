<span [ngSwitch]="model.check.state" data-testid="check.state">

    <div *ngSwitchCase="'create'"
         class="pull-left m-l-n-md  trans-icon"
         ngbPopover="Чек сохранён"
         triggers="mouseenter:mouseleave">
        <i class="fa fa-floppy-o text-warning-dk"></i>
    </div>

    <div *ngSwitchCase="'saved'" class="pull-left m-l-n-md  trans-icon"
         ngbPopover="Чек обработан"
         triggers="mouseenter:mouseleave"
         >
        <i class="fa fa-check-circle-o "></i>
    </div>

    <div *ngSwitchCase="'process'" class="pull-left m-l-n-md  trans-icon"
         ngbPopover="Чек обсчитывается"
         triggers="mouseenter:mouseleave"
         >
        <i class="fa fa-clock-o text-warning-dk"></i>
    </div>

    <div *ngSwitchCase="'old'" class="pull-left m-l-n-md  trans-icon"
         ngbPopover="Чек обсчитан"
         triggers="mouseenter:mouseleave"
         >
        <i class="fa fa-check-circle"></i>
    </div>

    <div *ngSwitchCase="'error'" class="pull-left m-l-n-md  trans-icon"
         ngbPopover="Ошибка разбора чека"
         triggers="mouseenter:mouseleave"
    >
        <i class="fa fa-times-circle text-danger"></i>
    </div>

</span>

<div class="m-l-lg">

    <div class="m-b-xs">
        <span class="h4">
                            <span [innerText]="model.merchantName" data-testid="merchantName"></span>
                          </span>,
        <span class="text-muted" *ngIf="model.check.chType!==2">оплата</span>
        <span class="text-muted" *ngIf="model.check.chType===2">возврат</span>
        <span class="m-l-xs m-r-xs" [innerText]="model.paidInCurrency | loyaCurrency " data-testid="check.paidInCurrency"></span>
        <i class="fa le" ngCurrencyLoya >
        </i>
        <span class="m-l-sm pull-right">
                            <span class="font-thin" [innerText]="model.check.date | loyaDateTime" data-testid="check.date"></span>
                            <span class="text-muted"></span>
                          </span>
    </div>

    <div class="m-b">
        <div  class="clearfix">
            <div class="pull-left m-r m-b-xs">

                <i class="fa fa-file-text-o text-muted f-s-9 m-r-xs" *ngIf="model.check.chType!==2"> </i>
                <i class="fa fa-history f-s-9 text-muted m-r-xs  text-danger"
                   ngbPopover="Чек на возврат"
                   triggers="mouseenter:mouseleave"
                   *ngIf="model.check.chType==2"></i>

                <span class="text-muted f-s-9">
                                Чек:
                            </span>
                <span class="f-s-9" *ngIf="searchModel" data-testid="check.number">
                    <search-highlight [model]="searchModel" [text]="model.check.number"></search-highlight>
                </span>

                <span class="f-s-9" *ngIf="!searchModel" [innerText]="model.check.number"></span>


            </div>
            <div class="pull-left m-r m-b-xs">
                <i class="fa fa-list-ul text-muted f-s-9 m-r-xs">
                </i>
                <span class="text-muted f-s-9">
                  Товаров всего:
                </span>
                <span class="f-s-9" [innerText]="model.check.positionCount" data-testid="check.positionCount"></span>
            </div>
            <!-- divclass="pull-left m-r m-b-xs">
                  <span class="text-muted f-s-9">
                    Тип транзакции:
                  </span>
                  <span class="f-s-9">

                  </span>
            </div -->
            <div class="pull-left m-r m-b-xs"
                 *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                <i class="fa fa-trophy text-muted f-s-9 m-r-xs">
                </i>
                <span class="text-muted f-s-9"
                      *ngIf="model.check.chType!==2">Оплачено бонусами:</span>
                <span class="text-muted f-s-9"
                      *ngIf="model.check.chType===2">Возрат оплаты бонусами:</span>
                <span class="f-s-9 m-l-xs m-r-xs" [innerText]="model.usedBonusInCurrency | loyaNumber:false" data-testid="check.usedBonusInCurrency"></span>
                <i class="fa le f-s-8 text-muted" ngCurrencyLoya ></i>
            </div>

            <div class="pull-left m-r m-b-xs" *ngIf="model.check.discount">
                <i class="fa fa-percent text-muted f-s-9 m-r-xs">
                </i>
                <span class="text-muted f-s-9" >Скидка:</span>
                <span class="f-s-9 m-l-xs m-r-xs" [innerText]="model.check.discount | loyaNumber:false" data-testid="check.discount"></span>
                <i class="fa le f-s-8 text-muted" ngCurrencyLoya ></i>
            </div>

            <div class="pull-left m-r m-b-xs" *ngIf="model.check.total">
                <i class="fa fa-shopping-cart text-muted f-s-9 m-r-xs">
                </i>
                <span class="text-muted f-s-9" >Сумма чека:</span>
                <span class="f-s-9 m-l-xs m-r-xs" [innerText]="model.check.total | loyaNumber:false" data-testid="check.total"></span>
                <i class="fa le f-s-8 text-muted" ngCurrencyLoya ></i>
            </div>

            <div class="pull-left m-r m-b-xs" *ngIf="model.check.total">
                <i class="fa fa-money text-muted f-s-9 m-r-xs">
                </i>
                <span class="text-muted f-s-9"  [innerText]=" model.check.chType!==2 ? 'Оплачено: ' : 'Возвращено: ' "></span>
                <span class="f-s-9 m-l-xs m-r-xs" [innerText]="getClientPaid(model) | loyaNumber:false" data-testid="check.clientPaid"></span>
                <i class="fa le f-s-8 text-muted" ngCurrencyLoya ></i>
            </div>

          <div class="clearfix" ></div>

          <div class="pull-left m-r m-b-xs"  *ngIf="model.check.originalId">

                 <span class="text-muted f-s-9">
                      Оригинальный чек:
                 </span>
              <a  class="f-s-9" [innerText]="model.check.originalCheckNumber"
                  [href]="'/#!/receipt/index/'+model.check.originalCheckNumber"
                  data-testid="check.originalCheckNumber"
              ></a>

          </div>

        </div>

        <div class="clearfix m-b-xs" *ngIf="showClient && model.client">
            <div class="pull-left m-r">

                <i class="fa fa-user-o text-muted f-s-9 m-r-xs" ></i>

                <span class="text-muted f-s-9">
                    Клиент:
                </span>

                <span class="f-s-9" >
                    <span [ngSwitch]="model.client.state" data-testid="client.state">

                        <i class="fa fa-circle text-danger"
                           *ngSwitchCase="'archive'"
                           ngbPopover="В архиве"
                           triggers="mouseenter:mouseleave"
                        ></i>

                        <i class="fa fa-circle text-primary"
                           *ngSwitchCase="'autogeneration'"
                           ngbPopover="Автогенерация"
                           triggers="mouseenter:mouseleave"
                         ></i>

                        <i class="fa fa-circle text-warning"
                           *ngSwitchCase="'express'"
                           ngbPopover="Экспресс регистрация"
                           triggers="mouseenter:mouseleave"
                         ></i>

                        <i class="fa fa-circle text-success"
                           *ngSwitchCase="'active'"
                           ngbPopover="Активный клиент"
                           triggers="mouseenter:mouseleave"
                         ></i>

                        <i class="fa fa-check-circle text-success-lter"
                           *ngSwitchCase="'fullProfile'"
                           ngbPopover="Анкета заполнена"
                           triggers="mouseenter:mouseleave"
                         ></i>

                    </span>

                    <a [href]="'/#!/crm/clients/view/'+model.client.clientId" data-testid="client.name">
                       {{ model.client.surname }}
                       {{ model.client.name }}
                       {{ model.client.patronymic }}
                    </a>

                    <span
                      *ngIf="model?.client?.phoneConfirmed"
                      class="m-l-xxs p-l-xxs"
                    >
                      <i class="fa fa-phone text-success m-l-xxs"
                         [ngbPopover]="'Телефон подтверждён'"
                         [triggers]="'mouseenter:mouseleave'"
                      ></i>
                    </span>
                </span>

            </div>
        </div>

        <div class="clearfix m-b-xs" *ngIf="!showClient && !!model?.client?.phoneConfirmed">
          <div class="pull-left m-r">

            <i class="fa fa-phone text-success f-s-9 m-r-xs" ></i>

            <span class="text-muted f-s-9">
                Подтверждение телефона
            </span>
          </div>
        </div>

          <div class="clearfix m-b-xs" *ngIf="model.cardNumber !== undefined">
            <div class="pull-left m-r">

                <i class="fa fa-credit-card text-muted f-s-9 m-r-xs" ></i>

                <span class="text-muted f-s-9 ">
                    Номер карты:
                </span>

                <span class="f-s-9" >
                    <span data-testid="cardNumber">
                       {{ model.cardNumber }}
                    </span>
                </span>



            </div>
        </div>

        <div class="clearfix m-b-xs" *ngIf="model.requestType !== undefined">
            <div class="pull-left m-r">

                <i class="fa fa-gear text-muted f-s-9 m-r-xs" ></i>

                <span class="text-muted f-s-9">
                    Стадия заказа:
                </span>

                <span class="f-s-9" >
                    <span>
                       {{ getRequestName(model.requestType) }}
                    </span>
                </span>

            </div>
        </div>

        <div class="">
            <a href="javascript:void(0);"
               class="text-muted  f-s-9"
               (click)="switchTransactionsDetails()"
               data-testid="switchTransactionsDetails()">
                <i class="fa {{ !transactionDetails ? 'fa-chevron-down' : 'fa-chevron-up' }}">
                </i>
                подробнее
            </a>
            <div *ngIf="transactionDetails"
                 class="padder b-t b-m b-l b-r r m-b-md">


                <div class="row bg-light lt b-b text-muted">
                    <div class="col-sm-1  wrapper-xs text-center">№</div>
                    <div class="col-sm-11 ">
                      <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-4 wrapper-xs hidden-xs padder">SKU</div>
                        <div class="col-lg-4 col-md-8 col-sm-6 wrapper-xs hidden-xs padder">Наименование
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 text-center wrapper-xs hidden-xs padder">
                            Кол-во
                        </div>
                        <div class="col-lg-2 col-sm-2 wrapper-xs padder text-right visible-lg">Цена</div>
                        <div class="col-lg-2 col-sm-2 wrapper-xs padder text-right visible-lg">Итого</div>
                      </div>
                    </div>
                </div>

                <div *ngFor="let detailItem of transactionDetails; let index = index"
                     class="row  m-t-n-xxs "
                     data-testid="let detailItem of transactionDetails">

                        <div class=" col-sm-1 no-padder text-center pointer b-b b-t v-top"
                             *ngIf="!isCancelReceipt"
                             ng-class="{'bg-light ':index % 2 === 0}"
                             (click)="switchResultsByItem(model.check.id, detailItem)"
                             data-testid="switchResultsByItem()">

                            <div class="m-t-xs ">
                                <i class="fa text-muted m-r-xs "
                                      *ngIf="detailItem.hasResults"
                                      [ngClass]="{'fa-chevron-up':!detailItem.results,'fa-chevron-down':detailItem.results}"></i>

                                <span [innerText]="index+1" class="f-s-12 m-t-xs"></span>
                            </div>

                        </div>

                        <div class=" col-sm-11  b-t b-l b-b m-b-none "
                             ng-class="{'bg-light ':index % 2 === 0}">

                          <div class="row">

                            <div class="col-sm-12  hidden-xs">
                              <div class="row">
                                <div class="col-lg-2 col-md-2 col-sm-4 wrapper-xs padder">

                                    <span class="m-l-xs" *ngIf="searchModel">
                                        <search-highlight [model]="searchModel" [text]="detailItem.item.sku" data-testid="detailItem.sku"></search-highlight>
                                    </span>

                                    <span *ngIf="!searchModel" [innerText]="detailItem.item.sku" class="m-l-xs" data-testid="detailItem.sku"></span>

                                </div>

                                <div class="col-lg-4 col-md-8 col-sm-6 wrapper-xs padder">
                                    <i class="fa fa-tag text-muted f-s-9 m-r-xs"></i>
                                    <span [innerText]="detailItem.skuName" data-testid="detailItem.skuName"></span>
                                </div>

                                <div class="col-lg-2 col-md-2 col-sm-2 wrapper-xs text-center padder">
                                    <span [innerText]="detailItem.item.quantity" data-testid="detailItem.quantity"></span>

                                    <span [ngSwitch]="detailItem.item.weight" data-testid="detailItem.weight">
                                                        <span *ngSwitchCase="'weight'">
                                                            <i ngbPopover="Весовой товар"
                                                               triggers="mouseenter:mouseleave"
                                                               class="fa fa-balance-scale f-s-8 text-muted"></i>
                                                        </span>
                                                        <span *ngSwitchCase="'piece'">
                                                            <i ngbPopover="Штучный товар"
                                                               triggers="mouseenter:mouseleave"
                                                               class="fa fa-shopping-basket f-s-8 text-muted"></i>
                                                        </span>
                                                    </span>

                                </div>

                                <div class="col-lg-2 col-sm-2 wrapper-xs padder text-right  visible-lg">
                                                <span class="text-ellipsis">
                                                    <span [innerText]="detailItem.item.price | loyaCurrency " data-testid="detailItem.price"></span>
                                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                </span>

                                </div>


                                <div class="col-lg-2 col-md-2 wrapper-xs text-right  visible-lg ">
                                  <span class="text-ellipsis">
                                    <span [innerText]="detailItem.item.total | loyaCurrency" data-testid="detailItem.total"></span>
                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                  </span>
                                  <span *ngIf="detailItem.item.minTotal!=0" class="text-muted f-s-8">
                                    <span >Мин. сумма: </span>
                                    <span [innerText]="detailItem.item.minTotal | loyaCurrency " data-testid="detailItem.minTotal"></span>
                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12  visible-lg">
                              <div class="row">
                                <div class="col-lg-3 col-md-3  text-center">
                                                <span class="text-muted f-s-8"
                                                      *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                                                    <span *ngIf="model.check.chType!==2">Выдано бонусов</span>
                                                    <span
                                                        *ngIf="model.check.chType===2">Возврат выданых бонусов</span>
                                                    <em class="clearfix"
                                                        *ngIf="model.check.bonusStartDate && model.check.chType!==2">
                                                        Действуют с <span
                                                        [innerText]="model.check.bonusStartDate | loyaDate" data-testid="check.bonusStartDate"></span>
                                                    </em>
                                                </span>
                                                <span class=" text-ellipsis"
                                                      *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}" >
                                                    <span
                                                        [innerText]="detailItem.item.awardBonus | loyaNumber:false" data-testid="detailItem.awardBonus"></span>
                                                    <span>б = </span>
                                                    <span
                                                        [innerText]="detailItem.awardBonusInCurrency | loyaCurrency" data-testid="detailItem.awardBonusInCurrency"></span>
                                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                </span>

                                </div>

                                <div class="col-lg-3 col-md-3  text-center ">
                                                <span class="text-muted f-s-8"
                                                      *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}" >
                                                    <span *ngIf="model.check.chType!==2">Списано бонусов</span>
                                                    <span
                                                        *ngIf="model.check.chType===2">Возврат списания бонусов</span>
                                                    <em class="clearfix"
                                                        *ngIf="model.check.bonusStartDate && model.check.chType===2">
                                                        Действуют с <span
                                                        [innerText]="model.check.bonusStartDate | loyaDate" data-testid="check.bonusReturnStartDate"></span>
                                                    </em>
                                                </span>
                                    <div class="m-r text-ellipsis"
                                         *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}" >
                                                    <span
                                                        [innerText]="detailItem.item.usedBonus | loyaNumber:false" data-testid="detailItem.usedBonus"></span>
                                        <span>б = </span>
                                        <span
                                            [innerText]="detailItem.usedBonusInCurrency | loyaCurrency" data-testid="detailItem.usedBonusInCurrency"></span>
                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3  text-right ">
                                    <span class="text-muted f-s-8" *ngIf="model.check.chType!==2">Скидка</span>
                                    <span class="text-muted f-s-8"
                                          *ngIf="model.check.chType===2">Возврат скидки</span>

                                    <span class="text-ellipsis">

                                                     <span
                                                         *ngIf=" detailItem.item.usedDiscount && detailItem.item.total "
                                                         class="text-muted f-s-9 m-r-xs">
                                                          (
                                                          <span
                                                              [innerText]=" ( ( detailItem.item.usedDiscount * 100 ) / detailItem.item.total).toFixed(2) " data-testid="detailItem.discountReturn">
                                                          </span>
                                                          %)
                                                     </span>


                                                  <span [innerText]="detailItem.item.usedDiscount | loyaCurrency" data-testid="detailItem.usedDiscount"></span>
                                                  <i class="fa m-l-xs" ngCurrencyLoya ></i>



                                                </span>

                                </div>

                                <div class="col-lg-3 col-md-3  text-right">
                                    <div class="m-r-md">
                                                    <span class="text-muted f-s-8"
                                                          *ngIf="model.check.chType!==2">Итого с учётом скидки</span>
                                        <span class="text-muted f-s-8"
                                              *ngIf="model.check.chType===2">К возврату</span>

                                        <span class="text-ellipsis">
                                                        <span
                                                            [innerText]="detailItem.paidInCurrency | loyaCurrency" data-testid="detailItem.paidInCurrency"> </span>
                                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                        </span>
                                        <div class="clearfix"></div>
                                        <div class=" f-s-8 m-b-xs" *ngIf="getClientPaid(detailItem)">
                                                <span class="text-muted" [innerText]=" model.check.chType!==2 ? 'Оплачено: ' : 'Возвращено: ' "></span>
                                                <span>
                                                    <span [innerText]="getClientPaid(detailItem) | loyaCurrency" data-testid="detailItem.clientPaid"></span>
                                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                </span>
                                        </div>

                                    </div>

                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12  hidden-lg">
                              <div class="row">
                                <div class="col-md-12 m-t-sm text-left visible-xs">
                                    <span class="text-muted">SKU</span>
                                    <span class="text-ellipsis pull-right">

                                         <span *ngIf="searchModel">
                                            <search-highlight [model]="searchModel" [text]="detailItem.item.sku"></search-highlight>
                                        </span>

                                        <span *ngIf="!searchModel"
                                              [innerText]="detailItem.item.sku ">
                                        </span>

                                    </span>

                                </div>

                                <div
                                    class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs visible-xs"></div>

                                <div class="col-md-12 text-left visible-xs">
                                    <span class="text-muted">Наименование</span>
                                    <span class="text-ellipsis pull-right">
                                                    <span [innerText]="detailItem.skuName "></span>
                                                </span>

                                </div>

                                <div
                                    class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs visible-xs"></div>

                                <div class="col-md-12 text-left visible-xs">
                                    <span class="text-muted">Единица измерения</span>
                                    <span class="text-ellipsis pull-right">
                                                    <span [innerText]="detailItem.item.quantity"></span>

                                                    <span [ngSwitch]="detailItem.item.weight">
                                                        <span *ngSwitchCase="'weight'">
                                                            <i  ngbPopover="Весовой товар"
                                                                triggers="mouseenter:mouseleave"
                                                               class="fa fa-balance-scale f-s-8 text-muted"></i>
                                                        </span>
                                                        <span *ngSwitchCase="'piece'">
                                                            <i ngbPopover="Штучный товар"
                                                               triggers="mouseenter:mouseleave"
                                                               class="fa fa-shopping-basket f-s-8 text-muted"></i>
                                                        </span>
                                                    </span>
                                                </span>

                                </div>

                                <div
                                    class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs visible-xs"></div>

                                <div class="m-t-sm visible-sm"></div>

                                <div class="col-md-12 text-left ">
                                    <span class="text-muted">Цена</span>
                                    <span class="text-ellipsis pull-right">
                                                     <span [innerText]="detailItem.item.price | loyaCurrency "></span>
                                                    <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                </span>

                                </div>


                                <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs "></div>


                                <div class="col-md-12 text-left">
                                       <span class="text-ellipsis pull-right"
                                             *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                                            <span
                                                    [innerText]="detailItem.item.awardBonus | loyaNumber:false"></span>
                                            <span>б = </span>
                                            <span
                                                    [innerText]="detailItem.awardBonusInCurrency | loyaCurrency"></span>
                                            <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                        </span>

                                    <span class="text-muted"  *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                                      Выдано бонусов
                                        <em class="clearfix" *ngIf="model.check.bonusStartDate">
                                            Действуют с <span
                                            [innerText]="model.check.bonusStartDate | loyaDate"></span>
                                        </em>
                                    </span>


                                </div>

                                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs"
                                     *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"></div>

                                <div class="col-md-12 text-left"  *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                                    <span class="text-muted">Списано бонусов</span>
                                    <div class="text-ellipsis pull-right">
                                                    <span
                                                        [innerText]="detailItem.item.usedBonus | loyaNumber:false"></span>
                                        <span>б = </span>
                                        <span
                                            [innerText]="detailItem.usedBonusInCurrency | loyaCurrency"></span>
                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                    </div>
                                </div>

                                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs"></div>

                                <div class="col-md-12 text-left ">
                                    <span class="text-muted">Скидка</span>
                                    <span class="text-ellipsis pull-right">

                                                    <span
                                                        *ngIf=" detailItem.item.usedDiscount && detailItem.item.total "
                                                        class="text-muted f-s-9 m-r-xs">
                                                          (
                                                          <span
                                                              [innerText]=" ( ( detailItem.item.usedDiscount * 100 ) / detailItem.item.total).toFixed(2) ">
                                                          </span>
                                                          %)
                                                     </span>

                                                  <span [innerText]="detailItem.item.usedDiscount | loyaCurrency"></span>
                                                  <i class="fa m-l-xs" ngCurrencyLoya ></i>

                                                </span>

                                </div>

                                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs"></div>

                                <div class="col-md-12 text-left ">
                                    <span class="text-muted">Итого</span>
                                    <span class="text-ellipsis pull-right">
                                                  <span [innerText]="detailItem.item.total | loyaCurrency"></span>
                                                  <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                </span>

                                </div>

                                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs"></div>

                                <div class="col-md-12 text-left">
                                    <span class="text-muted">Итого с учётом скидки</span>
                                    <span class="text-ellipsis text-primary pull-right">
                                                        <span
                                                            [innerText]="detailItem.paidInCurrency | loyaCurrency"> </span>
                                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                                    </span>
                                </div>

                                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs" *ngIf="getClientPaid(detailItem)"></div>

                                <div class="col-md-12 text-left" *ngIf="getClientPaid(detailItem)">
                                    <span class="text-muted" [innerText]=" model.check.chType!==2 ? 'Оплачено' : 'Возвращено' "></span>
                                    <span class="text-ellipsis text-primary pull-right">
                                        <span
                                                [innerText]="getClientPaid(detailItem) | loyaCurrency"> </span>
                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                    </span>
                                </div>

                              </div>

                            </div>

                          </div>

                    </div>

                    <div class="clearfix block wrapper-xs  bg-light dk "
                         *ngIf="detailItem.results">

                        <div *ngIf="detailItem.results===true"
                             class="text-center wrapper-md fa-3x text-warning">
                            <i class="fa fa-spin fa-spinner"></i>
                        </div>

                        <receit-details-item
                                [items]="detailItem.results">
                        </receit-details-item>

                    </div>

                </div>

                <div class="row   b-b b-t m-t-n-xxs m-b-none "
                     *ngIf="!isCancelReceipt"
                     [class.pointer]="model.hasResults"
                     (click)="switchResultsByCheck(model) ">

                  <div class="col-sm-12">
                    <div class="pull-right wrapper-xs padder  " data-testid="switchResultsByCheck()">

                                    <span class="fa text-muted m-r-xs"
                                            *ngIf="model.hasResults"
                                            [ngClass]="{ 'fa-chevron-right':!model.results,'fa-chevron-down':model.results }"></span>
                                    <span class="text-primary">
                                        <span [innerText]="model.paidInCurrency | loyaCurrency " data-testid="result.paidInCurrency"></span>
                                        <i class="fa m-l-xs" ngCurrencyLoya ></i>
                                    </span>

                    </div>
                      <div class="clearfix"></div>
                      <div class="f-s-8 pull-right padder m-b-xs"
                           *ngIf="getClientPaid(model)">
                          <span class="text-muted" [innerText]=" model.check.chType!==2 ? 'Оплачено: ' : 'Возвращено: ' "></span>

                          <a class="text-primary"
                             href="javascript:void(0)"
                             (click)="switchPayments(model); $event.stopImmediatePropagation()"
                          >
                              <span [innerText]="getClientPaid(model) | loyaCurrency " data-testid="result.clientPaid"></span>
                              <i class="fa m-l-xs" ngCurrencyLoya ></i>
                          </a>

                      </div>
                  </div>

                  <div class="col-sm-12  text-right wrapper-xs fa-2x   text-warning"
                       *ngIf="model?.payments === true"
                  >
                      <i class="fa fa-spin fa-spinner m-r-lg"></i>
                  </div>
                  <div class="col-sm-12 text-right m-b-sm pointer-default"
                       *ngIf="!!hasPayments(model)"
                       (click)="$event.stopImmediatePropagation()"
                  >
                    <table class=" f-s-8 pull-right m-r border-top line-dashed">
                      <tr
                           *ngFor="let paymentItem of model.payments; let index = index"
                      >

                          <td class="text-muted"
                              [class.p-t-sm]="index===0"
                          >

                            <span class="font-bold" [ngSwitch]="paymentItem.tpe">

                              <ng-container *ngSwitchCase="'card'" >Картой</ng-container>

                              <ng-container *ngSwitchCase="'cash'" >Наличными</ng-container>

                              <ng-container *ngSwitchCase="'giftcertificate'" >Подарочным сертификатом</ng-container>

                              <ng-container *ngSwitchCase="'other'" >Другое</ng-container>

                            </span>
                            <span *ngIf="!!paymentItem.info" class="m-l-xs">( {{paymentItem.info}} )</span>:
                          </td>

                          <td
                              [class.p-t-sm]="index===0"
                          >
                            <span [innerText]="paymentItem.amount | loyaCurrency " data-testid="paymentItem.amount"></span>
                            <i class="fa m-l-xs" ngCurrencyLoya ></i>
                          </td>
                      </tr>

                    </table>

                  </div>

                </div>

              <div class="row "  *ngIf="model.results">
                <div class="col-sm-12 wrapper-xs b-b bg-light dk details-container">


                      <div *ngIf="model.results===true"
                           class="text-center wrapper-md fa-3x text-warning">
                          <i class="fa fa-spin fa-spinner"></i>
                      </div>

                      <receit-details-item
                          [items]="model.results">
                      </receit-details-item>

                </div>
              </div>

            </div>

        </div>
    </div>

</div>

