'use strict';

import 'model/CouponRule';

export default  ['$scope', '$uibModalInstance', '$timeout', 'FileUploader', '$http', '$q', 'CouponRule',
    function ($scope, $uibModalInstance, $timeout, FileUploader, $http, $q, CouponRule) {

        /***** Настройки импорта *******/

        $scope.encodingList={

            'utf8': 'UTF-8',
            'cp1251': 'WIN1251'

        };

        $scope.importOptions = {

            encoding   : 'utf8',
            fromString : 1,
            barcodeIndex   : 1,
            rewardIndex: 2,
            delimiter  : ';',
            filenameOrigin : '',

        };

        $scope.delimiters = {

            tab: '\\t',
            comma: ',',
            semicolon: ';',
            space: ' ',
            colon: ':',
            other: 'other'

        };

        $scope.delimiterOptions = {
            index : 'other',
            otherValue : ''
        };

        $scope.tableRows = {

            "table": [  ]

        };

        /************************/

        $scope.reject = function() {

            if (loadedFile) {
                $http.delete( loadedFile  ).finally(function() {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }

        };

        var loadedFile : any  = false;
        $scope.$on('onSuccessItem', function( event, result ) {

            $scope.loadScreen('CONFIG');
            loadedFile = result;

            $scope.updateOptions().then(
                function(result) {

                    $scope.tableRows = result.table;

                }, $uibModalInstance.reject
            );

        });


        $scope.getColumnByIndex = function( index ) {

            switch (true) {
                case $scope.importOptions.barcodeIndex    === index :  return 'Штрихкод';
                case $scope.importOptions.rewardIndex     === index :  return 'Вознаграждение';

                case $scope.importOptions.SKUIndex        === index :  return 'SKU';
                case $scope.importOptions.countIndex      === index :  return 'Количество товара';
                case $scope.importOptions.dateFromIndex   === index :  return 'Дата С';
                case $scope.importOptions.dateToIndex     === index :  return 'Дата По';

                default : return '--';
            }

        }

        $scope.setColumnIndex = function( idColumn, index ) {

            ['barcodeIndex', 'rewardIndex'].forEach( column => {
                $scope.importOptions[column] = $scope.importOptions[column] === index ? -1 : $scope.importOptions[column];
            } );

            if (idColumn) {
                $scope.importOptions[idColumn] = index;
            }

        }

        $scope.isValidColums = function() {
            if (
                $scope.importOptions.barcodeIndex === $scope.importOptions.rewardIndex
            ) return false;

            if (
                $scope.importOptions.barcodeIndex      === -1
            ) return false;

            if ( $scope.isRequiredReward() &&
                ( $scope.importOptions.rewardIndex      === -1 || typeof $scope.importOptions.rewardIndex  === "undefined" )
            ) return false;

            if ( $scope.isRequiredDates() &&
                ( $scope.importOptions.dateFromIndex    === -1 || typeof $scope.importOptions.dateFromIndex  === "undefined" ||
                  $scope.importOptions.dateToIndex      === -1 || typeof $scope.importOptions.dateToIndex  === "undefined" )
            ) return false;

            if ( $scope.isRequiredCount() &&
                ( $scope.importOptions.countIndex    === -1 || typeof $scope.importOptions.countIndex  === "undefined"  )
            ) return false;

            if ( $scope.isRequiredSKU() &&
                ( $scope.importOptions.SKUIndex    === -1 || typeof $scope.importOptions.SKUIndex  === "undefined"  )
            ) return false;

            let rowsCount = $scope.tableRows &&
                            $scope.tableRows.table &&
                            $scope.tableRows.table[0] &&
                            $scope.tableRows.table[0].row ? $scope.tableRows.table[0].row.length -1 : 0 ;

            if (
                $scope.importOptions.barcodeIndex > rowsCount
            ) return false;

            return true;
        }

        let initFlag = true;
        $scope.isLoadPreview = false;
        $scope.previewError = false;
        $scope.updateOptions = function ( ) {

            if ( loadedFile === false || angular.isUndefined($scope.importOptions.fromString)) {
                return $q.resolve( { table: $scope.tableRows } );
            }

            var params : any = {};

            if ($scope.importOptions.delimiter !== '')
                params['delimiter']   = $scope.importOptions.delimiter;

            if ($scope.importOptions.fromString !== false ) {

                params.pager = {
                    limit: 20,
                    drop: $scope.importOptions.fromString - 1
                };

            }

            if ($scope.importOptions.encoding){
                params['encoding']    = $scope.importOptions.encoding;
            }

            if ($scope.importOptions.barcodeIndex !== false) {
                params['barcodeColumn']      = $scope.importOptions.barcodeIndex;
            }

            if ($scope.importOptions.rewardIndex !== false && $scope.importOptions.rewardIndex !== -1 ) {
                params['rewardColumn']    = $scope.importOptions.rewardIndex;
            }

            if ($scope.importOptions.dateFromIndex !== false && $scope.importOptions.dateFromIndex !== -1 ) {
                params['dateFromColumn']    = $scope.importOptions.dateFromIndex;
            }

            if ($scope.importOptions.dateToIndex !== false && $scope.importOptions.dateToIndex !== -1 ) {
                params['dateToColumn']    = $scope.importOptions.dateToIndex;
            }

            if ($scope.importOptions.countIndex !== false && $scope.importOptions.countIndex !== -1 ) {
                params['countColumn']    = $scope.importOptions.countIndex;
            }

            if ($scope.importOptions.SKUIndex !== false && $scope.importOptions.SKUIndex !== -1 ) {
                params['skuColumn']    = $scope.importOptions.SKUIndex;
            }


            if (initFlag) {
                params = {};
            }
            $scope.isLoadPreview = true;
            return CouponRule.preview( {
                id: $scope.$parent.ruleItem.couponRule.id,
                filename: loadedFile.split("/").splice(-1)
            }, params )
                .$promise
                .then(function(data) {

                    if (data && data.error) {

                        $scope.previewError = data.error;

                    } else {
                        $scope.previewError = false;
                    }

                    $scope.isLoadPreview = false;
                    if ( initFlag ) {

                        $scope.importOptions.extension = data.extension;
                        $scope.importOptions.filename = data.filename;
                        $scope.importOptions.fromString = ( data.settings.pager ? data.settings.pager.drop + 1 : 1 ) ;
                        $scope.importOptions.delimiter = data.settings.delimiter;
                        $scope.importOptions.encoding = data.settings.encoding;
                        $scope.importOptions.barcodeIndex = data.barcodeColumn || 0;
                        $scope.importOptions.rewardIndex  = data.rewardColumn;
                        $scope.importOptions.dateFromIndex  = data.dateFromColumn;
                        $scope.importOptions.dateToIndex    = data.dateToColumn;
                        $scope.importOptions.countIndex     = data.countColumn;
                        $scope.importOptions.SKUIndex       = data.skuColumn;

                        $scope.importOptions.filenameOrigin = data.filenameOrigin;

                        if ( data.table && data.table.table ) {

                            if ( data.table.table[0] && data.table.table[0].row.length -1 < $scope.importOptions.barcodeIndex ) {
                                $scope.importOptions.barcodeIndex = 0;
                            }

                            ['rewardIndex','dateFromIndex','dateToIndex','countIndex','SKUIndex'].forEach( i => {
                                if ( data.table.table[0] && data.table.table[0].row.length -1 < $scope.importOptions[i] ) {
                                    $scope.importOptions[i] = -1;
                                }
                            });

                        } else {
                            $scope.importOptions.barcodeIndex = 0;
                            $scope.importOptions.rewardIndex = 1;
                        }

                        $scope.delimiterOptions.index = false;
                        angular.forEach($scope.delimiters, function(value,  index ) {
                            if ( value === $scope.importOptions.delimiter) {
                                $scope.delimiterOptions.index = index;
                            }
                        });

                        if ( $scope.delimiterOptions.index === false ) {
                            $scope.delimiterOptions.index = 'other';
                            $scope.delimiterOptions.otherValue = $scope.importOptions.delimiter;
                        }

                        initFlag = false;
                    }

                    return data;

                }).catch(function(data) {
                    $scope.isLoadPreview = false;
                    $scope.previewError = data.data;
                    $uibModalInstance.dismiss({ error: data.data } );
                } );

        };

        $scope.$watch('delimiterOptions', function( newVal, oldVal ) {

            if ( angular.isUndefined(newVal) ) {
                return;
            }

            if ( newVal.index !== 'other' ) {

                $scope.importOptions.delimiter = $scope.delimiters[newVal.index];

            } else {

                $scope.importOptions.delimiter = newVal.otherValue;
            }

        },true);

        $scope.$watch('importOptions', function( newVal, oldVal ) {

            if ( angular.isUndefined(newVal) ) {
                return;
            }

            if ( newVal.barcodeIndex !== oldVal.barcodeIndex ) {

                let otherChanges = false;
                for ( let index in newVal) {
                    if (  newVal.hasOwnProperty(index) && newVal[index] !== oldVal[index]) {
                        otherChanges = true;
                        break;
                    }
                }

                if ( otherChanges )
                    return;
            }

            if ( $scope.delimiterOptions.index !== 'other' ) {

                $scope.delimiterOptions.index = false;
                angular.forEach($scope.delimiters, function(value,  index ) {
                    if ( value === newVal.delimiter) {
                        $scope.delimiterOptions.index = index;
                    }
                });

                if ( $scope.delimiterOptions.index === false ) {
                    $scope.delimiterOptions.index = 'other';
                    $scope.delimiterOptions.otherValue = newVal.delimiter;
                }

            }

            $scope.updateOptions().then(
                function(result) {

                    $scope.tableRows = result.table;

                }, $uibModalInstance.reject
            );

        }, true);

        $scope.confirmImport = function() {

            $scope.loadScreen('IMPORT');

            const uploader = $scope.$parent.ruleItem.couponRule.state === 'draft' ?
                                CouponRule.upload :
                                CouponRule.addCoupons ;

            uploader({
                id        : $scope.$parent.ruleItem.couponRule.id,
                filename  : $scope.importOptions.filename
            },{

                delimiter : $scope.importOptions.delimiter,
                encoding  : $scope.importOptions.encoding,

                barcodeColumn    : $scope.importOptions.barcodeIndex,
                rewardColumn  : $scope.importOptions.rewardIndex,

                dateFromColumn : $scope.importOptions.dateFromIndex,
                dateToColumn : $scope.importOptions.dateToIndex,
                countColumn  : $scope.importOptions.countIndex,
                skuColumn    : $scope.importOptions.SKUIndex,

                pager: {
                    drop      : $scope.importOptions.fromString - 1,
                    limit     : -1
                },

            }, function(data) {
                $uibModalInstance.close(data);
            }, function(data) {
                $uibModalInstance.dismiss({ error: data.data } );
            });

        };

        $scope.showOption = function( field ) {

            switch( field ) {
                case "encoding": return $scope.importOptions.extension === 'csv';
                case "delimiter": return $scope.importOptions.extension === 'csv';

            }

            return true;
        };

        $scope.isRequiredReward = function() {

            return $scope.$parent.getValue('ruleItem.couponRule.issueSettings.couponKind') === 'importedCoupon' || $scope.$parent.getValue('ruleItem.couponRule.issueSettings.couponKind') === 'couponWithBarcodeWithReceptParams';

        }

        $scope.isRequiredDates = function() {
            return $scope.$parent.getValue('ruleItem.couponRule.issueSettings.couponKind') === 'couponWithBarcodeWithReceptParams';
        }

        $scope.isRequiredSKU = function() {
            return $scope.$parent.getValue('ruleItem.couponRule.issueSettings.couponKind') === 'couponWithBarcodeWithReceptParams';
        }

        $scope.isRequiredCount = function() {
            return $scope.$parent.getValue('ruleItem.couponRule.issueSettings.couponKind') === 'couponWithBarcodeWithReceptParams';
        }

    }
];
