import {
    CouponApplyTypes,
    CouponKind,
    ECouponId,
    OnGoodRewType,
    RewardMeasureTypes,
    RewardWhatTypes
} from "./CouponKind";
import {CustomPeriodTypes, ExternalId, ManualId, PosId, TriggerId} from "../IssuingTypes/IssuingType";
import {Utils} from "../utils.class";


export class ECoupon extends CouponKind {

    static id = ECouponId;

    protected prefix = true;

    protected sameClientCount = true;
    protected customPeriodTypes = [ CustomPeriodTypes.CLIENT_BIRTHDAY ];

    get hasCanBeSold() : boolean {
        return this.issuingType.getId() === ExternalId;
    }

    public getCouponApply(): Array<CouponApplyTypes> {
        return [PosId, TriggerId, ManualId, ExternalId].indexOf(this.issuingType.getId()) >= 0
            ? [ CouponApplyTypes.APPLY_IN_FIRST_CHECK, CouponApplyTypes.APPLY_IN_FIRST_SUITED_CHECK, CouponApplyTypes.APPLY_ON_REQUEST ]
            : [ CouponApplyTypes.APPLY_ON_REQUEST ];
    }

    get hasBarcode() : boolean {
        return this.issuingType.isViewState;
    }

    public getRewardWhat(): Array<RewardWhatTypes> {

        this.rewardWhatTypes.splice(0, this.rewardWhatTypes.length);
        this.rewardWhatTypes.push(RewardWhatTypes.DISCOUNT);
        this.rewardWhatTypes.push(RewardWhatTypes.BONUS);

        if ( this.issuingType.getId() !== PosId ) {
            this.rewardWhatTypes.push(RewardWhatTypes.GIFT);
            return super.getRewardWhat();
        }

        if ( Utils.isReardGoodType(this.ruleItem, "count") ) {
            this.rewardWhatTypes.push(RewardWhatTypes.FIXPRICE);
        } else {
            this.rewardWhatTypes.push(RewardWhatTypes.GIFT);
            this.rewardWhatTypes.push( RewardWhatTypes.FIXPRICE );
        }

        return super.getRewardWhat();
    }


    public getOnGoodRewType(): Array<OnGoodRewType> {

        if ( this.issuingType.getId() === PosId && this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ) < 0 ) {
            this.onGoodRewTypes.push(OnGoodRewType.COUNT)
        }

        if ( this.issuingType.getId() !== PosId && this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ) >= 0 ) {
            this.onGoodRewTypes.splice(this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ), 1);
        }

        return super.getOnGoodRewType();
    }

    public getRewardMeasureList(): Array<RewardMeasureTypes> {

        let list = super.getRewardMeasureList();
        if ( !Utils.isReardGoodType(this.ruleItem, "count") )
            return list;

        if ( [RewardWhatTypes.DISCOUNT, RewardWhatTypes.BONUS].indexOf( Utils.getField(this.ruleItem, "couponRule.reward.reward.what") ) >=0 ) {
            return list.filter( i => i === RewardMeasureTypes.PERCENT);
        }

        return list;
    }

}