<div class="modal-header  bg-light align-items-center">

    <i class="fa fa-upload text-success m-r-sm f-s-12"> </i>


    <div class=" relative">
      <div class=" overflow-wrap-break">
        <span class="h4">
          Импорт из <span ng-bind="'ИМЯ ФАЙЛА'"></span>
        </span>


      </div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="">

      <button type="button" class="btn btn-sm btn-success m-r-xs btn-addon" data-testid="import()"
              (click)="import()"
              [disabled]="!isCanImport"
      >
        <i class=" fa fa-check"></i>
        <span class="btn-name"
        >Импортировать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default  " data-testid="cancel()"
              (click)="cancel()"
      >
        <!-- TODO подтверждение закрытия -->
        <i class=" fa fa-remove text-danger"></i>
      </button>

    </div>

</div>

<div class="modal-body no-padder pos-rlt">

  <form name="importOptionsForm" #importOptionsForm="ngForm" novalidate="" class="w-full h-full">

    <div class="rows-container">

      <div class="card no-back">
          <div class="card-header ">
              <i class="fa fa-gear m-r-xs"></i> <span class="">  Импорт </span>
          </div>

          <div class="card-body ">

              <div class="row ">

                  <div class="col-sm-6 col-lg-6 m-b-xs" *ngIf="isEncodingEnabled">
                    <div class="row">

                      <div class="col-sm-4 text-right text-ellipsis">
                        <div class="m-t-xs">
                          Кодировка <i class="fa fa-font m-l-xs text-muted"></i>
                        </div>
                      </div>

                      <div class=" col-sm-8">

                        <div class="editable-rec">
                          <select class="form-control form-control-edittable"
                                  [(ngModel)]="importOptions.encoding"
                                  name="importOptionsForm_encoding"
                                  placeholder="Выбор кодировки"
                                  [required]="true"
                                  (ngModelChange)="changePreview.next()"
                          >
                            <option  *ngFor="let item of ENCODING_LIST | keyvalue" [ngValue]="item.key" [innerText]="item.value"></option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-6 m-b-xs">
                    <div class="row">

                      <div class=" col-sm-4 text-right text-ellipsis">
                        <div class="m-t-xs">
                          Со строки <i class="fa fa-align-justify m-l-xs text-muted"></i>
                        </div>
                      </div>

                      <div class=" col-sm-8">

                        <div class="editable-rec">
                          <input type="number"
                                 class="form-control form-control-edittable"
                                 [(ngModel)]="importOptions.skip"
                                 (ngModelChange)="changePreview.next()"
                                 name="importOptionsForm_skip"
                                 placeholder="Позиция"
                                 [required]="true"
                                 [min]="1"
                                 [max]="65535"
                           >
                        </div>
                      </div>

                    </div>
                  </div>


                  <div class="col-sm-12" *ngIf="previewError">
                    <i class="fa fa-warning text-danger m-l-lg m-r-xs"></i>
                    <span class="text-danger" [innerHTML]="previewError"></span>
                  </div>


                </div>

              </div>

            </div>

      <div class="card no-back" *ngIf="isShowDelimiter">

        <div class="card-header ">
          <i class="fa fa-th-large m-r-xs"></i> <span class=" ">  Разделитель </span>
        </div>

        <div class="card-body ">

          <div class="row">
            <div class="offset-sm-2 col-sm-10">

              <div class="row  align-items-end">

                <div class="col-sm-10 col-md-6 col-lg-3"
                     *ngFor="let item of DELIMITER_LIST | keyvalue | callback: filterOthers"
                >

                  <div class="radio">
                    <label class="i-checks">
                      <input type="radio"
                             [(ngModel)]="viewOptions.delimiter"
                             (ngModelChange)="changePreview.next()"
                             name="viewOptions_delimiter"
                             (ngModelChange)="changeDelimiter()"
                             [value]="item.key"
                      >
                      <i></i>
                      {{ item.value }}
                    </label>
                  </div>

                </div>

                <div class="col-sm-10 col-md-6 col-lg-4 ">

                  <div class="checkbox inline m-r-sm">
                    <label class="i-checks">
                      <input type="radio"
                             [(ngModel)]="viewOptions.delimiter"
                             (ngModelChange)="changeDelimiter()"
                             name="viewOptions_delimiter"
                             [value]="'other'"
                      >
                      <i></i>
                      {{DELIMITER_LIST.other}}
                    </label>
                  </div>

                  <div class="editable-rec inline w-xs m-t-xs"
                       *ngIf="viewOptions.delimiter === 'other'"
                  >
                    <input type="text"
                           [disabled]=" viewOptions.delimiter !== 'other' "
                           [required]=" viewOptions.delimiter !== 'other' "
                           class="form-control form-control-edittable"
                           [(ngModel)]="importOptions.delimiter"
                           (ngModelChange)="changeDelimiter()"
                           name="importOptions_delimiter_value"
                           placeholder="Разделитель CSV"
                           [maxlength]="1">
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="card no-back fields-card">

        <div class="card-header ">
          <i class="fa fa-th m-r-xs"></i> <span class=" ">  Поля </span>
        </div>


        <div class="card-body pos-rlt ">

          <div class="table-import-container">
            <table class="table table-bordered table-striped m-b-none">

            <thead>

              <tr class="border-none"
                  *ngIf="tableData && !!tableData?.length"
              >
                <ng-container
                  *ngFor="let col of tableData[0]; let colIndex = index;"
                >
                  <th class="text-center ">

                    <div class="dropdown d-inline-block"  ngbDropdown
                         [container]="'body'"
                    >

                      <button class="btn btn-default btn-sm " ngbDropdownToggle>
                        <span class="btn-name"
                              [class.text-danger]="isNeedRequireColumn && !isRowEnabled(colIndex)"
                              [class.text-xs]="!isRowEnabled(colIndex)"
                              [class.text-success]="isRowEnabled(colIndex)"
                        >
                            {{ getRowNameForIndex(colIndex) }}
                            <span class="caret "></span>
                        </span>
                      </button>

                      <ul class="dropdown-menu scrollable"
                          ngbDropdownMenu>

                        <li class=" text-ellipsis text-center"
                            ngbDropdownItem
                        >
                          <a
                            href="javascript:void(0)"
                            (click)="setRowForIndex(undefined, colIndex);"
                          >
                           ---
                          </a>

                        </li>

                        <li class=" text-ellipsis"
                            ngbDropdownItem
                            *ngFor="let column of columnValues"
                        >

                          <a
                            href="javascript:void(0)"
                            (click)="setRowForIndex(column.key, colIndex);"
                          >
                            {{column.value || column.key || '--'}}
                          </a>

                        </li>

                      </ul>

                    </div>

                  </th>
                </ng-container>
              </tr>

            </thead>

            <tbody>

              <tr *ngFor="let rowItem of tableData"
                  class="table-import-row">

                <td *ngFor="let cell of  rowItem; let colIndex = index;"
                    [ngClass]="{ 'text-muted' : !isRowEnabled(colIndex) }"
                    [innerText]=" cell || '&nbsp;'"></td>

              </tr>

            </tbody>

          </table>
          </div>

          <div class=" bg-light table-import-loader" *ngIf="loader.isLoading('isPreview')">
            <i class="fa fa-spin fa-spinner text-warning fa-4x" ></i>
          </div>

        </div>

      </div>

    </div>

  </form>

  <div class="import-progress bg-light"
       *ngIf="loader.isLoading('isProgress')"
  >

    <div class="vbox">

      <div class="row-row" style="height: 2%">

        <div class="cell">
        </div>
      </div>
      <div class="row-row" style="height: 60%">

        <div class="cell ">
          <div class="cell-inner logo-container ">

          </div>
        </div>
      </div>

      <div class="row-row" style="height: 10%">

        <div class="cell">
        </div>
      </div>

      <div class="row-row">

        <div class="cell">
          <div class="clearfix">
            <div class="col-sm-8 offset-sm-2 text-center">

              <ngb-progressbar
                [value]="viewOptions.progressBarPercent"
                type="success"
                class="progress-striped active"
              >
                 {{viewOptions.progressBarPercent}}%
              </ngb-progressbar>
              <div class="m-t-sm text-muted text-sm">Загрузка файла</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="import-progress bg-light"
       *ngIf="loader.isLoading('isParse')"
  >

    <div class="vbox">

      <div class="row-row" style="height: 2%">

        <div class="cell">
        </div>
      </div>
      <div class="row-row" style="height: 60%">

        <div class="cell ">
          <div class="cell-inner logo-container ">

          </div>
        </div>
      </div>

      <div class="row-row" style="height: 10%">

        <div class="cell">
        </div>
      </div>

      <div class="row-row">

        <div class="cell">
          <div class="clearfix">
            <div class="col-sm-8 offset-sm-2 text-center">

              <i class="fa fa-spin fa-spinner text-warning fa-2x m-b-xs" ></i>

              <div class="m-t-sm text-muted text-sm">Импорт файла</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>




