import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {BrandService} from "../../../../../shared/service/api/Brand/Brand.service";
import {ClientCategory} from "../../../../../api/ClientCategoryApi/models/client-category";
import {Brand} from "../../../../../api/BrandApi/models/brand";


@Component({
  selector: 'brand-index-page',
  templateUrl: './brand-index-page.component.html',
  providers:[
    BrandService
  ]
})
export class BrandIndexPageComponent implements OnInit{

  searchString: string;
  listBrands: Array<Brand> =[]
  rolesList = {};

  constructor(
    public brandService: BrandService
  ) {
  }

  ngOnInit() {
    this.brandService.getListParams.reset();
    this.getList(this.brandService.getListParams.params);
  }

  getList(params) {
    this.brandService.getList$(params)
      .subscribe( result => {
        this.listBrands = this.listBrands.concat(result);
      });
  }


  onScroll() {
    this.brandService.getListParams.next();
    this.getList(this.brandService.getListParams.params)
  }

  reset() {
    this.brandService.getListParams.reset();
    this.listBrands=[];
    this.getList(this.brandService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.brandService.getListParams.search(this.searchString);
    this.reset();
  }
}
