/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FavoriteGoodsByPriorityApiConfiguration, FavoriteGoodsByPriorityApiConfigurationInterface } from './favorite-goods-by-priority-api-configuration';

import { FavoriteGoodsByPriorityApiService } from './services/favorite-goods-by-priority-api.service';

/**
 * Provider for all FavoriteGoodsByPriorityApi services, plus FavoriteGoodsByPriorityApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FavoriteGoodsByPriorityApiConfiguration,
    FavoriteGoodsByPriorityApiService
  ],
})
export class FavoriteGoodsByPriorityApiModule {
  static forRoot(customParams: FavoriteGoodsByPriorityApiConfigurationInterface): ModuleWithProviders<FavoriteGoodsByPriorityApiModule> {
    return {
      ngModule: FavoriteGoodsByPriorityApiModule,
      providers: [
        {
          provide: FavoriteGoodsByPriorityApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
