/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GroupApiConfiguration, GroupApiConfigurationInterface } from './group-api-configuration';

import { GroupApiService } from './services/group-api.service';

/**
 * Provider for all GroupApi services, plus GroupApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    GroupApiConfiguration,
    GroupApiService
  ],
})
export class GroupApiModule {
  static forRoot(customParams: GroupApiConfigurationInterface): ModuleWithProviders<GroupApiModule> {
    return {
      ngModule: GroupApiModule,
      providers: [
        {
          provide: GroupApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
