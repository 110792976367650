import { FieldHandler } from "./FieldHandler";
import {getNg1Injector} from "class/MigrationUtils";

export interface FieldInfo {

    moduleName : string;
    fieldName  : string;

}


export class CardHandler  {

    private CardModel: any;

    constructor() {

        let CardModel = getNg1Injector().get('CardModel')
        this.CardModel = new CardModel();
    }

    public getInfoForPath(  path = '')  : FieldInfo {

        let finded = this.getFieldByPath(  path );

        return finded ? {
            fieldName  : this.labelFromArray(finded.displayName , path),
            moduleName : ''
        } : {
            fieldName  : path,
            moduleName : ''
        };

    }

    private labelFromArray( label: string, path: string ) {

        if ( ! (/\$\d+/ig).test(label) )
            return label;

        (path.match(/\/\d+\//ig) || []).forEach(( value, index) => {
            label = label.replace(`$${index + 1}`, ( parseInt(value.substr(1, value.length -2 ) ) + 1 ) .toString() )
        });

        return label.replace(/\$\d+/ig,'');

    }

    public getFieldByPath(  path: string) : any  {

        let fieldId = FieldHandler.convertArraysInPath(path).toLowerCase().replace('/','');

        try{
            return this.CardModel.getFields(fieldId);
        } catch (e) {
            return  undefined;
        }

    }

    public getValueForPath(  path: string, value: any ) {

        let field = this.getFieldByPath( path);

        if ( field ) {
            if (  field.values ) {
                return field.values[value] || value;
            }
        }

        if ( typeof value === "boolean" || ["true", "false"].indexOf(value) >=0 ) {
            return (value === true || value === "true") ? 'Включено' : 'Выключено';
        }

        return value;
    }


}
