import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ClientCategoryApiService} from "../../../../api/ClientCategoryApi/services";
import {ClientCategory} from "../../../../api/ClientCategoryApi/models";
import {Observable, throwError} from "rxjs";
import {ObjectUtils} from "../../../class/utils/object.utils";
import {tap} from "rxjs/operators";

export interface InitParamsType  {
  pager ?: {
    type?: string,
    limit?: number,
    dropField: string,
    limitField: string,
  },
  search ?: {
    field: string,
    type? : string,
  },
  sort? : {
    type? : string,
    sortField  : string,
    orderField : string,
  },
  filters?: Array<{
    id?   : string,
    type? : string,
    field: string,
    values? : { [id:string]: string },
    currentValue : any
  }>
}

export class ParamsHandler {

  private _params: any = {};

  public onParamChange = new EventEmitter();

  get params() {

    let res: any = {};
    ObjectUtils.deepExtend( res, this.getParamsPager() );
    ObjectUtils.deepExtend( res, this.getParamsSearch() );
    ObjectUtils.deepExtend( res, this.getParamsSort() );
    ObjectUtils.deepExtend( res, this.getParamsFilters() );

    return res;
  }

  constructor( private initConfig: InitParamsType = {}) {
    this.parseConfigPager()  ;
    this.parseConfigSearch() ;
    this.parseConfigSort()   ;
    this.parseConfigFilter()  ;
  }

  //---------
  private parseConfigPager() {

    if ( !this.initConfig.pager )
       return;

    this._params.pager = {
      drop: 0,
      limit: this.initConfig.pager.limit || 20,
      isEnd: false
    }
  }

  private getParamsPager() {
      if (!this._params.pager) {
        return {};
      }

      let limit = 100;

      if (typeof this._params.pager?.initLimit !== "undefined" ) {
        limit = this._params.pager.initLimit;
        delete this._params.pager.initLimit;
      } else {
        limit =this._params.pager.limit;
      }

      switch (this.initConfig.pager.type) {
        case "string":
          return {
            [this.initConfig.pager.dropField  || 'pager.drop']  : this._params.pager.drop,
            [this.initConfig.pager.limitField || 'pager.limit'] : limit,
          }
        case "object":
          let res = {};

          (this.initConfig.pager.dropField  || 'pager.drop')
                .split('.')
                .reduce((obj,i, index, arr) => { obj[i] = obj[i] || ( arr.length - 1 === index  ? this._params.pager.drop : {} ); return obj[i] }, res);

          (this.initConfig.pager.limitField || 'pager.limit')
            .split('.')
            .reduce((obj,i, index, arr) => {   obj[i] = obj[i] || ( arr.length - 1 === index  ? limit : {} ); return obj[i] }, res);

          return res
      }

  }

  //-----------

  private parseConfigSearch() {

    if ( !this.initConfig.search )
      return;

    this._params.search = {
      field: this.initConfig.search.field,
      type: this.initConfig?.search?.type || 'string'
    }

  }

  private getParamsSearch() {
    if (!this._params.search || !this._params.search.value) {
      return {};
    }

    switch (this._params?.search?.type || '') {
      case "object":
        let res = {};

        (this._params.search.field  || 'search')
          .split('.')
          .reduce((obj,i, index, arr) => { obj[i] = obj[i] || ( arr.length - 1 === index  ? this._params.search.value : {} ); return obj[i] }, res);

        return res

      default:
        return  {
          [this._params.search.field] : this._params.search.value
        }

    }

  }

  public search(value: string) {
    if (!this._params.search)
      return false;

    this._params.search.value = value;
    this.onParamChange.emit({
      type:'search',
      value: this._params.search.value
    });
    this.reset();
  }

  public getSearch() {
    if (!this._params.search)
      return false;

    return this._params?.search?.value;
  }

  //-----------

  private parseConfigSort() {

    if ( !this.initConfig.sort )
      return;

    this._params.sort = {
      orderField : this.initConfig.sort.orderField,
      sortField  : this.initConfig.sort.sortField,
    }
  }

  private getParamsSort() {
    if (!this._params.sort || !this._params.sort.sortValue) {
      return {};
    }

    switch (this.initConfig.sort.type) {
      case "string":
        return {
          [this._params.sort.orderField || 'sorting.field'] : this._params.sort.orderValue,
          [this._params.sort.sortField || 'sorting.order']  : this._params.sort.sortValue,
        }
      case "object":
        let res = {};

        (this.initConfig.sort.orderField  || 'sorting.field')
          .split('.')
          .reduce((obj,i, index, arr) => { obj[i] = obj[i] || ( arr.length - 1 === index  ? this._params.sort.orderValue : {} ); return obj[i] }, res);

        (this.initConfig.sort.sortField || 'sorting.order')
          .split('.')
          .reduce((obj,i, index, arr) => {   obj[i] = obj[i] || ( arr.length - 1 === index  ? this._params.sort.sortValue : {} ); return obj[i] }, res);

        return res
    }

  }

  public setSort(field:string,value?: string) {
    if (!this._params.sort)
      return false;

    if (value) {
      this._params.sort.orderValue = value;
      this._params.sort.sortValue  = field;
    } else {
      this._params.sort.orderValue = undefined;
      this._params.sort.sortValue  = undefined;
    }

    this.onParamChange.emit({
      type:'sort',
      value: this._params.sort
    });

    this.reset();
  }

  public getSort() {

    if (!this._params.sort || !this._params.sort.sortValue) {
      return {};
    }

    return {
      field: this._params?.sort?.sortValue,
      order: this._params?.sort?.orderValue
    }

  }

  //------------

  private parseConfigFilter() {

    if ( !this.initConfig.filters || !Array.isArray(this.initConfig.filters) )
      return;

    this._params.filters = this._params.filters || [];
    this.initConfig.filters.forEach( filter => {
      this._params.filters.push(
        Object.assign({
            currentValue: undefined
          },
          filter,
        )
      );
    })

  }

  private getParamsFilters() {
    if (!this._params.filters) {
      return {};
    }

    let result = {};

    this._params.filters.forEach( filter => {


      switch (filter.type) {
        case "object":

          filter.field
            .split('.')
            .reduce((obj,i, index, arr) => { obj[i] = obj[i] || ( arr.length - 1 === index  ? filter.currentValue : {} ); return obj[i] }, result);

          break;

        default:

          Object.assign(result, {
            [filter.field] : filter.currentValue
          });

      }

    } )

    return result;

  }

  public getFilterValues(id:string) {
    if (!this._params.filters)
      return;

    let finded = this._params.filters.find( i => ( typeof i.id !== "undefined" && i.id === id ) || (i.field === id ) );

    if (finded) {
      return finded.values;
    }
    return;
  }

  public getFilterValue(id:string) {
    if (!this._params.filters)
      return;

    let finded = this._params.filters.find( i => ( typeof i.id !== "undefined" && i.id === id ) || (i.field === id ) );

    if (finded) {
      return finded.currentValue;
    }
    return;
  }

  public setFilterValue(id:string, value: any) {
    if (!this._params.filters)
      return;

    let finded = this._params.filters.find( i => ( typeof i.id !== "undefined" && i.id === id ) || (i.field === id ) );

    this.onParamChange.emit({
      type:'filter',
      id,
      value
    });

    finded.currentValue = value;
  }

  //-----------

  public setPager(limit, drop=0) {
    if (this._params.pager) {
      this._params.pager.drop = drop;
    }

    if (this._params.pager) {
      this._params.pager.initLimit = limit;
    }
  }

  public next() {

    if (this._params.pager) {
      this._params.pager.drop += this._params.pager.limit;
      delete this._params.pager.initLimit;
    }

  }

  public prev() {
    if (this._params.pager) {
      this._params.pager.drop -= this._params.pager.limit;
      this._params.pager.drop = this._params.pager.drop < 0 ? 0 : this._params.pager.drop;
      delete this._params.pager.initLimit;
    }
  }

  public reset() {
    if (this._params.pager) {
      this._params.pager.drop = 0;
      this._params.pager.isEnd = false;
      delete this._params.pager.initLimit;
    }
  }

  public pagerPipe<T>(){

    return tap<T>({
      next: (result) => {

        if ( Array.isArray(result) && result?.length < (this._params?.pager?.limit  || 0 ) ) {
          this._params.pager = this._params.pager || {};
          this._params.pager.isEnd = true;
        }

        return result;
      },
      error: (err) => {
        this._params.pager = this._params.pager || {};
        this._params.pager.isEnd = true;
        return throwError(err);
      },
    })
  }

  get isPagerEnded() {
    return !!this._params?.pager?.isEnd;
  }

}
