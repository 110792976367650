import './grid.scss'
import './gridItemForm'

class GridContainer {

    static $inject = ['$element', '$timeout'];

    private root: Element;

    constructor( $element: ng.IRootElementService, $timeout: any ) {
        this.root = $element[0];
        this.root.classList.add('grid-container');

        $timeout(() => {
          if ( this.root.firstElementChild &&  this.root.clientWidth < this.root.firstElementChild.clientWidth )
            this.root.classList.add('force-xs');
        })
   //     this.addDummy();
    }

    public addDummy() {

        const dummy = document.createElement('div');
        dummy.className='grid-item grid-item_dummy';

        for (let i = 0; i<5 ; i++) {
            this.root.appendChild( dummy.cloneNode() );
        }
    }

}


export default window['angular'].module('loya.builder').directive('gridContainer', () => ({

    restrict: 'EAC',
    controller  : GridContainer,

}));


