  <table  class="table m-b-none  b-b  "
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          [infiniteScrollContainer]="'.modal-body .type-block-container'"
          [fromRoot]="true"
          (scrolled)="onScroll()"
  >
    <thead>
    <tr>
      <th class="indicator-hide">
      </th>
      <th>
          <span>
            Список
          </span>
      </th>
      <th>
          <span>
            Описание
          </span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of goodgroupItems" data-testid="item of goodgroupItems">
      <td width="2%" align="center" >

        <div class="checkbox">
          <label class="i-checks">
            <input type="checkbox"
                   [checked]="isSelected(item.id)"
                   (change)="changeSelected(item.id, !!$event?.currentTarget?.checked)"
                   [disabled]="isDisabled(item.id)"
            >
            <i></i>
          </label>
        </div>

      </td>
      <td class=" table-big-link"  data-value="" data-testid="listItem.label">
        <text-highlight
          [search]="searchQuery"
          [text]="item.label"
        >
        </text-highlight>
      </td>

      <td class="table-big-link" data-testid="listItem.description">
        <text-highlight
          [search]="searchQuery"
          [text]="item.description"
        >
        </text-highlight>
      </td>
    </tr>

    </tbody>
  </table>

  <div class="w-full text-center padder"
    *ngIf="loader.isLoading('goodgroupItems')"
  >
      <div class="w-100 text-center">
        <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
      </div>
  </div>



