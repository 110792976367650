'use strict';

import module from 'frontendModule';

let routePath = module.name + '.system.settings.externalTask.index';

import 'model/ExternalTaskModel/resource';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/system.settings.externalTask.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/system.settings.externalTask.index" */ "./controllers"       ))

                .breadcrumbs( ["Настройки", "Задачи"] )

                .resolve('taskModules', ['modulesStorage',  ( modulesStorage : any ) =>  modulesStorage.get( 'system.settings.task' ) || {} ])

                .permissions( {
                    only: ['Administrator'],
                    permit: 'system.settings.externalTask'
                })

        }]);


