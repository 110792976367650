import {Directive, Input, forwardRef, ElementRef, Renderer2, OnChanges, SimpleChanges, Injector} from '@angular/core'
import {NG_VALIDATORS, Validator, AbstractControl, Validators, NgControl} from '@angular/forms'
import {Renderer} from "@angular/compiler-cli/ngcc/src/rendering/renderer";
import {formatDate} from "@angular/common";

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0;
}

@Directive({
  selector: '[max], [min]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxMinValidatorDirective, multi: true }],
})
export class MaxMinValidatorDirective implements Validator, OnChanges {

  @Input() max: number;
  @Input() min: number;

  @Input() minMaxValidateOnChange = false;

  constructor(
    private elemRef: ElementRef,
    private renderer: Renderer2,
    private _injector: Injector
  ) {

  }

  validate(control: AbstractControl): { [key: string]: any } {

    let validators = {};

    if ( typeof this.max !== "undefined") {

      if  ( typeof this.max === "string" && /^\d\d\d\d-\d\d-\d\d$/.test(this.max) ) {

          if (
              !isEmptyInputValue(control.value) &&
              formatDate(control.value,'yyyy-MM-dd', 'en_US') >
              formatDate(this.max,'yyyy-MM-dd', 'en_US')  ) {
            Object.assign(validators, {'max': {'max': this.max, 'actual': control.value}})
          }

      } else {
        Object.assign(validators, Validators.max(this.max)(control));
      }

    }

    if ( typeof this.min !== "undefined"){


      if  ( typeof this.min === "string" && /^\d\d\d\d-\d\d-\d\d$/.test(this.min) ) {

        if (
          !isEmptyInputValue(control.value) &&
          formatDate(control.value,'yyyy-MM-dd', 'en_US') <
          formatDate(this.min,'yyyy-MM-dd', 'en_US')  ) {
          Object.assign(validators, {'min': {'min': this.min, 'actual': control.value}})
        }

      } else {
        Object.assign(validators, Validators.min(this.min)(control));
      }
    }

    return validators;

  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.elemRef.nativeElement?.type === 'date') {
      if (changes['max']) {
        if (changes['max'].currentValue)
          this.renderer.setAttribute(this.elemRef.nativeElement,'max',changes['max'].currentValue.toString());
        else
          this.renderer.removeAttribute(this.elemRef.nativeElement,'max');
      }

      if (changes['min']) {
        if (changes['min'].currentValue)
          this.renderer.setAttribute(this.elemRef.nativeElement,'min',changes['min'].currentValue.toString());
        else
          this.renderer.removeAttribute(this.elemRef.nativeElement,'min');
      }
    }


    if (this.elemRef.nativeElement?.type === 'number') {
      if (changes['max']) {
        if (typeof changes['max'].currentValue !== "undefined")
          this.renderer.setAttribute(this.elemRef.nativeElement,'max',changes['max'].currentValue.toString());
        else
          this.renderer.removeAttribute(this.elemRef.nativeElement,'max');
      }

      if (changes['min']) {
        if (typeof changes['min'].currentValue !== "undefined")
          this.renderer.setAttribute(this.elemRef.nativeElement,'min',changes['min'].currentValue.toString());
        else
          this.renderer.removeAttribute(this.elemRef.nativeElement,'min');
      }
    }


    if (this.minMaxValidateOnChange) {
      let control = this._injector.get(NgControl, null);
      if (control)
        control?.control?.updateValueAndValidity();
    }

  }

}
