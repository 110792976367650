import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Directive,
  ElementRef,
  Renderer2,
  Injector,
  ComponentFactoryResolver,
  ViewContainerRef,
  NgZone,
  Inject,
  ChangeDetectorRef,
  ApplicationRef,
  OnDestroy,
  OnChanges, SimpleChanges
} from '@angular/core';
import {NgbPopover, NgbPopoverConfig} from "@ng-bootstrap/ng-bootstrap";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: '[ng1-popover]',
  template: '<ng-content></ng-content>'
})
export class Ng1PopoverComponent implements OnInit, OnDestroy, OnChanges {

  @Input() ng1Popover: string;
  @Input() popoverTrigger: string;
  @Input() popoverPlacement: string;
  @Input() popoverTitle: string;
  @Input() popoverEnable: boolean;
  @Input() popoverClass: string;

  private popover:any;

  constructor(  private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2, injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver, viewContainerRef: ViewContainerRef, config: NgbPopoverConfig,
    private _ngZone: NgZone, @Inject(DOCUMENT) private _document: any, private _changeDetector: ChangeDetectorRef,
    applicationRef: ApplicationRef) {

    this._elementRef.nativeElement.setAttribute('ngbPopover',"You see, I show up on hover!");
    this._elementRef.nativeElement.setAttribute('triggers', "mouseenter:mouseleave");

    this.popover = new NgbPopover(_elementRef,_renderer, injector, componentFactoryResolver, viewContainerRef, config,
      _ngZone, _document, _changeDetector, applicationRef );
  }

  ngOnInit() {
    this.popover.ngbPopover = this.ng1Popover;
    this.getTrigger(this.popoverTrigger);
    this.getPlacement(this.popoverPlacement);
    this.getTitle(this.popoverTitle);
    this.setDisabled(this.popoverEnable);
    this.setClass(this.popoverClass);
    this.popover.ngOnInit();
  }

  ngOnDestroy() {
    this.popover.ngOnDestroy();
    delete this.popover;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getTrigger(this.popoverTrigger);
    this.getPlacement(this.popoverPlacement);
    this.getTitle(this.popoverTitle);
    this.setDisabled(this.popoverEnable);
    this.setClass(this.popoverClass);

    this.popover.ngOnChanges(changes);
  }

  getTrigger( val:string ) {
    if (!val) return;
    val = val.replace("'","").replace("'","").trim();

    switch (val) {
      case 'blur':
        this.popover.triggers = 'mouseenter:mouseleave blur';
        break;
      case 'click':
        this.popover.triggers = 'click';
        break;
      case 'focus':
        this.popover.triggers = 'mouseenter:mouseleave focus';
        break;
      case 'mouseenter':
        this.popover.triggers = 'mouseenter:mouseleave';
        break;
      case 'none':
        this.popover.triggers = 'none';
        break;
      case 'outsideClick':
        this.popover.triggers = 'click';
        this.popover.autoClose="outside"
        break;
    }
  }

  getPlacement( val:string ) {
    if (!val) return;
    this.popover.placement = val.replace("'",'').replace("'",'').trim();
  }

  getTitle( val:string ) {
    if (!val) return;
    this.popover.title = val.replace("'",'').replace("'",'').trim();
  }

  setDisabled(val) {
    if (typeof val === "undefined" || val === "") return;
    this.popover.disablePopover = !val;
  }

  setClass(val) {
    if (!val) return;
    this.popover.popoverClass = val;
  }

}
