import {Component, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {EventEmitter} from "@angular/core";

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',

})
export class SearchInputComponent implements OnInit, OnChanges{


    // Внутренние переменные
    private _searchString : string;
    set searchString(value) {
      this.changeModel(value, this._searchString);
    }

    get searchString() {
      return this._searchString;
    }

    private changeOldVal;

    // Передаваемые параметры в компонент
    @Input()  public model       : any;
    @Output()  public modelChange = new EventEmitter<string>();
    @Input()  public placeholder : string;
    @Input()  public change    : any;
    @Input()  public isEnabled : boolean;
      public isSearchEnabled : boolean;
    @Input()  public isPending  : boolean;
    @Input()  public keyHook : any;

    constructor( ) { }

    public ngOnInit() {
        if ( typeof this.isEnabled === "undefined")
          this.isEnabled = true;

        if ( this.isEnabled ) {
            this.searchString = this.model;
            this.isSearchEnabled = !!this.searchString;
        }

    }

    public ngOnChanges(changes: SimpleChanges) {
      if ( changes.model ) {
        if ( this.isEnabled ) {
          this.searchString = changes.model.currentValue;
          this.isSearchEnabled = !!this.searchString;
        }
      }
    }

  public setSearch = () => {

        if ( !this.searchString ) {
            this.resetSearch();
        } else {
            this.model = this.searchString;
            this.modelChange.emit(this.model);
            this.isSearchEnabled = true;
        }

    }

    public _keyHook = ($event) => {

      if (this.keyHook)
        return this.keyHook($event);

      if(!$event)
      {
        $event = window.event;
      }

      if($event.keyCode == 13) {
        this.setSearch();
      }
    };

    private resetSearch = () => {
        this.searchString = '';
        this.isSearchEnabled = false;
        this.model = this.searchString;
        this.modelChange.emit(this.model);
    //    this.updateView();
    }

 /*   private updateView = () => {
        this.$scope.$apply();
    }*/


    private changeModel = function( currentVal, oldVal ) {
      if ( this.change && ( typeof currentVal !== "undefined" && this.changeOldVal !== currentVal ) ) {
        this.changeOldVal = currentVal;
        this._searchString = this.change(currentVal, oldVal );
      } else {
        this._searchString = currentVal;
      }
    };

};
