/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { MechanicCategoryApiConfiguration as __Configuration } from '../mechanic-category-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MechanicCategory } from '../models/mechanic-category';
@Injectable({
  providedIn: 'root',
})
class MechanicCategoryApiService extends __BaseService {
  static readonly createPath = '/mechaniccategory';
  static readonly queryPath = '/mechaniccategory';
  static readonly getPath = '/mechaniccategory/{id}';
  static readonly updatePath = '/mechaniccategory/{id}';
  static readonly deletePath = '/mechaniccategory/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param category Описание категории механики
   * @return Созданная категория механики
   */
  createResponse(category: MechanicCategory): __Observable<__StrictHttpResponse<MechanicCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = category;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mechaniccategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MechanicCategory>;
      })
    );
  }
  /**
   * @param category Описание категории механики
   * @return Созданная категория механики
   */
  create(category: MechanicCategory): __Observable<MechanicCategory> {
    return this.createResponse(category).pipe(
      __map(_r => _r.body as MechanicCategory)
    );
  }

  /**
   * Получить список категорий механики
   * @return Список категорий механики
   */
  queryResponse(): __Observable<__StrictHttpResponse<Array<MechanicCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mechaniccategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MechanicCategory>>;
      })
    );
  }
  /**
   * Получить список категорий механики
   * @return Список категорий механики
   */
  query(): __Observable<Array<MechanicCategory>> {
    return this.queryResponse().pipe(
      __map(_r => _r.body as Array<MechanicCategory>)
    );
  }

  /**
   * Получить категорию механики по ID
   * @param id ID категории механики
   * @return Параметры категории механики
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<MechanicCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mechaniccategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MechanicCategory>;
      })
    );
  }
  /**
   * Получить категорию механики по ID
   * @param id ID категории механики
   * @return Параметры категории механики
   */
  get(id: number): __Observable<MechanicCategory> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as MechanicCategory)
    );
  }

  /**
   * Обновить категорию механики по ID
   * @param params The `MechanicCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание категории механики
   *
   * - `id`: ID категории механики
   *
   * @return Параметры категории механики
   */
  updateResponse(params: MechanicCategoryApiService.UpdateParams): __Observable<__StrictHttpResponse<MechanicCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.partner;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mechaniccategory/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MechanicCategory>;
      })
    );
  }
  /**
   * Обновить категорию механики по ID
   * @param params The `MechanicCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание категории механики
   *
   * - `id`: ID категории механики
   *
   * @return Параметры категории механики
   */
  update(params: MechanicCategoryApiService.UpdateParams): __Observable<MechanicCategory> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as MechanicCategory)
    );
  }

  /**
   * Удалить категорию механики по ID
   * @param id ID категории механики
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mechaniccategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить категорию механики по ID
   * @param id ID категории механики
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MechanicCategoryApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Описание категории механики
     */
    partner: MechanicCategory;

    /**
     * ID категории механики
     */
    id: number;
  }
}

export { MechanicCategoryApiService }
