import { QueryHandler } from './../QueryHandler.class';
import { ResourceHandler} from './../ngResourceHandler.class';

export const FILTER_FIELD = '$handler';


export class ngGlobalHandler extends ResourceHandler{

  static $inject = ['handler', 'listFilters'];

    constructor(
        protected handler: QueryHandler,
        private listFilters: ResourceHandler[]
    ) {

        super(handler);
    }

    get field() {
        return FILTER_FIELD;
    }


    static canHandle( actions: any ) {
        return true;
    }

    public getCurrentParams() {
        let params = {};

        this.listFilters.forEach( filter => {

            QueryHandler.mergeParams(params, filter.getCurrentParams());

        } );

        return params;
    };

    public query = function () {
        return this.handler.reset();
    };


    public reset = function () {
        return this.handler.reset();
    };

    protected onUpdate( finish: Promise<any> ) {
        return {}
    };

    protected onStart() {}

    protected onReset() {}

}

