'use strict';

import module from 'frontendModule';


//TODO NEW FRONT check require

export default module.directive('mechanicConfigInput', function() {
    return {
        restrict: 'E',

        link: function (scope, element, attrs) {

        },
        template:  '',
        scope: {
            'type':     '=',
            'config':   '=',
            'readonly': '='

        },
        controller: ['$scope', '$element', '$attrs', '$transclude', '$q', '$templateRequest', '$compile', '$controller', 'modulesStorage',
          function ($scope, $element, $attrs, $transclude, $q, $templateRequest, $compile, $controller, modulesStorage ) {

            if ( angular.isUndefined($scope.type) )
                return;

            var config = modulesStorage.getCollection( 'campaign.mechanics.config', $scope.type );

            if (!config)
                return;

            $q.all({
                    controller: config.controler,
                    template:   $templateRequest( config.templateUrl )
                }
            ).then(function( result ) {

                var _scope = $scope.$new();

                try {
                    $controller(result.controller,
                        {
                            $scope   : _scope,
                            config   : $scope.config,
                            readonly : $scope.readonly
                        }
                    );
                } catch(e) {
                    console.log(e);
                }


                $element.append( result.template );
                $compile($element)(_scope);

            });

        }]
    };
});
