import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule, NgbModule, NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {TranslocoRootModule} from "../../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {GateIndexPageComponent} from "./index/gate-index-page.component";
import {GateNewsletterListComponent} from "./components/gate-newsletter-list/gate-newsletter-list.component";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";
import {GateTaskListComponent} from "./components/gate-task-list/gate-task-list.component";
import {SearchInputModule} from "../../../shared/component/search-input/search-input.module";
import {GateVereficationListComponent} from "./components/gate-verefication-list/gate-verefication-list.component";


@NgModule({
  declarations: [
    GateIndexPageComponent,
    GateNewsletterListComponent,
    GateTaskListComponent,
    GateVereficationListComponent
  ],
    imports: [
        BrowserModule,
        UpgradeModule,
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        HttpClientModule,
        TranslocoRootModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        NgbModule,
        NgbNavModule,
        FiltersComponentsModule,
        SearchInputModule,
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class GateModule {

  constructor() {}

}
