import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe } from '@angular/common';

@Pipe({
  name: 'loyaDate'
})
export class LoyaDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string) {}

  transform(input: string, args?: any): string {

    let datePipe: any =  new DatePipe(this._locale);

    if ( angular.isString(input) && input.indexOf(' ') !== -1 )
      input = <string>input.split(' ')[0];

    return datePipe.transform(input, 'dd.MM.yyyy');
  }
}
