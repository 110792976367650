
<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
  [showAddButton] = "true"
  (onAddClick)="createNew()"
  [updateList]="updateCounter"
>

  <ng-container
    *ngIf="!!currentPartner">

    <div class="fixed-tool-panel">


      <button type="button" class="btn btn-sm btn-addon btn-danger "
              [hidden]="!statesChangerService.hasButton('delete')"
              (click)="confirmActionService.confirm('Вы действительно хотите удалить партнёра ' + currentPartner.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
      >
        <i class=" m-r-xs fa fa-remove"></i>
        <span class="btn-name m-l-xs hidden-xxs">
                 {{ 'interface.buttons.remove' | transloco }}
                </span>
      </button>



      <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
              [hidden]="!statesChangerService.hasButton('edit')"
              (click)="statesChangerService.click('edit')"
      >

        <i class=" fa fa-pencil">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">
                 {{ 'interface.buttons.edit' | transloco }}
              </span>
      </button>


      <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
              *ngIf="statesChangerService.hasButton('save')"
              (click)="statesChangerService.click('save')"
              [disabled]="partnerForm && partnerForm.form && partnerForm.form.invalid"
      >
        <i class=" fa fa-check text-primary">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.save' | transloco }}
      </span>
      </button>

      <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
              *ngIf="statesChangerService.hasButton('cancel')"
              (click)="statesChangerService.click('cancel')">

        <i class=" fa fa-remove text-danger">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.cancel' | transloco }}
      </span>
      </button>


      <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
              *ngIf="statesChangerService.hasButton('preview')"
              (click)="statesChangerService.click('preview')"

              [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
              [disabled]="partnerForm && partnerForm.form && partnerForm.form.invalid"
      >
        <i class=" fa fa-eye text-success">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.preview' | transloco }}
      </span>
      </button>


    </div>

    <form name="partnerForm"  #partnerForm="ngForm"  novalidate>


      <div class="padder mt-3">
        <grid-container>

          <div class="grid-item text-center grid-item_row4"
          >

            <photo-loader
              [isActive]="statesChangerService.isActive"
              [(model)]="currentPartner.logoUrl"
            ></photo-loader>

          </div>

          <ng-container
            *ngIf="currentPartner.id !== undefined  ">

            <div class="grid-item grid-item__form"
                 *loyaPermitIf="{path:'partner', permitEnabled: ''}"
            >
              <div class="grid-item__label">
                <i class="fa fa-user"></i>ID Партнёра
              </div>
              <div class="grid-item__value">
                {{ currentPartner.id }}
              </div>
            </div>

          </ng-container>

          <div class="grid-item grid-item__form"
               *loyaPermitIf="{path:'partner', permitEnabled: ''}"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Имя
            </div>

            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive"> {{ currentPartner.name }}</div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.name"
                  name="name"
                  placeholder="Введите имя"
                  [required]="true"
                  [maxlength]="254"


                >
              </div>
            </div>

          </div>

          <div class="grid-item  grid-item__form"
               *loyaPermitIf="{path:'partner', permitEnabled: ''}"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>E-mail
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive"> {{ currentPartner.email }}</div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                <input
                  type="email"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.email"
                  name="email"
                  placeholder="Введите e-mail"
                  [required]="true"
                  pattern=".+@.+\..+"
                  [maxlength]="254"
                >
              </div>
            </div>
          </div>

          <div class="grid-item grid-item__form "
               *loyaPermitIf="{path:'partner', permitEnabled: ''}"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Описание
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{ currentPartner.description }} </div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                                      <textarea
                                        class="form-control form-control-edittable"
                                        [(ngModel)]="currentPartner.description"
                                        name="description"
                                        placeholder="Введите краткое описание партнера"
                                        [maxlength]="250"
                                        [required]="true"

                                        ></textarea>
              </div>
            </div>

          </div>


          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Максимальное количество списаний бонусов покупателя за день
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{
                currentPartner.limits.maxBonusCheckByDay }}
              </div>

              <div class="editable-rec " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.maxBonusCheckByDay"
                  name="maxBonusCheckByDay"
                  placeholder="Введите количество списаний"
                  [required]="true"
                  pattern="^[0-9]+$"
                  [min]="0"
                  [max]="999"
                >

              </div>
            </div>
          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Максимальный % от ст-ти позиции чека, разрешенный к оплате бонусами
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{ currentPartner.limits.maxTransactionSummPercent }} %</div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.maxTransactionSummPercent"
                  name="maxTransactionSummPercent"
                  placeholder="Введите процент суммы"
                  [required]="true"
                  pattern="^[0-9]+$"
                  [min]="0"
                  [max]="100"
                >
                <span class="input-group-addon">%</span>
              </div>
            </div>
          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Процент начисления бонусов всем покупателям
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{ currentPartner.limits.baseBonusPercent }} %</div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.baseBonusPercent"
                  name="baseBonusPercent"
                  placeholder="Введите процент"
                  [required]="true"
                  pattern="^(\d*[.])?\d+$"
                  [min]="0"
                  [max]="100"
                >
                <span class="input-group-addon">%</span>
              </div>
            </div>
          </div>

          <div class="grid-item grid-item_full"
               *ngIf="checkBonusPermissions()"
          >

          <bonus-config-ng1
            [model]="currentPartner.limits"
            [readOnly]="!statesChangerService.isActive"
          ></bonus-config-ng1>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Сжигать бонусы при неактивности покупателя
            </div>
            <div class="grid-item__value" data-testid="lastVisitBonusBurn">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurn">Да</span>
                <span *ngIf="!currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurn">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurn"
                           name="lastVisitBonusBurn"
                           (ngModelChange)="changeLastVisitBonusBurn()"
                           >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>
          </div>

          <div class="grid-item grid-item__form" data-testid="lastVisitBonusBurn_period"
               *ngIf="checkBonusPermissions() && currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurn"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i> Срок с даты последней покупки
            </div>
            <div class="grid-item__value" data-testid="lastVisitBonusBurnValidity">
              <div *ngIf="!statesChangerService.isActive" >
                {{ currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnValidity }} {{ bonusBurnPeriodList[currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnPeriod]?.label | lowercase }}
              </div>

              <div class="editable-rec w-full input-group " *ngIf="statesChangerService.isActive">

                <div class="input-group w-full ">

                  <input
                    class="form-control  wrapper-xs"

                    type="number"
                    [(ngModel)]="currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnValidity"
                    name="lastVisitBonusBurnValidity"
                    [required]="true"
                    [min]="0"
                    [max]="999"
                  >


                  <div class="input-group-btn dropdown" ngbDropdown>

                    <button type="button"
                            class="btn btn-default"
                            ngbDropdownToggle
                    >
                      {{ bonusBurnPeriodList[currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnPeriod]?.label || 'Период' }}<span
                      class="caret"></span>
                    </button>

                    <ul class="dropdown-menu pull-right" data-testid="dropdown-value"
                        ngbDropdownMenu
                    >
                      <li *ngFor="let item of bonusBurnPeriodList | keyvalue"
                          (click)="currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnPeriod = item.key"
                      >
                        <a href="javascript:void(0)"
                           ngbDropdownItem
                        >{{item.value?.label}}</a>
                      </li>
                    </ul>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>
              Предельный срок обработки оплаты бонусов
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive">
                {{ currentPartner.limits.bonusWaitConfirmDaysCount }}
                <span *ngIf="!!currentPartner.limits.bonusWaitConfirmDaysCount">дней</span>
                <span *ngIf="currentPartner.limits.bonusWaitConfirmDaysCount=== undefined || currentPartner.limits.bonusWaitConfirmDaysCount === null ">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.bonusWaitConfirmDaysCount"
                  name="limitsBonusWaitConfirmDaysCount"
                  placeholder="Введите предельный срок"
                  [required]="false"
                  [min]="0"
                  [max]="999"
                >
                <span class="input-group-addon">дней</span>
              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"
               *ngIf="checkBonusPermissions()"
          ></div>

          <div class="grid-item grid-item__form"
               *ngIf="checkSmsPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Имя отправителя sms
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive"> {{ currentPartner.senderSms }}</div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.senderSms"
                  name="senderSms"
                  placeholder="Имя отправителя sms"
                  [required]="true"
                  [maxlength]="35"
                >
              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkEmailPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Имя отправителя email
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive"> {{ currentPartner.senderEmail }}</div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.senderEmail"
                  name="senderEmail"
                  placeholder="Имя отправителя email"
                  [required]="true"
                  [maxlength]="254"
                >
              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkViberPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>Имя отправителя viber
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive"> {{ currentPartner.senderViber }}</div>

              <div class="editable-rec" *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.senderViber"
                  name="senderViber"
                  placeholder="Имя отправителя viber"
                  [required]="true"
                  [maxlength]="35"
                >
              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"
               *ngIf="checkEmailPermissions() || checkSmsPermissions() || checkViberPermissions()"
          ></div>

          <div class="grid-item grid-item__form"
               [grid-item-form] = "'Скидка на мелочь при оплате наличными'"
               grid-item-form-icon = "fa-gear"
          >
            <div *ngIf="!statesChangerService.isActive" >
              <span *ngIf="currentPartner.limits.useDiscountOnChangeCash">Да</span>
              <span *ngIf="!currentPartner.limits.useDiscountOnChangeCash">Нет</span>
            </div>

            <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

              <div class="checkbox inline ">
                <label class="i-checks">
                  <input type="checkbox"
                         class=""
                         [(ngModel)]="currentPartner.limits.useDiscountOnChangeCash"
                         (ngModelChange)="checkDiscountChange()"
                         name="useDiscountOnChangeCash"
                         >
                  <i></i>
                </label>
              </div>

            </div>
          </div>

          <ng-container
            *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
          >
            <div class="grid-item grid-item__form"
               grid-item-form = "Учитывать оплату бонусами при начислении бонусов"
               grid-item-form-icon = "fa-gear"
            >
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.considerUsedBonusInAwards">Да</span>
                <span *ngIf="!currentPartner.limits.considerUsedBonusInAwards">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.considerUsedBonusInAwards"
                           name="considerUsedBonusInAwards"
                           >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </ng-container>

          <div class="grid-item  grid-item_clean " ></div>

          <div class="grid-item grid-item__form "
               grid-item-form = "Скидка на мелочь при безналичной оплате"
               grid-item-form-icon = "fa-gear"
          >
            <div *ngIf="!statesChangerService.isActive" data-testid='useDiscountOnChangeCard'>
              <span *ngIf="currentPartner.limits.useDiscountOnChangeCard">Да</span>
              <span *ngIf="!currentPartner.limits.useDiscountOnChangeCard">Нет</span>
            </div>

            <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

              <div class="checkbox inline ">
                <label class="i-checks">
                  <input type="checkbox"
                         class=""
                         [(ngModel)]="currentPartner.limits.useDiscountOnChangeCard"
                         (ngModelChange)="checkDiscountChange()"
                         name="useDiscountOnChangeCard"
                         >
                  <i></i>
                </label>
              </div>

            </div>
          </div>

          <div class="grid-item grid-item__form"
               *ngIf="statesChangerService.isActive || currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard || currentPartner.limits.discountOnChangeUseGoodLimits"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Учитывать ограничения LOYA при выдаче скидки на мелочь
            </div>
            <div class="grid-item__value">

              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.discountOnChangeUseGoodLimits">Да</span>
                <span *ngIf="!currentPartner.limits.discountOnChangeUseGoodLimits">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.discountOnChangeUseGoodLimits"
                           name="discountOnChangeUseGoodLimits"
                           >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>
          </div>


          <div class="grid-item grid-item__form"
               *ngIf="statesChangerService.isActive || currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Давать скидку при сумме чека сверх
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{
                currentPartner.limits.discountOnChangeLimit || 0}} <i class="fa " ngCurrencyLoya></i>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable loya-input-money"
                  [(ngModel)]="currentPartner.limits.discountOnChangeLimit"
                  name="discountOnChangeLimit"
                  [disabled]="!(currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard)"
                  [required]="currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard"
                  [min]="0"
                >
                <span class="input-group-addon"><i class="fa " ngCurrencyLoya></i></span>
              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="statesChangerService.isActive || checkDiscountOnChangePrecisionEnabled()"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i> Кратность скидки на мелочь
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive">
                <span class=""> {{ discountOnChangePrecisionList[currentPartner.limits.discountOnChangePrecision] }}</span>
              </div>

              <div class="editable-rec w-full input-group" *ngIf="statesChangerService.isActive">

                <div class="input-group w-full ">

                  <div class="input-group-btn  w-full dropdown"
                       ngbDropdown
                       *ngIf="checkDiscountOnChangePrecisionEnabled()">

                    <button type="button" data-testid="DiscountOnChangePrecisionEnabled_btn"
                            class="btn btn-default dropdown-toggle w-full text-right"
                            ngbDropdownToggle>
                      {{
                      discountOnChangePrecisionList[currentPartner.limits.discountOnChangePrecision]
                      }}
                      <span class="caret "></span>
                    </button>

                    <ul class="dropdown-menu "
                        ngbDropdownMenu
                    >
                      <li *ngFor="let item of discountOnChangePrecisionList | keyvalue"
                          class="text-center"
                      >
                        <a href="javascript:void(0)"
                           ngbDropdownItem
                           (click)="currentPartner.limits.discountOnChangePrecision = item.key"
                        >
                          {{item.value}}
                        </a>

                      </li>
                    </ul>

                  </div>


                  <input type="text" *ngIf="!checkDiscountOnChangePrecisionEnabled()" data-testid="DiscountOnChangePrecisionEnabled"
                         class="form-control w-full text-center  form-control-edittable "
                         [disabled]="true"
                         disabled="disabled"
                         value="1.00"
                  >


                  <span class="input-group-addon">
                      <i class="fa " ngCurrencyLoya></i>
                  </span>

                </div>

              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="statesChangerService.isActive || currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard || currentPartner.limits.allowBonusPaymentRounding"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>Округлять доступную максимальную к оплате сумму бонусов
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.allowBonusPaymentRounding">Да</span>
                <span *ngIf="!currentPartner.limits.allowBonusPaymentRounding">Нет</span>
              </div>


              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.allowBonusPaymentRounding"
                           name="allowBonusPaymentRounding"
                           [disabled]="!(currentPartner.limits.useDiscountOnChangeCash || currentPartner.limits.useDiscountOnChangeCard)"
                           >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"
               *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
          ></div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions()"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>Зачисление сдачи на бонусный счёт
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.transferRestOnAccount">Да</span>
                <span *ngIf="!currentPartner.limits.transferRestOnAccount">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.transferRestOnAccount"
                           name="transferRestOnAccount"
                           [disabled]="!cantransferRestOnAccount()"
                           placeholder="Сдача на бонусный счёт"
                     >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form"
               *ngIf="checkBonusPermissions() && (currentPartner.limits.transferRestOnAccount ||statesChangerService.isActive )"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Максимально возможная сумма в виде сдачи на счет
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive" > {{
                currentPartner.limits.transferRestOnAccountMaxValue === undefined ?
                  maxRestOnAccountInCurrency : currentPartner.limits.transferRestOnAccountMaxValue }} <i
                  class="fa " ngCurrencyLoya></i></div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="text"
                  class="form-control form-control-edittable loya-input-money"
                  [(ngModel)]="currentPartner.limits.transferRestOnAccountMaxValue"
                  name="transferRestOnAccountMaxValue"
                  placeholder="{{ currentPartner.limits.transferRestOnAccount ? maxRestOnAccountInCurrency : '' }}"
                  [disabled]="!currentPartner.limits.transferRestOnAccount"

                  [customValidator]="transferRestOnAccountMaxValueValidator"

                  [required]="false"

                >
                <span class="input-group-addon"><i class="fa " ngCurrencyLoya></i></span>
              </div>
            </div>

          </div>

          <ng-container
            *ngIf="isUseAccountEnabled()"
          >
            <div class="grid-item grid-item__form"
               *loyaPermitIf="{path:'crm.accounts.credit', permitEnabled: ''}"
          >
            <div class="grid-item__label">
              <i class="fa fa-user"></i>
              Списывать мелочь с активного/кредитного счета клиента
            </div>
            <div class="grid-item__value" data-testid="currentPartner.limits.useAccount">

              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.useAccount">Да</span>
                <span *ngIf="!currentPartner.limits.useAccount">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks"
                         [ngbPopover]="'Невозможно списать с кредитного счёта при текущих настройках МНДЕ и кратности бонусов'"
                         [triggers]="'mouseenter:mouseleave'"
                         [disablePopover]="!isUseAccountDisabled()"
                  >
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.useAccount"
                           name="limitsUseAccount"
                           [disabled]="isUseAccountDisabled()"
                    >
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>
          </ng-container>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>Отправлять согласие покупателя на кассы самообслуживания
            </div>
            <div class="grid-item__value" data-testid="showRefusePaperCheckSCO">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.showRefusePaperCheckSCO">Да</span>
                <span *ngIf="!currentPartner.limits.showRefusePaperCheckSCO">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.showRefusePaperCheckSCO"
                           name="showRefusePaperCheckSCO"
                           placeholder="Отправлять согласие покупателя на кассы самообслуживания">
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>Начисление бонусов по QR коду
            </div>
            <div class="grid-item__value" data-testid="qrAwardBonusEnabled">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.qrAwardBonusEnabled">Да</span>
                <span *ngIf="!currentPartner.limits.qrAwardBonusEnabled">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.qrAwardBonusEnabled"
                           name="qrAwardBonusEnabled"
                           placeholder="Начисление бонусов по qr коду">
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>Списание бонусов по QR коду
            </div>
            <div class="grid-item__value" data-testid="qrUsedBonusEnabled">
              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.qrUsedBonusEnabled">Да</span>
                <span *ngIf="!currentPartner.limits.qrUsedBonusEnabled">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">

                <div class="checkbox inline ">
                  <label class="i-checks">
                    <input type="checkbox"
                           class=""
                           [(ngModel)]="currentPartner.limits.qrUsedBonusEnabled"
                           name="qrUsedBonusEnabled"
                           placeholder="Списание бонусов по qr коду">
                    <i></i>
                  </label>
                </div>

              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-user"></i>
              Срок действия QR
            </div>
            <div class="grid-item__value">
              <div *ngIf="!statesChangerService.isActive">
                {{ currentPartner.limits.qrLifetime }}
                <span *ngIf="!!currentPartner.limits.qrLifetime">секунд</span>
                <span *ngIf="!currentPartner.limits.qrLifetime ">Нет</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.qrLifetime"
                  name="qrLifetime"
                  placeholder="Срок действия QR"
                  [required]="false"
                  [min]="0"
                  [max]="65535"
                >
                <span class="input-group-addon">секунд</span>
              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>URL для PUSH уведомлений
            </div>
            <div class="grid-item__value" data-testid="pushSettings.pushUrl">
              <div *ngIf="!statesChangerService.isActive" >
                <span>{{currentPartner?.pushSettings?.pushUrl || 'Нет'}}</span>
              </div>

              <div class="editable-rec  " *ngIf="statesChangerService.isActive">

                  <input
                    type="text"
                    class="form-control form-control-edittable"
                    [(ngModel)]="currentPartner.pushSettings.pushUrl"
                    name="pushSettings_pushUrl"
                    placeholder="Введите URL"
                    [required]="false"
                    [maxlength]="10000"
                  >

              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>API для PUSH уведомлений
            </div>
            <div class="grid-item__value" data-testid="pushSettings.pushUrl">
              <div *ngIf="!statesChangerService.isActive" >
                <span>{{currentPartner?.pushSettings?.pushUrl || 'Нет'}}</span>
              </div>

              <div class="editable-rec  " *ngIf="statesChangerService.isActive">

                <input
                  type="text"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.pushSettings.pushApiKey"
                  name="pushSettings_pushApiKey"
                  placeholder="Введите API"
                  [required]="!!currentPartner?.pushSettings?.pushUrl"
                  [maxlength]="10000"
                >
              </div>
            </div>

          </div>


          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Цифровые коды товаров фиксированной длины
            </div>

            <div class="grid-item__value">

              <div *ngIf="!statesChangerService.isActive" >
                <span *ngIf="currentPartner.limits.skuLength">{{currentPartner.limits.skuLength}} знаков</span>
                <span *ngIf="!currentPartner.limits.skuLength">Выключено</span>
              </div>

              <div class="editable-rec input-group " *ngIf="statesChangerService.isActive">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentPartner.limits.skuLength"
                  name="skuLength"
                  pattern="^\d+$"
                  min="0"
                  max="128"
                >
                <span class="input-group-addon">знаков</span>
              </div>
            </div>

          </div>

          <div class="grid-item grid-item__form grid-item_full" *ngIf="!!currentPartner.limits.skuLength">
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
            </div>

            <div class="text-xs text-muted grid-item__value">

              Коды товаров вида: {{ '0'.repeat(currentPartner.limits.skuLength)}} - {{ '9'.repeat(currentPartner.limits.skuLength)}}
            </div>

          </div>


          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

          <div class="grid-item grid-item__form grid-item_full"
               *ngIf="statesChangerService.isActive || !!currentPartner?.limits?.currencyMNDE"
          >
            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Минимальная Наличная Денежная Единица
            </div>

            <div class="grid-item__value" data-testid="min_currency_mnde">

              <div *ngIf="!statesChangerService.isActive" class="">
                {{ selectedPartner.limits.currencyMNDE }}
                <i class="fa " ngCurrencyLoya></i>
              </div>

              <div class="editable-rec input-group w-full "
                   *ngIf="statesChangerService.isActive">

                <div class="input-group ">

                  <span class="input-group-addon bootstrap-touchspin-prefix no-padder">
                      <label class="i-checks m-l-xs m-t-xs">
                          <input type="checkbox"
                                 [ngModel]="isIdEnabled('currencyMNDE')"
                                 (ngModelChange)="changeIdEnabled($event, 'currencyMNDE')"
                                 name="enabledCurrencyMNDE"
                                 placeholder="Скидка на мелочь"

                                 >
                          <i></i>
                      </label>
                  </span>

                  <input
                    type="number"
                    class="form-control text-center wrapper-xs "
                    [ngModel]="calcK('currencyMNDE')"
                    (ngModelChange)="partnerForm.controls['currencyMNDE_K'].valid && changeK($event, 'currencyMNDE')"
                    name="currencyMNDE_K"
                    placeholder=""
                    [required]="true"
                    [disabled]="!isIdEnabled('currencyMNDE')"
                    pattern="^\d$"
                    [min]="1"
                    [max]="9"
                    min="1"
                    max="9"
                  >

                  <span class="input-group-addon bootstrap-touchspin-prefix"> x 10 ^ </span>

                  <input
                    type="number"
                    class="form-control text-center wrapper-xs "
                    [ngModel]="calcN('currencyMNDE')"
                    (ngModelChange)="partnerForm.controls['currencyMNDE_N'].valid && changeN($event, 'currencyMNDE')"
                    name="currencyMNDE_N"
                    [disabled]="!isIdEnabled('currencyMNDE')"
                    placeholder=""
                    [required]="true"
                    pattern="^(-[1,2]|\d)$"
                    [min]="-2"
                    [max]="9"
                    min="-2"
                    max="9"
                  >

                  <span class="input-group-addon bootstrap-touchspin-prefix"> = </span>

                  <input
                    type="text"
                    [disabled]="true"
                    class="form-control text-center wrapper-xs"
                    [(ngModel)]="currentPartner.limits.currencyMNDE"
                    name="currencyMNDE"
                  >
                </div>


              </div>
            </div>

          </div>

          <div class="grid-item  grid-item_clean "></div>

          <div class="grid-item grid-item__form grid-item_full"
               *ngIf="checkBonusPermissions() && ( statesChangerService.isActive || !!currentPartner?.limits?.bonusMNDE )"
          >

            <div class="grid-item__label">
              <i class="fa fa-gear"></i>
              Кратность бонусов
            </div>

            <div class="grid-item__value" data-testid="limits.bonusMNDE">

              <div *ngIf="!statesChangerService.isActive" class=""> {{ currentPartner.limits.bonusMNDE }}</div>

              <div class="editable-rec input-group w-full " *ngIf="statesChangerService.isActive">


                <div class="input-group ">

                  <span class="input-group-addon bootstrap-touchspin-prefix no-padder">
                      <label class="i-checks m-l-xs m-t-xs">
                          <input type="checkbox" class="ng-pristine ng-untouched ng-valid"
                                 [ngModel]="isIdEnabled('bonusMNDE')"
                                 (ngModelChange)="changeIdEnabled($event, 'bonusMNDE')"
                                 name="enabledBonusMNDE"
                          >
                          <i></i>
                      </label>
                  </span>

                  <input
                    type="number"
                    class="form-control text-center "
                    [ngModel]="calcK('bonusMNDE')"
                    (ngModelChange)="partnerForm.controls['bonusMNDE_K'].valid && changeK($event, 'bonusMNDE')"
                    name="bonusMNDE_K"
                    placeholder=""
                    [required]="true"
                    pattern="^\d$"
                    [disabled]="!isIdEnabled('bonusMNDE')"
                    [min]="1"
                    [max]="9"
                    min="1"
                    max="9"
                  >

                  <span class="input-group-addon bootstrap-touchspin-prefix"> x 10 ^ </span>

                  <input
                    type="number"
                    class="form-control text-center "
                    [ngModel]="calcN('bonusMNDE')"
                    (ngModelChange)="partnerForm.controls['bonusMNDE_N'].valid && changeN($event, 'bonusMNDE')"
                    name="bonusMNDE_N"
                    placeholder=""
                    [required]="true"
                    pattern="^-?\d$"
                    [disabled]="!isIdEnabled('bonusMNDE')"
                    [min]="-2"
                    [max]="9"
                    min="-2"
                    max="9"
                  >


                  <span class="input-group-addon bootstrap-touchspin-prefix"> = </span>

                  <input
                    type="text"
                    [disabled]="true"
                    class="form-control text-center"
                    [(ngModel)]="currentPartner.limits.bonusMNDE"
                    name="bonusMNDE"
                  >
                </div>


              </div>

            </div>

          </div>

          <div class="grid-item  grid-item_clean "></div>

          <div class="grid-item grid-item__form grid-item_full" *ngIf="checkBonusPermissions()">

            <div class="grid-item__label">
              Кросс-курс бонусов на списание
              <i class="fa fa-gear"></i>
            </div>

            <div class="grid-item__value" data-testid="limits.currencyToBonusRatio">

              <div *ngIf="!statesChangerService.isActive" class="">
                {{ currentPartner.limits.currencyToBonusRatio }} бонус{{ currentPartner.limits.currencyToBonusRatio == 1 ? '' : 'ов' }} = 1 <span
                ngCurrencyNameLoya></span></div>

              <div class="editable-rec input-group w-full " *ngIf="statesChangerService.isActive">

                <div class="input-group">

                  <input
                    type="number"
                    class="form-control text-center "
                    [ngModel]="calcK('currencyToBonusRatio')"
                    (ngModelChange)="partnerForm.controls['currencyToBonusRatio_K'].valid && changeK($event, 'currencyToBonusRatio')"
                    name="currencyToBonusRatio_K"
                    placeholder=""
                    [required]="true"
                    pattern="^\d$"
                    [disabled]="true"
                    [min]="1"
                    [max]="9"
                    min="1"
                    max="9"
                  >

                  <span class="input-group-addon "> x 10 ^ </span>

                  <input
                    type="number"
                    class="form-control text-center w-xl"
                    [ngModel]="calcN('currencyToBonusRatio')"
                    (ngModelChange)="partnerForm.controls['currencyToBonusRatio_N'].valid && changeN($event, 'currencyToBonusRatio')"
                    name="currencyToBonusRatio_N"
                    placeholder=""
                    [required]="true"
                    pattern="^-?\d$"
                    [min]="0"
                    [max]="3"
                    min="0"
                    max="3"

                  >

                  <span class="input-group-addon  w"> {{ currentPartner.limits.currencyToBonusRatio }} бонус{{ currentPartner.limits.currencyToBonusRatio == 1 ? '' : 'ов' }} = 1  <span
                    ngCurrencyNameLoya></span></span>

                </div>

              </div>

            </div>

          </div>

        </grid-container>
      </div>


      <div class="card-body m-r-m-15 col-sm-12 long-descriptions "
           style="height: auto;">


      </div>


    </form>

  </ng-container>

</partner-menu>

