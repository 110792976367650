<ng-select [(ngModel)]="id"
           [items]="filteredItems"
           [virtualScroll]="true"
           [loading]="loading"
           bindLabel="name"
           bindValue="id"
           placeholder="Шаблон информирования"
           name="selectInformingTemplateComponent"
           data-testId="campagin.templateInforming"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (search) = "onSearch($event)"
           (change) = "onChange()"
           (clear) = "clear()"
           (close) = "clear()"
           [disabled]="disabled"
           [required]="required"
           [class.ng-invalid]="!isValid"

>

  <ng-template ng-option-tmp let-item="item" let-index="index">
    {{item.name }}
  </ng-template>
</ng-select>

<input type="hidden" ng-required="!isValid" ng-init="dummy" ng-model="dummy">
