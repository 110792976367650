<div class="fixed-tool-panel ">

  <filter-date-period
    class="m-r-sm"
    (modelFromChange)="this.filterFrom = $event"
    (modelToChange)="this.filterTo = $event"
    (onChange)="onChangeFilers()"
    [maxPeriodMonth]="6"
    [requiredFrom]="true"
    [requiredTo]="true"
  ></filter-date-period>

  <filter-goods
    class="m-r-sm"
    [(model)]="filterGoods"
    (modelChange)="onChangeFilers()"
    [showCategories]="['sku']"
    [(partnerId)]="partnerId"
    [max]="10"
  ></filter-goods>

  <filter-locations
    [(model)]="filterLocations"
    (modelChange)="onChangeFilers()"
    [(partnerId)]="partnerId"
    class="m-r-sm"
  ></filter-locations>

  <filter-campaign
    [(model)]="filterCampaigns"
    (modelChange)="onChangeFilers()"
    [(partnerId)]="partnerId"
    [max]="1"
    [disabled]="isFilterEnabled('couponRules')"
    class="m-r-sm"
  ></filter-campaign>

  <filter-campaign-rule
    [(model)]="filterCampaignsRule"
    (modelChange)="onChangeFilers()"
    [(partnerId)]="partnerId"
    [max]="1"
    [disabled]="isFilterEnabled('campaigns')"
    class="m-r-sm"
  ></filter-campaign-rule>

</div>


<div class="padder">
  <div class="row m-t-lg"
       *ngIf="isReportPrepareStage()"
  >

    <div class="col-lg-8 col-md-10 col-sm-12  m-auto">
      <h4 class="text-muted m-t-lg"> Для построения отчёта необходимо задать следующие фильтры: </h4>
      <div class="clearfix m-t-md p-l-md">

        <div class="text-sm clearfix m-b-xs">
          <i class="fa m-r-xs" [ngClass]="{ 'fa-check-circle text-success': isFilterEnabled('dateFrom') && isFilterEnabled('dateTo'), 'fa-times-circle text-danger': !(isFilterEnabled('dateFrom') && isFilterEnabled('dateTo')) }"></i>
          Временной период
        </div>

        <div class="text-sm clearfix m-b-xs">
          <i class="fa m-r-xs" [ngClass]="{ 'fa-check-circle text-success': isFilterEnabled('sku'), 'fa-times-circle text-danger': !isFilterEnabled('sku') }"></i>
          Товары
        </div>

        <div class="text-sm clearfix m-b-xs">
          <i class="fa m-r-xs" [ngClass]="{ 'fa-check-circle text-success': isFilterEnabled('campaigns') || isFilterEnabled('couponRules'), 'fa-times-circle text-danger': !isFilterEnabled('campaigns') && !isFilterEnabled('couponRules') }"></i>
          Кампания или купонное правило
        </div>

        <div class="clearfix m-t-md m-b-xs"
             *ngIf="checkFilters() && !isEmptyTable()"
        >
          <button class="btn   btn-success"
                  (click)="buildReport()"
                  [disabled]="isLoading"
          >
            <i class="fa m-r-xs"
               [ngClass]="{ 'fa-gears ': !isLoading, 'fa-spin fa-spinner': isLoading}"
            ></i>
            <span class="btn-name">
              Построить отчёт
            </span>
          </button>

        </div>

      </div>

      <div class="clearfix text-center m-t-lg m-b-xs"
           *ngIf="isEmptyTable()"
      >

        <h4 class="text-muted m-t-lg"> Данные по отчёту отсутствуют </h4>

      </div>



    </div>

  </div>

  <div class="block clearfix w-full m-t scroll-x " *ngIf="hasTable()">

    <div class="report-table-wrapper" id="reportTableWrapper">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ isCouponRuleSelected() ? 'Купонное правило' : 'Кампания' }}</th>
            <th class="text-center">SKU</th>
            <th class="text-right">Скидка</th>
          </tr>
        </thead>

        <tbody>

        <ng-container
          *ngFor="let item of reportData?.rows"
        >
          <tr class="bg-light ">

            <td  class=" text-left  ">

                <a *ngIf="isCampaign(item)"
                  [uiSref]="'frontend.campaign.campaigns.view( {id:'+item.campaign?.id+'} )'"
                  [innerText]="item.campaign?.name || ('Кампания с ID:' + item.campaign?.id)"
                ></a>

                <a *ngIf="isCouponRule(item)"
                   [uiSref]="'frontend.coupons.rules.view( {id:'+item.campaign?.id+'} )'"
                   [innerText]="item.couponRule?.name || ('Купонное правило с ID:' + item.couponRule?.id)"
                ></a>

            </td>

            <td  class=" text-left  ">
             <span [innerText]="item.sku"></span>
            </td>

            <td  class=" text-right  font-weight-bold">
              <span [innerText]="item.discount"></span>
              <i class="fa m-l-xs" ngCurrencyLoya ></i>
            </td>

          </tr>

          <ng-container
            *ngIf="hasLocations(item)"
          >

            <tr *ngFor="let location of item.locations">
              <td colspan="2"  class=" text-right text-sm">
                <small>
                  <a *ngIf="isCampaign(item)"
                     [uiSref]="'frontend.config.directory.locations.view( {id:'+location?.locationId+', partnerId: '+ partnerId +'} )'"
                     [innerText]="location?.locationName || ('Локация с ID:' + location?.locationId)"
                  ></a>
                </small>
              </td>
              <td  class=" text-right text-sm">
                <small>
                  <span [innerText]="location.discount | loyaNumber"></span>
                  <i class="fa m-l-xs" ngCurrencyLoya ></i>
                </small>
              </td>
            </tr>

          </ng-container>

        </ng-container>

        </tbody>

      </table>
    </div>

  </div>



</div>
