import { QueryHandler } from './../../QueryHandler.class';
import { ResourceHandler} from './../../ngResourceHandler.class';

export const FILTER_FIELD = '$pager';

export interface IResourcePagerOptions {
    drop: string;
    limit: string;
    defaltLimit?: number;
    isObject?:boolean;
}

export interface IQueryPagerOptions {
    drop?:  number;
    limit?: number;
}

export class ngResourcePager extends ResourceHandler
{


    private isEnded = false;
    private dropIndex = 0;
    private limitIndex = 20;
    private options: any;

    private defaultOptions: IResourcePagerOptions = {

        drop: 'drop',
        limit: 'limit',
        isObject: false

    }

  static $inject = ['handler'];

    constructor(
        protected handler: QueryHandler
    ) {

        super(handler);
        this.options = ( <IResourcePagerOptions>  (<any>Object).assign({}, this.defaultOptions, this.handler.actions.pager) );

    }

    get field() {
        return FILTER_FIELD;
    }

    static canHandle( actions: any ) {
        return actions && !!actions.pager;
    }

    private setEnd = (endFlag: boolean) => this.isEnded = endFlag;

    private query = () => {
        if (this.isFinished) {
            return Promise.resolve();
        }

        return this.handler.query().$promise;
    };

    public getCurrentParams() {

        if ( this.limitIndex === -1 ) {
            return {};
        }

        if ( !this.options.isObject ) {

            return {
                [ this.options.drop  ] : this.dropIndex,
                [ this.options.limit ] : this.limitIndex
            };

        }

        let returnObj = {};

        returnObj = QueryHandler.setValueByPath( returnObj, this.options.drop , this.dropIndex );
        returnObj = QueryHandler.setValueByPath( returnObj, this.options.limit, this.limitIndex );

        return returnObj;

    };

    public setDefaultLimit = function (newLimit : any ) {
        this.limitIndex = newLimit;
    };

    public next = function (queryOptions : any ) {

        if ( this.isFinished ) {
            return Promise.resolve( this.handler.results );
        }
        if ( this.isPending ) {
            return;
        }
        if (queryOptions) {
            if (typeof queryOptions.drop !== 'undefined') {
                this.dropIndex = queryOptions.drop;
            }
            if (typeof queryOptions.limit !== 'undefined') {
                this.limitIndex = queryOptions.limit;
                this.dropIndex += this.limitIndex;
            }
            return this.query(queryOptions);
        }
        else {
            this.dropIndex += this.limitIndex;
            return this.query();
        }
    };

    public reset = function () {
        return this.handler.reset();
    };

    get isFinished() {
        return this.isEnded;
    }

    get drop() {
        return this.dropIndex;
    }

    get limit() {
        return this.limitIndex;
    }

    protected onStart(firstQueryOptions: Object = {}) {

        if ( typeof QueryHandler.getValueByPath( firstQueryOptions, this.options.drop ) !== "undefined" ) {
            this.dropIndex = QueryHandler.getValueByPath( firstQueryOptions, this.options.drop );
        } else if ( typeof firstQueryOptions[this.options.drop] !== "undefined") {
            this.dropIndex = firstQueryOptions[this.options.drop];
        }

        if ( typeof QueryHandler.getValueByPath( firstQueryOptions, this.options.limit ) !== "undefined" ) {
            this.setDefaultLimit(QueryHandler.getValueByPath( firstQueryOptions, this.options.limit ));
        } else if ( typeof firstQueryOptions[this.options.limit] !== "undefined") {
            this.setDefaultLimit( firstQueryOptions[this.options.limit] );
        } else {
            if (typeof this.options.defaltLimit !== "undefined") {
                this.setDefaultLimit(this.options.defaltLimit);
            }
        }

    };

    protected onUpdate( finish: Promise<any> ) {

        finish.then( (resultArray: any ) => {

            if (resultArray.length === 0) {
                this.setEnd(true);
                return;
            }

            if (resultArray.length < this.limitIndex) {

                if ( this.dropIndex < this.limitIndex ) {
                    this.dropIndex = resultArray.length;
                } else {
                    this.dropIndex -= this.limitIndex - resultArray.length;
                }

                if (  this.dropIndex < 0 )
                    this.dropIndex = 0;

                return;
            }

        });

        return this.getCurrentParams();

    };


    protected onReset() {
        this.dropIndex = 0;
        this.setEnd(false);
    };

}

