import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators"


@Injectable({
  providedIn: 'root',
})
export class RolesModelService {

  constructor( private httpClient: HttpClient ) {
  }

  public getUserRoles() {
    return this.httpClient
               .get('/roles', { responseType: "json" })
               .pipe(
                 map( (roles: any[]) => {
                   let res = {};
                   roles.forEach( item => res[item.id] = item.description );
                   return res;
                 })
               )
  }

}
