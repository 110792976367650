import {NgModule} from "@angular/core";
import {SearchInputComponent} from "./search-input.component";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {UpgradeModule} from "@angular/upgrade/static";


@NgModule({
  declarations: [
    SearchInputComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports:[
    SearchInputComponent,
  ]
})
export class SearchInputModule {

  constructor() {}

}
