'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.goodgroup.view';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:partnerId/:id')

        .template(   '<goodgroup-view-page [id]="id" [partner-id]="partnerId"></goodgroup-view-page>', true)
        .controller(['$scope', '$stateParams',
          function($scope, $stateParams) {
            $scope.id = $stateParams.id;
            $scope.partnerId = $stateParams.partnerId;
          } ])

        .breadcrumbs(  [ "Справочники", "Списки товаров", "Список товаров" ] );

    }])
;

