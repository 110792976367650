/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GoodGroupLinkApiConfiguration as __Configuration } from '../good-group-link-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodGroupAssignedGood } from '../models/good-group-assigned-good';
import { GoodLink } from '../models/good-link';
@Injectable({
  providedIn: 'root',
})
class GoodGroupLinkApiService extends __BaseService {
  static readonly queryGoodGroupLinksPath = '/goodgrouplink/{partnerId}/{id}';
  static readonly updatePath = '/goodgrouplink/{partnerId}/{id}';
  static readonly uploadPath = '/goodgrouplink/{partnerId}/{id}/upload';
  static readonly deleteListPath = '/goodgrouplink/{partnerId}/{id}/deleteList';
  static readonly deleteAllPath = '/goodgrouplink/{partnerId}/{id}/deleteAll';
  static readonly deletePath = '/goodgrouplink/{partnerId}/{id}/{sku}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка SKU, входящих в группу товаров
   *
   * Запрос списка SKU, входящих в группу товаров
   * @param params The `GoodGroupLinkApiService.QueryGoodGroupLinksParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `sku` - атрибут "SKU"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список SKU, входящих в группу товаров
   */
  queryGoodGroupLinksResponse(params: GoodGroupLinkApiService.QueryGoodGroupLinksParams): __Observable<__StrictHttpResponse<Array<GoodGroupAssignedGood>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoodGroupAssignedGood>>;
      })
    );
  }
  /**
   * Запрос списка SKU, входящих в группу товаров
   *
   * Запрос списка SKU, входящих в группу товаров
   * @param params The `GoodGroupLinkApiService.QueryGoodGroupLinksParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `sku` - атрибут "SKU"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список SKU, входящих в группу товаров
   */
  queryGoodGroupLinks(params: GoodGroupLinkApiService.QueryGoodGroupLinksParams): __Observable<Array<GoodGroupAssignedGood>> {
    return this.queryGoodGroupLinksResponse(params).pipe(
      __map(_r => _r.body as Array<GoodGroupAssignedGood>)
    );
  }

  /**
   * Привязка списка товаров к группе товаров
   * @param params The `GoodGroupLinkApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров для привязки
   *
   * - `id`: ID группы товаров
   */
  updateResponse(params: GoodGroupLinkApiService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязка списка товаров к группе товаров
   * @param params The `GoodGroupLinkApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров для привязки
   *
   * - `id`: ID группы товаров
   */
  update(params: GoodGroupLinkApiService.UpdateParams): __Observable<null> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Загрузить в группу товаров список товаров
   *
   * Загрузить в группу товаров список товаров
   * @param params The `GoodGroupLinkApiService.UploadParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров
   *
   * - `id`: ID группы товаров
   */
  uploadResponse(params: GoodGroupLinkApiService.UploadParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Загрузить в группу товаров список товаров
   *
   * Загрузить в группу товаров список товаров
   * @param params The `GoodGroupLinkApiService.UploadParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров
   *
   * - `id`: ID группы товаров
   */
  upload(params: GoodGroupLinkApiService.UploadParams): __Observable<null> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление списка товаров из группы товаров
   *
   * Удаление списка товаров из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров
   *
   * - `id`: ID группы товаров
   */
  deleteListResponse(params: GoodGroupLinkApiService.DeleteListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/deleteList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление списка товаров из группы товаров
   *
   * Удаление списка товаров из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список товаров
   *
   * - `id`: ID группы товаров
   */
  deleteList(params: GoodGroupLinkApiService.DeleteListParams): __Observable<null> {
    return this.deleteListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление всех товаров из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteAllParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  deleteAllResponse(params: GoodGroupLinkApiService.DeleteAllParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/deleteAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление всех товаров из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteAllParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  deleteAll(params: GoodGroupLinkApiService.DeleteAllParams): __Observable<null> {
    return this.deleteAllResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удалить товар из группы товаров
   *
   * Удалить товар из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  deleteResponse(params: GoodGroupLinkApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/goodgrouplink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить товар из группы товаров
   *
   * Удалить товар из группы товаров
   * @param params The `GoodGroupLinkApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: SKU товара
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  delete(params: GoodGroupLinkApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GoodGroupLinkApiService {

  /**
   * Parameters for queryGoodGroupLinks
   */
  export interface QueryGoodGroupLinksParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * Порядок сортировки:
     *   - `asc` - по увеличению
     *   - `desc` - по убыванию
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле (атрибут) сортировки списка:
     *   - `name` - атрибут "Наименование"
     *   - `sku` - атрибут "SKU"
     */
    sortingField?: 'name' | 'sku';

    /**
     * Сколько записей вернуть, по умолчанию - 50
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала списка, по умолчанию - 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список товаров для привязки
     */
    links: Array<GoodLink>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список товаров
     */
    links: Array<GoodLink>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for deleteList
   */
  export interface DeleteListParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список товаров
     */
    links: Array<GoodLink>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for deleteAll
   */
  export interface DeleteAllParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * SKU товара
     */
    sku: string;

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;
  }
}

export { GoodGroupLinkApiService }
