'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.locations.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id/:partnerId')

        .template(   '<locations-view-page [id]="id" [partner-id]="partnerId"></locations-view-page>', true)
        .controller(['$scope', '$stateParams',
          function($scope, $stateParams) {
            $scope.id = $stateParams.id;
            $scope.partnerId = $stateParams.partnerId;
          } ])

        .breadcrumbs(  [ "Справочники", "Точки продаж", "Точка продаж" ] );

    }])
;
