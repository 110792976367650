import {CouponKind, CouponWithBarcodeId, OnGoodRewType, RewardMeasureTypes, RewardWhatTypes} from "./CouponKind";
import {CustomPeriodTypes, ExternalId, PosId, UniqueCouponGenId} from "../IssuingTypes/IssuingType";
import {Utils} from "../utils.class";


export class CouponWithBarcode extends CouponKind {

    static id = CouponWithBarcodeId;
    protected customPeriodTypes = [ CustomPeriodTypes.CLIENT_BIRTHDAY ];

    protected prefix = true;

    get hasSameClientCount() : boolean {
        return this.issuingType.getId() === PosId || this.issuingType.getId() === ExternalId ;
    }

    get hasCanBeSold() : boolean {
        return this.issuingType.getId() === ExternalId;
    }


    get hasUsable3rdParties() : boolean {
        return [PosId,ExternalId].indexOf( this.issuingType.getId() ) >=0 ;
    }

    get isUniqueCouponGen() : boolean {
        return this.issuingType.getId() === UniqueCouponGenId;
    }

    public getRewardWhat(): Array<RewardWhatTypes> {


        this.rewardWhatTypes.splice(0, this.rewardWhatTypes.length);
        this.rewardWhatTypes.push(RewardWhatTypes.DISCOUNT);
        this.rewardWhatTypes.push(RewardWhatTypes.BONUS);

        if ( this.issuingType.getId() !== PosId ) {
            this.rewardWhatTypes.push(RewardWhatTypes.GIFT);
            return super.getRewardWhat();
        }

        if ( Utils.isReardGoodType(this.ruleItem, "count") ) {
            this.rewardWhatTypes.push(RewardWhatTypes.FIXPRICE);
        } else {
            this.rewardWhatTypes.push(RewardWhatTypes.GIFT);
            this.rewardWhatTypes.push( RewardWhatTypes.FIXPRICE );
        }

        return super.getRewardWhat();
    }

    public getOnGoodRewType(): Array<OnGoodRewType> {

        if ( this.issuingType.getId() === PosId && this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ) < 0 ) {
            this.onGoodRewTypes.push(OnGoodRewType.COUNT)
        }

        if ( this.issuingType.getId() !== PosId && this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ) >= 0 ) {
            this.onGoodRewTypes.splice(this.onGoodRewTypes.indexOf( OnGoodRewType.COUNT ), 1);
        }

        return super.getOnGoodRewType();
    }

    public getRewardMeasureList(): Array<RewardMeasureTypes> {

        let list = super.getRewardMeasureList();
        if ( !Utils.isReardGoodType(this.ruleItem, "count") )
            return list;

        if ( [RewardWhatTypes.DISCOUNT, RewardWhatTypes.BONUS].indexOf( Utils.getField(this.ruleItem, "couponRule.reward.reward.what") ) >=0 ) {
            return list.filter( i => i === RewardMeasureTypes.PERCENT);
        }

        return list;
    }

}
