'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";

let routePath   = module.name + '.system.modules.index';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider

            .route( routePath )
            .template( '<system-modules-index-page></system-modules-index-page>', true)

            .breadcrumbs( [ "Настройки", "Компоненты" ] )

            .permissions( {
              only: ['Administrator'],
            })

        }]);
