import {IssuingType, StartPeriodTypes, StopPeriodTypes, UniqueCouponGenId} from "./IssuingType";
import {CouponWithBarcode} from "../couponKinds/CouponWithBarcode";



export class UniqueCouponGen extends IssuingType{

    static id = UniqueCouponGenId;
    static couponKindsArray  = [
        CouponWithBarcode
    ];

    public startPeriodArray = [
        StartPeriodTypes.START_PERIOD_FORM_DATE
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE
    ];

}