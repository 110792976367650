'use strict';

import 'model/TriggersModel/resource';

import module from 'frontendModule';
import {UserSettingsStorage} from "class/user-settings-storage.service";
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.triggers.trigger.index';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/triggers.trigger.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/triggers.trigger.index" */ "./controllers"       ))

                .breadcrumbs([ "Цепочки", "Цепочки" ])

                .resolve("initFilter", () =>
                  UserSettingsStorage.get('triggers.trigger.index.filter')
                    .then(value => {
                      try{
                        return JSON.parse(value);
                      } catch (e) {
                        return {}
                      }
                    })
                    .catch(() => ({}) )
                )

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
                    permit: 'triggers'
                })

        }]);
