export const LIMITATION_TYPES_VALUES = {
  'discount' : 'discount',
  'bonus' : 'bonus',
  'numberOfTriggering' : 'numberOfTriggering'
}

export const PERIOD_TYPES_VALUES = {
  'period' : 'period',
  'days'   : 'days',
  'week'   : 'week',
  'month'  : 'month',
  'year'   : 'year',
  'allTheTime' : 'allTheTime',
}
