/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { DiscountOnPurchaseApiConfiguration as __Configuration } from '../discount-on-purchase-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ActiveCounter } from '../models/active-counter';
import { CounterState } from '../models/counter-state';
@Injectable({
  providedIn: 'root',
})
class DiscountOnPurchaseApiService extends __BaseService {
  static readonly getAllActiveCountersPath = '/clientprofile/discount-on-purchase/{clientId}';
  static readonly getCounterTracePath = '/discount-on-purchase/getTrace';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Профиль клиента (Активные счетчики)
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getAllActiveCountersResponse(clientId: number): __Observable<__StrictHttpResponse<Array<ActiveCounter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientprofile/discount-on-purchase/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActiveCounter>>;
      })
    );
  }
  /**
   * Профиль клиента (Активные счетчики)
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getAllActiveCounters(clientId: number): __Observable<Array<ActiveCounter>> {
    return this.getAllActiveCountersResponse(clientId).pipe(
      __map(_r => _r.body as Array<ActiveCounter>)
    );
  }

  /**
   * Изменение счетчика по клиенту и кампании
   * @param params The `DiscountOnPurchaseApiService.GetCounterTraceParams` containing the following parameters:
   *
   * - `locationId`: ID магазина
   *
   * - `limit`: Сколько записей выдать
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `clientId`: ID клиента
   *
   * - `campaignId`: ID кампании
   *
   * @return Журнал изменений счетчика
   */
  getCounterTraceResponse(params: DiscountOnPurchaseApiService.GetCounterTraceParams): __Observable<__StrictHttpResponse<Array<CounterState>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    if (params.campaignId != null) __params = __params.set('campaignId', params.campaignId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/discount-on-purchase/getTrace`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CounterState>>;
      })
    );
  }
  /**
   * Изменение счетчика по клиенту и кампании
   * @param params The `DiscountOnPurchaseApiService.GetCounterTraceParams` containing the following parameters:
   *
   * - `locationId`: ID магазина
   *
   * - `limit`: Сколько записей выдать
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `clientId`: ID клиента
   *
   * - `campaignId`: ID кампании
   *
   * @return Журнал изменений счетчика
   */
  getCounterTrace(params: DiscountOnPurchaseApiService.GetCounterTraceParams): __Observable<Array<CounterState>> {
    return this.getCounterTraceResponse(params).pipe(
      __map(_r => _r.body as Array<CounterState>)
    );
  }
}

module DiscountOnPurchaseApiService {

  /**
   * Parameters for getCounterTrace
   */
  export interface GetCounterTraceParams {

    /**
     * ID магазина
     */
    locationId?: number;

    /**
     * Сколько записей выдать
     */
    limit?: number;

    /**
     * Сколько записей пропустить от начала списка
     */
    drop?: number;

    /**
     * ID клиента
     */
    clientId?: number;

    /**
     * ID кампании
     */
    campaignId?: number;
  }
}

export { DiscountOnPurchaseApiService }
