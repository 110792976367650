/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FavoriteGoodsByPriorityApiConfiguration as __Configuration } from '../favorite-goods-by-priority-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Record } from '../models/record';
@Injectable({
  providedIn: 'root',
})
class FavoriteGoodsByPriorityApiService extends __BaseService {
  static readonly getByClientPath = '/{clientId}';
  static readonly updateProfilePath = '/{clientId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param clientId ID клиента
   */
  getByClientResponse(clientId: number): __Observable<__StrictHttpResponse<Array<Record>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Record>>;
      })
    );
  }
  /**
   * @param clientId ID клиента
   */
  getByClient(clientId: number): __Observable<Array<Record>> {
    return this.getByClientResponse(clientId).pipe(
      __map(_r => _r.body as Array<Record>)
    );
  }

  /**
   * @param params The `FavoriteGoodsByPriorityApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `clientId`: ID клиента
   */
  updateProfileResponse(params: FavoriteGoodsByPriorityApiService.UpdateProfileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `FavoriteGoodsByPriorityApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `clientId`: ID клиента
   */
  updateProfile(params: FavoriteGoodsByPriorityApiService.UpdateProfileParams): __Observable<null> {
    return this.updateProfileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FavoriteGoodsByPriorityApiService {

  /**
   * Parameters for updateProfile
   */
  export interface UpdateProfileParams {
    params: Array<{campaignId: number, orderedList: Array<string>}>;

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { FavoriteGoodsByPriorityApiService }
