import {Component, Input, OnInit} from "@angular/core";

interface TransactionResult {
    awardBonus     ?: number,
    awardDibs      ?: number,
    spentDibs      ?: number,
    awardCoupons   ?: number,
    bonusStartDate ?: string,
    bonusEndDate   ?: string,
    discount       ?: string,
    couponUsed     ?: number,
    couponIssued   ?: number,
    dibsUsed       ?: number,
    dibsIssued     ?: number,
    giftList       ?: Array<any>
    isDiscountOnChange ?: boolean,
    partner        : {
        id: number,
        name: string,
        description: string
    },
    campaign        ?: any,
    coupon          ?: any,
    referalCoupon   ?: any,
    description: string,
    id: number,
    name: string
}

@Component({
  selector: 'receit-details-item',
  template: `
<div class="row"
     *ngIf="isItemsArray()"
>
    <div class="col-lg-4 col-md-6 col-sm-12 "
        *ngFor="let result of items; let $index = index"
        data-testid="let result of items"
    >
        <div class="panel b-a m-b-xs"  data-testid="blockAward">
            <div class="card-header no-padder b-b b-light hbox hbox-auto" data-testid="result.blockAwardName">

                <div class="col b-r text-muted v-middle  p-l-sm p-r-sm"
                    *ngIf="!isRewardByPartner(result)">
                    <i class="fa  text-muted"
                        [ngClass]="{ 'fa-rocket' : isResultByCapaign(result), 'fa-barcode' : isResultByCoupon(result), 'fa-gift': isReferalCoupon(result) }"
                    ></i>
                </div>

                <div class="col w-full v-middle wrapper-xs f-s-9 p-l-sm p-r-sm text-break"
                >

                    <span *ngIf="isResultByCapaign(result)"
                          ngbPopover="{{ result.campaign.description }}"
                          triggers="mouseenter:mouseleave">
                        <a class=" pointer"
                           [href]="'/#!/campaign/campaigns/view/'+result.campaign.id"
                           [innerText]=" result.campaign.name || ''"
                           data-testid="result.campaign.name"></a>
                    </span>


                    <span *ngIf="isResultByCoupon(result) && isResultCouponExist(result)">
                         <a class=" pointer"
                            [href]="'/#!/coupons/coupons/view/' + result.coupon.couponId + '/'"
                         >

                            <span class="text-info-dk clearfix"
                                [innerText]="result.coupon.name || '' "data-testid="result.coupon.name"></span>
                            <span class="text-info-dk clearfix"
                                [innerText]="result.coupon.couponNumber || ''" data-testid="result.coupon.couponNumber"></span>
                        </a>
                    </span>

                    <span *ngIf="isResultByCoupon(result) && !isResultCouponExist(result)">
                         <a class=" pointer"
                            [href]="'/#!/coupons/rules/view/' + result.coupon.couponRuleId"
                         >

                            <span class="text-info-dk clearfix"
                                [innerText]="result.coupon.name || ''"></span>
                            <span class="text-info-dk clearfix"
                                [innerText]="result.coupon.couponNumber || ''"></span>
                        </a>
                    </span>

                    <span *ngIf="isDiscountOnChange(result)" >
                        Скидка на мелочь
                    </span>

                    <span *ngIf="isRewardByPartner(result)">
                        Базовые начисления
                    </span>

                    <!--span *ngIf="isReferalCoupon(result)"
                        class="text-muted f-s-8 clearfix">
                            <div class="clearfix"></div>
                            <i class="fa fa-gift text-success inline"></i>
                            Реферальный купон

                    </span-->

                </div>

                <div class="col text-ellipsis text-right text-muted f-s-9 v-middle wrapper-xs b-l p-l-sm p-r-sm "
                *ngIf="!!result?.partner?.name"
                     ngbPopover="{{ result?.partner?.description }}"
                     triggers="mouseenter:mouseleave"
                >

                    <span [innerText]=" result.partner.name || ''" data-testid="result.partner.name"></span>

                </div>


            </div>

            <div class="card-body no-padder" data-testid="result.blockAwardDesc">

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                    *ngIf="getDiscount(result)"
                >
                    <div class="w-full no-border  hbox hbox-auto">
                        <div class="col w-full ">
                            Скидка
                        </div>
                        <div class="col text-right text-ellipsis">
                            <span [innerText]="getDiscount(result) | loyaCurrency" data-testid="result.discount"></span>
                            <i class="fa le m-l-xs" ngCurrencyLoya ></i>
                        </div>
                    </div>

                </div>

                <span *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
                  <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                      *ngIf="getAwardBonus(result)"
                  >
                      <div class="w-full no-border  hbox hbox-auto">
                          <div class="col w-full ">
                              Выдано бонусов
                          </div>
                          <div class="col text-right text-ellipsis">
                              <span  [innerText]="getAwardBonus(result) | loyaNumber:false" data-testid="result.awardBonus"></span> шт.
                          </div>
                      </div>
                  </div>

                  <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                       *ngIf="getAwardBonus(result) && getStartDateBonus(result)"
                  >
                      <div class="w-full no-border  hbox hbox-auto">
                          <div class="col w-full ">
                              Начислятся с
                          </div>
                          <div class="col text-right text-ellipsis">
                              <span  [innerText]="getStartDateBonus(result)  | loyaDate" data-testid="result.startDateBonus"></span>
                          </div>
                      </div>
                  </div>

                  <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                       *ngIf="getAwardBonus(result) && getStopDateBonus(result)"
                  >
                      <div class="w-full no-border hbox hbox-auto"  >
                          <div class="col w-full ">
                              Сгорят
                          </div>
                          <div class="col text-right text-ellipsis">
                              <span  [innerText]="getStopDateBonus(result)  | loyaDate" data-testid="result.stopDateBonus"></span>
                          </div>
                      </div>
                  </div>
                </span>
                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                     *ngIf="getAwardDibs(result)"
                >
                    <div class="w-full  no-border  hbox hbox-auto">
                        <div class="col w-full ">
                            Выдано фишек
                        </div>
                        <div class="col text-right text-ellipsis">
                            <span [innerText]="getAwardDibs(result) | loyaNumber:false" data-testid="result.awardDibs"></span> шт.
                        </div>
                    </div>
                </div>

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                      *ngIf="getDibsUsed(result)"
                >
                    <div class="w-full  no-border  hbox hbox-auto">
                        <div class="col w-full ">
                            Использовано фишек
                        </div>
                        <div class="col text-right text-ellipsis"  >
                             <span [innerText]="getDibsUsed(result) | loyaNumber:false" data-testid="result.usedDibs"></span> шт.
                        </div>
                    </div>
                </div>

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                      *ngIf="getDibsIssued(result)"
                >
                    <div class="w-full no-border hbox hbox-auto">
                        <div class="col w-full ">
                            Выдано фишек
                        </div>
                        <div class="col text-right text-ellipsis">
                            <span [innerText]="getDibsIssued(result) | loyaNumber:false" data-testid="result.issuedDibs"></span> шт.
                        </div>
                    </div>
                </div>

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                    *ngIf="getSpentDibs(result)"
                >
                    <div class="w-full no-border reward-item hbox hbox-auto">
                        <div class="col w-full ">
                            Использовано фишек
                        </div>
                        <div class="col text-right text-ellipsis"  >
                             <span [innerText]="getSpentDibs(result) | loyaNumber:false" data-testid="result.spentDibs"></span> шт.
                        </div>
                    </div>
                </div>

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                    *ngIf="getCouponUsed(result)"
                >
                    <div class="w-full no-border reward-item hbox hbox-auto">
                        <div class="col w-full ">
                            Использовано купонов
                        </div>
                        <div class="col text-right text-ellipsis">
                            <span [innerText]="getCouponUsed(result) | loyaNumber:false" data-testid="result.usedCoupons"></span> шт.
                        </div>
                    </div>
                </div>

                <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item " data-testid="descAward"
                    *ngIf="getCouponIssued(result)"
                >
                    <div class="w-full no-border hbox hbox-auto">
                        <div class="col w-full ">
                            Выдано купонов
                        </div>
                        <div class="col text-right text-ellipsis"  >
                             <span [innerText]="getCouponIssued(result) | loyaNumber:false" data-testid="result.issuedCoupons"></span> шт.
                        </div>
                    </div>
                </div>

                 <div class="w-full padder  wrapper-xs b-t no-border b-dashed reward-item" data-testid="descAward"
                    *ngIf="getGiftList(result)"
                >
                    <div class="w-full no-border  hbox hbox-auto">
                        <div class="col w-full ">
                            Выдано подарков
                        </div>
                        <div class="col text-right text-ellipsis">
                            <span [innerText]="getGiftQuantityFromUsedAwards(result) | loyaNumber:false" data-testid="result.awardGift"></span> шт.
                        </div>
                    </div>

                </div>
                <div class="w-full padder   wrapper-xs b-t no-border b-dashed reward-item"
                     *ngIf="getGiftList(result) && getGiftList(result)[0]  && getGiftList(result)[0].skuList"
                >
                  <div class="w-full no-border  hbox hbox-auto m-b-xxs"
                       *ngFor="let item of getGiftList(result)[0].skuList"
                       data-testid="let item of giftList"
                  >
                    <div class="col w-full ">

                      <ng-container
                        *ngIf="!!item.name"
                      >
                        <span [innerText]="item.name"></span>
                        <div class="f-s-5 text-muted" [innerText]="item.sku"></div>
                      </ng-container>

                      <ng-container
                        *ngIf="!item.name"
                      >
                        <span class="text-muted mr-1">SKU:</span>
                        <span [innerText]="item.sku"></span>
                      </ng-container>

                    </div>
                    <div class="col text-right text-ellipsis">
                      <span [innerText]="item.price | loyaCurrency"></span> <i class="fa le m-l-xs" ngCurrencyLoya ></i>
                    </div>
                  </div>

                </div>

                <div class="full padder  wrapper-xs b-t no-border b-dashed reward-item text-center text-muted "
                     *ngIf="!hasResultAwards(result)"
                >
                    <span>Нет вознаграждений</span>
                </div>

            </div>

        </div>
    </div>
</div>
`,
  styleUrls: ['./receit-details-item.component.scss'],

})
export class ReceitDetailsItemComponent implements OnInit{

    @Input() public items : Array<any> = [];
    @Input() public usedAwards : any = false;

    constructor( ) {
    }

    public ngOnInit() {
    }

    public isItemsArray() {
      return Array.isArray(this.items);
    }

    public getDiscount( result :TransactionResult ) {
        return result.discount && result.discount !== '0.00' ? result.discount : false;
    }

    public getAwardBonus( result :TransactionResult ) {
        return result.awardBonus && result.awardBonus !== 0 ? result.awardBonus : false;
    }

    public getAwardCoupons( result :TransactionResult ) {
        return result.awardCoupons && result.awardCoupons !== 0 ? result.awardCoupons : false;
    }

    public getAwardDibs( result :TransactionResult ) {
        return result.awardDibs && result.awardDibs !== 0 ? result.awardDibs : false;
    }

    public getSpentDibs( result :TransactionResult ) {
        return result.spentDibs && result.spentDibs !== 0 ? result.spentDibs : false;
    }


    public getCouponUsed( result :TransactionResult ) {
        return result.couponUsed && result.couponUsed !== 0 ? result.couponUsed : false;
    }

    public getCouponIssued( result :TransactionResult ) {
        return result.couponIssued && result.couponIssued !== 0 ? result.couponIssued : false;
    }

    public getDibsUsed( result :TransactionResult ) {
        return result.dibsUsed && result.dibsUsed !== 0 ? result.dibsUsed : false;
    }

    public getDibsIssued( result :TransactionResult ) {
        return result.dibsIssued && result.dibsIssued !== 0 ? result.dibsIssued : false;
    }

    public getGiftList( result :TransactionResult ) {
        return Array.isArray(result.giftList) && result.giftList.length !== 0 ? result.giftList : false;
    }

    public getGiftQuantityFromUsedAwards( result :TransactionResult ) {

      if (this.usedAwards )
        if ( Array.isArray(this.usedAwards.usedGiftList) &&
          Array.isArray(result.giftList) && result.giftList.length !== 0 ) {
          let finded = this.usedAwards.usedGiftList.find( i=> i.campaignId === result.giftList[0].campaignId && i.listId === result.giftList[0].listId );
          if (finded) {
            return finded.quantity;
          }
        } else {
          return 0;
        }

      return Array.isArray(result.giftList) && result.giftList.length !== 0 ? result.giftList[0].quantity : false;
    }

    public getStartDateBonus( result :TransactionResult ) {
        return result.bonusStartDate ? result.bonusStartDate : false;
    }

    public getStopDateBonus( result :TransactionResult ) {
        return result.bonusEndDate ? result.bonusEndDate : false;
    }

    public hasResultAwards( result :TransactionResult ) {

        return this.getDiscount(   result ) !== false
            || this.getAwardBonus( result ) !== false
            || this.getAwardDibs(  result ) !== false
            || this.getSpentDibs(  result ) !== false
            || this.getAwardCoupons( result ) !== false
            || this.getCouponUsed( result ) !== false
            || this.getCouponIssued( result ) !== false
            || this.getDibsUsed( result ) !== false
            || this.getDibsIssued( result ) !== false
            || this.getGiftList( result ) !== false;

    }

    public isResultByCapaign( result :TransactionResult ) {
        return !!result.campaign
    }

    public isResultByCoupon( result :TransactionResult ) {
        return !!result.coupon
    }

    public isResultCouponExist( result :TransactionResult ) {
        return !!result.coupon.couponId
    }

    public isDiscountOnChange(result :TransactionResult ) {
        return !!result.isDiscountOnChange
    }

    public isReferalCoupon( result :TransactionResult ) {
        return !!result.referalCoupon
    }

    public isRewardByPartner(result :TransactionResult ) {
        return !this.isResultByCapaign(result)
            && !this.isResultByCoupon(result)
            && !this.isDiscountOnChange(result);
    }
}
