'use strict';

import module from 'frontendModule';
let routePath   = module.name + '.config.directory.campaigncategory.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<campaigncategory-index-page></campaigncategory-index-page>', true)
        .breadcrumbs( [ "Справочники", "Группы кампаний" ] );



    }]);
