<div class="d-flex align-items-stretch b-t">
  <div class="col">

    <tree-view
      [nodes]="tree"
      [showType]="showType"
      (getChildren)="getChildren($event)"
      [(selected)]="selected"

      [searchQuery]="searchQuery"
      (getNext)="getNext($event)"
      [selectOne]="selectOne"
      [editCallback]="editCallback"
      [isLoading]="isLoading"
    ></tree-view>

  </div>

  <div class="col b-l "
       *ngIf="(!!selected.size && !isEditMode) || isSearchState "
  >

    <selected-view
      [partnerId]="partnerId"
      [showType]="showType"
      [(selected)]="selected"

      [goodList] = "goodList"
      [searchQuery]="searchQuery"
      [selectOne]="selectOne"
      [editCallback]="editCallback"
    ></selected-view>

  </div>



</div>
