/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CampaignLimitationApiConfiguration as __Configuration } from '../campaign-limitation-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CampaignLimitation } from '../models/campaign-limitation';
import { CampaignLimitationQueryParam } from '../models/campaign-limitation-query-param';
@Injectable({
  providedIn: 'root',
})
class CampaignLimitationApiService extends __BaseService {
  static readonly queryCampaignLimitationPath = '/campaign-limitation';
  static readonly createCampaignLimitationPath = '/campaign-limitation';
  static readonly getCampaignLimitationByIdPath = '/campaign-limitation/{id}';
  static readonly updateCampaignLimitationByIdPath = '/campaign-limitation/{id}';
  static readonly deleteCampaignLimitationByIdPath = '/campaign-limitation/{id}';
  static readonly bindToCampaignLimitationPath = '/campaign-limitation/{id}/bind';
  static readonly unbindToCampaignLimitationPath = '/campaign-limitation/{id}/unbind';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка ограничений вознаграждений
   * @param params Параметры запроса
   * @return Список всех ограничений вознаграждений
   */
  queryCampaignLimitationResponse(params?: CampaignLimitationQueryParam): __Observable<__StrictHttpResponse<Array<CampaignLimitation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaign-limitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignLimitation>>;
      })
    );
  }
  /**
   * Запрос списка ограничений вознаграждений
   * @param params Параметры запроса
   * @return Список всех ограничений вознаграждений
   */
  queryCampaignLimitation(params?: CampaignLimitationQueryParam): __Observable<Array<CampaignLimitation>> {
    return this.queryCampaignLimitationResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignLimitation>)
    );
  }

  /**
   * Создание ограничения вознаграждений
   * @param limitation Параметры ограничения вознаграждений
   * @return Созданное ограничение вознаграждений
   */
  createCampaignLimitationResponse(limitation: CampaignLimitation): __Observable<__StrictHttpResponse<CampaignLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = limitation;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaign-limitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignLimitation>;
      })
    );
  }
  /**
   * Создание ограничения вознаграждений
   * @param limitation Параметры ограничения вознаграждений
   * @return Созданное ограничение вознаграждений
   */
  createCampaignLimitation(limitation: CampaignLimitation): __Observable<CampaignLimitation> {
    return this.createCampaignLimitationResponse(limitation).pipe(
      __map(_r => _r.body as CampaignLimitation)
    );
  }

  /**
   * Найти ограничение вознагражений по ID
   * @param id ID ограничения
   * @return Ограничение вознаграждений
   */
  getCampaignLimitationByIdResponse(id: number): __Observable<__StrictHttpResponse<CampaignLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaign-limitation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignLimitation>;
      })
    );
  }
  /**
   * Найти ограничение вознагражений по ID
   * @param id ID ограничения
   * @return Ограничение вознаграждений
   */
  getCampaignLimitationById(id: number): __Observable<CampaignLimitation> {
    return this.getCampaignLimitationByIdResponse(id).pipe(
      __map(_r => _r.body as CampaignLimitation)
    );
  }

  /**
   * Изменить ограничение вознагражений по ID
   * @param params The `CampaignLimitationApiService.UpdateCampaignLimitationByIdParams` containing the following parameters:
   *
   * - `trigger`: Параметры ограничения вознаграждений
   *
   * - `id`: ID ограничения
   *
   * @return Ограничение вознаграждений
   */
  updateCampaignLimitationByIdResponse(params: CampaignLimitationApiService.UpdateCampaignLimitationByIdParams): __Observable<__StrictHttpResponse<CampaignLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.trigger;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaign-limitation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignLimitation>;
      })
    );
  }
  /**
   * Изменить ограничение вознагражений по ID
   * @param params The `CampaignLimitationApiService.UpdateCampaignLimitationByIdParams` containing the following parameters:
   *
   * - `trigger`: Параметры ограничения вознаграждений
   *
   * - `id`: ID ограничения
   *
   * @return Ограничение вознаграждений
   */
  updateCampaignLimitationById(params: CampaignLimitationApiService.UpdateCampaignLimitationByIdParams): __Observable<CampaignLimitation> {
    return this.updateCampaignLimitationByIdResponse(params).pipe(
      __map(_r => _r.body as CampaignLimitation)
    );
  }

  /**
   * Удалить ограничение вознагражений по ID
   * @param id ID ограничения
   */
  deleteCampaignLimitationByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/campaign-limitation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить ограничение вознагражений по ID
   * @param id ID ограничения
   */
  deleteCampaignLimitationById(id: number): __Observable<null> {
    return this.deleteCampaignLimitationByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Привязать кампании к ограничению вознаграждений
   * @param params The `CampaignLimitationApiService.BindToCampaignLimitationParams` containing the following parameters:
   *
   * - `id`: ID ограничения
   *
   * - `campaignIds`: Список ID привязываемых кампаний
   */
  bindToCampaignLimitationResponse(params: CampaignLimitationApiService.BindToCampaignLimitationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.campaignIds;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaign-limitation/${encodeURIComponent(String(params.id))}/bind`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязать кампании к ограничению вознаграждений
   * @param params The `CampaignLimitationApiService.BindToCampaignLimitationParams` containing the following parameters:
   *
   * - `id`: ID ограничения
   *
   * - `campaignIds`: Список ID привязываемых кампаний
   */
  bindToCampaignLimitation(params: CampaignLimitationApiService.BindToCampaignLimitationParams): __Observable<null> {
    return this.bindToCampaignLimitationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Отвязать кампании к ограничению вознаграждений
   * @param params The `CampaignLimitationApiService.UnbindToCampaignLimitationParams` containing the following parameters:
   *
   * - `id`: ID ограничения
   *
   * - `campaignIds`: Список ID отвязываемых кампаний
   */
  unbindToCampaignLimitationResponse(params: CampaignLimitationApiService.UnbindToCampaignLimitationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.campaignIds;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaign-limitation/${encodeURIComponent(String(params.id))}/unbind`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Отвязать кампании к ограничению вознаграждений
   * @param params The `CampaignLimitationApiService.UnbindToCampaignLimitationParams` containing the following parameters:
   *
   * - `id`: ID ограничения
   *
   * - `campaignIds`: Список ID отвязываемых кампаний
   */
  unbindToCampaignLimitation(params: CampaignLimitationApiService.UnbindToCampaignLimitationParams): __Observable<null> {
    return this.unbindToCampaignLimitationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CampaignLimitationApiService {

  /**
   * Parameters for updateCampaignLimitationById
   */
  export interface UpdateCampaignLimitationByIdParams {

    /**
     * Параметры ограничения вознаграждений
     */
    trigger: CampaignLimitation;

    /**
     * ID ограничения
     */
    id: number;
  }

  /**
   * Parameters for bindToCampaignLimitation
   */
  export interface BindToCampaignLimitationParams {

    /**
     * ID ограничения
     */
    id: number;

    /**
     * Список ID привязываемых кампаний
     */
    campaignIds: Array<number>;
  }

  /**
   * Parameters for unbindToCampaignLimitation
   */
  export interface UnbindToCampaignLimitationParams {

    /**
     * ID ограничения
     */
    id: number;

    /**
     * Список ID отвязываемых кампаний
     */
    campaignIds: Array<number>;
  }
}

export { CampaignLimitationApiService }
