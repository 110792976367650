import {Injectable} from "@angular/core";
import {SearchCheckApiService} from "../../../../api/SearchCheckApi/services/search-check-api.service";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {CardApiService} from "../../../../api/CardApi/services/card-api.service";
import {of} from "rxjs";
import {SearchCheckQueryParam} from "../../../../api/SearchCheckApi/models/search-check-query-param";
import {CheckFilterListType} from "../../../../api/SearchCheckApi/models/check-filter-list-type";

@Injectable({
  providedIn: 'root',
})
export class SearchCheckService {

  constructor( private searchCheckApiService:SearchCheckApiService ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    filters: [
      {
        field: 'filter.checkId',
        id: 'checkId',
        type: "object",
        currentValue: undefined
      },
      {
        field: 'filter.dateFrom',
        id: 'dateFrom',
        type: "object",
        currentValue: undefined
      },
      {
        field: 'filter.dateTo',
        id: 'dateTo',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupsList',
        id: 'groupsList',
        currentValue: undefined
      },
      {
        field: 'filter.categoryList',
        id: 'categoryList',
        currentValue: undefined
      },
      {
        field: 'filter.listType',
        id: 'listType',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.locations',
        id: 'locations',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.maxTotal',
        id: 'maxTotal',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.minTotal',
        id: 'minTotal',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.partnerId',
        type: 'object',
        id: 'partnerId',
        currentValue: undefined
      },
      {
        field: 'filter.referalCoupons',
        id: 'referalCoupons',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.skuList',
        id: 'skuList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.timeFrom',
        id: 'timeFrom',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.timeTo',
        id: 'timeTo',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public query$( params: SearchCheckQueryParam ) {
    return this.searchCheckApiService.query(params)
  }

}
