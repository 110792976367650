import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {TriggersService} from "../../../../shared/service/api/Triggers/Triggers.service";
import {ActionClient} from "../../../../api/TriggersApi/models/action-client";

@Component({
  selector: 'triggers-transactions-page',
  templateUrl: './triggers-transactions-page.component.html',
  providers: [
    TriggersService
  ]
})
export class TriggersTransactionsPageComponent implements OnInit, OnDestroy{

  @Input() id: number;
  @Input() action: string;
  listClients:  ActionClient[] = [];


  constructor(
     private triggersService:TriggersService
  ) {
  }


  ngOnInit() {
    this.triggersService.getActionTransactionsParams.reset();
    this.getList(this.triggersService.getActionTransactionsParams.params);
  }

  getList(params) {
    this.triggersService.getActionTransactions$(this.id, this.action, params)
      .subscribe( result => {
        this.listClients = this.listClients.concat(result);
      });
  }


  onScroll() {
    this.triggersService.getActionTransactionsParams.next();
    this.getList(this.triggersService.getActionTransactionsParams.params)
  }

  ngOnDestroy() {

  }

  /*
  ngOnInit() {
    this.triggersService.getActionTransactions$(this.id, this.action, {drop:0, limit: 20})
      .subscribe(result => {
        this.listClients = result;
         [
          {
            birthday: new Date().toUTCString(),
            cards: [{ id:1, number:"123123" }, {id:2, number:"33333"}],
            createdAt: new Date().toUTCString(),
            email: "asd@asd.dd",
            id:1,
            name:"adadasdas",
            patronymic: "asdasdasd",
            surname: "asdasdasd",
            phone: "131231231231"
          }
        ]
      });*/
  /*  this.campaignCategoryService.getList$()
      .subscribe( result => {
        this.listCampaignCategory = result;
      });
  }
*/


}
