/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for TriggersApi services
 */
@Injectable({
  providedIn: 'root',
})
export class TriggersApiConfiguration {
  rootUrl: string = location.origin;
}

export interface TriggersApiConfigurationInterface {
  rootUrl?: string;
}
