<span id="main-header"
      class="header-line"
      [ngClass]="{
        'text-ellipsis hidden-xs': !!breadcrumbs
      }"
>
  <span *ngIf=" !breadcrumbs  " >Добро пожаловать!</span>

  <span *ngIf=" breadcrumbs  " style="max-width: 80%;" >
      <span *ngFor="let breadcrumb  of breadcrumbs; index as i; let last = last; ">
          {{ breadcrumb }}
        <i class="fa fa-arrow-right text-muted f-s-7 m-r-xs m-l-xs " *ngIf="!last"></i>
      </span>
  </span>

</span>
