
import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import Highcharts from "highcharts/highcharts.src";

@Component({
  selector: 'segment-graph-view',
  templateUrl: './segment-graph-view.component.html',
  styleUrls: ['./segment-graph-view.component.scss']
})
export class SegmentGraphViewComponent implements OnInit, OnChanges{

  @Input() title = 'График'
  @Input() model = []
  @Input() color;

  public Highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;

  public config: Highcharts.chart = {

    chart: {
      type: 'area',
      height: 300,
    },

    colors: [],

    plotOptions: {

      area: {
        animation: {
          duration: 1000
        },
        marker: {
          symbol: 'circle',
          enabled: true,
          fillColor: '#ffffff',
          lineColor: null,
          lineWidth: 2,
          radius: 5
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, '#ccf1d4'],
            [1, '#67d481']
          ]
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: false,
        softThreshold: false
      },

      series: {
        animation: {
          duration: 1000
        }
      },



      tooltip: {
        formatter: function () {
          return ( parseInt(this.x) || 0 ) + ` для ` + ( parseInt(this.y) || 0 ) + ` покупателей`;
        }
      },

    },


    legend: false,

    title: {
      text: ''
    },

    xAxis: {
      gridLineColor: '#f4f4f4',
      showEmpty: true,
      gridLineWidth: 1,
      tickWidth: 0,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels:
        {
          style: {
            color: '#ccc'
          }
        },
    },

    yAxis: {
      gridLineColor: '#f4f4f4',
      showEmpty: true,
      gridLineWidth: 1,
      tickWidth: 0,
      lineWidth: 0,
      tickmarkPlacement: 'on',
      labels: {
        style: {
          color: '#ccc'
        }
      },
      title: {
        text: ''
      },

    },

    credits: {
      enabled: false
    },

    series: [{
      name           : '',
      type           : 'area',
      pointPlacement : 'on',
      data           : [],
    }],
  }

  constructor( ) { }

  ngOnInit() {
  //  this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['model'])
      this.parseData();

    if (changes['color'])
      this.parseColor();

    if (changes['title'])
      this.config.series[0].name = changes['title']?.currentValue;

    this.updateFlag = true;

  }

  parseData() {

    if (!Array.isArray(this.model)) {
      this.reset();
      return;
    }

    this.config.series[0].data = [];

    delete this.config.xAxis.min;
    delete this.config.xAxis.max;

    delete this.config.yAxis.min;
    delete this.config.yAxis.max;

    this.model.forEach(item => {
      this.config.series[0].data.push( [ parseFloat(item.range2), parseFloat( item.count ) ] );
    });

  }

  parseColor() {
    this.config.colors = [this.color];
    this.config.plotOptions.area.fillColor.stops = [
      [ 0 , this.color.replace(/\d+\s*\)/, '0.3)') ],
      [ 1 , this.color.replace(/\d+\s*\)/, '0.6)') ]
    ];
  }

  reset() {


    this.config.xAxis.min = 0;
    this.config.xAxis.max = 10;

    this.config.yAxis.min = 0;
    this.config.yAxis.max = 10;

    this.config.series = [{
      name           : this.title,
      type           : 'area',
      pointPlacement : 'on',
      data           : [],
    }]

  }

}


/*

let result = JSON.parse( JSON.stringify(CHART_CONFIG) );

result.options.colors = [options.color];
result.options.plotOptions.area.fillColor.stops = [
  [ 0 , options.color.replace(/\d+\s*\)/, '0.3)') ],
  [ 1 , options.color.replace(/\d+\s*\)/, '0.6)') ]
];

  list.forEach(communication => {
    result.series[0].data.push( [ parseFloat(communication.range2), parseFloat( communication.count ) ] );
  });
*/
