/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for SocialApi services
 */
@Injectable({
  providedIn: 'root',
})
export class SocialApiConfiguration {
  rootUrl: string = location.origin;
}

export interface SocialApiConfigurationInterface {
  rootUrl?: string;
}
