
const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Ноября',
    'Октября',
    'Декабря'
];


export class ViberCtrl {

    // Для проверки картинок
    private imageForCheck = new Image();
    private isImgLoading : boolean = false;
    private isImgValid   : boolean = false;

   // Для отображения времён
    private curDate: string = "";
    private curTime: string = "";

    private validImageUrl: string = "";

    // Передаваемые параметры в компонент
    public message    : string;
//  public imageUrl   : string;  // реализован в ввиде get/set
    public buttonText : string;
    public buttonUrl  : string;

    public set imageUrl( url: string ) {

        if (!url || url.indexOf('http') !==0 ) {
            this.isImgValid   = false;
            this.isImgLoading = false;
            return;
        }

        try {
            this.checkImage(url);
        } catch (e) {
            setTimeout( _ => this.checkImage(url), 0 );
        }
    }

    public get imageUrl() {
        return this.validImageUrl;
    }

  static $inject = ['$scope' ];
    constructor( private $scope: any) { }


    public $onInit() {

        this.curDate = this.dateToString( new Date() )     ;
        this.curTime = this.dateToTimeString( new Date() ) ;

        this.imageForCheck.onload  = () => {

            this.validImageUrl = this.imageForCheck.src ;
            this.isImgValid    = true  ;
            this.isImgLoading  = false ;

            this.updateView();
        }

        this.imageForCheck.onerror = () =>  {

            this.isImgValid    = false ;
            this.isImgLoading  = false ;
            this.validImageUrl = ""    ;

            this.updateView();
        }

    }


    private dateToString = (date: Date) : string => {
        return `${date.getDate().toString()} ${months[ date.getMonth() ]} ${date.getFullYear().toString()}`;
    }

    private dateToTimeString = (date: Date) : string => {
        return `${date.getHours()}:${date.getMinutes()}`;
    }

    public htmlToText = ( input: string) : string => {
        let tmp = document.createElement("DIV");

        tmp.innerHTML = input;
        return tmp.textContent || tmp.innerText || "";
    }

    public checkImage = ( url: string ) : void => {

        this.isImgValid        = false ;
        this.isImgLoading      = true  ;
        this.imageForCheck.src = url   ;

    }

    private updateView = () => {
        this.$scope.$apply();
    }

};
