'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.locations.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('?partnerId')

        .template(   '<locations-index-page [partner-id]="partnerId" ></locations-index-page>', true)
        .controller(['$scope', '$stateParams',
          function($scope, $stateParams) {
            $scope.partnerId = $stateParams.partnerId;
          } ])
        .breadcrumbs( [ "Справочники", "Точки продаж" ]);

    }]);
