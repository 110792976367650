import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ElementRef,
  ViewChild,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ng1-datepicker',
  template: `
    <input type="hidden"
           [(ngModel)]="model"
           (ngModelChange)="onChangeModel()"
           ngbDatepicker
           [firstDayOfWeek]="1"
           (closed)="onClose()"
           #d="ngbDatepicker">
  `,

})
export class Ng1DatepickerComponent implements OnInit, OnChanges {

  @Input() value: string;
  @Input() show: boolean;

  @Output() valueChange = new EventEmitter<string>();
  @Output() showChange  = new EventEmitter<boolean>();

  model: any;
  @ViewChild('d') d: ElementRef<HTMLInputElement>;
  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.show && changes.show.currentValue) {
        (<any>this.d).open();
    }
  }

  onChangeModel() {
    this.valueChange.emit(
      `${this.model.year}-${('0'+this.model.month).slice(-2)}-${('0'+this.model.day).slice(-2)}T00:00:00.000Z`
    );
  }

  onClose() {
   this.showChange.emit(false);
  }

}
