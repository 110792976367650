import {Directive, ElementRef, Input, OnInit} from "@angular/core";

const DIRECTIVE_NAME = 'gridItemForm';

@Directive({ selector: '[grid-item-form]' })
export class GridItemFormDirective  implements  OnInit{

    @Input('grid-item-form') label;
    @Input('grid-item-form-icon') icon;
    @Input('grid-item-label-top') labelTop = false;

    constructor( private el: ElementRef) {
    }

    ngOnInit() {
      const labelElement = this.getLabelElement(this.label , this.icon);
      if (!labelElement)
        return;

      let child = this.decorateValueElement(this.el.nativeElement);
      this.el.nativeElement.insertBefore(labelElement, child);
      this.el.nativeElement.classList.add('grid-item__form');
      this.el.nativeElement.classList.add('form-item');
      if (this.labelTop) {
        this.el.nativeElement.classList.add('grid-item_label-top');
      }
    }

  private getIconElement(icon: string) {
        if ( !icon ) {
            return;
        }

        let el = document.createElement('i');
        el.classList.add(icon.trim());
        el.classList.add('fa'); // TODO: сделать проверку для разных библиотек иконок
        return el;
    }

    private getLabelElement(label = "", icon: string) {

        let el_icon = this.getIconElement(icon);
        if ( !label && !icon) {
            return;
        }

        let el = document.createElement('div');
        el.classList.add('grid-item__label');
        el.classList.add('form-label');
        el.innerHTML = label;
        !!icon && el.appendChild( el_icon );
        return el;
    }

    private decorateValueElement(root: HTMLElement) {
/*
        const newRoot = document.createElement('div');
        newRoot.className = root.className;
        newRoot.classList.add('grid-item');

        root.parentNode.insertBefore(newRoot, root);
        root.previousElementSibling.appendChild(root);

        root.className = '';
        root.classList.add('grid-item__value');
        root.classList.add('form-value');

        return newRoot;

 */
      const child = document.createElement('div');

      while (root.childNodes.length > 0) {
        child.appendChild(root.childNodes[0]);
      }

      root.classList.add('grid-item');
      root.appendChild(child)

      child.classList.add('grid-item__value');
      child.classList.add('form-value');

      return child;


    }
}
/*


export default window['angular'].module('loya.builder').directive(DIRECTIVE_NAME, () => ({

    restrict: 'EA',
    controller  : GridItemForm,

}));

// legacy для тестов
window['angular'].module('loya.builder').directive('gridItem', () => ({

    restrict: 'AEC',
    controller : ['$element', function($element: ng.IRootElementService){
        if ($element.hasClass('grid-item__form')) {
            $element.addClass('form-item');
        }
        $element.find('.grid-item__form').addClass('form-item');
        $element.find('.grid-item__label').addClass('form-label');
        $element.find('.grid-item__value').addClass('form-value');
    }]

}));

*/
