import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../goods-tree.variables";
import {GoodModelService} from "../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../service/api/CategoryModel/CategoryModel.service";
import {CategoryApiService} from "../../../../api/CategoryApi/services/category-api.service";
import {BrandService} from "../../../service/api/Brand/Brand.service";
import {GoodgroupService} from "../../../service/api/Goodgroup/Goodgroup.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GoodTreeGetterService {

  static cache: any;

  constructor(
    @Inject(GoodModelService)     private goodModelService: GoodModelService,
    @Inject(CategoryModelService) private categoryModelService: CategoryModelService,
    @Inject(BrandService)         private brandService: BrandService  ,
    @Inject(GoodgroupService)     private goodgroupService: GoodgroupService,
  ) {
      this.resetCache();
  }

  public resetCache() {
    if ( typeof GoodTreeGetterService.cache === "object")
      Object.keys( GoodTreeGetterService.cache )
        .forEach( i => {
          delete GoodTreeGetterService.cache[i];
        });

    GoodTreeGetterService.cache = {};
  }

  private getMapForPartner(partnerId, type:VIEW_TYPES): Map<any, any> {
    GoodTreeGetterService.cache = GoodTreeGetterService.cache || {};
    GoodTreeGetterService.cache[type] = GoodTreeGetterService.cache[type] || {};
    GoodTreeGetterService.cache[type][partnerId] = GoodTreeGetterService.cache[type][partnerId] || new Map();
    return GoodTreeGetterService.cache[type][partnerId];
  }

  getGood(id, partnerId, returnPromise?) {

    if (typeof id === "object") {
      return  {
        id: id.sku || id.id,
        label: id.name || id.label,
        partnerId: id.partnerId,
        dimension: id.dimension,
      };
    }

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.SKU);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.goodModelService.get$( id, partnerId )
        .subscribe({
          next: ( result ) => {

            let res = {
              id: result.sku,
              label: result.name,
              partnerId: result.partnerId,
              dimension: result.dimension,
            }

            mapStorage && mapStorage.set(id, res);
            resolve(res);
          },
          error: () => {
            let res = {
              id: id,
              name: 'Товар с ID:' + id
            }
            mapStorage && mapStorage.set(id, res);
            resolve( res );
          }
        });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getCategory(id, partnerId, returnPromise?) {

    if (typeof id === "object")
      return  {
        id: id.categoryId || id.id,
        label: id.name || id.label,
        partnerId: id.partnerId,
        dimension: undefined,
      };

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.CATEGORY);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.categoryModelService.get$( id, partnerId )
      .subscribe({
        next: ( result ) => {
          let res = {
            id: result.categoryId,
            label: result.name,
            partnerId: result.partnerId,
            dimension: undefined,

          }
          mapStorage && mapStorage.set(id, res);
          resolve(res);
        },
        error: () => {
          let res = {
            id: id,
            label: 'Категория с ID:' + id,
            partnerId: partnerId,
          };
          mapStorage && mapStorage.set(id, res);
          resolve( res );
        }
      });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getBrandsList(params, partnerId) {

    // let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.BRAND);

    return this.brandService
      .getList$(params)
      .pipe(
        map( ( result: any ) => {
          if (Array.isArray(result)) {
            result = result.map( item => {
              let res = {
                id: item.id,
                label: item.name,
                description: item.description
              }
             // mapStorage.set(item.id, res);
              return res;
            })
          }
          return result;
        })
      )

  }

  getBrand(id, partnerId, returnPromise?) {

    if (typeof id === "object")
      return  id;

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.BRAND);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.brandService.getBrand$( id )
      .subscribe({
        next: ( result: any ) => {
          mapStorage && mapStorage.set(id, {
            id: id,
            label: result.name,
            description: result.description,
          });
          resolve(result);
        },
        error: () => {
          let res = {
            id: id,
            label: 'Бренд с ID:' + id
          };
          mapStorage && mapStorage.set(id, res);
          resolve( res );
        }
      });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getGoodGroup(id, partnerId, returnPromise?) {

    if (typeof id === "object")
      return  id;

    let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.GOODGROUP);

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    let resolve;
    let retPromise = new Promise( (r) => { resolve = r });

    this.goodgroupService.getGoodgroup$( id, partnerId )
      .subscribe({
        next: ( result: any ) => {
          mapStorage && mapStorage.set(id, {
            id: id,
            label: result.name,
            description: result.description,
          });
          resolve(result);
        },
        error: () => {
          let res = {
            id: id,
            label: 'Список с ID:' + id
          };
          mapStorage && mapStorage.set(id, res);
          resolve( res );
        }
      });


    mapStorage && mapStorage.set(id, true);

    return returnPromise ? retPromise : true;

  }

  getGoodGroupList(params, partnerId) {

  //  let mapStorage = this.getMapForPartner(partnerId, VIEW_TYPES.GOODGROUP);

    return this.goodgroupService
      .getList$(params)
      .pipe(
        map( ( result: any ) => {
          if (Array.isArray(result)) {
            result = result.map( item => {
              let res = {
                id: item.id,
                label: item.name,
                description: item.description
              }
            //  mapStorage.set(item.id, res);
              return res;
            })
          }
          return result;
        })
      )

  }

  getTree(partnerId, filterRootId?,  drop?, limit?, sort?, filterDimensions?) {

    return new Promise( (resolve, reject) => {

      filterDimensions = filterDimensions && !Array.isArray(filterDimensions) ? [ filterDimensions ] : filterDimensions;

      let requestParams: CategoryApiService.TreeParams = {
        partnerId: partnerId,
        pagerDrop: drop,
        pagerLimit: limit,
        filterRootId,
        filterDimensions
      };

      if (!!sort) {
        requestParams.sort = sort;
      }

      this.categoryModelService.getTree$(requestParams)
        .subscribe({
          next: result => {
            resolve( result );
          },
          error: reject
        })
    })

  }

  getSearchTree(partnerId, filterSentence, filterRootId?, sort?, filterDimensions? ) {

    return new Promise( (resolve, reject) => {

      filterDimensions = filterDimensions && !Array.isArray(filterDimensions) ? [ filterDimensions ] : filterDimensions;

      let requestParams: CategoryApiService.SearchParams = {
        partnerId: partnerId,
        filterRootId,
        sort,
        filterDimensions,
        filterSentence
      };

      if (!!sort) {
        requestParams.sort = sort;
      }

      this.categoryModelService.getSearchTree$(requestParams)
        .subscribe({
          next: result => {
            resolve( result );
          },
          error: reject
        })
    })

  }

  getIdByType(id, partnerId, viewType: VIEW_TYPES) {

    switch (viewType) {

      case VIEW_TYPES.SKU:
        return this.goodModelService.get$( id, partnerId )
          .toPromise()
          .then( result =>{

            this.getMapForPartner(partnerId, VIEW_TYPES.SKU).set(id, {
              id: result.sku,
              label: result.name,
              partnerId: result.partnerId,
              dimension: result.dimension,
            });

            return result.sku
          });

      case VIEW_TYPES.CATEGORY:
        return this.categoryModelService.get$( id, partnerId )
          .toPromise()
          .then( result =>{
            this.getMapForPartner(partnerId, VIEW_TYPES.CATEGORY).set(id,  {
              id: result.categoryId,
              label: result.name,
              partnerId: result.partnerId,
              dimension: undefined,

            });
            return result.categoryId
          });

      case VIEW_TYPES.BRAND:
        return this.brandService.getBrand$( id )
          .toPromise()
          .then( ( item: any ) =>{
            this.getMapForPartner(partnerId, VIEW_TYPES.BRAND).set(id, {
              id: item.id,
              label: item.name,
              description: item.description
            });
            return item.id;
          });

      case VIEW_TYPES.GOODGROUP_SKU:
      case VIEW_TYPES.GOODGROUP:
        return this.goodgroupService.getGoodgroup$(id, partnerId )
          .toPromise()
          .then( ( item: any ) =>{
            this.getMapForPartner(partnerId, VIEW_TYPES.GOODGROUP).set(id, {
              id: item.id,
              label: item.name,
              description: item.description
            });
            return item.id;
          });

    }

  }

  async getResultById(ids=[], partnerId, currentView: VIEW_TYPES) {

    let mapStorage = this.getMapForPartner(partnerId, currentView);

    let promises = ids.slice()
      .map( async item => {

        switch (currentView) {

          case VIEW_TYPES.SKU:
            return this.getGood(item, partnerId, true);

          case VIEW_TYPES.CATEGORY:
            return this.getCategory(item, partnerId, true);

          case VIEW_TYPES.BRAND:
            return this.getBrand(item, partnerId, true);

          case VIEW_TYPES.GOODGROUP_SKU:
          case VIEW_TYPES.GOODGROUP:
            return this.getGoodGroup(item, partnerId, true);
        }

      })

    return await Promise.all(promises);
  }

}
