<div class="fixed-tool-panel">


  <div class="dropdown  pull-left m-r-sm"   ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs"   ngbDropdownToggle>
      <i>
        <span class="fa fa-filter"></span>
      </i>
      <span class="btn-name">
                Временной период
                <span class="caret "></span>
            </span>
    </button>

    <ul class="dropdown-menu scrollable "
        ngbDropdownMenu
        style="z-index: 9999; "
        onclick="event.stopPropagation()"
    >

      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group ">
          <span class="input-group-addon" style="min-width:50px">С</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filterFrom"
                 [min]="minDate"
                 [max]="filterTo"
          >
          <span class="input-group-btn" *ngIf="filterFrom">
                  <button
                    class="btn btn-danger m-b-none m-t-none"
                    type="button"
                    (click)="filterFrom = undefined">
                      <i class="fa fa-times"></i>
                  </button>
          </span>
        </div>

      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group m-b-xs">
          <span class="input-group-addon" style="min-width:50px">ПО</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filterTo"
                 [min]="filterFrom"
                 [max]="maxDate"
          >
          <span class="input-group-btn" *ngIf="filterTo">
              <button
                class="btn btn-danger  m-b-none m-t-none"
                type="button"
                (click)="filterTo = undefined">
                  <i class="fa fa-times"></i>
              </button>
          </span>
        </div>

      </li>

      <li class=" padder text-right">
        <button
          class="btn w-full btn-danger btn-sm btn-addon m-l-none m-b-xs m-t-none"
          type="button"
          *ngIf="filterTo && filterFrom"
          (click)="filterTo = undefined; filterFrom = undefined;">
          <i class="fa fa-times"></i>
          <span class="ben-name">
            Сбросить
          </span>
        </button>
      </li>

    </ul>

  </div>


  <button type="button"
          class="btn btn-sm btn-primary btn-addon pull-right collapse-xs  "
          (click)="exportByUser()">
    <i class="fa fa-share-square">
    </i>
    <span class="btn-name">
      Экспортировать в CSV
    </span>
  </button>

  <button type="button"
          class="btn btn-sm btn-success btn-addon pull-right collapse-xs  "
          [uiSref]="'^.transaction({id:\'\'})'"
          data-testid="back"
  >
    <i class="fa fa-arrow-left">
    </i>
    <span class="btn-name  hidden-xxs">
      К сводному отчету
    </span>
  </button>

</div>



 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="getNext()"
    >
     <thead>
       <tr>
         <th class=" table-row-xs ">Дата транзакции</th>
         <th >Номер счета</th>
         <th class=" table-row-xs ">ФИО</th>
         <th class="table-row-sm table-row-xs ">Номер телефона</th>
         <th class="text-right">Сумма</th>
         <th class="table-row-sm table-row-xs ">Тип операции</th>
         <th class="table-row-sm table-row-xs ">Причина</th>
         <th class="table-row-sm table-row-xs ">Оператор</th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listTransactions">
          <tr data-testid="let item of listTransactions">
            <td data-testid="item.accountRecord.date">
              <span [innerText]="item.accountRecord.date | loyaDateTime"></span>
            </td>
            <td data-testid="item.accountNumber">
              <a [uiSref]="'frontend.crm.accounts.edit( {id:'+item.accountId+', client_id:'+item.clientId+'})'"
                 href="javascript:void(0)"
              >
                <span [innerText]="item.accountNumber"></span>
              </a>
            </td>
            <td data-testid="item.client">
              <a
                [uiSref]="'frontend.crm.clients.view( {id:'+item.clientId+'})'"
              >
                <span [innerText]="item.surname || ''"></span>
                <span [innerText]="item.name || ''"></span>
              </a>
            </td>
            <td data-testid="item.phoneNumber">
              <span [innerText]="item.phoneNumber || ''"></span>
            </td>
            <td class="text-right" data-testid="item.accountRecord.amount">
              <span [innerText]="item.accountRecord.amount | loyaNumber"></span>
            </td>
            <td data-testid="item.accountRecord.operType">
              <span [innerText]="OPERATION_STATES[item.accountRecord.operType] || item.accountRecord.operType"></span>
            </td>
            <td data-testid="item.accountRecord.description">
              <span [innerText]="item.accountRecord.description"></span>
            </td>
            <td data-testid="item.operatorName">
              <span [innerText]="item.operatorName"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>
