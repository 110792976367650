import {Component, EventEmitter, Injectable, Input, OnDestroy, OnInit} from "@angular/core";

import {UserApiService} from "../../../api/UserApi/services"
import {RawUser} from "../../../api/UserApi/models"
import {Observable, from, of} from "rxjs";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'ngbd-modal-content',
  template: `
      <div class="modal-header wrapper-xs padder bg-light">
        <h4 class="modal-title" [innerText]="title"></h4>
      </div>
      <div class="modal-body hbox">
        <div class="col w-xxs text-left padder"><i class="fa fa-warning text-warning fa-2x"></i></div>
        <div class="col no-padder v-middle" [innerText]="message"></div>
      </div>
      <div class="modal-footer b-t bg-light dk ">
        <button class="btn btn-success" type="button" ng-click="okEvent()" (click)="okEvent()" [innerText]="ok">ОК</button>
      </div>
  `
})
export class NgbdModalContent {
  @Input() title = 'Внимание';
  @Input() message;
  @Input() ok = 'Ок';


  constructor(public activeModal: NgbActiveModal) {}

  okEvent() {
    this.activeModal.close('Ok');
  }

}





@Injectable({
  providedIn: 'root',
})
export class WarningBoxService {

  constructor(
    private modalService: NgbModal
  ) {

  }

  public warning(message, title='Внимание') {

    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    return modalRef.result;
  }


}
