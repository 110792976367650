/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for PromotionApi services
 */
@Injectable({
  providedIn: 'root',
})
export class PromotionApiConfiguration {
  rootUrl: string = location.origin;
}

export interface PromotionApiConfigurationInterface {
  rootUrl?: string;
}
