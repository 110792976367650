/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TransactionApiConfiguration as __Configuration } from '../transaction-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Transaction } from '../models/transaction';
import { TransactionData } from '../models/transaction-data';
@Injectable({
  providedIn: 'root',
})
class TransactionApiService extends __BaseService {
  static readonly transactionsPath = '/transactions';
  static readonly transactionsByUserPath = '/transactions/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Транзакции, сгруппированные по операторам
   *
   * Транзакции, сгруппированные по операторам
   * @param params The `TransactionApiService.TransactionsParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Транзакции
   */
  transactionsResponse(params: TransactionApiService.TransactionsParams): __Observable<__StrictHttpResponse<Array<Transaction>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/transactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Transaction>>;
      })
    );
  }
  /**
   * Транзакции, сгруппированные по операторам
   *
   * Транзакции, сгруппированные по операторам
   * @param params The `TransactionApiService.TransactionsParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Транзакции
   */
  transactions(params: TransactionApiService.TransactionsParams): __Observable<Array<Transaction>> {
    return this.transactionsResponse(params).pipe(
      __map(_r => _r.body as Array<Transaction>)
    );
  }

  /**
   * Транзакции оператора
   *
   * Транзакции оператора
   * @param params The `TransactionApiService.TransactionsByUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `to`:
   *
   * - `limit`:
   *
   * - `from`:
   *
   * - `drop`:
   *
   * @return Транзакции
   */
  transactionsByUserResponse(params: TransactionApiService.TransactionsByUserParams): __Observable<__StrictHttpResponse<Array<TransactionData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/transactions/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TransactionData>>;
      })
    );
  }
  /**
   * Транзакции оператора
   *
   * Транзакции оператора
   * @param params The `TransactionApiService.TransactionsByUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `to`:
   *
   * - `limit`:
   *
   * - `from`:
   *
   * - `drop`:
   *
   * @return Транзакции
   */
  transactionsByUser(params: TransactionApiService.TransactionsByUserParams): __Observable<Array<TransactionData>> {
    return this.transactionsByUserResponse(params).pipe(
      __map(_r => _r.body as Array<TransactionData>)
    );
  }
}

module TransactionApiService {

  /**
   * Parameters for transactions
   */
  export interface TransactionsParams {
    to?: string;
    from?: string;
  }

  /**
   * Parameters for transactionsByUser
   */
  export interface TransactionsByUserParams {
    userId: number;
    to?: string;
    limit?: number;
    from?: string;
    drop?: number;
  }
}

export { TransactionApiService }
