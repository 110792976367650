<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
>

  <div class="fixed-tool-panel">


    <button type="button" class="btn btn-sm btn-addon btn-danger "
            [hidden]="!statesChangerService.hasButton('delete')"
            (click)="confirmActionService.confirm('Вы действительно хотите удалить бренд ' + currentBrand.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
    >
      <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
      <i class=" m-r-xs fa fa-remove"></i>
      <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
    </button>



    <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
            [hidden]="!statesChangerService.hasButton('edit')"
            (click)="statesChangerService.click('edit')"
    >

      <i class=" fa fa-pencil">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('save')"
            (click)="statesChangerService.click('save')"
            [disabled]="brandForm && brandForm.form && brandForm.form.invalid"
    >
      <i class=" fa fa-check text-primary">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
    </button>

    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('cancel')"
            (click)="statesChangerService.click('cancel')">

      <i class=" fa fa-remove text-danger">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
            *ngIf="statesChangerService.hasButton('preview')"
            (click)="statesChangerService.click('preview')"

            [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
            [disabled]="brandForm && brandForm.form && brandForm.form.invalid"
    >
      <i class=" fa fa-eye text-success">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
    </button>

    <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
            *ngIf="statesChangerService.state === 'view'"
            uiSref="^.index"
    >

      <i class="fa fa-arrow-left"></i>
      <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
    </button>

  </div>

  <div class="card w-100 no-back"
       *transloco="let t"
  >

    <div class="card-body"
      *ngIf="currentBrand"
    >
      <form name="brandForm" #brandForm="ngForm" novalidate autocomplete="off">

        <grid-container>

          <div class="grid-item"
               [grid-item-form] = "'models.BrandApi.id.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
        <span
          [innerHTML]="currentBrand.id || '-'"
        ></span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'models.BrandApi.name.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
            <span [hidden]="statesChangerService.isActive"
                  [innerText]="currentBrand.name || ''"
            ></span>

                <span class="editable-rec"
                      [hidden]="!statesChangerService.isActive"
                >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentBrand.name"
                name="name"
                autoComplete="off"
                [placeholder]="'models.BrandApi.name.placeholder'  | transloco"
                [required]="true"
                [maxlength]="254"
              >
            </span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'models.BrandApi.description.name'  | transloco"
               grid-item-form-icon = "fa-align-justify"
          >
         <span [hidden]="statesChangerService.isActive"
               [innerText]="currentBrand.description || ''"
         ></span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
            <textarea
              class="form-control form-control-edittable"
              [(ngModel)]="currentBrand.description"
              name="description"
              [placeholder]="t('models.BrandApi.description.placeholder')"
              [required]="true"
              [maxlength]="254"
              rows="3"
            ></textarea>
        </span>
          </div>

        </grid-container>
      </form>

    </div>

  </div>

  <div class="card w-full no-back"
       *transloco="let t"
  >
    <div class="card-header">
      <i class="fa fa-rocket text-success m-r-sm"></i>
      {{ 'pages.Config.Directory.Brand.sku_table_header' | transloco }}
    </div>
    <div class="card-body no-padder"
    >

      <div class="wrapper padder-lg"
           *ngIf="statesChangerService.isActive"
      >
        <div class="row m-b-xs">
          <div class="col " id="addSKUContainer">
            <select-goods-ng2
              buttonName  = "Добавить SKU"
              buttonClass = "btn btn-success btn-addon btn-sm"
              [selectGoodsPopup]="onSelectSkuBinded"
              [showCategories]="['sku']"
              [forPartner]="selectedPartner.id"
            ></select-goods-ng2>
          </div>
          <div class="col text-right">
            <button type="button"
                    id="deleteSkuFromBrand"
                    class="btn btn-sm btn-addon btn-danger"
                    (click)="onDeleteBrands()"
            >
              <i class="m-r-xs fa fa-remove"></i>
              <span class="btn-name m-l-xs hidden-xxs" *ngIf="hasSelected">{{'pages.Config.Directory.Brand.delete_specific_button' | transloco}}</span>
              <span class="btn-name m-l-xs hidden-xxs" *ngIf="!hasSelected">{{'pages.Config.Directory.Brand.delete_all_button' | transloco}}</span>
            </button>
          </div>
        </div>
      </div>

      <table class="table m-b-none b-b m-t-n-xxs table-customers "
             infiniteScroll
             [infiniteScrollDistance]="1"
             [infiniteScrollThrottle]="50"
             [scrollWindow]="false"
             [infiniteScrollContainer]="'#app-container'"
             [fromRoot]="true"
             (scrolled)="getBrandList()"
      >
        <thead>
        <tr>
          <th data-sort-ignore="true"  *ngIf="statesChangerService.isActive"></th>
          <th data-sort-ignore="true" [innerText]=" 'models.BrandGoodLinkApi.sku.name' | transloco"></th>
          <th data-sort-ignore="true" [innerText]=" 'models.BrandGoodLinkApi.name.name' | transloco"></th>
        </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of brandGoodsList | callback: filterByDeleted "
          >
            <td class="w-xxs text-center"  *ngIf="statesChangerService.isActive">

              <div class="checkbox">
                <label class="i-checks">
                  <input type="checkbox"
                         [(ngModel)]="item.$isSelected"
                         (change)="onChangeSelected($event)"
                  >
                  <i></i>
                </label>
              </div>

            </td>
            <td>
              <span [innerText]="item.sku"></span>
            </td>
            <td>
              <i class="{{item.dimension === 'weight' ? 'fa-balance-scale fa text-info m-r-xs' : 'fa-tag fa text-info m-r-xs'}}"></i>
              <span [innerText]="item.name"></span>
            </td>

          </tr>
        </tbody>

      </table>

    </div>

  </div>





</partner-menu>
