
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {CampaignService} from "../../../../service/api/Campaign/Campaign.service";
import {CAMPAIGN_STATE_NAMES} from "../../../../service/api/Campaign/Campaign.values";
import {TriggersService} from "../../../../service/api/Triggers/Triggers.service";
import tr from "model/TriggersModel/l11n";
import {ObjectUtils} from "../../../../class/utils/object.utils";


@Component({
  selector: 'trigger-view',
  templateUrl: "./trigger-view.component.html",
  styleUrls: ['./trigger-view.component.scss' ],
  providers: [
    TriggersService
  ]
})
 export class TriggerViewComponent implements OnInit, OnChanges {

  @Input()  selected = new Map<number, any>();
  @Output() selectedChange = new EventEmitter();

  @Input()  selectedAll = false;
  @Output() selectedAllChange = new EventEmitter();

  @Input() max : number;
  @Input() selectOne : boolean;

  @Input() states;

  @Input() canCheckAll = false;

  @Input() customParams:any

  public campaignsItems = [];
  public loader = new LoaderHandlerClass();
  public selectedAllCache;

  public isPageAllCampaigns = false;

  public CAMPAIGN_STATE_NAMES = CAMPAIGN_STATE_NAMES;

  constructor(
    public triggersService: TriggersService,
  )
  {
  }

  ngOnInit() {
    this.triggersService.queryParams.reset();

    if (!!this.states)
      this.triggersService.queryParams.setFilterValue('states', this.states )

    this.getCampaigns();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['states'] && !changes['states'].isFirstChange()) {
      this.triggersService.queryParams.setFilterValue('states', changes['states'].currentValue )
      this.resetList();
      return;
    }

  }

  get isAllSelectedOnPageAllCampaigns() {
    return this.selectedAll;
  }

  getCampaigns() {

    this.triggersService
      .query$(
        ObjectUtils.deepExtend(this.triggersService.queryParams.params, this.customParams || {})
      )
      .pipe(
        this.loader.waitLoading('campaignsItems')
      )
      .subscribe({
        next: (result: any ) => {
          delete this.selectedAllCache;
          this.campaignsItems = this.campaignsItems.concat(
            result.map( i => ({
              id: i.trigger.id,
              state: i.trigger.state,
              name: i.trigger.name,
              description: i.trigger.description,
              createdAt: i.trigger.createdAt,
              updatedAt: i.trigger.updatedAt,
            }))
          );
        },
        error: err => {
          console.log(err)
        }
      })

  }

  isSelected = (id) => {

    if ( this.isAllSelectedOnPageAllCampaigns )
      return this.selectedAll;

    return this.selected.has(id)
  }

  changeSelected(item, value) {

    if (this.isDisabled(item?.id))
      return

    if (value) {

      if (this.selectOne) {
        this.selected.clear();
      }

      this.selected.set(item.id, item);

    } else {
      this.selected.delete(item.id)
    }

    if ( this.isAllSelectedOnPageAllCampaigns ){
      this.selected.clear();
      this.campaignsItems.forEach( i => {
        if (i.id !== item.id)
          this.selected.set(i.id, i)
      });
    }

    this.selectedAll = false;
    this.selectedAllChange.emit(this.selectedAll);

    this.selectedChange.emit(this.selected);
    delete this.selectedAllCache;
  }

  isDisabled( id ) {

    if ( !Number.isInteger(this.max) ) {
      return false;
    }

    if ( this.selected.size >= this.max )
      return !this.isSelected(id);

    return false;

  };

  onScroll() {
    if (this.loader.isLoading('campaignsItems'))
      return;

    this.triggersService.queryParams.next();
    this.getCampaigns()
  }

  resetList() {
    this.campaignsItems = [];
    this.triggersService.queryParams.reset();
    this.getCampaigns();
  }

  reset() {
    this.selected.clear();
    this.selectedChange.emit(this.selected);
    this.resetList();
  }

  isSelectedAll() {

    if ( this.isAllSelectedOnPageAllCampaigns )
      return this.selectedAll;


    if( !this.campaignsItems.length )
      return false;

    return !this.campaignsItems.some( item => !this.selected.has(item.id) );
  }

  changeSelectedAll(enabled) {


    if (this.isPageAllCampaigns) {
      this.selected.clear();
      this.selectedChange.emit(this.selected);
      this.selectedAll = enabled
      this.selectedAllChange.emit(this.selectedAll);
      return
    }

    this.selectedAll = false;
    this.selectedAllChange.emit(this.selectedAll);

    this.campaignsItems.forEach( item => {

      if (enabled) {
        this.selected.set(item.id, item);
      } else {
        this.selected.delete(item.id);
      }

    })

    delete this.selectedAllCache;
  }


}
