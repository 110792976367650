import {Component, OnInit, ViewChild} from "@angular/core";

import "./bonusrestrict-index-page.legacy"
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {VIEW_TYPES} from "../../../../../shared/component/goods-tree/goods-tree.variables";
import {CategoryApiService} from "../../../../../api/CategoryApi/services/category-api.service";
import {GoodModelService} from "../../../../../shared/service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../../../shared/service/api/CategoryModel/CategoryModel.service";
import {
  LocationsDialogService
} from "../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {LocationsService} from "../../../../../shared/service/api/Locations/Locations.service";

@Component({
  selector: 'bonus-restrict-index-page',
  templateUrl: './bonus-restrict-index-page.component.html',
  styleUrls: [`./bonus-restrict-index-page.component.scss`],
  providers:[
    ngCurrencyL10nService,
    PartnerService
  ]
})
export class BonusRestrictIndexPageComponent implements OnInit{

  public selectedPartner;

  public loader = new LoaderHandlerClass();
  @ViewChild('editWindow') editWindow;
  public itemForEdit: any = { limits: {} };

  public VIEW_TYPES = VIEW_TYPES;

  public awardTypes;
  public awardTypesDiscount;

  public cacheLocationNames = new Map();

  constructor(
    private ngbModal: NgbModal,
    private ngCurrencyL10nService : ngCurrencyL10nService,
    private partnerService: PartnerService,
    private goodModelService: GoodModelService,
    private categoryModelService: CategoryModelService,
    private locationsDialogService: LocationsDialogService,
    private locationsService: LocationsService
  ) {
  }

  ngOnInit() {
    this.initTypes();
  }

  initTypes() {
    const percent =  {
      name    : 'Процент',
      label   : '%',
      min     : 0,
      max     : 100,
      pattern : /^(\d{1,9})(\.\d{1,2})?$/
    }

    const count = {
      name  : 'Количество',
      label :  'шт',
      min     : 0,
      max     : 10000000000,
      pattern : /^((10000000000(.0{1,2})?)|[0-9]{1,10}(.[0-9]{1,2})?)$/
    }

    const fixprice = {
      name   : 'Мин. цена по скидкам',
      label  : undefined,
      min     : 0,
      max     : 10000000000,
      pattern : /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/
    }

    this.awardTypes = {
      'percent' : Object.assign({}, percent),
      'count'   : Object.assign({}, count),
    };

    this.awardTypesDiscount = {
      'percent' : Object.assign({}, percent),
      'count'   : Object.assign({}, count),
      'fixprice': Object.assign({}, fixprice),
    };

    this.ngCurrencyL10nService.getShortName()
      .then( result => {
        this.awardTypesDiscount['count'].label = result;
        this.awardTypesDiscount['fixprice'].label = result;
      })

  }


  changeSelectedPartner( inPartner ) {
    this.selectedPartner = inPartner;

    if (typeof this.selectedPartner?.id !== "undefined")
      this.partnerService
        .getBonusPattern(this.selectedPartner?.id)
        .then(result => {
          this.awardTypes.count.pattern = result;
        });
  }

  editCallback = async ( item: any ) => {

    let service: any = item.type == VIEW_TYPES.SKU ? this.goodModelService : this.categoryModelService;

    this.itemForEdit = await service.get$(item.id, this.selectedPartner.id )
      .pipe( this.loader.waitLoading('selectPrepare') )
      .toPromise();

    this.prepareEditItem(this.itemForEdit);
    if ( !!this.itemForEdit?.limitByLocations?.length ) {
      this.itemForEdit.limitByLocations.forEach( i => this.prepareEditItem(i) );
    }


    this.ngbModal.open(this.editWindow,
{
        size: "xl",
        windowClass: 'bonusrestrict-index-page-popup-modal-' + item.type
      })
      .result
        .then(
          (result) => {

            if ( !!this.itemForEdit?.limitByLocations?.length ) {
              this.itemForEdit.limitByLocations =
                this.itemForEdit.limitByLocations.filter( i => !(
                    ( !i?.limits?.maxDiscount && i?.limits?.maxDiscount !==0 ) &&
                    ( !i?.limits?.maxAllowBonus && i?.limits?.maxAllowBonus !==0 )  &&
                    ( !i?.limits?.maxAwardBonus && i?.limits?.maxAwardBonus !==0 )
                  )
                )
            }

            service
              .update$(this.itemForEdit)
              .pipe( this.loader.waitLoading('selectPrepare') )
              .toPromise();
          },
          (reason) => {},
        );

  }

  prepareEditItem(item) {

    item.limits = item.limits || {};
    item.limits.maxDiscountType = item.limits.maxDiscountType || Object.keys(this.awardTypesDiscount)[0];
    item.limits.maxAllowBonusType = item.limits.maxAllowBonusType || Object.keys(this.awardTypes)[0];
    item.limits.maxAwardBonusType = item.limits.maxAwardBonusType || Object.keys(this.awardTypes)[0];

  }

  addLimitByLocation() {

    this.locationsDialogService.open({
      forPartner: this.selectedPartner?.id,
      checkOne: true,
      canCheckAll: false,
      resultDirective: () => {},
    }).then( result => {

      if (!result?.locations?.length)
        return;

      this.itemForEdit.limitByLocations = this.itemForEdit.limitByLocations || [];
      let item = {
        locationId: result?.locations[0]?.id
      }

      if ( !this.itemForEdit.limitByLocations.some(i => i.locationId === item.locationId ) ) {
        this.prepareEditItem(item);
        this.itemForEdit.limitByLocations.push( item );
      }

      this.cacheLocationNames.set(`${item.locationId}:${this.selectedPartner?.id}`, result?.locations[0]?.name );

    })

  }

  getLocationName(locationId) {

    if (this.cacheLocationNames.has(`${locationId}:${this.selectedPartner?.id}`) )
      return this.cacheLocationNames.get(`${locationId}:${this.selectedPartner?.id}`);

    this.cacheLocationNames.set(`${locationId}:${this.selectedPartner?.id}`, '<i class="fa fa-spin fa-spinner text-warning"></i>');

    this.locationsService.get$(locationId)
      .subscribe( result => {
        this.cacheLocationNames.set(`${result.id}:${this.selectedPartner?.id}`, result.name )
      }, error => {
        this.cacheLocationNames.set(`${locationId}:${this.selectedPartner?.id}`, 'ТО с ID: ' + locationId  )
      })

    return this.cacheLocationNames.get(`${locationId}:${this.selectedPartner?.id}`);
  }

  deleteLimitByLocations(item) {
    if (!this.itemForEdit?.limitByLocations?.length) {
      return;
    }

    this.itemForEdit.limitByLocations = this.itemForEdit.limitByLocations.filter( i => i !== item);
  }

}
