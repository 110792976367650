'use strict';

import module from 'frontendModule';
import 'model/ClientTemplateModel/resource'

import * as template from './views/conditionOptions.html';

var GroupConditionsItems = {};



export default module.directive('conditionOptions', ['ClientTemplateModel', '$q', '$http', 'modulesStorage', '$templateCache', '$compile', '$controller', 'conditionItems',
    function (ClientTemplateModel, $q, $http, modulesStorage, $templateCache, $compile, $controller, conditionItems) {

        GroupConditionsItems = conditionItems.getConditions();

        return {
            restrict: 'E',
            templateUrl: template,

            link: function ($scope, element, attrs, tabsCtrl) {

                let localScope: any = false;

                $scope.$watchGroup(['model.filter.isReversed','model.filter'], function(val) {

                    if ( typeof val[0] !== 'undefined')
                        return;

                    if (typeof val[1] !== 'undefined' ) {
                        $scope.model.filter.isReversed = false;
                    }

                });

                $scope.$watch('selectedRuleId.id', function (id) {

                    let idFromRule = ( rule, component ) => {
                        if ( rule.ruleType === 'customField' ) {

                            return  ( rule.fieldSettings && rule.fieldSettings.id) ?
                                component + '.' + rule.ruleType + '.' + rule.fieldSettings.id :
                                component + '.' + rule.ruleType;

                        } else {
                            return component + '.' + rule.ruleType;
                        }
                    };

                    let idFromModel = ( model ) => {
                        if (!model.filter) return '';

                        if ( model.filter && model.filter.ruleType === 'customField' ) {
                            return model.component + '.' + model.filter.ruleType + '.' + model.filter.fieldId
                        } else {
                            return model.component + '.' + model.filter.ruleType;
                        }
                    };


                    if (!id) {
                        if ($scope.model &&
                            $scope.model.filter &&
                            $scope.model.filter.ruleType === "customField") {

                            id = idFromModel( $scope.model );

                        } else {
                            return;
                        }

                    };

                    $scope.error = false;
                    conditionItems.getConditions().then( GroupConditionsItems => {

                        const [ componentID, ruleID ] = id.split('.');

                        if (idFromModel($scope.model) !== id) {
                            $scope.model.filter = {};
                        }

                        $scope.model.component = componentID;
                        $scope.model.filter.ruleType = ruleID;

                        const ruleItem = GroupConditionsItems[componentID].rules.find( rule => idFromRule(rule, componentID) === id );

                        if ( ruleItem  ) {

                            if ( localScope ) {
                                localScope.$destroy();
                            }

                            localScope = $scope.$new();

                            if ( ruleItem.controller ) {
                                $controller(ruleItem.controller,
                                    {
                                        $scope   : localScope
                                    }
                                );
                            }

                            element.children().html( $templateCache.get( ruleItem.templateUrl ) );
                            $compile(element.children())(localScope);

                        } else {
                            $scope.error = 'Настройки критерия не найдены';
                        }

                    });

                });


            },
        }

    }]
);
