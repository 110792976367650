/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DibsListMechanicApiConfiguration, DibsListMechanicApiConfigurationInterface } from './dibs-list-mechanic-api-configuration';

import { DibsListMechanicApiService } from './services/dibs-list-mechanic-api.service';

/**
 * Provider for all DibsListMechanicApi services, plus DibsListMechanicApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    DibsListMechanicApiConfiguration,
    DibsListMechanicApiService
  ],
})
export class DibsListMechanicApiModule {
  static forRoot(customParams: DibsListMechanicApiConfigurationInterface): ModuleWithProviders<DibsListMechanicApiModule> {
    return {
      ngModule: DibsListMechanicApiModule,
      providers: [
        {
          provide: DibsListMechanicApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
