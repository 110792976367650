'use strict';

import 'model/CampaignCommonModel/resource';
import 'model/CampaignModel/resource';
import 'model/MechanicModel/resource';
import 'model/MsgTemplate';
import 'model/LocationGroupModel/resource';
import 'model/LocationModel/resource';
import 'model/Plugin';
import 'model/InformingTemplateModel/resource';

import 'directive/locationsPopup/main';
import 'directive/customValidator/main';

import moduleLoader from 'agModules/moduleLoader';
import {ImageUploaderHandler} from "../../../../module/imageUploader/imageUploaderHandler";
import { EventEmitter } from "@angular/core";
import CouponRule from "model/CouponRule";


export default ['$scope', '$state', '$uibModal', '$filter', '$interval', '$timeout', '$q', 'CampaignCommonModel', 'CampaignModel',  'CampaignItem', 'PartnerList', 'CampaignCategoryList', 'MechanicModel', 'Plugin', 'MsgTemplate',  'segmentsList', 'groupsList','CampaignResources', 'realValuesCampaign', 'loyaPermissions', 'CampaignLimitationsList', 'InformingTemplateModel', 'modulesStorage','dibsCampaign', 'settingsList', 'LocationModel', 'LocationGroupModel',
            function ($scope, $state, $uibModal, $filter, $interval, $timeout, $q, CampaignCommonModel, CampaignModel, CampaignItem, PartnerList, CampaignCategoryList, MechanicModel, Plugin, MsgTemplate, segmentsList, groupsList, CampaignResources, realValuesCampaign, loyaPermissions, CampaignLimitationsList, InformingTemplateModel, modulesStorage, dibsCampaign, settingsList, LocationModel, LocationGroupModel) {

                $scope.CampaignItem  = CampaignItem;
                $scope.PartnerList   = PartnerList;
                $scope.dibsCampaign  = dibsCampaign;
                $scope.settingsList  = settingsList;

                var filterMechanic = function (mechanicsList) {

                    if ($scope.CampaignItem &&
                        $scope.CampaignItem.campaign && !angular.isUndefined($scope.CampaignItem.campaign.mechanicId)
                    ) {

                        angular.forEach(mechanicsList, function (value, key) {
                            if (
                                value.campaignId === $scope.CampaignItem.campaign.id &&
                                value.id === $scope.CampaignItem.campaign.mechanicId
                            ) {
                                value.$current = true;
                            } else {
                                delete value.$current;
                            }

                        });

                        return mechanicsList;
                    }
                };

                $scope.getMsgTemplateList = function() {

                    let resultArray: any  = [];

                    resultArray.$promise = $q.all([

                        MsgTemplate.query( { status: 'template', 'pager.drop': 0, 'pager.limit': 99999 } ).$promise,
                        MsgTemplate.query( { status: 'campaign', 'pager.drop': 0, 'pager.limit': 99999  } ).$promise,

                    ]).then( ( templates ) => {

                        if (templates[0])
                            for ( let i of templates[0] ) {
                                resultArray.push(i);
                            }

                        if (templates[1])
                            for ( let i of templates[1] ) {
                                resultArray.push(i);
                            }

                    });

                    return resultArray;

                };

                let templateCache={};
                $scope.getTemplateById = function( id ) {

                    if (typeof id === "undefined")
                        return;

                    if ( !templateCache[id] ) {
                        templateCache[id] = MsgTemplate.get({ id: id });
                    }

                    return templateCache[id];

                };


                $scope.CampaignCategoryList = CampaignCategoryList;
                $scope.CampaignLimitationsList = CampaignLimitationsList;
                $scope.mechanicsList = MechanicModel.query(filterMechanic);
                $scope.MsgTemplateList = $scope.getMsgTemplateList();

                $scope.informingUnionList  = [ { id: undefined, name: '-- НЕТ --' } ];
                $scope.informingList   = Plugin.getInforming().installed();
                $scope.informingTemplatesList = [];

                $scope.segmentsList = segmentsList;
                $scope.groupsList = groupsList;
                $scope.options = {};
                $scope.realValuesCampaign = realValuesCampaign;
                $scope._t = new CampaignModel();
                $scope.statusValues = $scope._t.getField('campaign.state', 'values');
                $scope.completenessValues = $scope._t.getField('campaign.completeness', 'values');
                $scope.completenessvaluesStates = $scope._t.getField('campaign.completeness', 'valuesStates');


                var backupCampaign;
                var formStates = {
                    view: {
                        buttons: {
                            'edit': function () {
                                backupCampaign = angular.copy($scope.CampaignItem);
                                $scope.setFormState('edit');
                            }
                        },
                        activeForm: false
                    },

                    edit: {
                        buttons: {

                            'save': function () {
                                return $scope.saveCampaign();
                            },

                            'cancel': function () {

                                if (!$scope.CampaignItem.campaign || !$scope.CampaignItem.campaign.id) {
                                    $state.go('^.index');
                                } else {
                                    $scope.CampaignItem = angular.copy(backupCampaign);
                                    $scope.setFormState('view');
                                }

                            }
                        },
                        activeForm: true

                    }
                };

                var calculateCreator = (function () {
                    var callbacks = [];
                    return {
                        setCalculateWatchers: function () {

                            try {
                                if ($scope.CampaignItem.campaign.partnerId === undefined && $scope.PartnerList.length == 1) {
                                    $scope.CampaignItem.campaign.partnerId = $scope.PartnerList[0].id;
                                }
                            } catch (e) {
                                $scope.CampaignItem = {campaign: {partnerId: $scope.PartnerList[0].id}};
                            }


                            if (callbacks.length)
                                return;

                            callbacks = [

                                // Целевая группа
                                $scope.$watchGroup(['targetGroupInfo', 'campaignTimeCount.trigger.hours'], function (newVal) {

                                    if (newVal[0] && newVal[0].$promise)
                                        newVal[0].$promise.then(function (targetGroupInfoItem) {

                                            if (targetGroupInfoItem && targetGroupInfoItem.clientCount !== undefined) {
                                                $scope.CampaignItem.planValues.clientsCount = targetGroupInfoItem.clientCount;
                                            }

                                            if (targetGroupInfoItem && targetGroupInfoItem.extended && targetGroupInfoItem.extended.total !== undefined && newVal[1] !== undefined) {
                                                $scope.CampaignItem.planValues.prevTotal = ( ( parseFloat(targetGroupInfoItem.extended.total) / targetGroupInfoItem.extended.timeSize ) * newVal[1] ).toFixed(2);
                                            }

                                        });


                                }, true),


                                // Планируемое кол-во срабатываний
                                $scope.$watch(function () {
                                    var res = {Nt: 0, T: 0, P: 0, Tc: 0};

                                    try {
                                        res.Nt = $scope.targetGroupInfo.extended.checkCount;
                                    } catch (e) {
                                        res.Nt = 0;
                                    }

                                    try {
                                        res.T = $scope.targetGroupInfo.extended.timeSize;
                                    } catch (e) {
                                        res.T = 0;
                                    }

                                    try {
                                        res.P = $scope.CampaignItem.planValues.responsePercent;
                                    } catch (e) {
                                        res.P = 0;
                                    }

                                    try {
                                        res.Tc = $scope.campaignTimeCount.trigger.hours;
                                    } catch (e) {
                                        res.Tc = 0;
                                    }

                                    return res;
                                }, function (newVal) {

                                    if (newVal && newVal.T !== 0) {
                                        $scope.CampaignItem.planValues.operationCount = parseFloat(((newVal.Nt * newVal.P ) / (newVal.T * 100 ) * newVal.Tc ).toFixed(0)) || 0;
                                    } else {
                                        $scope.CampaignItem.planValues.operationCount = 0;
                                    }

                                }, true),


                                // Планируемый отклик
                                $scope.$watch(function () {
                                    return {
                                        clientsCount: parseFloat($scope.CampaignItem.planValues.clientsCount) || 0,
                                        responsePercent: parseFloat($scope.CampaignItem.planValues.responsePercent) || 0
                                    };
                                }, function (newVal) {

                                    $scope.CampaignItem.planValues.responseCount = Math.round( (newVal.clientsCount * newVal.responsePercent ) / 100  ) || 0;

                                }, true),


                                //Расходы на SMS сообщения
                                $scope.$watch(function () {
                                    return {
                                        smsPrice: parseFloat($scope.targetGroupInfo.smsPrice),
                                        smsCount: $scope.targetGroupInfo.smsCount,
                                        smsPrefer: $scope.targetGroupInfo.smsPrefer,
                                        usePrefer: $scope.CampaignItem.campaign.communication.usePrefer
                                    };
                                }, function (newVal) {

                                    if (newVal) {

                                        if (newVal.usePrefer && newVal.smsPrefer !== undefined && newVal.smsPrice !== undefined)
                                            $scope.CampaignItem.planValues.smsTotal = newVal.smsPrefer * newVal.smsCount;

                                        if (!newVal.usePrefer && newVal.smsCount !== undefined && newVal.smsPrice !== undefined)
                                            $scope.CampaignItem.planValues.smsTotal = newVal.smsPrice * newVal.smsCount;
                                    }

                                }, true),


                                //Расходы на E-mail сообщения
                                $scope.$watch(function () {
                                    return {
                                        emailPrice: parseFloat($scope.targetGroupInfo.emailPrice),
                                        emailCount: parseFloat($scope.targetGroupInfo.emailCount),
                                        emailPrefer: parseFloat($scope.targetGroupInfo.emailPrefer),
                                        usePrefer: parseFloat($scope.CampaignItem.campaign.communication.usePrefer)
                                    };
                                }, function (newVal) {

                                    if (newVal) {

                                        if (newVal.usePrefer && newVal.emailPrefer !== undefined && newVal.emailPrice !== undefined)
                                            $scope.CampaignItem.planValues.emailTotal = parseFloat((newVal.emailPrefer * newVal.emailPrice).toFixed(2)) || 0;

                                        if (!newVal.usePrefer && newVal.emailCount !== undefined && newVal.emailPrice !== undefined)
                                            $scope.CampaignItem.planValues.emailTotal = parseFloat((newVal.emailPrice * newVal.emailCount).toFixed(2)) || 0;


                                    }

                                }, true),

                                $scope.$watch('planeGifts', function (newVal) {

                                    if (newVal) {

                                        $scope.CampaignItem.planValues.giftTotal = 0;

                                        angular.forEach(newVal, function (gift) {

                                            if (parseFloat(gift.price) >= 0 && parseFloat(gift.planCount) >= 0) {
                                                $scope.CampaignItem.planValues.giftTotal += parseFloat(gift.price) * parseFloat(gift.planCount);
                                            }

                                        });

                                        $scope.CampaignItem.planValues.giftTotal = parseFloat($scope.CampaignItem.planValues.giftTotal.toFixed(2));

                                    }

                                }, true),


                                // Всего расходов
                                $scope.$watch(function () {
                                    var res = {
                                        emailTotal: 0,
                                        smsTotal: 0,
                                        discountTotal: 0,
                                        bonusTotal: 0,
                                        giftTotal: 0,
                                        additionalTotal: 0
                                    };

                                    try {
                                        if (( $scope.CampaignItem.campaign.communication.useEmail || $scope.CampaignItem.campaign.communication.usePrefer))
                                            res.emailTotal = parseFloat($scope.CampaignItem.planValues.emailTotal) || 0;
                                    } catch (e) {
                                    }

                                    try {
                                        if (( $scope.CampaignItem.campaign.communication.useEmail || $scope.CampaignItem.campaign.communication.usePrefer))
                                            res.smsTotal = parseFloat($scope.CampaignItem.planValues.smsTotal) || 0;
                                    } catch (e) {
                                    }

                                    try {
                                        if ($scope.mechanicFlags.discount)
                                            res.discountTotal = parseFloat($scope.CampaignItem.planValues.discountTotal) || 0;
                                    } catch (e) {
                                    }

                                    try {
                                        if ($scope.mechanicFlags.bonus)
                                            res.bonusTotal = parseFloat($scope.CampaignItem.planValues.bonusTotal) || 0;
                                    } catch (e) {
                                    }

                                    try {
                                        if ($scope.mechanicFlags.gift)
                                            res.giftTotal = parseFloat($scope.CampaignItem.planValues.giftTotal) || 0;
                                    } catch (e) {
                                    }

                                    try {
                                        res.additionalTotal = parseFloat($scope.CampaignItem.planValues.additionalTotal) || 0;
                                    } catch (e) {
                                    }


                                    return res;
                                }, function (newVal) {

                                    if (newVal !== undefined) {
                                        $scope.CampaignItem.planValues.totalCost = parseFloat(( newVal.emailTotal +
                                        newVal.smsTotal +
                                        newVal.discountTotal +
                                        newVal.bonusTotal +
                                        newVal.giftTotal +
                                        newVal.additionalTotal ).toFixed(2));


                                    }

                                }, true),


                                // Расходы на одного клиента
                                $scope.$watch(function () {
                                    return {
                                        clientsCount: parseFloat($scope.CampaignItem.planValues.clientsCount) || 0,
                                        totalCost: parseFloat($scope.CampaignItem.planValues.totalCost) || 0
                                    };
                                }, function (newVal) {

                                    if (newVal.clientsCount) {
                                        $scope.CampaignItem.planValues.totalByClient = parseFloat(( newVal.totalCost / newVal.clientsCount ).toFixed(2));
                                    } else {
                                        $scope.CampaignItem.planValues.totalByClient = 0;
                                    }


                                }, true),

                                // Средняя выручка от одного клиента текущая
                                $scope.$watch(function () {
                                    var res = {St1: 0, T: 0, Nt1: 0, P: 0, Tc: 0};

                                    try {
                                        res.St1 = parseFloat($scope.targetGroupInfo.extended.total);
                                    } catch (e) {
                                        res.St1 = 0;
                                    }

                                    try {
                                        res.T = $scope.targetGroupInfo.extended.timeSize;
                                    } catch (e) {
                                        res.T = 0;
                                    }

                                    try {
                                        res.P = $scope.CampaignItem.planValues.responsePercent;
                                    } catch (e) {
                                        res.P = 0;
                                    }

                                    try {
                                        res.Tc = $scope.campaignTimeCount.trigger.hours;
                                    } catch (e) {
                                        res.Tc = 0;
                                    }

                                    try {
                                        res.Nt1 = $scope.CampaignItem.planValues.clientsCount;
                                    } catch (e) {
                                        res.Nt1 = 0;
                                    }

                                    return res;
                                }, function (newVal) {

                                    if (newVal && newVal.T !== 0 && newVal.Nt1 !== 0) {
                                        $scope.CampaignItem.planValues.clientTotal = parseFloat(( (newVal.St1 / (newVal.T * newVal.Nt1 ) ) * newVal.P * newVal.Tc ).toFixed(2)) || 0;
                                    } else {
                                        $scope.CampaignItem.planValues.clientTotal = 0;
                                    }

                                }, true),

                                $scope.$watch(function () {
                                        return {
                                            clientTotalPercent: parseFloat($scope.CampaignItem.planValues.clientTotalPercent) || 0,
                                            clientTotal: parseFloat($scope.CampaignItem.planValues.clientTotal) || 0,
                                            clientSelling: parseFloat($scope.CampaignItem.planValues.clientSelling) || 0,
                                            clientInitiator: $scope.clientInitiator,
                                            responsePercent: $scope.CampaignItem.planValues.responsePercent
                                        };
                                    },
                                    function (newVal, oldValue) {

                                        if (newVal.clientInitiator !== oldValue.clientInitiator &&
                                            newVal.clientInitiator === false)
                                            return;


                                        if (newVal.clientInitiator === false) {

                                            if (newVal.clientTotalPercent !== oldValue.clientTotalPercent) {


                                                try {

                                                    // @ts-ignore
                                                    $scope.CampaignItem.planValues.clientSelling = parseFloat( $scope.CampaignItem.planValues.clientTotal * ( $scope.CampaignItem.planValues.clientTotalPercent / 100 + 1 ) ).toFixed(2) || 0 ;

                                                } catch (e) {
                                                    $scope.CampaignItem.planValues.clientSelling = 0;
                                                }


                                            }

                                            if (newVal.clientSelling !== oldValue.clientSelling || newVal.responsePercent !== oldValue.responsePercent) {

                                                if ( $scope.CampaignItem.planValues && $scope.CampaignItem.planValues.clientTotal !== 0) {

                                                    $scope.CampaignItem.planValues.clientTotalPercent = parseFloat(( ( (  $scope.CampaignItem.planValues.clientSelling - $scope.CampaignItem.planValues.clientTotal ) / $scope.CampaignItem.planValues.clientTotal ) * 100).toFixed(2)) || 0;

                                                } else
                                                    $scope.CampaignItem.planValues.clientTotalPercent = 0;


                                            }

                                        } else {

                                            if (newVal.clientTotalPercent !== oldValue.clientTotalPercent && newVal.clientInitiator == 'clientTotalPercent') {


                                                try {
                                                    // @ts-ignore
                                                    $scope.CampaignItem.planValues.clientSelling = parseFloat( $scope.CampaignItem.planValues.clientTotal * ( $scope.CampaignItem.planValues.clientTotalPercent / 100 + 1 ) ).toFixed(2) || 0 ;

                                                } catch (e) {
                                                    $scope.CampaignItem.planValues.clientSelling = 0;
                                                }


                                            }

                                            if (newVal.clientSelling !== oldValue.clientSelling && newVal.clientInitiator == 'clientSelling') {

                                                if ( $scope.CampaignItem.planValues && $scope.CampaignItem.planValues.clientTotal !== 0) {

                                                    $scope.CampaignItem.planValues.clientTotalPercent = parseFloat(( ( (  $scope.CampaignItem.planValues.clientSelling - $scope.CampaignItem.planValues.clientTotal ) / $scope.CampaignItem.planValues.clientTotal ) * 100).toFixed(2)) || 0;

                                                } else
                                                    $scope.CampaignItem.planValues.clientTotalPercent = 0;


                                            }

                                        }

                                    }, true),

                                /*

                                 // Средняя выручка от одного клиента плановая
                                 $scope.$watch(function () {
                                 return {
                                 clientTotalPercent: parseFloat($scope.CampaignItem.planValues.clientTotalPercent) || 0,
                                 clientTotal: parseFloat($scope.CampaignItem.planValues.clientTotal) || 0
                                 };
                                 }, function (newVal, oldValue) {


                                 try {
                                 $scope.CampaignItem.planValues.clientSelling = parseFloat(( ( -newVal.clientTotal ) / (  newVal.clientTotalPercent - 1 ) ).toFixed(2));
                                 } catch (e) {
                                 $scope.CampaignItem.planValues.clientSelling = 0;
                                 }

                                 }, true),


                                 // Средняя выручка от одного клиента рост в процентах
                                 $scope.$watch(function () {
                                 return {
                                 clientSelling: parseFloat($scope.CampaignItem.planValues.clientSelling) || 0,
                                 clientTotal: parseFloat($scope.CampaignItem.planValues.clientTotal) || 0
                                 };
                                 }, function (newVal, oldValue) {



                                 if (newVal && newVal.clientTotal !== 0) {

                                 $scope.CampaignItem.planValues.clientTotalPercent = parseFloat(( ( newVal.clientTotal - newVal.clientSelling ) / newVal.clientTotal ).toFixed(2));
                                 } else
                                 $scope.CampaignItem.planValues.clientTotalPercent = 0;

                                 }, true),
                                 */

                                // Средний доход от одного клиента
                                $scope.$watch(function () {
                                    return {
                                        clientTotal: parseFloat($scope.CampaignItem.planValues.clientTotal) || 0,
                                        totalByClient: parseFloat($scope.CampaignItem.planValues.totalByClient) || 0
                                    };
                                }, function (newVal) {

                                    if (newVal) {
                                        $scope.CampaignItem.planValues.clientIncome = parseFloat(( newVal.clientTotal - newVal.totalByClient ).toFixed(2));
                                    }


                                }, true),


                                // План по росту выручки
                                $scope.$watch(function () {
                                    return {
                                        clientSelling: parseFloat($scope.CampaignItem.planValues.clientSelling) || 0,
                                        responseCount: parseFloat($scope.CampaignItem.planValues.responseCount) || 0
                                    };
                                }, function (newVal) {

                                    if (newVal) {
                                        $scope.CampaignItem.planValues.clientSellingTotal = parseFloat((  newVal.clientSelling * newVal.responseCount ).toFixed(2));
                                    }


                                }, true),


                                // План по росту дохода
                                $scope.$watch(function () {
                                    return {
                                        clientSellingTotal: $scope.CampaignItem.planValues.clientSellingTotal,
                                        totalCost: $scope.CampaignItem.planValues.totalCost
                                    };
                                }, function (newVal) {

                                    if (newVal) {
                                        $scope.CampaignItem.planValues.clientIncomePercent = parseFloat((newVal.clientSellingTotal - newVal.totalCost).toFixed(2));
                                    }


                                }, true)


                            ];

                            $scope.selectLocationList = function (newVal) {

                                if (!newVal)
                                    return;

                                $scope.CampaignItem.location = [];
                                angular.forEach(newVal.locations, function (val, key) {

                                    $scope.CampaignItem.location.push({
                                        merchantId: val.id,
                                        name: val.name
                                    });

                                });

                            };

                            $scope.removeLocation = function (index) {

                                $scope.CampaignItem.location.splice(index, 1);

                            };

                        },
                        clearCalculateWatchers: function () {

                            if (!callbacks.length)
                                return;

                            for (var i in  callbacks) {
                                if (typeof  callbacks[i] == 'function')
                                    callbacks[i]();
                            }

                            callbacks = [];
                        }
                    };
                })();

                $scope.setFormState = function (state) {

                    if (formStates[state] !== undefined) {
                        $scope.curState = formStates[state];
                        $scope.curState.stateName = state;

                        resetMechanicList = false;

                        switch (state) {
                            case 'edit':
                                calculateCreator.setCalculateWatchers();
                                break;
                            case 'view':
                                calculateCreator.clearCalculateWatchers();
                                break;
                        }

                    } else
                        $scope.curState = false;

                };

                $scope.setFormState($scope.CampaignItem.campaign.id === undefined ? 'edit' : 'view');

                $scope.canRunCampaign = function() {

                    if ( $scope.CampaignItem.campaign && $scope.CampaignItem.campaign.communication ) {

                        if ( !loyaPermissions.byPath('channel.type').inFilter( 'viber' ) && $scope.CampaignItem.campaign.communication.useViber ) {
                            return false;
                        }

                    }

                    return true;

                };

                $scope.saveCampaign = function () {

                    $scope.CampaignItem.planValues = <any> angular.extend({
                        "clientsCount": 0,
                        "operationCount": 0,
                        "responseCount": 0,
                        "responsePercent": 0,
                        "emailTotal": "0.00",
                        "smsTotal": "0.00",
                        "discountTotal": "0.00",
                        "bonusTotal": 0,
                        "giftTotal": "0.00",
                        "additionalTotal": "0.00",
                        "totalCost": "0.00",
                        "totalByClient": "0.00",
                        "clientTotal": "0.00",
                        "clientTotalPercent": 0,
                        "clientSelling": "0.00",
                        "clientSellingTotal": "0.00",
                        "clientIncome": "0.00",
                        "clientIncomePercent": 0
                    }, $scope.CampaignItem.planValues);

                    for (var i in ['emailTotal', 'smsTotal', 'discountTotal', 'giftTotal', 'additionalTotal', 'totalCost', 'totalByClient', 'clientTotal',
                        'clientSelling', 'clientSellingTotal', 'clientIncome']) {
                        if ($scope.CampaignItem.planValues[i] !== undefined) {

                            $scope.CampaignItem.planValues[i] = $scope.CampaignItem.planValues[i].toString();
                        }
                    }

                    let queue = $q.resolve();

                    if (
                        !angular.isUndefined($scope.CampaignItem.campaign.informingId) ||
                        typeof $scope.CampaignItem.campaign.informingId === "string"
                    ) {
                        let filtered = $scope.informingList.filter( item => item.name == $scope.CampaignItem.campaign.informingId);

                        if (filtered.length) {
                            queue = queue.then( _=>
                                     $scope.createInforming(filtered[0])
                                        .then(template => {
                                            $scope.CampaignItem.campaign.informingId = template.id;
                                        })

                            );
                        }

                    }

                    return queue.then( _=> {
                        if ($scope.CampaignItem.campaign.id === undefined) {
                            return $scope.CampaignItem.$create(function (response) {
                                if (CampaignResources.length > 0)
                                  CampaignCommonModel.postCampaignResourcesInfo({id: $scope.CampaignItem.campaign.id}, CampaignResources);

                                $scope.MsgTemplateList = $scope.getMsgTemplateList();
                                $scope.MsgTemplateList.$promise.then( MsgTemplate.query({ status: 'campaign', 'pager.drop': 0, 'pager.limit': 99999  }).$promise );

                                $scope.updateInformigLists();
                                $scope.mechanicsList = MechanicModel.query(filterMechanic);
                                $state.go('^.view', {id: response.campaign.id}, {reload: true});
                            });
                        } else {
                            return $scope.CampaignItem.$update({id: $scope.CampaignItem.campaign.id}, function () {

                                if (CampaignResources.length > 0)
                                  CampaignCommonModel.postCampaignResourcesInfo({id: $scope.CampaignItem.campaign.id}, CampaignResources);

                                $scope.MsgTemplateList = $scope.getMsgTemplateList();
                                $scope.MsgTemplateList.$promise.then( MsgTemplate.query({ status: 'campaign', 'pager.drop': 0, 'pager.limit': 99999  }).$promise );

                                $scope.updateInformigLists();
                                $scope.mechanicsList = MechanicModel.query(filterMechanic);
                                $scope.setFormState('view');
                            });
                        }

                    }).then( () => $scope.commitImages());


                };

                $scope.templateFilter = function (template) {
                    return template.status === 'template' ||
                        template.id === $scope.CampaignItem.campaign.templateId;
                };

                // Проверка фронтальной кампании
                $scope.isFrontalCampaign = _ =>
                !(
                    // Если выбрана целевая аудитория
                    typeof $scope.CampaignItem.campaign.groupId   !== 'undefined' ||
                    typeof $scope.CampaignItem.campaign.segmentId !== 'undefined' ||
                    $scope.targetOptions.targetAudience           !== 'all' ||

                    // Если выбрано ограничение целевой аудитории
                    $scope.targetOptions.exceptionAudience              !== 'all' ||
                    typeof $scope.CampaignItem.campaign.exceptSegmentId !== 'undefined' ||
                    typeof $scope.CampaignItem.campaign.exceptGroupIds   !== 'undefined'
                )


                // проверка на оффлайн механику
                $scope.preventOffileMechanic = mechanic =>
                    mechanic.isOffline
                    && !$scope.isFrontalCampaign()

                // Валидатор для селекта
                $scope.preventOffline = function( value ) {

                    const res = $scope.mechanicsList.filter( mechanic => mechanic.id === value );

                        if (res && res.length && res[0].isOffline) {
                            return !$scope.preventOffileMechanic(res[0]);
                        }

                    return true;
                }

                var resetMechanicList = false;
                $scope.mechanicFilter = function (mechanic) {

                    if (!resetMechanicList && mechanic.$current) {
                        mechanic.currentName = mechanic.name + ' (текущая)';
                        return true;
                    }

                    if ( $scope.preventOffileMechanic( mechanic ) ) {

                        if ( $scope.CampaignItem.campaign.mechanicId === mechanic.id ) {
                            return true;
                        }

                        return false;
                    }

                    return ( mechanic.status === 'template' && angular.isUndefined(mechanic.campaignId) );
                };

                let currentMechanicId = $scope.CampaignItem.campaign.mechanicId
                $scope.mechanicFilterForComponent = function (mechanic) {

                  if (!mechanic)
                    return false;

                  if ( mechanic.$isCurrent)
                    return  true;

                  if (!resetMechanicList && mechanic.id === currentMechanicId) {
                    return false;
                  }

                  if ( $scope.preventOffileMechanic( mechanic ) ) {

                    if ( $scope.CampaignItem.campaign.mechanicId === mechanic.id ) {
                      return true;
                    }

                    return false;
                  }

                  return ( mechanic.status === 'template' && angular.isUndefined(mechanic.campaignId) );
                };



              $scope.$watch('CampaignItem.campaign.communication', function (newVal) {

                    try {
                        switch (true) {

                            case newVal.usePrefer && !newVal.useEmail && !newVal.useSms && !newVal.useViber :
                                $scope.prefChannel = 'pref';
                                break;

                            case !newVal.usePrefer && !newVal.useViber &&
                            newVal.useEmail && !newVal.useSms :
                                $scope.prefChannel = 'email';
                                break;

                            case !newVal.usePrefer && !newVal.useEmail && !newVal.useViber &&
                            newVal.useSms :
                                $scope.prefChannel = 'sms';
                                break;

                            case !newVal.usePrefer && !newVal.useEmail && !newVal.useSms &&
                            newVal.useViber :
                                $scope.prefChannel = 'viber';
                                break;


                            case !newVal.usePrefer &&
                            newVal.useEmail &&
                            newVal.useSms :
                                $scope.prefChannel = 'ES';
                                break;

                            case !newVal.usePrefer &&
                            newVal.useEmail &&
                            newVal.useViber :
                                $scope.prefChannel = 'EV';
                                break;

                            case !newVal.usePrefer &&
                            !newVal.useEmail &&
                            !newVal.useSms :
                                $scope.prefChannel = 'notUse';
                                break;
                        }
                    } catch (e) {
                        $scope.prefChannel = 'none';
                    }

                });


                $scope.prefChannelList = [
                    {id: 'notUse', name: 'Не рассылать'},
                    {id: 'pref',   name: 'По предпочтению'},
                    {id: 'email',  name: 'E-Mail'},
                    {id: 'viber',  name: 'Viber'},
                    {id: 'sms',    name: 'SMS'},
                    {id: 'ES',     name: 'E-Mail и SMS'},
                    {id: 'EV',     name: 'E-Mail и Viber'}
                ];

                $scope.filterPrefChannelList = function(item, index, array) {

                    switch ( item.id ) {
                        case 'sms'  : return loyaPermissions.byPath('channel.type').inFilter('sms');
                        case 'email': return loyaPermissions.byPath('channel.type').inFilter('email');
                        case 'ES'  :  return loyaPermissions.byPath('channel.type').inFilter( ['sms'   , 'email'] );
                        case 'EV'  :  return loyaPermissions.byPath('channel.type').inFilter( ['viber' , 'email'] );
                        case 'viber' :  return loyaPermissions.byPath('channel.type').inFilter( 'viber' );

                        default: return true;
                    }

                };

                $scope.$watch('prefChannel', function (newVal) {

                    var cs;
                    try {
                        cs = $scope.CampaignItem.campaign.communication;
                    } catch (e) {
                        return;
                    }


                    switch (newVal) {
                        case 'pref':
                            cs.usePrefer = true;
                            cs.useEmail = false;
                            cs.useSms = false;
                            cs.notUse = false;
                            cs.useViber = false;
                            break;
                        case 'email':
                            cs.usePrefer = false;
                            cs.useEmail = true;
                            cs.useSms = false;
                            cs.notUse = false;
                            cs.useViber = false;
                            break;
                        case 'sms':
                            cs.usePrefer = false;
                            cs.useEmail = false;
                            cs.useSms = true;
                            cs.notUse = false;
                            cs.useViber = false;
                            break;
                        case 'viber':
                            cs.usePrefer = false;
                            cs.useEmail = false;
                            cs.useSms = false;
                            cs.notUse = false;
                            cs.useViber = true;
                            break;
                        case 'ES':
                            cs.usePrefer = false;
                            cs.useEmail = true;
                            cs.useSms = true;
                            cs.notUse = false;
                            cs.useViber = false;
                            break;
                        case 'EV':
                            cs.usePrefer = false;
                            cs.useEmail = true;
                            cs.useSms = false;
                            cs.notUse = false;
                            cs.useViber = true;
                            break;
                        case 'notUse':
                            cs.usePrefer = false;
                            cs.useEmail = false;
                            cs.useSms = false;
                            cs.notUse = true;
                            cs.useViber = false;
                            break;
                    }

                });


                $scope.daysOfWeekDics   = [
                    { key: 1, name :'Пн' },
                    { key: 2, name :'Вт' },
                    { key: 3, name :'Ср' },
                    { key: 4, name :'Чт' },
                    { key: 5, name :'Пт' },
                    { key: 6, name :'Сб' },
                    { key: 7, name :'Вс' }
                ];

                $scope.daysOfMonthDics   = [];
                for ( let i =1; i<=31; i++) {
                  $scope.daysOfMonthDics.push({
                    key: i, name : i.toString()
                  })
                }


                $scope.isEnabledWeekDay = ( key, array) => array.indexOf(key)>=0;

                $scope.changeWeekDay = ( isEnabled, key, array) => {

                    if ( isEnabled ) {

                        if ( !$scope.isEnabledWeekDay(key, array) ) {
                            array.push(key);
                        }

                    } else {

                        let index = array.indexOf(key);
                        if ( index>=0 ) {
                            array.splice(index,1);
                        }

                    }

                };

                $scope.isEnabledMonthDay = ( key, array) => Array.isArray(array) ? array.indexOf(key)>=0 : false;

                $scope.changeMonthDay = ( isEnabled, key, period) => {

                  if ( isEnabled ) {

                    if ( !$scope.isEnabledMonthDay(key, period.daysOfMonth) ) {
                      period.daysOfMonth = period.daysOfMonth || [];
                      period.daysOfMonth.push(key);
                    }

                  } else {

                    if ( Array.isArray(period.daysOfMonth) ) {
                      let index = period.daysOfMonth.indexOf(key);
                      if ( index>=0 ) {
                        period.daysOfMonth.splice(index,1);
                      }

                      if (!period.daysOfMonth?.length)
                        delete period.daysOfMonth;

                    }

                  }

                };

                $scope.hasEnabledDays = (period) => {
                  if ( Array.isArray(period) )
                    return !!period.length

                  return !!period?.daysOfMonth?.length;
                }

                $scope.$watch('CampaignItem.campaign.partnerId', function (newVal, oldVal) {

                    if (newVal !== undefined) {
                        var params = {
                          filter : {
                            partnerId: newVal
                          },
                          pager: {
                            drop: 0,
                            limit: 99999,
                          }
                        };

                        $scope.LocationGroupList = LocationGroupModel.query(params, function (locations) {
                            if (angular.isArray(locations) && locations.length > 0)
                                locations.unshift({
                                    createdBy: "user",
                                    id: false,
                                    name: "--НЕТ--",
                                    partnerId: newVal
                                });

                            return locations;
                        });
                        $scope.LocationList = LocationModel.queryLocations({
                          filter: {
                            partnerId: newVal
                          },
                          pager:{
                            limit: 10
                          }
                        });

                        if (newVal !== oldVal) {
                            if ($scope.CampaignItem && $scope.CampaignItem.location) {
                                delete $scope.CampaignItem.location;
                            }

                            if ($scope.CampaignItem && $scope.CampaignItem.locationGroup) {
                                delete $scope.CampaignItem.locationGroup;
                            }

                            if ($scope.CampaignItem && $scope.CampaignItem.locationGroup) {
                                delete $scope.CampaignItem.locationGroup;
                            }

                            if ($scope.CampaignItem && $scope.CampaignItem.campaign && !angular.isUndefined($scope.CampaignItem.campaign.mechanicId)) {
                                resetMechanicList = true;
                                delete $scope.CampaignItem.campaign.mechanicId;
                            }

                        }
                    }
                });

                /****** informing ******/
                $scope.currentInformingTemplate = false;
                $scope.createInforming = function( plugin ) {

                    let newTemplate = new InformingTemplateModel(
                        {
                            "name":        plugin.description,
                            "description": plugin.description,
                            "plugin":      plugin.name,
                            "version":     plugin.version,
                            "status":      "template",
                            "extension":   {}
                        }
                    );

                    let pluginOptions = modulesStorage.get(    plugin.name ,  'system.modules.modules.manifest');

                    if ( !pluginOptions || !pluginOptions.options || !pluginOptions.options.skipConfig ) {
                        return newTemplate.$createInformingTemplate();
                    }

                    return newTemplate
                            .$createInformingTemplate()
                                .then( result => {
                                    result.status = "campaign";
                                    return result.$updateInformingTemplateById();
                                });


                }

                $scope.$watch('CampaignItem.campaign.informingId', newValue => {

                    if (angular.isUndefined(newValue) || typeof newValue === 'string') {
                        $scope.currentInformingTemplate = false;
                        return;
                    }

                    $scope.currentInformingTemplate = InformingTemplateModel.getInformationTemplateById({id:newValue});

                });

                $scope.updateInformigLists = function() {

                    $scope.informingUnionList  = [ { id: undefined, name: '-- НЕТ --' } ];

                    $scope.informingList.$promise.then( items =>
                      items
                        .filter(i => i.name !== "System" )
                        .forEach( item => $scope.informingUnionList.push(
                            { id: item.name, name: item.description }
                        ) ) );

                    $scope.informingTemplatesList = InformingTemplateModel.queryInformingTemplates();
                    /*$scope.informingTemplatesList.$promise.then( list => list.filter( item => item.status === 'template' || item.id === $scope.CampaignItem.campaign.informingId ) )
                        .then( list => list.forEach( item => $scope.informingUnionList.push(
                            { id: item.id, name: item.name + (item.id === $scope.CampaignItem.campaign.informingId ? ' ( текущая )' : '' ) }
                        ) ) );*/
                    $scope.informingTemplatesList.$promise.then( list => list.filter( item => item.id === $scope.CampaignItem.campaign.informingId  ) )
                        .then( list => list.forEach( item => $scope.informingUnionList.push(
                            { id: item.id, name: item.name + (item.id === $scope.CampaignItem.campaign.informingId ? ' ( текущая )' : '' ) }
                        ) ) );

                }

                $scope.updateInformigLists();

                /*****   completeness   ***/
                $scope.completenessState = {
                    inProgress: false,
                    message: $scope.completenessvaluesStates.planned.error
                };

                $scope.$updateCompletenessTimer = false;

                $scope.isExternalLoyalty = () => {
                  return $scope.settingsList.externalLoyalty === 'true';
                }

                if ($scope.settingsList.externalLoyalty) {
                  $scope.CampaignItem.elSettings = $scope.CampaignItem.elSettings || {};
                } else {
                  delete $scope.CampaignItem.elSettings;
                }

                var completnessUpdater = function (interval?) {

                    if (angular.isUndefined(interval)) {
                        interval = 1000;
                    }

                    if ($scope.$updateCompletenessTimer)
                        $interval.cancel($scope.$updateCompletenessTimer);

                    $scope.$updateCompletenessTimer = $interval(function () {

                        if ($scope.CampaignItem &&
                            $scope.CampaignItem.campaign && !angular.isUndefined($scope.CampaignItem.campaign.id))

                            CampaignModel.getState({id: $scope.CampaignItem.campaign.id}, function (result) {

                                if (result.state)
                                    $scope.CampaignItem.campaign.state = result.state;

                                if (result.completeness)
                                    $scope.CampaignItem.campaign.completeness = result.completeness;

                            });


                    }, interval);

                };

                $scope.$on('$destroy', function () {
                    if ($scope.$updateCompletenessTimer)
                        $interval.cancel($scope.$updateCompletenessTimer);
                });

                $scope.$watch('CampaignItem.campaign.completeness', function (newVal) {

                    if (angular.isUndefined(newVal))
                        return;

                    $scope.completenessState = {
                        inProgress: false,
                        message: ''
                    };

                    switch (newVal) {

                        case "unknown":
                        case "started":

                            $scope.completenessState.inProgress = true;
                            completnessUpdater(1000);
                            break;

                        case "finished":
                            $scope.completenessState.inProgress = false;
                            /*
                            if ($scope.$updateCompletenessTimer)
                                $interval.cancel($scope.$updateCompletenessTimer);
                             */
                            completnessUpdater(5000);
                            break;

                        case "error":
                            $scope.completenessState.inProgress = true;
                            completnessUpdater(1000);
                            break;

                    }

                    $scope.completenessState.message = ( $scope.completenessvaluesStates[$scope.CampaignItem.campaign.state] && $scope.completenessvaluesStates[$scope.CampaignItem.campaign.state][newVal] ) ? $scope.completenessvaluesStates[$scope.CampaignItem.campaign.state][newVal] : '';

                });

                completnessUpdater();

                (function(){

                    var indexes = {};
                    var weightArray = [];

                    $scope.liIndex = function( key , weight) {

                        if ( angular.isUndefined( indexes[key] ) ) {

                            let weightItem = {
                                index: key,
                                weight: weight
                            };

                            weightArray.push( weightItem );
                            weightArray = weightArray.sort( (item1, item2) => item1.weight - item2.weight );
                            indexes[key] = weightItem;
                        }

                        return weightArray.indexOf(indexes[key]) + 1;
                    };

                })();


                /***********************************/

                $scope.$watch('CampaignItem.locationGroup', function (newVal) {

                    if (newVal && newVal[0]) {
                        $scope.selectedLocationGroupList = newVal[0].groupId;
                    } else {
                        $scope.selectedLocationGroupList = false;
                    }
                }, true);

                $scope.$watch('selectedLocationGroupList', function (newVal) {

                    if (newVal !== undefined) {

                        if (newVal === false) {
                            $scope.CampaignItem.locationGroup = [];
                        } else
                            for (var i in $scope.LocationGroupList) {

                                if ($scope.LocationGroupList[i].id !== undefined && $scope.LocationGroupList[i].id === newVal) {

                                    $scope.CampaignItem.locationGroup = [{
                                        groupId: newVal,
                                        name: $scope.LocationGroupList[i].name
                                    }];

                                    return;
                                }
                            }
                    }

                });

                $scope.$watch('CampaignItem.campaign.campaignTime', function (newVal) {

                    if (newVal && newVal.stopDate && newVal.startDate) {

                        $scope.campaignTimeCount = CampaignModel.campaigntimecount(angular.copy(newVal));

                    }

                }, true);

                $scope.createCampaignPeriod = function() {

                    $scope.CampaignItem.campaign.campaignTime.periods = $scope.CampaignItem.campaign.campaignTime.periods || [];
                    $scope.CampaignItem.campaign.campaignTime.periods.push( {

                        "startTime" : new Date(1970, 0, 1,  0,  0, 0),
                        "stopTime"  : new Date(1970, 0, 1, 23, 59, 0),
                        "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]

                    } );

                };

                $scope.targetOptions = {};

                if ( loyaPermissions.byPath('segments').enabled ) {
                    $scope.targetOptions.targetAudience = $scope.CampaignItem.campaign.segmentId !== undefined ? 'segment' : $scope.CampaignItem.campaign.groupId !== undefined ? 'group' : 'all';
                } else {

                    if ( typeof $scope.CampaignItem.campaign.groupId === "undefined" ) {
                        $scope.targetOptions.targetAudience = 'all';
                    }

                    let group = $scope.groupsList.find( group => group.group.id === $scope.CampaignItem.campaign.groupId );

                    if ( !group ) {
                        $scope.targetOptions.targetAudience = 'all';
                    } else {
                        switch ( group.group.name ) {
                            case 'Все УПЛ':   $scope.targetOptions.targetAudience = 'upl'; break;
                            case 'Все неУПЛ': $scope.targetOptions.targetAudience = 'nupl'; break;
                            default: $scope.targetOptions.targetAudience = 'all';
                        }
                    }

                }

                $scope.targetGroupInfo = {};
                $scope.$watchGroup(['targetOptions.targetAudience', 'CampaignItem.campaign.partnerId'], function (newVal) {
                    if (newVal[0] === undefined || newVal[1] === undefined) {
                        return;
                    }

                    try {

                        switch (newVal[0]) {

                            case 'all':
                                delete $scope.CampaignItem.campaign.segmentId;
                                delete $scope.CampaignItem.campaign.groupId;

                                $scope.updateTargetInfoService();
                                break;

                            case 'group':
                                delete $scope.CampaignItem.campaign.segmentId;
                                $scope.updateTargetInfoService();
                                break;
                            case 'segment':
                                delete $scope.CampaignItem.campaign.groupId;
                                $scope.updateTargetInfoService();
                                break;

                            case 'upl':
                                delete $scope.CampaignItem.campaign.segmentId;
                                delete $scope.CampaignItem.campaign.groupId;

                                let uplGroup = $scope.groupsList.find( group => group.group.name === "Все УПЛ" );
                                if (uplGroup)
                                    $scope.CampaignItem.campaign.groupId =  uplGroup.group.id;

                                $scope.updateTargetInfoService();
                                break;

                            case 'nupl':
                                delete $scope.CampaignItem.campaign.segmentId;
                                delete $scope.CampaignItem.campaign.groupId;

                                let nuplGroup = $scope.groupsList.find( group => group.group.name === "Все неУПЛ" );
                                if (nuplGroup)
                                    $scope.CampaignItem.campaign.groupId =  nuplGroup.group.id;

                                $scope.updateTargetInfoService();
                                break;

                        }
                    } catch (e) {
                    }

                });

                $scope.targetOptions.exceptionAudience =
                      $scope.CampaignItem.campaign.exceptSegmentId !== undefined  ? 'segment' :
                        $scope.CampaignItem.campaign.exceptGroupIds !== undefined ? 'group' :
                                                                                     'all';

              $scope.$watchGroup(['targetOptions.exceptionAudience', 'CampaignItem.campaign.partnerId'], function (newVal) {
                    if (newVal[0] === undefined || newVal[1] === undefined) {
                        return;
                    }

                    try {
                        switch (newVal[0]) {

                            case 'all':
                                delete $scope.CampaignItem.campaign.exceptSegmentId;
                                delete $scope.CampaignItem.campaign.exceptGroupIds;
                                break;

                            case 'group':
                                delete $scope.CampaignItem.campaign.exceptSegmentId;
                                       $scope.CampaignItem.campaign.exceptGroupIds = $scope.CampaignItem.campaign.exceptGroupIds || [];
                                break;
                            case 'segment':
                                delete $scope.CampaignItem.campaign.exceptGroupIds;
                                break;
                        }
                    } catch (e) {
                    }

                });


                $scope.filterGroupByArray = ( selected ) => function(item) {
                    return selected === item.group.id || CampaignItem.campaign.exceptGroupIds.indexOf(item.group.id) < 0;
                };

                $scope.changeGroupInArray = function(checked, id) {

                  $scope.CampaignItem.campaign.exceptGroupIds = $scope.CampaignItem.campaign.exceptGroupIds || [];

                  if (checked)
                    $scope.CampaignItem.campaign.exceptGroupIds.push(id)
                  else {
                    let i = $scope.CampaignItem.campaign.exceptGroupIds.indexOf(id);
                    if (i>=0)
                      $scope.CampaignItem.campaign.exceptGroupIds.splice(i,1);

                  }
                }

                $scope.$watch('CampaignItem.campaign.segmentId', function (newVal) {
                    if ( !angular.isUndefined(newVal) ) {
                        $scope.updateTargetInfoService();
                    }


                });

                $scope.$watch('CampaignItem.campaign.groupId', function (newVal) {
                    if ( !angular.isUndefined(newVal) ) {
                        $scope.updateTargetInfoService();
                    }
                });

                $scope.$watch('CampaignItem.campaign.exceptSegmentId', function (newVal) {
                    if ( !angular.isUndefined(newVal) ) {
                        $scope.updateTargetInfoService();
                    }


                });

                $scope.$watch('CampaignItem.campaign.exceptGroupIds', function (newVal) {
                    if ( !angular.isUndefined(newVal) ) {
                        $scope.updateTargetInfoService();
                    }
                }, true);

                $scope.$watch('CampaignItem.campaign.mechanicId', function (newVal, oldVal) {
                    if ( !angular.isUndefined(newVal) ) {
                        $scope.mechanicsList.$promise.then( val =>
                            val.forEach( mechanic => {

                                if ( mechanic.id === newVal ){
                                    $scope.mechanicItem =  Object.assign({}, mechanic, {
                                      name       : mechanic.name + ' (текущая)',
                                      $isCurrent : true
                                    });
                                }

                            })
                        );

                        if (typeof oldVal !== "undefined" && newVal !== oldVal) {
                          $scope.mechanicFlags.gift = false
                        }

                    } else {
                        $scope.mechanicItem = undefined;
                    }
                });

                $scope.hasCouponIssueUntil = true;
                $scope.$watchGroup(['CampaignItem.campaign.mechanicId','CampaignItem.campaign.campaignTime.stopDate'], function (newVal) {

                  if ( !newVal[0] || !newVal[1] ) {
                    $scope.hasCouponIssueUntil = false;
                    return;
                  }

                  newVal[1] = new Date(newVal[1].getFullYear(), newVal[1].getMonth(), newVal[1].getDate(),0,0,0);

                  CampaignModel.checkCouponRuleIssueUntil({
                    mechanicId: newVal[0]
                  },(val) => {
                    if (!val || !Array.isArray(val)) {
                      $scope.hasCouponIssueUntil = false;
                      return;
                    }

                    $scope.hasCouponIssueUntil = val.some( i => new Date(Date.parse(i.issueUntil)) < newVal[1]);

                  })

                });

                $scope.updateTargetInfoService = function() {

                    var params: any = {};

                    if ( !$scope.CampaignItem ||
                         !$scope.CampaignItem.campaign) {
                        return;
                    }

                    params.partnerId = $scope.CampaignItem.campaign.partnerId;

                    switch ($scope.targetOptions.targetAudience) {

                        case 'all'     : break;
                        case 'group'   : params['include.groupId'] = $scope.CampaignItem.campaign.groupId; break;
                        case 'segment' : params['include.segmentId'] = $scope.CampaignItem.campaign.segmentId; break;
                        case 'upl'     : params['include.groupId'] = $scope.CampaignItem.campaign.groupId; break;
                        case 'nupl'    : params['include.groupId'] = $scope.CampaignItem.campaign.groupId; break;

                    }

                    switch ($scope.targetOptions.exceptionAudience) {

                        case 'all'     : break;
                        case 'group'   : params['exclude.groupIds']   = Array.isArray($scope.CampaignItem.campaign.exceptGroupIds) ? $scope.CampaignItem.campaign.exceptGroupIds.join(',') : ''; break;
                        case 'segment' : params['exclude.segmentId'] = $scope.CampaignItem.campaign.exceptSegmentId; break;

                    }

                    $scope.targetGroupInfo = CampaignModel.targetGroupInfo( params );

                };

                $scope.mechanicFlags = [];
                angular.forEach(CampaignResources, function (resItem) {

                    switch (resItem.resource) {
                        case "bonus":
                            $scope.mechanicFlags.bonus = true;
                            break;
                        case "discount":
                            $scope.mechanicFlags.discount = true;
                            break;
                        case "gift":
                            $scope.mechanicFlags.gift = true;
                            $scope.planeGifts = resItem.details;
                            break;
                    }

                });


                $scope.digestEmitter = new EventEmitter();
                $scope.$watch( _ => {
                  $scope.digestEmitter.emit();
                });

                $scope.options.limitationEnabled = !!$scope.CampaignItem.campaign && !angular.isUndefined($scope.CampaignItem.campaign.limitationId);
                let limitationIdBackup = $scope.options.limitationEnabled ? $scope.CampaignItem.campaign.limitationId : undefined;

                $scope.$watch('options.limitationEnabled', newVal => {

                    if ( !newVal ) {

                        if ( !!$scope.CampaignItem.campaign &&
                             !angular.isUndefined($scope.CampaignItem.campaign.limitationId) ) {
                            limitationIdBackup = $scope.CampaignItem.campaign.limitationId;
                            delete $scope.CampaignItem.campaign.limitationId;
                        }

                    } else {

                        if ( !!$scope.CampaignItem.campaign &&
                               angular.isUndefined( $scope.CampaignItem.campaign.limitationId ) &&
                               !angular.isUndefined( limitationIdBackup ) ) {

                            $scope.CampaignItem.campaign.limitationId = limitationIdBackup;
                            limitationIdBackup = undefined;

                        }
                    }

                });



                $scope.$watch( 'targetOptions.targetAudience', newVal => {

                    if (newVal==='all') {
                        $scope.options.limitationEnabled = false;
                    }

                });

                let limitationByIdDb = {};
                $scope.limitationById = id => {

                    if ( !limitationByIdDb[id] ) {

                        let filtred = $scope.CampaignLimitationsList.filter( item => item.id === id );
                        if ( filtred.length ) {
                            limitationByIdDb[id] = filtred[0];
                        }

                    }

                    return limitationByIdDb[id];

                };

                $scope.filterRealGifts = function (gift) {

                    return gift.realCount;

                };


                let imageHandler = new ImageUploaderHandler();
                $scope.campaignImages = [];
                $scope.addImageResource = function( item ) {

                    imageHandler.add(item);

                    if ( $scope.CampaignItem.campaign && typeof $scope.CampaignItem.campaign.id !== "undefined" ) {
                        return $scope.commitImages();
                    }

                };

                $scope.deleteImageResource = function( item ) {

                    imageHandler.delete(item);

                    if ( $scope.CampaignItem.campaign && typeof $scope.CampaignItem.campaign.id !== "undefined" ) {
                        return $scope.commitImages();
                    }
                };

                $scope.changeImageResource = function( before, after ) {

                    imageHandler.change(before, after);

                    if ( $scope.CampaignItem.campaign && typeof $scope.CampaignItem.campaign.id !== "undefined" ) {
                        return $scope.commitImages();
                    }
                };

                $scope.commitImages = function() {

                    let promises = [];

                    promises.concat(

                        imageHandler
                            .getItemsForRemove()
                            .map( item => CampaignModel.deleteImage({
                                id      : $scope.CampaignItem.campaign.id,
                                imageId : item.imageId
                            })),

                        imageHandler
                            .getItemsForSave()
                            .map( item => CampaignModel.saveImage({
                                id: $scope.CampaignItem.campaign.id,
                                imageId: item.imageId
                            }, item.imageType
                            )),

                    );

                    imageHandler.clear();
                    return $q.all(promises);

                };

                $scope.updateImages = function() {

                    if (!$scope.CampaignItem.campaign || !$scope.CampaignItem.campaign.id) {
                        $scope.campaignImages = [];
                        return;
                    }

                    CampaignModel.queryImages(
                        {
                            id: $scope.CampaignItem.campaign.id
                        },
                        (res) => $scope.campaignImages = res,
                        (error) => false
                    );
                };

                $scope.$watch('CampaignItem.campaign.id', $scope.updateImages());

                $scope.duplicateCampaign = function (createWithArchive) {

                    var createClone = function (response) {
                        // $scope.CampaignItem.campaign = response;


                        $scope.CampaignItem = new CampaignModel({

                            campaign: $scope.CampaignItem.campaign,
                            location: ( $scope.CampaignItem && $scope.CampaignItem.location ) ? angular.copy($scope.CampaignItem.location) : undefined,
                            locationGroup: ( $scope.CampaignItem && $scope.CampaignItem.locationGroup ) ? angular.copy($scope.CampaignItem.locationGroup) : undefined,
                            mechanic: $scope.CampaignItem.mechanic,
                            planValues: $scope.CampaignItem.planValues

                        });

                        delete $scope.CampaignItem.campaign.id;
                        $scope.CampaignItem.campaign.state = 'draft';

                        CampaignResources = [];

                        $scope.setFormState('edit');

                        /*
                         Campaign.create({

                         campaign: $scope.CampaignItem.campaign,
                         location: $scope.CampaignItem.location,
                         mechanic: $scope.CampaignItem.mechanic,
                         planValues: $scope.CampaignItem.planValues

                         }, function( result ) {
                         $scope.CampaignItem = result;
                         $scope.setFormState('edit');
                         });
                         */

                    };

                    if (createWithArchive) {
                        CampaignModel.setState({id: $scope.CampaignItem.campaign.id, state: 'archive'}, function (response) {
                            createClone(response);
                        });
                    } else {
                        createClone($scope.CampaignItem);
                    }

                };

                $scope.setCampaignState = function (state) {
                    var localid = $scope.CampaignItem.campaign.id;
                    /*  CampaignModel.setState($scope.CampaignItem,{ id:$scope.CampaignItem.campaign.id, state:state }, function(response) {
                     $state.go('^.view', { id: response.campaign.id  });
                     } );*/

                    if (!angular.isUndefined(localid)) {

                        return CampaignModel.getState({id: localid}, function (result) {

                            if ($scope.CampaignItem.campaign.state !== result.state) {

                                if (result.state)
                                    $scope.CampaignItem.campaign.state = result.state;

                                if (result.completeness)
                                    $scope.CampaignItem.campaign.completeness = result.completeness;

                            } else {

                                return CampaignModel.setState({
                                    id: $scope.CampaignItem.campaign.id,
                                    state: state
                                }, function (response) {

                                    $state.current.$$finishAuthorize = true;
                                    $state.reload();

                                });

                            }


                        });

                    } else
                        return CampaignModel.setState({
                            id: $scope.CampaignItem.campaign.id,
                            state: state
                        }, function (response) {

                            $state.current.$$finishAuthorize = true;
                            $state.reload();

                        });


                };

                $scope.getMechanicsForCampaign = function (id, returnContainer) {

                    $scope.MsgTemplateList.$promise.then(function (value) {

                        if (!id || !$scope.MsgTemplateList) {
                            returnContainer.return = false;
                            return;
                        }

                        for (var i in $scope.MsgTemplateList) {
                            if ($scope.MsgTemplateList[i].id === id) {

                                returnContainer.return = $scope.MsgTemplateList[i];
                                return;
                            }
                        }

                        returnContainer.return = false;

                    });

                };

                $scope.getLocationsError = function () {

                    return ( !angular.isArray($scope.CampaignItem.locationGroup) || !$scope.CampaignItem.locationGroup.length ) &&
                        ( !angular.isArray($scope.CampaignItem.location) || !$scope.CampaignItem.location.length  );

                };

                $scope.notDis = function($event:any = {}) {
                  return $event?.target;
                }


                $scope.easyPieCharts = {};
                $scope.easyPieCharts['realValuesCampaign.communications.email.factPercent'] = {
                        lineWidth: 4,
                        trackColor: $scope.app.color.light,
                        barColor: $scope.app.color.primary,
                        scaleColor: false,
                        size: 115,
                        lineCap: 'butt'
                };

                $scope.easyPieCharts['realValuesCampaign.communications.sms.factPercent'] = {
                    lineWidth: 4,
                    trackColor: $scope.app.color.light,
                    barColor: $scope.app.color.success,
                    scaleColor: false,
                    size: 115,
                    lineCap: 'butt'
                };

                $scope.easyPieCharts['realValuesCampaign.communications.check.factPercent'] = {
                    lineWidth: 4,
                    trackColor: $scope.app.color.light,
                    barColor: $scope.app.color.warning,
                    scaleColor: false,
                    size: 115,
                    lineCap: 'butt'
                };

                $scope.easyPieCharts['realValuesCampaign.communications.viber.factPercent'] = {
                    lineWidth: 4,
                    trackColor: $scope.app.color.light,
                    barColor: $scope.app.color.danger,
                    scaleColor: false,
                    size: 115,
                    lineCap: 'butt'
                };





                $scope.easyPieCharts['realValuesCampaign.avg.totalOneClient.percent'] = {
                    lineWidth: 3,
                    trackColor: $scope.app.color.light,
                    barColor: $scope.app.color.primary,
                    scaleColor: false,
                    color: '#fff',
                    size: 65,
                    lineCap: 'butt',
                    rotate: 0,
                    animate: 1000
                };

                $scope.easyPieCharts['realValuesCampaign.avg.sellingOneClient.percent'] = {
                    lineWidth: 3,
                    trackColor: $scope.app.color.light,
                    barColor:   $scope.app.color.info,
                    scaleColor: false,
                    color: '#fff',
                    size: 65,
                    lineCap: 'butt',
                    rotate: 0,
                    animate: 1000
               };




                $scope.easyPieCharts['realValuesCampaign.total.bonus.percent'] = {

                    lineWidth:  4,
                    trackColor: $scope.app.color.light,
                    barColor:   '#91c741',
                    scaleColor: false,
                    size:       140,
                    lineCap: 'butt'

                };

                $scope.easyPieCharts['realValuesCampaign.total.discount.percent'] = {

                    lineWidth: 4,
                    trackColor: $scope.app.color.light,
                    barColor: '#0a99f1',
                    scaleColor: false,
                    size: 140,

                    lineCap: 'butt'
                };

                $scope.easyPieCharts['realValuesCampaign.total.gift.percent'] = {

                    lineWidth: 4,
                    trackColor: $scope.app.color.light,
                    barColor: '#ff4001',
                    scaleColor: false,
                    size: 140,

                    lineCap: 'butt'
                };

            }] ;
