/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CouponRuleApiConfiguration as __Configuration } from '../coupon-rule-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CouponRuleImage } from '../models/coupon-rule-image';
import { CouponRuleSearchResult } from '../models/coupon-rule-search-result';
import { CouponRuleQueryParam } from '../models/coupon-rule-query-param';
@Injectable({
  providedIn: 'root',
})
class CouponRuleApiService extends __BaseService {
  static readonly queryImagesPath = '/couponruleimage/{id}';
  static readonly saveImagePath = '/couponruleimage/{id}/{imageId}';
  static readonly deleteImagePath = '/couponruleimage/{id}/{imageId}';
  static readonly searchCouponRulePath = '/couponrule/search';
  static readonly reindexPath = '/couponrule/reindex';
  static readonly reindexByIdPath = '/couponrule/reindex/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id ID купонного правила
   * @return Изображения по купонному правилу
   */
  queryImagesResponse(id: number): __Observable<__StrictHttpResponse<Array<CouponRuleImage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/couponruleimage/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CouponRuleImage>>;
      })
    );
  }
  /**
   * @param id ID купонного правила
   * @return Изображения по купонному правилу
   */
  queryImages(id: number): __Observable<Array<CouponRuleImage>> {
    return this.queryImagesResponse(id).pipe(
      __map(_r => _r.body as Array<CouponRuleImage>)
    );
  }

  /**
   * @param params The `CouponRuleApiService.SaveImageParams` containing the following parameters:
   *
   * - `imageType`: Тип картинки
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID купонного правила
   */
  saveImageResponse(params: CouponRuleApiService.SaveImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.imageType;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/couponruleimage/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.imageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CouponRuleApiService.SaveImageParams` containing the following parameters:
   *
   * - `imageType`: Тип картинки
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID купонного правила
   */
  saveImage(params: CouponRuleApiService.SaveImageParams): __Observable<null> {
    return this.saveImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CouponRuleApiService.DeleteImageParams` containing the following parameters:
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID купонного правила
   */
  deleteImageResponse(params: CouponRuleApiService.DeleteImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/couponruleimage/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.imageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CouponRuleApiService.DeleteImageParams` containing the following parameters:
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID купонного правила
   */
  deleteImage(params: CouponRuleApiService.DeleteImageParams): __Observable<null> {
    return this.deleteImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Поиск кп
   * @param params undefined
   * @return Список купонных правил
   */
  searchCouponRuleResponse(params?: CouponRuleQueryParam): __Observable<__StrictHttpResponse<Array<CouponRuleSearchResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/couponrule/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CouponRuleSearchResult>>;
      })
    );
  }
  /**
   * Поиск кп
   * @param params undefined
   * @return Список купонных правил
   */
  searchCouponRule(params?: CouponRuleQueryParam): __Observable<Array<CouponRuleSearchResult>> {
    return this.searchCouponRuleResponse(params).pipe(
      __map(_r => _r.body as Array<CouponRuleSearchResult>)
    );
  }

  /**
   * переиндексация в эластике
   */
  reindexResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/couponrule/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * переиндексация в эластике
   */
  reindex(): __Observable<null> {
    return this.reindexResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * переиндексация в эластике
   * @param id undefined
   */
  reindexByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/couponrule/reindex/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * переиндексация в эластике
   * @param id undefined
   */
  reindexById(id: number): __Observable<null> {
    return this.reindexByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CouponRuleApiService {

  /**
   * Parameters for saveImage
   */
  export interface SaveImageParams {

    /**
     * Тип картинки
     */
    imageType: Array<number>;

    /**
     * Id картинки
     */
    imageId: string;

    /**
     * ID купонного правила
     */
    id: number;
  }

  /**
   * Parameters for deleteImage
   */
  export interface DeleteImageParams {

    /**
     * Id картинки
     */
    imageId: string;

    /**
     * ID купонного правила
     */
    id: number;
  }
}

export { CouponRuleApiService }
