'use strict';

import module from 'frontendModule';

import {ConditionItems} from './components/conditionOptions/ConditionItems'
import './components/conditionOptions/ConditionItems';
import 'plugin/component/common/index';

export default module.service('conditionItems', ConditionItems );

