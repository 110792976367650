import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {MechanicService} from "../../service/api/Mechanic/Mechanic.service";
import {pipe} from "rxjs";
import {tap} from "rxjs/operators";
import {InformingTemplateService} from "../../service/api/InformingTemplate/InformingTemplate.service";
import {InformingTemplateApiService} from "../../../api/InformingTemplateApi/services/informing-template-api.service";
import {ControlContainer, NgForm} from "@angular/forms";
import {CampaignLimitation} from "../../../api/CampaignLimitationApi/models/campaign-limitation";
import {CampaignLimitationsService} from "../../service/api/CampaignLimitations/CampaignLimitations.service";
import {CampaignLimitationQueryParam} from "../../../api/CampaignLimitationApi/models/campaign-limitation-query-param";

@Component({
  selector: 'select-campaign-limitations',
  templateUrl: './select-campaign-limitations.component.html',
  styleUrls: ['./select-campaign-limitations.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    CampaignLimitationsService
  ]
})
export class SelectCampaignLimitationsComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();

  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  drop    = 0;
  limit   = 20;
  isValid = true;

  digestSubscribe;

  constructor(
    private campaignLimitationsService: CampaignLimitationsService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }
  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.validate();
  }

  pagerReset() {
    this.drop = 0;
  }


  pagerNext( searchText:string | boolean ) {

    let params : CampaignLimitationQueryParam = {
      pager: {
        drop  : this.drop,
        limit : this.limit
      }
    }

    if (searchText) {
      params.search = <string>searchText;
    }

    if (typeof this.id !== "undefined") {
      params.selectedId = this.id;
    }

    return this.campaignLimitationsService
                    .getList$(params)
                    .pipe(
                      tap( data => {
                        this.drop += data.length;

                        this.checkDigest();

                        return data.map( item => ({
                            id: item.id,
                            name: item.name,
                            description: item.description
                          })
                        )
                      })
                    );

  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }

  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}
