<form name="operBalance" #operBalance="ngForm" novalidate>
<div class="card">
  <div class="card-header">
    Корректировка баланса
  </div>
  <div class="card-body">


  <grid-container>

    <div class="grid-item"
         grid-item-form = "Тип операции"
         grid-item-form-icon = "fa-map-marker"
    >
      <div class="editable-rec" >
        <select class=" form-control  inline v-middle"
                [(ngModel)]="operationType"
                [required]="true"
                name="operationType"
                (change)="updateForm(operBalance)"
                data-testid="operationType"
        >
          <option
            *ngFor="let item of OPER_TYPES | keyvalue"
            [ngValue]="item.key"
            [innerText]="item.value"
          ></option>
        </select>
      </div>
    </div>

    <div class="grid-item"
         grid-item-form = "Количество бонусов"
         grid-item-form-icon = "fa-map-marker"
    >
      <div class="editable-rec">
        <input
          type="number"
          class="form-control form-control-edittable loya-input-bonus"
          [(ngModel)]="amount"
          name="amount"
          [required]="true"
          [max]="operationType === 'debit' ? currentBalance : 10000000"
          [min]="0.01"
          data-testid="amount"
        >
        <div class="clearfix text-danger f-s-8" *ngIf="operationType === 'debit' && operBalance.controls['amount'].invalid">
          Максимальная сумма списания {{currentBalance}}
        </div>
      </div>
    </div>


  </grid-container>


  </div>

  <div class="card-footer text-right">
    <button
      class="btn btn-default  btn-addon btn-sm m-r-sm"
      (click)="submit()"
      [disabled]="operBalance.invalid"
      data-testid="save_correction"Л
    >
      <i class="fa fa-check text-primary"></i>
      <span class="btn-name">
        Сохранить
      </span>
    </button>

    <button
      class="btn btn-default btn-addon btn-sm"
      (click)="cancel()"
      data-testid="cancel_correction"
    >
      <i class="fa fa-times text-danger"></i>
      <span class="btn-name">
        Отменить
      </span>
    </button>

  </div>
</div>

</form>
