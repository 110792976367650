import './polyfills';

import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import 'frontendModule';
import './index';

setAngularJSGlobal(angular);
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));



