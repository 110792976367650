import 'model/Settings';
import 'model/ClientModel/resource';

enum PrefixEnum {
    'ru'    = '+7',
    'by'    = '+375',
    'ua'    = '+380',
    'kz'    = '+7',
    'other' = '+'
}


const config : any = {

    ['ru'] : {
        name             : 'Россия',
        maskFunction     : [ '(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/  ],
        patternOperator  : /\(\d\d\d\)\s?/,
        patternInput     : '/[0-9]{3}/ig'
    },

    ['by'] : {
        name             : 'Беларусь',
        maskFunction     : function byPattern( value: string ) {
            let codeOperator = /[9|3|5|4|7]/;

            if ( value && value.length > 2 ) {
                switch(value[1]) {

                    case '2': codeOperator = /[9|5]/; break;
                    case '3': codeOperator = /[3]/; break;
                    case '4': codeOperator = /[4]/; break;
                    case '1': codeOperator = /[7]/; break;

                }
            }

            return [ '(',/[2|3|4|1]/, codeOperator, ')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/  ];
        },
        patternOperator  :  /\([29|33|25|44|17]\)\s?/,
        patternInput     :  '/\([29|33|25|44|17]\)\s?\d\d\d-\d\d-\d\d/'
    },
    ['ua'] : {
      name             : 'Украина',
      maskFunction     : function byPattern( value: string ) {
        let codeOperator = /[1|2|3|4|5|6|7|8|9]/;

        if ( value && value.length > 2 ) {
          switch(value[1]) {

            case '3': codeOperator = /[9]/; break;
            case '5': codeOperator = /[0]/; break;
            case '6': codeOperator = /[3|6|7|8]/; break;
            case '7': codeOperator = /[3]/; break;
            case '9': codeOperator = /[1|2|3|4|5|6|7|8|9]/; break;

          }
        }

        return [ '(',/[3|5|6|7|9]/, codeOperator, ')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/  ];
      },
      patternOperator  :  /\([29|33|25|44|17]\)\s?/,
      patternInput     :  '/\([29|33|25|44|17]\)\s?\d\d\d-\d\d-\d\d/'
    },
    ['kz'] : {
      name             : 'Казахстан',
      maskFunction     : function byPattern( value: string ) {
        let codeOperator = /[1|2|3|4|5|6|7|8|9]/;

        if ( value && value.length > 2 ) {
          switch(value[2]) {
            case '0': codeOperator = /[0|1|2|3|4|5|6|7|8|9]/; break;
            case '4': codeOperator = /[7]/; break;
            case '5': codeOperator = /[0|1]/; break;
            case '6': codeOperator = /[0|1|2|3|4]/; break;
            case '7': codeOperator = /[1|5|6|7|8]/; break;
          }
        }

        return [ '(',/[7]/, /[0|4|5|6|7]/, codeOperator,  ')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/  ];
      },
      patternOperator  :  /\([700|701|702|703|704|705|706|707|708|709|747|750|751|760|761|762|763|764|771|775|776|777|778]\)\s?/,
      patternInput     :  '/\([700|701|702|703|704|705|706|707|708|709|747|750|751|760|761|762|763|764|771|775|776|777|778]\)\s?\d\d\d-\d\d-\d\d/'
    },

    ['other'] : {
        name             : 'Свободный формат',
        maskFunction     :  false,

        patternOperator  :  /\([^\)]*\)\s?/,
        patternInput     :  '/\([^\)]*\)\s?\d\d\d-\d\d-\d\d/'
    }

}

let defaultPrefix:PrefixEnum = PrefixEnum.other;
let defaultCountry:keyof typeof PrefixEnum;

export class PhoneInput {

    public model: string;

    private prefixes     = PrefixEnum  ;

    private _phoneCountry = "";
    private _phonePrefix : PrefixEnum  ;
    private _inputModel  = "";

    private phoneCode    : string      ;
    private phoneTail    : string      ;

    private phonemask    : any      ;
    private phonepattern  = /.*/;

    private change : any;
    private blur   : any;

    public  config  =  config;

    private _t     : any;

    static $inject = ['$scope', 'Settings', 'ClientModel', '$timeout', '$element' ];
    constructor(private $scope: any,
                private Settings: any,
                private ClientModel: any,
                private $timeout: any,
                private $element: any
    ) {}

    set phoneCountry( value: string ) {

      this._phoneCountry = value;
      this.phonePrefix = PrefixEnum[value];

    }

    get phoneCountry() {
      return this._phoneCountry;
    }

    public $onInit() {

        this._t    = new this.ClientModel();
        this.model = this.model ? this.model.trim() : this.model;

        this.Settings
            .query()
            .$promise
            .then( (result: Array<any>) => {

                result.map( (v:any) => {

                    if ( v.key ==='frontend.phone.countryCode' ) {
                        if (!v.value || v.value.toLowerCase()  === "+") {
                            defaultPrefix = PrefixEnum.other;
                            defaultCountry = 'other';
                        } else {
                            defaultPrefix = <PrefixEnum>PrefixEnum[v.value.toLowerCase() ] || PrefixEnum.other;
                            defaultCountry = defaultPrefix === PrefixEnum.other ? 'other' : v.value.toLowerCase();
                        }
                    };

                });

            })
            .catch(() => {
                return Promise.resolve();
            })
            .then( ()  => {
                this.phoneCountry = this.getPhoneCountry( this.model );
                this.inputModel  = this.model ? this.model.trim() : this.model;
            })
        ;



    }

    public $doCheck( values : any ) {

        if (!this.model && this.inputModel) {

            this.inputModel = '';
            this.phoneCode  = '';

        }

    }

    set inputModel( value: string ) {

        if (typeof value === "undefined") {
            return;
        }

        if ( value.indexOf( this.phonePrefix ) === 0 ) {
            value = value.substr(this.phonePrefix.length).trim();
        }

        this._inputModel = value;

        this.phoneCode   = this.getPhoneCode( this._inputModel );

        this.phoneTail   = this.getPhoneTail( this._inputModel );

        this.updateModel();

    }

    get inputModel() { return this._inputModel }

    set phonePrefix( value: PrefixEnum ) {

        this._phonePrefix = value;

        if ( typeof value !== "undefined" ) {

            this.phonemask = {
                mask : config[this.phoneCountry].maskFunction,
                showMask : true,
                keepCharPositions: true,
                _update : Math.random()
            };

            this.phonemask.showMask = value !== PrefixEnum.other;

            this.phonepattern = config[this.phoneCountry].patternInput;

        }

        this.$element.find('#ClientPhoneTail').focus();


        if ( value === PrefixEnum.other && this.inputModel ) {
            setTimeout(() => {  this.inputModel = this.inputModel.replace(/[\(|\)|_|\s|-]/ig,'') }, 0) ;
        } else {
            this.updateModel();
        }

    }

    get phonePrefix() {
        return this._phonePrefix;
    }

    get isOther() {
        return this.phonePrefix === PrefixEnum.other;
    }

    get isEnabled() {
        return typeof this.model !== "undefined" && !!this.phonemask;
    }

    private getPhoneCountry( phone : string ): string {

      let phonePrefix = this.getPhonePrefix( phone );
      let id = 'other';

      if ( phonePrefix === '+7' ) {

        if (!phone && !!defaultCountry) {
          id = defaultCountry || 'ru';
        } else {
          const withoutPrefix = phone.substr(phonePrefix.length ).replace(/\D/ig,'');
          id = withoutPrefix[0] === '7' ? 'kz' : 'ru';
        }

      } else {

        Object.keys(PrefixEnum).forEach( key => {

          if (phonePrefix === PrefixEnum[key]) {
            id = key;
          }

        });

      }


      return id;

    }

    private getPhonePrefix( phone : string ): PrefixEnum {

        phone = phone.trim();

        for ( let key in PrefixEnum ) {

            if ( phone.indexOf(PrefixEnum[key]) === 0 ) {
                return <PrefixEnum>PrefixEnum[key];
            }

        }

        return phone ? PrefixEnum.other : defaultPrefix;
    }

    private getPhoneCode( phone : string ): string {

        let code = undefined;

        let searchResult = phone.match( config[this.phoneCountry].patternOperator );
        if ( searchResult && searchResult[0] ) {
            return searchResult[0];
        }

        searchResult = phone.match( /\([^\)]\)/ );
        if ( searchResult && searchResult[0] ) {
            return searchResult[0];
        }

        return '';
    }

    private getPhoneTail( phone : string ): string {

        if ( this.phoneCode ) {
            return phone.substr( phone.indexOf(this.phoneCode) + this.phoneCode.length ).trim();
        } else {
            return phone.trim();
        }

    }

    private updateModel() {

        if (!this.phoneTail) {
            if ( typeof this.phoneTail !== 'undefined' && !this.phoneCode)
                this.model = '';
        } else {
            let newValue : any;
            if ( this.isOther ) {
                newValue = `${this.phonePrefix}`;
                newValue += this.inputModel.substring(0, this.inputModel.indexOf(this.phoneCode) );
                newValue += `${this.phoneCode}${this.phoneTail}`;
            } else {
                newValue = `${this.phonePrefix} ${this.phoneCode}${this.phoneTail}`;
            }

            this.model = newValue;
        }

        this.changeEvent();
    }

    private changeEvent() {

        setTimeout( () => this.change(),0);

    }


};
