/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ClientTemplateApiConfiguration, ClientTemplateApiConfigurationInterface } from './client-template-api-configuration';

import { ClientTemplateApiService } from './services/client-template-api.service';

/**
 * Provider for all ClientTemplateApi services, plus ClientTemplateApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ClientTemplateApiConfiguration,
    ClientTemplateApiService
  ],
})
export class ClientTemplateApiModule {
  static forRoot(customParams: ClientTemplateApiConfigurationInterface): ModuleWithProviders<ClientTemplateApiModule> {
    return {
      ngModule: ClientTemplateApiModule,
      providers: [
        {
          provide: ClientTemplateApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
