/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ClientApiConfiguration as __Configuration } from '../client-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RawClient } from '../models/raw-client';
import { Client } from '../models/client';
import { ClientSearchParam } from '../models/client-search-param';
import { GroupInfo } from '../models/group-info';
@Injectable({
  providedIn: 'root',
})
class ClientApiService extends __BaseService {
  static readonly createPath = '/client';
  static readonly queryPath = '/client';
  static readonly expressPath = '/client/express';
  static readonly createWithCardPath = '/client/createWithCard';
  static readonly createExpressWithCardPath = '/client/createExpressWithCard';
  static readonly searchPath = '/client/search';
  static readonly reindexPath = '/client/reindex';
  static readonly checkphonePath = '/client/checkphone';
  static readonly getPath = '/client/{id}';
  static readonly updatePath = '/client/{id}';
  static readonly deletePath = '/client/{id}';
  static readonly restorePath = '/client/{id}';
  static readonly getGroupsClientByIdPath = '/client/{id}/groups';
  static readonly attachAccountPath = '/client/attach/{id}/{accountId}';
  static readonly detachAccountPath = '/client/detach/{id}';
  static readonly attachCardPath = '/client/attachcard/{id}/{cardId}';
  static readonly registerByPhoneNumberPath = '/client/registerByPhoneNumber';
  static readonly registerByNonActiveCardPath = '/client/registerByNonActiveCard';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание клиента
   *
   * Создание клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  createResponse(client: RawClient): __Observable<__StrictHttpResponse<RawClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawClient>;
      })
    );
  }
  /**
   * Создание клиента
   *
   * Создание клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  create(client: RawClient): __Observable<RawClient> {
    return this.createResponse(client).pipe(
      __map(_r => _r.body as RawClient)
    );
  }

  /**
   * Запрос списка клиентов
   *
   * Запрос списка клиентов
   * @param params The `ClientApiService.QueryParams` containing the following parameters:
   *
   * - `state`: Статусы клиентов
   *
   * - `sort`: Сортировка
   *
   * - `segmentState`: Статус сегментации
   *
   * - `segmentId`: ID сегмента
   *
   * - `limit`: Сколько записей выдать
   *
   * - `groupId`: ID группы клиентов
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `conditionId`: ID условия из группы клиентов
   *
   * @return Список клиентов
   */
  queryResponse(params: ClientApiService.QueryParams): __Observable<__StrictHttpResponse<Array<RawClient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.segmentState != null) __params = __params.set('segmentState', params.segmentState.toString());
    if (params.segmentId != null) __params = __params.set('segmentId', params.segmentId.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    if (params.conditionId != null) __params = __params.set('conditionId', params.conditionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawClient>>;
      })
    );
  }
  /**
   * Запрос списка клиентов
   *
   * Запрос списка клиентов
   * @param params The `ClientApiService.QueryParams` containing the following parameters:
   *
   * - `state`: Статусы клиентов
   *
   * - `sort`: Сортировка
   *
   * - `segmentState`: Статус сегментации
   *
   * - `segmentId`: ID сегмента
   *
   * - `limit`: Сколько записей выдать
   *
   * - `groupId`: ID группы клиентов
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `conditionId`: ID условия из группы клиентов
   *
   * @return Список клиентов
   */
  query(params: ClientApiService.QueryParams): __Observable<Array<RawClient>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<RawClient>)
    );
  }

  /**
   * Экспресс регистрация клиента
   *
   * Экспресс регистрация клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  expressResponse(client: Client): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/express`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * Экспресс регистрация клиента
   *
   * Экспресс регистрация клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  express(client: Client): __Observable<Client> {
    return this.expressResponse(client).pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * Создание клиента
   *
   * Создание клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  createWithCardResponse(client: RawClient): __Observable<__StrictHttpResponse<RawClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/createWithCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawClient>;
      })
    );
  }
  /**
   * Создание клиента
   *
   * Создание клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  createWithCard(client: RawClient): __Observable<RawClient> {
    return this.createWithCardResponse(client).pipe(
      __map(_r => _r.body as RawClient)
    );
  }

  /**
   * Экспресс регистрация клиента
   *
   * Экспресс регистрация клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  createExpressWithCardResponse(client: Client): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/createExpressWithCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * Экспресс регистрация клиента
   *
   * Экспресс регистрация клиента
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  createExpressWithCard(client: Client): __Observable<Client> {
    return this.createExpressWithCardResponse(client).pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * Поиск клиентов
   *
   * Поиск клиентов
   * @param params The `ClientApiService.SearchParams` containing the following parameters:
   *
   * - `state`: Статусы клиентов
   *
   * - `sentence`: Строка полнотесктового поиска
   *
   * - `params`: Сортировка
   *
   * - `limit`: Сколько записей выдать
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `distance`: Поиск по геокоординатам
   *
   * @return Список клиентов
   */
  searchResponse(params: ClientApiService.SearchParams): __Observable<__StrictHttpResponse<Array<RawClient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());
    __body = params.params;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    if (params.distance != null) __params = __params.set('distance', params.distance.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawClient>>;
      })
    );
  }
  /**
   * Поиск клиентов
   *
   * Поиск клиентов
   * @param params The `ClientApiService.SearchParams` containing the following parameters:
   *
   * - `state`: Статусы клиентов
   *
   * - `sentence`: Строка полнотесктового поиска
   *
   * - `params`: Сортировка
   *
   * - `limit`: Сколько записей выдать
   *
   * - `drop`: Сколько записей пропустить от начала списка
   *
   * - `distance`: Поиск по геокоординатам
   *
   * @return Список клиентов
   */
  search(params: ClientApiService.SearchParams): __Observable<Array<RawClient>> {
    return this.searchResponse(params).pipe(
      __map(_r => _r.body as Array<RawClient>)
    );
  }

  /**
   * Переиндексировать базу клиентов
   *
   * Переиндексировать базу клиентов
   */
  reindexResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать базу клиентов
   *
   * Переиндексировать базу клиентов
   */
  reindex(): __Observable<null> {
    return this.reindexResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Проверка существования номера телефона
   *
   * Проверка существования номера телефона
   * @param params The `ClientApiService.CheckphoneParams` containing the following parameters:
   *
   * - `phone`: Номер телефона, который хотим проверить
   *
   * - `id`: ID текущего клиента
   *
   * @return Клиент найден
   */
  checkphoneResponse(params: ClientApiService.CheckphoneParams): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/checkphone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * Проверка существования номера телефона
   *
   * Проверка существования номера телефона
   * @param params The `ClientApiService.CheckphoneParams` containing the following parameters:
   *
   * - `phone`: Номер телефона, который хотим проверить
   *
   * - `id`: ID текущего клиента
   *
   * @return Клиент найден
   */
  checkphone(params: ClientApiService.CheckphoneParams): __Observable<Client> {
    return this.checkphoneResponse(params).pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * Запрос описания клиента по ID
   *
   * Запрос описания клиента по ID
   * @param id ID клиента
   * @return Описание найденного клиента
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<RawClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawClient>;
      })
    );
  }
  /**
   * Запрос описания клиента по ID
   *
   * Запрос описания клиента по ID
   * @param id ID клиента
   * @return Описание найденного клиента
   */
  get(id: number): __Observable<RawClient> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as RawClient)
    );
  }

  /**
   * Обновление данных о клиенте по ID
   *
   * Обновление данных о клиенте по ID
   * @param params The `ClientApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `client`: Данные о клиенте
   *
   * @return Описание найденного клиента
   */
  updateResponse(params: ClientApiService.UpdateParams): __Observable<__StrictHttpResponse<RawClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.client;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawClient>;
      })
    );
  }
  /**
   * Обновление данных о клиенте по ID
   *
   * Обновление данных о клиенте по ID
   * @param params The `ClientApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `client`: Данные о клиенте
   *
   * @return Описание найденного клиента
   */
  update(params: ClientApiService.UpdateParams): __Observable<RawClient> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as RawClient)
    );
  }

  /**
   * Удаление клиента по ID
   *
   * Удаление клиента по ID
   * @param id ID клиента
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/client/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление клиента по ID
   *
   * Удаление клиента по ID
   * @param id ID клиента
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Восстановление по ID
   *
   * Восстановление клиента по ID
   * @param id ID клиента
   */
  restoreResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Восстановление по ID
   *
   * Восстановление клиента по ID
   * @param id ID клиента
   */
  restore(id: number): __Observable<null> {
    return this.restoreResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Получить список ID групп, в которые входит клиент
   *
   * Получить список ID групп, в которые входит клиент
   * @param id ID клиента
   * @return Список групп
   */
  getGroupsClientByIdResponse(id: number): __Observable<__StrictHttpResponse<Array<GroupInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/${encodeURIComponent(String(id))}/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GroupInfo>>;
      })
    );
  }
  /**
   * Получить список ID групп, в которые входит клиент
   *
   * Получить список ID групп, в которые входит клиент
   * @param id ID клиента
   * @return Список групп
   */
  getGroupsClientById(id: number): __Observable<Array<GroupInfo>> {
    return this.getGroupsClientByIdResponse(id).pipe(
      __map(_r => _r.body as Array<GroupInfo>)
    );
  }

  /**
   * Привязать счет к клиенту
   *
   * Привязать счет к клиенту
   * @param params The `ClientApiService.AttachAccountParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `accountId`: ID счета
   *
   * @return Счет привязан к клиенту
   */
  attachAccountResponse(params: ClientApiService.AttachAccountParams): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/attach/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.accountId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * Привязать счет к клиенту
   *
   * Привязать счет к клиенту
   * @param params The `ClientApiService.AttachAccountParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `accountId`: ID счета
   *
   * @return Счет привязан к клиенту
   */
  attachAccount(params: ClientApiService.AttachAccountParams): __Observable<Client> {
    return this.attachAccountResponse(params).pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * Отвязать счет от клиента
   *
   * Отвязать счет от клиента
   * @param id ID клиента
   * @return Счет отвязан от клиента
   */
  detachAccountResponse(id: number): __Observable<__StrictHttpResponse<Client>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/detach/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Client>;
      })
    );
  }
  /**
   * Отвязать счет от клиента
   *
   * Отвязать счет от клиента
   * @param id ID клиента
   * @return Счет отвязан от клиента
   */
  detachAccount(id: number): __Observable<Client> {
    return this.detachAccountResponse(id).pipe(
      __map(_r => _r.body as Client)
    );
  }

  /**
   * Привязать карту к клиенту
   *
   * Привязать карту к клиенту
   * @param params The `ClientApiService.AttachCardParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `cardId`: ID карты
   *
   * - `archiveOther`:
   */
  attachCardResponse(params: ClientApiService.AttachCardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.archiveOther != null) __params = __params.set('archiveOther', params.archiveOther.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/client/attachcard/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.cardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязать карту к клиенту
   *
   * Привязать карту к клиенту
   * @param params The `ClientApiService.AttachCardParams` containing the following parameters:
   *
   * - `id`: ID клиента
   *
   * - `cardId`: ID карты
   *
   * - `archiveOther`:
   */
  attachCard(params: ClientApiService.AttachCardParams): __Observable<null> {
    return this.attachCardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Регистрация клиента по номеру телефона
   *
   * Регистрация клиента по номеру телефона.
   * Если клиент с таким номером телефона уже существует в БД, то возвращаем его данные.
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  registerByPhoneNumberResponse(client: {phoneNumber: string, locationId: number, clientName?: string}): __Observable<__StrictHttpResponse<{id: number, accountId: number, cardId: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/registerByPhoneNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{id: number, accountId: number, cardId: number}>;
      })
    );
  }
  /**
   * Регистрация клиента по номеру телефона
   *
   * Регистрация клиента по номеру телефона.
   * Если клиент с таким номером телефона уже существует в БД, то возвращаем его данные.
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  registerByPhoneNumber(client: {phoneNumber: string, locationId: number, clientName?: string}): __Observable<{id: number, accountId: number, cardId: number}> {
    return this.registerByPhoneNumberResponse(client).pipe(
      __map(_r => _r.body as {id: number, accountId: number, cardId: number})
    );
  }

  /**
   * Регистрация клиента по номеру телефона
   *
   * Регистрация клиента по карте в статусе Исходная.
   * Если клиент с такой картой уже существует в БД, то возвращаем его данные.
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  registerByNonActiveCardResponse(client: {cardId: number, cardNumber: string, locationId: number, retailIdUse: boolean}): __Observable<__StrictHttpResponse<{id: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = client;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/client/registerByNonActiveCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{id: number}>;
      })
    );
  }
  /**
   * Регистрация клиента по номеру телефона
   *
   * Регистрация клиента по карте в статусе Исходная.
   * Если клиент с такой картой уже существует в БД, то возвращаем его данные.
   * @param client Описание клиента
   * @return Описание созданного клиента
   */
  registerByNonActiveCard(client: {cardId: number, cardNumber: string, locationId: number, retailIdUse: boolean}): __Observable<{id: number}> {
    return this.registerByNonActiveCardResponse(client).pipe(
      __map(_r => _r.body as {id: number})
    );
  }
}

module ClientApiService {

  /**
   * Parameters for query
   */
  export interface QueryParams {

    /**
     * Статусы клиентов
     */
    state?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Статус сегментации
     */
    segmentState?: 'draft' | 'deploy' | 'refresh' | 'archive';

    /**
     * ID сегмента
     */
    segmentId?: number;

    /**
     * Сколько записей выдать
     */
    limit?: number;

    /**
     * ID группы клиентов
     */
    groupId?: number;

    /**
     * Сколько записей пропустить от начала списка
     */
    drop?: number;

    /**
     * ID условия из группы клиентов
     */
    conditionId?: number;
  }

  /**
   * Parameters for search
   */
  export interface SearchParams {

    /**
     * Статусы клиентов
     */
    state?: string;

    /**
     * Строка полнотесктового поиска
     */
    sentence?: string;

    /**
     * Сортировка
     */
    params?: ClientSearchParam;

    /**
     * Сколько записей выдать
     */
    limit?: number;

    /**
     * Сколько записей пропустить от начала списка
     */
    drop?: number;

    /**
     * Поиск по геокоординатам
     */
    distance?: string;
  }

  /**
   * Parameters for checkphone
   */
  export interface CheckphoneParams {

    /**
     * Номер телефона, который хотим проверить
     */
    phone: string;

    /**
     * ID текущего клиента
     */
    id?: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID клиента
     */
    id: number;

    /**
     * Данные о клиенте
     */
    client: RawClient;
  }

  /**
   * Parameters for attachAccount
   */
  export interface AttachAccountParams {

    /**
     * ID клиента
     */
    id: number;

    /**
     * ID счета
     */
    accountId: number;
  }

  /**
   * Parameters for attachCard
   */
  export interface AttachCardParams {

    /**
     * ID клиента
     */
    id: number;

    /**
     * ID карты
     */
    cardId: number;
    archiveOther: boolean;
  }
}

export { ClientApiService }
