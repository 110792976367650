import { defaultCapabilities } from "./defaultCapabilities";
import { FieldTypes } from "./fieldTypes";

export var mapCapability = {

    'default': defaultCapabilities,

    'modules_client_preferences' : { 'modules.client.preferences'   : { [FieldTypes.FIELD_ENABLED]: true} },
    'modules_client_promotion'   : { 'modules.client.promotion'     : { [FieldTypes.FIELD_ENABLED]: true} },
    'modules_client_accumulation': { 'modules.client.accumulation'  : { [FieldTypes.FIELD_ENABLED]: true} },

    'campaign_mechanics_create' : { 'campaign.mechanics'   : { [FieldTypes.FIELD_QUERY]: { create: true } } },
//    'campaign_mechanics_update' : { 'campaign.mechanics'   : { [FieldTypes.FIELD_QUERY]: { update: true } } },

    'campaign_mechanics_bonus'  : { 'campaign.mechanics.bonus'   : { [FieldTypes.FIELD_ENABLED]: true} },

    'coupons_rule_couponKind_eCoupon'  : { 'coupons.rule.couponKind'    : { [FieldTypes.FIELD_FILTER]: ['eCoupon', 'importedCoupon', 'couponWithBarcodeWithReceptParams' ]  } },
    'coupons_rule_issuingType_external': { 'coupons.rule.issuingType'   : { [FieldTypes.FIELD_FILTER]: ['external'] } },
    'coupons_rule_issuingType_manual'  : { 'coupons.rule.issuingType'   : { [FieldTypes.FIELD_FILTER]: ['manual']   } },

    'crm_cards_multiple'  : { 'crm.cards.multiple'  : { [FieldTypes.FIELD_ENABLED]: true} },
    'crm_accounts'        : {
      'crm.accounts'        : { [FieldTypes.FIELD_ENABLED]: true},
      'global.accounts' : { [FieldTypes.FIELD_ENABLED]: true }
    },
    'crm_common_accounts' : { 'crm.accounts.common' : { [FieldTypes.FIELD_ENABLED]: true} },

    'segmentation' : { 'segments'        : { [FieldTypes.FIELD_ENABLED]: true},
                       'segments.groups' : { [FieldTypes.FIELD_ENABLED]: true} },

    'campaign_exceptionAudience'  : { 'campaign.settings.exceptionAudience' : { [FieldTypes.FIELD_ENABLED]: true} },

    'campaign_planValues'         : { 'campaign.settings.planValues'        : { [FieldTypes.FIELD_ENABLED]: true} },
    'campaign_realValues'         : { 'campaign.settings.realValues'        : { [FieldTypes.FIELD_ENABLED]: true} },

    'channel_type_sms'   : { 'channel.type'       : { [FieldTypes.FIELD_FILTER]: ['sms']   },
                             'channel.type.sms'   : { [FieldTypes.FIELD_ENABLED]: true },
                             'client.preferChannelType'   : { [FieldTypes.FIELD_FILTER]: ['sms'] }
                            },
    'channel_type_email' : { 'channel.type'       : { [FieldTypes.FIELD_FILTER]: ['email'] },
                             'channel.type.email' : { [FieldTypes.FIELD_ENABLED]: true },
                             'client.preferChannelType'   : { [FieldTypes.FIELD_FILTER]: ['email'] }
                             },
    'channel_type_viber' :  { 'channel.type'       : { [FieldTypes.FIELD_FILTER]: ['viber'] },
        'channel.type.viber' : { [FieldTypes.FIELD_ENABLED]: true },
        'client.preferChannelType'   : { [FieldTypes.FIELD_FILTER]: ['viber'] }
    },

    'modules':    {'modules' : { [FieldTypes.FIELD_ENABLED]: true },
                   'modules.components' : { [FieldTypes.FIELD_ENABLED]: true }},


    'triggers': { 'triggers' : { [FieldTypes.FIELD_ENABLED]: true } },

    'referrer': {
      'referrer' : {
        [FieldTypes.FIELD_ENABLED]: true,
        [FieldTypes.FIELD_QUERY]: {
            'create'  : true,
            'delete' : true,
            'update' : true,
        }
      },
      'referrer.journal' : {
        [FieldTypes.FIELD_ENABLED]: true
      }
    },

    'informing_on_campaign_apply' :  { 'campaign.settings.informingOnApply' : { [FieldTypes.FIELD_ENABLED]: true } },

    //-------------- NEW ----------------------------

    'clients' : {
      'global.clients' : { [FieldTypes.FIELD_ENABLED]: true },
      'coupons.rule.issuingType'   : { [FieldTypes.FIELD_FILTER]: ['uniqueCouponGen'] },
      'coupons.rule.couponKind'    : { [FieldTypes.FIELD_FILTER]: [ 'couponWithoutBarcode', 'importedCouponForPrint' , 'referralCouponWithBarcode' ] },
    },

    //--------------- User rights -------------------

    'allow_account_operations': {
        'model.account' : {
                        [FieldTypes.FIELD_QUERY]: { update: true }
        },
        'crm.accounts.merge' :  { [FieldTypes.FIELD_ENABLED]: true }
    },

    'allow_sticker': {
        'coupons.rule.couponKind' : { [FieldTypes.FIELD_FILTER]: [ 'sticker' ] }
     },

    'credit_account': {
        'crm.accounts.credit' : { [FieldTypes.FIELD_ENABLED]: true }
    },

    'gift_certificate': {
      'editions' : { [FieldTypes.FIELD_ENABLED]: true }
    },

    'external_tasks': {
      'system.settings.externalTask' : { [FieldTypes.FIELD_ENABLED]: true }
    }


};
