/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ReferrerApiConfiguration as __Configuration } from '../referrer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReferrerInfoForQuery } from '../models/referrer-info-for-query';
import { ReferrerCreatedResponse } from '../models/referrer-created-response';
import { CreateReferrerParams } from '../models/create-referrer-params';
import { RawReferrer } from '../models/raw-referrer';
import { UpdateReferrer } from '../models/update-referrer';
import { RawReferrerByClient } from '../models/raw-referrer-by-client';
import { RawReferrerRecord } from '../models/raw-referrer-record';
import { OperationParams } from '../models/operation-params';
import { ChangeLogItem } from '../models/change-log-item';
import { ChangeType } from '../models/change-type';
import { GraphItem } from '../models/graph-item';
@Injectable({
  providedIn: 'root',
})
class ReferrerApiService extends __BaseService {
  static readonly queryReferrerPath = '/referrer';
  static readonly createReferrerPath = '/referrer';
  static readonly getReferrerPath = '/referrer/{id}';
  static readonly updateReferrerPath = '/referrer/{id}';
  static readonly deleteReferrerPath = '/referrer/{id}';
  static readonly getReferrerByClientPath = '/referrer/byClient/{clientId}';
  static readonly queryReferrerRecordsPath = '/referrer/{accountId}/records';
  static readonly referrerBalanceOperationPath = '/referrer/{id}/correction';
  static readonly getChangelogPath = '/referrer/changelog';
  static readonly getGraphPath = '/referrer/graph/{referrerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Список реферов
   *
   * Список реферов
   * @param params The `ReferrerApiService.QueryReferrerParams` containing the following parameters:
   *
   * - `filter.state`: фильтр по состоянию рефера
   *
   * - `sentence`: Поисковая строка
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список реферов
   */
  queryReferrerResponse(params: ReferrerApiService.QueryReferrerParams): __Observable<__StrictHttpResponse<Array<ReferrerInfoForQuery>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.filterState != null) __params = __params.set('filter.state', params.filterState.toString());
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referrer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferrerInfoForQuery>>;
      })
    );
  }
  /**
   * Список реферов
   *
   * Список реферов
   * @param params The `ReferrerApiService.QueryReferrerParams` containing the following parameters:
   *
   * - `filter.state`: фильтр по состоянию рефера
   *
   * - `sentence`: Поисковая строка
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список реферов
   */
  queryReferrer(params: ReferrerApiService.QueryReferrerParams): __Observable<Array<ReferrerInfoForQuery>> {
    return this.queryReferrerResponse(params).pipe(
      __map(_r => _r.body as Array<ReferrerInfoForQuery>)
    );
  }

  /**
   * Создание рефера
   *
   * Создание рефера
   * @param referrer Описание рефера
   * @return Рефер создан
   */
  createReferrerResponse(referrer: CreateReferrerParams): __Observable<__StrictHttpResponse<ReferrerCreatedResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referrer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/referrer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferrerCreatedResponse>;
      })
    );
  }
  /**
   * Создание рефера
   *
   * Создание рефера
   * @param referrer Описание рефера
   * @return Рефер создан
   */
  createReferrer(referrer: CreateReferrerParams): __Observable<ReferrerCreatedResponse> {
    return this.createReferrerResponse(referrer).pipe(
      __map(_r => _r.body as ReferrerCreatedResponse)
    );
  }

  /**
   * Запрос рефера
   *
   * Запрос рефера
   * @param id ID referrer (client id)
   * @return Рефер
   */
  getReferrerResponse(id: number): __Observable<__StrictHttpResponse<RawReferrer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referrer/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawReferrer>;
      })
    );
  }
  /**
   * Запрос рефера
   *
   * Запрос рефера
   * @param id ID referrer (client id)
   * @return Рефер
   */
  getReferrer(id: number): __Observable<RawReferrer> {
    return this.getReferrerResponse(id).pipe(
      __map(_r => _r.body as RawReferrer)
    );
  }

  /**
   * Редактирование рефера
   *
   * Редактирование рефера
   * @param params The `ReferrerApiService.UpdateReferrerParams` containing the following parameters:
   *
   * - `referrer`: Описание рефера
   *
   * - `id`: ID referrer (client id)
   */
  updateReferrerResponse(params: ReferrerApiService.UpdateReferrerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.referrer;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/referrer/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Редактирование рефера
   *
   * Редактирование рефера
   * @param params The `ReferrerApiService.UpdateReferrerParams` containing the following parameters:
   *
   * - `referrer`: Описание рефера
   *
   * - `id`: ID referrer (client id)
   */
  updateReferrer(params: ReferrerApiService.UpdateReferrerParams): __Observable<null> {
    return this.updateReferrerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Архивация рефера
   *
   * Архивация рефера
   * @param id ID referrer (client id)
   */
  deleteReferrerResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/referrer/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Архивация рефера
   *
   * Архивация рефера
   * @param id ID referrer (client id)
   */
  deleteReferrer(id: number): __Observable<null> {
    return this.deleteReferrerResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос рефера по ID клиента
   *
   * Запрос рефера по ID клиента
   * @param clientId ID клиента (client id)
   * @return Рефер
   */
  getReferrerByClientResponse(clientId: number): __Observable<__StrictHttpResponse<RawReferrerByClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referrer/byClient/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawReferrerByClient>;
      })
    );
  }
  /**
   * Запрос рефера по ID клиента
   *
   * Запрос рефера по ID клиента
   * @param clientId ID клиента (client id)
   * @return Рефер
   */
  getReferrerByClient(clientId: number): __Observable<RawReferrerByClient> {
    return this.getReferrerByClientResponse(clientId).pipe(
      __map(_r => _r.body as RawReferrerByClient)
    );
  }

  /**
   * Список проводок рефера
   *
   * Список проводок рефера
   * @param params The `ReferrerApiService.QueryReferrerRecordsParams` containing the following parameters:
   *
   * - `accountId`: ID счета рефера
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `date`  - Дата операции
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка (по умолчанию 0)
   *
   * @return Рефер
   */
  queryReferrerRecordsResponse(params: ReferrerApiService.QueryReferrerRecordsParams): __Observable<__StrictHttpResponse<Array<RawReferrerRecord>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referrer/${encodeURIComponent(String(params.accountId))}/records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawReferrerRecord>>;
      })
    );
  }
  /**
   * Список проводок рефера
   *
   * Список проводок рефера
   * @param params The `ReferrerApiService.QueryReferrerRecordsParams` containing the following parameters:
   *
   * - `accountId`: ID счета рефера
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `date`  - Дата операции
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка (по умолчанию 0)
   *
   * @return Рефер
   */
  queryReferrerRecords(params: ReferrerApiService.QueryReferrerRecordsParams): __Observable<Array<RawReferrerRecord>> {
    return this.queryReferrerRecordsResponse(params).pipe(
      __map(_r => _r.body as Array<RawReferrerRecord>)
    );
  }

  /**
   * Корректировка баланса рефера
   *
   * Корректировка баланса рефера
   * @param params The `ReferrerApiService.ReferrerBalanceOperationParams` containing the following parameters:
   *
   * - `params`: referrer balance operation params
   *
   * - `id`: ID referrer
   */
  referrerBalanceOperationResponse(params: ReferrerApiService.ReferrerBalanceOperationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/referrer/${encodeURIComponent(String(params.id))}/correction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Корректировка баланса рефера
   *
   * Корректировка баланса рефера
   * @param params The `ReferrerApiService.ReferrerBalanceOperationParams` containing the following parameters:
   *
   * - `params`: referrer balance operation params
   *
   * - `id`: ID referrer
   */
  referrerBalanceOperation(params: ReferrerApiService.ReferrerBalanceOperationParams): __Observable<null> {
    return this.referrerBalanceOperationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос журнала изменений реферов
   *
   * Запрос журнала изменений реферов
   * @param filter Фильтр журнала изменений
   * @return Журнал изменений реферов
   */
  getChangelogResponse(filter: {referrerId?: number, userId?: number, clientId?: number, promoCode?: string, nickname?: string, dateFrom?: string, dateTill?: string, changeType?: ChangeType, field?: string, drop?: number, limit?: number}): __Observable<__StrictHttpResponse<Array<ChangeLogItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/referrer/changelog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ChangeLogItem>>;
      })
    );
  }
  /**
   * Запрос журнала изменений реферов
   *
   * Запрос журнала изменений реферов
   * @param filter Фильтр журнала изменений
   * @return Журнал изменений реферов
   */
  getChangelog(filter: {referrerId?: number, userId?: number, clientId?: number, promoCode?: string, nickname?: string, dateFrom?: string, dateTill?: string, changeType?: ChangeType, field?: string, drop?: number, limit?: number}): __Observable<Array<ChangeLogItem>> {
    return this.getChangelogResponse(filter).pipe(
      __map(_r => _r.body as Array<ChangeLogItem>)
    );
  }

  /**
   * График по данным реферов для клиента
   *
   * График по данным реферов для клиента
   * @param params The `ReferrerApiService.GetGraphParams` containing the following parameters:
   *
   * - `since`: С какой даты
   *
   * - `referrerId`:
   *
   * @return График реферов
   */
  getGraphResponse(params: ReferrerApiService.GetGraphParams): __Observable<__StrictHttpResponse<Array<GraphItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.since != null) __params = __params.set('since', params.since.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/referrer/graph/${encodeURIComponent(String(params.referrerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GraphItem>>;
      })
    );
  }
  /**
   * График по данным реферов для клиента
   *
   * График по данным реферов для клиента
   * @param params The `ReferrerApiService.GetGraphParams` containing the following parameters:
   *
   * - `since`: С какой даты
   *
   * - `referrerId`:
   *
   * @return График реферов
   */
  getGraph(params: ReferrerApiService.GetGraphParams): __Observable<Array<GraphItem>> {
    return this.getGraphResponse(params).pipe(
      __map(_r => _r.body as Array<GraphItem>)
    );
  }
}

module ReferrerApiService {

  /**
   * Parameters for queryReferrer
   */
  export interface QueryReferrerParams {

    /**
     * фильтр по состоянию рефера
     */
    filterState: 'active' | 'archive';

    /**
     * Поисковая строка
     */
    sentence?: string;

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for updateReferrer
   */
  export interface UpdateReferrerParams {

    /**
     * Описание рефера
     */
    referrer: UpdateReferrer;

    /**
     * ID referrer (client id)
     */
    id: number;
  }

  /**
   * Parameters for queryReferrerRecords
   */
  export interface QueryReferrerRecordsParams {

    /**
     * ID счета рефера
     */
    accountId: number;

    /**
     * Порядок сортировки:
     *   - `asc`
     *   - `desc`
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле для сортировки:
     *   - `date`  - Дата операции
     */
    sortingField?: 'date';

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала списка (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for referrerBalanceOperation
   */
  export interface ReferrerBalanceOperationParams {

    /**
     * referrer balance operation params
     */
    params: OperationParams;

    /**
     * ID referrer
     */
    id: number;
  }

  /**
   * Parameters for getGraph
   */
  export interface GetGraphParams {

    /**
     * С какой даты
     */
    since: string;
    referrerId: number;
  }
}

export { ReferrerApiService }
