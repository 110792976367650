'use strict';

import module from 'frontendModule';

import * as textT from './modules/text/index.html'
import textC from './modules/text'

import * as textareaT from './modules/textarea/index.html'
import textareaC from './modules/textarea'

import * as dateT from './modules/date/index.html'
import dateC from './modules/date'

import * as timeT from './modules/time/index.html'
import timeC from './modules/time'

import * as  numberT from './modules/number/index.html'
import numberC from './modules/number'

import * as selectGiftsT from './modules/selectGifts/index.html'
import selectGiftsC from './modules/selectGifts'

import quantityNumberC from './modules/quantityNumber';
import * as quantityNumberT from './modules/quantityNumber/index.html';

import * as  selectGoodsT from './modules/selectGoods/index.html'
import selectGoodsC from './modules/selectGoods'

import * as selectGoodsListT from './modules/selectGoodsList/index.html'
import selectGoodsListC from './modules/selectGoodsList'

export default module.config(
    [ 'builderLazyModuleProvider', 'modulesStorageProvider',
        function ( builderLazyModuleProvider, modulesStorageProvider ) {

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'text', {
                    templateUrl: textT,
                    controler: textC
                }
            );

          modulesStorageProvider.setCollection(
            'campaign.mechanics.config', 'textarea', {
              templateUrl: textareaT,
              controler: textareaC
            }
          );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'date', {
                    templateUrl: dateT,
                    controler: dateC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'time', {
                    templateUrl: timeT,
                    controler: timeC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'number', {
                    templateUrl: numberT,
                    controler: numberC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'selectGifts', {
                    templateUrl: selectGiftsT,
                    controler: selectGiftsC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'selectGoods', {
                    templateUrl: selectGoodsT,
                    controler: selectGoodsC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'quantityNumber', {
                    templateUrl: quantityNumberT,
                    controler: quantityNumberC
                }
            );

            modulesStorageProvider.setCollection(
                'campaign.mechanics.config', 'selectGoodsList', {
                    templateUrl: selectGoodsListT,
                    controler: selectGoodsListC
                }
            );


        }
    ]);
