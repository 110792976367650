/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GiftCertificateApiConfiguration as __Configuration } from '../gift-certificate-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateGiftCertificate } from '../models/create-gift-certificate';
import { GetGiftCertificate } from '../models/get-gift-certificate';
import { GiftCertificateInfo } from '../models/gift-certificate-info';
import { AddRecord } from '../models/add-record';
@Injectable({
  providedIn: 'root',
})
class GiftCertificateApiService extends __BaseService {
  static readonly createPath = '/giftCertificate';
  static readonly certificateListPath = '/giftCertificate';
  static readonly checkRangePath = '/giftCertificate/checkRange';
  static readonly getCertificatePath = '/giftCertificate/{id}';
  static readonly addRecordPath = '/giftCertificate/{id}/addRecord';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание подарочных сертификатов
   * @param certificate undefined
   */
  createResponse(certificate: CreateGiftCertificate): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = certificate;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/giftCertificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Создание подарочных сертификатов
   * @param certificate undefined
   */
  create(certificate: CreateGiftCertificate): __Observable<null> {
    return this.createResponse(certificate).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Список сертификатов
   * @param params The `GiftCertificateApiService.CertificateListParams` containing the following parameters:
   *
   * - `sorting.order`:
   *
   * - `sorting.field`: Поле для сортировки, номер сертификата, либо имя
   *
   * - `pager.limit`:
   *
   * - `pager.drop`:
   *
   * - `filter.status`: Поиск по статусу сертификата
   *
   * - `filter.number`: Поиск по номеру чека
   *
   * @return Список сертификатов
   */
  certificateListResponse(params: GiftCertificateApiService.CertificateListParams): __Observable<__StrictHttpResponse<Array<GetGiftCertificate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    (params.filterStatus || []).forEach(val => {if (val != null) __params = __params.append('filter.status', val.toString())});
    if (params.filterNumber != null) __params = __params.set('filter.number', params.filterNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/giftCertificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GetGiftCertificate>>;
      })
    );
  }
  /**
   * Список сертификатов
   * @param params The `GiftCertificateApiService.CertificateListParams` containing the following parameters:
   *
   * - `sorting.order`:
   *
   * - `sorting.field`: Поле для сортировки, номер сертификата, либо имя
   *
   * - `pager.limit`:
   *
   * - `pager.drop`:
   *
   * - `filter.status`: Поиск по статусу сертификата
   *
   * - `filter.number`: Поиск по номеру чека
   *
   * @return Список сертификатов
   */
  certificateList(params: GiftCertificateApiService.CertificateListParams): __Observable<Array<GetGiftCertificate>> {
    return this.certificateListResponse(params).pipe(
      __map(_r => _r.body as Array<GetGiftCertificate>)
    );
  }

  /**
   * Проверить наличие подарочных сертификатов в диапазоне
   * @param params The `GiftCertificateApiService.CheckRangeParams` containing the following parameters:
   *
   * - `quantity`:
   *
   * - `minNumber`: Наименьший номер тиража
   *
   * - `editionId`:
   *
   * @return Диапазон содержит сертификаты
   */
  checkRangeResponse(params: GiftCertificateApiService.CheckRangeParams): __Observable<__StrictHttpResponse<{result?: boolean}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.quantity != null) __params = __params.set('quantity', params.quantity.toString());
    if (params.minNumber != null) __params = __params.set('minNumber', params.minNumber.toString());
    if (params.editionId != null) __params = __params.set('editionId', params.editionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/giftCertificate/checkRange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{result?: boolean}>;
      })
    );
  }
  /**
   * Проверить наличие подарочных сертификатов в диапазоне
   * @param params The `GiftCertificateApiService.CheckRangeParams` containing the following parameters:
   *
   * - `quantity`:
   *
   * - `minNumber`: Наименьший номер тиража
   *
   * - `editionId`:
   *
   * @return Диапазон содержит сертификаты
   */
  checkRange(params: GiftCertificateApiService.CheckRangeParams): __Observable<{result?: boolean}> {
    return this.checkRangeResponse(params).pipe(
      __map(_r => _r.body as {result?: boolean})
    );
  }

  /**
   * Запрос сертификата и его истории по id
   * @param id undefined
   * @return Список сертификатов
   */
  getCertificateResponse(id: number): __Observable<__StrictHttpResponse<GiftCertificateInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/giftCertificate/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GiftCertificateInfo>;
      })
    );
  }
  /**
   * Запрос сертификата и его истории по id
   * @param id undefined
   * @return Список сертификатов
   */
  getCertificate(id: number): __Observable<GiftCertificateInfo> {
    return this.getCertificateResponse(id).pipe(
      __map(_r => _r.body as GiftCertificateInfo)
    );
  }

  /**
   * Добавление проводки по сертификату
   * @param params The `GiftCertificateApiService.AddRecordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `record`:
   */
  addRecordResponse(params: GiftCertificateApiService.AddRecordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.record;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/giftCertificate/${encodeURIComponent(String(params.id))}/addRecord`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Добавление проводки по сертификату
   * @param params The `GiftCertificateApiService.AddRecordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `record`:
   */
  addRecord(params: GiftCertificateApiService.AddRecordParams): __Observable<null> {
    return this.addRecordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GiftCertificateApiService {

  /**
   * Parameters for certificateList
   */
  export interface CertificateListParams {
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле для сортировки, номер сертификата, либо имя
     */
    sortingField?: 'number' | 'name' | 'date';
    pagerLimit?: number;
    pagerDrop?: number;

    /**
     * Поиск по статусу сертификата
     */
    filterStatus?: Array<'created' | 'deployed' | 'used' | 'blocked'>;

    /**
     * Поиск по номеру чека
     */
    filterNumber?: string;
  }

  /**
   * Parameters for checkRange
   */
  export interface CheckRangeParams {
    quantity: number;

    /**
     * Наименьший номер тиража
     */
    minNumber: string;
    editionId: number;
  }

  /**
   * Parameters for addRecord
   */
  export interface AddRecordParams {
    id: number;
    record?: AddRecord;
  }
}

export { GiftCertificateApiService }
