/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CampaignCommonApiConfiguration, CampaignCommonApiConfigurationInterface } from './campaign-common-api-configuration';

import { CampaignCommonApiService } from './services/campaign-common-api.service';

/**
 * Provider for all CampaignCommonApi services, plus CampaignCommonApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CampaignCommonApiConfiguration,
    CampaignCommonApiService
  ],
})
export class CampaignCommonApiModule {
  static forRoot(customParams: CampaignCommonApiConfigurationInterface): ModuleWithProviders<CampaignCommonApiModule> {
    return {
      ngModule: CampaignCommonApiModule,
      providers: [
        {
          provide: CampaignCommonApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
