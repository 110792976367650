import 'model/ClientTransactionsModel/resource'
import {Component, Input} from "@angular/core";
import { getNg1Service } from './../../class/utils/angularjs.utils'

const REQUEST_TYPE_NAMES = {
    preCheck    : 'Формирование заказа',
    paymentHold : 'Подтверждение заказа',
    paymentTry  : 'Оплата заказа',
    confirm     : 'Заказ доставлен'
};

@Component({
  selector: 'receipt-view',
  templateUrl: './receipt-view.component.html',
  styleUrls: ['./receipt-view.component.scss'],

})
export class ReceiptViewComponent {

    // Передаваемые параметры в компонент
    @Input() public model       : any;
    @Input() public searchModel : Object;
    @Input() public showClient  : boolean;
    @Input() public isCancelReceipt : boolean;


    public transactionDetails: any;
    public ClientTransactionsModel : any ;

    get isOrderHistory() {
        return this.model &&
               typeof this.model.historyId !== "undefined" &&
               typeof this.model.orderId !== "undefined"
    }


    constructor() {
      this.ClientTransactionsModel  = getNg1Service('ClientTransactionsModel');
    }

    public switchTransactionsDetails() {

        if (this.transactionDetails) {

            delete this.transactionDetails;

        } else {

            if (this.isOrderHistory)
                this.transactionDetails =
                    this.ClientTransactionsModel.getOrderHistoryItemDetails( {
                            orderId: this.model.orderId,
                            historyId: this.model.historyId
                    });

            if (this.isCancelReceipt)
                this.transactionDetails =
                    this.ClientTransactionsModel.getCanceledTransactionDetails( {
                        checkId: this.model.check.id
                    });

            if (!this.isOrderHistory && !this.isCancelReceipt)
                this.transactionDetails =
                    this.ClientTransactionsModel.getClientTransactionDetails( {
                        checkId: this.model.check.id
                    });

            return this.transactionDetails.$promise;

        }

    }

    public switchResultsByItem(checkID : number , item: any) {

        if ( item && item.results && item.results !== true ) {
            delete item.results;
            return;
        }

        item.results = true;

        return this.isOrderHistory
            ? this.ClientTransactionsModel.getOrderHistoryItemResultsByPosition({
                    orderId: this.model.orderId,
                    historyId: this.model.historyId,
                    positionId: item.item.position
              }, function (data: any) {
                item.results = data;
              }).$promise

            : this.ClientTransactionsModel.getClientTransactionResultsByItem({
                checkId: checkID,
                positionId: item.item.position
            }, function (data: any) {
                item.results = data;
            }).$promise;

    }

    public switchResultsByCheck( transaction: any ) {

        if (!transaction.hasResults)
          return ;

        if (transaction && transaction.results && transaction.results !== true) {
            delete transaction.results;
            return;
        }

        transaction.results = true;

        return this.isOrderHistory

        ? this.ClientTransactionsModel.getOrderHistoryItemResults({
                orderId: this.model.orderId,
                historyId: this.model.historyId,
            },function (data : any ) {
                transaction.results = data;
            }).$promise

        : this.ClientTransactionsModel.getClientTransactionResultsByCheck({
                checkId: transaction.check.id
            },function (data : any ) {
                transaction.results = Array.isArray(data) ? data :
                                           !!data?.results ? data.results :
                                                            undefined ;
            }).$promise;

    }

    public switchPayments( transaction: any ) {

      if (this.isOrderHistory)
        return ;

      if (transaction && transaction.payments && transaction.payments !== true) {
        delete transaction.payments;
        return;
      }

      transaction.payments = true;

      this.ClientTransactionsModel.getClientTransactionResultsByCheck({
          checkId: transaction.check.id
        },function (data : any ) {
          transaction.payments = !!data?.payments ? data.payments : undefined ;
        }).$promise;

    }

    public hasPayments(transaction: any) {
      return transaction && Array.isArray(transaction?.payments) && !!transaction?.payments?.length && transaction.check.chType!==2;
    }

    public getRequestName( idRequest: string ) {
        return REQUEST_TYPE_NAMES[idRequest] || '';
    }

    public getClientPaid( model: any ) {

        if ( !model || typeof model.paidInCurrency === "undefined" ) return;

        let result = model.paidInCurrency;

        if ( model.usedBonusInCurrency ) {
            result -= model.usedBonusInCurrency;
        }

        return result;
    }


};
