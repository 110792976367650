/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MechanicCategoryApiConfiguration, MechanicCategoryApiConfigurationInterface } from './mechanic-category-api-configuration';

import { MechanicCategoryApiService } from './services/mechanic-category-api.service';

/**
 * Provider for all MechanicCategoryApi services, plus MechanicCategoryApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    MechanicCategoryApiConfiguration,
    MechanicCategoryApiService
  ],
})
export class MechanicCategoryApiModule {
  static forRoot(customParams: MechanicCategoryApiConfigurationInterface): ModuleWithProviders<MechanicCategoryApiModule> {
    return {
      ngModule: MechanicCategoryApiModule,
      providers: [
        {
          provide: MechanicCategoryApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
