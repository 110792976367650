'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";


let routePath   = module.name + '.campaign.campaigns.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(  '<campaigns-view-page [id]="id"></campaigns-view-page>', true )
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])

        .permissions( {
          only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],
        })

        .breadcrumbs(["Кампании", "Просмотр", "Инфо о кампании"])

    }])

