import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import { getNg1Service } from '../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../shared/service/confirmAction/confirmAction.service";
import {ClientService} from "../../../shared/service/api/Client/Client.service";
import {ReferrerService} from "../../../shared/service/api/Referrer/Referrer.service";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {RawReferrerRecord} from "../../../api/ReferrerApi/models/raw-referrer-record";

@Component({
  selector: 'referrer-view-page',
  templateUrl: './referrer-view-page.component.html',
  providers: [
    StatesChangerService,
    ClientService,
    ReferrerService
  ]
})
export class ReferrerViewPageComponent implements OnInit, OnChanges{

  @Input() id: any;
  @Input() clientId: any;

  public currentReferrer;
  public currentClient;

  public editReferrer;

  public mcategoryForm;

  private uiRouterState;
  public records : Array<RawReferrerRecord> = [];

  public isCorrectOn = false;

  public  OPER_TYPES = {
    credit :  "Начисление",
    debit  :  "Списание"
  }

  get isCreateForm() {
    return this.id === '' && this.clientId!=='';
  }

  get isUpdateForm() {
    return !!this.clientId && this.id !== '';
  }

  constructor(
    public statesChangerService : StatesChangerService,
    public confirmActionService : ConfirmActionService,
    public referrerService      : ReferrerService,
    public clientService        : ClientService,
  ) {

  }

  ngOnInit() {
    this.bindButtons();
    this.uiRouterState = getNg1Service('$state');
    this.referrerService.gerRecordsParams.setSort('date','desc')

    if (this.isCreateForm) {
      this.editReferrer = {};
      this.prepareCreateForm();
    }

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getReferrer();
    }
  }

  onSave() {
    if ( this.isUpdateForm ) {
      //update

      if (
        !this.editReferrer?.promocode
      )
        return;

      this.referrerService.update$({
        id : this.currentReferrer.id,
        referrer: {
          nickname: this.editReferrer.nickname,
          promocode: this.editReferrer.promocode,
        }
      })
        .subscribe((result) => {
          this.uiRouterState.go('^.view', {id: this.currentReferrer.id}, {reload: true});
        }, error => {
          error.stopPopupError();
          this.currentClient.$error = error.error.message;
        });

    } else {
      if (
        !this.editReferrer?.promocode ||
        !this.currentClient?.client?.id
      )
        return;

      this.referrerService.create$({
        clientId: this.currentClient.client.id,
        nickname: this.editReferrer.nickname,
        promocode: this.editReferrer.promocode,
      })
        .subscribe((result) => {
          this.uiRouterState.go('^.view', {id: result.id});
        }, error => {
          error.stopPopupError();
          this.currentClient.$error = error.error.message;
        });
    }


  }

  onCancel() {
    if (this.isUpdateForm) {
      this.statesChangerService.changeState('view');
    } else {
      this.uiRouterState.go('^.index');
    }

  }

  onDelete() {
    this.referrerService.archive$(this.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      });
  }

  onPreview() {

  }

  onEdit() {
    this.editReferrer = {
      nickname  : this.currentReferrer.nickname,
      promocode : this.currentReferrer.promocode,
    }
    this.prepareCreateForm();
  }

  getReferrer() {

    if (this.isCreateForm)
      return;

    this.referrerService
      .get$(this.id)
      .subscribe( result => {
        this.currentReferrer = result;
        this.clientId = this.currentReferrer.clientId;
        this.getClientById(this.currentReferrer.clientId)
          .toPromise()
          .then(() => {
            this.resetRecordPage();
          }, () => {
            this.resetRecordPage();
          })
      })
  }

  nextRecordPage(){
    if (!this.currentReferrer?.account)
      return;

    this.referrerService.gerRecords$(
        Object.assign({}, this.referrerService.gerRecordsParams.params,
        {
          accountId: this.currentReferrer.account.id
        }
      )
    ).subscribe( result => {
      this.records = this.records.concat(result);
      this.referrerService.gerRecordsParams.next();

    }, () => {

      this.referrerService.gerRecordsParams.next();

    })
  }

  getClientById(id) {

    return this.clientService.getClient$(id)
      .pipe(
        map( i => {
          this.currentClient = i;
          return i;
        },
          catchError( error => {
            this.currentClient = {
              $error: 'Клиент не найден'
            };
            return error;
          })
        )
      )

  }

  prepareCreateForm() {
    this.getClientById(this.clientId)
      .subscribe( result => {
        this.statesChangerService.changeState('edit');
      }, error => {
        this.statesChangerService.changeState('edit');
      })
  }


  resetRecordPage() {
    this.referrerService.gerRecordsParams.reset();
    this.records = [];
    this.nextRecordPage();
  }

  refreshPage() {
    this.getReferrer();
  }

}
