import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'

import {MechanicCategoryApiService} from "../../../../../api/MechanicCategoryApi/services/mechanic-category-api.service";
import {MechanicCategory} from "../../../../../api/MechanicCategoryApi/models";
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {NgForm} from "@angular/forms";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";


@Component({
  selector: 'mechaniccategory-view-page',
  templateUrl: './mechaniccategory-view-page.component.html',
  providers: [
    StatesChangerService
  ]
})
export class MechaniccategoryViewPageComponent implements OnInit, OnChanges{

  @Input() id: string;

  @ViewChild('mcategoryForm') mcategoryForm;
  public currentMechanicCategory;

  private uiRouterState;

  constructor(
    private mechanicCategoryService:MechanicCategoryApiService,
    public statesChangerService:StatesChangerService,
    public confirmActionService: ConfirmActionService
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getMechanicCategoryById(changes.id.currentValue)
        .then( result => {
          if (changes.id.firstChange) {

            this.statesChangerService.state = Object.keys(result).length === 0 ?
                                              'edit' :
                                              'view';
          }
        })
      ;
    }
  }

  getMechanicCategoryById( id:string = '' ) {

    if (id === '') {
      this.currentMechanicCategory = {};
      return <any>Promise.resolve(this.currentMechanicCategory);
    }

    return this.mechanicCategoryService
        .get(parseInt(id))
        .toPromise()
        .then( result => this.currentMechanicCategory = result );
  }

  onSave() {

    if ( typeof this.currentMechanicCategory.id !== "undefined" )

        this.mechanicCategoryService.update({
          id: this.currentMechanicCategory.id,
          partner: this.currentMechanicCategory
        }).subscribe(result=> {
          this.currentMechanicCategory = result;
          this.statesChangerService.state = 'view';
        });

    else {

      this.mechanicCategoryService.create(this.currentMechanicCategory)
          .subscribe(result => {
            this.uiRouterState.go('^.view', {id: result.id});
          });

    }
  }

  onCancel() {
    if (typeof this.currentMechanicCategory.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getMechanicCategoryById(this.id)
        .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentMechanicCategory.id === "undefined")
        return;

    this.mechanicCategoryService
        .delete(this.currentMechanicCategory.id)
        .subscribe(() => {
          this.uiRouterState.go('^.index');
        })
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }



}
