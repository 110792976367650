/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CommunicationApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CommunicationApiConfiguration {
  rootUrl: string = location.origin;
}

export interface CommunicationApiConfigurationInterface {
  rootUrl?: string;
}
