import {Injectable} from "@angular/core";
import {CreditAccountService as CreditAccountServiceApi} from "../../../../api/CreditAccount/services";
import {ParamsHandler} from "../ParamsHandler/Params.handler";


@Injectable({
  providedIn: "root"
})
export class CreditAccountService {

  constructor( private creditAccountService:CreditAccountServiceApi ) {
  }

  public getRecordsParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    sort: {
      type: "string",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'creditAccountId',
        currentValue: undefined
      }
    ]
  });

  public get$(accountId: number){
    return this.creditAccountService.getByAccountId(accountId)
  }

  public getRecords$(params: CreditAccountServiceApi.GetRecordsParams){
    return this.creditAccountService.getRecords(params)
  }


}
