import {Component, ElementRef, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'grid-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./grid-container.component.scss']
})
export class GridContainerComponent implements OnChanges {

    @Input() columns;

    private root: Element;

    constructor(
        private el: ElementRef,
      ) {
        this.root = el.nativeElement;
        this.root.classList.add('grid-container');
   //     this.addDummy();
    }

    public addDummy() {

        const dummy = document.createElement('div');
        dummy.className='grid-item grid-item_dummy';

        for (let i = 0; i<5 ; i++) {
            this.root.appendChild( dummy.cloneNode() );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['columns']) {
          this.root.classList.forEach(className => {
            if (className.startsWith('grid-container_columns-'))
              this.root.classList.remove(className);
          });

          if (changes['columns'].currentValue)
            this.root.classList.add('grid-container_columns-' + changes['columns'].currentValue);
        }
    }

}
