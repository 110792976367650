import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {DashboardRequestService} from "../services/dashboard-request.service";
import { BLOCK_viberId, BLOCK_ID_SMS, BLOCK_ID_VIBER } from "../block-ids.variables";

export const BLOCK_ID = BLOCK_viberId;

@Component({
  selector: 'dashboard-viber-block',
  template: `

    <div class=" card wrapper-md padder    item">
      <div class="row m-b-n">

        <div class="col-sm-12 col-md-4 col-lg-4 m-b">

          <span class="text-info">
            <ng-container
              *ngIf="!isLoading"
            >
              <div class="h1 font-thin viberCount" [innerText]="valueCount|| 0" data-testid="viberCount"></div>


            </ng-container>

            <ng-container
              *ngIf="isLoading"
            >
              <div class="">
                  <i class="fa fa-2x fa-spin fa-spinner"></i>
              </div>

            </ng-container>
          </span>

           <span class="text-muted text-xs">Viber отправлено</span>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 m-b">

          <span class="text-success">
            <ng-container
              *ngIf="!isLoading"
            >
              <div class="h1 font-thin viberCount" [innerText]="viberDelivered|| 0" data-testid="viberDelivered"></div>


            </ng-container>

            <ng-container
              *ngIf="isLoading"
            >
              <div class="">
                  <i class="fa fa-2x fa-spin fa-spinner"></i>
              </div>

            </ng-container>
          </span>

          <span class="text-muted text-xs">доставлено по Viber </span>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 m-b">

          <span class="text-warning">
            <ng-container
              *ngIf="!isLoading"
            >
              <div class="h1 font-thin viberCount" [innerText]="valueSmsCount || 0" data-testid="viberSmsDelivered"></div>


            </ng-container>

            <ng-container
              *ngIf="isLoading"
            >
              <div class="">
                  <i class="fa fa-2x fa-spin fa-spinner"></i>
              </div>

            </ng-container>
          </span>

          <span class="text-muted text-xs">доставлено по SMS</span>
        </div>

      </div>

    </div>

  `,
  providers: [
  ]
})
export class DashboardViberBlockComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public valueCount = 0;
  public valueDelivered = 0;
  public valueSmsCount = 0;
  public isLoading = true


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }


  updateValue(params?) {
    this.isLoading = true
    let count = 3;

    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {
          this.valueDelivered = result;
          this.isLoading = false;
        },

        error: () => {
          this.isLoading = false;
        }
      })

    this.dashboardRequestService
      .get$(BLOCK_ID_SMS, params)
      .subscribe({
        next: result => {
          this.valueSmsCount = result;
          this.isLoading = false;
        },

        error: () => {
          this.isLoading = false;
        }
      })

    this.dashboardRequestService
      .get$(BLOCK_ID_VIBER, params)
      .subscribe({
        next: result => {
          this.valueCount = result;
          this.isLoading = false;
        },

        error: () => {
          this.isLoading = false;
        }
      })

  }


  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
