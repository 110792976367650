'use strict';

import module from 'frontendModule';

import { GroupPaymentLimitation } from './controller';
import * as  templateView      from  './index.html';

export default module.component('groupPaymentLimitation', {
    templateUrl : templateView,
    controller  : GroupPaymentLimitation,

    bindings: {
        groupId : '<',
        state   : '<'
    }

});

