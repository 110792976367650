
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";

import {TreeViewComponent} from "../tree-view/tree-view.component";
import {ITreeNode} from "@circlon/angular-tree-component/lib/defs/api";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";
import {TreeNode} from "@circlon/angular-tree-component";
import {UserSettingsStorage} from "class/user-settings-storage.service";

const LIMIT=30;
const SETTINGS_SORT_ID = 'directives.goodsPopup.sort';

@Component({
  selector: 'goods-tree-view',
  templateUrl: "./goods-tree-view.component.html",
  styleUrls: ['./goods-tree-view.component.scss' ],
  providers: [
  ]
})
 export class GoodsTreeViewComponent implements OnInit, OnChanges {

    @Input() partnerId;
    @Input() showType;

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max : number;
    @Input() selectOne : boolean;
    @Input() dimension;
    @Input() isSkuHide;
    @Input() isShowArchiveSku;

    @Input()  searchQuery: string;

    @Input()  sortingField: string;
    @Output() sortingFieldChange = new EventEmitter();

    @Input() editCallback;

    get isEditMode() {
      return typeof this.editCallback === "function"
    };

    public tree = [];
    public goodList = [];

    public isLoading = false;

    @ViewChild(TreeViewComponent)
    private treeView: TreeViewComponent;

    constructor(
      private goodTreeGetterService: GoodTreeGetterService,
    )
    {
    }

    ngOnInit() {
      this.reset();
    }


    ngOnChanges(changes: SimpleChanges) {

      if (changes['partnerId'] && !changes['partnerId'].firstChange) {
        this.reset();
        return;
      }

      if (changes['searchQuery'] && !changes['searchQuery'].firstChange) {
        this.reset();
        return;
      }

      if (changes['sortingField'] && !changes['sortingField'].firstChange) {

        if (!!changes['sortingField'].currentValue) {
          UserSettingsStorage.set(SETTINGS_SORT_ID, changes['sortingField'].currentValue);
        }

        if ( typeof changes['sortingField'].previousValue !== "undefined" )
          this.reset();

        return;
      }

    }

    get isSearchState() {
      return !!this.searchQuery
    }

    async getTree( filterRootId?, drop= 0, limit = LIMIT ): Promise<ITreeNode[]> {

      this.isLoading = typeof filterRootId === "undefined";

      if ( typeof this.sortingField === "undefined") {
        try {
          await UserSettingsStorage.get(SETTINGS_SORT_ID).then( i =>  {
            this.sortingField = i || 'none';
            this.sortingFieldChange.emit(this.sortingField);
          }, () => {
            this.sortingField = 'none';
          });
        } catch (e) {
          this.sortingField = 'none';
        }

      }

      if (typeof this.partnerId === "undefined")
        return undefined;

      if (this.isSearchState) {
        return this.goodTreeGetterService
          .getSearchTree(
            this.partnerId,
            this.searchQuery,
            filterRootId,
            this.sortingField === 'none' ? undefined : this.sortingField ,
            this.dimension
          )
          .then( res => {
            this.isLoading = false;
            return res;
          }, err => {
            this.isLoading = false;
            return err;
          })
          .then( this.parseSearchResult.bind(this));

      } else
        return this.goodTreeGetterService
            .getTree(
              this.partnerId,
              filterRootId,
              drop,
              limit,
              this.sortingField === 'none' ? undefined : this.sortingField,
              this.dimension
            )
            .then( res => {
              this.isLoading = false;
              return res;
            }, err => {
              this.isLoading = false;
              return err;
            })
            .then( this.parseResult.bind(this) );

    }

    private parseResult(result ): any[] {

      let callbackArray = [];
      result.categories.forEach(
        category => {
          callbackArray.push({
            id          : category.category.categoryId,
            name        : category.category.name,
            category    :  category,
            hasChildren : !!category.categoryCount || !!category.goodCount,
            hasLimits   : !!Object.keys(category?.category?.limits || {}).length
          })
        }
      );

      if (!this.isSkuHide )
        result.goods.forEach(
          good => {
            callbackArray.push({
              id          : good.sku,
              name        : good.name,
              good        : good,
              hasChildren : false,
              hasLimits   : !!Object.keys(good?.limits || {}).length
            })
          }
        );

      return callbackArray;
    }

    private parseSearchResult(result ): ITreeNode[] {


      let callbackArray = [];

      let parseCatalogRecourse = (item, callbackArray = []) => {

        let childrenArray = []

        callbackArray.push({
          id          : item.category.categoryId,
          name        : item.category.name,
          category    : item,
          children    : childrenArray
        })

        if ( Array.isArray( item.categories ) && item.categories.length ) {
          item.categories.forEach( subCat => {
            parseCatalogRecourse( subCat, childrenArray)
          })
          delete item.categories;
        }

        return callbackArray;
      }

      result?.tree?.forEach(
        item => {
          parseCatalogRecourse( item, callbackArray )
        }
      );

      this.goodList = [];
      if (!this.isSkuHide) {
        this.goodList = result.goods
                .filter( i => this.isShowArchiveSku || (!this.isShowArchiveSku && i.state !== 'archive' ) )
                .map( i => ({
                    id: i.sku,
                    label: i.name,
                    partnerId: i.partnerId,
                    dimension: i.dimension

                  })
                );
      }



      return callbackArray;
    }

    public getChildren( {node, resolve, reject} ) {

      if (this.isSearchState)
        return false;

      this.getTree( node.id )
        .then( ( result : any ) => {

          if ( result.filter(i => !!i.good).length < LIMIT ) {
            node.data[TreeViewComponent.END_FLAG_ID] = true;
          }

          return result;

        })
        .then(resolve, reject)

    }

    public getNext(node: TreeNode) {

      node.data.$isLoading = true;

      this.getTree( node.id, node.data.children.filter( i => !!i.good).length )
        .then( (result: any[]) => {

          node.data.$isLoading = false;

          if ( !Array.isArray(result) )
            return

          let onlyGoods = result.filter(i => !!i.good);

          onlyGoods.forEach(i => {
            node.data.children.push(i)
          })

          node.data[TreeViewComponent.END_FLAG_ID] = !onlyGoods.length || onlyGoods.length < LIMIT;

          this.treeView?.redraw();
        }, () => {
          node.data.$isLoading = false;
        })

    }

    public async reset() {
      this.tree = await this.getTree();
      this.treeView?.updateTree(!!this.searchQuery);
    }


}
