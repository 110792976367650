'use strict';

import module from 'frontendModule';


import 'model/GiftCertificateModel/resource';
import {RoleType} from "module/permissions/conf/roles";
import {UserSettingsStorage} from "class/user-settings-storage.service";

let routePath = module.name + '.editions.certificate.index';
module.requires = module.requires.concat([
  'infinite-scroll',
  'ui.footable'
]);

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   () => import( /* webpackChunkName: "assets/pages/certificate.rules.index" */ "./views/index.html"  ))
        .controller( () => import( /* webpackChunkName: "assets/pages/certificate.rules.index" */ "./controllers"       ))

        .resolve('filterSettings', [function () {
          return UserSettingsStorage
            .get('editions.certificate.index.filter')
            .then( ( val : any ) => JSON.parse(val ) )
            .catch(() => ({
              'sorting.field': 'date',
              'sorting.order': 'asc',
              'filter.status': 'created,deployed,used,blocked'
            }) );

        }]  )

        .permissions( {
          only: [ RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter ],
          permit: 'editions'
        })

        .breadcrumbs( [ "Подарочные сертификаты" , "Сертификаты"]);

    }]);
