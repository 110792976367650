export default {

  "limitType" : {
    "none"       : 'Ограничения на оплату бонусами не заданы',
    "bycampaign" : 'Оплата бонусами ограничена по кампаниям и купонным правилам',
    "completeProhibit"  : 'Оплата бонусами полностью запрещена',
    "byCashDiscount" : 'Оплата бонусами ограничена на позиции со скидкой кассы',
    "ignoreAllProhibit" : 'Игнорируются все ограничения на оплату бонусами'
  }

}