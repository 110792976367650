import {Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {LocationsService} from "../../../../../shared/service/api/Locations/Locations.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {Location} from "../../../../../api/LocationApi/models/location";
import {LocationGroupService} from "../../../../../shared/service/api/LocationGroup/LocationGroup.service";
import {LocationGroupLinkService} from "../../../../../shared/service/api/LocationGroupLink/LocationGroupLink.service";


@Component({
  selector: 'locations-view-page',
  templateUrl: './locations-view-page.component.html',
  providers:[
    StatesChangerService,
    ConfirmActionService,
    LocationsService,
    PartnerService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.locations'
    },
  ],
})
export class LocationsViewPageComponent implements OnInit{

  @Input() id: number;
  @Input() partnerId: string;

  @ViewChild('locationFrom') locationFrom;

  public currentLocation: Location = <any>{};
  private uiRouterState;

  public currentPartner;
  public locationItemGroups;
  public locationItemGroupsList = [];

  public applyCashDiscountList = {
    "bySum"    : "все вознаграждения суммируются",
    "onlyCash" : "приоритет скидок кассы",
    "maxValue" : "по максимальному вознаграждению"
  }

  constructor(
    public  locationsService: LocationsService,
    public  locationGroupService: LocationGroupService,
    public  locationGroupLinkService: LocationGroupLinkService,

    public  statesChangerService:StatesChangerService,
    public  confirmActionService :ConfirmActionService,
    private partnerService :PartnerService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getLocation( changes.id.currentValue )
        .then( (result:any) => {
          if (changes.id.firstChange) {
            this.statesChangerService.state = !changes.id.currentValue ?
              'edit' :
              'view';
          }
        });
//      this.initLocationGroupLink( changes.id.currentValue );
    }

    if ( changes.partnerId ) {
      this.partnerService.get$(changes.partnerId.currentValue)
        .subscribe( result => {
          this.currentPartner = result;
        })
      ;
      this.initLocationGroups();
    }
  }

  initLocationGroups() {
    let partnerId = parseInt(this.partnerId);
    if ( typeof partnerId !== "number")
      return;

    this.locationGroupService
      .getList$({
        filter: {
          partnerId
        },
        pager:{
          drop: 0,
          limit: 999999
        }
      })
      .subscribe( result => {
        this.locationItemGroupsList = result.map( i => ({
          id: i.id,
          name: i.name,
          code: i.code
        }))
      })
  }
/*

  initLocationGroupLink( id? ) {

    if ( typeof id === "undefined" || id ==='' ) {
      return;
    }

    this.locationGroupLinkService
        .getList$({
          merchantId: id
        }).subscribe( result => {
          this.locationItemGroups = Array.isArray(result) ? result.map( i => i.groupId) : [];
    })

  }
*/

  getLocation( id:number  ) {

    return this.locationsService
      .getOrCreate$(id)
      .toPromise()
      .then( result => {
        this.currentLocation = result
        if (typeof this.currentLocation?.partnerId === "undefined") {
          this.currentLocation.partnerId = parseInt(this.partnerId);
        }
      })

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  onSave() {

    this.locationsService
      .createOrUpdate$(this.currentLocation)
      .toPromise()
      .then(result => this.currentLocation = result )
      .then(() => this.statesChangerService.state = 'view')

  }

  onCancel() {
    if (typeof this.currentLocation.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getLocation(this.id)
      .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {

    if (typeof this.currentLocation.id === "undefined")
      return;

    this.locationsService
      .delete$(this.currentLocation.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  getNameOdLocationGroup(id) {
    return this.locationItemGroupsList.find( i => i.id === id)?.name || '';
  }

  compareLocation(a, b) {
    const id_a = typeof a === "object" ? a?.id : a;
    const id_b = typeof b === "object" ? b?.id : b;

    return id_a === id_b;
  }


}
