import {Component, ElementRef, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FormGroup} from "@angular/forms";

enum FORM_STATES  {
    MESSAGE,
    SENDING,
    CONFIRM,
    ERROR
};

@Component({
  selector: 'feedback-button',
  template: `
    <div class="nav navbar-right pull-right db-right no-pad-xs m-r-none hidden-xs"
    >

      <li class="dropdown d-inline-block"
          ngbDropdown
          auto-close="disabled"
          [(open)]="isOpen"
      >

        <a href="javascript:void(0)" class=" pos-rlt feedback-icon"
           ngbDropdownToggle
           aria-haspopup="true"
           aria-expanded="true">
          <i class="fa fa-lightbulb-o fa-2x text-primary-lter "></i>
        </a>

        <div
          ngbDropdownMenu
          class="padder drop-container p-t-none p-b-none">

          <form #itemForm="ngForm" name="feedbackForm">
            <div class="row padder  bg-light b-b p-t-sm p-b-sm ">
              <span class="">Помогите сделать LOYA лучше</span>
            </div>

            <div class=" pos-rlt p-t-sm view-container">

                    <span *ngIf="isMessageState || isSendingState">

                        <div class="f-s-9 text-muted">
                            <ul class="m-l-n-sm">
                                <li>У вас есть идея?</li>
                                <li>Хотите доработку?</li>
                                <li>Или просто пожелание?</li>
                            </ul>
                          <!-- <div class=" padder clearfix m-b-xs">Мы будем рады их услышать</div> -->
                        </div>

                        <div class="line-dotted clearfix m-b-xs"></div>

                        <div class="padder m-b-xs text-muted f-s-9">
                            Пожелания<span class="text-danger">*</span>:
                        </div>
                        <div class="padder m-b-sm">
                            <textarea
                              class="form-control mini-scroll"
                              required="true"
                              placeholder="Ваши пожелания"
                              rows="5"
                              name="message"
                              [maxLength]="3200"
                              [(ngModel)]="message"
                            ></textarea>
                        </div>

                        <div class="padder m-b-xs text-muted f-s-9">
                            Телефон для связи:
                        </div>
                        <div class="padder m-b-sm">
                            <input
                              type="text"
                              placeholder="Ваш телефон"
                              class="form-control"
                              name="phone"
                              [(ngModel)]="phone"
                              [maxLength]="40"
                              pattern="^[\\+\\(\\)-\\s\\.\/0-9]*$"
                            >
                        </div>

                    </span>

              <div class=" backdrop text-center fade in bg-light"
                   *ngIf="isSendingState">

                <div class="hbox">
                  <div class="col v-middle">
                    <i class="fa fa-spinner fa-spin fa-4x text-warning"></i>
                  </div>
                </div>

              </div>

              <div class="p-t-lg p-b-lg bg-white text-center pointer"
                   (click)="closeDialog()"
                   *ngIf="isConfirmState">

                <div class="hbox">
                  <div class="col v-middle">
                    <i class="fa fa-smile-o fa-4x text-success"></i>
                    <div class="clearfix"></div>
                    <div class="m-t-sm m-t-sm text-lg">
                      Ваше сообщение отправлено
                    </div>
                  </div>
                </div>

              </div>


              <div class="p-t-lg p-b-lg bg-white text-center"
                   (click)="closeDialog()"
                   *ngIf="isErrorState">

                <div class="hbox">
                  <div class="col v-middle">
                    <i class="fa fa-frown-o fa-4x text-danger"></i>
                    <div class="clearfix"></div>
                    <div class="m-t-sm text-lg">
                      Сообщение не доставлено
                    </div>
                    <div class=" text-xs text-muted">
                      Попробуйте повторить запрос позже
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="row padder text-right bg-light b-t p-t-sm p-b-sm"
                 *ngIf="isMessageState || isSendingState">

              <button
                (click)="sendMessage()"
                class="btn btn-success btn-addon btn-sm"
                [disabled]="itemForm.form.invalid"
              >
                <i class="fa fa-send"></i>
                <span class="btn-name">Отправить</span>
              </button>

            </div>
          </form>

        </div>

      </li>

      <li class="dropdown  d-inline-block">

        <a href="https://manual.retail-soft.pro/pages/viewpage.action?pageId=50615636"
           target="_blank"
           class=" pos-rlt dropdown-toggle feedback-icon"
        >
          <i class="fa fa-question-circle fa-2x text-primary-lter "></i>
        </a>

      </li>

    </div>


  `,
  styleUrls: ['./feedback-button.component.scss'],
})
export class FeedbackButtonComponent implements OnInit {

    public message : any;
    public phone   : string;

    form = new FormGroup({});

    private curState: FORM_STATES;

    get isMessageState() { return this.curState === FORM_STATES.MESSAGE };
    get isSendingState() { return this.curState === FORM_STATES.SENDING };
    get isConfirmState() { return this.curState === FORM_STATES.CONFIRM };
    get isErrorState()   { return this.curState === FORM_STATES.ERROR };

    private _isOpen: boolean = false;
    get isOpen() { return this._isOpen }
    set isOpen( value: boolean ){

        if ( value ) {
            this.curState = FORM_STATES.MESSAGE;
            this.bindClick();
        } else {
            this.unbindClick();
        }

        setTimeout(() => this._isOpen = value);
    }

    constructor( private el: ElementRef,
                 private http: HttpClient,
    ) { }

    public ngOnInit() {
        this.curState = FORM_STATES.MESSAGE;
    }

    private clickListener = (event : any) => {

        let currentParent = event.target;
        let insideClick = false;

        do {
            currentParent = currentParent.parentNode;
            insideClick = insideClick || (currentParent === this.el.nativeElement);
        } while( !insideClick && currentParent );

        if (!insideClick) {
            this.isOpen = false;
        }

     };

    private bindClick() {
        document.addEventListener('mousedown', this.clickListener );
    }

    private unbindClick() {
        document.removeEventListener('mousedown', this.clickListener);
    }

    public closeDialog() {
        this.isOpen = false;
    }

    public sendMessage() {

        this.curState = FORM_STATES.SENDING;

        return this.http.post( '/user/feedback', this.prepareMessage())
                 .toPromise()
                 .then( () => this.confirmSending(), () => this.errorSending() );

    }


    private prepareMessage = () => ({
        currentUrl   : this.getUrl(),
        message      : this.message,
        phoneNumber  : this.phone
    });

    private getUrl = () => window.location.href;

    private confirmSending() {
        this.message  = '';
        this.phone    = '';
        this.curState = FORM_STATES.CONFIRM;
    }

    private errorSending() {
        this.message  = '';
        this.phone    = '';
        this.curState = FORM_STATES.ERROR;
    }




}
