export const BLOCK_ID_BonusId = 'bonuses';
export const BLOCK_CampaingId='campaignDiscounts';
export const BLOCK_ActiveCampaignId='campaigns';
export const BLOCK_SegmentsId='segments%CLIENTS%';
export const BLOCK_ID_AVG_WITHOUT_CLIENT = "avgCheckWithoutClient";
export const BLOCK_ID_AVG_WTH_CLIENT = "avgCheckWithClient";
export const BLOCK_ID_CHECK_WITHOUT_CLIENT = "checkLengthWithoutClient";
export const BLOCK_ID_CHECK_WITH_CLIENT   = "checkLengthWithClient";
export const BLOCK_revenueId='segments';

export const BLOCK_emailId='emailCosts';
export const BLOCK_emailCount='emailCount';
export const BLOCK_receiptCount='checkInfoCount';
export const BLOCK_smsCost='smsCosts';
export const BLOCK_smsCount='smsCount';
export const BLOCK_viberId='viberByViberCount';
export const BLOCK_ID_SMS='viberBySmsCount';
export const BLOCK_ID_VIBER='viberCount';

export const BLOCK_checkCount='checkCount';
export const BLOCK_bonusAward='bonusAward';
export const BLOCK_incomeTotalId='incomeTotal';
export const BLOCK_costsDiscount='costsDiscount';
export const BLOCK_totalGift='costsGift';

