<div class="bg-light b-b padder wrapper-sm">

  <filter-date-period
    class="m-r-sm"
    [modelFrom]="newsletterService.queryParams.getFilterValue('fromDate')"
    [modelTo]="newsletterService.queryParams.getFilterValue('tillDate')"
    (modelFromChange)="newsletterService.queryParams.setFilterValue('fromDate',$event);"
    (modelToChange)="newsletterService.queryParams.setFilterValue('tillDate',$event);"
    (onChange)="reset()"
    [maxPeriodMonth]="6"
    [requiredFrom]="true"
    [requiredTo]="true"
  ></filter-date-period>

  <filter-checkbox

    class="m-r-sm"
    [name]         = "'Канал'"
    [canSelectAll] = "false"
    [showClearButton] = "false"
    [inputValues]  = "ChannelTypeNames"
    [selectedValues] = "newsletterService.queryParams.getFilterValue('channels')"
    (selectedValuesChange) = "newsletterService.queryParams.setFilterValue(
                    'channels',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <filter-checkbox

    [name]         = "'Источник'"
    [canSelectAll] = "false"
    [showClearButton] = "false"
    [inputValues]  = "SourceTypeNames"
    [selectedValues] = "newsletterService.queryParams.getFilterValue('sourceType')"
    (selectedValuesChange) = "newsletterService.queryParams.setFilterValue(
                    'sourceType',
                    $event
                );
            reset();"
  ></filter-checkbox>

</div>


<ng-container
  *ngIf="!!newsletterService.queryParams.getFilterValue('fromDate') && !!newsletterService.queryParams.getFilterValue('tillDate')"
>

  <div class="streamline b-l b-default m-l-lg m-b padder-v"

       infiniteScroll
       [infiniteScrollDistance]="1"
       [infiniteScrollThrottle]="50"
       [scrollWindow]="false"
       [infiniteScrollContainer]="'#app-container'"
       [fromRoot]="true"
       (scrolled)="onScroll()"

  >

    <div *ngFor="let item of newsletterList "
         data-testid="gate.item in campaignList">

      <div class="pull-left m-l-n-md trans-icon"
           [ngbPopover]=" 'Рассылка ' + ChannelTypeNames[item?.channel] "
           [triggers]="'mouseenter:mouseleave'"
           [attr.data-testid]="item?.channel"
      >
        <i class="fa"
           [ngClass]="getStateIcon(item)"
        ></i>
      </div>


      <div class="m-l-lg">
        <div class="m-b-xs">
          <div class="m-b-xs">

            <span class="h5 m-r-sm"
                  *ngIf="item.campaignId !== undefined">
              <i class="fa fa-rocket text-success m-r-xs"></i>
              <a href=""
                 [uiSref]="'frontend.campaign.campaigns.view({id:'+   item.campaignId +'})'"
                 [innerText]="item.campaignName || 'Кампания с ID: ' + item.campaignId  "
                 data-testid="gate.campaignName"></a>
            </span>

            <span class="h5 m-r-sm"
                  *ngIf="item.segmentId !== undefined">
               <i class="fa fa-pie-chart text-success m-r-xs"></i>
               <a href=""
                  [uiSref]="'frontend.segments.view({id:'+item.segmentId+'})'"
                  [innerText]="item.segmentName || 'Сегмент с ID: ' + item.segmentId"
                  data-testid="gate.segmentName"></a>
            </span>

            <span class="h5 m-r-sm" *ngIf="item.groupId !== undefined">
              <i class="fa fa-users text-success m-r-xs"></i>
              <a href=""
                 [uiSref]="'frontend.segments.groups.view({id:  '+ item.groupId + ', state: \'deploy\'})'"
                 [innerText]="item.groupName || 'Группа c ID ' + item.groupId"
                 data-testid="gate.groupName"></a>
            </span>

            <span class="h5 m-r-sm" *ngIf="item.triggerId !== undefined">
              <i class="fa fa-link text-success m-r-xs"></i>
              <a href=""
                 [uiSref]="'frontend.triggers.triggers.view({id:'+item.triggerId+'})'"
                 [innerText]="item.triggerName"
                 data-testid="gate.triggerName"></a>
            </span>

            <span class="h5"
                  *ngIf="item.forall">
                Для всех клиентов
            </span>

            <span class="m-l-sm pull-right m-r">

                  <span  class="text-muted"
                  >
                    <span [innerText]="item.startDateTime | loyaDateTime" data-testid="gate.startDateTime"></span>

                    <span class="" *ngIf="item.stopDateTime">
                      - <span [innerText]="item.stopDateTime | loyaDateTime" data-testid="gate.stopDateTime"></span>
                    </span>
                  </span>

                  <div class="clear text-right text-left-xs m-t-xs ">
                    <span class="text-muted m-r-xs">Статус рассылки:</span>
                    <span [ngClass]="getStateColor(  item.state)"
                          [innerText]="CampaignStatusNames[item.state] || item.state" data-testid="gate.state"></span>
                  </div>
                </span>

            <div class="clear m-t-xs"></div>

            <i class="fa fa-money text-muted fa-lg m-l-xxs m-r-xs "></i>

            <span class="">
                  <span class="text-muted ">
                    Потрачено:
                  </span>
                  <span class="text-success m-r-xs"
                        [innerText]="item.statistic?.totalCost  || 0 | loyaCurrency "
                        data-testid="gate.cost"></span>
                  <i class="fa le text-success" ngCurrencyLoya> </i>

            </span>

          </div>


          <div class="m-b">
            <div>

              <div class="pull-left m-r ">

                <span class="text-muted ">
                  Сообщений всего:
                </span>

                <span class=" text-primary"
                      [innerText]="item.statistic?.totalCount || 0"
                      data-testid="gate.totalCount">
                </span>

              </div>

              <div class="d-inline m-r-md ">
                <i class="fa fa-clock-o text-muted m-r-xs"></i>
                <span class="text-muted m-r-xs">В обработке:</span>
                <span class="text-warning-dker"
                      [innerText]="item.statistic?.inProgress || 0"
                      data-testid="gate.unprocessed"></span>
              </div>


              <div class="d-inline m-r-md ">
                <i class="fa fa-envelope-o text-muted m-r-xs"> </i>
                <span class="text-muted m-r-xs ">Доставлено:</span>
                <span class="text-success-dker"
                      [innerText]="item.statistic?.delivered || 0" data-testid="gate.done"></span>
              </div>

              <div class=" d-inline-block  m-r-md">
                <span class="text-muted">
                  <i class="fa fa-envelope-open-o m-r-xxs "></i>
                  Прочитано:
                 </span>
                <span class="text-success"
                      [innerText]="item.statistic?.read || 0 "
                      data-testid="gate.read"></span>
              </div>

              <div class="d-inline m-r-md ">
                <i class="fa fa-clock-o text-muted m-r-xs"></i>
                <span class="text-muted m-r-xs ">Не доставлено:</span>
                <span class=" text-danger-dker"
                      [innerText]="item.statistic?.error || 0"
                      data-testid="gate.errors"></span>
              </div>

              <div class="clearfix">
              </div>

            </div>
            <div class="m-t-xs">
              <a href="javascript:void(0)" class="text-muted m-xs f-s-9"
                 (click)="resetDetails(item.id)"
                 data-testid="gate.showCampaign()">

                <i class="fa"
                   [ngClass]="{ 'fa-chevron-down':detailsId !== item.id, 'fa-chevron-up': detailsId === item.id && !loader.isLoading('details'), 'fa-refresh fa-spin': detailsId === item.id && loader.isLoading('details') }"
                >
                </i>

                подробнее
              </a>

              <div class="m-b-lg card no-shadow bg-light lt m-r-md"
                   *ngIf="detailsId === item.id && !loader.isLoading('details')"

                   infiniteScroll
                   [infiniteScrollDistance]="1"
                   [infiniteScrollThrottle]="50"
                   [scrollWindow]="false"
                   [infiniteScrollContainer]="'#app-container'"
                   [fromRoot]="true"
                   (scrolled)="onScrollDetails()"
              >
                <div class="card-body no-padder">
                  <div class="panel-inner-table m-t-n-xxs">
                    <table class="table m-b-none">
                      <thead>
                      <tr>

                        <th>Адресат</th>
                        <th>Клиент</th>
                        <th>Статус</th>
                        <th>Цена</th>
                        <th>Создано</th>
                        <th>Обновлено</th>
                      </tr>

                      </thead>

                      <tbody>
                      <tr *ngFor=" let detail of detailsList"
                          data-testid="gate.item in byCampaignList"
                      >
                        <td>
                          <i class="fa m-r-xs"
                             [ngClass]="getClassStatusTransaction(detail.externalState)"
                          ></i>
                          <span
                            [innerText]="detail.recipientContact"
                            data-testid="item.recipientContact"></span>
                        </td>
                        <td>
                          <a href=""
                             [innerText]="detail.recipientName"
                             data-testid="item.recipientName"
                             [uiSref]="'frontend.crm.clients.view({id:' + detail.clientId+ '})'"></a>
                        </td>
                        <td class="text-center">

                            <span
                              [innerText]="getStateNames(detail)"
                              data-testid="item.stateNames"></span>

                          <div class="text-muted text-sm"
                               *ngIf="!!detail.errorMsg"
                               [innerText]="detail.errorMsg"
                               data-testid="item.errorMsg"
                          ></div>

                        </td>
                        <td>
                          <span
                            [innerText]="detail.cost | loyaCurrency"
                            data-testid="item.cost"
                          ></span>

                          <span *ngIf="detail.count>1"
                                [innerText]="'( x'+detail.count+' )'"></span>
                        </td>
                        <td>
                           <span
                             [innerText]="detail.createdAt | loyaDateTime" data-testid="item.createdAt"></span>
                        </td>
                        <td>
                           <span
                             [innerText]="detail.updatedAt | loyaDateTime" data-testid="item.updatedAt"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <div *ngIf="detailsId === item.id && loader.isLoading('details')"
                   class="text-center">
                <i class="fa fa-refresh fa-spin fa-3x text-muted m-t-n-md"></i>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</ng-container>

<ng-container
  *ngIf="!newsletterService.queryParams.getFilterValue('fromDate') || !newsletterService.queryParams.getFilterValue('tillDate')"
>

    <div class="text-center padder m-t-lg">
      <h5 class="text-muted">Задайте временной период</h5>
    </div>

</ng-container>
