/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AccountApiConfiguration as __Configuration } from '../account-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccountUnionParams } from '../models/account-union-params';
@Injectable({
  providedIn: 'root',
})
class AccountApiService extends __BaseService {
  static readonly unionPath = '/account/{id}/union';
  static readonly reindexAllPath = '/reindexAll';
  static readonly reindexPath = '/account/reindex';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Данные об операции
   * @param params The `AccountApiService.UnionParams` containing the following parameters:
   *
   * - `params`: Параметры операции
   *
   * - `id`: ID счета
   */
  unionResponse(params: AccountApiService.UnionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/account/${encodeURIComponent(String(params.id))}/union`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Данные об операции
   * @param params The `AccountApiService.UnionParams` containing the following parameters:
   *
   * - `params`: Параметры операции
   *
   * - `id`: ID счета
   */
  union(params: AccountApiService.UnionParams): __Observable<null> {
    return this.unionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Переиндексировать все индексы
   *
   * Переиндексировать все индексы
   */
  reindexAllResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reindexAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать все индексы
   *
   * Переиндексировать все индексы
   */
  reindexAll(): __Observable<null> {
    return this.reindexAllResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Переиндексировать базу счетов
   *
   * Переиндексировать базу счетов
   */
  reindexResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/account/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать базу счетов
   *
   * Переиндексировать базу счетов
   */
  reindex(): __Observable<null> {
    return this.reindexResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AccountApiService {

  /**
   * Parameters for union
   */
  export interface UnionParams {

    /**
     * Параметры операции
     */
    params: AccountUnionParams;

    /**
     * ID счета
     */
    id: number;
  }
}

export { AccountApiService }
