'use strict';

import module from 'frontendModule';

let routePath = module.name + '.config.directory.campaignLimitations.index';

export default
  module
    .config(
      ['routeBuilderProvider', (routeBuilderProvider : any) => {

        routeBuilderProvider
          .route( routePath )

          .template(   '<campaignlimitations-index-page></campaignlimitations-index-page>', true)
          .breadcrumbs( [ "Справочники", "Ограничения вознаграждений кампаний" ]);

      }]);
