'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';

import 'angular-file-upload';
import 'ng-infinite-scroll';
import 'angular-footable';
import 'ui-select';

import 'directive/jqSparkline/main'

import '../index/factorys';

import 'model/CampaignModel/resource';
import 'model/PartnerModel/resource';
import 'model/CampaignCategoryModel/resource';
import 'model/CampaignCommonModel/resource';
import 'model/SegmentationModel/resource';
import 'model/GroupModel/resource';
import 'model/MsgTemplate';
import 'model/MechanicModel/resource';
import 'model/CampaignLimitationModel/resource';

import '../../../../models/DibsprofilebyclientModel/resource';

import 'directive/menuAnchor/main';
import 'directive/menuAnchor/menuAnchors';

import '../../../../models/DibsprofilebyclientModel/resource';
import {RoleType} from "module/permissions/conf/roles";


let routePath = module.name + '.campaign.campaigns.view2';
module.requires = module.requires.concat([
    'angularFileUpload',
    'infinite-scroll',
    'ui.footable',
    'ui.select'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')

                .template(template)
                .controller(controller)

                .resolve('CampaignItem', ['$stateParams', 'CampaignModel', '$q',
                    function ($stateParams, CampaignModel, $q) {

                        if (!$stateParams.id && $stateParams.id !== 0) {

                            var defer = $q.defer();
                            defer.resolve(new CampaignModel({
                                "campaign": {

                                    "campaignTime": {
                                        "informationDate": 1,
                                        "periods": [
                                            {
                                                "startTime": new Date(1970, 0, 1, 0, 0, 0),
                                                "stopTime": new Date(1970, 0, 1, 23, 59, 0),
                                                "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]
                                            }
                                        ]
                                    },
                                    "communication": {
                                        "useCheck": false,
                                        "usePos": false
                                    },
                                    "listOfTags": [],
                                    "state": "draft"
                                },
                                "mechanic": {
                                    "name": "test designer",
                                    "description": "test desc"
                                },
                                planValues: {
                                    "clientsCount": 0,
                                    "operationCount": 0,
                                    "responseCount": 0,
                                    "responsePercent": 0,
                                    "emailTotal": "0.00",
                                    "smsTotal": "0.00",
                                    "discountTotal": "0.00",
                                    "bonusTotal": 0,
                                    "giftTotal": "0.00",
                                    "additionalTotal": "0.00",
                                    "totalCost": "0.00",
                                    "totalByClient": "0.00",
                                    "clientTotal": "0.00",
                                    "clientTotalPercent": 0,
                                    "clientSelling": "0.00",
                                    "clientSellingTotal": "0.00",
                                    "clientIncome": "0.00",
                                    "clientIncomePercent": 0,
                                    "prevTotal": 0
                                }
                            }));

                            return defer.promise;
                        }

                        return CampaignModel.get($stateParams).$promise;
                    }])

                .resolve('PartnerList', ['PartnerModel',  ( PartnerModel : any ) => PartnerModel.query().$promise ])

                .resolve('CampaignCategoryList', ['CampaignCategoryModel',  ( CampaignCategoryModel : any ) => CampaignCategoryModel.query({"pager": {
                    "drop": 0,
                    "limit": 99999
                  }}).$promise ])

                .resolve('CampaignLimitationsList', [ 'CampaignLimitationModel',  ( CampaignLimitationModel : any ) => CampaignLimitationModel.queryCampaignLimitation({}).$promise ])

                .resolve('segmentsList', ['SegmentationModel',  ( SegmentationModel : any ) => SegmentationModel.query({ "filter": {'state': 'deploy' } }).$promise ])

                .resolve('groupsList', ['GroupModel', '$q', function (GroupModel, $q) {
                    return $q(function (resolve, reject) {

                        var resultArray = [];

                        GroupModel.query({
                            filter: {
                                states: ['deploy']
                            },
                            pager: { drop: 0, limit: 99999 }
                        }, function (result) {

                            angular.forEach(result, function (groupItem) {

                                if (groupItem.group.state === "deploy")
                                    resultArray.push(groupItem);

                            });

                            resolve(resultArray);
                        }, reject);
                    });
                }])

                .resolve('CampaignResources', ['$stateParams', 'CampaignCommonModel',
                    function ($stateParams, CampaignCommonModel) {
                        if (!$stateParams.id && $stateParams.id !== 0) {
                            return [];
                        } else return CampaignCommonModel.campaignResourcesInfo($stateParams).$promise;
                    }])

                .resolve('realValuesCampaign', ['CampaignItem', 'CampaignResources', 'getRealCampaignValues',
                    function (CampaignItem, CampaignResources, getRealCampaignValues) {


                        return getRealCampaignValues(CampaignItem, CampaignResources);


                    }
                ])

                .resolve('dibsCampaign', ['CampaignItem', 'DibsprofilebyclientModel',
                    function (CampaignItem, DibsprofilebyclientModel) {

                      if ( typeof CampaignItem.campaign.id === "undefined")
                        return false;

                      return DibsprofilebyclientModel.getInfoByCampaign({campaignId: CampaignItem.campaign.id}).$promise.then(
                        (val:any) => typeof val === "object" && Object.keys(val).filter(i=>i[0]!=='$').length === 0 ? false : val,
                        () => false
                      );

                    }
                  ])

                .resolve('settingsList', ['Settings',
                  function (Settings) {

                    return new Promise( resolve => {

                      let res = {};

                      Settings.getByKeys(['externalLoyalty'],result => {

                        if (
                          !Array.isArray(result)
                        ) {
                          return resolve({});
                        }

                        result.forEach( i => {
                           res[i.key] = i.value;
                        });

                        return resolve(res);
                      });

                    })

                  }
                ])



        .breadcrumbs(["Кампании", "Просмотр", "Инфо о кампании"])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                })

            ;

        }]);
