import {Component, ElementRef} from "@angular/core";

@Component({
  selector: 'form-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./form-container.component.scss']
})
export class FormContainerComponent {

    private root: Element;

    constructor(
        private el: ElementRef,
      ) {
        this.root = el.nativeElement;
        this.root.classList.add('form-container');

    }

}
