<div class="fixed-tool-panel"
     *ngIf="!!currentCard"
>

  <button type="button"
          data-testid="card.delete()"
          class="btn btn-sm btn-addon btn-danger "
          (click)="confirmActionService.confirm('Вы действительно хотите удалить карту ' + currentCard.number + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
          *ngIf="statesChangerService.hasButton('delete') && currentCard.state !== CARD_STATES.archive"
  >
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
     {{ 'interface.buttons.remove' | transloco }}
    </span>
  </button>


  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          *ngIf="statesChangerService.hasButton('edit')"
          (click)="statesChangerService.click('edit')"
          data-testid="card.edit()"
  >

    <i class=" fa fa-pencil"></i>
    <span class="btn-name m-l-xs hidden-xxs">
       {{ 'interface.buttons.edit' | transloco }}
    </span>
  </button>


  <button type="button"  class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="cardForm && cardForm.form && cardForm.form.invalid"
          data-testid="card.save()"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button"  class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="card.cancel()"
  >

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button"  class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"
          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="cardForm && cardForm.form && cardForm.form.invalid"
          data-testid="card.preview()"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
          data-testid="card.back()"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>

<div class="card no-back w-full"
     *transloco="let t"
>

  <div class="card-body w-full"
       *ngIf="currentCard"
  >
    <form name="cardForm" #cardForm="ngForm" novalidate autocomplete="off">

      <grid-container>

        <div class="grid-item"
             [grid-item-form] = "'Номер карты'"
             grid-item-form-icon = "fa-home"
        >
          <span *ngIf="!statesChangerService.isActive || ( statesChangerService.isActive && !isCreateForm() )"
                [innerText]="currentCard.number"
                data-testid="card.number"
          ></span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive && isCreateForm()"
          >
            <input
              type="text"
              class="form-control form-control-edittable"
              [(ngModel)]="currentCard.number"
              data-testid="card.input.number"
              name="number"
              placeholder="Введите № Карты"
              pattern="^[0-9]+$"
              [required]="true"
              [maxLength]="254"
            >

          </span>

        </div>


        <div class="grid-item"
             [grid-item-form] = "'Статус'"
             grid-item-form-icon = "fa-home"
        >
          <span *ngIf="!statesChangerService.isActive"
                        [innerText]="CARD_STATE_NAMES[currentCard.state] || currentCard.state"
             data-testid="card.state"
          ></span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >

            <select class=" form-control  inline  "
                    [(ngModel)]="currentCard.state"
                    [required]="true"
                    name="state"
                    data-testid="card.input.state"
            >
              <option *ngFor="let c of CARD_STATE_NAMES | keyvalue " [ngValue]="c.key">{{c.value}}</option>
            </select>

          </span>

        </div>

        <ng-container
          *loyaPermitIf="{path:'crm.cards.pin', permitEnabled: ''}"
        >

          <div class="grid-item"
               [grid-item-form] = "'ПИН-код'"
               grid-item-form-icon = "fa-credit-card-alt"
               *ngIf="!isCreateForm()"
          >
            <div data-testid="card.pin">
              {{currentCard.pin}}
            </div>

          </div>

        </ng-container>

        <div class="grid-item grid-item_clean"></div>


        <ng-container
          *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
        >

          <div class="grid-item"
               [grid-item-form] = "'Клиент'"
               grid-item-form-icon = "fa-home"
               *ngIf="!isCreateForm()"
          >
            <a [uiSref]="'frontend.crm.clients.view({id:' + currentCard.clientId + '})'"
               data-testid="card.client">
              {{ currentCardInformation?.client?.surname }} {{ currentCardInformation?.client?.name }}
            </a>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'Дата создания'"
               grid-item-form-icon = "fa-home"
               *ngIf="!isCreateForm()"
          >
            <span data-testid="card.createDate">{{ currentCard.createDate | loyaDate }}</span>
          </div>

        </ng-container>


        <div class="grid-item"
             [grid-item-form] = "'Пользователь'"
             grid-item-form-icon = "fa-home"
             *ngIf="!isCreateForm()"
        >
          <div data-testid="card.user.name">
            {{ currentCardInformation?.user?.name }}
          </div>
        </div>


      </grid-container>

    </form>

    <div class="clearfix w-full m-t" *ngIf="!isCreateForm()">

      <client-log-history-ng1
        [cardId]="currentCard.id"
      >
      </client-log-history-ng1>

    </div>

  </div>

</div>

<div class="text-center text-warning w-full m-t-lg m-b-lg"
     *ngIf="isLoading"
>
  <div class="">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
