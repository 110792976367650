import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'card-number-input-ng1'
})
export class CardNumberInputDirective extends UpgradeComponent {

  @Input()  model       : any;
  @Input()  required    : any;
  @Input()  card   : any;

  @Output()  modelChange       : any;
  @Output()  requiredChange    : any;
  @Output()  cardChange  : any;

  @Input()  onError       : any;
  @Output()  onErrorChange : any;


  constructor(elementRef: ElementRef, injector: Injector) {
    super('cardNumberInput', elementRef, injector);
  }
}

