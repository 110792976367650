
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {CampaignDialogService, legacyDirectiveParams} from "../../campaign-dialog/service/campaign-dialog.service";

@Component({
  selector: 'filter-campaign',
  templateUrl: './filter-campaign.component.html',
  providers: [
    CampaignDialogService
  ]
})
export class FilterCampaignComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public partnerId;
  @Output() public partnerIdChange  = new EventEmitter();
            private changedPartnerId;

  @Input() public currentPartner;

  @Input() public max: number;
  @Input() public disabled;
  @Input() public filterIDs;

  @Input() public isPending = false;

  public list = [];

  get hasSelectedCampaigns() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private locationsDialogService: CampaignDialogService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {

    if (
      changes['partnerId'] &&
      !!changes['partnerId']?.currentValue &&
      !!this.changedPartnerId &&
      changes['partnerId']?.currentValue !== this.changedPartnerId
    ) {
      this.clear();
    }

  }

  openDialog() {

    this.locationsDialogService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})

  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};

    if (this.partnerId) {
      options.initPartner = this.partnerId;
    }

    if (typeof this.max !== "undefined") {
      options.max = this.max;
    }

    if (!!this.filterIDs) {
      options.filterIDs = this.filterIDs;
    }

    return options;
  }

  public changeResult(result) {

    this.partnerId = result.partnerId;
    this.changedPartnerId = result.partnerId;
    this.partnerIdChange.emit( this.partnerId );

    if (Array.isArray(result.campaigns)) {

      this.list = result.campaigns.slice();

    }

    this.emitModel();

  }

  private emitModel() {
    this.modelChange.emit(!this.list?.length ? undefined : this.list.map(i => i.id));
  }

  public getName(item) {
    return typeof  item === "object" ? item?.name : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }

}
