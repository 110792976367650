
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy
} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";


@Component({
  selector: 'anchor-menu',
  template: `
    <additional-menu
      [hasFilter]="false"
      [hideMenu]="hideMenu"
      [showAddButton]="false"
    >
      <div menuBlock>
        <ul class="nav nav-scroll flex-column"
            *ngIf="!hideMenu"
        >
          <ng-container *ngFor="let anchor of preparedAnchorList">
            <li *ngIf="!!anchor?.value" class="nav-item" data-testid="anchor of preparedAnchorList">

              <a (click)="selectAnchor(anchor.key)"
                 [ngClass]="{ 'nav-active':selectedAnchor==anchor?.key }"
                 [attr.goto]="anchor?.key"
                 class="overflow-wrap-break nav-link"
              >
                <span [innerText]="anchor?.value" ></span>
              </a>

            </li>
          </ng-container>
        </ul>
      </div>

      <div selectedBlock>

        <span class="h4"
              [innerText]="selectedAnchor ? getAnchorName(selectedAnchor)  : '- Выбрать -'">
        </span>

      </div>

      <div contentBlock
           #anchorContentBlock
      >
        <ng-content ></ng-content>
      </div>

    </additional-menu>`,
  styleUrls: ['./anchor-menu.component.scss'],
  providers: []
})
 export class AnchorMenuComponent implements OnInit, OnChanges, OnDestroy {


  @Input() anchorList: Array<{key:string, value:string}> | Array<string> | {[key:string]:string} = {}

  @Input()  public selectedAnchor : any;
  @Output() public selectedAnchorChange = new EventEmitter();

  @ViewChild('anchorContentBlock') anchorContentBlock;

  public hideMenu = false;
  public preparedAnchorList = [];
  public scrollContainer;

  private bindedScroll;
  constructor() {
  }

  public ngOnInit() {
    this.scrollContainer = document.getElementById('app-container');

    this.bindedScroll = this.onScroll.bind(this)
    this.scrollContainer.addEventListener('scroll' ,this.bindedScroll );

  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['anchorList'] ) {
        this.prepareAnchorList();
        this.checkSelected();
    }
  }

  public prepareAnchorList() {


    this.hideMenu = false;

    if (!this.anchorList) {
      this.hideMenu = true;
      return;
    }

    if ( Array.isArray(this.anchorList) && this.anchorList?.length) {

      if ( typeof this.anchorList[0] === "object" ) {
        this.preparedAnchorList = this.anchorList.slice();
      } else {
        this.preparedAnchorList = (<any>this.anchorList).map( i => ({
          key: i,
          value: i
        }))
      }

      return;
    }

    if ( typeof this.anchorList === "object" ) {
      this.preparedAnchorList = [];
      Object.keys(this.anchorList)
          .forEach( key => {
            this.preparedAnchorList.push({
              key,
              value: this.anchorList[key]
            })
          })
      return;
    }

    this.hideMenu = true;

  }

  public getAnchorName(key) {
    return this.preparedAnchorList.find( i => i.key === key)?.value || key
  }

  public selectAnchor(key) {
    this.selectedAnchor = key;
    this.selectedAnchorChange.emit(key);

    this.scroll(key);
  }

  public scroll(id) {
/*
    let correctHeight = this.anchorContentBlock?.nativeElement
        ?.querySelector('.fixed-tool-panel')
        ?.offsetHeight || 0;
*/

    const box = document.getElementById(id)?.getBoundingClientRect();

    if (!box)
      return;

    const top  = box.top +  this.scrollContainer.scrollTop + this.scrollContainer.getBoundingClientRect().top;

    this.scrollContainer.scrollTo({
      behavior: 'smooth',
      top: Math.round(top) - 160
      /*  finded.getBoundingClientRect().y -
        //    document.body.getBoundingClientRect().top -
        correctHeight,*/
    })

  }


  checkSelected() {

    if ( !this.selectedAnchor || !this.preparedAnchorList.some( i => i.key === this.selectedAnchor) ) {
      if (!!this.preparedAnchorList?.length)
        this.selectAnchor(this.preparedAnchorList[0].key)
    }

  }



  private onScroll() {

    if (
      !this.anchorList ||
      (Array.isArray(this.anchorList) && !this.anchorList?.length ) ||
      (typeof this.anchorList == "object" && !Object.keys(this.anchorList)?.length )
    )
      return;

    let nodes: Array<{
      el:HTMLElement
      rect: any,
      position: any
    }> = [];

    Object.keys(this.anchorList).forEach( (key, index) => {

      const el = document.getElementById(key);
      if (!el)
        return;

      const rect = el.getBoundingClientRect();
      if (!rect)
        return;

      const position = this.scrollContainer.scrollHeight + rect.top;
      nodes.push( { el, rect, position });

    });

    if (!nodes?.length)
      return;

    let activeNode : HTMLElement  =
      nodes.reduce( (lastActive:HTMLAnchorElement, anchorElement, index) => {

        const position = anchorElement.rect;
        switch (true) {
          // Для скрола вверху выбираем верхний элемент
          case this.scrollContainer.scrollTop < 140 :
            return position.top < lastActive.getBoundingClientRect().top ? anchorElement.el : lastActive;

          // Для скрола внизу выбираем последний
          case (this.scrollContainer.scrollTop + this.scrollContainer.clientHeight >= this.scrollContainer.scrollHeight - 50 ):
            return position.top > lastActive.getBoundingClientRect().top ? anchorElement.el : lastActive;

          // Выбираем ближний к центру экрана
          default :
            if ( position.top > this.scrollContainer.clientHeight + this.scrollContainer.getBoundingClientRect().top)
              return lastActive;

            return  Math.abs(position.top - this.scrollContainer.clientHeight /2) < Math.abs(lastActive.getBoundingClientRect().top - this.scrollContainer.clientHeight /2) ? anchorElement.el : lastActive;

        }

      },nodes[0].el);

    this.selectedAnchor = activeNode?.id;

  }

  ngOnDestroy(): void {
    this.scrollContainer.removeEventListener('scroll' , this.bindedScroll);
  }

}
