import { ImageItem } from "../imageUploaderHandler";
import { API_GET_IMAGE, ID_TYPES_IN_SETTINGS } from "../settings";

interface typeItem {
    id   : number,
    name : string
}

enum imageStatus {
    LOADING,
    LOADED,
    ERROR
}


interface imageInfo {
    status : imageStatus,
    width?  : number,
    height? : number,
}


export class imageCarouselUploader {

    // Передаваемые параметры в компонент
    public options      : any;
    public uploader     : Function;
    public max          : number;

    public items        : Array<ImageItem>;

    public onAdd    : Function;
    public onDelete : Function;
    public onChange : Function;

    public getDropContainer : any;

    public isServiceAvailable : boolean  = false;

    private listTypes: Array<typeItem>;
    private checkedImages: Map<string,imageInfo>;

  static $inject = ['$uibModal', 'Settings', '$element', '$http' ];

    constructor( private $uibModal : any,
                 private Settings  : any,
                 private $element  : any,
                 private $http     : any
    ) { }

    public $onInit() {
        this.checkAvailibleService();
        this.checkedImages = new Map();
        this.items = this.items || [];
        this.typesArray();
        this.getSettingsTypes();
        this.getDropContainer = this.$element.find('.for-drop-down');
        this.max = this.max || 10;
    }

    private typesArray() {
        this.items.map( i => {
            if ( !Array.isArray( i.imageType ) ) {
                i.imageType = [i.imageType];
            }
        } )
    }

    private checkAvailibleService() {

        this.$http
            .get('/api/1.0/image/checkService')
            .then(( res: any ) => this.isServiceAvailable = (res.data || res ) === "ok",
                () => this.isServiceAvailable = false);

    }

    public getSettingsTypes() {

        return this.listTypes = this.Settings
                    .query()
                        .$promise
                        .then( (list:Array<any> )=> {

                            this.listTypes = [];

                            if (! Array.isArray(list) ) {
                                return;
                            }

                            let finded = list.filter( i => i.key === ID_TYPES_IN_SETTINGS );

                            if ( !finded.length ) {
                                return;
                            }

                            try {

                                finded = JSON.parse( finded[0].value );
                                this.listTypes = this.listTypes.concat(finded);

                            } catch(e){
                                console.log( `Error setting ${ID_TYPES_IN_SETTINGS}: ${e}` );
                            }

                        });

    }

    public deleteImageEvent(item : ImageItem) {

        if (!item) return;

        this.items.splice( this.items.indexOf(item), 1 );
        this.fireDelete(item);
    }

    public checkImageLoad( item : ImageItem ): imageStatus {

        const url = this.getUrlByGUID(item);

        if ( this.checkedImages.has(url) ) {
            return this.checkedImages.get(url).status;
        }

        let testImg = new Image();
        testImg.src = url;
        testImg.onload  = () => { this.checkedImages.set(url, {
                status : imageStatus.LOADED,
                width  : testImg.width,
                height : testImg.height
            });
        };

        testImg.onerror = () => { this.checkedImages.set(url, { status: imageStatus.ERROR });  };

        this.checkedImages.set(url, {
            status: imageStatus.LOADING
        } );
        return this.checkedImages.get(url).status;
    }

    public getImageInfo( item : ImageItem ) : imageInfo {
        return this.checkedImages.get( this.getUrlByGUID(item) );
    }

    public getUrlByGUID( item : ImageItem ) {
        return `${API_GET_IMAGE}${item.imageId}`;
    }

    public getSize( item : ImageItem ) {
        if ( !this.isLoaded(item) ) {
            return ;
        }

        return this.getImageInfo(item).width +'x'+ this.getImageInfo(item).height;
    }

    public isLoading = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.LOADING;
    public isLoaded  = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.LOADED;
    public isError   = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.ERROR;

    public getGUIDFromUloader( GUID :string) {

        if (this.isMaxImages())
            return;

        const item : ImageItem = {
            imageId   : GUID,
            imageType : this.listTypes.map( i => i.id)
        };

        this.items.unshift( item );
        this.fireAdd(item);
    }

    public hasInfo( item: ImageItem ) {
        if ( !this.isLoaded(item) ) {
            return ;
        }

        let resString = '';

        item.imageType.forEach( type => {
            switch (this.getTypeName(type).toLowerCase()) {

                case "viber":
                    if (this.getImageInfo(item).width !== this.getImageInfo(item).height)
                        resString += 'Для  ' + this.getTypeName(type) + ' рекомендуются пропорции изображения 1:1 <br/>';
                    break;

                case "facebook":
                case "telegramm":
                case "vk":
                    const res = this.getImageInfo(item).height / this.getImageInfo(item).width;
                    if (res < 0.40 || res > 0.60)
                        resString += 'Для ' + this.getTypeName(type) + ' рекомендуются пропорции изображения 1:2 <br/>';
                    break;


            }
        });


        return resString;

    }

    private fireAdd( item: ImageItem) {
        return this.onAdd({ item: item });
    }

    private fireDelete( item: ImageItem) {

        return this.$http
                .delete( '/api/1.0/image/' + item.imageId )
                .then(() => this.onDelete({ item: item }), () => this.onDelete({ item: item }) );

    }

    private fireChange( before: ImageItem,  after: ImageItem) {
        return this.onChange({ before: before, after: after });
    }

    public getTypeName( id: number ) {

        if ( this.listTypes && typeof (<any>this.listTypes).then === "function" ) {
            return;
        }

        let finded = this.listTypes.find( (i:any) => i.id === id );

        if ( typeof finded === "undefined" ) {
            return `Тип с ID ${id}`;
        }

        return finded.name;

    }

    public  setType( item: ImageItem, imageType: number ) {

        if (!item) return;

        const before = Object.assign({}, item);
        if (item.imageType.indexOf( imageType ) < 0) {
            item.imageType.push(imageType);
            this.fireChange(before, item);
        } else {
            item.imageType.splice( item.imageType.indexOf( imageType ),1 );
            this.fireChange(before, item);
        }

    }

    public typeChecked( item: ImageItem, imageType: number ) {

        if (!item || !Array.isArray( item.imageType ) ) return;

        return item.imageType.indexOf( imageType ) >= 0;

    }

    public isMaxImages() {
        return this.max <= this.items.length;
    }




}
