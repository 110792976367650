import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";

@Component({
  selector: 'campaign-row-view',
  templateUrl: './campaign-row-view.component.html',
  styleUrls: [
    `./campaign-row-view.component.scss`
  ],
  providers: [
  ]
})
export class CampaignRowViewComponent implements OnInit, OnChanges {

  @Input() name = '';
  @Input() index = 1;

  constructor(
  ) {
  }

  get badgeColor() {
    switch (true) {
      case this.index % 5 == 1: return 'bg-danger';
      case this.index % 5 == 2: return 'bg-info';
      case this.index % 5 == 3: return 'bg-success';
      case this.index % 5 == 4: return 'bg-primary';
      case this.index % 5 == 0: return 'bg-warning';
    }
    return 'bg-info';
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  setIndex(index) {
    this.index = index;
  }

}
