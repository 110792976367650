<div class="btn-group dropdown" ngbDropdown
     [placement]="'bottom-start'"
>

    <button
        class="btn btn-sm btn-addon btn-default collapse-xs "
        (click)="openDialog()"
    >

        <i>
            <span class="fa"
                  [ngClass]="{ 'fa-filter': !isPending, 'fa-spin fa-spinner': isPending , 'text-danger': hasSelectedGoods  }"></span>
        </i>

        <span class="btn-name" *ngIf="!hasSelectedGoods">
                    Товары не выбраны
        </span>

        <span class="btn-name" *ngIf="hasSelectedGoods">

             <span [ngSwitch]="type">
                 <span *ngSwitchCase="'sku'">
                     Выбрано товаров
                 </span>
                 <span *ngSwitchCase="'category'">
                     Выбрано категорий
                 </span>
                 <span *ngSwitchCase="'brand'">
                     Выбран бренд
                 </span>
                 <span *ngSwitchCase="'favourite'">
                     Выбраны любимые товары
                 </span>
                 <span *ngSwitchCase="'custom'">
                     Выбраны списки
                 </span>
                 <span *ngSwitchDefault>
                     <span>Выбраны все товары</span>
                 </span>
             </span>

             <span>
                 : {{ countSelected }}
             </span>

          </span>

    </button>

    <ul class="dropdown-menu scroll-y"
        ngbDropdownMenu
        *ngIf="hasSelectedGoods"
        style="z-index: 9999; max-height: 30em;"
        (click)="$event.stopPropagation()"
    >
        <li *ngFor="let item of list"
            class="padder  m-t-sm ">

            <div class="hbox">
                <div class="col"
                     [innerHTML]="getName(item)"></div>

                <div class="col" style="width: 30px;">
                    <button class="btn btn-danger btn-xs pull-right"
                            (click)="removeItem(item); $event.stopImmediatePropagation()">
                        <i class="fa fa-times"></i>
                    </button>
                </div>

            </div>

        </li>

    </ul>


    <button
        *ngIf="hasSelectedGoods"
        class="btn btn-default btn-sm "
        ngbDropdownToggle
    >
        <span class="caret"></span>
    </button>

    <button
        *ngIf="hasSelectedGoods"
        (click)="clear()"
        class="btn btn-danger btn-sm ">
        <span class="fa fa-times"></span>
    </button>

</div>
