import { FieldTypes } from "./fieldTypes";

export const defaultCapabilities = {
    'coupons.rule.couponKind'    : { [FieldTypes.FIELD_FILTER]: ['couponWithBarcode', 'couponRuleBarcode',  'virtualRuleBarcode'] },
    'coupons.rule.issuingType'   : { [FieldTypes.FIELD_FILTER]: ['pos','public'] },
    'channel.type'               : { [FieldTypes.FIELD_FILTER]: ['check'] },
    'client.preferChannelType'   : { [FieldTypes.FIELD_FILTER]: ['check','web'] },

    'crm.client' : { [FieldTypes.FIELD_QUERY]: {
        'create'  : true,
        'delete' : true,
        'update' : true,
    }},

    'partner'  : { [FieldTypes.FIELD_ENABLED]: true} ,
    'crm.accounts.list'   : { [FieldTypes.FIELD_ENABLED]: true} ,
    'crm.accounts.attach' : { [FieldTypes.FIELD_ENABLED]: true},
    'crm.accounts.mergeOnlyProfile' : { [FieldTypes.FIELD_ENABLED]: false} ,

    'crm.cards.attach' : { [FieldTypes.FIELD_ENABLED]: true },
    'crm.cards.pin' : { [FieldTypes.FIELD_ENABLED]: false },

    'model.card' : { [FieldTypes.FIELD_QUERY]: { update: true } },

    'campaign.mechanics'   : { [FieldTypes.FIELD_QUERY]: { update: true,  create: true  } },

    'coupons.coupons.list' : { [FieldTypes.FIELD_ENABLED]: true },
    'model.coupon' : { [FieldTypes.FIELD_QUERY]: {
        create: true,
        'delete': true
    } },

    'modules.dibsactionprofileplugin.edit' : { [FieldTypes.FIELD_ENABLED]: true },

    'modules.levelbychecksplugin.edit' : { [FieldTypes.FIELD_ENABLED]: false },

    'editions.editions' : {
      'create' : { [FieldTypes.FIELD_ENABLED]: true },
      'edit'   :  { [FieldTypes.FIELD_ENABLED]: true },
      'delete'   :  { [FieldTypes.FIELD_ENABLED]: true },
    },

    'editions.certificate' : {
      'create' : { [FieldTypes.FIELD_ENABLED]: true }
    },

 //  'global.clients' : { [FieldTypes.FIELD_ENABLED]: true},
 //  'global.accounts': { [FieldTypes.FIELD_ENABLED]: true}

};
