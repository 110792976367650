import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GoodGroupLinkCategoriesApiService} from "../../../../api/GoodGroupLinkCategoriesApi/services";

import {ParamsHandler} from "../ParamsHandler/Params.handler";

@Injectable()
export class GoodgroupLinkCategoriesService {

  constructor( private goodGroupLinkCategoriesApiService:GoodGroupLinkCategoriesApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    sort: {
      type: "string",
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    }
  });

  public getList$(params: GoodGroupLinkCategoriesApiService.QueryGoodGroupCategoriesParams){
    return this.goodGroupLinkCategoriesApiService.queryGoodGroupCategories(params)
  }


  public addList$(params: GoodGroupLinkCategoriesApiService.AddListParams){
    return this.goodGroupLinkCategoriesApiService.addList(params)
  }

  public deleteList$(params: GoodGroupLinkCategoriesApiService.DeleteListParams){
    return this.goodGroupLinkCategoriesApiService.deleteList(params)
  }

  public deleteAll$(id: number, partnerId: number){
    return this.goodGroupLinkCategoriesApiService.deleteAll({
      id: id,
      partnerId: partnerId
    })
  }

}
