import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {NewsletterService} from "../../../../../shared/service/api/Newsletter/Newsletter.service";
import {
  CampaignStatus, CampaignStatusNames, ChannelType, ChannelTypeNames,
  NewsletterStatus,
  NewsletterStatusNames, SourceTypeNames, NewsletterTaskStateNames, NewsletterTaskExternalStateNames
} from "../../../../../shared/service/api/Newsletter/Newsletter.variables";
import {NewsletterProtocol} from "../../../../../api/NewsletterApi/models/newsletter-protocol";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {NewsletterProtocolTask} from "../../../../../api/NewsletterApi/models/newsletter-protocol-task";
import {NewsletterTotalProtocol} from "../../../../../api/NewsletterApi/models/newsletter-total-protocol";
import {NewsletterChannel} from "../../../../../api/NewsletterApi/models/newsletter-channel";
import {NewsletterTaskExternalState} from "../../../../../api/NewsletterApi/models/newsletter-task-external-state";
import {DateUtils} from "../../../../../shared/class/utils/date.utils";
import {tap} from "rxjs/operators";
import {NewsletterUtilsClass} from "../../shared/class/newsletter-utils.class";

@Component({
  selector: 'gate-task-list',
  templateUrl: './gate-task-list.component.html',
  providers: [
    NewsletterService
  ]
})
export class GateTaskListComponent implements OnInit {

  public loader = new LoaderHandlerClass();

  public CampaignStatus = CampaignStatus;
  public CampaignStatusNames = CampaignStatusNames;
  public NewsletterStatus = NewsletterStatus;
  public NewsletterStatusNames = NewsletterStatusNames;
  public NewsletterTaskStateNames = NewsletterTaskStateNames;
  public NewsletterTaskExternalStateNames = NewsletterTaskExternalStateNames;
  public ChannelType = ChannelType;
  public ChannelTypeNames = ChannelTypeNames;
  public SourceTypeNames = SourceTypeNames;

  public newsletterList: Array<NewsletterProtocolTask> = [];
  public totalSettings: NewsletterTotalProtocol | false = false;

  public searchString;

  constructor(
    public  newsletterService: NewsletterService
  ) {
  }

  getStateIcon = NewsletterUtilsClass.getStateIcon;
  getStateColor = NewsletterUtilsClass.getStateColor;
  getClassStatusTransaction = NewsletterUtilsClass.getClassStatusTransaction;


  ngOnInit() {

    let today = new Date();
    let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

    this.newsletterService.taskQueryParams.setFilterValue('fromDate', DateUtils.dateToText(yesterday) );
    this.newsletterService.taskQueryParams.setFilterValue('tillDate', DateUtils.dateToText(today) );
    this.newsletterService.taskQueryParams.setFilterValue('channels', Object.keys(ChannelTypeNames) );
    this.newsletterService.taskQueryParams.setFilterValue('state',  Object.keys(NewsletterTaskExternalStateNames)  );

    this.newsletterService.taskQueryParams.setSort('date', 'desc');

    this.reset();
  }

  getList(params, noTotal?) {

    if (!params?.filter?.fromDate || !params?.filter?.tillDate) {
      this.totalSettings = false;
      return;
    }

    if ( params?.pager?.drop === 0  && !noTotal) {
      this.getTotal( params );
    }

    this.newsletterService.taskQuery$(params)
      .pipe(
        this.loader.waitLoading('newsletterList'),
        this.newsletterService.taskQueryParams.pagerPipe()
      )
      .subscribe( ( result: any ) => {
        this.newsletterList = this.newsletterList.concat(result);
      });
  }

  onScroll() {

    if (this.loader.isLoading('newsletterList') || this.newsletterService.taskQueryParams.isPagerEnded )
      return;

    this.newsletterService.taskQueryParams.next();
    this.getList(this.newsletterService.taskQueryParams.params)
  }

  reset(noTotal?) {
    this.newsletterService.taskQueryParams.reset();
    this.newsletterList=[];
    this.getList(this.newsletterService.taskQueryParams.params, noTotal);
  }

  getTotal(params) {

    params = JSON.parse( JSON.stringify( params ) );
    delete params.pager;
    Object.keys(params.filter).forEach( key => {
      if ( ['channels', 'states', 'fromDate', 'tillDate', 'triggerIds', 'campaignIds', 'nameQuery' ].indexOf(key) <0 ) {
        delete params.filter[key];
      }
    })

    this.newsletterService.taskTotal$(params)
      .subscribe( result => {
        this.totalSettings = result;
      }, error => {
        this.totalSettings = false;
      });

  }

  onSearchChange(value) {
    this.searchString = value;
    this.newsletterService.taskQueryParams.search(this.searchString);
    this.reset();
  }

  getStateNames(detailItem) {
    return NewsletterTaskExternalStateNames[detailItem.externalState] || detailItem.externalState;
  }

}
