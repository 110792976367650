import {IssuingType, StartPeriodTypes, StopPeriodTypes, PosId} from "./IssuingType";
import {ECoupon} from "../couponKinds/eCoupon";
import {CouponWithBarcode} from "../couponKinds/CouponWithBarcode";
import {CouponWithoutBarcode} from "../couponKinds/CouponWithoutBarcode";
import {VirtualRuleBarcode} from "../couponKinds/VirtualRuleBarcode";
import {ImportedCouponForPrint} from "../couponKinds/ImportedCouponForPrint";
import {Sticker} from "../couponKinds/Sticker";
import {ReferralCouponWithBarcode} from "../couponKinds/ReferralCouponWithBarcode";



export class Pos extends IssuingType{

    static id = PosId;
    static couponKindsArray  = [
        ECoupon,
        CouponWithBarcode,
        CouponWithoutBarcode,
        VirtualRuleBarcode,
        ImportedCouponForPrint,
        Sticker,
        ReferralCouponWithBarcode
    ];

    public startPeriodArray = [
        StartPeriodTypes.START_PERIOD_FORM_ISSUE,
        StartPeriodTypes.START_PERIOD_FORM_DATE,
        StartPeriodTypes.START_PERIOD_SCHEDULED
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE,
        StopPeriodTypes.STOP_PERIOD_SCHEDULED,

    ];

}
