'use strict';

import 'angular-validation-match';
import 'directive/ngModelString/main';
import {isNumeric} from "rxjs/internal-compatibility";

var savedStates = {};

export default ['$scope', '$state', '$transitions', 'mechanicItem', 'mechanicsCategoryItem', 'campaignItem', 'toaster',
        function ($scope, $state, $transitions, mechanicItem, mechanicsCategoryItem, campaignItem, toaster ) {

            $scope.mechanicItem          = mechanicItem;
            $scope.mechanicsCategoryItem = mechanicsCategoryItem;
            $scope.campaignItem          = campaignItem;


                var saveCurrentState = function() {
                    try {
                        savedStates[$scope.mechanicItem.id + "_" + $scope.campaignItem.campaign.id ] = Object.assign({}, $scope.mechanicItem.extension);
                    } catch(e) {
                        clearCurrentState();
                    }
                };

                var restoreCurrentState = function() {

                    if ( angular.isObject(savedStates) && angular.isObject(savedStates[$scope.mechanicItem.id + "_" + $scope.campaignItem.campaign.id ])) {
                        $scope.mechanicItem.extension = savedStates[$scope.mechanicItem.id + "_" + $scope.campaignItem.campaign.id ];
                        clearCurrentState();
                    }

                };

                var clearCurrentState = function() {

                    if ( angular.isObject(savedStates) && angular.isObject(savedStates[$scope.mechanicItem.id + "_" + $scope.campaignItem.campaign.id ])) {
                        delete savedStates[$scope.mechanicItem.id + "_" + $scope.campaignItem.campaign.id ];
                    }

                };


                var disableStateEvent = $transitions.onStart({}, function(trans) {

                    if (/\.master$/.test(trans.to().name)) {
                        saveCurrentState();
                    } else {
                        clearCurrentState();
                    }

                    if (angular.isFunction(disableStateEvent)) {
                        disableStateEvent();
                    }

                });


                restoreCurrentState();

                var createPattern = function(item) {

                    if (item.pattern) {

                        try{

                            if ( angular.isString( item.pattern ) ) {
                                var match = item.pattern.match(new RegExp('^/(.*?)/([gimy]*)$'));
                                return new RegExp(match[1], match[2]);
                            }

                            if ( angular.isObject( item.pattern ) && item.pattern.source ) {
                                return new RegExp( item.pattern.source, item.pattern.flags);
                            }

                        } catch(e) {
                            return '';
                        }

                    } else {
                        return '';
                    }

                };

            $scope.groupsFields = {};
            $scope.NO_GROUP_ID='!!NO_GROUP_ID!!'
            $scope.hasEditableFieldsOnActive = false;
            angular.forEach( mechanicItem.extension._metaFields, function( item, key ) {

                var keyPath = key.split('.');

                if ( keyPath.length > 2 && !$scope.$eval( 'mechanicItem.' + keyPath.slice(0, keyPath.length - 1).join('.'))) {
                    $scope.$eval( 'mechanicItem.' + keyPath.slice(0, keyPath.length - 1).join('.') + '= {}' )
                }

                $scope.groupsFields[item.groupName || $scope.NO_GROUP_ID] = $scope.groupsFields[item.groupName || $scope.NO_GROUP_ID] || {
                  name  : item.groupName || $scope.NO_GROUP_ID,
                  icon  : item.groupIcon,
                  position : isNumeric(item.groupPosition) ? item.groupPosition :
                             !item.groupName ? 999999: 99999,
                  items : []
                }

                $scope.groupsFields[item.groupName || $scope.NO_GROUP_ID].items.push(

                    Object.defineProperties({}, {
                        type: {
                            value: item.type,
                            enumerable: true
                        },

                        name: {
                            value: item.name,
                            enumerable: true
                        },

                        position: {
                            value: item.position,
                            enumerable: true
                        },

                        key: {
                            value: key,
                            enumerable: true
                        },

                        meta: {
                            value: item,
                            enumerable: true
                        },

                            pattern: {
                                value: createPattern(item),
                                enumerable: true
                            },

                        mechanic: {
                            value: $scope.mechanicItem,
                            enumerable: true
                        },

                        campaign: {
                            value: $scope.campaignItem,
                            enumerable: true
                        },

                        category: {
                            value: $scope.mechanicsCategoryItem,
                            enumerable: true
                        },

                        parent: {
                            value: keyPath.length > 1 ? $scope.$eval( 'mechanicItem.' + keyPath.slice(0, keyPath.length - 1).join('.')) : $scope.$eval( 'mechanicItem' ),
                            enumerable: false
                        },

                        $parentIndex: {
                            value: keyPath[keyPath.length - 1],
                            enumerable: false
                        },

                        value: {
                            get: function() {
                               return this.parent[ this.$parentIndex ];
                            },

                            set: function( val ) {
                                this.parent[ this.$parentIndex ] = val;
                            }
                        }
                    })

                );

                $scope.hasEditableFieldsOnActive = $scope.hasEditableFieldsOnActive || (!!item?.canActiveEdit && campaignItem.state !== 'archive')
            });

            $scope.getGroupsArray = function(){
              return Object.values($scope.groupsFields).sort( (a:any,b:any) =>
                ( isNumeric(a.position) ? a.position : 99999 ) - ( isNumeric(b.position) ? b.position : 99999 )
              )

            }

            $scope.readonly             = $scope.campaignItem.campaign.state === "draft" ? false : true;

            $scope.cancelMechanic = function() {

                $state.go( 'frontend.campaign.campaigns.view', {id: $scope.mechanicItem.campaignId} );

            };

            var addIfNotExist = function (value, array) {

                if (angular.isUndefined(array))
                    array = [];

                for (var i in array) {
                    if (angular.equals(array[i], value))
                        return false;
                }

                array.push(value);
                return array;
            };


            $scope.goodsObj={};

            $scope.saveMechanic = function() {

                if ( $scope.mechanicItem.id !== undefined ) {

                    $scope.mechanicItem.status = "campaign";

                    $scope.$broadcast('mechanic.save', $scope.mechanicItem);

                    $scope.mechanicItem.
                        $update(function(savedMechanic) {

                            if ( savedMechanic.campaignId !== undefined ) {
                                $state.go( 'frontend.campaign.campaigns.view', {id: savedMechanic.campaignId} );
                            }

                        });

                }

            };

            var callbacksFunctions = {};

            $scope.createCallBack = function (key) {

                if (angular.isUndefined(callbacksFunctions[key])) {

                    callbacksFunctions[key] = function (item) {

                        if ($scope.goodsObj[key] === undefined || $scope.goodsObj[key].type === undefined) {

                            var type = $scope.goodsObj[key].model.split('.');

                            $scope.goodsObj[key].type = type[type.length - 1];
                            $scope.goodsObj[key].result = {};
                            $scope.goodsObj.modelItem = $scope.$eval(type.slice(0, type.length - 1).join('.'));

                            return;
                        }

                        if (
                            $scope.goodsObj[key].type && !angular.equals(item, {})
                        ) {
                            switch ($scope.goodsObj[key].type) {

                                case 'whiteListType':
                                    $scope.selectWhiteGoods(item, $scope.goodsObj[key].model);
                                    break;

                                case 'blackListType':
                                    $scope.selectBlackGoods(item, $scope.goodsObj[key].model);
                                    break;

                                case 'giftList':
                                    $scope.selectGiftsList(item, $scope.goodsObj[key].model);
                                    break;

                            }

                        }


                    };
                }

                return callbacksFunctions[key];

            };


            $scope.$watch( 'goodsObj', function( newVal, oldVal) {
                angular.forEach( newVal, function( item, key ) {

                    if ( oldVal[key] === undefined  || oldVal[key].type === undefined ) {

                        var type = item.model.split('.');

                            item.type =  type[ type.length-1 ];
                            item.result = {};
                            item.modelItem =  $scope.$eval( type.slice(0, type.length - 1).join('.') );

                            return;
                    }
                });

            }, true);



            $scope.selectGiftsList = function( returnGifts, modelPath )  {

                modelPath = modelPath.split('.');
                var eventItem = $scope.$eval( modelPath.slice(0, modelPath.length - 1).join('.') );

                eventItem.giftList = eventItem.giftList || [];
                angular.forEach( returnGifts.list, function( item ) {

                    addIfNotExist({
                        sku: item.id,
                        "price": "0.00",
                        "planCount": 0,
                        "realCount": 0,
                        "total": 0

                    }, eventItem.giftList);

                });
            };


            $scope.selectWhiteGoods = function ( returnGoods, modelPath ) {

                if (angular.isArray(returnGoods.list) && returnGoods.list.length === 0) {
                    return;
                }

                modelPath = modelPath.split('.');
                var eventItem = $scope.$eval( modelPath.slice(0, modelPath.length - 2).join('.') );

                eventItem.goodList = eventItem.goodList || {};
                if ( returnGoods.type === undefined) {
                    delete eventItem.goodList.whiteListType;
                    delete eventItem.goodList.whiteIdList;
                    delete eventItem.goodList.whiteSKUList;
                    return;
                }

                if ( eventItem.goodList.whiteListType !== returnGoods.type ) {
                     eventItem.goodList.whiteListType = returnGoods.type;

                    switch( eventItem.goodList.whiteListType ) {

                        case 'sku': eventItem.goodList.whiteSKUList = [];
                            delete eventItem.goodList.whiteIdList;
                            break;

                        default: eventItem.goodList.whiteIdList = [];
                            delete eventItem.goodList.whiteSKUList;
                            break;
                    }
                }


                angular.forEach( returnGoods.list, function( item ) {
                    switch( eventItem.goodList.whiteListType ) {

                        case 'sku':
                            addIfNotExist(item.id, eventItem.goodList.whiteSKUList);
                            break;

                        default:
                            addIfNotExist(item.id, eventItem.goodList.whiteIdList);
                            break;
                    }

                });

            };

            $scope.selectBlackGoods = function ( returnGoods, modelPath ) {
                modelPath = modelPath.split('.');
                var eventItem = $scope.$eval( modelPath.slice(0, modelPath.length - 2).join('.') );


                eventItem.goodList = eventItem.goodList || {};

                if ( returnGoods.type === undefined) {
                    delete eventItem.goodList.blackListType;
                    delete eventItem.goodList.blackIdList;
                    delete eventItem.goodList.blackSKUList;
                    return;
                }

                if ( eventItem.goodList.blackListType !== returnGoods.type ) {
                    eventItem.goodList.blackListType = returnGoods.type;

                    switch( eventItem.goodList.blackListType ) {

                        case 'sku': eventItem.goodList.blackSKUList = [];
                            delete eventItem.goodList.blackIdList;
                            break;

                        default: eventItem.goodList.blackIdList = [];
                            delete eventItem.goodList.blackSKUList;
                            break;
                    }


                }


                angular.forEach( returnGoods.list, function( item ) {
                    switch( eventItem.goodList.blackListType ) {

                        case 'sku':
                            addIfNotExist(item.id, eventItem.goodList.blackSKUList);
                            break;

                        default:
                            addIfNotExist(item.id, eventItem.goodList.blackIdList);
                            break;
                    }

                });

            };

            $scope.clearGoodList = function ( goodsList, type ) {
                switch( type ) {

                    case 'white':
                        delete goodsList.whiteListType;
                        delete goodsList.whiteIdList;
                        delete goodsList.whiteSKUList;
                        break;

                    case 'black':
                        delete goodsList.blackListType;
                        delete goodsList.blackIdList;
                        delete goodsList.blackSKUList;
                        break;

                    case 'gifts':
                        delete goodsList.giftList;
                        break;
                }

            };

            $scope.viewMechanic = function() {

                if ($scope.mechanicItem && !angular.isUndefined($scope.mechanicItem.id))
                    $state.go('^.master', { mechanicId: $scope.mechanicItem.id });

            };

        } ];

