import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  ReportSkuDiscountsService
} from "../../../../../shared/service/api/ReportSkuDiscounts/ReportSkuDiscounts.service";

@Component({
  selector: 'discount-by-company-report',
  templateUrl: './discount-by-company-report.component.html',
  providers:[
    ReportSkuDiscountsService
  ]
})
export class DiscountByCompanyReportComponent implements OnInit{

  public filterGoods;
  public filterLocations;
  public filterCampaigns;
  public filterCampaignsRule;
  public filterFrom;
  public filterTo;

  public partnerId;
  public reportData;

  private isLoading = false;


  constructor(
    private reportSkuDiscountsService:ReportSkuDiscountsService,
  ) {
  }

  ngOnInit() {

  }

  buildReport() {

    if (!this.checkFilters())
      return

    this.reportData = undefined;
    this.isLoading = true;

    this.reportSkuDiscountsService.build$(this.reportSkuDiscountsService.buildParams.params).subscribe({
      next: result => {
        this.isLoading = false;
        this.reportData = result;
      },
      error: () => {
        this.isLoading = false;
      }
    })

  }

  checkFilters() {
    if (
        !this.reportSkuDiscountsService.buildParams.getFilterValue('campaigns') &&
        !this.reportSkuDiscountsService.buildParams.getFilterValue('couponRules')
    ) {
      return false
    }

    if (
      !this.reportSkuDiscountsService.buildParams.getFilterValue('dateFrom') ||
      !this.reportSkuDiscountsService.buildParams.getFilterValue('dateTo')
    ) {
      return false
    }

    if (
   //   !this.reportSkuDiscountsService.buildParams.getFilterValue('locations') ||
      !this.reportSkuDiscountsService.buildParams.getFilterValue('sku')
    ) {
      return false
    }

    return true;

  }

  onChangeFilers() {

    this.reportSkuDiscountsService.buildParams.setFilterValue('campaigns',!!this.filterCampaigns  ? this.filterCampaigns : undefined);
    this.reportSkuDiscountsService.buildParams.setFilterValue('couponRules', !!this.filterCampaignsRule ? this.filterCampaignsRule : undefined);
    this.reportSkuDiscountsService.buildParams.setFilterValue('locations', !!this.filterLocations ? this.filterLocations : undefined);
    this.reportSkuDiscountsService.buildParams.setFilterValue('sku',!!this.filterGoods ? this.filterGoods?.list?.map( i => i.id) : undefined);
    this.reportSkuDiscountsService.buildParams.setFilterValue('dateFrom',!!this.filterFrom ? this.filterFrom : undefined);
    this.reportSkuDiscountsService.buildParams.setFilterValue('dateTo',!!this.filterTo ? this.filterTo : undefined);

    this.reportData = undefined;
  }

  hasTable() {
    return Array.isArray(this.reportData?.rows) && !!this.reportData?.rows?.length
  }

  isEmptyTable() {
    return  Array.isArray(this.reportData?.rows) && !this.reportData?.rows?.length;
  }

  isCampaign(item) {
    return !!item?.campaign;
  }

  isCouponRule(item) {
    return !!item?.couponRule;
  }

  isCouponRuleSelected() {
    return !!this.reportSkuDiscountsService?.buildParams?.getFilterValue('couponRules');
  }

  hasLocations(item) {
    return Array.isArray(item?.locations) && !!item?.locations?.length;
  }

  isFilterEnabled(value) {
    return !!this.reportSkuDiscountsService.buildParams.getFilterValue(value);
  }

  isReportPrepareStage() {
    return typeof this.reportData === "undefined" ||  this.isEmptyTable();
  }

}
