import  "./left-layout-container.component.scss"


class LeftLayoutContainerComponent {

    static $inject = ['$rootScope','$http', '$element'];

    public curDate : Date;
    public version : string;

    constructor(
        private $rootScope: any,
        private $http:any,
        private $element: ng.IRootElementService
    ) {
    }

    public $onInit() {

        this.curDate = new Date();

        this.getCurrentVersion();

        this.detectSmartDevices();

        this.setFixesLayout();

        this.fixScrollWidth();
    }

    public $onDestroy() {
    }

    public toggleHeaderSlot() {

        const headerSlot = this.$element[0].querySelector('.left-layout-container__header-slot');

        if ( headerSlot )
            headerSlot.classList.toggle('left-layout-container__header-slot_show');

    }

    public toggleMenuSide() {

        const headerSlot = this.$element[0].querySelector('.left-layout-container__menu-side');

        if ( headerSlot )
            headerSlot.classList.toggle('left-layout-container__menu-side_off-screen');

    }

    private getCurrentVersion() {

        this.$http.get( '/assets/frontend/js/version.json', {
            params: { '_' : this.curDate.getTime() },
            cache: false,
            responseType: 'json'
        }).then( (response: any) => response && response.data ? response.data : {}, () => {})
          .then( ( version: { version: "string", build: "string" } ) => {
              this.version = version.version + ( version.build ? ' ( Build ' + version.build + ' )' : '' );
          });

    }

    private detectSmartDevices() {

        // detect IExplorer
        if (!!navigator.userAgent.match(/MSIE/i)) {
            window.document.body.classList.add('ie');
        }

        // Detect mobile device. Adapted from http://www.detectmobilebrowsers.com
        const ua = window.navigator.userAgent || window.navigator.vendor || (<any>window).opera;
        if ((/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua)) {
            window.document.body.classList.add('smart');
        }

    }

    private setFixesLayout() {

        // FIX для перерисовки графиков
        this.$rootScope.$on('$viewContentLoaded', () =>{
            setTimeout(() => this.$rootScope.$broadcast('highchartsng.reflow'), 0);
        });


        // Для наследственности старых плагинов т.к. скролится flex елемент
        this.$element[0]
            .querySelector('.left-layout-container__container')
            .addEventListener('scroll' , () => window.dispatchEvent(new Event('scroll')) );


    }


    private fixScrollWidth() {

        const getSroolBarWidth = () => {
          var scrollDiv = document.createElement("div");
          scrollDiv.style.cssText = 'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;';
          document.body.appendChild(scrollDiv);

          var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
          document.body.removeChild(scrollDiv);
          return scrollbarWidth;
        }


        let menuSideInner = this.$element.find('.left-layout-container__menu-side-inner')[0];
        let delta = getSroolBarWidth();
        menuSideInner.style.marginRight = -delta + 'px';
        menuSideInner.style.paddingRight = ( delta - 1 ) + 'px';
        menuSideInner.style.width = ( menuSideInner.clientWidth + delta ) + 'px';

    }
}

export default window['angular'].module('loya.builder').component('leftLayoutContainer', {
    template    : `
        <div class="app app-aside-fixed left-layout-container" id="app">

            <div class="app-header left-layout-container__header-row" >
                <div class="left-layout-container__header-layout">

                    <button
                        ng-click="$ctrl.toggleHeaderSlot()"
                    >
                        <i class="fa fa-cog"></i>
                    </button>

                    <button
                        ng-click="$ctrl.toggleMenuSide()"
                    >
                        <i class="fa fa-align-justify"></i>
                    </button>

                    <a href="/#/"
                        class="navbar-brand"
                        ng-transclude="logoSlot"
                    >
                    </a>

                </div>

                <div class="left-layout-container__header-slot bg-white  navbar-buttons "
                     ng-transclude="headerSlot"
                 >
                </div>

            </div>

            <div id="app-container" class="app-container left-layout-container__container">

                <div id="app-aside" class="left-layout-container__menu-side  app-aside">
                    <div class="left-layout-container__menu-side-inner  noscroll"
                        ng-transclude="leftSlot">
                    </div>
                </div>

                <div class="left-layout-container__content-side app-rside ">

                    <div ui-butterbar></div>
                    <div class="app-content-body"
                         id="app-content-body" >

                        <div class="left-layout-container__content-inner app-content-body-inner "
                             id="app-content-body-inner"
                             ng-transclude="contentSlot"
                        ></div>

                        <div class="app-content-dummy left-layout-container__content-dummy"></div>

                    </div>


                    <div class="left-layout-container__footer app-footer">
                        <span class="pull-right">
                            {{ ::$ctrl.version }}
                            <!--a href ui-scroll="app" class="m-l-sm text-muted">
                                <i class="fa fa-long-arrow-up"></i>
                            </a-->
                        </span>
                        &copy; {{ ::$ctrl.$rootScope.app.date | date:'yyyy'}} Copyright.
                    </div>

                </div>

            </div>
        </div>
        <toaster-container></toaster-container>
`,
    controller  : LeftLayoutContainerComponent,
 /*   bindings    : InputComponentBindongs, */
    transclude  : {
        'leftSlot'       : '?leftBlock',
        'logoSlot'       : '?logoBlock',
        'headerSlot'     : '?headerBlock',
        'contentSlot'    : '?contentBlock'

    }

});
