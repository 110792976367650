import 'model/PartnerModel/resource'
import { BonusConfigHandler, BurnModes, StartModes, BonusPeriods } from './bonusConfigHandler';

export class BonusConfig {

    // Передаваемые параметры в компонент
    public model  : any;
    public active : any;
    public fieldPath : any;
    public initPartner : number;

    // Для доступа к полям модели
    private _t   : any;
    private handler: BonusConfigHandler;
    private preparedModel : any;

    public isRequired : boolean;
    public BurnModes  = BurnModes;
    public StartModes = StartModes;

    public monthList = {
        1: { id: 1, name: "Январь",   maxDays:31 },
        2: { id: 2, name: "Февраль",  maxDays: 28},
        3: { id: 3, name: "Март",     maxDays:31 },
        4: { id: 4, name: "Апрель",   maxDays:30 },
        5: { id: 5, name: "Май",      maxDays:31 },
        6: { id: 6, name: "Июнь",     maxDays:30 },
        7: { id: 7, name: "Июль",     maxDays:31 },
        8: { id: 8, name: "Август",   maxDays:31 },
        9: { id: 9, name: "Сентябрь", maxDays:30 },
        10: { id: 10, name: "Октябрь",  maxDays:31 },
        11: { id: 11, name: "Ноябрь",   maxDays:30 },
        12: { id: 12, name: "Декабрь",  maxDays:31 }
    };

    public options = {
        bonusBurnSaveEnabled : false,
        loadingSettings      : false
    }

    private watchers: any[] = [];

    static $inject = ['$scope','$element','PartnerModel' ];

    constructor( private $scope: any, private $element: any, private PartnerModel: any) { }

    public $onInit() {

        this._t = new this.PartnerModel();
        this.visibleWatcher();

        if ( typeof this.model !== "undefined" ) {

            this.preparedModel = BonusConfigHandler.preparePathInModel(this.model, this.fieldPath);
            this.init();
        }

    }

    public $onChanges( changes: any ) {

        if ( changes.model && typeof changes.model.currentValue !== "undefined" && typeof changes.model.currentValue !== "string" ) {
            this.init();
        }

        if ( changes.initPartner &&
            typeof changes.initPartner.currentValue !== "undefined" &&
            !changes.initPartner.isFirstChange()
           ) {

            this.initFromPartner( changes.initPartner.currentValue );
        }

    }

    private async init()  {

        this.handler = new BonusConfigHandler( this.model, this.fieldPath );

        this.handler.init();

        this.preparedModel = BonusConfigHandler.preparePathInModel(this.model, this.fieldPath);

        await this.initFromPartner();

// TODO Всё перевести на onChange

        if (
            this.preparedModel[this.handler.burn_save_date_field] &&
            typeof this.preparedModel[ this.handler.burn_save_date_field ][ this.handler.burn_save_date_month_field ] !== "undefined"
           ) {

            this.options.bonusBurnSaveEnabled = true;

        }

        this.watchers.forEach( (w: Function) => w() );
        this.watchers = [];

        this.watchers.push(
            this.$scope.$watch(`$ctrl.preparedModel.${this.handler.burn_field}`, ( newVal:any ) => {

                if ( typeof newVal  === "undefined" ) {

                    this.handler.init();
                    newVal = this.preparedModel[this.handler.burn_field];
                }

                switch (newVal) {
                    case BurnModes.UNLIMITED:
                        delete this.preparedModel[this.handler.period_field];
                        delete this.preparedModel[this.handler.validity_field];
                        delete this.preparedModel[this.handler.burn_day_field];
                        delete this.preparedModel[this.handler.burn_month_field];
                        break;

                    case BurnModes.DURATION:
                        delete this.preparedModel[this.handler.burn_day_field];
                        delete this.preparedModel[this.handler.burn_month_field];

                        if ( typeof this.preparedModel[this.handler.period_field] === "undefined" ) {
                            this.preparedModel[this.handler.period_field] = BonusPeriods.DAY;
                        }
                        break;

                    case BurnModes.ANNUALLY:
                        delete this.preparedModel[this.handler.period_field];
                        delete this.preparedModel[this.handler.validity_field];

                        if ( typeof this.preparedModel[this.handler.burn_month_field] === "undefined" ) {
                            this.preparedModel[this.handler.burn_month_field] = 1;
                        }
                        break;

                    case  BurnModes.MONTHDAY:
                        delete this.preparedModel[ this.handler.period_field ];
                        delete this.preparedModel[this.handler.validity_field];
                        delete this.preparedModel[this.handler.burn_month_field];

                        if ( ( typeof this.preparedModel[this.handler.burn_day_field] === "undefined" )
                            || this.preparedModel[this.handler.burn_day_field] > 31
                            || this.preparedModel[this.handler.burn_day_field] < 1) {

                        this.preparedModel[this.handler.burn_day_field] = 31;
                        }
                        break;
                }

            })
        );

        this.watchers.push(
            this.$scope.$watch(`$ctrl.preparedModel.${this.handler.start_field}`, ( newVal?: StartModes ) => {

                if ( typeof newVal === "undefined" ) {
                    return false;
                }

                if ( newVal === StartModes.ATONCE ) {
                    this.preparedModel[this.handler.start_value_field ] = 0;
                } else {
                    if (
                        typeof this.preparedModel[this.handler.start_value_field ] === "undefined" ||
                        this.preparedModel[this.handler.start_value_field ] === 0) {
                        this.preparedModel[this.handler.start_value_field ] = 1;
                    }
                }

            })
        );

        this.watchers.push(
            this.$scope.$watch('$ctrl.options.bonusBurnSaveEnabled', ( newVal: boolean ) => {

                if ( newVal ) {
                    this.preparedModel[ this.handler.burn_save_date_field ] = this.preparedModel[ this.handler.burn_save_date_field ] || {};
                    this.preparedModel[ this.handler.burn_save_date_field ][ this.handler.burn_save_date_day_field ]   = this.preparedModel[ this.handler.burn_save_date_field ][this.handler.burn_save_date_day_field]|| 1;
                    this.preparedModel[ this.handler.burn_save_date_field ][ this.handler.burn_save_date_month_field ] = this.preparedModel[ this.handler.burn_save_date_field ][this.handler.burn_save_date_month_field] || this.monthList['1'].id;
                } else {
                    if (this.preparedModel ) {
                        delete this.preparedModel[ this.handler.burn_save_date_field ];
                    }
                }

            })
        );

    }

    private initFromPartner( partnerId ?: number) : Promise<any> {

        if ( typeof partnerId === "undefined" ) {
            partnerId = this.initPartner;
        }

        if ( typeof partnerId === "undefined" )
            return Promise.resolve();

        this.options.loadingSettings = true;

        return this.PartnerModel.get({
            id : partnerId
        })
            .$promise
            .then( ( result : any ) => {
                this.options.loadingSettings = false;
                this.handler.restoreConfig(result.limits);
            })
            .catch( () => this.options.loadingSettings = false );
    }

    private visibleWatcher() {

        this.$scope.$watch( () => this.$element.is(':not(:hidden)'), (val : boolean) => this.isRequired = val );

    }

    public getNextYear( day: number, month: number ) {

        if ( typeof day === "undefined" || typeof month === "undefined" )
            return "";
        let curDate = new Date();
        let calcDate = new Date( curDate.getFullYear(),month -1, day,curDate.getHours(),curDate.getMinutes(),curDate.getSeconds());

        if ( curDate.getTime() > calcDate.getTime() + 5000 ) {
            calcDate.setFullYear( curDate.getFullYear() + 1 );
        }

        return  `${('0' + calcDate.getDate()).slice(-2)}.${('0' + (calcDate.getMonth()+1)).slice(-2)}.${calcDate.getFullYear()}`;

    }

};
