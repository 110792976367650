'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.crm.accounts.transaction';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id?from&to')

                .template(   `
                  <account-transaction-index-page ng-if="!isByUser"
                     [filter-from]="from"
                    [filter-to]="to"
                  ></account-transaction-index-page>
                  <account-transaction-by-user-page ng-if="isByUser"
                    [user-id]="id"
                    [filter-from]="from"
                    [filter-to]="to"
                  ></account-transaction-by-user-page>
                `, true)
                .controller(['$scope', '$stateParams', function($scope, $stateParams) {
                    $scope.isByUser = !!$stateParams.id;
                    $scope.id = $stateParams.id;
                    $scope.from = $stateParams.from;
                    $scope.to = $stateParams.to;
                } ])

                .breadcrumbs( [ "Клиенты", "Счета", "Отчет по операторам" ])

                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.TopManager],
                })
      }]);
