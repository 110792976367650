import "./app.nav.scss"

import { MENU_ARRAY } from "./menu";
import {IMenuItem} from "./typings";

class NavComponent {

    static $inject = ['$scope', '$location', '$transitions', '$state', 'loyaPermissions', 'menuBuilderArray'];

    public menu : Array<IMenuItem>

    private currentMenuItem : IMenuItem;
    private unBounds : Array<Function> = [];


    constructor(
       private $scope: any,
       private $location: any,  private $transitions: any, private $state: any,  private loyaPermissions: any, private menuBuilderArray: any
    ) {

    }

    public $onInit() {
        this.unBounds = [];

        this.initMenu();

        this.appPartnerPage();
        this.buildMenu();

        this.biuldTransactions();

        this.updateCurrentMenu( this.$state.current.name);
    }

    public $onDestroy() {
        this.unBounds.map( f => f() );
        delete this.unBounds;
    }

    private initMenu() {

        this.menu = [];
        MENU_ARRAY.forEach( ( i : IMenuItem ) => {

            let obj = Object.assign({}, i);

            if ( Array.isArray(obj.subMenu) ) {
                obj.subMenu = obj.subMenu.slice(0)
            }

            this.menu.push(obj);
        });

    }

    private buildMenu() {

        if (this.menuBuilderArray.getMenu().length) {
            this.menu = this.menu.concat( this.menuBuilderArray.getMenu());
        }

        let refMenu = this.menu.find( i => i.uiSref === 'frontend.referrer.index');
        if ( refMenu ) {
          this.menu = this.menu.filter( i => i !== refMenu);
          this.menu.splice(2, 0, refMenu);

        }

        this.menu.forEach( (menu: IMenuItem) => {

            let subMenu = this.menuBuilderArray.getSubmenu( menu.uiSref );
            if (!Array.isArray( subMenu ) || !subMenu.length)
                return;

            menu.subMenu = ( menu.subMenu || [] ) .concat(subMenu);

        });

    }

    private updateCurrentMenu = ( stateName: any )=> {

        let index = -1;
        let getIndex = (srefArray: any, stateArray: any) => {

            let i = 0;
            while( srefArray[i] && stateArray[i] &&
                ( srefArray[i] === stateArray[i] || ( srefArray[i] === 'index' && stateArray[i] === 'view' ) )
                ) {
                i++;
            }

            return i;
        };

        if ( this.$location.path() === '/crm/clients/view/worksheet') {
            this.currentMenuItem = this.menu[0];
            return;
        }

        stateName = stateName.split('.');
        this.menu.filter( (menu: any ) => menu.uiSref !== 'frontend.crm.clients.view({id:\'worksheet\'})' )
            .forEach( (menu: any) => {

                if ( Array.isArray(menu.subMenu) ) {
                    menu.subMenu.forEach( ( subMenu : any ) => {

                        let curIndex = getIndex(
                            subMenu.uiSref.split('.').map( ( item: any ) => item.replace(/\(.*\)/, '') ),
                            stateName);

                        if ( curIndex > index ) {
                            index = curIndex;
                            this.currentMenuItem = subMenu;
                        }

                    });
                } else {
                    let curIndex = getIndex(
                        menu.uiSref.split('.').map( ( item: any ) => item.replace(/\(.*\)/, '') ),
                        stateName);

                    if ( curIndex >= index ) {
                        index = curIndex;
                        this.currentMenuItem = menu;
                    }
                }

            });

        if (index <= 1) {
            this.currentMenuItem = undefined;
        }

    };

    private biuldTransactions() {
        this.unBounds.push(
            this.$transitions.onSuccess({}, ( trans : any ) => {
                this.updateCurrentMenu(trans.to().name);
            })
        );
    }

    public getCss = ( ngMenu: IMenuItem ) => {
        if ( Array.isArray( ngMenu.subMenu ) ) {
            return  ngMenu.subMenu.indexOf(this.currentMenuItem) >=0 ? 'active' : '';
        } else {
            return  ngMenu === this.currentMenuItem ? 'active' : '';
        }
    };

    public permissionShow = ( permissions: any ) => {

        if ( typeof permissions === "undefined" ) {
            return true;
        } else {
            return this.loyaPermissions.hasUserGrants(permissions);
        }

    };

    public getFirstSubMenu = ( menuItem: any  ) => {

        if ( !Array.isArray(menuItem.subMenu) || menuItem.subMenu.length === 0 ) {
            return menuItem.uiSref;
        }

        for (var i = 0; i < menuItem.subMenu.length; i++ ) {

            if ( this.permissionShow(menuItem.permissions) &&
                 this.permissionShow(menuItem.subMenu[i].permissions)
            ) {
              if ( menuItem.subMenu[i].permit && this.loyaPermissions.byPath(menuItem.subMenu[i].permit).disabled )
                 continue;

              return menuItem.subMenu[i].uiSref;
            }
        }

        return menuItem.uiSref;

    };

    private appPartnerPage() {

        let DIRECTORY_DUB_ARRAY = this.menu.reduce( (acc : Array<any>, i) => i.uiSref === "frontend.config.directory.users.index" ? i.subMenu : acc, []);
        let SETTINGS_SUB_ARRAY = this.menu.reduce( (acc : Array<any>, i) => i.uiSref === "frontend.system.settings.client.modules" ? i.subMenu : acc, []);

        if (this.loyaPermissions.byPath('partner').enabled  ) {
            if ( !DIRECTORY_DUB_ARRAY.some( (i: any) => i.uiSref ===  "frontend.config.directory.partners.index" ) )
                DIRECTORY_DUB_ARRAY.splice(1, 0 ,  {
                    name:   "Партнёры",
                    uiSref: "frontend.config.directory.partners.index"
                });
        } else {
            if ( !SETTINGS_SUB_ARRAY.some( (i: any) => i.uiSref ===  "frontend.config.directory.partners.index" ) )
                SETTINGS_SUB_ARRAY.splice(0, 0 ,  {
                    name:   "Общие параметры",
                    uiSref: "frontend.config.directory.partners.index"
                });
        }
    }

}

export default window['angular'].module('loya.builder').component('nav', {
    template    : `
        <div class="app-menu">
            <ul class="app-menu__list" >

                <li ng-repeat="menuItem in $ctrl.menu"
                    class=" {{  $ctrl.getCss( menuItem ) }}"
                    ng-if="::($ctrl.permissionShow(menuItem.permissions))"
                    loya-permit-if="{{ menuItem.permit }}"
                    permit-enabled=""
                >
                    <a ui-sref="{{::$ctrl.getFirstSubMenu(menuItem)}}"
                       class="auto first-level"
                       ng-class="::{ 'has-sub-items': menuItem.subMenu && menuItem.subMenu.length > 0 }"
                    >
                      <span ng-if="::menuItem.subMenu.length" class="pull-right text-muted">
                        <i class="fa fa-fw fa-angle-right text"></i>
                        <i class="fa fa-fw fa-angle-down text-active"></i>
                      </span>

                        <i ng-class="::menuItem.icon"></i>
                        <span ng-bind="::menuItem.name"></span>
                    </a>

                    <ul ng-if="::menuItem.subMenu.length" class="nav nav-sub dk">

                        <li ng-repeat="subMenuItem in ::menuItem.subMenu"
                            class="{{ $ctrl.getCss( subMenuItem  ) }}"
                            ng-if="::($ctrl.permissionShow(menuItem.permissions) && $ctrl.permissionShow(subMenuItem.permissions))"

                            loya-permit-if="{{ subMenuItem.permit }}" permit-enabled=""
                        >
                            <a ui-sref="{{ ::subMenuItem.uiSref }}" class="second-level">
                                <span ng-bind="::subMenuItem.name"></span>
                            </a>
                        </li>

                    </ul>

                </li>

            </ul>

        </div>
`,
    controller  : NavComponent,
 /*   bindings    : InputComponentBindongs,
    transclude  : InputComponentTransclude*/

});


/*
        Старый код для сохранности
        .directive('uiNav', ['$timeout', function () {
            return {
                restrict: 'AC',
                link: function (scope, el) {

                    var _window = $(window),
                        _mb = 768,
                        wrap = $('.app-aside'),
                        next,
                        backdrop = '.dropdown-backdrop';
                    // unfolded
                    el.on('click', 'a', function (e) {
                        if (next) {
                            next.trigger('mouseleave.nav');
                        }
                        var _this = $(this);
                        _this.parent().siblings(".active").toggleClass('active');
                        if (_this.next().is('ul')) {
                            if (_this.parent().toggleClass('active')) {
                                e.preventDefault();
                            }
                        }

                    });

                    // folded & fixed
                    el.on('mouseenter', 'a', function (e) {
                        if (next) {
                            next.trigger('mouseleave.nav');
                        }

                        $('> .nav', wrap).remove();
                        if (!$('.app-aside-fixed.app-aside-folded').length || ( _window.width() < _mb )) {
                            return;
                        }

                        var _this = $(e.target),
                            top ,
                            w_h = $(window).height() ,
                            offset = 50 ,
                            min = 150;

                        if (!_this.is('a')) {
                            (_this = _this.closest('a'));
                        }
                        if (_this.next().is('ul')) {
                            next = _this.next();
                        } else {
                            return;
                        }

                        _this.parent().addClass('active');
                        top = _this.parent().position().top + offset;
                        next.css('top', top);
                        if (top + next.height() > w_h) {
                            next.css('bottom', 0);
                        }
                        if (top + min > w_h) {
                            next.css('bottom', w_h - top - offset).css('top', 'auto');
                        }
                        next.appendTo(wrap);

                        next.on('mouseleave.nav', function () {
                            $(backdrop).remove();
                            next.appendTo(_this.parent());
                            next.off('mouseleave.nav').css('top', 'auto').css('bottom', 'auto');
                            _this.parent().removeClass('active');
                        });

                        if ($('.smart').length) {
                            $('<div class="dropdown-backdrop"/>').insertAfter('.app-aside').on('click', function (next) {
                                if (next) {
                                    next.trigger('mouseleave.nav');
                                }
                            });
                        }

                    });

                    wrap.on('mouseleave', function () {
                        if (next) {
                            next.trigger('mouseleave.nav');
                        }

                        $('> .nav', wrap).remove();
                    });
                }
            };
        }])

 */
