'use strict';

import {FieldTypes} from "../../../../../components/configModuleFields/ConfigModuleService";

export default ['$scope',
    function ($scope) {

        $scope.fieldTypes = FieldTypes;

        $scope.fieldType = () => $scope.selectedRuleId && $scope.selectedRuleId.fieldSettings && $scope.selectedRuleId.fieldSettings.dataType;
        $scope.fieldId   = () => $scope.selectedRuleId && $scope.selectedRuleId.fieldSettings && $scope.selectedRuleId.fieldSettings.id;

        $scope.$watch( _ => $scope.fieldId(), (newVal, prevVal) => {
            if (typeof newVal === "undefined") return;

            $scope.fieldSettings    = Object.assign( {}, $scope.selectedRuleId.fieldSettings, {
                required: true
            } ) ;
            $scope.fieldSettings.id = 'value';

            if ( newVal === FieldTypes.date ) {
                $scope.fieldSettings.format = 'YYYY-MM-DDTHH:mm:ssZ';
            }

        });

        if ($scope.fieldType() === FieldTypes.date) {
            $scope.$parent.model.filter.value = $scope.parseDate($scope.$parent.model.filter.value);
        }

        $scope.$parent.model.filter.fieldType = $scope.fieldType();
        $scope.$parent.model.filter.fieldId   = $scope.fieldId();

        $scope.compartionText = {
            [$scope.fieldTypes.text] : [
                { id:'equals', name:'Равно' },
                { id:'notEquals', name:'Не равно' },
                { id:'contain', name:'Содержит подстроку' },
                { id:'notContain', name:'Не содержит подстроку' },
                { id:'defined', name:'Задано' },
                { id:'undefined', name:'Не задано' },
            ],

            [$scope.fieldTypes.number] : [
                { id:'greaterThan', name:'Больше' },
                { id:'lessThan', name:'Меньше' },
                { id:'equals', name:'Равно' },
                { id:'greaterThanOrEqual', name:'Больше или равно' },
                { id:'lessThanOrEqual', name:'Меньше или равно' },
                { id:'defined', name:'Задано' },
                { id:'undefined', name:'Не задано' },
            ],

            [$scope.fieldTypes.date] : [
                { id:'greaterThan', name:'Больше' },
                { id:'lessThan', name:'Меньше' },
                { id:'equals', name:'Равно' },
                { id:'greaterThanOrEqual', name:'Больше или равно' },
                { id:'lessThanOrEqual', name:'Меньше или равно' },
                { id:'defined', name:'Задано' },
                { id:'undefined', name:'Не задано' },
            ],

            [$scope.fieldTypes.switch] : [
                { id:true,  name:'Выбрано' },
                { id:false, name:'Не выбрано' },
            ],

            [$scope.fieldTypes.select] : [
                { id:'contain'   , name:'Выбрано' },
                { id:'notContain', name:'Не выбрано' },
                { id:'defined', name:'Задано' },
                { id:'undefined', name:'Не задано' },
            ],

            [$scope.fieldTypes.multiselect] : [
                { id:'contain'   , name:'Выбрано' },
                { id:'notContain', name:'Не выбрано' },
                { id:'defined', name:'Задано' },
                { id:'undefined', name:'Не задано' },
            ]
        };

        $scope.compartionByType = ()  => !!$scope.compartionText[$scope.fieldType()] ? $scope.compartionText[$scope.fieldType()] : undefined;

        $scope.isValueHidden = compartion => ['defined', 'undefined'].indexOf(compartion) >= 0;

        $scope.$watch('model.filter.comparison', val => {

            if ( $scope.isValueHidden(val) ) {
                delete $scope.model.filter.value;
            }

        })

    }];
