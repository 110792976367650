'use strict';

import module from 'frontendModule';


import 'model/EditionModel/resource';
import {RoleType} from "module/permissions/conf/roles";
import {UserSettingsStorage} from "class/user-settings-storage.service";

let routePath = module.name + '.editions.editions.index';
module.requires = module.requires.concat([
    'infinite-scroll',
    'ui.footable'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/editions.rules.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/editions.rules.index" */ "./controllers"       ))

                .resolve('filterSettings', [function () {
                  return UserSettingsStorage
                    .get('editions.editions.index.filter')
                    .then( ( val : any ) => JSON.parse(val ) )
                    .catch(() => ({
                      'pager.limit':50,
                      'filter.status': 'active,draft,inProgress',
                      'sorting.field': 'id',
                      'sorting.order': 'asc'
                    }) );

                }]  )

                .permissions( {
                    only: [ RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter ],
                    permit: 'editions'
                })

                .breadcrumbs( [ "Подарочные сертификаты", "Тиражи" ]);

        }]);
