import {CARD_STATE_NAMES} from "../../../../../../app/shared/service/api/Card/Card.values";

export class ClientCards {

    // Передаваемые параметры в компонент
    public clientId: any;
    public cards: Array<any>;

    public CARD_STATE_NAMES = CARD_STATE_NAMES;

    static $inject = ['CardModel' ];

    constructor( private CardModel: any ) {

    }

    public $onChanges( changesObj : any) {

      if ( changesObj.clientId ) {
        if (typeof changesObj.clientId.currentValue !== "undefined")
          this.cards = this.CardModel.queryCards({
            'filter.clientId' : changesObj.clientId.currentValue
          });

      }

    }

}

