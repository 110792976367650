export const ENCODING_LIST = {
  "utf8"  : "UTF-8",
  "cp1251": "WIN1251",
}

export const DELIMITER_LIST = {
  "tab"  : "Табуляция",
  "comma"  : "Запятая",
  "semicolon"  : "Точка с запятой",
  "space"  : "Пробел",
  "colon"  : "Двоеточие",
  "other"  : "Другое",
}

export const DELIMITER_VALUES = {
  "tab"  : '\\t',
  "comma"  : ",",
  "semicolon"  : ";",
  "space"  : " ",
  "colon"  : ":",
}




export interface ImportCsvOptions {

  columns:[ {
    key: string,  // ID
    value: string, // Наименование
    required? :boolean
  }],

  limit?: number

}

