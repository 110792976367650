import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GoodGroupLinkBrandsApiService} from "../../../../api/GoodGroupLinkBrandsApi/services";

import {ParamsHandler} from "../ParamsHandler/Params.handler";

@Injectable()
export class GoodgroupLinkBrandsService {

  constructor( private goodGroupLinkBrandsApiService:GoodGroupLinkBrandsApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    sort: {
      type: "string",
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    }
  });

  public getList$(params: GoodGroupLinkBrandsApiService.QueryGoodGroupBrandsParams){
    return this.goodGroupLinkBrandsApiService.queryGoodGroupBrands(params)
  }


  public addList$(params: GoodGroupLinkBrandsApiService.AddListParams){
    return this.goodGroupLinkBrandsApiService.addList(params)
  }

  public deleteList$(params: GoodGroupLinkBrandsApiService.DeleteListParams){
    return this.goodGroupLinkBrandsApiService.deleteList(params)
  }

  public deleteAll$(id: number, partnerId: number){
    return this.goodGroupLinkBrandsApiService.deleteAll(id)
  }

}
