import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class MessageTemplateService {

  constructor( private httpClient:HttpClient ) {
  }

  public getList$(params: {
    'pager.drop'?:number,
    'pager.limit'?:number,
    status?:string,
    selectedId?:number
  }): Observable<any[]> {

    let selectedId;

    if (params.selectedId) {
      selectedId =params.selectedId;
      delete params.selectedId;
    }

    return this.httpClient.get('/msgtemplate', {
      responseType: "json",
      params: <any>params
    }).pipe(
      switchMap(
        result => {
          if (!!params['pager.drop'] || !selectedId)
            return of(result)

          return this.get$(selectedId)
                     .pipe(
                       map( i => {
                         return [i].concat(result)
                       })
                     )
        }
      ),
      map( i => Array.isArray(i) ? i : [])
    )
  }

  public get$(id): Observable<any> {

    return this.httpClient.get(`/msgtemplate/${id}`, {
      responseType: "json"
    })

  }


}
