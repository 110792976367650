'use strict';

import module from 'frontendModule';

import 'model/ExternalTaskModel/resource';
import 'model/UserModel/resource';

import * as  template     from  './views/index.html';
import controller from './controllers';

let routePath = module.name + '.system.settings.externalTask.view';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )
                .url( '/:id' )

                .template(template)
                .controller(controller)

                .breadcrumbs( ["Задачи"] )

                .resolve('taskModules', ['modulesStorage',(modulesStorage) =>  modulesStorage.get( 'system.settings.task' ) || {} ])
                .resolve('task', ['ExternalTaskModel', '$stateParams', '$rootScope', 'taskModules', function( ExternalTaskModel, $stateParams, $rootScope, taskModules ) {

                    if ( angular.isUndefined( $stateParams.id ) || $stateParams.id === ""  ) {

                        let pluginName;

                        if ( Object.keys(taskModules).length > 0 ) {

                            Object.keys(taskModules).forEach( ( item : any ) => {
                                if (!item.readOnly)
                                    pluginName = item;
                            });

                        }

                        var date = new Date();
                        return  new ExternalTaskModel(
                            {
                                name       : '',
                                pluginName : pluginName,
                                state      : 'draft'
                            }

                            /*{
                                userId : $rootScope.currentUser.id,
                                date   : date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
                                taskType : 'fullimport',
                                state    : 'draft'
                            }*/
                        );

                    }

                    return ExternalTaskModel.get( $stateParams ).$promise;

                }])

                .permissions( {
                    only: ['Administrator'],
                    permit: 'system.settings.externalTask',
                    redirectTo: 'frontend.nopermissions'
                })

        }]);

