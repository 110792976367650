/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { InformingTemplateApiConfiguration as __Configuration } from '../informing-template-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InformingTemplate } from '../models/informing-template';
import { OutInformingTemplate } from '../models/out-informing-template';
@Injectable({
  providedIn: 'root',
})
class InformingTemplateApiService extends __BaseService {
  static readonly queryInformingTemplatesPath = '/informing-template';
  static readonly createInformingTemplatePath = '/informing-template';
  static readonly getInformationTemplateByIdPath = '/informing-template/{id}';
  static readonly updateInformingTemplateByIdPath = '/informing-template/{id}';
  static readonly deleteInformingTemplateByIdPath = '/informing-template/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка шаблонов информирования
   * @param params The `InformingTemplateApiService.QueryInformingTemplatesParams` containing the following parameters:
   *
   * - `selectedId`: Код объекта, который надо вернуть первым
   *
   * - `search`: Строка поиска
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Список всех шаблонов информирования
   */
  queryInformingTemplatesResponse(params: InformingTemplateApiService.QueryInformingTemplatesParams): __Observable<__StrictHttpResponse<Array<InformingTemplate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.selectedId != null) __params = __params.set('selectedId', params.selectedId.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/informing-template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InformingTemplate>>;
      })
    );
  }
  /**
   * Запрос списка шаблонов информирования
   * @param params The `InformingTemplateApiService.QueryInformingTemplatesParams` containing the following parameters:
   *
   * - `selectedId`: Код объекта, который надо вернуть первым
   *
   * - `search`: Строка поиска
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Список всех шаблонов информирования
   */
  queryInformingTemplates(params: InformingTemplateApiService.QueryInformingTemplatesParams): __Observable<Array<InformingTemplate>> {
    return this.queryInformingTemplatesResponse(params).pipe(
      __map(_r => _r.body as Array<InformingTemplate>)
    );
  }

  /**
   * Создать шаблон информирования
   * @param template Параметеры шаблона информирования
   * @return Шаблон информирования создан
   */
  createInformingTemplateResponse(template: InformingTemplate): __Observable<__StrictHttpResponse<OutInformingTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = template;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/informing-template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OutInformingTemplate>;
      })
    );
  }
  /**
   * Создать шаблон информирования
   * @param template Параметеры шаблона информирования
   * @return Шаблон информирования создан
   */
  createInformingTemplate(template: InformingTemplate): __Observable<OutInformingTemplate> {
    return this.createInformingTemplateResponse(template).pipe(
      __map(_r => _r.body as OutInformingTemplate)
    );
  }

  /**
   * Найти шаблон информирования по ID
   * @param id ID шаблона
   * @return Шаблон информирования
   */
  getInformationTemplateByIdResponse(id: number): __Observable<__StrictHttpResponse<OutInformingTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/informing-template/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OutInformingTemplate>;
      })
    );
  }
  /**
   * Найти шаблон информирования по ID
   * @param id ID шаблона
   * @return Шаблон информирования
   */
  getInformationTemplateById(id: number): __Observable<OutInformingTemplate> {
    return this.getInformationTemplateByIdResponse(id).pipe(
      __map(_r => _r.body as OutInformingTemplate)
    );
  }

  /**
   * Изменить параметры шаблона информирования по ID
   * @param params The `InformingTemplateApiService.UpdateInformingTemplateByIdParams` containing the following parameters:
   *
   * - `template`: Параметры шаблона информирования
   *
   * - `id`: ID шаблона
   *
   * @return Шаблон информирования
   */
  updateInformingTemplateByIdResponse(params: InformingTemplateApiService.UpdateInformingTemplateByIdParams): __Observable<__StrictHttpResponse<OutInformingTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.template;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/informing-template/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OutInformingTemplate>;
      })
    );
  }
  /**
   * Изменить параметры шаблона информирования по ID
   * @param params The `InformingTemplateApiService.UpdateInformingTemplateByIdParams` containing the following parameters:
   *
   * - `template`: Параметры шаблона информирования
   *
   * - `id`: ID шаблона
   *
   * @return Шаблон информирования
   */
  updateInformingTemplateById(params: InformingTemplateApiService.UpdateInformingTemplateByIdParams): __Observable<OutInformingTemplate> {
    return this.updateInformingTemplateByIdResponse(params).pipe(
      __map(_r => _r.body as OutInformingTemplate)
    );
  }

  /**
   * Удалить шаблон информирования по ID
   * @param id ID шаблона
   */
  deleteInformingTemplateByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/informing-template/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить шаблон информирования по ID
   * @param id ID шаблона
   */
  deleteInformingTemplateById(id: number): __Observable<null> {
    return this.deleteInformingTemplateByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module InformingTemplateApiService {

  /**
   * Parameters for queryInformingTemplates
   */
  export interface QueryInformingTemplatesParams {

    /**
     * Код объекта, который надо вернуть первым
     */
    selectedId?: number;

    /**
     * Строка поиска
     */
    search?: string;

    /**
     * Сколько всего выбрать записей
     */
    limit?: number;

    /**
     * Сколько пропустить записей с начала выборки
     */
    drop?: number;
  }

  /**
   * Parameters for updateInformingTemplateById
   */
  export interface UpdateInformingTemplateByIdParams {

    /**
     * Параметры шаблона информирования
     */
    template: InformingTemplate;

    /**
     * ID шаблона
     */
    id: number;
  }
}

export { InformingTemplateApiService }
