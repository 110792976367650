import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ng1-alert',
  template: `
    <ngb-alert [type]="type" (closed)="closeWrapper()">
      <ng-content></ng-content>
    </ngb-alert>
  `,

})
export class Ng1AlertComponent implements OnInit {
  @Input() type: string;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.type  = this.type || 'warning';
  }

  closeWrapper() {
    this.close.emit()
  }

}
