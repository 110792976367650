/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CouponRuleApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CouponRuleApiConfiguration {
  rootUrl: string = location.origin;
}

export interface CouponRuleApiConfigurationInterface {
  rootUrl?: string;
}
