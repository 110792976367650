import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ng1-progressbar',
  template: `
    <ngb-progressbar
      [max]="max"
      [value]="value"
      [type]="type"
      [ngClass]="class"
    >
      <ng-content></ng-content>
    </ngb-progressbar>
  `,

})
export class Ng1ProgressbarComponent implements OnInit {
  @Input() max: number;
  @Input() value: number;
  @Input() type: string;
  @Input() class: string = "";

  ngOnInit() {
    this.type  = this.type || 'success';
    if (typeof this.max === "string") {
      this.max = parseFloat(this.max);
    }
/*

    if (typeof this.value === "string") {
      this.value = parseFloat(this.value);
    }
*/

  }

}
