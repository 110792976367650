/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FavoriteGoodsMechanicApiConfiguration, FavoriteGoodsMechanicApiConfigurationInterface } from './favorite-goods-mechanic-api-configuration';

import { FavoriteGoodsMechanicApiService } from './services/favorite-goods-mechanic-api.service';

/**
 * Provider for all FavoriteGoodsMechanicApi services, plus FavoriteGoodsMechanicApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FavoriteGoodsMechanicApiConfiguration,
    FavoriteGoodsMechanicApiService
  ],
})
export class FavoriteGoodsMechanicApiModule {
  static forRoot(customParams: FavoriteGoodsMechanicApiConfigurationInterface): ModuleWithProviders<FavoriteGoodsMechanicApiModule> {
    return {
      ngModule: FavoriteGoodsMechanicApiModule,
      providers: [
        {
          provide: FavoriteGoodsMechanicApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
