import {ChangeDetectorRef, Component, Input, OnInit, Output} from "@angular/core";
import {EventEmitter} from "@angular/core";
import {ReferrerService} from "../../../../shared/service/api/Referrer/Referrer.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'referrer-graph-account',
  templateUrl: './referrer-graph-account.component.html',
  providers: [
    ReferrerService
  ]
})
export class ReferrerGraphAccountComponent implements OnInit{

    // Передаваемые параметры в компонент
    @Input()   public id       : any;

    Highcharts: typeof Highcharts = Highcharts;
    config = {
    options: {
      chart: {
        type: 'spline',
        height: 240,
      },

      colors: ['#23b7e5', '#ff6666'],

      plotOptions: {
        spline: {
          fillOpacity: 0.5,
          lineWidth: 5,

          marker: {
            enabled: true,
            fillColor: '#ffffff',
            lineColor: null,
            lineWidth: 2,
            radius: 6
          }

        },

        series: {
          animation: {
            duration: 1000
          }
        },

      },

      legend: {

        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 5,
        floating: true,
        itemMarginBottom: 10,
        backgroundColor: 'rgba(255,255,255,0.7)',
        itemStyle: {
          color: '#ccc',
          fontWeight: 'normal'
        }
      },

    },

    title: {
      text: ''
    },

    xAxis: {
      gridLineColor: '#f4f4f4',
      gridLineWidth: 1,
      tickWidth: 0,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels:
        {
          style: {
            color: '#ccc'
          }
        }
      ,
      categories: []
    }
    ,

    yAxis: [{
      gridLineColor: '#f4f4f4',
      gridLineWidth:
        1,
      tickWidth:
        0,
      lineWidth:
        0,
      tickmarkPlacement:
        'on',
      labels:
        {
          style: {
            color: '#7cb5ec',
          }
        }
      ,
      title: {
        text: 'Количество чеков'
      }

    },
      {
        opposite: true,
        gridLineColor: '#f4f4f4',
        gridLineWidth:
          1,
        tickWidth:
          0,
        lineWidth:
          0,
        tickmarkPlacement:
          'on',
        labels:
          {
            style: {
              color: 'red',
            }
          }
        ,
        title: {
          text: 'Сумма покупок'
        }

      }],

    credits: {
      enabled: false
    },

    series: [],

  };

    public inited = false
    constructor(
      public referrerService      : ReferrerService,
    ) {
    }

    public ngOnInit() {

      this.getData();

    }

    getData() {
      this.referrerService.getGraphValues$({
        referrerId: this.id,
        since: "2021-02-08",
      }).subscribe( values => {
        this.addToGraph(values);
      })
    }

    addToGraph(values = []) {

      let serieCount = {
        yAxis: 0,
        type: 'spline',
        pointPlacement: 'on',
        marker: { symbol: 'circle' },
        name: "Количество чеков",
        data: []
      }

      let checkTotal = {
        yAxis: 1,
        type: 'spline',
        pointPlacement: 'on',
        color: 'red',
        marker: { symbol: 'circle' },
        name: "Сумма покупок",
        data: []
      }

      values.forEach( i => {
        this.config.xAxis.categories.push(i.date);
        serieCount.data.push(i.checkCount);
        checkTotal.data.push(i.checkTotal)
      });

      this.config.series = [serieCount, checkTotal];

      this.inited = true;
    }

}
