import {getNg1Injector} from "class/MigrationUtils";
import 'model/ClientTemplateModel/resource'

let singletonClientTemplatesProvider : ClientTemplatesProvider;

export class ClientTemplatesProvider {

    private _templatesList : Array<any>;
    private ClientTemplateModel : any;

    private loadedPromise : Promise<any> | boolean;

    get templateList() : Promise<any> {
        return this.loadedPromise === false ?
                    Promise.resolve( this._templatesList.slice().map(i => Object.assign({}, i)) ) :
                    <Promise<any>>this.loadedPromise;
    }

    static getInstance() {

        if (singletonClientTemplatesProvider) {
            return singletonClientTemplatesProvider;
        }

        singletonClientTemplatesProvider = new ClientTemplatesProvider();
        return singletonClientTemplatesProvider;

    }

    constructor() {

        this.ClientTemplateModel = getNg1Injector().get('ClientTemplateModel');

        this.loadedPromise = new Promise<any>( async (resolve, reject) => {
            try {
                await this.getTemplates();
                resolve( this._templatesList.slice().map(i => Object.assign({}, i)) );
                this.loadedPromise = false;
            } catch (e) {
                this._templatesList = undefined;
                reject();
            }
        });

    }

    private async getTemplates() {

        await this.ClientTemplateModel
            .query()
                .$promise
                .then( ( result : Array<any> ) => {
                    this._templatesList = result;
                });
    }

}
