import { Directive, ElementRef, Injector, SimpleChanges, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'select-goods-ng2'
})
export class GoodsPopupDirective extends UpgradeComponent {

  @Input() buttonClass: any;
  @Input() buttonName: any;

  @Input() selectGoodsPopup: any;
  @Input() forPartner: any;
  @Input() checkOne: any;
  @Input() linkOne: any;
  @Input() showCategories: any;
  @Input() initSelectedItems: any;
  @Input() initPartner: any;
  @Input() hideByType: any;
  @Input() filterByParams: any;
  @Input() max: any;
  @Input() showArchive: any;


  //: '=selectGoodsPopup',
  /*forPartner: '=forPartner',
  popupTrigger: '@',
  checkOne: '=',
  linkOne: '=',
  showCategories: '=showCategories',
  initSelectedItems: '=initSelectedItems',
  initPartner: '=initPartner',
  hideByType: '=',
  filterByParams: '=',
  max: '=',
  showArchive: '='
  */
  constructor(elementRef: ElementRef, injector: Injector) {
    super('goodsPopupButton', elementRef, injector);
  }
}

