/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for AccountApi services
 */
@Injectable({
  providedIn: 'root',
})
export class AccountApiConfiguration {
  rootUrl: string = location.origin;
}

export interface AccountApiConfigurationInterface {
  rootUrl?: string;
}
