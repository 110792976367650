const DIRECTIVE_NAME = 'gridItemForm';

class GridItemForm {

    static $inject = ['$element', '$attrs', '$interpolate', '$scope'];


    constructor( $element: ng.IRootElementService, $attrs: ng.IAttributes , $interpolate: ng.IInterpolateService, $scope: ng.IScope) {

        let label :string;
        try {
            label = $interpolate($attrs[`${DIRECTIVE_NAME}`])($scope);
        } catch(e) {
            label = $attrs[`${DIRECTIVE_NAME}`];
        }

        const labelElement = this.getLabelElement(label , $attrs[`${DIRECTIVE_NAME}Icon`]);
        if (!labelElement)
            return;

        let newRoot = this.decorateValueElement($element[0]);
        newRoot.insertBefore(labelElement, $element[0]);
        newRoot.classList.add('grid-item__form');
        newRoot.classList.add('form-item');
    }

    private getIconElement(icon: string) {
        if ( !icon ) {
            return;
        }

        let el = document.createElement('i');
        el.classList.add(icon.trim());
        el.classList.add('fa'); // TODO: сделать проверку для разных библиотек иконок
        return el;
    }

    private getLabelElement(label = "", icon: string) {

        let el_icon = this.getIconElement(icon);
        if ( !label && !icon) {
            return;
        }

        let el = document.createElement('div');
        el.classList.add('grid-item__label');
        el.classList.add('form-label');
        el.innerHTML = label;
        !!icon && el.appendChild( el_icon );
        return el;
    }

    private decorateValueElement(root: HTMLElement) {
   /*     let rootClone = <HTMLElement>root.cloneNode(false);
        Array.from(rootClone.attributes)
            .forEach( ( attr  ) => attr.name.indexOf('class') !== 0 && rootClone.removeAttribute(attr.name) );
        root.parentElement.insertBefore(rootClone, root);
        rootClone.appendChild(root);

        Array.from(root.attributes)
            .forEach( ( attr  ) => attr.name.indexOf('grid-item') === 0 && root.removeAttribute(attr.name) );

        root.className = "item_value";
        return rootClone;
*/
/*
        const valueElement = document.createElement('div');
        valueElement.classList.add('grid-item__value');

        root.appendChild( valueElement );
        for ( let i = root.childNodes.length - 1; i>=0; i-- ) {
            if (root.childNodes[i] === valueElement) continue;

            valueElement.chil appendChild( root.childNodes[i] );
        }
        return root;

 */

        const newRoot = document.createElement('div');
        newRoot.className = root.className;
        newRoot.classList.add('grid-item');
        root.parentNode.insertBefore(newRoot, root);
        root.previousElementSibling.appendChild(root);
     /*   root.className.split(' ').forEach( className => {
            if ( className.indexOf('grid-item') >= 0 ) {
                root.classList.remove(className);
            }
        });*/
        root.className = '';
        root.classList.add('grid-item__value');
        root.classList.add('form-value');
        if ( newRoot.classList.contains('ng-binding') ) {
            root.classList.add('ng-binding');
            newRoot.classList.remove('ng-binding')
        }

        return newRoot;
    }
}


export default window['angular'].module('loya.builder').directive(DIRECTIVE_NAME, () => ({

    restrict: 'EA',
    controller  : GridItemForm,

}));

// legacy для тестов
window['angular'].module('loya.builder').directive('gridItem', () => ({

    restrict: 'AEC',
    controller : ['$element', function($element: ng.IRootElementService){
        if ($element.hasClass('grid-item__form')) {
            $element.addClass('form-item');
        }
        $element.find('.grid-item__form').addClass('form-item');
        $element.find('.grid-item__label').addClass('form-label');
        $element.find('.grid-item__value').addClass('form-value');
    }]

}));

