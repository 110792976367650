/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BehaviorApiConfiguration, BehaviorApiConfigurationInterface } from './behavior-api-configuration';

import { BehaviorApiService } from './services/behavior-api.service';

/**
 * Provider for all BehaviorApi services, plus BehaviorApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    BehaviorApiConfiguration,
    BehaviorApiService
  ],
})
export class BehaviorApiModule {
  static forRoot(customParams: BehaviorApiConfigurationInterface): ModuleWithProviders<BehaviorApiModule> {
    return {
      ngModule: BehaviorApiModule,
      providers: [
        {
          provide: BehaviorApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
