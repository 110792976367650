import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GoodApiService} from "../../../../api/GoodApi/services";

import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {Good} from "../../../../api/GoodApi/models/good";

@Injectable({
  providedIn: "root"
})
export class GoodModelService {

  constructor( private goodApiService:GoodApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    sort: {
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    }
  });

  get$(sku, partnerId) {
    return this.goodApiService.get({
      partnerId,
      sku
    })
  }

  update$(sku: string | Good, partnerId?: number, good?: Good) {

    return this.goodApiService.update({
      sku: typeof sku === "object" ? sku.sku : sku,
      partnerId: typeof sku === "object" ? sku.partnerId : partnerId,
      good: typeof sku === "object" ? sku : good,
    })

  }

}
