import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AccountApiService} from "../../../../api/AccountApi/services";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";


@Injectable({
  providedIn: "root"
})
export class AccountService {

  constructor(
    private accountApiService:AccountApiService,
    private httpClient:HttpClient
  ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "drop",
      limitField : "limit",
      limit: 200
    },
    search: {
      field: "sentence"
    },
    sort: {
      type: "string",
      sortField  : 'sort',
      orderField : 'sortOrder'
    }
  });

  public getParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    sort: {
      type: "string",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    }
  });

  public getList$( params :any ) {

    if (params.sort) {
      params.sort =  ( params?.sortOrder === 'desc' ? '-' : '') + params.sort;
      delete params.sortOrder;
    }

    return this.httpClient.get('/account', {
      params: params,
      responseType: "json"
    });
  }

  public get$(id: number, params? :any ) {
    return this.httpClient.get('/account/' + id, {
      params: params,
      responseType: "json"
    });
  }

  public create$( body :any ) {
    return this.httpClient.put('/account', body, {
      responseType: "json"
    });
  }

  public update$( id: number, body :any ) {
    return this.httpClient.post('/account/'+id, body, {
      responseType: "json"
    });
  }

  public delete$( id: number ) {
    return this.httpClient.delete('/account/' + id, {
      responseType: "json",
    });
  }

  public bonusSettings$(body:any) {
    return this.httpClient.post('/account/bonusSettings', body, {
      responseType: "json",
    });
  }

  public search$( params: any ) {
    return this.httpClient.get('/account/search', {
      responseType: "json",
      params
    });
  }

  public union$(accountId, body) {

    return this.httpClient.put(`/account/${accountId}/union`, body, {
      responseType: "json"
    });

  }

}
