/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CampaignCommonApiConfiguration as __Configuration } from '../campaign-common-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CampaignResourceInfo } from '../models/campaign-resource-info';
import { CampaignDetailsResult } from '../models/campaign-details-result';
import { CampaignQueryByDateResult } from '../models/campaign-query-by-date-result';
import { CampaignCommonState } from '../models/campaign-common-state';
@Injectable({
  providedIn: 'root',
})
class CampaignCommonApiService extends __BaseService {
  static readonly campaignResourcesInfoPath = '/campaignresources/{id}';
  static readonly postCampaignResourcesInfoPath = '/campaignresources/{id}';
  static readonly campaignDetailsPath = '/campaigndetails/{id}';
  static readonly queryByDatePath = '/campaignbydate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос ресурсов кампании по ID
   * @param id undefined
   * @return Данные о ресурсах кампании
   */
  campaignResourcesInfoResponse(id: number): __Observable<__StrictHttpResponse<Array<CampaignResourceInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaignresources/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignResourceInfo>>;
      })
    );
  }
  /**
   * Запрос ресурсов кампании по ID
   * @param id undefined
   * @return Данные о ресурсах кампании
   */
  campaignResourcesInfo(id: number): __Observable<Array<CampaignResourceInfo>> {
    return this.campaignResourcesInfoResponse(id).pipe(
      __map(_r => _r.body as Array<CampaignResourceInfo>)
    );
  }

  /**
   * Сохранение ресурсов кампании
   * @param params The `CampaignCommonApiService.PostCampaignResourcesInfoParams` containing the following parameters:
   *
   * - `resources`:
   *
   * - `id`:
   */
  postCampaignResourcesInfoResponse(params: CampaignCommonApiService.PostCampaignResourcesInfoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.resources;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaignresources/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Сохранение ресурсов кампании
   * @param params The `CampaignCommonApiService.PostCampaignResourcesInfoParams` containing the following parameters:
   *
   * - `resources`:
   *
   * - `id`:
   */
  postCampaignResourcesInfo(params: CampaignCommonApiService.PostCampaignResourcesInfoParams): __Observable<null> {
    return this.postCampaignResourcesInfoResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Получение детальной информации о кампании по ID
   * @param id undefined
   * @return Данные о кампании
   */
  campaignDetailsResponse(id: number): __Observable<__StrictHttpResponse<CampaignDetailsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaigndetails/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignDetailsResult>;
      })
    );
  }
  /**
   * Получение детальной информации о кампании по ID
   * @param id undefined
   * @return Данные о кампании
   */
  campaignDetails(id: number): __Observable<CampaignDetailsResult> {
    return this.campaignDetailsResponse(id).pipe(
      __map(_r => _r.body as CampaignDetailsResult)
    );
  }

  /**
   * Запросить информацию о кампаниях, по временному периоду
   * @param params The `CampaignCommonApiService.QueryByDateParams` containing the following parameters:
   *
   * - `params.tillDate`: Дата
   *
   * - `params.state`: Фильтр по статусам кампаний, выводит только включенные в фильтр кампании
   *
   * - `params.limit`: Лимит
   *
   * - `params.fromDate`: Дата
   *
   * @return Данные о кампаниях
   */
  queryByDateResponse(params: CampaignCommonApiService.QueryByDateParams): __Observable<__StrictHttpResponse<Array<CampaignQueryByDateResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.paramsTillDate != null) __params = __params.set('params.tillDate', params.paramsTillDate.toString());
    (params.paramsState || []).forEach(val => {if (val != null) __params = __params.append('params.state', val.toString())});
    if (params.paramsLimit != null) __params = __params.set('params.limit', params.paramsLimit.toString());
    if (params.paramsFromDate != null) __params = __params.set('params.fromDate', params.paramsFromDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaignbydate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignQueryByDateResult>>;
      })
    );
  }
  /**
   * Запросить информацию о кампаниях, по временному периоду
   * @param params The `CampaignCommonApiService.QueryByDateParams` containing the following parameters:
   *
   * - `params.tillDate`: Дата
   *
   * - `params.state`: Фильтр по статусам кампаний, выводит только включенные в фильтр кампании
   *
   * - `params.limit`: Лимит
   *
   * - `params.fromDate`: Дата
   *
   * @return Данные о кампаниях
   */
  queryByDate(params: CampaignCommonApiService.QueryByDateParams): __Observable<Array<CampaignQueryByDateResult>> {
    return this.queryByDateResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignQueryByDateResult>)
    );
  }
}

module CampaignCommonApiService {

  /**
   * Parameters for postCampaignResourcesInfo
   */
  export interface PostCampaignResourcesInfoParams {
    resources: Array<CampaignResourceInfo>;
    id: number;
  }

  /**
   * Parameters for queryByDate
   */
  export interface QueryByDateParams {

    /**
     * Дата
     */
    paramsTillDate: string;

    /**
     * Фильтр по статусам кампаний, выводит только включенные в фильтр кампании
     */
    paramsState: Array<CampaignCommonState>;

    /**
     * Лимит
     */
    paramsLimit: number;

    /**
     * Дата
     */
    paramsFromDate: string;
  }
}

export { CampaignCommonApiService }
