'use strict';

import module from 'frontendModule';

import 'ng-infinite-scroll';

import './service'
import './factorys'

//import 'agModules/loya-charts';

import 'easy-pie-chart/dist/angular.easypiechart.min.js';
import 'angular-footable';

import 'model/PartnerModel/resource';
import 'model/CampaignCategoryModel/resource';
import 'model/MsgTemplate';
import 'model/CampaignCommonModel/resource';
import 'model/CampaignModel/resource';
import {RoleType} from "module/permissions/conf/roles";


let routePath = module.name + '.campaign.campaigns.index2';
module.requires = module.requires.concat([
    'infinite-scroll',
    'ui.footable',
    'easypiechart',
    'highcharts-ng'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.index" */ "./controllers"       ))

                .params('search', null)

                .resolve('PartnerList',          [ 'PartnerModel',  ( PartnerModel : any ) => PartnerModel.query().$promise ])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                })

                .breadcrumbs([ "Кампании", "Просмотр" ]);

        }]);
