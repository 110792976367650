import {EventEmitter, Injectable, OnDestroy, OnInit} from "@angular/core";
import {ngCurrencyL10nClass} from "directive/ngCurrencyLoya/ngCurrencyL10nClass";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class StatesChangerService implements OnDestroy{

  private states = {};
  private currentState: string = 'view';

  private onChangeStateEmitter: EventEmitter<string> = new EventEmitter<string>();

  get isActive() {
    return !!(this.states[this.state] && this.states[this.state].isActive);
  }

  get state() {
    return this.currentState;
  }

  set state(value:string) {
    this.changeState(value);
  }

  constructor() {
    this.initDefaultStates();
  }

  initDefaultStates() {

    this.createState('preview');
    this.createState('view');
    this.createState('edit', true);

  }

  createState( stateName: string, isActive = false ) {
    this.states[stateName] = this.states[stateName] || {
      isActive: isActive
    }
  }

  createButton(stateName: string, buttonName: string, event: Function) {
    this.createState(stateName);
    this.states[stateName][buttonName] = event;
  }

  changeState(stateName) {
    if ( !(stateName in this.states) )
      return ;

    this.currentState = stateName;
    this.onChangeStateEmitter.emit(this.state);
  }

  onChangeState(event) {
    this.onChangeStateEmitter.subscribe(event);
  }

  hasButton(buttonName: string) {
    return this.states[this.state] &&
           this.states[this.state][buttonName] &&
           typeof this.states[this.state][buttonName] === 'function';
  }

  click(buttonName: string, ...params  ){
    if (!this.hasButton(buttonName)) {
      return;
    }

    return this.states[this.state][buttonName](...params);
  }

  ngOnDestroy() {
    this.onChangeStateEmitter.unsubscribe();
  }

}
