/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ThresholdPriceApiConfiguration as __Configuration } from '../threshold-price-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Item } from '../models/item';
@Injectable({
  providedIn: 'root',
})
class ThresholdPriceApiService extends __BaseService {
  static readonly queryPath = '/{mechanicId}/list';
  static readonly deleteListPath = '/{mechanicId}/list';
  static readonly searchPath = '/{mechanicId}/list/search';
  static readonly addItemPath = '/{mechanicId}/list/item';
  static readonly updateItemByLocationPath = '/{mechanicId}/list/{id}/{locationId}';
  static readonly queryItemByLocationPath = '/{mechanicId}/list/{id}/{locationId}';
  static readonly deleteItemByLocationPath = '/{mechanicId}/list/{id}/{locationId}';
  static readonly updateItemPath = '/{mechanicId}/list/{id}';
  static readonly queryItemPath = '/{mechanicId}/list/{id}';
  static readonly deleteItemPath = '/{mechanicId}/list/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос позиций
   * @param params The `ThresholdPriceApiService.QueryParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список позиций
   */
  queryResponse(params: ThresholdPriceApiService.QueryParams): __Observable<__StrictHttpResponse<Array<Item>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Item>>;
      })
    );
  }
  /**
   * Запрос позиций
   * @param params The `ThresholdPriceApiService.QueryParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список позиций
   */
  query(params: ThresholdPriceApiService.QueryParams): __Observable<Array<Item>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<Item>)
    );
  }

  /**
   * @param mechanicId ID механики
   */
  deleteListResponse(mechanicId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(mechanicId))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param mechanicId ID механики
   */
  deleteList(mechanicId: number): __Observable<null> {
    return this.deleteListResponse(mechanicId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Поиск позиций
   * @param params The `ThresholdPriceApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`: Строка поиска
   *
   * - `mechanicId`: ID механики
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список позиций
   */
  searchResponse(params: ThresholdPriceApiService.SearchParams): __Observable<__StrictHttpResponse<Array<Item>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Item>>;
      })
    );
  }
  /**
   * Поиск позиций
   * @param params The `ThresholdPriceApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`: Строка поиска
   *
   * - `mechanicId`: ID механики
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список позиций
   */
  search(params: ThresholdPriceApiService.SearchParams): __Observable<Array<Item>> {
    return this.searchResponse(params).pipe(
      __map(_r => _r.body as Array<Item>)
    );
  }

  /**
   * Добавление позиции
   * @param params The `ThresholdPriceApiService.AddItemParams` containing the following parameters:
   *
   * - `params`: Параметры операции
   *
   * - `mechanicId`: ID механики
   *
   * @return Позиция успешно добавлена
   */
  addItemResponse(params: ThresholdPriceApiService.AddItemParams): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * Добавление позиции
   * @param params The `ThresholdPriceApiService.AddItemParams` containing the following parameters:
   *
   * - `params`: Параметры операции
   *
   * - `mechanicId`: ID механики
   *
   * @return Позиция успешно добавлена
   */
  addItem(params: ThresholdPriceApiService.AddItemParams): __Observable<Item> {
    return this.addItemResponse(params).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * updateItem
   * @param params The `ThresholdPriceApiService.UpdateItemByLocationParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   *
   * @return Позиция успешно обновлена
   */
  updateItemByLocationResponse(params: ThresholdPriceApiService.UpdateItemByLocationParams): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * updateItem
   * @param params The `ThresholdPriceApiService.UpdateItemByLocationParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   *
   * @return Позиция успешно обновлена
   */
  updateItemByLocation(params: ThresholdPriceApiService.UpdateItemByLocationParams): __Observable<Item> {
    return this.updateItemByLocationResponse(params).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * queryItem
   * @param params The `ThresholdPriceApiService.QueryItemByLocationParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   *
   * @return Запрошенная позиция
   */
  queryItemByLocationResponse(params: ThresholdPriceApiService.QueryItemByLocationParams): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * queryItem
   * @param params The `ThresholdPriceApiService.QueryItemByLocationParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   *
   * @return Запрошенная позиция
   */
  queryItemByLocation(params: ThresholdPriceApiService.QueryItemByLocationParams): __Observable<Item> {
    return this.queryItemByLocationResponse(params).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * Удаление позиции
   * @param params The `ThresholdPriceApiService.DeleteItemByLocationParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   */
  deleteItemByLocationResponse(params: ThresholdPriceApiService.DeleteItemByLocationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление позиции
   * @param params The `ThresholdPriceApiService.DeleteItemByLocationParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `locationId`: ID локации
   *
   * - `id`: ID позиции
   */
  deleteItemByLocation(params: ThresholdPriceApiService.DeleteItemByLocationParams): __Observable<null> {
    return this.deleteItemByLocationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * updateItem
   * @param params The `ThresholdPriceApiService.UpdateItemParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   *
   * @return Позиция успешно обновлена
   */
  updateItemResponse(params: ThresholdPriceApiService.UpdateItemParams): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * updateItem
   * @param params The `ThresholdPriceApiService.UpdateItemParams` containing the following parameters:
   *
   * - `params`:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   *
   * @return Позиция успешно обновлена
   */
  updateItem(params: ThresholdPriceApiService.UpdateItemParams): __Observable<Item> {
    return this.updateItemResponse(params).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * queryItem
   * @param params The `ThresholdPriceApiService.QueryItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   *
   * @return Запрошенная позиция
   */
  queryItemResponse(params: ThresholdPriceApiService.QueryItemParams): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * queryItem
   * @param params The `ThresholdPriceApiService.QueryItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   *
   * @return Запрошенная позиция
   */
  queryItem(params: ThresholdPriceApiService.QueryItemParams): __Observable<Item> {
    return this.queryItemResponse(params).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * Удаление позиции
   * @param params The `ThresholdPriceApiService.DeleteItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   */
  deleteItemResponse(params: ThresholdPriceApiService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление позиции
   * @param params The `ThresholdPriceApiService.DeleteItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID механики
   *
   * - `id`: ID позиции
   */
  deleteItem(params: ThresholdPriceApiService.DeleteItemParams): __Observable<null> {
    return this.deleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ThresholdPriceApiService {

  /**
   * Parameters for query
   */
  export interface QueryParams {

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for search
   */
  export interface SearchParams {

    /**
     * Строка поиска
     */
    sentence: string;

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for addItem
   */
  export interface AddItemParams {

    /**
     * Параметры операции
     */
    params: Item;

    /**
     * ID механики
     */
    mechanicId: number;
  }

  /**
   * Parameters for updateItemByLocation
   */
  export interface UpdateItemByLocationParams {
    params: Item;

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID локации
     */
    locationId: number;

    /**
     * ID позиции
     */
    id: string;
  }

  /**
   * Parameters for queryItemByLocation
   */
  export interface QueryItemByLocationParams {

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID локации
     */
    locationId: number;

    /**
     * ID позиции
     */
    id: string;
  }

  /**
   * Parameters for deleteItemByLocation
   */
  export interface DeleteItemByLocationParams {

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID локации
     */
    locationId: number;

    /**
     * ID позиции
     */
    id: string;
  }

  /**
   * Parameters for updateItem
   */
  export interface UpdateItemParams {
    params: Item;

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID позиции
     */
    id: string;
  }

  /**
   * Parameters for queryItem
   */
  export interface QueryItemParams {

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID позиции
     */
    id: string;
  }

  /**
   * Parameters for deleteItem
   */
  export interface DeleteItemParams {

    /**
     * ID механики
     */
    mechanicId: number;

    /**
     * ID позиции
     */
    id: string;
  }
}

export { ThresholdPriceApiService }
