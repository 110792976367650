
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {AccountService} from "../../../../../../shared/service/api/Account/Account.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {
  ACCOUNT_OPER_TYPE,
  ACCOUNT_OPER_TYPE_MANUAL,
  ACCOUNT_RECORD_STATUS
} from "../../../../../../shared/service/api/Account/Account.variables";
import {getDescription} from "../../utils/account-paget.utils";
import {PartnerService} from "../../../../../../shared/service/api/Partner/Partner.service";
import {
  ClientTransactionsService
} from "../../../../../../shared/service/api/ClientTransactions/ClientTransactions.service";
import {result} from "lodash";
import {NgForm} from "@angular/forms";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {CardService} from "../../../../../../shared/service/api/Card/Card.service";
import {switchMap} from "rxjs/operators";
import {ClientService} from "../../../../../../shared/service/api/Client/Client.service";
import {CreditAccount} from "../../../../../../api/CreditAccount/models/credit-account";
import {CreditAccountService} from "../../../../../../shared/service/api/CreditAccount/CreditAccount.service";

@Component({
  selector: 'account-merge-edit-page',
  templateUrl: './accountMerge-edit-page.component.html',
  styleUrls: ['./accountMerge-edit-page.component.scss'],
  providers: [
    PartnerService,
    AccountService,
    ClientTransactionsService,
    ClientService,
    CardService,
    CreditAccountService
  ]
})
export class AccountMergeEditPageComponent implements OnInit, OnChanges {

  public loader = new LoaderHandlerClass();

  @ViewChild('accountMergeForm') accountMergeForm: NgForm;

  @Input() public id: number;
  @Input() public clients: any[];

  @Output() onErrors = new EventEmitter();
  @Output() onUpdated = new EventEmitter();

  @Input() onSave: boolean;
  @Output() onSaveChange = new EventEmitter();

  public deleteAfterUseDisabled;
  public donorClient: any;
  public donorAccount: any;
  public donorCreditAccount: any;

  public cardNumber;
  public searchedCard;
  public notFound = false;

  public partnerList: any = [];
  public record: any = {
    deleteAfterUse: false
  };

  public bonusSettings: any;
  public showBonusConfig = false;

  public prevBonusValiditySettings;

  public ACCOUNT_OPER_TYPE = ACCOUNT_OPER_TYPE;
  public ACCOUNT_OPER_TYPE_MANUAL = ACCOUNT_OPER_TYPE_MANUAL;

  constructor(
    public accountService: AccountService,
    public creditAccountService: CreditAccountService,
    public partnerService : PartnerService,
    public cardService : CardService,
    public clientService: ClientService,
    public clientTransactionsService: ClientTransactionsService,
  ) { }

  ngOnInit() {

    this.onErrors.emit(false)
    this.onSaveChange.emit(false);
    this.checkCapabilities();

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['onSave']?.currentValue && !changes['onSave']?.firstChange)
      this.saveRecord();

    if (changes['clients']) {
      this.setDefaultDestClient();
    }

  }

  ngDoCheck() {

    this.onErrors
      .emit( !(
            typeof this.record?.deleteAfterUse !== "undefined" &&
            typeof this.record?.destClientId !== "undefined" &&
            typeof this.record?.cardId !== "undefined"
        )
      )

  }



  searchByClient(id) {

    this.loader.startLoading('updateClient');

    return this.clientService.getClient$(id).toPromise()
      .then( result => this.donorClient = result, result => {
        this.donorClient = undefined;
        return Promise.resolve( this.donorClient);
      } )
      .then( (result:any) => {

        if (result?.client?.accountId)
          return this.accountService.get$(result.client.accountId).toPromise()

        return undefined;
      } )
      .then( result => {
        this.donorAccount = result;
        if (this.donorAccount)
          return this.creditAccountService.get$(this.donorAccount?.account?.id).toPromise()

        return undefined
      })
      .then(result => this.donorCreditAccount = result)
      .finally(() => {
        this.loader.stopLoading('updateClient');
      })

  }

  setDefaultDestClient() {

    if (!this.clients?.length)
      return

    this.record.destClientId = this.clients[0].id

  }


  checkCapabilities() {

    this.deleteAfterUseDisabled = false;
    if ( getNg1Service('loyaPermissions').isCapabilityEnabled('crm.accounts.mergeOnlyProfile') ) {
      this.record.deleteAfterUse = true;
      this.deleteAfterUseDisabled = true;
    }

  }

  filterRecord(item) {
    return  item?.state !== 'archive';
  }

  filterDest = (item) => {
    return  item?.id === this.record?.destClientId;
  }

  public saveRecord() {
    this.accountService
      .union$( this.id, this.record)
      .pipe(
        this.loader.waitLoading('update')
      )
      .subscribe( {
        next: () => {
          this.onUpdated.emit(true);
          this.onSaveChange.emit(false);
        },
        error: () => {
          this.onSaveChange.emit(false);
        }
      })

  }

  onChangeCard(card) {

    if (!card || !card?.clientId) {
      this.donorAccount = undefined;
      this.donorCreditAccount = undefined;
      this.donorClient = undefined;
      delete this.record.cardId;
      return
    }

    this.notFound = false;

    this.record.cardId = card?.id;
    this.searchByClient(card?.clientId);

  }

  onErrorChange(error) {

    if (!error)
      return;

    this.notFound = true;
    this.donorAccount = undefined;
    this.donorCreditAccount = undefined;
    this.donorClient = undefined;

  }


}
