import {Injectable} from "@angular/core";
import {ReferrerApiService} from "../../../../api/ReferrerApi/services/referrer-api.service";
import {GetActionTransactionParams} from "../../../../api/TriggersApi/models/get-action-transaction-params";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {of} from "rxjs";
import GetGraphParams = ReferrerApiService.GetGraphParams;
import {CreateReferrerParams} from "../../../../api/ReferrerApi/models/create-referrer-params";
import {UpdateReferrer} from "../../../../api/ReferrerApi/models/update-referrer";
import UpdateReferrerParams = ReferrerApiService.UpdateReferrerParams;

@Injectable()
export class ReferrerService {

  constructor( private referrerApiService: ReferrerApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 50
    },
    search: {
      field: "sentence"
    },
    filters: [
      {
        field: 'filterState',
        values: {
            'active': 'active',
            'archive': 'archive',
        },
        currentValue: 'active'

      }
    ]
  });

  public gerRecordsParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 50
    },
    sort: {
      orderField: 'sortingOrder',
      sortField: 'sortingField',
    }
  });

  public getList$( params: ReferrerApiService.QueryReferrerParams ){
    return this.referrerApiService.queryReferrer( params );
  }

  public get$( id: number ){
    return this.referrerApiService.getReferrer( id );
  }

  public archive$( id: number ){
    return this.referrerApiService.deleteReferrer( id );
  }

  public gerRecords$( params : ReferrerApiService.QueryReferrerRecordsParams ) {
    return this.referrerApiService.queryReferrerRecords(params)
  }

  public balanceOperation$( params : ReferrerApiService.ReferrerBalanceOperationParams ) {
    return this.referrerApiService.referrerBalanceOperation(params);
  }

  public getChangelog$( params : any ) {
    return this.referrerApiService.getChangelog(params);
  }

  public getGraphValues$(params: GetGraphParams) {
    return this.referrerApiService.getGraph(params);
  }

  public create$(referer: CreateReferrerParams) {
    return this.referrerApiService.createReferrer(referer);
  }

  public update$(referer: UpdateReferrerParams) {
    return this.referrerApiService.updateReferrer(referer);
  }

}
