<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
>

    <div *ngIf="selectedPartner"
    >

      <goods-tree
        style="height: 100vh"
        [partnerId]="selectedPartner.id"
        [editCallBack]="editCallback"
      ></goods-tree>

    </div>

</partner-menu>


<ng-template #editWindow let-modal>

  <form name="bonusrestrictIndexPageFrom" #bonusrestrictIndexPageFrom="ngForm" novalidate autocomplete="off">

      <div class="modal-header  bg-light align-items-center">

        <i class="fa fa-list text-success m-r-sm f-s-12"> </i>

        <div class=" relative overflow-wrap-break">

          <span class="h4"> Ограничения вознаграждений для
            <span class="text-success"
                  [innerText]="itemForEdit?.name || ''"
            >
            </span>
          </span>

        </div>

        <div class="flex-grow-1"></div>

        <div class="cancel-buttons-col">
          <div class="text-nowrap">
            <button type="button" class="btn btn-sm btn-default m-r-xs btn-addon"
              data-testid="limitRewardGoods.save()"
              (click)="modal.close(true)"
              [disabled]="bonusrestrictIndexPageFrom.invalid"
            >
              <i class=" fa fa-check text-primary"></i>
              <span class="btn-name"
              >Сохранить</span>
            </button>

            <button type="button" class="btn btn-sm btn-default  "
                data-testid="limitRewardGoods.cancel()"
                (click)="modal.dismiss()"
            >
              <i class=" fa fa-remove text-danger"></i>
            </button>

          </div>
        </div>
      </div>

      <div class="modal-body no-padder"
           *ngIf="!loader.isLoading('selectPrepare')"
      >

          <div class="d-flex m-t-xs m-b-xs">

            <div class="col p-r-sm ">
              <grid-container>

            <div class="grid-item grid-item__form form-item"
                 *ngIf="!!itemForEdit?.sku"
            >
              <div class="grid-item__label">
                Ограничение для SKU
              </div>
              <div class="grid-item__value"
                   [innerText]="itemForEdit?.sku || ''"
              >
              </div>

            </div>

            <div class="grid-item grid-item__form form-item"
                 id="maxDiscountContainer"
            >
              <div class="grid-item__label">
                <i class="fa fa-align-justify">
                </i>
                <div>
                  <span>Максимальная скидка</span>
                  <div class="clear" *ngIf="itemForEdit.limits.maxDiscountType ==='fixprice'">(Мин. цена по скидкам)</div>
                </div>
              </div>

              <div class="grid-item__value">
                <div class="editable-rec input-group "  >

                  <input
                    type="number"
                    class="form-control form-control-edittable "
                    [(ngModel)]="itemForEdit.limits.maxDiscount"
                    name="maxDiscount"
                    data-testid="limitRewardGoods.input.maxDiscount"
                    placeholder="Введите максимальную скидку"
                    [max]="!!itemForEdit.limits.maxDiscountType ? awardTypesDiscount[itemForEdit.limits.maxDiscountType].max : 100"
                    [min]="!!itemForEdit.limits.maxDiscountType ? awardTypesDiscount[itemForEdit.limits.maxDiscountType].min : 0"
                    [pattern]="!!itemForEdit.limits.maxDiscountType ? awardTypesDiscount[itemForEdit.limits.maxDiscountType].pattern : ''"
                    (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                  >

                  <div class="input-group-btn dropdown"
                       ngbDropdown
                       placement="bottom-right"
                  >
                    <button type="button"
                            class="btn btn-default"
                            data-testid="limitRewardGoods.maxDiscount.chooseType()"
                            ngbDropdownToggle
                    >
                      <span [innerText]="awardTypesDiscount[itemForEdit.limits.maxDiscountType]?.label"></span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu " ngbDropdownMenu>
                      <li ngbDropdownItem class="no-padder"
                          *ngFor=" let item of awardTypesDiscount |  keyvalue" data-testid="limitRewardGoods.maxDiscount.type">
                        <a href="javascript:void(0)"
                           (click)="itemForEdit.limits.maxDiscountType = item.key"
                        >
                          <span [innerText]="item.value.name"></span>
                          <span class="text-muted">
                                  ( <span [innerText]="item.value.label"></span> )
                              </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="grid-item grid-item__form form-item"
                 id="maxAllowBonusContainer"
                 *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: itemForEdit.limits.maxAllowBonus>=0 }"
            >
              <div class="grid-item__label">
                <i class="fa fa-align-justify">
                </i>
                <span> Максимальная оплата бонусами</span>
              </div>

              <div class="grid-item__value">

                <div class="editable-rec input-group "  >
                  <input
                    type="number"
                    class="form-control form-control-edittable "
                    name="maxAllowBonus"
                    [(ngModel)]="itemForEdit.limits.maxAllowBonus"
                    data-testid="limitRewardGoods.input.maxAllowBonus"
                    placeholder="Введите максимальную оплата бонусами"
                    [max]="!!itemForEdit.limits.maxAllowBonusType ? awardTypes[itemForEdit.limits.maxAllowBonusType].max : 1000"
                    [min]="!!itemForEdit.limits.maxAllowBonusType ? awardTypes[itemForEdit.limits.maxAllowBonusType].min : 0"
                    [pattern]="!!itemForEdit.limits.maxAllowBonusType ? awardTypes[itemForEdit.limits.maxAllowBonusType].pattern : ''"
                    (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                  >

                  <div class="input-group-btn dropdown"
                       ngbDropdown
                       placement="bottom-right"
                  >
                    <button type="button"
                            class="btn btn-default"
                            data-testid="limitRewardGoods.maxAllowBonus.chooseType()"
                            ngbDropdownToggle
                    >
                      <span [innerText]="awardTypes[itemForEdit.limits.maxAllowBonusType]?.label"></span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                      <li ngbDropdownItem class="no-padder"
                          *ngFor=" let item of awardTypes |  keyvalue" data-testid="limitRewardGoods.maxAllowBonus.type">
                        <a href="javascript:void(0)"
                           (click)="itemForEdit.limits.maxAllowBonusType = item.key"
                        >
                          <span [innerText]="item.value.name"></span>
                          <span class="text-muted">
                                  ( <span [innerText]="item.value.label"></span> )
                              </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="grid-item grid-item__form form-item"
                 id="maxAwardBonusContainer"
                 *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: itemForEdit.limits.maxAwardBonus>=0 }"
            >

              <div class="grid-item__label">
                <i class="fa fa-align-justify">
                </i>
                <span> Максимальное начисление бонусов</span>
              </div>

              <div class="grid-item__value">

                <div class="editable-rec input-group "  >
                  <input
                    type="number"
                    class="form-control form-control-edittable "
                    [(ngModel)]="itemForEdit.limits.maxAwardBonus"
                    name="maxAwardBonus"
                    data-testid="limitRewardGoods.input.maxAwardBonus"
                    placeholder="Введите максимальное начисление бонусов"
                    [max]="itemForEdit.limits.maxAwardBonusType ? awardTypes[itemForEdit.limits.maxAwardBonusType].max : 1000"
                    [min]="itemForEdit.limits.maxAwardBonusType ? awardTypes[itemForEdit.limits.maxAwardBonusType].min : 0"
                    [pattern]="itemForEdit.limits.maxAwardBonusType ? awardTypes[itemForEdit.limits.maxAwardBonusType].pattern : ''"
                    (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                  >

                  <div class="input-group-btn dropdown"
                       ngbDropdown
                       placement="bottom-right"
                  >
                    <button type="button"
                            class="btn btn-default"
                            data-testid="limitRewardGoods.maxAwardBonus.chooseType()"
                            ngbDropdownToggle
                    >
                      <span [innerText]="awardTypes[itemForEdit.limits.maxAwardBonusType]?.label"></span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                      <li ngbDropdownItem class="no-padder"
                          *ngFor=" let item of awardTypes |  keyvalue" data-testid="limitRewardGoods.maxAwardBonus.type">
                        <a href="javascript:void(0)"
                           (click)="itemForEdit.limits.maxAwardBonusType = item.key"
                        >
                          <span [innerText]="item.value.name"></span>
                          <span class="text-muted">
                                  ( <span [innerText]="item.value.label"></span> )
                              </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>

          </grid-container>
            </div>

            <div class="col-auto"
                 *ngIf="!!itemForEdit?.limitByLocations?.length"
            >
              <button  class="btn btn-danger  m-t-xs"
                       [style.visibility]="'hidden'"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>

          </div>

          <div class="card no-back   "
               *ngIf="!!itemForEdit?.sku"
          >

            <div class="card-header b-t d-flex justify-content-center align-items-center">

              <div class="col">
                Ограничения товара по точкам продаж
              </div>

              <div class="col-auto text-right no-padder">
                <button class="btn btn-sm btn-addon  bg-success "
                        type="button"
                        (click)="addLimitByLocation()"
                        data-testid="limitRewardGoods.addLimitByLocation()"
                >
                  <i class=" fa fa-plus "></i>
                  <span class="btn-name">Добавить ограничение</span>
                </button>
              </div>

            </div>

            <div class="card-body no-padder" >

              <div class="d-flex m-t-xs"
                   *ngFor="let itemLocation of itemForEdit.limitByLocations; let index = index"
              >

                <div class="col p-r-sm">

                  <grid-container>
                    <div class="grid-item grid-item__form form-item">
                      <div class="grid-item__label">
                        Ограничение для ТО
                      </div>
                      <div class="grid-item__value">
                        <span [innerHTML]="getLocationName(itemLocation?.locationId)"></span>
                      </div>
                    </div>

                    <div class="grid-item grid-item__form form-item">
                      <div class="grid-item__label">
                        <i class="fa fa-align-justify">
                        </i>
                        <div>
                          <span>Максимальная скидка</span>
                          <div class="clear" *ngIf="itemLocation.limits.maxDiscountType ==='fixprice'">(Мин. цена по скидкам)</div>
                        </div>
                      </div>

                      <div class="grid-item__value">
                        <div class="editable-rec input-group "  >

                          <input
                            type="number"
                            class="form-control form-control-edittable "
                            data-testid="limitByLocations.input.maxDiscount"
                            [(ngModel)]="itemLocation.limits.maxDiscount"
                            [name]="'maxDiscount' + index"
                            placeholder="Введите максимальную скидку"
                            [max]="!!itemLocation.limits.maxDiscountType ? awardTypesDiscount[itemLocation.limits.maxDiscountType].max : 100"
                            [min]="!!itemLocation.limits.maxDiscountType ? awardTypesDiscount[itemLocation.limits.maxDiscountType].min : 0"
                            [pattern]="!!itemLocation.limits.maxDiscountType ? awardTypesDiscount[itemLocation.limits.maxDiscountType].pattern : ''"
                            (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                          >

                          <div class="input-group-btn dropdown"
                               ngbDropdown
                               placement="bottom-right"
                          >
                            <button type="button"
                                    class="btn btn-default"
                                    data-testid="limitByLocations.maxDiscount.chooseType()"
                                    ngbDropdownToggle
                            >
                              <span [innerText]="awardTypesDiscount[itemLocation.limits.maxDiscountType]?.label"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu " ngbDropdownMenu>
                              <li ngbDropdownItem class="no-padder"
                                  *ngFor=" let item of awardTypesDiscount |  keyvalue" data-testid="limitByLocations.maxDiscount.type">
                                <a href="javascript:void(0)"
                                   (click)="itemLocation.limits.maxDiscountType = item.key"
                                >
                                  <span [innerText]="item.value.name"></span>
                                  <span class="text-muted">
                                      ( <span [innerText]="item.value.label"></span> )
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="grid-item grid-item__form form-item"
                         *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: itemLocation.limits.maxAllowBonus>=0 }"
                    >
                      <div class="grid-item__label">
                        <i class="fa fa-align-justify">
                        </i>
                        <span> Максимальная оплата бонусами</span>
                      </div>

                      <div class="grid-item__value">

                        <div class="editable-rec input-group "  >
                          <input
                            type="number"
                            class="form-control form-control-edittable "
                            [name]="'maxAllowBonus' + index"
                            data-testid="limitByLocations.input.maxAllowBonus"
                            [(ngModel)]="itemLocation.limits.maxAllowBonus"
                            placeholder="Введите максимальную оплата бонусами"
                            [max]="!!itemLocation.limits.maxAllowBonusType ? awardTypes[itemLocation.limits.maxAllowBonusType].max : 1000"
                            [min]="!!itemLocation.limits.maxAllowBonusType ? awardTypes[itemLocation.limits.maxAllowBonusType].min : 0"
                            [pattern]="!!itemLocation.limits.maxAllowBonusType ? awardTypes[itemLocation.limits.maxAllowBonusType].pattern : ''"
                            (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                          >

                          <div class="input-group-btn dropdown"
                               ngbDropdown
                               placement="bottom-right"
                          >
                            <button type="button"
                                    class="btn btn-default"
                                    data-testid="limitByLocations.maxAllowBonus.chooseType()"
                                    ngbDropdownToggle
                            >
                              <span [innerText]="awardTypes[itemLocation.limits.maxAllowBonusType]?.label"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                              <li ngbDropdownItem class="no-padder"
                                  *ngFor=" let item of awardTypes |  keyvalue" data-testid="limitByLocations.maxAllowBonus.type">
                                <a href="javascript:void(0)"
                                   (click)="itemLocation.limits.maxAllowBonusType = item.key"
                                >
                                  <span [innerText]="item.value.name"></span>
                                  <span class="text-muted">
                                      ( <span [innerText]="item.value.label"></span> )
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="grid-item grid-item__form form-item"
                         *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: itemLocation.limits.maxAwardBonus>=0 }"
                    >

                      <div class="grid-item__label">
                        <i class="fa fa-align-justify">
                        </i>
                        <span> Максимальное начисление бонусов</span>
                      </div>

                      <div class="grid-item__value">

                        <div class="editable-rec input-group "  >
                          <input
                            type="number"
                            class="form-control form-control-edittable "
                            [(ngModel)]="itemLocation.limits.maxAwardBonus"
                            [name]="'maxAwardBonus' + index"
                            data-testid="limitByLocations.input.maxAwardBonus"
                            placeholder="Введите максимальное начисление бонусов"
                            [max]="itemLocation.limits.maxAwardBonusType ? awardTypes[itemLocation.limits.maxAwardBonusType].max : 1000"
                            [min]="itemLocation.limits.maxAwardBonusType ? awardTypes[itemLocation.limits.maxAwardBonusType].min : 0"
                            [pattern]="itemLocation.limits.maxAwardBonusType ? awardTypes[itemLocation.limits.maxAwardBonusType].pattern : ''"
                            (keydown)="$event.keyCode === 69 ? $event.preventDefault() : ''"
                          >

                          <div class="input-group-btn dropdown"
                               ngbDropdown
                               placement="bottom-right"
                          >
                            <button type="button"
                                    class="btn btn-default"
                                    data-testid="limitByLocations.maxAwardBonus.chooseType()"
                                    ngbDropdownToggle
                            >
                              <span [innerText]="awardTypes[itemLocation.limits.maxAwardBonusType]?.label"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                              <li ngbDropdownItem class="no-padder"
                                  *ngFor=" let item of awardTypes |  keyvalue" data-testid="limitByLocations.maxAwardBonus.type">
                                <a href="javascript:void(0)"
                                   (click)="itemLocation.limits.maxAwardBonusType = item.key"
                                >
                                  <span [innerText]="item.value.name"></span>
                                  <span class="text-muted">
                                      ( <span [innerText]="item.value.label"></span> )
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>

                    </div>

                  </grid-container>

                </div>

                <div class="col-auto">
                  <button  class="btn btn-danger m-t-xs"
                           (click)="deleteLimitByLocations(itemLocation)"
                           data-testid="limitByLocations.delete()"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>

              </div>

              <div class=" wrapper padder text-center "
                   *ngIf="!itemForEdit || !itemForEdit.limitByLocations || !itemForEdit.limitByLocations.length"
              >

                  <div class="text-lg text-muted clearfix" data-testid="limitByLocations.empty">
                    Ограничения по ТП отсутствуют
                  </div>

              </div>

            </div>
          </div>


      </div>

      <div class="location-block-loading"
           *ngIf="loader.isLoading('selectPrepare')"
      >

        <i class="fa fa-spin fa-spinner text-warning fa-5x"></i>

      </div>


  </form>
</ng-template>
