import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_bonusAward} from "../block-ids.variables";

export const BLOCK_ID = BLOCK_bonusAward;

@Component({
  selector: 'dashboard-count-bonuses',
  template: `
    <div class="r card padder-v item">
      <div class="   padder-v r-r text-ellipsis text-center">
        <div class="text-info-dker font-thin h1">

          <ng-container
            *ngIf="!isLoading"
          >
            <span [innerText]="value  | loyaNumber:false"></span>

          </ng-container>

          <ng-container
            *ngIf="isLoading"
          >
            <span class="f-s-6 font-thin">
                <i class="fa fa-lg fa-spin fa-spinner"></i>
            </span>

          </ng-container>

        </div>
        <span class="text-muted text-xs">Начислено бонусов</span>
      </div>
    </div>
  `,
  providers: [
  ]
})
export class DashboardCountBonusesComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public value = 0;
  public isLoading = true


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  updateValue(params?) {
    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {
          this.value = result;
          this.isLoading = false;
        },

        error: () => {
          this.value = 0;
          this.isLoading = false;
        }
      })

  }


  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
