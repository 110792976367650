import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map} from "rxjs/operators";

const API_PATH = '/api/1.0'

@Injectable({
  providedIn: "root"
})
export class ImageCarouselUploaderService {

  constructor( private httpClient:HttpClient ) {
  }

  public checkService$(){
    return this.httpClient
            .get(`${API_PATH}/image/checkService`,{
              responseType: "text"
            })
            .pipe(
              map( result => result?.toLowerCase() === 'ok',
                  error => {
                      error?.stopPopupError();
                      return throwError(false);
                    })
            )
  }

  public saveFile$(fileItem): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('image', fileItem );

    return this.httpClient
      .post(`${API_PATH}/image`,formData, {
        responseType: "text"
      })
  }

  public deleteFile$(guid): Observable<any>{
    return this.httpClient
      .delete(`${API_PATH}/image/${guid}`, {
        responseType: "text"
      })

  }

  public getImageUrl(imageId): string{
    return `${API_PATH}/image/` + (typeof imageId === "object" ? imageId?.guid : imageId)
  }

  public campaignGetList$(campaignId): Observable<any> {
    return this.httpClient.get(`/campaignimage/${campaignId}` )
  }

  public campaignSaveImage$(campaignId, imageId, body?) {
    return this.httpClient.put(`/campaignimage/${campaignId}/${imageId}`, body )
  }

  public campaignDeleteImage$(campaignId, imageId) {
    return this.httpClient.delete(`/campaignimage/${campaignId}/${imageId?.guid || imageId}` )
  }

}
