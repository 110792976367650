/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { UserApiConfiguration as __Configuration } from '../user-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserProtocol } from '../models/user-protocol';
import { RawUser } from '../models/raw-user';
import { FeedbackInfo } from '../models/feedback-info';
@Injectable({
  providedIn: 'root',
})
class UserApiService extends __BaseService {
  static readonly createPath = '/user';
  static readonly queryPath = '/user';
  static readonly dataPath = '/user/data';
  static readonly getPath = '/user/{id}';
  static readonly updatePath = '/user/{id}';
  static readonly deletePath = '/user/{id}';
  static readonly sendFeedbackPath = '/user/feedback';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создать нового пользователя
   * @param userProtocol Параметры создаваемого пользователя
   * @return Созданный пользователь
   */
  createResponse(userProtocol: UserProtocol): __Observable<__StrictHttpResponse<UserProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userProtocol;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProtocol>;
      })
    );
  }
  /**
   * Создать нового пользователя
   * @param userProtocol Параметры создаваемого пользователя
   * @return Созданный пользователь
   */
  create(userProtocol: UserProtocol): __Observable<UserProtocol> {
    return this.createResponse(userProtocol).pipe(
      __map(_r => _r.body as UserProtocol)
    );
  }

  /**
   * Список пользователей
   * @return Список пользователей
   */
  queryResponse(): __Observable<__StrictHttpResponse<Array<UserProtocol>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserProtocol>>;
      })
    );
  }
  /**
   * Список пользователей
   * @return Список пользователей
   */
  query(): __Observable<Array<UserProtocol>> {
    return this.queryResponse().pipe(
      __map(_r => _r.body as Array<UserProtocol>)
    );
  }

  /**
   * Данные по текущему пользователю
   * @return Данные по текущему пользователю
   */
  dataResponse(): __Observable<__StrictHttpResponse<RawUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawUser>;
      })
    );
  }
  /**
   * Данные по текущему пользователю
   * @return Данные по текущему пользователю
   */
  data(): __Observable<RawUser> {
    return this.dataResponse().pipe(
      __map(_r => _r.body as RawUser)
    );
  }

  /**
   * Данные о пользователю по ID
   * @param id ID пользователя
   * @return Данные о пользователе
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<UserProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProtocol>;
      })
    );
  }
  /**
   * Данные о пользователю по ID
   * @param id ID пользователя
   * @return Данные о пользователе
   */
  get(id: number): __Observable<UserProtocol> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as UserProtocol)
    );
  }

  /**
   * Обновление данных о пользователе
   * @param params The `UserApiService.UpdateParams` containing the following parameters:
   *
   * - `userProtocol`: Данные о пользователе
   *
   * - `id`: ID пользователя
   *
   * @return Данные о пользователе
   */
  updateResponse(params: UserApiService.UpdateParams): __Observable<__StrictHttpResponse<UserProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.userProtocol;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProtocol>;
      })
    );
  }
  /**
   * Обновление данных о пользователе
   * @param params The `UserApiService.UpdateParams` containing the following parameters:
   *
   * - `userProtocol`: Данные о пользователе
   *
   * - `id`: ID пользователя
   *
   * @return Данные о пользователе
   */
  update(params: UserApiService.UpdateParams): __Observable<UserProtocol> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as UserProtocol)
    );
  }

  /**
   * Удаление пользователя по ID
   * @param id ID пользователя
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление пользователя по ID
   * @param id ID пользователя
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Отправка пожеланий по улучшению
   * @param feedbackInfo undefined
   */
  sendFeedbackResponse(feedbackInfo: FeedbackInfo): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = feedbackInfo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/feedback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Отправка пожеланий по улучшению
   * @param feedbackInfo undefined
   */
  sendFeedback(feedbackInfo: FeedbackInfo): __Observable<null> {
    return this.sendFeedbackResponse(feedbackInfo).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Данные о пользователе
     */
    userProtocol: UserProtocol;

    /**
     * ID пользователя
     */
    id: number;
  }
}

export { UserApiService }
