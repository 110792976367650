/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for ClientWalletApi services
 */
@Injectable({
  providedIn: 'root',
})
export class ClientWalletApiConfiguration {
  rootUrl: string = location.origin;
}

export interface ClientWalletApiConfigurationInterface {
  rootUrl?: string;
}
