import {NgModule} from "@angular/core";


import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";
import {DirectiveModule} from "../../directive/directive.module";
import {FilterGoodsComponent} from "./filter-goods/filter-goods.component";
import {FilterLocationsComponent} from "./filter-locations/filter-locations.component";
import {FilterCampaignComponent} from "./filter-campaign/filter-campaign.component";
import {CampaignDialogModule} from "../campaign-dialog/campaign-dialog.module";
import {FilterCouponRuleComponent} from "./filter-coupon-rule/filter-coupon-rule.component";
import {CouponRuleDialogModule} from "../coupon-rule-dialog/coupon-rule-dialog.module";
import {FilterDatePeriodComponent} from "./filter-date-period/filter-date-period.component";
import {FilterCheckboxComponent} from "./filter-checkbox/filter-checkbox.component";
import {FilterTriggerComponent} from "./filter-trigger/filter-trigger.component";
import {CampaigncategoryComponent} from "./filter-campaigncategory/campaigncategory.component";
import {FilterTimePeriodComponent} from "./filter-time-period/filter-time-period.component";
import {FilterPricePeriodComponent} from "./filter-price-period/filter-price-period.component";
import {FilterRadioComponent} from "./filter-radio/filter-radio.component";


@NgModule({
  declarations: [
    FilterGoodsComponent,
    FilterLocationsComponent,
    FilterCampaignComponent,
    FilterCouponRuleComponent,
    FilterDatePeriodComponent,
    FilterCheckboxComponent,
    FilterTriggerComponent,
    FilterTimePeriodComponent,
    CampaigncategoryComponent,
    FilterPricePeriodComponent,
    FilterRadioComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
    InfiniteScrollModule,
    PipesComponentsModule,
    DirectiveModule,
    CampaignDialogModule,
    CouponRuleDialogModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports:[
    FilterGoodsComponent,
    FilterLocationsComponent,
    FilterCampaignComponent,
    FilterCouponRuleComponent,
    FilterDatePeriodComponent,
    FilterCheckboxComponent,
    FilterTriggerComponent,
    FilterTimePeriodComponent,
    CampaigncategoryComponent,
    FilterPricePeriodComponent,
    FilterRadioComponent
  ]
})
export class FiltersComponentsModule {

  constructor() {}

}
