import module from 'frontendModule';
import {downgradeComponent} from "@angular/upgrade/static";
import {AuthPageComponent} from "./auth-page.component";

export default
  module
    .config(
      ['routeBuilderProvider', (routeBuilderProvider : any) => {

        routeBuilderProvider
          .route( 'auth' )
          .template( '<auth-page></auth-page>', true);

      }])
    .directive('authPage',
        downgradeComponent({ component: AuthPageComponent })
    )
