<div class="dropdown  d-inline-block"   ngbDropdown>

  <button class="btn btn-default btn-sm btn-addon collapse-xs"
          ngbDropdownToggle
          [disabled]="disabled"
  >
    <i>
        <span class="fa "
              [ngClass]="{ 'fa-filter': !isPending, 'fa-spin fa-spinner': isPending , 'text-danger': !!filterFrom || !!filterTo  }"

        ></span>
    </i>
    <span class="btn-name">
        Интервалы
        <span class="caret "></span>
    </span>
  </button>

  <ul class="dropdown-menu scrollable "
      ngbDropdownMenu
      style="z-index: 9999; "
      onclick="event.stopPropagation()"
  >

    <li class="wrapper-xs padder radio text-ellipsis">
      <label class="i-checks" (click)="$event.stopPropagation()">
        <input type="radio" [(ngModel)]="datesKind" (ngModelChange)="onKindChange($event)" [value]="undefined" data-testid="filter.datesKind.not">
        <i></i>
        Не задан
      </label>
    </li>

    <li class="wrapper-xs padder radio text-ellipsis">
      <label class="i-checks" (click)="$event.stopPropagation()">
        <input type="radio" [(ngModel)]="datesKind"  (ngModelChange)="onKindChange($event)" [value]="'start'" data-testid="filter.datesKind.start">
        <i></i>
        Фильтр по дате старта
      </label>
    </li>

    <li class="wrapper-xs padder radio text-ellipsis">
      <label class="i-checks" (click)="$event.stopPropagation()">
        <input type="radio" [(ngModel)]="datesKind"  (ngModelChange)="onKindChange($event)" [value]="'active'" data-testid="filter.datesKind.active">
        <i></i>
        Фильтр по дате активности
      </label>
    </li>

    <li class="wrapper-xs padder text-ellipsis"
        [hidden]="!datesKind"
    >

      <div class="input-group ">
        <span class="input-group-addon" style="min-width:50px">С</span>
        <input type="date"
               data-testid="datesKind.input.from"
               class="form-control"
               #filterFormModel="ngModel"
               [(ngModel)]="filterFrom"
               (ngModelChange)="updateValidators('from')"
               [min]="minDate"
               [max]="filterTo"
               [disabled]="disabled"
               [required]="requiredFrom && !!datesKind"
        >
        <span class="input-group-btn" *ngIf="filterFrom">
                  <button
                    class="btn btn-danger m-b-none m-t-none"
                    type="button"
                    [disabled]="disabled"
                    (click)="filterFrom = undefined">
                      <i class="fa fa-times"></i>
                  </button>
          </span>
      </div>

    </li>
    <li class="wrapper-xs padder text-ellipsis"
        [hidden]="!datesKind"
    >

      <div class="input-group m-b-xs">
        <span class="input-group-addon" style="min-width:50px">ПО</span>
        <input type="date"
               class="form-control"
               data-testid="datesKind.input.till"
               #filterToModel="ngModel"
               [(ngModel)]="filterTo"
               (ngModelChange)="updateValidators('to')"
               [min]="filterFrom"
               [max]="maxDate"
               [disabled]="disabled"
               [required]="requiredTo && !!datesKind"
        >
        <span class="input-group-btn" *ngIf="filterTo">
              <button
                class="btn btn-danger  m-b-none m-t-none"
                type="button"
                [disabled]="disabled"
                (click)="filterTo = undefined">
                  <i class="fa fa-times"></i>
              </button>
          </span>
      </div>

    </li>
    <li class=" padder text-right"
        [hidden]="!datesKind"
    >
      <button
        class="btn w-full btn-danger btn-sm btn-addon m-l-none m-b-xs m-t-none"
        type="button"
        *ngIf="filterTo && filterFrom"
        [disabled]="disabled"
        (click)="filterTo = undefined; filterFrom = undefined;">
        <i class="fa fa-times"></i>
        <span class="ben-name">
            Сбросить
          </span>
      </button>
    </li>

  </ul>

</div>
