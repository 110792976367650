'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";

let routePath   = module.name + '.receipt.index';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )
            .url( '/:number?:id' )

            .template( '<receipt-index-page [id]="id" [number]="number"></receipt-index-page>', true)
            .controller(['$scope', '$stateParams', function($scope, $stateParams) {
              $scope.id = $stateParams.id;
              $scope.number = $stateParams.number;
            } ])

            .breadcrumbs( [ "Чеки", "Поиск чеков" ] )

            .permissions( {
              only: RECEIPT_ROLES,
            })

        }]);
