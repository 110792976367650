
export class Utils {

    static  getField(ruleItem: any, field:string) {

        const path = field.split('.').reverse();
        let item = ruleItem[ path.pop() ];

        while ( typeof item !== 'undefined' && path.length ) {
            const index = path.pop();
            if ( typeof item !=='object' || typeof item[index] === "undefined" ) {
                return undefined;
            }
            item = item[index];
        }

        return item;
    }

    static  isReardGoodType(ruleItem: any, type: string) {
        return Utils.getField(ruleItem, 'couponRule.reward.reward.rewType') === "goods" &&
               Utils.getField(ruleItem, 'couponRule.reward.onGood.rewType') === type;
    }

}