import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_emailCount} from "../block-ids.variables";

export const BLOCK_ID = BLOCK_emailCount;

@Component({
  selector: 'dashboard-email-count',
  template: `
    <div class="card wrapper-md padder item">

      <span class="text-info">
        <ng-container
          *ngIf="!isLoading"
        >
          <div class="h1 font-thin " [innerText]="value || 0" data-testid="emailDelivered"></div>

        </ng-container>

        <ng-container
          *ngIf="isLoading"
        >
          <div class="">
              <i class="fa fa-2x fa-spin fa-spinner"></i>
          </div>

        </ng-container>
      </span>

      <span class="text-muted text-xs">E-MAIL отправлено</span>
    </div>
  `,
  providers: [
  ]
})
export class DashboardEmailCountComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public value = 0;
  public isLoading = true


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }

  ngOnInit() {
    this.bindUpdateEvent();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }


  updateValue(params?) {
    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {
          this.value = result;
          this.isLoading = false;
        },

        error: () => {
          this.value = 0;
          this.isLoading = false;
        }
      })

  }

  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
