'use strict';

import module from 'frontendModule';

import 'model/GroupModel/resource';
import {UserSettingsStorage} from "class/user-settings-storage.service";
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.segments.groups.index';

module.requires.push('infinite-scroll');
module.requires.push('ui.footable');

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )


                .template(   () => import( /* webpackChunkName: "assets/pages/segments.groups.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/segments.groups.index" */ "./controllers"       ))


                .breadcrumbs(  ["Сегментация", "Группы" ] )


                .resolve('filterSettings', [function () {
                    return UserSettingsStorage
                        .get('segments.groups.index.filter')
                        .then( ( val : any ) => JSON.parse(val ) )
                        .catch(() => ({ 'filter': {
                            states : [ 'deploy', 'draft', 'refresh' ]
                        } }) );

                }]  )

                .resolve('groupsList', ['GroupModel', 'filterSettings', function (GroupModel, filterSettings) {

                    filterSettings.filter = Object.assign({
                        withoutSubgroup: true
                    }, filterSettings.filter || {});

                    return GroupModel.query( Object.assign({
                        pager: { limit: 50 }
                    }, filterSettings)  ).$promise;
                }])

                .resolve('groupsCompleteness',  ['groupsList', 'GroupModel', '$q', function (groupsList, GroupModel, $q) {

                  let ids = groupsList?.filter( i => i.group.state !== 'archive')?.map(i => i.group.id) || [];

                  if (!ids.length)
                    return Promise.resolve({});

                  return GroupModel.getStates(ids).$promise
                      .then( response => {
                        let result = {};

                        response.forEach(state => {
                          if (state.stateCompleteness === 'started') {
                            result[state.id] = {
                              "groupState": state.groupState,
                              "stateCompleteness": state.stateCompleteness
                            };
                          } else {
                            result[state.id] = false
                          }
                        })

                        return result;
                      })

                }])

                .resolve('segmentNames', ['SegmentationModel', '$state', '$q', function (SegmentationModel, $state, $q) {
                    var deferred = $q.defer();

                    SegmentationModel.query({ "filter": {'state': 'deploy'} }, function (data) {
                        var segmentNames = [];
                        angular.forEach(data, function (segmentitem) {
                            segmentNames[segmentitem.id] = segmentitem.name;
                        });

                        deferred.resolve(segmentNames);
                    }, function () {
                        deferred.reject([]);
                    });

                    return deferred.promise;

                }])


                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
                    redirectTo: 'frontend.nopermissions'
                })

        }]);

