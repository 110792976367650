'use strict';

import module from 'frontendModule';

export default module
    .service('campaignsFilter', function () {

        var filter = {
            values: false
        };

        return filter;

    });
