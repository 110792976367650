import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {CampaignProtocol} from "../../../../../../api/CampaignApi/models/campaign-protocol";
import {ControlContainer, NgForm} from "@angular/forms";

export const checkExternalCode = (campaignItem: CampaignProtocol) => true; // LOYA-4227 !!campaignItem?.elSettings?.externalCode

@Component({
  selector: 'campaign-external-code',
  template: `

    <ng-container *ngIf="isExternalCodeCampaign && !!campaignItem?.elSettings">

      <ng-container *ngIf="!isEdit">

        <div class="grid-item__label form-label">
          {{label}}
          <i class="fa-gears fa"></i>
        </div>
        <div class="grid-item__value form-value">
             <div data-testid="campaign.elSettings.externalCode" class="text-break"
                   [innerText]="campaignItem?.elSettings?.externalCode || ''"
             >
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="isEdit">

        <form-container>

          <div [form-item-row] = "label"
               form-item-row-input="true"
          >
            <div class=" m-n m-b w-md">
              <input
                type="text"
                class="form-control  inline  m-r-sm form-control-edittable"
                [(ngModel)]="campaignItem.elSettings.externalCode"
                data-testid="campaign.elSettings.externalCode"
                [name]="name"
                [required]="false"
                [maxLength]="100"
              />
            </div>

          </div>

        </form-container>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        &:empty {
          display: none!important;
        }
      }
    `
  ],
  providers: [
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CampaignExternalCodeComponent implements OnInit, OnChanges {

  @Input() campaignItem: CampaignProtocol;
  @Input() isEdit = true;

  @Input() label = 'Код внешней системы';
  @Input() name = 'CampaignItem_externalCode'
  @Input() isSuspend;

  public isExternalCodeCampaign = false;
  @Output() enabled = new EventEmitter<boolean>();
  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if (!!changes['campaignItem']?.currentValue) {
      this.campaignItem.elSettings = this.campaignItem.elSettings || {};
    }

    this.checkExternalCode();
  }

  checkExternalCode() {
    this.isExternalCodeCampaign = checkExternalCode(this.campaignItem);
    this.enabled.emit(this.isExternalCodeCampaign);
  }



}
