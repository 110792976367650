
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {GoodsSelectorService, legacyDirectiveParams} from "../../goods-tree/service/goods-selector.service";
import {LocationsDialogService} from "../../locations-dialog/service/locations-dialog.service";
import {CampaignDialogService} from "../../campaign-dialog/service/campaign-dialog.service";
import {NgModel} from "@angular/forms";
import {DateUtils} from "../../../class/utils/date.utils";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'filter-price-period',
  templateUrl: './filter-price-period.component.html',
  providers: [
  ]
})
export class FilterPricePeriodComponent implements OnInit, OnChanges {

  @Input() public modelFrom;
  @Output() public modelFromChange = new EventEmitter();


  @Input() public modelTo;
  @Output() public modelToChange = new EventEmitter();

  modelChangeSubject = new Subject();

  @Output() public onChange = new EventEmitter();

  @Input() public disabled;

  @Input() public isPending = false;

  @Input() public requiredFrom = false;
  @Input() public requiredTo = false;

  @Input() public label = 'Период времени';

  @ViewChild('filterFormModel') public filterFormModel: NgModel;
  @ViewChild('filterToModel')   public filterToModel: NgModel;

  private _filterFrom: any;
  private _filterTo: any;

  private isLoading = true;

  @Input()  get filterFrom() {
    return this._filterFrom;
  }

  set filterFrom(value) {
    this._filterFrom = value;
    this.emitModel();
  }

  @Input() get filterTo() {
    return this._filterTo;
  }

  set filterTo(value) {
    this._filterTo = value;
    this.emitModel();
  }

  constructor(
  ) {
  }

  ngOnInit() {
    this.modelChangeSubject
      .pipe(
        debounceTime(500))
      .subscribe(() => {
        this.modelToChange.emit( this.filterToModel.invalid ? undefined : this.filterTo);
        this.modelFromChange.emit(this.filterFormModel.invalid ? undefined : this.filterFrom);
        this.onChange.emit({
          from:this.filterFormModel.invalid ? undefined : this.filterFrom,
          to: this.filterToModel.invalid ? undefined : this.filterTo
        });
      })
  }


  ngOnChanges(changes) {
    if ( changes['modelTo'] && this.modelTo ) {
      this._filterTo = this.modelTo
    }

    if ( changes['modelFrom'] && this.modelFrom ) {
      this._filterFrom = this.modelFrom
    }

  }

  private emitModel() {
    this.modelChangeSubject.next();
  }

  public updateValidators(type) {
/*
    setTimeout(() => {
      if (type === 'to' && this.filterFormModel.invalid) {
        this.filterFrom = undefined;
      }

      if (type === 'from' && this.filterToModel.invalid) {
        this.filterTo = undefined;
      }
    })*/
  }

}
