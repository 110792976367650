import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {GoodgroupService} from "../../../../../shared/service/api/Goodgroup/Goodgroup.service";
import {GoodGroup} from "../../../../../api/GoodGroupApi/models/good-group";

@Component({
  selector: 'goodgroup-index-page',
  templateUrl: './goodgroup-index-page.component.html',
  providers:[
    GoodgroupService
  ]
})
export class GoodgroupIndexPageComponent implements OnInit{

  @Input() partnerId;

  searchString: string;
  listGoodGroups: Array<GoodGroup> =[]
  rolesList = {};

  public selectedPartner;

  constructor(
    public goodgroupService: GoodgroupService
  ) {
  }

  ngOnInit() {

    this.initSelectedPartner();
    this.goodgroupService.getListParams.reset();
    this.getList(this.goodgroupService.getListParams.params);

  }

  getList(params) {
    if (!this.selectedPartner)
      return ;

    params = Object.assign({}, params, {
      partnerId: this.selectedPartner.id
    });

    this.goodgroupService.getList$(params)
      .subscribe( result => {
        this.listGoodGroups = this.listGoodGroups.concat(result);
      });
  }


  onScroll() {
    this.goodgroupService.getListParams.next();
    this.getList(this.goodgroupService.getListParams.params)
  }

  reset() {
    this.goodgroupService.getListParams.reset();
    this.listGoodGroups=[];
    this.getList(this.goodgroupService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.goodgroupService.getListParams.search(this.searchString);
    this.reset();
  }

  changeSelectedPartner( inPartner ) {
    this.selectedPartner = inPartner;
    this.reset();
  }

  initSelectedPartner() {

    if ( !!this.partnerId && this.partnerId !== 0 ) {
      this.selectedPartner = {
        id: this.partnerId
      }
    }

  }

}
