
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import Highcharts from "highcharts/highcharts.src";
import {ComponentService} from "../../../../../shared/service/api/Component/Component.service";

@Component({
  selector: 'segment-rfm-custom',
  templateUrl: './segment-rfm-custom.component.html',
  styleUrls: ['./segment-rfm-custom.component.scss'],
  providers:[
    ComponentService
  ]
})
export class SegmentRfmCustomComponent implements OnInit, OnChanges{

  @Output() modelChange = new EventEmitter()

  public rfmList : any = false;
  public monetarySelected = 0;

  public clickEmptyState = false;

  public rfmSegmentsList = [];
  public currentRfmSegment = -1;
  public rfmMatrix = {};

  public countColors = 1;

  public tableColors = ["91c741", "0a99f1", "ff4001", "13b28a", "0149a2", "ffcc03", "ff00ff", "c1272d", "cde82b", "009245",
    "f5e82e", "7925cd", "0071bc", "9e005d", "754c24", "a67c52", "b3b3b3", "998675", "0000ff", "2e3192"];


  constructor(
    public componentService: ComponentService,
  ) { }

  ngOnInit() {
    this.getRfmList();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  getRfmList() {

    this.componentService.get$({
      componentType: 'Behavior',
      what: 'rfmdetails'
    }).subscribe(
      result => {
        this.rfmList = result;
      }
    )

  }

  getRfmSegmetColor(recency, frequency, monetary) {

    var number = this.rfmMatrix[[recency, frequency, monetary].join(',')];

    if (angular.isUndefined(number) || angular.isUndefined(this.rfmSegmentsList[number.segment])) {
      return 'rgba(255,255,255,0)';
    }

    return this.rfmSegmentsList[number.segment].$color;

  };

  addRuleToRfmSegment(recency, frequency, monetary, clientsCount) {

    if ( this.currentRfmSegment == -1 ) {
      return;
    }

    var matrixId = [recency, frequency, monetary].join(',');

    this.clickEmptyState = angular.isObject(this.rfmMatrix[matrixId]) && this.rfmMatrix[matrixId].segment === this.currentRfmSegment;
    if ( this.clickEmptyState ) {
      delete this.rfmMatrix[matrixId];

    } else {

      this.rfmMatrix[matrixId] = {
        segment: this.currentRfmSegment,
        countClients: clientsCount
      };

    }

    this.updateClientsCount();

  };

  moveRuleToRfmSegment(recency, frequency, monetary, clientsCount, $event) {

    if ( ($event !== false && !this.isLeftButtonPressed($event) ) ||
      this.currentRfmSegment == -1 ) {
      return;
    }

    var matrixId = [recency, frequency, monetary].join(',');

    if ( this.clickEmptyState ) {
      delete this.rfmMatrix[matrixId];

    } else {

      this.rfmMatrix[matrixId] = {
        segment: this.currentRfmSegment,
        countClients: clientsCount
      };

    }

    this.updateClientsCount();


  };

  updateClientsCount() {

    if ( Array.isArray(this.rfmSegmentsList) && this.rfmSegmentsList.length === 0)
      return;

    this.rfmSegmentsList.forEach( (item) => {
      item.countClients = 0;
    });

    Object.keys(this.rfmMatrix).forEach( key => {

      let item = this.rfmMatrix[key];

      if ( angular.isObject(this.rfmSegmentsList[item.segment]) ) {
        this.rfmSegmentsList[item.segment].countClients += item.countClients;
      }

    });

    this.updateModel();
  };


  isLeftButtonPressed( $event ) {

    if ( !angular.isUndefined( $event ) && $event !== false) {
      if ($event.buttons !== undefined) {

        if ($event.buttons === 1) {
          return true;
        }

      } else if ( !angular.isUndefined( $event.which ) && $event.which === 1) {
        return true;
      }

    }

    return false;
  };


  createNewRfmSegment() {

    this.rfmSegmentsList.push(
      {
        "name": "Сегмент " + this.countColors,
        "description": "",
        "segmentType": "rfmcustom",
        "countClients": 0,
        "edited": false,
        "rules": [],
        $color: this.getColor(this.countColors)
      }
    );
    this.countColors++;

    this.currentRfmSegment = this.rfmSegmentsList.length - 1;

    this.updateModel();
  };

  deleteSegment(index) {
    for (var i in this.rfmMatrix) {

      switch (true) {
        case this.rfmMatrix[i].segment > index:
          this.rfmMatrix[i].segment -= 1;
          break;
        case this.rfmMatrix[i].segment == index:
          delete this.rfmMatrix[i];
          break;
      }

    }

    this.rfmSegmentsList.splice(index, 1);
    if (this.rfmSegmentsList.length) {
      this.currentRfmSegment = 0;
    } else {
      this.currentRfmSegment = -1;
    }

    this.updateModel();
  };

  getColor(i) {

    return '#' + ( (i > this.tableColors.length - 1) ? ((1 << 24) * Math.random() | 0).toString(16) : this.tableColors[i] );

  };

  updateModel() {

      let result = this.rfmSegmentsList.map( i => {
        let newVal = Object.assign({}, i);

        delete newVal.$color;
        newVal.rules = [];

        return newVal;
      } );

      let options;

      for (var i in this.rfmMatrix) {

        if ( typeof result[this.rfmMatrix[i].segment] !== "undefined" ) {

          options = i.split(',');
          result[this.rfmMatrix[i].segment].rules.push({
            "recency": parseInt(options[0]),
            "frequency": parseInt(options[1]),
            "monetary": parseInt(options[2])
          });

        }

      }

      this.modelChange
          .emit(result)

  }

}

