/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CreditAccountConfiguration, CreditAccountConfigurationInterface } from './credit-account-configuration';

import { CreditAccountService } from './services/credit-account.service';

/**
 * Provider for all CreditAccount services, plus CreditAccountConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CreditAccountConfiguration,
    CreditAccountService
  ],
})
export class CreditAccountModule {
  static forRoot(customParams: CreditAccountConfigurationInterface): ModuleWithProviders<CreditAccountModule> {
    return {
      ngModule: CreditAccountModule,
      providers: [
        {
          provide: CreditAccountConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
