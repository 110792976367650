<div class="d-flex"
*ngIf="rfmList">

  <div class="col-sm-12 col-md-6" >

    <table class="rfm-segments-block" width="100%">
      <tr>
        <td width="5%" class="rfm-freq-row">&nbsp;</td>
        <td width="5%">&nbsp;</td>
        <td width="90%" colspan="5">Monetary</td>
      </tr>
      <tr>
        <td width="5%" class="rfm-freq-row">&nbsp;</td>
        <td width="5%">&nbsp;</td>
        <td width="18%"
            *ngFor="let monetary of rfmList.monetary; let index = index">
          <div class="radio">
            <label class="i-checks">
              <input type="radio"
                     [(ngModel)]="monetarySelected"
                     [value]="index"
                     [name]="'rfmCustoMm' + (index+1)"
                     id="rfmCustoMm{{index+1}}">
              <i></i>
              {{ index+1 }}
            </label>
          </div>
        </td>
      </tr>
    </table>

    <div class="rfm-block"
         *ngFor="let monetary of rfmList.monetary; let index = index"
         id="rfm{{ index+1 }}"
    >
      <ng-container
        *ngIf="monetarySelected === index"
      >
        <table class="rfm-segments-block rfm-segments-colors w-full">
          <tr *ngFor="let frequency of monetary.frequency; let subIndex = index">

            <td width="5%" rowspan="5" class="rfm-freq-row"
                *ngIf="subIndex==0"
            >
              <div class="vertical-text">Frequency</div>
            </td>

            <td width="5%"
                [innerText]="monetary.frequency.length - subIndex"></td>

            <td width="18%"
                *ngFor="let recency of frequency.recency"

                [ngStyle]=" { 'background-color': getRfmSegmetColor(recency.level, frequency.level, monetary.level) } "
                class="rfm-segment"
                (mousedown)="addRuleToRfmSegment(recency.level, frequency.level, monetary.level, recency.value )"
                (mouseenter)="moveRuleToRfmSegment(recency.level, frequency.level, monetary.level, recency.value, $event)">
              {{ recency.value }}
            </td>
          </tr>

          <tr>
            <td width="5%" class="rfm-freq-row">&nbsp;</td>
            <td width="5%">&nbsp;</td>
            <td width="18%">1</td>
            <td width="18%">2</td>
            <td width="18%">3</td>
            <td width="18%">4</td>
            <td width="18%">5</td>
          </tr>
          <tr>
            <td width="5%" class="rfm-freq-row">&nbsp;</td>
            <td width="5%">&nbsp;</td>
            <td colspan="5">Recency
            </td>
          </tr>
        </table>
      </ng-container>

    </div>

  </div>

  <div class="col-sm-12 col-md-6">
      <div class="rfm-segment-selection">
        Выбор сегмента для указания:
        <div class="card m-t">
          <ul class="" id="rfmu">

            <li *ngFor="let segment of rfmSegmentsList; let index = index"
                id="rfm-l{{index+1}}"
                [ngClass]="{'active': currentRfmSegment==index }"
                (click)="currentRfmSegment = index">
              <i class="fa fa-pie-chart m-r-sm "
                 [ngStyle]="{ 'color': segment.$color } "></i>
              <span *ngIf="segment.edited">
                <input type="text" [(ngModel)]="segment.name"
                       (keydown)="segment.edited = ($event.keyCode == 13) ? false : true"
                       (blur)="segment.edited = false"/>
              </span>
              <span *ngIf="!segment.edited" class="slide"
                    (dblclick)="segment.edited=!segment.edited">{{ segment.name }}</span>


              <button class="btn btn-warning btn-xs m-l-sm"
                      (click)="segment.edited=!segment.edited">
                  <span class="fa text-mutted"
                        [ngClass]="{ 'fa-check':segment.edited, 'fa-pencil':!segment.edited }"></span>
              </button>

              <div class="pull-right m-t-xxs ">
                <button class="btn btn-danger btn-xs  m-t-xxs m-l-sm"
                        (click)="deleteSegment(index)">
                  <span class="fa fa-times text-mutted"></span>
                </button>
              </div>

              <span class="pull-right badge m-t-xs "
                    [ngStyle]="{ 'background-color': segment.$color } "> {{ segment.countClients }} </span>
            </li>
          </ul>

          <button type="button" class="btn btn-addon btn-sm btn-primary m-t-sm m-b m-l"
                  (click)="createNewRfmSegment()">
            <i class="fa fa-plus">
            </i>
            Новый сегмент
          </button>

        </div>
      </div>
    </div>

</div>


<div class="d-flex justify-content-center align-items-center "
     *ngIf="!rfmList"
>
  <div class="text-warning m-t-lg">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
