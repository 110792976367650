/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CouponRuleApiConfiguration, CouponRuleApiConfigurationInterface } from './coupon-rule-api-configuration';

import { CouponRuleApiService } from './services/coupon-rule-api.service';

/**
 * Provider for all CouponRuleApi services, plus CouponRuleApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CouponRuleApiConfiguration,
    CouponRuleApiService
  ],
})
export class CouponRuleApiModule {
  static forRoot(customParams: CouponRuleApiConfigurationInterface): ModuleWithProviders<CouponRuleApiModule> {
    return {
      ngModule: CouponRuleApiModule,
      providers: [
        {
          provide: CouponRuleApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
