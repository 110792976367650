'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.locationsGroup.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<locations-group-view-page></locations-group-view-page>', true)
        .breadcrumbs(  [ "Справочники", "Локации" ] );

    }]);
