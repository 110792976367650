/* tslint:disable */
type CampaignState =
  'draft' |
  'suspended' |
  'unsuspend' |
  'planned' |
  'started' |
  'active' |
  'completed' |
  'archive';
module CampaignState {
  export const DRAFT: CampaignState = 'draft';
  export const SUSPENDED: CampaignState = 'suspended';
  export const UNSUSPEND: CampaignState = 'unsuspend';
  export const PLANNED: CampaignState = 'planned';
  export const STARTED: CampaignState = 'started';
  export const ACTIVE: CampaignState = 'active';
  export const COMPLETED: CampaignState = 'completed';
  export const ARCHIVE: CampaignState = 'archive';
  export function values(): CampaignState[] {
    return [
      DRAFT,
      SUSPENDED,
      UNSUSPEND,
      PLANNED,
      STARTED,
      ACTIVE,
      COMPLETED,
      ARCHIVE
    ];
  }
}

export { CampaignState }