/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PartnerApiConfiguration as __Configuration } from '../partner-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Partner } from '../models/partner';
@Injectable({
  providedIn: 'root',
})
class PartnerApiService extends __BaseService {
  static readonly createPath = '/partner';
  static readonly queryPath = '/partner';
  static readonly getPath = '/partner/{id}';
  static readonly updatePath = '/partner/{id}';
  static readonly deletePath = '/partner/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param partner Описание партнера
   * @return Список партнеров
   */
  createResponse(partner: Partner): __Observable<__StrictHttpResponse<Partner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = partner;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/partner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Partner>;
      })
    );
  }
  /**
   * @param partner Описание партнера
   * @return Список партнеров
   */
  create(partner: Partner): __Observable<Partner> {
    return this.createResponse(partner).pipe(
      __map(_r => _r.body as Partner)
    );
  }

  /**
   * Получить список партнеров
   * @return Список партнеров
   */
  queryResponse(): __Observable<__StrictHttpResponse<Array<Partner>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/partner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Partner>>;
      })
    );
  }
  /**
   * Получить список партнеров
   * @return Список партнеров
   */
  query(): __Observable<Array<Partner>> {
    return this.queryResponse().pipe(
      __map(_r => _r.body as Array<Partner>)
    );
  }

  /**
   * Получить партнера по ID
   * @param id ID партнера
   * @return Параметры партнера
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<Partner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/partner/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Partner>;
      })
    );
  }
  /**
   * Получить партнера по ID
   * @param id ID партнера
   * @return Параметры партнера
   */
  get(id: number): __Observable<Partner> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as Partner)
    );
  }

  /**
   * Обновить партнера по ID
   * @param params The `PartnerApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание партнера
   *
   * - `id`: ID партнера
   *
   * @return Параметры партнера
   */
  updateResponse(params: PartnerApiService.UpdateParams): __Observable<__StrictHttpResponse<Partner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.partner;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/partner/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Partner>;
      })
    );
  }
  /**
   * Обновить партнера по ID
   * @param params The `PartnerApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание партнера
   *
   * - `id`: ID партнера
   *
   * @return Параметры партнера
   */
  update(params: PartnerApiService.UpdateParams): __Observable<Partner> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as Partner)
    );
  }

  /**
   * Удалить партнера по ID
   * @param id ID партнера
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/partner/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить партнера по ID
   * @param id ID партнера
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PartnerApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Описание партнера
     */
    partner: Partner;

    /**
     * ID партнера
     */
    id: number;
  }
}

export { PartnerApiService }
