<div class="bg-light b-b padder wrapper-sm">

  <filter-date-period
    class="m-r-sm"
    [modelFrom]="newsletterService.taskQueryParams.getFilterValue('fromDate')"
    [modelTo]="newsletterService.taskQueryParams.getFilterValue('tillDate')"
    (modelFromChange)="newsletterService.taskQueryParams.setFilterValue('fromDate',$event);"
    (modelToChange)="newsletterService.taskQueryParams.setFilterValue('tillDate',$event);"
    (onChange)="reset()"
    [maxPeriodMonth]="6"
    [requiredFrom]="true"
    [requiredTo]="true"
  ></filter-date-period>

  <filter-checkbox

    class="m-r-sm"
    [name]         = "'Канал'"
    [canSelectAll] = "false"
    [showClearButton] = "false"
    [inputValues]  = "ChannelTypeNames"
    [selectedValues] = "newsletterService.taskQueryParams.getFilterValue('channels')"
    (selectedValuesChange) = "newsletterService.taskQueryParams.setFilterValue(
                    'channels',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <filter-checkbox

    class="m-r-sm"
    [name]         = "'Статус'"
    [canSelectAll] = "true"
    [showClearButton] = "false"
    [inputValues]  = "NewsletterTaskExternalStateNames"
    [selectedValues] = "newsletterService.taskQueryParams.getFilterValue('state')"
    (selectedValuesChange) = "newsletterService.taskQueryParams.setFilterValue(
                    'state',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <filter-campaign

    class="m-r-sm"
    [model]="newsletterService.taskQueryParams.getFilterValue('campaignIds')"
    (modelChange)="newsletterService.taskQueryParams.setFilterValue(
                    'campaignIds',
                    $event
                );
            reset();"

    [filterIDs]="!!totalSettings && totalSettings?.existsCampaignIds"
  ></filter-campaign>

  <filter-trigger

    class="m-r-sm"
    [model]="newsletterService.taskQueryParams.getFilterValue('triggerIds')"
    (modelChange)="newsletterService.taskQueryParams.setFilterValue(
                    'triggerIds',
                    $event
                );
            reset();"
    [filterIDs]="!!totalSettings && totalSettings?.existsTriggerIds"
  ></filter-trigger>

</div>

<div class="wrapper">
  <search-input
    [model]="searchString"
    (modelChange)="onSearchChange($event)"
    placeholder="Введите адресата"
  ></search-input>
</div>

<div class="b-t padder wrapper-xs"
     *ngIf="!!totalSettings"
>

    <div class=" d-inline-block  m-r-md">
      <span class="text-muted">
        Сообщений всего:
      </span>
      <span class="text-info-dk"
            [innerText]="totalSettings.totalCount "
            data-testid="totalSettings.totalCount"></span>
    </div>

    <div class=" d-inline-block  m-r-md">
      <span class="text-muted">
        <i class="fa fa-clock-o m-r-xxs "></i>
        В обработке:
      </span>
      <span class="text-warning"
            [innerText]="totalSettings.inProgress "
            data-testid="totalSettings.inProgress"></span>
    </div>

    <div class=" d-inline-block  m-r-md">
      <span class="text-muted">
        <i class="fa fa-envelope-o m-r-xxs "></i>
        Доставлено:
       </span>
      <span class="text-success"
            [innerText]="totalSettings.delivered "
            data-testid="totalSettings.delivered"></span>
    </div>
    <div class=" d-inline-block  m-r-md">
        <span class="text-muted">
          <i class="fa fa-envelope-open-o m-r-xxs "></i>
          Прочитано:
         </span>
      <span class="text-success"
            [innerText]="totalSettings.read "
            data-testid="totalSettings.read"></span>
    </div>

    <div class=" d-inline-block  m-r-md">
      <span class="text-muted">
        <i class="fa fa-times-circle m-r-xxs "></i>
        Не доставлено:
       </span>
      <span class="text-danger-dker"
            [innerText]="totalSettings.error "
            data-testid="totalSettings.error"></span>
    </div>

    <div class=" d-inline-block  pull-right">
     <span class="text-muted">
         <i class="fa fa-money m-r-xxs "></i>
        Потрачено:
     </span>
      <span class="text-success"
            [innerText]="totalSettings.totalCost | loyaCurrency "
            data-testid="totalSettings.totalCost"></span>
      <i class="fa le text-success m-l-xs" ngCurrencyLoya> </i>
    </div>

</div>


<div infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
>

    <table class="table m-b-none">
      <thead>
        <tr>

          <th>Адресат</th>
          <th>Клиент</th>
          <th class="text-center">Канал</th>
          <th class="text-center">Сообщение</th>
          <th class="text-center">Статус</th>
          <th class="text-right">Цена</th>
          <th class="text-right">
            <sort-view
              column="date"
              [params]="newsletterService.taskQueryParams"
              (onChange)="reset(true)"
            >
              Создано
            </sort-view>
          </th>
          <th class="text-right">Обновлено</th>

        </tr>

      </thead>

      <tbody>
        <tr *ngFor=" let item of newsletterList"
            data-testid="item of newsletterList"
        >
          <td class="align-middle">
            <span class="text-ellipsis">
              <i class="fa"
                 [ngClass]="getClassStatusTransaction(item.externalState)"
              ></i>

              <span class="m-l-xs"  data-testid="item.recipientContact">
                <text-highlight
                  [text]="item.recipientContact"
                  [search]="searchString"
                ></text-highlight>
              </span>

            </span>
          </td>
          <td>
            <a href=""
               [innerText]="item.recipientName"
               data-testid="item.recipientName"
               [uiSref]="'frontend.crm.clients.view({id:' + item.clientId+ '})'"></a>
          </td>
          <td class="text-center">
                   <span
                     [innerText]="item.channel" data-testid="item.channel"></span>
          </td>
          <td class="text-left">
                   <span
                     [innerText]="item.body" data-testid="item.body"></span>
          </td>
          <td class="text-center">

              <span
                [innerText]="getStateNames(item)"
                data-testid="item.stateNames"></span>

              <div class="text-muted text-sm"
                   *ngIf="!!item.errorMsg"
                [innerText]="item.errorMsg"
                data-testid="item.errorMsg"
              ></div>

          </td>
          <td class="text-right">
                  <span
                    [innerText]="item.cost | loyaCurrency"
                    data-testid="item.cost"
                  ></span>

            <span *ngIf="item.count>1"
                  [innerText]="'( x'+item.count+' )'"></span>
          </td>
          <td class="text-right">
                   <span
                     class="text-ellipsis"
                     [innerText]="item.createdAt | loyaDateTime" data-testid="item.createdAt"></span>
          </td>
          <td class="text-right ">
                   <span
                     class="text-ellipsis"
                     [innerText]="item.updatedAt | loyaDateTime" data-testid="item.updatedAt"></span>
          </td>
        </tr>
      </tbody>

    </table>

</div>

<ng-container
  *ngIf="!newsletterService.taskQueryParams.getFilterValue('fromDate') || !newsletterService.taskQueryParams.getFilterValue('tillDate')"
>

  <div class="text-center padder m-t-lg">
    <h5 class="text-muted">Задайте временной период</h5>
  </div>

</ng-container>


<div *ngIf="loader.isLoading('newsletterList')"
     class="text-center">
  <i class="fa fa-spinner fa-spin text-warning fa-3x m-t m-b"></i>
</div>

