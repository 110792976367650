'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.bonusRestrict.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template( '<bonus-restrict-index-page ></bonus-restrict-index-page>', true )
        .breadcrumbs([ "Справочники", "Ограничения вознаграждений" ]);

    }]);
