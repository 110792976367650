import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import "./dynamic-reports-page.legacy"

@Component({
  selector: 'reports-dynamic-page',
  templateUrl: './dynamic-reports-page.component.html',
  providers:[
  ]
})
export class DynamicReportsPageComponent implements OnInit{

  @Input() id;

  public searchReport;
  public reportList: any = []

  public selectedReport;

  constructor(
  ) {
  }

  ngOnInit() {
    this.initReportList();
    this.selectById();
  }

  initReportList() {

    this.reportList = [
      {
        id: 'discountByCompany',
        name: 'Продажи товаров со скидками'
      },
    ]

  }

  selectById() {

    if (!this.reportList?.length)
      return;

    if ( !this.id ) {
      this.selectedReport = this.reportList[0];
      return
    }

    this.selectedReport = this.reportList?.find( i => i.id === this.id);

  }


  onSelectReport(item) {
    this.selectedReport = item;
  }

  filterByName = (item) => {

    if (!this.searchReport || !item)
      return true;

    return item?.name?.includes(this.searchReport);

  }

}
