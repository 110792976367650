<div class="card">

  <div class="card-header ">
    {{title}}
  </div>

  <div class="card-body">

    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="config"
      [(update)]="updateFlag"
      class="d-block w-100"
    ></highcharts-chart>

  </div>

</div>
