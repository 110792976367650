
export const CARD_STATES = {
  'nonactive': "nonactive",
  'active'   : "active",
  'blocked'  : "blocked",
  'archive'  : "archive"
}

export const CARD_STATE_NAMES = {

  [CARD_STATES.nonactive]: "Исходная",
  [CARD_STATES.active]: "Выдана",
  [CARD_STATES.blocked]: "Заблокирована",
  [CARD_STATES.archive]  : "Архив",

}

