
import { Component } from "@angular/core";

@Component({
  selector: 'favourite-view',
  templateUrl: "./favourite-view.component.html",
  styleUrls: ['./favourite-view.component.scss' ],
  providers: [
  ]
})
 export class FavouriteViewComponent  {

    constructor(
    )
    {
    }



}
