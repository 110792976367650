import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {StatesChangerService} from "../../../../../../../shared/service/statesChanger/statesChanger.service";
import {LocationGroupService} from "../../../../../../../shared/service/api/LocationGroup/LocationGroup.service";
import {LocationGroup} from "../../../../../../../api/LocationGroupApi/models/location-group";
import {getNg1Service} from "../../../../../../../shared/class/utils/angularjs.utils";
import {ConfirmActionService} from "../../../../../../../shared/service/confirmAction/confirmAction.service";
import {Partner} from "../../../../../../../api/PartnerApi/models/partner";
import {LocationGroupLink} from "../../../../../../../api/LocationGroupLinkApi/models/location-group-link";
import {
  LocationGroupLinkService
} from "../../../../../../../shared/service/api/LocationGroupLink/LocationGroupLink.service";
import {LocationsService} from "../../../../../../../shared/service/api/Locations/Locations.service";
import {LoaderHandlerClass} from "../../../../../../../shared/class/loader-handler.class";
import {
  LocationsDialogService
} from "../../../../../../../shared/component/locations-dialog/service/locations-dialog.service";



@Component({
  selector: 'location-group-index-view-page',
  templateUrl: './location-group-index-view-page.component.html',
  styleUrls: ['./location-group-index-view-page.component.scss'],
  providers: [
    StatesChangerService,
    ConfirmActionService,
    LocationGroupService
  ]
})
export class LocationGroupIndexViewPageComponent implements OnInit, OnChanges {

  @Input() public locationGroupId: number;
  @Input() public selectedPartner: Partner;

  @Input() public createEvent: number;

  @Output() public onDelete = new EventEmitter()
  @Output() public onCreate = new EventEmitter()
  @Output() public onUpdate = new EventEmitter()

  @ViewChild('locationGroupForm') locationGroupForm;

  public currentLocationGroup: LocationGroup;
  public uiRouterState;

  public locationsList;
  public loader = new LoaderHandlerClass();

  public locationsListForAdd = [];
  public locationsListForDelete = [];

  public searchString;

  constructor(
    public  statesChangerService:StatesChangerService,
    public locationGroupService: LocationGroupService,
    public locationGroupLinkService: LocationGroupLinkService,
    public locationsService: LocationsService,
    public confirmActionService: ConfirmActionService,
    public locationsDialogService: LocationsDialogService,
  ) { }

  ngOnInit() {
    this.uiRouterState = getNg1Service('$state');
    this.statesChangerService.onChangeState( () => {
      this.locationsListForAdd = [];
      this.locationsListForDelete = [];
    });
    this.bindButtons();

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDeleteInner.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {

    if (  changes['locationGroupId'] && !changes['locationGroupId']?.currentValue ) {
      this.currentLocationGroup = undefined;
    }

    if ( ( changes['locationGroupId'] && changes['locationGroupId']?.currentValue ) || (changes['createEvent'] && !changes['createEvent']?.firstChange ) ) {

      this.locationGroupById(  (changes['createEvent'] && !changes['createEvent']?.firstChange ) ? undefined : changes['locationGroupId'].currentValue )
        .then( result => {

          this.statesChangerService.state = !result?.id ?
            'edit' :
            'view';

          result.partnerId = this.selectedPartner?.id;

          this.locationsService.getListParams.setFilterValue('locationGroupId', result?.id );
          this.resetLocationList();
        })
      ;
    }

  }

  onSave() {

    let isCreate = !this.currentLocationGroup?.id;

    this.locationGroupService
      .createOrUpdate$(this.currentLocationGroup)
      .subscribe(result=> {
        this.currentLocationGroup = result;
        this.locationGroupLinkService
          .updateGroupForMerchants$(
            this.currentLocationGroup?.id,
            this.locationsListForAdd.map(i => i?.id),
            this.locationsListForDelete.map( i => i?.id)
          ).then( () => {
            this.locationsListForAdd = [];
            this.locationsListForDelete = [];
            this.resetLocationList();

            if (isCreate)
              this.onCreate.emit( Object.assign({},  this.currentLocationGroup ))
            else
              this.onUpdate.emit( Object.assign({}, this.currentLocationGroup ))

            this.statesChangerService.changeState('view')
        })

      });

  }

  onCancel() {
    if (!this.locationGroupId) {
      this.clear();
      return
    } else {
      this.locationGroupById( this.locationGroupId )
        .then(() => {
          this.statesChangerService.changeState('view')
        })
    }
  }

  onDeleteInner() {

    if (!this.locationGroupId)
      return;

    this.locationGroupService
      .delete$(this.currentLocationGroup.id)
      .subscribe(() => {
        this.onDelete.emit( Object.assign({}, this.currentLocationGroup) );
        this.clear();
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  clear(){
    this.locationsListForAdd = [];
    this.locationsListForDelete = [];
    this.currentLocationGroup = undefined;
  }

  locationGroupById(id) {
    return this.locationGroupService
      .getOrCreate$(id)
      .pipe(
        this.loader.waitLoading('currentLocationGroup')
      )
      .toPromise()
      .then( result => this.currentLocationGroup = result );
  }

  onSearchChange(value) {
    this.searchString = value;
    this.locationsService.getListParams.search(this.searchString);
    this.resetLocationList();
  }

  addLocationToGroup() {
    this.locationsDialogService.open({
      canCheckAll: false,
      forPartner: this.selectedPartner.id,
      resultDirective: () => {}
    }).then( result => {

      this.locationsListForAdd = this.locationsListForAdd || [];

      result?.locations?.forEach( r => {

        if ( !this.locationsListForAdd.some( i => i?.id === r?.id) ) {
          this.locationsListForAdd.push(r);
        }

      })


    })
  }

  getLocationList() {

    if (!this.currentLocationGroup?.id) {
      this.locationsList = [];
      return;
    }

    this.locationsService.getList$(
      this.locationsService.getListParams.params
    ).
      pipe(
        this.loader.waitLoading('locationList')
      )
      .subscribe( result => {
        this.locationsList = this.locationsList.concat(result);
      })

  }

  onScrollLocationList() {
    this.locationsService.getListParams.next();
    this.getLocationList();
  }

  resetLocationList() {
    this.locationsList = [];
    this.locationsService.getListParams.reset();
    this.getLocationList();
  }

  deleteLocation(locationItem){

    this.locationsListForAdd = this.locationsListForAdd.filter( i => i?.id !== locationItem?.id)

    if (!this.locationsListForDelete.some( i => i?.id === locationItem?.id)) {
      this.locationsListForDelete.push(locationItem);
    }

  }

  filterDeletedLocations = (locationItem) => {
    return !(
      Array.isArray(this.locationsListForDelete) &&
      this.locationsListForDelete?.some( i => i?.id === locationItem?.id)
    )
  }

}
