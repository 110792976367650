//import { ModalImageUploader } from "./modal";
import {API_UPLOAD_IMAGE} from "../../settings";
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {getNg1Service} from "../../../../class/utils/angularjs.utils";
import {ImageCarouselUploaderService} from "../../service/image-carousel-uploader.service";

@Component({
  selector: 'image-uploader',
  templateUrl: './imageUploader.component.html',
  providers: [
    ImageCarouselUploaderService
  ]
})
export class ImageUploaderComponent implements OnInit {

    // Передаваемые параметры в компонент
    @Input()  public options      : any;
    @Output() public onAdd        = new EventEmitter();

    private toaster  : any;

    constructor( private ngbModal : NgbModal,
                 private el: ElementRef,
                 public imageCarouselUploaderService: ImageCarouselUploaderService,
                 ) { }

    public ngOnInit() {
        this.toaster = getNg1Service('toaster');
   //     this.bindChange();
        this.bindDragEvents();
    }
/*
    public openModal(): Promise<any>{

        return this.ngbModal.open({
//            templateUrl : modalTemplate,
            controller  : ModalImageUploader,
            size        : 'lg'
        }).result;

    }*/
/*

    private bindChange() {
        let that = this;

        this.el.nativeElement.find('#imageUploaderInput')[0].addEventListener('change', function() {
            that.loadFiles(this.files);
        }, false);

    }
*/

    private bindGUID( GUID: string) {

        this.onAdd.emit(GUID);

    }

    private bindDragEvents() {

        let dropArea = this.el.nativeElement;
        ['dragenter', 'dragover', 'dragleave','drop'].forEach(eventName => {
            dropArea.addEventListener(eventName,  function preventDefaults (e:any) {
                e.preventDefault();
                e.stopPropagation();
            }, false)
        });

        dropArea.addEventListener('drop', ( e:any ) => {
                if (this.isLoading) return ;

                let dt    = e.dataTransfer;
                let files = dt.files;
                this.loadFiles(files);
            }
        , false);

    }

    public isLoading: boolean = false;
    private loadFiles( files: Array<any> ){

        let that = this;
        this.isLoading = true;
        let promises = [];

        for( var i = 0; i<files.length; i++ ) {

            if ( files[i].type.indexOf("image")!== 0 ){
                this.toaster.pop('warning','Ошибка загрузки файла', files[i].name + " не является изображением" );
                continue;
            }

            promises.push(
              this.imageCarouselUploaderService
                .saveFile$(files[i])
                .toPromise()
                .then(
                  result => that.bindGUID(result)
                )
            );

        }

        Promise.all(promises).then( () => this.isLoading = false, () => this.isLoading = false );

    }


}
