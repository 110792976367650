import {FieldTypes, IStaticField} from "../../../components/configModuleFields/ConfigModuleService";

export const MODULE_NAME = 'Common';


export const MODEL_FIELDS:Array<any> = [
    {
        id    : "surname",
        displayName : "Фамилия",
        custom: {
            dataType: FieldTypes.text,
            visible : true,
        },
        log : {
            path: '/surname'
        }
    },
    {
        id    : "name",
        displayName : "Имя",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
            required: true,
            ruleType: "name"
        },
        log : {
            path: '/name'
        }
    },
    {
        id    : "patronymic",
        displayName : "Отчество",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },
        log : {
            path: '/patronymic'
        }
    },
    {
        id    : "phone",
        displayName : "Мобильный телефон",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },

        log : {
            path: '/phone',
            exclude: '/correctPhone'
        }
    },
    {
      id    : "phoneConfirmation",
      displayName : "Номер телефона подтвержден",
      log : {
        path: '/phoneConfirmation'
      },
    },
    {
        id    : "email",
        displayName : "E-mail",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },
        log : {
            path: '/email'
        }
    },
    {
        id    : "preferChannelType",
        displayName : "Предпочтительно",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },
        options:[
            {
                "id": "email",
                "name": "E-Mail"
            },
            {
                "id": "viber",
                "name": "Viber"
            },
            {
                "id": "sms",
                "name": "SMS"
            },
            {
                "id": "check",
                "name": "ЧЕК"
            },
            {
                "id": "web",
                "name": "WEB"
            }
        ],
        log : {
            path: '/preferChannelType'
        }
    },
    {
        id    : "getEmail",
        displayName : "Получает рекламную Email рассылку",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },
        log : {
            path: '/receiveEmail'
        }
    },
    {
        id    : "getSms",
        displayName : "Получает рекламную SMS рассылку",
        custom: {
            dataType: FieldTypes.text,
            visible: true,
        },
        log : {
            path: '/receiveSms'
        }
    },

    {
        id    : "state",
        displayName : "Статус",
        log : {
            path: '/state'
        },
        options:[
            {
                "id": "archive",
                "name": "В архиве"
            },
            {
                "id": "autogeneration",
                "name": "Автогенерация"
            },
            {
                "id": "express",
                "name": "Экспресс регистрация"
            },
            {
                "id": "active",
                "name": "Активный клиент"
            },
            {
                "id": "fullProfile",
                "name": "Анкета заполнена"
            }
        ],
    },
];
