import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";
import {
  CampaignCategoryMatrix,
  DEFAULT_RULE_TYPE, RawMatrix
} from "./shared/class/CampaignCategoryMatrix";

import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {CampaignCategoryMatrixService} from "../../../../../shared/service/api/CampaignCategoryMatrix/CampaignCategoryMatrix.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {RuleNames} from "./shared/component/campaignCategoryRule/campaignCategoryRule-index-page.component";

const angular = <any>(<any>window).angular;

let resizeOptions;


type selectedPartner = {
  id: number,
  name: string
}

@Component({
  selector: 'campaigncategorylimitations-index-page',
  templateUrl: './campaigncategorylimitations-index-page.component.html',
  styleUrls: ['./campaigncategorylimitations-index-page.component.scss'],
  providers:[
    CampaignCategoryService,
    CampaignCategoryMatrixService,
    ConfirmActionService
  ]
})
export class CampaigncategorylimitationsIndexPageComponent implements OnInit, OnDestroy{

  private currentPartner : number;
  public  isEdit = false;
  public matrix: any;
  public campaignCategoryList: any = {};
  public campaignCategoryArray: any = {};

  public selectedPartner;
  public campaignCaterogyForAdd: any;

  constructor(
    private campaignCategoryService: CampaignCategoryService,
    protected campaignCategoryMatrixService : CampaignCategoryMatrixService,
    public confirmActionService: ConfirmActionService,
  ) {
  }

  ngOnInit() {
    this.getCampaignCategoryList();
    this.initHeigher();

  }

  public getCampaignCategoryList() {
    return this.campaignCategoryService.getList$({})
      .toPromise()
      .then( ( result : any ) => {
        this.campaignCategoryList = {};
        this.campaignCategoryArray = [];
        result.forEach( ( cc : any ) => {
          this.campaignCategoryList[cc.id] = {
            id        : cc.id,
            name      : cc.name,
            collision : cc.collision
          }
          this.campaignCategoryArray.push(this.campaignCategoryList[cc.id]);
        });
      })
  }

  public hasCollisions() {
    return !(!this.matrix || this.matrix.getGroupList().length === 0);
  }

  public cancelEdit() {
    this.isEdit = false;
    return this.getMatrix()
      .then( (result : any) => {
        this.matrix = new CampaignCategoryMatrix(result);
      });
  }

  public goEdit() {
    this.isEdit = true;
  }

  public partnerChange(newValue: selectedPartner) {
    if (typeof newValue === 'undefined') return;

    this.selectedPartner = newValue;
    this.currentPartner = newValue.id;

    return this.getMatrix()
      .then( (result : any) => {
        this.isEdit     = false;
        this.matrix =  new CampaignCategoryMatrix(result);
      });

  }

  public getMatrix() {

    return this.campaignCategoryMatrixService.getMatrix$( this.currentPartner)
      .toPromise()
      .catch( (result : any) => {

        if ( result.error && result.error.code === 404 ) {
          result?.stopPopupError();
          return <any>{
            groups : [],
            rules  : []
          }
        }
      });

  }

  public saveMatrix() {

    const data = this.matrix.getRawData();

    data.groups.map( (group : any) => Object.assign(group,{
      groupName          : this.getGroupName( group.groupId ),
      groupCollisionType : this.campaignCategoryList[group.groupId] ? this.campaignCategoryList[group.groupId].collision || DEFAULT_RULE_TYPE : DEFAULT_RULE_TYPE
    }));

    return this.campaignCategoryMatrixService.save$({partnerId: this.currentPartner, matrix: data} )
      .toPromise();

  }

  public removeMatrix() {

    return this.campaignCategoryMatrixService
      .delete$( this.currentPartner)
      .toPromise();

  }

  public getGroupName(groupId: number, shortName = false) {
    return this.campaignCategoryList[groupId] ?
      shortName ? this.shortName( this.campaignCategoryList[groupId].name ) : this.campaignCategoryList[groupId].name
      : `Удалённая группа (id:${groupId})`;
  }

  public getGroupCollisionType(groupId: number) {
    return this.campaignCategoryList[groupId] ? RuleNames[this.campaignCategoryList[groupId].collision] : RuleNames[DEFAULT_RULE_TYPE] ;
  }

  public addGroup(groupId: number) {
    // TODO имя группы запросить.
    return this.matrix.addGroup(groupId);
  }

  public scopeSave() {

    return this.saveMatrix()
      .then( (result ) => this.matrix.setRawData(result))
      .then( () => { this.isEdit = false });


  }

  public scopeRemove() {
    return this.removeMatrix()
      .then( () => this.getMatrix() )
      .then( (result : any) => {
        this.matrix = new CampaignCategoryMatrix(result  );
      });

  }

  public filterCategoryList( item: any) {

    if (!this.matrix) {
      return true;
    }

    return this.matrix.getGroupList().indexOf( item.id ) < 0;

  }

  private filtredCategoryList: any = {};
  public filterCategoryLisByName(name : string = "") {


    return this.campaignCategoryArray;

    /*Object.keys(this.campaignCategoryList)
      .forEach( key => {

        if ( !name || this.campaignCategoryList[key].name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ) {
          this.filtredCategoryList[this.campaignCategoryList[key].id] = this.campaignCategoryList[key];
        } else {
          delete this.filtredCategoryList[this.campaignCategoryList[key].id];
        }

      });

    return this.filtredCategoryList;*/
  }

  private shortName( name : string = '') {

    if ( name.length < 95 )
      return name;

    return name.substr(0 , 95) + '...';

  }

  private initHeigher() {

  }

  ngOnDestroy() {

  }

}
