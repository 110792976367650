
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {BrandService} from "../../../../service/api/Brand/Brand.service";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {LocationsDialogGetterService} from "../../service/locations-dialog-getter.service";
import {LocationsService} from "../../../../service/api/Locations/Locations.service";
import {LocationGroupService} from "../../../../service/api/LocationGroup/LocationGroup.service";
import {CampaignCategory} from "../../../../../api/CampaignCategoryApi/models/campaign-category";


@Component({
  selector: 'locations-groups-view',
  templateUrl: "./locations-groups-view.component.html",
  styleUrls: ['./locations-groups-view.component.scss' ],
  providers: [
    LocationGroupService
  ]
})
 export class LocationsGroupsViewComponent implements OnInit, OnChanges {

  @Input() partnerId;

  @Input()  selected = new Set<number>();
  @Output() selectedChange = new EventEmitter();

  @Input() selectedLocations = new Set<number>();

  @Input() max : number;
  @Input() selectOne : boolean;

  public searchQuery: string;

  public locationGroupsItems = [];
  public loader = new LoaderHandlerClass();

  @Input()  selectedLocationGroupId;
  @Output() selectedLocationGroupIdChange = new EventEmitter();

  @Input()  selectedAll = false;
  @Output() selectedAllChange = new EventEmitter();

  constructor(
    private locationGroupService: LocationGroupService,
    private locationsDialogGetterService: LocationsDialogGetterService,
  )
  {
  }

  ngOnInit() {
    this.locationGroupService.getListParams.reset();
    this.locationGroupService.getListParams.setFilterValue('partnerId', this.partnerId )
    this.getLocationGroups();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['partnerId'] && !changes['partnerId'].isFirstChange()) {
      this.locationGroupService.getListParams.setFilterValue('partnerId', changes['partnerId'].currentValue )
      this.reset();
      return;
    }

  }

  getLocationGroups() {

    this.locationsDialogGetterService
      .getLocationGroupsList(
        this.locationGroupService.getListParams.params,
        this.partnerId
      )
      .pipe(
        this.loader.waitLoading('locationGroupsItems')
      )
      .subscribe({
        next: result => {
          this.locationGroupsItems = this.locationGroupsItems.concat( result );
        },
        error: err => {
          console.log(err)
        }
      })

  }

  isSelected = (id) =>
    this.selected.has(id)

  changeSelected(id, value) {

    if (value) {

      if (this.selectOne) {
        this.selected.clear();
      }

      this.selected.add(id);
      this.locationsDialogGetterService
          .getLocationGroup(id,this.partnerId);

    } else {
      this.selected.delete(id)
    }

    this.locationsDialogGetterService
        .getLocationIdsForGroup(id,this.partnerId)
        .then( result => {

          result.forEach( id => {
            if (value) {
              this.selectedLocations?.add(id);
            } else {
              this.selectedLocations?.delete(id);
            }
          })

        })

    this.selectedChange.emit(this.selected);
    this.selectedAll = false;
    this.selectedAllChange.emit(this.selectedAll);

  }

  isDisabled( id ) {

    if ( !Number.isInteger(this.max) ) {
      return false;
    }

    if ( this.selected.size >= this.max )
      return !this.isSelected(id);

    return false;

  };

  filterBySearch = (item: any) => {
    if (!this.searchQuery || !item) return true;

    return  item.name && item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >=0;

  }

  onScroll() {
    if (this.loader.isLoading('locationGroupsItems'))
      return;

    this.locationGroupService.getListParams.next();
    this.getLocationGroups()
  }

  reset() {
    this.locationGroupsItems = [];
    this.selected.clear();
    this.locationGroupService.getListParams.reset();
    this.selectedChange.emit(this.selected);
    this.locationGroupSelect(undefined);

    this.getLocationGroups();
  }

  locationGroupSelect(selected) {
    this.selectedLocationGroupId = selected ? selected?.id : undefined;
    this.selectedLocationGroupIdChange.emit(this.selectedLocationGroupId);
  }

}
