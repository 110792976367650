<div class="fixed-tool-panel">

  <div class="dropdown  pull-left m-r-sm"   ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs"   ngbDropdownToggle>
      <i>
          <span class="fa fa-filter"></span>
      </i>
      <span class="btn-name">
                Временной период
                <span class="caret "></span>
            </span>
    </button>

    <ul class="dropdown-menu scrollable "
        ngbDropdownMenu
        style="z-index: 9999; "
        onclick="event.stopPropagation()"
    >

      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group ">
          <span class="input-group-addon" style="min-width:50px">С</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filterFrom"
                 [min]="minDate"
                 [max]="filterTo"
                 data-testid="filterFrom"
          >
          <span class="input-group-btn" *ngIf="filterFrom">
                  <button
                    class="btn btn-danger m-b-none m-t-none"
                    type="button"
                    (click)="filterFrom = undefined"
                    data-testid="filterFrom.reset()">
                      <i class="fa fa-times"></i>
                  </button>
          </span>
         </div>

      </li>
      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group m-b-xs">
          <span class="input-group-addon" style="min-width:50px">ПО</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filterTo"
                 [min]="filterFrom"
                 [max]="maxDate"
                 data-testid="filterTo"
          >
          <span class="input-group-btn" *ngIf="filterTo">
              <button
                class="btn btn-danger  m-b-none m-t-none"
                type="button"
                (click)="filterTo = undefined"
                data-testid="filterTo.reset()">
                  <i class="fa fa-times"></i>
              </button>
          </span>
        </div>

      </li>
      <li class=" padder text-right">
        <button
          class="btn w-full btn-danger btn-sm btn-addon m-l-none m-b-xs m-t-none"
          type="button"
          *ngIf="filterTo && filterFrom"
          (click)="filterTo = undefined; filterFrom = undefined;"
          data-testid="filter.reset()">
          <i class="fa fa-times"></i>
          <span class="ben-name">
            Сбросить
          </span>
        </button>
      </li>

    </ul>

  </div>

  <button type="button"
          class="btn btn-sm btn-primary btn-addon pull-right collapse-xs  "
          (click)="export()"
          data-testid="export()">
    <i class="fa fa-share-square">
    </i>
    <span class="btn-name">
      Экспортировать в CSV
    </span>
  </button>

</div>



 <responsive-table>
   <table
     class="table b-b "
    >
     <thead>
       <tr>
         <th>Оператор</th>
         <th class="text-right">Итого начислено</th>
         <th class="text-right table-row-xs ">Количество клиентов</th>
         <th class="text-right table-row-xs ">Количество операций</th>
         <th class="text-right table-row-sm table-row-xs ">Сумма списаний</th>
         <th class="text-right table-row-sm table-row-xs ">Сумма начислений</th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listTransactions">
          <tr
              [uiSref]="'^.transaction({id:'+item.userId+', from:\''+filterFrom+'\', to:\''+filterTo+'\'})'"
              class="pointer"
              data-testid="let item of listTransactions"
          >
            <td data-testid="item.userName">
              <span [innerText]="item.userName"></span>
            </td>
            <td class="text-right" data-testid="item.total">
              <span [innerText]="item.total"></span>
            </td>
            <td class="text-right" data-testid="item.clientCount">
              <span [innerText]="item.clientCount"></span>
            </td>
            <td class="text-right" data-testid="item.operationCount">
              <span [innerText]="item.operationCount"></span>
            </td>
            <td class="text-right" data-testid="item.removed">
              <span [innerText]="item.removed"></span>
            </td>
            <td class="text-right" data-testid="item.added">
              <span [innerText]="item.added"></span>
            </td>

          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>
