export const COUPON_GROUP_STATES = {
  'draft'    : "draft",
  'active'   : "active",
  'archive'  : "archive"
}

export const COUPON_GROUP_STATE_NAMES = {

  [COUPON_GROUP_STATES.draft]: "Черновик",
  [COUPON_GROUP_STATES.active]   : "Активна",
  [COUPON_GROUP_STATES.archive]  : "В архиве"

}
