'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';
import {RoleType} from "module/permissions/conf/roles";
import {TEMPLATES_ID} from "../../../crm/clients/view/templates";


let routePath = module.name + '.editions.certificate.create';
module.requires = module.requires.concat([
]);


export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id/:editionId')

                .template(template)
                .controller(controller)


                .resolve('partnerList', ['PartnerModel', function (PartnerModel) {

                    return PartnerModel.query().$promise.then(function( data ){
                        var result = {};

                        angular.forEach(data, function(item) {
                            result[item.id] = item;
                        });

                        data = null;

                        return result;
                    });

                }] )

                .resolve('certificateItem',  ['$stateParams', 'GiftCertificateModel', '$q',
                    function ($stateParams, GiftCertificateModel, $q ) {

                        if (!$stateParams.id && $stateParams.id !== 0) {

                            var result = new GiftCertificateModel({
                              "editionId"   : $stateParams.editionId ? parseInt($stateParams.editionId) : undefined ,
                              "number"      : undefined,
                              "quantity"   : 1,
                              "amount"      : undefined,
                              "description" : ""
                            });

                            return $q.resolve(result);

                        }

                        return GiftCertificateModel.get($stateParams).$promise;
                    }])

              .resolve('unlimitedGenerate', ['Settings', '$q',
                function (Settings, $q) {

                  return $q( ( resolve : any ) => {

                    Settings.query()
                      .$promise
                      .then( ( list : any ) => {

                        if (! Array.isArray(list) ) {
                          return resolve(false);
                        }

                        let finded : any = list
                          .filter( ( i : any ) => i.key === 'frontend.certificate.unlimitedGenerate');

                        if ( !finded.length ) {
                          return resolve(false);
                        }

                        finded = finded[0];
                        if (finded.value === "true") {
                          return resolve(true);
                        }

                        resolve(false);

                      });

                  });

                }] )


              /*

                            .resolve('historyItem',  ['editionItem', 'EditionModel',
                              function (editionItem, EditionModel  ) {

                                if (!editionItem || !editionItem.id) {
                                  return [];
                                }

                                return EditionModel.queryHistory({id:editionItem.id}).$promise;
                              }])

              */


                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager],
                    permit: 'editions'
                })

                .breadcrumbs( ["Подарочные сертификаты", "Генерация сертификатов"] );

        }]);
