import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ClientCategoryApiService} from "../../../../api/ClientCategoryApi/services";
import {ClientCategory} from "../../../../api/ClientCategoryApi/models";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";

@Injectable()
export class ClientCategoryService {

  constructor( private clientCategoryApiService:ClientCategoryApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    }
  });

  public getList( params: any = {}){
    return this.clientCategoryApiService.queryClientCategories(params);
  }

  public get( id? :number ){
    if (!id)
      return  new Observable(s => {
        s.next({});
        s.complete();
      });

    return this.clientCategoryApiService.get(id)
  }

  public saveCategory( category: ClientCategory ){

    if (typeof category.id !== "undefined") {
      return this.clientCategoryApiService.update({ id: category.id, partner: category });
    } else {
      return this.clientCategoryApiService.create( category );
    }

  }

  public deleteCategory( id: number ){

    return this.clientCategoryApiService.delete(id);

  }

}
