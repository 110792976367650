'use strict';

import module from 'frontendModule';

import { ClientAccount } from './controller';
import * as  templateView    from  './index.html';

export default module.component('clientAccount', {
    templateUrl : templateView,
    controller  : ClientAccount,

    bindings: {
        client       : '='
    }

});

