const MONTH_LIST = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

export class ClientByMonth {

    // Передаваемые параметры в компонент
    public clientId: number;

    public config: any;
    public categories : any[] = MONTH_LIST;

    public isEmpty: boolean = false;

    static $inject = ['ClientModel', 'ReportController', '$timeout' ];

    constructor(
            private ClientModel: any,
            private ReportController: any,
            private $timeout: ng.ITimeoutService
        ) {
    }

    public $onInit() {

        this.$timeout(() => {

             this.config = {
            options: {
                chart: {
                    type: 'spline',
                    height: 240,
                },

                colors: ['#23b7e5', '#1c92b7'],

                plotOptions: {
                    spline: {
                        fillOpacity: 0.5,
                        lineWidth: 5,

                        marker: {
                            enabled: true,
                            fillColor: '#ffffff',
                            lineColor: null,
                            lineWidth: 2,
                            radius: 6
                        }

                    },

                    series: {
                        animation: {
                            duration: 1000
                        }
                    },

                },

                tooltip: {
                    formatter: function () {
                        return 'Сумма покупок ' + + ( parseInt(this.y) || 0 );
                    }
                },

                legend: {

                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    x: 0,
                    y: 5,
                    floating: true,
                    itemMarginBottom: 10,
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    itemStyle: {
                        color: '#ccc',
                        fontWeight: 'normal'
                    }
                },

            },

            title: {
                text: ''
            },

            xAxis: {
                gridLineColor: '#f4f4f4',
                gridLineWidth: 1,
                tickWidth: 0,
                tickmarkPlacement: 'on',
                lineWidth: 0,
                labels:
                    {
                        style: {
                            color: '#ccc'
                        }
                    }
                ,
                categories: this.categories
            }
            ,

            yAxis: {
                gridLineColor: '#f4f4f4',
                gridLineWidth:
                    1,
                tickWidth:
                    0,
                lineWidth:
                    0,
                tickmarkPlacement:
                    'on',
                labels:
                    {
                        style: {
                            color: '#ccc'
                        }
                    }
                ,
                title: {
                    text: ''
                }

            },

            credits: {
                enabled: false
            },

            series: [],

        };

        });

    }

    public $onChanges( changesObj : any) {

        if ( changesObj.clientId ) {
            this.prepareData(changesObj.clientId.currentValue);
        }

    }


    private requestData( clientID : number ) {

        const localDate = new Date();
        return this.ReportController.clientbymonth({
                id: clientID
            }, [
                    {"yearId": localDate.getFullYear() - 1},
                    {"yearId": localDate.getFullYear()}
               ]).$promise;

    }

    private prepareData( clientID : number ) {

        this.requestData( clientID )
                .then(this.prepareSeries.bind(this))
                .then( ( data : any ) => {

                    this.isEmpty = data && data[0] && data[0].name === false;

                    this.config.series = data.map( ( i : any ) => Object.assign({
                        type: 'spline',
                        pointPlacement: 'on',
                        marker: { symbol: 'circle' }
                    }, i ) );

                    this.config.xAxis.categories = this.categories;
                })

    }

    private prepareSeries( requestData: any[] ) {

        const localDate = new Date();
              localDate.setDate(1);

          let prepared = {};
          let monthIntersection = false;
          let months = [];

        for ( let byYear of requestData )
            if ( typeof byYear.months !== 'undefined')
                for ( let byMonth of byYear.months ) {

                    if ( !monthIntersection && months.indexOf(byMonth.monthId)>=0 ) {
                        monthIntersection = true;
                    }

                    months.push(byMonth.monthId);

                    prepared[ byMonth.yearId ] = prepared[ byMonth.yearId ] || {};
                    prepared[ byMonth.yearId ][byMonth.monthId] = byMonth;
                    /*
                      prepared.push({
                          sort    : byMonth.yearId + byMonth.monthId/100,
                          monthId : byMonth.monthId,
                          yearId  : byMonth.yearId,
                          value   : byMonth.total
                      });*/
                }

        let result = [];
        if ( monthIntersection ) {
            // Разбивка на 2 года
            for ( let year in prepared ) {

                let item = {
                    name: year.toString(),
                    data: <any[]>[],
                }

                for ( let i = 1; i <= 12; i++ ) {
                    item.data.push([i - 1, ( prepared[year][i] &&  parseInt(prepared[year][i].total) ) || 0 ]);
                }

                result.push( item );
            }

            this.categories = MONTH_LIST;

        } else {
            // 2 года одной линией

            let item = {
                name: Object.keys(prepared).sort().join(' - ') || false,
                data:  <any[]>[],
            }

            this.categories = [];

            for ( let year in prepared ) {

                for ( let month in prepared[year] ) {
                    item.data.push( [ item.data.length, parseInt(prepared[year][month].total) ]);
                    this.categories.push( MONTH_LIST[ <any> month -1 ] );
                }

            }

            result.push( item );

        }

        return result;
    }

};
