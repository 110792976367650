import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'referrer-options-ng1'
})
export class ReferrerOptionsDirective extends UpgradeComponent {

  @Input()  settings       : any;
  @Input()  isEdit    : any;
  @Input()  additionalFields   : any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('referrerOptions', elementRef, injector);
  }
}

