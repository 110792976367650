/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GoodGroupApiConfiguration as __Configuration } from '../good-group-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodGroup } from '../models/good-group';
import { UploadFilePager } from '../models/upload-file-pager';
import { UploadFileEncoding } from '../models/upload-file-encoding';
import { PreviewFileTable } from '../models/preview-file-table';
@Injectable({
  providedIn: 'root',
})
class GoodGroupApiService extends __BaseService {
  static readonly createPath = '/goodgroup';
  static readonly queryPath = '/goodgroup/{partnerId}';
  static readonly getPath = '/goodgroup/{partnerId}/{id}';
  static readonly updatePath = '/goodgroup/{partnerId}/{id}';
  static readonly deletePath = '/goodgroup/{partnerId}/{id}';
  static readonly previewPath = '/goodgroup/preview/{filename}';
  static readonly uploadPath = '/goodgroup/{partnerId}/{id}/upload/{filename}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание группы товаров
   *
   * Создание группы товаров
   * @param goodGroup Параметры группы товаров
   * @return Группа товаров создана
   */
  createResponse(goodGroup: GoodGroup): __Observable<__StrictHttpResponse<GoodGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = goodGroup;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/goodgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoodGroup>;
      })
    );
  }
  /**
   * Создание группы товаров
   *
   * Создание группы товаров
   * @param goodGroup Параметры группы товаров
   * @return Группа товаров создана
   */
  create(goodGroup: GoodGroup): __Observable<GoodGroup> {
    return this.createResponse(goodGroup).pipe(
      __map(_r => _r.body as GoodGroup)
    );
  }

  /**
   * Запрос списка групп товаров
   *
   * Запрос списка групп товаров
   * @param params The `GoodGroupApiService.QueryParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `description` - атрибут "Описание"
   *
   * - `sentence`: Строка поиска
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список групп товаров
   */
  queryResponse(params: GoodGroupApiService.QueryParams): __Observable<__StrictHttpResponse<Array<GoodGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goodgroup/${encodeURIComponent(String(params.partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoodGroup>>;
      })
    );
  }
  /**
   * Запрос списка групп товаров
   *
   * Запрос списка групп товаров
   * @param params The `GoodGroupApiService.QueryParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `description` - атрибут "Описание"
   *
   * - `sentence`: Строка поиска
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список групп товаров
   */
  query(params: GoodGroupApiService.QueryParams): __Observable<Array<GoodGroup>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<GoodGroup>)
    );
  }

  /**
   * Запрос группы товаров по ID
   *
   * Запрос группы товаров по ID
   * @param params The `GoodGroupApiService.GetParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * @return Параметры группы товаров
   */
  getResponse(params: GoodGroupApiService.GetParams): __Observable<__StrictHttpResponse<GoodGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goodgroup/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoodGroup>;
      })
    );
  }
  /**
   * Запрос группы товаров по ID
   *
   * Запрос группы товаров по ID
   * @param params The `GoodGroupApiService.GetParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * @return Параметры группы товаров
   */
  get(params: GoodGroupApiService.GetParams): __Observable<GoodGroup> {
    return this.getResponse(params).pipe(
      __map(_r => _r.body as GoodGroup)
    );
  }

  /**
   * Обновление параметров группы товаров по ID
   *
   * Обновление параметров группы товаров по ID
   * @param params The `GoodGroupApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `goodGroup`: Параметры группы товаров
   *
   * @return Группа товаров обновлена
   */
  updateResponse(params: GoodGroupApiService.UpdateParams): __Observable<__StrictHttpResponse<GoodGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.goodGroup;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgroup/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoodGroup>;
      })
    );
  }
  /**
   * Обновление параметров группы товаров по ID
   *
   * Обновление параметров группы товаров по ID
   * @param params The `GoodGroupApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `goodGroup`: Параметры группы товаров
   *
   * @return Группа товаров обновлена
   */
  update(params: GoodGroupApiService.UpdateParams): __Observable<GoodGroup> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as GoodGroup)
    );
  }

  /**
   * Удаление группы товаров по ID
   *
   * Удаление группы товаров по ID
   * @param params The `GoodGroupApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  deleteResponse(params: GoodGroupApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/goodgroup/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление группы товаров по ID
   *
   * Удаление группы товаров по ID
   * @param params The `GoodGroupApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   */
  delete(params: GoodGroupApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Предпросмотр файла для загрузки списка товаров
   *
   * Предпросмотр файла для загрузки списка товаров
   * @param params The `GoodGroupApiService.PreviewParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   */
  previewResponse(params: GoodGroupApiService.PreviewParams): __Observable<__StrictHttpResponse<{pager: UploadFilePager, delimiter: string, encoding: UploadFileEncoding, skuColumn: number, locationColumn: number, quantityColumn: number, quantityPiece: number, quantityWeight: number, table?: PreviewFileTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgroup/preview/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{pager: UploadFilePager, delimiter: string, encoding: UploadFileEncoding, skuColumn: number, locationColumn: number, quantityColumn: number, quantityPiece: number, quantityWeight: number, table?: PreviewFileTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>;
      })
    );
  }
  /**
   * Предпросмотр файла для загрузки списка товаров
   *
   * Предпросмотр файла для загрузки списка товаров
   * @param params The `GoodGroupApiService.PreviewParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   */
  preview(params: GoodGroupApiService.PreviewParams): __Observable<{pager: UploadFilePager, delimiter: string, encoding: UploadFileEncoding, skuColumn: number, locationColumn: number, quantityColumn: number, quantityPiece: number, quantityWeight: number, table?: PreviewFileTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}> {
    return this.previewResponse(params).pipe(
      __map(_r => _r.body as {pager: UploadFilePager, delimiter: string, encoding: UploadFileEncoding, skuColumn: number, locationColumn: number, quantityColumn: number, quantityPiece: number, quantityWeight: number, table?: PreviewFileTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string})
    );
  }

  /**
   * Загрузка состава группы товаров из файла
   *
   * Загрузка состава группы товаров из файла
   * @param params The `GoodGroupApiService.UploadParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `params`: Параметеры загрузки из файл
   *
   * - `id`: ID группы товаров
   *
   * - `filename`: Файл для загрузки
   *
   * @return Статистика по загрузке списка товаров
   */
  uploadResponse(params: GoodGroupApiService.UploadParams): __Observable<__StrictHttpResponse<{total: number, success: number, duplicates: number, errors: number, duplicatesCSV: string, errorsCSV: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.params;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgroup/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/upload/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{total: number, success: number, duplicates: number, errors: number, duplicatesCSV: string, errorsCSV: string}>;
      })
    );
  }
  /**
   * Загрузка состава группы товаров из файла
   *
   * Загрузка состава группы товаров из файла
   * @param params The `GoodGroupApiService.UploadParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `params`: Параметеры загрузки из файл
   *
   * - `id`: ID группы товаров
   *
   * - `filename`: Файл для загрузки
   *
   * @return Статистика по загрузке списка товаров
   */
  upload(params: GoodGroupApiService.UploadParams): __Observable<{total: number, success: number, duplicates: number, errors: number, duplicatesCSV: string, errorsCSV: string}> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as {total: number, success: number, duplicates: number, errors: number, duplicatesCSV: string, errorsCSV: string})
    );
  }
}

module GoodGroupApiService {

  /**
   * Parameters for query
   */
  export interface QueryParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Порядок сортировки:
     *   - `asc` - по увеличению
     *   - `desc` - по убыванию
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле (атрибут) сортировки списка:
     *   - `name` - атрибут "Наименование"
     *   - `description` - атрибут "Описание"
     */
    sortingField?: 'name' | 'description';

    /**
     * Строка поиска
     */
    sentence?: string;

    /**
     * Сколько записей вернуть, по умолчанию - 50
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала списка, по умолчанию - 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for get
   */
  export interface GetParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * Параметры группы товаров
     */
    goodGroup: GoodGroup;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for preview
   */
  export interface PreviewParams {

    /**
     * Параметры предпросмотра
     */
    params: {pager?: UploadFilePager, delimiter?: string, encoding?: UploadFileEncoding, skuColumn?: number, locationColumn?: number, quantityColumn?: number, quantityPiece?: number, quantityWeight?: number};

    /**
     * Файл для предпросмотра
     */
    filename: string;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Параметеры загрузки из файл
     */
    params: {delimiter: string, encoding: UploadFileEncoding, drop: number, skuColumn: number, locationColumn?: number, quantityColumn?: number, quantityPiece: number, quantityWeight: number};

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * Файл для загрузки
     */
    filename: string;
  }
}

export { GoodGroupApiService }
