import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {UserService} from "../../../../../shared/service/api/User/User.service"
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service"
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {NgForm} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {CampaignCategory} from "../../../../../api/CampaignCategoryApi/models/campaign-category";
import {COLLISION_VALUES} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.values";
import {CampaignService} from "../../../../../shared/service/api/Campaign/Campaign.service";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {WarningBoxService} from "../../../../../shared/service/warningBox/warningBox.service";
import {
  LIMITATION_TYPES_VALUES, PERIOD_TYPES_VALUES
} from "../../../../../shared/service/api/CampaignLimitations/CampaignLimitations.values";
import {
  CampaignLimitationsService
} from "../../../../../shared/service/api/CampaignLimitations/CampaignLimitations.service";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'campaignlimitations-view-page',
  templateUrl: './campaignlimitations-view-page.component.html',
  providers: [
    CampaignLimitationsService,

    StatesChangerService,
    CampaignCategoryService,
    CampaignService,
    CouponRuleService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.campaignlimitations'
    },
    WarningBoxService
  ]
})
export class CampaignlimitationsViewPageComponent implements OnInit, OnChanges{

  @Input() id: string;

  @ViewChild('cgroupForm') cgroupForm;
  public currentGroup: any;

  public LIMITATION_TYPES_VALUES = LIMITATION_TYPES_VALUES;
  public PERIOD_TYPES_VALUES = Object.values(PERIOD_TYPES_VALUES);



  public valuePatterns = {
    'discount'  : /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/,
    'bonus'     : /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/,
    'numberOfTriggering' : /^((10000000000)|[0-9]{1,10})$/
  };

  get hasActive() {

    return !!(
        this.currentGroup &&
        Array.isArray( this.currentGroup?.links ) &&
        !!this?.currentGroup?.links.some( item => item.state === 'active' )
    );

  };

  public itemForAdd: any;
  public isCampaignLoading = false;

  public listCampaings = [];
  public listIncludedCampaings = [];

  private uiRouterState;


  constructor(
    private campaignLimitationsService:CampaignLimitationsService,
    private warningBoxService:WarningBoxService,
    private campaignService:CampaignService,
    public  statesChangerService:StatesChangerService,
    public confirmActionService: ConfirmActionService,

  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();

    let loyaPermissions = getNg1Service('loyaPermissions');
    if ( !loyaPermissions.byPath('campaign.mechanics.bonus').enabled ) {
      delete this.LIMITATION_TYPES_VALUES['bonus'];
    }

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getCategoryById(changes.id.currentValue)
        .then( result => {
          if (changes.id.firstChange) {

            this.statesChangerService.state = !changes.id.currentValue ?
                                              'edit' :
                                              'view';
          }
        })
      ;
    }
  }

  getCategoryById( id?:number  ) {

    return this.campaignLimitationsService
        .get(id)
        .toPromise()
        .then( result => this.currentGroup = result )
        .then( () => this.getCampaings())

  }

  onSave() {

    this.campaignLimitationsService
      .save(this.currentGroup)
      .subscribe(result=> {
        this.currentGroup = result;

        if ( !Array.isArray( this.currentGroup?.links ) ) {
          this.currentGroup.links = []
        }


        let forBind = this.listIncludedCampaings.filter( camp => !this.currentGroup.links.some( linkCamp => linkCamp.id === camp?.sourceId) ) || [];
        let forUnbind = this.currentGroup.links.filter( linkCamp => !this.listIncludedCampaings.some( camp => linkCamp.id === camp?.sourceId) ) || [];


        this.campaignLimitationsService
            .unbindCampaign$(this.currentGroup.id, forUnbind.map(i => i.id))
            .pipe(
              switchMap( () =>
                this.campaignLimitationsService
                  .bindCampaign$(this.currentGroup.id, forBind.map(i => i.sourceId))
              )
            )
            .subscribe(() => {
              this.uiRouterState.go('^.view',{id: this.currentGroup.id},{reload:true});
            });

      });

  }

  onCancel() {
    if (typeof this.currentGroup.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.uiRouterState.go('^.view',{id: this.currentGroup.id},{reload:true});
  }

  onDelete() {
    if (typeof this.currentGroup.id === "undefined")
        return;

    this.campaignLimitationsService
        .delete$(this.currentGroup.id)
        .subscribe(() => {
          this.uiRouterState.go('^.index');
        }, error=> {
            error?.stopPopupError();
            this.warningBoxService.warning(error.error && error.error.message ||  'Ошибка удаления');
        })
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }



  getCampaings() {

    this.listCampaings = [];
    this.listIncludedCampaings = [];
    this.isCampaignLoading = true;

    this.campaignService.query$({
      filter: { states: ["draft", "suspended", "unsuspend", "planned", "started", "active"] },
      pager: { drop:0, limit:99999 }
    }).subscribe( (list = [] ) => {

      this.listCampaings = list
        .filter( campaignItem =>
              (
                typeof campaignItem?.campaign?.segmentId !== "undefined" ||
                typeof campaignItem?.campaign?.groupId !== "undefined"
              )
          )
          .map(
          campaignItem => ({
            "sourceId": campaignItem.campaign.id,
            "source": "campaign",
            "campaignDetails": {
              "name": campaignItem.campaign.name,
              "description":campaignItem.campaign.description,
              "state": campaignItem.campaign.state
            }
          })
          );

      this.listIncludedCampaings = [];
      if ( Array.isArray(this.currentGroup?.links) ) {
        this.currentGroup.links.forEach( item => {

          this.itemForAdd = this.listCampaings.find( i => i.sourceId === item.id );
          if (this.itemForAdd)
            this.addCampaignInGroup();

          this.itemForAdd = undefined;
        })
      }

      this.isCampaignLoading = false;
    });

  }


  addCampaignInGroup() {

    if (!this.itemForAdd)
      return;

    let key = this.listCampaings.indexOf(this.itemForAdd);
    this.listCampaings.splice(key, 1);

    this.listIncludedCampaings.push(this.itemForAdd);
    this.itemForAdd = undefined;

  };

  deleteCampaignFromGroup(campaign) {

    let key = this.listIncludedCampaings.indexOf(campaign);

    this.listCampaings.push(campaign);

    this.listIncludedCampaings.splice(key, 1);

  };


}
