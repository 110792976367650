'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
let routePath   = module.name + '.campaign.gate.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .template( '<gate-index-page></gate-index-page>', true)

        .permissions( {
          only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],

        })

        .breadcrumbs([ "Кампании", "Журнал рассылки" ])

    }]);
