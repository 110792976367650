import { QueryHandler } from './../../QueryHandler.class';
import {ResourceHandler} from "./../../ngResourceHandler.class";
import {ngResourceFilterSet} from "./ngResourceFilterSet.class";
import {ngResourceFilterToggle} from "./ngResourceFilterToggle.class";
import {ngResourceFilterCustom} from "./ngResourceFilterCustom.class";

export const FILTER_FIELD = '$filter';

export interface IResourceFieldOptions {
    field?  : string;   // Если задано, то берётся пусть в json из этого поля
    useAll  : boolean;
    method? : string;
    isObject? : boolean;
    values:  {
        [ alias:string ] : string
    }
}

export interface IResourceFilterOptions {

    [ filed:string ] : IResourceFieldOptions

}

export class ngResourceFilter  extends ResourceHandler{

    private defaultFieldOptions: IResourceFieldOptions = {
        useAll : true,
        values : {}
    }

    private options:any = {};
  static $inject = ['handler'];

    constructor(
        protected handler: QueryHandler
    ) {

        super(handler);

        for ( let field in this.handler.actions.filter ) {
            this.options[field] = ( <IResourceFieldOptions>  (<any>Object).assign({}, this.defaultFieldOptions, this.handler.actions.filter[field]) );
        }

    }

    get field() {
        return FILTER_FIELD;
    }


    static canHandle( actions: any ) {
        return actions && !!actions.filter;
    }

    public getCurrentParams() {
        let params = {};

        for ( let field in this.options ) {
            if ( this[field] && this[field].hasOwnProperty('getParamQuery') ) {
                params = QueryHandler.mergeParams( params, this[field].getParamQuery() );
            }

        }

        return params;
    };

    protected onUpdate( finish: Promise<any> ) {
        return this.getCurrentParams()
    };

    protected onStart(startOptions: Object) {

        for ( let field in this.options ) {

            switch( this.options[field].type ) {

                case 'toggle' :
                    this[field] = new ngResourceFilterToggle(this.options[field], this.options[field].field || field,  this.reset.bind(this), startOptions );
                    break;

                case 'custom' :
                    this[field] = new ngResourceFilterCustom(this.options[field], this.options[field].field || field,  this.reset.bind(this), startOptions );
                    break;

                case 'set':
                default:
                    this[field] = new ngResourceFilterSet(this.options[field], this.options[field].field || field,  this.reset.bind(this), startOptions );

            }


        }
    }

    protected onReset() {}

    private query = function () {
        return this.handler.query();
    };

    private reset = function () {
        return this.handler.reset();
    };

}

