import {Injectable} from "@angular/core";
import {ngCurrencyL10nClass} from "directive/ngCurrencyLoya/ngCurrencyL10nClass";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class ngCurrencyL10nService
          extends ngCurrencyL10nClass {

  static cacheCurrency: string | undefined;
  static isRequested = false;

  constructor(  http: HttpClient ) {
    super(http, {});
  }

   public getCurrentCerrency( withResetCache? ) {

    if (!!withResetCache) {
      ngCurrencyL10nService.cacheCurrency = undefined;
      ngCurrencyL10nService.isRequested = false;
    }

    if (ngCurrencyL10nService.isRequested)
      return;

    if (typeof ngCurrencyL10nService.cacheCurrency !== "undefined") {
      this.currentCurrency = ngCurrencyL10nService.cacheCurrency ? ngCurrencyL10nService.cacheCurrency : 'ru';
      ngCurrencyL10nService.isRequested = false;
      return;
    }

    ngCurrencyL10nService.isRequested = true;
    this.promiseLoading = ( <HttpClient> this.$http)
      .post('/settingsByKeys',["frontend.i18n.currency"])
      .toPromise()
        .then( (arr: Array<any>) => {
          this.promiseLoading = false;
          const i18n = arr.find( i => i.key === 'frontend.i18n.currency' );
          this.currentCurrency = i18n ? i18n.value : 'ru';
          ngCurrencyL10nService.cacheCurrency = this.currentCurrency;
          ngCurrencyL10nService.isRequested = false;
        }).catch( () => {
          this.promiseLoading = false;
          this.currentCurrency = 'ru';
          ngCurrencyL10nService.isRequested = false;
      } );

  }

}
