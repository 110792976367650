import {Injectable} from "@angular/core";
import {SegmentationApiService} from "../../../../api/SegmentationApi/services/segmentation-api.service";
import {SegmentationQueryParam} from "../../../../api/SegmentationApi/models/segmentation-query-param";
import {catchError, map, switchMap} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import {Observable as __Observable} from "rxjs/internal/Observable";
import {SegmentRefreshState} from "../../../../api/SegmentationApi/models/segment-refresh-state";

import {GroupApiService  } from "../../../../api/GroupApi/services";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {GroupState} from "../../../../api/GroupApi/models/group-state";
import {GroupQueryParam} from "../../../../api/GroupApi/models/group-query-param";


@Injectable()
export class GroupService {

  public getListParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.isAutorefresh',
        id: 'isAutorefresh',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.isCustom',
        id: 'isCustom',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.withoutSubgroup',
        id: 'withoutSubgroup',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  constructor( private groupApiService: GroupApiService ) {
  }


  public getList$( params?: GroupQueryParam ){

    return this.groupApiService.query( params)

  }

}
