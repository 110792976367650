export const FORMATTER_FIELD = '$errorFormatter';

const isUndefined = (val : any ) => typeof val === "undefined";

const errorParsers = {
    required  : () => 'Поле обязательно для ввода',

    minlength : ( ctrl: any) => {
         const minLength = ctrl.$$attr && ctrl.$$attr.ngMinlength;
         return isUndefined(minLength)? `Не введён минимум символов`: `Введите минимум ${minLength} символов`
     },

     maxlength : ( ctrl: any) => {
         const maxLength = ctrl.$$attr && ctrl.$$attr.ngMaxlength;
         return isUndefined(maxLength)? `Превышен лимит символов`: `Превышен лимит в ${maxLength} символов`
     },

     pattern   : () => `Нарушен шаблон ввода`,

     min       :  ( ctrl: any) => {
        const ngMin = ctrl.$$attr && ctrl.$$attr.ngMin;
        return isUndefined(ngMin)? `Введено число меньше допустимого`: `Минимальное доступное число для ввода: ${ngMin} `
    },

     max       :  ( ctrl: any) => {
         const ngMax = ctrl.$$attr && ctrl.$$attr.ngMax;
         return isUndefined(ngMax)? `Введено число больше допустимого`: `Максимальное доступное число для ввода: ${ngMax} `
     },
};

export class InputErrorFormatter {

    private attachedErrorHandlers : { [key:string]: Function };
    private errorList : Array<string> = [];

  static $inject = ['ctrl' ];

    constructor( private ctrl: ng.INgModelController) {
        this.attachedErrorHandlers = {};
    }

    static attachToController(ctrl: ng.INgModelController) {

        if ( !ctrl[FORMATTER_FIELD] ) {
            ctrl[FORMATTER_FIELD] = new InputErrorFormatter( ctrl);
        }
    }

    private getTextForError( errorType: string ) {

        switch (true) {

            case typeof this.attachedErrorHandlers[errorType] === "function":
                    return this.attachedErrorHandlers[errorType](this.ctrl);
                break;

            case typeof errorParsers[errorType] === "function":
                    return errorParsers[errorType](this.ctrl);
                break;

            default: return "Неизвестная ошибка ввода";
        }

    }

    get errorMessages() {
        return this.getAllErrorsMessage();
    }

    public attachErrorHandler( key: string, handler: Function ) {
        this.attachedErrorHandlers[key] = handler;
        return () => this.dettachErrorHandler(key);

    }

    public dettachErrorHandler( key: string ) {
        delete this.attachedErrorHandlers[key];
    }


    public hasErrors() {
        return this.ctrl.$error && !!Object.keys(this.ctrl.$error).length;
    }

    public getAllErrorsMessage() : Array<string> {

        if ( !this.hasErrors())
            return [];

        let errors : Array<string> = [];

        Object.keys( this.ctrl.$error ).forEach( key => {

            if ( this.ctrl.$error[key] ){
                errors.push( this.getTextForError( key ) );
            }

        });

        return errors;
    }



}

