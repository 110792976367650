
import {CouponApplyTypes, CouponKind, CouponWithBarcodeWithReceptParamsId, RewardWhatTypes} from "./CouponKind";
import {
    ExternalId,
    ManualId,
    PosId,
    StartPeriodTypes,
    StopPeriodTypes,
    TriggerId,
    UniqueCouponGenId
} from "../IssuingTypes/IssuingType";

export class CouponWithBarcodeWithReceptParams extends CouponKind {

    static id = CouponWithBarcodeWithReceptParamsId;

    protected prefix = false;
    protected useCount = false;

    protected startPeriodArray: Array<StartPeriodTypes> = [];
    protected stopPeriodArray: Array<StopPeriodTypes> = [];

    protected rewardWhatTypes: Array<RewardWhatTypes> = [
        RewardWhatTypes.DISCOUNT
    ];

    protected useRewardWhat = false;
    protected useRewardRewType = false;

    protected importCoupons = true;

    get hasStartPeriod() { return true; }
    get hasStopPeriod()  { return true; }

    get isUniqueCouponGen() : boolean {
        return this.issuingType.getId() === UniqueCouponGenId;
    }

    get canImportCoupons(): boolean {
        return this.ruleItem.couponRule.state === 'draft' || this.ruleItem.couponRule.state === 'active';
    }

    public $save() {

        this.ruleItem.couponRule.usingSettings = this.ruleItem.couponRule.usingSettings || {};
        this.ruleItem.couponRule.usingSettings.usable3rdParties = true;
        const ignoreLimits  = this.ruleItem.couponRule.reward.ignoreLimits;
        this.ruleItem.couponRule.reward =  {
                "reward": {
                    "what": "discount",
                    "rewType": "goods",
                    "id": 0,
                    "description": "",
                    "measure": "percent",
                    "extensionType": "every",
                    "value": 0
                },
                "onGood": {
                    "rewType": "every"
                }
        }
        this.ruleItem.couponRule.reward.ignoreLimits = ignoreLimits;


    }



}