<div class="fixed-tool-panel">


  <button type="button" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete')"
          (click)="confirmActionService.confirm('Вы действительно хотите удалить тип механики ' + currentMechanicCategory.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
  >
    <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
  </button>



  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          [hidden]="!statesChangerService.hasButton('edit')"
          (click)="statesChangerService.click('edit')"
  >

    <i class=" fa fa-pencil">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')">

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>


<div class="card w-100 no-back"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentMechanicCategory"
  >
    <form name="mcategoryForm" #mcategoryForm="ngForm" novalidate>

      <grid-container>

      <div class="grid-item"
           [grid-item-form] = "'models.MechanicCategory.id.name' | transloco"
           grid-item-form-icon = "fa-gear"
      >
        <span
          [innerHTML]="currentMechanicCategory.id || '-'"
        ></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.MechanicCategory.name.name'  | transloco"
           grid-item-form-icon = "fa-gear"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentMechanicCategory.name"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
          <input
            type="text"
            class="form-control form-control-edittable"
            [(ngModel)]="currentMechanicCategory.name"
            name="name"
            [placeholder]="'models.MechanicCategory.name.placeholder'  | transloco"
            [required]="true"
            [maxlength]="254"
          >
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.MechanicCategory.description.name'  | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
         <span [hidden]="statesChangerService.isActive"
               [innerText]="currentMechanicCategory.description"
         ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
            <textarea
              class="form-control form-control-edittable"
              [(ngModel)]="currentMechanicCategory.description"
              name="description"
              [placeholder]="t('models.MechanicCategory.description.placeholder')"
              [required]="true"
              [maxlength]="254"
              rows="3"
            ></textarea>
        </span>
      </div>

    </grid-container>
    </form>

  </div>

</div>
