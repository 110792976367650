/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CampaignCategoryMatrixApiConfiguration, CampaignCategoryMatrixApiConfigurationInterface } from './campaign-category-matrix-api-configuration';

import { CampaignCategoryMatrixApiService } from './services/campaign-category-matrix-api.service';

/**
 * Provider for all CampaignCategoryMatrixApi services, plus CampaignCategoryMatrixApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CampaignCategoryMatrixApiConfiguration,
    CampaignCategoryMatrixApiService
  ],
})
export class CampaignCategoryMatrixApiModule {
  static forRoot(customParams: CampaignCategoryMatrixApiConfigurationInterface): ModuleWithProviders<CampaignCategoryMatrixApiModule> {
    return {
      ngModule: CampaignCategoryMatrixApiModule,
      providers: [
        {
          provide: CampaignCategoryMatrixApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
