'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';

import 'model/MechanicCategoryModel/resource';
import 'model/MechanicModel/resource';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.campaign.mechanics.index';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(template)
                .controller(controller)

                .resolve('mechanicsList', [ '$q', 'MechanicModel', function ($q, MechanicModel) {
                    return MechanicModel
                                   .query({
                                       "filter": {
                                           states: ['template'],
                                       },
                                       "pager": {
                                           limit: 20
                                       }
                                   })

                }])

                .resolve('mechanicsCategoryList', [ 'MechanicCategoryModel', '$q', function (MechanicCategoryModel, $q) {

                    var defer = $q.defer();

                    MechanicCategoryModel.query({}, function( data ) {
                        var result = {};

                        angular.forEach( data, function( category ) {
                            result[ category.id ] = category.name;
                        });

                        defer.resolve(result);
                    }, function() {  defer.reject({}); });


                    return defer.promise;
                }])

                .breadcrumbs([ "Кампании", "Шаблоны механик" ])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                })
        }]);
