import {QueryHandler} from "./QueryHandler.class";

export abstract class ResourceHandler {

  static $inject = ['handler'];

    constructor(protected handler: QueryHandler) {

        this.bindToResult();
        this.bindEvents();

    }

    abstract get field(): string;

    get isPending() {
        return this.handler.isPending;
    }

    protected abstract onStart(options: Object): void;

    protected abstract onUpdate(finish: Promise<any>): void;

    protected abstract onReset(): void;

    public abstract getCurrentParams(): Object;


    private bindToResult() {

        let remove = this.handler.onUpdate((finish: Promise<any>) => {

            this.assignToResult(this.handler.results);
            setTimeout(remove, 0);

        });

    }

    private bindEvents() {

        this.onStart && this.handler.onStart(this.onStart.bind(this));
        this.onUpdate && this.handler.onUpdate(this.onUpdate.bind(this));
        this.onReset && this.handler.onReset(this.onReset.bind(this));

    }

    private assignToResult = function (result: any) {

        Object.defineProperty(this.handler.results, this.field, {
            value: this,
            enumerable: false,
            configurable: false,
        });

        return result;
    };


}


