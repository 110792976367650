/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReportSkuDiscountsApiConfiguration, ReportSkuDiscountsApiConfigurationInterface } from './report-sku-discounts-api-configuration';

import { ReportSkuDiscountsApiService } from './services/report-sku-discounts-api.service';

/**
 * Provider for all ReportSkuDiscountsApi services, plus ReportSkuDiscountsApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ReportSkuDiscountsApiConfiguration,
    ReportSkuDiscountsApiService
  ],
})
export class ReportSkuDiscountsApiModule {
  static forRoot(customParams: ReportSkuDiscountsApiConfigurationInterface): ModuleWithProviders<ReportSkuDiscountsApiModule> {
    return {
      ngModule: ReportSkuDiscountsApiModule,
      providers: [
        {
          provide: ReportSkuDiscountsApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
