'use strict';

import module from 'frontendModule';


let routePath = module.name + '.triggers.trigger.view';


import 'model/TriggersModel/resource';
import {RoleType} from "module/permissions/conf/roles";

export default
    module
        .config(
            ['routeBuilderProvider', (routeBuilderProvider : any) => {

                routeBuilderProvider
                    .route( routePath )
                    .url('/:id')

                    .template(   () => import( /* webpackChunkName: "assets/pages/triggers.trigger.view" */ "./views/index.html"  ))
                    .controller( () => import( /* webpackChunkName: "assets/pages/triggers.trigger.view" */ "./controllers"       ))

                 //   .template(template)
                    /*
                  .controller(controller)
*/
                    .breadcrumbs([ "Цепочки", "Цепочка" ])

                    .permissions( {
                        only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
                        permit: 'triggers'
                    })

                    .resolve('TriggerItem', ['$stateParams', 'TriggersModel', '$q',
                        function ($stateParams : any, TriggersModel : any, $q : any) {

                            if (!$stateParams.id && $stateParams.id !== 0) {
                                return new TriggersModel();
                            }

                            return TriggersModel.findTriggerById($stateParams).$promise;
                        }])

                    .resolve('demoTriggers', ['Settings', '$q',
                        function (Settings : any, $q : any) {

                            return $q( (resolve : any) => {

                                Settings.query()
                                        .$promise
                                        .then( ( list : any ) => {

                                            if (! Array.isArray(list) ) {
                                                return resolve(false);
                                            }

                                            let finded: any = list
                                                        .filter( ( i : any ) => i.key === 'frontend.triggers.demo');

                                            if ( !finded.length ) {
                                                return resolve(false);
                                            }

                                            finded = finded[0];
                                            if (finded.value === "true") {
                                                return resolve(true);
                                            }

                                            resolve(false);

                                        });

                            });

                        }])

                    .resolve('debugTriggers', [ '$cookies',
                        function ( $cookies:any ) {
                            return !!$cookies.get('triggers.debug');
                        }]
                    )

            }]);
