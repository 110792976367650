/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for ClientTemplateApi services
 */
@Injectable({
  providedIn: 'root',
})
export class ClientTemplateApiConfiguration {
  rootUrl: string = location.origin;
}

export interface ClientTemplateApiConfigurationInterface {
  rootUrl?: string;
}
