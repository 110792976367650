'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.crm.cards.index';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )

            .template(   '<cards-index-page></cards-index-page>', true)
            .breadcrumbs( [ "Клиенты", "Карты"] )

            .permissions( {
              only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator],
              permit: 'global.clients'
            })

        }]);
