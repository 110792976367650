
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgModel} from "@angular/forms";

@Component({
  selector: 'filter-radio',
  templateUrl: './filter-radio.component.html',
  providers: [
  ]
})
export class FilterRadioComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Output() public onChange = new EventEmitter();

  @Input() public disabled;
  @Input() public isPending = false;

  @Input() public label = 'Фильтр';

  @Input() public inputValues;

  private isLoading = true;

  public preparedInputValues: any = [];

  constructor(
  ) {
  }

  ngOnInit() {
  }


  ngOnChanges(changes) {

    if (changes['inputValues'] )
      this.prepareInputValues();

  }

  public emitModel() {
    this.modelChange.emit(  this.model);
    this.onChange.emit( this.model);
  }

  prepareInputValues() {
    this.preparedInputValues = [];

    for (let key in this.inputValues) {
      let id: any = key;

      if ( /^\d+$/.test(id) && !!this.inputValues[ parseInt(id) ] )
        id = parseInt(id);

      this.preparedInputValues.push({
        id: id,
        value: this.inputValues[key]
      })

    }

  }

}
