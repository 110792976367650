import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import './gate-index-page.legacy';
import {NewsletterService} from "../../../../shared/service/api/Newsletter/Newsletter.service";
import {StatisticResponse} from "../../../../api/NewsletterApi/models/statistic-response";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";


@Component({
  selector: 'gate-index-page',
  templateUrl: './gate-index-page.component.html',
  providers: [
    NewsletterService
  ]
})
export class GateIndexPageComponent implements OnInit{

  public profile: StatisticResponse;
  public loader = new LoaderHandlerClass();

  constructor(
    private newsletterService: NewsletterService
  ) {
  }

  ngOnInit() {
    this.newsletterService.statistics$()
      .pipe(
        this.loader.waitLoading('statistics')
      )
      .subscribe( {
        next: (result: StatisticResponse) => {
          this.profile = result;
        }
      })
  }


}
