/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CategoryApiConfiguration, CategoryApiConfigurationInterface } from './category-api-configuration';

import { CategoryApiService } from './services/category-api.service';

/**
 * Provider for all CategoryApi services, plus CategoryApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CategoryApiConfiguration,
    CategoryApiService
  ],
})
export class CategoryApiModule {
  static forRoot(customParams: CategoryApiConfigurationInterface): ModuleWithProviders<CategoryApiModule> {
    return {
      ngModule: CategoryApiModule,
      providers: [
        {
          provide: CategoryApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
