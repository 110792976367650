import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {NewsletterService} from "../../../../../shared/service/api/Newsletter/Newsletter.service";
import {
  CampaignStatus, CampaignStatusNames, ChannelType, ChannelTypeNames,
  NewsletterStatus,
  NewsletterStatusNames, SourceTypeNames, NewsletterTaskExternalStateNames
} from "../../../../../shared/service/api/Newsletter/Newsletter.variables";
import {NewsletterProtocol} from "../../../../../api/NewsletterApi/models/newsletter-protocol";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {NewsletterProtocolTask} from "../../../../../api/NewsletterApi/models/newsletter-protocol-task";
import {DateUtils} from "../../../../../shared/class/utils/date.utils";
import {NewsletterUtilsClass} from "../../shared/class/newsletter-utils.class";

@Component({
  selector: 'gate-newsletter-list',
  templateUrl: './gate-newsletter-list.component.html',
  providers: [
    NewsletterService
  ]
})
export class GateNewsletterListComponent implements OnInit {

  public loader = new LoaderHandlerClass();

  public CampaignStatus = CampaignStatus;
  public CampaignStatusNames = CampaignStatusNames;
  public NewsletterStatus = NewsletterStatus;
  public NewsletterStatusNames = NewsletterStatusNames;
  public NewsletterTaskExternalStateNames = NewsletterTaskExternalStateNames;
  public ChannelType = ChannelType;
  public ChannelTypeNames = ChannelTypeNames;
  public SourceTypeNames = SourceTypeNames;

  public newsletterList: Array<NewsletterProtocol> = [];
  public detailsList: Array<NewsletterProtocolTask> = [];
  public detailsId;

constructor(
    public  newsletterService: NewsletterService
  ) {
  }


  getStateIcon = NewsletterUtilsClass.getStateIcon;
  getStateColor = NewsletterUtilsClass.getStateColor;
  getClassStatusTransaction = NewsletterUtilsClass.getClassStatusTransaction;

  ngOnInit() {

    let today = new Date();
    let yesterday = new Date();
        yesterday.setMonth(yesterday.getMonth() - 1);

    this.newsletterService.queryParams.setFilterValue('fromDate', DateUtils.dateToText(yesterday) );
    this.newsletterService.queryParams.setFilterValue('tillDate', DateUtils.dateToText(today) );
    this.newsletterService.queryParams.setFilterValue('channels', Object.keys(ChannelTypeNames) );
    this.newsletterService.queryParams.setFilterValue('sourceType', Object.keys(SourceTypeNames) );
    this.reset();
  }

  getList(params) {
    if (!params?.filter?.fromDate || !params?.filter?.tillDate)
      return;

    this.newsletterService.query$(params)
      .subscribe( result => {
        this.newsletterList = this.newsletterList.concat(result);
      });
  }


  onScroll() {
    this.newsletterService.queryParams.next();
    this.getList(this.newsletterService.queryParams.params)
  }

  reset() {
    this.newsletterService.queryParams.reset();
    this.newsletterList=[];
    this.getList(this.newsletterService.queryParams.params);
  }


  getDetailsList(params ) {

    if (typeof this.detailsId === "undefined")
      return;

    this.newsletterService
      .details$(this.detailsId,params)
      .subscribe( result => {
        this.detailsList = this.detailsList.concat(result);
      });
  }

  onScrollDetails() {
    this.newsletterService.queryParams.next();
    this.getList(this.newsletterService.queryParams.params)
  }

  resetDetails(id) {
    if (this.detailsId === id) {
      delete this.detailsId;
      return;
    }

    this.detailsId = id
    this.newsletterService.detailsParams.reset();
    this.detailsList=[];
    this.getDetailsList(this.newsletterService.detailsParams.params);
  }

  getStateNames(detailItem) {
    return NewsletterTaskExternalStateNames[detailItem.externalState] || detailItem.externalState;
  }


}
