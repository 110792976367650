/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LocationApiConfiguration as __Configuration } from '../location-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationQueryResponse } from '../models/location-query-response';
import { LocationQueryParam } from '../models/location-query-param';
@Injectable({
  providedIn: 'root',
})
class LocationApiService extends __BaseService {
  static readonly createLocationPath = '/location';
  static readonly queryLocationsPath = '/location';
  static readonly reindexLocationsPath = '/location/reindex';
  static readonly getLocationByByIdPath = '/location/{id}';
  static readonly deleteLocationByIdPath = '/location/{id}';
  static readonly updateLocationByIdPath = '/location/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создать новый магазин
   * @param location undefined
   * @return Магазин создан
   */
  createLocationResponse(location: Location): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = location;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * Создать новый магазин
   * @param location undefined
   * @return Магазин создан
   */
  createLocation(location: Location): __Observable<Location> {
    return this.createLocationResponse(location).pipe(
      __map(_r => _r.body as Location)
    );
  }

  /**
   * Вернуть список магазинов
   * @param params Фильтры запроса
   * @return Список магазинов
   */
  queryLocationsResponse(params?: LocationQueryParam): __Observable<__StrictHttpResponse<Array<LocationQueryResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationQueryResponse>>;
      })
    );
  }
  /**
   * Вернуть список магазинов
   * @param params Фильтры запроса
   * @return Список магазинов
   */
  queryLocations(params?: LocationQueryParam): __Observable<Array<LocationQueryResponse>> {
    return this.queryLocationsResponse(params).pipe(
      __map(_r => _r.body as Array<LocationQueryResponse>)
    );
  }

  /**
   * Переиндексировать список магазинов
   */
  reindexLocationsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/location/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать список магазинов
   */
  reindexLocations(): __Observable<null> {
    return this.reindexLocationsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Получить описание магазина по ID
   * @param id ID
   * @return Описание магазина
   */
  getLocationByByIdResponse(id: number): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/location/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * Получить описание магазина по ID
   * @param id ID
   * @return Описание магазина
   */
  getLocationByById(id: number): __Observable<Location> {
    return this.getLocationByByIdResponse(id).pipe(
      __map(_r => _r.body as Location)
    );
  }

  /**
   * Удалить магазин по ID
   * @param id ID
   */
  deleteLocationByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/location/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить магазин по ID
   * @param id ID
   */
  deleteLocationById(id: number): __Observable<null> {
    return this.deleteLocationByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Обновить данные магазина по ID
   * @param params The `LocationApiService.UpdateLocationByIdParams` containing the following parameters:
   *
   * - `location`:
   *
   * - `id`: ID
   *
   * @return Магазин обновлен
   */
  updateLocationByIdResponse(params: LocationApiService.UpdateLocationByIdParams): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.location;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/location/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * Обновить данные магазина по ID
   * @param params The `LocationApiService.UpdateLocationByIdParams` containing the following parameters:
   *
   * - `location`:
   *
   * - `id`: ID
   *
   * @return Магазин обновлен
   */
  updateLocationById(params: LocationApiService.UpdateLocationByIdParams): __Observable<Location> {
    return this.updateLocationByIdResponse(params).pipe(
      __map(_r => _r.body as Location)
    );
  }
}

module LocationApiService {

  /**
   * Parameters for updateLocationById
   */
  export interface UpdateLocationByIdParams {
    location: Location;

    /**
     * ID
     */
    id: number;
  }
}

export { LocationApiService }
