import 'model/FrontendSettingsModel/resource';
import { getNg1Injector, ng1RootElement } from "class/MigrationUtils";

interface UserStorageApi {

    set( values: Array<UserSettingsItem>): Promise<any>;
    get( values : Array<UserRequestSettingsItem> ) : Promise<Array<UserSettingsItem>>;
    remove( key: string ): Promise<any>;

}

class LocalStorage implements  UserStorageApi {
    public set( values: Array<UserSettingsItem> = [] )  {

        values.forEach( i => {

            if ( typeof i.userId === "undefined" )
                i.userId = <any>"";

            localStorage.setItem( `${i.userId}.${i.key}`, JSON.stringify(i.value) );
        });

        return Promise.resolve( values );
    }

    public get( values : Array<UserRequestSettingsItem> = [] )  {

        return Promise.resolve(
            values.map( i  => {
                const userSetting = localStorage.getItem( `${i.userId || ''}.${i.key}` );

                if ( !userSetting ) {
                    return undefined;
                }

                return <UserSettingsItem> {
                    userId: i.userId,
                    key: i.key,
                    value: JSON.parse(userSetting)
                };
            }).filter(i => !!i)
        );

    }

    public remove( key: string )  {
        return Promise.resolve( localStorage.setItem( key, '') );
    }
}

class ServiceStorage implements  UserStorageApi {

    private FrontendSettings: any;

    get isValid() : boolean {

        if (this.FrontendSettings)
            return true;

        this.initService();

        return !!this.FrontendSettings;
    }

    private initService() {
        try {
            this.FrontendSettings = getNg1Injector().get('FrontendSettingsModel');
        } catch(e) {
            this.FrontendSettings = undefined;
        }
    }

    public set( values: Array<UserSettingsItem> )  {
        if ( !this.isValid )
            return Promise.reject();

        return this.FrontendSettings.add( values )
                   .$promise;
    }

    public get( values : Array<UserRequestSettingsItem> = [] )  {
        if ( !this.isValid )
            return Promise.reject();

        return this.FrontendSettings.query( values )
                .$promise;
    }

    public remove( key: string )  {
        return Promise.resolve();
    }
}

export interface UserSettingsItem {
    userId?: number,
    key    : string,
    value  : any
}

export interface UserRequestSettingsItem {
    userId ?: number,
    key    : string
}


export class UserSettingsStorageService {

    protected static _instance :UserSettingsStorageService;

    private localStorageInstance: UserStorageApi;
    private serviceStorageInstance: ServiceStorage;

    constructor() {

        if (UserSettingsStorageService._instance) {
            return UserSettingsStorageService._instance;
        }

        this.localStorageInstance = new LocalStorage();
        this.serviceStorageInstance = new ServiceStorage();

        UserSettingsStorageService._instance = this;
    }

    public static getInstance() :UserSettingsStorageService {
        if (UserSettingsStorageService._instance) {
            return UserSettingsStorageService._instance;
        }
        return UserSettingsStorageService._instance = new UserSettingsStorageService;
    }

    get( key: string | Array<string>, isForAll?: boolean ) : Promise<any> {

        let stringKey = '';
        if ( typeof key === "string" ) {
            stringKey = key;
            key = [key];
        }

        return this.getSettings(key)
              .then( this.filterByKey(stringKey));
    }

    set( keyOrArray: String | Array<UserSettingsItem>, value?: any, isForAll?: boolean) : Promise< any | Array<UserSettingsItem> > {

        let stringKey = '';
        if ( typeof keyOrArray === "string" ) {
            stringKey = keyOrArray;
            keyOrArray = [
                isForAll ?
                    {
                        key: keyOrArray,
                        value: value
                    }:
                    {
                        userId: this.getCurrentUserId(),
                        key: keyOrArray,
                        value: value
                    }
            ];
        }

        return this
            .setArraySettings( keyOrArray as Array<UserSettingsItem>)
            .then( this.filterByKey(stringKey))

    }


    private getStorage() : UserStorageApi  {

        if ( this.serviceStorageInstance.isValid ) {
            return this.serviceStorageInstance;
        }

        return this.localStorageInstance ;

    }

    private filterByKey(stringKey ?: string) {
        return (value : Array<UserSettingsItem>) => {

            if (!stringKey)
                return value;

            const  filtred = value.filter( i => i.key === stringKey );
            return filtred.length ? filtred[0].value : undefined;
        };
    }

    private setArraySettings( values:Array<UserSettingsItem> = []) : Promise<Array<UserSettingsItem>> {
        return this.getStorage().set(values);
    }

    private getSettings( key : Array<string> = [] , isForAll?: boolean ) : Promise<Array<UserSettingsItem>> {

        let currentUserId = this.getCurrentUserId();
        let requestKeys: Array<UserRequestSettingsItem> = [];

        key.forEach( key => {

            if (isForAll) {
                requestKeys.push({
                    key: key
                });
            } else {
                requestKeys.push({
                    key: key,
                    userId: currentUserId
                });
            }
        });

        return this.getStorage().get( requestKeys );

    }

    private getCurrentUserId( ) : number {

        // TODO Грязный хак. Переделать, когда будет синглтон юзера
        const $rootScope = (<any>window).angular.element( ng1RootElement() ).scope().$root;
        return $rootScope.currentUser.id;
    }

}

export const UserSettingsStorage = new UserSettingsStorageService();
