/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AddressApiConfiguration as __Configuration } from '../address-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RawAddress } from '../models/raw-address';
import { SearchAddress } from '../models/search-address';
@Injectable({
  providedIn: 'root',
})
class AddressApiService extends __BaseService {
  static readonly createProfilePath = '/{templateId}/{clientId}';
  static readonly getProfilePath = '/{templateId}/{clientId}';
  static readonly updateProfilePath = '/{templateId}/{clientId}';
  static readonly deleteProfilePath = '/{templateId}/{clientId}';
  static readonly searchAddressPath = '/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание профиля клиента
   * @param params The `AddressApiService.CreateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  createProfileResponse(params: AddressApiService.CreateProfileParams): __Observable<__StrictHttpResponse<RawAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.profile;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawAddress>;
      })
    );
  }
  /**
   * Создание профиля клиента
   * @param params The `AddressApiService.CreateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  createProfile(params: AddressApiService.CreateProfileParams): __Observable<RawAddress> {
    return this.createProfileResponse(params).pipe(
      __map(_r => _r.body as RawAddress)
    );
  }

  /**
   * Запрос профиля клиента
   * @param params The `AddressApiService.GetProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getProfileResponse(params: AddressApiService.GetProfileParams): __Observable<__StrictHttpResponse<RawAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawAddress>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param params The `AddressApiService.GetProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getProfile(params: AddressApiService.GetProfileParams): __Observable<RawAddress> {
    return this.getProfileResponse(params).pipe(
      __map(_r => _r.body as RawAddress)
    );
  }

  /**
   * Обновление профиля клиента
   * @param params The `AddressApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  updateProfileResponse(params: AddressApiService.UpdateProfileParams): __Observable<__StrictHttpResponse<RawAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.profile;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawAddress>;
      })
    );
  }
  /**
   * Обновление профиля клиента
   * @param params The `AddressApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  updateProfile(params: AddressApiService.UpdateProfileParams): __Observable<RawAddress> {
    return this.updateProfileResponse(params).pipe(
      __map(_r => _r.body as RawAddress)
    );
  }

  /**
   * Удаление профиля клиента
   * @param params The `AddressApiService.DeleteProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   */
  deleteProfileResponse(params: AddressApiService.DeleteProfileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление профиля клиента
   * @param params The `AddressApiService.DeleteProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   */
  deleteProfile(params: AddressApiService.DeleteProfileParams): __Observable<null> {
    return this.deleteProfileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос профиля клиента
   * @param params ID профиля
   * @return Данные профиля клиента
   */
  searchAddressResponse(params: SearchAddress): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param params ID профиля
   * @return Данные профиля клиента
   */
  searchAddress(params: SearchAddress): __Observable<Array<string>> {
    return this.searchAddressResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }
}

module AddressApiService {

  /**
   * Parameters for createProfile
   */
  export interface CreateProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * Данные профиля клиента
     */
    profile: RawAddress;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for getProfile
   */
  export interface GetProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for updateProfile
   */
  export interface UpdateProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * Данные профиля клиента
     */
    profile: RawAddress;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for deleteProfile
   */
  export interface DeleteProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { AddressApiService }
