import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule, NgbModule, NgbNavModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {AccauntsIndexPageComponent} from "./index/accaunts-index-page.component";
import {downgradeComponent} from "@angular/upgrade/static";
import {AccauntsEditPageComponent} from "./edit/accaunts-edit-page.component";
import {
  AccountOperationsLogEditPageComponent
} from "./shared/component/accountOperationsLog/accountOperationsLog-edit-page.component";
import {
  CreaditAccountOperationsLogEditPageComponent
} from "./shared/component/creaditAccountOperationsLog/creaditAccountOperationsLog-edit-page.component";
import {AccountEditEditPageComponent} from "./shared/component/accountEdit/accountEdit-edit-page.component";
import {AccountMergeEditPageComponent} from "./shared/component/accountMerge/accountMerge-edit-page.component";

@NgModule({
  declarations: [
    AccauntsIndexPageComponent,
    AccauntsEditPageComponent,
    AccountOperationsLogEditPageComponent,
    CreaditAccountOperationsLogEditPageComponent,
    AccountEditEditPageComponent,
    AccountMergeEditPageComponent
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    HttpClientModule,
    InfiniteScrollModule,
    CommonModule,
    SharedComponentsModule,
    PipesComponentsModule,
    DirectiveModule,
    NgbNavModule,
    NgbPopoverModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class AccauntsPageModule {

  constructor() {}

}

angular.module('loya.builder')
  .directive('accauntsIndexPage', downgradeComponent({ component: AccauntsIndexPageComponent }) )
  .directive('accauntsEditPage', downgradeComponent({ component: AccauntsEditPageComponent }) )

