export class ClientCoupons {

    // Передаваемые параметры в компонент
    public clientId   : number;
    public state      : string;

    public couponList : Array<any>;
    public _c : any;

    static $inject = ['Coupon' ];

    constructor(
            private Coupon: any,
        ) {
    }

    public $onInit() {

        if ( typeof this.clientId !== 'undefined' ) {
            this.getCoupons( this.clientId );
        }

        this._c = new this.Coupon();
    }

    public $onChanges( changesObj : any) {

        if ( changesObj.clientId ) {
            this.getCoupons( changesObj.clientId.currentValue );
        }

    }

    public getCoupons( clientId: number ) {

        var params = {};
        params['filter.clientId'] = clientId;
        params['filter.states']   = 'partialApply,active,partialReturn,fullyReturn';
        params['sorting.field']   = 'tillDate';
        params['sorting.order']   = 'desc';
        params['pager.drop']      = 0;
        params['pager.limit']     = 6;


        return this.Coupon
            .query(params)
            .$promise
            .then( (data: Array<any>) => {

                let now:number = Math.floor( ( new Date() ).setHours(0, 0, 0, 0) / 1000 );

                return data.filter(function ( item ) {
                    return Math.floor( <any>new Date(item.tillDate) / 1000 ) >= now;
                })
            })
            .then( (data: Array<any>) => this.couponList = data );

    }

};
