<div class="fixed-tool-panel">
 <div class="d-flex align-items-center">

   <div class="col-auto p-l-none">

      <ng-container
        *ngIf="isRefresh && hasRefreshSegments"
      >
        <i class="fa fa-spin fa-spinner text-warning m-l-xs m-r-sm"></i>
        <span class="text-muted">Обновление состава покупателей</span>
      </ng-container>

     <ng-container
       *ngIf="isDraft && hasRefreshSegments"
     >
       <i class="fa fa-spin fa-spinner text-warning m-l-xs m-r-sm"></i>
       <span class="text-muted">Пересегментирование состава покупателей</span>
     </ng-container>

   </div>

   <div class="col">
      <button *ngIf="isDeploy && !isUpdatingProcess"
              type="button"
              class="btn btn-addon btn-sm btn-success btn-addon  collapse-xs pull-right"
              (click)="refreshClients()"
              data-testid="segment.refreshClients()">
        <i class="fa fa-refresh">
        </i>
        <span class="btn-name m-l-xs  ">
          Обновить состав покупателей
        </span>
      </button>

      <button type="button"
              *ngIf="isDeploy && !isUpdatingProcess"
              class="btn btn-addon btn-sm btn-warning btn-addon collapse-xs pull-right "
              [uiSref]="'^.resegment'"
              data-testid="segment.resegment()"
      >
        <i class="fa fa-exclamation"></i>
        <span class="btn-name m-l-xs  ">
          Пересегментировать
        </span>
      </button>

      <button type="button" class="btn btn-sm btn-default btn-addon  pull-right"
              *ngIf="isRefresh && !isUpdatingProcess"
              [disabled]="hasRefreshSegments"
              (click)="deploy()"
              data-testid="segment.deploy()">
        <i class=" fa fa-check text-primary text">
        </i>
        <span class="btn-name m-l-xs  ">Опубликовать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default btn-addon  pull-right"
              *ngIf="isDraft && !isUpdatingProcess"
              [disabled]="hasRefreshSegments"
              (click)="deploy(deployConfirm)"
              data-testid="segment.deploy()">
        <i class=" fa fa-check text-primary text">
        </i>
        <span class="btn-name m-l-xs  ">Опубликовать</span>
      </button>


      <button type="button" class="btn btn-sm btn-default btn-addon  pull-right"
              *ngIf="( isRefresh || isDraft ) && !isUpdatingProcess"
              (click)="cancel()"
              data-testid="segment.cancel()"
              [disabled]="hasRefreshSegments"
              id="backDeploy"
      >
        <i class=" fa fa-remove text-danger"></i>
        <span class="btn-name m-l-xs">
          Отменить
        </span>
      </button>
   </div>
 </div>
</div>

<ng-container
  *ngIf="!isUpdatingProcess"
>

 <responsive-table>
   <table
     class="table b-b "
    >
     <thead>
       <tr>
         <th></th>
         <th>
           <sort-view
             column="name"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Название, описание
          </sort-view>
         </th>
         <th class="text-center">
           <sort-view
             column="clientCount"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Количество покупателей
           </sort-view>
         </th>
         <th
           class="text-center"
         >
           <sort-view
             [column]="SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Процент кол-ва покупателей
           </sort-view>
         </th>
         <th
           *ngIf="isRefresh"
           class="text-center"
         >
           <sort-view
             column="clientCountGrowth"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Изменение кол-ва покупателей
           </sort-view>
         </th>
         <th class="text-right">
           <sort-view
             column="lifeValue"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Ценность
           </sort-view>
         </th>
         <th class="text-center">
           <sort-view
             [column]="SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Процент от общей ценности
           </sort-view>
         </th>
         <th
           *ngIf="isRefresh"
           class="text-center"
         >
           <sort-view
             column="lifeValueGrowth"
             [params]="segmentationService.getListParams"
             (onChange)="changeSort()"
           >
             Изменение ценности
           </sort-view>
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let segment of segmentsList ">
          <tr data-testid="segment.item of segmentsList">

            <td width="2%"
                class="text-center">

                <i class="fa fa-spin fa-spinner text-warning fa-2x"
                   *ngIf="segment.completeness==='started' || segment.completeness==='unknown'"
                ></i>

                <i class="fa fa-warning text-danger fa-2x"
                   *ngIf="segment.completeness==='error'"
                ></i>

                <i class="icon-segment i1"
                   *ngIf="( isDeploy || isRefresh ) && (!segment.completeness || segment.completeness==='finished')"
                ></i>

                <i class="icon-segment i0"
                   *ngIf="isDraft && (!segment.completeness || segment.completeness==='finished')"></i>

            </td>

            <td class="table-big-link">

              <a href [uiSref]="'^.view( {id:' + segment.id + ', state:' +segment.state + '} )'"
                 [innerText]="segment.name"
                 class="m-b-sm"
                 data-testid="segment.name"
              ></a>

              <div class="m-b-sm text-muted text-xs"
                   [innerText]="segment.description"
              ></div>

            </td>

            <td class="table-big-link text-center">

              <a href [uiSref]="'^.clients( {id:' + segment.id + ', type: \'segment\', state:\'' + segment.state + '\' } )'" data-testid="segment.clientCount"
                 [innerText]="segment.clientCount"
              ></a>
            </td>

            <td class=" text-center pointer"
                (click)="calculator.switch(segment?.id)"
            >

                <div class="m-b-xs">
                  <span
                    class="m-r-xs"
                data-testid="segment.clientPercent"
                    [innerText]="calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) | loyaNumber : 2 "
                  >
                  </span>%
                </div>

                <sparkline-graph

                  [data]="[
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT),
                    100 - calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT)
                  ]"

                  [colors]="[
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) < 10 ? LOYA_CONFIG.color.danger :
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) < 25  ? LOYA_CONFIG.color.warning :
                    LOYA_CONFIG.color.success
                  ]"

                ></sparkline-graph>

            </td>

            <td class="text-center"
                *ngIf="isRefresh"
            >
              <div class="m-b-xs">
                 <span
                   class="m-r-xs"
                   data-testid="segment.clientCountGrowth"
                   [innerText]="segment.clientCountGrowth | loyaNumber : 2 "
                 >
                  </span>%
              </div>
              <i class="fa f-s-30px m-l-xs m-r-xs"
                 [ngClass]="{ 'fa-caret-up text-success':segment.clientCountGrowth>0, 'fa-caret-down text-danger':segment.clientCountGrowth<0  }"></i>
            </td>

            <td class="text-right"
            >

              <div class="m-b-xs">

                <ng-container
                  *ngIf="isDeploy"
                  data-testid="segment.totalPeriod"
                >
                  {{ segment?.period0?.total  | loyaCurrency }}
                </ng-container>

                <ng-container
                  *ngIf="!isDeploy"
                  data-testid="segment.lifeValue"
                >
                  {{ segment.lifeValue | loyaCurrency }}
                </ng-container>

              </div>

              <i class="fa f-s-30px m-l-xs m-r-xs"
                 [ngClass]="{ 'fa-caret-up text-success':segment.totalGrowth>0, 'fa-caret-down text-danger':segment.totalGrowth<0  }"></i>

            </td>

            <td class="text-center pointer"
                (click)="calculator.switch(segment?.id)"
            >
              <div class="m-b-xs">
                <span
                  class="m-r-xs"
                  data-testid="segment.totalPercent"
                  [innerText]="calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) | loyaNumber : 2  "
                >
                  </span>%
              </div>
              <sparkline-graph

                [data]="[
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT),
                    100 - calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT)
                  ]"

                [colors]="[
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) < 10 ? LOYA_CONFIG.color.danger :
                    calculator.value(segment?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) < 25  ? LOYA_CONFIG.color.warning :
                    LOYA_CONFIG.color.success
                  ]"

              ></sparkline-graph>

            </td>

            <td class="text-center"
                *ngIf="isRefresh"
            >
              <div class="m-b-xs">
                 <span
                   class="m-r-xs"
                   data-testid="segment.lifeValueGrowth"
                   [innerText]="segment.lifeValueGrowth | loyaNumber : 2 "
                 >
                  </span>%
              </div>
              <i class="fa f-s-30px m-l-xs m-r-xs"
                 [ngClass]="{ 'fa-caret-up text-success':segment.lifeValueGrowth>0, 'fa-caret-down text-danger':segment.lifeValueGrowth<0  }"></i>

            </td>

          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

</ng-container>

<div class="d-flex justify-content-center align-items-center m-t-lg"
     *ngIf="isUpdatingProcess"
>
  <div class="text-warning m-t-lg">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>


<ng-template #deployConfirm let-modal>

  <div class="modal-header wrapper-xs padder bg-light">
    <span class="modal-title">Обновление состава кампаний</span>
  </div>
  <div class="modal-body">
    <div class="no-padder">
      Обновить состав кампаний, аудитория которых сформирована по <br> сегментам ?
    </div>
  </div>
  <div class="modal-footer b-t bg-light dk ">
    <button class="btn btn-success btn-sm" type="button" (click)="modal.close(true)" data-testid="segment.modal.yes()">Да</button>
    <button class="btn btn-danger btn-sm" type="button" (click)="modal.dismiss()" data-testid="segment.modal.no()">Нет</button>
  </div>

</ng-template>
