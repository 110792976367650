'use strict';

import 'model/ExternalTaskModel/resource';

export default ['$scope', '$state', '$uibModal', '$compile', '$stateParams', '$timeout', '$interval', 'ExternalTaskModel', 'task', '$q', 'taskModules', '$controller', '$templateCache',
    function ($scope, $state, $uibModal, $compile, $stateParams, $timeout, $interval, ExternalTaskModel, task, $q, taskModules, $controller, $templateCache) {

        $scope.pluginsNames = {};
        for (let key of Object.keys(taskModules)) {

            if ( taskModules[key].readOnly ) continue;

            $scope.pluginsNames[key] = taskModules[key] ? taskModules[key].name : '-' ;

        }

        $scope.canDelete = false;
        $scope.setCanDelete = result => $scope.canDelete = result;

        $scope.task = task;
        $scope._t = new ExternalTaskModel();

        var backupTask;
        var formStates = {
            view: {
                buttons: {},
                activeForm: false
            },

            edit: {
                buttons: {
                    'save': function () {
                        return $scope.updateTask();
                    },

                    'cancel': function () {

                        if (backupTask) {
                            $scope.task = angular.copy(backupTask);
                        }

                        $scope.updateState();

                    }
                },
                activeForm: true
            },

            create: {
                buttons: {

                    'save': function () {
                        return $scope.saveTask();
                    },

                    'cancel': function () {

                        $state.go('^.index');

                    }
                },
                activeForm: true

            },

            ping: {
                buttons: {

                    'edit': function () {
                        backupTask = angular.copy($scope.task);
                        $scope.setFormState('edit');
                    },

                    'delete': function () {
                        return $scope.removeTask();
                    }
                }
            },

            active: {
                buttons: {}
            },

            error: {
                buttons: {

                    'restart': function () {
                        return $scope.runAccountType();
                    },

                    'delete': function () {
                        return $scope.removeTask();
                    }
                }
            }

        };

        $scope.setFormState = function (state) {

            if (formStates[state] !== undefined) {
                $scope.curState = formStates[state];
                $scope.curState.stateName = state;

            } else
                $scope.curState = false;

        };

        $scope.pluginCtrl = angular.noop;

        let pluginScope: any = false;
        let loadPlugin = (controller, template) => {

            if (pluginScope) {
                pluginScope.$destroy();
            }

            pluginScope = $scope.$new();
            var templateCtrl = $controller(controller, {
                $scope: pluginScope
            });

            angular.element('#pluginContainer').html($templateCache.get(template))

            var compiled = $compile(angular.element('#pluginContainer'))(pluginScope);

        }

        $scope.removeTask = function() {
            $scope.task.$delete(() => {
                $state.go('^.index');
            });
        }

        $scope.saveTask = function () {

            return $scope.task.$create().then(function (data) {

                return $q(resolve => {

                    let options = {
                        chain: Promise.resolve(data),
                        task: data
                    }

                    $scope.$broadcast('task.create', options);

                    options.chain.then(() => {

                        $state.go('^.view', {id: data.id});
                        resolve(data);

                    }, angular.noop);

                });

            });


        };

        $scope.toGreed = function () {
            $state.go('^.index');
        };

        let isDestroyed = false;
        $scope.$on('$destroy', function () {
            isDestroyed = true;
        });

        $scope.$on('task.update', function () {

            if (!angular.isUndefined($scope.task.id)) {
                $scope.task.$get();
            }

        });

        $scope.$watch('task.pluginName', function (newValue) {

            if (!newValue || !taskModules[newValue]) {
                return;
            }

            $scope.canDelete = false;

            loadPlugin(
                taskModules[newValue].controller,
                angular.isObject(taskModules[newValue].template) ? taskModules[newValue].template : taskModules[newValue].template
            );
        });

        if (angular.isUndefined($scope.task.id)) {
            $scope.setFormState('create');
        } else {
            $scope.setFormState('view');
        }


        $scope.setSettings = (newSettings) => {

            if (!$scope.task || angular.isUndefined($scope.task.id)) {
                return Promise.reject();
            }

            return $q((resolve, reject) => {

                    let newSetting = angular.extend(
                        {},
                        $scope.task && $scope.task.settings ? $scope.task.settings : {},
                        newSettings || {}
                    );

                    ExternalTaskModel
                        .setSettings({id: $scope.task.id}, newSetting)
                        .$promise.then(() => {
                          //  ExternalTask.get({id: $scope.task.id}, ( task ) => {
                            //    $scope.task = task;
                                $scope.task.settings = newSetting;
                                resolve( $scope.task );
                          //  }, reject);
                    }, reject);
                }
            )
        }


        let updateTask = (resolve, reject) => {
            ExternalTaskModel.get({id: $scope.task.id}, ( task ) => {
                $scope.task = task;
                if ($scope.task.state !== "runing" /*&& $scope.task.state !== "start"*/) {
                    return resolve($scope.task);
                } else {
                    if (!isDestroyed)
                        $timeout(() => updateTask(resolve, reject), 1000);
                }
            }, reject);

        }



        let lastRunCommand = {
            command : '',
            promise : $q.resolve()
        };

        $scope.runCommand = (command) => {

            if (!$scope.task || angular.isUndefined($scope.task.id)) {
                return Promise.reject();
            }

            if ( lastRunCommand.command  === command &&
                lastRunCommand.promise.$$state.status === 0 ) {
                return lastRunCommand.promise;
            }

            lastRunCommand.command = command;
            lastRunCommand.promise =  new $q((resolve, reject) => {

                if (command) {
                    command = {
                        "command": command
                    };
                }

                ExternalTaskModel
                    .run({id: $scope.task.id}, command, () => {

                        updateTask( resolve, reject );

                    }, reject);


            });

            lastRunCommand.promise.catch( data => {
                $scope.task.errorTxt = angular.isObject(data) && data.data ? data.data : data;
                $scope.task.state = 'error';
            });

            return lastRunCommand.promise;
        }

        let updateRunning = (resolve, reject) => {

            if (!isDestroyed)
                return;

            if ( $scope.task && $scope.task.state === "runing" ) {

                if ( lastRunCommand.promise &&
                     lastRunCommand.promise.$$state &&
                     lastRunCommand.promise.$$state.status === 0 ) {

                    $timeout( () => updateRunning(resolve, reject), 2000 );

                } else {
                    ExternalTaskModel.get( {id: $scope.task.id}, ( task ) => {
                        $scope.task = task;
                        $timeout( () => updateRunning(resolve, reject), 1000 );
                    }, reject);

                }

            }

        }

        if ($scope.task.state === "runing") {
            updateRunning(angular.noop, angular.noop);
        }

    }];
