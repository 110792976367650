import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {LOYA_CONFIG} from "../../variables/loya-config.variable";
import Highcharts from "highcharts/highcharts.src";

@Component({
  selector: 'sparkline-graph',
  templateUrl: './sparkline-graph.component.html',
  styles:[
    `
      :host {
        display: inline-block;
      }

      highcharts-chart {
        display: inline-block;
      }
    `
  ]
})
export class SparklineGraphComponent implements OnInit, OnChanges{

    @Input() data: any[]  = [];
    @Input() colors: any[] = [];

    @Input() height = 30;

    public Highcharts: typeof Highcharts = Highcharts;
    public chartConfig :Highcharts.chart = {

      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        backgroundColor: null,
        plotShadow: false,
        borderWidth: 0,
        type: 'pie',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        width: this.height,
        height: this.height,
        style: {
          overflow: 'visible'
        },

        skipClone: true
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        hideDelay: 0,
        outside: false,
        shared: false,
        headerFormat: '',
        pointFormat: '{point.name}',
      },
      plotOptions: {
        pie: {
          animation: false,
          /*{
            defer: 0,
            duration: 100,
          },*/
          allowPointSelect: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
          size: '100%',
          slicedOffset: 0,
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      colors: Array.apply(null, {length: 10}).map( ()=> '#eaeaea' ),
      series: [{
        colorByPoint: true,
        data: [{
          name: '100%',
          y: 100,
        }]
      }]
    };

    public update = false;

    constructor() { }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges) {

      if (changes['data']) {
        this.parseData(changes['data']?.currentValue)
      }

      if (changes['colors']) {
        this.chartConfig.colors =
          ( changes['colors']?.currentValue || [] ).concat(
            Array.apply(null, {length: 10}).map( ()=> '#eaeaea' )
          );
      }

      if (changes['height']) {
        this.chartConfig.chart.width  = changes['height'].currentValue;
        this.chartConfig.chart.height = changes['height'].currentValue;
      }

      this.update = true;
    }

    public parseData(values) {

      if ( !Array.isArray(values) ) {
        this.chartConfig.series = [{
          colorByPoint: true,
          data: [{
            name: '100%',
            y: 100,
          }]
        }];
        return
      }

      this.chartConfig.series = [{
        colorByPoint: true,
        data: []
      }];

      values.forEach( i => {
        this.chartConfig.series[0].data.push({
          name: i + '%',
          y: i,
        })
      })

    }


}
