import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {MechanicService} from "../../service/api/Mechanic/Mechanic.service";
import {pipe, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";
import {InformingTemplateService} from "../../service/api/InformingTemplate/InformingTemplate.service";
import {InformingTemplateApiService} from "../../../api/InformingTemplateApi/services/informing-template-api.service";
import {PluginInstallService} from "../../service/api/PluginInstall/PluginInstall.service";
import {ControlContainer, NgForm} from "@angular/forms";
import {typeofExpr} from "@angular/compiler/src/output/output_ast";

@Component({
  selector: 'select-informing-template',
  templateUrl: './select-informing-template.component.html',
  styleUrls: ['./select-informing-template.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SelectInformingTemplateComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();

  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  drop    = 0;
  limit   = 20;
  isValid = true;

  isEmpty = false;

  digestSubscribe;

  constructor(
    private informingTemplateService: InformingTemplateService,
    private pluginInstallService: PluginInstallService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }
  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.validate();
  }

  pagerReset() {
    this.drop = 0;
    this.isEmpty = false;
  }


  pagerNext( searchText:string | boolean ) {

    let params : InformingTemplateApiService.QueryInformingTemplatesParams = {
      drop  : this.drop,
      limit : this.limit
    }

    if (searchText) {
      params.search = <string>searchText;
    }
/*

    if (typeof this.id !== "undefined") {
      params.selectedId = this.id;
    }
*/

    if (this.isEmpty) {
      return of([]);
    }

    return this.informingTemplateService
                    .query$(params)
                    .pipe(
                      tap( data => {
                        this.isEmpty = !this.drop && !data?.length;
                        this.drop += data.length;
                        return data;
                      }),
                      switchMap(result =>
                        !params?.drop ?  this.pluginInstallService
                                .informing$()
                                .pipe(
                                  map( ( result:any ) => result
                                    .filter( i => ["System"].indexOf( i.name ) < 0)
                                    .map( i => ({
                                          id: i,
                                          name: i.description
                                      })
                                    )
                                  ),
                                  map( informList => (informList || [] ).concat(result) )
                                )
                          : of(result)

                      ),
                      map( r => {
                        r
                          .filter( i => typeof i?.id === "number" )
                          .forEach( i => {
                            this.filteredItems = this.filteredItems.filter( f => f?.id?.name !== i?.plugin );
                            r = r.filter( f => f?.id?.name !== i?.plugin );
                          })
                        return r;
                      }),
                      tap( r => {
                        this.checkDigest();
                        return r;
                      })
                    );

  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }

  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}
