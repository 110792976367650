/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for GoodGroupLinkCategoriesApi services
 */
@Injectable({
  providedIn: 'root',
})
export class GoodGroupLinkCategoriesApiConfiguration {
  rootUrl: string = location.origin;
}

export interface GoodGroupLinkCategoriesApiConfigurationInterface {
  rootUrl?: string;
}
