'use strict';

import module from 'frontendModule';

import 'directive/mechanicCustomField/main';


let routePath = module.name + '.campaign.mechanics.master';


export default
    ['$scope', '$state', '$rootScope', '$timeout', '$compile', '$templateCache', '$controller', 'isReadOnly', 'currentTemplate', 'pluginItem', 'modulesStorage', 'campaignItem',
      function ($scope, $state, $rootScope, $timeout, $compile,  $templateCache, $controller, isReadOnly, currentTemplate, pluginItem, modulesStorage, campaignItem) {

        $scope.informingTemplate = currentTemplate;
        $scope.pluginItem        = pluginItem;
        $scope.campaignItem      = campaignItem;
        $scope.isReadOnly        = isReadOnly;

        $scope.createView = function() {

            if ( angular.isUndefined( $scope.informingTemplate.plugin ) )
              return false;

            var promises = [];

            var response = modulesStorage.get($scope.informingTemplate.plugin.replace(/Plugin$/,''), 'campaign.informing.config');

            if (!response)
                response = modulesStorage.get($scope.informingTemplate.plugin, 'campaign.informing.config');

            if (!response) {

              response = modulesStorage.get($scope.informingTemplate.plugin, 'campaign.informing.config');

              if (!response)
                return;
            }

            var controller = response.controller,
              view = $templateCache.get(response.templateUrl);

            var localScope = $scope.$new();
            localScope.informingTemplate = $scope.informingTemplate;

            if (controller)
              $controller( controller , { $scope: localScope });

            angular.element('#informingTemplateContainer').html( view );
            $compile( angular.element('#informingTemplateContainer').contents() )( localScope );

        }

        $scope.$on('$viewContentLoaded',$scope.createView );

        $scope.saveTemplate = _=> {

            $scope.informingTemplate.status = "campaign";
            $scope.informingTemplate.$updateInformingTemplateById( _=> $state.go( 'frontend.campaign.campaigns.view' , {id: $scope.campaignItem.campaign.id} ) );

        }

        $scope.cancelTemplate = _=> {

          $state.go( 'frontend.campaign.campaigns.view' , {id: $scope.campaignItem.campaign.id} );

        }


      }];
