import {pathToRealPath} from "../input/helpers/modelRealPath";

class GridBlock {

    public label    : string;
    public icon     : string;
    public settings : any;

  static $inject = ['$transclude'];

    public isEdit : boolean;
    public onlyInput : boolean = false;


    constructor(private $transclude: ng.ITranscludeFunction ) {
    }

    public $onInit() {
        this.fillFieldsFromSettings();
    }

    private fillFieldsFromSettings() {

        if (!this.settings)  return;

        this.label = this.label || this.settings.displayName || this.settings.label  ;

    }
}

export default window['angular'].module('loya.builder').component('gridBlock', {

    template    : `
    <span ng-if="::!$ctrl.onlyInput">
        <div class="form-item">
            <div class="form-label">
                <i class="fa" ng-class="::$ctrl.icon"></i>
                <span ng-bind="::$ctrl.label" data-testid="item.label"></span>
            </div>
            <div class="form-value">
                <ng-transclude></ng-transclude>
                <div ng-if="!$ctrl.isEdit"
                    ng-transclude="viewSlot"
                ></div>

                <div ng-if=" $ctrl.isEdit" class="editable-rec" ng-transclude="editSlot"></div>
            </div>
        </div>
        <div class="br-fix"></div>
    </span>
    <span ng-if="::$ctrl.onlyInput">
        <span ng-if="!$ctrl.isEdit" ng-transclude="viewSlot"></span>
        <span ng-if=" $ctrl.isEdit" ng-transclude="editSlot"></span>
    </span>
`,

    controller  : GridBlock,
    bindings    : {
        label    : '<',
        icon     : '@',
        settings : '<',
        isEdit   : '<',
        onlyInput : '<'
    },

    transclude: {
        'editSlot': '?edit',
        'viewSlot': '?view'
    },

});

