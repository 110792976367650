/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ProcessingProxyApiConfiguration as __Configuration } from '../processing-proxy-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RawCheck } from '../models/raw-check';
import { CheckDetails } from '../models/check-details';
@Injectable({
  providedIn: 'root',
})
class ProcessingProxyApiService extends __BaseService {
  static readonly preCheckPath = '/precheck';
  static readonly paymentTryPath = '/paymenttry';
  static readonly checkConfirmPath = '/checkconfirm';
  static readonly checkCancelPath = '/checkcancel';
  static readonly preCheckDetailsPath = '/precheckdetails';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос precheck
   *
   * Запрос precheck
   * @param check Описание чека
   * @return Чек обработан
   */
  preCheckResponse(check: RawCheck): __Observable<__StrictHttpResponse<RawCheck>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = check;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/precheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawCheck>;
      })
    );
  }
  /**
   * Запрос precheck
   *
   * Запрос precheck
   * @param check Описание чека
   * @return Чек обработан
   */
  preCheck(check: RawCheck): __Observable<RawCheck> {
    return this.preCheckResponse(check).pipe(
      __map(_r => _r.body as RawCheck)
    );
  }

  /**
   * Запрос paymenttry
   *
   * Запрос paymenttry
   * @param check Описание чека
   * @return Чек обработан
   */
  paymentTryResponse(check: RawCheck): __Observable<__StrictHttpResponse<RawCheck>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = check;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/paymenttry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawCheck>;
      })
    );
  }
  /**
   * Запрос paymenttry
   *
   * Запрос paymenttry
   * @param check Описание чека
   * @return Чек обработан
   */
  paymentTry(check: RawCheck): __Observable<RawCheck> {
    return this.paymentTryResponse(check).pipe(
      __map(_r => _r.body as RawCheck)
    );
  }

  /**
   * Запрос checkconfirm
   *
   * Запрос checkconfirm
   * @param check Описание чека
   */
  checkConfirmResponse(check: RawCheck): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = check;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checkconfirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Запрос checkconfirm
   *
   * Запрос checkconfirm
   * @param check Описание чека
   */
  checkConfirm(check: RawCheck): __Observable<null> {
    return this.checkConfirmResponse(check).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос checkcancel
   *
   * Запрос checkcancel
   * @param check Описание чека
   */
  checkCancelResponse(check: RawCheck): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = check;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checkcancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Запрос checkcancel
   *
   * Запрос checkcancel
   * @param check Описание чека
   */
  checkCancel(check: RawCheck): __Observable<null> {
    return this.checkCancelResponse(check).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос precheckdetails
   *
   * Запрос precheckdetails
   * @param check Описание чека
   * @return Чек обработан
   */
  preCheckDetailsResponse(check: RawCheck): __Observable<__StrictHttpResponse<CheckDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = check;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/precheckdetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckDetails>;
      })
    );
  }
  /**
   * Запрос precheckdetails
   *
   * Запрос precheckdetails
   * @param check Описание чека
   * @return Чек обработан
   */
  preCheckDetails(check: RawCheck): __Observable<CheckDetails> {
    return this.preCheckDetailsResponse(check).pipe(
      __map(_r => _r.body as CheckDetails)
    );
  }
}

module ProcessingProxyApiService {
}

export { ProcessingProxyApiService }
