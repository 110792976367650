import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ITreeOptions, TreeComponent, TreeNode} from "@circlon/angular-tree-component";
import {VIEW_TYPES} from "../../goods-tree.variables";

const INTERMEDIATE_FIELD = "$INTERMEDIATE$";

@Component({
  selector: 'tree-view',
  templateUrl: "./tree-view.component.html",
  styleUrls: ['./tree-view.component.scss' ],
  providers: [
  ]
})
 export class TreeViewComponent implements  OnInit, OnChanges{

    @Input() nodes;
    @Input() showType;
    @Output() getChildren = new EventEmitter();

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Output() getNext = new EventEmitter();

    @Input() searchQuery: string;
    @Input() selectOne = false

    @Input() editCallback;

    @Input() isLoading = false;

    get isEditMode() {
      return typeof this.editCallback === "function"
    };

    @ViewChild(TreeComponent)
    private treeComponent: TreeComponent;

    public options:ITreeOptions = {}

    static END_FLAG_ID = '$isEndedGoods';


  constructor(
    )
    {}

    get isSearchState() {
      return !!this.searchQuery;
    }

    ngOnInit() {
      this.initOptions()
    }

    ngOnChanges(changes: SimpleChanges) {
    /*  if (changes['nodes'])
        this.updateTree();*/
    }

    public isCheckboxShow(node) {
        if (!!this.selectOne || this.isEditMode)
          return  false;

        if ( (this.showType === VIEW_TYPES.CATEGORY) && this.isNodeFolder(node) ) {
          return true;
        }

        if ( (this.showType === VIEW_TYPES.SKU) && this.isNodeSku(node) ) {
          return true;
        }

      return false;
    }

    public isRadioShow(node) {
      if (!this.selectOne || this.isEditMode)
        return  false;

      if ( (this.showType === VIEW_TYPES.CATEGORY) && this.isNodeFolder(node) ) {
        return true;
      }

      if ( (this.showType === VIEW_TYPES.SKU) && this.isNodeSku(node) ) {
        return true;
      }

      return false;
    }

    private initOptions() {

      this.options.getChildren = (node:TreeNode) =>
        new Promise( (resolve, reject) => {
          this.getChildren.emit({
            resolve,
            reject,
            node
          });
        })

      this.updateTree();
    }

    public updateTree( expandAll = false ) {

      if (!expandAll) {
        this.treeComponent?.treeModel?.collapseAll();
      }

      this.redraw();

      if (expandAll) {
        setTimeout(IO => {
          this.treeComponent?.treeModel?.expandAll();
        } )
      }

    }

    public redraw() {
      this.treeComponent?.treeModel?.update();
    }

    isNodeFolder(node) {
      return !!node?.data?.category
    }

    isNodeSku(node) {
      return !!node?.data?.good
    }

    isLastNode(node: TreeNode) {

      if (!this.isNodeSku(node))
        return false;

      if (node?.parent?.getLastChild() !== node)
        return false;

      if (!!node?.parent?.data?.$isEndedGoods)
        return false

     //  let total = node?.parent?.data?.category?.goodCount || 0;


      return true;
      /*
      if (!total)
        return false;

      let count = node?.parent?.children?.filter( i => this.isNodeSku(i)).length;

      return total !== count;*/
    }

    resetIntermediateCacheForParent(node){

      if (this.isIndeterminateEnabled(node)) {
        delete node.data[INTERMEDIATE_FIELD];
        let parent = node?.parent;
        while ( !!parent ) {
          delete parent.data[INTERMEDIATE_FIELD];
          parent = parent?.parent;
        }

      }

    }

    changeSelected(node: TreeNode, $event?) {

      switch (true) {

        case this.isSubIndeterminate(node):
          if (this.changeSubIndeterminateSelected(node)) {

            if ($event && !!$event?.target) {
              $event.target.checked = false;
            }

          }
          break;

        case this.isIndeterminate(node):
          this.disableSubFolder(node);

        case this.isNodeFolder(node):
          this.changeSelectedFolder(node);
          break

        default:
          if (this.selected.has(node.id)) {
            this.selected.delete(node.id)
          } else {

            if (this.selectOne)
              this.selected.clear();

            this.selected.add(node.id);
          }
      }

      this.resetIntermediateCacheForParent(node);
      this.selectedChange.emit(this.selected);

    }

    changeSubIndeterminateSelected(node: TreeNode) {

      this.selected.delete(node?.id);

      if (!node?.parent )
        return false;

      if (!this.isSubIndeterminate(node?.parent) && !this.isChecked(node?.parent))
        return false

      this.selected.delete(node?.parent?.id);

      node.parent.children.forEach( i => {
        if (i === node || !this.isNodeFolder(i))
          return;

        this.selected.add(i.id);
      })

      this.changeSubIndeterminateSelected(node.parent);

      return true;
    }

    disableSubFolder( node: TreeNode ) {

      let disableSubFolderiterate = (subNode: TreeNode) => {

          if (!this.isNodeFolder(subNode))
            return false;

          if (subNode!==node) {
            this.selected.delete(subNode.id);
            delete node.data[INTERMEDIATE_FIELD];
          }

          subNode?.children?.forEach( i => {
            disableSubFolderiterate(i);
          });
        }

      disableSubFolderiterate(node);
    }

    changeSelectedFolder(node: TreeNode) {

      delete node.data[INTERMEDIATE_FIELD];

      if (this.selected.has(node.id)) {
        this.selected.delete(node.id)
      } else {
        if (this.selectOne)
          this.selected.clear();

        this.selected.add(node.id);
      }

      this.disableSubFolder(node);

    }

    editClick(node: TreeNode) {

      if (!this.editCallback)
        return;

      this.editCallback({
        type: this.isNodeSku(node) ? VIEW_TYPES.SKU : VIEW_TYPES.CATEGORY,
        id: node.id
      })

    }

    getIcon(node: TreeNode) {

      switch (true) {
        /*case node.loading :
          return 'fa fa-spinner fa-spin ';*/
        case this.isNodeFolder( node ) && (node.data.category.goodCount > 0 || node.data.category.categoryCount > 0)  :
          return 'fa text-success fa-folder' + ((node.isExpanded || this.isSearchState) ? '-open ' : '' );
        case this.isNodeFolder( node ) && (node.data.category.goodCount == 0 && node.data.category.categoryCount == 0) :
          return 'fa text-muted fa-folder' + ((node.isExpanded || this.isSearchState) ? '-open-o ' : '-o' );
        case this.isNodeSku(node):
          return node.data?.good?.dimension === 'weight' ? 'fa-balance-scale fa text-info ' : 'fa-tag fa text-info ';
      }

    };

    getUniqNodeName(node: TreeNode) {
      return escape(`goodPopupInput_${this.showType}_${node.id}`);
    }

    isChecked( node: TreeNode ) {
      return this.selected.has(node.id);
    }

    isIndeterminateEnabled(node: TreeNode) {
      return this.showType === VIEW_TYPES.CATEGORY && this.isNodeFolder(node);
    }

    isIndeterminate(node: TreeNode) {

      if ( !this.isIndeterminateEnabled(node) || this.isChecked(node) )
        return false;

      return node?.children?.some( i => {
      /*  if ( typeof i.data[INTERMEDIATE_FIELD] !== "undefined" )
          return i.data[INTERMEDIATE_FIELD];

        i.data[INTERMEDIATE_FIELD] = this.isChecked(i) || this.isIndeterminate(i) || false;
        return i.data[INTERMEDIATE_FIELD]*/
        return this.isChecked(i) || this.isIndeterminate(i) || false;
      });

    }

    isSubIndeterminate(node) {

      if ( !this.isIndeterminateEnabled(node) || this.isChecked(node)  )
        return false;


      if ( node?.parent && ( this.isChecked(node?.parent) || this.isSubIndeterminate(node?.parent)) ) {
        return true
      }

      return false;

    }

}

