import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";
import {LocationsService} from "../../../../../shared/service/api/Locations/Locations.service";
import {Location} from "../../../../../api/LocationApi/models/location";

import "./locations-group-index-page.legacy";
import {LocationGroupService} from "../../../../../shared/service/api/LocationGroup/LocationGroup.service";
import {LocationGroup} from "../../../../../api/LocationGroupApi/models/location-group";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";

@Component({
  selector: 'locations-group-index-page',
  templateUrl: './locations-group-index-page.component.html',
  providers:[
    LocationsService,
    LocationGroupService,
  ]
})
export class LocationsGroupIndexPageComponent implements OnInit{

  searchString: string;
  listLocationGroups: Array<LocationGroup> =[]
  rolesList = {};

  public selectedPartner;
  public selectedLocationGroup: LocationGroup;

  public createCounter = 0;

  public loader = new LoaderHandlerClass();

  constructor(
    public locationsService: LocationsService,
    public locationGroupService: LocationGroupService
  ) {
  }

  ngOnInit() {
  }

  getList(params) {

    if ( !this.selectedPartner || this.locationGroupService.getListParams.isPagerEnded || this.loader.isLoading('listLocationGroups') )
      return;

    this.locationGroupService.getList$(params)
      .pipe(
        this.loader.waitLoading('listLocationGroups'),
        this.locationGroupService.getListParams.pagerPipe()
      )
      .subscribe( result => {

        this.listLocationGroups = this.listLocationGroups.concat(result);

        if ( !this.selectedLocationGroup || !this.listLocationGroups?.some( i => i?.id === this.selectedLocationGroup?.id) )
            this.selectedLocationGroup = (!!this.listLocationGroups?.length) ? this.listLocationGroups[0] : undefined;

      }, () => {
      });
  }

  onScroll() {
    this.locationGroupService.getListParams.next();
    this.getList(this.locationGroupService.getListParams.params)
  }

  reset() {
    this.locationGroupService.getListParams.reset();
    this.listLocationGroups=[];
    this.getList(this.locationGroupService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.locationGroupService.getListParams.search(this.searchString);
    this.reset();
  }

  changeSelectedPartner( inPartner ) {
    this.selectedPartner = inPartner;
    this.locationGroupService.getListParams.setFilterValue('partnerId', !!this.selectedPartner ? this.selectedPartner?.id : undefined)
    this.onSearchChange('');
  }

  selectLocationGroup(locationGroup) {
    this.selectedLocationGroup = locationGroup;
  }

  onAddClick(value?) {
    this.selectedLocationGroup = undefined;
    this.createCounter++;
  }

  onDelete(event : any = {}) {
    this.listLocationGroups = this.listLocationGroups.filter( i => i.id !== event?.id)
    if (this.selectedLocationGroup?.id === event?.id)
      this.selectedLocationGroup = undefined;
  }

  onCreate(event : any = {}) {
    this.listLocationGroups.unshift( event )
    this.selectedLocationGroup = event;
  }

  onUpdate(event : any = {}) {
    this.listLocationGroups = this.listLocationGroups.map( i => i?.id === event?.id ? event : i );
    this.selectedLocationGroup = this.listLocationGroups.find( i => i?.id === event?.id )
  }
}
