/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CardApiConfiguration as __Configuration } from '../card-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Card } from '../models/card';
import { RawCard } from '../models/raw-card';
import { CardState } from '../models/card-state';
@Injectable({
  providedIn: 'root',
})
class CardApiService extends __BaseService {
  static readonly createCardPath = '/card';
  static readonly queryCardsPath = '/card';
  static readonly searchCardsPath = '/card/search';
  static readonly getCardPath = '/card/{cardId}';
  static readonly deleteCardPath = '/card/{cardId}';
  static readonly getCardByNumberPath = '/card/number/{number}';
  static readonly reindexCardsPath = '/card/reindex';
  static readonly deleteCardListPath = '/card/deletelist';
  static readonly attachCardPath = '/card/attach/{cardId}/{clientId}';
  static readonly detachCardPath = '/card/detach/{cardId}';
  static readonly changeCardStatusPath = '/card/status/{cardId}/{status}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание карты
   *
   * Создание карты
   * @param params The `CardApiService.CreateCardParams` containing the following parameters:
   *
   * - `card`:
   *
   * - `prefix`:
   *
   * - `generator`:
   *
   * @return Созданная карта
   */
  createCardResponse(params: CardApiService.CreateCardParams): __Observable<__StrictHttpResponse<Card>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.card;
    if (params.prefix != null) __params = __params.set('prefix', params.prefix.toString());
    if (params.generator != null) __params = __params.set('generator', params.generator.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/card`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Card>;
      })
    );
  }
  /**
   * Создание карты
   *
   * Создание карты
   * @param params The `CardApiService.CreateCardParams` containing the following parameters:
   *
   * - `card`:
   *
   * - `prefix`:
   *
   * - `generator`:
   *
   * @return Созданная карта
   */
  createCard(params: CardApiService.CreateCardParams): __Observable<Card> {
    return this.createCardResponse(params).pipe(
      __map(_r => _r.body as Card)
    );
  }

  /**
   * Запрос карт
   *
   * Запрос карт
   * @param params The `CardApiService.QueryCardsParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     * `asc` - по возрастанию
   *     * `desc` - по убыванию
   *
   * - `sorting.field`: Поле сортировки:
   *     * `number` - номер карты
   *     * `state` - статус карты
   *     * `createDate` - дата создания карты
   *     * `clientFio` - ФИО клиента, привязанного к карте
   *     * `userName` - имя пользователя, создавшего карту
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 10)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * - `filter.states`: Фильтр по статусам карт
   *
   * - `filter.clientId`: Фильтр по коду клиента
   *
   * @return Список карт
   */
  queryCardsResponse(params: CardApiService.QueryCardsParams): __Observable<__StrictHttpResponse<Array<RawCard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    (params.filterStates || []).forEach(val => {if (val != null) __params = __params.append('filter.states', val.toString())});
    if (params.filterClientId != null) __params = __params.set('filter.clientId', params.filterClientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/card`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawCard>>;
      })
    );
  }
  /**
   * Запрос карт
   *
   * Запрос карт
   * @param params The `CardApiService.QueryCardsParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     * `asc` - по возрастанию
   *     * `desc` - по убыванию
   *
   * - `sorting.field`: Поле сортировки:
   *     * `number` - номер карты
   *     * `state` - статус карты
   *     * `createDate` - дата создания карты
   *     * `clientFio` - ФИО клиента, привязанного к карте
   *     * `userName` - имя пользователя, создавшего карту
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 10)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * - `filter.states`: Фильтр по статусам карт
   *
   * - `filter.clientId`: Фильтр по коду клиента
   *
   * @return Список карт
   */
  queryCards(params: CardApiService.QueryCardsParams): __Observable<Array<RawCard>> {
    return this.queryCardsResponse(params).pipe(
      __map(_r => _r.body as Array<RawCard>)
    );
  }

  /**
   * Поиск карт
   *
   * Поиск карт
   * @param params The `CardApiService.SearchCardsParams` containing the following parameters:
   *
   * - `usePrefix`: Поиск по префиксу
   *
   * - `state`: Фильтр по статусам карт
   *
   * - `sentence`: Строка поиска
   *
   * - `limit`: Сколько записей вернуть (по умолчанию 20)
   *
   * - `drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return Список карт
   */
  searchCardsResponse(params: CardApiService.SearchCardsParams): __Observable<__StrictHttpResponse<Array<RawCard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.usePrefix != null) __params = __params.set('usePrefix', params.usePrefix.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/card/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawCard>>;
      })
    );
  }
  /**
   * Поиск карт
   *
   * Поиск карт
   * @param params The `CardApiService.SearchCardsParams` containing the following parameters:
   *
   * - `usePrefix`: Поиск по префиксу
   *
   * - `state`: Фильтр по статусам карт
   *
   * - `sentence`: Строка поиска
   *
   * - `limit`: Сколько записей вернуть (по умолчанию 20)
   *
   * - `drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return Список карт
   */
  searchCards(params: CardApiService.SearchCardsParams): __Observable<Array<RawCard>> {
    return this.searchCardsResponse(params).pipe(
      __map(_r => _r.body as Array<RawCard>)
    );
  }

  /**
   * Запрос карты по ID
   * @param cardId ID карты
   * @return Карта по ID
   */
  getCardResponse(cardId: number): __Observable<__StrictHttpResponse<Card>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/card/${encodeURIComponent(String(cardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Card>;
      })
    );
  }
  /**
   * Запрос карты по ID
   * @param cardId ID карты
   * @return Карта по ID
   */
  getCard(cardId: number): __Observable<Card> {
    return this.getCardResponse(cardId).pipe(
      __map(_r => _r.body as Card)
    );
  }

  /**
   * Удаление карты по  ID
   * @param cardId ID карты
   */
  deleteCardResponse(cardId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/card/${encodeURIComponent(String(cardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление карты по  ID
   * @param cardId ID карты
   */
  deleteCard(cardId: number): __Observable<null> {
    return this.deleteCardResponse(cardId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Проверка существования номера карты
   * @param number Номер карты
   * @return Карта по ID
   */
  getCardByNumberResponse(number: string): __Observable<__StrictHttpResponse<Card>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/card/number/${encodeURIComponent(String(number))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Card>;
      })
    );
  }
  /**
   * Проверка существования номера карты
   * @param number Номер карты
   * @return Карта по ID
   */
  getCardByNumber(number: string): __Observable<Card> {
    return this.getCardByNumberResponse(number).pipe(
      __map(_r => _r.body as Card)
    );
  }

  /**
   * Переиндексация карт
   */
  reindexCardsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/card/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексация карт
   */
  reindexCards(): __Observable<null> {
    return this.reindexCardsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление списка карт
   * @param cardIds Список ID карт для удаления
   */
  deleteCardListResponse(cardIds: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = cardIds;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/card/deletelist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление списка карт
   * @param cardIds Список ID карт для удаления
   */
  deleteCardList(cardIds: Array<number>): __Observable<null> {
    return this.deleteCardListResponse(cardIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Привязка карты
   * @param params The `CardApiService.AttachCardParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `cardId`: ID карты
   */
  attachCardResponse(params: CardApiService.AttachCardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/card/attach/${encodeURIComponent(String(params.cardId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязка карты
   * @param params The `CardApiService.AttachCardParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `cardId`: ID карты
   */
  attachCard(params: CardApiService.AttachCardParams): __Observable<null> {
    return this.attachCardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Отвязка карты
   * @param cardId ID карты
   */
  detachCardResponse(cardId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/card/detach/${encodeURIComponent(String(cardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Отвязка карты
   * @param cardId ID карты
   */
  detachCard(cardId: number): __Observable<null> {
    return this.detachCardResponse(cardId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Обновление статуса карты
   * @param params The `CardApiService.ChangeCardStatusParams` containing the following parameters:
   *
   * - `status`: Статус карты
   *
   * - `cardId`: ID карты
   */
  changeCardStatusResponse(params: CardApiService.ChangeCardStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/card/status/${encodeURIComponent(String(params.cardId))}/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновление статуса карты
   * @param params The `CardApiService.ChangeCardStatusParams` containing the following parameters:
   *
   * - `status`: Статус карты
   *
   * - `cardId`: ID карты
   */
  changeCardStatus(params: CardApiService.ChangeCardStatusParams): __Observable<null> {
    return this.changeCardStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CardApiService {

  /**
   * Parameters for createCard
   */
  export interface CreateCardParams {
    card: Card;
    prefix?: string;
    generator?: string;
  }

  /**
   * Parameters for queryCards
   */
  export interface QueryCardsParams {

    /**
     * Порядок сортировки:
     *   * `asc` - по возрастанию
     *   * `desc` - по убыванию
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле сортировки:
     *   * `number` - номер карты
     *   * `state` - статус карты
     *   * `createDate` - дата создания карты
     *   * `clientFio` - ФИО клиента, привязанного к карте
     *   * `userName` - имя пользователя, создавшего карту
     */
    sortingField?: 'number' | 'state' | 'createDate' | 'clientFio' | 'userName';

    /**
     * Сколько записей вернуть (по умолчанию 10)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить (по умолчанию 0)
     */
    pagerDrop?: number;

    /**
     * Фильтр по статусам карт
     */
    filterStates?: Array<CardState>;

    /**
     * Фильтр по коду клиента
     */
    filterClientId?: number;
  }

  /**
   * Parameters for searchCards
   */
  export interface SearchCardsParams {

    /**
     * Поиск по префиксу
     */
    usePrefix?: boolean;

    /**
     * Фильтр по статусам карт
     */
    state?: string;

    /**
     * Строка поиска
     */
    sentence?: string;

    /**
     * Сколько записей вернуть (по умолчанию 20)
     */
    limit?: number;

    /**
     * Сколько записей пропустить (по умолчанию 0)
     */
    drop?: number;
  }

  /**
   * Parameters for attachCard
   */
  export interface AttachCardParams {

    /**
     * ID клиента
     */
    clientId: number;

    /**
     * ID карты
     */
    cardId: number;
  }

  /**
   * Parameters for changeCardStatus
   */
  export interface ChangeCardStatusParams {

    /**
     * Статус карты
     */
    status: string;

    /**
     * ID карты
     */
    cardId: number;
  }
}

export { CardApiService }
