import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule, NgbModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {TranslocoRootModule} from "../../../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../../shared/directive/directive.module";
import {AccountTransactionIndexPageComponent} from "./index/account-transaction-index-page.component";
import {AccountTransactionByUserPageComponent} from "./by-user/account-transaction-by-user-page.component";
import {UIRouter} from "angular-ui-router";


@NgModule({
  declarations: [
    AccountTransactionIndexPageComponent,
    AccountTransactionByUserPageComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    HttpClientModule,
    TranslocoRootModule,
    InfiniteScrollModule,
    CommonModule,
    SharedComponentsModule,
    PipesComponentsModule,
    DirectiveModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class TransactionModule {

  constructor() {}

}
