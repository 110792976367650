import {Component, ElementRef, Input, OnInit, AfterContentInit} from "@angular/core";

@Component({
  selector: 'responsive-table',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./responsive-table.component.scss'],

})
export class ResponsiveTableComponent implements OnInit, AfterContentInit {

    private table: HTMLTableElement;
    private counters: {
      [key: string]: Set<number>;
    } = {};

    constructor(
      private el: ElementRef,
    ) {
    }

    public ngOnInit() {

    }

    public ngAfterContentInit() {
        this.findTable();
        if (!this.table)
            return;

        this.initSizeCounters();
        this.createCssVariables();
        this.createClasses();
    }

    private findTable() {
      this.table = this.el.nativeElement.querySelector('table')
    }


    private initSizeCounters() {

      let firstRow = this.table.querySelectorAll('tr:first-child th');

      if (!firstRow.length) {
        firstRow = this.table.querySelectorAll('tr:first-child td');
        if (!firstRow.length)
          return
      }

      this.counters[''] = new Set();

      firstRow.forEach( (node, index) => {
        index+=1;
        this.counters[''].add(index);
        ( node.className.match(/table-row-[a-zA-Z]*/ig) || [] ).forEach( className => {
          className = className.replace('table-row-','');
            this.counters[className] = this.counters[className] || new Set();
            this.counters[ className].add(index);
        });

        if ( node.tagName.toLowerCase() === 'th' ) {
          this.table.style.setProperty('--header-row-'+( index ), `'${node.textContent}:'` );
        }

      })

    }

    private createCssVariables() {

      if (!this.table)
        return;

      for( let size of ['', 'xs','sm','md','lg','xl'] ) {
        this.table.style.setProperty('--grid-rows'+(size === '' ? '': '-' ) + size, size === '' ? this.counters[size].size.toString() : ( this.counters[''].size - (size in this.counters ? this.counters[size].size : 0 ) ) .toString() );
      }

    }

    private createClasses() {

      if (!this.table)
        return;


      for( let size in this.counters ) {

        if (size === '') continue;

        this.counters[size].forEach(id => {
          this.table.classList.add(`row-${size}-${id}`);
        });

      }

    }
}
