'use strict';

import module from 'frontendModule';

import 'angular-footable';
import 'model/MechanicCategoryModel/resource';

let routePath   = module.name + '.triggers.trigger.transactions';
module.requires = module.requires.concat([
  'ui.footable',

]);

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id/:action')

        .template(   '<triggers-transactions-page [id]="id" [action]="action" ></triggers-transactions-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) {
          $scope.id = $stateParams.id;
          $scope.action = $stateParams.action;
        }])

        .breadcrumbs(  [ "Цепочки", "Транзакции" ] );

    }])
;
