import {NgModule} from "@angular/core";
import {LoyaCurrencyPipe} from "./loya-currency/loya-currency.pipe";
import {LoyaDatetimePipe} from "./loya-datetime/loya-datetime.pipe";
import {LoyaDatePipe} from "./loya-datetime/loya-date.pipe";
import {LoyaTimePipe} from "./loya-datetime/loya-time.pipe";
import {LoyaNumberPipe} from "./loya-number/loya-number.pipe";
import {CallbackPipe} from "./callback/callback.pipe";
import {CallbackSortPipe} from "./callbackSort/callbackSort.pipe";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {UpgradeModule} from "@angular/upgrade/static";
import {TranslocoRootModule} from "../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgSelectModule} from "@ng-select/ng-select";


@NgModule({
  declarations: [
    LoyaCurrencyPipe,
    LoyaDatetimePipe,
    LoyaDatePipe,
    LoyaTimePipe,
    LoyaNumberPipe,
    CallbackPipe,
    CallbackSortPipe,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
    TranslocoRootModule,
    InfiniteScrollModule,
    NgSelectModule
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports:[
    LoyaCurrencyPipe,
    LoyaDatetimePipe,
    LoyaDatePipe,
    LoyaTimePipe,
    LoyaNumberPipe,
    CallbackPipe,
    CallbackSortPipe,
  ]
})
export class PipesComponentsModule {

  constructor() {}

}
