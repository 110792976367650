import {Component, EventEmitter, Injectable, Input, OnDestroy, OnInit} from "@angular/core";

import {UserApiService} from "../../../api/UserApi/services"
import {RawUser} from "../../../api/UserApi/models"
import {Observable, from, of} from "rxjs";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'ngbd-modal-content',
  template: `
      <div class="modal-header wrapper-xs padder bg-light ng-scope">
        <h4 class="modal-title ng-binding" [innerText]="title"></h4>
      </div>
      <div class="modal-body overflow-wrap-break ng-scope">
        <div class="no-padder ng-binding" [innerText]="message"></div>
      </div>
      <div class="modal-footer b-t bg-light dk ng-scope">
        <button class="btn btn-success ng-binding" type="button" (click)="okEvent()" ng-click="okEvent()" [innerText]="ok">Ок</button>
        <button class="btn btn-danger ng-binding" type="button" (click)="cancelEvent()" ng-click="cancelEvent()" [innerText]="cancel">Отмена</button>
      </div>
  `
})
export class NgbdModalContent {
  @Input() title = 'Подтвердите действие';
  @Input() message;
  @Input() ok = 'Ок';
  @Input() cancel = 'Отмена';


  constructor(public activeModal: NgbActiveModal) {}

  okEvent() {
    this.activeModal.close('Ok');
  }

  cancelEvent() {
    this.activeModal.close('Cancel');
  }
}





@Injectable({
  providedIn: 'root',
})
export class ConfirmActionService {

  constructor(
    private modalService: NgbModal
  ) {

  }

  public confirm(question, callback) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.message = question;
    modalRef.result.then( result => {
      if (result === 'Ok')
        callback();
    })

  }


}
