'use strict';

import module from 'frontendModule';
let routePath   = module.name + '.config.directory.campaigncategorylimitations.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<campaigncategorylimitations-index-page></campaigncategorylimitations-index-page>', true)
        .breadcrumbs( [ "Справочники", "Коллизии групп кампаний" ] );

    }]);
