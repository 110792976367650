import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {getNg1Service} from "../class/utils/angularjs.utils";

interface HttpErrorResponseWithPopup extends HttpErrorResponse {
  stopPopupError: () => boolean;
  setError: (text?, header?) => boolean;
}

@Injectable()
export class HttpErrorCatchingInterceptor implements HttpInterceptor {
  private toaster;
  constructor() {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponseWithPopup) => {
        let errorText, errorHeader;
        let timeoutId = setTimeout(() => {

          let resultText = errorText || error.error || error.message || error.statusText;
/*

          if (
            typeof error?.error === "string" &&
            error?.error?.toLowerCase() !== 'internal server error'
          )
            resultText = error.error;
*/

          this.toaster = getNg1Service('toaster');
          this.toaster.error(
            errorHeader || 'Ошибка',
            resultText
          );
        }, 20);

        error.stopPopupError = () => {
          timeoutId && clearTimeout(timeoutId);
          return true;
        };

        error.setError = (text?, header?) => {
          errorText = text;
          errorHeader = header || 'Ошибка';
          return true;
        };

        return throwError(error);
      })
    );
  }
}
