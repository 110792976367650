<responsive-table>
  <table class="table m-b-none b-b "
         infiniteScroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         [infiniteScrollContainer]="'#app-container'"
         [fromRoot]="true"
         (scrolled)="onScroll()"
  >
    <thead>
      <tr>

        <th class="text-center">
          <sort-view
            column="date"
            [params]="creditAccountService.getRecordsParams"
            (onChange)="reset()"
          >
            Дата операции
          </sort-view>
        </th>

        <th class="text-right">Сумма<br/> операции</th>

        <th class="text-right">Кредитный<br/> баланс</th>

        <th style=" width: 50% ">Тип операции</th>

      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let postingRow of creditHistory; let index = index" >

        <tr data-testid="postingRow in creditHistory">

          <td class="table-big-link text-center"
              data-testid="postingRow.creditHistory.date.time">
            <span class="" data-testid="postingRow.creditHistory.date">{{ postingRow.date | loyaDate }}</span>
            <span class="clearfix text-muted f-s-8 m-t-n-xxs" data-testid="postingRow.creditHistory.time">{{ postingRow.date | loyaTime }}</span>
          </td>

          <td class="table-big-link footable-visible text-right" data-testid="postingRow.creditHistory.amount">
            {{ postingRow.amount }}
          </td>

          <td class="table-big-link footable-visible text-right " data-testid="postingRow.creditHistory.balance">
            {{ postingRow.balance }}
          </td>

          <td >

            <span
              class="m-t-xxs m-b-n-xxs  pull-left" *ngIf="postingRow.recType === 'manual'"
              [ngbPopover]="'Ручная корректировка'"
              [triggers]="'mouseclick'"
            >
                <i class="fa fa-user text-success m-r-xs m-t-xxs m-b-n-xxs"></i>
            </span>

            <span *ngIf="postingRow.recType === 'manual'">

                <a (click)="postingRow.$moreCollapse=!postingRow.$moreCollapse">
                     {{ ACCOUNT_OPER_TYPE[postingRow.record.operType]}}
                  <i class="fa link text-muted"
                     [ngClass]="{ 'fa-angle-down':!postingRow.$moreCollapse, 'fa-angle-up':postingRow.$moreCollapse }"></i>
                </a>

                <div *ngIf="postingRow.$moreCollapse" class="f-s-9 m-t-sm">

                    <div class="clearfix">
                        <span class=" text-muted m-r-xs">Оператор:</span>
                        <span class="">{{ postingRow?.user?.name }}</span>
                    </div>


                    <div class="clearfix" style="max-width: 300px;">
                        <span class=" text-muted m-r-xs">Причина:</span>
                        <span class=" word-break-all"  [innerHTML]="getDescription( postingRow.record.description, postingRow.record )"></span>
                    </div>

                </div>

            </span>


            <span *ngIf="postingRow.recType !== 'manual'">

                <a (click)="postingRow.$moreDescCollapse=!postingRow.$moreDescCollapse"
                   *ngIf="postingRow.description"
                >
                     {{ ACCOUNT_OPER_TYPE[postingRow.operType] || postingRow.operType }}
                  <i class="fa link text-muted"
                     [ngClass]="{ 'fa-angle-down':!postingRow.$moreDescCollapse, 'fa-angle-up':postingRow.$moreDescCollapse }"></i>
                </a>

                <span *ngIf="!postingRow.description" data-testid="postingRow.creditHistory.operType">
                     {{ ACCOUNT_OPER_TYPE[postingRow.operType] || postingRow.operType }}
                </span>

                <div *ngIf="postingRow.description && postingRow.$moreDescCollapse"
                     class="f-s-9 m-t-sm">

                    <div class="clearfix">
                        <span class=" text-muted m-r-xs">Причина:</span>
                        <span class=" word-break-all">{{ postingRow.description }}</span>
                    </div>

                </div>

            </span>

          </td>


        </tr>

        <tr *ngIf="!!postingRow.checkNumber "
            class="bg"
        >
          <td colspan="100" class="no-padder  pos-rlt">

            <i class="fa fa-exchange f-s-12 text-muted m-l-sm pos-abt" style="
                                         top: -10px;left: 7px;transform:rotate(90deg);
                                      "></i>

            <div class="clearfix f-s-8  wrapper-sm p-l-md"
                 [ngClass]="{'pointer': !!postingRow.storageCheckId}"
                 (click)="showCheck(postingRow.storageCheckId, index)"
                 data-testid="receipt.show()"
                 *ngIf="!isCheckShow(index)"
            >
                              <span>Чек: №
                                  <span
                                    [innerText]="postingRow.checkNumber"
                                    [ngClass]="{'text-info': !!postingRow.storageCheckId}"
                                    data-testid="receiptForShow.receipt.number"

                                  ></span>
                                  <i class="fa fa-angle-down"
                                     *ngIf="!!postingRow.storageCheckId"
                                     [ngClass]="{'fa-angle-down': !isCheckShow(index), 'fa-spin fa-spinner text-warning': checkForShowIndex === index && loader.isLoading('check') }"
                                  ></i>
                              </span>
            </div>


            <div
              class="clearfix wrapper-sm"
              *ngIf="isCheckShow(index)"
            >
              <div class="card m-b-none">

                <div class="card-header pointer f-s-8"
                     (click)="checkForShowIndex = undefined"
                     data-testid="receipt.hide()">
                  Чек: №
                  <span
                    [innerText]="checkForShow.check.number"
                    class="font-bold"
                    data-testid="receiptForShow.receipt.number"
                  ></span>
                  <i class="fa fa fa-angle-up pull-right fa-lg "></i>
                </div>
                <div class="card-body no-padder">
                  <div class="b-default m-l-lg b-l p-t-md p-r p-b-xxs">
                    <receipt-view
                      [model]="checkForShow"
                      [showClient]="true"
                    ></receipt-view>
                  </div>
                </div>

              </div>
            </div>

          </td>
        </tr>

      </ng-container>
    </tbody>

  </table>
</responsive-table>
