/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ReportSkuDiscountsApiConfiguration as __Configuration } from '../report-sku-discounts-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportData } from '../models/report-data';
import { ReportParameters } from '../models/report-parameters';
@Injectable({
  providedIn: 'root',
})
class ReportSkuDiscountsApiService extends __BaseService {
  static readonly buildReportPath = '/report-sku-discounts/build-report';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Построение отчёта «По продажам товаров со скидками»
   *
   * Построение отчёта «По продажам товаров со скидками»
   * @param reportParameters Параметры отчёта
   * @return Данные отчёта
   */
  buildReportResponse(reportParameters: ReportParameters): __Observable<__StrictHttpResponse<ReportData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reportParameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/report-sku-discounts/build-report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportData>;
      })
    );
  }
  /**
   * Построение отчёта «По продажам товаров со скидками»
   *
   * Построение отчёта «По продажам товаров со скидками»
   * @param reportParameters Параметры отчёта
   * @return Данные отчёта
   */
  buildReport(reportParameters: ReportParameters): __Observable<ReportData> {
    return this.buildReportResponse(reportParameters).pipe(
      __map(_r => _r.body as ReportData)
    );
  }
}

module ReportSkuDiscountsApiService {
}

export { ReportSkuDiscountsApiService }
