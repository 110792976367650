'use strict';

import module from 'frontendModule';
let routePath = module.name + '.config.directory.brand.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<brand-index-page></brand-index-page>', true)
        .breadcrumbs( [ "Справочники", "Бренды" ]);

    }]);
