import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {LocationsDialogComponent} from "./locations-dialog.component";

import {TreeModule} from "@circlon/angular-tree-component";
import {SearchInputModule} from "../search-input/search-input.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {LocationsDialogGetterService} from "./service/locations-dialog-getter.service";
import {SharedComponentsModule} from "../shared.components.module";
import {LocationsDialogService} from "./service/locations-dialog.service";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {LocationsViewComponent} from "./components/locations-view/locations-view.component";
import {LocationsGroupsViewComponent} from "./components/locations-groups-view/locations-groups-view.component";
import {DirectiveModule} from "../../directive/directive.module";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";


@NgModule({
  declarations: [
    LocationsViewComponent,
    LocationsGroupsViewComponent,
    LocationsDialogComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        UpgradeModule,
        TreeModule,
        SearchInputModule,
        InfiniteScrollModule,
        SharedComponentsModule,
        NgbDropdownModule,
        DirectiveModule,
        PipesComponentsModule,
    ],
  providers: [
    LocationsDialogGetterService,
    LocationsDialogService
  ],
  entryComponents:[],
  exports:[
    LocationsDialogComponent
  ]
})
export class LocationsDialogModule {

  constructor() {}

}

//-- LEGACY ---

angular.module('loya.builder')
  .factory('LocationsDialog', downgradeInjectable(LocationsDialogService))
