/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GoodGroupLinkBrandsApiConfiguration as __Configuration } from '../good-group-link-brands-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodGroupAssignedBrand } from '../models/good-group-assigned-brand';
import { BrandLink } from '../models/brand-link';
@Injectable({
  providedIn: 'root',
})
class GoodGroupLinkBrandsApiService extends __BaseService {
  static readonly queryGoodGroupBrandsPath = '/goodgrouplink/brand/{id}';
  static readonly addListPath = '/goodgrouplink/brand/{id}';
  static readonly deleteListPath = '/goodgrouplink/brand/{id}/deleteList';
  static readonly deleteAllPath = '/goodgrouplink/brand/{id}/deleteAll';
  static readonly deletePath = '/goodgrouplink/brand/{id}/{brandId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка брэндов, входящих в группу товаров
   *
   * Запрос списка брэндов, входящих в группу товаров
   * @param params The `GoodGroupLinkBrandsApiService.QueryGoodGroupBrandsParams` containing the following parameters:
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `description` - атрибут "Описание"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список брэндов, входящих в группу товаров
   */
  queryGoodGroupBrandsResponse(params: GoodGroupLinkBrandsApiService.QueryGoodGroupBrandsParams): __Observable<__StrictHttpResponse<Array<GoodGroupAssignedBrand>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goodgrouplink/brand/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoodGroupAssignedBrand>>;
      })
    );
  }
  /**
   * Запрос списка брэндов, входящих в группу товаров
   *
   * Запрос списка брэндов, входящих в группу товаров
   * @param params The `GoodGroupLinkBrandsApiService.QueryGoodGroupBrandsParams` containing the following parameters:
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *     - `description` - атрибут "Описание"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список брэндов, входящих в группу товаров
   */
  queryGoodGroupBrands(params: GoodGroupLinkBrandsApiService.QueryGoodGroupBrandsParams): __Observable<Array<GoodGroupAssignedBrand>> {
    return this.queryGoodGroupBrandsResponse(params).pipe(
      __map(_r => _r.body as Array<GoodGroupAssignedBrand>)
    );
  }

  /**
   * Привязка списка брэндов к группе товаров
   * @param params The `GoodGroupLinkBrandsApiService.AddListParams` containing the following parameters:
   *
   * - `links`: Список брэндов для привязки
   *
   * - `id`: ID группы товаров
   */
  addListResponse(params: GoodGroupLinkBrandsApiService.AddListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/brand/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязка списка брэндов к группе товаров
   * @param params The `GoodGroupLinkBrandsApiService.AddListParams` containing the following parameters:
   *
   * - `links`: Список брэндов для привязки
   *
   * - `id`: ID группы товаров
   */
  addList(params: GoodGroupLinkBrandsApiService.AddListParams): __Observable<null> {
    return this.addListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление списка брэндов из группы товаров
   *
   * Удаление списка брэндов из группы товаров
   * @param params The `GoodGroupLinkBrandsApiService.DeleteListParams` containing the following parameters:
   *
   * - `links`: Список брэндов
   *
   * - `id`: ID группы товаров
   */
  deleteListResponse(params: GoodGroupLinkBrandsApiService.DeleteListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/brand/${encodeURIComponent(String(params.id))}/deleteList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление списка брэндов из группы товаров
   *
   * Удаление списка брэндов из группы товаров
   * @param params The `GoodGroupLinkBrandsApiService.DeleteListParams` containing the following parameters:
   *
   * - `links`: Список брэндов
   *
   * - `id`: ID группы товаров
   */
  deleteList(params: GoodGroupLinkBrandsApiService.DeleteListParams): __Observable<null> {
    return this.deleteListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление всех брэндов из группы товаров
   * @param id ID группы товаров
   */
  deleteAllResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/brand/${encodeURIComponent(String(id))}/deleteAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление всех брэндов из группы товаров
   * @param id ID группы товаров
   */
  deleteAll(id: number): __Observable<null> {
    return this.deleteAllResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удалить брэнд из группы товаров
   *
   * Удалить брэнд из группы товаров
   * @param params The `GoodGroupLinkBrandsApiService.DeleteParams` containing the following parameters:
   *
   * - `id`: ID группы товаров
   *
   * - `brandId`: ID брэнда
   */
  deleteResponse(params: GoodGroupLinkBrandsApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/goodgrouplink/brand/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.brandId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить брэнд из группы товаров
   *
   * Удалить брэнд из группы товаров
   * @param params The `GoodGroupLinkBrandsApiService.DeleteParams` containing the following parameters:
   *
   * - `id`: ID группы товаров
   *
   * - `brandId`: ID брэнда
   */
  delete(params: GoodGroupLinkBrandsApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GoodGroupLinkBrandsApiService {

  /**
   * Parameters for queryGoodGroupBrands
   */
  export interface QueryGoodGroupBrandsParams {

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * Порядок сортировки:
     *   - `asc` - по увеличению
     *   - `desc` - по убыванию
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле (атрибут) сортировки списка:
     *   - `name` - атрибут "Наименование"
     *   - `description` - атрибут "Описание"
     */
    sortingField?: 'name' | 'description';

    /**
     * Сколько записей вернуть, по умолчанию - 50
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала списка, по умолчанию - 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for addList
   */
  export interface AddListParams {

    /**
     * Список брэндов для привязки
     */
    links: Array<BrandLink>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for deleteList
   */
  export interface DeleteListParams {

    /**
     * Список брэндов
     */
    links: Array<BrandLink>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * ID брэнда
     */
    brandId: number;
  }
}

export { GoodGroupLinkBrandsApiService }
