interface ICirculatioItem {
    id?            : number;
    startNumber    : number;
    stopNumber     : number;
    $circulation   : number;
    $isNew?        : boolean;
    name?          : string;
    runningDateTime? : string;
    runningUserId?   : number;
    state?           : string;
}

export class CirculationCouponRule {

    public ruleId       : number;
    public ruleState   : string;

    public circulationList : Array<ICirculatioItem>;
    public currentRule : any;

    public isActive : boolean;

    private runningIDs : Set<number> = new Set();

    static $inject = ['CouponRule', '$state' ,'$timeout','toaster' ];

    constructor(
        private CouponRule : any,
        private $state     : any,
        private $timeout   : any,
        private toaster    : any
    ) {

    }

    get rulePrefix() {
        return ( this.currentRule &&
                 this.currentRule.couponRule &&
                this.currentRule.couponRule.prefix ) || "";
    }

    get isRuleActive() {
        return this.ruleState === 'active';
    }

    public $onInit() {

        this.isActive = false;
        this.circulationList = [];

        this.getCouponRule(this.ruleId);
        this.getCirculationList(this.ruleId);

    }

    public $onChanges( changes: any ) {

        if ( changes.ruleId ) {
            this.getCouponRule(changes.ruleId.currentValue);
        }

    }

    private requestCirculationList(id: number) : Promise<Array<ICirculatioItem>> {
        return this.CouponRule.queryCouponEditions({
            id: this.ruleId
        }).$promise
    }

    private prepareCirculationItem( item :ICirculatioItem ) {
        item.$circulation = item.stopNumber - item.startNumber + 1;
        return item;
    }

    private getCirculationList( id: number ) {
        return this.requestCirculationList(id)
                .then( ( result: Array<ICirculatioItem>) => {
                    this.circulationList = result.map( this.prepareCirculationItem);
                    return this.circulationList;
                })
                .then(this.getRunningIds.bind(this));
    }

    private getRunningIds( circulationList : Array<ICirculatioItem> ) {

        circulationList.forEach( ( item: ICirculatioItem)  => {

            if ( ( this.isCirculationRunning(item) /*|| this.isCirculationDraft(item)*/ ) && !this.runningIDs.has(item.id) ) {
                this.runningIDs.add(item.id);
                return;
            }

            if ( this.runningIDs.has(item.id) && !this.isCirculationRunning(item)) {
                this.runningIDs.delete(item.id);
                return;
            }

        });

        this.startRunningUpdater();
        return circulationList;
    };

    private getCouponRule = function( id: number ) {
        this.currentRule = this.CouponRule.get({ id: id});
        return this.currentRule.$promise;
    };

    public addNewCirculation() {

        let indexStart;
        this.circulationList = this.circulationList || [];

        if (this.circulationList && this.circulationList.length) {
            indexStart = this.circulationList[this.circulationList.length-1].stopNumber;
        } else {
           // indexStart = parseInt( this.padZeroes(this.rulePrefix,0) );
            indexStart = 0;
        }

        this.circulationList.push({
            startNumber : indexStart + 1,
            stopNumber  : indexStart + 1,
            $circulation: 1,
            $isNew      : true
        });

    }

    public countZeroes = function( prefix:string, num: number ) {
        if ( typeof num === "undefined") return '';

        return  '000000000000'.slice(0, 12-prefix.length-num.toString().length);
    };

    public padZeroes = function( prefix:string, num: number ) {
        if ( typeof num === "undefined") return '';

        return prefix + this.countZeroes( prefix, num ) + num.toString();
    };

    public canAddNewCirculation() {

        if (!this.isRuleActive) return false;

        if ( !this.circulationList || !this.circulationList.length )  {
            return true;
        }

        return this.isCirculationLaunched(this.circulationList[this.circulationList.length - 1]) && !this.isCirculationRunning(this.circulationList[this.circulationList.length - 1]);

    }

    public isCirculationNew(circulation : ICirculatioItem) {
        return circulation && !!circulation.$isNew;
    }

    public canCirculationRun(circulation : ICirculatioItem) {

        if (!this.isRuleActive) return false;

        if ( this.isCirculationNew(circulation) || this.isCirculationError(circulation) )
            return false;

        return !this.isCirculationLaunched(circulation);
    }

    public isCirculationDraft(circulation : ICirculatioItem) {
        return circulation && circulation.state && ["draft"].indexOf(circulation.state) >=0 ;
    }

    public isCirculationRunning(circulation : ICirculatioItem) {
        return circulation && circulation.state && ["runing", "run"].indexOf(circulation.state) >=0 ;
    }

    public isCirculationError(circulation : ICirculatioItem) {
        return circulation && circulation.state && ["error"].indexOf(circulation.state) >=0 ;
    }

    public isCirculationLaunched(circulation : ICirculatioItem) {
        return circulation && circulation.state && ( ["finished"].indexOf(circulation.state) >=0 || this.isCirculationRunning(circulation) );
    }

    public saveCirculation( circulation : ICirculatioItem, circulationNum: number ) {

        if ( !this.isCirculationNew(circulation) ) {
            return false;
        }

        // Такое имя, потому что больше не влезает :(
        return this.CouponRule.createGeneratorTask({ id: this.ruleId}, {
            name        : `Генерация тиража ${circulationNum} для КП (ID ${this.currentRule.couponRule.id})`,
            startNumber : circulation.startNumber,
            stopNumber  : circulation.stopNumber
        })
            .$promise
            .then(() => this.getCirculationList(this.ruleId) )
            .catch( (error: any) => this.toaster.error('Ошибка сохранения тиража', error.data || error) );

    }

    public runCirculation( circulation : ICirculatioItem ) {

        if ( !circulation && !circulation.id) {
            return false;
        }

        // Такое имя, потому что больше не влезает :(
        return this.CouponRule.runGeneratorTask({ taskId: circulation.id}, {})
            .$promise
            .then(() => this.getCirculationList(this.ruleId) )
            .catch( (error: any) => this.toaster.error('Ошибка генерации тиража', error.data || error) );

    }


    public continueCirculation( circulation : ICirculatioItem ) {

        if ( !circulation && !circulation.id) {
            return false;
        }

        return this.CouponRule.continueGeneratorTask({ taskId: circulation.id}, {})
            .$promise
            .then(() => this.getCirculationList(this.ruleId) )
            .catch( (error: any) => this.toaster.error('Ошибка перевыпуска тиража', error.data || error) );

    }

    public removeCirculation( circulation : ICirculatioItem ) {

        if ( !circulation && !circulation.id) {
            return false;
        }

        this.CouponRule.deleteGeneratorTask({ taskId: circulation.id}, {})
            .$promise
            .then(() => {
                this.rejectCirculation(circulation);
            })
            .catch( (error: any) => this.toaster.error('Ошибка удаления тиража', error.data || error) );

    }

    public rejectCirculation( circulation : ICirculatioItem ) {

        if ( this.circulationList && this.circulationList.indexOf(circulation) >= 0 ) {
            this.circulationList.splice( this.circulationList.indexOf(circulation), 1 );
        }
    }

    public getMaximumDigits() {
        return 12 - this.rulePrefix.length;
    }

    public getMaximumValue() {
        return Math.pow(10, this.getMaximumDigits()) - 1;
    }

    public getMaximumCirculation(circulation : ICirculatioItem) {

        if ( !circulation.startNumber ) {
            return 1000000;
        }
        // return 1000000 - parseInt( circulation.startNumber.toString().slice( this.rulePrefix.toString().length - circulation.startNumber.toString().length ) );

        return 1000000 - circulation.startNumber;
    }

    public isCirculationGenerated(circulation : ICirculatioItem) {
        return circulation && !circulation.$isNew;
    }

    public circulationChanged(circulation : ICirculatioItem) {

        if (circulation.$circulation < 1) {
            circulation.$circulation = 1;
        }

        circulation.stopNumber = circulation.startNumber + circulation.$circulation - 1;
        return circulation;
    }

    public startNumberChanged(circulation : ICirculatioItem, num : number) {

        if ( typeof num === "undefined") {
            // circulation.startNumber = parseInt(this.padZeroes(this.rulePrefix, 0 ) );
            circulation.startNumber = 0;
            return circulation;
        }

        //circulation.startNumber = parseInt(this.padZeroes(this.rulePrefix, num ) );
        circulation.startNumber = num;
        return this.circulationChanged(circulation);
    }

    // Running updater

    public updaterId : number = 0;
    public startRunningUpdater() {

        if (this.runningIDs.size == 0) {
            return;
        }

        setTimeout( () => {

            if ( this.runningIDs.size == 0 ) return;

            this.requestCirculationList(this.ruleId)
                .then( circulatioList => {

                    circulatioList.forEach( circulatioItem => {

                        if ( this.runningIDs.has(circulatioItem.id) && !this.isCirculationRunning(circulatioItem) ) {

                            this.runningIDs.delete(circulatioItem.id);

                            const index = this.circulationList.findIndex( i => i.id === circulatioItem.id );
                            if ( index>=0 ) {
                                this.circulationList[index] = this.prepareCirculationItem( circulatioItem );
                            }
                        }

                    });

                    this.startRunningUpdater();

                });

        }, 2000);

    }



    public $onDestroy() {
        this.runningIDs.clear();
    }

};
