'use strict';

import module from 'frontendModule';

import 'ng-infinite-scroll';

import 'model/SearchCheckModel/resource';
import 'component/filterGoods/main';
import 'component/filterLocations/main';
import {RECEIPT_ROLES} from "component/nav/menu";


let routePath = module.name + '.receipt.index2';
module.requires = module.requires.concat([
    'infinite-scroll',
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url( '/:number?:id' )

                .template(   () => import( /* webpackChunkName: "assets/pages/receipt.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/receipt.index" */ "./controllers"       ))

                .breadcrumbs( [ "Чеки", "Поиск чеков" ] )

                .permissions( {
                    only: RECEIPT_ROLES,

                })

        }]);

