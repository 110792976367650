<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
>

<div class="fixed-tool-panel">

  <button
          *ngIf="selectedPartner"
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view({partnerId:'+selectedPartner.id+'})'"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

  <div class="wrapper">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Введите имя списка товаров"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>{{ 'models.Goodgroup.id.name' | transloco }}</th>
         <th>
           <sort-view
             column="name"
             [params]="goodgroupService.getListParams"
             (onChange)="reset()"
           >
           {{ 'models.Goodgroup.name.name' | transloco }}
           </sort-view>
         </th>
         <th class="table-row-sm table-row-xs ">
           <sort-view
             column="description"
             [params]="goodgroupService.getListParams"
             (onChange)="reset()"
           >
             {{ 'models.Goodgroup.description.name' | transloco }}
           </sort-view>
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listGoodGroups">
          <tr
              [uiSref]="'^.view({id:'+item.id+', partnerId:'+selectedPartner.id+'})'"
              class="pointer"
          >
            <td>
              <span [innerText]="item.id"></span>
            </td>
            <td class="table-big-link">
              <a  href=""
                 [uiSref]="'^.view({id:'+item.id+', partnerId:'+selectedPartner.id+'})'">
                <text-highlight [search]="searchString" [text]="item.name" ></text-highlight>
              </a>
            </td>
            <td>
              <text-highlight [search]="searchString" [text]="item.description || ''" ></text-highlight>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

</partner-menu>
