import { API_GET_IMAGE, ID_TYPES_IN_SETTINGS } from "../../settings";
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {SettingsService} from "../../../../service/api/Settings/Settings.service";
import {HttpClient} from "@angular/common/http";
import {ConfirmActionService} from "../../../../service/confirmAction/confirmAction.service";
import {ImageCarouselUploaderService} from "../../service/image-carousel-uploader.service";
import {ImageUploaderHandler} from "./../../imageUploaderHandler";
import {ImageItem} from "../../imageUploaderHandler";

interface typeItem {
    id   : number,
    name : string
}

enum imageStatus {
    LOADING,
    LOADED,
    ERROR
}


interface imageInfo {
    status : imageStatus,
    width?  : number,
    height? : number,
}


@Component({
  selector: 'image-carousel-uploader',
  templateUrl: './imageCarouselUploader.component.html',
  styleUrls: [`imageCarouselUploader.component.scss`],
  providers: [
    SettingsService,
    HttpClient,
    ImageCarouselUploaderService,
  ]
})
export class imageCarouselUploader implements OnInit, OnChanges{

    // Передаваемые параметры в компонент
    @Input() public max          : number;

    @Input() public campaignId: number;

    public items: Array<ImageItem>;

  //  public getDropContainer : any;

    public imageHandler = new ImageUploaderHandler();

    public isServiceAvailable : boolean  = false;

    private listTypes: Array<typeItem>;
    private checkedImages: Map<string,imageInfo>;

    constructor( private Settings  : SettingsService,
                 private el: ElementRef,
                 private httpClient     : HttpClient,
                 public confirmActionService: ConfirmActionService,
                 public imageCarouselUploaderService: ImageCarouselUploaderService,
    ) { }

    public ngOnInit() {
        this.checkAvailibleService();
        this.checkedImages = new Map();
        this.items = this.items || [];
        this.typesArray();
        this.getSettingsTypes();
  //      this.getDropContainer = this.el.nativeElement.find('.for-drop-down');
        this.max = this.max || 10;
    }


    public ngOnChanges(changes: SimpleChanges): void {

      if (!!changes['campaignId'] ) {

        if (
          !!changes['campaignId']?.currentValue &&
          !changes['campaignId']?.previousValue &&
          this.imageHandler.hasItems()
        ) {
          this.commitCampaignImages()
            .finally( () => this.getCampaignList(changes['campaignId']?.currentValue) )
        } else {
          this.getCampaignList(changes['campaignId']?.currentValue)
        }

      }


    }

    public getCampaignList(id) {
      if (!id ) {
        this.imageHandler.clear();
        this.items = [];
        return
      }

      this.imageCarouselUploaderService
        .campaignGetList$(id)
        .subscribe({
          next: result => {
            this.imageHandler.clear();
            this.items = result;
          }
        })
    }

    private typesArray() {
        this.items.map( i => {
            if ( !Array.isArray( i.imageType ) ) {
                i.imageType = [i.imageType];
            }
        } )
    }

    private checkAvailibleService() {

      this.imageCarouselUploaderService
        .checkService$()
        .subscribe({
          next: r => this.isServiceAvailable = r,
          error: r => {
            r.stopPopupError();
            this.isServiceAvailable = false;
          }
        })
    }

    public getSettingsTypes() {

        return this.Settings
                    .query$()
                        .toPromise()
                        .then( (list:Array<any> )=> {

                            this.listTypes = [];

                            if (! Array.isArray(list) ) {
                                return;
                            }

                            let finded = list.filter( i => i.key === ID_TYPES_IN_SETTINGS );

                            if ( !finded.length ) {
                                return;
                            }

                            try {

                                finded = JSON.parse( finded[0].value );
                                this.listTypes = this.listTypes.concat(finded);

                            } catch(e){
                                console.log( `Error setting ${ID_TYPES_IN_SETTINGS}: ${e}` );
                            }

                        });

    }

    public deleteImageEvent = (item : ImageItem) => {

        if (!item) return;

        this.items.splice( this.items.indexOf(item), 1 );
        this.fireDelete(item);
    }

    public checkImageLoad( item : ImageItem ): imageStatus {

        const url = this.getUrlByGUID(item);

        if ( this.checkedImages.has(url) ) {
            return this.checkedImages.get(url).status;
        }

        let testImg = new Image();
        testImg.src = url;
        testImg.onload  = () => { this.checkedImages.set(url, {
                status : imageStatus.LOADED,
                width  : testImg.width,
                height : testImg.height
            });
        };

        testImg.onerror = () => { this.checkedImages.set(url, { status: imageStatus.ERROR });  };

        this.checkedImages.set(url, {
            status: imageStatus.LOADING
        } );
        return this.checkedImages.get(url).status;
    }

    public getImageInfo( item : ImageItem ) : imageInfo {
        return this.checkedImages.get( this.getUrlByGUID(item) );
    }

    public getUrlByGUID( item : ImageItem ) {
        return  this.imageCarouselUploaderService.getImageUrl(item?.imageId);
    }

    public getSize( item : ImageItem ) {
        if ( !this.isLoaded(item) ) {
            return ;
        }

        return this.getImageInfo(item).width +'x'+ this.getImageInfo(item).height;
    }

    public isLoading = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.LOADING;
    public isLoaded  = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.LOADED;
    public isError   = (item : ImageItem) =>  this.checkImageLoad(item) === imageStatus.ERROR;

    public getGUIDFromUloader( GUID :string) {
        if (this.isMaxImages())
            return;

        const item : ImageItem = {
            imageId   : GUID,
            imageType : this.listTypes.map( i => i.id)
        };

        this.items.unshift( item );
        this.fireAdd(item);
    }

    public hasInfo( item: ImageItem ) {
        if ( !this.isLoaded(item) ) {
            return ;
        }

        let resString = '';

        item.imageType.forEach( type => {
            switch (this.getTypeName(type).toLowerCase()) {

                case "viber":
                    if (this.getImageInfo(item).width !== this.getImageInfo(item).height)
                        resString += 'Для  ' + this.getTypeName(type) + ' рекомендуются пропорции изображения 1:1 <br/>';
                    break;

                case "facebook":
                case "telegramm":
                case "vk":
                    const res = this.getImageInfo(item).height / this.getImageInfo(item).width;
                    if (res < 0.40 || res > 0.60)
                        resString += 'Для ' + this.getTypeName(type) + ' рекомендуются пропорции изображения 1:2 <br/>';
                    break;


            }
        });


        return resString;

    }

    private fireAdd( item: ImageItem) {
        //return this.onAdd.emit({ item: item });

        this.imageHandler.add(item);
        return this.commitCampaignImages();
    }

    private fireDelete( item: ImageItem) {

       return this.imageCarouselUploaderService
          .deleteFile$(item?.imageId )
          .toPromise()
          .then(() => {
            this.imageHandler.delete(item);
            return <any>this.commitCampaignImages();
          },() => {
            this.imageHandler.delete(item);
            return <any>this.commitCampaignImages();
          });

    }

    private fireChange( before: ImageItem,  after: ImageItem) {
       // return this.onChange.emit({ before: before, after: after });
        this.imageHandler.change(before, after);
        return this.commitCampaignImages();
    }

    public getTypeName( id: number ) {

        if ( this.listTypes && typeof (<any>this.listTypes)?.then === "function" ) {
            return;
        }

        let finded = this.listTypes?.find( (i:any) => i.id === id );

        if ( typeof finded === "undefined" ) {
            return `Тип с ID ${id}`;
        }

        return finded.name;

    }

    public  setType( item: ImageItem, imageType: number ) {

        if (!item) return;

        const before = Object.assign({}, item);
        if (item.imageType.indexOf( imageType ) < 0) {
            item.imageType.push(imageType);
            this.fireChange(before, item);
        } else {
            item.imageType.splice( item.imageType.indexOf( imageType ),1 );
            this.fireChange(before, item);
        }

    }

    public typeChecked( item: ImageItem, imageType: number ) {

        if (!item || !Array.isArray( item.imageType ) ) return;

        return item.imageType.indexOf( imageType ) >= 0;

    }

    public isMaxImages() {
        return this.max <= this.items.length;
    }

    public commitCampaignImages() {

      if (!this.campaignId)
        return Promise.resolve();

      let promises = [];

      promises.concat(

        this.imageHandler
          .getItemsForRemove()
          .map( item => this.imageCarouselUploaderService.campaignDeleteImage$( this.campaignId, item.imageId).toPromise() ),

        this.imageHandler
          .getItemsForSave()
          .map( item => this.imageCarouselUploaderService.campaignSaveImage$(this.campaignId, item.imageId, item.imageType).toPromise() ),

      );

      this.imageHandler.clear();
      return Promise.all(promises);

    };


}
