/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GoodGroupLinkApiConfiguration, GoodGroupLinkApiConfigurationInterface } from './good-group-link-api-configuration';

import { GoodGroupLinkApiService } from './services/good-group-link-api.service';

/**
 * Provider for all GoodGroupLinkApi services, plus GoodGroupLinkApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    GoodGroupLinkApiConfiguration,
    GoodGroupLinkApiService
  ],
})
export class GoodGroupLinkApiModule {
  static forRoot(customParams: GoodGroupLinkApiConfigurationInterface): ModuleWithProviders<GoodGroupLinkApiModule> {
    return {
      ngModule: GoodGroupLinkApiModule,
      providers: [
        {
          provide: GoodGroupLinkApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
