export interface anchorListItem {
    name      : string;
    id?       : string;
    active?   : boolean;
    position? : number;
}

interface  ScopeDirective  extends ng.IScope {
    list? : Array<anchorListItem>;
}

class MenuAnchorsCtrl {

    static $inject = ['$scope', '$element'];

    private list: Array<anchorListItem>;

    constructor(private $scope: ScopeDirective, private $element: ng.IRootElementService) {
        this.$scope.$watch('list', this.listChange.bind(this), true );
        this.bindAll();
    }

    private listChange( newList : Array<anchorListItem> ) {
        this.list = newList;
        this.onScroll();
    }

    private updateMenuList() {

        let menuChildrens = (<any> this.$element.children() ).toArray();

        this.list
            .sort((a,b)=>a.position - b.position)
            .forEach((item , index)=> {

                item.id = item.id || 'r' + index;
                let liElement = menuChildrens.find( (i:any) => (<HTMLElement>i.firstChild).getAttribute('goto') === item.id );

                if ( liElement ) {
                    liElement.parentNode.appendChild(liElement); // Для сортировки в конце переносим
                    liElement.firstElementChild.className = item.active ? "nav-active nav-link" : "nav-link";
                    liElement.firstElementChild.innerText = item.name
                    menuChildrens = menuChildrens.filter( (i:any) => i != liElement);
                    return;
                }

                let link = document.createElement('a');
                    link.setAttribute('goto', `${item.id}`);
                    link.className='nav-link';
                    if (item.active)
                      link.className+=' nav-active';

                    link.innerText = item.name;

                let menuItem = document.createElement('li');
                    menuItem.className = "nav-item";
                    menuItem.appendChild(link);

                this.$element.append(menuItem);

            });

        menuChildrens.forEach((i:any)=> i.parentElement.removeChild(i) );

    }

    private bindAll() {
        this.$element[0].addEventListener('click'    , this.onClick.bind(this));
        this.$element[0].addEventListener('touchend' , this.onClick.bind(this));

        document.getElementById('app-container').addEventListener('scroll' , this.onScroll.bind(this));

        //window.addEventListener('scroll', this.onScroll.bind(this));

    }


    private onClick(event: MouseEvent){

        if (!event.target  || !event.target )
            return;

        const anchor = (<HTMLElement>event.target).getAttribute('goto');
        if (!anchor)
            return;

        const anchorElement = document.querySelector(`a[name="${anchor}"]`);

        if (!anchorElement)
            return;

        const box = anchorElement.getBoundingClientRect();

        if (!box)
            return;

        const container = document.getElementById('app-container');
        var scrollTop =  container.scrollTop + container.getBoundingClientRect().top ;//   window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop - document.documentElement.clientTop || document.documentElement.clientTop || 0;
        var top  = box.top +  scrollTop;

        window['angular'].element('#app-container').animate({ scrollTop: Math.round(top) - 150 }, 300);

    }



    private onScroll() {

        if (!this.list || !this.list.length)
            return;

        let queryString = '';
        let nodes: Array<{
            el:HTMLAnchorElement
            rect: any
        }> = [];

        this.list.forEach( (i, index) => {

            i.active = false;

            const el = document.querySelector<HTMLAnchorElement>(`a[name="${i.id}"]`);
            const rect = el.getBoundingClientRect();
            i.position = document.getElementById('app-container').scrollHeight + rect.top;
            nodes.push( { el : el, rect: rect });

        });


        // const nodes =  document.querySelectorAll<HTMLAnchorElement>(queryString.slice(0,-1));

        let activeNode : HTMLAnchorElement  =
             nodes .reduce( (lastActive:HTMLAnchorElement, anchorElement, index) => {

                const position = anchorElement.rect;
                const containerElement = document.getElementById('app-container');
                switch (true) {
                    // Для скрола вверху выбираем верхний элемент
                    case containerElement.scrollTop < 140 :
                        return position.top < lastActive.getBoundingClientRect().top ? anchorElement.el : lastActive;

                    // Для скрола внизу выбираем последний
                    case (containerElement.scrollTop + containerElement.clientHeight >= containerElement.scrollHeight - 50 ):
                       return position.top > lastActive.getBoundingClientRect().top ? anchorElement.el : lastActive;

                    // Выбираем ближний к центру экрана
                    default :
                        if ( position.top > containerElement.clientHeight + containerElement.getBoundingClientRect().top)
                            return lastActive;

                        return  Math.abs(position.top - containerElement.clientHeight /2) < Math.abs(lastActive.getBoundingClientRect().top - containerElement.clientHeight /2) ? anchorElement.el : lastActive;

                }

            },nodes[0].el);

        this.list.forEach(i => {
            if (i.id === activeNode.name) {
                i.active = true;
            }
        });

        this.updateMenuList();
    }


}

export default window['angular']
    .module('loya.builder')
    .directive('menuAnchors', [
        function () {

            return {
                restrict: 'A',
                controller: MenuAnchorsCtrl,
                scope: {
                    list: '=menuAnchors'
                }

            };

        }]);
