import { IResourceFieldOptions } from "./ngResourceInterval.class";
import {QueryHandler} from "../../QueryHandler.class";

export class ngResourceIntervalNumber {

    private _from: any;
    private _till: any;
    private _enabled = true;

  static $inject = ['options', 'paramName','_resetFunction','startOptions'];

    constructor(
        private options: IResourceFieldOptions,
        private paramName: any,
        private _resetFunction: any,
        startOptions: any
    ) {

        if ( startOptions ) {
            this._initValues( startOptions );
        }

    }

    public getParamQuery = function() {

        if ( !this._enabled ) {
            return {};
        }

        if ( this.options.bothRequired && (!this._from || !this._till) ) {
            return {};
        }

        let params = {};
        if (this._from) {
            QueryHandler.setValueByPath( params, this.options.from, this._from );
        }

        if (this._till) {
            QueryHandler.setValueByPath( params, this.options.till, this._till );
        }
        return params;

    };

    get from() {
        return this._from;
    }

    set from( value : any) {
        this._from = value;
        this._changeFilterValue();
    }

    get till() {
        return this._till;
    }

    set till( value : any) {
        this._till = value;
        this._changeFilterValue();
    }

    get $enabled() {
        return this._enabled;
    }

    set $enabled( status : boolean ) {
        this._enabled = status;

     /*   if (!this._enabled) {
            this._from = undefined;
            this._till = undefined;
        }*/

        this._changeFilterValue();
    }

    public clear = function() {
        this._from = undefined;
        this._till = undefined;
        this._changeFilterValue();
    }

    private _initValues = function( startParamValues?: any ) {

        if ( QueryHandler.getValueByPath( startParamValues, this.options.from ) ) {
            this._from = QueryHandler.getValueByPath( startParamValues, this.options.from );
        }

        if (  QueryHandler.getValueByPath( startParamValues, this.options.till ) ) {
            this._till = QueryHandler.getValueByPath( startParamValues, this.options.till );
        }

    }

    private _changeFilterValue = function() {

        this._resetFunction();

    }

}
