'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';

import 'model/MechanicModel/resource';
import 'model/MechanicCategoryModel/resource';
import 'model/CampaignModel/resource';

import './directives';
import './config';

let routePath = module.name + '.campaign.mechanics.config';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:mechanicId')

                .template(template)
                .controller(controller)

                .resolve('mechanicItem', [ '$stateParams', 'MechanicModel', function ($stateParams, MechanicModel) {

                    if ($stateParams.mechanicId) {
                        return MechanicModel.get({id: $stateParams.mechanicId}).$promise;
                    }

                }])

                .resolve('campaignItem', [ '$stateParams', 'mechanicItem', 'CampaignModel', function ($stateParams, mechanicItem, CampaignModel) {

                    if (mechanicItem.campaignId) {
                        return CampaignModel.get({id: mechanicItem.campaignId}).$promise;
                    }

                }])

                .resolve('mechanicsCategoryItem', ['MechanicCategoryModel', 'mechanicItem', function (MechanicCategoryModel, mechanicItem) {
                    return MechanicCategoryModel.get({ id: mechanicItem.categoryId }).$promise;
                } ])

                .breadcrumbs([ "Механики", "Конфигурация" ])

        }]);

