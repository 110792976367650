'use strict';

import module from 'frontendModule';

import { ViberCtrl } from './controller';
import * as  templateView    from  './index.html';
import './styles.scss';

export default module.component('viberPreviewTemplate', {
    templateUrl : templateView,
    controller  : ViberCtrl,

    bindings: {
        message    : '<',
        imageUrl   : '<',
        buttonText : '<',
        buttonUrl  : '<',
    }

} );

