<div>
  Ручной ввод
  <div class="input-group m-t-xs w-full">
    <input type        = "text"
           [placeholder] = "getPlaceholder()"
           class       = "form-control"
           [(ngModel)] = "inputForAdd"
           (ngModelChange)="inputError = ''"
           (keydown.enter)="addNewItem()"
           data-testid="good.inputForAdd"
    >
    <span class="input-group-btn ">
            <button class="btn btn-small  w-sm btn-success btn-addon" data-testid="good.addNewItem()"
                    (click)="addNewItem()"
            >
                <i class="fa fa-plus"></i>
                Добавить
            </button>
          </span>
  </div>

  <div class="text-danger col-sm-12 f-s-9 no-padder pointer"
       *ngIf="!!inputError"
       (click)="inputError = ''"
       data-testid="good.inputError"
  >
    {{ inputError }}
  </div>

</div>
