import { QueryHandler } from './../../QueryHandler.class';
import {ResourceHandler} from "./../../ngResourceHandler.class";
import {ngResourceIntervalDate}   from "./ngResourceIntervalDate.class";
import {ngResourceIntervalNumber} from "./ngResourceIntervalNumber.class";


export const FILTER_FIELD = '$interval';

export interface IResourceFieldOptions {
    method?: string;
    bothRequired?: boolean;
    from:  any;
    till:  any;
}

export interface IResourceIntervalOptions {

    [ filed:string ] : IResourceFieldOptions

}

export class ngResourceInterval  extends ResourceHandler{

    private defaultFieldOptions: IResourceFieldOptions = {
        bothRequired: false,
        from: 'from',
        till: 'till'
    }

    private options:any = {};

  static $inject = ['handler'];

    constructor(
        protected handler: QueryHandler
    ) {

        super(handler);

        for ( let field in this.handler.actions.interval ) {
            this.options[field] = ( <IResourceFieldOptions>  (<any>Object).assign({}, this.defaultFieldOptions, this.handler.actions.interval[field]) );
        }

    }

    get field() {
        return FILTER_FIELD;
    }


    static canHandle( actions: any ) {
        return actions && !!actions.interval;
    }

    public getCurrentParams() {
        let params = {};

        for ( let field in this.options ) {
            if ( this[field] && this[field].hasOwnProperty('getParamQuery') ) {
                params = QueryHandler.mergeParams( params, this[field].getParamQuery() );
            }

        }

        return params;
    };

    protected onUpdate( finish: Promise<any> ) {
        return this.getCurrentParams()
    };

    protected onStart(startOptions: Object) {

        for ( let field in this.options ) {

            switch( this.options[field].type ) {

                case 'number':
                    this[field] = new ngResourceIntervalNumber(this.options[field], field,  this.reset.bind(this), startOptions );
                case 'date':
                default:
                    this[field] = new ngResourceIntervalDate(this.options[field], field,  this.reset.bind(this), startOptions );

            }

        }
    }

    protected onReset() {}

    private query = function () {
        return this.handler.query();
    };

    private reset = function () {
        return this.handler.reset();
    };

}

