import {Injectable} from "@angular/core";
import {forkJoin, of} from "rxjs";
import {
  LocationGroupLinkApiService
} from "../../../../api/LocationGroupLinkApi/services/location-group-link-api.service";
import {LocationGroupLink} from "../../../../api/LocationGroupLinkApi/models/location-group-link";
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LocationGroupLinkService {

  constructor(
    private locationGroupLinkApiService:LocationGroupLinkApiService,
  ) {
  }

  public getList$(params: LocationGroupLinkApiService.QueryParams){
    return this.locationGroupLinkApiService.query(params)
  }

  public createLinks( groupIdOrArray : number | LocationGroupLink | Array<LocationGroupLink> , merchantId? ) {

    if ( typeof groupIdOrArray === "number" ) {
      groupIdOrArray = {
        groupId: groupIdOrArray,
        merchantId: merchantId
      }
    }

    if (typeof groupIdOrArray === "object" && !Array.isArray(groupIdOrArray)) {
      return this.locationGroupLinkApiService.create(<LocationGroupLink>groupIdOrArray);
    }

    return of(<Array<LocationGroupLink>>groupIdOrArray)
      .pipe(
        map(links => links.map(this.locationGroupLinkApiService.create.bind(this.locationGroupLinkApiService)) ),
        switchMap( (links: any ) =>  forkJoin(...links) )
      );
  }

  public deleteLinks( groupIdOrArray: number | LocationGroupLinkApiService.DeleteParams | LocationGroupLinkApiService.DeleteParams[], merchantId? ) {
    if ( typeof groupIdOrArray === "number" ) {
      groupIdOrArray = {
        groupId: groupIdOrArray,
        merchantId: merchantId
      }
    }

    if (typeof groupIdOrArray === "object"  && !Array.isArray(groupIdOrArray)) {
      return this.locationGroupLinkApiService.delete(<LocationGroupLink>groupIdOrArray);
    }

    return of(<LocationGroupLinkApiService.DeleteParams[]>groupIdOrArray)
      .pipe(
        map(links => links.map(this.locationGroupLinkApiService.delete.bind(this.locationGroupLinkApiService))),
        switchMap( (links: any ) => forkJoin(...links))
      );
  }

  public async updateMerchantForId$( merchantId: number, groupIdOrArray : Array<any> ) {

    groupIdOrArray = groupIdOrArray.map( i => typeof i === "object" ? i : {
      groupId: i,
      merchantId: merchantId
    });

    let links = await this.getList$({
      merchantId
    }).toPromise()

    let linksForDelete = links.filter( i =>
      !groupIdOrArray.some( j => j.groupId === i.groupId)
    )

    groupIdOrArray = groupIdOrArray.filter( i =>
      !links.some( j => j.groupId === i.groupId)
    )

    await this.deleteLinks(linksForDelete).toPromise();

    await this.createLinks(groupIdOrArray).toPromise();

  }

  public async updateGroupForMerchants$( groupId: number,addMerchantsArray: Array<any> = [],  deleteMerchantsArray: Array<any> = [] ) {

    let links = await this.getList$({
      groupId
    }).toPromise()

    addMerchantsArray = addMerchantsArray.map( i => typeof i === "object" ? i : {
      groupId: groupId,
      merchantId: i
    });

    deleteMerchantsArray = deleteMerchantsArray.map( i => typeof i === "object" ? i : {
      groupId: groupId,
      merchantId: i
    });

    let linksForDelete = deleteMerchantsArray.filter( i => links.some( j => j.merchantId === i.merchantId) )

    let linksForAdd = addMerchantsArray.filter( i => !links.some( j => j.merchantId === i.merchantId) || linksForDelete.some( j => j.merchantId === i.merchantId)  )

    if (!!linksForDelete?.length)
      await this.deleteLinks(linksForDelete).toPromise();

    if (!!linksForAdd?.length)
      await this.createLinks(linksForAdd).toPromise();

  }


}
