import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'

import {MechanicCategoryApiService} from "../../../../../api/MechanicCategoryApi/services/mechanic-category-api.service";
import {MechanicCategory} from "../../../../../api/MechanicCategoryApi/models";


@Component({
  selector: 'mechaniccategory-index-page',
  templateUrl: './mechaniccategory-index-page.component.html',
})
export class MechaniccategoryIndexPageComponent  implements OnInit{

  searchString: string;
  listMechanicCategory: Array<MechanicCategory>

  constructor(
    private mechanicCategoryService:MechanicCategoryApiService
  ) {
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.mechanicCategoryService.query()
      .subscribe( result => {
        this.listMechanicCategory = result;
      });
  }

  filterBySearch = (item: MechanicCategory) => {
    if (!this.searchString || !item) return true;

    return  item.name && item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) >=0;

  }

}
