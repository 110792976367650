/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ComponentApiConfiguration as __Configuration } from '../component-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JsValue } from '../models/js-value';
@Injectable({
  providedIn: 'root',
})
class ComponentApiService extends __BaseService {
  static readonly getPath = '/componentget/{componentType}';
  static readonly setPath = '/componentget/{componentType}';
  static readonly deletePath = '/componentget/{componentType}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос данных профиля клиента
   *
   * Запрос данных профиля клиента
   * @param params The `ComponentApiService.GetParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `componentType`: Название компонента
   *
   * @return Данные из профиля клиента
   */
  getResponse(params: ComponentApiService.GetParams): __Observable<__StrictHttpResponse<JsValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.what != null) __params = __params.set('what', params.what.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/componentget/${encodeURIComponent(String(params.componentType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JsValue>;
      })
    );
  }
  /**
   * Запрос данных профиля клиента
   *
   * Запрос данных профиля клиента
   * @param params The `ComponentApiService.GetParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `componentType`: Название компонента
   *
   * @return Данные из профиля клиента
   */
  get(params: ComponentApiService.GetParams): __Observable<JsValue> {
    return this.getResponse(params).pipe(
      __map(_r => _r.body as JsValue)
    );
  }

  /**
   * Обновить данные в профиле клиента
   *
   * Обновить данные в профиле клиента
   * @param params The `ComponentApiService.SetParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `profileData`: Данные для обновления профиля клиента
   *
   * - `componentType`: Название компонента
   */
  setResponse(params: ComponentApiService.SetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.what != null) __params = __params.set('what', params.what.toString());
    __body = params.profileData;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/componentget/${encodeURIComponent(String(params.componentType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновить данные в профиле клиента
   *
   * Обновить данные в профиле клиента
   * @param params The `ComponentApiService.SetParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `profileData`: Данные для обновления профиля клиента
   *
   * - `componentType`: Название компонента
   */
  set(params: ComponentApiService.SetParams): __Observable<null> {
    return this.setResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление данных профиля клиента
   *
   * Удаление данных профиля клиента
   * @param params The `ComponentApiService.DeleteParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `componentType`: Название компонента
   */
  deleteResponse(params: ComponentApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.what != null) __params = __params.set('what', params.what.toString());

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/componentget/${encodeURIComponent(String(params.componentType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление данных профиля клиента
   *
   * Удаление данных профиля клиента
   * @param params The `ComponentApiService.DeleteParams` containing the following parameters:
   *
   * - `what`: Какие данные вернуть из профиля клиента
   *
   * - `componentType`: Название компонента
   */
  delete(params: ComponentApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ComponentApiService {

  /**
   * Parameters for get
   */
  export interface GetParams {

    /**
     * Какие данные вернуть из профиля клиента
     */
    what: string;

    /**
     * Название компонента
     */
    componentType: string;
  }

  /**
   * Parameters for set
   */
  export interface SetParams {

    /**
     * Какие данные вернуть из профиля клиента
     */
    what: string;

    /**
     * Данные для обновления профиля клиента
     */
    profileData: JsValue;

    /**
     * Название компонента
     */
    componentType: string;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * Какие данные вернуть из профиля клиента
     */
    what: string;

    /**
     * Название компонента
     */
    componentType: string;
  }
}

export { ComponentApiService }
