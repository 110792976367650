export const WARNINGS_MESSAGES = {
  MAX_CLIENTS_WARNING: {
    true: {
      "ru_RU": "Внимание! Превышено максимальное количество зарегистрированных клиентов! <br/>"+
      "<span class='f-s-8'>Разрешено в лицензии: <b>{{maxAllowedClients}}</b> клиентов.</span><br/>"+
      "<span class='f-s-8'>В настоящее время в системе: <b>{{realClientCount}}</b> клиентов.</span><br/>"+
      "<span class='f-s-8'>При достижении количества <b>{{limit}}</b> клиентов веб-интерфейс будет заблокирован</span>"
    },
    false: {
      "ru_RU": "Внимание! Количество клиентов в системе подходит к максимуму!<br/>"+
      "<span class='f-s-8'>Разрешено в лицензии: <b>{{maxAllowedClients}}</b> клиентов.</span><br/>"+
      "<span class='f-s-8'>В настоящее время в системе: <b>{{realClientCount}}</b> клиентов.</span><br/>"+
      "<span class='f-s-8'>При достижении количества <b>{{limit}}</b> клиентов веб-интерфейс будет заблокирован</span>"
    }

  },
  UNKNOWN_CLIENTS_COUNT: {
    "ru_RU": ""
  }
};
