import 'model/ClientModel/resource';

const PHONE_PATTERN = new RegExp("^[0-9,\\+,\\-,(,),\\s]+$");

export class UserPhoneInput {

    public model    : string;
    public phoneConfirmed : boolean;
    public clientId : number;
    public isEdit   : boolean;
    public required : boolean;

    public _invalidPhone : any;
    public _waitPhone    : any;

    public isPhoneValid : string; // Для костыля валидации в темплейте

    public originalPhone;
    public originalConfirmed;

    get invalidPhone() { return this._invalidPhone; }
    set invalidPhone( value: any ) {
        this._invalidPhone = value;
        this.$timeout(() => {
 //           this.isPhoneValid = (!value) ? true : undefined;
            if (value)  {
                this.$scope.$parent.$parent.$parent.$parent.$parent.invalidFields.isPhoneInvalid = true;
            } else {
                delete this.$scope.$parent.$parent.$parent.$parent.$parent.invalidFields.isPhoneInvalid;
            }
        });
    }

    get waitPhone() { return this._waitPhone; }
    set waitPhone( value: any ) {
        this._waitPhone = value;
        this.$timeout(() => {

  //          this.isPhoneValid = (!value) ? true : undefined;
            if (value)  {
                this.$scope.$parent.$parent.$parent.$parent.$parent.invalidFields.isPhoneInvalid = true;
            } else {
                delete this.$scope.$parent.$parent.$parent.$parent.$parent.invalidFields.isPhoneInvalid;
            }
        });
    }

    static $inject = ['$scope', '$timeout', 'Settings','ClientModel' ];

    constructor(
        private $scope   : any,
        private $timeout : any,
        private Settings : any,
        private ClientModel: any ) {

    }

    public $onInit() {
        this.invalidPhone = false;
        this.waitPhone    = false;
    }

    private lastTimeout = false;
    private lastPhone : string;
    public issetPhone( phone: string, id : number, forceUpdate : boolean ) {

        this.checkConfirmed();

        if ( this.lastTimeout ) {
            this.$timeout.cancel( this.lastTimeout );
            this.lastTimeout = undefined;
        }

        this.lastTimeout = this.$timeout( () => {

            if (this.lastPhone === phone && !forceUpdate) {
                return ;
            } else {
                this.lastPhone = phone;
            }

            if ( typeof phone === "undefined"  || phone === "") {
                this.invalidPhone = false;
                this.waitPhone    = false;
                return;
            }

            if ( typeof phone === "string" && phone && !PHONE_PATTERN.test( phone )  ) {
                this.invalidPhone = "Неверный формат введённого телефона";
                return;
            }

            let params : any = {
                phone: phone
            };

            if ( typeof id !== "undefined" ) {
                params.id = id;
            }

            this.waitPhone = this.ClientModel.checkphone( params );
            this.invalidPhone = false;


            return this.waitPhone
                        .$promise
                        .then(( data : any ) => {
                            this.waitPhone = false;

                            if ( typeof data.id === "undefined" ) {
                                this.invalidPhone = false;
                                return Promise.reject(null);
                            }

                            this.invalidPhone = data;

                            return data;

                        }, () => {
                            this.waitPhone = false;
                            this.invalidPhone = "Ошибка проверки телефона";
                        });

        }, 300);

    };

    public checkConfirmed() {

        if (
          typeof this.model === "undefined"  ||
          typeof this.originalPhone === "undefined")
          return

        if (this.model === this.originalPhone) {
          this.phoneConfirmed = this.originalConfirmed;
        } else {
          this.phoneConfirmed = false;
        }

    }

    public $onChanges( changes: any ) {

        if ( changes.model && typeof changes.model.currentValue === "undefined" ) {
            this.invalidPhone = false;
            this.waitPhone    = false;
        }

        if ( changes.isEdit ) {

            if (  changes.isEdit.currentValue  ) {

                if ( this.model ) {

                    this.originalPhone = this.model;
                    this.originalConfirmed = !!this.phoneConfirmed;

                    this.issetPhone(this.model, this.clientId, true );

                }

            } else {
                this.invalidPhone = false;
                this.waitPhone = false;
            }

        }

    }

};


/*

$scope.$watchGroup(['invalidPhone','waitPhone'], function(newVal) {
    if ( newVal[0] || newVal[1] ) {
        $scope.invalidFields.invalidPhone = true;
    } else {
        delete $scope.invalidFields.invalidPhone;
    }
});

$scope.$on('clientSaved', function(newVal) {

    $scope.invalidPhone = false;
    $scope.waitPhone = false;

});

$scope.$watch('curState' , function(state, prevState ) {

    if (state && state.activeForm) {

        if (prevState.stateName !== "preview") {
            $scope.invalidPhone = false;
            $scope.waitPhone = false;
        }

        if ( $scope.client.client.phone ) {
            $scope.issetPhone($scope.client.client.phone, $scope.client.client.id, true );
        }

    }

});

};
*/
