import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {UpgradeModule} from "@angular/upgrade/static";
import {ImageUploaderComponent} from "./component/imageUploader/imageUploader.component";
import {imageCarouselUploader} from "./component/imageCarouselUploader/imageCarouselUploader.component";


@NgModule({
  declarations: [
    ImageUploaderComponent,
    imageCarouselUploader
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
  ],
  providers: [],
  exports:[
    ImageUploaderComponent,
    imageCarouselUploader
  ]
})
export class ImageUploaderModule {

  constructor() {}

}
