/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { MechanicPriceListApiConfiguration as __Configuration } from '../mechanic-price-list-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PriceList } from '../models/price-list';
import { UploadState } from '../models/upload-state';
import { PreviewResponseResult } from '../models/preview-response-result';
import { UploadSettings } from '../models/upload-settings';
import { UploadFileResponseResult } from '../models/upload-file-response-result';
import { PriceListItem } from '../models/price-list-item';
@Injectable({
  providedIn: 'root',
})
class MechanicPriceListApiService extends __BaseService {
  static readonly queryPath = '/{mechanicId}/list';
  static readonly cleanDeployPath = '/{mechanicId}/list';
  static readonly searchPath = '/{mechanicId}/list/search';
  static readonly queryDraftPath = '/{mechanicId}/list/draft';
  static readonly deployDraftPath = '/{mechanicId}/list/deploy';
  static readonly cancelDraftPath = '/{mechanicId}/list/cancel';
  static readonly getStatePath = '/{mechanicId}/list/state';
  static readonly previewPath = '/{mechanicId}/list/{filename}/preview';
  static readonly uploadPath = '/{mechanicId}/list/{filename}/upload';
  static readonly addItemPath = '/{mechanicId}/list/item';
  static readonly updateItemPath = '/{mechanicId}/list/item';
  static readonly deleteItemPath = '/{mechanicId}/list/item/{sku}/{locationId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MechanicPriceListApiService.QueryParams` containing the following parameters:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return items list
   */
  queryResponse(params: MechanicPriceListApiService.QueryParams): __Observable<__StrictHttpResponse<PriceList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceList>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.QueryParams` containing the following parameters:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return items list
   */
  query(params: MechanicPriceListApiService.QueryParams): __Observable<PriceList> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as PriceList)
    );
  }

  /**
   * @param mechanicId undefined
   */
  cleanDeployResponse(mechanicId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(mechanicId))}/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param mechanicId undefined
   */
  cleanDeploy(mechanicId: number): __Observable<null> {
    return this.cleanDeployResponse(mechanicId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   */
  searchResponse(params: MechanicPriceListApiService.SearchParams): __Observable<__StrictHttpResponse<PriceList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceList>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   */
  search(params: MechanicPriceListApiService.SearchParams): __Observable<PriceList> {
    return this.searchResponse(params).pipe(
      __map(_r => _r.body as PriceList)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.QueryDraftParams` containing the following parameters:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return items list
   */
  queryDraftResponse(params: MechanicPriceListApiService.QueryDraftParams): __Observable<__StrictHttpResponse<PriceList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/draft`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceList>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.QueryDraftParams` containing the following parameters:
   *
   * - `mechanicId`:
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 100)
   *
   * - `pager.drop`: Сколько записей пропустить (по умолчанию 0)
   *
   * @return items list
   */
  queryDraft(params: MechanicPriceListApiService.QueryDraftParams): __Observable<PriceList> {
    return this.queryDraftResponse(params).pipe(
      __map(_r => _r.body as PriceList)
    );
  }

  /**
   * @param mechanicId undefined
   */
  deployDraftResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(mechanicId))}/list/deploy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * @param mechanicId undefined
   */
  deployDraft(mechanicId: number): __Observable<UploadState> {
    return this.deployDraftResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * @param mechanicId undefined
   */
  cancelDraftResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(mechanicId))}/list/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * @param mechanicId undefined
   */
  cancelDraft(mechanicId: number): __Observable<UploadState> {
    return this.cancelDraftResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * @param mechanicId undefined
   */
  getStateResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(mechanicId))}/list/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * @param mechanicId undefined
   */
  getState(mechanicId: number): __Observable<UploadState> {
    return this.getStateResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.PreviewParams` containing the following parameters:
   *
   * - `settings`:
   *
   * - `mechanicId`:
   *
   * - `filename`:
   */
  previewResponse(params: MechanicPriceListApiService.PreviewParams): __Observable<__StrictHttpResponse<PreviewResponseResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.settings;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.filename))}/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PreviewResponseResult>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.PreviewParams` containing the following parameters:
   *
   * - `settings`:
   *
   * - `mechanicId`:
   *
   * - `filename`:
   */
  preview(params: MechanicPriceListApiService.PreviewParams): __Observable<PreviewResponseResult> {
    return this.previewResponse(params).pipe(
      __map(_r => _r.body as PreviewResponseResult)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.UploadParams` containing the following parameters:
   *
   * - `settings`:
   *
   * - `mechanicId`:
   *
   * - `filename`:
   */
  uploadResponse(params: MechanicPriceListApiService.UploadParams): __Observable<__StrictHttpResponse<UploadFileResponseResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.settings;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.filename))}/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadFileResponseResult>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.UploadParams` containing the following parameters:
   *
   * - `settings`:
   *
   * - `mechanicId`:
   *
   * - `filename`:
   */
  upload(params: MechanicPriceListApiService.UploadParams): __Observable<UploadFileResponseResult> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as UploadFileResponseResult)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.AddItemParams` containing the following parameters:
   *
   * - `priceListItem`:
   *
   * - `mechanicId`:
   */
  addItemResponse(params: MechanicPriceListApiService.AddItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.priceListItem;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.AddItemParams` containing the following parameters:
   *
   * - `priceListItem`:
   *
   * - `mechanicId`:
   */
  addItem(params: MechanicPriceListApiService.AddItemParams): __Observable<null> {
    return this.addItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.UpdateItemParams` containing the following parameters:
   *
   * - `priceListItem`:
   *
   * - `mechanicId`:
   */
  updateItemResponse(params: MechanicPriceListApiService.UpdateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.priceListItem;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.UpdateItemParams` containing the following parameters:
   *
   * - `priceListItem`:
   *
   * - `mechanicId`:
   */
  updateItem(params: MechanicPriceListApiService.UpdateItemParams): __Observable<null> {
    return this.updateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MechanicPriceListApiService.DeleteItemParams` containing the following parameters:
   *
   * - `sku`:
   *
   * - `mechanicId`:
   *
   * - `locationId`:
   */
  deleteItemResponse(params: MechanicPriceListApiService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(params.mechanicId))}/list/item/${encodeURIComponent(String(params.sku))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MechanicPriceListApiService.DeleteItemParams` containing the following parameters:
   *
   * - `sku`:
   *
   * - `mechanicId`:
   *
   * - `locationId`:
   */
  deleteItem(params: MechanicPriceListApiService.DeleteItemParams): __Observable<null> {
    return this.deleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MechanicPriceListApiService {

  /**
   * Parameters for query
   */
  export interface QueryParams {
    mechanicId: number;

    /**
     * Сколько записей вернуть (по умолчанию 100)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for search
   */
  export interface SearchParams {
    sentence: string;
    mechanicId: number;

    /**
     * Сколько записей вернуть (по умолчанию 100)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for queryDraft
   */
  export interface QueryDraftParams {
    mechanicId: number;

    /**
     * Сколько записей вернуть (по умолчанию 100)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for preview
   */
  export interface PreviewParams {
    settings: UploadSettings;
    mechanicId: number;
    filename: string;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {
    settings: UploadSettings;
    mechanicId: number;
    filename: string;
  }

  /**
   * Parameters for addItem
   */
  export interface AddItemParams {
    priceListItem: PriceListItem;
    mechanicId: number;
  }

  /**
   * Parameters for updateItem
   */
  export interface UpdateItemParams {
    priceListItem: PriceListItem;
    mechanicId: number;
  }

  /**
   * Parameters for deleteItem
   */
  export interface DeleteItemParams {
    sku: string;
    mechanicId: number;
    locationId: string;
  }
}

export { MechanicPriceListApiService }
