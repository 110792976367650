<div class="fixed-tool-panel">
 <div class="d-flex align-items-center">

   <div class="col-auto p-l-none">

   </div>

   <div class="col text-right">

     <button type="button" class="btn  btn-success btn-sm btn-addon "
             *ngIf="statesChangerService.state === 'view'"
             uiSref="^.index"
             data-testid="segment.back()"
     >

       <i class="fa fa-arrow-left"></i>
       <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
       </span>
     </button>

     <button type="button" class="btn btn-sm btn-success btn-addon "
             [hidden]="!statesChangerService.hasButton('edit')"
             (click)="statesChangerService.click('edit')"
             [disabled]="loader.isLoading('segment')"
             *ngIf="!isArchive"
             data-testid="segment.edit()"
     >

       <i class=" fa fa-pencil">
       </i>
       <span class="btn-name m-l-xs hidden-xxs">
          {{ 'interface.buttons.edit' | transloco }}
       </span>
     </button>


     <button type="button" class="btn btn-sm btn-addon btn-default "
             *ngIf="statesChangerService.hasButton('cancel')"
             (click)="statesChangerService.click('cancel')"
             [disabled]="loader.isLoading('segment')"
             data-testid="segment.cancel()"
     >

       <i class=" fa fa-remove text-danger">
       </i>
       <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.cancel' | transloco }}
      </span>
     </button>

     <button type="button" class="btn btn-sm btn-addon btn-default "
             *ngIf="statesChangerService.hasButton('save')"
             (click)="statesChangerService.click('save')"
             [disabled]="loader.isLoading('segment')"
             data-testid="segment.save()"
     >
       <i class=" fa fa-check text-primary">
       </i>
       <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
     </button>


   </div>
 </div>
</div>

<div class="padder wrapper"
  *ngIf="!loader.isLoading('segment')"
>

  <div class="row">

    <div class="col-md-12 col-lg-6">

      <div class="card">

        <div class="card-body p-b-none">

          <div class="row">
            <div class="col text-center-xs text-center-sm text-center-md text-left padder">
              <div class="row ">

                <div class="col col-auto padder">
                    <i class="fa fa-pie-chart fa-2x"
                       [ngClass]="{'text-danger': isArchive, 'text-success': !isArchive}"
                    ></i>
                </div>

                <div class="col">

                  <ng-container
                    *ngIf="statesChangerService.isActive"
                  >
                    <div class="m-b-xs p-r-sm">

                      <input type="text"
                             class="form-control form-control-edittable"
                             placeholder="Введите наименование сегмента"
                             [(ngModel)]="segmentItem.name">

                    </div >

                    <div class="m-b-xs p-r-sm">

                      <input type="text" class="form-control "
                             placeholder="Введите описание сегмента"
                             [(ngModel)]="segmentItem.description"
                      >

                    </div>

                  </ng-container>

                  <ng-container
                    *ngIf="!statesChangerService.isActive"
                  >

                    <div class="h4 font-thin m-b-none">
                      {{ segmentItem.name }}

                      <div class=" text-danger overflow-wrap-break text-sm "
                           *ngIf="isArchive"
                      >
                        Сегмент находится в архиве
                      </div>

                    </div>


                    <div class=" text-muted overflow-wrap-break m-b-xs ">
                       {{ segmentItem.description }}
                    </div>


                  </ng-container>


                </div>

              </div>

            </div>
          </div>

          <div class="row b-t ">

            <div class="col wrapper-sm text-center b-r ">

              <span class="text-muted">
                Количество клиентов в группе
              </span>

              <div class="h4 m-t-xs m-b-sm">
                <span data-testid="segment.clientCount">
                  {{ segmentItem.clientCount || 0}}
                </span>
              </div>

              <div class="m-b-xs  m-r m-l">

                <ngb-progressbar
                  [max]="segmentItem.totalClientsCount"
                  [value]="segmentItem.clientCount"
                  [type]="'warning'"
                  [ngClass]="'progress-xxs m-b-none'"
                >
                </ngb-progressbar>

                <span class="f-s-7 text-muted pull-left">
                  0
                </span>
                <span class="f-s-7 text-muted pull-right">
                  {{ segmentItem.totalClientsCount || 0 }}
                </span>
              </div>

              <span
                [ngbPopover]="'Процент от общего количества клиентов'"
                [triggers]="'mouseenter:mouseleave'"
              >
                <i class="fa fa-circle text-warning m-r-sm" data-testid="segment.clientPercent"></i>  {{ getClientsPercent() | loyaNumber : 2 }}%
              </span>

            </div>

            <div class="col text-center wrapper-sm">
              <span class="text-muted">
                Общая сумма продаж
              </span>
              <div class=" m-t-xs m-b-sm">
                <span class="h4" data-testid="segment.total">
                    {{ segmentItem?.period0?.total || 0 }}
                    <i class="fa text-muted" ngCurrencyLoya></i>
                </span>
              </div>
              <div class="m-b-xs  m-r m-l">

                <ngb-progressbar
                  [max]="segmentItem.totalLifeValue"
                  [value]="segmentItem?.period0?.total || 0"
                  [type]="'danger'"
                  [ngClass]="'progress-xxs m-b-none'"
                >
                </ngb-progressbar>

                <span class="f-s-7 text-muted pull-left">
                  0
                </span>
                <span class="f-s-7 text-muted pull-right">
                  {{ segmentItem.totalLifeValue }}
                </span>
              </div>

              <span
                [ngbPopover]="'Процент от общей выручки'"
                [triggers]="'mouseenter:mouseleave'"
              >
                <i class="fa fa-circle text-danger m-r-sm"></i> {{ getTotalPercent()  | loyaNumber : 2 }}%
              </span>

            </div>
          </div>

          <div class="row b-t ">

            <div class="col text-center wrapper-sm b-r ">
              <span class="text-muted">
                Канал связи - SMS
              </span>

              <div class="h4 m-t-xs m-b-sm">
                  {{ segmentItem.communication?.smsChannel || 0 }}
              </div>

              <div class="m-b-xs  m-r m-l">

                <ngb-progressbar
                  [max]="segmentItem?.clientCount || 0"
                  [value]="segmentItem?.communication?.smsChannel || 0"
                  [type]="'primary'"
                  [ngClass]="'progress-xxs m-b-none'"
                >
                </ngb-progressbar>

                <span class="f-s-7 text-muted pull-left">
                  0
                </span>
                <span class="f-s-7 text-muted pull-right">
                  {{ segmentItem?.clientCount || 0 }}
                </span>
              </div>

              <span
                [ngbPopover]="'Процент от общего количества клиентов'"
                [triggers]="'mouseenter:mouseleave'"
              >
                <i class="fa fa-circle text-primary m-r-sm"></i>
                {{ getSmsPercent() | loyaNumber:2 }}%
              </span>

            </div>

            <div class="col text-center wrapper-sm b-r ">
              <span class="text-muted">
                Канал связи - Email
              </span>
              <div class="h4 m-t-xs m-b-sm">
                <span>
                  {{ segmentItem.communication?.emailChannel }}
                </span>

              </div>
              <div class="m-b-xs  m-r m-l">

                <ngb-progressbar
                  [max]="segmentItem?.clientCount || 0"
                  [value]="segmentItem?.communication?.emailChannel || 0"
                  [type]="'primary'"
                  [ngClass]="'progress-xxs m-b-none'"
                >
                </ngb-progressbar>

                <span class="f-s-7 text-muted pull-left">
                  0
                </span>
                <span class="f-s-7 text-muted pull-right">
                  {{ segmentItem?.clientCount || 0 }}
                </span>

              </div>
              <span
                [ngbPopover]="'Процент от общего количества клиентов'"
                [triggers]="'mouseenter:mouseleave'"
              >
                <i class="fa fa-circle text-primary m-r-sm"></i>
                {{ getEmailPercent() | loyaNumber:2 }}%
              </span>
            </div>

            <div class="col text-center wrapper-sm ">
              <span class="text-muted">
                Без канала связи
              </span>
              <div class="h4 m-t-xs m-b-sm">
                <span>
                  {{ segmentItem.communication?.emptyChannel }}
                </span>

              </div>
              <div class="m-b-xs  m-r m-l">

                <ngb-progressbar
                  [max]="segmentItem?.clientCount || 0"
                  [value]="segmentItem?.communication?.emptyChannel || 0"
                  [type]="'primary'"
                  [ngClass]="'progress-xxs m-b-none'"
                >
                </ngb-progressbar>

                <span class="f-s-7 text-muted pull-left">
                  0
                </span>
                <span class="f-s-7 text-muted pull-right">
                  {{ segmentItem?.clientCount || 0 }}
                </span>
              </div>

              <span
                [ngbPopover]="'Процент от общего количества клиентов'"
                [triggers]="'mouseenter:mouseleave'"
              >
                <i class="fa fa-circle text-primary m-r-sm"></i>
                {{ getEmailPercent() | loyaNumber:2 }}%
              </span>
            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="col-md-12 col-lg-6">

      <div class="card">
        <div class="card-header ">Предпочтения по товарам</div>
        <div class="card-body no-padder">

          <ng-container
            *ngIf="!!segmentItem?.clientDescription?.preferences && !!segmentItem?.clientDescription?.preferences?.length"
          >
            <responsive-table>
              <tabel class="table d-table w-full pref-table m-b-none">
                <tr
                  *ngFor="let prefItem of segmentItem.clientDescription.preferences; let index = index; let odd = odd"
                  data-testid="segment.item in preferences"
                >
                  <td
                    [innerText]="prefItem?.name || '-'" data-testid="segment.pref.name"
                  >
                  </td>

                  <td class="w-full">
                    <ng-container
                      *ngIf="prefItem?.categoryId !== -1"
                    >
                      <ngb-progressbar
                        [max]="totalPreference"
                        [value]="prefItem.total"
                        [type]="index >= (segmentItem.clientDescription.preferences?.length / 3 )*2 ? 'danger' : index >= (segmentItem.clientDescription.preferences?.length / 3 ) ? 'warning' : 'success'"
                        [ngClass]="'progress-xs m-t-none m-b-none '"
                      >
                      </ngb-progressbar>
                    </ng-container>
                  </td>

                  <td
                    class="text-right text-ellipsis"
                  >
                    <span class="m-r-xs" data-testid="segment.pref.total">
                      {{ prefItem.total  | loyaCurrency }}
                    </span>
                    <i class="fa le text-muted" ngCurrencyLoya ></i>
                  </td>


                </tr>
              </tabel>
            </responsive-table>
          </ng-container>

          <div
            class="text-center m-t-lg m-b-lg"
            *ngIf="!(!!segmentItem?.clientDescription?.preferences && !!segmentItem?.clientDescription?.preferences?.length)"
          >
            <h4 class="text-muted" data-testid="segment.pref.empty">Предпочтения по товарам отсутствуют</h4>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row">

    <div class=" col-sm-12 col-md-6 ">
      <segment-graph-view
        [title]="'CLV'"
        [model]="segmentItem?.clientDescription?.lifeValue"
        color="rgba(39, 194, 76, 1)"
      ></segment-graph-view>
    </div>

    <div class=" col-sm-12 col-md-6 ">
      <segment-graph-view
        [title]="'Средний чек'"
        [model]="segmentItem?.clientDescription?.avgCheck"
        color="rgba(48, 91, 192 , 1)"
      ></segment-graph-view>
    </div>

    <div class=" col-sm-12 col-md-6 ">
      <segment-graph-view
        [title]="'Частота покупок'"
        [model]="segmentItem?.clientDescription?.avgInterval"
        color="rgba(250, 215, 51 , 1)"
      ></segment-graph-view>
    </div>

    <div class=" col-sm-12 col-md-6 ">
      <segment-graph-view
        [title]="'Длина чека'"
        [model]="segmentItem?.clientDescription?.itemsCount"
        color="rgba(240, 80, 80 , 1)"
      ></segment-graph-view>
    </div>

  </div>

</div>



<div class="d-flex justify-content-center align-items-center m-t-lg"
     *ngIf="loader.isLoading('segment')"
>
  <div class="text-warning m-t-lg">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
