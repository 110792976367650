import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LOYA_CONFIG} from "../../../../shared/variables/loya-config.variable";
import {ngCurrencyL10nService} from "../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {
  BLOCK_ID_AVG_WITHOUT_CLIENT,
  BLOCK_ID_AVG_WTH_CLIENT, BLOCK_ID_CHECK_WITH_CLIENT,
  BLOCK_ID_CHECK_WITHOUT_CLIENT
} from "../block-ids.variables";


@Component({
  selector: 'dashboard-avg-chart',
  template: `



    <div class="card wrapper clearfix">

      <div class="col-sm-12 col-md-6 col-lg-12 m-b-sm">
        <h4 class="font-thin m-t-none m-b-md text-muted">Средний чек ПЛ</h4>
        <div  *ngIf="!isLoading" >

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfigCheck"
            style=" display: block;"
          ></highcharts-chart>

        </div>

        <div class="text-center w-100"
             *ngIf="isLoading"
        >
          <div class="text-info-dker font-thin h1">

              <span class="f-s-6 font-thin">
                  <i class="fa fa-lg fa-spin fa-spinner"></i>
              </span>

          </div>
        </div>

      </div>


      <div class="col-sm-12 col-md-6 col-lg-12 m-b-sm">
        <h4 class="font-thin m-t-none m-b-md text-muted">Среднее количество товаров</h4>

        <div  *ngIf="!isLoading" >
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfigGoods"
            style=" display: block;"
          ></highcharts-chart>
        </div>

        <div class="text-center w-100"
             *ngIf="isLoading"
        >
          <div class="text-info-dker font-thin h1">

              <span class="f-s-6 font-thin">
                  <i class="fa fa-lg fa-spin fa-spinner"></i>
              </span>

          </div>
        </div>

      </div>


    </div>

  `,
  providers: [
    ngCurrencyL10nService
  ]
})
export class DashboardAvgChartComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public isLoading = true

  public Highcharts: typeof Highcharts = Highcharts;
  public chartConfigCheck =  {
    credits: {
      enabled: false
    },

    chart: {
      type: 'bar',
      height: 168
    },
    colors: [
      LOYA_CONFIG.color.success, LOYA_CONFIG.color.primary
    ],

    plotOptions: {
      series: {
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          color: 'white',
          style: {
            textShadow: '0 0 3px black'
          }
        }
      }
    },

    legend: {
      reversed: true
    },

    title: {
      text: ''
    },
    xAxis: {

      title: {
        text: ''
      },

      gridLineColor: 'transparent',
      tickColor: "#FFFFFF",

      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',

      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0

    },
    yAxis: {
      categories: [' '],
      title: {
        text: ' '
      },
      gridLineColor: 'transparent',
      tickColor: "#FFFFFF",

      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',

      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0

    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: ({point.percentage:.0f}%)<br/>',
      shared: true
    },


    series: [
      {
        name: 'Средний чек ПЛ',
        data: []
      },
      {
        name: 'Средний чек НПЛ',
        data: []
      }
    ]


  };

  public chartConfigGoods =  {
    credits: {
      enabled: false
    },

    chart: {
      type: 'bar',
      height: 168
    },
    colors: [
      LOYA_CONFIG.color.orange, LOYA_CONFIG.color.primary
    ],

    plotOptions: {
      series: {
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          color: 'white',
          style: {
            textShadow: '0 0 3px black'
          }
        }
      }
    },

    legend: {
      reversed: true
    },

    title: {
      text: ''
    },
    xAxis: {
      title: {
        text: ''
      },


      gridLineColor: 'transparent',
      tickColor: "#FFFFFF",

      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',

      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0

    },
    yAxis: {
      categories: [''],
      title: {
        text: ''
      },
      gridLineColor: 'transparent',
      tickColor: "#FFFFFF",

      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',

      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0

    },

    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true
    },

    series: [
      {
        name: 'Среднее кол-во товаров ПЛ',
        data: []
      },
      {
        name: 'Среднее кол-во товаров НПЛ',
        data: []
      }]

  };


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.initChart();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  initChart() {
    this.chartConfigCheck.series.forEach(i => {
      i.data = i.data || [];
    });
    this.chartConfigGoods.series.forEach(i => {
      i.data = i.data || [];
    });
  }

  updateValue(params?) {

    this.chartConfigCheck.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });

    this.chartConfigGoods.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });


    this.isLoading = true

    this.dashboardRequestService
      .get$(BLOCK_ID_AVG_WTH_CLIENT, params)
      .subscribe({
        next: result => {
          this.chartConfigCheck.series[0].data[0] = result;
          this.setUnload();
        },

        error: () => {
          this.chartConfigCheck.series[0].data[0] = 0;
          this.setUnload();
        }
      })

    this.dashboardRequestService
      .get$(BLOCK_ID_AVG_WITHOUT_CLIENT, params)
      .subscribe({
        next: result => {
          this.chartConfigCheck.series[1].data[0] = result;
          this.setUnload();
        },

        error: () => {
          this.chartConfigCheck.series[1].data[0] = 0;
          this.setUnload();
        }
      })

     this.dashboardRequestService
        .get$(BLOCK_ID_CHECK_WITH_CLIENT, params)
        .subscribe({
          next: result => {
            this.chartConfigGoods.series[0].data[0] = result;
            this.setUnload();
          },

          error: () => {
            this.chartConfigGoods.series[0].data[0] = 0;
            this.setUnload();
          }
        })


      this.dashboardRequestService
        .get$(BLOCK_ID_CHECK_WITHOUT_CLIENT, params)
        .subscribe({
          next: result => {
            this.chartConfigGoods.series[1].data[0] = result;
            this.setUnload();
          },

          error: () => {
            this.chartConfigGoods.series[1].data[0] = 0;
            this.setUnload();
          }
        })


  }

  setUnload() {
    let notLoaded = false;
    this.chartConfigCheck.series.forEach( i => {
      notLoaded = !i.data.length ? true : notLoaded ;
    });

    this.chartConfigGoods.series.forEach( i => {
      notLoaded = !i.data.length ? true : notLoaded ;
    });

    if (notLoaded)
      return;

    setTimeout(() => this.isLoading = false);
  }



  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
