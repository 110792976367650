/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CampaignCategoryMatrixApiConfiguration as __Configuration } from '../campaign-category-matrix-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Matrix } from '../models/matrix';
@Injectable({
  providedIn: 'root',
})
class CampaignCategoryMatrixApiService extends __BaseService {
  static readonly createMatrixPath = '/campaigncategory/matrix/{partnerId}';
  static readonly getMatrixPath = '/campaigncategory/matrix/{partnerId}';
  static readonly deleteMatrixPath = '/campaigncategory/matrix/{partnerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание матрицы коллизий
   *
   * Создание
   * @param params The `CampaignCategoryMatrixApiService.CreateMatrixParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `matrix`: Описание матрицы коллизий
   *
   * @return Описание матрицы
   */
  createMatrixResponse(params: CampaignCategoryMatrixApiService.CreateMatrixParams): __Observable<__StrictHttpResponse<Matrix>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.matrix;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaigncategory/matrix/${encodeURIComponent(String(params.partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Matrix>;
      })
    );
  }
  /**
   * Создание матрицы коллизий
   *
   * Создание
   * @param params The `CampaignCategoryMatrixApiService.CreateMatrixParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `matrix`: Описание матрицы коллизий
   *
   * @return Описание матрицы
   */
  createMatrix(params: CampaignCategoryMatrixApiService.CreateMatrixParams): __Observable<Matrix> {
    return this.createMatrixResponse(params).pipe(
      __map(_r => _r.body as Matrix)
    );
  }

  /**
   * Запрос матрицы коллизий
   *
   * Запрос матрицы коллизий
   * @param partnerId ID партнера
   * @return Матрица коллизий
   */
  getMatrixResponse(partnerId: number): __Observable<__StrictHttpResponse<Matrix>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaigncategory/matrix/${encodeURIComponent(String(partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Matrix>;
      })
    );
  }
  /**
   * Запрос матрицы коллизий
   *
   * Запрос матрицы коллизий
   * @param partnerId ID партнера
   * @return Матрица коллизий
   */
  getMatrix(partnerId: number): __Observable<Matrix> {
    return this.getMatrixResponse(partnerId).pipe(
      __map(_r => _r.body as Matrix)
    );
  }

  /**
   * Удаление матрицы коллизий
   * @param partnerId ID партнера
   */
  deleteMatrixResponse(partnerId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/campaigncategory/matrix/${encodeURIComponent(String(partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление матрицы коллизий
   * @param partnerId ID партнера
   */
  deleteMatrix(partnerId: number): __Observable<null> {
    return this.deleteMatrixResponse(partnerId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CampaignCategoryMatrixApiService {

  /**
   * Parameters for createMatrix
   */
  export interface CreateMatrixParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Описание матрицы коллизий
     */
    matrix: Matrix;
  }
}

export { CampaignCategoryMatrixApiService }
