import {WARNINGS_MESSAGES} from "./header-alert-box-messages.const";
import {Component, Input, OnChanges, OnInit, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'header-alert-box',
  template: `
    <ul class="nav pull-right">
      <li class="dropdown "
          ngbDropdown
          (click)="readWarnings=true"
          *ngIf="hasWarnings()">
        <a href="javascript:void(0)" class=" dropdown-toggle "
           ngbDropdownToggle
           aria-haspopup="true"
           aria-expanded="false">
          <i class="fa fa-bell-o fa-lg text-primary-lter "></i>
          <i class="visible-xs fa fa-bell-o fa-fw"></i>
          <span class="visible-xs-inline">Уведомления</span>
          <span *ngIf="!readWarnings" class="badge badge-sm up bg-danger m-r-n-sm pull-right-xs"
                [innerText]="warningsCount()"></span>
        </a>

        <div ngbDropdownMenu
             class="pointer drop_cont">
          <div class="">

            <div class="list-group">

          <span class="list-group-item no-border hbox"
                *ngFor = " let warning of warningList"
                [ngSwitch]="warning.type"
          >
              <div *ngSwitchCase="'MAX_CLIENTS_WARNING'" >
                  <!--div class="col v-middle">
                      <i class="fa fa-warning fa-2x text-warning m-r m-t-xs"></i>
                  </div-->
                  <div class="col v-middle">
                      <span [innerHTML]="warning.text"></span><br>
                       <div class="clear m-t-sm m-b-xs " *ngIf="warning.percentClientCount">
                         <ngb-progressbar
                           [value]="warning.percentClientCount"
                           [type]="warning.progressbarType" class="m-b-none"
                         >
                           <b>{{warning.percentClientCount}}%  ( {{ warning.realClientCount }} )</b>
                         </ngb-progressbar>
                           <span class="pull-left f-s-9 text-muted">0</span>
                           <span class="pull-right f-s-9 text-muted">{{warning.maxAllowedClients}}</span>
                       </div>
                  </div>
              </div>

              <div *ngSwitchDefault>
                   <div class="col">
                      <i class="fa fa-warning  m-r-sm m-t-xs" [ngClass]="warning.alarmLevel"></i>
                  </div>
                  <div class="col v-middle">
                      <span [innerHTML]="warning.text"></span><br>
                 </div>

              </div>

          </span>

            </div>
          </div>
        </div>

      </li>
    </ul>

  `
  ,
  styleUrls: ['./header-alert-box.component.scss'],
})
export class  HeaderAlertBoxComponent implements OnInit, OnChanges {


  @Input() currentUser: any;

  warningList: any;
  readWarnings = false;


  constructor() {
  }

  ngOnInit() {
    this.warningList = [];
  }

  ngOnChanges(changes) {
    if (changes.currentUser && changes.currentUser.currentValue) {
      this.warningList = [];
      this.parseServerWarnings(changes.currentUser.currentValue);
    }

  }

  parseServerWarnings(data : any) {

    if (!Array.isArray( data ) )
      return;

    for (let warning of data ) {
      switch (warning.warningType) {
        case 'maxClientWarning':
          warning.warningType='MAX_CLIENTS_WARNING';

        case 'MAX_CLIENTS_LIMIT':
          this.warningList.push({
            type: warning.warningType,
            text: WARNINGS_MESSAGES[warning.warningType][warning.parameterClientLimit.maxAllowedClients<warning.parameterClientLimit.realClientCount]['ru_RU']
                  .replace('{{maxAllowedClients}}',(warning.parameterClientLimit.maxAllowedClients).toFixed(0))
                  .replace('{{realClientCount}}',(warning.parameterClientLimit.realClientCount).toFixed(0))
                  .replace('{{limit}}',(warning.parameterClientLimit.limit).toFixed(0)),
            maxAllowedClients: warning.parameterClientLimit.maxAllowedClients || 0,
            realClientCount: warning.parameterClientLimit.realClientCount || 0,
            percentClientCount: warning.parameterClientLimit.maxAllowedClients > 0 ?
              ((warning.parameterClientLimit.realClientCount / warning.parameterClientLimit.maxAllowedClients) * 100).toFixed(0) : 0,
            progressbarType: warning.parameterClientLimit.maxAllowedClients <= warning.parameterClientLimit.realClientCount ? 'danger': 'warning'
          });

          break;

        case 'couponRuleWarning':
          this.warningList.push({
            type: warning.warningType,
            text: `Количество купонов подходит к максимуму (${warning.parametersCouponRule.couponCount}/${warning.parametersCouponRule.couponMax}) по КП "<a href=\"#!/coupons/rules/view/${warning.parametersCouponRule.couponRuleID}\">${warning.parametersCouponRule.couponRuleName}</a>"`,
            alarmLevel: warning.alarmLevel === 'yellow' ? 'text-warning' :
                        warning.alarmLevel === 'red'    ? 'text-danger'  : 'text-info',

          });

          break;

        default:
          this.warningList.push({
            type: 'text',
            alarmLevel: warning.alarmLevel === 'yellow' ? 'text-warning' :
                        warning.alarmLevel === 'red'    ? 'text-danger'  : 'text-info',
            text: `<span class='f-s-8'>${warning.message}</span>`,
          });
      }
    }

  }

  hasWarnings() {
    return this.warningList && this.warningList.length > 0;
  };

  warningsCount() {
    return this.warningList && this.warningList.length;
  };


}
