'use strict';

import module from 'frontendModule';
import { GroupModelDecorator } from './class/GroupModelDecorator';

import 'model/GroupModel/resource';
import {getParentIdForGroup} from "../view/utils";
import {RoleType} from "module/permissions/conf/roles";
let routePath = module.name + '.segments.groups.test';



export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )
                .url('/:id')

                .template(   () => import( /* webpackChunkName: "assets/pages/segments.groups.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/segments.groups.index" */ "./controllers"       ))
                .controllerAs( '$ctrl' )

                .breadcrumbs(  ["Группы", "А/Б Тестирование"] )

                .resolve('groupItem', [ 'GroupModel', '$stateParams', function (GroupModel, $stateParams) {
                    return new GroupModelDecorator( GroupModel ).get( $stateParams.id );
                }])

                .resolve('parentGroupId', [ 'groupItem', '$state', function (groupItem, $state) {
                    return getParentIdForGroup(groupItem);
                }])

                .resolve('subGroups', [ 'GroupModel', '$stateParams', function (GroupModel, $stateParams) {
                    return new GroupModelDecorator( GroupModel ).getSubgroup( $stateParams.id );
                }])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager]
                })

        }]);

