/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LocationApiConfiguration, LocationApiConfigurationInterface } from './location-api-configuration';

import { LocationApiService } from './services/location-api.service';

/**
 * Provider for all LocationApi services, plus LocationApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    LocationApiConfiguration,
    LocationApiService
  ],
})
export class LocationApiModule {
  static forRoot(customParams: LocationApiConfigurationInterface): ModuleWithProviders<LocationApiModule> {
    return {
      ngModule: LocationApiModule,
      providers: [
        {
          provide: LocationApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
