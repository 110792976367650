<div class="fixed-tool-panel">


  <button type="button" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete')"
          data-testid="limitRewardCampaigns.delete()"
          (click)="confirmActionService.confirm('Вы действительно хотите удалить группу ограничений ' + currentGroup.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
  >
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
  </button>


  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          [hidden]="!statesChangerService.hasButton('edit')"
          data-testid="limitRewardCampaigns.edit()"
          (click)="statesChangerService.click('edit')"
  >

    <i class=" fa fa-pencil">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          data-testid="limitRewardCampaigns.save()"
          [disabled]="cgroupForm && cgroupForm.form && cgroupForm.form.invalid"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          data-testid="limitRewardCampaigns.cancel()"
          (click)="statesChangerService.click('cancel')">

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          data-testid="limitRewardCampaigns.preview()"
          (click)="statesChangerService.click('preview')"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="cgroupForm && cgroupForm.form && cgroupForm.form.invalid"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
          data-testid="limitRewardCampaigns.back()"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>


<div class="card no-back w-full"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentGroup"
  >
    <form name="cgroupForm" #cgroupForm="ngForm" novalidate autocomplete="off">

      <grid-container>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignLimitationsApi.id.name' | transloco"
           grid-item-form-icon = "fa-gears"
      >
        <span
          [innerHTML]="currentGroup.id || ''"
        ></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignLimitationsApi.name.name' | transloco"
           grid-item-form-icon = "fa-book"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentGroup.name"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
          <input
            type="text"
            class="form-control form-control-edittable"
            [(ngModel)]="currentGroup.name"
            name="name"
            [placeholder]="'models.CampaignLimitationsApi.name.placeholder'| transloco"
            [required]="true"
            [maxlength]="40"
          >
        </span>
      </div>

      <div class="grid-item"
             [grid-item-form] = "'models.CampaignLimitationsApi.description.name'| transloco"
             grid-item-form-icon = "fa-align-justify"
        >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentGroup.description"
        ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
          <textarea
            class="form-control form-control-edittable"
            [(ngModel)]="currentGroup.description"
            name="description"
            [placeholder]="'models.CampaignLimitationsApi.description.placeholder' | transloco"
            [required]="false"
            [maxlength]="250"
          ></textarea>
        </span>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignLimitationsApi.limitationType.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
          <span *ngIf="!statesChangerService.isActive"
                [innerHTML]=" (currentGroup.limitationType ? 'models.CampaignLimitationsApi.limitationType.values.' + currentGroup.limitationType : '')  | transloco "
          ></span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
             <select class=" form-control  inline v-middle"
                     name="collision"
                     [(ngModel)]="currentGroup.limitationType"
                     [disabled]="hasActive"
             >
               <option *ngFor="let item of LIMITATION_TYPES_VALUES | keyvalue"
                       [ngValue]="item.key"
                       [innerHTML]="  'models.CampaignLimitationsApi.limitationType.values.' + item.value | transloco"
               ></option>
            </select>
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignLimitationsApi.limitValue.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
        <span [hidden]="statesChangerService.isActive">

          <span
            [innerHTML]="currentGroup.limitValue || ''"
          ></span>

          <span [ngSwitch]="currentGroup.limitationType">
              <span *ngSwitchCase="LIMITATION_TYPES_VALUES.discount">
                    <i class="fa m-l-sm" ngCurrencyLoya ></i>
              </span>
              <span *ngSwitchCase="LIMITATION_TYPES_VALUES.bonus">
                  шт.
              </span>
              <span *ngSwitchCase="LIMITATION_TYPES_VALUES.numberOfTriggering">
                  раз
              </span>

          </span>

        </span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
              <div class="input-group ">
                <input
                  type="number"
                  class="form-control form-control-edittable"
                  [(ngModel)]="currentGroup.limitValue"
                  name="limitValue"
                  [placeholder]="'models.CampaignLimitationsApi.limitValue.placeholder'| transloco"
                  [required]="true"
                  [pattern]="valuePatterns[currentGroup.limitationType] || ''"
                >

                <span class="input-group-addon"
                      [ngSwitch]="currentGroup.limitationType">
                  <span *ngSwitchCase="LIMITATION_TYPES_VALUES.discount">
                        <i class="fa m-l-sm" ngCurrencyLoya ></i>
                  </span>
                  <span *ngSwitchCase="LIMITATION_TYPES_VALUES.bonus">
                      шт.
                  </span>
                  <span *ngSwitchCase="LIMITATION_TYPES_VALUES.numberOfTriggering">
                      раз
                  </span>
                </span>

              </div>
        </span>
        </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignLimitationsApi.periodType.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]=" (currentGroup.periodType ? 'models.CampaignLimitationsApi.periodType.values.' + currentGroup.periodType : '') | transloco"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
           <select class=" form-control  inline v-middle"
                   name="periodType"
                   [(ngModel)]="currentGroup.periodType"
                   [disabled]="hasActive"
           >
               <option *ngFor="let item of PERIOD_TYPES_VALUES "
                       [ngValue]="item"
                       [innerHTML]="  'models.CampaignLimitationsApi.periodType.values.' + item | transloco"
               ></option>
            </select>
        </span>
      </div>


      <ng-container
        *ngIf="currentGroup.periodType === 'period' "
      >

        <div class="grid-item"
             [grid-item-form] = "'models.CampaignLimitationsApi.from.name' | transloco"
             grid-item-form-icon = "fa-align-justify"
        >
          <span [hidden]="statesChangerService.isActive">
            с {{ currentGroup.from | loyaDate }} по {{ currentGroup.till | loyaDate }}
          </span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
            <div class="input-group m-b-sm ">
                <span class="input-group-addon">c</span>
                <input
                  type="date"
                  class="form-control "
                  [(ngModel)]="currentGroup.from"
                  name="from"
                  [placeholder]="'models.CampaignLimitationsApi.from.placeholder'| transloco"
                  [required]="true"
                  [disabled]="hasActive"
                >
            </div>

            <div class="input-group  m-r-sm"></div>

            <div class="input-group m-b-sm ">
                <span class="input-group-addon">по</span>
                <input
                  type="date"
                  class="form-control "
                  [(ngModel)]="currentGroup.till"
                  name="till"
                  [placeholder]="'models.CampaignLimitationsApi.till.placeholder'| transloco"
                  [min]="currentGroup.from"
                  [required]="true"
                  [disabled]="hasActive"
                >

            </div>


          </span>
        </div>

      </ng-container>

      </grid-container>
    </form>

  </div>

</div>

<div class="card w-full no-back"
     *transloco="let t"
>
  <div class="card-header">
    <i class="fa fa-rocket text-success m-r-sm"></i>
    {{ 'pages.Config.Directory.Campaignlimitations.table_header' | transloco }}
  </div>
  <div class="card-body no-padder"
       *ngIf="currentGroup"
  >

    <div class="wrapper padder-lg"
         *ngIf="statesChangerService.isActive"
    >
      <div class="row m-b-xs">
        <div class="col ">
          <span class="text-muted f-s-9">{{ 'pages.Config.Directory.Campaignlimitations.header_select_campaign' | transloco }}:</span>
        </div>
      </div>
      <div class="row">
       <div class="col ">
        <ng-select [(ngModel)]="itemForAdd"
                   [placeholder]="'pages.Config.Directory.Campaignlimitations.select_placeholder' | transloco "
                   [disabled]="isCampaignLoading"
        >
          <ng-template ng-label-tmp let-item="item">
            <i class="fa"
               [ngClass]="{ 'fa-barcode text-primary': item.source === 'couponRule', 'fa-rocket text-success': item.source === 'campaign'  }"></i>
            {{item[ item.source + 'Details'].name}}
          </ng-template>

          <ng-option *ngFor="let item of listCampaings" [value]="item">
            <i class="fa m-r-xs "
               [ngClass]="{ 'fa-barcode text-primary': item.source === 'couponRule', 'fa-rocket text-success': item.source === 'campaign'  }"></i>
            <span [innerHTML]="item[ item.source + 'Details'].name "></span>
            <span class="clearfix text-muted f-s-8">
            <span class="m-l-md"
                      [innerHTML]="item[ item.source + 'Details'].description"></span>
            </span>

          </ng-option>
        </ng-select>
      </div>
      <div class="col col-auto v-middle">

        <button type="button" class="btn  btn-success btn-addon pull-right m-l-sm"
                (click)="addCampaignInGroup()"
                data-testid="limitRewardCampaigns.addCampaignInGroup()"
                [disabled]=" !itemForAdd || isCampaignLoading"
        >

          <i class=" fa fa-plus">
          </i>
          <span class="btn-name m-l-xs hidden-xxs">
               {{  'interface.buttons.add' | transloco }}
            </span>
        </button>

      </div>
    </div>
    </div>

    <table class="table m-b-none b-b m-t-n-xxs table-customers ">
  <thead>
  <tr>
    <th data-sort-ignore="true" [innerText]=" 'models.CampaignLimitationsApi.links.name' | transloco "></th>
    <th data-sort-ignore="true" [innerText]=" 'models.CampaignLimitationsApi.links.description' | transloco"></th>
    <th data-sort-ignore="true" [innerText]=" 'models.CampaignLimitationsApi.links.state' | transloco"></th>
    <th *ngIf="statesChangerService.isActive"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let campaign of listIncludedCampaings "
      [ngClass]="{ 'bg-light dk pointer':lastSwapedCampaign === campaign }"
      (click)="lastSwapedCampaign=false"
      data-testid="limitRewardCampaigns.item of includedCampaings">
    <td class="v-middle" data-testid="limitRewardCampaigns.campaign.name">

      <i class="fa m-r-xs fa-lg m-t-xs m-b-n-xs pull-left"
         [ngClass]="{ 'fa-barcode text-primary': campaign.source === 'couponRule', 'fa-rocket text-success': campaign.source === 'campaign'  }"></i>

      {{ campaign[campaign.source + 'Details' ].name }}
    </td>
    <td class="v-middle" data-testid="limitRewardCampaigns.campaign.description">{{ campaign[campaign.source + 'Details' ].description }}</td>
    <td class="v-middle" data-testid="limitRewardCampaigns.campaign.state">{{ 'models.CampaignApi.state.values.' + campaign[campaign.source + 'Details' ].state  | transloco }}</td>

    <td *ngIf="statesChangerService.isActive">
      <button class="btn btn-sm btn-danger"
              data-testid="limitRewardCampaigns.campaign.remove()"
              (click)="deleteCampaignFromGroup(campaign)"
      >
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
  </tbody>

</table>

    <div class="text-center text-warning m-t-lg"
      *ngIf="isCampaignLoading"
    >
      <div class="">
        <i class="fa fa-3x fa-spin fa-spinner"></i>
      </div>

    </div>

  </div>

</div>
