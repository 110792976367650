/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ClientTransactionsApiConfiguration, ClientTransactionsApiConfigurationInterface } from './client-transactions-api-configuration';

import { ClientTransactionsApiService } from './services/client-transactions-api.service';

/**
 * Provider for all ClientTransactionsApi services, plus ClientTransactionsApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ClientTransactionsApiConfiguration,
    ClientTransactionsApiService
  ],
})
export class ClientTransactionsApiModule {
  static forRoot(customParams: ClientTransactionsApiConfigurationInterface): ModuleWithProviders<ClientTransactionsApiModule> {
    return {
      ngModule: ClientTransactionsApiModule,
      providers: [
        {
          provide: ClientTransactionsApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
