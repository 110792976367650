<div class="fixed-tool-panel">
 <div class="d-flex align-items-center">

   <div class="col-auto p-l-none">

   </div>

   <div class="col text-right">

     <button type="button"
             class="btn btn-sm btn-addon btn-default "
             [uiSref]="'^.index'"
             data-testid="segment.cancel()"

     >

       <i class=" fa fa-remove text-danger">
       </i>
       <span class="btn-name m-l-xs hidden-xxs">
        {{ 'interface.buttons.cancel' | transloco }}
      </span>
     </button>


     <button type="button"
             class="btn btn-addon btn-sm btn-warning btn-addon"
             (click)="resegment()"
             [disabled]="loader.isLoading('resegment')"
             data-testid="segment.resegment()"
     >
       <i class="fa"
          [ngClass]="{ 'fa-exclamation': !loader.isLoading('resegment'), 'fa-spinner': loader.isLoading('resegment') }"
       >
       </i>
       Сформировать <span class="hidden-xxs">новые сегменты</span>
     </button>

   </div>
 </div>
</div>

<div class="d-flex justify-content-center"
     *ngIf="!loader.isLoading('resegment')"
>

  <div class="col-xl-6 col-lg-8 col-md-12 m-t-lg">

    <h4 class="m-b-md">Выберите способ формирования сегментов</h4>


    <div class="radio w-full" *ngFor="let key of segmentTypeList " data-testid="segment.key of segmentTypeList">
      <label class="i-checks">
        <input type="radio"
               [(ngModel)]="selectedType"
               [value]="key"
               data-testid="segment.input.type"
        >
        <i></i>
        {{ RESEGMENT_TYPE_NAMES[key] }}
      </label>
    </div>


  </div>

</div>

<segment-rfm-custom
  *ngIf="!loader.isLoading('resegment') && selectedType === RESEGMENT_TYPES.rfmcustom "
  (modelChange)="customRfmBody = $event"
>
</segment-rfm-custom>

<div class="d-flex justify-content-center align-items-center m-t-lg"
     *ngIf="loader.isLoading('resegment')"
>
  <div class="text-warning m-t-lg">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
