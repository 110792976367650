import {Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {BrandService} from "../../../../../shared/service/api/Brand/Brand.service";
import {ClientCategory} from "../../../../../api/ClientCategoryApi/models/client-category";
import {Brand} from "../../../../../api/BrandApi/models/brand";
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {BrandGoodLinkService} from "../../../../../shared/service/api/BrandGoodLinkApi/BrandGoodLink.service";
import {result} from "lodash";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";


@Component({
  selector: 'brand-view-page',
  templateUrl: './brand-view-page.component.html',
  providers:[
    BrandService,
    StatesChangerService,
    ConfirmActionService,
    BrandGoodLinkService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.brand'
    },
  ],
})
export class BrandViewPageComponent implements OnInit{

  @Input() id: number;

  @ViewChild('brandForm') brandForm;

  public currentBrand;
  private uiRouterState;

  public selectedPartner;
  public brandGoodsList = [];
  public brandListDrop = 0;
  public onSelectSkuBinded: Function;

  public hasSelected = false;
  public deletedAll  = false;

  constructor(
    public  brandService: BrandService,
    public  statesChangerService:StatesChangerService,
    public  confirmActionService :ConfirmActionService,
    private brandGoodLinkService:BrandGoodLinkService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
    this.onSelectSkuBinded = this.onSelectSku.bind(this); // Для проброса в старый ангуляр
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getBrandId(changes.id.currentValue)
        .then( result => {
          if (changes.id.firstChange) {

            this.statesChangerService.state = Object.keys(result).length === 0 ?
              'edit' :
              'view';
          }
        })
      ;
    }
  }

  getBrandId( id?:number  ) {

    return this.brandService
      .getBrand$(id)
      .toPromise()
      .then( result => this.currentBrand = result );
  }


  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  onSave() {

    this.brandService
      .saveBrand$(this.currentBrand)
      .toPromise()
      .then(result => this.currentBrand = result )
      .then(() => this.processBrandList() )
      .then(() =>this.statesChangerService.state = 'view');

  }

  onCancel() {
    if (typeof this.currentBrand.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getBrandId(this.id)
      .then(() => this.resetBrandList())
      .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentBrand.id === "undefined")
      return;

    this.brandService
      .deleteBrand$(this.currentBrand.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  changeSelectedPartner( inPartner ) {
    this.selectedPartner = inPartner;
    this.resetBrandList();
  }

  resetBrandList() {
    this.brandGoodsList = [];
    this.brandListDrop  = 0;
    this.hasSelected = false;
    this.deletedAll  = false;
    return this.getBrandList();
  }

  getBrandList() {
    const PAGER_LIMIT = 50;
    if (!this.currentBrand || typeof this.currentBrand.id === "undefined") {
      this.brandGoodsList = [];
      return Promise.resolve();
    }

    if (this.brandListDrop == -1) {
      return Promise.resolve();
    }

    return this.brandGoodLinkService.getList$({
      id        : this.currentBrand.id,
      partnerId : this.selectedPartner.id,
      pagerDrop : this.brandListDrop,
      pagerLimit: PAGER_LIMIT,
    }).toPromise()
      .then( result => {
        this.brandListDrop = result.length !== PAGER_LIMIT ? -1 : this.brandListDrop + result.length;
        this.brandGoodsList = this.brandGoodsList.concat(result);
      })
  }

  processBrandList() {
    return Promise.resolve()
      .then(() => {
        if (this.deletedAll)
          return this.processDeleteAllSku();
      })
      .then(() => {
        if (this.brandGoodsList.some(i=> i.$isForDelete))
          return this.brandGoodLinkService.deleteList$({
            id         : this.currentBrand.id,
            partnerId  : this.selectedPartner.id,
            listOfSKU  : this.brandGoodsList.filter(i=> i.$isForDelete).map(i=> i.sku)
          }).toPromise();
      })
      .then(()=> {
        if (this.brandGoodsList.some(i=> i.$isForAdd))
          return this.brandGoodLinkService.updateList$({
            id         : this.currentBrand.id,
            partnerId  : this.selectedPartner.id,
            listOfSKU  : this.brandGoodsList.filter(i=> i.$isForAdd).map(i=> i.sku)
          }).toPromise();
      })
      .then(()=> {
        this.brandGoodsList.forEach( i => {
          delete i.$isForAdd;
          delete i.$isForDelete;
          delete i.$isSelected;
        });
      })

  }

  processDeleteAllSku() {
    const LIMIT_PAGER = 100;
    const tick = () =>
      this.brandGoodLinkService.getList$({
        id         : this.currentBrand.id,
        partnerId  : this.selectedPartner.id,
        pagerDrop  : 0,
        pagerLimit : LIMIT_PAGER,
      }).toPromise()
        .then( result => {
          if (!result.length)
            return result;

          return this.brandGoodLinkService.deleteList$({
            id         : this.currentBrand.id,
            partnerId  : this.selectedPartner.id,
            listOfSKU  : result.map( i=> i.sku )
          }).toPromise().then(() => result)
        })
        .then(result => result.length === LIMIT_PAGER ? tick() : undefined);

    return tick();
  }

  filterByDeleted(item) {
    return !item.$isForDelete;
  }

  onSelectSku( addSkuList : any) {

    this.brandGoodsList = this.brandGoodsList || [];
    this.brandGoodsList = this.brandGoodsList.concat(
      addSkuList.list
        .filter( i => !this.brandGoodsList.some( b=> {
          if ( b.sku !== i.id )
            return false;

          if (b.$isForDelete) {
            b.$isForDelete = false;
            b.$isSelected  = false;
          }
          return true;

        }) )
        .map(i=> ({
          '$isForAdd' : true,
          'partnerId' : this.selectedPartner.id,
          'sku'       : i.id,
          'name'      : i.label,
          'dimension' : i.dimension,
          'brandId'   : this.currentBrand.id
        }))
    );
  }

  onDeleteBrands() {

    if ( !this.hasSelected ) {
      this.deletedAll = true;
      this.brandGoodsList = [];
      return;
    }

    this.brandGoodsList.forEach( i => {
      if (i.$isSelected) {
        i.$isForDelete = true;
        i.$isSelected = false;
      }
    })
    this.hasSelected = false;
  }

  onChangeSelected($event) {
    if ($event.target.checked) {
      this.hasSelected = true;
    }

    this.hasSelected = this.brandGoodsList.some( i => i.$isSelected);
  }

}
