<div class="modal-header  bg-light align-items-center"
     *ngIf="!isEditMode"
>

    <i class="fa fa-list text-success m-r-sm f-s-12"> </i>


    <div class=" relative">
      <div class=" overflow-wrap-break">
        <span class="h4"> Выбор купонного правила </span>


        <span class="dropdown"
              *loyaPermitIf="{path:'partner', permitEnabled: ''}"
        >

              <span *ngIf="!isPartnerSetted"
                    ngbDropdown
              >
                   <ul class="dropdown-menu scroll-y"
                       ngbDropdownMenu
                       *ngIf="partnerList.length"
                        style="max-height: 30em;">

                       <li *ngFor="let partnerItem of partnerList"
                           ngbDropdownItem
                           class="w-100 no-padder"
                           data-testid="let partnerItem of partnerList"
                       >
                           <a href="javascript:void(0)"
                              [innerHTML]="partnerItem.value"
                              (click)="partnerId=partnerItem.id"
                              class="w-100"
                           ></a>
                       </li>

                   </ul>

                  <span class="h4" *ngIf="!!getPartnerById(partnerId)?.value"> для партнёра </span>

                  <span
                    ngbDropdownToggle
                    class="h4">

                     <span class=" text-success pointer  " style="max-width:600px" data-testid="partnerId">{{ getPartnerById(partnerId)?.value }} </span>

                     <i class="caret pointer " *ngIf="!!partnerList.length"></i>
                  </span>
              </span>

              <span *ngIf="isPartnerSetted"
                    class="h4"
              >
                  <span ng-if="getPartnerById(partnerId)?.value"> для партнёра <span
                    class="text-success " data-testid="partnerId">{{ getPartnerById(partnerId)?.value }} </span></span>
              </span>

          </span>


      </div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="">

      <button type="button" class="btn btn-sm btn-default m-r-xs btn-addon" data-testid="select()"
              (click)="select()"
      >
        <i class=" fa fa-check"></i>
        <span class="btn-name"
        >Выбрать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default  " data-testid="cancel()"
              (click)="cancel()"
      >
        <!-- TODO подтверждение закрытия -->
        <i class=" fa fa-remove text-danger"></i>
      </button>

    </div>


</div>
<div class="fixed-tool-panel ">

  <div class="dropdown pull-left " ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs collapse-sm" ngbDropdownToggle>
      <i>
          <span class="fa fa-filter"></span>
      </i>
      <span class="btn-name">
          Статусы
          <span class="caret "></span>
      </span>
    </button>

    <ul class="dropdown-menu scrollable"
        ngbDropdownMenu
        style="z-index: 9999; " (click)="$event.stopPropagation()">

      <li class="wrapper-xs padder" (click)="$event.stopPropagation()">
        <label class="checkbox-inline i-checks" (click)="$event.stopPropagation()">
          <input type="checkbox"
                 [checked]="selectedStates?.length === CAMPAIGN_STATE_NAMES?.length"
                 (change)="changeSelectedStatesAll(!!$event?.currentTarget?.checked) "
          ><i></i>Выбрать
          все
        </label>
      </li>

      <li class="b-b m-t-xs m-b-xs"></li>

      <li class="wrapper-xs padder text-ellipsis"
          *ngFor="let item of CAMPAIGN_STATE_NAMES  "
      >
        <label class="checkbox-inline i-checks" (click)="$event.stopPropagation()">
          <input type="checkbox"
                 [checked]="selectedStates.includes(item.key)"
                 (change)="changeSelectedStates(item.key, !!$event?.currentTarget?.checked)"
                 ><i></i>
          {{ item.value }}
        </label>
      </li>

    </ul>

  </div>


</div>
<div class="modal-body campaigns-tree-body pb-0">

  <div class="campaigns-column">
    <div class="campaigns-column-header">



      <div class=" d-block padder mt-3 " >
        <search-input
          [(model)]="searchString"
          placeholder="Название"
        ></search-input>
      </div>

      <div class=" d-block padder mt-2 mb-2" >

        <ng-container
          *ngIf="!selectedAll">

          <span
            class="text-muted m-r-xs">Выбрано купонных правил:</span> <b data-testid="selectedCampaigns.size">{{selectedCampaigns.size}}</b>

          <span
            *ngIf="!!max"
          >
            из
            <span data-testid="selectedCampaigns.max">{{max}}</span>
          </span>
        </ng-container>

        <span
          *ngIf="selectedAll"
          class="text-muted m-r-xs">Выбраны все правила</span>

      </div>

    </div>
    <div class="campaigns-column-body b-t">
      <coupon-rule-view
        [partnerId]="partnerId"
        [(selected)]="selectedCampaigns"
        [searchQuery]="searchString"
        [selectOne]="selectOne"
        [canCheckAll]="canCheckAll"
        [(selectedAll)]="selectedAll"
        [states]="selectedStates"
        [max]="max"
      ></coupon-rule-view>
    </div>
  </div>

</div>


<div class="campaign-rule-block-loading"
     *ngIf="loader.isLoading('selectPrepare')"
>

  <i class="fa fa-spin fa-spinner text-warning fa-5x"></i>

</div>




