<div class="padder p-t-sm p-l-md">

  <ng-container *ngIf="!isLoading">
    <tree-root  [focused]="true" [nodes]="nodes"  [options]="options">
    <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">

      <div class="tree-row" data-testid="treeRow">

        <div class="tree-row_line"></div>

        <div class="tree-row_select-block">
          <div class="checkbox "
               *ngIf="isCheckboxShow(node)"
          >
            <label class="i-checks">
              <!-- ng-class="{ 'indeterminate':ctrl.getIndeterminateState(node) && !node.selected, 'checked warning':ctrl.getIndeterminateState(node) && node.selected }" ng-change="ctrl.select(node, node.selected, $parent.$parent.$parent.node.selected )" ng-disabled="ctrl.isDisabled(node, ctrl)" -->
              <input type="checkbox"
                     [ngClass]="{ 'indeterminate': isIndeterminate(node), 'checked warning':isSubIndeterminate(node) }"
                     [checked]="isChecked(node)"
                     (change)="changeSelected(node, $event)"
                     [name]="getUniqNodeName(node)"
              />
              <i></i>
            </label>
          </div>

          <div class="checkbox "
               *ngIf="isRadioShow(node)"
          >
            <label class="i-checks">
              <input type="checkbox" class="i-radio"
                     [checked]="isChecked(node)"
                     (change)="changeSelected(node, $event)"
                     [name]="getUniqNodeName(node)"
              />
              <i></i>
            </label>
          </div>

        </div>

        <div class="tree-row_expander-icon ">

          <div class=""
               (click)="node.toggleExpanded()"
          >
            <i [ngClass]="getIcon(node)"></i>
          </div>

        </div>

        <div class="tree-row_content-name "
             (click)="isNodeFolder(node) && node.toggleExpanded()"
        >

            <div class="d-inline-block align-top"
                 (click)="isNodeSku(node) && isCheckboxShow(node) && changeSelected(node)"
            >
              <span [class]="node.data.className" [class.title]="true">{{ node.data.name }}</span>

            <span class="text-muted"
                   *ngIf="isNodeSku(node) "
              >
                 {{node.data.id}}
              </span>


            </div>

          <div class="d-inline-block align-top m-l-sm"
               *ngIf="isEditMode"
          >
            <a
              class="text-xs"
              (click)="editClick(node); $event.stopPropagation()"
            >
              <i class="fa fa-pencil text-primary "></i>
              Редактировать
            </a>
          </div>


          <div class="d-inline-block align-top m-l-sm"
               *ngIf="isNodeFolder(node)"
          >

            <span
              *ngIf="!!node.data?.category?.categoryCount || ( node.data?.category?.categoryCount==0 && node.data?.category?.goodCount==0  )"
            >
              <span class="text-muted">
                {{node.data?.category?.categoryCount}}
                <sup><span class="fa fa-folder m-r-xs text-success"></span></sup>
              </span>
            </span>

            <span
              *ngIf="node.data?.category?.goodCount != undefined"
            >
              <span class="text-muted"> {{ node.data?.category?.goodCount }}
                <sup><span class="fa fa-tag text-info"></span></sup>
              </span>
            </span>

          </div>

        </div>

        <div class="tree-row_break"></div>
<!--

        <div class="tree-row_sku-row text-muted"
             *ngIf="isNodeSku(node) && isEditMode"
        >
          {{node.data.id}}
        </div>

-->
        <div *ngIf="isLastNode(node)"
             class="tree-row_add-button m-t-xs"
        >

          <button
            *ngIf="!node?.parent?.data?.$isLoading"
            class="btn btn-default btn-addon btn-sm"
            (click)="getNext.emit(node.parent)"
          >
            <i class="fa fa-plus text-primary"></i>
            <span class="btn-name">
              ещё
            </span>
          </button>

          <div
            *ngIf="!!node?.parent?.data?.$isLoading"
            class="tree-loading-block">
            <i class="fa fa-spin fa-spinner text-warning fa-2x"></i>
          </div>
        </div>

        <div class="tree-row_children"
             *ngIf="isNodeFolder(node)"
        >
          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
        </div>

      </div>

    </ng-template>


    <ng-template #loadingTemplate>
        <div class="tree-loading-block">
          <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
        </div>
    </ng-template>



  </tree-root>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="w-100 text-center">
      <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
    </div>
  </ng-container>
</div>

