const BONUS_START_FIELD            = 'bonusStartMode'  ;
const BONUS_VALUE_FIELD            = 'bonusStartValue' ;

const BONUS_BURN_FIELD             = 'bonusBurnMode'   ;
const BONUS_VALIDITY_FIELD         = 'bonusValidity'   ;
const BONUS_PERIOD_FIELD           = 'bonusPeriod'     ;
const BONUS_BURN_DAY_FIELD         = 'bonusBurnDay'    ;
const BONUS_BURN_MONTH_FIELD       = 'bonusBurnMonth'  ;
const BONUS_BURN_SAVE_DATE_FIELD   = 'bonusBurnSaveDate'  ;

const BONUS_BURN_SAVE_DATE_DAY_FIELD   = 'bonusBurnSaveDay'  ;
const BONUS_BURN_SAVE_DATE_MONTH_FIELD   = 'bonusBurnSaveMonth'  ;

export enum BurnModes {
    UNLIMITED = 'unlimited',   // 'Без срока действия'
    DURATION  = 'duration',    // 'Ограничивать в днях/месяцах'
    ANNUALLY  = 'annually',    // 'До указанного дня года'
    MONTHDAY  = 'monthday'     // 'До указанного дня месяца'
}

export enum StartModes {
    ATONCE       = 'atonce',       // 'Сразу',
    WITHDAYDELAY = 'withdaydelay', //'Отложенное',
    MONTHDAY     = 'monthday'      //'В указанный день месяца'
}

export enum BonusPeriods {
    DAY   = 'day',
    MONTH = 'month'
}


export class BonusConfigHandler {

    private preparedModel: any;
    private fieldsNames = [
        BONUS_START_FIELD,
        BONUS_VALUE_FIELD,
        BONUS_BURN_FIELD,
        BONUS_VALIDITY_FIELD,
        BONUS_PERIOD_FIELD,
        BONUS_BURN_DAY_FIELD,
        BONUS_BURN_MONTH_FIELD,
        BONUS_BURN_SAVE_DATE_FIELD,
        BONUS_BURN_SAVE_DATE_DAY_FIELD,
        BONUS_BURN_SAVE_DATE_MONTH_FIELD,
    ];

  static $inject = ['model', 'fieldPath'];

    constructor( private model: any, private fieldPath?: any ) {

        this.preparedModel = BonusConfigHandler.preparePathInModel( this.model, this.fieldPath );

    }

    get isEnabled() {
        return typeof this.preparedModel[BONUS_START_FIELD] !== "undefined" || typeof this.preparedModel[BONUS_BURN_FIELD] !== "undefined";
    }

    get start_field() { return BONUS_START_FIELD };
    get start_value_field() { return BONUS_VALUE_FIELD };

    get burn_field()  { return BONUS_BURN_FIELD };
    get validity_field() { return BONUS_VALIDITY_FIELD };
    get period_field() { return BONUS_PERIOD_FIELD };
    get burn_day_field() { return BONUS_BURN_DAY_FIELD };
    get burn_month_field() { return BONUS_BURN_MONTH_FIELD };
    get burn_save_date_field() { return BONUS_BURN_SAVE_DATE_FIELD };

    get burn_save_date_day_field() { return BONUS_BURN_SAVE_DATE_DAY_FIELD };
    get burn_save_date_month_field() { return BONUS_BURN_SAVE_DATE_MONTH_FIELD };

    static preparePathInModel( model: any, fieldPath: string ) {

        if ( fieldPath ) {

            return fieldPath.split('.').reduce( ( model: any , index : any ) => {
                model[index] = model[index] || {};
                return model[index];
            }, model);

        }

        return model;

    }

    public init() {

        if (!this.preparedModel || typeof this.preparedModel === "string")
            return;

        this.preparedModel = BonusConfigHandler.preparePathInModel( this.model, this.fieldPath );

        if ( typeof this.preparedModel[this.start_field] === "undefined" ) {
            this.preparedModel[this.start_field] = StartModes.ATONCE;
        }

        if ( typeof this.preparedModel[this.start_value_field] === "undefined" ) {
            this.preparedModel[this.start_value_field] = 0;
        }

        if ( typeof this.preparedModel[this.burn_field] === "undefined" ) {
            this.preparedModel[this.burn_field] = BurnModes.UNLIMITED;
        }

    }

    public clear() {
        delete this.preparedModel[BONUS_START_FIELD];
        delete this.preparedModel[BONUS_VALUE_FIELD];

        delete this.preparedModel[BONUS_BURN_FIELD];
        delete this.preparedModel[BONUS_VALIDITY_FIELD];
        delete this.preparedModel[BONUS_PERIOD_FIELD];
        delete this.preparedModel[BONUS_BURN_DAY_FIELD];
        delete this.preparedModel[BONUS_BURN_MONTH_FIELD];
        delete this.preparedModel[BONUS_BURN_SAVE_DATE_FIELD];

        delete this.model[ this.fieldPath.split('.')[0] ];
    }

    public saveConfig() {

        return {
           [BONUS_START_FIELD]: this.preparedModel[BONUS_START_FIELD],
           [BONUS_VALUE_FIELD]: this.preparedModel[BONUS_VALUE_FIELD],

           [BONUS_BURN_FIELD]:     this.preparedModel[BONUS_BURN_FIELD],
           [BONUS_VALIDITY_FIELD]: this.preparedModel[BONUS_VALIDITY_FIELD],
           [BONUS_PERIOD_FIELD]:   this.preparedModel[BONUS_PERIOD_FIELD],
           [BONUS_BURN_DAY_FIELD]: this.preparedModel[BONUS_BURN_DAY_FIELD],
           [BONUS_BURN_MONTH_FIELD]: this.preparedModel[BONUS_BURN_MONTH_FIELD],
           [BONUS_BURN_SAVE_DATE_FIELD]: this.preparedModel[BONUS_BURN_SAVE_DATE_FIELD]
        };
    }

    public restoreConfig( fields: any ) {

        if (!fields) {
            return;
        }

        Object.keys( fields ).forEach( ( fieldId: string ) => {

            if ( this.fieldsNames.indexOf(fieldId) < 0 )
                return;

            this.preparedModel[ fieldId ] = fields[fieldId];

        });

    }

};
