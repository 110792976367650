'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';

import './factorys';

import 'model/MechanicModel/resource';
import 'model/MechanicCategoryModel/resource';
import 'model/Plugin';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.campaign.mechanics.master';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:mechanicId')

                .template(template)
                .controller(controller)

                .resolve('currentMechanic', ['$stateParams', '$injector', 'MechanicModel', 'savePreviousMechanic', 'installedPluginsList',
                    function ($stateParams, $injector, MechanicModel, savePreviousMechanic, installedPluginsList) {

                        if ($stateParams.mechanicId) {
                            return MechanicModel.get({id: $stateParams.mechanicId}).$promise;
                        } else {

                            var $location = $injector.get('$location'),
                                pluginName = $location.$$url.split('/').slice(-1)[0];
                            pluginName = pluginName.toLowerCase();

                            var objMerge = {};

                            angular.forEach(installedPluginsList, function (intallItem) {

                                if (pluginName == intallItem.name.toLowerCase()) {
                                    objMerge = {
                                        mechType: intallItem.name,
                                        version: intallItem.version || "1.0"
                                    };
                                }

                            });


                            return new MechanicModel(angular.extend({}, {
                                'new': true,  // Флаг что новая
                                inDirectory: 0,
                                version: '1.0',
                                status: 'template'
                            }, objMerge));
                        }

                    }])

                .resolve('mechanicsCategoryList', ['MechanicCategoryModel',  ( MechanicCategoryModel : any ) => MechanicCategoryModel.query().$promise ])

                .resolve('installedPluginsList', ['Plugin',  ( Plugin : any ) => Plugin.getMechanics().installed().$promise ])

                .resolve('isReadOnly', ['currentMechanic', '$injector',  function (currentMechanic, $injector) {

                    if ( $injector.has('loyaPermissions') ) {
                        var loyaPermission = $injector.get('loyaPermissions');

                        if (! loyaPermission.byPath('campaign.mechanics').canUpdate ) {
                            return true;
                        }

                    }

                    return currentMechanic.campaignId !== undefined;
                }])

                .breadcrumbs(["Кампании", "Шаблоны механик"])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                })
            ;

            routeBuilderProvider
                .route( routePath + '.empty' )
                .template('', true)

        }]);
