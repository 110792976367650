
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {CampaignDialogService} from "../../campaign-dialog/service/campaign-dialog.service";
import {legacyDirectiveParams, TriggerDialogService} from "../../trigger-dialog/service/trigger-dialog.service";

@Component({
  selector: 'filter-trigger',
  templateUrl: './filter-trigger.component.html',
  providers: [
    CampaignDialogService
  ]
})
export class FilterTriggerComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public currentPartner;

  @Input() public max: number;
  @Input() public disabled;

  @Input() public isPending = false;
  @Input() public filterIDs;

  public list = [];

  get hasSelectedCampaigns() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private triggerDialogService: TriggerDialogService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {


  }

  openDialog() {

    this.triggerDialogService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})

  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};


    if (typeof this.max !== "undefined") {
      options.max = this.max;
    }

    if (!!this.filterIDs) {
      options.filterIDs = this.filterIDs;
    }

    return options;
  }

  public changeResult(result) {


    if (Array.isArray(result.campaigns)) {

      this.list = result.campaigns.slice();

    }

    this.emitModel();

  }

  private emitModel() {
    this.modelChange.emit(!this.list?.length ? undefined : this.list.map(i => i.id));
  }

  public getName(item) {
    return typeof  item === "object" ? item?.name : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }

}
