import {CouponKind, StickerId} from "./CouponKind";

import {StartPeriodTypes, StopPeriodTypes, PosId} from "../IssuingTypes/IssuingType";



export class Sticker extends CouponKind {

    static id = StickerId;

    public startPeriodArray = [StartPeriodTypes.START_PERIOD_FORM_DATE] ;
    public stopPeriodArray  = [StopPeriodTypes.STOP_PERIOD_FORM_DATE]   ;

    protected sameClientCount = false;
    protected useCount = false;

    get hasBarcode() : boolean {
        return this.issuingType.getId() === PosId;
    }

    public $save() {

        this.ruleItem.couponRule.issueSettings = this.ruleItem.couponRule.issueSettings || {};
        this.ruleItem.couponRule.issueSettings.sameClientCount = 1;

    }

}