

export class GroupModelDecorator{

    constructor( private GroupModel: any ) {

    }

    private updateCampaings(result: any) {
        if (!result.groupsA)
          return result;

        return Promise
          .all(
            result.groupsA.map( item =>
              this.GroupModel.get({id:item.id,state: item.state })
                .$promise
                .then( groupItem => {
                  item.$hasCamaings = (!!groupItem.campaignActive || !!groupItem.campaignInplanned);
                  return item;
                })
            )
          ).then(res=>{
            return result;
          });

    }

    public get( id: number ) {
        return this.GroupModel
                   .getStatesGroupById({id: id})
                   .$promise
                   .then( result =>
                       this.GroupModel
                           .get({ state: result.groupState, id: id })
                           .$promise
                   );
    }

    public getSubgroup( id: number ) {
        return this.GroupModel
            .getSubgroup({id: id})
            .$promise
              .then( this.updateCampaings.bind(this) );
    }

    public saveGroups(id: number, body: any) {
        return this.GroupModel
            .createABGroup({id: id}, body, () => {}, false)
            .$promise
              .then( this.updateCampaings.bind(this) );
    }

    public deleteAll( id: number) {
        return this.GroupModel
            .deleteSubgroup({id: id}, {},  () => {}, false)
            .$promise;
    }

}
