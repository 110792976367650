'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
let routePath   = module.name + '.crm.cards.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(   '<cards-view-page [id]="id"></cards-view-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])

        .breadcrumbs( [ "Клиенты", "Карты", "Просмотр"] )

        .permissions( {
          only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator],
          permit: 'global.clients'
        })

    }]);
