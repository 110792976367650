import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule, NgbModule, NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {TranslocoRootModule} from "../../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {SearchInputModule} from "../../../shared/component/search-input/search-input.module";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";
import {CampaignsIndexPageComponent} from "./index/campaigns-index-page.component";
import {
  FilterCampaignPeriodComponent
} from "./shared/components/filter-campaign-period/filter-campaign-period.component";
import {CampaignPreviewComponent} from "./shared/components/campaign-preview/campaign-preview.component";
import {HighchartsChartModule} from "highcharts-angular";
import {CampaignsViewPageComponent} from "./view/campaigns-view-page.component";
import {CampaignEditComponent} from "./shared/components/campaign-edit/campaign-edit.component";
import {ImageUploaderModule} from "../../../shared/modules/imageUploader/imageUploader.module";
import {CampaignViewComponent} from "./shared/components/campaign-view/campaign-view.component";
import {CampaignRowViewComponent} from "./shared/components/campaign-row-view/campaign-row-view.component";
import {CampaignCircleGraphComponent} from "./shared/components/campaign-circle-graph/campaign-circle-graph.component";
import {
  CampaignExternalCodeComponent
} from "./shared/components/campaign-external-code/campaign-external-code.component";


@NgModule({
  declarations: [
    CampaignsIndexPageComponent,
    FilterCampaignPeriodComponent,
    CampaignPreviewComponent,
    CampaignsViewPageComponent,
    CampaignEditComponent,
    CampaignViewComponent,
    CampaignRowViewComponent,
    CampaignCircleGraphComponent,
    CampaignExternalCodeComponent
  ],
    imports: [
        BrowserModule,
        UpgradeModule,
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        HttpClientModule,
        TranslocoRootModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        NgbModule,
        NgbNavModule,
        FiltersComponentsModule,
        SearchInputModule,
        HighchartsChartModule,
        ImageUploaderModule
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class CampaignsModule {

  constructor() {}

}
