import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GoodGroupApiService} from "../../../../api/GoodGroupApi/services";

import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {GoodGroup} from "../../../../api/GoodGroupApi/models/good-group";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: "root"
})
export class GoodgroupService {

  constructor( private goodGroupApiService:GoodGroupApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    search: {
      field: "sentence"
    },
    sort: {
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    },
    filters: [
      {
        field: 'partnerId',
        currentValue: undefined
      }
    ]
  });

  public getList$(params: GoodGroupApiService.QueryParams){
    return this.goodGroupApiService.query(params)
  }

  public getGoodgroup$( id :number, partnerId: string ) {
    if (!id)
      return new Observable(s => {
        s.next({
          limits : {
            maxDiscountType   : 'percent',
            maxAllowBonusType : 'percent',
            maxAwardBonusType : 'percent'
          },
          partnerId: parseInt(partnerId)
        });
        s.complete();
      });

    return this.goodGroupApiService.get({
      id: id,
      partnerId: parseInt(partnerId)
    }).pipe(
      map( item => {

        item.limits = item.limits || {};
        item.limits.maxDiscountType = item.limits.maxDiscountType || 'percent';
        item.limits.maxAllowBonusType = item.limits.maxAllowBonusType || 'percent';
        item.limits.maxAwardBonusType = item.limits.maxAwardBonusType || 'percent';

        return item;
      })
    );
  }

  public saveGoodgroup$(goodgroup: GoodGroup ) {
    if (typeof goodgroup.id !== "undefined") {
      return this.goodGroupApiService.update({id: goodgroup.id, partnerId: goodgroup.partnerId, goodGroup: goodgroup});
    } else {
      return this.goodGroupApiService.create( goodgroup );
    }
  }

  public deleteGoodgroup$(id: number, partnerId: number) {
    return this.goodGroupApiService.delete({
      id: id,
      partnerId: partnerId
    })
  }

}
