/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FrontendSettingsApiConfiguration as __Configuration } from '../frontend-settings-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FrontendSettings } from '../models/frontend-settings';
import { FrontendSettingsQuery } from '../models/frontend-settings-query';
@Injectable({
  providedIn: 'root',
})
class FrontendSettingsApiService extends __BaseService {
  static readonly addPath = '/fsettings/add';
  static readonly queryPath = '/fsettings';
  static readonly deletePath = '/fsettings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Добавление/изменение настроек
   * @param params Параметры настроек
   */
  addResponse(params: Array<FrontendSettings>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fsettings/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Добавление/изменение настроек
   * @param params Параметры настроек
   */
  add(params: Array<FrontendSettings>): __Observable<null> {
    return this.addResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос настроек
   * @param params Ключ настройки
   * @return Данные
   */
  queryResponse(params: Array<FrontendSettingsQuery>): __Observable<__StrictHttpResponse<Array<FrontendSettings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fsettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FrontendSettings>>;
      })
    );
  }
  /**
   * Запрос настроек
   * @param params Ключ настройки
   * @return Данные
   */
  query(params: Array<FrontendSettingsQuery>): __Observable<Array<FrontendSettings>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<FrontendSettings>)
    );
  }

  /**
   * Удаление настройки по ключу
   * @param key Ключ настройки
   */
  deleteResponse(key: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (key != null) __params = __params.set('key', key.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fsettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление настройки по ключу
   * @param key Ключ настройки
   */
  delete(key: string): __Observable<null> {
    return this.deleteResponse(key).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FrontendSettingsApiService {
}

export { FrontendSettingsApiService }
