import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";


import {CardService} from "../../../../shared/service/api/Card/Card.service";
import {RawCard} from "../../../../api/CardApi/models/raw-card";

import "./cards-index-page.legacy";
import {CARD_STATES, CARD_STATE_NAMES} from "../../../../shared/service/api/Card/Card.values";
import {  Subscription} from "rxjs";

@Component({
  selector: 'cards-index-page',
  templateUrl: './cards-index-page.component.html',
  providers:[
    CardService
  ]
})
export class CardsIndexPageComponent  implements OnInit{

  searchString: string = '';
  listCards: Array<RawCard> = []

  isLoading = false;
  subRequest: Subscription;
  CARD_STATE_NAMES = CARD_STATE_NAMES;

  constructor(
    public cardService:CardService,
  ) {
  }

  get isSearchEnabled() {
    return !!this.cardService.queryParams.getSearch()
  }

  ngOnInit() {
    this.cardService.queryParams.setFilterValue('states', Object.values(CARD_STATES).filter( i=> i !== CARD_STATES.archive));
    this.getList();
  }

  getList() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.subRequest = this.cardService.query$(this.cardService.queryParams.params)
      .subscribe({
        next: result => {
          this.listCards = this.listCards.concat(result);
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });

  }

  onSearchChange(searchString) {
    this.searchString = searchString;
    this.cardService.queryParams.search(this.searchString);
    this.reset();
  }


  onScroll() {
    this.cardService.queryParams.next();
    this.getList();
  }

  reset() {
    this.subRequest.unsubscribe();
    this.isLoading = false;
    this.cardService.queryParams.reset();
    this.listCards = [];
    this.getList();
  }


}
