import 'model/CardModel/resource';

export class CardNumberInput {

    public model : string;
    public required  = false;
    public card  : any;

    public onError  : any;

    public patterns: string[];

    private internalModel: string;

    static $inject = ['$scope','CardModel', '$timeout' ];

    constructor(private $scope: any, private CardModel: any, private $timeout: any ) {}

    public $onInit() {
        this.internalModel = this.model;
    }

    public searchPatternCard( value : string ) {

        return this.CardModel.searchCards({
            'drop'    : 0,
            'limit'   : 10,
            'sentence'      : value,
            'usePrefix'     : true,
            'state' : 'nonactive,active,blocked'
        }).$promise;

    }

    private tiemoutId: any;
    public internalChange( withoutDelay = false ) {

        this.$timeout.cancel(this.tiemoutId);

        const localValue = this.internalModel;


        this.tiemoutId = this.$timeout( () => {


            this.CardModel.getCardByNumber({ number: localValue })
                     .$promise
                     .then( (cardResult: any) => {
                         this.model = cardResult.number;
                         this.card  = cardResult;
                         this.onError = undefined;
                     })
                     .catch((error) => {
                         this.model = localValue;
                         this.card  = undefined;
                         this.onError = error?.data || error;
                     });


        }, withoutDelay ? 0 : 1000 );

    }

};
