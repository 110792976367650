/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SegmentationApiConfiguration, SegmentationApiConfigurationInterface } from './segmentation-api-configuration';

import { SegmentationApiService } from './services/segmentation-api.service';

/**
 * Provider for all SegmentationApi services, plus SegmentationApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    SegmentationApiConfiguration,
    SegmentationApiService
  ],
})
export class SegmentationApiModule {
  static forRoot(customParams: SegmentationApiConfigurationInterface): ModuleWithProviders<SegmentationApiModule> {
    return {
      ngModule: SegmentationApiModule,
      providers: [
        {
          provide: SegmentationApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
