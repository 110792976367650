/* tslint:disable */
type UserState =
  'Active' |
  'Archive';
module UserState {
  export const ACTIVE: UserState = 'Active';
  export const ARCHIVE: UserState = 'Archive';
  export function values(): UserState[] {
    return [
      ACTIVE,
      ARCHIVE
    ];
  }
}

export { UserState }