import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LOYA_CONFIG} from "../../../../shared/variables/loya-config.variable";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_SegmentsId} from "../block-ids.variables";

export const BLOCK_ID = BLOCK_SegmentsId; // Для уникальности

@Component({
  selector: 'dashboard-clients-chart',
  template: `


    <div class="card wrapper">

      <div class="col-sm-12">
        <h4 class="font-thin m-t-none m-b-md text-muted">Клиенты</h4>
      </div>

      <div class="row row-sm text-center"
           style="height:  400px"
      >


        <div class="col-md-12"
             *ngIf="!isLoading"
        >

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfig"
            [(update)]="updateFlag"
            style="width: 100%; height: 400px; display: block;"
          ></highcharts-chart>

        </div>

        <div class="d-flex justify-content-center align-items-center w-100 h-100 col"
             *ngIf="isLoading"
        >
          <div class="text-success-dker ">
            <i class="fa fa-3x fa-spin fa-spinner"></i>
          </div>
        </div>


      </div>

      <no-location-warning
        [params]="params"
      ></no-location-warning>
    </div>


  `,
  providers: [
  ]
})
export class DashboardClientsChartComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public isLoading = true
  public updateFlag = false;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartConfig = {
    credits: {
      enabled: false
    },

    chart: {
      polar: true,
      type: 'line'
    },
    colors: [
      LOYA_CONFIG.color.orange
    ],

    title: {
      text: ''
    },

    xAxis: {
      categories: [],
      tickmarkPlacement: 'on',
      lineWidth: 0
    },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },

    yAxis: {
      title: {
        text: ''
      },
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0
    },

    tooltip: {
      headerFormat: '<b>{series.name}</b>:',
      pointFormat: '{point.y}'
    },

    series: [{
      name: 'Кол-во клиентов по сегментам',
      data: [],
      pointPlacement: 'on'
    }]

  };

  public categories = [];


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.initChart();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;


    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  initChart() {
    this.chartConfig.xAxis.categories = this.categories || [];
    this.chartConfig.series[0].data = [];
  }



  updateValue(params?) {

    this.chartConfig.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });

    this.chartConfig.xAxis.categories.splice(0, this.categories.length);

    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {
          if (!Array.isArray(result)) {
            this.isLoading = false;
            return;
          }

          if (result[0] && Array.isArray(result[0].values)) {
            this.chartConfig.series[0].name =  result[0].name;
            (<any>result[0]?.values)?.forEach(i => {
              this.chartConfig.series[0].data.push(i.value);
              this.chartConfig.xAxis.categories.push(i.name);
            })
          }


          this.isLoading = false;
          this.updateFlag = true;
        },
        error: () => {
          this.isLoading = false;
          this.updateFlag = true;
        }
      })


  }


/*
  updateValue() {

    this.chartConfig.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });

    this.chartConfig.xAxis.categories.splice(0, this.categories.length);


    // ----Удалить
    this.chartConfig.series.forEach( ( item: any ) => {

      for( let i = 0; i<5; i++) {
        item?.data?.push( Math.random() * 10 );
      }

    })
    //----------------


    this.isLoading = true
    setTimeout(() => this.isLoading = false, 4000);
  }


*/


  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
