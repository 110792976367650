import {Component, Input, OnInit, Output} from "@angular/core";
import {EventEmitter} from "@angular/core";

@Component({
  selector: 'sort-view',
  template:
  `
    <span (click)="changeSort()" [ngClass]="{ 'pointer': enabled } ">
      <ng-content></ng-content>
      <i class="fa"
         *ngIf="!disabled"
         [ngClass]="{
              'fa-sort-asc'  : isCurrentField && isUp,
              'fa-sort-desc' : isCurrentField && isDown ,
              'fa-sort'      : enabled
              }"></i>
    </span>

  `,

})
export class SortViewComponent implements OnInit{


    @Input() column : any;
    @Input() params : any;
    @Input() disabled = false

    @Output() onChange = new EventEmitter();

    constructor( ) { }

    get enabled() {
      return !this.disabled && !!this.params._params.sort;
    }

    get isCurrentField() {
      return this.enabled && this.params._params.sort.sortValue === this.column;
    }

    get isUp() {
      return this.enabled && this.params._params.sort.orderValue.toLowerCase() === 'asc';
    }

    get isDown() {
      return this.enabled && this.params._params.sort.orderValue.toLowerCase() === 'desc';
    }

    public ngOnInit() {

    }

    public changeSort = function() {
      if ( !this.enabled )
        return;

      if (!this.isCurrentField)
        this.params.setSort(this.column,'asc');
      else {
        if (this.isUp) {
          this.params.setSort(this.column,'desc');
        } else {
          this.params.setSort(this.column);
        }
      }

      this.onChange.emit();
    }

}

