import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {UserService} from "../../../../../shared/service/api/User/User.service"
import {UserProtocol} from "../../../../../api/UserApi/models"
import {rolesList as systemRolesList, RoleType} from "module/permissions/conf/roles";
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service";
import {CurrentUserService} from "../../../../../shared/service/currentUser/currentUser.service";
import {AccountTransactionService} from "../../../../../shared/service/api/AccountTransaction/AccountTransaction.service";
import {Transaction} from "../../../../../api/TransactionApi/models/transaction";

const dateToText = function(curDate: Date) {
  return`${curDate.getFullYear()}-${('0' + (curDate.getMonth()+1)).slice(-2)}-${('0' + curDate.getDate()).slice(-2)}`
}

@Component({
  selector: 'account-transaction-index-page',
  templateUrl: './account-transaction-index-page.component.html',
  providers: [
    AccountTransactionService
  ]
})
export class AccountTransactionIndexPageComponent implements OnInit{

  listTransactions: Array<Transaction>


  private _filterFrom: any;
  private _filterTo: any;

  private isLoading = true;

  @Input()  get filterFrom() {
    return this._filterFrom;
  }

  set filterFrom(value) {
    this._filterFrom = value;
    this.updateDateFilters();
  }

  @Input() get filterTo() {
    return this._filterTo;
  }

  set filterTo(value) {
    this._filterTo = value;
    this.updateDateFilters();
  }

  get maxDate() {
    if (!this.filterFrom)
      return '';

    let fromDate = new Date(this.filterFrom);
    return dateToText(new Date(fromDate.setMonth(fromDate.getMonth()+3)));
  }

  get minDate() {
    if (!this.filterTo)
      return '';

    let fromDate = new Date(this.filterTo);
    return dateToText(new Date(fromDate.setMonth(fromDate.getMonth()-3)));
  }

  constructor(
    public accountTransactionService: AccountTransactionService
  ) {
  }

  ngOnInit() {

    if (!this.filterTo) {
      let curDate = new Date();
      this.filterTo = dateToText(curDate);
    }
    if (!this.filterFrom) {
      let curDate = new Date();
      curDate.setDate(1);
      this.filterFrom = dateToText(curDate);
    }

    this.isLoading = false;
    this.getList();
  }

  getList() {

    if (this.isLoading)
      return

    this.isLoading = true;
    this.accountTransactionService.getList$(
      this.accountTransactionService.getListParams.params
    ).subscribe(result => {
        this.isLoading = false;
        this.listTransactions = result;
      },() => {
        this.isLoading = false;
    });
  }

  export() {
    this.accountTransactionService.exportList(
      this.accountTransactionService.getListParams.params
    )
  }

  reset() {
    this.getList();
  }

  updateDateFilters() {
    if (this.filterFrom && this.filterTo ) {
      this.accountTransactionService.getListParams.setFilterValue('from', this.filterFrom);
      this.accountTransactionService.getListParams.setFilterValue('to', this.filterTo);
      this.reset();
    } else {
      this.accountTransactionService.getListParams.setFilterValue('to', undefined);
      this.accountTransactionService.getListParams.setFilterValue('from', undefined);
      this.listTransactions=[];
    }
  }

}
