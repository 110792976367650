'use strict';

import module from 'frontendModule';

import './style.scss';

import 'model/CouponRule';

import { CirculationCouponRule } from './controller';
import * as  templateView    from  './index.html';


export default module.component('circulationCouponRule', {
    templateUrl : templateView,
    controller  : CirculationCouponRule,

    bindings : {
        ruleId        : '=',
        ruleState     : '<'
    }

});

