import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ng1-dropdown',
  template: `
      <ng-content></ng-content>
      <div  ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary " id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button>
        <div  class="dropdown-menu" aria-labelledby="dropdownBasic1">
          <button >Action - 1</button>
          <button >Another Action</button>
          <button >Something else is here</button>
        </div>
      </div>
  `,

})
export class Ng1DropdownComponent implements OnInit {

  ngOnInit() {

/*

    if (typeof this.value === "string") {
      this.value = parseFloat(this.value);
    }
*/

  }

}
