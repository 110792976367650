<div class="dropdown  d-inline-block"   ngbDropdown>

  <button class="btn btn-default btn-sm btn-addon collapse-xs"
          ngbDropdownToggle
          [disabled]="disabled"
  >
    <i>
        <span class="fa "
              [ngClass]="{ 'fa-filter': !isPending, 'fa-spin fa-spinner': isPending , 'text-danger': !model  }"

        ></span>
    </i>
    <span class="btn-name">
        {{label}}
        <span class="caret "></span>
    </span>
  </button>

  <ul class="dropdown-menu scrollable "
      ngbDropdownMenu
      style="z-index: 9999; "
      onclick="event.stopPropagation()"
  >

    <li class="wrapper-xs padder radio text-ellipsis"
        *ngFor="let item of preparedInputValues"
    >
      <label class="i-checks" (click)="$event.stopPropagation()">
        <input type="radio"
               [(ngModel)]="model"
               (ngModelChange)="emitModel()"
               [value]="item.id">
        <i></i>
        {{ item.value }}
      </label>
    </li>
  </ul>

</div>
