/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CouponApiConfiguration as __Configuration } from '../coupon-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CouponIssueInfoPlus } from '../models/coupon-issue-info-plus';
import { RawCouponTransaction } from '../models/raw-coupon-transaction';
@Injectable({
  providedIn: 'root',
})
class CouponApiService extends __BaseService {
  static readonly reindexPath = '/coupon/reindex';
  static readonly getCouponIssueInfoPath = '/coupon/couponIssueInfo/{id}';
  static readonly transactionsPath = '/coupon/transactions/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Переиндексировать базу купонов
   *
   * Переиндексировать базу купонов
   */
  reindexResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать базу купонов
   *
   * Переиндексировать базу купонов
   */
  reindex(): __Observable<null> {
    return this.reindexResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос информации о выдаче купона
   *
   * Запрос информации о выдаче купона
   * @param id ID купоннa
   * @return Информация о выдаче купона
   */
  getCouponIssueInfoResponse(id: number): __Observable<__StrictHttpResponse<CouponIssueInfoPlus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/couponIssueInfo/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CouponIssueInfoPlus>;
      })
    );
  }
  /**
   * Запрос информации о выдаче купона
   *
   * Запрос информации о выдаче купона
   * @param id ID купоннa
   * @return Информация о выдаче купона
   */
  getCouponIssueInfo(id: number): __Observable<CouponIssueInfoPlus> {
    return this.getCouponIssueInfoResponse(id).pipe(
      __map(_r => _r.body as CouponIssueInfoPlus)
    );
  }

  /**
   * Запрос чеков, в которых использовался данный купон
   * @param params The `CouponApiService.TransactionsParams` containing the following parameters:
   *
   * - `id`: ID купоннa
   *
   * - `pager.limit`:
   *
   * - `pager.drop`: сколько записей пропустить
   *
   * @return Перечень транзакций
   */
  transactionsResponse(params: CouponApiService.TransactionsParams): __Observable<__StrictHttpResponse<Array<RawCouponTransaction>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/transactions/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawCouponTransaction>>;
      })
    );
  }
  /**
   * Запрос чеков, в которых использовался данный купон
   * @param params The `CouponApiService.TransactionsParams` containing the following parameters:
   *
   * - `id`: ID купоннa
   *
   * - `pager.limit`:
   *
   * - `pager.drop`: сколько записей пропустить
   *
   * @return Перечень транзакций
   */
  transactions(params: CouponApiService.TransactionsParams): __Observable<Array<RawCouponTransaction>> {
    return this.transactionsResponse(params).pipe(
      __map(_r => _r.body as Array<RawCouponTransaction>)
    );
  }
}

module CouponApiService {

  /**
   * Parameters for transactions
   */
  export interface TransactionsParams {

    /**
     * ID купоннa
     */
    id: number;
    pagerLimit?: number;

    /**
     * сколько записей пропустить
     */
    pagerDrop?: number;
  }
}

export { CouponApiService }
