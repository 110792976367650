'use strict';

import module from 'frontendModule';

let routePath = module.name + '.config.directory.goodgroup.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('?partnerId')

        .template(   '<goodgroup-index-page [partner-id]="partnerId"></goodgroup-index-page>', true)
        .controller(['$scope', '$stateParams',
          function($scope, $stateParams) {
            $scope.partnerId = $stateParams.partnerId;
          } ])
        .breadcrumbs( [ "Справочники", "Списки товаров" ]);

    }]);
