import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BrandApiService} from "../../../../api/BrandApi/services";
import {Brand} from "../../../../api/BrandApi/models";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import QueryBrandsParams = BrandApiService.QueryBrandsParams;

@Injectable({
  providedIn: "root"
})
export class BrandService {

  constructor( private brandApiService:BrandApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    search: {
      field: "sentence"
    },
    sort: {
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    },
    filters: [
      {
        field: 'partnerId',
        currentValue: undefined
      }
    ]
  });

  public getList$(params: QueryBrandsParams){
    return this.brandApiService.queryBrands(params)
  }

  public getBrand$( id? :number ) {
    if (!id)
      return  new Observable(s => {
        s.next({});
        s.complete();
      });

    return this.brandApiService.get(id);
  }

  public saveBrand$( brand: Brand ) {
    if (typeof brand.id !== "undefined") {
      return this.brandApiService.update({ id: brand.id, brand: brand });
    } else {
      return this.brandApiService.create( brand );
    }
  }

  public deleteBrand$(id: number) {
    return this.brandApiService.delete(id);
  }

}
