
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";

@Component({
  selector: 'filter-campaign-period',
  templateUrl: './filter-campaign-period.component.html',
  providers: [
  ]
})
export class FilterCampaignPeriodComponent implements OnInit, OnChanges {

  @Input() public modelFrom;
  @Output() public modelFromChange = new EventEmitter();

  @Input() public modelTo;
  @Output() public modelToChange = new EventEmitter();

  @Input() public datesKind;
  @Output() public datesKindChange = new EventEmitter();

  @Output() public onChange = new EventEmitter();

  @Input() public maxPeriodMonth = 0;
  @Input() public disabled;

  @Input() public isPending = false;

  @Input() public requiredFrom = false;
  @Input() public requiredTo = false;

  @ViewChild('filterFormModel') public filterFormModel: NgModel;
  @ViewChild('filterToModel')   public filterToModel: NgModel;

  private _filterFrom: any;
  private _filterTo: any;

  private isLoading = true;

  @Input()  get filterFrom() {
    return this._filterFrom;
  }

  set filterFrom(value) {
    this._filterFrom = value;
    this.emitModel();
  }

  @Input() get filterTo() {
    return this._filterTo;
  }

  set filterTo(value) {
    this._filterTo = value;
    this.emitModel();
  }

  get maxDate() {
    if (!this.filterFrom)
      return '';

    let fromDate = new Date(this.filterFrom);
    return DateUtils.dateToText(new Date(fromDate.setMonth(fromDate.getMonth()+ this.maxPeriodMonth )));
  }

  get minDate() {
    if (!this.filterTo)
      return '';

    let fromDate = new Date(this.filterTo);
    return DateUtils.dateToText(new Date(fromDate.setMonth(fromDate.getMonth() - this.maxPeriodMonth )));
  }


  constructor(
  ) {
  }

  ngOnInit() {
  }


  ngOnChanges(changes) {
    if ( changes['modelTo'] ) {
      this._filterTo = this.modelTo;
      if (this._filterTo)
        this._filterTo = this._filterTo?.replace(/T\d\d.*/,'');
    }

    if ( changes['modelFrom'] ) {
      this._filterFrom = this.modelFrom;
      if (this._filterFrom)
        this._filterFrom = this._filterFrom?.replace(/T\d\d.*/,'');
    }

  }

  private emitModel() {
    this.modelToChange.emit( this.filterToModel.invalid ? undefined : this.filterTo);
    this.modelFromChange.emit(this.filterFormModel.invalid ? undefined : this.filterFrom);
    this.onChange.emit({
      from:this.filterFormModel.invalid ? undefined : this.filterFrom,
      to: this.filterToModel.invalid ? undefined : this.filterTo,
      datesKind: this.datesKind
    });
  }

  public updateValidators(type) {
/*
    setTimeout(() => {
      if (type === 'to' && this.filterFormModel.invalid) {
        this.filterFrom = undefined;
      }

      if (type === 'from' && this.filterToModel.invalid) {
        this.filterTo = undefined;
      }
    })*/
  }

  public onKindChange($event) {

    if (!$event) {
      this.filterTo = undefined;
      this.filterFrom = undefined;
    }

    this.datesKindChange.emit($event);
    this.emitModel();
  }

}
