'use strict';

import module from 'frontendModule';


export default module.service('savePreviousMechanic', function() {

    var savedMechanic = false;

    this.save = function(MechanicModel) {
        savedMechanic = MechanicModel;
        return savedMechanic;
    };

    this.get = function() {
        return savedMechanic;
    };

    this.clear = function() {
        savedMechanic = false;
    };

    this.issetNotSaved = function() {
        return savedMechanic !== false;
    };

});
