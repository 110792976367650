/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AccPastMonthInformingApiConfiguration, AccPastMonthInformingApiConfigurationInterface } from './acc-past-month-informing-api-configuration';

import { AccPastMonthInformingApiService } from './services/acc-past-month-informing-api.service';

/**
 * Provider for all AccPastMonthInformingApi services, plus AccPastMonthInformingApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AccPastMonthInformingApiConfiguration,
    AccPastMonthInformingApiService
  ],
})
export class AccPastMonthInformingApiModule {
  static forRoot(customParams: AccPastMonthInformingApiConfigurationInterface): ModuleWithProviders<AccPastMonthInformingApiModule> {
    return {
      ngModule: AccPastMonthInformingApiModule,
      providers: [
        {
          provide: AccPastMonthInformingApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
