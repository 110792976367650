/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CategoryfullpathApiConfiguration as __Configuration } from '../categoryfullpath-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FullPath } from '../models/full-path';
@Injectable({
  providedIn: 'root',
})
class CategoryfullpathApiService extends __BaseService {
  static readonly getFullPathByCategoryPath = '/good/{partnerId}/{categoryId}/path';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * путь до корня по id категории
   * @param params The `CategoryfullpathApiService.GetFullPathByCategoryParams` containing the following parameters:
   *
   * - `partnerId`: ID  партнера
   *
   * - `categoryId`: ID  категории
   *
   * @return Полный путь категории
   */
  getFullPathByCategoryResponse(params: CategoryfullpathApiService.GetFullPathByCategoryParams): __Observable<__StrictHttpResponse<FullPath>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/good/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}/path`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FullPath>;
      })
    );
  }
  /**
   * путь до корня по id категории
   * @param params The `CategoryfullpathApiService.GetFullPathByCategoryParams` containing the following parameters:
   *
   * - `partnerId`: ID  партнера
   *
   * - `categoryId`: ID  категории
   *
   * @return Полный путь категории
   */
  getFullPathByCategory(params: CategoryfullpathApiService.GetFullPathByCategoryParams): __Observable<FullPath> {
    return this.getFullPathByCategoryResponse(params).pipe(
      __map(_r => _r.body as FullPath)
    );
  }
}

module CategoryfullpathApiService {

  /**
   * Parameters for getFullPathByCategory
   */
  export interface GetFullPathByCategoryParams {

    /**
     * ID  партнера
     */
    partnerId: number;

    /**
     * ID  категории
     */
    categoryId: number;
  }
}

export { CategoryfullpathApiService }
