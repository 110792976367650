import {Injectable} from "@angular/core";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {LocationGroupApiService} from "../../../../api/LocationGroupApi/services/location-group-api.service";
import {LocationGroupQueryParam} from "../../../../api/LocationGroupApi/models/location-group-query-param";
import {LocationGroup} from "../../../../api/LocationGroupApi/models/location-group";
import {Location} from "../../../../api/LocationApi/models/location";
import {of} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LocationGroupService {

  constructor( private locationGroupApiService:LocationGroupApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    },
    filters: [
      {
        field: 'filter.partnerId',
        type: 'object',
        id: 'partnerId',
        currentValue: undefined
      },
      {
        field: 'selectedId',
        currentValue: undefined
      },
    ]
  });

  public getList$(params: LocationGroupQueryParam){
    return this.locationGroupApiService.query(params)
  }

  public get$(id: number){
    return this.locationGroupApiService.get(id)
  }

  getOrCreate$(id?) {

    if (typeof id !== "undefined" && id !=="" && id !== null) {
      return this.get$(id);
    }

    return of(<LocationGroup>{});

  }

  public create$(locationGroup: LocationGroup){
    return this.locationGroupApiService.create(locationGroup)
  }

  public update$(id: number, locationGroup: LocationGroup){
    return this.locationGroupApiService.update({
      id,
      locationGroup
    })
  }

  public createOrUpdate$( locationGroup: LocationGroup) {
    if ( typeof locationGroup?.id !== "undefined")
      return this.update$( locationGroup.id, locationGroup )
    else
      return this.create$( locationGroup )
  }

  public delete$(id: number){
    return this.locationGroupApiService.delete(id)
  }

}
