<div class="fixed-tool-panel">

  <button type="button" class="btn btn-sm  btn-default btn-addon  pull-right"
          ng-disabled="configForm.$invalid"
          *ngIf="!isStatic"
          (click)="saveSettings()"
          [disabled]="!!formElement?.$invalid"
          data-testid="configureModule.save()">
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Сохранить</span>
  </button>

  <button type="button" class="btn btn-sm  btn-default btn-addon  pull-right"
          *ngIf="!isStatic"
          [uiSref]="'^.index'"
          data-testid="configureModule.cancel()">
    <i class=" fa fa-remove text-danger">
    </i>

    <span class="btn-name m-l-xs hidden-xxs">Отменить</span>
  </button>

  <button type="button"
          class="btn btn-sm btn-success btn-addon pull-right"
          [uiSref]="'^.index'"
          data-testid="configureModule.back()">
    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">К списку</span>
  </button>

</div>

<div class="card-header ">

  <div class="clear hbox">
    <div class="col thumb-md">
      <a href="" class=" thumb-md avatar b-3x m-r">
        <img [src]="manifest?.logo"/>
      </a>
    </div>

    <div class="col w-full">
      <div class="m-t-xs m-b-xs">
        <span class="h3" data-testid="module.name">{{ plaginInstallItem.description }}</span>
      </div>
      <div *ngIf="!!manifest.tags">
        <span class="badge m-r-xs bg-primary" data-testid="module.type">{{ manifest.type }}</span>

        <span *ngFor="let tag of manifest.tags" class="badge m-r-xs bg-success"
              data-testid="module.tag">{{ tag }}</span>
      </div>
    </div>
    <div class="col thumb">


      <div class="block text-xxs m-t-sm  text-right">
        <i class="fa fa-circle text-success"></i>
      </div>
      <div class="block text-muted small m-t-sm text-right  text-ellipsis" data-testid="module.version">
        ver. {{ plaginInstallItem.version }}
      </div>

    </div>

  </div>
</div>



<div class="w-100 wrapper-sm"
     *ngIf="!!config && !loader.isLoading('list')"
>
  <ng1-controller
    [controller]="config.controller"
    [templateUrl]="config.templateUrl"
    [(scope)]="scope"
    [contollerProviders]="{
      settingsList: settingsList
    }"
    [useForm]="true"
    (formDummy)="formElement = $event"
  ></ng1-controller>
</div>

<div class="text-center text-warning m-t-lg m-b-lg"
     *ngIf="loader.isLoading('list')"
>
  <i class="fa fa-3x fa-spin fa-spinner"></i>
</div>
