import {Injectable} from "@angular/core";
import {InformingTemplateApiService} from "../../../../api/InformingTemplateApi/services";

import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {InformingTemplate} from "../../../../api/InformingTemplateApi/models/informing-template";

@Injectable({
  providedIn: "root"
})
export class InformingTemplateService {

  constructor( private informingTemplateApiService:InformingTemplateApiService ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "drop",
      limitField : "limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    filters: [
      {
        field: 'selectedId',
        currentValue: undefined
      }
    ]
  });

  public query$(params: InformingTemplateApiService.QueryInformingTemplatesParams){
    return this.informingTemplateApiService.queryInformingTemplates(params)
  }

  public create$(template: InformingTemplate){

    if (!template?.plugin) {
      template.plugin = template.name;
      template.name = template.description;
      template.status = "template";
      template.extension = {};
    }

    return this.informingTemplateApiService.createInformingTemplate(template)
  }

}
