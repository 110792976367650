/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { DibsListMechanicApiConfiguration as __Configuration } from '../dibs-list-mechanic-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UsedBySKUPosition } from '../models/used-by-skuposition';
import { UploadState } from '../models/upload-state';
import { PreviewResponseResult } from '../models/preview-response-result';
import { UploadSettings } from '../models/upload-settings';
import { UploadFileResponse } from '../models/upload-file-response';
@Injectable({
  providedIn: 'root',
})
class DibsListMechanicApiService extends __BaseService {
  static readonly searchPath = '/dibsList/{mechanicId}/search';
  static readonly getListPath = '/dibsList/{mechanicId}';
  static readonly cleanDeployPath = '/dibsList/{mechanicId}';
  static readonly getStatePath = '/dibsList/{mechanicId}/state';
  static readonly queryDraftPath = '/dibsList/{mechanicId}/draft';
  static readonly deployDraftPath = '/dibsList/{mechanicId}/deploy';
  static readonly cancelDraftPath = '/dibsList/{mechanicId}/cancel';
  static readonly updateItemPath = '/dibsList/{mechanicId}/item';
  static readonly addItemPath = '/dibsList/{mechanicId}/item';
  static readonly deleteItemPath = '/dibsList/{mechanicId}/item/{sku}';
  static readonly previewPath = '/dibsList/{mechanicId}/list/{filename}/preview';
  static readonly uploadPath = '/dibsList/{mechanicId}/list/{filename}/upload';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Поиск по списку
   * @param params The `DibsListMechanicApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`: Строка полнотесктового поиска
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список фишечных данных
   */
  searchResponse(params: DibsListMechanicApiService.SearchParams): __Observable<__StrictHttpResponse<Array<UsedBySKUPosition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsedBySKUPosition>>;
      })
    );
  }
  /**
   * Поиск по списку
   * @param params The `DibsListMechanicApiService.SearchParams` containing the following parameters:
   *
   * - `sentence`: Строка полнотесктового поиска
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список фишечных данных
   */
  search(params: DibsListMechanicApiService.SearchParams): __Observable<Array<UsedBySKUPosition>> {
    return this.searchResponse(params).pipe(
      __map(_r => _r.body as Array<UsedBySKUPosition>)
    );
  }

  /**
   * Получить список
   * @param params The `DibsListMechanicApiService.GetListParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список фишечных данных
   */
  getListResponse(params: DibsListMechanicApiService.GetListParams): __Observable<__StrictHttpResponse<Array<UsedBySKUPosition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsedBySKUPosition>>;
      })
    );
  }
  /**
   * Получить список
   * @param params The `DibsListMechanicApiService.GetListParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список фишечных данных
   */
  getList(params: DibsListMechanicApiService.GetListParams): __Observable<Array<UsedBySKUPosition>> {
    return this.getListResponse(params).pipe(
      __map(_r => _r.body as Array<UsedBySKUPosition>)
    );
  }

  /**
   * Удалить список
   * @param mechanicId ID  механики
   */
  cleanDeployResponse(mechanicId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(mechanicId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить список
   * @param mechanicId ID  механики
   */
  cleanDeploy(mechanicId: number): __Observable<null> {
    return this.cleanDeployResponse(mechanicId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Получить состояние
   * @param mechanicId ID  механики
   * @return Список фишечных данных
   */
  getStateResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(mechanicId))}/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * Получить состояние
   * @param mechanicId ID  механики
   * @return Список фишечных данных
   */
  getState(mechanicId: number): __Observable<UploadState> {
    return this.getStateResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * Получить состояние
   * @param params The `DibsListMechanicApiService.QueryDraftParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список данных по SKU в черновике
   */
  queryDraftResponse(params: DibsListMechanicApiService.QueryDraftParams): __Observable<__StrictHttpResponse<Array<UsedBySKUPosition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/draft`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsedBySKUPosition>>;
      })
    );
  }
  /**
   * Получить состояние
   * @param params The `DibsListMechanicApiService.QueryDraftParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `pager.limit`: Число включенных обьектов с информацией , по умолчанию 10
   *
   * - `pager.drop`: Число пропущенных обьектов с информацией , по умолчанию 0
   *
   * @return Список данных по SKU в черновике
   */
  queryDraft(params: DibsListMechanicApiService.QueryDraftParams): __Observable<Array<UsedBySKUPosition>> {
    return this.queryDraftResponse(params).pipe(
      __map(_r => _r.body as Array<UsedBySKUPosition>)
    );
  }

  /**
   * Опубликовать черновик
   * @param mechanicId ID  механики
   * @return Публикация список данных по SKU из черновика
   */
  deployDraftResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(mechanicId))}/deploy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * Опубликовать черновик
   * @param mechanicId ID  механики
   * @return Публикация список данных по SKU из черновика
   */
  deployDraft(mechanicId: number): __Observable<UploadState> {
    return this.deployDraftResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * Очистить черновик
   * @param mechanicId ID  механики
   * @return Очистка списка данных по SKU из черновика
   */
  cancelDraftResponse(mechanicId: number): __Observable<__StrictHttpResponse<UploadState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(mechanicId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadState>;
      })
    );
  }
  /**
   * Очистить черновик
   * @param mechanicId ID  механики
   * @return Очистка списка данных по SKU из черновика
   */
  cancelDraft(mechanicId: number): __Observable<UploadState> {
    return this.cancelDraftResponse(mechanicId).pipe(
      __map(_r => _r.body as UploadState)
    );
  }

  /**
   * Изменение позиции
   * @param params The `DibsListMechanicApiService.UpdateItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `item`: Описание позиции
   */
  updateItemResponse(params: DibsListMechanicApiService.UpdateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.item;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Изменение позиции
   * @param params The `DibsListMechanicApiService.UpdateItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `item`: Описание позиции
   */
  updateItem(params: DibsListMechanicApiService.UpdateItemParams): __Observable<null> {
    return this.updateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Добавление позиции
   * @param params The `DibsListMechanicApiService.AddItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `item`: Описание позиции
   */
  addItemResponse(params: DibsListMechanicApiService.AddItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.item;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Добавление позиции
   * @param params The `DibsListMechanicApiService.AddItemParams` containing the following parameters:
   *
   * - `mechanicId`: ID  механики
   *
   * - `item`: Описание позиции
   */
  addItem(params: DibsListMechanicApiService.AddItemParams): __Observable<null> {
    return this.addItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление позиции
   * @param params The `DibsListMechanicApiService.DeleteItemParams` containing the following parameters:
   *
   * - `sku`: SKU
   *
   * - `mechanicId`: ID  механики
   */
  deleteItemResponse(params: DibsListMechanicApiService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/item/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление позиции
   * @param params The `DibsListMechanicApiService.DeleteItemParams` containing the following parameters:
   *
   * - `sku`: SKU
   *
   * - `mechanicId`: ID  механики
   */
  deleteItem(params: DibsListMechanicApiService.DeleteItemParams): __Observable<null> {
    return this.deleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Предпросмотр файла
   * @param params The `DibsListMechanicApiService.PreviewParams` containing the following parameters:
   *
   * - `settings`: Параметры загрузки
   *
   * - `mechanicId`: ID  механики
   *
   * - `filename`: Имя файла
   *
   * @return Операция выполнена
   */
  previewResponse(params: DibsListMechanicApiService.PreviewParams): __Observable<__StrictHttpResponse<PreviewResponseResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.settings;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.filename))}/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PreviewResponseResult>;
      })
    );
  }
  /**
   * Предпросмотр файла
   * @param params The `DibsListMechanicApiService.PreviewParams` containing the following parameters:
   *
   * - `settings`: Параметры загрузки
   *
   * - `mechanicId`: ID  механики
   *
   * - `filename`: Имя файла
   *
   * @return Операция выполнена
   */
  preview(params: DibsListMechanicApiService.PreviewParams): __Observable<PreviewResponseResult> {
    return this.previewResponse(params).pipe(
      __map(_r => _r.body as PreviewResponseResult)
    );
  }

  /**
   * Предпросмотр файла
   * @param params The `DibsListMechanicApiService.UploadParams` containing the following parameters:
   *
   * - `settings`: Параметры загрузки
   *
   * - `mechanicId`: ID  механики
   *
   * - `filename`: Имя файла
   *
   * @return Операция выполнена
   */
  uploadResponse(params: DibsListMechanicApiService.UploadParams): __Observable<__StrictHttpResponse<UploadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.settings;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dibsList/${encodeURIComponent(String(params.mechanicId))}/list/${encodeURIComponent(String(params.filename))}/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadFileResponse>;
      })
    );
  }
  /**
   * Предпросмотр файла
   * @param params The `DibsListMechanicApiService.UploadParams` containing the following parameters:
   *
   * - `settings`: Параметры загрузки
   *
   * - `mechanicId`: ID  механики
   *
   * - `filename`: Имя файла
   *
   * @return Операция выполнена
   */
  upload(params: DibsListMechanicApiService.UploadParams): __Observable<UploadFileResponse> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as UploadFileResponse)
    );
  }
}

module DibsListMechanicApiService {

  /**
   * Parameters for search
   */
  export interface SearchParams {

    /**
     * Строка полнотесктового поиска
     */
    sentence: string;

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Число включенных обьектов с информацией , по умолчанию 10
     */
    pagerLimit?: number;

    /**
     * Число пропущенных обьектов с информацией , по умолчанию 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for getList
   */
  export interface GetListParams {

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Число включенных обьектов с информацией , по умолчанию 10
     */
    pagerLimit?: number;

    /**
     * Число пропущенных обьектов с информацией , по умолчанию 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for queryDraft
   */
  export interface QueryDraftParams {

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Число включенных обьектов с информацией , по умолчанию 10
     */
    pagerLimit?: number;

    /**
     * Число пропущенных обьектов с информацией , по умолчанию 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for updateItem
   */
  export interface UpdateItemParams {

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Описание позиции
     */
    item: UsedBySKUPosition;
  }

  /**
   * Parameters for addItem
   */
  export interface AddItemParams {

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Описание позиции
     */
    item: UsedBySKUPosition;
  }

  /**
   * Parameters for deleteItem
   */
  export interface DeleteItemParams {

    /**
     * SKU
     */
    sku: string;

    /**
     * ID  механики
     */
    mechanicId: number;
  }

  /**
   * Parameters for preview
   */
  export interface PreviewParams {

    /**
     * Параметры загрузки
     */
    settings: UploadSettings;

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Имя файла
     */
    filename: string;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {

    /**
     * Параметры загрузки
     */
    settings: UploadSettings;

    /**
     * ID  механики
     */
    mechanicId: number;

    /**
     * Имя файла
     */
    filename: string;
  }
}

export { DibsListMechanicApiService }
