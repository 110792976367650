import {IssuingType, StartPeriodTypes, StopPeriodTypes, ManualId} from "./IssuingType";
import {ECoupon} from "../couponKinds/eCoupon";



export class Manual  extends IssuingType {

    static id = ManualId;
    static couponKindsArray  = [ ECoupon ];

    public startPeriodArray = [
        StartPeriodTypes.START_PERIOD_FORM_ISSUE,
        StartPeriodTypes.START_PERIOD_FORM_DATE,
        StartPeriodTypes.START_PERIOD_SCHEDULED
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE,
        StopPeriodTypes.STOP_PERIOD_SCHEDULED,
     //   StopPeriodTypes.STOP_PERIOD_DAY_ISSUE,
    ];

}
