import {Directive, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {PartnerService} from "../../service/api/Partner/Partner.service";

const DEFAULT_REGEXP = new RegExp("^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$");

@Directive({
  selector: '[loyaInputBonus]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: LoyaInputBonusDirective, multi: true},
    PartnerService
  ]
})
export class LoyaInputBonusDirective implements Validator, OnChanges {
  @Input('loyaInputBonus') forPartner: any = false;

  private patternInput = DEFAULT_REGEXP;

  constructor(
    private  partnerService: PartnerService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['forPartner']) {
      if ( this.forPartner >= 0 || parseInt(this.forPartner) >=0 )
        this.getPartnerSettings()
      else
        this.patternInput = DEFAULT_REGEXP;
    }

  }

  private getPartnerSettings() {
      this.partnerService.get$(parseInt(this.forPartner))
      .subscribe({
        next:  ( partnerItem => {

          let count = 0;
          if ( partnerItem && partnerItem.limits && partnerItem.limits.bonusMNDE ) {
            if ( partnerItem.limits.bonusMNDE.toString().indexOf('0.') === 0 ) {
              count = partnerItem.limits.bonusMNDE.toString().replace("0.", "").length;
            }
          }

          this.patternInput = new RegExp(`^((10000000000(\.0{0,${count})?)|[0-9]{1,10}(\.[0-9]{0,${count}})?)$`);
        }),
        error: () => {
          this.patternInput = DEFAULT_REGEXP;
        }
      })

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!(this.patternInput instanceof RegExp))
      return null;

    return ( !!control?.value && this.patternInput.test(control?.value?.toString()) ) ? null : {
      loyaInputBonus: false
    };
  }

}
