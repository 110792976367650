/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { SegmentationApiConfiguration as __Configuration } from '../segmentation-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Segment } from '../models/segment';
import { SegmentationQueryParam } from '../models/segmentation-query-param';
import { JsValue } from '../models/js-value';
import { SegmentRefreshState } from '../models/segment-refresh-state';
@Injectable({
  providedIn: 'root',
})
class SegmentationApiService extends __BaseService {
  static readonly refreshPath = '/segmentation/refresh';
  static readonly queryPath = '/segmentation';
  static readonly deployPath = '/segmentation';
  static readonly deletePath = '/segmentation';
  static readonly createPath = '/segmentation/build/{buildType}';
  static readonly updatePath = '/segmentation/{id}';
  static readonly getStatesPath = '/segmentation/states';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Обновление сегментов
   *
   * Обновление сегментов
   */
  refreshResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/segmentation/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновление сегментов
   *
   * Обновление сегментов
   */
  refresh(): __Observable<null> {
    return this.refreshResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Список сегментов
   *
   * Список сегментов
   * @param params Фильтры запроса
   * @return Список сегментов
   */
  queryResponse(params?: SegmentationQueryParam): __Observable<__StrictHttpResponse<Array<Segment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/segmentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Segment>>;
      })
    );
  }
  /**
   * Список сегментов
   *
   * Список сегментов
   * @param params Фильтры запроса
   * @return Список сегментов
   */
  query(params?: SegmentationQueryParam): __Observable<Array<Segment>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<Segment>)
    );
  }

  /**
   * Публикация сегментов
   *
   * Публикация сегментов
   * @param deployRequest undefined
   */
  deployResponse(deployRequest: {updateCampaign: boolean}): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = deployRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/segmentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Публикация сегментов
   *
   * Публикация сегментов
   * @param deployRequest undefined
   */
  deploy(deployRequest: {updateCampaign: boolean}): __Observable<null> {
    return this.deployResponse(deployRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удалить сегментацию
   *
   * Удалить сегментацию
   */
  deleteResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/segmentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить сегментацию
   *
   * Удалить сегментацию
   */
  delete(): __Observable<null> {
    return this.deleteResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Создать сегментацию
   *
   * Создать сегментацию
   * @param params The `SegmentationApiService.CreateParams` containing the following parameters:
   *
   * - `rules`:
   *
   * - `buildType`: Тип сегментации
   */
  createResponse(params: SegmentationApiService.CreateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.rules;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/segmentation/build/${encodeURIComponent(String(params.buildType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Создать сегментацию
   *
   * Создать сегментацию
   * @param params The `SegmentationApiService.CreateParams` containing the following parameters:
   *
   * - `rules`:
   *
   * - `buildType`: Тип сегментации
   */
  create(params: SegmentationApiService.CreateParams): __Observable<null> {
    return this.createResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Обновить параметры сегмента
   * @param params The `SegmentationApiService.UpdateParams` containing the following parameters:
   *
   * - `params`: Параметры сегмента
   *
   * - `id`: ID сегмента
   */
  updateResponse(params: SegmentationApiService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/segmentation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновить параметры сегмента
   * @param params The `SegmentationApiService.UpdateParams` containing the following parameters:
   *
   * - `params`: Параметры сегмента
   *
   * - `id`: ID сегмента
   */
  update(params: SegmentationApiService.UpdateParams): __Observable<null> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос состояние сегмента
   * @param ids undefined
   * @return Состояние сегмента
   */
  getStatesResponse(ids: Array<number>): __Observable<__StrictHttpResponse<Array<SegmentRefreshState>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/segmentation/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SegmentRefreshState>>;
      })
    );
  }
  /**
   * Запрос состояние сегмента
   * @param ids undefined
   * @return Состояние сегмента
   */
  getStates(ids: Array<number>): __Observable<Array<SegmentRefreshState>> {
    return this.getStatesResponse(ids).pipe(
      __map(_r => _r.body as Array<SegmentRefreshState>)
    );
  }
}

module SegmentationApiService {

  /**
   * Parameters for create
   */
  export interface CreateParams {
    rules: JsValue;

    /**
     * Тип сегментации
     */
    buildType: string;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Параметры сегмента
     */
    params: {name: string, description: string};

    /**
     * ID сегмента
     */
    id: number;
  }
}

export { SegmentationApiService }
