import { IResourceFieldOptions } from "./ngResourceFilter.class";
import {QueryHandler} from "../../QueryHandler.class";

export class ngResourceFilterSet {

    private _fieldFlags: {
        [ name: string ] : boolean
    } = {}
    private _useAll = true;

  static $inject = ['options','paramName','_resetFunction','startOptions'];

    constructor(
        private options: IResourceFieldOptions,
        private paramName: any,
        private _resetFunction: any,
        startOptions: any
    ) {

        if ( typeof options.isObject === "undefined" )
          options.isObject = true;

        this._bindFilterFields();

        if ( startOptions && QueryHandler.getValueByPath( startOptions, this.paramName, options.isObject ) ) {
            this._initValues( QueryHandler.getValueByPath( startOptions, this.paramName, options.isObject ) );
        } else {
            this._updateUseAll( true );
        }

    }

    public getParamQuery = function() {

        if ( this.useAll ) {
            return {};
        }

        let result:any;

        if ( this.options.method !== 'array' ) {
            result = '';
        } else {
            result = [];
        }

        for ( let alias of Object.keys( this.options.values ) ) {

            if ( this[alias] ) {

                if ( this.options.method !== 'array' ) {
                    result += ( !result ? '' : ',' ) + this.options.values[alias];
                } else {
                    result.push( this.options.values[alias] );
                }

            }

        }

        return QueryHandler.setValueByPath({},this.paramName, result ? result : undefined , this.options.isObject);

    };

    private _bindFilterFields = function() {

        for ( let alias in this.options.values ) {

            this._fieldFlags[alias] = false;

            Object.defineProperty( this, alias, {
                get: function () {
                    return this._fieldFlags[alias];
                },
                set: function( status :boolean ) {
                    this._changeFilterValue( alias, status );
                    return true;
                },
                enumerable: true,
                configurable: true
            });

        };

    }

    get $all() {
        return this._useAll;
    }

    set $all( status : boolean ) {
        this._updateUseAll( status );
        this._resetFunction();
    }

    private _updateUseAll = function( status : boolean ) {

        this._useAll = status;
        Object.keys(this._fieldFlags).forEach( key => {
            this._fieldFlags[key] = this._useAll;
        });

    }

    private _initValues = function( startParamValues?: string ) {

        if ( !startParamValues ) {
            return this._updateUseAll(true);
        }

        let innitedValues:any;

        if ( this.options.method !== 'array' ) {
            innitedValues = startParamValues.split(',');
        } else {
            innitedValues = startParamValues;
        }


        let isUseAll = true;

        Object.keys(this._fieldFlags).forEach( key => {
            this._fieldFlags[key] = innitedValues.some( ( v: any) => v===key );
            isUseAll = isUseAll && this._fieldFlags[key];
        });

        if (isUseAll) {
            return this._updateUseAll(true);
        } else {
            this._useAll = isUseAll;
        }

    }

    private _changeFilterValue = function( alias: string, status: boolean ) {

        this._fieldFlags[alias] = status;

        let useAll = true;
        Object.keys(this._fieldFlags).forEach( key => {
            useAll = useAll && this._fieldFlags[key];
        });

        if ( useAll !== this.useAll) {
            this._useAll = useAll;
        }

        this._resetFunction();

    }

}
