import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  OnDestroy
} from "@angular/core";


import "./system-modules-config-page.legacy";
import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {SettingsService} from "../../../../shared/service/api/Settings/Settings.service";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {NewsletterService} from "../../../../shared/service/api/Newsletter/Newsletter.service";
import {FormControl} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {getNg1Service} from "../../../../shared/class/utils/angularjs.utils";
import {PluginInstallService} from "../../../../shared/service/api/PluginInstall/PluginInstall.service";
import {ComponentService} from "../../../../shared/service/api/Component/Component.service";

@Component({
  selector: 'system-modules-config-page',
  templateUrl: './system-modules-config-page.component.html',
  providers:[
    PluginInstallService,
    SettingsService,
    ComponentService,
  ]
})
export class SystemModulesConfigPageComponent implements OnInit, OnChanges, OnDestroy {

  @Input() module;
  public loader = new LoaderHandlerClass();

  public modulesStorageService = getNg1Service('modulesStorage');
  public uiRouterState = getNg1Service('$state');
  public config: any  = {};
  public manifest : any = {};
  public plaginInstallItem: any  = {};

  public settingsList = [];
  public scope;

  public formElement  =  null;

  get isStatic() {
    return  this.manifest && this.manifest.config === true;
  }

  constructor(
    private pluginInstallService: PluginInstallService,
    private settingsService: SettingsService,
    private componentService: ComponentService
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes:SimpleChanges){
    if (changes['module']) {
      this.getModuleConfig();
    }
  }

  async getModuleConfig() {
    if (!this.module){
      this.config = false;
      return;
    }

    this.config = this.modulesStorageService.get(this.module, 'system.modules.modules.config');
    if (!this.config)
      return;

    this.loader.startLoading('list');

    this.manifest  = this.modulesStorageService.get(this.module,'system.modules.modules.manifest');

    await this.pluginInstallService
      .all$()
      .toPromise()
      .then( list => {
        this.plaginInstallItem = list.find(item  =>  item.name  ===  this.module) || {};
      }, () => {
        this.plaginInstallItem = {};
      })

    this.scope = {
      module: Object.assign({},  this.manifest,  this.plaginInstallItem)
    };

    if (this.manifest?.config) {
      await this.componentService.getConfig$(this.module, this.manifest.config)
        .toPromise()
        .then( result => {
          this.scope.module  = this.scope.module || {};
          this.scope.module.configModel = result;
        })

    }

    this.loader.stopLoading('list');
  }

  getSettings()  {
    this.settingsService.query$()
      .pipe(
        this.loader.waitLoading('settings')
      )
      .subscribe(
        {
          next: (result) => {
            this.settingsList = result;
          }
        }
      )
  }

  async saveSettings()  {

    var broadcastSettings = [];

    if (!this.scope.$broadcast)
      return;

    this.scope.$broadcast('config.save', broadcastSettings)

    var responce = broadcastSettings;

    if (!!this.scope?.module?.configModel) {
      await this.componentService.setConfig$(this.module, this.scope?.module?.configModel, this.manifest.config)
        .toPromise()
        .then( result => {
        })

    }

    if (responce.length >= 0) {
      await Promise.all(responce)
          .catch( error  =>  console.error(error) )
    }

    this.uiRouterState.go('^.index');

  }

  ngOnDestroy()  {
  }

}
