<div class="dropdown  d-inline-block"   ngbDropdown>

  <button class="btn btn-default btn-sm btn-addon collapse-xs"
          ngbDropdownToggle
          [disabled]="disabled"
  >
    <i>
        <span class="fa "
              [ngClass]="{ 'fa-filter': !isPending, 'fa-spin fa-spinner': isPending , 'text-danger': !!filterFrom && !!filterTo  }"

        ></span>
    </i>
    <span class="btn-name">
                {{label}}
                <span class="caret "></span>
            </span>
  </button>

  <ul class="dropdown-menu scrollable "
      ngbDropdownMenu
      style="z-index: 9999; "
      onclick="event.stopPropagation()"
  >

    <li class="wrapper-xs padder text-ellipsis">

      <div class="input-group w-md ">
        <span class="input-group-addon" style="min-width:50px">ОТ</span>

        <input type="number"
               class="form-control "
               #filterFormModel="ngModel"
               [(ngModel)]="filterFrom"
               (ngModelChange)="updateValidators('from')"
               data-testid="filter.price.from"
               [required]="requiredFrom"
               [disabled]="disabled"
               [max]="filterTo"
        >

        <span class="input-group-addon" >
          <i class="fa" ngCurrencyLoya></i>
        </span>

        <span class="input-group-btn" *ngIf="filterFrom">
                  <button
                    class="btn btn-danger m-b-none m-t-none"
                    type="button"
                    [disabled]="disabled"
                    (click)="filterFrom = undefined">
                      <i class="fa fa-times"></i>
                  </button>
          </span>
      </div>

    </li>
    <li class="wrapper-xs padder text-ellipsis">

      <div class="input-group m-b-xs">
        <span class="input-group-addon" style="min-width:50px">ДО</span>
        <input type="number"
               class="form-control w-md"
               #filterToModel="ngModel"
               data-testid="filter.price.to"
               [(ngModel)]="filterTo"
               (ngModelChange)="updateValidators('to')"
               [required]="requiredTo"
               [disabled]="disabled"
               [min]="filterFrom"
        >
        <span class="input-group-addon" >
          <i class="fa" ngCurrencyLoya></i>
        </span>
        <span class="input-group-btn" *ngIf="filterTo">
              <button
                class="btn btn-danger  m-b-none m-t-none"
                type="button"
                [disabled]="disabled"
                (click)="filterTo = undefined">
                  <i class="fa fa-times"></i>
              </button>
          </span>
      </div>

    </li>
    <li class=" padder text-right">
      <button
        class="btn w-full btn-danger btn-sm btn-addon m-l-none m-b-xs m-t-none"
        type="button"
        *ngIf="filterTo && filterFrom"
        [disabled]="disabled"
        (click)="filterTo = undefined; filterFrom = undefined;">
        <i class="fa fa-times"></i>
        <span class="ben-name">
            Сбросить
          </span>
      </button>
    </li>

  </ul>

</div>
