'use strict';

import module from 'frontendModule';

import * as moment from "moment";

import "fullcalendar/dist/fullcalendar";

import 'angular-ui-calendar';
import 'model/CampaignCommonModel/resource';
import 'model/CampaignModel/resource';
import 'model/PartnerModel/resource';
import 'model/CampaignCategoryModel/resource';
import 'model/MsgTemplate';
import '../index/service';

import './styles.scss';

let routePath = module.name + '.campaign.campaigns.calendar';
    module.requires.push('ui.calendar');


// @ts-ignore
if (!window.moment)
  // @ts-ignore
  window.moment = moment.default || moment;

export default
    module
    .config(
        ['routeBuilderProvider', routeBuilderProvider => {

          routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.calendar" */ "./views/index.html"  ))
                .controller( () =>  import( /* webpackChunkName: "assets/pages/campaign.campaigns.calendar" */ "./controllers" ) )


                .resolve('PartnerList',          [ 'PartnerModel',          PartnerModel => PartnerModel.query().$promise ])

                .resolve('PartnerList',          [ 'PartnerModel',          PartnerModel => PartnerModel.query().$promise ])
                .resolve('CampaignCategoryList', [ 'CampaignCategoryModel', CampaignCategoryModel => CampaignCategoryModel.query({"pager": {
                    "drop": 0,
                    "limit": 99999
                  }}).$promise ])
                .resolve('MsgTemplateList',      [ 'MsgTemplate',      MsgTemplate => MsgTemplate.query({status: 'campaign'}).$promise ])

                .breadcrumbs([ "Кампании", "Календарь" ]);

        }]);

