import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PartnerApiService} from "../../../../api/PartnerApi/services";
import {Partner} from "../../../../api/PartnerApi/models/partner";
import {getNg1Service} from "../../../class/utils/angularjs.utils";
import { of } from "rxjs";
import {LoyaPermissions} from "../../loyaPermissions/loyaPermissions.service";


@Injectable()
export class PartnerService {

  constructor(
    private partnerApiService: PartnerApiService,
    private loyaPermissions: LoyaPermissions
  ) {
  }

  public query$(){
    return this.partnerApiService.query();
  }

  public get$(id : number){
    return this.partnerApiService.get(id);
  }

  public getLimitsForPartner( id : number) {
    return new Promise( (resolve, reject) => {
      this.get$(id)
        .subscribe( result => {

          if (result && result.limits)
            return resolve(result.limits)

          return reject();

        }, reject);
    })
  }

  public getBonusPattern( id : number) {
    return this.getLimitsForPartner(id)
               .then( ( limits: any ) => {

                  let count = 0;
                  if ( limits.bonusMNDE ) {

                    if ( limits.bonusMNDE.toString().indexOf('0.') === 0 ) {
                      count = limits.bonusMNDE.toString().replace("0.", "").length;
                    }

                  }

                  return new RegExp(`^((10000000000(\\.{0,${count}})?)|[0-9]{1,10}(\\.[0-9]{0,${count}})?)$`);
               });
  }

  public create$( params: Partner ){
    return this.partnerApiService.create( params );
  }

  public update$( params: PartnerApiService.UpdateParams ){
    return this.partnerApiService.update(params);
  }

  public delete$( id: number ){
    return this.partnerApiService.delete(id);
  }

  public getOrInit$( id?: number ){

    if  (id === void 0 ) {

      let defaultConfig: any = {
        'limits': {
          'bonusPeriod'       : 'day',
          'baseBonusPercent'  : 2,
          'useDiscountOnChangeCash': false,
          'useDiscountOnChangeCard': false,
          'allowBonusPaymentRounding': false,
          'bonusStartMode' : "atonce",
          'bonusStartValue': 0,
          'bonusBurnMode': "unlimited",
          'currencyToBonusRatio': 1,
          'considerUsedBonusInAwards' : false,
          'transferRestOnAccount'     : false,
          'discountOnChangeUseGoodLimits' : true,
          'lastVisitBonusBurnSettings' : {
            'lastVisitBonusBurn' : false
          },
          'showRefusePaperCheckSCO' : true,
        },
        pushSettings: {}
      };

      if ( !this.loyaPermissions.byPath('campaign.mechanics.bonus').enabled ) {
        defaultConfig.limits.maxTransactionSummPercent = 0;
        defaultConfig.limits.maxBonusCheckByDay = 0;
      }

      if ( !this.loyaPermissions.byPath('channel.type.sms').enabled ) {
        defaultConfig.senderSms = '';
      }

      if ( !this.loyaPermissions.byPath('channel.type.email').enabled  ) {
        defaultConfig.senderEmail = '';
      }

      if ( !this.loyaPermissions.byPath('channel.type.viber').enabled  ) {
        defaultConfig.senderViber = '';
      }

      return of(defaultConfig);
    }

    return this.get$(id);
  }

  public createOrUpdate$( params: Partner  ){

    if ( typeof params?.id !== "undefined") {
      return this.update$({
        id: params?.id,
        partner: params
      })
    }

    return this.create$(params);
  }


}
