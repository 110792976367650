import {Component, Input, OnDestroy, OnInit} from "@angular/core";

import "./segments-resegment-page.legacy"
import {SegmentationService} from "../../../shared/service/api/Segment/Segment.service";
import {LoaderHandlerClass} from "../../../shared/class/loader-handler.class";
import {
  RESEGMENT_TYPE_NAMES,
  RESEGMENT_TYPES
} from "../../../shared/service/api/Segment/Segment.values";
import {getNg1Service} from "../../../shared/class/utils/angularjs.utils";

@Component({
  selector: 'segments-resegment-page',
  templateUrl: './segments-resegment-page.component.html',
  styles:[],
  providers: [
    SegmentationService,
  ]
})
export class SegmentsResegmentPageComponent implements OnInit, OnDestroy {

  public loader = new LoaderHandlerClass();

  public RESEGMENT_TYPES = RESEGMENT_TYPES;
  public RESEGMENT_TYPE_NAMES = RESEGMENT_TYPE_NAMES;


  public segmentTypeList = [
    RESEGMENT_TYPES.lifecycle,
    RESEGMENT_TYPES.brokendownbyvalue,
    RESEGMENT_TYPES.brokendownbygrowth,
    RESEGMENT_TYPES.rfsegmentation,
    RESEGMENT_TYPES.rfsegmentationforexposure,
    RESEGMENT_TYPES.rfmclustering,
    RESEGMENT_TYPES.rfmfloatclustering,
    RESEGMENT_TYPES.rfmcustom
  ];
  public selectedType = this.segmentTypeList[0];
  public customRfmBody;

  private uiRouterState;
  private subscribers$:any = {};

  constructor(
    public segmentationService: SegmentationService,
  ) {
  }

  ngOnInit() {
    this.uiRouterState = getNg1Service('$state');
  }


  ngOnDestroy() {
    Object.values(this.subscribers$).forEach( (sub: any) => {
      sub && sub?.unsubscribe();
    })
  }


  resegment() {
    let body  = {
      buildType: this.selectedType,
      rules: []
    };

    if ( this.selectedType === RESEGMENT_TYPES.rfmcustom ) {
      body = this.getRfmCustomBody();
    }

    this.loader.startLoading('resegment');
    this.segmentationService.create$(<any>body)
      .subscribe(r => {
        this.uiRouterState.go("^.index");
      }, error => {
        this.loader.stopLoading('resegment');
      })

  }

  getRfmCustomBody() {

    let body  = {
      buildType: this.selectedType,
      rules: this.customRfmBody || [],
    };

    return body;
  }


}
