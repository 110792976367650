
export enum RuleTypes {
    bySum      = 'sumBonus',
    byMaximum  = 'maxBonus',
    byPriority = 'priority'
}

type GroupItem = {
    groupId   : number,
    groupName : string,
    position  : number
}

type RulesItem = {
    position      : number,
    columnGroupId : number,
    rowGroupId    : number,
    rule          : RuleTypes,
    winId?        : number
}

export type RawMatrix = {
    groups : Array<GroupItem>,
    rules  : Array<RulesItem>
}

export const DEFAULT_RULE_TYPE = RuleTypes.bySum;

export class CampaignCategoryMatrix {

    private rulesList   : any = {};
    private groupList   : Array<number> = [];

    protected rawData: RawMatrix

    constructor( rawData: RawMatrix ) {
        this.setRawData(rawData)
    }

    private initRulesList() {

        this.rulesList = {};
        this.rawData.rules.forEach( item => {
            this.rulesList[item.rowGroupId]                     = this.rulesList[item.rowGroupId] || {};
            this.rulesList[item.rowGroupId][item.columnGroupId] = this.rulesList[item.rowGroupId][item.columnGroupId] || {
                rule  : item.rule,
                winId : item.winId
            };
        });

    }

    private initGroupList() {
        this.groupList = [];
        this.rawData.groups.forEach( item => {
            this.groupList[ item.position - 1 ] = item.groupId;
        });
    }

    public setRawData( rawData: RawMatrix ) {

        this.rawData = rawData || {
          rules: [],
          groups: []
        };

        this.initGroupList();
        this.initRulesList();
    }

    public getRawData() {

        let result: any = {
            rules: []
        };

        result.groups = this.groupList.slice(0).map( ( groupId , position ) => <GroupItem>{
            groupId   : groupId,
            position  : position + 1,
            groupName : '',
            groupCollisionType : 'priority',
        });

        for ( let rowGroupIndex  = 0; rowGroupIndex < result.groups.length; rowGroupIndex++ ) {
            for ( let columnGroupIndex = 0; columnGroupIndex < rowGroupIndex; columnGroupIndex++ ) {

                result.rules.push(
                    Object.assign({
                        "position"      : result.rules.length + 1                 ,
                        "columnGroupId" : result.groups[columnGroupIndex].groupId ,
                        "rowGroupId"    : result.groups[rowGroupIndex].groupId    ,
                    }, this.rulesList[result.groups[rowGroupIndex].groupId][result.groups[columnGroupIndex].groupId])
                );

            }
        }

        return result;
    }

    public getGroupList() {
        return this.groupList;
    }

    public getValue( groupRowId: number, groupColumnId: number ) {
        if (!this.rulesList[groupRowId])
            return;

        return this.rulesList[groupRowId][groupColumnId];
    }

    public hasRule(groupRowId: number, groupColumnId: number) {
        return this.groupList.indexOf(groupRowId) > this.groupList.indexOf(groupColumnId);
    }

    public groupUp( groupRow: number ) {

        const groupIndex = this.groupList.indexOf(groupRow);

        if ( groupIndex <=0 ) return;

        return this.groupSetPosition(groupRow, groupIndex - 1);
    }

    public groupDown( groupRow: number ) {

        const groupIndex = this.groupList.indexOf(groupRow);

        if ( groupIndex <0 || groupIndex === this.groupList.length -1 ) return;

        return this.groupSetPosition(groupRow, groupIndex + 1);
    }

    public groupSetPosition( groupRow: number, position: number ) {

        const groupIndex = this.groupList.indexOf(groupRow);

        if ( groupIndex <0 || position< 0 || position > this.groupList.length -1 )
            return;

        const groupColumn = this.groupList[position];

        const indexFrom = groupIndex < position ? groupRow : groupColumn ;
        const indexTo   = groupIndex < position ? groupColumn : groupRow ;

        this.rulesList[indexFrom] = this.rulesList[indexFrom] || {};
        this.rulesList[indexFrom][indexTo] = this.rulesList[indexTo][indexFrom];
        delete this.rulesList[indexTo][indexFrom];

        this.groupList[groupIndex] = this.groupList.splice(position, 1, this.groupList[groupIndex])[0];


        return ;
    }

    public addGroup( groupId: number ) {

        if ( this.groupList.indexOf(groupId) >=0 ) {
            return;
        }

        this.rulesList[groupId] = this.rulesList[groupId] || {};
        this.groupList.forEach( groupIdColumn => {

            this.rulesList[groupId][groupIdColumn] = {
                rule          : DEFAULT_RULE_TYPE
            }

        });

        this.groupList.push(groupId);

    }

    public deleteGroup( groupId: number ) {

        const groupIndex = this.groupList.indexOf(groupId);
        if ( groupIndex < 0 )
            return;

        this.groupList.splice(groupIndex, 1);

        delete this.rulesList[groupId];
        Object.keys(this.rulesList).forEach( index => delete this.rulesList[index][groupId] );

    }

    private _positionsForIndexes = new Map<number,number>();
    public getRulePosition( groupRowId: number, groupColumnId: number ) {

        const rowIndex = this.groupList.indexOf(groupRowId);

            if ( !this._positionsForIndexes.has(rowIndex) ) {
                this._positionsForIndexes.set(rowIndex,  Array.apply(null, {length: rowIndex})
                                         .reduce( (acc : number , b: number , i : number) => acc + i, 0 ) );
            }

        return  this._positionsForIndexes.get(rowIndex) + this.groupList.indexOf(groupColumnId) + 1;

    }

}
