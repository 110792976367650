'use strict';

import module from 'frontendModule';
let routePath = module.name + '.config.directory.brand.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(   '<brand-view-page [id]="id"></brand-view-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])

        .breadcrumbs(  [ "Справочники", "Бренды", "Бренд" ] );

    }])
;
