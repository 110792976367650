import { MENU_ARRAY } from "./menu";
import {IMenuItem} from "./typings";
import {Component, OnInit, OnDestroy} from "@angular/core";
import {getNg1Service} from "../../class/utils/angularjs.utils";

@Component({
  selector: 'nav-menu',
  template: `

        <div class="app-menu">
            <ul class="app-menu__list" >

              <ng-container *ngFor="let menuItem of menu">
                <ng-container *loyaPermitIf="{path: menuItem.permit, permitEnabled: ''}">
                    <li
                        [class]="  getCss( menuItem ) "
                        *ngIf="(permissionShow(menuItem.permissions))"
                    >
                      <a [uiSref]="getFirstSubMenu(menuItem)"
                         class="auto first-level"
                         [ngClass]="{ 'has-sub-items': menuItem.subMenu && menuItem.subMenu.length > 0 }"
                      >
                        <span *ngIf="menuItem.subMenu && menuItem.subMenu.length"
                              class="pull-right text-muted">
                          <i class="fa fa-fw fa-angle-right text"></i>
                          <i class="fa fa-fw fa-angle-down text-active"></i>
                        </span>

                          <i [ngClass]="menuItem.icon"></i>
                          <span [innerText]="menuItem.name"></span>
                      </a>

                      <ul *ngIf="menuItem.subMenu && menuItem.subMenu.length" class="nav nav-sub dk">

                        <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
                          <ng-container *loyaPermitIf="{path: subMenuItem.permit, permitEnabled: ''}">
                            <li [class]=" getCss( subMenuItem  ) "
                                *ngIf="(permissionShow(menuItem.permissions) && permissionShow(subMenuItem.permissions))"
                            >
                                <a  [uiSref]="subMenuItem.uiSref" class="second-level">
                                    <span [innerText]="subMenuItem.name"></span>
                                </a>
                            </li>
                          </ng-container>
                        </ng-container>

                      </ul>

                  </li>
                </ng-container>
              </ng-container>

            </ul>

        </div>
`
  ,
 // styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent
  implements OnInit, OnDestroy {

    public menu : Array<IMenuItem>

    private currentMenuItem : IMenuItem;
    private unBounds : Array<Function> = [];

    private loyaPermissions  : any;
    private menuBuilderArray : any;
    private $transitions     : any;
    private $state           : any;
    private $location        : any;

  constructor(
     /*  private $scope: any,
       private $location: any,  private $transitions: any, private $state: any,  private loyaPermissions: any, private menuBuilderArray: any*/
    ) {
      this.loyaPermissions = getNg1Service('loyaPermissions');
      this.menuBuilderArray = getNg1Service('menuBuilderArray');
      this.$transitions = getNg1Service('$transitions');
      this.$state = getNg1Service('$state');
      this.$location = getNg1Service('$location');
    }

    ngOnInit() {
        this.unBounds = [];

        this.initMenu();

        this.appPartnerPage();
        this.buildMenu();

        this.biuldTransactions();

        this.updateCurrentMenu( this.$state.current.name);
    }

    ngOnDestroy() {
        this.unBounds.map( f => f() );
        delete this.unBounds;
    }

    private initMenu() {

        this.menu = [];
        MENU_ARRAY.forEach( ( i : IMenuItem ) => {

            let obj = Object.assign({}, i);

            if ( Array.isArray(obj.subMenu) ) {
                obj.subMenu = obj.subMenu.slice(0)
            }

            this.menu.push(obj);
        });

    }

    private buildMenu() {
        if (this.menuBuilderArray.getMenu().length) {
            this.menu = this.menu.concat( this.menuBuilderArray.getMenu());
        }

        this.menu.forEach( (menu: IMenuItem) => {

            let subMenu = this.menuBuilderArray.getSubmenu( menu.uiSref );
            if (!Array.isArray( subMenu ) || !subMenu.length)
                return;

            menu.subMenu = ( menu.subMenu || [] ) .concat(subMenu);

        });

    }

    private updateCurrentMenu = ( stateName: any )=> {

        let index = -1;
        let getIndex = (srefArray: any, stateArray: any) => {

            let i = 0;
            while( srefArray[i] && stateArray[i] &&
                ( srefArray[i] === stateArray[i] || ( srefArray[i] === 'index' && stateArray[i] === 'view' ) )
                ) {
                i++;
            }

            return i;
        };

        if ( this.$location.path() === '/crm/clients/view/worksheet') {
            this.currentMenuItem = this.menu[0];
            return;
        }

        stateName = stateName.split('.');
        this.menu.filter( (menu: any ) => menu.uiSref !== 'frontend.crm.clients.view({id:\'worksheet\'})' )
            .forEach( (menu: any) => {

                if ( Array.isArray(menu.subMenu) ) {
                    menu.subMenu.forEach( ( subMenu : any ) => {

                        let curIndex = getIndex(
                            subMenu.uiSref.split('.').map( ( item: any ) => item.replace(/\(.*\)/, '') ),
                            stateName);

                        if ( curIndex > index ) {
                            index = curIndex;
                            this.currentMenuItem = subMenu;
                        }

                    });
                } else {
                    let curIndex = getIndex(
                        menu.uiSref.split('.').map( ( item: any ) => item.replace(/\(.*\)/, '') ),
                        stateName);

                    if ( curIndex >= index ) {
                        index = curIndex;
                        this.currentMenuItem = menu;
                    }
                }

            });

        if (index <= 1) {
            this.currentMenuItem = undefined;
        }

    };

    private biuldTransactions() {
        this.unBounds.push(
            this.$transitions.onSuccess({}, ( trans : any ) => {
                this.updateCurrentMenu(trans.to().name);
            })
        );
    }

    public getCss = ( ngMenu: IMenuItem ) => {
        if ( Array.isArray( ngMenu.subMenu ) ) {
            return  ngMenu.subMenu.indexOf(this.currentMenuItem) >=0 ? 'active' : '';
        } else {
            return  ngMenu === this.currentMenuItem ? 'active' : '';
        }
    };

    public permissionShow = ( permissions: any ) => {

        if ( typeof permissions === "undefined" ) {
            return true;
        } else {
            return this.loyaPermissions.hasUserGrants(permissions);
        }

    };

    public getFirstSubMenu = ( menuItem: any  ) => {

        if ( !Array.isArray(menuItem.subMenu) || menuItem.subMenu.length === 0 ) {
            return menuItem.uiSref;
        }

        for (var i = 0; i < menuItem.subMenu.length; i++ ) {

            if ( this.permissionShow(menuItem.permissions) &&
                 this.permissionShow(menuItem.subMenu[i].permissions)
            ) {
              if ( menuItem.subMenu[i].permit && this.loyaPermissions.byPath(menuItem.subMenu[i].permit).disabled )
                 continue;

              return menuItem.subMenu[i].uiSref;
            }
        }

        return menuItem.uiSref;

    };

    private appPartnerPage() {

        let DIRECTORY_DUB_ARRAY = this.menu.reduce( (acc : Array<any>, i) => i.uiSref === "frontend.config.directory.users.index" ? i.subMenu : acc, []);
        let SETTINGS_SUB_ARRAY = this.menu.reduce( (acc : Array<any>, i) => i.uiSref === "frontend.system.settings.client.modules" ? i.subMenu : acc, []);

        if (this.loyaPermissions.byPath('partner').enabled  ) {
            if ( !DIRECTORY_DUB_ARRAY.some( (i: any) => i.uiSref ===  "frontend.config.directory.partners.index" ) )
                DIRECTORY_DUB_ARRAY.splice(1, 0 ,  {
                    name:   "Партнёры",
                    uiSref: "frontend.config.directory.partners.index"
                });
        } else {
            if ( !SETTINGS_SUB_ARRAY.some( (i: any) => i.uiSref ===  "frontend.config.directory.partners.index" ) )
                SETTINGS_SUB_ARRAY.splice(0, 0 ,  {
                    name:   "Общие параметры",
                    uiSref: "frontend.config.directory.partners.index"
                });
        }
    }

}
