'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.segments.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<segments-index-page></segments-index-page>', true)
        .breadcrumbs( ["Сегменты", "Просмотр"] )

        .permissions( {
            only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
            redirectTo: 'frontend.nopermissions'
        })

    }]);
