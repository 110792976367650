'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.segments.resegment';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<segments-resegment-page></segments-resegment-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])

        .breadcrumbs( ["Сегменты", "Пересегментирование"] )

        .permissions( {
            only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
            redirectTo: 'frontend.nopermissions'
        })

    }]);
