import {NgModule} from "@angular/core";


import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";


import {LoyaPermitIfDirective} from "./permit/loya-permit-if.directive";
import {NgCurrencyLoyaDirective, NgCurrencyNameLoyaDirective} from "./ngCurrencyLoya/ng-currency-loya.directive"
import {UiSrefDirective } from "./uiSref/ui-sref.directive";
import {InnerTextDirectiveDebugger} from "./innerText/innerText.directive";
import {NgModelDebugger} from "./ngModel/ngModel.directive";
import {GoodsPopupDirective} from "./goodsPopup/goodsPopup.directive";
import {ImportGoodgroupExcelDirective} from "./importGoodgroupExcel/importGoodgroupExcel.directive";
import {MaxMinValidatorDirective} from "./maxMinValidator/maxMinValidator.directive";
import {SelectLocationsPopupDirective} from "./selectLocationsPopup/selectLocationsPopup.directive";
import {TranslocoRootModule} from "../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgSelectModule} from "@ng-select/ng-select";
import {PipesComponentsModule} from "../pipes/pipes.components.module";
import {BonusConfigDirective} from "./bonusConfig/bonusConfig.directive";
import {CustomValidatorDirective} from "./customValidator/customValidator.directive";
import {ClientLogHistoryDirective} from "./clientLogHistory/clientLogHistory.directive";
import {LoyaInputBonusDirective} from "./loyaInputBonus/loyaInputBonus.directive";
import {CardNumberInputDirective} from "./cardNumberInput/cardNumberInput.directive";
import {TagInputModule} from "ngx-chips";
import {ReferrerOptionsDirective} from "./referrerOptions/referrerOptions.directive";


@NgModule({
  declarations: [
    LoyaPermitIfDirective,
    NgCurrencyLoyaDirective,
    UiSrefDirective,
    InnerTextDirectiveDebugger,
    NgModelDebugger,
    GoodsPopupDirective,
    ImportGoodgroupExcelDirective,
    MaxMinValidatorDirective,
    CustomValidatorDirective,
    NgCurrencyNameLoyaDirective,
    LoyaPermitIfDirective,
    SelectLocationsPopupDirective,
    BonusConfigDirective,
    ClientLogHistoryDirective,
    LoyaInputBonusDirective,
    CardNumberInputDirective,
    ReferrerOptionsDirective
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
    TranslocoRootModule,
    InfiniteScrollModule,
    NgSelectModule,
    PipesComponentsModule,
    TagInputModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports:[
    LoyaPermitIfDirective,
    NgCurrencyLoyaDirective,
    UiSrefDirective,
    InnerTextDirectiveDebugger,
    NgModelDebugger,
    GoodsPopupDirective,
    ImportGoodgroupExcelDirective,
    MaxMinValidatorDirective,
    CustomValidatorDirective,
    NgCurrencyNameLoyaDirective,
    LoyaPermitIfDirective,
    SelectLocationsPopupDirective,
    BonusConfigDirective,
    ClientLogHistoryDirective,
    LoyaInputBonusDirective,
    CardNumberInputDirective,
    ReferrerOptionsDirective
  ]
})
export class DirectiveModule {

  constructor() {}

}
