<div class="card m-l m-r m-t">
  <div class="card-body"
       id="anchorI1"
  >
    <div class="d-flex">

      <div class="col-auto padder-sm">
        <i class="fa fa-rocket fa-2x"
           [ngClass]=" 'text-' + CAMPAIGN_STATES_COLORS[campaignItem?.campaign?.state]"
        ></i>
      </div>

      <div class="col">

        <h4 class=" overflow-wrap-break m-b-none" data-testid="campaign.name">
          {{ campaignItem.campaign.name || '' }}
        </h4>

        <div class="small m-b-xs">

          <span [innerText]="CAMPAIGN_STATE_NAMES[campaignItem?.campaign?.state] || campaignItem?.campaign?.state"
                [ngClass]=" 'text-' + CAMPAIGN_STATES_COLORS[campaignItem?.campaign?.state]"
                data-testid="campaign.state"></span>
        </div>

        <campaign-tags
          [model]="campaignItem.campaign.listOfTags"
          [isView]="true"
          [campaignId]="campaignItem.campaign.id"
        ></campaign-tags>

        <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-xs"></div>

        <div class="text-muted text-small overflow-wrap-break" data-testid="campaign.description">
          {{ campaignItem.campaign.description || '' }}
        </div>

      </div>

      <div class="col-auto  no-padder text-right font-thin text-muted text-small"
           data-testid="campaign.id"
           *ngIf="campaignItem?.campaign?.id>=0"
      >
          ID: <span class="">{{ campaignItem.campaign.id }}</span>
      </div>

    </div>
  </div>
</div>


<div class=" w-full padding">
  <div class="row campaign-column-row">
    <div class="col col-campaign-column">
      <div class="card">
        <div class="card-body p-t-none p-b-none">

            <campaign-row-view
              name="Партнер"
            >
              <i class="fa fa-spin fa-spinner text-warning"
                 *ngIf="loader.isLoading('partner')"
              ></i>
              <span data-testid="campaign.partner"
                    [innerText]="getPartnerById(campaignItem?.campaign?.partnerId)"
              ></span>
            </campaign-row-view>

            <campaign-row-view
              name="Группа кампании"
            >
               <span data-testid="campaign.category">
                {{ campaignItem?.campaign?.categoryName }}
               </span>
            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.locationGroup?.length"
              name="Локации"
            >
               <span *ngFor="let locationGroup of campaignItem.locationGroup ;  let last = last;" data-testid="campaign.locationGroup">
                 {{ locationGroup?.name || '' }} {{ last ? '' : ','}}
                </span>
            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.location?.length"
              name="Точки продаж"
            >
               <span *ngFor="let location of campaignItem.location | slice:0:3;  let last = last;" data-testid="campaign.location">
                {{ location?.name || '' }} {{ last ? '' : ','}}
              </span>

              <div *ngIf="campaignItem?.location?.length>3"
                   class="pos-rlt table-big-link">


                <ng-template #locationTemplate >

                  <div style="max-height:300px; min-width:200px;"
                       class="mini-scroll scroll-y  ">

                    <div class="text-word-break  " *ngFor="let t of campaignItem.location " data-testid="campaign.popup.location">
                      {{t.name }}
                    </div>

                  </div>

                </ng-template>


                <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                   href="javascript:void(0)"

                   [ngbPopover]="locationTemplate"
                   [popoverTitle]="'Для магазинов'"
                   [placement]="'bottom'"
                   data-testid="campaign.location.more"

                >
                  + ещё {{campaignItem.location.length-3}}
                </a>

              </div>


            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.exceptLocationGroup?.length"
              name="Исключённые локации"
            >
                 <span *ngFor="let locationGroup of campaignItem.exceptLocationGroup ;  let last = last;" data-testid="campaign.exceptLocationGroup">
                   {{ locationGroup?.name || '' }} {{ last ? '' : ','}}
                  </span>
            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.exceptLocation?.length"
              name="Исключённые точки продаж"
            >
                 <span *ngFor="let location of campaignItem.exceptLocation | slice:0:3;  let last = last;" data-testid="campaign.exceptLocation">
                  {{ location?.name || '' }} {{ last ? '' : ','}}
                </span>

              <div *ngIf="campaignItem?.exceptLocation?.length>3"
                   class="pos-rlt table-big-link">


                <ng-template #exceptLocationTemplate >

                  <div style="max-height:300px; min-width:200px;"
                       class="mini-scroll scroll-y  ">

                    <div class="text-word-break  " *ngFor="let t of campaignItem.exceptLocation " data-testid="campaign.popup.exceptLocation">
                      {{t.name }}
                    </div>

                  </div>

                </ng-template>


                <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                   href="javascript:void(0)"

                   [ngbPopover]="exceptLocationTemplate"
                   [popoverTitle]="'Для магазинов'"
                   [placement]="'bottom'"
                   data-testid="campaign.exceptLocation.more"

                >
                  + ещё {{campaignItem.exceptLocation.length-3}}
                </a>

              </div>


            </campaign-row-view>

            <campaign-row-view
              *loyaPermitIf="{path:'segments', permitEnabled: ''}"
              name="Целевая аудитория"
              data-testid="campaign.targetAudience"
            >
              <span *ngIf="campaignItem.segment" data-testid="segment.name">сегмент - {{campaignItem?.segment?.name}}</span>
              <span *ngIf="campaignItem.group" data-testid="group.name">группа - {{campaignItem?.group?.name}}</span>
              <span *ngIf="!campaignItem?.segment && !campaignItem?.group" data-testid="segment.group.all">Все</span>

            </campaign-row-view>

            <campaign-row-view
              *loyaPermitIf="{path:'segments', permitDisabled: ''}"
              name="Целевая аудитория"
              data-testid="campaign.targetAudience"
            >

              <span *ngIf="campaignItem?.group?.name==='Все УПЛ'">Участники ПЛ</span>
              <span *ngIf="campaignItem?.group?.name==='Все неУПЛ'">Не участники ПЛ</span>
              <span *ngIf="campaignItem?.group && campaignItem?.group?.name!=='Все неУПЛ' && campaignItem?.group?.name!=='Все УПЛ'" data-testid="group.name">группа - {{campaignItem?.group?.name}}</span>

              <span *ngIf="!campaignItem?.group">Все</span>

            </campaign-row-view>

            <campaign-row-view
              *loyaPermitIf="{path:'campaign.settings.exceptionAudience', permitEnabled: ''}"
              name="Ограничения целевой аудитории"
              data-testid="campagin.exceptionAudience"
            >

              <span *ngIf="campaignItem?.exceptSegment" data-testid="exceptSegment.name">исключая сегмент - {{ campaignItem?.exceptSegment?.name }}</span>
              <span *ngIf="campaignItem?.exceptGroups">
                <span class="text-muted">исключая группы:</span>
                <div class="text-right clearfix" >
                  <span class="clearfix text-break" *ngFor="let gr of campaignItem.exceptGroups"  data-testid="exceptGroup.name">
                    {{ gr.name }}
                  </span>
                </div>
              </span>
              <span *ngIf="!campaignItem?.exceptSegment && !campaignItem?.exceptGroups" data-testid="exceptSegment.exceptGroup.all">Без ограничений</span>

            </campaign-row-view>

            <campaign-row-view
              name="Шаблон механики"
            >

             <button
                class="btn btn-sm  btn-addon text-ellipsis inline campaign-row-button"
                [ngClass]="{' btn-danger': isMechanicNotConfigured, 'btn-success': !isMechanicNotConfigured}"

                [triggers]="'mouseenter:mouseleave'"
                [ngbPopover]="'Необходимо донастроить механику'"
                [disablePopover]="!isMechanicNotConfigured"

                [uiSref]="'frontend.campaign.mechanics.config({mechanicId:' + campaignItem?.campaign?.mechanicId + '})'"
                data-testid="mechanic.name"
             >
                 <i class="fa "
                    [ngClass]="{'fa-exclamation-triangle':isMechanicNotConfigured, 'fa-paperclip':!isMechanicNotConfigured}"
                 ></i>
                 <span class="btn-name">{{campaignItem?.mechanic?.name }}</span>
             </button>


            </campaign-row-view>

        </div>
      </div>
    </div>

    <div class="col col-campaign-column">
      <div class="card">
        <div class="card-body p-t-none p-b-none">
            <campaign-row-view
              name="Период действия"
            >
              <span data-testid="campaignTime.period">
                 с {{ campaignItem?.campaign?.campaignTime?.startDate | loyaDate }} по {{ campaignItem?.campaign?.campaignTime?.stopDate | loyaDate }}
              </span>
            </campaign-row-view>

            <campaign-row-view
              name="Рассылка за"
            >
              <span data-testid="campaignTime.informationDate">
                {{ campaignItem?.campaign?.campaignTime?.informationDate || 0}} дней
              </span>
            </campaign-row-view>

            <campaign-row-view
              name="Время действия"
            >
              <div *ngFor="let period of campaignItem?.campaign?.campaignTime?.periods"
                   data-testid="period in campaignTime.periods"

              >

                <div class="clearfix text-right">

                   <span data-testid="campaignTime.time.period">
                    с {{ period.startTime | loyaTime }} по {{ period.stopTime  | loyaTime }}
                  </span>

                  <div class="f-s-9 text-nowrap">
                    <i class="fa fa-circle  m-r-xs"
                       *ngFor="let day of daysOfWeekDict"
                       data-testid="day in daysOfWeek"
                       [ngClass]="{'text-muted': period.daysOfWeek.indexOf(day.key)===-1 , 'text-info': period.daysOfWeek.indexOf(day.key)>=0 }"
                       [ngbPopover]="day.key"
                       [triggers]="'mouseenter:mouseleave'"
                       [container]="'body'"
                    >
                    </i>
                  </div>

                </div>

                <div class="clearfix m-b-xs m-t-none"
                     *ngIf="period.daysOfMonth && period.daysOfMonth.length"
                >
                  <div class="clearfix text-muted">День месяца</div>

                  <span *ngFor="let monthDayItem of daysOfMonthDics"
                        data-testid="monthDayItem in daysOfMonth">

                      <span class="text-nowrap f-s-9">
                        <i class="fa fa-circle text-success m-r-xs"
                           [ngClass]="{'text-muted': period.daysOfMonth.indexOf(monthDayItem.key)===-1 }"
                           [ngbPopover]="monthDayItem.name"
                           triggers="mouseenter:mouseleave"
                           [container]="'body'"
                        >
                        </i>
                      </span>

                      <br *ngIf="monthDayItem.key % 7 === 0" />

                   </span>

                </div>

              </div>

            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.campaign?.limitationId"
              name="Ограничения вознаграждений"
            >
                <span  data-testid="campaign.limitation">
                     {{ campaignItem?.limitation?.name }}
                </span>

            </campaign-row-view>

            <campaign-row-view
              *ngIf="!!campaignItem?.campaign?.informingId"
              name="Шаблон информирования"
            >

              <button
                class="btn btn-sm  btn-addon text-ellipsis inline campaign-row-button"

                [ngClass]="{' btn-danger': isInformingNotConfigured, 'btn-success': !isInformingNotConfigured}"

                [triggers]="'mouseenter:mouseleave'"
                [ngbPopover]="'Необходимо донастроить шаблон информирования'"
                [disablePopover]="!isInformingNotConfigured"

                [uiSref]="'frontend.campaign.informing.config({informingId:' + campaignItem?.campaign?.informingId + ', campaignId:' + campaignItem?.campaign?.id +' })'"
                data-testid="campaign.informingTemplate"
              >
                <i class="fa "
                   [ngClass]="{'fa-exclamation-triangle':isInformingNotConfigured, 'fa-paperclip':!isInformingNotConfigured}"
                ></i>
                <span class="btn-name">{{campaignItem?.informing?.name }}</span>
              </button>


            </campaign-row-view>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="card  m-l m-r m-t">
  <div class="card-header"  id="anchorI2">
    Информирование
  </div>
  <div class="card-body">

    <grid-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'email' }">

        <div class="grid-item"
             [grid-item-form] = "'Всего контактов E-Mail'"
             grid-item-form-icon = "fa-envelope-o"
        >
          <span data-testid="campaign.statistics.totalEmail">
            {{ campaignItem?.statistics?.totalEmail || 0 | loyaNumber }}
          </span>

        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'sms' }">
        <div class="grid-item"
             [grid-item-form] = "'Всего контактов SMS'"
             grid-item-form-icon = "fa-mobile"
        >
          <span data-testid="campaign.statistics.totalSMS">
            {{ campaignItem?.statistics?.totalSMS || 0 | loyaNumber }}
          </span>

        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }">
        <div class="grid-item"
             [grid-item-form] = "'Всего контактов Viber'"
             grid-item-form-icon = "fa-viber"
        >
            <span data-testid="campaign.statistics.totalViber">
              {{ campaignItem?.statistics?.totalViber || 0 | loyaNumber }}
            </span>

        </div>
      </ng-container>

      <div class="grid-item  grid-item_clean"></div>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'email' }">

        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов E-Mail'"
             grid-item-form-icon = "fa-envelope-o"
        >
          <span data-testid="campaign.statistics.emailPrefer">
            {{ campaignItem?.statistics?.preferEmail || 0 | loyaNumber }}
          </span>

        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'sms' }">
        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов SMS'"
             grid-item-form-icon = "fa-mobile"
        >
          <span data-testid="campaign.statistics.smsPrefer">
            {{ campaignItem?.statistics?.preferSMS  || 0 | loyaNumber }}
          </span>

        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }">
        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов Viber'"
             grid-item-form-icon = "fa-viber"
        >
            <span data-testid="campaign.statistics.viberPrefer">
              {{ campaignItem?.statistics?.preferViber || 0 | loyaNumber }}
            </span>

        </div>
      </ng-container>

      <div class="grid-item  grid-item_clean"></div>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['email','sms'] }">
        <div class="grid-item grid-item__form form-item">
          <div class="grid-item__label form-label">
            Всего контактов E-Mail, SMS
            <ng-container *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['viber'] }">
              и Viber
            </ng-container>
            <i class="fa fa-comments-o"></i>
          </div>
          <div class="grid-item__value">
            <div data-testid="campaign.statistics.totalEmailSMS">
              {{ campaignItem?.statistics?.totalEmailSMS  || 0 | loyaNumber }}
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['email','sms'] }">
        <div class="grid-item"
             [grid-item-form] = "'Всего контактов без предпочтений'"
             grid-item-form-icon = "fa-comments-o"
        >
            <span data-testid="campaign.statistics.withoutPrefer">
              {{ campaignItem?.statistics?.withoutPrefer || 0 | loyaNumber }}
            </span>

        </div>
      </ng-container>

    </grid-container>

    <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-xs"></div>

    <grid-container>

      <ng-container>

        <div class="grid-item"
             [grid-item-form] = "'Настройка типа канала коммуникации'"
             grid-item-form-icon = "fa-envelope-o"
        >
          <span data-testid="campaign.prefChannel">

            <span class="badge bg-success clearfix m-r-xs"
                  *ngIf="!!campaignItem?.campaign?.communication?.usePrefer"
            >По предпочтению</span>

            <span class="badge bg-success clearfix m-r-xs"
                  *ngIf="!!campaignItem?.campaign?.communication?.useEmail"
            >E-mail</span>

            <span class="badge bg-success clearfix m-r-xs"
                  *ngIf="!!campaignItem?.campaign?.communication?.useSms"
            >SMS</span>

            <span class="badge bg-success clearfix m-r-xs"
                  *ngIf="!!campaignItem?.campaign?.communication?.useViber"
            >Viber</span>

            <span class="badge bg-success clearfix m-r-xs"
                  *ngIf="isCommunicationNotUse"
            >Не рассылать</span>

          </span>
        </div>
      </ng-container>

      <ng-container
        *ngIf="!isCommunicationNotUse || isUseCheck"
      >
        <div class="grid-item"
             [grid-item-form] = "'Шаблон сообщений'"
             grid-item-form-icon = "fa-files-o"
        >
          <a class="btn btn-xs btn-success"
             href="javascript:void(0)"
             [uiSref]="'frontend.campaign.campaigns.templates.view({id:' + campaignItem?.campaign?.templateId + '})'"
             data-testid="campaign.messageTemplate"
          >
            <i class="fa fa-list-alt m-r-xs "></i>
            {{ campaignItem.msgTemplateName || 'Темплейт с ID:' + campaignItem?.campaign?.templateId}}
          </a>

        </div>
      </ng-container>

      <div class="grid-item  grid-item_clean"></div>

      <ng-container>

        <div class="grid-item"
             [grid-item-form] = "'Печать в чек'"
             grid-item-form-icon = "fa-file-text-o"
        >
          <span data-testid="campaign.communication.useCheck"
                [innerText]="!!campaignItem?.campaign?.communication?.useCheck ? 'Да' : 'Нет'"
          ></span>
        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'campaign.settings.informingOnApply', permitEnabled: ''}"
      >
        <div class="grid-item"
             [grid-item-form] = "'Печать в чек при срабатывании кампании'"
             grid-item-form-icon = "fa-file-text-o"
        >
         <span data-testid="campaign.communication.useCheckIfApplied"
               [innerText]="!!campaignItem?.campaign?.communication?.useCheckIfApplied ? 'Да' : 'Нет'"
         ></span>
        </div>
      </ng-container>

      <ng-container
        *ngIf="!!campaignItem?.campaign?.communication?.useCheck && !campaignItem?.campaign?.communication?.useCheckIfApplied && !!campaignItem?.campaign?.communication?.useCheckLimit"
      >

        <div class="grid-item"
             [grid-item-form] = "'Количество сообщений в день'"
             grid-item-form-icon = "fa-file-text-o"
        >
          <span data-testid="campaign.communication.useCheck"
                [innerText]="campaignItem?.campaign?.communication?.useCheckLimit || 0"
          ></span> шт
        </div>
      </ng-container>

      <div class="grid-item  grid-item_clean"></div>

      <ng-container>

        <div class="grid-item"
             [grid-item-form] = "'Сообщение кассиру'"
             grid-item-form-icon = "fa-commenting-o"
        >
          <span data-testid="campaign.communication.usePos"
                [innerText]="!!campaignItem?.campaign?.communication?.usePos ? 'Да' : 'Нет'"
          ></span>
        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'campaign.settings.informingOnApply', permitEnabled: ''}"
      >
        <div class="grid-item"
             [grid-item-form] = "'Сообщение кассиру при срабатывании кампании'"
             grid-item-form-icon = "fa-file-text-o"
        >
         <span data-testid="campaign.communication.usePosIfApplied"
               [innerText]="!!campaignItem?.campaign?.communication?.usePosIfApplied ? 'Да' : 'Нет'"
         ></span>
        </div>
      </ng-container>


    </grid-container>

  </div>
</div>


<div class="card  m-l m-r m-t "
     *loyaPermitIf="{path:'campaign.settings.planValues', permitEnabled: ''}"
>
  <div class="card-header"  id="anchorI3">
    Фактические показатели
  </div>
  <div class="card-body p-b-none">

    <div class="row">

      <div class="col-md-6 col-sm-12 col-lg-4 text-center"
           *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '' }"
      >

        <h5>Начислено бонусов</h5>

        <campaign-circle-graph
          [color]="LOYA_CONFIG.color.success"
          [width]="160"
          [percent]="getPercentForType('bonus')"
          [(hideOnZeroFlag)]="settingsObj.hideZeroResourceRow"
        >

          <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.total.bonus.real"
               [class.text-muted]="!getResourceType('bonus').realValue"
               [innerText]="getResourceType('bonus').realValue | loyaNumber"
          ></div>

          <div class="text-xs m-b text-muted"
               *ngIf="getResourceType('bonus').planValue !==0 " data-testid="realValuesCampaign.total.bonus.plan">
            План: {{ getResourceType('bonus').planValue | loyaNumber }}
          </div>
        </campaign-circle-graph>

      </div>

      <div class="col-md-6 col-sm-12 col-lg-4 text-center">

        <h5>Предоставлено скидок</h5>

        <campaign-circle-graph
          [color]="LOYA_CONFIG.color.info"
          [width]="160"
          [percent]="getPercentForType('discount')"
          [(hideOnZeroFlag)]="settingsObj.hideZeroResourceRow"
        >

          <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.total.discount.real"
               [class.text-muted]="!getResourceType('discount').realValue"
               [innerText]="getResourceType('discount').realValue | loyaCurrency"
          ></div>

          <div class="text-xs m-b text-muted"
               *ngIf="getResourceType('discount').planValue !==0 " data-testid="realValuesCampaign.total.discount.plan">
            План: {{ getResourceType('discount').planValue | loyaCurrency }}
          </div>
        </campaign-circle-graph>

      </div>


      <div class="col-md-6 col-sm-12 col-lg-4 text-center">

        <h5>Предоставлено подарков</h5>

        <campaign-circle-graph
          [color]="LOYA_CONFIG.color.danger"
          [width]="160"
          [percent]="getPercentForType('gift')"
          [(hideOnZeroFlag)]="settingsObj.hideZeroResourceRow"
        >

          <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.total.gift.real"
               [class.text-muted]="!getResourceType('gift').realValue"
               [innerText]="getResourceType('gift').realValue | loyaCurrency"
          ></div>

          <div class="text-xs m-b text-muted"
               *ngIf="getResourceType('gift').planValue !==0 " data-testid="realValuesCampaign.total.gift.plan">
            План: {{ getResourceType('gift').planValue | loyaCurrency }}
          </div>
        </campaign-circle-graph>

      </div>


    </div>

    <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-sm"></div>

    <div class="row">

      <div class="col-sm-12 col-md-6 text-center">

        <h5>Расходы на коммуникации</h5>

        <div class="row">
          <div class="col">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="communicationsConfig"
              [(update)]="updateFlag"
              class="easy-chart-block"
            ></highcharts-chart>
          </div>
          <div class="col">
            <div class="row m-t-sm m-b">
              <div class="col">
                <i class="fa fa-circle text-info"></i>
                E-mail
              </div>
              <div class="col-auto">
                <span data-testid="realValuesCampaign.communicationsCost.email.cost">
                  {{  getCommunicationType('email').cost | loyaCurrency }}
                </span>
              </div>
            </div>

            <div class="row  m-b">
              <div class="col">
                <i class="fa fa-circle text-warning"></i>
                SMS
              </div>
              <div class="col-auto">
                <span data-testid="realValuesCampaign.communicationsCost.sms.cost">
                  {{  getCommunicationType('sms').cost | loyaCurrency }}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col p-l-sm">
                Всего
              </div>
              <div class="col-auto text-right">
                <span data-testid="realValuesCampaign.communicationsCost.email.sms">
                  {{  ( getCommunicationType('sms').cost + getCommunicationType('email').cost )| loyaCurrency }}
                </span>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col text-center">
            <h5>POS-откликов</h5>
            <span class="clearfix">
              <i class="fa fa-4x text-info fa-external-link-square"></i>
            </span>
            <h4 data-testid="realValuesCampaign.receiptCount">
              {{ ( campaignItem?.realValues?.receiptCount || 0 ) | loyaNumber }}
            </h4>
          </div>

          <div class="col text-center">
            <h5>Реальное количество участников</h5>
            <span class="clearfix">
              <i class="fa fa-4x text-primary fa-users"></i>
            </span>
            <h4 data-testid="realValuesCampaign.clientCount">
              {{ ( campaignItem?.realValues?.clientCount || 0 ) | loyaNumber }}
            </h4>
          </div>

        </div>
      </div>

    </div>


    <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-sm" *ngIf="hasResourceRealCountGift"></div>

    <div class="row" *ngIf="hasResourceRealCountGift">
      <div class="col-sm-12 text-center m-b-xs">
        <span class="h5 m-t-lg m-b-lg">Предоставленные подарки</span>
      </div>

      <div class="col-sm-12 panel-inner-table p-l-none p-r-none">
        <table
          class="table table-l m-b-none b-b"
        >
          <thead>
            <tr>
              <th>наименование подарка</th>
              <th>SKU</th>
              <th>цена</th>
              <th>количество план</th>
              <th>количество факт</th>
              <th>стоимость</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let gift of resourceDetails"
            >

              <tr *ngIf="!!gift?.realCount" data-testid="gift in planeGifts">
                <td data-testid="gift.name"> {{ gift.name }}</td>
                <td data-testid="gift.sku"> {{ gift.sku }}</td>
                <td data-testid="gift.price"> {{ gift.price }}</td>
                <td data-testid="gift.planCount"> {{ gift.planCount }}</td>
                <td data-testid="gift.realCount"> {{ gift.realCount }}</td>
                <td data-testid="gift.total"> {{ gift.total }}</td>
              </tr>

            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-sm" ></div>

    <div class="row">

      <div class="col-md-6 col-sm-12 col-lg-6 text-center">

        <h5>Средняя сумма продаж одного клиента</h5>

        <campaign-circle-graph
          [color]="LOYA_CONFIG.color.primary"
          [width]="160"
          [percent]="calcPercent( totalOneClientReal, campaignItem?.planValues?.clientSelling)"
          [(hideOnZeroFlag)]="settingsObj.hideZeroClientSellingRow"
        >

          <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.avg.totalOneClient.real"
               [class.text-muted]="!totalOneClientReal"
               [innerText]="totalOneClientReal | loyaCurrency"
          ></div>

          <div class="text-xs m-b text-muted"
               *ngIf="!!campaignItem?.planValues?.clientSelling" data-testid="realValuesCampaign.clientSelling">
            из {{ campaignItem?.planValues?.clientSelling | loyaCurrency }}
          </div>
        </campaign-circle-graph>

      </div>

      <div class="col-md-6 col-sm-12 col-lg-6 text-center">

        <h5>Средние расходы на одного клиента</h5>

        <campaign-circle-graph
          [color]="LOYA_CONFIG.color.primary"
          [width]="160"
          [percent]="calcPercent( sellingOneClientReal, campaignItem?.planValues?.totalByClient)"
          [(hideOnZeroFlag)]="settingsObj.hideZeroClientSellingRow"
        >

          <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.avg.sellingOneClient.real"
               [innerText]="sellingOneClientReal | loyaCurrency"
               [class.text-muted]="!sellingOneClientReal"
          ></div>

          <div class="text-xs m-b text-muted"
               *ngIf="!!campaignItem?.planValues?.totalByClient " data-testid="realValuesCampaign.totalByClient">
            из {{ campaignItem?.planValues?.totalByClient | loyaCurrency }}
          </div>
        </campaign-circle-graph>

      </div>


    </div>

    <div class="row bg b-t b-b ">
      <div class="col-sm-12 text-center p-t p-b">

        <span>Общая сумма продаж</span>

        <h1 class="text-info font-thin clearfix m-b-none"
            data-testid="realValuesCampaign.total.selling.real"
            [innerText]="campaignItem?.realValues?.receiptSelling | loyaCurrency"
        ></h1>

        <div *ngIf="!!campaignItem?.planValues?.clientSellingTotal"
             class="h4 text-info font-thin"
             data-testid="realValuesCampaign.total.selling.percent"

        >
          {{calcPercent(campaignItem?.realValues?.receiptSelling, campaignItem?.planValues?.clientSellingTotal) | loyaNumber}}%
        </div>

        <div *ngIf="!!campaignItem?.planValues?.clientSellingTotal"
             class="text-xs text-muted m-t "
             data-testid="realValuesCampaign.total.selling.plan">
          План: {{ campaignItem?.planValues?.clientSellingTotal | loyaCurrency}}
        </div>


      </div>
    </div>

    <div class="row justify-content-center">

      <ng-container
        *ngIf="!isEmptyCommunication"
      >
        <div class="col-sm-12 text-center m-t-sm">
          <h5>Отправлено сообщений</h5>
        </div>

        <div class="col-md-6 col-sm-12 col-lg-4 text-center">

          <h5>E-mail</h5>

          <campaign-circle-graph
            [color]="LOYA_CONFIG.color.primary"
            [width]="160"
            [percent]="getPercentForCommunication('email')"
          >

            <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.communications.email.delivered"
                 [innerText]="getCommunicationType('email').delivered | loyaNumber"
            ></div>

            <div class="text-xs m-b text-muted"
                 *ngIf="getCommunicationType('email').filled !==0 " data-testid="realValuesCampaign.communications.email.filled">
              из {{ getCommunicationType('email').filled | loyaNumber }}
            </div>
          </campaign-circle-graph>

        </div>

        <div class="col-md-6 col-sm-12 col-lg-4 text-center">

          <h5>SMS</h5>

          <campaign-circle-graph
            [color]="LOYA_CONFIG.color.success"
            [width]="160"
            [percent]="getPercentForCommunication('sms')"
          >

            <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.communications.sms.delivered"
                 [innerText]="getCommunicationType('sms').delivered | loyaNumber"
            ></div>

            <div class="text-xs m-b text-muted"
                 *ngIf="getCommunicationType('sms').filled !==0 " data-testid="realValuesCampaign.communications.sms.filled">
              из {{ getCommunicationType('sms').filled | loyaNumber }}
            </div>
          </campaign-circle-graph>

        </div>

        <div class="col-md-6 col-sm-12 col-lg-4 text-center">

          <h5>Чек</h5>

          <campaign-circle-graph
            [color]="LOYA_CONFIG.color.warning"
            [width]="160"
            [percent]="getPercentForCommunication('check')"
          >

            <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.communications.check.worked"
                 [innerText]="getCommunicationType('check').worked | loyaNumber"
            ></div>

            <div class="text-xs m-b text-muted"
                 *ngIf="getCommunicationType('check').filled !==0 " data-testid="realValuesCampaign.communications.check.filled">
              из {{ getCommunicationType('check').filled | loyaNumber }}
            </div>
          </campaign-circle-graph>

        </div>

        <div class="col-md-6 col-sm-12 col-lg-4 text-center"
             *loyaPermitIf="{path:'channel.type.viber', permitEnabled: ''}"
        >

          <h5>Viber</h5>

          <campaign-circle-graph
            [color]="LOYA_CONFIG.color.danger"
            [width]="160"
            [percent]="getPercentForCommunication('viber')"
          >

            <div class="m-t-sm m-b-xs h4" data-testid="realValuesCampaign.communications.viber.delivered"
                 [innerText]="getCommunicationType('viber').delivered | loyaNumber"
            ></div>

            <div class="text-xs m-b text-muted"
                 *ngIf="getCommunicationType('viber').filled !==0 "
                 data-testid="realValuesCampaign.communications.viber.filled">
              из {{ getCommunicationType('viber').filled | loyaNumber }}

              <span class="clearfix"
                    *ngIf="getCommunicationType('viber').doneByAlternative" data-testid="realValuesCampaign.communications.viber.doneByAlternative">
                    включая SMS {{ getCommunicationType('viber').doneByAlternative  | loyaNumber  }}
              </span>

            </div>
          </campaign-circle-graph>

        </div>
      </ng-container>

      <div class="text-center"
           *ngIf="isEmptyCommunication"
      >
        <h5 class="text-muted m-t m-b">Нет отправленных сообщений</h5>
      </div>

    </div>
  </div>
</div>

<div class="card  m-l m-r m-t "
     *ngIf="preparedFields.has('dibsCampaign')"
>
  <div class="card-header"
       id="anchorI4"
       data-testid="campaign.dibs.info"
  >
    Информация о фишках
  </div>
  <div class="card-body">

    <grid-container>

        <div class="grid-item"
             [grid-item-form] = "'Выдано'"
             grid-item-form-icon = "fa-tag"
        >
          <span  data-testid="campaign.dibs.avaliable">
            {{ preparedFields.get('dibsCampaign').avaliableDibs }}
          </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Потрачено'"
             grid-item-form-icon = "fa-tag"
        >
            <span  data-testid="campaign.dibs.spent">
              {{ preparedFields.get('dibsCampaign').spentDibs }}
            </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Возвращено'"
             grid-item-form-icon = "fa-tag"
        >
            <span  data-testid="campaign.dibs.returned">
              {{ preparedFields.get('dibsCampaign').returnedDibs }}
            </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Всего'"
             grid-item-form-icon = "fa-tag"
        >
              <span  data-testid="campaign.dibs.total">
                {{ preparedFields.get('dibsCampaign').totalDibs }}
              </span>
        </div>

    </grid-container>

  </div>
</div>

<div class="card  m-l m-r m-t "
     *loyaPermitIf="{path:'campaign.settings.realValues', permitEnabled: ''}"
>
  <div class="card-header"
       id="anchorI5"
  >
    Плановые показатели
  </div>
  <div class="card-body">


    <grid-container>

      <div class="grid-item"
           [grid-item-form] = "'Целевая группа'"
           grid-item-form-icon = "fa-dot-circle-o"
      >
          <span  data-testid="planValues.clientsCount">
            {{ campaignItem?.planValues?.clientsCount }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Процент отклика'"
           grid-item-form-icon = "fa-undo"
      >
          <span  data-testid="planValues.responsePercent">
            {{ campaignItem?.planValues?.responsePercent }} %
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Планируемый отклик'"
           grid-item-form-icon = "fa-undo"
      >
          <span  data-testid="planValues.responseCount">
            {{ campaignItem?.planValues?.responseCount }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Планируемое кол-во срабатываний'"
           grid-item-form-icon = "fa-rss"
      >
          <span  data-testid="planValues.operationCount">
            {{ campaignItem?.planValues?.operationCount }}
          </span>
      </div>

      <div class="grid-item grid-item_clean_hr clean"></div>

      <div class="grid-item"
           *ngIf="campaignItem?.campaign?.communication?.useEmail || campaignItem?.campaign?.communication?.usePrefer"
           [grid-item-form] = "'Расходы на E-mail сообщения'"
           grid-item-form-icon = "fa-envelope-o"
      >
          <span  data-testid="planValues.emailTotal">
            {{ campaignItem?.planValues?.emailTotal | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           *ngIf="campaignItem?.campaign?.communication?.useSms || campaignItem?.campaign?.communication?.usePrefer"
           [grid-item-form] = "'Расходы на SMS сообщения'"
           grid-item-form-icon = "fa-mobile"
      >
          <span  data-testid="planValues.emailTotal">
            {{ campaignItem?.planValues?.smsTotal | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           *ngIf="!!getCampaignResource('discount')"
           [grid-item-form] = "'Сумма скидок'"
           grid-item-form-icon = "fa-tag"
      >
          <span  data-testid="planValues.discountTotal">
            {{ campaignItem?.planValues?.discountTotal | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           *ngIf="getCampaignResource('bonus')"
           [grid-item-form] = "'Сумма начисляемых бонусов'"
           grid-item-form-icon = "fa-star"
      >
          <span  data-testid="planValues.bonusTotal">
            {{ campaignItem?.planValues?.bonusTotal | loyaNumber }}
          </span>
      </div>

      <div class="grid-item"
           *ngIf="getCampaignResource('gift')"
           [grid-item-form] = "'Сумма расходов на подарки'"
           grid-item-form-icon = "fa-money"
      >
          <span  data-testid="planValues.giftTotal">
            {{ campaignItem?.planValues?.giftTotal | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Прочие расходы'"
           grid-item-form-icon = "fa-money"
      >
          <span  data-testid="planValues.additionalTotal">
            {{ campaignItem?.planValues?.additionalTotal | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Всего расходов'"
           grid-item-form-icon = "fa-money"
      >
          <span  data-testid="planValues.totalCost">
            {{ campaignItem?.planValues?.totalCost | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Расходы на одного клиента'"
           grid-item-form-icon = "fa-user"
      >
          <span  data-testid="planValues.totalByClient">
            {{ campaignItem?.planValues?.totalByClient | loyaCurrency }}
          </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'План по росту общей суммы продаж'"
           grid-item-form-icon = "fa-bar-chart"
      >
          <span  data-testid="planValues.clientSellingTotal">
            {{ campaignItem?.planValues?.clientSellingTotal | loyaCurrency }}
          </span>
      </div>


    </grid-container>


    <div class="row m-t-sm"
         *ngIf="hasResourceGift"
    >
      <div class="col-sm-12 panel-inner-table p-l-none p-r-none">
        <table
          class="table table-l m-b-none"
        >

          <thead>
            <tr>
              <th>наименование подарка</th>
              <th>SKU</th>
              <th>цена одной единицы</th>
              <th>количество</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let gift of resourceDetails" data-testid="gift in planeGifts">
              <td data-testid="gift.name">
                {{ gift.name }}
              </td>
              <td data-testid="gift.sku">
                {{ gift.sku }}
              </td>
              <td data-testid="gift.price">
                {{ gift.price | loyaCurrency }}
              </td>
              <td data-testid="gift.planCount">
                {{ gift.planCount | loyaNumber }}
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>

<div class="card  m-l m-r m-t "
     *ngIf="isExternalLoyalty"
>
  <div class="card-header"
       id="anchorI6"
  >
    Внешняя система лояльности
  </div>
  <div class="card-body">

    <grid-container>

      <campaign-external-code
        class="grid-item grid-item__form form-item"
        [campaignItem]="campaignItem"
        [isEdit]="false"
      ></campaign-external-code>

      <ng-container
        *ngIf="externalLoyaltyFlag"
      >
        <div class="grid-item"
             [grid-item-form] = "'Запретить списание бонусов на товары по этой акции во внешней системе'"
             grid-item-form-icon = "fa-gears"
        >
          <span data-testid="externalLoyalty.disableBonusUsed"
                [innerText]="!!campaignItem?.elSettings?.disableBonusUsed ? 'Да' : 'Нет'"
          >
          </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Запретить начисление бонусных баллов на товары по этой акции во внешней системе'"
             grid-item-form-icon = "fa-gears"
        >
          <span data-testid="externalLoyalty.disableBonusAward"
                [innerText]="!!campaignItem?.elSettings?.disableBonusAward ? 'Да' : 'Нет'"
          >
          </span>
        </div>
      </ng-container>

    </grid-container>
  </div>
</div>
