import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";


import "./system-modules-index-page.legacy";
import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {SettingsService} from "../../../../shared/service/api/Settings/Settings.service";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {NewsletterService} from "../../../../shared/service/api/Newsletter/Newsletter.service";
import {FormControl} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {getNg1Service} from "../../../../shared/class/utils/angularjs.utils";
import {PluginInstallService} from "../../../../shared/service/api/PluginInstall/PluginInstall.service";

let savedFilter  =  'all';

@Component({
  selector: 'system-modules-index-page',
  templateUrl: './system-modules-index-page.component.html',
  styleUrls: ['./system-modules-index-page.component.scss'],
  providers:[
    PluginInstallService
  ]
})
export class SystemModulesIndexPageComponent implements OnInit{

  public filterTypes = {
    'all' : 'Все модули',
    'configureModule' : 'Конфигурируемые модули'
  }
  public filterType = savedFilter;

  public loader = new LoaderHandlerClass();

  public modulesList: any[] = [];
  public preparedModulesList: any[] = [];

  public openModuleId  =  '';

  public modulesStorageService = getNg1Service('modulesStorage');
  public templateCacheService = getNg1Service('$templateCache');

  constructor(
    private pluginInstallService: PluginInstallService
  ) {
  }

  ngOnInit() {
    this.initModulesList();
  }


  initModulesList()  {
    this.pluginInstallService
      .all$()
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe((response: any) => {

        this.modulesList = response.filter( item  => !['System', 'Common'].includes(item.name));
        this.modulesList = this.modulesList.concat( [ {
          description : "Служебный компонент",
          name        : "System",
          packageType : "component",
          version     : "1.0"
        },{
          description : "Экспресс компонент",
          name        : "Common",
          packageType : "component",
          version     : "1.0"
        }]);
        this.prepareModulesList();
      })
  }

  prepareModulesList()  {
    this.modulesList?.
        filter( item  =>  {
          const loyaPermissions = getNg1Service('loyaPermissions');

          if ( item?.name === 'Preference'  && !loyaPermissions.byPath('modules.client.preferences').enabled ) {
            return  false;
          }

          if (item?.name === 'Promotion'  && !loyaPermissions.byPath('modules.client.promotion').enabled ) {
            return  false;
          }

          if (item?.name === 'AccumulationProfile'  && !loyaPermissions.byPath('modules.client.accumulation').enabled ) {
            return  false;
          }

          return  true;
        })?.
        forEach( item => {

          const paramItem = this.modulesStorageService.get(item.name, 'system.modules.modules.manifest' );
          if (!paramItem)
            return;

          let indexView =  this.modulesStorageService.get(item.name, 'system.modules.modules.index' ) || '';
          if (indexView)
            indexView = this.templateCacheService?.get(indexView) || '';

          if (!this.preparedModulesList.some( i => i.name  ===  item.name))
            this.preparedModulesList.push({
              name: item.name,
              description: item.description,
              type: paramItem.type,
              version: item.version || paramItem.version,
              config: !!paramItem.config,
              packageType: item.packageType,
              staticConfigPage: paramItem.config === true,
              tags: paramItem.tags,
              logo: paramItem.logo,
              indexView: indexView,
            });

        })

    this.preparedModulesList?.
      sort((a, b)  =>
        a?.packageType?.localeCompare(b?.packageType)
      );
  }

  filterModules = ( item: any ) => {
    return  this.filterType ===  'configureModule' ?  !!item.config  :  true;
  }

  saveFilter() {
    savedFilter = this.filterType;
  }

}
