
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {GoodgroupService} from "../../../../service/api/Goodgroup/Goodgroup.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../../service/api/CategoryModel/CategoryModel.service";
import {VIEW_TYPES} from "../../goods-tree.variables";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";


@Component({
  selector: 'selected-view',
  templateUrl: "./selected-view.component.html",
  styleUrls: ['./selected-view.component.scss' ],
  providers: [
    GoodModelService,
    CategoryModelService,
  ]
})
 export class SelectedViewComponent implements OnInit, OnChanges {

    @Input() partnerId;

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() showType;

    @Input() goodList: any;
    @Input() searchQuery: string;
    @Input() selectOne = false;

    @Input() editCallback;

    get isEditMode() {
      return typeof this.editCallback === "function"
    };

  private cache = new Map<number, any>()

    constructor(
      private goodTreeGetterService:GoodTreeGetterService,
    )
    {
    }

    get isSku() {
      return this.showType === VIEW_TYPES.SKU;
    }

    get isCategory() {
      return this.showType === VIEW_TYPES.CATEGORY;
    }

    get isSearchState() {
      return !!this.searchQuery;
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    getId(id) {

      if (this.isLoading(id))
        return id;

      switch (this.showType) {
        case VIEW_TYPES.SKU:
          return this.goodTreeGetterService.getGood(id, this.partnerId)?.id;

        case VIEW_TYPES.CATEGORY:
          return this.goodTreeGetterService.getCategory(id, this.partnerId)?.id;

      }

    }

    getName(id) {

      if (this.isLoading(id))
        return {};

      switch (this.showType) {
        case VIEW_TYPES.SKU:
          return this.goodTreeGetterService.getGood(id, this.partnerId)?.label;

        case VIEW_TYPES.CATEGORY:
          return this.goodTreeGetterService.getCategory(id, this.partnerId)?.label;

      }

    }

    getList() {

      if (this.isSearchState) {
          return this.goodList;
      }

      return Array.from( this.selected.values() ).reverse();

    }

    isLoading(id) {

      switch (this.showType) {
        case VIEW_TYPES.SKU:
          return this.goodTreeGetterService.getGood(id, this.partnerId) === true;

        case VIEW_TYPES.CATEGORY:
          return this.goodTreeGetterService.getCategory(id, this.partnerId) === true;

      }

      return  false;

    }

    removeSelected(id) {

        if ( !this.selected.has(id) )
          return;

      this.selected.delete(id);
      this.selectedChange.emit(this.selected);

    }

    toggleSelected(id, checked?) {

      if (!!checked) {
        this.selected.add(id);
      } else {
        this.selected.delete(id);
      }

      this.selectedChange.emit(this.selected);

    }

    get isSelectedAll() {
      return !this.goodList?.some( i => {

        if (typeof i?.sku !== "undefined")
          return !this.selected.has(i?.sku)

        if (typeof i?.id !== "undefined")
          return !this.selected.has(i?.id)

        return false
      } )
    }

    toggleSelectedAll(checked?) {

      if (!!checked) {
       this.goodList?.forEach( i => {
         if ( typeof i?.sku !== "undefined") {
           this.selected.add(i.sku)
           return
         }

         if ( typeof i?.id !== "undefined") {
           this.selected.add(i.id)
           return
         }

       })
      } else {

        this.goodList?.forEach( i => {

          if ( typeof i?.sku !== "undefined") {
            this.selected.delete(i.sku)
            return
          }

          if ( typeof i?.id !== "undefined") {
            this.selected.delete(i.id)
            return
          }

        })

       // this.selected.clear();
      }

    }

    editClick(id) {

        if (!this.editCallback)
          return;

        this.editCallback({
          type: VIEW_TYPES.SKU,
          id: typeof id == "object" ? id?.id : id
        })

    }

}
