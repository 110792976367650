<div class="modal-header  bg-light align-items-center"
     *ngIf="!isEditMode"
>

    <i class="fa fa-list text-success m-r-sm f-s-12"> </i>


    <div class=" relative">
      <div class=" overflow-wrap-break">
        <span class="h4"> Выбор цепочек </span>

      </div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="">

      <button type="button" class="btn btn-sm btn-default m-r-xs btn-addon" data-testid="trigger.select()"
              (click)="select()"
      >
        <i class=" fa fa-check"></i>
        <span class="btn-name"
        >Выбрать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default  " data-testid="trigger.cancel()"
              (click)="cancel()"
      >
        <!-- TODO подтверждение закрытия -->
        <i class=" fa fa-remove text-danger"></i>
      </button>

    </div>


</div>
<div class="fixed-tool-panel ">

  <div class="dropdown pull-left " ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs collapse-sm" ngbDropdownToggle>
      <i>
          <span class="fa fa-filter"></span>
      </i>
      <span class="btn-name">
          Статусы
          <span class="caret "></span>
      </span>
    </button>

    <ul class="dropdown-menu scrollable"
        ngbDropdownMenu
        style="z-index: 9999; " (click)="$event.stopPropagation()">

      <li class="wrapper-xs padder" (click)="$event.stopPropagation()">
        <label class="checkbox-inline i-checks" (click)="$event.stopPropagation()">
          <input type="checkbox"
                 [checked]="selectedStates?.length === TRIGGER_STATE_NAMES?.length"
                 (change)="changeSelectedStatesAll(!!$event?.currentTarget?.checked) "
          ><i></i>Выбрать
          все
        </label>
      </li>

      <li class="b-b m-t-xs m-b-xs"></li>

      <li class="wrapper-xs padder text-ellipsis"
          *ngFor="let item of TRIGGER_STATE_NAMES  "
      >
        <label class="checkbox-inline i-checks" (click)="$event.stopPropagation()">
          <input type="checkbox"
                 [checked]="selectedStates.includes(item.key)"
                 (change)="changeSelectedStates(item.key, !!$event?.currentTarget?.checked)"
                 ><i></i>
          {{ item.value }}
        </label>
      </li>

    </ul>

  </div>


</div>
<div class="modal-body campaigns-tree-body pb-0">

  <div class="campaigns-column">
    <div class="campaigns-column-header">

      <div class=" d-block padder mt-2 mb-2" >

        <ng-container
          *ngIf="!selectedAll">

          <span
            class="text-muted m-r-xs">Выбрано цепочек:</span> <b data-testid="trigger.selectedCampaigns.size">{{selectedCampaigns.size}}</b>

          <span
            *ngIf="!!max"
          >
            из
            <span data-testid="trigger.selectedCampaigns.max">{{max}}</span>
          </span>

        </ng-container>

        <span
          *ngIf="selectedAll"
          class="text-muted m-r-xs">Выбраны все цепочки</span>

      </div>

    </div>
    <div class="campaigns-column-body b-t">
      <trigger-view
        [(selected)]="selectedCampaigns"
        [selectOne]="selectOne"
        [canCheckAll]="canCheckAll"
        [(selectedAll)]="selectedAll"
        [states]="selectedStates"
        [max]="max"
      ></trigger-view>
    </div>
  </div>

</div>


<div class="campaign-block-loading"
     *ngIf="loader.isLoading('selectPrepare')"
>

  <i class="fa fa-spin fa-spinner text-warning fa-5x"></i>

</div>
