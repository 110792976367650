  <table  class="table m-b-none  b-b  "
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          [infiniteScrollContainer]="'locations-view'"
          [fromRoot]="true"
          (scrolled)="onScroll()"
          data-testid="locations-view"
  >
    <thead>
    <tr>
      <th class="indicator-hide">

        <div class="checkbox"
             *ngIf="canCheckAll"
        >
          <label class="i-checks mb-0">
            <input type="checkbox"
                   [checked]="isSelectedAll()"
                   (change)="changeSelectedAll(!!$event?.currentTarget?.checked)"
            >
            <i></i>
          </label>
        </div>

      </th>
      <th class="align-middle">
        <sort-view
          column="name"
          [params]="locationsService.getListParams"
          (onChange)="reset()"
        >
          ТОЧКА ПРОДАЖ
        </sort-view>
      </th>
      <th class="align-middle">
        <sort-view
          column="code"
          [params]="locationsService.getListParams"
          (onChange)="reset()"
        >
          КОД
        </sort-view>
      </th>
      <th class="align-middle">
        <sort-view
          column="address"
          [params]="locationsService.getListParams"
          (onChange)="reset()"
        >
          АДРЕС
        </sort-view>
      </th>
      <th class="align-middle">
        <sort-view
          column="description"
          [params]="locationsService.getListParams"
          (onChange)="reset()"
        >
            ОПИСАНИЕ
        </sort-view>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of locationsItems; let odd = odd"
        data-testid="item of locationsItems"
        [class.bg-light]=" odd "
        class="pointer"
        (click)="changeSelected(item.id, !$event?.currentTarget?.querySelector('input')?.checked);"
    >

      <td width="2%" align="center" >

        <div class="checkbox">
          <label class="i-checks">
            <input type="checkbox"
                   [checked]="isSelected(item.id)"
                   (change)="changeSelected(item.id, !!$event?.currentTarget?.checked)"
                   [disabled]="isDisabled(item.id)"
                   (click)="$event.stopPropagation();$event.stopImmediatePropagation(); $event.preventDefault()"
            >
            <i></i>
          </label>
        </div>

      </td>
      <td class=" table-big-link"  data-value="" data-testid="locationItem.name">
        <text-highlight [search]="searchQuery" [text]="item.name" ></text-highlight>
      </td>

      <td class="table-big-link" data-testid="locationItem.code">
        <text-highlight [search]="searchQuery" [text]="item.code" ></text-highlight>
      </td>
      <td class="table-big-link" data-testid="locationItem.address">
        <text-highlight [search]="searchQuery" [text]="item.address" ></text-highlight>
      </td>
      <td class="table-big-link" data-testid="locationItem.description">
        <text-highlight [search]="searchQuery" [text]="item.description" ></text-highlight>
      </td>
    </tr>

    </tbody>
  </table>

  <div class="w-full text-center padder mt-3"
    *ngIf="loader.isLoading('locationsItems')"
  >
    <div class="w-100 text-center">
      <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
    </div>

  </div>



