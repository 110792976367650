/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CountersApiConfiguration as __Configuration } from '../counters-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Counter } from '../models/counter';
import { CounterCurrentValue } from '../models/counter-current-value';
@Injectable({
  providedIn: 'root',
})
class CountersApiService extends __BaseService {
  static readonly getAllCountersPath = '/list';
  static readonly createCounterPath = '/list';
  static readonly getCounterByIdPath = '/list/{counterId}';
  static readonly deleteCounterByIdPath = '/list/{counterId}';
  static readonly getAllCounterValuesByClientPath = '/byClient/{clientId}';
  static readonly getCounterValueByClientPath = '/byClient/{clientId}/{counterId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка счётчиков
   *
   * Запрос списка счётчиков
   * @return Список счётчиков
   */
  getAllCountersResponse(): __Observable<__StrictHttpResponse<Array<Counter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Counter>>;
      })
    );
  }
  /**
   * Запрос списка счётчиков
   *
   * Запрос списка счётчиков
   * @return Список счётчиков
   */
  getAllCounters(): __Observable<Array<Counter>> {
    return this.getAllCountersResponse().pipe(
      __map(_r => _r.body as Array<Counter>)
    );
  }

  /**
   * Добавление нового счётчика
   *
   * Добавление нового счётчика
   * @param counter undefined
   * @return Счётчик создан
   */
  createCounterResponse(counter: Counter): __Observable<__StrictHttpResponse<Counter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = counter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Counter>;
      })
    );
  }
  /**
   * Добавление нового счётчика
   *
   * Добавление нового счётчика
   * @param counter undefined
   * @return Счётчик создан
   */
  createCounter(counter: Counter): __Observable<Counter> {
    return this.createCounterResponse(counter).pipe(
      __map(_r => _r.body as Counter)
    );
  }

  /**
   * Запрос параметров счётчика по ID
   *
   * Запрос параметров счётчика по ID
   * @param counterId ID счётчика
   * @return Параметры счётчика
   */
  getCounterByIdResponse(counterId: number): __Observable<__StrictHttpResponse<Counter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/list/${encodeURIComponent(String(counterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Counter>;
      })
    );
  }
  /**
   * Запрос параметров счётчика по ID
   *
   * Запрос параметров счётчика по ID
   * @param counterId ID счётчика
   * @return Параметры счётчика
   */
  getCounterById(counterId: number): __Observable<Counter> {
    return this.getCounterByIdResponse(counterId).pipe(
      __map(_r => _r.body as Counter)
    );
  }

  /**
   * Удаление счётчика
   *
   * Удаление счётчика
   * @param counterId ID счётчика
   */
  deleteCounterByIdResponse(counterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/list/${encodeURIComponent(String(counterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление счётчика
   *
   * Удаление счётчика
   * @param counterId ID счётчика
   */
  deleteCounterById(counterId: number): __Observable<null> {
    return this.deleteCounterByIdResponse(counterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос значений всех счётчиков по данному клиенту
   *
   * Запрос значений всех счётчиков по данному клиенту
   * @param clientId ID клиента
   * @return Список значений cчётчиков
   */
  getAllCounterValuesByClientResponse(clientId: number): __Observable<__StrictHttpResponse<Array<CounterCurrentValue>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/byClient/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CounterCurrentValue>>;
      })
    );
  }
  /**
   * Запрос значений всех счётчиков по данному клиенту
   *
   * Запрос значений всех счётчиков по данному клиенту
   * @param clientId ID клиента
   * @return Список значений cчётчиков
   */
  getAllCounterValuesByClient(clientId: number): __Observable<Array<CounterCurrentValue>> {
    return this.getAllCounterValuesByClientResponse(clientId).pipe(
      __map(_r => _r.body as Array<CounterCurrentValue>)
    );
  }

  /**
   * Запрос значения указанного счётчика по данному клиенту
   *
   * Запрос значения указанного счётчика по данному клиенту
   * @param params The `CountersApiService.GetCounterValueByClientParams` containing the following parameters:
   *
   * - `counterId`: ID счётчика
   *
   * - `clientId`: ID клиента
   *
   * @return Значение cчётчика
   */
  getCounterValueByClientResponse(params: CountersApiService.GetCounterValueByClientParams): __Observable<__StrictHttpResponse<CounterCurrentValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/byClient/${encodeURIComponent(String(params.clientId))}/${encodeURIComponent(String(params.counterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CounterCurrentValue>;
      })
    );
  }
  /**
   * Запрос значения указанного счётчика по данному клиенту
   *
   * Запрос значения указанного счётчика по данному клиенту
   * @param params The `CountersApiService.GetCounterValueByClientParams` containing the following parameters:
   *
   * - `counterId`: ID счётчика
   *
   * - `clientId`: ID клиента
   *
   * @return Значение cчётчика
   */
  getCounterValueByClient(params: CountersApiService.GetCounterValueByClientParams): __Observable<CounterCurrentValue> {
    return this.getCounterValueByClientResponse(params).pipe(
      __map(_r => _r.body as CounterCurrentValue)
    );
  }
}

module CountersApiService {

  /**
   * Parameters for getCounterValueByClient
   */
  export interface GetCounterValueByClientParams {

    /**
     * ID счётчика
     */
    counterId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { CountersApiService }
