import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {ClientCategory} from "../../../../../api/ClientCategoryApi/models";
import {ClientCategoryService} from "../../../../../shared/service/api/ClientCategory/ClientCategory.service";
import {ParamsHandler} from "../../../../../shared/service/api/ParamsHandler/Params.handler";
import {MechanicCategory} from "../../../../../api/MechanicCategoryApi/models/mechanic-category";

@Component({
  selector: 'clientcategory-index-page',
  templateUrl: './clientcategory-index-page.component.html',
  providers: [
    ClientCategoryService
  ]
})
export class ClientcategoryIndexPageComponent implements OnInit{

  listClientCategory: Array<ClientCategory> = [];
  searchString: string;

  constructor(
    private clientCategoryService:ClientCategoryService
  ) {
  }

  ngOnInit() {
    this.clientCategoryService.getListParams.reset();
    this.getList(this.clientCategoryService.getListParams.params);
  }

  getList(params) {
    this.clientCategoryService.getList(params)
      .subscribe( result => {
        this.listClientCategory = this.listClientCategory.concat(result);
      });
  }


  onScroll() {
    this.clientCategoryService.getListParams.next();
    this.getList(this.clientCategoryService.getListParams.params)
  }

  filterBySearch = (item: ClientCategory) => {
    if (!this.searchString || !item) return true;

    return  item.name && item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) >=0;

  }

}
