import {IssuingType, StopPeriodTypes, TriggerId} from "./IssuingType";
import {CouponWithBarcode} from "../couponKinds/CouponWithBarcode";




export class Trigger extends IssuingType {

    static id = TriggerId;
    static couponKindsArray  = [
        CouponWithBarcode
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE,
        StopPeriodTypes.STOP_PERIOD_SCHEDULED,
    ];

}
