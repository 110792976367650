import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CouponRuleApiService} from "../../../../api/CouponRuleApi/services";
import {CouponRuleQueryParam} from "../../../../api/CouponRuleApi/models/coupon-rule-query-param";
import CouponRuleFilters from "../../../../../js/models/CouponRuleModel/filters";
import {ParamsHandler} from "../ParamsHandler/Params.handler";


@Injectable()
export class CouponRuleService {

  constructor( private couponRuleApiService: CouponRuleApiService ) {
  }


  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'selectedId',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.datesKind',
        id: 'datesKind',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.locations',
        id: 'locations',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.skuList',
        id: 'skuList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.categoryList',
        id: 'categoryList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupsList',
        id: 'groupsList',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public query$(params?:CouponRuleQueryParam){

    params.filter = params.filter || {};
    params.filter.couponKind = params.filter.couponKind ||
      Object.values(CouponRuleFilters?.searchCouponRule?.filter?.couponKind?.values || {} )
    params.filter.issuingType = params.filter.issuingType ||
      Object.values(CouponRuleFilters?.searchCouponRule?.filter?.issuingType?.values || {} )

    return this.couponRuleApiService.searchCouponRule(params);
  }

  public get$(id: number){

  }


}
