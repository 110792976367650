import { Component, OnInit } from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'

@Component({
  selector: 'breadcrumbs-path',
  templateUrl: './breadcrumbs-path.component.html',
  styleUrls: ['./breadcrumbs-path.component.scss'],

})
export class BreadcrumbsPathComponent implements OnInit {

  public breadcrumbs: any[];
  private $transitions: any;
  private $state: any;
  private $injector: any;

  constructor() {
  }

  public ngOnInit() {

    this.$transitions = getNg1Service('$transitions');
    this.$state       = getNg1Service('$state');
    this.$injector    = getNg1Service('$injector');

    this.breadcrumbs = this.getBreadcrumbs( this.$state.current );

    this.$transitions.onSuccess({}, ( trans: any ) => {
      this.breadcrumbs = this.getBreadcrumbs( trans.to() );
    });

  }

  private getBreadcrumbs( curState: any ) {

    if ( curState.breadcrumbs !== undefined &&
      Array.isArray(curState.breadcrumbs) )
    {
      let resultArray: any = [];

      curState.breadcrumbs.forEach( ( item: any ) => {
        if ( typeof item !== 'string' ) {

          resultArray.push( this.$injector.invoke(item) );

        } else {
          resultArray.push(item);
        }
      });

      return resultArray;
    } else {
      return false;
    }
  };

}
