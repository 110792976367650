
export const OPERATION_STATES = {
  'credit':'начисление',
  'debit':'списание',
  'correct':'коррекция',
  'burning':'сжигание',
  'returnCreditInactive': 'возврат начисления',
  'returnCreditActive': 'возврат начисления',
  'returnDebit':'возврат списания',
  'transfer':'перевод отложенных начислений',
  'hold':'кредит',
  'payment':'гашение'
}
