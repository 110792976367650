/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { BrandApiConfiguration as __Configuration } from '../brand-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Brand } from '../models/brand';
@Injectable({
  providedIn: 'root',
})
class BrandApiService extends __BaseService {
  static readonly createPath = '/brand';
  static readonly queryBrandsPath = '/brand';
  static readonly getPath = '/brand/{id}';
  static readonly updatePath = '/brand/{id}';
  static readonly deletePath = '/brand/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание бренда
   *
   * Создание бренда
   * @param brand Описание бренда
   * @return Бренд создан
   */
  createResponse(brand: Brand): __Observable<__StrictHttpResponse<Brand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = brand;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/brand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Brand>;
      })
    );
  }
  /**
   * Создание бренда
   *
   * Создание бренда
   * @param brand Описание бренда
   * @return Бренд создан
   */
  create(brand: Brand): __Observable<Brand> {
    return this.createResponse(brand).pipe(
      __map(_r => _r.body as Brand)
    );
  }

  /**
   * Запросить список брендов
   *
   * Запросить список брендов
   * @param params The `BrandApiService.QueryBrandsParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование купона
   *     - `description` - описание купона
   *
   * - `sentence`: Строка поиска
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список брендов
   */
  queryBrandsResponse(params: BrandApiService.QueryBrandsParams): __Observable<__StrictHttpResponse<Array<Brand>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.sentence != null) __params = __params.set('sentence', params.sentence.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Brand>>;
      })
    );
  }
  /**
   * Запросить список брендов
   *
   * Запросить список брендов
   * @param params The `BrandApiService.QueryBrandsParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование купона
   *     - `description` - описание купона
   *
   * - `sentence`: Строка поиска
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список брендов
   */
  queryBrands(params: BrandApiService.QueryBrandsParams): __Observable<Array<Brand>> {
    return this.queryBrandsResponse(params).pipe(
      __map(_r => _r.body as Array<Brand>)
    );
  }

  /**
   * Запрос данных бренда по ID
   *
   * Запрос данных бренда по ID
   * @param id ID бренда
   * @return Описание бренда
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<Brand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brand/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Brand>;
      })
    );
  }
  /**
   * Запрос данных бренда по ID
   *
   * Запрос данных бренда по ID
   * @param id ID бренда
   * @return Описание бренда
   */
  get(id: number): __Observable<Brand> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as Brand)
    );
  }

  /**
   * Обновление данных бренда
   *
   * Обновление данных бренда
   * @param params The `BrandApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID бренда
   *
   * - `brand`: Описание бренда
   *
   * @return Бренд обновлен
   */
  updateResponse(params: BrandApiService.UpdateParams): __Observable<__StrictHttpResponse<Brand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.brand;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/brand/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Brand>;
      })
    );
  }
  /**
   * Обновление данных бренда
   *
   * Обновление данных бренда
   * @param params The `BrandApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID бренда
   *
   * - `brand`: Описание бренда
   *
   * @return Бренд обновлен
   */
  update(params: BrandApiService.UpdateParams): __Observable<Brand> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as Brand)
    );
  }

  /**
   * Удаление бренда по ID
   *
   * Удаление бренда по ID
   * @param id ID бренда
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/brand/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление бренда по ID
   *
   * Удаление бренда по ID
   * @param id ID бренда
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BrandApiService {

  /**
   * Parameters for queryBrands
   */
  export interface QueryBrandsParams {

    /**
     * Порядок сортировки:
     *   - `asc`
     *   - `desc`
     *   - `reverse`
     */
    sortingOrder?: 'asc' | 'desc' | 'reverse';

    /**
     * Поле для сортировки:
     *   - `name` - наименование купона
     *   - `description` - описание купона
     */
    sortingField?: 'name' | 'description';

    /**
     * Строка поиска
     */
    sentence?: string;

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID бренда
     */
    id: number;

    /**
     * Описание бренда
     */
    brand: Brand;
  }
}

export { BrandApiService }
