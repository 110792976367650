/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CardApiConfiguration, CardApiConfigurationInterface } from './card-api-configuration';

import { CardApiService } from './services/card-api.service';

/**
 * Provider for all CardApi services, plus CardApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CardApiConfiguration,
    CardApiService
  ],
})
export class CardApiModule {
  static forRoot(customParams: CardApiConfigurationInterface): ModuleWithProviders<CardApiModule> {
    return {
      ngModule: CardApiModule,
      providers: [
        {
          provide: CardApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
