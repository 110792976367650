
  <div class="fixed-tool-panel">


    <button type="button" class="btn btn-sm btn-addon btn-danger "
            [hidden]="!statesChangerService.hasButton('delete')"
            data-testid="location.delete()"
            (click)="confirmActionService.confirm('Вы действительно хотите удалить точку продаж ' + currentLocation.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
    >
      <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
      <i class=" m-r-xs fa fa-remove"></i>
      <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
    </button>



    <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
            [hidden]="!statesChangerService.hasButton('edit')"
            (click)="statesChangerService.click('edit')"
    >

      <i class=" fa fa-pencil">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
    </button>


    <button type="button" data-testid="location.save()" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('save')"
            (click)="statesChangerService.click('save')"
            [disabled]="locationFrom && locationFrom.form && locationFrom.form.invalid"
    >
      <i class=" fa fa-check text-primary">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
    </button>

    <button type="button" data-testid="location.cancel()" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('cancel')"
            (click)="statesChangerService.click('cancel')">

      <i class=" fa fa-remove text-danger">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
    </button>


    <button type="button" data-testid="location.preview()" class="btn btn-sm btn-addon  btn-default pull-right"
            *ngIf="statesChangerService.hasButton('preview')"
            (click)="statesChangerService.click('preview')"

            [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
            [disabled]="locationFrom && locationFrom.form && locationFrom.form.invalid"
    >
      <i class=" fa fa-eye text-success">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
    </button>

    <button type="button" data-testid="location.back()" class="btn  btn-success btn-sm btn-addon pull-right"
            *ngIf="statesChangerService.state === 'view'"
            [uiSref]="'^.index({partnerId:'+partnerId+'})'"
    >

      <i class="fa fa-arrow-left"></i>
      <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
    </button>

  </div>

  <form name="locationFrom" #locationFrom="ngForm" novalidate autocomplete="off">

  <div class="card w-full no-back"
       *transloco="let t"
  >

    <div class="card-body"
      *ngIf="currentLocation"
    >

        <grid-container>

          <div class="grid-item"
               [grid-item-form] = "'models.Location.id.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
            <span data-testid="location.id"
              [innerHTML]="currentLocation.id || '-'"
            ></span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'Название'"
               grid-item-form-icon = "fa-user"
          >
            <span [hidden]="statesChangerService.isActive"
                  [innerText]="currentLocation.name"
                  data-testid="location.name"
            ></span>

                <span class="editable-rec"
                      [hidden]="!statesChangerService.isActive"
                >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocation.name"
                data-testid="location.input.name"
                name="name"
                autoComplete="off"
                [placeholder]="'Введите название магазина'"
                [required]="true"
                [maxlength]="254"
              >
            </span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'Партнёр'"
               grid-item-form-icon = "fa-user"
          >
            <span data-testid="location.partner"
              [innerHTML]="currentPartner?.name || '-'"
            ></span>
          </div>


          <div class="grid-item"
               [grid-item-form] = "'Адрес'"
               grid-item-form-icon = "fa-user"
          >
            <span [hidden]="statesChangerService.isActive"
                  [innerText]="currentLocation.address || ''"
                  data-testid="location.address"
            ></span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocation.address"
                data-testid="location.input.address"
                name="address"
                autoComplete="off"
                [placeholder]="'Введите адрес магазина'"
                [required]="false"
                [maxlength]="254"
              >
            </span>
          </div>


          <div class="grid-item"
               [grid-item-form] = "'Описание'"
               grid-item-form-icon = "fa-align-justify"
          >
         <span [hidden]="statesChangerService.isActive"
               [innerText]="currentLocation.description || ''"
               data-testid="location.description"
         ></span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
              <textarea
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocation.description"
                data-testid="location.input.description"
                name="description"
                [placeholder]="'Введите краткое описание магазина'"
                [required]="false"
                [maxlength]="254"
                rows="3"
              ></textarea>
          </span>
          </div>


          <div class="grid-item"
               [grid-item-form] = "'Код'"
               grid-item-form-icon = "fa-user"
          >
            <span [hidden]="statesChangerService.isActive"
                  [innerText]="currentLocation.code"
                  data-testid="location.code"
            ></span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentLocation.code"
                data-testid="location.input.code"
                name="code"
                autoComplete="off"
                [placeholder]="'Введите код магазина'"
                pattern="^[a-zA-Z0-9\.\-_]+$"
                [required]="true"
                [maxlength]="254"
              >
            </span>
          </div>

        </grid-container>

    </div>

  </div>


  <div class="card w-full no-back"  >
    <div class="card-header">
      Локации
    </div>
    <div class="card-body " >

      <grid-container>

        <div class="grid-item"
             [grid-item-form] = "'Локации'"
             grid-item-form-icon = "fa-user"
        >
          <span *ngIf="!statesChangerService.isActive">

            <div data-testid="locationGroups"
              *ngFor=" let item of currentLocation.locationGroups "
            >
              {{ item.name || '' }}
            </div>

          </span>

          <span class="editable-rec" data-testid="locationGroups"
                *ngIf="statesChangerService.isActive"
          >
              <ng-select [(ngModel)]="currentLocation.locationGroups"
                         name="locationItemGroups"
                         data-testid="location.select.locationGroups"
                         [placeholder]="'Выберите локацию'"
                         [multiple]="true"
                         bindLabel="name"
                         [compareWith]="compareLocation"
              >

                <ng-option *ngFor="let item of locationItemGroupsList" [value]="{ id: item.id, name: item.name }" data-testid="location.item of locationGroups">
                  {{item.name}}
                </ng-option>


              </ng-select>

          </span>
        </div>

      </grid-container>

    </div>

  </div>

  <div class="card w-full no-back"  >
    <div class="card-header">
      Параметры точки продаж
    </div>
    <div class="card-body " >

      <grid-container>

        <div class="grid-item"
             [grid-item-form] = "'Правила разрешения коллизий с кассой'"
             grid-item-form-icon = "fa-user"
        >
          <span [hidden]="statesChangerService.isActive"
                [innerText]=" applyCashDiscountList[currentLocation.applyCashDiscount] || ''"
                data-testid="location.applyCashDiscount"
          ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >

              <ng-select [(ngModel)]="currentLocation.applyCashDiscount"
                         [placeholder]="'Выберите локацию'"
                         data-testid="location.select.applyCashDiscount"
                         name="applyCashDiscount"
                         bindLabel="value"
                         bindValue="key"
              >

                <ng-option *ngFor="let item of applyCashDiscountList | keyvalue" [value]="item.key" data-testid="location.item of applyCashDiscountList">
                  {{item.value }}
                </ng-option>

              </ng-select>

          </span>
        </div>

      </grid-container>

    </div>

  </div>


  </form>

