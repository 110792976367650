import {Injectable} from "@angular/core";
import {TriggersApiService} from "../../../../api/TriggersApi/services/triggers-api.service";
import {GetActionTransactionParams} from "../../../../api/TriggersApi/models/get-action-transaction-params";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {TriggerState} from "../../../../api/TriggersApi/models/trigger-state";

@Injectable()
export class TriggersService {

  constructor( private triggersApiService: TriggersApiService ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "drop",
      limitField : "limit",
      limit: 50
    }, filters: [
      {
        field: 'states',
        id: 'states',
        currentValue: undefined
      }
    ]
  });

  public getActionTransactionsParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "drop",
      limitField : "limit",
      limit: 50
    },
    filters: [
      {
        field: 'states',
        id: 'states',
        currentValue: undefined
      }
    ]
  });

  public query$( params: TriggersApiService.QueryTriggersParams ){
    return this.triggersApiService.queryTriggers(
      params
    );
  }



  public getActionTransactions$(id: number, action: string, pager: any = {}, date = new Date() ){
    return this.triggersApiService.getActionTransactions(Object.assign({
      id: id,
      params: {
        actionId: action,
        date: date,
      }
    }, pager)
    );
  }


}
