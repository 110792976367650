import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";

import {AuthPageComponent} from "./auth-page.component";

import "./auth-legacy.module"


@NgModule({
  declarations: [
    AuthPageComponent,
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    CommonModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class AuthPageModule {

  constructor() {}

}
