/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UserApiConfiguration, UserApiConfigurationInterface } from './user-api-configuration';

import { UserApiService } from './services/user-api.service';

/**
 * Provider for all UserApi services, plus UserApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    UserApiConfiguration,
    UserApiService
  ],
})
export class UserApiModule {
  static forRoot(customParams: UserApiConfigurationInterface): ModuleWithProviders<UserApiModule> {
    return {
      ngModule: UserApiModule,
      providers: [
        {
          provide: UserApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
