/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AddressApiConfiguration, AddressApiConfigurationInterface } from './address-api-configuration';

import { AddressApiService } from './services/address-api.service';

/**
 * Provider for all AddressApi services, plus AddressApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AddressApiConfiguration,
    AddressApiService
  ],
})
export class AddressApiModule {
  static forRoot(customParams: AddressApiConfigurationInterface): ModuleWithProviders<AddressApiModule> {
    return {
      ngModule: AddressApiModule,
      providers: [
        {
          provide: AddressApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
