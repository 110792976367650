/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { BehaviorApiConfiguration as __Configuration } from '../behavior-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientLastCheckDate } from '../models/client-last-check-date';
import { ClientLastCheckDateParams } from '../models/client-last-check-date-params';
import { BehaviorInternalApi } from '../models/behavior-internal-api';
@Injectable({
  providedIn: 'root',
})
class BehaviorApiService extends __BaseService {
  static readonly collectLastCheckDatesPath = '/lastcheckdates';
  static readonly getProfilePath = '/{clientId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запросить клиентов с датой последней покупки до указанной даты
   * @param params Параметры запроса
   * @return Список дат последней покупки клиентов в указанном диапазоне
   */
  collectLastCheckDatesResponse(params: ClientLastCheckDateParams): __Observable<__StrictHttpResponse<Array<ClientLastCheckDate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/lastcheckdates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientLastCheckDate>>;
      })
    );
  }
  /**
   * Запросить клиентов с датой последней покупки до указанной даты
   * @param params Параметры запроса
   * @return Список дат последней покупки клиентов в указанном диапазоне
   */
  collectLastCheckDates(params: ClientLastCheckDateParams): __Observable<Array<ClientLastCheckDate>> {
    return this.collectLastCheckDatesResponse(params).pipe(
      __map(_r => _r.body as Array<ClientLastCheckDate>)
    );
  }

  /**
   * Профиль клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getProfileResponse(clientId: number): __Observable<__StrictHttpResponse<BehaviorInternalApi>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BehaviorInternalApi>;
      })
    );
  }
  /**
   * Профиль клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getProfile(clientId: number): __Observable<BehaviorInternalApi> {
    return this.getProfileResponse(clientId).pipe(
      __map(_r => _r.body as BehaviorInternalApi)
    );
  }
}

module BehaviorApiService {
}

export { BehaviorApiService }
