import {CARD_STATE_NAMES} from "../../../../../../app/shared/service/api/Card/Card.values";

const REMOVED_FIELD = "$removed";
const CHANGE_STATE_FIELD = "$change_state";
const ADD_FIELD = "$isNew"


export class clientCardsEdit {

    // Передаваемые параметры в компонент
    public clientId: any;
    public useEmptyCard: any;
    public isCreateForm: any;

    public cards: Array<any>;
    public _tcr : any;
    public stateList : Array<any>;

    public newClientCardNumber : string;

    static $inject = ['CardModel', 'ClientModel', 'toaster', '$scope' ];

    public CARD_STATE_NAMES = CARD_STATE_NAMES;

    constructor(
      private CardModel: any,
      private ClientModel: any,
      private toaster: any,
      private $scope: any
    ) {

    }

    public $onInit() {
      this._tcr = new this.CardModel();
      this.stateList = this._tcr.getFields().status;

      this.$scope.$on( 'client.afterSave', ($event, client, queue) => {

        this.onSaveClient(client.client.id,queue );

      } );
    }

    public $onChanges( changesObj : any) {

      if ( changesObj.clientId ) {
        this.getCardsForClient( changesObj.clientId.currentValue );
      }

    }

    private getCardsForClient( clientId ) {

      this.cards = typeof clientId === "undefined" ? [] : this.CardModel.queryCards({
        'filter.clientId' : clientId
      });

    }

    public changeStateEvent( card ) {
      card[CHANGE_STATE_FIELD] = true;
    }

    private filterByDeleted( card ) {
      return  !card  || !card[REMOVED_FIELD]
    }

    private clientCardAdd() {
        /// запрашиваем информацию по карте
        /// проверка на наличие
        /// проверка на привязку карты другому клиенту
        /// проверка на статус
        /// если все ОК - добавляем в список кондидатов

        this.CardModel.getCardByNumber({'number': this.newClientCardNumber},
          (condidateCard) => {

            if (  ['nonactive', 'active'] .indexOf(condidateCard.state) != -1) {

              let currentIndex = this.cards.findIndex( i => i.card.id === condidateCard.id);
              if ( currentIndex >=0 ) {
                if ( this.cards[currentIndex][REMOVED_FIELD] )
                  delete this.cards[currentIndex][REMOVED_FIELD];

                this.newClientCardNumber = '';
                return;
              }

              if (condidateCard.clientId !== undefined) {
                condidateCard.oldClientId = condidateCard.clientId;
                condidateCard.oldClient = this.ClientModel.get({id:condidateCard.oldClientId});
              }

              condidateCard.clientId = this.clientId;
              var addCard = new this.CardModel();
              addCard.card = condidateCard;
              addCard.card.state = 'active';
              addCard[ADD_FIELD] = true;
              this.cards.push(addCard);
            } else {
              if (this._tcr.getField('status', condidateCard.state) !== false) {
                this.toaster.pop('warning', 'Ошибка', 'Карта ' + this.newClientCardNumber + ' в стостоянии ' + this._tcr.getField('status', condidateCard.state) + ', невозможно выполнить привязку к клиенту');
              }
            }

            this.newClientCardNumber = '';
          },
          () => {
            this.toaster.pop('warning', 'Ошибка', 'Нет информации о карте ' + this.newClientCardNumber);
            this.newClientCardNumber = '';
          });
    }

    private clientCardRemove(__id) {
      for (var key in this.cards) {
        if (this.cards[key].card.id == __id) {
          this.cards[key][REMOVED_FIELD] = true;
          break;
        }
      }
    };

    private onSaveClient( clientId, queue = [] ) {

        if ( typeof clientId === "undefined" )
          clientId = this.clientId;

      this.cards.forEach( (card: any ) => {

        if ( card[REMOVED_FIELD] ) {
          queue.push( this.CardModel.detachCard({ cardId: card.card.id }).$promise );
        }

        if ( card[ADD_FIELD] ) {
          queue.push(  this.CardModel.attachCard({ cardId: card.card.id,  clientId: clientId })
                          .$promise.then(()=> this.CardModel.changeCardStatus({  cardId: card.card.id, status:card.card.state}) )
          );
        }

        if ( card[CHANGE_STATE_FIELD] ) {
          queue.push( this.CardModel.changeCardStatus({  cardId: card.card.id, status:card.card.state}).$promise );
        }

      })

    }

}

