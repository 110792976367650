/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PartnerApiConfiguration, PartnerApiConfigurationInterface } from './partner-api-configuration';

import { PartnerApiService } from './services/partner-api.service';

/**
 * Provider for all PartnerApi services, plus PartnerApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    PartnerApiConfiguration,
    PartnerApiService
  ],
})
export class PartnerApiModule {
  static forRoot(customParams: PartnerApiConfigurationInterface): ModuleWithProviders<PartnerApiModule> {
    return {
      ngModule: PartnerApiModule,
      providers: [
        {
          provide: PartnerApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
