/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ClientWalletApiConfiguration as __Configuration } from '../client-wallet-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Wallet } from '../models/wallet';
@Injectable({
  providedIn: 'root',
})
class ClientWalletApiService extends __BaseService {
  static readonly getPath = 'client/{clientId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Получить wallet
   * @param clientId ID клиента
   * @return wallet
   */
  getResponse(clientId: number): __Observable<__StrictHttpResponse<Wallet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `client/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Wallet>;
      })
    );
  }
  /**
   * Получить wallet
   * @param clientId ID клиента
   * @return wallet
   */
  get(clientId: number): __Observable<Wallet> {
    return this.getResponse(clientId).pipe(
      __map(_r => _r.body as Wallet)
    );
  }
}

module ClientWalletApiService {
}

export { ClientWalletApiService }
