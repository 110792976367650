<div class="fixed-tool-panel">


  <button type="button" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete')"
          data-testid="categoryClient.delete()"
          (click)="confirmActionService.confirm('Вы действительно хотите удалить категорию клиентов ' + currentClientCategory.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
  >
    <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
  </button>



  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          [hidden]="!statesChangerService.hasButton('edit')"
          (click)="statesChangerService.click('edit')"
          data-testid="categoryClient.edit()"
  >

    <i class=" fa fa-pencil">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          data-testid="categoryClient.save()"
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          data-testid="categoryClient.cancel()"
          (click)="statesChangerService.click('cancel')">

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"
          data-testid="categoryClient.preview()"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
          data-testid="categoryClient.back()"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>


<div class="card w-full no-back"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentClientCategory"
  >
    <form name="mcategoryForm" #mcategoryForm="ngForm" novalidate>

      <grid-container>

      <div class="grid-item"
           [grid-item-form] = "'models.ClientCategoryApi.id.name' | transloco"
           grid-item-form-icon = "fa-gear"
      >
        <span
          [innerHTML]="currentClientCategory.id || '-'"
          data-testid="categoryClient.id"
        ></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.ClientCategoryApi.name.name'| transloco"
           grid-item-form-icon = "fa-gear"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentClientCategory.name"
              data-testid="categoryClient.name"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
          <input
            type="text"
            class="form-control form-control-edittable"
            [(ngModel)]="currentClientCategory.name"
            data-testid="categoryClient.input.name"
            name="name"
            [placeholder]="'models.ClientCategoryApi.name.placeholder'| transloco"
            [required]="true"
            [maxlength]="254"
          >
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.ClientCategoryApi.description.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
         <span [hidden]="statesChangerService.isActive"
               [innerText]="currentClientCategory.description"
               data-testid="categoryClient.description"
         ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
            <textarea
              class="form-control form-control-edittable"
              [(ngModel)]="currentClientCategory.description"
              data-testid="categoryClient.input.description"
              name="description"
              [placeholder]="'models.ClientCategoryApi.description.placeholder'| transloco"
              [required]="true"
              [maxlength]="254"
              rows="3"
            ></textarea>
        </span>
      </div>

        <div class="grid-item"

             loya-permit-if="campaign.mechanics.bonus" permit-enabled=""
             permit-or-if="selectedClientCategory.maxAllowBonus>=0"

             [grid-item-form] = "'models.ClientCategoryApi.maxAllowBonus.name'| transloco"
             grid-item-form-icon = "fa-align-justify"
        >
         <span [hidden]="statesChangerService.isActive"
               [innerText]="currentClientCategory.maxAllowBonus === undefined ? '-' : currentClientCategory.maxAllowBonus"
               data-testid="categoryClient.maxAllowBonus"
         ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
            <input
              type="number"
              class="form-control form-control-edittable"
              [(ngModel)]="currentClientCategory.maxAllowBonus"
              data-testid="categoryClient.input.maxAllowBonus"
              name="maxAllowBonus"
              [placeholder]="'models.ClientCategoryApi.maxAllowBonus.placeholder'| transloco"
              [required]="false"
            >
        </span>
        </div>


    </grid-container>
    </form>

  </div>

</div>
