export const getDescription = (description: string, record?: any) => {

  if (
    !description ||
    typeof description === "string" && !description.toLowerCase().startsWith('начислено цепочкой')
  ) return  description;

  const result = /(начислено цепочкой\s+)\[(\d+)\](.*)/gmi.exec(description);

  if (!result) return description;

  result[3] = !!result[3] ? result[3].trim() : undefined;

  const linkName = !!result[3] ? result[3] : result[1] + `(ID: ${result[2]})`;

  return ( !!result[3] ? "Начислено цепочкой " : "" ) +
    `<a href="#!/triggers/trigger/view/${result[2]}" >${linkName}</a>`;
}
