import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserApiService} from "../../../../api/UserApi/services";
import {UserProtocol, UserState} from "../../../../api/UserApi/models";
import {from, Observable, throwError} from "rxjs";
import SHA1 from "crypto-js/sha1";
import SHA256 from "crypto-js/sha256";
import {delayWhen, map, switchMap} from "rxjs/operators";
import {getNg1Service} from "../../../class/utils/angularjs.utils";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private userApiService:UserApiService,
    private httpClient: HttpClient
  ) {
  }

  public getList(){
    return this.userApiService.query()
  }

  public getUser( id? :number ){
    if (!id)
      return  new Observable(s => {
        s.next({
          state: UserState.ACTIVE
        });
        s.complete();
      });

    return this.userApiService.get(id)
  }

  public saveUser( user: UserProtocol ){

    if (typeof user.id !== "undefined") {
      return this.userApiService.update({ id: user.id, userProtocol: user });
    } else {
      return this.userApiService.create( user );
    }

  }

  public deleteUser( id: number ){

    return this.userApiService.delete(id);

  }

  public getVersion() {

    return this.httpClient.get('/assets/frontend/js/version.json?t=' +  ( new Date() ).getTime(), {
      responseType: "json"
    } )

  }

  public login(email, password) {

    const UserModel = getNg1Service('UserModel');

    return this.httpClient.get(
      '/flogin/salt',
      {
              responseType: "text"
            }
    )
      .pipe(
        switchMap( (saltResult: any = '') =>
           !!saltResult ?
            this.httpClient.post('/flogin', {
              email,
              password: SHA256( SHA1( password ).toString() + saltResult || "" ).toString(),
              apikey: ""
            },{
              responseType: "text"
            }) :
            throwError(() => new Error('Salt is empty') )

        ),
        map( res => {
          if (res.toLowerCase().trim() === "login success" ) {
            return true
          } else {
            throw new Error(res);
          }
        }),
        delayWhen(() => from( UserModel.$updateCapabilities() ))
      );

  }

  public logout() {

  }

}
