import { IResourceFieldOptions } from "./ngResourceFilter.class";
import { QueryHandler } from './../../QueryHandler.class';

export class ngResourceFilterToggle {

    private _enabled = true;
    private _fieldFlags: {
        [ name: string ] : boolean
    } = {}

  static $inject = ['options','paramName','_resetFunction','startOptions'];

    constructor(
        private options: IResourceFieldOptions,
        private paramName: any,
        private _resetFunction: any,
        startOptions: any
    ) {

        this._bindFilterFields();
        this._setDefault( startOptions );

    }


    public getParamQuery = function() {

        if ( !this._enabled ){
            return {};
        }

        let result = '';

        for ( let alias of Object.keys( this.options.values ) ) {

            if ( this[alias] ) {
                result += ( !result ? '' : ',' ) + this.options.values[alias];
            }

        }

        return QueryHandler.setValueByPath({},this.paramName, result ? result : undefined );

    };

    get $enabled() {
        return this._enabled;
    }

    set $enabled( value: boolean ) {
        this._enabled = value;
        this._resetFunction();
    }

    get $value() {

        let arr = Object.keys( this._fieldFlags ).filter( (alias: string) =>  this._fieldFlags[alias] );
        if ( arr.length ) {
            return arr[0];
        }

        return;

    }

    set $value( alias: string) {

        if ( typeof alias === "undefined" || alias === "" ) {
            Object.keys( this._fieldFlags )
                  .map( (alias: string) =>  this._fieldFlags[alias] = false );

            this._resetFunction();
        }

        if ( this.options.values[alias] ) {
            this[alias] = true;
        }

    }

    private _bindFilterFields = function() {

        for ( let alias in this.options.values ) {

            this._fieldFlags[alias] = false;

            Object.defineProperty( this, alias, {
                get: function () {
                    return this._fieldFlags[alias];
                },
                set: function( status :boolean ) {
                    this._changeFilterValue( alias, status );
                },
                enumerable: true,
                configurable: true
            });

        };

    }

    private _changeFilterValue = function( alias: string, status: boolean ) {

        this._fieldFlags[alias] = status;

        Object.keys(this._fieldFlags)
              .filter( key => key !== alias )
              .forEach( key => {
                    this._fieldFlags[key] = false;
              });

         this._resetFunction();

    }

    private _setDefault = function( startOptions: any ) {

        switch( true ) {

            case !!QueryHandler.getValueByPath( startOptions, this.paramName ):
                this._fieldFlags[ QueryHandler.getValueByPath( startOptions, this.paramName ) ] = true;
            break;

            case !!this.options.default:
                this._fieldFlags[this.options.default] = true;
            break;
        }

    }
}

