/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CampaignCategoryApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CampaignCategoryApiConfiguration {
  rootUrl: string = location.origin;
}

export interface CampaignCategoryApiConfigurationInterface {
  rootUrl?: string;
}
