'use strict';

import module from 'frontendModule';

import 'directive/mechanicCustomField/main';
import 'model/CampaignModel/resource';


let routePath = module.name + '.campaign.mechanics.master';

/* TODO NEW FRONT toaster */

export default
    ['$scope', '$state', '$rootScope', '$timeout', 'MechanicModel', 'currentMechanic', 'toaster', 'savePreviousMechanic', 'installedPluginsList', 'mechanicsCategoryList', 'CampaignModel', 'isReadOnly',
        function ($scope, $state, $rootScope, $timeout, MechanicModel, currentMechanic, toaster, savePreviousMechanic, installedPluginsList, mechanicsCategoryList, CampaignModel, isReadOnly) {

            var previousState       = $scope.$previousState,
                previousStateParams = $scope.$previousStateParams;

            $scope.readOnly = isReadOnly;
            $scope.loadPlugin = false;

                $scope._t = new MechanicModel();
            $scope.mechanic = currentMechanic;
            $scope.pluginTabs = [];

            if ($scope.mechanic.status == "campaign" && $scope.mechanic.campaignId !== undefined) {
                $scope.campaign = CampaignModel.get({id: $scope.mechanic.campaignId}, function () {
                    $scope.mechanic.extension.partnerId = $scope.campaign.campaign.partnerId;
                });

            }
            $scope.mechanicErrors = {};
            $scope.hasMechanicErrors = function () {

                for (var i in $scope.mechanicErrors) {
                    return true;
                }

                return false;

            };

            $scope.mechanicChanged = false;


            $scope.mechanicsCategoryList = mechanicsCategoryList;
            /*
             $scope.installedPluginsList = installedPluginsList;
             */

            $scope.installedPluginsList = [];

            angular.forEach(installedPluginsList, function (plug) {

                if (plug.name.toLowerCase() === "afteralldiscountsgiftmechanic" && currentMechanic?.mechType?.toLowerCase() !== "afteralldiscountsgiftmechanic" ) {
                  return
                }

                plug.label = plug.name.toLowerCase();
                $scope.installedPluginsList.push(plug);
            });

            $scope.showPlugins = true;

            $scope.saveCurrent = function () {
                $scope.mechanic.$update();
            };

            $scope.$watch('mechanic.mechType', function (newValue, oldValue) {

                    if (newValue && newValue !== oldValue) {

                        $state.go(routePath + '.empty').finally(function() {

                            $timeout(function() {

                                if (newValue !== oldValue) {
                                    $scope.mechanic.extension = {};
                                    $scope.unconfiguredFields = {};
                                    $scope.mechanicErrors     = {};

                                    const newPlugin = installedPluginsList.filter( plg => plg.name ===  newValue);
                                    if (newPlugin && newPlugin[0] ) {
                                        $scope.mechanic.version = newPlugin[0].version;
                                    }

                                }

                                $state.go(routePath + '.' + newValue.toLowerCase());

                            }, 100);
                    });

                }

            });


            $scope.deleteMechanic = function () {

                if ($scope.mechanic.id !== undefined) {

                    return $scope.mechanic.$delete(function () {
                        $state.go('frontend.campaign.mechanics.index');
                    }).then(function () {
                        savePreviousMechanic.clear();
                    });

                }

            };

            $scope.duplicateMechanic = function () {

                if ($scope.mechanic.id !== undefined) {

                    delete $scope.mechanic.id;
                    $scope.mechanic.status = "template";
                    $scope.mechanic.name = $scope.mechanic.name + ' ( копия )';
                    return $scope.mechanic.$create(function (responce) {

                        $state.go('frontend.campaign.mechanics.master', {mechanicId: responce.id});
                        toaster.pop('success', 'Дублирование механики', 'Дублирование механики ' + $scope.mechanic.name + ' произведено успешно');

                    }).then(function () {

                        savePreviousMechanic.clear();

                    });

                }

            };


            if ($scope.mechanic.mechType) {
                $state.go(routePath + '.' + $scope.mechanic.mechType.toLowerCase());

            }

            /*----------------------------------------------------------------*/

            var masterStates = [
                'create',
                'config'
            ];
            var masterStatePointer = 0;
            $scope.masterState = masterStates[masterStatePointer];

            $scope.nextStep = function () {

                if (masterStatePointer + 1 <= masterStates.length - 1) {
                    masterStatePointer++;
                    $scope.masterState = masterStates[masterStatePointer];
                }

            };


            $scope.prevStep = function () {

                if (masterStatePointer - 1 >= 0) {
                    masterStatePointer--;
                    $scope.masterState = masterStates[masterStatePointer];
                }

            };

            $scope.unconfiguredFields = {};
            $scope.blockerShow = false;

            /*
             $scope.$watch( 'unconfiguredFields',  function( newVal ) {

             if ( Object.keys(newVal).length ) {

             $scope.nextStepButton = $scope.masterState;

             } else {
             $scope.nextStepButton = false;
             }

             }, true);
             */
            /*
             $scope.$watch( function() {
             return  $scope.masterState == masterStates[1] ; //$scope.hasUnconfigurefFields() && $scope.masterState == masterStates[1];
             },  function( newVal ) {

             $scope.blockerShow = newVal;

             });
             */

            $scope.hasUnconfigurefFields = function () {
                return !!Object.keys($scope.unconfiguredFields).length;
            };

            $scope.saveMechanic = function () {

                if (!$scope.hasUnconfigurefFields()) {

                        $scope.$broadcast('mechanic.save', $scope.mechanic);

                        if ($scope.mechanic.id !== undefined) {

                        return $scope.mechanic.$update(function (savedMechanic) {

                            if (savedMechanic.status === "campaign" && savedMechanic.campaignId !== undefined) {
                                $state.go('frontend.campaign.campaigns.view', {id: savedMechanic.id});
                            } else {
                                $state.go('frontend.campaign.mechanics.index');
                                // $state.go('frontend.campaign.mechanics.master',{ mechanicId: savedMechanic.id });
                            }

                        }).then(function () {
                            savePreviousMechanic.clear();
                        });

                    } else {
                        return $scope.mechanic.$create(function (responce) {
                            $state.go('frontend.campaign.mechanics.index');
                            // $state.go('frontend.campaign.mechanics.master',{ mechanicId: responce.id });
                        }).then(function () {
                            savePreviousMechanic.clear();
                        });
                    }

                }

            };


            $scope.cancelMechanic = function () {

                if ($scope.mechanic.id !== undefined) {

                    if ($scope.mechanic.status === "campaign" && $scope.mechanic.campaignId !== undefined) {
                        $state.go('frontend.campaign.campaigns.view', {id: $scope.mechanic.campaignId});
                    } else {
                        $state.go('frontend.campaign.mechanics.index');
                    }

                } else {

                    $state.go('frontend.campaign.mechanics.index');

                }

            };




        }]
;
