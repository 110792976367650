<div class="app ng-scope app-header-fixed app-aside-fixed"
     id="app">

  <div ui-view="" class="fade-in-right-big m-t-lg smooth ng-scope">
    <div class="container w-xxl m-t-lg w-auto-xs ng-scope" >

      <div class="m-b-lg m-t-lg">
        <div class="padder m-t-lg m-b-md text-center" style="position: relative">
          <a href="" class=" m-t m-b" id="login_brand_logo"></a>
          <div class="clearfix m-t-xs m-b-md"
               *ngIf="!!version"
          >
            <span class="text-muted text-center f-s-8" id="version">
               v {{version?.version}} (build {{version?.build}})
            </span>
          </div>
          <strong class="m-t-xs">Вход</strong>
        </div>

        <form name="loginForm" #loginForm="ngForm"  autocomplete="on" id="loginform">

          <div class="text-danger padder m-b-sm text-center "
               *ngIf="!!error"
               [innerText]="error"
          >
          </div>

          <ng-container
            *ngIf="!isLoading"
          >

            <div class="list-group list-group-sm">
                <div class="list-group-item m-b">
                    <input
                      type="email"
                      name="email"
                      data-testid="login.input.email"
                      placeholder="Email"
                      id="email"
                      class="form-control no-border"
                      autocomplete="username"
                      [(ngModel)]="email"
                      [required]="true"
                      (keydown.enter)="login()"
                    >
                </div>
                <div class="list-group-item m-t-sm">
                    <input type="password"
                           name="password"
                           data-testid="login.input.password"
                           id="password"
                           class="form-control no-border"
                           autocomplete="current-password"
                           placeholder="Пароль"
                           [(ngModel)]="password"
                           [required]="true"
                           (keydown.enter)="login()"
                    >
                </div>
            </div>

            <button type="button"
                    id="loginbutton"
                    name="login"
                    data-testid="login()"
                    class="btn btn-lg btn-primary btn-block m-t-sm"
                    [disabled]="loginForm.invalid"
                    (click)="login()"
            >
                Войти
            </button>

          </ng-container>

          <ng-container
            *ngIf="isLoading"
          >
            <div class="text-center m-t-lg fa-4x">
              <i class="fa fa-spin fa-spinner text-warning" data-testid="login.warning"></i>
            </div>
          </ng-container>


        </form>

      </div>

    </div>
  </div>
</div>
