import {DEFAULT_RULE_TYPE, RuleTypes} from '../../class/CampaignCategoryMatrix';
import {Component, Input} from "@angular/core";

export const RuleNames = {
    [RuleTypes.bySum]      : 'все вознаграждения суммируются',
    [RuleTypes.byMaximum]  : 'по максимальному вознаграждению',
    [RuleTypes.byPriority] : 'по приоритету'
}

type GroupInputData  = {
    id   : number,
    name : string
}

@Component({
  selector: 'campaign-category-rule-index-page',
  templateUrl: './campaignCategoryRule-index-page.component.html',
  styleUrls: ['./campaignCategoryRule-index-page.component.scss']
})
export class СampaignCategoryRuleComponent {

    // Передаваемые параметры в компонент
    @Input() public rule  : {
        rule   : RuleTypes,
        winId? : number
    };
  @Input() public groupColumn : GroupInputData;
  @Input() public groupRow    : GroupInputData;
  @Input() public position    : number;
  @Input() public editable    : boolean;

    public ruleTypesList = RuleTypes;

    constructor( ) { }

    get isByPriority() {
        return this.rule && this.rule.rule === RuleTypes.byPriority;
    }

    public $onInit() {
        this.initRuleType();
    }

    private initRuleType() {
        this.rule.rule = this.rule.rule || DEFAULT_RULE_TYPE;
    }

    private getRuleName( ruleId: RuleTypes ){
        return RuleNames[ruleId];
    }

    private setRule( newValue: RuleTypes ) {
        this.rule.rule = newValue;
        this.onChangeRule();
    }

    private setWinId( newValue: number ) {
        this.rule.winId = newValue;
    }

    private onChangeRule() {
        if ( this.rule.rule === RuleTypes.byPriority ) {
            this.rule.winId = this.groupColumn.id;
        } else {
            delete this.rule.winId;
        }
    }

    private getGroupNameById( id : number, shortName = false ) {
        switch(true){
            case this.groupRow.id === id    : return shortName ? this.shortName(this.groupRow.name)    : this.groupRow.name;
            case this.groupColumn.id === id : return shortName ? this.shortName(this.groupColumn.name) : this.groupColumn.name ;
            default : return `Удалённая группа (id:${id})`;
        }
    }

    private shortName( name : string = '') {

        if ( name.length < 95 )
            return name;

        return name.substr(0 , 95) + '...';

    }





};
