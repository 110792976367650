<div class="fixed-tool-panel ">

  <button class="btn   btn-sm btn-warning   pull-right"
          (click)="sendToUpdate()">
    <i class="fa fa-refresh"></i>
  </button>


  <div class=" ">
    <input
      type="hidden"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="2"
      [dayTemplate]="t"
      outsideDays="hidden"
      autoClose="outside"
      [startDate]="fromDate"
    />

    <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
    </ng-template>

  </div>

  <div class="btn-group ">





    <button type="button" class="btn btn-sm btn-warning" (click)="updateDate(-1)">
      <i class="fa fa-arrow-left"></i>
    </button>

    <button class="btn btn-default btn-sm m-l-none  "
            (click)="datepicker.toggle()" >

      <span *ngIf="fromDate && toDate"
      >{{ dateName || 'Не задано' }}</span
      >
        <span *ngIf="!fromDate || !toDate" class="text-danger">Не задано</span>

        <span class="caret ml-1"></span>

    </button>


    <button type="button" class="btn btn-sm btn-warning" (click)="updateDate(1)">
      <i class="fa fa-arrow-right"></i>
    </button>

  </div>

  <div class="btn-group dropdown m-l-md" ngbDropdown
       [placement]="['bottom']"
  >

    <select-locations-ng2
      (callback)="selectLocation($event)"
      [initSelectedItems]="[params.locationId]"
      [checkOne]="false"
      class="btn btn-default btn-sm btn-addon collapse-xs collapse-sm"
    >

      <i>
        <span class="fa fa-filter"
              [ngClass]="{ 'text-danger': isLocationSelected()  }">
        </span>
      </i>

      <span class="btn-name" *ngIf="isLocationSelected()"
            [innerText]=" 'Выбрано ТП: ' + selectedLocations?.length || 0">
      </span>

      <span class="btn-name" *ngIf="!isLocationSelected()">
            ТП не выбрано
      </span>
    </select-locations-ng2>


    <ul class="dropdown-menu scroll-y"
        ngbDropdownMenu
        *ngIf=" isLocationSelected() "
        style="z-index: 9999; max-height: 30em; min-width: 15vw; max-width: 30vw;"
        (click)="$event.stopPropagation()"
    >
      <li class="padder  m-t-sm "
          *ngFor="let item of selectedLocations"
      >

        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center"
               [innerText]="item.name"></div>

          <div class=" align-items-center d-flex p-l-sm" >
            <button class="btn btn-danger btn-xs m-l-sm"
                    (click)=" removeLocation(item.id); $event.stopImmediatePropagation()">
              <i class="fa fa-times"></i>
            </button>
          </div>

        </div>

      </li>

    </ul>

    <button
      *ngIf="isLocationSelected() "
      class="btn btn-default btn-sm "
      ngbDropdownToggle
      aria-expanded="false">
      <span class="caret"></span>
    </button>

    <button
      *ngIf="isLocationSelected() "
      (click)=" removeLocation()"
      class="btn btn-danger btn-sm ">
      <span class="fa fa-times"></span>
    </button>


  </div>



</div>



<div class="wrapper-md m-b-n-md bg-light lt">

  <div class="row ">

    <div class="col-md-6  col-sm-12  col-lg-4 ">
      <dashboard-income-total
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-income-total>
    </div>

    <div class="col-md-6  col-sm-12  col-lg-4 ">
      <dashboard-total-discount
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-total-discount>
    </div>

    <div class="col-md-6  col-sm-12  col-lg-4 ">
      <dashboard-total-gifts
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-total-gifts>
    </div>


    <div class="col-md-6  col-sm-12  col-lg-6 "
         *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
    >

      <dashboard-count-bonuses
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-count-bonuses>

    </div>

    <div class="  col-sm-12 col-md-6 col-lg-6"  >

      <dashboard-check-count
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-check-count>

    </div>


    <div class="col-md-6"
         *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}">
      <dashboard-campaign-bonus-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-campaign-bonus-chart>
    </div>

    <div class="col-md-6">
      <dashboard-campaign-discounts-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-campaign-discounts-chart>
    </div>



    <div class="col-sm-12 col-md-12 col-lg-6 "
         *loyaPermitIf="{path:'channel.type.sms', permitEnabled: ''}"
    >

      <div class="row row-sm text-center">

        <div class="col-sm-12  col-sm-6 col-md-6 col-lg-6"
             *loyaPermitIf="{path:'channel.type.sms', permitEnabled: ''}"
        >
          <dashboard-sms-count
            [updateEvent]="updateEvent"
            [params]="params"
          ></dashboard-sms-count>
        </div>

        <div class="col-sm-12  col-sm-6 col-md-6 col-lg-6"
             *loyaPermitIf="{path:'channel.type.sms', permitEnabled: ''}"
        >
          <dashboard-sms-cost
            [updateEvent]="updateEvent"
            [params]="params"
          ></dashboard-sms-cost>
        </div>

        <div class="col-sm-12  col-sm-6 col-md-6 col-lg-6 "
             *loyaPermitIf="{path:'channel.type.email', permitEnabled: ''}"
        >
          <dashboard-email-count
            [updateEvent]="updateEvent"
            [params]="params"
          ></dashboard-email-count>
        </div>

        <div class="col-sm-12  col-sm-6 col-md-6 col-lg-6"
             *loyaPermitIf="{path:'channel.type.email', permitEnabled: ''}"
        >
          <dashboard-email-cost
            [updateEvent]="updateEvent"
            [params]="params"
          ></dashboard-email-cost>
        </div>

       <div class="col-sm-12   "
            *loyaPermitIf="{path:'channel.type.viber', permitEnabled: ''}"
       >
         <dashboard-viber-block
           [updateEvent]="updateEvent"
           [params]="params"
         ></dashboard-viber-block>
       </div>

      <div class="col-sm-12   "
           *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
      >
        <dashboard-receipt-count
          [updateEvent]="updateEvent"
          [params]="params"
        ></dashboard-receipt-count>
      </div>

      </div>
    </div>


    <div class="col-sm-12 col-md-12 col-lg-6">

      <dashboard-campaign-active-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-campaign-active-chart>

    </div>


    <div class="col-sm-12 col-md-6 col-lg-4"
         *loyaPermitIf="{path:'segments', permitEnabled: ''}"
    >
      <dashboard-clients-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-clients-chart>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4"
         *loyaPermitIf="{path:'segments', permitEnabled: ''}"
    >
      <dashboard-revenue-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-revenue-chart>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-4"
         *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
    >
      <dashboard-avg-chart
        [updateEvent]="updateEvent"
        [params]="params"
      ></dashboard-avg-chart>
    </div>

  </div>

</div>
