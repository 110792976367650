'use strict';


import 'model/CouponRule';
import 'model/Coupon';
import 'model/CardModel/resource';
import 'model/ClientModel/resource';
import 'model/CouponModel/resource';

import 'directive/locationsPopup/main';
import 'directive/clientSearch/main';

import * as deleteReasonTemplate from './views/deleteReason.html';
import {CKEditorBuilder} from 'class/CKEditorBuilder/CKEditorBuilder';
import {ISSUING_TYPES} from "../../rules/view/issuingTypes";
import {StopPeriodTypes} from "../../rules/view/IssuingTypes/IssuingType";
import {CARD_STATE_NAMES} from "../../../../../app/shared/service/api/Card/Card.values";


export default ['$scope', '$state', '$uibModal', '$filter', '$interval', '$q','$stateParams',  'CouponRule', 'Coupon', 'CouponModel', 'CardModel', 'couponItem', 'rawValues', 'partnerList',  'couponRulesList', 'ClientModel', 'clientItem', 'toaster', 'couponIssueInfo',
            function ($scope, $state, $uibModal, $filter, $interval, $q, $stateParams,  CouponRule, Coupon, CouponModel, CardModel, couponItem, rawValues, partnerList,  couponRulesList, ClientModel, clientItem, toaster, couponIssueInfo) {

                $scope._t = new Coupon();
                $scope._r = new CouponRule();
                $scope.couponItem = couponItem;
                $scope.partnerList = partnerList;
                $scope.client = clientItem;
                $scope.couponIssueInfo = couponIssueInfo;
                $scope.today = new Date();
                $scope.today.setHours(0, 0, 0, 0, 0);
                $scope.rule = false;
                $scope.fromClient = (!angular.isUndefined($stateParams.clientId)) && $stateParams.clientId !== '';

                $scope.CARD_STATE_NAMES = CARD_STATE_NAMES;

                const ckeBuilder = new CKEditorBuilder()
                    .setMonospace()
                    .addRowColumnCounter();


                $scope.filterHtml     = ckeBuilder.filterHtml.bind(ckeBuilder);
                $scope.getCountFields = ckeBuilder.getCountFields.bind(ckeBuilder);
                $scope.getCountChars  = ckeBuilder.getCountChars.bind(ckeBuilder);
                $scope.maxDescriptionValidator = ckeBuilder.getMaxlenValidator(2000);
                $scope.optionsDescription = ckeBuilder.getConfig();


                var backupItem;

                var formStates = {

                    view: {
                        buttons: {
                            'edit': function () {
                                backupItem = angular.copy($scope.couponItem);
                                $scope.setFormState('edit');
                            },
                            'remove': function () {
                                var modalInstance = $uibModal.open({
                                    templateUrl    : deleteReasonTemplate,
                                    controller     : [ '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

                                        $scope.okEvent = function() {
                                            $uibModalInstance.close( $scope.reasonCoupon );
                                        };

                                        $scope.cancelEvent = function() {
                                            $uibModalInstance.dismiss();
                                        };

                                    }],
                                    size : ''
                                });

                                modalInstance.result.then(function ( reason ) {
                                    Coupon.delete({id: $scope.couponItem.id}, { reason: reason }, function() {
                                        $state.go('^.index');
                                    });
                                });

                            }
                        },
                        activeForm: false
                    },

                    edit: {
                        buttons: {

                            'save': function () {

                                if ( angular.isUndefined( $scope.couponItem.id ) ) {

                                    CouponRule.generate(
                                        { id: $scope.couponItem.couponRuleId},
                                        {
                                            "clientId"    : $scope.couponItem.clientId,
                                            "name"        : $scope.couponItem.name,
                                            "description" : $scope.couponItem.description,
                                            "issueDate"   :
                                                $scope.couponItem.issueDate.getFullYear() + '-' +
                                                ('0' + ($scope.couponItem.issueDate.getMonth() + 1 ) ).slice(-2) + '-' +
                                                ('0' + $scope.couponItem.issueDate.getDate() ).slice(-2) + ' 00:00:00'
                                        },
                                        function( createdCoupon ) {
                                            $scope.couponItem = new Coupon(createdCoupon);
                                            $state.go('^.view', {id: $scope.couponItem.id}, {reload: true});
                                        }, function (data) {
                                            toaster.error( 'Ошибка сохранения', data.data.text );
                                        }
                                    );

                                    // $scope.couponItem

                                    //$scope.couponItem.$create(function (response) {

                                        //$state.go('^.view', {id: response.id}, {reload: true});

                                   // });

                                } else {

                                    $scope.couponItem.$update({id: $scope.couponItem.id}, function () {

                                        $scope.setFormState('view');

                                    });

                                }

                            },

                            'cancel': function () {

                                if ( angular.isUndefined( $scope.couponItem.id ) ) {

                                    if ( angular.isUndefined( $stateParams.clientId ) ) {
                                        $state.go('^.index');
                                    } else {
                                        $state.go('frontend.crm.clients.view', { id: $stateParams.clientId  });
                                    }

                                } else {
                                    if (backupItem) {
                                        $scope.couponItem = angular.copy(backupItem);
                                    }

                                    $scope.setFormState('view');
                                }

                            }
                        },
                        activeForm: true

                    }

                };

                $scope.setFormState = function (state) {

                    if (formStates[state] !== undefined) {
                        $scope.curState = formStates[state];
                        $scope.curState.stateName = state;


                    } else
                        $scope.curState = false;

                };

                $scope.unsetValue = function( path ) {

                    var findedPath = $scope;
                    path = path.split('.');

                    for ( var i = 0; i < path.length - 1; i++ ) {
                        if (  angular.isUndefined( findedPath[path[i]] ) ) {
                            return false;
                        }

                        findedPath = findedPath[path[i]];
                    }

                    if ( angular.isUndefined( findedPath[ path[ path.length - 1 ] ] ) ) {
                        return false;
                    } else {
                        delete findedPath[ path[ path.length - 1 ] ];
                        return true;
                    }

                };
                $scope.getValue = function( path ) {
                    try{
                        return $scope.$eval(path);
                    } catch(e) {
                        return undefined;
                    }
                };

                $scope.options = {
                    couponPriceType: 'currency'
                };

                backupItem = angular.copy($scope.couponItem);

                $scope.values = angular.copy(rawValues);

                var updateValues = function( rawIndex ) {

                    return function(newVal, oldVal) {

                        if ( angular.isUndefined(newVal) ) {
                            return;
                        }

                        if ( newVal && angular.isObject( rawValues[rawIndex][newVal] ) ) {
                            $scope.values[rawIndex] = rawValues[rawIndex][newVal];

                            if ( Object.keys( $scope.values[rawIndex] ).length > 0 && Object.keys($scope.values[rawIndex]).indexOf( $scope.$eval('couponItem.' + rawIndex) ) < 0 ) {
                                $scope.$eval('couponItem.' + rawIndex + ' = value', { value:  Object.keys($scope.values[rawIndex])[0] } );
                            }

                        }

                    };

                };

                $scope.timeWords = function( type, value ) {

                    var types = {

                        'day'   : [ "день"  , "дня"   , "дней" ],
                        'week'  : [ "неделя", "недели" , "недель" ],
                        'month' : [ "месяц" , "месяца" , "месяцев" ],
                        'year'  : [ "год"   , "года"   , "лет" ]

                    };

                    if (!value && value !== 0 ) {
                        return types[type][0];
                    }

                    value = Math.round(value);

                    switch (true) {

                        case ["11","12","13","14","15","16","17","18","19"].indexOf( value.toString().slice(-2) ) >= 0: return types[type][2];

                        case ["0","5","6","7","8","9"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][2];

                        case ["1"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][0];

                        case ["2", "3", "4"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][1];

                    }

                };

                $scope.setFormState( angular.isUndefined( $scope.getValue( 'couponItem.id') )  ? 'edit'  : 'view'  );


                $scope.$watch('couponItem.couponRuleId', function( newVal ) {

                    if ( angular.isUndefined(newVal) || newVal === '' ){
                        return;
                    }

                    $scope.rule = CouponRule.get({ id: newVal}, function(res) {
                        $scope.currentIssueType = new ISSUING_TYPES[res.couponRule.issueSettings.issuingType ]( res.couponRule );
                        $scope.currentCouponKind = $scope.currentIssueType.getCurrentCouponKind();
                    });

                });


                $scope.isRuleDateExpired = () => {
                  if ( ! ($scope.rule.couponRule &&
                          $scope.rule.couponRule.issueSettings &&
                          $scope.rule.couponRule.issueSettings.issueUntil) )
                    return false;
                  let tmp = new Date( Date.parse($scope.rule.couponRule.issueSettings.issueUntil));
                  tmp.setDate(tmp.getDate() + 1)
                  return  tmp < new Date();

                };



                $scope.options.issueDateManual = false;
                $scope.$watchGroup( [
                    'rule.couponRule.id',
                    'rule.couponRule.issueSettings.issuingType'
                ] , function( data ) {

                    if ( !$scope.curState.activeForm || angular.isUndefined(data[0]) ) {
                        return;
                    }

                    $scope.options.issueDateManual = false;
                    switch( data[1] ) {

                        case 'pos':        // "Выдача на кассе"
                            $scope.couponItem.issueDate = new Date();
                            break;
                        case 'public':     // "Публикация в открытых источниках"
                        case 'external':   // "По запросу из внешней ИС"

                            $scope.couponItem.issueDate = new Date();

                            /*
                                if ( !( $scope.rule.couponRule.issueSettings.startDate instanceof Date ) ) {
                                    return ;
                                }

                                $scope.couponItem.issueDate =  new Date( $scope.rule.couponRule.issueSettings.startDate.getTime() );
                            */
                            break;

                        default:
                            //  "Ручной ввод"
                            //  "Триггерная цепочка"

                            $scope.couponItem.issueDate = new Date();
                            $scope.options.issueDateManual = false;

                    }

                } );

                $scope.$watchGroup( [
                    'rule.couponRule.id',
                    'rule.couponRule.issueSettings.startPeriod',
                    'couponItem.issueDate'
                ] , function( data ) {

                    if ( !$scope.curState.activeForm || angular.isUndefined(data[0]) ) {
                        return;
                    }


                    switch( data[1] ) {


                        case 'fromIssue':
                            if ( !( $scope.couponItem.issueDate instanceof Date ) ) {
                                return ;
                            }

                            $scope.couponItem.fromDate = new Date( $scope.couponItem.issueDate.getTime() );
                            break;

                        case 'fromDate':

                            if ( !( $scope.rule.couponRule.issueSettings.startDate instanceof Date ) ) {
                                return ;
                            }

                            $scope.couponItem.fromDate = new Date( $scope.rule.couponRule.issueSettings.startDate.getTime() );
                            break;

                        case 'scheduled':

                            if ( !( $scope.couponItem.issueDate instanceof Date ) ) {
                                return ;
                            }

                            $scope.couponItem.fromDate = new Date( $scope.couponItem.issueDate.getTime() );

                            switch( $scope.rule.couponRule.issueSettings.startScheduled.dimension ) {

                                case "day":

                                    $scope.couponItem.fromDate.setDate(
                                        $scope.couponItem.fromDate.getDate() +
                                        $scope.rule.couponRule.issueSettings.startScheduled.value
                                    );

                                    break;
                                case "week":

                                    $scope.couponItem.fromDate.setDate(
                                        $scope.couponItem.fromDate.getDate() +
                                        ( $scope.rule.couponRule.issueSettings.startScheduled.value * 7 )
                                    );

                                    break;
                                case "month":
                                    $scope.couponItem.fromDate.setMonth(
                                        $scope.couponItem.fromDate.getMonth() +
                                        $scope.rule.couponRule.issueSettings.startScheduled.value
                                    );
                                    break;
                                case "year":
                                    $scope.couponItem.fromDate.setFullYear(
                                        $scope.couponItem.fromDate.getFullYear() +
                                        $scope.rule.couponRule.issueSettings.startScheduled.value
                                    );
                                    break;

                            }

                            break;

                    }

                } );

                $scope.$watchGroup( [
                    'rule.couponRule.id',
                    'rule.couponRule.issueSettings.stopPeriod',
                    'couponItem.issueDate',
                    'couponItem.fromDate'
                ] , function( data ) {

                    if ( !$scope.curState.activeForm || angular.isUndefined(data[0]) ) {
                        return;
                    }

                    switch( data[1] ) {

                        case 'fromDate':

                            if ( ! ( $scope.rule.couponRule.issueSettings.stopDate instanceof Date ) ) {
                                return ;
                            }

                            $scope.couponItem.tillDate = new Date( $scope.rule.couponRule.issueSettings.stopDate.getTime() );
                            break;

                        case 'scheduled':

                            if (
                                $scope.rule.couponRule.issueSettings &&
                                $scope.rule.couponRule.issueSettings.startPeriod === 'fromIssue'
                            ) {
                                data[3] =  data[2];
                            }

                            if ( ! ( data[3] instanceof Date ) ) {
                                return ;
                            }

                            $scope.couponItem.tillDate = new Date( data[3].getTime() );


                            switch( $scope.rule.couponRule.issueSettings.stopScheduled.dimension ) {

                                case "day":

                                    $scope.couponItem.tillDate.setDate(
                                        $scope.couponItem.tillDate.getDate() +
                                        $scope.rule.couponRule.issueSettings.stopScheduled.value - 1
                                    );

                                    break;
                                case "week":

                                    $scope.couponItem.tillDate.setDate(
                                        $scope.couponItem.tillDate.getDate() +
                                        ( $scope.rule.couponRule.issueSettings.stopScheduled.value * 7 )
                                    );

                                    break;
                                case "month":
                                    $scope.couponItem.tillDate.setMonth(
                                        $scope.couponItem.tillDate.getMonth() +
                                        $scope.rule.couponRule.issueSettings.stopScheduled.value
                                    );
                                    break;
                                case "year":
                                    $scope.couponItem.tillDate.setFullYear(
                                        $scope.couponItem.tillDate.getFullYear() +
                                        $scope.rule.couponRule.issueSettings.stopScheduled.value
                                    );
                                    break;

                            }

                            break;

                    }

                } );


                $scope.canBeDeleted = false;
                $scope.$watchGroup( [
                    'couponItem.id',
                    'rule.couponRule.issueSettings.issuingType',
                    'couponItem.state',
                    'couponItem.couponType'
                ] , function( data ) {

                    if ( angular.isUndefined( data[0] ) ||
                         angular.isUndefined( data[1] ) ||
                         angular.isUndefined( data[2] ) ||
                         data[3] === 'archive'
                        ) {
                        $scope.canBeDeleted = false;
                        return;
                    }
/*
                    $scope.canBeDeleted = false;

                    if ( !angular.isUndefined( data[0] ) &&
                          data[1] === 'manual' &&
                          [  "active", "partialReturn", "fullyReturn" ].indexOf( data[2] ) >= 0
                    ) {
                        $scope.canBeDeleted = true;
                    }*/
                    $scope.canBeDeleted = true;

                });

                    /*   $scope.today = false;
                       $scope.$watchGroup( [
                           'couponItem.fromDate',
                           'couponItem.tillDate'
                       ] , function( data ) {

                           if ( angular.isUndefined(data[0]) || angular.isUndefined(data[1]) ) {
                               return;
                           }

                           $scope.dateProgress = data[1]

                       } );
       */
                $scope.isPresent = ( value ) => typeof value !== "undefined";

                $scope.$watch( 'couponItem.clientId' , function( data ) {

                    if ( angular.isUndefined(data) ) {
                        return;
                    }

                    if (  !$scope.client || !$scope.client.client || $scope.client.client.id !== data ) {

                        $scope.client = ClientModel.get({ id: data });
                        var cardParams = {};
                        cardParams['filter.clientId'] = data;
                        $scope.client.cards = CardModel.queryCards(cardParams);

                    }

                } );


                $scope.transactions = [];
                $scope.$watch('couponItem.id', function( newVal ) {

                    if ( angular.isUndefined(newVal) || newVal === '' ){
                        $scope.transactions = [];
                        return;
                    }

                    $scope.resetPages();


                });

                var promiseAutoLoad: any = false,
                    end = false,
                    drop  = 0,
                    limit = 20;

                $scope.resetPages = function() {


                    if (promiseAutoLoad && promiseAutoLoad.$promise) {

                        return promiseAutoLoad.$promise.then(function() {
                            drop = 0;
                            $scope.couponsRules = [];

                            promiseAutoLoad = false;
                            end = false;

                            return $scope.queryNewPage();

                        }, function() {
                            drop = 0;
                            $scope.couponsRules = [];

                            promiseAutoLoad = false;
                            end = false;

                            return $scope.queryNewPage();

                        });

                    } else {

                        drop = 0;
                        $scope.couponsRules = [];

                        promiseAutoLoad = false;
                        end = false;

                        return $scope.queryNewPage();
                    }
                };

                $scope.queryNewPage = function() {

                    if ( !promiseAutoLoad && !end && !angular.isUndefined($scope.couponItem.id)) {
                        $scope.$emit('$startButterbar');
                        promiseAutoLoad = true;

                        var qParam = {
                            'id'          : $scope.couponItem.id,
                            'pager.drop'  : $scope.transactions.length,
                            'pager.limit' :limit
                        };

                        CouponModel.transactions( qParam, function(data) {

                            $scope.$emit('$stopButterbar');
                            if ( data.length === 0 ) {
                                end = true;
                                return;
                            }

                            $scope.transactions = $scope.transactions.concat(data) ;
                            promiseAutoLoad = false;

                        }, function() {

                            $scope.$emit('$stopButterbar');
                            promiseAutoLoad = false;

                        });
                    }
                };


                $scope.filterByName = (filter)  => {
                  return (item) => {
                    return  !filter ? true : item?.couponRule?.name?.toLowerCase()?.includes(filter.toLowerCase());
                  };
                }


            }] ;
