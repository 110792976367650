import {ReferrerIndexPageComponent} from "./index/referrer-index-page.component";
import {ReferrerViewPageComponent} from "./view/referrer-view-page.component";
import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule, NgbModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {TranslocoRootModule} from "../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {ReferrerLogHistory} from "./components/referrer-log-history/referrer-log-history.component";
import {NgModule} from "@angular/core";
import {ReferrerEditAccountComponent} from "./components/referrer-edit/referrer-edit-account.component";
import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../shared/directive/directive.module";
import {ReferrerJournalPageComponent} from "./journal/referrer-journal-page.component";
import {ReferrerLogHistoryChangesComponent} from "./components/referrer-log-history/component/referrer-log-history-changes.component";
import {HighchartsChartModule} from "highcharts-angular";
import {ReferrerGraphAccountComponent} from "./components/referrer-graph/referrer-graph-account.component";
import {SearchInputModule} from "../../shared/component/search-input/search-input.module";

@NgModule({
  declarations: [
    ReferrerIndexPageComponent,
    ReferrerViewPageComponent,
    ReferrerEditAccountComponent,
    ReferrerJournalPageComponent,
    ReferrerLogHistoryChangesComponent,
    ReferrerLogHistory,
    ReferrerGraphAccountComponent
  ],
    imports: [
        BrowserModule,
        UpgradeModule,
        NgSelectModule,
        FormsModule,
        NgbAlertModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbPopoverModule,
        NgbModalModule,
        NgbModule,
        HttpClientModule,
        TranslocoRootModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        HighchartsChartModule,
        SearchInputModule
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class ReferrerModule {

  constructor() {}

}
