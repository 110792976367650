/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { InternalMethodsApiConfiguration as __Configuration } from '../internal-methods-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ConfirmCheckEvent } from '../models/confirm-check-event';
import { ConfirmRecord } from '../models/confirm-record';
@Injectable({
  providedIn: 'root',
})
class InternalMethodsApiService extends __BaseService {
  static readonly streamConfirmPath = '/stream/confirm';
  static readonly findCheckByIdPath = '/stream/confirm/findCheck/{checkId}/{locationId}';
  static readonly collectOriginalCheckByIdPath = '/stream/confirm/collectOriginalCheck/{originalId}/{locationId}';
  static readonly cleanModulePath = '/stream/confirm/clean/{moduleName}';
  static readonly kickActorPath = '/doAction/{name}';
  static readonly recalculateDataPath = '/recalculateData/{clientId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Опубликовать в очереди чек с ID
   *
   * Опубликовать в очереди чек с ID
   * @param body Тело запроса
   */
  streamConfirmResponse(body: ConfirmCheckEvent): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stream/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Опубликовать в очереди чек с ID
   *
   * Опубликовать в очереди чек с ID
   * @param body Тело запроса
   */
  streamConfirm(body: ConfirmCheckEvent): __Observable<null> {
    return this.streamConfirmResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Найти чек по ID
   *
   * Найти чек по ID
   * @param params The `InternalMethodsApiService.FindCheckByIdParams` containing the following parameters:
   *
   * - `locationId`: ID магазина
   *
   * - `checkId`: ID чека (строковый)
   *
   * @return Чек найден
   */
  findCheckByIdResponse(params: InternalMethodsApiService.FindCheckByIdParams): __Observable<__StrictHttpResponse<ConfirmRecord>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stream/confirm/findCheck/${encodeURIComponent(String(params.checkId))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfirmRecord>;
      })
    );
  }
  /**
   * Найти чек по ID
   *
   * Найти чек по ID
   * @param params The `InternalMethodsApiService.FindCheckByIdParams` containing the following parameters:
   *
   * - `locationId`: ID магазина
   *
   * - `checkId`: ID чека (строковый)
   *
   * @return Чек найден
   */
  findCheckById(params: InternalMethodsApiService.FindCheckByIdParams): __Observable<ConfirmRecord> {
    return this.findCheckByIdResponse(params).pipe(
      __map(_r => _r.body as ConfirmRecord)
    );
  }

  /**
   * Собираем данные по чеку продажи
   *
   * Собираем данные по чеку продажи
   * @param params The `InternalMethodsApiService.CollectOriginalCheckByIdParams` containing the following parameters:
   *
   * - `originalId`: ID оригинального чека (строковый)
   *
   * - `locationId`: ID магазина
   *
   * @return Чек найден
   */
  collectOriginalCheckByIdResponse(params: InternalMethodsApiService.CollectOriginalCheckByIdParams): __Observable<__StrictHttpResponse<ConfirmRecord>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stream/confirm/collectOriginalCheck/${encodeURIComponent(String(params.originalId))}/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfirmRecord>;
      })
    );
  }
  /**
   * Собираем данные по чеку продажи
   *
   * Собираем данные по чеку продажи
   * @param params The `InternalMethodsApiService.CollectOriginalCheckByIdParams` containing the following parameters:
   *
   * - `originalId`: ID оригинального чека (строковый)
   *
   * - `locationId`: ID магазина
   *
   * @return Чек найден
   */
  collectOriginalCheckById(params: InternalMethodsApiService.CollectOriginalCheckByIdParams): __Observable<ConfirmRecord> {
    return this.collectOriginalCheckByIdResponse(params).pipe(
      __map(_r => _r.body as ConfirmRecord)
    );
  }

  /**
   * Очистить и запустить пересчет данных модуля
   *
   * Очистить и запустить пересчет данных модуля
   * @param params The `InternalMethodsApiService.CleanModuleParams` containing the following parameters:
   *
   * - `moduleName`: Название модуля
   *
   * - `params.till`: Дата "по"
   *
   * - `params.resetOds`: Флаг пересчета чеков
   *
   * - `params.part`: Часть для пересчета
   *
   * - `params.from`: Дата "с"
   *
   * - `params.cleanData`: Флаг очистки данных
   *
   * @return Пересчет запущен
   */
  cleanModuleResponse(params: InternalMethodsApiService.CleanModuleParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.paramsTill != null) __params = __params.set('params.till', params.paramsTill.toString());
    if (params.paramsResetOds != null) __params = __params.set('params.resetOds', params.paramsResetOds.toString());
    if (params.paramsPart != null) __params = __params.set('params.part', params.paramsPart.toString());
    if (params.paramsFrom != null) __params = __params.set('params.from', params.paramsFrom.toString());
    if (params.paramsCleanData != null) __params = __params.set('params.cleanData', params.paramsCleanData.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stream/confirm/clean/${encodeURIComponent(String(params.moduleName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Очистить и запустить пересчет данных модуля
   *
   * Очистить и запустить пересчет данных модуля
   * @param params The `InternalMethodsApiService.CleanModuleParams` containing the following parameters:
   *
   * - `moduleName`: Название модуля
   *
   * - `params.till`: Дата "по"
   *
   * - `params.resetOds`: Флаг пересчета чеков
   *
   * - `params.part`: Часть для пересчета
   *
   * - `params.from`: Дата "с"
   *
   * - `params.cleanData`: Флаг очистки данных
   *
   * @return Пересчет запущен
   */
  cleanModule(params: InternalMethodsApiService.CleanModuleParams): __Observable<{}> {
    return this.cleanModuleResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `InternalMethodsApiService.KickActorParams` containing the following parameters:
   *
   * - `name`: Scheduler's ID
   *
   * - `parameter`:
   */
  kickActorResponse(params: InternalMethodsApiService.KickActorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.parameter != null) __params = __params.set('parameter', params.parameter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/doAction/${encodeURIComponent(String(params.name))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `InternalMethodsApiService.KickActorParams` containing the following parameters:
   *
   * - `name`: Scheduler's ID
   *
   * - `parameter`:
   */
  kickActor(params: InternalMethodsApiService.KickActorParams): __Observable<null> {
    return this.kickActorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Пересчитать данные профилей на основе чеков
   *
   * Пересчитать данные профилей на основе чеков
   * @param params The `InternalMethodsApiService.RecalculateDataParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `cleanData`: Флаг очистки данных
   */
  recalculateDataResponse(params: InternalMethodsApiService.RecalculateDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.cleanData != null) __params = __params.set('cleanData', params.cleanData.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/recalculateData/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Пересчитать данные профилей на основе чеков
   *
   * Пересчитать данные профилей на основе чеков
   * @param params The `InternalMethodsApiService.RecalculateDataParams` containing the following parameters:
   *
   * - `clientId`: ID клиента
   *
   * - `cleanData`: Флаг очистки данных
   */
  recalculateData(params: InternalMethodsApiService.RecalculateDataParams): __Observable<null> {
    return this.recalculateDataResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module InternalMethodsApiService {

  /**
   * Parameters for findCheckById
   */
  export interface FindCheckByIdParams {

    /**
     * ID магазина
     */
    locationId: number;

    /**
     * ID чека (строковый)
     */
    checkId: string;
  }

  /**
   * Parameters for collectOriginalCheckById
   */
  export interface CollectOriginalCheckByIdParams {

    /**
     * ID оригинального чека (строковый)
     */
    originalId: string;

    /**
     * ID магазина
     */
    locationId: number;
  }

  /**
   * Parameters for cleanModule
   */
  export interface CleanModuleParams {

    /**
     * Название модуля
     */
    moduleName: string;

    /**
     * Дата "по"
     */
    paramsTill?: string;

    /**
     * Флаг пересчета чеков
     */
    paramsResetOds?: boolean;

    /**
     * Часть для пересчета
     */
    paramsPart?: string;

    /**
     * Дата "с"
     */
    paramsFrom?: string;

    /**
     * Флаг очистки данных
     */
    paramsCleanData?: boolean;
  }

  /**
   * Parameters for kickActor
   */
  export interface KickActorParams {

    /**
     * Scheduler's ID
     */
    name: 'recalcProfile' | 'deleteOverdueBonus' | 'accountOlap' | 'clientsOlap' | 'communicationOlap' | 'customOlap' | 'groupRefresher' | 'reindexLimits' | 'clientTransactionHandler' | 'segmentTransactionHandler' | 'elasticTrimCheck' | 'elasticTrimChangelog' | 'overdueAccountRecord' | 'loccitaneCRMImport' | 'loccitaneDiscountsExport' | 'loccitaneRetailCRMExport' | 'loccitaneEventExport' | 'loccitaneCouponMapping';
    parameter?: string;
  }

  /**
   * Parameters for recalculateData
   */
  export interface RecalculateDataParams {

    /**
     * ID клиента
     */
    clientId: number;

    /**
     * Флаг очистки данных
     */
    cleanData?: boolean;
  }
}

export { InternalMethodsApiService }
