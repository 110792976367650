import { RoleType } from "./roles";
import { FieldTypes } from "./fieldTypes";

export enum UserCapabilitiesTypes {
    CreateUser = "CreateUser",
    EditAccount = ""
}

// Запретительный принцип. Кто в списке тому нельзя.
export const UserCapabilityList = {
    [RoleType.Administrator]: {
      'crm.cards.pin' : { [FieldTypes.FIELD_ENABLED]: true },
      'modules.levelbychecksplugin.edit' : { [FieldTypes.FIELD_ENABLED]: true },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true, update: true, delete: true},
      },
    },
    [RoleType.Operator]: {
        'coupons.rule.issuingType': { [FieldTypes.FIELD_FILTER]: []   },
        'editions.editions' : {
          'create' : { [FieldTypes.FIELD_ENABLED]: false },
          'edit'   :  { [FieldTypes.FIELD_ENABLED]: false },
          'delete' :  { [FieldTypes.FIELD_ENABLED]: false },
        },
        'editions.certificate' : {
          'create' : { [FieldTypes.FIELD_ENABLED]: false }
        },
        'modules.levelbychecksplugin.edit' : { [FieldTypes.FIELD_ENABLED]: true },
        'modules.housewarming': {
          [FieldTypes.FIELD_QUERY]: { create: true },
        },
    },
    [RoleType.InputOperator]: {
      'referrer' : { [FieldTypes.FIELD_ENABLED]: false },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true },
      },
    },
    [RoleType.GiftMarketingManager]: {
      'referrer' : {
        [FieldTypes.FIELD_ENABLED]: false,
        [FieldTypes.FIELD_QUERY]: {
          'create'  : false,
          'delete' : false,
          'update' : false,
        }
      },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true, update: true },
      },
    },
    [RoleType.MarketingManager]: {
      'modules.levelbychecksplugin.edit' : { [FieldTypes.FIELD_ENABLED]: true },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true, update: true },
      },
    },
    [RoleType.TopManager]: {
      'referrer' : { [FieldTypes.FIELD_ENABLED]: false },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true, update: true },
      },

    },
    [RoleType.HeadOperator]: {
      'editions.editions' : {
        'create' : { [FieldTypes.FIELD_ENABLED]: false },
        'edit'   :  { [FieldTypes.FIELD_ENABLED]: false },
        'delete' :  { [FieldTypes.FIELD_ENABLED]: false },
      },
      'editions.certificate' : {
        'create' : { [FieldTypes.FIELD_ENABLED]: false }
      },
      'modules.housewarming': {
        [FieldTypes.FIELD_QUERY]: { create: true  }
      },

    },
    [RoleType.Infocenter]: {
        'crm.client'   : { [FieldTypes.FIELD_QUERY]: {
            create  : false,
            'delete' : false,
            'update' : true,
        }} ,

        'crm.accounts.list' : { [FieldTypes.FIELD_ENABLED]: false} ,
        'crm.accounts.attach' : { [FieldTypes.FIELD_ENABLED]: false},
        'crm.accounts.mergeOnlyProfile' : { [FieldTypes.FIELD_ENABLED]: true } ,

        'model.account' : { [FieldTypes.FIELD_QUERY]: { update: false } },

        'crm.cards.attach' : { [FieldTypes.FIELD_ENABLED]: false },
        'model.card' : { [FieldTypes.FIELD_QUERY]: { update: false } },

        'coupons.coupons.list' : { [FieldTypes.FIELD_ENABLED]: false },
        'model.coupon' : { [FieldTypes.FIELD_QUERY]: {
            create: false,
            'delete': true
        } },

        'modules.dibsactionprofileplugin.edit': { [FieldTypes.FIELD_ENABLED]: false },

        'modules.housewarming': {
          [FieldTypes.FIELD_QUERY]: { create: true, update: true  }
        },

        'editions.editions' : {
          'create' : { [FieldTypes.FIELD_ENABLED]: false },
          'edit'   :  { [FieldTypes.FIELD_ENABLED]: false }
        },

        'editions.certificate' : {
          'create' : { [FieldTypes.FIELD_ENABLED]: false }
        },
        'referrer' : {
          [FieldTypes.FIELD_ENABLED]: true,
          [FieldTypes.FIELD_QUERY]: {
            'create'  : false,
            'delete' : false,
            'update' : false,
          }
        },
        'referrer.journal' : {
          [FieldTypes.FIELD_ENABLED]: false
        },

    },
    [RoleType.Api]: {
      'modules.levelbychecksplugin.edit' : { [FieldTypes.FIELD_ENABLED]: true },
    }
};
