
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";

@Component({
  selector: 'campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styles: [
    `
      .easy-chart-block {
        margin: 0 auto;
        display: block;
      }
    `
  ],
  providers: [
    CampaignService
  ]
})
export class CampaignPreviewComponent implements OnInit, OnChanges {

  @Input() campaignId;
  @Input() campaignItem;

  public loader = new LoaderHandlerClass();

  public realValuesCampaign;

  public chartConfig: any = {

    credits: {
      enabled: false
    },

    options: {
      chart: {
        zoomType: 'xy'
      },
      tooltip: {
        shared: true
      },
      legend: {
        // backgroundColor: '#321322'
      },
      line: {
        pointPlacement: 'between',
        "stacking": "normal"
      }
    },

    series: [],

    title: {
      text: ''
    },

    xAxis: [{
      categories: [],
      crosshair: true,
      type: 'datetime',
      minTickInterval: 24 * 3600 * 1000,
      showEmpty: true,
      labels: {
        format: '{value:%d.%m.%Y}'

      },
      plotLines: [{
        color: LOYA_CONFIG.color.info,
        dashStyle: 'longdashdot',
        value: 0,
        width: 2,
        label: {
          text: 'Начало кампании'
        }

      }]


    }],
    yAxis: [{
      labels: {
        format: '{value} р.',
        style: {
          color: LOYA_CONFIG.color.info
        }
      },
      title: false,


      plotLines: []

    }, {
      gridLineWidth: 0,
      title: false,
      plotLines: [],

      labels: {
        format: '{value}',
        style: {
          color: LOYA_CONFIG.color.success
        }
      },
      opposite: true

    }],


    size: {
      height: 500
    }
  };
  public Highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;

  public easyPieConfig = {
    title: false,

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      backgroundColor: null,
      plotShadow: false,
      borderWidth: 0,
      type: 'pie',
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      width: 110,
      height: 110,
      style: {
        overflow: 'visible',
        margin: '0 auto'
      },

      skipClone: true
    },

    credits: {
      enabled: false
    },

    subtitle: {
      useHTML: true,
      text: '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        fontWeight:700,
        fontSize: '1.2em'
      },
      y: 5
    },

    legend: {
      enabled: false
    },

    tooltip:false,

    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '90%',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        dataLabels: {
          enabled: false,
          crop: false,
          distance: '-10%',
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        }
      }
    },
    colors: [LOYA_CONFIG.color.light, LOYA_CONFIG.color.light],
    series: [
      {
        type: 'pie',
        name: '-',
        data: [ ]
      }
    ]
  };
  public easyPieConfigs: any = {
    email: false,
    sms: false,
    check: false,
    viber: false,
  }

  constructor(
    public campaignService: CampaignService
  ) {
  }

  ngOnInit() {
    this.getCampaign();
  }


  ngOnChanges(changes) {

    if (changes['campaignId']) {
      this.getCampaign();
    }

  }

  getCampaign() {

    if (!this.campaignId) {
      this.realValuesCampaign = undefined;
      this.realValuesCampaign = undefined;
      return;
    }

    this.campaignService
        .campaignDetails$(this.campaignId)
        .pipe(
          this.loader.waitLoading('details')
        )
        .subscribe( result =>{
          this.realValuesCampaign = result;
          this.calculateChart();
          this.calculateEasyChart();
        })

  }

  calculateChart() {

    if (!this.campaignItem)
      return;

    //Legacy Code

    this.chartConfig.series = [];
    this.chartConfig.xAxis[0].categories = [];
    this.chartConfig.yAxis[0].plotLines = [];

    if (this.campaignItem?.campaign?.campaignTime && this.campaignItem?.campaign?.campaignTime.startDate) {

      let campaignStartDate = new Date( this.campaignItem?.campaign?.campaignTime?.startDate + 'T00:00:00Z'),
         campaignDeliveryDate = new Date( this.campaignItem?.campaign?.campaignTime?.startDate  + 'T00:00:00Z');

      campaignStartDate?.setTime(campaignStartDate?.getTime());
      campaignDeliveryDate?.setTime(campaignStartDate?.getTime());

      this.chartConfig.xAxis[0].plotLines[0].value = campaignStartDate?.getTime() + 3 * 60 * 60 * 1000;
      campaignDeliveryDate.setDate(campaignDeliveryDate?.getDate() - this.campaignItem?.campaign?.campaignTime.informationDate);
      this.chartConfig.xAxis[0].min = campaignDeliveryDate?.getTime();

      for (;
        campaignDeliveryDate.getTime() <= campaignStartDate?.getTime();
        campaignDeliveryDate.setDate(campaignDeliveryDate?.getDate() + 1)
      ) {
        this.chartConfig.xAxis[0].categories.push(
          new Date([campaignDeliveryDate?.getMonth(),
            campaignDeliveryDate?.getDate(),
            campaignDeliveryDate?.getFullYear()
          ].join("-")).getTime()
        );
      }
    }


    this.chartConfig.yAxis[0].plotLines.push({
      value: parseFloat(this.realValuesCampaign?.planValues?.clientSellingTotal),
      color: LOYA_CONFIG.color.primary,
      dashStyle: "Dash",
      width: 2,
      zIndex: 0,
      label: {text: 'План общей суммы продаж', align: "right"}
    });

    this.chartConfig.yAxis[1].plotLines.push({
      value: this.realValuesCampaign?.planValues.responseCount,
      color: LOYA_CONFIG.color.success,
      dashStyle: "Dash",
      width: 2,
      zIndex: 0,
      label: {text: 'План клиентов'}
    });

    this.chartConfig.yAxis[0].plotLines.push({
      value: parseFloat(this.realValuesCampaign?.planValues.totalCost),
      color: LOYA_CONFIG.color.primary,
      dashStyle: "Dash",
      width: 2,
      zIndex: 0,
      label: {text: 'План расходов', align: "left"}
    });


    var updatedXAxis = false;
    this.realValuesCampaign?.reports?.forEach( (reportItem, key) => {


      var dataItem: any = {
        name: reportItem.report,
        data: []
      };


      switch (reportItem.report) {

        case "revenue":
          dataItem.name = "Общая сумма продаж";
          dataItem.zIndex = 2;
          dataItem.type = "areaspline";
          dataItem.connectNulls = false;
          dataItem.color = LOYA_CONFIG.color.warning;
          dataItem.legendIndex = 10;
          dataItem.marker = {
            radius: 3,
            symbol: "circle"
          };
          break;

        case "flow":
          dataItem.name = "Расходы";
          dataItem.color = LOYA_CONFIG.color.info;
          dataItem.zIndex = 4;
          dataItem.legendIndex = 20;
          dataItem.marker = {
            radius: 5,
            symbol: "circle"
          };
          break;

        case "clients":
          dataItem.name = "Клиенты";
          dataItem.type = "line";
          dataItem.color = LOYA_CONFIG.color.orange;
          dataItem.yAxis = 1;
          dataItem.zIndex = 5;
          dataItem.legendIndex = 40;
          // dataItem.pointWidth = reportItem.info.length/2;
          dataItem.maxPointWidth = 50;
          dataItem.marker = {
            radius: 5,
            symbol: "triangle"
          };
          break;

        case "receipt":
          dataItem.name = "Отклики";
          //   dataItem.type = "column";
          dataItem.type = "line";
          dataItem.color = LOYA_CONFIG.color.success;
          dataItem.yAxis = 1;
          dataItem.legendIndex = 50;


          // dataItem.pointWidth = reportItem.info.length/2;
          dataItem.lineWidth = 1;
          dataItem.marker = {
            radius: 5,
            symbol: "triangle"
            //lineWidth: 200
          };
          dataItem.zIndex = 5;

          break;

        default:
          return;

      }
      let x;
      for (var i in reportItem.info) {

        x = new Date(reportItem.info[i].yearId, reportItem.info[i].monthId - 1,
          reportItem.info[i].dayId).getTime();

        x = x + 3 * 60 * 60 * 1000;

        if (!updatedXAxis) {
          this.chartConfig.xAxis[0].categories.push(x);
        }

        dataItem.data.push([x, reportItem.info[i].value]);

      }

      this.chartConfig.series.push(dataItem);
      if (!updatedXAxis)
        updatedXAxis = true;

    });

    this.updateFlag = true;
  }

  calculateEasyChart() {

    if (!this.campaignItem)
      return;

    let colors = {
      'email': LOYA_CONFIG.color.primary,
      'sms': LOYA_CONFIG.color.success,
      'check': LOYA_CONFIG.color.warning,
      'viber': LOYA_CONFIG.color.danger
    }

    Object.keys(colors).forEach( key => {

      let factPercent

      this.easyPieConfigs[key] = Object.assign({}, this.easyPieConfig);
      this.easyPieConfigs[key].subtitle = Object.assign({}, this.easyPieConfigs[key].subtitle);
      factPercent = (this.getCommunicationsType('[key]')?.filled > 0 ) ? ( ( this.getCommunicationsType(key)?.delivered / this.getCommunicationsType(key)?.filled ) * 100 ).toFixed(2) : 100;
      this.easyPieConfigs[key].colors = [colors[key], LOYA_CONFIG.color.light];
      this.easyPieConfigs[key].series = [ { type: 'pie', data: [ ['', factPercent], ['', 100 - factPercent] ] } ]
      this.easyPieConfigs[key].subtitle.text = factPercent === 100 ? '' : factPercent + '%'

    });

    this.updateFlag = true;
  }


  getTotalClient(){
    return {
      real: this?.realValuesCampaign?.realValues && this?.realValuesCampaign?.realValues?.clientCount > 0 ? this?.realValuesCampaign?.realValues?.receiptSelling / this?.realValuesCampaign?.realValues?.clientCount : 0,
      plan: parseFloat(this?.realValuesCampaign?.planValues?.clientSelling) || 0,
    }
  }

  getSellingOneClient() {
    return {
      real: ( this?.realValuesCampaign?.realValues && parseFloat(this?.realValuesCampaign?.realValues?.clientCount) ) ? parseFloat(this?.realValuesCampaign?.realValues?.totalCosts) / this?.realValuesCampaign?.realValues?.clientCount : 0,
      plan: parseFloat(this?.realValuesCampaign?.planValues?.totalByClient) || 0,
    }
  }

  getCommunicationsType(type) {
    return this.realValuesCampaign?.communications?.find( i => i.channelType === type);
  }

  getResourceType(type) {
    return this.realValuesCampaign?.resource?.find( i => i.resource === type);
  }


}
