<span *ngIf="!isLoading">
    <label for="imageUploaderInput" class="pointer">
        <input type="file"
               id="imageUploaderInput"
               accept="image/*"
               multiple="multiple"
               style="display:none"
               (change)="loadFiles($event?.currentTarget?.files)"
        >

             <ng-content></ng-content>

    </label>
</span>

<span *ngIf="isLoading">

    <div class = "image-item-container " >

        <div class="add-item text-center ">
            <i class="fa fa-spin fa-spinner fa-3x m-t-lg text-warning"></i>
        </div>

    </div>

</span>
