import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../locations-dialog.variables";
import {GoodModelService} from "../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../service/api/CategoryModel/CategoryModel.service";
import {CategoryApiService} from "../../../../api/CategoryApi/services/category-api.service";
import {BrandService} from "../../../service/api/Brand/Brand.service";
import {GoodgroupService} from "../../../service/api/Goodgroup/Goodgroup.service";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {LocationsService} from "../../../service/api/Locations/Locations.service";
import {LocationGroupService} from "../../../service/api/LocationGroup/LocationGroup.service";
import {LocationGroupLinkService} from "../../../service/api/LocationGroupLink/LocationGroupLink.service";

@Injectable({
  providedIn: "root",
})
export class LocationsDialogGetterService {

  static cache: any;

  constructor(
    @Inject(LocationsService)         private locationsService: LocationsService  ,
    @Inject(LocationGroupLinkService) private locationGroupLinkService: LocationGroupLinkService ,
    @Inject(LocationGroupService)     private locationGroupService: LocationGroupService,
  ) {
      this.resetCache();
  }

  public resetCache() {
    if ( typeof LocationsDialogGetterService.cache === "object")
      Object.keys( LocationsDialogGetterService.cache )
        .forEach( i => {
          delete LocationsDialogGetterService.cache[i];
        });

    LocationsDialogGetterService.cache = {};
  }

  private getMapForPartner(partnerId, type: 'location' | 'locationGorup' ): Map<any, any> {
    LocationsDialogGetterService.cache = LocationsDialogGetterService.cache || {};
    LocationsDialogGetterService.cache[type] = LocationsDialogGetterService.cache[type] || {};
    LocationsDialogGetterService.cache[type][partnerId] = LocationsDialogGetterService.cache[type][partnerId] || new Map();
    return LocationsDialogGetterService.cache[type][partnerId];
  }

  getLocationsList(params) {

    if (!params?.filter?.partnerId || params?.pager?.drop > 0)
      return of([]);

    // Временно
    Object.assign(params, {
      "pager": {
        "drop": 0,
        "limit": 999999
      }
    })

    return this.locationsService
      .getList$(params)
      .pipe(
        map( result => {
          result = <any>result.map(item => {
            let res = {
              id: item.id,
              name: item.name,
              code: item.code,
              description: item.description,
              address: item.address
            }
            //  mapStorage.set(item.id, res);
            return res;
          })

          return result;
        })
      )

  }

  getLocation(id, partnerId) {

    let mapStorage = this.getMapForPartner(partnerId, "location");

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    return this.locationsService.get$( id )
      .toPromise()
      .then( ( result ) => {
          mapStorage && mapStorage.set(id, result);
          return result;
        }, () => Promise.resolve(undefined)
      );

  }

  getLocationGroupsList(params, partnerId) {
    return this.locationGroupService
      .getList$(params)
      .pipe(
        map( ( result: any ) => {

          if (Array.isArray(result)) {

            result = result
              .filter( item => item.partnerId == partnerId)
              .map( item => {
                let res = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                  description: item.description,
                  address: item.address
                }

                return res;
              })

          }
          return result;
        })
      )
  }

  getLocationGroup(id, partnerId) {

    let mapStorage = this.getMapForPartner(partnerId, "locationGorup");

    if (mapStorage && mapStorage.has(id) ) {
      return mapStorage.get(id);
    }

    return this.locationGroupService.get$( id )
      .toPromise()
      .then( ( result ) => {
          mapStorage && mapStorage.set(id, result);
          return result;
        }, () => Promise.resolve(undefined)
      );
  }

  getLocationIdsForGroup(id, partnerId) {

    return this.locationGroupLinkService.getList$({
      groupId:  id
    } )
      .toPromise()
      .then( ( result ) => {
          return result.map( i => i.merchantId);
        }, () => Promise.resolve([])
      );

  }


  async getLocationByIds(ids, partnerId) {

    return await new Promise( resolve => {


        this.locationsService.getList$({
          pager: {
            drop: 0,
            limit: 999999
          },
          filter: {
            partnerId: partnerId
          }
        }).subscribe( result => {

          resolve(
            result.filter( i => ids.indexOf(i.id) >=0)
          )

        })

    })

  }

  async getLocationGroupsByIds(ids, partnerId) {

    return await Promise.all(
      ids.map( id =>
        this.getLocationGroup(id, partnerId)
      )
    )

  }

  async getLocationIdsForGroups(ids, partnerId) {

    return await Promise.all(
      ids.map( id =>
        this.getLocationIdsForGroup(id, partnerId)
      )
    ).then( result => {
      let res = [];
      result.forEach( item => {
        res = res.concat(item)
      })

      return res;
    })

  }


}
