import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";

const DIRECTIVE_NAME = 'formItemRow';

@Directive({ selector: '[form-item-row]' })
export class FormItemRowDirective implements  OnInit, OnChanges{

    @Input('form-item-row') label;
    @Input('form-item-row-icon') icon;
    @Input('form-item-row-input') forInput;
    @Input('form-item-row-label-style') labelStyle;

    constructor( private el: ElementRef) {
    }

    ngOnInit() {
      const labelElement = this.getLabelElement(this.label , this.icon);
      if (!labelElement)
        return;

      let child = this.decorateValueElement(this.el.nativeElement);
      this.el.nativeElement.insertBefore(labelElement, child);
      this.el.nativeElement.classList.add('form-item-row__form');
      this.el.nativeElement.classList.add('form-item-row');
    }

    ngOnChanges(changes: SimpleChanges) {

      if ( changes['forInput'] ) {
        setTimeout(() => {
          let finded = this.el?.nativeElement?.querySelector('.form-item-row__label')
          if (finded) {
            if ( changes['forInput']?.currentValue )
              finded.classList.add('form-item-row__label_input')
            else
              finded.classList.remove('form-item-row__label_input')
          }
        })
      }

      if ( changes['labelStyle'] ) {
        setTimeout(() => {
          let finded = this.el?.nativeElement?.querySelector('.form-item-row__label')
          if (finded) {
              finded.style.cssText = changes['labelStyle']?.currentValue;
          }
        })
      }

    }

  private getIconElement(icon: string) {
        if ( !icon ) {
            return;
        }

        let el = document.createElement('i');
        el.classList.add(icon.trim());
        el.classList.add('fa'); // TODO: сделать проверку для разных библиотек иконок
        return el;
    }

    private getLabelElement(label = "", icon: string) {

        let el_icon = this.getIconElement(icon);
        if ( !label && !icon) {
            return;
        }

        let el = document.createElement('div');
        el.classList.add('form-item-row__label');
        el.innerHTML = label;
        !!icon && el.appendChild( el_icon );
        return el;
    }

    private decorateValueElement(root: HTMLElement) {

      const child = document.createElement('div');

      while (root.childNodes.length > 0) {
        child.appendChild(root.childNodes[0]);
      }

      root.classList.add('form-item-row');
      root.appendChild(child)

      child.classList.add('form-item-row__value');

      return child;


    }
}
