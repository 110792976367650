import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {ClientCategory} from "../../../../../api/ClientCategoryApi/models";
import {ClientCategoryService} from "../../../../../shared/service/api/ClientCategory/ClientCategory.service";
import {ParamsHandler} from "../../../../../shared/service/api/ParamsHandler/Params.handler";
import {MechanicCategory} from "../../../../../api/MechanicCategoryApi/models/mechanic-category";
import {
  CampaignLimitationsService
} from "../../../../../shared/service/api/CampaignLimitations/CampaignLimitations.service";
import {CampaignLimitation} from "../../../../../api/CampaignLimitationApi/models/campaign-limitation";

@Component({
  selector: 'clientcategory-index-page',
  templateUrl: './campaignlimitations-index-page.component.html',
  providers: [
    CampaignLimitationsService
  ]
})
export class CampaignlimitationsIndexPageComponent implements OnInit{

  listCampaignLimitations: Array<CampaignLimitation> = [];
  public searchString: string;

  constructor(
    private campaignLimitationsService:CampaignLimitationsService
  ) {
  }

  ngOnInit() {
    this.campaignLimitationsService.getListParams.reset();
    this.getList(this.campaignLimitationsService.getListParams.params);
  }

  getList(params) {
    this.campaignLimitationsService.getList$(params)
      .subscribe( result => {
        this.listCampaignLimitations = this.listCampaignLimitations.concat(result);
      });
  }


  onScroll() {
    this.campaignLimitationsService.getListParams.next();
    this.getList(this.campaignLimitationsService.getListParams.params)
  }

  reset() {
    this.campaignLimitationsService.getListParams.reset();
    this.listCampaignLimitations=[];
    this.getList(this.campaignLimitationsService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.campaignLimitationsService.getListParams.search(this.searchString);
    this.reset();
  }

}
