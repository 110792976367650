'use strict';

import module from 'frontendModule';

// import 'agModules/loya-charts';

import 'model/ReportController';
import 'model/SegmentationModel/resource';
import 'model/CampaignModel/resource';
import 'model/CampaignCommonModel/resource';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.dashboard.index';
module.requires = module.requires.concat([
    'highcharts-ng',
    'ui.router'
]);


export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/dashboard.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/dashboard.index" */ "./controllers"       ))

                .breadcrumbs( [ "Добро пожаловать!" ] )

                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.TopManager ],
                    redirectTo: function( permissions : any ) {

                        switch( permissions.userRole ) {
                            case RoleType.InputOperator: return {
                                path   : "frontend.crm.clients.view",
                                params : { id:'worksheet' }
                            };
                            default              : return 'frontend.crm.clients.index';
                        }

                    }
                })

        }]);

