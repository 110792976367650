import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import {AccountService} from "../../../../shared/service/api/Account/Account.service";

import "./accaunts-edit-page.legacy"
import {UserService} from "../../../../shared/service/api/User/User.service";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {getNg1Service} from "../../../../shared/class/utils/angularjs.utils";
import {CreditAccountService} from "../../../../shared/service/api/CreditAccount/CreditAccount.service";
import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";

@Component({
  selector: 'accaunts-edit-page',
  templateUrl: './accaunts-edit-page.component.html',
  providers:[
    StatesChangerService,
    AccountService,
    CreditAccountService
  ]
})
export class AccauntsEditPageComponent implements OnInit, OnChanges{

  @Input() clientId;
  @Input() id;

  public loader = new LoaderHandlerClass();
  public itemAccount;
  public itemCreditAccount
  public hasErrors = false;
  public saveEvent = false;

  get isCreditEnabled() {
    return getNg1Service('loyaPermissions').isCapabilityEnabled('crm.accounts.credit');
  }

  constructor(
    public accountService: AccountService,
    public currentUser: UserService,
    public creditAccountService: CreditAccountService,
    public statesChangerService:StatesChangerService,
  ) {
  }

  ngOnInit() {

    this.buildStates();

  }

  buildStates() {

    this.statesChangerService.createState('correct', true);
    this.statesChangerService.createState('merge', true);

    this.statesChangerService.createButton('view','correct' , this.onCorrect.bind(this)    );
    this.statesChangerService.createButton('view','merge'   , this.onMerge.bind(this)  );

    this.statesChangerService.createButton('correct','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('correct','cancel' , this.onCancel.bind(this)  );

    this.statesChangerService.createButton('merge','mergeSave'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('merge','cancel' , this.onCancel.bind(this)  );

    this.statesChangerService.changeState('view');

  }

  onCorrect() {
    this.statesChangerService.changeState('correct');
  }

  onMerge() {
    this.statesChangerService.changeState('merge');
  }

  onSave() {
    this.saveEvent = true;
  }

  onCancel() {
    this.statesChangerService.changeState('view');
  }

  reloadPage() {
    getNg1Service('$state')
      .go('^.edit', {id: this.id, client_id: this.clientId}, {reload: true});
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['id']) {
      this.getAccount();
      this.getCreditAccount();
    }

  }

  getAccount() {

    this.accountService.get$(
      this.id
    ).pipe(
      this.loader.waitLoading('account')
    ).subscribe({
      next: result => {
        this.itemAccount = result;
      }
    })

  }

  getCreditAccount() {

    if (!this.isCreditEnabled) {
      this.itemCreditAccount = {};
      return;
    }

    this.creditAccountService.get$(
      this.id
    ).pipe(
      this.loader.waitLoading('creditAccount')
    ).subscribe({
      next: result => {
        this.itemCreditAccount = result;
      }
    })

  }

  isArchive() {
    return this.itemAccount?.account?.state === 'archive';
  }

  canMerge = function() {
    return !!this.itemAccount?.clients?.length;
  }


}
