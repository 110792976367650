import 'model/UserModel/resource';
import {UserService} from "../../../../../shared/service/api/User/User.service";

interface IUserItem {
    email    : string;
    id       : number;
    name     : string;
    password : string;
    role     : string;
}


interface IUserOption {
    id       : number;
    label     : string;
}


export class UserHandler {

    private optionsList:  Array<IUserOption> = [];

    constructor( private UserModel: UserService) {
        this.initList();
    }

    get options() {
        return this.optionsList;
    }

    private initList(): Promise<any> {
        return this.UserModel.getList()
          .toPromise()
          .then( (result) => {
            this.optionsList = result.map( i => ({
                id: i.id,
                label: i.name
            }));
        });
    }

    public userNameById( id: number ) {
        let finded = this.optionsList.find( i => i.id === id);
        return finded ? finded.label : undefined;
    }


}
