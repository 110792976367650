import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GoodGroupLinkApiService} from "../../../../api/GoodGroupLinkApi/services";

import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {GoodGroup} from "../../../../api/GoodGroupApi/models/good-group";
import QueryGoodGroupLinksParams = GoodGroupLinkApiService.QueryGoodGroupLinksParams;


@Injectable()
export class GoodgroupLinkService {

  constructor( private goodGroupLinkApiService:GoodGroupLinkApiService ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    sort: {
      type: "string",
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    }
  });

  public getList$(params: QueryGoodGroupLinksParams){
    return this.goodGroupLinkApiService.queryGoodGroupLinks(params)
  }

  public addList$(params: GoodGroupLinkApiService.UpdateParams){
    return this.update$(params)
  }

  public update$(params: GoodGroupLinkApiService.UpdateParams){
    return this.goodGroupLinkApiService.update(params)
  }


  public deleteList$(params: GoodGroupLinkApiService.DeleteListParams){
    return this.goodGroupLinkApiService.deleteList(params)
  }

  public deleteAll$(id: number, partnerId: number){

    return new Observable(s => {

      const LIMIT_PAGER = 100;
      const tick = () =>
        this.getList$({
          id         : id,
          partnerId  : partnerId,
          pagerDrop  : 0,
          pagerLimit : LIMIT_PAGER,
        }).toPromise()
          .then( result => {
            if (!result.length)
              return result;

            return this.deleteList$({
              id         : id,
              partnerId  : partnerId,
              links      : result
            }).toPromise().then(() => result)
          })
          .then(result => result.length === LIMIT_PAGER ? tick() : undefined);

      tick()
        .then(() => {
          s.next({});
          s.complete();
        });
    });

  }
}
