import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import EventElement from "module/trigger/element/eventElement";
import {getNg1Service} from "../../class/utils/angularjs.utils";
import {CampaignService} from "../../service/api/Campaign/Campaign.service";
import {HashtagDictionaryService} from "../../service/api/HashtagDictionary/HashtagDictionary.service";

@Component({
  selector: 'campaign-tags',
  templateUrl: './campaign-tags.component.html',
  styleUrls: ['./campaign-tags.component.scss'],
  providers: [
    CampaignService,
    HashtagDictionaryService
  ]
})
export class CampaignTagsComponent implements OnInit, OnChanges{

    @Input() public model       : Array<string>;
    @Output() public modelChange = new EventEmitter();

    @Input() public isView      : boolean = false;
    @Input() public placeholder : string;
    @Input() public campaignId  : number;
    @Input() public tagCss      : string;
    @Input() public highlight   : any   ;

    public isEdit : boolean;
    private backupModel : Array<string>;

    public minLength  = 3  ;
    public maxLength  = 50 ;
    public maxTags    = 10 ;


    constructor( private hashtagDictionaryService : HashtagDictionaryService,
                 private CampaignModel     : CampaignService
    ) {}

    get isCanEdit() {
        return this.isView && typeof this.campaignId !== "undefined" ;
    }

    get isInvalid() {
        return this.model.length > this.maxTags || this.model.some( (tag:string) => tag.length < this.minLength || tag.length > this.maxLength);
    }

    get isEditFromView() {
        return this.isView && this.isEdit;
    }

    get hasValues() {
        return this.model && !!this.model.length;
    }

    public ngOnInit() {
        this.placeholder = this.placeholder || 'Введите тэг';
        this.tagCss = this.tagCss || 'badge no-select bg-info pull-left m-r-xs m-t-xs p-t-xs p-b-xs disabled';
        this.model       = this.model || [];
        this.isEdit      = !this.isView;
    }

    public ngOnChanges(changes: SimpleChanges) {

        if ( !!changes['isView'] ) {
            this.isEdit = !changes.isView.currentValue;
        }

    }

    public switchToEdit() {
        if ( !this.isCanEdit )
            return;

        this.backupModel = this.model.slice(0);
        this.isEdit = true;

    }

    public switchToView() {
        if ( !this.isCanEdit )
            return;

        this.isEdit = false;

    }

    public cancelChanges() {

        this.model = this.backupModel.slice(0);
        this.switchToView();

    }

    public searchTags = ( $query : any  ) => {

        return this.hashtagDictionaryService.getAllTags$( {
                "prefix": $query,
                "limit" : 10,
                "drop"  : 0
        });

    }

    public saveChanges() {

        this.CampaignModel.markCampaignByIdWithListOfTags$({
            id: this.campaignId,
            listOfTags: this.model
        })
            .toPromise()
            .catch( () => this.cancelChanges() )
            .then( () => this.switchToView() );

    }

    public searchByTag( tag:string ) {
        getNg1Service('$state').go('frontend.campaign.campaigns.index', { search: tag }, { reload: true });
    }

}
