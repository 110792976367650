
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {AccountService} from "../../../../../../shared/service/api/Account/Account.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {
  ACCOUNT_OPER_TYPE,
  ACCOUNT_OPER_TYPE_MANUAL,
  ACCOUNT_RECORD_STATUS
} from "../../../../../../shared/service/api/Account/Account.variables";
import {getDescription} from "../../utils/account-paget.utils";
import {PartnerService} from "../../../../../../shared/service/api/Partner/Partner.service";
import {
  ClientTransactionsService
} from "../../../../../../shared/service/api/ClientTransactions/ClientTransactions.service";
import {result} from "lodash";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'account-edit-edit-page',
  templateUrl: './accountEdit-edit-page.component.html',
  styleUrls: ['./accountEdit-edit-page.component.scss'],
  providers: [
    PartnerService,
    AccountService,
    ClientTransactionsService
  ]
})
export class AccountEditEditPageComponent implements OnInit, OnChanges {

  public loader = new LoaderHandlerClass();

  @Input() public id: number;
  @Input() public max: number;

  @ViewChild('accountEditForm') accountEditForm: NgForm;
  @ViewChild('bonusSettingsContainer') bonusSettingsContainer: ElementRef;

  @Output() onErrors = new EventEmitter();
  @Output() onUpdated = new EventEmitter();

  @Input() onSave: boolean;
  @Output() onSaveChange = new EventEmitter();

  public partnerList: any = [];
  public record: any = {
    amount: 1,
    operType: Object.keys(ACCOUNT_OPER_TYPE_MANUAL)[0]
  };

  public bonusSettings: any;
  public showBonusConfig = false;

  public prevBonusValiditySettings;

  public ACCOUNT_OPER_TYPE = ACCOUNT_OPER_TYPE;
  public ACCOUNT_OPER_TYPE_MANUAL = ACCOUNT_OPER_TYPE_MANUAL;

  constructor(
    public accountService: AccountService,
    public partnerService : PartnerService,
    public clientTransactionsService: ClientTransactionsService,
  ) { }

  ngOnInit() {

    this.onErrors.emit(false)
    this.onSaveChange.emit(false);

    this.getPartners();
    setTimeout( this.bindErrorEventsForm.bind(this) );

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['onSave']?.currentValue && !changes['onSave']?.firstChange)
      this.saveRecord();
  }

  ngDoCheck() {

    let newSettings = JSON.stringify(this.record.bonusValiditySettings);
    if (this.prevBonusValiditySettings !== newSettings) {
      this.prevBonusValiditySettings = newSettings;
      this.updateBonusSettings();
    }

  }


  public saveRecord() {
    this.accountService
      .update$( this.id, this.record)
      .pipe(
        this.loader.waitLoading('update')
      )
      .subscribe( {
        next: () => {
          this.onUpdated.emit(true);
          this.onSaveChange.emit(false);
        },
        error: () => {
          this.onSaveChange.emit(false);
        }
      })

  }

  public revaludateForm() {

    if (!this.accountEditForm?.controls)
      return;

    Object.keys(this.accountEditForm.controls)
      .forEach( key => {
        this.accountEditForm.controls[key].updateValueAndValidity();
      })
  }

  private bindErrorEventsForm() {

    if (!this.accountEditForm)
      return;

    this.accountEditForm.statusChanges
      .subscribe({
        next: (result) => {
          setTimeout(() => {
            this.onErrors.emit(
              result?.toLowerCase() !== 'valid' || !!this.bonusSettingsContainer?.nativeElement?.querySelectorAll('.ng-invalid')?.length
            )
          })

        }
      })

  }

  public getPartners() {
    this.partnerService.query$()
      .subscribe({
        next: result => {
          this.partnerList = result;
          this.record.partnerId = (!!this.partnerList?.length ? this.partnerList[0].id : undefined)
          this.onPartnerChange();
        }
      })
  }

  public onPartnerChange() {
    this.updateBonusSettings();
  }

  public onOperChange() {

    if ( this.record?.operType !== 'credit' && !!this.record?.bonusValiditySettings) {
      delete this.record?.bonusValiditySettings;
    }

    this.updateBonusSettings();
  }

  public updateBonusSettings() {

    this.revaludateForm();

    this.accountService.bonusSettings$({
      "amount": this.record.amount || 1,
      "description": this.record?.description || "",
      "operType": this.record.operType,
      "partnerId": this.record.partnerId,
      "bonusValiditySettings":  (this.record && this.record.bonusValiditySettings) ? this.record.bonusValiditySettings : undefined ,
    })
      .pipe(
        this.loader.waitLoading('bonusSettings')
      )
      .subscribe( result => {
        this.bonusSettings = result;
        this.revaludateForm();
      }, error => {
        error?.stopPopupError();
      /*  this.bonusSettings.startDate = undefined;
        this.bonusSettings.endDate = undefined;*/
      })

  }


}
