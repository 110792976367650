export const daysOfWeekDict = [
  { key: 1, name :'Пн' },
  { key: 2, name :'Вт' },
  { key: 3, name :'Ср' },
  { key: 4, name :'Чт' },
  { key: 5, name :'Пт' },
  { key: 6, name :'Сб' },
  { key: 7, name :'Вс' }
];

export let daysOfMonthDics = [];

for ( let i =1; i<=31; i++) {
  daysOfMonthDics.push({
    key: i, name : i.toString()
  })
}

