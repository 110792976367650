
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {GoodgroupService} from "../../../../service/api/Goodgroup/Goodgroup.service";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";


@Component({
  selector: 'goodgroup-view',
  templateUrl: "./goodgroup-view.component.html",
  styleUrls: ['./goodgroup-view.component.scss' ],
  providers: [
    GoodgroupService
  ]
})
 export class GoodgroupViewComponent implements OnInit, OnChanges {

    @Input() partnerId;

    @Input()  selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max : number;
    @Input() selectOne : boolean;

    @Input()  searchQuery: string;

    public goodgroupItems = [];
    public loader = new LoaderHandlerClass();

    constructor(
      private goodgroupService: GoodgroupService,
      private goodTreeGetterService: GoodTreeGetterService,
    )
    {
    }

    ngOnInit() {
      this.goodgroupService.getListParams.reset();
      this.goodgroupService.getListParams.setFilterValue('partnerId', this.partnerId )
      this.getGoodgroup();
    }

    ngOnChanges(changes: SimpleChanges) {

      if (changes['partnerId'] && !changes['partnerId'].isFirstChange()) {
        this.goodgroupService.getListParams.setFilterValue('partnerId', changes['partnerId'].currentValue )
        this.reset();
        return;
      }

      if (changes['searchQuery'] && !changes['searchQuery'].firstChange) {
        this.goodgroupService.getListParams.search( changes['searchQuery'].currentValue )
        this.reset();
        return;
      }

    }

    getGoodgroup() {

        this.goodTreeGetterService
          .getGoodGroupList(
            this.goodgroupService.getListParams.params,
            this.partnerId
          )
          .pipe(
            this.loader.waitLoading('goodgroupItems')
          )
          .subscribe({
            next: result => {
              this.goodgroupItems = this.goodgroupItems.concat( result );
            },
            error: err => {
              console.log(err)
            }
          })

      }

    isSelected = (id) =>
      this.selected.has(id)

    changeSelected(id, value) {

      if (value) {

        if (this.selectOne) {
          this.selected.clear();
        }

        this.selected.add(id);
        this.goodTreeGetterService.getGoodGroup(id,this.partnerId);

      } else {
        this.selected.delete(id)
      }

      this.selectedChange.emit(this.selected);

    }

    isDisabled( id ) {

      if ( !Number.isInteger(this.max) ) {
        return false;
      }

      if ( this.selected.size >= this.max )
        return !this.isSelected(id);

      return false;

    };

    onScroll() {
      if (this.loader.isLoading('goodgroupItems'))
        return;

      this.goodgroupService.getListParams.next();
      this.getGoodgroup()
    }

    reset() {
      this.goodgroupItems = [];
      this.loader.stopLoading('goodgroupItems');
      this.selected.clear();
      this.selectedChange.emit(this.selected);
      this.getGoodgroup();
    }

}
