'use strict';

import module from 'frontendModule';

import '../userPhoneInput/main';

import { ClientHeaderForm } from './controller';
import * as  templateView    from  './index.html';

export default module.component('clientHeaderForm', {
    templateUrl : templateView,
    controller  : ClientHeaderForm,

    bindings : {
        model     : '=',
        isEdit    : '<',
        isExpress : '<',
        template  : '<'
    }

});

