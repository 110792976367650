
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {CategoryModelService} from "../../../../service/api/CategoryModel/CategoryModel.service";
import {CategoryApiService} from "../../../../../api/CategoryApi/services/category-api.service";
import {TreeComponent} from "@circlon/angular-tree-component";
import {TreeViewComponent} from "../tree-view/tree-view.component";
import {ITreeNode} from "@circlon/angular-tree-component/lib/defs/api";
import {BrandService} from "../../../../service/api/Brand/Brand.service";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";
import {VIEW_TYPES, VIEW_TYPES_NAMES} from "./../../goods-tree.variables";

@Component({
  selector: 'manual-good-input',
  templateUrl: "./manual-good-input.component.html",
  styleUrls: ['./manual-good-input.component.scss' ],
  providers: [
    BrandService
  ]
})
 export class ManualGoodInputComponent implements OnInit, OnChanges {

    @Input() partnerId;

    @Input() selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max:number;
    @Input() selectOne:boolean;

    @Input() currentView;

    public inputForAdd = '';
    public inputError = ''

    public loader = new LoaderHandlerClass();

    public VIEW_TYPES = VIEW_TYPES;

    constructor(
      private goodTreeGetterService: GoodTreeGetterService,
    )
    {
    }

    ngOnInit() {
      this.inputError = '';
    }

    ngOnChanges(changes: SimpleChanges) {

    }


    addNewItem() {

      this.goodTreeGetterService
        .getIdByType(this.inputForAdd, this.partnerId, this.currentView)
        .then( id => {

            if (this.selectOne && !this.selected.has(id))
              this.selected.clear();

            this.selected.add(id);

            this.inputForAdd = undefined;

        }, result => {

          switch (this.currentView) {

            case VIEW_TYPES.SKU:
              return this.inputError = 'SKU не найдено'

            case VIEW_TYPES.CATEGORY:
              return this.inputError = 'Категория не найдена'

            case VIEW_TYPES.BRAND:
              return this.inputError = 'Бренд не найден'

            case VIEW_TYPES.GOODGROUP_SKU:
            case VIEW_TYPES.GOODGROUP:
              return this.inputError = 'Список не найден'
          }


        })

    }

  getPlaceholder() {
    switch (this.currentView) {

      case VIEW_TYPES.SKU:
        return  'Введите SKU'

      case VIEW_TYPES.CATEGORY:
        return  'Введите ID категории'

      case VIEW_TYPES.BRAND:
        return  'Введите ID бренда'

      case VIEW_TYPES.GOODGROUP_SKU:
      case VIEW_TYPES.GOODGROUP:
        return  'Введите ID списка'
    }
  }


}
