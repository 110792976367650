import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LOYA_CONFIG} from "../../../../shared/variables/loya-config.variable";
import {ngCurrencyL10nService} from "../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_ActiveCampaignId} from "../block-ids.variables";

export const BLOCK_ID = BLOCK_ActiveCampaignId;

@Component({
  selector: 'dashboard-campaign-active-chart',
  template: `

    <div class="card wrapper">

      <div class="col-sm-12">
        <h4 class="font-thin m-t-none m-b-md text-muted">Кампании</h4>
      </div>


      <div class="row  text-center"
           style="height:  400px"
      >

        <div class="col-md-6"
             *ngIf="!isLoading">

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfigActive"
            [(update)]="updateFlag"
            class="d-block w-100 h-100"
          ></highcharts-chart>

        </div>


        <div class="col-md-6"
             *ngIf="!isLoading"
        >

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfigToday"
            [(update)]="updateFlag"
            class="d-block w-100 h-100"
          ></highcharts-chart>

        </div>


        <div class="d-flex justify-content-center align-items-center w-100 h-100 col"
             *ngIf="isLoading"
        >
          <div class="text-warning-dker ">
            <i class="fa fa-3x fa-spin fa-spinner"></i>
          </div>
        </div>

      </div>

      <no-location-warning
        [params]="params"
      ></no-location-warning>

    </div>


  `,
  providers: [
    ngCurrencyL10nService
  ]
})
export class DashboardCampaignActiveChartComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public isLoading = true
  public updateFlag = false;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartConfigActive =  {
    credits: {
      enabled: false
    },

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false
    },
    colors: [
      LOYA_CONFIG.color.danger, LOYA_CONFIG.color.orange, LOYA_CONFIG.color.primary, LOYA_CONFIG.color.success, LOYA_CONFIG.color.dark,
      LOYA_CONFIG.color.info, LOYA_CONFIG.color.warning
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',

        dataLabels: {
          enabled: true,
          distance: -1,

          crop: false,
          style: {
            fontWeight: 'bold',
            color: '#333333',
            textShadow: '1px 1px 1px white, -1px -1px 1px white, -1px 1px 1px white, 1px -1px 1px white'
          }
        },

        startAngle: 0,
        endAngle: 360,
        center: ['50%', '50%']
      }
    },

    title: {
      text: 'Активные<br/>кампании',
      align: 'center',
      verticalAlign: 'middle',
      y: 0
    },


    tooltip: {
      headerFormat: '<b>{series.name}</b>: ',
      pointFormat: '{point.y}'
    },

    series: [{
      type: 'pie',
      name: 'Активных кампаний',
      size: '80%',
      innerSize: '60%',

      data: []
    }]

  };
  public chartConfigToday =  {
    credits: {
      enabled: false
    },

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    colors: [
      LOYA_CONFIG.color.danger, LOYA_CONFIG.color.orange, LOYA_CONFIG.color.primary, LOYA_CONFIG.color.success, LOYA_CONFIG.color.dark,
      LOYA_CONFIG.color.info, LOYA_CONFIG.color.warning
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',

        dataLabels: {
          enabled: true,
          distance: -1,

          crop: false,
          style: {
            fontWeight: 'bold',
            color: '#333333',
            textShadow: '1px 1px 1px white, -1px -1px 1px white, -1px 1px 1px white, 1px -1px 1px white'
          }
        },

        startAngle: 0,
        endAngle: 360,
        center: ['50%', '50%']
      }
    },

    title: {
      text: 'Запущенные<br/>сегодня',
      align: 'center',
      verticalAlign: 'middle',
      y: 0
    },


    tooltip: {
      headerFormat: '<b>{series.name}</b>: ',
      pointFormat: '{point.y}'
    },

    series: [{
      type: 'pie',
      name: 'Запущенных сегодня',
      size: '80%',
      innerSize: '60%',

      data: []
    }]


  };


  constructor(
    private dashboardRequestService:DashboardRequestService
  ) {
  }


  ngOnInit() {
    this.bindUpdateEvent();
    this.initChart();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;


    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  initChart() {
    this.chartConfigActive.series[0].data = this.chartConfigActive.series[0].data || [];
    this.chartConfigToday.series[0].data = this.chartConfigToday.series[0].data || [];
  }



  updateValue(params?) {

    this.chartConfigActive.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });

    this.chartConfigToday.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });


    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {

          if (!Array.isArray(result)) {
            this.isLoading = false;
            return;
          }

          if (result[0] && Array.isArray(result[0].values)) {
            this.chartConfigActive.series[0].name =  result[0].name;
            (<any>result[0]?.values)?.forEach(i => {
              this.chartConfigActive.series[0].data.push({
                name: i.name,
                y: i.value,
              })
            })
          }

          if ( result[1] && Array.isArray(result[1].values) ) {
            this.chartConfigToday.series[0].name =  result[1].name;
            (<any>result[1]?.values)?.forEach( i => {
              this.chartConfigToday.series[0].data.push({
                name: i.name,
                y: i.value,
              })
            })
          }

          this.isLoading = false;
          this.updateFlag = true;
        },
        error: () => {
          this.isLoading = false;
          this.updateFlag = true;
        }
      })


  }

/*


  updateValue() {

    this.chartConfigActive.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });

    this.chartConfigToday.series.forEach( i => {
      i.data.splice(0, i.data.length);
    });



    // ----Удалить
    this.chartConfigActive.series.forEach( ( item: any ) => {

      for( let i = 0; i<5; i++) {
        item?.data?.push( Math.random() * 10 );
      }

    })

    this.chartConfigToday.series.forEach( ( item: any ) => {

      for( let i = 0; i<5; i++) {
        item?.data?.push( Math.random() * 10 );
      }

    })
    //----------------


    this.isLoading = true
    setTimeout(() => this.isLoading = false, 2000);
  }


*/


  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
