import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {AccountService} from "../../../../shared/service/api/Account/Account.service";

import "./accaunts-index-page.legacy"
import {UserService} from "../../../../shared/service/api/User/User.service";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";

@Component({
  selector: 'accaunts-index-page',
  templateUrl: './accaunts-index-page.component.html',
  providers:[
  ]
})
export class AccauntsIndexPageComponent implements OnInit{

  public loader = new LoaderHandlerClass();

  searchString: string;
  listAccounts: Array<any> =[]

  constructor(
    public accountService: AccountService,
    public currentUser: UserService
  ) {
  }

  ngOnInit() {
    this.accountService.getListParams.reset();
    this.getList(this.accountService.getListParams.params);
  }

  getList(params) {
    this.accountService.getList$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.listAccounts = this.listAccounts.concat(result);
      });
  }

  onScroll() {
    this.accountService.getListParams.next();
    this.getList(this.accountService.getListParams.params)
  }

  reset() {
    this.accountService.getListParams.reset();
    this.listAccounts=[];
    this.getList(this.accountService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.accountService.getListParams.search(this.searchString);
    this.reset();
  }

  canTransactions() {
    //!currentUser.role.toLocaleLowerCase().includes('operator') && !currentUser.role.toLocaleLowerCase().includes('infocenter')
    return true;
  }

}
