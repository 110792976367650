import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {UserService} from "../../../../../shared/service/api/User/User.service"
import {UserProtocol} from "../../../../../api/UserApi/models"
import {rolesList as systemRolesList, RoleType} from "module/permissions/conf/roles";
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service";
import {CurrentUserService} from "../../../../../shared/service/currentUser/currentUser.service";
import {AccountTransactionService} from "../../../../../shared/service/api/AccountTransaction/AccountTransaction.service";
import {Transaction} from "../../../../../api/TransactionApi/models/transaction";
import {TransactionApiService} from "../../../../../api/TransactionApi/services/transaction-api.service";
import TransactionsByUserParams = TransactionApiService.TransactionsByUserParams;
import {TransactionData} from "../../../../../api/TransactionApi/models/transaction-data";
import {OPERATION_STATES} from "../../../../../shared/service/api/AccountTransaction/variables";

const dateToText = function(curDate: Date) {
  return`${curDate.getFullYear()}-${('0' + (curDate.getMonth()+1)).slice(-2)}-${('0' + curDate.getDate()).slice(-2)}`
}


@Component({
  selector: 'account-transaction-by-user-page',
  templateUrl: './account-transaction-by-user-page.component.html',
  providers: [
    AccountTransactionService
  ]
})
export class AccountTransactionByUserPageComponent implements OnInit{

  @Input() userId;
  listTransactions: Array<TransactionData> = [];

  OPERATION_STATES = OPERATION_STATES;


  private _filterFrom: any;
  private _filterTo: any;

  private isLoading = true;

  @Input() get filterFrom() {

    return this._filterFrom;
  }

  set filterFrom(value) {
    this._filterFrom = value;
    this.updateDateFilters();
  }

  @Input() get filterTo() {
    return this._filterTo;
  }

  set filterTo(value) {
    this._filterTo = value;
    this.updateDateFilters();
  }

  get maxDate() {
    if (!this.filterFrom)
      return '';

    let fromDate = new Date(this.filterFrom);
    return dateToText(new Date(fromDate.setMonth(fromDate.getMonth()+3)));
  }

  get minDate() {
    if (!this.filterTo)
      return '';

    let fromDate = new Date(this.filterTo);
    return dateToText(new Date(fromDate.setMonth(fromDate.getMonth()-3)));
  }

  constructor(
    public accountTransactionService: AccountTransactionService
  ) {
  }

  ngOnInit() {

    if (!this.filterTo) {
      let curDate = new Date();
      this.filterTo = dateToText(curDate);
    }
    if (!this.filterFrom) {
      let curDate = new Date();
      curDate.setDate(1);
      this.filterFrom = dateToText(curDate);
    }


    this.accountTransactionService.getByUserParams.reset();
    this.isLoading = false;
    this.getList();
  }

  getList() {
    if (!this.userId || this.isLoading)
      return;

    this.isLoading = true;
    this.accountTransactionService.getByUser$(
      Object.assign(
  {
          userId: this.userId
        },
        this.accountTransactionService.getByUserParams.params
      )
    ).subscribe(result => {
        this.isLoading = false;
        this.listTransactions = this.listTransactions.concat( result );
      }, () => {
      this.isLoading = false;
    });
  }

  getNext() {
    this.accountTransactionService.getByUserParams.next();
    this.getList();
  }

  reset() {
    this.listTransactions = [];
    this.accountTransactionService.getByUserParams.reset();
    this.getList();
  }

  exportByUser() {
    this.accountTransactionService.exportByUser( Object.assign(
        {
          userId: this.userId
        },
        this.accountTransactionService.getByUserParams.params
      )
    )
  }

  updateDateFilters() {
    if (this.filterFrom && this.filterTo ) {
      this.accountTransactionService.getByUserParams.setFilterValue('from', this.filterFrom);
      this.accountTransactionService.getByUserParams.setFilterValue('to', this.filterTo);
      this.reset();
    } else {
      this.accountTransactionService.getByUserParams.setFilterValue('to', undefined);
      this.accountTransactionService.getByUserParams.setFilterValue('from', undefined);
      this.listTransactions=[];
    }
  }

}
