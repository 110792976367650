'use strict';

import module from 'frontendModule';

import 'model/Settings';
import 'model/Newsletter';

let routePath = module.name + '.system.settings.index2';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/system.settings.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/system.settings.index" */ "./controllers"       ))

                .breadcrumbs( ["Настройки", "Настройки системы"] )

                .resolve('settingsList', ['Settings',  ( Settings : any ) => Settings.query().$promise ])

                .permissions( {
                    only: ['Administrator'],
                //    redirectTo: 'frontend.nopermissions'
                })

        }])

    .filter('passwordSecret', function() {
        return function(input) {
            if (!input) {
                return '';
            }

            var secret = '';
            for(var i = 0; i < input.length; ++i)
            {
                secret += '*';
            }
            return secret;
        };
    });

