'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.crm.accounts.edit';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id/:client_id')

        .template(   '<accaunts-edit-page [id]="id" [clientId]=clientId></accaunts-edit-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; $scope.client_id = $stateParams.client_id; } ])

        .breadcrumbs(  [ "Клиенты", "Счета", "Просмотр" ] )
        .permissions( {
          only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter],
        })

    }]);

