/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CouponApiConfiguration, CouponApiConfigurationInterface } from './coupon-api-configuration';

import { CouponApiService } from './services/coupon-api.service';

/**
 * Provider for all CouponApi services, plus CouponApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CouponApiConfiguration,
    CouponApiService
  ],
})
export class CouponApiModule {
  static forRoot(customParams: CouponApiConfigurationInterface): ModuleWithProviders<CouponApiModule> {
    return {
      ngModule: CouponApiModule,
      providers: [
        {
          provide: CouponApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
