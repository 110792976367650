/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CreditAccountConfiguration as __Configuration } from '../credit-account-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreditAccount } from '../models/credit-account';
import { CreditAccountRecord } from '../models/credit-account-record';
@Injectable({
  providedIn: 'root',
})
class CreditAccountService extends __BaseService {
  static readonly getByAccountIdPath = '/credit-account/byAccountId/{accountId}';
  static readonly getRecordsPath = '/credit-account/records/{creditAccountId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запросить кредитный счёт по ID
   *
   * Запросить кредитный счёт по ID
   * @param accountId ID активного счёта
   * @return Данные счёта с историей транзакций
   */
  getByAccountIdResponse(accountId: number): __Observable<__StrictHttpResponse<CreditAccount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/credit-account/byAccountId/${encodeURIComponent(String(accountId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreditAccount>;
      })
    );
  }
  /**
   * Запросить кредитный счёт по ID
   *
   * Запросить кредитный счёт по ID
   * @param accountId ID активного счёта
   * @return Данные счёта с историей транзакций
   */
  getByAccountId(accountId: number): __Observable<CreditAccount> {
    return this.getByAccountIdResponse(accountId).pipe(
      __map(_r => _r.body as CreditAccount)
    );
  }

  /**
   * Запросить счёт по ID с историей транзакций
   *
   * Запросить счёт по ID с историей транзакций
   * @param params The `CreditAccountService.GetRecordsParams` containing the following parameters:
   *
   * - `creditAccountId`: ID кредитного счёта
   *
   * - `sorting.order`: Порядок сортировки (оп умолчанию `desc`)
   *
   * - `sorting.field`: Поле для сортировки (по умолчанию `id`)
   *
   * - `pager.limit`: Сколько транзакций вернуть (по умолчанию 5)
   *
   * - `pager.drop`: Сколько транзакций опустить от начала списка (по умолчанию 0)
   *
   * @return Данные счёта с историей транзакций
   */
  getRecordsResponse(params: CreditAccountService.GetRecordsParams): __Observable<__StrictHttpResponse<Array<CreditAccountRecord>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/credit-account/records/${encodeURIComponent(String(params.creditAccountId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CreditAccountRecord>>;
      })
    );
  }
  /**
   * Запросить счёт по ID с историей транзакций
   *
   * Запросить счёт по ID с историей транзакций
   * @param params The `CreditAccountService.GetRecordsParams` containing the following parameters:
   *
   * - `creditAccountId`: ID кредитного счёта
   *
   * - `sorting.order`: Порядок сортировки (оп умолчанию `desc`)
   *
   * - `sorting.field`: Поле для сортировки (по умолчанию `id`)
   *
   * - `pager.limit`: Сколько транзакций вернуть (по умолчанию 5)
   *
   * - `pager.drop`: Сколько транзакций опустить от начала списка (по умолчанию 0)
   *
   * @return Данные счёта с историей транзакций
   */
  getRecords(params: CreditAccountService.GetRecordsParams): __Observable<Array<CreditAccountRecord>> {
    return this.getRecordsResponse(params).pipe(
      __map(_r => _r.body as Array<CreditAccountRecord>)
    );
  }
}

module CreditAccountService {

  /**
   * Parameters for getRecords
   */
  export interface GetRecordsParams {

    /**
     * ID кредитного счёта
     */
    creditAccountId: number;

    /**
     * Порядок сортировки (оп умолчанию `desc`)
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле для сортировки (по умолчанию `id`)
     */
    sortingField?: 'id' | 'date' | 'operation';

    /**
     * Сколько транзакций вернуть (по умолчанию 5)
     */
    pagerLimit?: number;

    /**
     * Сколько транзакций опустить от начала списка (по умолчанию 0)
     */
    pagerDrop?: number;
  }
}

export { CreditAccountService }
