/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ClientChangelogApiConfiguration, ClientChangelogApiConfigurationInterface } from './client-changelog-api-configuration';

import { ClientChangelogApiService } from './services/client-changelog-api.service';

/**
 * Provider for all ClientChangelogApi services, plus ClientChangelogApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ClientChangelogApiConfiguration,
    ClientChangelogApiService
  ],
})
export class ClientChangelogApiModule {
  static forRoot(customParams: ClientChangelogApiConfigurationInterface): ModuleWithProviders<ClientChangelogApiModule> {
    return {
      ngModule: ClientChangelogApiModule,
      providers: [
        {
          provide: ClientChangelogApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
