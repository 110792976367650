
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  Optional,
  ElementRef
} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderHandlerClass} from "../../class/loader-handler.class";
import {TRIGGER_STATE_NAMES, TRIGGER_STATES} from "../../service/api/Triggers/Trigger.values";
import {getNg1Service} from "../../class/utils/angularjs.utils";

@Component({
  selector: 'ng1-controller',
  templateUrl: "./ng1-controller.component.html",
  styleUrls: ['./ng1-controller.component.scss'],
  providers: [
    PartnerService,
  ]
})
 export class Ng1ControllerComponent implements OnInit, OnChanges{

  @Input() controller;
  @Input() template;
  @Input() templateUrl;
  @Input() scope;
  @Output() scopeChange = new EventEmitter();
  @Input() contollerProviders: any = {};

  @Input() useForm = false;
  @Output() formDummy  = new EventEmitter();

  public loader = new LoaderHandlerClass();

  public modulesStorageService = getNg1Service('modulesStorage');
  public $compileService = getNg1Service('$compile');
  public $controller = getNg1Service('$controller');
  public $templateCache = getNg1Service('$templateCache');
  public $rootScope = getNg1Service('$rootScope');

  constructor(
    private elRef: ElementRef
  )
  {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.controller) {
      this.build();
    }
  }

  public getHTML(url: string)  {
    try {
      let template = this.$templateCache.get( url );

      if (this.useForm) {
        template = '<form name="$$ng2FormDummy" novalidate="">' + template +'</form>';
      }

      return template || '';

    } catch  {
      return '';
    }

  }

  public build()  {

    let template = this.template || this.getHTML(this.templateUrl);

    if (!template || !this.controller)
      return;

    const scope = this.$rootScope.$new();
    Object.assign(scope, this.scope || {} );

    let controller = this.$controller(
      this.controller,
      Object.assign( {
        $scope: scope,
        $element: this.elRef.nativeElement
      }, this.contollerProviders || {} )
    );

    this.elRef.nativeElement.innerHTML = template;
    this.$compileService(this.elRef.nativeElement)(scope);

    this.scope = scope;
    this.scopeChange.emit(this.scope);

    if (this.useForm && this.scope?.$$ng2FormDummy)
      this.formDummy.emit(this.scope?.$$ng2FormDummy);

  }



}
