import {FieldHandler} from "../class/FieldHandler";
import { CardHandler } from "../class/CardHandler";
import {changeNames, changeTypes, pathFields} from "../interface/changeTypes";
import {Component, Input} from "@angular/core";

export enum OpTypes {
    replace = "replace",
    add     = "add",
    remove  = "remove"
}

export interface ChangeItem {

    "operation"    : OpTypes,
    "path"  : string,
    "value" : any,
    "old"   ?: any,

}

@Component({
  selector: "referrer-log-history-changes",
  template: `
    <span
      *ngIf=" type === changeTypes.referrerNicknameUpdated || type === changeTypes.referrerPromoCodeAndNicknameUpdated  || type === changeTypes.referrerPromoCodeUpdated ">


             <div class="clearfix f-s-9" >
                <div class=" inline text-success"
                     [innerText]="changeTypeNames[type]"></div>
            </div>

            <ng-container >
              <div class="clearfix"
                   *ngFor="let value of model"
              >

                  <div class="clearfix f-s-9">

                      <span *ngIf="value.operation === OpTypes.replace">

                          <div class="text-muted inline m-l-sm m-r-xs">
                              <span class=""
                                [innerText]="!!value.old ? 'Изменение ' + this.getFieldName(value.path) + 'a': 'Добавление ' + this.getFieldName(value.path) + 'a'"
                              ></span>:
                          </div>

                          <span class="text-danger m-r-xs"
                                *ngIf="value.old"
                                [innerText]="value.old || '-'"></span>
                          <i class="fa fa-arrow-right f-s-9 text-muted m-r-xs"
                             *ngIf="value.old"
                          ></i>
                          <span class="text-success"
                                [innerText]="value.value"></span>
                      </span>

                      <span *ngIf="value.operation === OpTypes.add">
                          <span class="text-muted m-l-sm">Добавление {{ this.getFieldName(value.path) + 'a'}}:
                          </span>

                        <span class="text-success"
                              [innerText]="value.value || ''"></span>
                      </span>

                      <span *ngIf="value.operation === OpTypes.remove">
                        <span class="text-muted m-l-sm">Удаление {{ this.getFieldName(value.path) + 'a'}}:
                        </span>

                        <span class="text-success m-r-xs" *ngIf="value.value"
                              [innerText]="value.value"></span>
                        <span class="text-danger m-r-xs" *ngIf="value.old"
                              [innerText]="value.old"></span>
                      </span>

                  </div>

              </div>
            </ng-container>

          <a class="f-s-9"
             *ngIf="model.clientDiff && model.clientDiff.length>3"
             (click)="switchFull()"
             [innerText]="showFull ? 'Свернуть' : 'Показать всё'"
          ></a>

    </span>

    <span
      *ngIf=" type === changeTypes.referrerCreated">

         <div class="clearfix f-s-9" >
            <div class=" inline text-success"
                 [innerText]="changeTypeNames[type]"></div>
         </div>

    </span>

    <span *ngIf=" type === changeTypes.referrerArchived">

        <div class=" inline">
                <span class="f-s-9 text-danger" [innerText]="changeTypeNames[type]"></span>
        </div>

    </span>

  `
})
export class ReferrerLogHistoryChangesComponent {

  @Input() public model        : ChangeItem   ;
  @Input() public type         : string ;
  @Input()  public item         : any ;
  @Input()  public fieldHandler : FieldHandler ;
  @Input()  public cardHandler  : CardHandler ;
  @Input()  public filterHandler : FieldHandler ;

    public showFull = false;

    public changeTypes = changeTypes;
    public changeTypeNames = changeNames;

    public OpTypes = OpTypes;

    public pathFields = pathFields;

      constructor() {}

    public $onInit() {
        this.cardHandler = new CardHandler();
      }

      get limitCounter() {
        return this.showFull ? 999999 : 3;
      }

    public switchFull() {
        this.showFull = !this.showFull;
      }

    public getFieldName(field?) {
        return this.pathFields?.[field] || 'никнейм';
    }
}
