/* tslint:disable */
type CheckFilterListType =
  'sku' |
  'category' |
  'custom';
module CheckFilterListType {
  export const SKU: CheckFilterListType = 'sku';
  export const CATEGORY: CheckFilterListType = 'category';
  export const CUSTOM: CheckFilterListType = 'custom';
  export function values(): CheckFilterListType[] {
    return [
      SKU,
      CATEGORY,
      CUSTOM
    ];
  }
}

export { CheckFilterListType }