'use strict';

import module from 'frontendModule';
let routePath   = module.name + '.config.directory.clientcategory.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<clientcategory-index-page></clientcategory-index-page>', true)
        .breadcrumbs( [ "Справочники", "Категории клиентов" ] )

        .permissions( {
          permit: 'global.clients'
        });

    }]);
