/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ClientWalletApiConfiguration, ClientWalletApiConfigurationInterface } from './client-wallet-api-configuration';

import { ClientWalletApiService } from './services/client-wallet-api.service';

/**
 * Provider for all ClientWalletApi services, plus ClientWalletApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ClientWalletApiConfiguration,
    ClientWalletApiService
  ],
})
export class ClientWalletApiModule {
  static forRoot(customParams: ClientWalletApiConfigurationInterface): ModuleWithProviders<ClientWalletApiModule> {
    return {
      ngModule: ClientWalletApiModule,
      providers: [
        {
          provide: ClientWalletApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
