import { ConfigFieldItem, FieldTypes, IStaticField, SettingsForPlugin } from "../components/configModuleFields/ConfigModuleService";
import {MODEL_FIELDS} from "plugin/component/social/variables";
import {MODULE_NAME as moduleName} from "plugin/component/address/variables";
import { getNg1Injector } from 'class/MigrationUtils'

export const SETTINGS_KEY = 'clientprofile.customfields';

export const MODULE_FIELDS_HANDLER = 'MODULE_FIELDS_HANDLER';

interface ModuleFieldItemLog {
    path         : string // Пусть в логировании
    exclude     ?: string | [string] // Запрет полей для показа
}

export interface ModuleFieldItem {

    id: string,

    // Для полей
    displayName : string,
    type        : string,
    placeholder ?: string,
    required    ?: false,
    maxLength   ?: number,
    minLength   ?: number,
    max         ?: number,
    min         ?: number,
    pattern     ?: RegExp,

    options     ?: Array<{
        id   : string,
        name : string
    }>

    // Для кастомных полей
    custom      ?: {
        dataType     : FieldTypes,
        ruleType    ?: string | Array<string>, // Какие правила привязаны к этому полю
        visible     ?: boolean, // если задан, в настройке будет захардкожен
        required    ?: boolean, // если задан, в настройке будет захардкожен
    },

    // Для логирования изменений
    log : ModuleFieldItemLog
}

export interface ICustomField {
    id        : string,
    label     : string,
    dataType  : string,
    ruleType  : string | Array<String>,
    visible   : boolean,
    required  : boolean,
}

export interface ILogField extends ModuleFieldItemLog {
    id          : string,
    displayName : string,
    isCustom   ?:boolean
}

export interface RepeaterConfigFieldItem extends ConfigFieldItem {
    readonly ?: boolean
}

export class ModuleFieldsHandler {

    private fieldsForModel          : any ;
    private fieldsForCustom         : Array<ICustomField> ;
    private fieldsForCustomRepeater : Array<ConfigFieldItem> ;
    private fieldsForLog            : Array<ILogField> ;
    private settingsCustomFields    : Array<ConfigFieldItem>;

    private parsedSettings : SettingsForPlugin | boolean = false;
    private settingsList   : Array<ConfigFieldItem>;

    private settingsVersion : number;

    static loadingPromise() {
        return ModuleFieldsHandler.cacheSettings.storage &&
               typeof ModuleFieldsHandler.cacheSettings.storage.$promise !== "undefined" &&
               ModuleFieldsHandler.cacheSettings.storage.$promise ;
    }

    static cacheSettings : {
        lastDate  : number
        storage   : string | any
        iteration : number
    };

    static resetCache( newSettings ? : string) {
        ModuleFieldsHandler.cacheSettings.storage = newSettings;
        ModuleFieldsHandler.cacheSettings.iteration++;
        if ( ModuleFieldsHandler.cacheSettings.storage ) {
            ModuleFieldsHandler.cacheSettings.lastDate = Date.now()
        }
    }

  static $inject = ['pluginId', 'fields'];

    constructor(
        private pluginId: string,
        private fields : Array<ModuleFieldItem> ) {
           setTimeout(this.updateFieldsFromSettings.bind(this));
    }

    static getInstanceForModule( moduleId : string ) : ModuleFieldsHandler {
        let modulesStorage = getNg1Injector().get('modulesStorage');
        if (!modulesStorage) return;

        return modulesStorage.get( moduleId, MODULE_FIELDS_HANDLER );
    }

    public async updateFieldsFromSettings() {

        this.settingsList = await this
                                    .getSettings()
                                    .then( this.updateFieldNames.bind(this), () => Promise.resolve([]) );

    }

    public updateFieldNames(fields: Array<any>) {

            if ( Array.isArray(fields) ) {
                fields.forEach( i => {
                    let finded = this.fields.find(f => f.id === i.id );
                    if ( i.label && finded ) {
                        finded.displayName = i.label;
                    }
                })
            }

            return fields;

    }

    public getFieldsForModel() {

        if ( this.isSettingsNotUpdated() && this.fieldsForModel )
            return Object.assign({}, this.fieldsForModel );

        this.fieldsForModel = {};
        this.fields.forEach( i => {
            this.fieldsForModel[i.id] = Object.assign({}, i);
            delete this.fieldsForModel[i.id].id;
            delete this.fieldsForModel[i.id].custom;
            delete this.fieldsForModel[i.id].path;
        });

        return this.fieldsForModel;
    }

    public getFieldsForCustom() : Array<ICustomField> {

        if ( this.isSettingsNotUpdated() && this.fieldsForCustom)
            return this.fieldsForCustom.slice();

        this.fieldsForCustom = this.fields
            .filter( i => !!i.custom)
            .map( i => ({
                id        : i.id,
                label     : i.displayName,
                dataType  : i.custom.dataType,
                ruleType  : i.custom.ruleType,
                visible   : i.custom.visible,
                required  : i.custom.required,
            }));

        return this.fieldsForCustom.slice();
    }

    public getFieldsForLog()  : Array<ILogField> {

        if ( this.isSettingsNotUpdated() && this.fieldsForLog)
            return this.fieldsForLog.slice();

        this.fieldsForLog = this.fields
            .filter( i => !!i.log)
                .map( i => Object.assign({
                    id          : i.id,
                    displayName : i.displayName
                }, i.log) )
            .concat(
                this.getCustomFields()
                    .map(i => <ILogField>{
                        id          : i.id,
                        displayName : i.label,
                        path        : '/' + i.id,
                        isCustom    : true
                    })
            );

        return this.fieldsForLog.slice();
    }

    public getFieldsForCustomRepeater() : Array<RepeaterConfigFieldItem> {

        if ( this.isSettingsNotUpdated() && this.fieldsForCustomRepeater)
            return this.fieldsForCustomRepeater;

        if ( !this.settingsList ||  !this.settingsList.length ) {

            this.fieldsForCustomRepeater = [];
            this.getFieldsForCustom().forEach( (staticField: any, index: number) => {

                this.fieldsForCustomRepeater.push({
                    positionId : index ,
                    dataType   : staticField   . dataType,
                    id         : staticField   . id,
                    label      : staticField   . label,
                    required   : !!staticField . required,
                    visible    : true,
                    isFixed    : true
                });

            });
        } else {
            this.fieldsForCustomRepeater = this.settingsList.slice();
        }

        this.disableFieldsWithPrivileges(this.fieldsForCustomRepeater);

        return this.fieldsForCustomRepeater;

    }

    public getFieldsFromSettings() : Array<ConfigFieldItem> {

        this.isSettingsNotUpdated();

        if ( this.parsedSettings  && this.pluginId )
            return this.parsedSettings[ this.pluginId ] || [];

        return [];
    }

    public fieldByPath( path: string ) {

        this.isSettingsNotUpdated();

        return this.fields.find( i => i.log && i.log.path === path);
    }

    public customFieldByPath( path: string ) {

        this.isSettingsNotUpdated();

        let finded =  this.getCustomFields().find( ( i : any ) => {
            if ( i.isFixed ) return false;

            if ( i.dataType !== "multiSelect" || '/' + i.id === path ) {
                return '/' + i.id === path;
            } else {
                return '/' + i.id === path.replace(/\/[\d|-]$/,'');
            }
        });

        if (!finded) return;

        return <ModuleFieldItem> {
            id          : finded.id,
            displayName : finded.label,
            type        : finded.dataType,
            required    : finded.required
        }
    }

    public fieldById( id: string ) {

        this.isSettingsNotUpdated();

        return this.fields.find( i => i.id === id);
    }

    public getFieldName( id: string ) {

        if ( this.getFieldsForCustomRepeater() ) {
            let finded = this.getFieldsForCustomRepeater().find( (i:any) => i.id === id );
            if (finded)
                return finded.label;
        }

        let finded = this.fields.find( i => i.id === id);
        return finded ? finded.displayName : '';

    }

    public isSettingsNotUpdated() {

        if ( !this.settingsVersion ) {
            this.settingsVersion = ModuleFieldsHandler.cacheSettings.iteration;
        }

        if ( ModuleFieldsHandler.cacheSettings.iteration && this.settingsVersion === ModuleFieldsHandler.cacheSettings.iteration ) {
            return true;
        }

        this.settingsVersion = ModuleFieldsHandler.cacheSettings.iteration;

        this.settingsList = this.parseSettings( ModuleFieldsHandler.cacheSettings.storage );
        this.updateFieldNames( this.settingsList );

        this.fieldsForModel   = undefined;
        this.fieldsForCustom  = undefined;
        this.fieldsForCustomRepeater = undefined;
        this.fieldsForLog     = undefined;
        this.settingsCustomFields = undefined;

        return false;
    }

    private getCustomFields() {

        if ( this.isSettingsNotUpdated() && this.settingsCustomFields)
            return this.settingsCustomFields.slice();

        if (!this.settingsList)
            return [];

        this.settingsCustomFields =  this.settingsList.filter( ( i : any ) => !i.isFixed );
        return this.settingsCustomFields.slice();
    }

    private parseSettings( settings: string ) {
        try {
            this.parsedSettings = JSON.parse( settings);
            return this.parsedSettings[ this.pluginId ] || [];
        } catch (e) {
            this.parsedSettings = false;
            return [];
        }
    }

    private async getSettings(): Promise<Array<ConfigFieldItem>> {

        if ( this.parsedSettings && typeof ModuleFieldsHandler.cacheSettings !== "undefined" )
            return this.parsedSettings[ this.pluginId ] || [];

        let allSettings : any;

        // Обновляем кеш каждую минуту
        if ( ModuleFieldsHandler.cacheSettings  && ModuleFieldsHandler.cacheSettings.lastDate + 60000 > Date.now() ) {

            allSettings = await ModuleFieldsHandler.cacheSettings.storage;

        } else {

            let Settings = getNg1Injector().get('Settings');
            const inc = !ModuleFieldsHandler.cacheSettings || typeof ModuleFieldsHandler.cacheSettings.iteration === "undefined" ? 1 : ModuleFieldsHandler.cacheSettings.iteration + 1;

            ModuleFieldsHandler.cacheSettings = {
                storage   : Settings.getByKeys([SETTINGS_KEY]).$promise,
                lastDate  : Date.now(),
                iteration : inc
            };

            this.settingsVersion = inc;
            allSettings = await ModuleFieldsHandler.cacheSettings.storage;
        }


        if (!allSettings || !allSettings.length )
            return [];

        return this.parseSettings(allSettings[0].value);
    }

    private disableFieldsWithPrivileges( items: Array<RepeaterConfigFieldItem> ): Array<RepeaterConfigFieldItem> {

        let loyaPermissions = getNg1Injector().get('loyaPermissions');

        const disabledFields = loyaPermissions.byPath('crm.client.customFields');

        let fieldsForModule : Array<string> = [];

        if ( disabledFields.value && disabledFields.value[this.pluginId] ) {
            fieldsForModule = disabledFields.value[this.pluginId];
        }

        items.forEach( i => i.readonly = fieldsForModule.indexOf(i.id) >= 0 );

        return items;
    }

}
