
import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, Optional} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderHandlerClass} from "../../class/loader-handler.class";
import {TRIGGER_STATE_NAMES, TRIGGER_STATES} from "../../service/api/Triggers/Trigger.values";

@Component({
  selector: 'trigger-dialog',
  templateUrl: "./trigger-dialog.component.html",
  styleUrls: ['./trigger-dialog.component.scss'],
  providers: [
    PartnerService,
  ]
})
 export class TriggerDialogComponent implements OnInit, OnChanges{

  //-------------LEGACY--------------

  showPartner = true

  @Input()  selectedCampaigns = new Map<number, any>();
  @Input()  editCallBack: any;

  @Input() public partnerId;
  public isPartnerSetted = false;
  public partnerList = [];
  public partner;

  public selectOne;
  public canCheckAll = false;
  public selectedAll = false;

  public max;

  public customParams;



  public selectedStates = Object.keys(TRIGGER_STATES)
    .filter( i => i!== TRIGGER_STATES.archive )
  public TRIGGER_STATE_NAMES = Object.keys(TRIGGER_STATE_NAMES)
    .map( i => ({
        key: i,
        value: TRIGGER_STATE_NAMES[i]
      }));

  searchString = ''

  public loader = new LoaderHandlerClass();

  constructor(
      private partnerService : PartnerService,
      @Optional() private activeModal: NgbActiveModal
  )
  {

  }

  ngOnInit() {
    this.getPartnerList();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  // Партнёр по умолчанию
  public setPartner( id: number, canEdit = true ) {
    this.isPartnerSetted = !canEdit;
    this.partnerId = id;
  }

  public getPartnerById(id) {

    if (typeof id === "undefined")
      return ;

    if (this.partner?.id === id)
      return this.partner;

    this.partner = this.partnerList.find( i => i.id === id);
    return this.partner;
  }

  // Установка только одного значения
  public setOneSelect( value = true ) {
    this.selectOne = value;
  }

  public setCheckAll( value = false ) {
    this.canCheckAll = !!value;
    if (this.canCheckAll)
      this.setOneSelect(false)
  }

  public initSelected( values: any ) {

    this.selectedCampaigns.clear();

    if ( values === true)
      this.selectedAll = true;

    if ( Array.isArray(values)) {

      values.forEach( val => {
        this.selectedCampaigns.set(val.id, val);
      })

    }

    return;
  }

  get hasSelected() {
    let selectedMap = this.selectedCampaigns;
    return selectedMap ? !!this.selectedCampaigns?.size : false
  }

  get isEditMode() {
    return typeof this.editCallBack === "function"
  }

  public setMax( value  ) {
    this.max = value;
  }

  public setCustomParams( value ) {
    this.customParams = value;
  }

  getPartnerList() {
    this.partnerService
        .query$()
        .subscribe({
          next: result => {
            this.partnerList = result.map( i => ({
                id: i.id,
                value: i.name,
              })
            );

            if ( typeof this.partnerId === "undefined")
              this.partnerId = this.partnerList[0]?.id;

          }

        })
  }

  cancel() {
    this.activeModal.dismiss(false);
  }

  async select() {

    this.loader.startLoading('selectPrepare');

    try {
      let result = {
        partnerId: this.partnerId,
        ids: Array.from(this.selectedCampaigns.keys()),
        campaigns: Array.from(this.selectedCampaigns.values())
      };
      this.activeModal.close( result );
      this.loader.stopLoading('selectPrepare');
    } catch (e) {
      this.loader.stopLoading('selectPrepare');
    }

  }

  public forCallbacks = (value) => {
    if (!this.isEditMode)
      return

    this.editCallBack(value);
  }

  public changeSelectedStates(id, value) {

      if (value) {

        if (this.selectedStates.indexOf(id) <0 )
          this.selectedStates = this.selectedStates.slice();
          this.selectedStates.push(id)
      } else {

        if (this.selectedStates.indexOf(id) >=0 )
          this.selectedStates = this.selectedStates.filter( i => i !== id)

      }

  }

  public changeSelectedStatesAll( value) {

    this.selectedStates = value ? Object.keys(TRIGGER_STATE_NAMES) : []

  }

}
