/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LocationGroupLinkApiConfiguration, LocationGroupLinkApiConfigurationInterface } from './location-group-link-api-configuration';

import { LocationGroupLinkApiService } from './services/location-group-link-api.service';

/**
 * Provider for all LocationGroupLinkApi services, plus LocationGroupLinkApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    LocationGroupLinkApiConfiguration,
    LocationGroupLinkApiService
  ],
})
export class LocationGroupLinkApiModule {
  static forRoot(customParams: LocationGroupLinkApiConfigurationInterface): ModuleWithProviders<LocationGroupLinkApiModule> {
    return {
      ngModule: LocationGroupLinkApiModule,
      providers: [
        {
          provide: LocationGroupLinkApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
