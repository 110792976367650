import 'model/GroupModel/resource'
import 'model/CampaignModel/resource'
import 'model/CouponRule'

import l11n from './l11n/ru_RU'

enum LimitTypeEnum {
    none       = "none",
    byCampaign = "bycampaign",
    completeProhibit  = "completeProhibit",
    byCashDiscount = "byCashDiscount",
    ignoreAllProhibit = "ignoreAllProhibit"
}

export class GroupPaymentLimitation {

    // Передаваемые параметры в компонент
    public groupId : number;
    public state   : string;

    private activeGroupsList    : any[];
    private selectedCampaign    : any;

    private LimitTypeEnum = LimitTypeEnum;
    private limitType : LimitTypeEnum = LimitTypeEnum.none;
    private campaigns : any[];

    private isEditable = false;

    private limitTypeList  =  {
        [LimitTypeEnum.none]       : l11n.limitType[LimitTypeEnum.none]       || '',
        [LimitTypeEnum.byCampaign] : l11n.limitType[LimitTypeEnum.byCampaign] || '',
        [LimitTypeEnum.completeProhibit]  : l11n.limitType[LimitTypeEnum.completeProhibit]  || '',
        [LimitTypeEnum.byCashDiscount]  : l11n.limitType[LimitTypeEnum.byCashDiscount]  || '',
        [LimitTypeEnum.ignoreAllProhibit]  : l11n.limitType[LimitTypeEnum.ignoreAllProhibit]  || '',
    };

    static $inject = ['$scope', '$timeout', '$element','$q','GroupModel','CampaignModel','CouponRule' ];

    constructor(
        private $scope        : any,
        private $timeout      : any,
        private $element      : any,
        private $q            : any,
        private GroupModel    : any,
        private CampaignModel : any,
        private CouponRule    : any
    ) { }

    public $onInit() {

        this.$scope.$watchGroup( ['$ctrl.limitType', '$ctrl.isEditable'], (values) => {
          if (values[0] !== this.LimitTypeEnum.byCampaign || !values[1])
            return;

          this.getActiveCampaigns();
        })

    }

    public getActiveCampaigns() {

        if ( typeof this.groupId === "undefined" ) return;

        if ( Array.isArray(this.activeGroupsList) )
          return;

        this.activeGroupsList = this.GroupModel.getActiveCampaigns({
            id: this.groupId
        });

        return this.$q.all( [

            this.CampaignModel.query({

                filter : { states: ["draft", "suspended", "unsuspend", "planned", "started", "active"] },
                pager  : {
                    drop  : 0,
                    limit : 99999
                }

            }).$promise
                .then( (result: any) =>

                    result.map( (c: any ) => {
                        return {
                            source      : "campaign",
                            id          : c.campaign.id,
                            name        : c.campaign.name,
                            description : c.campaign.description,
                            startDate   : c.campaign.campaignTime.startDate,
                            stopDate    : c.campaign.campaignTime.stopDate
                        }
                    })
                ),

            this.CouponRule.query({
                'filter.states': 'draft,active',
                'pager.drop'  : 0,
                'pager.limit' : 99999
            })
                .$promise
                .then( (result: any) =>
                    result.map( (c: any ) => {
                        return {
                            source      : "couponRule",
                            id          : c.couponRule.id,
                            name        : c.couponRule.name,
                            description : c.couponRule.description,
                            startDate   : c.couponRule.issueSettings.startDate,
                            stopDate    : c.couponRule.issueSettings.stopDate
                        }
                    })
                )
            ]
        ).then( (result: any) => this.activeGroupsList = [].concat(result[0],result[1]) )
         .catch( () => { });

    }

    public groupBySource(item: any) {

        switch (item.source) {
            case "campaign":
                return 'Кампании';
            case "couponRule":
                return 'Купонные правила';
        }

    };


    public getCampaigns() {

        if ( typeof this.groupId === "undefined" ) return;

        this.GroupModel.getPaymentLimit({
            id: this.groupId
         })
            .$promise
            .then(  (result: any) => this.updateFromService(result) )
            .catch( () => {
                this.limitType = LimitTypeEnum.none;
                this.campaigns = undefined;
            } );

    }

    public updateCampaigns() {

        if ( typeof this.groupId === "undefined" ) return;

        this.GroupModel.updatePaymentLimit({
            id: this.groupId
        }, this.campaigns)
            .$promise
            .then( ( result: any ) => this.campaigns = result );

    }

    public filterSelectedCampaings( campaign: any ) {

        if ( !this.campaigns ) return true;

        return !this.campaigns.some( ( c: any ) => c.id === campaign.id && c.source === campaign.source );
    }

    public addSelectedCampaignToLimit( selectedCampaign: any ) {

        if (!selectedCampaign )  return;

        this.campaigns = this.campaigns || [];

        // Проверка если уже добавлена
        if (this.campaigns.some( p => p.id === selectedCampaign.id && p.source === selectedCampaign.source )) return;

        this.campaigns.push(selectedCampaign);
        delete this.selectedCampaign;

        setTimeout(() => {

            let campaignContainer = this.$element.find('#campaignsContainer');
            if (campaignContainer.length) {
                campaignContainer[0].scrollTo(0, campaignContainer[0].scrollHeight)
            }

        },0);
    }

    public removeFromLimit( campaign: any ) {

        if (!campaign )  return;

        // Поиск позиции в массиве
        let position = this.campaigns.indexOf(campaign);

        if ( position < 0 ) return;

        this.campaigns.splice(position, 1);
    }

    public saveChanges() {

        if ( typeof this.groupId === "undefined" ) return;

        let model: any = {
            limitType: this.limitType
        }

        switch ( model.limitType ) {

            case LimitTypeEnum.byCampaign:
                model.campaigns = this.campaigns;
            break;

        }

        return this.GroupModel.updatePaymentLimit({
            id: this.groupId
        }, model, this.campaigns)
            .$promise
            .then( ( result: any ) => this.updateFromService(result) )
            .catch( () => {
                this.limitType = LimitTypeEnum.none;
                this.campaigns = undefined;
                return true;
            } )
            .then( () => {  this.isEditable = false; });

    }

    public $onChanges( changed:any ) {

        if ( changed.groupId ) {
            this.getCampaigns();
        }

    }

    private updateFromService( result: any ) {

        this.limitType = result.limitType;

        switch( this.limitType ) {

            case LimitTypeEnum.byCampaign:
                this.campaigns = result.campaigns;
                break;

            default:
                this.campaigns = undefined;
        }

    }

};
