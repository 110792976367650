<div class="modal-header  bg-light align-items-center"
     *ngIf="!isEditMode"
>

    <i class="fa fa-list text-success m-r-sm f-s-12"> </i>


    <div class=" relative">
      <div class=" overflow-wrap-break">
        <span class="h4"> Выбор товаров </span>

        <!-- TODO ВЫБОР ПАРТНЁРА -->

      </div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="">

      <button type="button" class="btn btn-sm btn-default m-r-xs btn-addon" data-testid="select()"
              (click)="select()"
              [disabled]="isLimitError()"
      >
        <i class=" fa fa-check"></i>
        <span class="btn-name"
        >Выбрать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default  " data-testid="cancel()"
              (click)="cancel()"
      >
        <!-- TODO подтверждение закрытия -->
        <i class=" fa fa-remove text-danger"></i>
      </button>

    </div>


</div>
<div class="modal-body goods-tree-body pb-0">

  <!-- Input block -->

  <div class=" m-b-sm" data-testid="searchGoods">
    <search-input
      [(model)]="searchString"
      placeholder="Поиск товара"
    ></search-input>
  </div>

  <div class="row "
     *ngIf="!isEditMode"
  >

      <div class="m-b-xs"
           [ngClass]="{ 'col-sm-6 col-md-4':showPartner, 'col-md-6 col-lg-8':!showPartner }">
        Представление
        <div class="m-t-xs">
          <select class="form-control w100p" data-testid="selectedViewType"
                  [ngModel]="currentView"
                  (ngModelChange)="changeView($event)"
          >
            <option *ngFor="let item of viewList " [ngValue]="item.id">{{item.label}}</option>
          </select>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 m-b-xs"
           *ngIf="showPartner"
      >
        Партнёр
        <div class="m-t-xs">
          <select
            [(ngModel)]="partnerId"
            [disabled]="isPartnerSetted"
            class="form-control w100p"  data-testid="selectedPartner">
              <option *ngFor="let item of partnerList" [ngValue]="item.id">{{item.value}}</option>
          </select>
        </div>
      </div>

      <div class="m-b-xs" [ngClass]="{ 'col-sm-12 col-md-4':showPartner, 'col-md-6 offset-md-0 col-lg-4 ':!showPartner }"
           *ngIf="currentView === VIEW_TYPES.SKU "
      >
         <manual-good-input
           [selected]="getSelectedMap"
           [currentView]="currentView"
           [max]="maximumLimit"
           [partnerId]="partnerId"
           [selectOne]="selectOne"
         ></manual-good-input>
      </div>


</div>

  <!-- Info block -->

  <div class="clearfix"></div>

  <div class="row m-t-sm ">

    <div class="col-sm-12  col-md-7 " >

      <ng-container
        *ngIf="!isEditMode"
        >

        <span class=" block text-muted"
              *ngIf="!hasSelected"
        >
          Нет выбранных элементов
        </span>

        <div
          class=" block "
          [ngClass]="{'text-danger': isLimitError()}"
          *ngIf="hasSelected"
        >
          <ng-container
            [ngSwitch]="currentView"
          >
            <span
              *ngSwitchCase="VIEW_TYPES.SKU"
            >
              <span class="text-muted m-r-xs">Выбрано SKU:</span> <b>{{selectedSku.size}}</b>
            </span>

            <span
              *ngSwitchCase="VIEW_TYPES.CATEGORY"
            >
               <span class="text-muted m-r-xs">Выбрано категорий:</span> {{selectedCategory.size}}
            </span>

            <span
              *ngSwitchCase="VIEW_TYPES.BRAND"
            >
               <span class="text-muted m-r-xs">Выбрано брендов:</span> {{selectedBrand.size}}
            </span>

            <span
              *ngSwitchCase="VIEW_TYPES.GOODGROUP"
            >
               <span class="text-muted m-r-xs">Выбрано списков:</span> {{selectedGoodGroup.size}}
            </span>

            <span
              *ngSwitchCase="VIEW_TYPES.GOODGROUP_SKU"
            >
               <span class="text-muted m-r-xs">Выбрано списков:</span> {{selectedGoodGroup.size}}
            </span>


          </ng-container>

          <span *ngIf="maximumLimit>=0">
            из {{maximumLimit}}
          </span>

        </div>

      </ng-container>

      <ng-container
        *ngIf="isEditMode"
      >

        <span class="text-muted">
          Выберите SKU или категорию для редактирования
        </span>

      </ng-container>

    </div>

    <span class="col-sm-12 col-md-5 text-left-xs p-r-sm f-s-9  text-right"
         *ngIf="currentView === VIEW_TYPES.SKU && !!sortingField"
    >

      <span class="dropdown  m-t-xxs"  ngbDropdown>
        <span class="text-muted m-t-xxs m-r-xs" >Сортировать:</span>
        <span class="">
          <a href="javascript:void(0)" class="item-toggle "
             id="winner-dropdown"
             ngbDropdownToggle>
            <span class="" [innerText]="sortingTypes[sortingField]"></span>
            <i class="fa fa-caret-down m-l-xs m-r-xs"></i>
          </a>
        </span>

        <ul class="dropdown-menu"
            ngbDropdownMenu
            aria-labelledby="winner-dropdown" data-testid="setSorting(key)">
          <li
            *ngFor="let item of sortingTypes | keyvalue"
            [ngClass]="{'active': sortingField === item.key}"
          >
            <a href="javascript:void(0)"
               ngbDropdownItem
               [title]=" item.value "
               (click)="sortingField = item.key"
               [innerText]=" item.value "></a>
          </li>

        </ul>

      </span>

    </span>

  </div>

  <!-------Type blocks ----------->

  <div class="type-block-parent mt-3">

    <div class="type-block-container">

      <ng-container
        *ngIf="currentView === VIEW_TYPES.SKU">

        <goods-tree-view
          [partnerId]="partnerId"
          [showType]="VIEW_TYPES.SKU"

          [(selected)]="selectedSku"

          [searchQuery]="searchString"
          [max]="maximumLimit"
          [dimension] = "dimension"
          [isSkuHide] = "isSkuHide"
          [isShowArchiveSku] = "isShowArchiveSku"
          [selectOne] = "selectOne"
          [(sortingField)] = "sortingField"
          [editCallback]="isEditMode ? forCallbacks : false"
        ></goods-tree-view>

      </ng-container>

      <ng-container
        *ngIf="currentView === VIEW_TYPES.CATEGORY">

        <goods-tree-view
          [partnerId]="partnerId"
          [showType]="VIEW_TYPES.CATEGORY"

          [(selected)]="selectedCategory"

          [searchQuery]="searchString"

          [max]="maximumLimit"
          [dimension]="dimension"
          [isSkuHide] = "isSkuHide"
          [isShowArchiveSku] = "isShowArchiveSku"
          [selectOne] = "selectOne"
          [(sortingField)] = "sortingField"
          [editCallback]="isEditMode ? forCallbacks : false"
        ></goods-tree-view>

      </ng-container>

      <ng-container
        *ngIf="currentView === VIEW_TYPES.BRAND">
        <brand-view
          [partnerId]="partnerId"
          [(selected)]="selectedBrand"

          [searchQuery]="searchString"

          [max]="maximumLimit"
          [selectOne] = "selectOne"
        ></brand-view>
      </ng-container>

      <ng-container
        *ngIf="currentView === VIEW_TYPES.GOODGROUP || currentView === VIEW_TYPES.GOODGROUP_SKU">
        <goodgroup-view
          [partnerId]="partnerId"
          [(selected)]="selectedGoodGroup"

          [searchQuery]="searchString"

          [max]="maximumLimit"
          [selectOne] = "selectOne"
        ></goodgroup-view>
      </ng-container>

      <ng-container
        *ngIf="currentView === VIEW_TYPES.FAVOURITE">
        <favourite-view>
        </favourite-view>
      </ng-container>

    </div>
  </div>


</div>





<!--


<div class="details-screen">

  <div class="wrapper-md p-b-none p-t-none b-b">
    <div class=" row m-b-sm m-t-sm">

      <a ng-show="selectGoodsNamespace.searchState" class="btn btn-link pull-right m-t-sm m-r-md link-grey visible-sm visible-xs no-padder"
         ui-toggle-class="menu-show" data-target=".details-screen">
        <i class="fa fa-bars">
        </i>
      </a>

      <div class="" ng-class=" { 'col-sm-8 col-md-9': rejectSelect && confirmSelect,'col-sm-12': !(rejectSelect && confirmSelect) }">
        <div class="h4 relative">
          <div class="pull-left m-t-n-xxs m-b-xs"><i class="fa fa-list text-success m-r-sm f-s-12"> </i></div>
          <div class="m-t-xs overflow-wrap-break">Выбор товаров
            <span
              loya-permit-if="partner"
              permit-enabled=""
              permit-and-if="partnersList.name"> для партнёра
                            <span class="text-success " >{{ partnersList.name }} </span>
                        </span>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-3" ng-if="rejectSelect && confirmSelect" >
        <button type="button" class="btn btn-sm btn-default pull-right m-t-xs"
                ng-confirm-click="{
                          'title': 'Вы действительно хотите закрыть окно?',
                          'message': 'При закрытии окна список выбранных товаров будет сброшен'
                         }"
                ng-confirm-disabled="hasErrors() || !selectedLength"
                ng-click="rejectSelect()" >
          <i class=" fa fa-remove text-danger"></i>
        </button>
        <button type="button" class="btn btn-sm btn-default m-r-xs pull-right m-t-xs btn-addon"
                ng-click="confirmSelect()"
                ng-disabled="hasErrors()"
        >
          <i class=" fa fa-check"></i>
          <span class="btn-name">Выбрать</span>
        </button>
      </div>

    </div>
  </div>


  <div class=" clearfix ">
    <div class="hbox hbox-auto-xs m-t m-b-xs">
      <div class="col">
        <div class="input-group padder-md ">
          <input type="text"
                 class="form-control"
                 id="theq"
                 title="Search"
                 ng-model="selectGoodsNamespace.searchByClassif"
                 ng-keypress="hookEnterFindClassif($event)"
                 placeholder="{{ getSearchPlaceholder() }}"
                 ng-disabled="!selectGoodsNamespace.selectedPartner || ( selectGoodsNamespace.selectedViewType && ['sku', 'category', 'brand', 'custom'].indexOf(selectGoodsNamespace.selectedViewType )  < 0 )"
                 key-press-filter="[a-zA-Zа-яА-ЯеЁ\d\*_-\s\.]" >

          <span class="input-group-btn">
                            <button class="btn btn-danger"
                                    type="button"
                                    ng-if="selectGoodsNamespace.searchByClassif && !!selectGoodsNamespace.searchByClassif"
                                    ng-click="selectGoodsNamespace.searchByClassif=''">
                                <i class="fa fa-times"></i>
                            </button>
                            <button id="db-search"
                                    class="btn btn-default btn-addon w-sm "
                                    type="button"
                                    ng-disabled="!selectGoodsNamespace.selectedPartner || ( selectGoodsNamespace.selectedViewType && ['sku', 'category', 'brand', 'custom'].indexOf(selectGoodsNamespace.selectedViewType ) < 0 ) "
                                    ng-click="findByClassif()">
                                <i class="fa fa-search"></i>
                                <span class="btn-name">Поиск</span>
                            </button>
                        </span>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper-md p-t-none">
    <div class="row m-t-sm">

      <div ng-if="viewType.length" class="m-b-xs" ng-class="{ 'col-sm-6 col-md-4':showPartner, 'col-md-6 col-lg-8':!showPartner }">
        Представление
        <div class="m-t-xs">
          <select ng-init="selectGoodsNamespace.selectedViewType = selectGoodsNamespace.selectedViewType || viewType[0].id"
                  class="form-control w100p"
                  ng-model="selectGoodsNamespace.selectedViewType"
                  ng-options="vt.id as vt.name for vt in viewType" >

          </select>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 m-b-xs" ng-if="showPartner">
        Партнёр
        <div class="m-t-xs">
          <select
            class="form-control w100p"
            ng-model="selectGoodsNamespace.selectedPartner"
            ng-options="p.id as p.name for p in partnersList" >
          </select>
        </div>
      </div>

      <div class="m-b-xs" ng-class="{ 'col-sm-12 col-md-4':showPartner, 'col-md-6 offset-md-0 col-lg-4 ':!showPartner }"
           ng-if="selectGoodsNamespace.selectedViewType === 'sku'" >
        Ручной ввод
        <div class="input-group m-t-xs w-full">
          <input type        = "text"
                 ng-model    = "$parent.skuForAdd"
                 placeholder = "SKU"
                 class       = "form-control"
                 ng-keydown  = "skuKeyDown($event)"
          >
          <span class="input-group-btn ">
                      <button class="btn btn-small  w-sm btn-success btn-addon" ng-click="addSku()">
                          <i class="fa fa-plus"></i>
                          Добавить
                      </button>
                    </span>
        </div>
        <div class="text-danger col-sm-12 f-s-9 no-padder pointer"
             ng-if="addError"
             ng-bind="addError"
             ng-click="$parent.addError=''">
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="col-sm-12  col-sm-7 m-t-sm" >
        <span class="text-muted" ng-show="!selectedLength && selectGoodsNamespace.selectedViewType!=='favourite'">Нет выбранных элементов</span>
        <span class="text-muted" ng-show="selectedLength">Выбрано элементов: {{ selectedLength }}</span>
        <span class=" block f-s-8 text-info-dker" ng-show="internalMax && selectedLength && !checkOne">Максимум ({{internalMax}})</span>

        <span class=" block f-s-8"
              ng-class="{ 'text-danger': selectedSkuMaxCustomLength < selectedSkuCustomLength, 'text-muted': selectedSkuMaxCustomLength >= selectedSkuCustomLength }"
              ng-if="!!selectedLength && !!selectedSkuCustomLength && hasCustomSku()">Выбрано SKU {{selectedSkuCustomLength}} из {{ selectedSkuMaxCustomLength }}</span>

      </div>

      <div class="col-sm-12 text-left-xs col-sm-5 f-s-9 m-t-sm text-right" ng-if="showTree">

        <span class="text-muted m-t-xxs" >Сортировать:</span>
        <span class="dropdown  block-inline m-t-xxs" uib-dropdown>

                    <a href="" class="no-shadow  pointer" uib-dropdown-toggle>
                         <span ng-bind="sortingTypes[sortingField]" class=""></span>
                         <span class="caret"></span>
                    </a>

                    <ul class="dropdown-menu dropdown-menu-right scroll-y mini-scroll" role="menu" aria-labelledby="single-button"
                        style="max-height: 500px;">
                        <li role="menuitem" ng-repeat="(key, name) in sortingTypes">
                            <a href ng-bind="::name" ng-click="setSorting(key)"></a>
                        </li>
                    </ul>
                </span>

      </div>

    </div>
  </div>

&lt;!&ndash;

  <div class="ivh-modalcanvas b-t" ng-if="showTree&&!linkOne&&!selectGoodsNamespace.searchState">

    <div class="row">
      <div class="padder clearfix">
        <div class="hbox hbox-auto-xs">

          <div class="col">
            <div class="ivh-tree-container relative">
              <div ivh-treeview="stuff"
                   ivh-treeview-expand-to-depth="1"
                   ivh-treeview-max-select="internalMax"
                   ivh-treeview-filter="myQuery"
                   ivh-treeview-options="customOpts"
                   ivh-treeview-change-handler="itemChangeHandler"
                   ivh-treeview-click-handler="nodeClick">
              </div>
            </div>
          </div>

          <div class="col col-lg-4 no-padder b-l" ng-if="selectGoodsNamespace.selectedViewType === 'sku' && (returnResult.list && returnResult.list.length) && !isSkuHidden() ">

            <table  class="table  m-b-none m-t-n-xxs b-b m-b-n-xxs footable-visible footable-sortable footable"
                    data-page-size="99999"
                    data-filter="#filter" >
              <thead>
              <tr>
                <th>
                                    <span>
                                      SKU
                                    </span>
                </th>
                <th style="width: 100%;">
                                    <span>
                                      Название
                                    </span>
                </th>
                <th  data-sort-ignore="true">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="good in returnResult.list" ng-click="nodeClickActionItem(good)">
                <td ng-bind="::good.id"></td>
                <td >
                                    <span ng-bind="::good.label">
                                    </span>
                </td>
                <td>
                  <button ng-click="removeUserSku(good)" class="btn btn-danger btn-sm m-t-n-xs m-b-n-xs">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="ivh-modalcanvas b-t" ng-if="showTree&&linkOne&&!selectGoodsNamespace.searchState">

    <div class="ivh-tree-container relative">
      <div ivh-treeview="stuff"
           ivh-treeview-expand-to-depth="1"
           ivh-treeview-filter="myQuery"
           ivh-treeview-max-select="internalMax"
           ivh-treeview-options="customOpts"
           ivh-treeview-click-open-handler="nodeClickOne"
           ivh-treeview-click-action-handler="nodeClickAction"
           ivh-treeview-click-action-item-handler="nodeClickActionItem">
      </div>
    </div>
  </div>

  <div class="ivh-modalcanvas b-t" ng-if="selectGoodsNamespace.searchState && ( !selectGoodsNamespace.selectedViewType || selectGoodsNamespace.selectedViewType && ['sku', 'category'].indexOf(selectGoodsNamespace.selectedViewType ) >= 0 )">
    <div class = "row">
      <div class="padder clearfix">
        <div class="hbox hbox-auto-xs">

          <div class="col" ng-if="showTree&&!linkOne">
            <div class=" noscroll ">
              <div class="ivh-tree-container relative">
                <div ivh-treeview="stuffCategory"
                     ivh-treeview-expand-to-depth="1"
                     ivh-treeview-filter="myQuery"
                     ivh-treeview-max-select="internalMax"
                     ivh-treeview-options="customOpts"
                     ivh-treeview-change-handler="itemChangeHandler"
                     ivh-treeview-click-handler="nodeClick">
                </div>
              </div>

            </div>
          </div>

          <div class="col" ng-if="showTree&&linkOne">
            <div class=" noscroll">
              <div class="ivh-tree-container relative">
                <div ivh-treeview="stuffCategory"
                     ivh-treeview-expand-to-depth="1"
                     ivh-treeview-filter="myQuery"
                     ivh-treeview-max-select="internalMax"
                     ivh-treeview-options="customOpts"
                     ivh-treeview-click-open-handler="nodeClickOne"
                     ivh-treeview-click-action-handler="nodeClickAction">
                </div>
              </div>

            </div>
          </div>

          <div class="col b-l" ng-if="(stuffGood.length || stuffCategory.length ) && !isSkuHidden() ">
            <table  class="table m-b-none b-b m-t-n-xxs footable-visible footable-first-column footable-sortable footable table-customers"
                    data-page-size="99999"
                    data-filter="#filter" >
              <thead>
              <tr>
                <th class="indicator-hide" data-sort-ignore="true">
                  <label ng-show="customOpts.useCheckboxes(stuffGood[0])" class="i-checks m-b-none" >
                    <input type="checkbox" ng-model="selectGoodsNamespace.selectedAllGood" ><i></i>
                  </label>
                </th>
                <th >
                        <span>
                          SKU
                        </span>
                </th>
                <th class="w-full">
                        <span>
                          Название
                        </span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="good in stuffGood" ng-click="nodeClickActionItem(good)"
                  ng-class="::{'text-danger':isGoodArchive(good)}"
                  ng-if="::!showArchive && isGoodArchive(good) ? false : true "
              >
                <td>

                  <div ng-show="customOpts.useCheckboxes(good)" >
                    <label class="i-checks">
                      <input type="checkbox"
                             ng-model="good.selected"
                             ng-class="{ 'indeterminate': indeterminateArray[good.id] ? true : false, 'checked':good.selected }"
                             ng-change="itemChangeHandler(good, good.selected )"
                             ng-disabled="brandDisabled(good)"
                      />
                      <i></i>
                    </label>
                  </div>
                  <div ng-show="customOpts.useRadio(good)" >
                    <label class="i-checks">
                      <input type="checkbox"
                             class="i-radio"
                             ng-model="good.selected"
                             ng-change="itemChangeHandler(good, good.selected)" />
                      <i></i>
                    </label>
                  </div>

                </td>
                <td>{{good.quantity}}</td>
                <td>
                  <i class="fa fa fa-archive" ng-if="::isGoodArchive(good)"></i>
                  <span ng-bind-html="good.label"></span>
                  <div class="clearfix m-t-n-xxs f-s-8" ng-if="::isGoodArchive(good)" >Архивный товар</div>
                </td>
              </tr>
              <tr ng-if="stuffGood.length && showNextButton()">
                <td colspan='3'>
                  <button class="btn btn-default btn-addon m-l-n" ng-click="customOpts.nextEvent(stuffGood[0])">
                    <i class="fa fa-plus"></i>Следующие 30
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="" ng-if="showGrid && showBrand">

    <table  class="table m-b-none b-b footable-visible footable-first-column footable-sortable footable table-customers"
            data-page-size="99999"
            data-filter="#filter"
            infinite-scroll="queryBrandWindow()"
            infinite-scroll-distance="1"
            infinite-scroll-container="'.modal.fade'"
    >
      <thead>
      <tr>
        <th class="indicator-hide">

        </th>
        <th>
                        <span>
                          Бренд
                        </span>
        </th>
        <th>
                        <span>
                          Описание
                        </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr ng-repeat="brandtItem in brandList">
        <td width="2%" align="center" >
          &lt;!&ndash;   <div class="checkbox">
                 <label class="i-checks">
                     <input type="radio" ng-value="brandtItem" ng-model="selectGoodsNamespace.checkedBrand">
                     <i></i>
                 </label>
             </div>
&ndash;&gt;
          <div class="checkbox">
            <label class="i-checks">
              <input type="checkbox"
                     ng-value="brandtItem"
                     ng-model="selectGoodsNamespace.checkedBrand[brandtItem.id]"
                     ng-disabled="brandDisabled(brandtItem)"
              >
              <i></i>
            </label>
          </div>

        </td>
        <td class=" table-big-link"  data-value="">
          {{ brandtItem.name  }}
        </td>

        <td class="table-big-link">
          {{ brandtItem.description  }}
        </td>
      </tr>

      </tbody>
    </table>




  </div>

  <div class="" ng-if="showGrid && showCustom">

    <table class="table m-b-none b-b footable-visible footable-first-column footable-sortable table-customers"
           infinite-scroll="queryCustomWindow()"
           infinite-scroll-distance="1"
           infinite-scroll-container="'.modal.fade'">
      <thead>
      <tr>
        <th class="indicator-hide">

        </th>
        <th>
                        <span>
                          Список
                        </span>
        </th>
        <th>
                        <span>
                          Описание
                        </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr ng-repeat="customItem in customList">
        <td width="2%" align="center" >
          <div class="checkbox">
            <label class="i-checks">
              <input type="checkbox"
                     ng-value="customItem"
                     ng-model="selectGoodsNamespace.checkedCustom[customItem.id]"
                     ng-disabled="customDisabled(customItem)"
              >
              <i></i>
            </label>
          </div>
        </td>
        <td class=" table-big-link"  data-value="">
          {{ customItem.name  }}
        </td>

        <td class="table-big-link">
          {{ customItem.description  }}
        </td>
      </tr>

      </tbody>
    </table>

  </div>

  <div class="wrapper-md p-t-none" ng-if="loadingPromise.$$state && loadingPromise.$$state.status === 0">
    <div class="row">
      <div class="col-sm-12 m-t-lg m-b  text-center ">
        <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
      </div>
    </div>
  </div>

  <div class="wrapper-md p-t-none" ng-if="selectGoodsNamespace.selectedViewType=='favourite'">
    <div class="row">
      <div class="col-sm-12 m-t m-b-xl text-center ">
        <h4>Товары будут выбраны в соответствии с личными предпочтениями покупателя</h4>
      </div>
    </div>
  </div>
&ndash;&gt;

</div>


-->
