/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for HousewarmingApi services
 */
@Injectable({
  providedIn: 'root',
})
export class HousewarmingApiConfiguration {
  rootUrl: string = location.origin;
}

export interface HousewarmingApiConfigurationInterface {
  rootUrl?: string;
}
