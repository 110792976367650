import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TransactionApiService} from "../../../../api/TransactionApi/services";
import {Brand} from "../../../../api/BrandApi/models";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import TransactionsParams = TransactionApiService.TransactionsParams;
import TransactionsByUserParams = TransactionApiService.TransactionsByUserParams;


@Injectable()
export class AccountTransactionService {

  constructor( private transactionApiService:TransactionApiService ) {
  }

  public getListParams = new ParamsHandler({
      filters: [
        {
          field: 'from',
          currentValue: undefined
        },
        {
          field: 'to',
          currentValue: undefined
        }
      ]
    });

  public getByUserParams = new ParamsHandler({
    pager: {
      dropField: 'drop',
      limitField: 'limit',
      limit: 50,
      type:"string"
    },
    filters: [
      {
        field: 'from',
        currentValue: undefined
      },
      {
        field: 'to',
        currentValue: undefined
      }
    ]
  });

  public getList$(params: TransactionsParams){
    return this.transactionApiService.transactions(params)
  }

  public getByUser$( params: TransactionsByUserParams ) {
    return this.transactionApiService.transactionsByUser(params);
  }

  public exportList(params: TransactionsParams){
    let query_params  = new URLSearchParams();

    if ( typeof params.from !== "undefined")
      query_params.append('from', params.from);

    if ( typeof params.to !== "undefined")
      query_params.append('to', params.to);

    window.location.assign('/transactions/export?' + query_params.toString());

  }

  public exportByUser( params: TransactionsByUserParams ) {

    let query_params  = new URLSearchParams();

    if ( typeof params.from !== "undefined")
      query_params.append('from', params.from);

    if ( typeof params.to !== "undefined")
      query_params.append('to', params.to);

    window.location.assign('/transactions/'+params.userId+'/export?' + query_params.toString());

  }

}
