/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AccPastYearApiConfiguration as __Configuration } from '../acc-past-year-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Profile } from '../models/profile';
import { Settings } from '../models/settings';
@Injectable({
  providedIn: 'root',
})
class AccPastYearApiService extends __BaseService {
  static readonly queryProfilePath = '/clientprofile/accpastyear/{clientId}';
  static readonly querySettingsPath = '/clientprofile/accpastyear/settings';
  static readonly setSettingsPath = '/clientprofile/accpastyear/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос профиля клиента
   * @param clientId ID клиента
   * @return Значение настроек модуля
   */
  queryProfileResponse(clientId: number): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientprofile/accpastyear/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param clientId ID клиента
   * @return Значение настроек модуля
   */
  queryProfile(clientId: number): __Observable<Profile> {
    return this.queryProfileResponse(clientId).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * Запрос настроек
   * @return Значение настроек модуля
   */
  querySettingsResponse(): __Observable<__StrictHttpResponse<Settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientprofile/accpastyear/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Settings>;
      })
    );
  }
  /**
   * Запрос настроек
   * @return Значение настроек модуля
   */
  querySettings(): __Observable<Settings> {
    return this.querySettingsResponse().pipe(
      __map(_r => _r.body as Settings)
    );
  }

  /**
   * Сохранить настройки
   * @param template Значение настроек модуля
   * @return Настройки сохранены
   */
  setSettingsResponse(template: Settings): __Observable<__StrictHttpResponse<Settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = template;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clientprofile/accpastyear/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Settings>;
      })
    );
  }
  /**
   * Сохранить настройки
   * @param template Значение настроек модуля
   * @return Настройки сохранены
   */
  setSettings(template: Settings): __Observable<Settings> {
    return this.setSettingsResponse(template).pipe(
      __map(_r => _r.body as Settings)
    );
  }
}

module AccPastYearApiService {
}

export { AccPastYearApiService }
