'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.crm.accounts.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .template( '<accaunts-index-page></accaunts-index-page>', true)

        .breadcrumbs(  [ "Клиенты", "Счета" ] )
        .permissions( {
          only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator],

        })

    }]);

