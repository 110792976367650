/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CampaignCategoryApiConfiguration, CampaignCategoryApiConfigurationInterface } from './campaign-category-api-configuration';

import { CampaignCategoryApiService } from './services/campaign-category-api.service';

/**
 * Provider for all CampaignCategoryApi services, plus CampaignCategoryApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CampaignCategoryApiConfiguration,
    CampaignCategoryApiService
  ],
})
export class CampaignCategoryApiModule {
  static forRoot(customParams: CampaignCategoryApiConfigurationInterface): ModuleWithProviders<CampaignCategoryApiModule> {
    return {
      ngModule: CampaignCategoryApiModule,
      providers: [
        {
          provide: CampaignCategoryApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
