/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PreferenceApiConfiguration as __Configuration } from '../preference-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PreferenceInternalApi } from '../models/preference-internal-api';
import { AdditionalServicesInternal } from '../models/additional-services-internal';
@Injectable({
  providedIn: 'root',
})
class PreferenceApiService extends __BaseService {
  static readonly getProfilePath = '/clientprofile/preference/{clientId}';
  static readonly updateProfilePath = '/clientprofile/preference/{clientId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Профиль клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getProfileResponse(clientId: number): __Observable<__StrictHttpResponse<PreferenceInternalApi>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientprofile/preference/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PreferenceInternalApi>;
      })
    );
  }
  /**
   * Профиль клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getProfile(clientId: number): __Observable<PreferenceInternalApi> {
    return this.getProfileResponse(clientId).pipe(
      __map(_r => _r.body as PreferenceInternalApi)
    );
  }

  /**
   * Обновить профиль клиента
   * @param params The `PreferenceApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   */
  updateProfileResponse(params: PreferenceApiService.UpdateProfileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.profile;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clientprofile/preference/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновить профиль клиента
   * @param params The `PreferenceApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   */
  updateProfile(params: PreferenceApiService.UpdateProfileParams): __Observable<null> {
    return this.updateProfileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PreferenceApiService {

  /**
   * Parameters for updateProfile
   */
  export interface UpdateProfileParams {

    /**
     * Данные профиля клиента
     */
    profile: {additionalServices?: AdditionalServicesInternal};

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { PreferenceApiService }
