import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnChanges
} from "@angular/core";
import {ngCurrencyL10nService} from "../../service/ngCurrencyL10n/ngCurrencyL10n.service";

@Directive({
  selector: '[ngCurrencyLoya]',
  providers: [ngCurrencyL10nService]
})
export class NgCurrencyLoyaDirective implements OnInit {


  constructor(
    private el: ElementRef,
    private ngCurrencyL10n: ngCurrencyL10nService
  ) {
  }


  ngOnInit() {
    this.ngCurrencyL10n
      .getIcon()
      .then( i => this.el.nativeElement.classList.add( i ) );

  }

}


@Directive({
  selector: '[ngCurrencyNameLoya]',
  providers: [ngCurrencyL10nService]
})
export class NgCurrencyNameLoyaDirective implements OnInit {

  constructor(
    private el: ElementRef,
    private ngCurrencyL10n: ngCurrencyL10nService
  ) {
  }

  ngOnInit() {
    this.ngCurrencyL10n
      .getShortName()
      .then( i => this.el.nativeElement.innerHTML = i );

  }

}
