import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule, NgbModule, NgbNavModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../../shared/directive/directive.module";
import {downgradeComponent} from "@angular/upgrade/static";
import {SystemModulesConfigPageComponent} from "./system-modules-config-page.component";
import {SearchInputModule} from "../../../../shared/component/search-input/search-input.module";
import {FiltersComponentsModule} from "../../../../shared/component/filters/filters.components.module";
import {TranslocoModule} from "@ngneat/transloco";
import {Ng1ControllerModule} from "../../../../shared/component/ng1-controller/ng1-controller.module";

@NgModule({
  declarations: [
    SystemModulesConfigPageComponent,
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    HttpClientModule,
    InfiniteScrollModule,
    CommonModule,
    SharedComponentsModule,
    PipesComponentsModule,
    DirectiveModule,
    NgbNavModule,
    NgbPopoverModule,
    SearchInputModule,
    FiltersComponentsModule,
    TranslocoModule,
    Ng1ControllerModule,
  ],
  providers: [],
})
export class SystemModulesConfigPageModule {

  constructor() {}

}

angular.module('loya.builder')
  .directive('systemModulesConfigPage' , downgradeComponent({ component: SystemModulesConfigPageComponent  }) )

