
export class ObjectUtils{

  static deepExtend(destination, source) {
    if (Array.isArray(source)) {
      destination = (Array.isArray(destination) ? destination : []).concat(source);
    } else if ( typeof source === "object" ) {
      for (var property in source) {
        destination[property] = ObjectUtils.deepExtend(destination[property] || {}, source[property] );
      }
    } else {
      destination = source;
    }
    return destination;
  }

}
