<div class="input-group">
  <ng-select [(ngModel)]="id"
             [items]="filteredItems"
             [virtualScroll]="true"
             [loading]="loading"
             bindLabel="name"
             bindValue="id"
             [name]="name"
             [placeholder]="placeholder"
             (scroll)="onScroll($event)"
             (scrollToEnd)="onScrollToEnd()"
             (search) = "onSearch($event)"
             (change) = "onChange()"
             (clear) = "clear()"
             (close) = "clear()"
             [disabled]="disabled"
             [required]="required"
             [class.ng-invalid]="!isValid || (isEmpty && required)"
             [multiple]="multiple"

  >

    <ng-template ng-label-tmp let-item="item">

      <div class="hbox hbox-auto">

        <div class="col w-full">
          <div class="clearfix">
            {{ item.name }}
          </div>

        </div>

      </div>

    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">

      <div class="hbox hbox-auto">

        <div class="col w-full">

          <div class="clearfix">
            <search-highlight
              [model]="lastSearchText || ''"
              [text]="item.name"
            ></search-highlight>
          </div>

        </div>

      </div>

    </ng-template>
  </ng-select>

  <span class="input-group-btn"  *ngIf="!required && !isEmpty">
    <button
      class="btn btn-danger m-b-none m-t-none"
      type="button"
      (click)="setEmpty()"
    >
      <i class="fa fa-times"></i>
    </button>
  </span>

</div>

<input type="hidden" [required]="required" [name]="'selectLogationGroupDummy'" [(ngModel)]="id">
