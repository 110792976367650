/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ClientCategoryApiConfiguration as __Configuration } from '../client-category-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientCategory } from '../models/client-category';
@Injectable({
  providedIn: 'root',
})
class ClientCategoryApiService extends __BaseService {
  static readonly createPath = '/clientcategory';
  static readonly queryClientCategoriesPath = '/clientcategory';
  static readonly getPath = '/clientcategory/{id}';
  static readonly updatePath = '/clientcategory/{id}';
  static readonly deletePath = '/clientcategory/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание категории клиентов
   * @param category Описание категории клиентов
   * @return Описание созданной категории клиентов
   */
  createResponse(category: ClientCategory): __Observable<__StrictHttpResponse<ClientCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = category;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clientcategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCategory>;
      })
    );
  }
  /**
   * Создание категории клиентов
   * @param category Описание категории клиентов
   * @return Описание созданной категории клиентов
   */
  create(category: ClientCategory): __Observable<ClientCategory> {
    return this.createResponse(category).pipe(
      __map(_r => _r.body as ClientCategory)
    );
  }

  /**
   * Получить список категорий клиентов
   * @param params The `ClientCategoryApiService.QueryClientCategoriesParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование категории клиентов
   *     - `description` - описание категории клиентов
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список категорий клиентов
   */
  queryClientCategoriesResponse(params: ClientCategoryApiService.QueryClientCategoriesParams): __Observable<__StrictHttpResponse<Array<ClientCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientcategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientCategory>>;
      })
    );
  }
  /**
   * Получить список категорий клиентов
   * @param params The `ClientCategoryApiService.QueryClientCategoriesParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование категории клиентов
   *     - `description` - описание категории клиентов
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список категорий клиентов
   */
  queryClientCategories(params: ClientCategoryApiService.QueryClientCategoriesParams): __Observable<Array<ClientCategory>> {
    return this.queryClientCategoriesResponse(params).pipe(
      __map(_r => _r.body as Array<ClientCategory>)
    );
  }

  /**
   * Получить категорию клиента по ID
   * @param id ID категории клиентов
   * @return Параметры категории клиентов
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<ClientCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clientcategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCategory>;
      })
    );
  }
  /**
   * Получить категорию клиента по ID
   * @param id ID категории клиентов
   * @return Параметры категории клиентов
   */
  get(id: number): __Observable<ClientCategory> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as ClientCategory)
    );
  }

  /**
   * Обновить категорию клиента по ID
   * @param params The `ClientCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание категории клиентов
   *
   * - `id`: ID категории клиентов
   *
   * @return Параметры категории клиентов
   */
  updateResponse(params: ClientCategoryApiService.UpdateParams): __Observable<__StrictHttpResponse<ClientCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.partner;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clientcategory/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientCategory>;
      })
    );
  }
  /**
   * Обновить категорию клиента по ID
   * @param params The `ClientCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partner`: Описание категории клиентов
   *
   * - `id`: ID категории клиентов
   *
   * @return Параметры категории клиентов
   */
  update(params: ClientCategoryApiService.UpdateParams): __Observable<ClientCategory> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as ClientCategory)
    );
  }

  /**
   * Удалить категорию клиента по ID
   * @param id ID категории клиентов
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clientcategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить категорию клиента по ID
   * @param id ID категории клиентов
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ClientCategoryApiService {

  /**
   * Parameters for queryClientCategories
   */
  export interface QueryClientCategoriesParams {

    /**
     * Порядок сортировки:
     *   - `asc`
     *   - `desc`
     *   - `reverse`
     */
    sortingOrder?: 'asc' | 'desc' | 'reverse';

    /**
     * Поле для сортировки:
     *   - `name` - наименование категории клиентов
     *   - `description` - описание категории клиентов
     */
    sortingField?: 'name' | 'description';

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Описание категории клиентов
     */
    partner: ClientCategory;

    /**
     * ID категории клиентов
     */
    id: number;
  }
}

export { ClientCategoryApiService }
