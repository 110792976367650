import {IssuingType, StartPeriodTypes, StopPeriodTypes, PublicId} from "./IssuingType";
import {CouponWithoutBarcode} from "../couponKinds/CouponWithoutBarcode";
import {CouponRuleBarcode} from "../couponKinds/CouponRuleBarcode";



export class Public extends IssuingType{

    static id = PublicId;
    static couponKindsArray  = [
        CouponRuleBarcode,
        CouponWithoutBarcode
    ];

    public startPeriodArray = [
        StartPeriodTypes.START_PERIOD_FORM_DATE
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE,
        StopPeriodTypes.STOP_PERIOD_SCHEDULED,
    ];
}
