import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {ImportFromFileDialogComponent} from "./import-from-file-dialog.component";

import {TreeModule} from "@circlon/angular-tree-component";
import {SearchInputModule} from "../search-input/search-input.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {SharedComponentsModule} from "../shared.components.module";
import {ImportFromFileService} from "./service/import-from-file.service";
import {NgbDropdownModule, NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import {DirectiveModule} from "../../directive/directive.module";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";


@NgModule({
  declarations: [
    ImportFromFileDialogComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        UpgradeModule,
        TreeModule,
        SearchInputModule,
        InfiniteScrollModule,
        SharedComponentsModule,
        NgbDropdownModule,
        DirectiveModule,
        PipesComponentsModule,
        NgbProgressbarModule,
    ],
  providers: [
    ImportFromFileService
  ],
  entryComponents:[],
  exports:[
    ImportFromFileDialogComponent
  ]
})
export class ImportFromFileModule {

  constructor() {}

}

//-- LEGACY ---

angular.module('loya.builder')
  .factory('ImportFromFileDialog', downgradeInjectable(ImportFromFileService))
