<div class="fixed-tool-panel">


  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          *ngIf="statesChangerService.hasButton('edit')  "
          (click)="statesChangerService.click('edit')"
          data-testid="edit">

    <i class="fa fa-pencil"></i>
    <span class="btn-name m-l-xs hidden-xxs">
                Редактировать
              </span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="settingsForm?.form?.invalid"
          data-testid="save"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Сохранить</span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="cancel"
  >

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Отменить</span>
  </button>
<!--


  <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="settingsForm?.form?.invalid"
          data-testid="preview"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
     Предпросмотр
    </span>
  </button>
-->



</div>

<form name="settingsForm" #settingsForm="ngForm" novalidate autocomplete="off">


  <div class="card w-full no-back"  >
    <div class="card-header"
         data-testid="settings.general"
    >
      Общие настройки
    </div>
    <div class="card-body "
         id="mainSettingsBody"
    >

      <grid-container
        [columns]="3"
      >

        <ng-container
          *loyaPermitIf="{path:'crm.accounts.credit', permitEnabled: ''}"
        >

          <div class="grid-item"
               [grid-item-form] = "'Работать с кредитным счетом'"
               grid-item-form-icon = "fa-user"
          >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.general.allowCreditAccount.value"
                    [innerText]="YesNoByKey('credit.allowCreditAccount')"
              >
              </span>

                <span class="editable-rec"
                    *ngIf="statesChangerService.isActive"
                >
                  <div class="checkbox inline m-t-none m-b-none">
                    <label class="i-checks">
                      <input   type="checkbox"

                               [ngModel]="isChecked('credit.allowCreditAccount')"
                               (ngModelChange)="changeChecked('credit.allowCreditAccount', $event)"
                               data-testid="settings.general.input.allowCreditAccount"
                               name="settingsObj['credit.allowCreditAccount'].key"
                               placeholder="Включение кредитного счёта"
                      >
                      <i></i>
                    </label>
                  </div>
                </span>
          </div>

          <div class="grid-item"
               *ngIf="isChecked('credit.allowCreditAccount')"
               [grid-item-form] = "'Максимальный размер кредитного счета'"
               grid-item-form-icon = "fa-gear"
          >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.general.maxCreditValue.value"
                    [innerText]="settingsItem['credit.maxCreditValue'] || ''"
              >
              </span>

              <span class="editable-rec"
                    *ngIf="statesChangerService.isActive"
              >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="settingsItem['credit.maxCreditValue']"
                  data-testid="settings.general.input.maxCreditValue"
                  name="settingsObj['credit.maxCreditValue'].key"
                  placeholder="0"
                  [required]="true"
                  [min]="0"
                  [max]="10000000000"
                  pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
                >
                </span>
          </div>

          <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        </ng-container>

        <div class="grid-item"
             [grid-item-form] = "'Рассчитывать купоны, игнорирующие ограничения скидок LOYA последними'"
             grid-item-form-icon = "fa-user"
        >

          <span *ngIf="!statesChangerService.isActive"  >
             <span data-testid="settings.general.ignoreLimitsLast.value"
                  [innerText]="YesNoByKey('precheck.ignorelimits.calc.last')"></span>

             <i [ngbPopover]="'При включенном параметре, вознаграждения купонов, игнорирующих ограничения скидок LOYA, рассчитываются после обычных вознаграждений '"
                [popoverTitle]="'Порядок расчета вознаграждений'"
                [triggers]="'mouseenter:mouseleave'"
                [placement]="'bottom'"
                class="fa fa-question-circle text-info m-l-xs f-s-12"></i>

          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
              <div class="checkbox inline m-t-none m-b-none">
                <label class="i-checks">
                    <input type="checkbox"

                           [ngModel]="isChecked('precheck.ignorelimits.calc.last')"
                           (ngModelChange)="changeChecked('precheck.ignorelimits.calc.last', $event)"
                           data-testid="settings.general.input.ignoreLimitsLast"
                           name="settingsObj['precheck.ignorelimits.calc.last'].key"
                           [disabled]="isChecked('processing.reward.calcIgnoreLimitsWithDiscount')"
                  >
                  <i></i>
                </label>
              </div>
            </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Рассчитывать купоны, игнорирующие ограничения скидок LOYA с учетом выданных скидок'"
             grid-item-form-icon = "fa-user"
        >

          <span *ngIf="!statesChangerService.isActive"  >
             <span data-testid="settings.general.ignoreLimitsWithDiscount.value"
                   [innerText]="YesNoByKey('processing.reward.calcIgnoreLimitsWithDiscount')"></span>

            <ng-template #calcIgnoreLimitsWithDiscountPopContent>
              <div>При включенном параметре, вознаграждения купонов, игнорирующих ограничения скидок LOYA, рассчитываются:<ul>
                <li>с учетом скидок, выданных обычными кампаниями и купонами</li>
                <li>не участвуют в коллизиях групп кампаний</li>
                <li>не участвуют в коллизиях со скидками кассы</li>
                <li>при наличии нескольких купонов, если их суммарная скидка превышает допустимую, сумма скидки уменьшается пропорционально величине вознаграждения</li></ul></div>
            </ng-template>

             <i [ngbPopover]="calcIgnoreLimitsWithDiscountPopContent"
                [popoverTitle]="'Порядок расчета вознаграждений'"
                [triggers]="'mouseenter:mouseleave'"
                [placement]="'bottom'"
                class="fa fa-question-circle text-info m-l-xs f-s-12"></i>

          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
              <div class="checkbox inline m-t-none m-b-none">
                <label class="i-checks">
                    <input type="checkbox"

                           [ngModel]="isChecked('processing.reward.calcIgnoreLimitsWithDiscount')"
                           (ngModelChange)="changeChecked('processing.reward.calcIgnoreLimitsWithDiscount', $event); checkIfTrue('precheck.ignorelimits.calc.last', $event) "
                           data-testid="settings.general.input.ignoreLimitsWithDiscount"
                           name="settingsObj['processing.reward.calcIgnoreLimitsWithDiscount'].key"
                    >
                  <i></i>
                </label>
              </div>
            </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Минимальная сумма чека для касс самообслуживания'"
             grid-item-form-icon = "fa-money"
        >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.general.minSumSco.value"
              >
                <span *ngIf="hasValue('processing.sco.pos.type.limit')">
                  {{ settingsItem['processing.sco.pos.type.limit']  | loyaCurrency }}
                  <i class="fa le" ngCurrencyLoya> </i>
                </span>
              </span>

              <span class="editable-rec input-group"
                    *ngIf="statesChangerService.isActive"
              >
                    <input
                      type="number"
                      class="form-control"
                      [(ngModel)]="settingsItem['processing.sco.pos.type.limit']"
                      data-testid="settings.general.input.minSumSco"
                      name="settingsObj['processing.sco.pos.type.limit'].key"
                      placeholder="Введите сумму"
                      [required]="false"
                      [min]="0"
                      [max]="10000000000"
                      pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
                    >

                    <span class="input-group-addon"><i class="fa " ngCurrencyLoya></i></span>
                </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Информирование на кассах самообслуживания'"
             grid-item-form-icon = "fa-info"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.general.cardPin.label"
                [innerText]="YesNoByKey('processing.informing.sco.byPosChannel.isEnable')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('processing.informing.sco.byPosChannel.isEnable')"
                         (ngModelChange)="changeChecked('processing.informing.sco.byPosChannel.isEnable', $event)"
                         data-testid="processing.informing.sco.byPosChannel.isEnable"
                         name="settingsObj['processing.informing.sco.byPosChannel.isEnable'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Работа с ПИН-кодами'"
             grid-item-form-icon = "fa-credit-card"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.general.input.cardPin.value"
                [innerText]="YesNoByKey('processing.card.pin.usable')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('processing.card.pin.usable')"
                         (ngModelChange)="changeChecked('processing.card.pin.usable', $event)"
                         data-testid="settings.general.input.cardPin"
                         name="settingsObj['processing.card.pin.usable'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

        <div class="grid-item"
             *ngIf="isChecked('processing.card.pin.usable' ) "
             [grid-item-form] = "'Ограничения длины ПИН-кода'"
             grid-item-form-icon = "fa-gear"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.general.cardPinLength.value">
              с {{ settingsItem['external.api.card.pin.length.min'] || 0 }} по {{ settingsItem['external.api.card.pin.length.max'] || 0 }}
          </span>

          <div class="editable-rec input-group m-b-sm"
                *ngIf="statesChangerService.isActive"
          >
            <span class="input-group-addon w-xxs">c</span>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="settingsItem['external.api.card.pin.length.min']"
              data-testid="settings.general.input.cardPinLength.from"
              name="settingsObj['external.api.card.pin.length.min'].key"
              placeholder="0"
              [required]="true"
              [min]="4"
              [max]="settingsItem['external.api.card.pin.length.max'] || 10"
              pattern="^[0-9]+$"
            >
          </div>

          <div class="editable-rec input-group"
               *ngIf="statesChangerService.isActive"
          >
            <span class="input-group-addon w-xxs">по</span>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="settingsItem['external.api.card.pin.length.max']"
              data-testid="settings.general.input.cardPinLength.to"
              name="settingsObj['external.api.card.pin.length.max'].key"
              placeholder="0"
              [required]="true"
              [min]="settingsItem['external.api.card.pin.length.min']"
              [max]="10"
              pattern="^[0-9]+$"
            >
          </div>

        </div>

        <div class="grid-item"
             *ngIf="isChecked('processing.card.pin.usable' ) "
             [grid-item-form] = "'Порог для запроса ПИН-кода'"
             grid-item-form-icon = "fa-gear"
        >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.general.minSumSco.value"
              >
                <span *ngIf="hasValue('processing.card.pin.bonus.amount')">
                  от {{ settingsItem['processing.card.pin.bonus.amount']  | loyaCurrency }}
                  <i class="fa le" ngCurrencyLoya> </i>
                </span>
              </span>

          <span class="editable-rec input-group"
                *ngIf="statesChangerService.isActive"
          >
              <span class="input-group-addon">от</span>

              <input
                type="number"
                class="form-control"
                [(ngModel)]="settingsItem['processing.card.pin.bonus.amount']"
                data-testid="settings.general.input.cardPinBonusAmount"
                name="settingsObj['processing.card.pin.bonus.amount'].key"
                placeholder="Введите сумму"
                [required]="true"
                [min]="0"
                [max]="10000000000"
                pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
              >

              <span class="input-group-addon"><i class="fa " ngCurrencyLoya></i></span>
            </span>
        </div>

        <div class="grid-item  grid-item_clean "></div>

        <div class="grid-item"
             *ngIf="isChecked('processing.card.pin.usable' ) "
             [grid-item-form] = "'Предупреждение при отсутствии карты с ПИН-кодом'"
             grid-item-form-icon = "fa-gear"
        >

          <div *ngIf="!statesChangerService.isActive"
               class="word-break-all"
                data-testid="settings.general.cardPinWarning.value"
          >
             {{ settingsItem['processing.card.pin.warning'] || '' }}
          </div>

          <span class="editable-rec  "
                *ngIf="statesChangerService.isActive"
          >
             <textarea
               class="form-control  "
               [(ngModel)]="settingsItem['processing.card.pin.warning']"
               data-testid="settings.general.input.cardPinWarning"
               name="settingsObj['processing.card.pin.warning'].key"
               placeholder="Текст предупреждения"
               [required]="false"
               rows="5"
               [maxLength]="255"
             ></textarea>
            </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Каскадная верификация телефона'"
             grid-item-form-icon = "fa-phone"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.auth.flashcall.cascade"
                [innerText]="YesNoByKey('auth.flashcall.cascade')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('auth.flashcall.cascade')"
                         (ngModelChange)="changeChecked('auth.flashcall.cascade', $event)"
                         data-testid="settings.auth.flashcall.cascade"
                         name="settingsObj['auth.flashcall.cascade'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Проверка номера телефона для списания бонусов'"
             grid-item-form-icon = "fa-phone"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.auth.phone.confirmed"
                [innerText]="YesNoByKey('auth.phone.confirmed')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('auth.phone.confirmed')"
                         (ngModelChange)="changeChecked('auth.phone.confirmed', $event)"
                         data-testid="settings.auth.phone.confirmed"
                         name="settingsObj['auth.phone.confirmed'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Оповещения в Wallet'"
             grid-item-form-icon = "fa-mobile-phone"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="settings.wallet.enabled"
                [innerText]="YesNoByKey('wallet.enabled')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('wallet.enabled')"
                         (ngModelChange)="changeChecked('wallet.enabled', $event)"
                         data-testid="settings.wallet.enabled"
                         name="settingsObj['wallet.enabled'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

        <div class="grid-item"
             *ngIf="isChecked('wallet.enabled' ) "
             [grid-item-form] = "'Wallet URL'"
             grid-item-form-icon = "fa-gear"
        >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.wallet.url.value"
              >
                  {{ settingsItem['wallet.url'] || '' }}
              </span>

              <span class="editable-rec "
                    *ngIf="statesChangerService.isActive"
              >
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="settingsItem['wallet.url']"
                    data-testid="settings.wallet.url"
                    name="settingsObj['wallet.url'].key"
                    placeholder="Введите URL"
                    [required]="true"
                    [maxLength]="10000"
                  >

              </span>
        </div>

        <div class="grid-item"
             *ngIf="isChecked('wallet.enabled' ) "
             [grid-item-form] = "'Wallet secret'"
             grid-item-form-icon = "fa-gear"
        >

              <span *ngIf="!statesChangerService.isActive"
                    data-testid="settings.wallet.url.value"
              >
                  {{ settingsItem['wallet.secret'] || '' }}
              </span>

              <span class="editable-rec "
                    *ngIf="statesChangerService.isActive"
              >
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="settingsItem['wallet.secret']"
                    data-testid="settings.wallet.secret"
                    name="settingsObj['wallet.secret'].key"
                    placeholder="Введите secret"
                    [required]="true"
                    [maxLength]="10000"
                  >

              </span>
        </div>

        <div class="grid-item  grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             [grid-item-form] = "'Добавление свободной карты при создании клиента'"
             grid-item-form-icon = "fa-user"
        >

          <span *ngIf="!statesChangerService.isActive"
                data-testid="freeCard"
                [innerText]="YesNoByKey('use.empty.card.default')"
          >
          </span>

          <span class="editable-rec"
                *ngIf="statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks">
                <input   type="checkbox"

                         [ngModel]="isChecked('use.empty.card.default')"
                         (ngModelChange)="changeChecked('use.empty.card.default', $event)"
                         data-testid="settings.use.empty.card.default"
                         name="settingsObj['use.empty.card.default'].key"
                >
                <i></i>
              </label>
            </div>
          </span>
        </div>

      </grid-container>

    </div>
  </div>

  <div class="card w-full no-back"
       *loyaPermitIf="{path:'referrer', permitEnabled: ''}"
  >
    <div class="card-header"
         data-testid="settings.referrer"
    >
      Реферы
    </div>
    <div class="card-body "
    >


      <referrer-options-ng1
        [settings]="settingsItem"
        [isEdit]="statesChangerService.isActive"
      ></referrer-options-ng1>

      <bonus-config-ng1
        [model]="settingsItem['referrer.bonus.validity']"
        [readOnly]="!statesChangerService.isActive"
      ></bonus-config-ng1>

    </div>
  </div>

  <div class="card w-full no-back"
       data-testid="settings.infogate"
       *loyaPermitIf="{path:'channel.type.sms', permitEnabled: ''}"
  >
    <div class="card-header">
      Сервер уведомлений
    </div>
    <div class="card-body ">

      <grid-container
        [columns]="3"
      >

        <div class="grid-item"
             [grid-item-form] = "'Адрес сервера'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.infogate.host.label"
            >
                {{ settingsItem['infogate.host'] || '' }}
            </span>

            <span class="editable-rec "
                  *ngIf="statesChangerService.isActive"
            >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="settingsItem['infogate.host']"
                  data-testid="settings.infogate.input.host"
                  name="settingsObj['infogate.host'].key"
                  placeholder="Адрес сервера"
                  [required]="true"
                  [maxLength]="256"
                >

              </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Порт'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.infogate.port.label"
            >
                {{ settingsItem['infogate.port'] || '' }}
            </span>

            <span class="editable-rec "
                  *ngIf="statesChangerService.isActive"
            >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="settingsItem['infogate.port']"
                  data-testid="settings.infogate.input.port"
                  name="settingsObj['infogate.port'].key"
                  placeholder="Порт сервера"
                  [required]="true"
                  [maxLength]="65535"
                >
            </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Таймаут ожидания ответа'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.infogate.wait.label"
            >
                {{ settingsItem['infogate.wait'] || '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="settingsItem['infogate.wait']"
                  data-testid="settings.infogate.input.wait"
                  name="settingsObj['infogate.wait'].key"
                  placeholder="Таймаут ожидания"
                  [required]="true"
                  [maxLength]="65535"
                >
            </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Имя пользователя'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.infogate.user.label"
            >
                {{ settingsItem['infogate.user'] || '' }}

              <i class="fa m-l-xxs"
                 [ngClass]="{ 'fa-times-circle text-danger': !loader.isLoading('gate') && !gate.success,
                                                 'fa-check-circle text-success': !loader.isLoading('gate') && gate.success,
                                                 'fa-refresh fa-spin': loader.isLoading('gate') }"
              ></i>

              <div *ngIf="!loader.isLoading('gate') && !gate.success"
                   class=" text-danger-dker text-xs "
                   [innerText]="gate.message"></div>

            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="settingsItem['infogate.user']"
                  data-testid="settings.infogate.input.user"
                  name="settingsObj['infogate.user'].key"
                  placeholder="Введите имя пользователя"
                  [required]="settingsItem['infogate.host']"
                  [maxLength]="256"
                >

              </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Пароль пользователя'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.infogate.password.label"
            >
                {{ !!settingsItem['infogate.pass'] ? '******' : '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="password"
                  class="form-control"
                  [(ngModel)]="settingsItem['infogate.pass']"
                  (ngModelChange)="confirmInfogatePass = ''"
                  data-testid="settings.infogate.input.password"
                  name="settingsObj['infogate.pass'].key"
                  placeholder="Пароль пользователя"
                  [required]="true"
                  [maxLength]="39"
                  pattern="^[0-9a-zA-Z@\-_\.]+$"
                >

              </span>
        </div>

        <div class="grid-item"
             *ngIf="statesChangerService.isActive"
             [grid-item-form] = "'Подтвердите пароль'"
             grid-item-form-icon = "fa-user"
        >

          <span class="editable-rec ">
                <input
                  type="password"
                  class="form-control"
                  [(ngModel)]="confirmInfogatePass"
                  data-testid="settings.infogate.input.confirmInfogatePass"
                  name="settingsObj['confirmInfogatePass'].key"
                  placeholder="Подтвердите пароль"
                  [required]="true"
                  pattern="^[0-9a-zA-Z@\-_\.]+$"
                  [disabled]="!settingsForm?.controls['settingsObj[\'infogate.pass\'].key']?.dirty"
                  [customValidator]="comparePasswordsValidator"
                >
          </span>
        </div>


      </grid-container>

    </div>
  </div>

  <div class="card w-full no-back"
       data-testid="settings.trigger"
  >
    <div class="card-header">
      Сервер цепочек
    </div>
    <div class="card-body ">

      <grid-container
        [columns]="3"
      >

        <div class="grid-item"
             [grid-item-form] = "'Адрес сервера'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.loyaTrigger.host.label"
            >
                {{ settingsItem['loyaTrigger.host'] || '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="settingsItem['loyaTrigger.host']"
                  data-testid="settings.loyaTrigger.input.host"
                  name="settingsObj['loyaTrigger.host'].key"
                  placeholder="Адрес сервера"
                  [required]="false"
                  [max]="256"
                >

              </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Порт сервера'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.loyaTrigger.port.label"
            >
                {{ settingsItem['loyaTrigger.port'] || '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="settingsItem['loyaTrigger.port']"
                  data-testid="settings.loyaTrigger.input.port"
                  name="settingsObj['loyaTrigger.port'].key"
                  placeholder="Порт сервера"
                  [required]="false"
                  [max]="65535"
                >
            </span>
        </div>

      </grid-container>

    </div>
  </div>


  <div class="card w-full no-back"
       data-testid="settings.demo"
  >
    <div class="card-header">
      Сервер процессинга для демо кассы
    </div>
    <div class="card-body ">

      <grid-container
        [columns]="3"
      >

        <div class="grid-item"
             [grid-item-form] = "'Адрес сервера'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.processing-loya.host.label"
            >
                {{ settingsItem['processing-loya.host'] || '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="settingsItem['processing-loya.host']"
                  data-testid="settings.processing-loya.input.host"
                  name="settingsObj['processing-loya.host'].key"
                  placeholder="Адрес сервера"
                  [required]="false"
                  [max]="256"
                >

              </span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Порт сервера'"
             grid-item-form-icon = "fa-user"
        >

            <span *ngIf="!statesChangerService.isActive"
                  data-testid="settings.processing-loya.port.label"
            >
                {{ settingsItem['processing-loya.port'] || '' }}
            </span>

          <span class="editable-rec "
                *ngIf="statesChangerService.isActive"
          >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="settingsItem['processing-loya.port']"
                  data-testid="settings.processing-loya.input.port"
                  name="settingsObj['processing-loya.port'].key"
                  placeholder="Порт сервера"
                  [required]="false"
                  [max]="65535"
                >
            </span>
        </div>

      </grid-container>

    </div>
  </div>

</form>
