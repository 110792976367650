
export class FilterLocations {

    // Передаваемые параметры в компонент
    public model              : any;
    public fieldLocations     : string;
    public fieldLocationGroup : string;
    public forPartner         : number;


    private locationsGroupCache: any = {};
    private locationsCache: any = {};
    private localPartner: number;

  static $inject = ['$scope','$timeout', 'LocationModel','LocationGroupModel' ];

    constructor( private $scope   : any,
                 private $timeout : any,
                 private LocationModel : any,
                 private LocationGroupModel : any,
    ) { }


    public isSelectedLocations() {
        return this.model.$filter[this.fieldLocations] &&
               this.model.$filter[this.fieldLocations].locations &&
               this.model.$filter[this.fieldLocations].locations.length > 0;
    }

    public isSelectedLocationGroups() {
        return this.fieldLocationGroup &&
            this.model.$filter[this.fieldLocationGroup] &&
            this.model.$filter[this.fieldLocationGroup].locationGroups &&
            this.model.$filter[this.fieldLocationGroup].locationGroups.length > 0;
    }

    public getNameLocationById( id: number ) {

        if (typeof this.locationsCache[id] === "undefined")
            this.locationsCache[id] = this.LocationModel
                                            .getLocationByById({id: id})
                                            .$promise
                                            .then( ( res: any ) => this.locationsCache[id].name = res.name );



        return this.locationsCache[id];

    }

    public getNameLocationGroupById( id: number ) {

        if (typeof this.locationsGroupCache[id] === "undefined")
            this.locationsGroupCache[id] = this.LocationGroupModel
                                                .get({id: id})
                                                .$promise
                                                .then( ( res: any ) =>  this.locationsGroupCache[id].name = res.name );



        return this.locationsGroupCache[id];
    }


    private removeLocationById(id:number) {

        if ( !this.isSelectedLocations () ) {
            return;
        }

        const filtredArray = this
            .model
            .$filter[this.fieldLocations].locations
            .filter( ( filterId: number ) => filterId !== id );

        this.model.$filter[this.fieldLocations].locations = filtredArray.length ? filtredArray : undefined ;
    }

    private removeLocationGroupById(id:number) {

        if ( !this.isSelectedLocationGroups() ) {
            return;
        }

        const filtredArray = this
            .model
            .$filter
            [this.fieldLocationGroup].locationGroups
            .filter( ( filterId: number ) => filterId !== id );

        this.model.$filter[this.fieldLocationGroup].locationGroups = filtredArray.length ? filtredArray : undefined ;
    }

    public selectLocationList = ( result: any ) => {

        if (result.ids.length === 0) {
            this.clearLocations();
            return;
        }

        if ( this.forPartner !== result.partnerId ) {

            this.clearLocations();

            this.$timeout( () => {
                this.localPartner = result.partnerId;
                this.forPartner   = result.partnerId;
                this.model.$filter[this.fieldLocations].partnerId = result.partnerId;
            });

        }


        if ( this.fieldLocationGroup &&
             Array.isArray(result.locationGroup) && result.locationGroup.length
           ) {
            // Выбраны локации

                this.model.$filter[this.fieldLocations].locations = undefined;
                this.model.$filter[this.fieldLocationGroup].locationGroups = result.locationGroup.map( (l: any) => {
                    this.locationsGroupCache[l.id] = { name: l.name };
                    return l.id;
                });

            } else {

                this.model.$filter[this.fieldLocations].locations = result.ids.slice();
                result.locations.map( ( location : any ) => {
                    this.locationsCache[location.id] = { name: location.name };
                });

            }

    }

    public getSelectedLocations() {
        if ( this.isSelectedLocations() ) {
            return this.model.$filter[this.fieldLocations].locations;
        }

        return ;
    }

    public getSelectedLocationGroups() {
        if ( this.isSelectedLocationGroups() ) {
            return this.model.$filter[this.fieldLocationGroup].locationGroups;
        }

        return ;
    }

    public clearLocations() {

        if ( this.isSelectedLocations() ) {
            this.model.$filter[this.fieldLocations].locations = undefined;
        }

        if ( this.isSelectedLocationGroups() ) {
            this.model.$filter[this.fieldLocationGroup].locationGroups = undefined;
        }

        if ( !this.isSelectedLocations() && !this.isSelectedLocationGroups() ) {
            this.model.$filter[this.fieldLocations].partnerId = undefined;
        }

    }

    public $onInit() {

        // Проверка на изменение партнёра
        this.$scope.$watch( ( _: any ) => this.forPartner , (newVal: number, oldVal: number ) => {

            if ( newVal !== this.localPartner ) {

                if ( typeof oldVal !== "undefined" ) {
                    this.localPartner = this.forPartner;
                    this.clearLocations();
                }

                if ( typeof newVal === "undefined"  ) {

                    if ( this.isSelectedLocations() ) {
                        this.model.$filter[this.fieldLocations].partnerId = this.forPartner;
                        this.forPartner = this.localPartner;
                    } else {
                        this.model.$filter[this.fieldLocations].partnerId = undefined;
                        this.localPartner  = undefined;
                    }

                }


            }

        });

    }

/*
    public $onChanges( changes : any) {

        if ( changes.forPartner ) {

            if (typeof changes.forPartner.currentValue === "undefined") {
                return;
            }

            if ( changes.forPartner.currentValue !== changes.forPartner.previousValue ) {
                this.clearLocations();
            }
        }

    }
*/

    public get isPending() {
        return this.model &&
            this.model.$filter &&
            this.model.$filter.isPending;
    }


};
