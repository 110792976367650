import {Component, OnDestroy, OnInit} from "@angular/core";

import "./segments-index-page.legacy"
import {SegmentationService} from "../../../shared/service/api/Segment/Segment.service";
import {Segment} from "../../../api/SegmentationApi/models/segment";
import {LoaderHandlerClass} from "../../../shared/class/loader-handler.class";
import {SEGMENT_STATES} from "../../../shared/service/api/Segment/Segment.values";
import {SEGMENT_CALCULATED_FIELD, SegmentCalculatorClass} from "../shared/class/segment-calculator.class";
import {LOYA_CONFIG} from "../../../shared/variables/loya-config.variable";
import {takeWhile} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'segments-index-page',
  templateUrl: './segments-index-page.component.html',
  providers: [
    SegmentationService,
    NgbModal
  ]
})
export class SegmentsIndexPageComponent implements OnInit, OnDestroy {

  public segmentsList: Segment[] = [];
  public loader = new LoaderHandlerClass();
  public calculator = new SegmentCalculatorClass();
  public SEGMENT_CALCULATED_FIELD = SEGMENT_CALCULATED_FIELD;
  public LOYA_CONFIG = LOYA_CONFIG;

  private isNotDestroyed = true;
  private subscribers$:any = {};

  constructor(
    public segmentationService: SegmentationService,
    private modalService: NgbModal
  ) {
  }

  get hasSegments() {
    return Array.isArray(this.segmentsList) && !!this.segmentsList?.length;
  }

  get isDraft() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.draft;
  }

  get isRefresh() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.refresh;
  }

  get isDeploy() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.deploy;
  }

  get isUpdatingProcess() {
    return this.loader?.isLoading('refresh') ||
      this.loader?.isLoading('loadingList') ||
      this.loader?.isLoading('deploy') ||
      this.loader?.isLoading('delete')
  }

  get hasRefreshSegments() {
    return !!this.segmentsList.find( this.isSegmentRefresh )
  }

  public isSegmentRefresh(segment: Segment = <any>{}) {
    return segment.completeness==='started' || segment.completeness==='unknown'
  }

  ngOnInit() {
    this.updateList();
  }

  public updateList() {

    this.subscribers$.getList =
      this.segmentationService.getList$()
        .pipe(
          this.loader.waitLoading('loadingList')
        )
        .subscribe( (r:any) => {
          this.segmentsList = r;
          this.calculator.segments(this.segmentsList);
          this.updateStatuses();
        })

  }

  public refreshClients() {

    this.segmentationService.refresh$()
      .pipe(
        this.loader.waitLoading('refresh')
      )
      .subscribe( (r:any) => {
        this.updateList();
      })

  }

  public deploy(deployConfirmContent) {

    let update = (updateCampaign) => {
      this.subscribers$.deploy =
        this.segmentationService.deploy$(updateCampaign)
          .pipe(
            this.loader.waitLoading('deploy')
          )
          .subscribe( (r:any) => {
            this.updateList();
          })
    }

    if (!!deployConfirmContent) {

      this.modalService.open(deployConfirmContent)
        .result
        .then((result) => {
          update(true);
        }, (reason) => {
          update(false);
        });

    } else {
      update(false);
    }

  }

  public cancel() {

    this.segmentationService.delete$()
      .pipe(
        this.loader.waitLoading('delete')
      )
      .subscribe( (r:any) => {
        this.updateList();
      })

  }

  public updateStatuses() {

    this.subscribers$?.updater?.unsubscribe();

    let idsForUpdate = this.segmentsList
      .filter( this.isSegmentRefresh )
      .map( segment => segment?.id);

    if (!idsForUpdate?.length)
      return

    this.subscribers$.updater = this.segmentationService
        .waitStates$(idsForUpdate)
        .subscribe( {
          next: segment => {

            let finded = this.segmentsList.find( i => i.id === segment.id );
            if (!finded)
              return;

            Object.assign(finded, segment);
            /*
              finded.state = segment.state;
              finded.completeness = segment.completeness;
            */

          },
          complete: () => {
          }
        });


  }

  public changeSort() {

    let params = this.segmentationService.getListParams.getSort();
    if (!Object.keys(params)?.length)
      return;

    this.segmentsList.sort(
      (a,b) => {

        switch (params.field) {

          case 'name':
              return params.order === 'asc' ?  a.name?.localeCompare(b?.name) : (-1) * a.name?.localeCompare(b?.name)

          case 'clientCount':
            return params.order === 'asc' ?  a.clientCount - b.clientCount : b.clientCount - a.clientCount

          case SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT:
            return params.order === 'asc' ?
                    this.calculator.value(a?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) - this.calculator.value(b?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) :
                    this.calculator.value(b?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT) - this.calculator.value(a?.id, SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT)

          case 'clientCountGrowth':
            return params.order === 'asc' ?  a.clientCountGrowth - b.clientCountGrowth : b.clientCountGrowth - a.clientCountGrowth

          case 'lifeValue':
            if (this.isDeploy) {
              return params.order === 'asc' ?  a.period0?.total - b.period0?.total : b.period0?.total - a.period0?.total
            } else {
              return params.order === 'asc' ?  a.lifeValue - b.lifeValue : b.lifeValue - a.lifeValue
            }

          case SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT:
            return params.order === 'asc' ?
              this.calculator.value(a?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) - this.calculator.value(b?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) :
              this.calculator.value(b?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT) - this.calculator.value(a?.id, SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT)

          case 'lifeValueGrowth':
            return params.order === 'asc' ?  a.lifeValueGrowth - b.lifeValueGrowth : b.lifeValueGrowth - a.lifeValueGrowth

        }

        return 0;
    })

  }

  ngOnDestroy() {
    Object.values(this.subscribers$).forEach( (sub: any) => {
      sub && sub?.unsubscribe();
    })
  }

}
