/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for GoodGroupLinkBrandsApi services
 */
@Injectable({
  providedIn: 'root',
})
export class GoodGroupLinkBrandsApiConfiguration {
  rootUrl: string = location.origin;
}

export interface GoodGroupLinkBrandsApiConfigurationInterface {
  rootUrl?: string;
}
