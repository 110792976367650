import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe } from '@angular/common';

@Pipe({
  name: 'loyaTime'
})
export class LoyaTimePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string) {}

  transform(input:  string, timezone: any): string {

    let datePipe: any =  new DatePipe(this._locale);

    if ( angular.isString(input) && input.indexOf(' ') !== -1 )
      input = <string>input.split(' ')[1];

    return datePipe.transform( new Date('1970-01-01T'+input), 'HH:mm:ss', timezone);
  }
}
