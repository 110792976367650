<responsive-table>
  <table class="table m-b-none b-b "
         infiniteScroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         [infiniteScrollContainer]="'#app-container'"
         [fromRoot]="true"
         (scrolled)="onScroll()"
  >
    <thead>
    <tr>

      <th class="text-center">
        <sort-view
          column="date"
          [params]="accountService.getParams"
          (onChange)="reset()"
        >
          Дата операции
        </sort-view>
      </th>

      <th class="text-center">Дата начала<br/> действия бонусов</th>

      <th class="text-center">Дата окончания<br/> действия бонусов</th>

      <th class="text-right">Сумма<br/> операции</th>

      <th class="text-right">Активный<br/> баланс</th>

      <th class="text-right">Неактивный<br/> баланс</th>

      <th style=" max-width: 250px; min-width: 165px;">Тип операции</th>

      <th>Статус операции</th>

      <th>Партнёр</th>

    </tr>
    </thead>
    <tbody>

    <ng-container *ngFor="let postingRow of listHistory; let index = index">
      <tr data-testid="postingRow in account.history"
          [class.created-row]="postingRow.record.status==='created'"
          [class.confirmed-row]="postingRow.record.status==='confirmed'"
      >

        <td class="table-big-link text-center"
            data-testid="postingRow.record.date">
          <span class="">{{ postingRow.record.date | loyaDate }}</span>
          <span class="clearfix text-muted f-s-8 m-t-n-xxs" data-testid="postingRow.record.date.time">{{ postingRow.record.date | loyaTime }}</span>
        </td>

        <td class="table-big-link  text-center" data-testid="postingRow.record.bonusStartDate">
          {{ postingRow.record.bonusStartDate | loyaDate }}
        </td>
        <td class="table-big-link  text-center" data-testid="postingRow.record.bonusEndTime">
          {{ postingRow.record.bonusEndTime  | loyaDate }}
        </td>
        <td class="table-big-link  text-right" data-testid="postingRow.record.amount">
          {{ postingRow.record.amount }}
        </td>
        <td class="table-big-link  text-right" data-testid="postingRow.record.balance">
          {{ postingRow.record.balance }}
        </td>
        <td class="table-big-link  text-right" data-testid="postingRow.record.balanceInactive">
          {{ postingRow.record.balanceInactive }}
        </td>

        <td class=" " style=" max-width: 250px; min-width: 165px;">

          <span
            class="m-t-xxs m-b-n-xxs  pull-left" ng-if="postingRow.record.recType === 'manual'"
            [ngbPopover]="'Ручная корректировка'"
            [triggers]="'mouseclick'"
          >
              <i class="fa fa-user text-success m-r-xs m-t-xxs m-b-n-xxs"></i>
          </span>

          <span  *ngIf="postingRow.record.recType === 'manual'">

              <a (click)="postingRow.$moreCollapse=!postingRow.$moreCollapse" data-testid="postingRow.record.operType">
                   {{ ACCOUNT_OPER_TYPE[postingRow.record.operType] || postingRow.record.operType}}
                <i class="fa link text-muted" [ngClass]="{ 'fa-angle-down':!postingRow.$moreCollapse, 'fa-angle-up':postingRow.$moreCollapse }"></i>
              </a>

              <div *ngIf="postingRow.$moreCollapse" class="f-s-9 m-t-sm">

                  <div class="clearfix">
                      <span class=" text-muted m-r-xs">Оператор:</span>
                      <span class="" data-testid="postingRow.user.name">{{ postingRow?.user?.name }}</span>
                  </div>

                  <div class="clearfix" style="max-width: 300px;">
                      <span class=" text-muted m-r-xs">Причина:</span>
                      <span class=" word-break-all"
                            [innerHtml]="getDescription( postingRow.record.description, postingRow.record )"
                            data-testid="postingRow.record.description"
                      ></span>
                  </div>

              </div>

          </span>


          <span *ngIf="postingRow.record.recType !== 'manual'">

              <a (click)="postingRow.$moreDescCollapse=!postingRow.$moreDescCollapse"
                 *ngIf="postingRow.record.description"
                 data-testid="postingRow.record.operType"
              >
                   {{ ACCOUNT_OPER_TYPE[postingRow.record.operType] || postingRow.record.operType}}
                <i class="fa link text-muted"
                   [ngClass]="{ 'fa-angle-down':!postingRow.$moreDescCollapse, 'fa-angle-up':postingRow.$moreDescCollapse }"></i>
              </a>

              <span *ngIf="!postingRow.record.description" data-testid="postingRow.record.operType">
                   {{ ACCOUNT_OPER_TYPE[postingRow.record.operType] || postingRow.record.operType}}
              </span>

              <div *ngIf="postingRow.record.description && postingRow.$moreDescCollapse"
                   class="f-s-9 m-t-sm">

                  <div class="clearfix" style="max-width: 300px;">
                      <span class=" text-muted m-r-xs">Причина:</span>
                      <span class=" word-break-all"
                            [innerHtml]="getDescription( postingRow.record.description, postingRow.record ) " data-testid="postingRow.record.description"></span>
                  </div>

              </div>

          </span>


        </td>

        <td class=" ">
          <span [innerText]="getStatusName( postingRow.record.status, postingRow.record.operType )" data-testid="postingRow.record.status"></span>
        </td>


        <td class=" ">
          <span [innerText]="getPartnerName( postingRow.record.partnerId)" data-testid="postingRow.record.partnerId"></span>
        </td>

      </tr>
      <tr *ngIf="postingRow.record.checkNumber "
          class="bg"
          data-testid="postingRow in postingRow.record.receiptNumber"
      >
        <td colspan="100"
            class="no-padder pos-rlt"
        >
          <i class="fa fa-exchange f-s-12 text-muted m-l-sm pos-abt" style="
                                         top: -10px;left: 7px;transform:rotate(90deg);
                                      "></i>

          <div class="clearfix f-s-8  wrapper-sm p-l-md"
               [ngClass]="{'pointer': !!postingRow.record.storageCheckId}"
               (click)="showCheck(postingRow.record.storageCheckId, index)"
               data-testid="receipt.show()"
               *ngIf="!isCheckShow(index)"
          >

              <span>Чек: №
                  <span
                    [innerText]="postingRow.record.checkNumber"
                    data-testid="postingRow.record.receiptNumber"
                    [ngClass]="{'text-info': !!postingRow.record.storageCheckId}"

                  ></span>
                  <i class="fa fa-angle-down m-l-xs"
                     *ngIf="!!postingRow.record.storageCheckId"
                     [ngClass]="{'fa-angle-down': !isCheckShow(index), 'fa-spin fa-spinner text-warning': checkForShowIndex === index && loader.isLoading('check') }"
                  ></i>
              </span>
          </div>

          <div
            class="clearfix wrapper-sm"
            *ngIf="isCheckShow(index)"
          >
            <div class="card m-b-none">

              <div class="card-header pointer f-s-8"
                   (click)="checkForShowIndex = undefined"
                   data-testid="receipt.hide()">
                Чек: №
                <span
                  [innerText]="checkForShow.check.number"
                  data-testid="receiptForShow.receipt.number"
                  class="font-bold"
                ></span>
                <i class="fa fa fa-angle-up pull-right fa-lg "></i>
              </div>
              <div class="card-body no-padder">
                <div class="b-default m-l-lg b-l p-t-md p-r p-b-xxs">
                  <receipt-view
                    [model]="checkForShow"
                    [showClient]="true"
                  ></receipt-view>
                </div>
              </div>

            </div>
          </div>

        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</responsive-table>
