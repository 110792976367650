/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ProcessingProxyApiConfiguration, ProcessingProxyApiConfigurationInterface } from './processing-proxy-api-configuration';

import { ProcessingProxyApiService } from './services/processing-proxy-api.service';

/**
 * Provider for all ProcessingProxyApi services, plus ProcessingProxyApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ProcessingProxyApiConfiguration,
    ProcessingProxyApiService
  ],
})
export class ProcessingProxyApiModule {
  static forRoot(customParams: ProcessingProxyApiConfigurationInterface): ModuleWithProviders<ProcessingProxyApiModule> {
    return {
      ngModule: ProcessingProxyApiModule,
      providers: [
        {
          provide: ProcessingProxyApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
