
export enum SEGMENT_STATES  {
  'refresh'  = "refresh",
  'deploy'   = "deploy",
  'draft'    = "draft",
  'archive'  = "archive"
}

export const SEGMENT_STATE_NAMES = {

  [SEGMENT_STATES.refresh] : "В обновлении",
  [SEGMENT_STATES.deploy]  : "Обновлены",
  [SEGMENT_STATES.draft]   : "Черновик",
  [SEGMENT_STATES.archive] : "Архив",

}

export enum RESEGMENT_TYPES {
  "lifecycle"= "lifecycle",
  "brokendownbyvalue" = "brokendownbyvalue",
  "brokendownbygrowth" = "brokendownbygrowth",
  "rfsegmentation" = "rfsegmentation",
  "rfsegmentationforexposure"= "rfsegmentationforexposure",
  "rfmclustering"= "rfmclustering",
  "rfmfloatclustering"= "rfmfloatclustering",
  "rfmcustom" = "rfmcustom"
}

export const RESEGMENT_TYPE_NAMES = {

  [RESEGMENT_TYPES.lifecycle] : "Классический жизненный цикл",
  [RESEGMENT_TYPES.brokendownbyvalue] : "Жизненный цикл с разбиением по ценности",
  [RESEGMENT_TYPES.brokendownbygrowth] : "Жизненный цикл с разбиением по динамике роста",
  [RESEGMENT_TYPES.rfsegmentation] : "Типичное поведение (RF сегментация)",
  [RESEGMENT_TYPES.rfsegmentationforexposure] : "Наилучший способ воздействия (RF сегментация для воздействия)",
  [RESEGMENT_TYPES.rfmclustering] : "Smart-анализ (RFM кластеризация)",
  [RESEGMENT_TYPES.rfmfloatclustering] : "Smart-анализ (RFM кластеризация с плавающим периодом)",
  [RESEGMENT_TYPES.rfmcustom] : "Пользовательская RFM сегментация",


}
