<grid-container>

  <div class="grid-item grid-item_full"
       grid-item-form = "Номер карты"
       grid-item-form-icon = "fa-map-marker"
  >

  <card-number-input-ng1
    class="block  w-full-sm w-full-xs w-full-md text-success"
    [(model)]="cardNumber"
    [(card)]="searchedCard"
    (cardChange)="onChangeCard($event)"
    (onErrorChange)="onErrorChange($event)"
  ></card-number-input-ng1>

  </div>

  <div class="grid-item grid-item_full"
       grid-item-form = "Объединение профилей"
       grid-item-form-icon = "fa-map-marker"
  >
    <label class="i-switch bg-success m-b-n-xs m-t-n-xxs m-r-xs">
      <input type="checkbox"
             [(ngModel)]="record.deleteAfterUse"
             data-testid="account.merge.deleteAfterUse"
             name="deleteProfile"
             [disabled]="deleteAfterUseDisabled"
             placeholder="">
      <i></i>
    </label>

    <span class=" ">
        <span class="" *ngIf="!record.deleteAfterUse" data-testid="account.merge.deleteAfterUse">Без объединения профилей</span>
        <span class="" *ngIf="record.deleteAfterUse" data-testid="account.merge.deleteAfterUse">Включено объединение профилей</span>
    </span>
  </div>

  <ng-container   *ngIf="record.deleteAfterUse && donorAccount ">

      <div class="grid-item grid-item__form grid-item_full" >

        <div class="grid-item__label align-self-start">
          <i class="fa fa-user align-self-start">
          </i>
          Объединить с клиентом
        </div>

        <div class="grid-item__value">

          <a class="link "
             [uiSref]="'frontend.crm.clients.view({id:' + donorClient.client.id+ '})'"
          >{{donorClient.client.name}} {{donorClient.client.surname}}
          </a>

          объединяется с:

          <div class="radio m-t-xs m-b-none" *ngFor="let client of clients | callback:filterRecord; let last = last"
               [class.m-b-none]="last">

            <label class="i-checks m-b-none">
              <input type="radio"
                     [(ngModel)]="record.destClientId"
                     [value]="client.id">
              <i></i>
              {{ client.name }} {{client.surname}}
            </label>

          </div>
        </div>
      </div>

  </ng-container>

  <div class="grid-item grid-item__form grid-item_full "
       *ngIf="donorAccount && donorAccount.clients.length && record.deleteAfterUse"
  >
    <div class="grid-item__label align-self-start">
      <i class="fa fa-warning text-warning align-self-start">
      </i>
      Внимание!
    </div>
    <div class="grid-item__value">

        <div class="">
           Профиль клиента
          <a class="link"
             [uiSref]="'frontend.crm.clients.view({id:' + donorClient.client.id + '})'"
          >{{donorClient.client.name}} {{donorClient.client.surname}}
          </a>
          будет объединён с профилем
          <span *ngFor="let client of clients ">
              <a class="link"
                 *ngIf="client.id === record.destClientId"
                 [uiSref]="'frontend.crm.clients.view({id:' + client.id + '})'"
              >{{client.name}} {{client.surname}}
              </a>
          </span>
        </div>

        <div class="clearfix"></div>

        <div class=" ">
          Профиль клиента
           <a class="link"
              [uiSref]="'frontend.crm.clients.view({id:' + donorClient.client.id + '})'"
           >{{donorClient.client.name}} {{donorClient.client.surname}}
          </a> будет отправлен в архив
        </div>


    </div>
  </div>

</grid-container>

<ng-container
  *ngIf="!loader.isLoading('updateClient')"
>

  <ng-container
    *ngIf="!!donorAccount">

    <div class="padder m-t ">

      <h5
        class="m-l "
      >Счёт для объединения</h5>

      <div class="line  b-b line-lg pull-in m-t-xs m-b-xs"></div>

    </div>

    <grid-container

    >

      <div class="grid-item"
           grid-item-form = "№ Счёта для объединения"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="clearfix" [ngClass]="{'text-danger': donorAccount.account.state==='archive'}" >
          {{ donorAccount.account.number }}
        </div>
        <div class="m-b f-s-7 m-t-n-xs text-danger" *ngIf="donorAccount.account.state==='archive'">
          Архивный счёт
        </div>
      </div>

      <div class="grid-item"
           grid-item-form = "Активный баланс"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="">
          {{ donorAccount.account.balance }}
        </div>
      </div>

      <div class="grid-item"
           grid-item-form = "Неактивный баланс"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="">
          {{ donorAccount?.account?.balanceInactive || 0 }}
        </div>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

      <div class="grid-item"
           grid-item-form = "Начисленные бонусы"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="">
          {{ donorAccount?.account?.awardAmount || 0 }}
        </div>
      </div>

      <div class="grid-item"
           grid-item-form = "Списанные бонусы"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="">
          {{ donorAccount?.account?.salesAmount || 0 }}
        </div>
      </div>

      <div class="grid-item"
           grid-item-form = "Сгоревшие бонусы"
           grid-item-form-icon = "fa-map-marker"
      >
        <div class="">
          {{ donorAccount?.account?.burntAmount || 0 }}
        </div>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"
           *ngIf="donorAccount.bindsAccount && donorAccount.bindsAccount.length"
      ></div>

      <div class="grid-item grid-item__form"
           *ngIf="donorAccount.bindsAccount && donorAccount.bindsAccount.length"
      >
        <div class="grid-item__label">
          <i class="fa fa-map-marker">
          </i>
          Объединённые счета
        </div>
        <div class="grid-item__value">
          <div class="clearfix" *ngFor="let bind of  donorAccount.bindsAccount" >
            <a href=""
               [uiSref]="'^.edit({ id:' + bind.id + ' })'"
               [innerText]="bind?.number"
               data-testid="bind.number"
            ></a>
          </div>
        </div>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"
           *ngIf="donorCreditAccount"
      ></div>

      <div class="grid-item grid-item__form"
           *ngIf="donorCreditAccount"
      >
        <div class="grid-item__label">
          <i class="fa fa-map-marker">
          </i>
          Кредитные бонусы
        </div>
        <div class="grid-item__value" data-testid="creditAccount.balance">
          {{ donorCreditAccount?.balance }}
        </div>
      </div>

    </grid-container>

  </ng-container>

  <div class="clearfix"></div>

  <ng-container
    *ngIf="!!donorAccount?.clients?.length"
  >

    <div class="padder m-t ">
      <h5
        class="m-l "
        class-retro="clearfix f-s-12 text-dark-lter m-b padder-lg"
      >Клиенты</h5>

      <div class="line  b-b line-lg pull-in m-t-xs m-b-xs"></div>

    </div>

    <grid-container>

      <ng-container
        *ngFor="let client of donorAccount.clients"
      >

        <div class="grid-item"
             grid-item-form = "Клиент"
             grid-item-form-icon = "fa-user"
        >
          <a
            href="javascript:void(0)"
            [innerHTML]="client.name + ' ' +  (client.surname || '' )"
            [uiSref]="'frontend.crm.clients.view({id:' + client.id + '})'"
          ></a>

          <div class="text-danger clearfix small"
               *ngIf="client.state === 'archive'"
          >
            Клиент в архиве
          </div>

        </div>

        <div class="grid-item"
             grid-item-form = "Карты клиента"
             grid-item-form-icon = "fa-user"
             *ngIf="!!client.cards?.length "
        >
          <div *ngFor="let card of client.cards">
            <a
              href="javascript:void(0)"
              [innerHTML]="card.number"
              [uiSref]="'frontend.crm.cards.view({id:' + card.id + '})'"
            ></a>
          </div>
        </div>

        <div class="grid-item grid-item_clean " ></div>

      </ng-container>

    </grid-container>

  </ng-container>

  <div *ngIf="!donorAccount && notFound">

    <div class=" text-center wrapper-lg padder text-muted f-s-12"
         *ngIf="!donorClient"
         style="height: auto;">
      Счёт не найден
    </div>

    <div class="card-body text-center wrapper-lg padder text-muted f-s-12"
         *ngIf="donorClient"
         style="height: auto;">
      Счёт не найден. <br/>
      Покупки клиента {{ donorClient?.client?.name}} {{donorClient?.client?.surname}} будут перенесены к клиенту
      <span *ngFor="let client of clients | callback: filterDest ">
          {{ client.name}} {{client.surname}}
      </span>
    </div>

  </div>

</ng-container>

<div *ngIf="loader.isLoading('updateClient')"
     class="text-center">
  <i class="fa fa-spinner fa-spin text-warning fa-3x m-t m-b"></i>
</div>
