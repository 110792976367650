
import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";


@Component({
  selector: 'partner-menu',
  template: `
    <additional-menu
      [hasFilter]="true"
      [filterValue]="filterPartrenName"
      (filterValueChange)="onChangeFilterPartrenName($event)"
      (onAddClick)="onAddClickInner($event)"
      [hideMenu]="hidePartnerMenu"
      [showAddButton]="showAddButton"
    >
      <div menuBlock>
        <ul class="nav nav-scroll flex-column"
            *ngIf="!hidePartnerMenu"
        >
          <li *ngFor="let pertner of innerPartnerList | callback: filterByName.bind(this)" class="nav-item" data-testid="item in partners">
            <a (click)="selectPartner(pertner)"
               [ngClass]="{ 'nav-active':selectedPartner?.id==pertner?.id }"
               class="overflow-wrap-break nav-link"
            >
              <span [innerText]="pertner?.name" ></span>
            </a>
          </li>
        </ul>
      </div>

      <div selectedBlock>

            <span class="h4"
                  [innerText]="selectedPartner ? selectedPartner?.name : '- Выбрать -'">
            </span>

      </div>

      <div contentBlock>
        <ng-content ></ng-content>
      </div>

    </additional-menu>`,
  styleUrls: ['./partner-menu.component.scss'],
  providers: [
    PartnerService
  ]
})
 export class PartnerMenuComponent implements OnInit, OnChanges {

   /*

     'selectedPartner' : '=',
        'onAddClick'      : '&',
        'showAddButton'   : '<',
        'partnerList'     : '='

   * */

  public filterPartrenName: string;

  @Input()  public selectedPartner : any;
  @Output() public selectedPartnerChange = new EventEmitter();
  @Output() public onAddClick  = new EventEmitter();
  @Input()  public showAddButton : boolean;
  @Input()  public updateList : any;

  @Input()  private innerPartnerList : any;

    get hasInitedPartnerList() {
         return true; //!!this.$element.attr('filter-value');
    }

  @Input()  get partnerList() {
         return this.hasInitedPartnerList ? this.innerPartnerList : undefined;
     }

     set partnerList(value :any[]) {
        this.innerPartnerList = value;
     }

    get hidePartnerMenu() {
        return false; // Array.isArray( this.innerPartnerList ) && this.innerPartnerList.length <= 1 &&  !this.$element.attr('show-add-button');
    }

    constructor(
        private partnerService : PartnerService )
    {
    }

    public onAddClickInner(event){
      if (this.onAddClick) {
        this.onAddClick.emit(event);
      }
    }

    public ngOnInit() {

        if (typeof this.partnerList === "undefined") {
            this.getPartnersList();
        } else {
            this.innerPartnerList = this.partnerList;
            this.initSelectedPartner( this.innerPartnerList );
        }

    }
/*

    private transcludeFix() {

        if ( this.$transclude.isSlotFilled('contentSlot') ) {
            this.$transclude(this.$scope.$parent, ( el:any, scope:any) => {
                this.$element.find('.contentSlot').append(el);
            }, undefined, 'contentSlot');
        }

    }
*/

  public ngOnChanges(changes: SimpleChanges) {

    if (
        changes['updateList'] &&
        !changes['updateList'].firstChange
      ) {
        this.getPartnersList();
      }

  }

  private getPartnersList() {
        this.partnerService.query$().subscribe( items => {
          this.partnerList = items;
          this.initSelectedPartner(items)
        });
        return this.innerPartnerList;
    }

    private initSelectedPartner(data : any[]) {

        if ( typeof this.selectedPartner !== "undefined" && typeof this.selectedPartner !== "boolean") {
          let finded = data.find( i => i.id === this.selectedPartner?.id);
          if (finded)
            this.selectedPartner = finded;
          return;
        }

        this.selectedPartner = Array.isArray(data) && data.length ? data[0] : undefined;
        this.selectedPartnerChange.emit(this.selectedPartner);

    }

    public filterByName( item ) {
      if (!this.filterPartrenName)
        return true;

      return  item.name.indexOf(this.filterPartrenName) >=0 ;
    }

    public onChangeFilterPartrenName( value : string ) {
      this.filterPartrenName = value;
    }

    selectPartner( partner ) {
      this.selectedPartner = partner ;
      this.selectedPartnerChange.emit(this.selectedPartner);
    }


}
