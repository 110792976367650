/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for ThresholdPriceApi services
 */
@Injectable({
  providedIn: 'root',
})
export class ThresholdPriceApiConfiguration {
  rootUrl: string = location.origin;
}

export interface ThresholdPriceApiConfigurationInterface {
  rootUrl?: string;
}
