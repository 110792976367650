<div class="fixed-tool-panel">

  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view'"
          data-testid="campaignGroups.add()"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

<div class="wrapper">
  <search-input
    [(model)]="searchString"
    placeholder="Введите название"
  ></search-input>
</div>


 <responsive-table>
   <table
     class="table b-b "
    >
     <thead>
       <tr>
         <th>{{ 'models.CampaignCategoryApi.id.name' | transloco }}</th>
         <th>{{ 'models.CampaignCategoryApi.name.name' | transloco }}</th>
         <th class="table-row-sm table-row-xs ">{{ 'models.CampaignCategoryApi.description.name' | transloco }}</th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listCampaignCategory | callback:filterBySearch">
          <tr
              [uiSref]="'^.view({id:'+item.id+'})'"
              class="pointer"
              data-testid="campaignGroups.item of listCampaignCategory"
          >
            <td>
              <span [innerText]="item.id" data-testid="campaignGroups.id"></span>
            </td>
            <td class="table-big-link">
              <a [innerText]="item.name" href=""
                 [uiSref]="'^.view({id:'+item.id+'})'" data-testid="campaignGroups.name"></a>
            </td>
            <td>
              <span [innerText]="item.description" data-testid="campaignGroups.description"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>
