'use strict';

import module from 'frontendModule';

import 'model/MsgTemplate';
import 'model/UserModel/resource';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.campaign.campaigns.templates.index';

export default
    module
        .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.templates.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.templates.index" */ "./controllers"       ))

                .resolve('MsgTemplateList', [ 'MsgTemplate',  ( MsgTemplate : any ) => MsgTemplate.getTemplate().query().$promise ])
                .resolve('UserList',        [ 'UserModel',  ( UserModel : any ) =>  UserModel.query().$promise ])

                .breadcrumbs([ "Кампании", "Шаблоны сообщений" ])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                })
        }]);
