export const TRIGGER_STATES = {
  'draft'    : "draft",
  'suspended': "suspended",
  'active'   : "active",
  'stopped'  : "stopped",
  'archive'  : "archive"
}

export const TRIGGER_STATE_NAMES = {

  [TRIGGER_STATES.draft]     : "Черновик",
  [TRIGGER_STATES.suspended] : "Приостановлена",
  [TRIGGER_STATES.active]    : "Активна",
  [TRIGGER_STATES.stopped]   : "Завершена",
  [TRIGGER_STATES.archive]   : "В архиве"

}

