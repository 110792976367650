import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {DashboardRequestService} from "../services/dashboard-request.service";

export const BLOCK_ID = 'emailCount';

@Component({
  selector: 'no-location-warning',
  template: `

    <div class="row b-t text-xs  m-b-n-sm "
         *ngIf="hasSelectedLocation()"
    >
      <div class="col text-right padder text-muted p-t-xs">
        <i class="fa fa-warning text-warning m-t-xxs m-r-xs"></i>
        {{text}}
      </div>
    </div>

  `,
  providers: [
  ]
})
export class NoLocationWarningComponent {

  @Input() params: any;
  @Input() text = 'Без учёта выбранных ТП';

  constructor() {
  }

  hasSelectedLocation() {
    return this.params?.locationIds?.length > 0;
  }

}
