import {EventEmitter, Injectable, OnDestroy, OnInit} from "@angular/core";

import {UserApiService} from "../../../api/UserApi/services"
import {RawUser} from "../../../api/UserApi/models"
import {Observable, from, of} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {

  public currentUser : RawUser;

  private tmpObs$;

  constructor(
    private userApiService:UserApiService
  ) {
    this.tmpObs$ = new Observable<RawUser>( s => {
      this.userApiService.data()
        .subscribe(result => {
          this.currentUser = result;
          s.next(this.currentUser);
          s.complete();
        });
    });
  }

  public getCurrentUser(): Observable<RawUser> {
    return !this.currentUser ? this.tmpObs$ : new Observable( s=> { s.next(this.currentUser); s.complete() });
  }


}
