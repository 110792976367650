import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../locations-dialog.variables";
import {GoodModelService} from "../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../service/api/CategoryModel/CategoryModel.service";
import {CategoryApiService} from "../../../../api/CategoryApi/services/category-api.service";
import {BrandService} from "../../../service/api/Brand/Brand.service";
import {GoodgroupService} from "../../../service/api/Goodgroup/Goodgroup.service";
import {map} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LocationsDialogComponent} from "../locations-dialog.component";

type legacyDirectiveParams = {
  resultDirective: (result: any) => any | {},  //+
  forPartner?: number | string,
  checkOne?: boolean | string,
  canCheckAll?: boolean | string,
  linkOne?: boolean | string,
  initSelectedItems?: any,
  initPartner?: number | string,

}

@Injectable({
  providedIn: "root",
})
export class LocationsDialogService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public open(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      LocationsDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    return modalRef.result;

  }

  public legacyPopup(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      LocationsDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    modalRef.result.then( result => {

      this.legacyResult(result, options.resultDirective)

    }, cancal => {})

  }

  private legacyResult(result, resultDirective) {

    if (typeof resultDirective === "function") {
      resultDirective(result);
    }

    if (typeof resultDirective === "object") {

      Object.keys(resultDirective).forEach( key => {
        delete resultDirective[key];
      })

      Object.assign(resultDirective, result);
    }

  }

  private legacySetOptions(modalInstance: LocationsDialogComponent, options: legacyDirectiveParams) {

    // Выбранный партнёр для старта
    if (!!options?.initPartner) {
      let partnerId = parseInt(<any>options?.initPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId );
    }

    // Форма только для этого партнёра
    if (!!options?.forPartner) {
      let partnerId = parseInt(<any>options?.forPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId, false );
    }

    if ( !!options?.linkOne || !!options?.checkOne ) {
      modalInstance.setOneSelect();
    }

    if ( !!options?.canCheckAll ) {
      modalInstance.setCheckAll( !!options?.canCheckAll );
    }

    if ( typeof options?.initSelectedItems !== "undefined") {
      modalInstance.initSelected(options?.initSelectedItems)
    }


  }

  private legacyConvertTypes( inputType ) {

    inputType = inputType.toLowerCase();

    switch (true) {
      case inputType === 'goodgroups' : return VIEW_TYPES.GOODGROUP;
      case inputType === 'goods'      : return VIEW_TYPES.SKU;
      case inputType === 'categories' : return VIEW_TYPES.CATEGORY;
      case inputType === 'brands'     : return VIEW_TYPES.BRAND;
    }

    return inputType;
  }


}
