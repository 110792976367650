<div class="fixed-tool-panel">

<ng-container
  *loyaPermitIf="{path:'referrer', permitType: 'create'}"
>
  <button type="button" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete') || currentReferrer?.state === 'archive' "
          (click)="statesChangerService.click('delete')"
          data-testid="remove"
  >
    <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'Архивировать'  }}
              </span>
  </button>

  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          *ngIf="statesChangerService.hasButton('edit') && currentReferrer?.state !== 'archive' "
          (click)="statesChangerService.click('edit')"
          data-testid="edit">

    <i class="fa fa-pencil"></i>
    <span class="btn-name m-l-xs hidden-xxs">
                Редактировать
              </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          (click)="isCorrectOn=true"
          *ngIf="!isCorrectOn && !statesChangerService.isActive"
          data-testid="correct"
  >

    <i class="fa fa-pencil"></i>
    <span class="btn-name hidden-xxs">
          Корректировка
    </span>
  </button>

</ng-container>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index" data-testid="to_list"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

<ng-container
  *loyaPermitIf="{path:'referrer', permitType: 'create'}"
>
  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="mcategoryForm?.invalid || !editReferrer?.promocode"
          data-testid="save"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Сохранить</span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="cancel"
  >

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Отменить</span>
  </button>

</ng-container>

</div>


<div class="card w-full no-back"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentReferrer && !statesChangerService.isActive"
  >
    <form name="mcategoryForm" #mcategoryForm="ngForm" novalidate autocomplete="off">

      <div class="row">

        <div class="col-sm-6">


          <div class="card b-a padder ">

            <div class=" m-t m-b ">
              <div class="h4 font-thin m-b-xs overflow-wrap-break">
                <a  [uiSref]="'frontend.crm.clients.view({id:'+currentReferrer.clientId+'})'" data-testid="currentReferrer.fio">
                  <span [innerText]="currentReferrer.fio || ''"></span>
                </a>
              </div>

              <div class="m-b f-s-9 ">
               <span [ngSwitch]="currentReferrer.state" data-testid="currentReferrer.state">
                 <span *ngSwitchCase="'active'">
                    <i class="fa fa-circle text-success m-r-xs"></i> Активный рефер
                 </span>

                 <span *ngSwitchCase="'archive'">
                    <i class="fa fa-circle text-danger m-r-xs"></i> Архивный рефер
                 </span>
               </span>
              </div>

              <div class="m-b-xs f-s-9">
                <i class="fa fa-calendar m-r-sm text-muted"></i>
                <span class="text-muted">Дата регистрации: &nbsp;</span>
                <span [innerText]="currentReferrer.registrationDate | loyaDateTime" data-testid="currentReferrer.registrationDate"></span>
              </div>
              <div class=" f-s-9">
                <i class="fa fa-user m-r-sm text-muted"></i>
                <span class="text-muted">Никнейм: &nbsp;</span>
                <span  [innerText]="currentReferrer.nickname || ''" data-testid="currentReferrer.nickname"></span>
              </div>
            </div>

            <div class="row  b-t bg-light lter">

              <div class="col-sm-6 b-r">

                <div class="row">

                  <div class="col-sm-12 f-s-12 b-b wrapper-sm padder">
                    <span class="text-muted">Промокод</span>
                    <span class="pull-right text-success" [innerText]="currentReferrer.promocode || '-'" data-testid="currentReferrer.promocode"></span>
                  </div>

                  <div class="col-sm-12 wrapper-sm padder" >

                    <div class="clearfix f-s-12 ">
                      <span class="text-muted">Баланс</span>
                      <span class="pull-right text-info" data-testid="currentReferrer.account.balance" [innerText]="currentReferrer.account.balance || 0"></span>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs"></div>

                    <div class="clearfix f-s-8">
                      <span class="text-muted">Начисленные бонусы</span>
                      <span class="pull-right" data-testid="currentReferrer.account.awardAmount" [innerText]="currentReferrer.account.awardAmount || 0"></span>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs"></div>

                    <div class="clearfix f-s-8">
                      <span class="text-muted">Списанные бонусы</span>
                      <span class="pull-right" data-testid="currentReferrer.account.salesAmount" [innerText]="currentReferrer.account.salesAmount || 0"></span>
                    </div>


                  </div>


                </div>

              </div>

              <div class="col-sm-6">

                <div class="row">

                  <div class="col-sm-12  wrapper-sm padder b-b">

                    <div class="clearfix">
                      <span class="text-muted">Кол-во привлечённых клиентов</span>
                      <span class="pull-right" [innerText]="currentReferrer.uniqueReferrals || 0"></span>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs"></div>

                    <div class="clearfix">
                      <span class="text-muted">Новых клиентов</span>
                      <span class="pull-right" [innerText]="currentReferrer.uniqueNewReferrals || 0"></span>
                    </div>

                  </div>
                  <div class="col-sm-12 wrapper-sm padder">

                    <div class="clearfix">
                      <span class="text-muted">Количество чеков</span>
                      <span class="pull-right" [innerText]="currentReferrer.checkCount || 0"></span>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs"></div>

                    <div class="clearfix">
                      <span class="text-muted">Сумма покупок</span>
                      <div class="d-inline-block pull-right">
                        <span [innerText]="currentReferrer.checkTotal || 0"></span>
                        <i class="fa le m-l-xs"  ngCurrencyLoya=""></i>
                      </div>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-xs"></div>

                    <div class="clearfix">
                      <span class="text-muted">Средний чек</span>
                      <div class="d-inline-block pull-right">
                        <span [innerText]="currentReferrer.averageCheck || 0"></span>
                        <i class="fa le m-l-xs"  ngCurrencyLoya=""></i>
                      </div>
                    </div>

                  </div>


                </div>
              </div>

            </div>

          </div>


        </div>
        <div class="col-sm-6">
          <referrer-graph-account
            [id]="currentReferrer.id"
          ></referrer-graph-account>
        </div>
      </div>

      <div class="row" *ngIf="isCorrectOn">
        <div class="col-sm-6">
          <referrer-edit-account
            [id]="currentReferrer.id"
            [currentBalance]="currentReferrer.account.balance"
            (onChange)="isCorrectOn = false; !!$event && refreshPage()"
          ></referrer-edit-account>
        </div>
      </div>

      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Операции по счёту</a>
          <ng-template ngbNavContent>
          <div class="row">
            <div class="col-md-12 no-padder">

              <responsive-table>
                <table
                  class="table b-b "
                  infiniteScroll
                  [infiniteScrollDistance]="1"
                  [infiniteScrollThrottle]="50"
                  [scrollWindow]="false"
                  [infiniteScrollContainer]="'#app-container'"
                  [fromRoot]="true"
                  (scrolled)="nextRecordPage()"
                >
                  <thead>
                  <tr>
                    <th>
                      <sort-view
                        column="date"
                        [params]="referrerService.gerRecordsParams"
                        (onChange)="resetRecordPage()"
                      >
                        Дата
                      </sort-view>
                    </th>
                    <th>
                      Тип операции
                    </th>
                    <th>
                      Сумма
                    </th>
                    <th class="table-row-sm table-row-xs ">
                      Номер чека
                    </th>

                  </tr>
                  </thead>
                  <tbody>
                  <ng-container  *ngFor="let item of records">
                    <tr data-testid="item in records">
                      <td data-testid="item.date">
                        <span [innerText]="item.date | loyaDateTime"></span>
                      </td>
                      <td class="table-big-link" data-testid="item.operType">
                        <span [innerText]="OPER_TYPES[item.operType] || item.operType"></span>
                      </td>
                      <td data-testid="item.amount">
                        <span [innerText]="item.amount || 0 | loyaNumber"></span>
                      </td>
                      <td data-testid="item.checkNumber">
                        <ng-container *ngIf="!!item.checkNumber">
                          <a  [innerText]="item.checkNumber"
                              [href]="'/#!/receipt/index/'+item.checkNumber+ '?id=' + item.checkId"
                          ></a>
                        </ng-container>
                        <ng-container *ngIf="!item.checkNumber">
                          <span class="text-muted">Чек отсутствует</span>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </responsive-table>

            </div>
          </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Журнал изменений</a>
          <ng-template ngbNavContent>
            <referrer-log-history
              *ngIf=" currentReferrer?.id!== undefined"
              [referrerId]="currentReferrer?.id"
            ></referrer-log-history>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="m-t-n-xxs"></div>





    </form>

  </div>

  <div class="card-body"
       *ngIf="statesChangerService.isActive "
  >
    <form name="mcategoryForm" #mcategoryForm="ngForm" novalidate autocomplete="off">
      <div>
      <div class="row">
        <div class="col-sm-3 m-b ng-binding">Клиент</div>
        <div class="col-sm-9 m-b">
          <a href class="text-info"
             *ngIf="currentClient?.client"
             [uiSref]="'frontend.crm.clients.view({id:'+ currentClient?.client?.id +'})'">
            {{ currentClient.client.surname }} {{ currentClient.client.name }}
          </a>

          <div class="m-t"
               *ngIf="!!currentClient?.$error"
               data-testid="alert"
          >
            <ng1-alert
              [type]="'danger'"
              (close)="currentClient.$error = undefined"
            >
              {{currentClient?.$error}}
            </ng1-alert>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 m-b-sm ng-binding">Промокод</div>
        <div class="col-sm-9 m-b-sm">
          <input type="text"
                 class="form-control form-control-edittable m-b-xs"
                 [(ngModel)]="editReferrer.promocode"
                 name="currentReferrer_promocode"
                 placeholder="Введите промокод"
                 [maxlength]="16"
                 [required]="true"
                 pattern="[\da-zA-Z]+"
                 data-testid="currentReferrer_promocode"
                 >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 m-b-sm ng-binding">Никнейм</div>
        <div class="col-sm-9 m-b-sm">
          <input type="text"
                 class="form-control form-control-edittable m-b-xs"
                 [(ngModel)]="editReferrer.nickname"
                 name="currentReferrer_nickname"
                 placeholder="Введите никнейм"
                 [maxlength]="20"
                 data-testid="currentReferrer_nickname"
          >
        </div>
      </div>

    </div>
    </form>
  </div>


</div>
