import {tap} from "rxjs/operators";
import {throwError} from "rxjs";

export class LoaderHandlerClass {

    private storage = new Map();

    constructor() {
    }

    startLoading(key) {
        this.storage.set(key, true);
    }

    stopLoading(key) {
        this.storage.set(key, false);
    }

    isLoading(key) {
        return this.storage.has(key) ? this.storage.get(key) : false;
    }

    waitLoading<T>(key){
        this.startLoading(key);
        return tap<T>({
            next: (result) => {
                this.stopLoading(key);
                return result;
            },
            error: (err) => {
                this.stopLoading(key);
                return throwError(err);
            },
            complete: () => {
                this.stopLoading(key);
            }
        })
    }

}
