
import {Component, Input, OnInit} from "@angular/core";
import {AccountService} from "../../../../../../shared/service/api/Account/Account.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {ACCOUNT_OPER_TYPE, ACCOUNT_RECORD_STATUS} from "../../../../../../shared/service/api/Account/Account.variables";
import {getDescription} from "../../utils/account-paget.utils";
import {PartnerService} from "../../../../../../shared/service/api/Partner/Partner.service";
import {
  ClientTransactionsService
} from "../../../../../../shared/service/api/ClientTransactions/ClientTransactions.service";
import {CreditAccountService} from "../../../../../../shared/service/api/CreditAccount/CreditAccount.service";

@Component({
  selector: 'credit-account-operations-log-edit-page',
  templateUrl: './creaditAccountOperationsLog-edit-page.component.html',
  styleUrls: ['./creaditAccountOperationsLog-edit-page.component.scss'],
  providers: [
    PartnerService,
    AccountService,
    ClientTransactionsService
  ]
})
export class CreaditAccountOperationsLogEditPageComponent implements OnInit {

  public loader = new LoaderHandlerClass();

  @Input() public id: number;

  public creditHistory = [];
  public partnerList: any = false;

  public checkForShow: any = false;
  public checkForShowIndex;

  public getDescription = getDescription;
  public ACCOUNT_OPER_TYPE = ACCOUNT_OPER_TYPE;

  constructor(
    public creditAccountService: CreditAccountService,
    public partnerService : PartnerService,
    public clientTransactionsService: ClientTransactionsService,
  ) { }

  ngOnInit() {

    this.getCreditAccountId()
      .then( value => {
        if ( !value?.id)
          return;

        this.creditAccountService.getRecordsParams.setFilterValue('creditAccountId',value.id)
        this.reset();
      })

  }

  getCreditAccountId() {
    return this.creditAccountService
      .get$(this.id)
      .pipe(
        this.loader.waitLoading('list')
      ).toPromise()
  }

  public getPartners() {
    this.partnerService.query$()
      .subscribe({
        next: result => {
          this.partnerList = result;
        }
      })
  }


  public getList() {

    this.creditAccountService
      .getRecords$(this.creditAccountService.getRecordsParams.params)
      .pipe(
        this.loader.waitLoading('list'),
        this.creditAccountService.getRecordsParams.pagerPipe()
      )
      .subscribe( {
        next: (result: any) => {
          this.creditHistory = this.creditHistory.concat(result)
        }
      })

  }

  public reset() {
    this.creditHistory = [];
    this.creditAccountService.getRecordsParams.reset();
    this.getList();
  }

  public onScroll() {
    this.creditAccountService.getRecordsParams.next();
    this.getList();
  }


  public getStatusName(id, operType) {

    if ( id === 'transferred') {
      return ( operType === 'credit' ? ACCOUNT_RECORD_STATUS[id+'Credit'] : ACCOUNT_RECORD_STATUS[id+'Other'] ) || '';
    }

    return ACCOUNT_RECORD_STATUS[id] || id;
  }

  public getPartnerName(id) {

    if (this.partnerList === false)
      return ;

    return this.partnerList.reduce( ( name, item ) => item.id === id ? item.name : name , '');

  }

  public showCheck( checkId, index ) {

    if (!checkId || this.loader.isLoading('check'))
      return;

    this.checkForShow = checkId;
    this.checkForShowIndex = index;

    this.clientTransactionsService
      .findCheckById$(checkId)
      .pipe(
        this.loader.waitLoading('check')
      )
      .subscribe( {
        next: (result) => {
          if (result && result[0]) {
            this.checkForShow = result[0];
          }
        },
      error: () => {
        this.checkForShow = undefined;
       }
      });

  }

  public isCheckShow(index) {
    return this.checkForShowIndex === index && !this.loader.isLoading('check')
  }

}
