
import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, Optional} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";
import {VIEW_TYPES, VIEW_TYPES_NAMES} from "./locations-dialog.variables";
import {LocationsDialogGetterService} from "./service/locations-dialog-getter.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderHandlerClass} from "../../class/loader-handler.class";

@Component({
  selector: 'locations-dialog',
  templateUrl: "./locations-dialog.component.html",
  styleUrls: ['./locations-dialog.component.scss'],
  providers: [
    PartnerService,
  ]
})
 export class LocationsDialogComponent implements OnInit, OnChanges{

  //-------------LEGACY--------------

  showPartner = true

  @Input()  selectedLocation = new Set<number>();
  @Input()  selectedLocationGroup = new Set<number>();

  @Input()  editCallBack: any;

  @Input() public partnerId;
  public isPartnerSetted = false;
  public partnerList = [];
  public partner;

  public VIEW_TYPES = VIEW_TYPES;
  public viewList: any[];

  public selectOne;
  public canCheckAll = false;
  public selectedAll = false;



  public sortingTypes = {
    'none' : 'Без сортировки',
    'name' : 'По имени'
  }
  public sortingField;

  searchString = ''

  public selectedLocationGroupId;

  public loader = new LoaderHandlerClass();

  constructor(
      private partnerService : PartnerService,
      private locationsDialogGetterService: LocationsDialogGetterService,
      @Optional() private activeModal: NgbActiveModal
  )
  {

  }

  ngOnInit() {


    if (!this.viewList)
      this.setViewList( Object.values(VIEW_TYPES).filter(i => i !== VIEW_TYPES.GOODGROUP_SKU) );

    this.getPartnerList();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  // Список представлений
  public setViewList( values: VIEW_TYPES[] = [], defaultSelect?:VIEW_TYPES ) {

    this.viewList = [];

    values.forEach( key => {
      this.viewList.push ( {
        id: key,
        label: VIEW_TYPES_NAMES[key]
      } )
    })

  }

  // Партнёр по умолчанию
  public setPartner( id: number, canEdit = true ) {
    this.isPartnerSetted = !canEdit;
    this.partnerId = id;
  }

  public getPartnerById(id) {

    if (typeof id === "undefined")
      return ;

    if (this.partner?.id === id)
      return this.partner;

    this.partner = this.partnerList.find( i => i.id === id);
    return this.partner;
  }

  // Установка только одного значения
  public setOneSelect( value = true ) {
    this.selectOne = value;
  }

  public setCheckAll( value = false ) {
    this.canCheckAll = !!value;
    if (this.canCheckAll)
      this.setOneSelect(false)
  }

  public initSelected( values: any ) {

    this.selectedLocation.clear();

    if ( values === true)
      this.selectedAll = true;

    if ( Array.isArray(values)) {

      values.forEach( val => {
        let valForAdd;

        if( typeof val === "object") {
          valForAdd = parseInt(val?.id);
          if ( isNaN(valForAdd) ) {
            valForAdd = parseInt(val?.merchantId);
          }

        } else {
          valForAdd = parseInt(val);
        }

        if ( typeof valForAdd === "number" ) {
          this.selectedLocation.add(valForAdd);
        }

      })

    }

    return;
  }

  get hasSelected() {
    let selectedMap = this.selectedLocation;
    return selectedMap ? !!this.selectedLocation?.size : false
  }

  get isEditMode() {
    return typeof this.editCallBack === "function"
  }

  getPartnerList() {
    this.partnerService
        .query$()
        .subscribe({
          next: result => {
            this.partnerList = result.map( i => ({
                id: i.id,
                value: i.name,
              })
            );

            if ( typeof this.partnerId === "undefined")
              this.partnerId = this.partnerList[0]?.id;

          }

        })
  }

  cancel() {
    this.locationsDialogGetterService.resetCache();
    this.activeModal.dismiss(false);
  }

  async prepareResult() {

    let result: any = {
      ids:  [],
      partnerId: this.partnerId,
      locationGroup: [],
      locations: [],
      hasLocationsNotInGroups: false
    }

    if (this.selectedAll) {
      result.selectedAll = true;
      return result;
    }

    if (!this.hasSelected)
      return result;

    result.ids = Array.from( this.selectedLocation?.values() );
    result.locations = await this.locationsDialogGetterService.getLocationByIds( Array.from( this.selectedLocation?.values()), this.partnerId );
    result.locationGroup = await this.locationsDialogGetterService.getLocationGroupsByIds( Array.from( this.selectedLocationGroup?.values()), this.partnerId );

    // TODO ждём, когда в Location будет LocationGroupID
    const idsForLocations = await this.locationsDialogGetterService.getLocationIdsForGroups( Array.from( this.selectedLocationGroup?.values()), this.partnerId );
    result.hasLocationsNotInGroups = result.ids.some( id => !idsForLocations.includes(id) );

    return result;

  }

  async select() {

    this.loader.startLoading('selectPrepare');

    try {
      let result = await this.prepareResult();
      this.activeModal.close( result );
      this.locationsDialogGetterService.resetCache();
      this.loader.stopLoading('selectPrepare');
    } catch (e) {
      this.loader.stopLoading('selectPrepare');
    }

  }

  public forCallbacks = (value) => {
    if (!this.isEditMode)
      return

    this.editCallBack(value);
  }

}
