'use strict';

import 'model/GoodGroupModel/resource';

export default  ['$scope', '$uibModalInstance', '$timeout', 'FileUploader', '$http', '$q', 'GoodGroupModel',
    function ($scope, $uibModalInstance, $timeout, FileUploader, $http, $q, GoodGroupModel) {

        /***** Настройки импорта *******/

        $scope.encodingList={

            'utf8': 'UTF-8',
            'cp1251': 'WIN1251'

        };

        $scope.importOptions = {

            encoding   : 'utf8',
            fromString : 1,
            scuIndex   : 1,
            quantityIndex: 2,
            locationIndex: 3,
            delimiter  : ';',
            filenameOrigin : '',

            quantityPiece  : 1,
            quantityWeight : 0.001,

        };

        $scope.delimiters = {

            tab: '\\t',
            comma: ',',
            semicolon: ';',
            space: ' ',
            colon: ':',
            other: 'other'

        };

        $scope.delimiterOptions = {
            index : 'other',
            otherValue : ''
        };

        $scope.tableRows = {

            "table": [  ]

        };

        /************************/

        $scope.reject = function() {

            if (loadedFile) {
                $http.delete( loadedFile  ).finally(function() {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }

        };

        var loadedFile: any = false;
        $scope.$on('onSuccessItem', function( event, result ) {

            $scope.loadScreen('CONFIG');
            loadedFile = result;

            $scope.updateOptions().then(
                function(result) {

                    $scope.tableRows = result.table;

                }, $uibModalInstance.reject
            );

        });


        $scope.getColumnByIndex = function( index ) {

            switch (true) {
                case $scope.importOptions.scuIndex          === index :  return 'SKU';
                case $scope.importOptions.quantityIndex     === index :  return 'Количество';
                case $scope.importOptions.locationIndex     === index :  return 'ТП';

                default : return '--';
            }

        }

        $scope.setColumnIndex = function( idColumn, index ) {

            ['scuIndex', 'quantityIndex', 'locationIndex'].forEach( column => {
                $scope.importOptions[column] = $scope.importOptions[column] === index ? -1 : $scope.importOptions[column];
            } );

            if (idColumn) {
                $scope.importOptions[idColumn] = index;
            }

        }

        $scope.isValidColums = function() {
            if (
                $scope.importOptions.scuIndex === $scope.importOptions.quantityIndex
            ) return false;

            if (
                $scope.importOptions.scuIndex === $scope.importOptions.locationIndex
            ) return false;

            if (
                $scope.importOptions.scuIndex      === -1
            ) return false;

            let rowsCount = $scope.tableRows &&
                            $scope.tableRows.table &&
                            $scope.tableRows.table[0] &&
                            $scope.tableRows.table[0].row ? $scope.tableRows.table[0].row.length -1 : 0 ;

            if (
                $scope.importOptions.scuIndex > rowsCount
            ) return false;

            return true;
        }

        let initFlag = true;
        $scope.isLoadPreview = false;
        $scope.previewError = false;
        $scope.updateOptions = function ( ) {

            if ( loadedFile === false || angular.isUndefined($scope.importOptions.fromString)) {
                return $q.resolve( { table: $scope.tableRows } );
            }

            var params: any = {};

            if ($scope.importOptions.delimiter !== '')
                params['delimiter']   = $scope.importOptions.delimiter;

            if ($scope.importOptions.fromString !== false ) {

                params.pager = {
                    limit: 20,
                    drop: $scope.importOptions.fromString - 1
                };

            }

            if ($scope.importOptions.encoding){
                params['encoding']    = $scope.importOptions.encoding;
            }

            if ($scope.importOptions.scuIndex !== false) {
                params['skuColumn']      = $scope.importOptions.scuIndex;
            }

            if ($scope.importOptions.quantityIndex !== false && $scope.importOptions.quantityIndex !== -1 ) {
                params['quantityColumn']    = $scope.importOptions.quantityIndex;
            }

            if ($scope.importOptions.locationIndex !== false && $scope.importOptions.locationIndex !== -1 ) {
                params['locationColumn']    = $scope.importOptions.locationIndex;
            }

            if ( $scope.importOptions.quantityPiece !== false  && !angular.isUndefined( $scope.importOptions.quantityPiece ) ) {
                params['quantityPiece']    = $scope.importOptions.quantityPiece;
            }

            if ( $scope.importOptions.quantityWeight !== false && !angular.isUndefined( $scope.importOptions.quantityWeight ) ) {
                params['quantityWeight']    = $scope.importOptions.quantityWeight;
            }

            if (initFlag) {
                params = {};
            }
            $scope.isLoadPreview = true;
            return GoodGroupModel.preview( { filename: loadedFile.split("/").splice(-1) }, params, function(result) {

                if ( result.status !== 200) {
                    return $q.reject(result.data);
                }

                try {
                    return angular.isString( result.data ) ? JSON.parse( result.data ) : result.data;
                } catch(e) {
                    return $q.reject(result.data);
                }

            } )
                .$promise
                .then(function(data) {

                    if (data && data.error) {

                        $scope.previewError = data.error;

                    } else {
                        $scope.previewError = false;
                    }

                    $scope.isLoadPreview = false;
                    if ( initFlag ) {

                        $scope.importOptions.extension = data.extension;
                        $scope.importOptions.filename = data.filename;
                        $scope.importOptions.fromString = data.pager.drop + 1 ;
                        $scope.importOptions.delimiter = data.delimiter;
                        $scope.importOptions.encoding = data.encoding;
                        $scope.importOptions.scuIndex = data.skuColumn;
                        $scope.importOptions.quantityIndex  = data.quantityColumn;
                        $scope.importOptions.locationIndex  = data.locationColumn;
                        $scope.importOptions.filenameOrigin = data.filenameOrigin;

                        if ( !angular.isUndefined( data.quantityPiece ) ) {
                            $scope.importOptions.quantityPiece    = data.quantityPiece;
                        }

                        if ( !angular.isUndefined( data.quantityWeight ) ) {
                            $scope.importOptions.quantityWeight    = data.quantityWeight;
                        }

                        if ( data.table && data.table.table ) {

                            if ( data.table.table[0] && data.table.table[0].row.length -1 < $scope.importOptions.scuIndex ) {
                                $scope.importOptions.scuIndex = 0;
                            }

                            if ( data.table.table[0] && data.table.table[0].row.length -1 < $scope.importOptions.quantityIndex ) {
                                $scope.importOptions.quantityIndex = -1;
                            }

                            if ( data.table.table[0] && data.table.table[0].row.length -1 < $scope.importOptions.locationIndex ) {
                                $scope.importOptions.locationIndex = -1;
                            }

                        } else {
                            $scope.importOptions.scuIndex = 0;
                            $scope.importOptions.quantityIndex = 1;
                            $scope.importOptions.locationIndex = 2;
                        }

                        $scope.delimiterOptions.index = false;
                        angular.forEach($scope.delimiters, function(value,  index ) {
                            if ( value === $scope.importOptions.delimiter) {
                                $scope.delimiterOptions.index = index;
                            }
                        });

                        if ( $scope.delimiterOptions.index === false ) {
                            $scope.delimiterOptions.index = 'other';
                            $scope.delimiterOptions.otherValue = $scope.importOptions.delimiter;
                        }

                        initFlag = false;
                    }

                    return data;

                }).catch(function(data) {
                    $scope.isLoadPreview = false;
                    $scope.previewError = data.data;
                    $uibModalInstance.dismiss({ error: data.data } );
                } );

        };

        $scope.$watch('delimiterOptions', function( newVal, oldVal ) {

            if ( angular.isUndefined(newVal) ) {
                return;
            }

            if ( newVal.index !== 'other' ) {

                $scope.importOptions.delimiter = $scope.delimiters[newVal.index];

            } else {

                $scope.importOptions.delimiter = newVal.otherValue;
            }

        },true);

        $scope.$watch('importOptions', function( newVal, oldVal ) {

            if ( angular.isUndefined(newVal) ) {
                return;
            }

            if ( newVal.scuIndex !== oldVal.scuIndex ) {

                let otherChanges = false;
                for ( let index in newVal) {
                    if (  newVal.hasOwnProperty(index) && newVal[index] !== oldVal[index]) {
                        otherChanges = true;
                        break;
                    }
                }

                if ( otherChanges )
                    return;
            }

            if ( $scope.delimiterOptions.index !== 'other' ) {

                $scope.delimiterOptions.index = false;
                angular.forEach($scope.delimiters, function(value,  index ) {
                    if ( value === newVal.delimiter) {
                        $scope.delimiterOptions.index = index;
                    }
                });

                if ( $scope.delimiterOptions.index === false ) {
                    $scope.delimiterOptions.index = 'other';
                    $scope.delimiterOptions.otherValue = newVal.delimiter;
                }

            }

            $scope.updateOptions().then(
                function(result) {

                    $scope.tableRows = result.table;

                }, $uibModalInstance.reject
            );

        }, true);

        $scope.confirmImport = function() {

            $scope.loadScreen('IMPORT');
            GoodGroupModel.upload({
                partnerId : $scope.partnerId,
                id        : $scope.id,
                filename  : $scope.importOptions.filename,
                'pager.limit' : undefined,
            },{

                skuColumn    : $scope.importOptions.scuIndex,
                quantityColumn  : $scope.importOptions.quantityIndex,
                locationColumn  : $scope.importOptions.locationIndex,
                delimiter : $scope.importOptions.delimiter,
                encoding  : $scope.importOptions.encoding,
                drop      : $scope.importOptions.fromString - 1,

                quantityPiece  : $scope.importOptions.quantityPiece,
                quantityWeight : $scope.importOptions.quantityWeight

            }, function(data) {
                $uibModalInstance.close(data);
            }, function(data) {
                $uibModalInstance.dismiss({ error: data.data } );
            });

        };

        $scope.showOption = function( field ) {

            switch( field ) {
                case "encoding": return $scope.importOptions.extension === 'csv';
                case "delimiter": return $scope.importOptions.extension === 'csv';

            }

            return true;
        }


    }
];
