/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FavoriteGoodsProfileApiConfiguration as __Configuration } from '../favorite-goods-profile-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QueryByClientResult } from '../models/query-by-client-result';
import { Profile } from '../models/profile';
@Injectable({
  providedIn: 'root',
})
class FavoriteGoodsProfileApiService extends __BaseService {
  static readonly queryByClientPath = '/{clientId}';
  static readonly createPath = '/';
  static readonly updatePath = '/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FavoriteGoodsProfileApiService.QueryByClientParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `pager.limit`: Сколько всего выбрать записей
   *
   * - `pager.drop`: Сколько пропустить записей с начала выборки
   *
   * - `campaignId`:
   */
  queryByClientResponse(params: FavoriteGoodsProfileApiService.QueryByClientParams): __Observable<__StrictHttpResponse<Array<QueryByClientResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    if (params.campaignId != null) __params = __params.set('campaignId', params.campaignId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QueryByClientResult>>;
      })
    );
  }
  /**
   * @param params The `FavoriteGoodsProfileApiService.QueryByClientParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `pager.limit`: Сколько всего выбрать записей
   *
   * - `pager.drop`: Сколько пропустить записей с начала выборки
   *
   * - `campaignId`:
   */
  queryByClient(params: FavoriteGoodsProfileApiService.QueryByClientParams): __Observable<Array<QueryByClientResult>> {
    return this.queryByClientResponse(params).pipe(
      __map(_r => _r.body as Array<QueryByClientResult>)
    );
  }

  /**
   * @param profile undefined
   */
  createResponse(profile: Profile): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = profile;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param profile undefined
   */
  create(profile: Profile): __Observable<Profile> {
    return this.createResponse(profile).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param profile undefined
   */
  updateResponse(profile: Profile): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = profile;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param profile undefined
   */
  update(profile: Profile): __Observable<Profile> {
    return this.updateResponse(profile).pipe(
      __map(_r => _r.body as Profile)
    );
  }
}

module FavoriteGoodsProfileApiService {

  /**
   * Parameters for queryByClient
   */
  export interface QueryByClientParams {
    clientId: number;

    /**
     * Сколько всего выбрать записей
     */
    pagerLimit?: number;

    /**
     * Сколько пропустить записей с начала выборки
     */
    pagerDrop?: number;
    campaignId?: number;
  }
}

export { FavoriteGoodsProfileApiService }
