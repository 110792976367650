import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {UserService} from "../../../../../shared/service/api/User/User.service"
import {UserProtocol, UserState} from "../../../../../api/UserApi/models"
import {rolesList as systemRolesList, RoleType} from "module/permissions/conf/roles";
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service";
import {CurrentUserService} from "../../../../../shared/service/currentUser/currentUser.service";

import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";

@Component({
  selector: 'users-index-page',
  templateUrl: './users-index-page.component.html',
})
export class UsersIndexPageComponent implements OnInit{

  searchString: string;
  listUsers: Array<UserProtocol>
  rolesList = {};

  public loader = new LoaderHandlerClass();

  constructor(
    private userService:UserService,
    private rolesModelService: RolesModelService,
    private currentUserService: CurrentUserService
  ) {
  }

  ngOnInit() {
    this.getRoles();
    this.getList();
  }

  getList() {
    this.loader.startLoading('userList')
    Promise.all([
        this.userService.getList().toPromise(),
        this.currentUserService.getCurrentUser().toPromise()
      ]
    ).then(result => {
        this.listUsers = result[0];
        this.loader.stopLoading('userList')
        this.listUsers = this.listUsers.filter( role => !(result[1].role !== RoleType.Administrator  && role.role === RoleType.Administrator) );
      });
  }

  getRoles() {
    this.rolesModelService.getUserRoles()
      .subscribe(result => {
        this.rolesList = <any>result;
      });
  }

  getRoleName(id) {
    return this.rolesList[id] || systemRolesList[id] || id;
  }

  filterBySearch = (item: UserProtocol) => {
    if (!this.searchString || !item) return true;

    return  item.name && item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) >=0
         || item.email && item.email.toLowerCase().indexOf(this.searchString.toLowerCase())>=0;
  }

  isActive( item: UserProtocol) {
    return item.state === UserState.ACTIVE;
  }

  isArchive( item: UserProtocol) {
    return item.state === UserState.ARCHIVE;
  }

}
