'use strict';

import module from 'frontendModule';

import 'model/Settings';

import * as  template     from  './views/index.html';
import controller from './controllers';

let routePath = module.name + '.system.settings.system';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )

                .template(template)
                .controller(controller)

                .breadcrumbs( ["Системные настройки"] )

                .resolve('settingsList', [ 'Settings',(Settings) => Settings.query().$promise ])

                .permissions({
                    only: ['Administrator'],
                    redirectTo: 'frontend.nopermissions'
                })

        }]);

