/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for TransactionApi services
 */
@Injectable({
  providedIn: 'root',
})
export class TransactionApiConfiguration {
  rootUrl: string = location.origin;
}

export interface TransactionApiConfigurationInterface {
  rootUrl?: string;
}
