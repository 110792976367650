import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule, TRANSLOCO_SCOPE, TranslocoService
} from '@ngneat/transloco';
import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import { environment } from '../environments/environment';



export const preLoad = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: function preloadTransloco( transloco: TranslocoService) {
  return function() {
    return Promise.all([ transloco.load('interface/ru').toPromise(), transloco.load('models/ru').toPromise()]);
  };
},
  deps: [ TranslocoService]
};

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string = '') {

    if (lang.startsWith('pages'))
      lang = lang.replace(/\./ig,'/');

    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['ru', 'en'],
        defaultLang: 'ru',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_SCOPE, useValue: ['interface', 'models'] },

  ]
})

export class TranslocoRootModule {
  constructor(private translocoService: TranslocoService) {
    (<any>window)['ru'] = () => translocoService.setActiveLang('ru');
    (<any>window)['en'] = () => translocoService.setActiveLang('en');
  }
}
