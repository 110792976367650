/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LocationGroupApiConfiguration as __Configuration } from '../location-group-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LocationGroup } from '../models/location-group';
import { LocationGroupQueryParam } from '../models/location-group-query-param';
@Injectable({
  providedIn: 'root',
})
class LocationGroupApiService extends __BaseService {
  static readonly queryPath = '/locationgroup';
  static readonly createPath = '/locationgroup';
  static readonly getPath = '/locationgroup/{id}';
  static readonly updatePath = '/locationgroup/{id}';
  static readonly deletePath = '/locationgroup/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка локаций
   * @param params Фильтры запроса
   * @return Список всех ограничений вознаграждений
   */
  queryResponse(params?: LocationGroupQueryParam): __Observable<__StrictHttpResponse<Array<LocationGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/locationgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationGroup>>;
      })
    );
  }
  /**
   * Запрос списка локаций
   * @param params Фильтры запроса
   * @return Список всех ограничений вознаграждений
   */
  query(params?: LocationGroupQueryParam): __Observable<Array<LocationGroup>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<LocationGroup>)
    );
  }

  /**
   * Создание локации
   * @param locationGroup Параметры локации
   * @return Созданная локация
   */
  createResponse(locationGroup: LocationGroup): __Observable<__StrictHttpResponse<LocationGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = locationGroup;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/locationgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationGroup>;
      })
    );
  }
  /**
   * Создание локации
   * @param locationGroup Параметры локации
   * @return Созданная локация
   */
  create(locationGroup: LocationGroup): __Observable<LocationGroup> {
    return this.createResponse(locationGroup).pipe(
      __map(_r => _r.body as LocationGroup)
    );
  }

  /**
   * Найти локацию по ID
   * @param id ID локации
   * @return Локация
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<LocationGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/locationgroup/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationGroup>;
      })
    );
  }
  /**
   * Найти локацию по ID
   * @param id ID локации
   * @return Локация
   */
  get(id: number): __Observable<LocationGroup> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as LocationGroup)
    );
  }

  /**
   * Изменить локацию по ID
   * @param params The `LocationGroupApiService.UpdateParams` containing the following parameters:
   *
   * - `locationGroup`: Параметры локации
   *
   * - `id`: ID локации
   *
   * @return Локация
   */
  updateResponse(params: LocationGroupApiService.UpdateParams): __Observable<__StrictHttpResponse<LocationGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.locationGroup;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/locationgroup/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationGroup>;
      })
    );
  }
  /**
   * Изменить локацию по ID
   * @param params The `LocationGroupApiService.UpdateParams` containing the following parameters:
   *
   * - `locationGroup`: Параметры локации
   *
   * - `id`: ID локации
   *
   * @return Локация
   */
  update(params: LocationGroupApiService.UpdateParams): __Observable<LocationGroup> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as LocationGroup)
    );
  }

  /**
   * Удалить локацию по ID
   * @param id ID локации
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/locationgroup/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить локацию по ID
   * @param id ID локации
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LocationGroupApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * Параметры локации
     */
    locationGroup: LocationGroup;

    /**
     * ID локации
     */
    id: number;
  }
}

export { LocationGroupApiService }
