export interface ImageItem {
    imageId : string | {guid: string};
    imageType  : Array<number>;
}

export class ImageUploaderHandler {

    private saveStorageItems   : { [key: string]: ImageItem};
    private removeStorageItems : { [key: string]: ImageItem};

    constructor() {
        this.clear();
    }

    public getGuid(item: ImageItem) {
      return typeof item?.imageId === "object" ? item?.imageId?.guid : item?.imageId;
    }

    public clear() {
        this.saveStorageItems   = {};
        this.removeStorageItems = {};
    }

    private getStorageID( item: ImageItem ) {
        return this.getGuid(item);
    }

    private inSaveStorage( item: ImageItem ) {
        return !!this.saveStorageItems[this.getStorageID(item)];
    }

    private inRemoveStorage( item: ImageItem ) {
        return !!this.removeStorageItems[this.getStorageID(item)];
    }

    private addToSave( item: ImageItem ) {
        this.saveStorageItems[this.getStorageID(item)] = item;
    }

    private removeFromSave( item: ImageItem ) {
        delete this.saveStorageItems[this.getStorageID(item)];
    }

    private addToRemove( item: ImageItem ) {
        this.removeStorageItems[this.getStorageID(item)] = item;
    }

    private removeFromRemove( item: ImageItem ) {
        delete this.removeStorageItems[this.getStorageID(item)];
    }

    public hasItems() {
      return !!Object.keys(this.saveStorageItems)?.length || !!Object.keys(this.removeStorageItems)?.length
    }

    public add( item : ImageItem ) {

        if ( this.inSaveStorage(item) )
            return;

        if ( this.inRemoveStorage(item) )
            return this.removeFromRemove(item);

        this.addToSave(item);

    }

    public delete( item : ImageItem ) {

        if ( this.inRemoveStorage(item) )
            return;

        if ( this.inSaveStorage(item) )
            return this.removeFromSave(item);

        this.addToRemove(item);

    }

    public change( before : ImageItem, after: ImageItem) {
        if ( this.inRemoveStorage(before) )
            this.removeFromRemove(before);

        if ( this.inSaveStorage(before) )
            this.removeFromSave(before);

        this.addToSave(after);
    }

    public getItemsForRemove() {
        return Object.keys(this.removeStorageItems)
                     .map(key => this.removeStorageItems[key]);
    }

    public getItemsForSave() {
        return Object.keys(this.saveStorageItems)
                     .map(key => this.saveStorageItems[key]);
    }

}
