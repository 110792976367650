/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SocialApiConfiguration, SocialApiConfigurationInterface } from './social-api-configuration';

import { SocialApiService } from './services/social-api.service';

/**
 * Provider for all SocialApi services, plus SocialApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    SocialApiConfiguration,
    SocialApiService
  ],
})
export class SocialApiModule {
  static forRoot(customParams: SocialApiConfigurationInterface): ModuleWithProviders<SocialApiModule> {
    return {
      ngModule: SocialApiModule,
      providers: [
        {
          provide: SocialApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
