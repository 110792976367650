/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EditionApiConfiguration as __Configuration } from '../edition-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetEdition } from '../models/get-edition';
import { Edition } from '../models/edition';
import { ChangeStatus } from '../models/change-status';
import { HistoryElement } from '../models/history-element';
import { RangeInfo } from '../models/range-info';
@Injectable({
  providedIn: 'root',
})
class EditionApiService extends __BaseService {
  static readonly editionListPath = '/edition/list';
  static readonly createEditionPath = '/edition';
  static readonly getPath = '/edition/{id}';
  static readonly editPath = '/edition/{id}';
  static readonly deletePath = '/edition/{id}';
  static readonly changeStatusPath = '/edition/{id}/status';
  static readonly queryHistoryPath = '/edition/{id}/history';
  static readonly checkRangePath = '/edition/checkRange';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запросить список тиражей
   *
   * Запросить список тиражей
   * @param params The `EditionApiService.EditionListParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки (оп умолчанию `asc`)
   *
   * - `sorting.field`: Поле для сортировки (по умолчанию `id`)
   *
   * - `pager.limit`:
   *
   * - `pager.drop`: сколько записей пропустить
   *
   * - `filter.status`:
   *
   * - `filter.name`:
   *
   * @return Список тиражей
   */
  editionListResponse(params: EditionApiService.EditionListParams): __Observable<__StrictHttpResponse<Array<GetEdition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    (params.filterStatus || []).forEach(val => {if (val != null) __params = __params.append('filter.status', val.toString())});
    if (params.filterName != null) __params = __params.set('filter.name', params.filterName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edition/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GetEdition>>;
      })
    );
  }
  /**
   * Запросить список тиражей
   *
   * Запросить список тиражей
   * @param params The `EditionApiService.EditionListParams` containing the following parameters:
   *
   * - `sorting.order`: Порядок сортировки (оп умолчанию `asc`)
   *
   * - `sorting.field`: Поле для сортировки (по умолчанию `id`)
   *
   * - `pager.limit`:
   *
   * - `pager.drop`: сколько записей пропустить
   *
   * - `filter.status`:
   *
   * - `filter.name`:
   *
   * @return Список тиражей
   */
  editionList(params: EditionApiService.EditionListParams): __Observable<Array<GetEdition>> {
    return this.editionListResponse(params).pipe(
      __map(_r => _r.body as Array<GetEdition>)
    );
  }

  /**
   * Создать тираж
   * @param edition undefined
   * @return Данные счёта с историей транзакций
   */
  createEditionResponse(edition: Edition): __Observable<__StrictHttpResponse<Edition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = edition;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/edition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edition>;
      })
    );
  }
  /**
   * Создать тираж
   * @param edition undefined
   * @return Данные счёта с историей транзакций
   */
  createEdition(edition: Edition): __Observable<Edition> {
    return this.createEditionResponse(edition).pipe(
      __map(_r => _r.body as Edition)
    );
  }

  /**
   * Запросить тираж по ID
   * @param id ID Тиража
   * @return Информация о тираже
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<Edition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edition/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edition>;
      })
    );
  }
  /**
   * Запросить тираж по ID
   * @param id ID Тиража
   * @return Информация о тираже
   */
  get(id: number): __Observable<Edition> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as Edition)
    );
  }

  /**
   * Редактировать тираж
   * @param params The `EditionApiService.EditParams` containing the following parameters:
   *
   * - `id`: ID Тиража
   *
   * - `newEdition`:
   *
   * @return Данные счёта с историей транзакций
   */
  editResponse(params: EditionApiService.EditParams): __Observable<__StrictHttpResponse<Edition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.newEdition;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/edition/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edition>;
      })
    );
  }
  /**
   * Редактировать тираж
   * @param params The `EditionApiService.EditParams` containing the following parameters:
   *
   * - `id`: ID Тиража
   *
   * - `newEdition`:
   *
   * @return Данные счёта с историей транзакций
   */
  edit(params: EditionApiService.EditParams): __Observable<Edition> {
    return this.editResponse(params).pipe(
      __map(_r => _r.body as Edition)
    );
  }

  /**
   * Удалить тираж
   * @param id ID Тиража
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/edition/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить тираж
   * @param id ID Тиража
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Сменить статус тиража
   * @param params The `EditionApiService.ChangeStatusParams` containing the following parameters:
   *
   * - `id`: ID Тиража
   *
   * - `newStatus`:
   *
   * @return Данные счёта с историей транзакций
   */
  changeStatusResponse(params: EditionApiService.ChangeStatusParams): __Observable<__StrictHttpResponse<Edition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.newStatus;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/edition/${encodeURIComponent(String(params.id))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Edition>;
      })
    );
  }
  /**
   * Сменить статус тиража
   * @param params The `EditionApiService.ChangeStatusParams` containing the following parameters:
   *
   * - `id`: ID Тиража
   *
   * - `newStatus`:
   *
   * @return Данные счёта с историей транзакций
   */
  changeStatus(params: EditionApiService.ChangeStatusParams): __Observable<Edition> {
    return this.changeStatusResponse(params).pipe(
      __map(_r => _r.body as Edition)
    );
  }

  /**
   * История изменений тиража
   * @param id ID Тиража
   * @return Данные счёта с историей транзакций
   */
  queryHistoryResponse(id: number): __Observable<__StrictHttpResponse<Array<HistoryElement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edition/${encodeURIComponent(String(id))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HistoryElement>>;
      })
    );
  }
  /**
   * История изменений тиража
   * @param id ID Тиража
   * @return Данные счёта с историей транзакций
   */
  queryHistory(id: number): __Observable<Array<HistoryElement>> {
    return this.queryHistoryResponse(id).pipe(
      __map(_r => _r.body as Array<HistoryElement>)
    );
  }

  /**
   * проверка совпадающих диапазонов номеров в тиражах
   * @param params The `EditionApiService.CheckRangeParams` containing the following parameters:
   *
   * - `minRange`:
   *
   * - `maxRange`:
   *
   * - `id`: ID Тиража
   *
   * @return информация о конфликтующем тираже
   */
  checkRangeResponse(params: EditionApiService.CheckRangeParams): __Observable<__StrictHttpResponse<RangeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.minRange != null) __params = __params.set('minRange', params.minRange.toString());
    if (params.maxRange != null) __params = __params.set('maxRange', params.maxRange.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/edition/checkRange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RangeInfo>;
      })
    );
  }
  /**
   * проверка совпадающих диапазонов номеров в тиражах
   * @param params The `EditionApiService.CheckRangeParams` containing the following parameters:
   *
   * - `minRange`:
   *
   * - `maxRange`:
   *
   * - `id`: ID Тиража
   *
   * @return информация о конфликтующем тираже
   */
  checkRange(params: EditionApiService.CheckRangeParams): __Observable<RangeInfo> {
    return this.checkRangeResponse(params).pipe(
      __map(_r => _r.body as RangeInfo)
    );
  }
}

module EditionApiService {

  /**
   * Parameters for editionList
   */
  export interface EditionListParams {

    /**
     * Порядок сортировки (оп умолчанию `asc`)
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле для сортировки (по умолчанию `id`)
     */
    sortingField?: 'id' | 'name' | 'minNumber';
    pagerLimit?: number;

    /**
     * сколько записей пропустить
     */
    pagerDrop?: number;
    filterStatus?: Array<'draft' | 'inProgress' | 'active' | 'archive'>;
    filterName?: string;
  }

  /**
   * Parameters for edit
   */
  export interface EditParams {

    /**
     * ID Тиража
     */
    id: number;
    newEdition?: Edition;
  }

  /**
   * Parameters for changeStatus
   */
  export interface ChangeStatusParams {

    /**
     * ID Тиража
     */
    id: number;
    newStatus?: ChangeStatus;
  }

  /**
   * Parameters for checkRange
   */
  export interface CheckRangeParams {
    minRange: string;
    maxRange: string;

    /**
     * ID Тиража
     */
    id?: number;
  }
}

export { EditionApiService }
