import {PermissionsClass} from "module/permissions/class/PermissionsClass";

export function getPermissionsRequester( path ) {

  if (!path)
    return false;

  let PermissionsClassInstance: any = PermissionsClass.getInstance();
  return PermissionsClassInstance.byPath(path);

};

let checkPermissionsField = function ( attrs , requester ) {

  if ( attrs.permitType ) {
    switch (attrs.permitType.toLowerCase() ) {

      case 'create': return requester.canCreate;
      case 'update': return requester.canUpdate;
      case 'delete': return requester.canDelete;

      default: return false;
    }

  }

  if ( attrs.permitNotType ) {

    switch (attrs.permitNotType.toLowerCase() ) {

      case 'create': return !requester.canCreate;
      case 'update': return !requester.canUpdate;
      case 'delete': return !requester.canDelete;

      default: return true;
    }

  }

  if ( attrs.permitInFilter ) {
    return requester.inFilter( Array.isArray( attrs.permitInFilter ) ? attrs.permitInFilter : [attrs.permitInFilter] );
  }

  if ( typeof attrs.permitEnabled !== 'undefined' ) {
    return requester.enabled;
  }

  if ( typeof attrs.permitDisabled !== 'undefined' ) {
    return requester.disabled;
  }

  if ( attrs.permitNotInFilter ) {
    return requester.notInFilter( Array.isArray( attrs.permitNotInFilter ) ? attrs.permitNotInFilter : [attrs.permitNotInFilter] );
  }

};

export function checkPermissions( attrs , requester ) {

  if ( !requester ) {
    return true;
  }

  let fieldsResult = checkPermissionsField( attrs , requester );


  if ( attrs.permitOrIf ) {
      return attrs.permitOrIf || fieldsResult;
  }

  if ( attrs.permitAndIf ) {
    return attrs.permitAndIf && fieldsResult;
  }

  return fieldsResult;

};


export function createDefaultScope( userScope ) {

  return Object.assign(
    {
      'permitByPath'     : '@?',

      'permitType'       : '@?',

      'permitNotType'    : '@?',

      'permitEnabled'    : '@?',

      'permitDisabled'   : '@?',

      'permitNotInFilter': '=?',

      'permitInFilter'   : '=?',

      'permitAndIf'      : '=?',

      'permitOrIf'       : '=?',


    },
    userScope
  );

};
