'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.campaigncategory.view';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(  '<campaigncategory-view-page [id]="id"></campaigncategory-view-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])
        .breadcrumbs( [ "Справочники", "Группы кампаний", "Группа кампании" ] );

    }]);
