import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'import-goodgroup-excel-ng2'
})
export class ImportGoodgroupExcelDirective extends UpgradeComponent {

  @Output() callback: any;

  @Input() id: any;
  @Input() partnerId: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('importGoodgroupExcel', elementRef, injector);
  }
}

