'use strict';

import module from 'frontendModule';
import 'model/ClientModel/resource';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.segments.clients';

module.requires.push('infinite-scroll');
module.requires.push('ui.footable');

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )
                .url('/:type/:id/:state')

                .template(   () => import( /* webpackChunkName: "assets/pages/segments.clients" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/segments.clients" */ "./controllers"       ))

                .breadcrumbs( [ "Покупатели", "Просмотр" ] )

                .resolve('clientList', [  'ClientModel', '$stateParams', function (ClientModel, $stateParams) {
                    var params : any = {'state': '!archive'};
                    params.segmentState = $stateParams.state;
                    params[$stateParams.type+"Id"]= $stateParams.id;

                    return ClientModel.query(params).$promise;
                } ])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
                    redirectTo: 'frontend.nopermissions'
                })

        }]);

