/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CategoryfullpathApiConfiguration, CategoryfullpathApiConfigurationInterface } from './categoryfullpath-api-configuration';

import { CategoryfullpathApiService } from './services/categoryfullpath-api.service';

/**
 * Provider for all CategoryfullpathApi services, plus CategoryfullpathApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CategoryfullpathApiConfiguration,
    CategoryfullpathApiService
  ],
})
export class CategoryfullpathApiModule {
  static forRoot(customParams: CategoryfullpathApiConfigurationInterface): ModuleWithProviders<CategoryfullpathApiModule> {
    return {
      ngModule: CategoryfullpathApiModule,
      providers: [
        {
          provide: CategoryfullpathApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
