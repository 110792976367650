import {External} from "./IssuingTypes/External";
import {Manual} from "./IssuingTypes/Manual";
import {Pos} from "./IssuingTypes/Pos";
import {Public} from "./IssuingTypes/Public";
import {Trigger} from "./IssuingTypes/Trigger";
import {UniqueCouponGen} from "./IssuingTypes/UniqueCouponGen";

export const ISSUING_TYPES = {
    [External.getId()]        : External,
    [Manual.getId()]          : Manual,
    [Pos.getId()]             : Pos,
    [Public.getId()]          : Public,
    [Trigger.getId()]         : Trigger,
    [UniqueCouponGen.getId()] : UniqueCouponGen
};