
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {GoodsSelectorService, legacyDirectiveParams} from "../../goods-tree/service/goods-selector.service";
import {LocationsDialogService} from "../../locations-dialog/service/locations-dialog.service";

@Component({
  selector: 'filter-locations',
  templateUrl: './filter-locations.component.html',
  providers: [
  ]
})
export class FilterLocationsComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public partnerId;
  @Output() public partnerIdChange  = new EventEmitter();
            private changedPartnerId;

  @Input() public max;

  @Input() public isPending = false;

  public list = [];

  get hasSelectedGoods() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private locationsDialogService: LocationsDialogService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (
      changes['partnerId'] &&
      !!changes['partnerId']?.currentValue &&
      !!this.changedPartnerId &&
      changes['partnerId']?.currentValue !== this.changedPartnerId
    ) {
      this.clear();
    }
  }

  openDialog() {
    this.locationsDialogService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})
  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};

    if (this.partnerId) {
      options.initPartner = this.partnerId;
    }

    if (!!this.max) {
      options.max = this.max;
    }

    return options;
  }

  public changeResult(result) {

    this.partnerId = result.partnerId;
    this.changedPartnerId = result.partnerId;
    this.partnerIdChange.emit( this.partnerId );

    this.list = result.locations;

    this.emitModel();

  }

  private emitModel() {
    this.modelChange.emit(!this.list?.length ? undefined : this.list.map(i => i.id));
  }

  public getName(item) {
    return typeof  item === "object" ? item?.name : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }

}
