import {Component, OnInit, OnDestroy} from "@angular/core";
import {UserService} from "../../shared/service/api/User/User.service";
import {getNg1Service} from "../../shared/class/utils/angularjs.utils";


@Component({
  selector: 'auth-page',
  templateUrl: './auth-page.component.html',
  providers: [
    UserService
  ]
})
export class AuthPageComponent implements OnInit, OnDestroy {

  public version;
  public email;
  public password;
  public error;

  public uiRouterState;
  public $rootScope;

  public isLoading;

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.uiRouterState = getNg1Service('$state');
    this.$rootScope = getNg1Service('$rootScope');

    this.getVersion();
  }

  ngOnDestroy() {
  }

  getVersion() {
    this.userService
        .getVersion()
        .subscribe( result => this.version = result)
  }

  login() {

    this.error = '';
    this.isLoading = true;

    this.userService
      .login(
        this.email,
        this.password
      ).subscribe( {
        next: result => {
          this.$rootScope.$emit('on.auth', result);
          setTimeout(() => {
            this.uiRouterState.go('frontend.dashboard.index');
          }, 100);
        },
        error: error => {
          this.isLoading = false;
          this.error = error?.error || "Данные неверны";
        }
      })
  }

}
