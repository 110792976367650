import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LOYA_CONFIG} from "../../../../shared/variables/loya-config.variable";
import {ngCurrencyL10nService} from "../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {DashboardRequestService} from "../services/dashboard-request.service";
import {BLOCK_CampaingId} from "../block-ids.variables";

const BLOCK_ID = BLOCK_CampaingId;

@Component({
  selector: 'dashboard-campaign-discounts-chart',
  template: `

    <div class="card wrapper">

      <div class="col-sm-12">
        <h4 class="font-thin m-t-none m-b-md text-muted">Скидки/Подарки</h4>
      </div>

      <div class="row  text-center"
           style="height:  400px"
      >


        <div class="col-md-12"
             *ngIf="!isLoading"
        >

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfig"
            [(update)]="updateFlag"
            class="d-block w-100 h-100"
          ></highcharts-chart>

        </div>

        <div class="d-flex justify-content-center align-items-center w-100 h-100"
             *ngIf="isLoading"
        >
          <div class="text-info-dker ">
            <i class="fa fa-3x fa-spin fa-spinner"></i>
          </div>
        </div>

      </div>
    </div>


  `,
  providers: [
    ngCurrencyL10nService
  ]
})
export class DashboardCampaignDiscountsChartComponent implements OnInit, OnDestroy{

  @Input() updateEvent: EventEmitter<string>;
  @Input() params: any;

  private subscribe: Subscription;

  public isLoading = true
  public updateFlag = false;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartConfig = {


      chart: {
        type: 'line',
        animation: {
          duration: 1000
        }
      },
      colors: [
        LOYA_CONFIG.color.primary, LOYA_CONFIG.color.danger, LOYA_CONFIG.color.success, LOYA_CONFIG.color.dark,
        LOYA_CONFIG.color.info, LOYA_CONFIG.color.warning, LOYA_CONFIG.color.orange
      ],

      plotOptions: {
        areaspline: {
          fillOpacity: 0.5
        },

        series: {
          animation: {
            duration: 2000
          }
        }

      },

      title: {
        text: undefined
      },

      xAxis: {
        categories: []
      },
      yAxis: {
        title: {
          text: ''
        },
        plotLines: [{
          value: 0,
          width: 1,
          color: '#808080'
        }]
      },

      tooltip: {
        shared: true,
        valueSuffix: ''
      },
      credits: {
        enabled: false
      },

      series: []

    };

  public categories = [];
  public series =  [];

  constructor(
    private ngCurrencyL10nService : ngCurrencyL10nService,
    private dashboardRequestService:DashboardRequestService
  ) {
  }

  ngOnInit() {
    this.bindUpdateEvent();
    this.initChart();
    this.updateValue(this.params);
  }

  bindUpdateEvent() {
    if (!this.updateEvent)
      return;

    this.subscribe =
      this.updateEvent.subscribe( this.updateValue.bind(this) );

  }

  initChart() {
    this.chartConfig.xAxis.categories = this.categories || [];
    this.chartConfig.series = this.series || [];


    this.ngCurrencyL10nService
      .getFullName()
      .then( ( name: any ) =>  {
        this.chartConfig.yAxis.title.text = name || '';
        this.chartConfig.tooltip.valueSuffix = ' ' + (name || '');
      });

  }

  updateValue(params?) {

    this.series.splice(0, this.series.length);
    this.categories.splice(0, this.categories.length);


    this.isLoading = true
    this.dashboardRequestService
      .get$(BLOCK_ID, params)
      .subscribe({
        next: result => {

          Array.isArray(result) &&
          result.forEach( (item, index )  => {
            let i = {
              name: item?.name,
              data: []
            };
            this.series.push( i );

            Array.isArray(item?.values) &&
            item?.values.forEach( dataItem => {

              if (index === 0)
                this.categories.push( dataItem.name );

              i.data.push( dataItem.value );

            })

          })

          this.isLoading = false;
          this.updateFlag = true;
        },
        error: () => {
          this.isLoading = false;
          this.updateFlag = true;
        }
      })


  }





  ngOnDestroy() {
    if (this.subscribe)
      this.subscribe.unsubscribe();
  }

}
