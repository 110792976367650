import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnChanges
} from "@angular/core";
import {checkPermissions, getPermissionsRequester} from "./directive-params";

@Directive({
  selector: '[loyaPermitIf]'

})
export class LoyaPermitIfDirective<T = unknown> implements OnInit, OnChanges {

  private _thenTemplateRef: TemplateRef<NgIfContext<T>>|null = null;
  private path: any;
  private permissionsRequester: any;

  private lastPermission: boolean;

  constructor(
      private _viewContainer: ViewContainerRef,
      templateRef: TemplateRef<NgIfContext<T>>,
      private el: ElementRef
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input()
  set loyaPermitIf(values: T) {

    Object.assign(this,values);
    this._updateView();
  }

  @Input() permitByPath   :any;
  @Input() permitType     :any;
  @Input() permitNotType  :any;
  @Input() permitEnabled  :any;
  @Input() permitDisabled :any;
  @Input() permitNotInFilter:any;
  @Input() permitInFilter :any;
  @Input() permitAndIf    :any;
  @Input() permitOrIf     :any;


  private _updateView() {

    const currentPermission = checkPermissions(this, this.permissionsRequester);
    if (this.lastPermission === currentPermission ) {
      return;
    }

    this.lastPermission = currentPermission;
    if (currentPermission) {
        this._viewContainer.clear();
        this._viewContainer.createEmbeddedView(this._thenTemplateRef);

    } else {
        this._viewContainer.clear();
    }
  }

  ngOnInit() {
    this.permissionsRequester = getPermissionsRequester( this.path || '' );
  }

  ngOnChanges() {
    setTimeout(() => this._updateView(), 0);
  }

}

export class NgIfContext<T = unknown> {
  public $implicit: T = null!;
  public ngIf: T = null!;
}
