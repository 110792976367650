<form name="accountEditForm" #accountEditForm="ngForm" novalidate autocomplete="off">

  <grid-container>

    <div class="grid-item"
         grid-item-form = "Партнёр"
         grid-item-form-icon = "fa-map-marker"
    >

        <span *ngIf="partnerList?.length === 1"
            [innerText]="partnerList[0]?.name"
        ></span>

        <select class=" form-control  inline v-middle"
                *ngIf="partnerList.length !== 1"
                [(ngModel)]="record.partnerId"
                data-testid="account.select.record.partnerId"
                (ngModelChange)="onPartnerChange()"
                name="partnerId"
        >
          <option *ngFor="let item of partnerList"
                  [ngValue]="item.id"
                  [innerHTML]="item.name"
          ></option>
        </select>

    </div>

    <div class="grid-item"
         grid-item-form = "Дата начала бонусов"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.bonusSettings.startDate"
    >
      <i class="fa fa-spin fa-spinner text-warning"
         *ngIf="loader.isLoading('bonusSettings')"
      ></i>
      <span *ngIf="!!bonusSettings?.startDate && !loader.isLoading('bonusSettings')">
       {{ bonusSettings.startDate | loyaDate }}
      </span>
    </div>

    <div class="grid-item"
         grid-item-form = "Дата сжигания бонусов"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.bonusSettings.endDate"
    >
      <i class="fa fa-spin fa-spinner text-warning"
         *ngIf="loader.isLoading('bonusSettings')"
      ></i>

      <span *ngIf="!!bonusSettings?.endDate && !loader.isLoading('bonusSettings')">
          {{ bonusSettings.endDate | loyaDate }}
      </span>

      <span *ngIf="!bonusSettings?.endDate && !loader.isLoading('bonusSettings')"
            class="text-muted">
          не задана
      </span>
    </div>

    <div class="grid-item grid-item_clean"></div>

    <div class="grid-item"
         grid-item-form = "Тип операции"
         grid-item-form-icon = "fa-map-marker"
    >
      <select class=" form-control  inline v-middle"
              [(ngModel)]="record.operType"
              data-testid="account.select.record.operType"
              name="operType"
              (ngModelChange)="onOperChange()"
      >
        <option *ngFor="let item of ACCOUNT_OPER_TYPE_MANUAL | keyvalue"
                [ngValue]="item.key"
                [innerHTML]="item.value"
        ></option>
      </select>
   </div>

    <div class="grid-item"
         grid-item-form = "Количество бонусов"
         grid-item-form-icon = "fa-map-marker"
    >

      <input
        type="number"
        class="form-control form-control-edittable"
        [loyaInputBonus]="record.partnerId"
        ng-class="{ 'ng-invalid ': maximumAmountError || recordForm.amount.$invalid }"
        [(ngModel)]="record.amount"
        #amountNgModel="ngModel"
        [max]="record.operType === 'debit' ? max : 10000000"
        data-testid="account.input.record.amount"
        name="amount"
      >
      <span class="clearfix font-thin text-danger f-s-9"
            *ngIf="amountNgModel?.errors?.max"
            [innerText]="'Максимальная сумма: ' + amountNgModel?.errors?.max?.max + ' бонусов'"></span>

    </div>

    <div class="grid-item"
         grid-item-form = "Описание"
         grid-item-form-icon = "fa-align-justify"
    >

      <textarea
        class="form-control form-control-edittable"
        [(ngModel)]="record.description"
        data-testid="account.input.record.description"
        name="description"
        placeholder="Введите причину ручного ввода"
        [required]="true"
        [maxlength]="254"
      ></textarea>

    </div>

    <div class="grid-item grid-item_clean"></div>


  </grid-container>

  <div *ngIf="record.operType === 'credit' ">

    <grid-container>

      <div class="grid-item"
           grid-item-form = "Настройки срока действия бонусов"
           grid-item-form-icon = "fa-user"
      >
        <label class="i-switch m-b-n-xs m-l-xs">
          <input type="checkbox"
                 [(ngModel)]="showBonusConfig"
                 name="showBonusConfig"
                 data-testid="account.checkbox.showBonusConfig"
          >
          <i></i>
        </label>

      </div>

    </grid-container>

    <div class="clearfix"></div>

    <span *ngIf="showBonusConfig"
          #bonusSettingsContainer
    >

        <bonus-config-ng1
          [model]="record"
          [fieldPath]="'bonusValiditySettings'"
          [readOnly]="false"
          [initPartner]="record.partnerId"
        ></bonus-config-ng1>

    </span>
  </div>

</form>
