'use strict';


import 'model/Settings';

export default ['$scope', '$state', '$uibModal', '$stateParams', 'Settings', 'settingsList', 'toaster',
        function ($scope, $state, $uibModal, $stateParams, Settings, settingsList, toaster) {


            $scope._t = new Settings();

            $scope.settings = settingsList;
            $scope.backupSettings = false;
            $scope.passwordConfirm = [];
            $scope.settingsObj = {};

            $scope.userStatus = 0;
            $scope.userGateStatus = 'Обновление информации';

            var checkSettings = function(respArr) {

                $scope.backupSettings = angular.copy(respArr);
                $scope.settings = respArr;
            };

            checkSettings(settingsList);

            $scope.elocState =  {
                VIEW: 0,
                EDIT: 1,
                PREVIEW: 2
            };

            $scope.currentLocState = $scope.elocState.VIEW;

            $scope.setLocState = function(state) {
                switch (state) {
                    case $scope.elocState.VIEW:
                    case $scope.elocState.EDIT:
                    case $scope.elocState.PREVIEW:
                        $scope.currentLocState = state;
                        break;
                    default :
                        $scope.currentLocState = $scope.elocState.VIEW;
                }
            };

            $scope.checkLocState = function(state)
            {
                return($scope.currentLocState === state);
            };

            $scope.checkLocStateEdit = function()
            {
                return $scope.checkLocState($scope.elocState.EDIT);
            };

            $scope.checkLocStateView = function()
            {
                return $scope.checkLocState($scope.elocState.VIEW);
            };

            $scope.checkLocStatePreview = function()
            {
                return $scope.checkLocState($scope.elocState.PREVIEW);
            };

            $scope.checkLocStateEditPreview = function()
            {
                return ($scope.checkLocState($scope.elocState.EDIT) || $scope.checkLocState($scope.elocState.PREVIEW));
            };

            $scope.checkLocStateViewPreview = function()
            {
                return ($scope.checkLocState($scope.elocState.VIEW) || $scope.checkLocState($scope.elocState.PREVIEW));
            };


            $scope.editSettings = function () {

                $scope.setLocState($scope.elocState.EDIT);
            };

            $scope.saveSettings = function () {
                if($scope.settingsForm.$valid) {

                    Settings.update(null, $scope.settings, function () {

                        var tmpRespSettings = Settings.query(function () {
                            checkSettings(tmpRespSettings);
                            $scope.setLocState($scope.elocState.view);
                        });

                    });
                }
            };

            $scope.rollbackSettings = function () {
                checkSettings($scope.backupSettings);
                $scope.setLocState($scope.elocState.view);
            };

            $scope.preview = function(){
                if($scope.checkLocStatePreview())
                {
                    $scope.setLocState($scope.elocState.EDIT);
                }
                else
                {
                    $scope.setLocState($scope.elocState.PREVIEW);
                }
            };

            $scope.errorInterpreter = function( $error ) {

                if ( $error ) {
                    switch ( true ) {

                        case $error.maxlength:
                            return "Превышен максимальный размер допустимой строки";

                        case $error.required:
                            return "Поле обязательно для заполнения";

                        case $error.pattern:
                            return "Неправильный формат поля";

                        case $error.match:
                            return "Пароль не совпадает";

                    }
                }
            };

    }];
