import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {concat, merge, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PluginInstallService {

  constructor( private httpClient:HttpClient ) {
  }

  public getByType$(packageType): Observable<any>  {
    return this.httpClient.get('/plugininstall', {
      responseType: "json",
      params: {
        packageType
      }
    }).pipe(
      map( i => Array.isArray(i) ? i : [])
    )
  }

  public all$(): Observable<any[]> {

    return this.component$()
      .pipe(
        switchMap( result => this.mechanic$().pipe( map( i => result.concat(i) ) ) ),
        switchMap( result => this.informing$().pipe( map( i => result.concat(i) ) ) ),
      )
  }

  public informing$(): Observable<any[]> {
    return this.httpClient.get('/plugininstall', {
      responseType: "json",
      params: {
        packageType: 'informing'
      }
    }).pipe(
      map( i => Array.isArray(i) ? i : [])
    )
  }

  public mechanic$(): Observable<any[]> {
    return this.httpClient.get('/plugininstall', {
      responseType: "json",
      params: {
        packageType: 'mechanic'
      }
    }).pipe(
      map( i => Array.isArray(i) ? i : [])
    )
  }

  public component$(): Observable<any[]> {
    return this.httpClient.get('/plugininstall', {
      responseType: "json",
      params: {
        packageType: 'component'
      }
    }).pipe(
      map( i => Array.isArray(i) ? i : [])
    )
  }

}
