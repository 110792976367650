'use strict';

import 'model/EditionModel/resource';

export default ['$scope', 'certificateItem', 'EditionModel', '$state', 'toaster', 'partnerList', '$timeout',  'UserModel', 'PartnerModel', 'GoodModel', 'GiftCertificateModel', '$uibModal', 'editionItem', 'historyItem',
      function ($scope, certificateItem, EditionModel, $state, toaster, partnerList, $timeout,   UserModel, PartnerModel, GoodModel, GiftCertificateModel, $uibModal, editionItem, historyItem) {

        $scope._t = new GiftCertificateModel();
        $scope._te = new EditionModel();
        $scope.certificateItem = certificateItem;
        $scope.partnerList = partnerList;
        $scope.editionItem = editionItem;
        $scope.historyItem = historyItem;
        $scope.operTypes = {
          credit :  "Начисление",
          debit  :  "Списание",
          burning : "Сгорание",
        }
        $scope.manualOperTypes = Object.assign({}, $scope.operTypes);
        delete $scope.manualOperTypes.burning;

        $scope.recordEdit = false;

        $scope.maxRangeCalc = function(minRange, copiesQuantity) {
          return parseInt(minRange) +  copiesQuantity - 1;
        }

        $scope.getNumber = function (number, eanFlag = false) {
          return eanFlag ? number.slice(-1) :  number.slice(0,-1);
        }

        $scope.statuses = {
          'draft'      : "Черновик",
          'inProgress' : "В подготовке",
          'active'     : "Активный",
          'archive'    : "Архивный"
        };

        $scope.certificateStatuses = {
          'created'  : "Создан",
          'deployed' : "Выпущен",
          'used'     : "Полное использование",
          'blocked'  : "Заблокирован"
        }

        $scope.fixedOptions = [{
          name: 'Фиксированный номинал',
          id: true
        },{
          name : 'Произвольный номинал',
          id: false
        }
        ];

        $scope.fixedName = function(value) {
          value = !!value;
          return $scope.fixedOptions.filter( i=> i.id === value )[0].name;
        }


        $scope.runRecordEdit = function() {
          $scope.recordEdit = true;
          $scope.record = {
            operType: Object.keys($scope.operTypes)[0]
          };
        }

        $scope.cancelRecord = function() {
          $scope.recordEdit = false;
        }

        $scope.addRecord = function () {

          return GiftCertificateModel
            .addRecord({ id: certificateItem.id }, $scope.record)
            .$promise
              .then( () => GiftCertificateModel.getCertificate({ id: $scope.certificateItem.id }).$promise )
              .then( result => {
                $scope.certificateItem = result.certificate;
                $scope.historyItem = result.history;
                $scope.recordEdit = false;
              });



        }

      }] ;


