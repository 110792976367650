import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {UserService} from "../../../../../shared/service/api/User/User.service"
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service"
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {NgForm} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {rolesList as systemRolesList, RoleType} from "module/permissions/conf/roles";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {CurrentUserService} from "../../../../../shared/service/currentUser/currentUser.service";
import {UserProtocol} from "../../../../../api/UserApi/models/user-protocol";
import {UserState} from "../../../../../api/UserApi/models/user-state";


@Component({
  selector: 'users-view-page',
  templateUrl: './users-view-page.component.html',
  providers: [
    StatesChangerService
  ]
})
export class UsersViewPageComponent implements OnInit, OnChanges{

  @Input() id: number;

  @ViewChild('mcategoryForm') mcategoryForm;
  public currentUser;
  public currentLoginnedUser;

  private uiRouterState;
  public rolesList= {};
  public tmpRulesList;


  public password;
  public confirmPassword;
  public changePassword = false;

  constructor(
    private userService:UserService,
    public statesChangerService:StatesChangerService,
    private rolesModelService: RolesModelService,
    public confirmActionService: ConfirmActionService,
    public currentUserService: CurrentUserService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
    this.getRoles();

    this.currentUserService.getCurrentUser()
      .subscribe( result => this.currentLoginnedUser = result );
  }


  get isCreate() {
    return typeof this.currentUser?.id === "undefined";
  }

  getRoles() {
    this.rolesModelService.getUserRoles()
      .subscribe(result => {
        this.rolesList = <any>result;
      });
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getUserId(changes.id.currentValue)
        .then( (result:any) => {

          if (changes.id.firstChange) {

            this.statesChangerService.state = result && typeof result.id === "undefined" ?
                                              'edit' :
                                              'view';

            this.changePassword = false;
          }
        })
      ;
    }
  }

  getUserId( id?:number  ) {

    return this.userService
        .getUser(id)
        .toPromise()
        .then( result => this.currentUser = result );
  }

  onSave() {

    this.userService
      .saveUser(this.currentUser)
      .subscribe(result=> {
        this.currentUser = result;
        this.statesChangerService.state = 'view';
      });

  }

  onCancel() {
    if (typeof this.currentUser.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getUserId(this.id)
        .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentUser.id === "undefined")
        return;

    this.userService
        .deleteUser(this.currentUser.id)
        .subscribe(() => {
          this.uiRouterState.go('^.index');
        })
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
    this.password = '';
    this.confirmPassword  = '';
    this.changePassword = false;
  }


  getRolesList() {

    if (!this.rolesList || !Object.keys(this.rolesList).length || !this.currentLoginnedUser)
      return ;

    if (!this.tmpRulesList) {
      this.tmpRulesList = Object.assign({}, this.rolesList );
      if ( this.currentLoginnedUser.role !== RoleType.Administrator) {
        delete this.tmpRulesList[RoleType.Administrator];
      }
    }

    return this.tmpRulesList;
  }

  getRoleName(id) {
    return this.rolesList[id] || systemRolesList[id] || id;
  }

  isArchive( item?: UserProtocol) {
    return item?.state === UserState.ARCHIVE;
  }

  onChangePassword() {

    if ( !this.password || this.password !== this.confirmPassword) {
      this.currentUser.password = '';
      return;
    }

    this.currentUser.password = SHA1(this.password).toString();

  }


}
