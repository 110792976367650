'use strict';

import module from 'frontendModule';

import { PosPrinterCtrl } from './controller';
import * as  templateView    from  './index.html';
import './styles.scss';

export default module.component('posPrinterTemplate', {
    templateUrl : templateView,
    controller  : PosPrinterCtrl,

    bindings: {
        message    : '<'
    }

} );

