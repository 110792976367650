import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";


import "./receipt-index-page.legacy";
import {SearchCheckService} from "../../../shared/service/api/SearchCheck/SearchCheck.service";
import {LoaderHandlerClass} from "../../../shared/class/loader-handler.class";
import {Subscription} from "rxjs";
import {CheckFilterListType} from "../../../api/SearchCheckApi/models/check-filter-list-type";

@Component({
  selector: 'receipt-index-page',
  templateUrl: './receipt-index-page.component.html',
  providers:[
    SearchCheckService
  ]
})
export class ReceiptIndexPageComponent implements OnInit{

  @Input() public id: any;
  @Input() public number: any;

  public loader = new LoaderHandlerClass();

  public searchString: string;
  public listReceipts: Array<any> =[]

  private lastRequest: Subscription;

  constructor(
    public searchCheckService:SearchCheckService
  ) {
  }

  get goodsList()  {

    let type = this.searchCheckService?.
                    queryParams?.
                    getFilterValue('listType');

    if ( !type )
      return undefined;

    switch (type)  {
      case CheckFilterListType.SKU:
        return {
          type,
          list: this.searchCheckService?.
                      queryParams?.
                      getFilterValue('skuList') || []
        };
      case CheckFilterListType.CATEGORY:
        return {
          type,
          list: this.searchCheckService?.
                    queryParams?.
                    getFilterValue('categoryList') || []
        };
      case CheckFilterListType.CUSTOM:
        return {
          type,
          list: this.searchCheckService?.
                    queryParams?.
                    getFilterValue('groupsList') || []
        };
    }

    return undefined;
  }

  set goodsList(data: any)  {

    this.searchCheckService?.queryParams?.setFilterValue('skuList', undefined);
    this.searchCheckService?.queryParams?.setFilterValue('categoryList', undefined);
    this.searchCheckService?.queryParams?.setFilterValue('groupsList', undefined);

    if (!data || !data.type) {
      this.searchCheckService?.queryParams?.setFilterValue('listType', undefined);
      return;
    }

    this.searchCheckService?.queryParams?.setFilterValue('listType', data.type);

    switch (data?.type)  {
      case CheckFilterListType.SKU:
        this.searchCheckService?.queryParams?.setFilterValue('skuList', data?.list?.map(i => i.id) || []);
        this.reset();
        return;

      case CheckFilterListType.CATEGORY:
        this.searchCheckService?.queryParams?.setFilterValue('categoryList', data?.list?.map(i => i.id) || []);
        this.reset();
        return;

      case CheckFilterListType.CUSTOM:
        this.searchCheckService?.queryParams?.setFilterValue('groupsList', data?.list?.map(i => i.id) || []);
        this.reset();
        return;

    }

  }

  ngOnInit() {
    this.searchCheckService.queryParams.reset();
    this.getList(this.searchCheckService.queryParams.params);
  }

  getList(params) {

    this.lastRequest = this.searchCheckService.query$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.listReceipts = this.listReceipts.concat(result);
      });
  }

  onScroll() {
    if ( this.loader.isLoading('list') ) {
      return;
    }

    this.searchCheckService.queryParams.next();
    this.getList(this.searchCheckService.queryParams.params)
  }

  reset() {
    this.lastRequest?.unsubscribe();
    this.searchCheckService.queryParams.reset();
    this.listReceipts=[];
    this.getList(this.searchCheckService.queryParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.searchCheckService.queryParams.search(this.searchString);
    this.reset();
  }


  isFilterEnabled() {
    return true; // Перенесено из Legacy
  }

  hasReceipts()  {
    return !!this.listReceipts?.length ;
  }

  changeTime(value, field) {

    this.searchCheckService.queryParams.setFilterValue(field, value );

  }

}
