/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ClientApiConfiguration, ClientApiConfigurationInterface } from './client-api-configuration';

import { ClientApiService } from './services/client-api.service';

/**
 * Provider for all ClientApi services, plus ClientApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ClientApiConfiguration,
    ClientApiService
  ],
})
export class ClientApiModule {
  static forRoot(customParams: ClientApiConfigurationInterface): ModuleWithProviders<ClientApiModule> {
    return {
      ngModule: ClientApiModule,
      providers: [
        {
          provide: ClientApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
