/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MechanicPriceListApiConfiguration, MechanicPriceListApiConfigurationInterface } from './mechanic-price-list-api-configuration';

import { MechanicPriceListApiService } from './services/mechanic-price-list-api.service';

/**
 * Provider for all MechanicPriceListApi services, plus MechanicPriceListApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    MechanicPriceListApiConfiguration,
    MechanicPriceListApiService
  ],
})
export class MechanicPriceListApiModule {
  static forRoot(customParams: MechanicPriceListApiConfigurationInterface): ModuleWithProviders<MechanicPriceListApiModule> {
    return {
      ngModule: MechanicPriceListApiModule,
      providers: [
        {
          provide: MechanicPriceListApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
