import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy, NgZone
} from "@angular/core";
import { getNg1Service } from '../../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../shared/service/confirmAction/confirmAction.service";

import "./campaigns-view-page.legacy";
import {CampaignService} from "../../../../shared/service/api/Campaign/Campaign.service";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {
  CAMPAIGN_ACTIONS, CAMPAIGN_COMPLETENESS, CAMPAIGN_COMPLETENESS_VALUES_STATES,
  CAMPAIGN_STATES,
  CAMPAIGN_STATES_TREE
} from "../../../../shared/service/api/Campaign/Campaign.values";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {CampaignProtocol} from "../../../../api/CampaignApi/models/campaign-protocol";
import {CampaignState} from "../../../../api/CampaignApi/models/campaign-state";

@Component({
  selector: 'campaigns-view-page',
  templateUrl: './campaigns-view-page.component.html',
  styleUrls:[`./campaigns-view-page.component.scss`],
  providers: [
    StatesChangerService,
    CampaignService,
  ]
})
export class CampaignsViewPageComponent implements OnInit, OnChanges, OnDestroy{

  @Input() id: string;
  private uiRouterState;

  public campaignItem:CampaignProtocol;
  public campaignResources;

  public anchorList;

  public CAMPAIGN_ACTIONS = CAMPAIGN_ACTIONS;
  public CAMPAIGN_COMPLETENESS_VALUES_STATES = CAMPAIGN_COMPLETENESS_VALUES_STATES;

  public loader = new LoaderHandlerClass();
  public unsubscribers: any = {}

  constructor(
    public statesChangerService: StatesChangerService,
    public confirmActionService: ConfirmActionService,
    public campaignService: CampaignService,
    private ngZone: NgZone,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }


  get isCompletenessEnded() {
    return !this?.campaignItem?.campaign?.completeness ||
      this?.campaignItem?.campaign?.completeness === CAMPAIGN_COMPLETENESS.finished
  }

  get isCompletenessInProgress() {
    return !!this?.campaignItem?.campaign?.completeness && this?.campaignItem?.campaign?.completeness !== CAMPAIGN_COMPLETENESS.finished
  }

  get completenessMessage() {
    return CAMPAIGN_COMPLETENESS_VALUES_STATES?.[this?.campaignItem?.campaign?.state]?.[this?.campaignItem?.campaign?.completeness]
  }

  ngOnInit() {
    this.bindButtons();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  isEnabledButton(id: CAMPAIGN_ACTIONS) {
    if (!this.campaignItem?.campaign || this.statesChangerService?.isActive)
      return false;

    const value =  CAMPAIGN_STATES_TREE?.[this.campaignItem?.campaign?.state]?.[id];
    return  !!value || value === false;
  }

  isDisabledButton(id: CAMPAIGN_ACTIONS) {
    if (!this.isEnabledButton(id))
      return false;

    if(this.loader.isLoading('loading')) {
      return true;
    }

    if (id === CAMPAIGN_ACTIONS.run && this.checkRunButtonDisabled()) {
      return true;
    }

    return CAMPAIGN_STATES_TREE?.[this.campaignItem?.campaign?.state]?.[id] === false;
  }

  checkRunButtonDisabled() {
    return this.campaignItem?.mechanic?.status=='template' || this.campaignItem?.informing?.status=='template'
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getOrCreateCampaign(changes.id.currentValue)
        .then( result => {

            this.statesChangerService.changeState(this?.campaignItem?.campaign?.id >=0 ?
              'view' :
              'edit');

        })
      ;
    }
  }

  getOrCreateCampaign( id:any ) {

    return this.campaignService
          .getOrCreate$(parseInt(id))
          .pipe(
            switchMap(result => {
              if (!result?.campaign?.id) {
                this.campaignResources = [];
                return of(result);
              }

              return this.campaignService
                    .campaignResourcesInfo$(result?.campaign?.id)
                    .pipe(
                      map(res => {
                        this.campaignResources = res;
                        return result;
                      })
                    )
            }),
            this.loader.waitLoading('loading')
          )
          .toPromise()
          .then( result => {
              this.campaignItem = result

            if ( this.campaignItem?.campaign?.id >=0 ) {

              if ( typeof this.unsubscribers.stateUpdater?.unsubscribe === "function")
                this.unsubscribers.stateUpdater?.unsubscribe();
              let updateStatus = (value) => {
                if (this.statesChangerService?.isActive)
                  return;

                this.campaignItem.campaign.state = value.state || this.campaignItem.campaign.state;
                this.campaignItem.campaign.completeness = value.completeness || this.campaignItem.campaign.completeness;
              }

              this.ngZone.runOutsideAngular(() => {

                this.unsubscribers.stateUpdater =
                  this.campaignService?.stateUpdater$(this.campaignItem?.campaign?.id).subscribe({
                    next: value => {
                      this.ngZone.run(() => { updateStatus(value);} );
                    }
                  })

              });
            }

          })
  }

  clickChangeState(inState) {

    let state = CAMPAIGN_STATES_TREE?.[this.campaignItem?.campaign?.state]?.[inState];
    if ( typeof state !== "string" )
      return;

    if ( inState === CAMPAIGN_ACTIONS.completed ) {
      this.confirmActionService.confirm('Вы действительно хотите завершить кампанию ?', () => {
        this.changeCampaignState(state);
      })
    } else
      this.changeCampaignState(state);
  }

  changeCampaignState(state) {

    if ( !(this.campaignItem?.campaign?.id >=0 ) || !state )
      return;

    this.campaignService.setState$(
      this?.campaignItem?.campaign?.id,
      state
    ).subscribe( result => {
      this.campaignItem = result;
    })

  }

  cloneCampaign() {
    this.campaignItem = Object.assign({}, this.campaignItem);
    delete this.campaignItem.campaign.id;
    this.campaignItem.campaign.state = CampaignState.DRAFT;
    this.campaignResources = [];

    this.switchToEdit();
  }

  switchToEdit() {
    this.statesChangerService?.changeState('edit');
  }

  getClientCategoryById( id:string = '' ) {
/*
    if (id === '') {
      this.currentClientCategory = {};
      return <any>Promise.resolve(this.currentClientCategory);
    }

    return this.clientCategoryService
      .get(parseInt(id))
      .toPromise()
      .then( result => this.currentClientCategory = result );*/
  }

  onSave() {
/*

    this.campaignItem?.campaign?.campaignTime?.periods?.forEach( i => {
        i.startTime = /\d{2}:\d{2}/.test(i.startTime) ? i.startTime + ":00" : i.startTime;
        i.stopTime = /\d{2}:\d{2}/.test(i.stopTime) ? i.stopTime + ":59" : i.stopTime;
    })
*/

    this.loader.startLoading('loading')

    this.campaignService
      .createOrUpdate$(this.campaignItem)
      .pipe(
        switchMap(result =>
          this.campaignService.updateResources$(result?.campaign?.id, this.campaignResources || [])
            .pipe(
              map( i => {
                this.campaignResources = i;
                return result;
              })
            )
        )
      )
      .subscribe({
        next: result => {

          this.campaignItem = result;
          this.uiRouterState.go('^.view', {id: result?.campaign?.id}, {reload: true});
        },
        error: er => {
          this.loader.stopLoading('loading')
        }
      })
  }

  onCancel() {
    if (! (this.campaignItem?.campaign?.id >=0 )) {
      this.uiRouterState.go('^.index');
      return
    }

    this.getOrCreateCampaign(this.campaignItem?.campaign?.id)
      .then( () =>  this.statesChangerService?.changeState('view') )
  }

  onDelete() {
   /* if (typeof this.currentClientCategory.id === "undefined")
      return;

    this.clientCategoryService
      .deleteCategory(this.currentClientCategory.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })*/
  }

  onPreview() {
  /*  this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;*/
  }

  setAnchorList(value) {
    this.anchorList = typeof value === "object" ? Object.assign({}, value) : value;
    this.anchorList.anchorImagesI = 'Прикреплённые изображения';
  }

  ngOnDestroy(): void {
    Object.values( this.unsubscribers )
      .forEach(
        (i: any) => typeof i?.unsubscribe === "function" && i.unsubscribe()
      )
  }

}
