export class ClientAccount {

    // Передаваемые параметры в компонент
    public client: any;

    public _ta: any;
    public searchAccount: string;

    private accountForCreate: boolean = false;
    private accountForAttach: number;
    private accountForDetach: number;

    static $inject = ['ClientModel', 'Account', '$scope' ];

    constructor(
        private ClientModel: any,
        private Account: any,
        private $scope: any
    ) {

    }

    public $onInit() {
        this._ta = new this.Account();
        this.searchAccount = '';
        this.$scope.$on('client.beforeSave', this.onBeforeSave.bind(this));
        this.$scope.$on('client.afterSave', this.onAfterSave.bind(this));
    }

    public $onChanges(changesObj: any) {

    }

    get hasAccount() {
        return this.client &&
            this.client.client &&
            !!this.client.client.account;
    }

    public clientAccountRemove = function () {

        if (!this.client.client.account) {
            return;
        }

        if (
            typeof this.client.client.account.account.id !== "undefined" &&
            !this.accountForCreate &&
            this.client.client.account.account.id !== this.accountForAttach
        ) {
            this.accountForDetach = this.client.client.account.account.id;
        }

        delete this.accountForAttach;
        this.accountForCreate = false;
        delete this.client.client.accountId;
        this.client.client.account = false;

    }

    public clientAccountCreate = function () {

        this.clientAccountRemove();

        this.accountForCreate = true;
        this.client.client.account = {
            account: {balance: 0},
            clients: []
        };

    };

    public clientAccountAttach = (account: any) => {
        this.searchAccount = '';
        this.clientAccountRemove();

        this.accountForAttach = account.account.id;
        this.client.client.accountId = this.accountForAttach;
        this.client.client.account   = account;
    };

    public onBeforeSave = function ($event: any, client: any) {

        /*    $event.$promise = $event.$promise || Promise.resolve();
            $event.$promise.then(() => {

            });
    */
    }

    public onAfterSave = function ($event: any, client: any) {

        $event.$promise = ($event.$promise || Promise.resolve())
            .then(() =>
                (typeof this.accountForDetach === "undefined")
                    ? Promise.resolve()
                    : this.detachFromClient(client.client.id)
                        .then(() => {
                            delete client.client.accountId
                        })
            )
            .then(() => {

                if (this.accountForCreate) { // если создать новый счёт
                    return this.attachToClient(client.client.id, true);
                }

                if (typeof this.accountForAttach !== "undefined") { // привязать существующий счёт
                    return this.attachToClient(client.client.id, this.accountForAttach);
                }

                return Promise.resolve();

            })
            .then((account: any) => {
                if (account) {
                    client.client.accountId = account.account.id;
                    client.client.account = account;
                }
            });

    }

    private detachFromClient = (clientId: number) => {
        return this.ClientModel.detachAccount({
            id: clientId
        }).$promise;
    }

    private attachToClient = (clientId: number, accountId: number | boolean) => {

        let result = Promise.resolve({clientId: clientId, accountId: accountId});

        if (accountId === true) { //  Создать новый счёт
            result = result.then(result =>
                this.Account
                    .create({})
                    .$promise
                    .then((acc: any) => ({clientId: result.clientId, accountId: acc.id, account: {account: acc} }))
            );
        } else {
            result = result.then(result =>
                this.Account
                    .get({id: result.accountId})
                    .$promise
                    .then((acc: any) => ({clientId: result.clientId, accountId: acc.account.id, account: acc}))
            );
        }

        return result.then((result: any) =>
            this.ClientModel
                .attachAccount({id: result.clientId, accountId: result.accountId})
                .$promise
                .then(() => result.account)
        );

    }

    public getDescription(description: string, record?: any) {

      if (
        !description ||
        typeof description === "string" && !description.toLowerCase().startsWith('начислено цепочкой')
      ) return  description;

      const result = /(начислено цепочкой\s+)\[(\d+)\](.*)/gmi.exec(description);

      if (!result) return description;

      result[3] = !!result[3] ? result[3].trim() : undefined;

      const linkName = !!result[3] ? result[3] : result[1] + `(ID: ${result[2]})`;

      return ( !!result[3] ? "Начислено цепочкой " : "" ) +
        `<a href="#!/triggers/trigger/view/${result[2]}" >${linkName}</a>`;
    }


}

