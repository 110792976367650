<div class="fixed-tool-panel">

  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view'"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

  <div class="wrapper">
    <search-input
      [(model)]="searchString"
      placeholder="Введите имя или E-mail"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
    >
     <thead>
       <tr>
         <th>{{ 'models.UserApi.id.name' | transloco }}</th>
         <th>{{ 'models.UserApi.name.name' | transloco }}</th>
         <th class="table-row-sm table-row-xs ">{{ 'models.UserApi.role.name' | transloco }}</th>
         <th class="table-row-sm table-row-xs ">{{ 'models.UserApi.email.name' | transloco }}</th>
         <th class="table-row-sm table-row-xs ">Статус</th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listUsers | callback:filterBySearch">
          <tr
              [uiSref]="'^.view({id:'+item.id+'})'"
              class="pointer"
              [class.text-muted]="isArchive(item)"
          >
            <td>
              <span [innerText]="item.id"></span>
            </td>
            <td class="table-big-link">
              <a [innerText]="item.name" href=""
                 [uiSref]="'^.view({id:'+item.id+'})'"></a>
            </td>
            <td>
              <span [innerText]="getRoleName(item.role)"></span>
            </td>
            <td>
              <span [innerText]="item.email"></span>
            </td>

            <td>
              <span *ngIf="isActive(item)">
                <i class="fa fa-circle text-success"></i> <span class=" ml-2">Активный</span>
              </span>
              <span *ngIf="isArchive(item)">
                <i class="fa fa-circle text-danger"></i> <span class=" ml-2">Архивный</span>
              </span>
            </td>

          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

<div class="text-center text-warning m-t-lg"
     *ngIf="loader.isLoading('userList')"
>
  <div class="">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
