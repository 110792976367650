import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CampaignCategoryMatrixApiService} from "../../../../api/CampaignCategoryMatrixApi/services";

@Injectable()
export class CampaignCategoryMatrixService {

  constructor( private campaignCategoryMatrixApiService: CampaignCategoryMatrixApiService ) {
  }

  public getMatrix$(partnerId: number){
    return this.campaignCategoryMatrixApiService.getMatrix( partnerId );
  }

  public save$( params: CampaignCategoryMatrixApiService.CreateMatrixParams ){
    return this.campaignCategoryMatrixApiService.createMatrix(params);
  }

  public delete$( partnerId: number ){
    return this.campaignCategoryMatrixApiService.deleteMatrix(partnerId);
  }

}
