import {
  Directive,
  ElementRef,
  Host,
  Inject,
  OnInit,
  Optional,
  Renderer2,
  Self,
  Input,
  TemplateRef, ViewContainerRef, EmbeddedViewRef, ChangeDetectorRef, Injector, ApplicationRef, OnChanges,
} from "@angular/core";

import {
  AsyncValidator,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  NgModel,
  Validator,
  ValidatorFn
} from "@angular/forms";



@Directive({
  selector: '[innerHTML], [innerText]',
  providers: [
  ]
})
export class InnerTextDirectiveDebugger {

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private vc: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private ar: ApplicationRef,
  )
 {
   this.renderer.setAttribute(el.nativeElement, "binding-id", 'id_' + (<any>this.injector)._tNode.providerIndexes);
  }

}



