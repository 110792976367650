/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GroupApiConfiguration as __Configuration } from '../group-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RawGroup } from '../models/raw-group';
import { GroupQueryParam } from '../models/group-query-param';
import { RawSubgroup } from '../models/raw-subgroup';
import { Group } from '../models/group';
import { GroupCampaignInfo } from '../models/group-campaign-info';
import { PaymentLimitInfo } from '../models/payment-limit-info';
import { StatesInfoWithId } from '../models/states-info-with-id';
import { StatesInfo } from '../models/states-info';
import { GroupShortInfo } from '../models/group-short-info';
@Injectable({
  providedIn: 'root',
})
class GroupApiService extends __BaseService {
  static readonly createPath = '/group';
  static readonly queryPath = '/group';
  static readonly createABGroupPath = '/group/{id}/subgroup';
  static readonly deleteSubgroupPath = '/group/{id}/subgroup';
  static readonly getSubgroupPath = '/group/{id}/subgroup';
  static readonly updatePath = '/group/{id}';
  static readonly getPath = '/group/{id}';
  static readonly deletePath = '/group/{id}';
  static readonly getActiveCampaignsPath = '/group/{id}/activeCampaigns';
  static readonly getPaymentLimitPath = '/group/{id}/paymentLimitation';
  static readonly updatePaymentLimitPath = '/group/{id}/paymentLimitation';
  static readonly getStatesPath = '/group/states';
  static readonly getStatesGroupByIdPath = '/group/{id}/states';
  static readonly previewFilePath = '/group/preview/{filename}';
  static readonly uploadFilePath = '/group/{id}/upload/{filename}';
  static readonly getForTriggerDateReachedPath = '/group/triggerDateReached';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создание группы
   * @param group Параметры создаваемой группы
   * @return Данные созданной группы
   */
  createResponse(group: RawGroup): __Observable<__StrictHttpResponse<RawGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = group;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/group`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawGroup>;
      })
    );
  }
  /**
   * Создание группы
   * @param group Параметры создаваемой группы
   * @return Данные созданной группы
   */
  create(group: RawGroup): __Observable<RawGroup> {
    return this.createResponse(group).pipe(
      __map(_r => _r.body as RawGroup)
    );
  }

  /**
   * Запрос групп
   * @param params Фильтры запроса
   * @return Данные групп
   */
  queryResponse(params?: GroupQueryParam): __Observable<__StrictHttpResponse<Array<RawGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RawGroup>>;
      })
    );
  }
  /**
   * Запрос групп
   * @param params Фильтры запроса
   * @return Данные групп
   */
  query(params?: GroupQueryParam): __Observable<Array<RawGroup>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<RawGroup>)
    );
  }

  /**
   * Создание подгрупп для АБ анализа
   *
   * Создание подгрупп для АБ анализа
   * @param params The `GroupApiService.CreateABGroupParams` containing the following parameters:
   *
   * - `id`: ID  group
   *
   * - `groupSeq`: Параметры создаваемых групп
   *
   * @return Подгруппа успешно создана
   */
  createABGroupResponse(params: GroupApiService.CreateABGroupParams): __Observable<__StrictHttpResponse<RawSubgroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.groupSeq;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}/subgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawSubgroup>;
      })
    );
  }
  /**
   * Создание подгрупп для АБ анализа
   *
   * Создание подгрупп для АБ анализа
   * @param params The `GroupApiService.CreateABGroupParams` containing the following parameters:
   *
   * - `id`: ID  group
   *
   * - `groupSeq`: Параметры создаваемых групп
   *
   * @return Подгруппа успешно создана
   */
  createABGroup(params: GroupApiService.CreateABGroupParams): __Observable<RawSubgroup> {
    return this.createABGroupResponse(params).pipe(
      __map(_r => _r.body as RawSubgroup)
    );
  }

  /**
   * Удаление подгрупп
   *
   * Удаление подгрупп
   * @param id ID  group
   */
  deleteSubgroupResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/group/${encodeURIComponent(String(id))}/subgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление подгрупп
   *
   * Удаление подгрупп
   * @param id ID  group
   */
  deleteSubgroup(id: number): __Observable<null> {
    return this.deleteSubgroupResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Просмотр подгрупп
   *
   * Просмотр подгрупп
   * @param id ID  group
   * @return Список подгрупп
   */
  getSubgroupResponse(id: number): __Observable<__StrictHttpResponse<RawSubgroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${encodeURIComponent(String(id))}/subgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawSubgroup>;
      })
    );
  }
  /**
   * Просмотр подгрупп
   *
   * Просмотр подгрупп
   * @param id ID  group
   * @return Список подгрупп
   */
  getSubgroup(id: number): __Observable<RawSubgroup> {
    return this.getSubgroupResponse(id).pipe(
      __map(_r => _r.body as RawSubgroup)
    );
  }

  /**
   * Обновление группы по ID
   *
   * Обновление группы по ID
   * @param params The `GroupApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID  group
   *
   * - `entry`: Описание группы
   *
   * @return Обновленная группа
   */
  updateResponse(params: GroupApiService.UpdateParams): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * Обновление группы по ID
   *
   * Обновление группы по ID
   * @param params The `GroupApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID  group
   *
   * - `entry`: Описание группы
   *
   * @return Обновленная группа
   */
  update(params: GroupApiService.UpdateParams): __Observable<Group> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * Запрос группы по ID и статусу
   *
   * Запрос группы по ID и статусу
   * @param params The `GroupApiService.GetParams` containing the following parameters:
   *
   * - `state`: Статус группы
   *
   * - `id`: ID  group
   *
   * @return Список кампаний
   */
  getResponse(params: GroupApiService.GetParams): __Observable<__StrictHttpResponse<RawGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawGroup>;
      })
    );
  }
  /**
   * Запрос группы по ID и статусу
   *
   * Запрос группы по ID и статусу
   * @param params The `GroupApiService.GetParams` containing the following parameters:
   *
   * - `state`: Статус группы
   *
   * - `id`: ID  group
   *
   * @return Список кампаний
   */
  get(params: GroupApiService.GetParams): __Observable<RawGroup> {
    return this.getResponse(params).pipe(
      __map(_r => _r.body as RawGroup)
    );
  }

  /**
   * Удаление группы по ID и статусу
   *
   * Удаление группы по ID и статусу
   * @param params The `GroupApiService.DeleteParams` containing the following parameters:
   *
   * - `state`: Статус группы
   *
   * - `id`: ID  group
   */
  deleteResponse(params: GroupApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление группы по ID и статусу
   *
   * Удаление группы по ID и статусу
   * @param params The `GroupApiService.DeleteParams` containing the following parameters:
   *
   * - `state`: Статус группы
   *
   * - `id`: ID  group
   */
  delete(params: GroupApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Получение списка активных кампаний, действующих на группу
   * @param id ID  group
   * @return Список кампаний
   */
  getActiveCampaignsResponse(id: number): __Observable<__StrictHttpResponse<Array<GroupCampaignInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${encodeURIComponent(String(id))}/activeCampaigns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GroupCampaignInfo>>;
      })
    );
  }
  /**
   * Получение списка активных кампаний, действующих на группу
   * @param id ID  group
   * @return Список кампаний
   */
  getActiveCampaigns(id: number): __Observable<Array<GroupCampaignInfo>> {
    return this.getActiveCampaignsResponse(id).pipe(
      __map(_r => _r.body as Array<GroupCampaignInfo>)
    );
  }

  /**
   * Получение информации по ограничению оплаты бонусами
   * @param id ID  group
   * @return Описание ограничения
   */
  getPaymentLimitResponse(id: number): __Observable<__StrictHttpResponse<PaymentLimitInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${encodeURIComponent(String(id))}/paymentLimitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentLimitInfo>;
      })
    );
  }
  /**
   * Получение информации по ограничению оплаты бонусами
   * @param id ID  group
   * @return Описание ограничения
   */
  getPaymentLimit(id: number): __Observable<PaymentLimitInfo> {
    return this.getPaymentLimitResponse(id).pipe(
      __map(_r => _r.body as PaymentLimitInfo)
    );
  }

  /**
   * Обновление информации по ограничению оплаты бонусами
   * @param params The `GroupApiService.UpdatePaymentLimitParams` containing the following parameters:
   *
   * - `limitInfo`: Описание ограничений
   *
   * - `id`: ID  group
   *
   * @return Список ID кампаний
   */
  updatePaymentLimitResponse(params: GroupApiService.UpdatePaymentLimitParams): __Observable<__StrictHttpResponse<PaymentLimitInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.limitInfo;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}/paymentLimitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentLimitInfo>;
      })
    );
  }
  /**
   * Обновление информации по ограничению оплаты бонусами
   * @param params The `GroupApiService.UpdatePaymentLimitParams` containing the following parameters:
   *
   * - `limitInfo`: Описание ограничений
   *
   * - `id`: ID  group
   *
   * @return Список ID кампаний
   */
  updatePaymentLimit(params: GroupApiService.UpdatePaymentLimitParams): __Observable<PaymentLimitInfo> {
    return this.updatePaymentLimitResponse(params).pipe(
      __map(_r => _r.body as PaymentLimitInfo)
    );
  }

  /**
   * получить GroupTransitionState и GroupState по Id группы
   * @param ids ID группы клиентов
   * @return id, GroupTransitionState и GroupState
   */
  getStatesResponse(ids: Array<number>): __Observable<__StrictHttpResponse<Array<StatesInfoWithId>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StatesInfoWithId>>;
      })
    );
  }
  /**
   * получить GroupTransitionState и GroupState по Id группы
   * @param ids ID группы клиентов
   * @return id, GroupTransitionState и GroupState
   */
  getStates(ids: Array<number>): __Observable<Array<StatesInfoWithId>> {
    return this.getStatesResponse(ids).pipe(
      __map(_r => _r.body as Array<StatesInfoWithId>)
    );
  }

  /**
   * получить GroupTransitionState и GroupState по Id группы
   * @param id ID группы клиентов
   * @return GroupTransitionState и GroupState
   */
  getStatesGroupByIdResponse(id: number): __Observable<__StrictHttpResponse<StatesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/${encodeURIComponent(String(id))}/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StatesInfo>;
      })
    );
  }
  /**
   * получить GroupTransitionState и GroupState по Id группы
   * @param id ID группы клиентов
   * @return GroupTransitionState и GroupState
   */
  getStatesGroupById(id: number): __Observable<StatesInfo> {
    return this.getStatesGroupByIdResponse(id).pipe(
      __map(_r => _r.body as StatesInfo)
    );
  }

  /**
   * Предпросмотр файла для загрузки списка клиентов
   *
   * Предпросмотр файла для загрузки списка клиентов
   * @param params The `GroupApiService.PreviewFileParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   *
   * @return Результат предпросмотра
   */
  previewFileResponse(params: GroupApiService.PreviewFileParams): __Observable<__StrictHttpResponse<{pager: {limit: number, drop: number}, delimiter: string, encoding: 'cp1251' | 'utf8', cardColumn: number, table?: {table: Array<{i: number, row: Array<{j: number, cell: string}>}>}, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/preview/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{pager: {limit: number, drop: number}, delimiter: string, encoding: 'cp1251' | 'utf8', cardColumn: number, table?: {table: Array<{i: number, row: Array<{j: number, cell: string}>}>}, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>;
      })
    );
  }
  /**
   * Предпросмотр файла для загрузки списка клиентов
   *
   * Предпросмотр файла для загрузки списка клиентов
   * @param params The `GroupApiService.PreviewFileParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   *
   * @return Результат предпросмотра
   */
  previewFile(params: GroupApiService.PreviewFileParams): __Observable<{pager: {limit: number, drop: number}, delimiter: string, encoding: 'cp1251' | 'utf8', cardColumn: number, table?: {table: Array<{i: number, row: Array<{j: number, cell: string}>}>}, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}> {
    return this.previewFileResponse(params).pipe(
      __map(_r => _r.body as {pager: {limit: number, drop: number}, delimiter: string, encoding: 'cp1251' | 'utf8', cardColumn: number, table?: {table: Array<{i: number, row: Array<{j: number, cell: string}>}>}, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string})
    );
  }

  /**
   * Загрузка состава группы клиентов из файла
   *
   * Загрузка состава группы клиентов из файла
   * @param params The `GroupApiService.UploadFileParams` containing the following parameters:
   *
   * - `params`: Параметеры загрузки из файла
   *
   * - `id`: ID группы клиентов
   *
   * - `filename`: Файл для загрузки
   *
   * @return Статистика по загрузке списка клиентов
   */
  uploadFileResponse(params: GroupApiService.UploadFileParams): __Observable<__StrictHttpResponse<{total: number, duplicate: number, error: number, errorsCSV: string, added: number}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/group/${encodeURIComponent(String(params.id))}/upload/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{total: number, duplicate: number, error: number, errorsCSV: string, added: number}>;
      })
    );
  }
  /**
   * Загрузка состава группы клиентов из файла
   *
   * Загрузка состава группы клиентов из файла
   * @param params The `GroupApiService.UploadFileParams` containing the following parameters:
   *
   * - `params`: Параметеры загрузки из файла
   *
   * - `id`: ID группы клиентов
   *
   * - `filename`: Файл для загрузки
   *
   * @return Статистика по загрузке списка клиентов
   */
  uploadFile(params: GroupApiService.UploadFileParams): __Observable<{total: number, duplicate: number, error: number, errorsCSV: string, added: number}> {
    return this.uploadFileResponse(params).pipe(
      __map(_r => _r.body as {total: number, duplicate: number, error: number, errorsCSV: string, added: number})
    );
  }

  /**
   * получить список групп для цепочек
   * @return список групп для события в цепочках Наступление даты
   */
  getForTriggerDateReachedResponse(): __Observable<__StrictHttpResponse<Array<GroupShortInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/group/triggerDateReached`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GroupShortInfo>>;
      })
    );
  }
  /**
   * получить список групп для цепочек
   * @return список групп для события в цепочках Наступление даты
   */
  getForTriggerDateReached(): __Observable<Array<GroupShortInfo>> {
    return this.getForTriggerDateReachedResponse().pipe(
      __map(_r => _r.body as Array<GroupShortInfo>)
    );
  }
}

module GroupApiService {

  /**
   * Parameters for createABGroup
   */
  export interface CreateABGroupParams {

    /**
     * ID  group
     */
    id: number;

    /**
     * Параметры создаваемых групп
     */
    groupSeq: RawSubgroup;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID  group
     */
    id: number;

    /**
     * Описание группы
     */
    entry: RawGroup;
  }

  /**
   * Parameters for get
   */
  export interface GetParams {

    /**
     * Статус группы
     */
    state: string;

    /**
     * ID  group
     */
    id: number;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * Статус группы
     */
    state: string;

    /**
     * ID  group
     */
    id: number;
  }

  /**
   * Parameters for updatePaymentLimit
   */
  export interface UpdatePaymentLimitParams {

    /**
     * Описание ограничений
     */
    limitInfo: PaymentLimitInfo;

    /**
     * ID  group
     */
    id: number;
  }

  /**
   * Parameters for previewFile
   */
  export interface PreviewFileParams {

    /**
     * Параметры предпросмотра
     */
    params: {pager: {limit: number, drop: number}, delimiter?: string, encoding?: 'cp1251' | 'utf8', cardColumn?: number};

    /**
     * Файл для предпросмотра
     */
    filename: string;
  }

  /**
   * Parameters for uploadFile
   */
  export interface UploadFileParams {

    /**
     * Параметеры загрузки из файла
     */
    params: {delimiter: string, encoding: 'cp1251' | 'utf8', pager: {drop: number}, cardColumn: number};

    /**
     * ID группы клиентов
     */
    id: number;

    /**
     * Файл для загрузки
     */
    filename: string;
  }
}

export { GroupApiService }
