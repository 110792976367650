const OPTIONS = {
    url: '/files',
    autoUpload: true
}

const DEFAULT_IMAGE = '/assets/img/user-default.jpg';

export class AvatarLoader {

    // Передаваемые параметры в компонент
    public model      : any;
    public isEnabled  : boolean;
    public isEdit     : boolean;

    public uploader        : any;
    public uploaderOptions = OPTIONS;

    private _viewImage : string;
    get viewImage() { return this._viewImage; };
    set viewImage( value:string ) {

        this.$timeout( () => {
            this._viewImage = value;
        });

    };

    static $inject = ['FileUploader', '$element', '$q','$timeout' ];

    constructor(
        private FileUploader: any,
        private $element    : any,
        private $q          : any,
        private $timeout    : any,
    ) { }

    public $onInit() {

        this.isEnabled = typeof this.isEnabled === 'undefined' ? true  : this.isEnabled;
        this.isEdit  = typeof this.isEdit  === 'undefined' ? false : this.isEdit;

        this.uploader = new this.FileUploader();
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
        this.uploader.onSuccessItem     = this.onSuccessItem.bind(this);

        this.getImage( this.model );
    }

    private onAfterAddingFile = function () {

        this.uploader.uploadAll();
        this.$element.find('input.fileUploaderInp').val('');

    };

    private onSuccessItem = function ( item: any, response: String) {
        this.getImage(response)
            .then( (result: string) => this.model = result );

    };

    public openFileDialog = function () {
        setTimeout( () => {
            this.$element.find('input.fileUploaderInp').trigger('click');
        });
    };

    public removePhoto = function () {
        this.viewImage = DEFAULT_IMAGE;
        this.model = undefined;
    };

    public $onChanges( changes: any ) {

        if ( changes.model ) {
            this.getImage(changes.model.currentValue);
        }

    }

    public getImage = function( src : string ) {

        return this.$q( (resolve : any, reject : any) => {

            this.viewImage = DEFAULT_IMAGE;

            if (!src) {
                return;
            }

            let testImage = new Image();
            testImage.onload = () => {
                this.viewImage = src;
                resolve(src);
            };

            testImage.onerror = reject;
            testImage.onabort = reject;

            testImage.src = src;

        });
    }

};
