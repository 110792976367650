import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ClientTransactionsApiService} from "../../../../api/ClientTransactionsApi/services";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";

@Injectable({
  providedIn: "root"
})
export class ClientTransactionsService {

  constructor( private clientTransactionsApiService:ClientTransactionsApiService ) {
  }

  public findCheckById$(checkId: number){
    return this.clientTransactionsApiService.findCheckById(checkId)
  }

}
