import {ModuleFieldsHandler} from "class/ModuleFieldsHandler";
import {ConfigValueItems, FieldTypes} from "../../../../../../components/configModuleFields/ConfigModuleService";
import 'model/ClientTemplateModel/resource'

import {customTemplate, customTemplateCtrl} from "../../../modules/customField/main";
import {ClientTemplatesProvider} from "../../../../../../classes/ClientTemplates/ClientTemplates";


const ADDITIONAL_TEMPLATES = [
    {
        componentTypeId: "Common",
        date: 1424849289000,
        description: "",
        id: '',
        name: "Коммуникации",
        position: 0
    },
    {
        componentTypeId: "System",
        date: 1424849289001,
        description: "",
        id: '',
        name: "Системные",
        position: 1
    }
];

export class ConditionItems {

    private conditionsList: Object | boolean = false;
    private moduleFieldsService : ModuleFieldsHandler;

    static $inject = ['ClientTemplateModel', 'modulesStorage', 'Settings'];
    constructor(private ClientTemplateModel: any, private modulesStorage: any, private Settings: any) {

    }

    private async queryTemplates() : Promise<Array<any>> {

        return await ClientTemplatesProvider
                        .getInstance()
                        .templateList;
    }

    private async init() {

        let templatesList = await this.queryTemplates();
        templatesList = templatesList.concat(ADDITIONAL_TEMPLATES);

        this.conditionsList = {};

        let rules = {};
        let arrayPromises: any = [];

        // Чёт не ОК это всё

        await ModuleFieldsHandler.loadingPromise();

        templatesList.forEach(   (template) => {

            arrayPromises.push(
                this.getRulesForComponent(template.componentTypeId)
                    .then( res => rules[template.componentTypeId] = res)
            );

        });

        await Promise.all(arrayPromises);

        templatesList.forEach(  (template) => {

            this.conditionsList[template.componentTypeId + template.id] = {
                id: template.id,
                componentTypeId: template.componentTypeId,
                name: template.name,
                position: template.position,
                rules:  rules[template.componentTypeId]
            };

        });

    }

    private async getRulesForComponent( id: string ) {

        this.moduleFieldsService = ModuleFieldsHandler.getInstanceForModule(id);

        let customFields = this.moduleFieldsService ? this.moduleFieldsService.getFieldsFromSettings() : [];
        let moduleFields = this.modulesStorage.get(id, 'segments.groups.view')|| [];
            moduleFields = moduleFields.slice(0);

        if ( Array.isArray(customFields) && customFields.length ) {

            customFields.forEach( customField => {

                if ( !customField.isFixed  ) {

                    moduleFields.push({
                        name: customField.label,
                        ruleType: "customField",
                        templateUrl : customTemplate,
                        controller  : customTemplateCtrl,
                        fieldSettings:  customField,
                        hidden : !customField.visible
                    })
                } else {

                    moduleFields.forEach( (item : any ) => {
                        if (
                            ( item.ruleType === customField.ruleType || ( Array.isArray(customField.ruleType) && customField.ruleType.indexOf(item.ruleType) >= 0 ) )
                            && !customField.visible
                        ) {
                            item.hidden  = !customField.visible;
                        }

                        if (item.ruleType === customField.ruleType) {
                            item.name = customField.label;
                        }

                    } );

                }

            });
        }

        return moduleFields;

    }

    public async getConditions( reset: boolean = false ): Promise<Object> {

        if (reset){
            this.conditionsList = {};
            await this.init();
        }

        if (!this.conditionsList) {
            try {
                await this.init();
            } catch( e ) {
                return {};
            }
        }

        return this.conditionsList;
    }

    public async conditionsByGroup( reset: boolean = false ) {

        const conditions = await this.getConditions(reset);

        return Object.keys(conditions).reduce( (resArray: Array<any>, id:string) => {

            if (!Array.isArray( conditions[id].rules ) ) return resArray;

            return conditions[id].rules.reduce( ( resArray: Array<any>, rule: any ) => {

                resArray.push({
                    id            : id + '.' + ( rule.ruleType === "customField" ? rule.ruleType + '.' + rule.fieldSettings.id : rule.ruleType ) ,
                    componentType : id,
                    group         : conditions[id].name,
                    name          : rule.name,
                    templateUrl   : rule,
                    fieldSettings : rule.fieldSettings
                });

                return resArray;

            }, resArray);

        } , [])

    }

}
