import {Component, Input, OnInit, Output, EventEmitter, OnDestroy, Injectable} from "@angular/core";
import {NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";


const I18N_VALUES = {
  ru: {
    weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekLabel: 'Неделя',
  },
  // other languages you would support
};

@Injectable()
export class I18n {
  language = 'ru';
}


@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
}


@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard-page.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
  styles: [
    `
			.custom-day {
				text-align: center;
				padding: 0.185rem 0.25rem;
				display: inline-block;
				height: 2rem;
				width: 2rem;
			}
			.custom-day.focused {
				background-color: #e6e6e6;
			}
			.custom-day.range,
			.custom-day:hover {
				background-color: rgb(2, 117, 216);
				color: white;
			}
			.custom-day.faded {
				background-color: rgba(2, 117, 216, 0.5);
			}

      .fixed-tool-panel select-locations-ng2 {
        margin-top: 5px;
        margin-bottom: 5px;
      }

		`,
  ],
})
export class DashboardPageComponent implements OnInit, OnDestroy{


  public updateEvent = new EventEmitter()

  public months = {
  }

  public dates;
  public params: any = {};
  public dateName = '';
  public selectedLocations;

  constructor(private calendar: NgbCalendar) {

  }

  ngOnInit() {
    this.fillMonths();
    this.initDate();
  }

  initDate() {
    let today = new Date();

    this.fromDate = NgbDate.from({
      day:1,
      month: today.getMonth()+1,
      year: today.getFullYear()
    });

    today.setMonth(today.getMonth() + 1);
    today.setDate(0);

    this.toDate = NgbDate.from({
      day: today.getDate(),
      month: today.getMonth()+1,
      year: today.getFullYear()
    });

    this.sendToUpdate();
  }

  fillMonths() {

    let objDate = new Date();
    let locale = "RU-ru";
    for (let i=0;i<12;i++) {
      objDate.setDate(1);
      objDate.setMonth(i);

      this.months[i+1] = objDate.toLocaleString(locale, { month: "long" });
      this.months[i+1] = this.months[i+1].charAt(0).toUpperCase() + this.months[i+1].slice(1);
    }

  }


  sendToUpdate() {

    if (!this.fromDate || !this.toDate)
      return

    this.params.from = (this.fromDate.year) +'-'+ ('00' + this.fromDate.month).slice(-2) +'-'+ ('00' + this.fromDate.day).slice(-2);
    this.params.to = (this.toDate.year) +'-'+ ('00' + this.toDate.month).slice(-2) +'-'+ ('00' + this.toDate.day).slice(-2);

    this.setDateName();
    this.updateEvent.emit( this.params);
  }


  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && ( date.after(this.fromDate) || date.equals(this.fromDate) ) ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.sendToUpdate();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }


  updateDate(index = 1) {

    const date_from = new Date( this.fromDate.year, this.fromDate.month-1, this.fromDate.day,0, 0, 0, 0);
    const date_to = new Date( this.toDate.year, this.toDate.month-1, this.toDate.day,0, 0, 0, 0);
    const shiftDays = Math.ceil((date_to.getTime() - date_from.getTime()) / (1000 * 3600 * 24));

    if (index>0) {

      if (this.isMonthStart(this.fromDate) && this.isMonthEnd(this.toDate)) {
        this.fromDate = this.calendar.getNext(this.fromDate,"m", 1 );
        this.toDate.day = 1;
        this.toDate = this.calendar.getNext(this.toDate,"m", 2 );
        this.toDate = this.calendar.getPrev(this.toDate,"d", 1 );

      } else  {
        this.fromDate = this.calendar.getNext(this.fromDate,"d", shiftDays );
        this.toDate = this.calendar.getNext(this.toDate,"d", shiftDays );
      }

    } else  {

      if (this.isMonthStart(this.fromDate) && this.isMonthEnd(this.toDate)) {
        this.fromDate = this.calendar.getPrev(this.fromDate,"m", 1 );
        this.toDate.day = 1;
        this.toDate = this.calendar.getPrev(this.toDate,"d", 1 );
      } else  {
        this.fromDate = this.calendar.getPrev(this.fromDate,"d", shiftDays );
        this.toDate = this.calendar.getPrev(this.toDate,"d", shiftDays );
      }

    }

    this.sendToUpdate();
  }

  isMonthStart(inDate: NgbDate) {

    return inDate.day === 1;

  }

  isMonthEnd(inDate: NgbDate) {

    let testDate =   this.calendar.getNext(inDate,"d", 1 );
    return testDate.month !== inDate.month;

  }

  setDateName() {

    if (!this.fromDate || !this.toDate) {
      this.dateName = 'Не задано';
      return;
    }

    if (this.isMonthStart(this.fromDate) && this.isMonthEnd(this.toDate)) {

      if (this.fromDate.month === this.toDate.month) {
        this.dateName = this.months[this.fromDate.month] + ' ' + this.fromDate.year
      } else {
        this.dateName = this.months[this.fromDate.month] +
          (  this.fromDate.year !== this.toDate.year ? ' ' + this.fromDate.year : '' ) +
          ' - ' +
          this.months[this.toDate.month] + ' ' + this.toDate.year
      }

      return;
    }


    this.dateName =
      ('00' + this.fromDate.day).slice(-2) + '.' +
      ('00' + this.fromDate.month).slice(-2) + '.' +
      ('00' + this.fromDate.year).slice(-2) + ' - ' +
      ('00' + this.toDate.day).slice(-2) + '.' +
      ('00' + this.toDate.month).slice(-2) + '.' +
      ('00' + this.toDate.year).slice(-2);

  }

  selectLocation(result) {

    if (result?.locations?.length) {
      this.selectedLocations = result?.locations;
      this.params.locationIds = result?.ids;
    } else {
      this.selectedLocations = undefined;
      delete this.params.locationIds;
    }

    this.sendToUpdate();
  }

  isLocationSelected() {
    return !!this.params?.locationIds?.length
  }


  removeLocation(id?) {
    if (typeof  id === "undefined") {
      this.selectedLocations = [];
      this.params.locationIds = [];
    } else {
      this.selectedLocations  = this.selectedLocations.filter(i => i.id !== id);
      this.params.locationIds = this.params.locationIds.filter(i => i !== id);
    }

    if ( !this.selectedLocations?.length) {
      this.selectedLocations = undefined;
      delete this.params.locationIds;
    }

    this.sendToUpdate();
  }

  ngOnDestroy() {
    this.updateEvent.complete();
    this.updateEvent.unsubscribe();
    this.updateEvent = null;
  }

}
