<div class="card no-back">

  <div class="card-header">
    Статистика
  </div>

  <div class="card-body long-descriptions" >

    <grid-container
      *ngIf="!loader.isLoading('statistics')"
    >

      <div class="grid-item"
           [grid-item-form] = "'Отправлено SMS'"
           grid-item-form-icon = "fa-mobile"
      >
        <span [innerText]="profile?.smsInfo?.count || 0"></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Потрачено на SMS'"
           grid-item-form-icon = "fa-money"
      >
        <span [innerText]="profile?.smsInfo?.cost || 0 | loyaCurrency"></span>
        <span class="m-l-xs" ngCurrencyNameLoya></span>
      </div>

      <div class="grid-item  grid-item_clean"></div>



      <div class="grid-item"
           [grid-item-form] = "'Отправлено E-MAIL'"
           grid-item-form-icon = "fa-envelope-o"
      >
        <span [innerText]="profile?.emailInfo?.count || 0"></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'Потрачено на E-MAIL'"
           grid-item-form-icon = "fa-money"
      >
        <span [innerText]="profile?.emailInfo?.cost || 0 | loyaCurrency"></span>
        <span class="m-l-xs" ngCurrencyNameLoya></span>
      </div>

      <div class="grid-item  grid-item_clean"></div>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }"
      >

        <div class="grid-item"
             [grid-item-form] = "'Отправлено Viber'"
             grid-item-form-icon = "fa-mobile"
        >
          <span [innerText]="profile?.viberInfo?.count || 0"></span>
        </div>

        <div class="grid-item"
             [grid-item-form] = "'Потрачено на Viber'"
             grid-item-form-icon = "fa-money"
        >
          <span [innerText]="profile?.viberInfo?.cost || 0 | loyaCurrency"></span>
          <span class="m-l-xs" ngCurrencyNameLoya></span>
        </div>

      </ng-container>

    </grid-container>

    <div class="text-center m-t-lg fa-4x"
         *ngIf="loader.isLoading('statistics')"
    >
      <i class="fa fa-spin fa-spinner text-warning"></i>
    </div>
  </div>

</div>

<ul ngbNav #nav="ngbNav"
    class="nav-tabs m-t-sm">

  <li ngbNavItem>

    <a ngbNavLink
       class="m-l-sm"
    > Рассылки
    </a>

    <ng-template ngbNavContent>
      <gate-newsletter-list></gate-newsletter-list>
    </ng-template>

  </li>

  <li ngbNavItem>

    <a ngbNavLink
       class="m-l-sm"
    > Сообщения
    </a>

    <ng-template ngbNavContent>
      <gate-task-list></gate-task-list>
    </ng-template>

  </li>

  <li ngbNavItem>

    <a ngbNavLink
       class="m-l-sm"
    > Верификация
    </a>

    <ng-template ngbNavContent>
      <gate-verefication-list></gate-verefication-list>
    </ng-template>

  </li>

</ul>
<div [ngbNavOutlet]="nav"></div>


