/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for GroupApi services
 */
@Injectable({
  providedIn: 'root',
})
export class GroupApiConfiguration {
  rootUrl: string = location.origin;
}

export interface GroupApiConfigurationInterface {
  rootUrl?: string;
}
