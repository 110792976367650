import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {UserService} from "../../../../../shared/service/api/User/User.service"
import {RolesModelService} from "../../../../../shared/service/api/RolesModel/RolesModel.service"
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {NgForm} from "@angular/forms";
import SHA1 from "crypto-js/sha1";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {CampaignCategory} from "../../../../../api/CampaignCategoryApi/models/campaign-category";
import {COLLISION_VALUES} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.values";
import {CampaignService} from "../../../../../shared/service/api/Campaign/Campaign.service";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {WarningBoxService} from "../../../../../shared/service/warningBox/warningBox.service";

@Component({
  selector: 'campaigncategory-view-page',
  templateUrl: './campaigncategory-view-page.component.html',
  providers: [
    StatesChangerService,
    CampaignCategoryService,
    CampaignService,
    CouponRuleService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.campaigncategory'
    },
    WarningBoxService
  ]
})
export class CampaigncategoryViewPageComponent implements OnInit, OnChanges{

  @Input() id: string;

  @ViewChild('ccategoryForm') ccategoryForm;
  public currentCategory: CampaignCategory;

  private uiRouterState;
  public rolesList= {};

  public listCampaings = [];
  public listIncludedCampaings = [];
  public priceFlag = false;
  public collisionValues = COLLISION_VALUES;
  public lastSwapedCampaign: any;
  public itemForAdd: any;

  constructor(
    private campaignCategoryService:CampaignCategoryService,
    public  statesChangerService:StatesChangerService,
    private campaignService:CampaignService,
    private couponRuleService:CouponRuleService,
    private changeDetection: ChangeDetectorRef,
    public confirmActionService: ConfirmActionService,
    private warningBoxService:WarningBoxService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
    this.getCampaings();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getCategoryById(changes.id.currentValue)
        .then( result => {
          if (changes.id.firstChange) {

            this.statesChangerService.state = !changes.id.currentValue ?
                                              'edit' :
                                              'view';
          }
        })
      ;
    }
  }

  getCategoryById( id?:number  ) {

    return this.campaignCategoryService
        .get(id)
        .toPromise()
        .then( result => this.currentCategory = result );
  }

  onSave() {

    this.campaignCategoryService
      .save(this.currentCategory)
      .subscribe(result=> {
        this.currentCategory = result;

        this.campaignCategoryService
            .postLinks$(this.currentCategory.id, this.listIncludedCampaings)
            .subscribe(() => {
              this.uiRouterState.go('^.view',{id: this.currentCategory.id}, {reload: true});
            });

      });

  }

  onCancel() {
    if (typeof this.currentCategory.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getCampaings();
    this.getCategoryById(parseInt(this.id))
        .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentCategory.id === "undefined")
        return;

    this.campaignCategoryService
        .delete$(this.currentCategory.id)
        .subscribe(() => {
          this.uiRouterState.go('^.index');
        }, error=> {
          error?.stopPopupError();
          if ( error.status === 409 )
            this.warningBoxService.warning(error.error && error.error.message ||  'Внимание! Группа участвует в коллизиях групп кампаний. Удаление невозможно.');
          else
            this.warningBoxService.warning(error.error && error.error.message ||  'Ошибка удаления');
        })
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  getCampaings() {
    this.listCampaings = [];


    this.campaignCategoryService.queryLinks$(parseInt(this.id) )
      .subscribe(links=> {
        this.listIncludedCampaings = links;

        if ( links.some( link => link.campaignDetails && link.campaignDetails.priceFlag ) ) {
          this.priceFlag = true;
        }

        let campaignIDs = [];
        let couponRuleIDs = [];
        this.listIncludedCampaings.forEach( i => {
          switch (i?.source) {
            case "campaign": campaignIDs.push(i.sourceId); break;
            case "couponRule": couponRuleIDs.push(i.sourceId); break;
          }
        });

        this.campaignService.query$({
          filter : {
            "states":["draft","suspended","unsuspend","planned","started","active","completed"]
          },
          pager: {
            drop:0,
            limit: 99999
          }
        }).subscribe( (list = [] ) => {

          this.listCampaings = this.listCampaings.concat(
            list
              .filter(
                campaignItem => campaignItem.campaign.categoryId !== parseInt(<any>this.id) && !campaignIDs.includes(campaignItem.campaign.id)
              )
              .map(
                campaignItem => ({
                  "sourceId": campaignItem.campaign.id,
                  "categoryId": campaignItem.campaign.categoryId,
                  "source": "campaign",
                  "campaignDetails": {
                    "name": campaignItem.campaign.name,
                    "description":campaignItem.campaign.description,
                    "state": campaignItem.campaign.state
                  }
                })
              ));
        });

        this.couponRuleService.query$({
          filter: {
            states: ['draft','active']
          },
          pager: {
            drop: 0,
            limit: 9999
          }
        }).subscribe((list:any[]) => {

          this.listCampaings = this.listCampaings.concat(
            list
              .filter(
                ruleItem => ruleItem?.categoryId !==  parseInt(<any>this.id) && !couponRuleIDs.includes(ruleItem?.id)
              )
              .map(
                ruleItem => ({
                  "sourceId": ruleItem?.id,
                  "categoryId": ruleItem?.categoryId,
                  "source": "couponRule",
                  "couponRuleDetails": {
                    "name": ruleItem?.name,
                    "description":ruleItem?.description,
                    "state": ruleItem?.state
                  }
                })
              ));

        });

      })


  }

  groupBySource(item:any) {

    switch (item.source) {
      case "campaign":
        return 'Кампании';
      case "couponRule":
        return 'Купонные правила';
    }
  };

  swapUp(campaign) {

    if (!campaign) {
      return;
    }


    var prevPriority: any = false;
    this.listIncludedCampaings.forEach( function (item) {

      if (item === campaign) {
        return;
      }

      if (item.priority < campaign.priority) {

        if (prevPriority === false) {
          prevPriority = item;
          return;
        }

        if (prevPriority.priority < item.priority) {
          prevPriority = item;
        }
      }

    });

    if (prevPriority !== false) {

      this.lastSwapedCampaign = campaign;

      var pr = prevPriority.priority;
      prevPriority.priority = campaign.priority;
      campaign.priority = pr;
    }


  };

  swapDown(campaign) {


    if (!campaign) {
      return;
    }


    var prevPriority: any = false;
    this.listIncludedCampaings.forEach( function (item) {

      if (item === campaign) {
        return;
      }

      if (item.priority > campaign.priority) {

        if (prevPriority === false) {
          prevPriority = item;
          return;
        }

        if (prevPriority.priority > item.priority) {
          prevPriority = item;
        }
      }

    });

    if (prevPriority !== false) {

      this.lastSwapedCampaign = campaign;

      var pr = prevPriority.priority;
      prevPriority.priority = campaign.priority;
      campaign.priority = pr;
    }


  };

  findMaxPriority() {
    var maxPriority = 0;
    var sourceId;

    if (this.listIncludedCampaings.length) {
      sourceId = this.listIncludedCampaings[0].sourceId;
      maxPriority = this.listIncludedCampaings[0].priority;

      for (var i in this.listIncludedCampaings) {
        if (this.listIncludedCampaings[i].priority >= maxPriority) {
          maxPriority = this.listIncludedCampaings[i].priority;
          sourceId = this.listIncludedCampaings[i].sourceId;
        }
      }
    }

    return {priority: maxPriority, sourceId: sourceId};
  };

  addCampaignInGroup() {
    var mp = this.findMaxPriority();

    if (!this.itemForAdd)
      return;

    var key = this.listCampaings.indexOf(this.itemForAdd);

    this.listIncludedCampaings.push(Object.assign({}, this.itemForAdd, {
        priority: mp.priority + 1
      })
    );

    this.listCampaings.splice(key, 1);
    this.itemForAdd = undefined;
  };

  sortByPriority(item1, item2) {
    return item1.priority - item2.priority;
  }

  campaignOutCategoryDisabled() {

    var ret = true;
    for (var i = 0; i < this.listCampaings.length; i++) {
      if (this.listCampaings[i][this.listCampaings[i].source + 'Details'].state == 'draft') {
        ret = false;
        break;
      }
    }
    return ret;
  };

}
