/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PromotionApiConfiguration, PromotionApiConfigurationInterface } from './promotion-api-configuration';

import { PromotionApiService } from './services/promotion-api.service';

/**
 * Provider for all PromotionApi services, plus PromotionApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    PromotionApiConfiguration,
    PromotionApiService
  ],
})
export class PromotionApiModule {
  static forRoot(customParams: PromotionApiConfigurationInterface): ModuleWithProviders<PromotionApiModule> {
    return {
      ngModule: PromotionApiModule,
      providers: [
        {
          provide: PromotionApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
