
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {GoodsSelectorService, legacyDirectiveParams} from "../../goods-tree/service/goods-selector.service";

@Component({
  selector: 'filter-goods',
  templateUrl: './filter-goods.component.html',
  providers: [
    GoodsSelectorService
  ]
})
export class  FilterGoodsComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public partnerId;
  @Output() public partnerIdChange  = new EventEmitter();
            private changedPartnerId;

  @Input() public showCategories;
  @Input() public max;

  public isPending = false;
  public type = '';
  public list = [];

  get hasSelectedGoods() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private goodsSelectorService: GoodsSelectorService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (
      changes['partnerId'] &&
      !!changes['partnerId']?.currentValue &&
      !!this.changedPartnerId &&
      changes['partnerId']?.currentValue !== this.changedPartnerId
    ) {
      this.clear();
    }
  }

  openDialog() {
    this.goodsSelectorService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})
  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};

    if (this.partnerId) {
      options.initPartner = this.partnerId;
    }

    if (this.showCategories) {
      options.showCategories = this.showCategories;
    }

    if (!!this.max) {
      options.max = this.max;
    }

    return options;
  }

  public changeResult(result) {

    this.partnerId = result.partnerId;
    this.changedPartnerId = result.partnerId;
    this.partnerIdChange.emit( this.partnerId );

    this.list = result.list;
    this.type = result.type;

    this.emitModel();

  }

  private emitModel() {

    this.modelChange.emit(
      !this.list?.length ?
        undefined :
        {
          list: this.list,
          type: this.type
        });
  }

  public getName(item) {
    return typeof  item === "object" ? item?.label : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }

}
