/* tslint:disable */
type UserRole =
  'Administrator' |
  'MarketingManager' |
  'GiftMarketingManager' |
  'TopManager' |
  'Operator' |
  'InputOperator' |
  'Infocenter' |
  'Api' |
  'HeadOperator';
module UserRole {
  export const ADMINISTRATOR: UserRole = 'Administrator';
  export const MARKETING_MANAGER: UserRole = 'MarketingManager';
  export const GIFT_MARKETING_MANAGER: UserRole = 'GiftMarketingManager';
  export const TOP_MANAGER: UserRole = 'TopManager';
  export const OPERATOR: UserRole = 'Operator';
  export const INPUT_OPERATOR: UserRole = 'InputOperator';
  export const INFOCENTER: UserRole = 'Infocenter';
  export const API: UserRole = 'Api';
  export const HEAD_OPERATOR: UserRole = 'HeadOperator';
  export function values(): UserRole[] {
    return [
      ADMINISTRATOR,
      MARKETING_MANAGER,
      GIFT_MARKETING_MANAGER,
      TOP_MANAGER,
      OPERATOR,
      INPUT_OPERATOR,
      INFOCENTER,
      API,
      HEAD_OPERATOR
    ];
  }
}

export { UserRole }