import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscriber} from "rxjs";
import {map} from "rxjs/operators";
import {DashboardApiService} from "../../../../api/DashboardApi/services/dashboard-api.service";

import { BLOCK_ID_BonusId as BonusId} from "../block-ids.variables";
import {BLOCK_CampaingId as CampaingId} from "../block-ids.variables";
import {BLOCK_ActiveCampaignId as ActiveCampaignId} from "../block-ids.variables";
import {BLOCK_SegmentsId as SegmentsId} from "../block-ids.variables";
import {
  BLOCK_ID_AVG_WITHOUT_CLIENT,
  BLOCK_ID_AVG_WTH_CLIENT,
  BLOCK_ID_CHECK_WITHOUT_CLIENT,
  BLOCK_ID_CHECK_WITH_CLIENT
} from "../block-ids.variables";
import {BLOCK_emailId as emailId} from "../block-ids.variables";
import {BLOCK_emailCount as emailCount} from "../block-ids.variables";
import {BLOCK_receiptCount as receiptCount} from "../block-ids.variables";
import {BLOCK_smsCost as smsCost} from "../block-ids.variables";
import {BLOCK_smsCount as smsCount} from "../block-ids.variables";
import {BLOCK_viberId as viberId,  BLOCK_ID_SMS, BLOCK_ID_VIBER} from "../block-ids.variables";
import {BLOCK_checkCount as checkCount} from "../block-ids.variables";
import {BLOCK_bonusAward as bonusAward} from "../block-ids.variables";
import {BLOCK_incomeTotalId as incomeTotalId} from "../block-ids.variables";
import {BLOCK_costsDiscount as costsDiscount} from "../block-ids.variables";
import {BLOCK_totalGift as totalGift} from "../block-ids.variables";
import {BLOCK_revenueId as revenueId} from "../block-ids.variables";

const STAT_ID_FOR_MERGE =
  [
    //graph
    BLOCK_ID_AVG_WITHOUT_CLIENT,
    BLOCK_ID_AVG_WTH_CLIENT,
    BLOCK_ID_CHECK_WITHOUT_CLIENT,
    BLOCK_ID_CHECK_WITH_CLIENT,

    // Informing
    emailId,
    emailCount,
    receiptCount,
    smsCost,
    smsCount,
    viberId,
    BLOCK_ID_SMS,
    BLOCK_ID_VIBER,

    // TOP
    checkCount,
    bonusAward,
    incomeTotalId,
    'incomeTotal',
    costsDiscount,
    totalGift,

]

const CHARTS_ID = [
  BonusId,
  CampaingId,
  ActiveCampaignId,
  SegmentsId,
  revenueId,
]


@Injectable({
  providedIn: "root"
})
export class DashboardRequestService implements OnInit, OnDestroy{

  private queue: any = [];
  private runned = false;

  private prevRequests = {};

  constructor(
    private dashboardApiService:DashboardApiService,
  ) {

  }


  ngOnDestroy() {
  }

  ngOnInit(): void {

  }

  public get$( id='', params? ) : Observable<any> {


    this.cancelRequest(id);

    let obs = new Observable( subscriber => {

      this.queue.forEach( i => {
        if (i.id === id)
          i.subscriber.complete()
      });
      this.queue = this.queue.filter( i => i.id !== id);

      this.queue.push({
        id: id,
        params: params,
        subscriber: subscriber
      });

      this.runQueue();

    });

    return obs;

  }

  private runQueue() {

    if ( !(this.queue?.length > 0 ) || this.runned )
      return;


    this.runned = true
    setTimeout( () => {

      this.processOneRequest()
        .then( () => {
          this.runned = false;
          this.runQueue();
        }, () => {
          this.runned = false;
          this.runQueue();
        });

    });

  }

  private getItemsForRequest() {

    if ( !(this.queue?.length > 0 ) )
      return;

    let item = this.queue.shift();
    let itemsForMerge = [];

    if ( STAT_ID_FOR_MERGE.includes( item?.id) ) {

      itemsForMerge = this.queue.filter( queueItem =>
        STAT_ID_FOR_MERGE.includes( queueItem?.id ) &&
        item.params === queueItem.params
      )

      this.queue = this.queue.filter( i => !itemsForMerge.includes( i ))
    }

    itemsForMerge.unshift(item);
    return itemsForMerge;

  }

  private processOneRequest() {
    return new Promise( resolve => {

      let items = this.getItemsForRequest();
      if (!items) {
        return resolve(true);
      }

      switch (true) {

        case CHARTS_ID.indexOf(items[0]?.id) >= 0:
          Promise.all(
            items.map( ( item: any ) => new Promise( subResolve => {

              let cancel =
                this.dashboardApiService.detailedStat({
                  from: item.params?.from,
                  to  : item.params?.to,
                  locationIds  : item.params?.locationIds,
                  fact : item?.id.replace(/%.*%/ig, ''),
                }).subscribe(
                    result => {
                      (<Subscriber<any>>item.subscriber).next(result );
                      (<Subscriber<any>>item.subscriber).complete();
                      item.subscriber = null;
                      this.prevRequests[item?.id] = undefined;
                      subResolve(true);
                    },
                    error => {
                      (<Subscriber<any>>item.subscriber).error(error);
                      (<Subscriber<any>>item.subscriber).complete();
                      item.subscriber = null;
                      this.prevRequests[item?.id] = undefined;
                      subResolve(true);
                    }
                  );

              this.setCancelRequest(item, cancel, subResolve);

              })
            )
          ).then( resolve, resolve);
          break;

        case STAT_ID_FOR_MERGE.indexOf(items[0]?.id) >= 0:

          let cancel = this.dashboardApiService.commonStat({
            from: items[0].params?.from,
            to  : items[0].params?.to,
            locationIds  : items[0].params?.locationIds,
            facts  : items.map( i => i?.id),
          })
            .subscribe(
              result => {

                  items.forEach( item => {

                    if ( Array.isArray(result) ) {
                      let finded = result.find( i => i.fact === item?.id);
                      (<Subscriber<any>>item.subscriber).next( finded? finded.value : 0);
                    } else {
                      (<Subscriber<any>>item.subscriber).next( 0);
                    }
                    (<Subscriber<any>>item.subscriber).complete();

                    this.prevRequests[item?.id] = undefined;

                  })

                  resolve(true);
                },
                error => {

                  items.forEach( item => {

                    (<Subscriber<any>>item.subscriber).next( error);
                    (<Subscriber<any>>item.subscriber).complete();
                    this.prevRequests[item?.id] = undefined;
                  });

                  resolve(true);

                 })



          items.forEach( item => {
            this.setCancelRequest(item, cancel, resolve);
          })

          break;

        default:

          Promise.all(
            items.map( ( item: any ) => new Promise( subResolve => {

              let cancel = this.dashboardApiService.commonStat({
                from: item.params?.from,
                to  : item.params?.to,
                locationIds  : item.params?.locationIds,
                facts : [item?.id],
              }).subscribe(
                  result => {

                    let finded: any = 0;
                    if ( Array.isArray(result) ) {
                      let finded = result.find( i => i.fact === item?.id);
                    }

                    (<Subscriber<any>>item.subscriber).next(finded? finded?.value : 0 );
                    (<Subscriber<any>>item.subscriber).complete();
                    item.subscriber = null;
                    this.prevRequests[item?.id] = undefined;
                    subResolve(true);
                  },
                  error => {
                    (<Subscriber<any>>item.subscriber).error(error);
                    (<Subscriber<any>>item.subscriber).complete();
                    item.subscriber = null;
                    this.prevRequests[item?.id] = undefined;
                    subResolve( true);
                  }
                )

              this.setCancelRequest(item, cancel, subResolve);

              })
            )
          ).then( resolve, resolve);

      }



    })
  }

  public setCancelRequest(item: any = {}, cancel, resolve): any {
    this.cancelRequest(item?.id)
    let unsubFunction = cancel?.unsubscribe;

    cancel.unsubscribe = function () {
      resolve(true);
      return unsubFunction.apply(this, arguments);
    }

    this.prevRequests[item?.id] = {
      cancel: cancel,
      subscriber: item?.subscriber,
    };

  }

  public cancelRequest(id?): any {
    if ( typeof id === "undefined" || typeof this.prevRequests[id] !== "object" )
      return;

    try {
      this.prevRequests[id].cancel.unsubscribe();
    } catch (e) {
    }

    try {
      this.prevRequests[id].subscriber.complete();
    } catch (e) {
    }

    delete this.prevRequests[id];

  }


  public testHttpRequest() {

    return new Observable( observer => {
      setTimeout(() => {
        observer.next(1);
        observer.complete();
      }, Math.random() * 2000 );
    });

  }
}
