import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {BrandService} from "../../../shared/service/api/Brand/Brand.service";
import {ClientCategory} from "../../../api/ClientCategoryApi/models/client-category";
import {Brand} from "../../../api/BrandApi/models/brand";
import {ReferrerService} from "../../../shared/service/api/Referrer/Referrer.service";
import {ReferrerInfoForQuery} from "../../../api/ReferrerApi/models/referrer-info-for-query";


@Component({
  selector: 'referrer-index-page',
  templateUrl: './referrer-index-page.component.html',
  providers:[
    ReferrerService
  ]
})
export class ReferrerIndexPageComponent implements OnInit{

  searchString: string;
  listReferrers: Array<ReferrerInfoForQuery> =[]
  rolesList = {};

  constructor(
    public referrerService: ReferrerService
  ) {
  }

  ngOnInit() {
    this.referrerService.getListParams.reset();
    this.getList(this.referrerService.getListParams.params);
  }

  getList(params) {
    this.referrerService.getList$(params)
      .subscribe( result => {
        this.listReferrers = this.listReferrers.concat(result);
      });
  }


  onScroll() {
    this.referrerService.getListParams.next();
    this.getList(this.referrerService.getListParams.params)
  }

  reset() {
    this.referrerService.getListParams.reset();
    this.listReferrers=[];
    this.getList(this.referrerService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.referrerService.getListParams.search(this.searchString);
    this.reset();
  }

  changeFilter( $event ) {
    this.referrerService.getListParams.setFilterValue( $event.target.name, $event.target.value);
    this.reset();

  }
}
