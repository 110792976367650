import {ChangeDetectorRef, Component, Input, OnInit, Output} from "@angular/core";
import {EventEmitter} from "@angular/core";
import {ReferrerService} from "../../../../shared/service/api/Referrer/Referrer.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'referrer-edit-account',
  templateUrl: './referrer-edit-account.component.html',
  providers: [
    ReferrerService
  ]
})
export class ReferrerEditAccountComponent implements OnInit{

    // Передаваемые параметры в компонент
    @Input()   public id       : any;
    @Input()   public currentBalance : number;
    @Output()  public onChange = new EventEmitter();

    public operBalance: any;

    public OPER_TYPES = {
      "credit" : 'начисление',
      "debit"  : 'списание',
    }

    public amount: number;
    public operationType: string;

    constructor(
      public referrerService      : ReferrerService,
      public detector : ChangeDetectorRef,
    ) { }

    public ngOnInit() {
      this.operationType = Object.keys(this.OPER_TYPES)[0];
    }

    public updateForm(form: NgForm) {
      setTimeout(() => {
        form.controls['amount'].updateValueAndValidity();
        this.detector.detectChanges();
      });
    }

    submit() {
      this.referrerService.balanceOperation$({
        id: this.id,
        params: {
          amount        : this.amount,
          operationType : <any>this.operationType,
        }
      }).subscribe( result => {
        this.onChange.emit(true);
      })
    }

    cancel() {
      this.onChange.emit();
   }

};
