/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RewardWithAmountApiConfiguration, RewardWithAmountApiConfigurationInterface } from './reward-with-amount-api-configuration';

import { RewardWithAmountApiService } from './services/reward-with-amount-api.service';

/**
 * Provider for all RewardWithAmountApi services, plus RewardWithAmountApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    RewardWithAmountApiConfiguration,
    RewardWithAmountApiService
  ],
})
export class RewardWithAmountApiModule {
  static forRoot(customParams: RewardWithAmountApiConfigurationInterface): ModuleWithProviders<RewardWithAmountApiModule> {
    return {
      ngModule: RewardWithAmountApiModule,
      providers: [
        {
          provide: RewardWithAmountApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
