import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MechanicApiService} from "../../../../api/MechanicApi/services/mechanic-api.service";
import {MechanicQueryParam} from "../../../../api/MechanicApi/models/mechanic-query-param";


@Injectable({
  providedIn: 'root',
})
export class MechanicService {

  constructor( private mechanicApiService:MechanicApiService ) {
  }

  public query$(params?:MechanicQueryParam){
    return this.mechanicApiService.query(params)
  }


}
