import {Inject, Injectable, Injector} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TriggerDialogComponent} from "../trigger-dialog.component";

export type legacyDirectiveParams = {
  resultDirective: (result: any) => any | {},  //+
  forPartner?: number | string,
  checkOne?: boolean | string,
  canCheckAll?: boolean | string,
  linkOne?: boolean | string,
  initSelectedItems?: any,
  initPartner?: number | string,
  max?: number,
  filterIDs?: any
}

@Injectable({
  providedIn: "root",
})
export class TriggerDialogService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public open(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      TriggerDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    return modalRef.result;

  }

  public legacyPopup(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      TriggerDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    modalRef.result.then( result => {

      this.legacyResult(result, options.resultDirective)

    }, cancal => {})

  }

  private legacyResult(result, resultDirective) {

    if (typeof resultDirective === "function") {
      resultDirective(result);
    }

    if (typeof resultDirective === "object") {

      Object.keys(resultDirective).forEach( key => {
        delete resultDirective[key];
      })

      Object.assign(resultDirective, result);
    }

  }

  private legacySetOptions(modalInstance: TriggerDialogComponent, options: legacyDirectiveParams) {


    if ( !!options?.linkOne || !!options?.checkOne ) {
      modalInstance.setOneSelect();
    }

    if ( !!options?.canCheckAll ) {
      modalInstance.setCheckAll( !!options?.canCheckAll );
    }

    if ( typeof options?.initSelectedItems !== "undefined") {
      modalInstance.initSelected(options?.initSelectedItems)
    }

    if ( typeof options?.max !== "undefined") {
      modalInstance.setMax(options?.max)
    }

    if ( typeof options?.filterIDs !== "undefined") {
/*      modalInstance.setCustomParams({
          ids: Array.isArray(options?.filterIDs) ? options?.filterIDs : [options?.filterIDs]
      })*/
    }

  }


}
