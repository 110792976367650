
export class ModalImageUploader {

    // Передаваемые параметры в компонент
    public options      : any;
    public uploader     : Function;
  static $inject = ['$uibModal' ];
    constructor( private $uibModal : any) { }

    public $onInit() {

    }

    public openModal(){


    }

}
