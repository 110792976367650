export enum CampaignStatus {
    'created' = 'created',
    'sending' = 'sending',
    'collectStat' = 'collectStat',
    'inProgress' = 'inProgress',
    'done' = 'done'
}

export const CampaignStatusNames = {
    [CampaignStatus.created]: 'Создана',
    [CampaignStatus.inProgress]: 'Отправляется',
    [CampaignStatus.sending]: 'Отправляется',
    [CampaignStatus.collectStat]: 'Сбор статистики',
    [CampaignStatus.done]: 'Завершена'
}

export enum NewsletterStatus {
  'error' = 'error',
  'created' = 'created',
  'sending' = 'sending',
  'delivered' = 'delivered',
  'read' = 'read'
}

export const NewsletterStatusNames = {
  [NewsletterStatus.error]: 'Создана',
  [NewsletterStatus.created]: 'Создано',
  [NewsletterStatus.sending]: 'Отправлено',
  [NewsletterStatus.delivered]: 'Доставлено',
  [NewsletterStatus.read]: 'Прочитано'
}

export enum NewsletterTaskState {
  'created' = 'created',
  'sending' = 'sending',
  'waitState' = 'waitState',
  'waitRead' = 'waitRead',
  'done' = 'done'
}

export const NewsletterTaskStateNames = {
  [NewsletterTaskState.created]: 'Создано',
  [NewsletterTaskState.sending]: 'Отправлено',
  [NewsletterTaskState.waitState]: 'Ждем статус',
  [NewsletterTaskState.waitRead]: 'Доставлено',
  [NewsletterTaskState.done]: 'Архив'
}


export enum NewsletterTaskExternalState {
  'wrongNumber' = 'wrongNumber',
  'forbidden' = 'forbidden',
  'insufficientFunds' = 'insufficientFunds',
  'created' = 'created',
  'delivered' = 'delivered',
  'undeliverable' = 'undeliverable',
  'read' = 'read',
  'error' = 'error',
  'unknown' = 'unknown'
}

export const NewsletterTaskExternalStateNames = {
  [NewsletterTaskExternalState.wrongNumber]: 'Неверный номер',
  [NewsletterTaskExternalState.forbidden]: 'Запрещено',
  [NewsletterTaskExternalState.created]: 'Создано',
  [NewsletterTaskExternalState.delivered]: 'Доставлено',
  [NewsletterTaskExternalState.read]: 'Прочитано',
  [NewsletterTaskExternalState.insufficientFunds]: 'Недостаточно средств',
  [NewsletterTaskExternalState.undeliverable]: 'Отклонено',
  [NewsletterTaskExternalState.error]: 'Ошибка',
  [NewsletterTaskExternalState.unknown]: 'Неизвестно'
}

export enum ChannelType {
  'sms' = 'sms',
  'email' = 'email',
  'viber' = 'viber',
  'flashcall' = 'flashcall',
}

export const ChannelTypeNames = {
  [ChannelType.sms]: 'SMS',
  [ChannelType.email]: 'E-mail',
  [ChannelType.viber]: 'Viber',
  [ChannelType.flashcall]: 'Flashcall'
}

export enum SourceType {
  'campaign' = 'campaign',
  'group' = 'group',
  'trigger' = 'trigger',
}

export const SourceTypeNames = {
  [SourceType.campaign]: 'Кампания',
  [SourceType.group]: 'Группа',
  [SourceType.trigger]: 'Цепочка'
}
