import {CouponKind, ImportedCouponId} from "./CouponKind";
import {ExternalId} from "../IssuingTypes/IssuingType";



export class ImportedCoupon  extends CouponKind {


    static id = ImportedCouponId;

    protected sameClientCount = true;
    protected importCoupons = true;

    get hasCanBeSold() : boolean {
        return this.issuingType.getId() === ExternalId;
    }

    get canImportCoupons(): boolean {
        return this.ruleItem.couponRule.state === 'draft';
    }
}