<ng-select [(ngModel)]="id"
           [items]="filteredItems"
           [virtualScroll]="true"
           [loading]="loading"
           bindLabel="name"
           bindValue="id"
           [name]="name"
           [placeholder]="placeholder"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (search) = "onSearch($event)"
           (change) = "onChange()"
           (clear) = "clear()"
           (close) = "clear()"
           [disabled]="disabled"
           [required]="required"
           [class.ng-invalid]="!isValid || (isEmpty && required)"
           [multiple]="multiple"
           [closeOnSelect]="!multiple"
           [maxSelectedItems]="max"

>

  <ng-template ng-label-tmp let-item="item">

    <div class="hbox hbox-auto">

      <div class="col w-full">
        <div class="clearfix">
          {{ item.name }}
        </div>

      </div>

    </div>

  </ng-template>

  <ng-template ng-multi-label-tmp>
    <div class="inline-block m-t-n-xs m-l-xs m-r-xs">
      Выбрано групп ({{id?.length || 0}})
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index">

    <div class="hbox hbox-auto">

      <div class="col w-full">

        <div class="clearfix">
          <search-highlight
            [model]="lastSearchText || ''"
            [text]="item.name"
          ></search-highlight>
        </div>

      </div>

    </div>

  </ng-template>
</ng-select>

<input type="hidden" [required]="required" [name]="'selectGroupDummy'" [(ngModel)]="id">
