import {Injectable} from "@angular/core";
import {ComponentApiService} from "../../../../api/ComponentApi/services";
import {ClientCategory} from "../../../../api/ClientCategoryApi/models";
import {forkJoin, Observable, of} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {HttpClient} from "@angular/common/http";
import SetParams = ComponentApiService.SetParams;
import {map, switchMap} from "rxjs/operators";
import {getNg1Service} from "../../../class/utils/angularjs.utils";

@Injectable()
export class ComponentService {

  constructor(
      private componentApiService:ComponentApiService,
      private http: HttpClient,
    ) {
  }


  public get$( params: ComponentApiService.GetParams){

    return this.http.get(
      ComponentApiService.getPath.replace('{componentType}', params.componentType),
      {
        params: {
          what: params.what
        },
        responseType: "json"
      }
    )

  }

  public set$(params: SetParams) {
    return this.componentApiService.set(params)
  }

  public delete$(params: ComponentApiService.DeleteParams) {
    return this.componentApiService.delete(params)
  }

  public getConfig$( componentType: string, config: Array<any> | true = [])  {

    if (config === true) {
      return of({});
    }

    let arrayRequests = config.map(  (configItem) =>
      this.get$({
        componentType,
        what: configItem.id
      }).pipe(
        map( result => ({
          configId: configItem.id,
          result
        }))
      )
    )

    const ComponentModelNg1  = getNg1Service('ComponentModel');
    const $q = getNg1Service('$q');

    return forkJoin(arrayRequests)
      .pipe(
        map( results  => {
          let ret = {};
          results.forEach( result  =>  {

            if ( config?.find(configItem => configItem.id === result.configId)?.isArray ) {
              ret[result.configId] = result?.result;
            } else {
              ret[result.configId] = new ComponentModelNg1(result?.result);
            }

            if ( typeof ret[result.configId] === "object" && typeof ret[result.configId]!==  null ) {
              ret[result.configId].$promise = $q.resolve(ret[result.configId]);
            }

          })
          return ret;
        })
      )

  }


  public setConfig$( componentType: string, data: Array<any> | Object, config: Array<any> = [])  {

    const isSaveAsArray = (id: string) => !!config?.find(configItem  => configItem.id  === id)?.saveAsArray;

    if  (typeof data === "object" && !Array.isArray(data) ) {
      let resArray  =  [];
      Object.keys(data).forEach( key  =>  {

        if (key?.startsWith("$") )
          return;

        resArray.push({
          id: key,
          value: data[key]
        });
      })
      data = resArray;
    }

    if ( Array.isArray(data) )  {
      let resArray  =  [];

      data.forEach( dataItem  =>  {
        if ( Array.isArray(dataItem.value) && !isSaveAsArray(dataItem.id) )
          dataItem.value.forEach( item  => {

              if ( dataItem?.id?.startsWith("$") )
                return;

              resArray.push({
                id: dataItem.id,
                value: item
              })
            }
          )
        else
          if ( !dataItem?.id?.startsWith("$") )
            resArray.push(dataItem);

        data = resArray;
      })
    }

    (<any[]>data)?.forEach(
      (dataItem) =>   {
        typeof dataItem?.value === "object" &&
        Object.keys(dataItem?.value)
          .filter(  (key)  => key?.startsWith("$") )
          .forEach(   (key)   =>   {
            delete dataItem.value[key];
          })
      })

    const isForDelete = (item) => {

      if (!item?.value)
        return true;

      if ( typeof item?.value === "object" && !Object.keys(item?.value)?.length )
        return true;

      if ( item?.value?.hasOwnProperty('value') && typeof item?.value?.value === "undefined")
        return true;

      return false;
    }

    let arrayRequests = (<any[]>data)
      .filter( (dataItem) =>  !isForDelete(dataItem) )
      .map(  (dataItem) =>
        this.set$({
          componentType,
          what: dataItem.id,
          profileData: dataItem.value
        })
      )

    let removeRequests = (<any[]>data)
      .filter( (dataItem) => isForDelete( dataItem ) )
      .map(  (dataItem) =>
        this.delete$({
          componentType,
          what: dataItem.id
        })
      )

    return forkJoin(arrayRequests)
            .pipe(
              switchMap( results  =>  forkJoin(removeRequests) )
            );

  }


}
