export const ACCOUNT_OPER_TYPE = {
    'credit':'начисление',
    'debit':'списание',
    'correct':'коррекция',
    'burning':'сжигание',
    'returnCreditInactive': 'возврат начисления',
    'returnCreditActive': 'возврат начисления',
    'returnDebit':'возврат списания',
    'transfer':'перевод отложенных начислений',
    'hold':'кредит',
    'payment':'гашение'
};

export const ACCOUNT_OPER_TYPE_MANUAL = {
  'credit':'начисление',
  'debit':'списание'
};

export const ACCOUNT_RECORD_STATUS = {
  'created'          : 'создана',
  'confirmed'        : 'чек подтвержден',
  'lockedToTransfer' : 'переводятся на счет',
  'transferred'      : 'переведена на счет/зафиксирована',
  'transferredCredit': 'переведена на счет',
  'transferredOther' : 'зафиксирована',
  'lockedToSpread'   : 'обработка [списания]',
  'archived'         : 'обработка завершена'
};

export const ACCOUNT_REC_TYPE = {
  'auto':'автоматическая',
  'manual':'ручная'
};
