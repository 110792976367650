/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PreferenceApiConfiguration, PreferenceApiConfigurationInterface } from './preference-api-configuration';

import { PreferenceApiService } from './services/preference-api.service';

/**
 * Provider for all PreferenceApi services, plus PreferenceApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    PreferenceApiConfiguration,
    PreferenceApiService
  ],
})
export class PreferenceApiModule {
  static forRoot(customParams: PreferenceApiConfigurationInterface): ModuleWithProviders<PreferenceApiModule> {
    return {
      ngModule: PreferenceApiModule,
      providers: [
        {
          provide: PreferenceApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
