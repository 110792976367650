import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'callbackSort',
  pure: false
})
export class CallbackSortPipe implements PipeTransform {
  transform(arr: any[], callback: (item1: any, item2: any) => any  ): any {
    if (!Array.isArray(arr)) {
      return;
    }

    arr.sort(callback);
    return arr;
  }
}
