import { QueryHandler } from './../../QueryHandler.class';
import {ResourceHandler} from "./../../ngResourceHandler.class";

export const FILTER_FIELD = '$search';

interface IResourceSearchOptions {
    paramName: string;
}

export class ngResourceSearch extends ResourceHandler{


    private currentSearchValue = "";

    private options: IResourceSearchOptions;
    private defaultOptions: IResourceSearchOptions = {
        paramName: ''
    }

  static $inject = ['handler'];

    constructor(
        protected handler: QueryHandler
    ) {

        super(handler);
        this.options = (<IResourceSearchOptions>  (<any>Object).assign({}, this.defaultOptions, this.handler.actions.search));

    }

    get field() {
        return FILTER_FIELD;
    }

    static canHandle( actions: any ) {
        return actions && !!actions.search;
    }

    public getCurrentParams() {

        if (this.currentSearchValue === '') {
            return {};
        }

        return {
            [this.options.paramName]: this.currentSearchValue
        };
    };

    public query = function () {
        return this.handler.reset();
    };

    public reset = function () {
        this.currentSearchValue = '';
        return this.query();
    };

    protected onUpdate( finish: Promise<any> ) {

        return this.getCurrentParams();

    };

    protected onStart( firstQueryOptions: Object ) {

        if ( typeof firstQueryOptions[this.options.paramName] !== "undefined" ) {
            this.currentSearchValue = firstQueryOptions[this.options.paramName];
        }

    }

    protected onReset() {}

    get value() {

        if (!this.currentSearchValue || this.currentSearchValue === '') {
            return ;
        }

        return this.currentSearchValue;
    }

    set value( value: string ) {

        this.currentSearchValue = value;
        this.query();

    }

    get isEnabled() {
        return !!this.currentSearchValue;
    }

    get isDisabled() {
        return !this.isEnabled;
    }

}

