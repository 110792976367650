'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

import 'ng-infinite-scroll';

import 'model/CardModel/resource';

import 'directive/accountPopup/main';

import 'model/ClientModel/resource';

let routePath = module.name + '.crm.clients.index';
module.requires = module.requires.concat([
    ,
    'infinite-scroll',
    'ui.footable'
]);

let searchState: any  = {
    enabled : false
};

export default
module
    .run(['$transitions', function($transitions) {
        let moduleName = routePath.split('.').slice(0,-1).join('.');
        $transitions.onStart({}, function(transition) {
            if ( searchState.enabled && transition.to().name.indexOf(moduleName) !== 0 ) {
                delete searchState.items;
                searchState = {
                    enabled : false
                };
            }
        });

    }])
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/crm.clients.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/crm.clients.index" */ "./controllers"       ))

                .resolve('lastSearchState', [ () => searchState ])
                .resolve('clientList', [  'ClientModel', 'lastSearchState', ( ClientModel, lastSearchState ) => {
                    if ( lastSearchState && lastSearchState.enabled ) {

                       // return lastSearchState.items || [];

                        var qParam = {
                            'drop'  : 0,
                            'limit' : lastSearchState.searchState.drop,
                            'sort'  : lastSearchState.sortClients ? lastSearchState.sortClients : undefined,
                            'sentence' : lastSearchState.searchAccountClient,
                            'state': lastSearchState?.searchState?.isArchive ? 'archive' : '!archive'
                        };

                        if (qParam.limit === 0) {
                            delete qParam.limit;
                        }

                        return ClientModel.search(qParam).$promise;

                    } else {
                        return ClientModel.query({ 'state': lastSearchState?.searchState?.isArchive ? 'archive' : '!archive' }).$promise;

                    }

                } ])

                .breadcrumbs( [ "Клиенты", "Просмотр" ] )

                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.InputOperator, RoleType.Infocenter],
                })

        }]);

