import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe } from '@angular/common';
import { LoyaDatePipe } from './loya-date.pipe';
import { LoyaTimePipe } from './loya-time.pipe';

@Pipe({
  name: 'loyaDateTime'
})
export class LoyaDatetimePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string) {}

  transform(value:  string, args?: any): string {

    let datePipe: any =  new LoyaDatePipe(this._locale);
    let timePipe: any =  new LoyaTimePipe(this._locale);

    return datePipe.transform(value) + " " + timePipe.transform(value) ;

  }
}
