'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

let routePath   = module.name + '.config';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],

                })

        }]);
