'use strict';

import module from 'frontendModule';
let routePath   = module.name + '.config.directory.partners.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template( '<partners-index-page></partners-index-page>', true)
        .breadcrumbs( [ "Справочники",
          [ 'loyaPermissions', (loyaPermissions) =>
            loyaPermissions.byPath('partner').enabled ? "Партнёры" : "Общие параметры"
          ]
        ] );
    }]);
