import { UserHandler} from "./class/UserHandler";
import {FiltersHandler} from "./class/FiltersHandler";
import {FieldHandler} from "./class/FieldHandler";
import {ChangeItem, OpTypes} from "./component/referrer-log-history-changes.component";
import {HistoryLogService} from "./class/HistoryLogService";
import {Component, Input, OnInit} from "@angular/core";
import {ReferrerService} from "../../../../shared/service/api/Referrer/Referrer.service";
import {UserService} from "../../../../shared/service/api/User/User.service";

interface LogHistoryRow {

    date     : Date,
    userId   : number,
    userName : string,
    moduleId : number,
    moduleName : string,
    fieldId    : string,
    fieldName  : string,

    nickname : string,
    referrerId : number,

    clientName ?: string,
    clientId   ?: string,

    values: ChangeItem,

}


@Component({
  selector: 'referrer-log-history',
  templateUrl: './referrer-log-history.component.html',
  providers: [
    ReferrerService
  ]
})
export class ReferrerLogHistory implements OnInit {


    @Input() public referrerId  : number;
    public cardId    : number;
    public accountId : number;

    public userHandler: UserHandler;
    public filter: FiltersHandler;

    public fieldHandler: FieldHandler;
    public historyLog: HistoryLogService;

    private _fields : Array<LogHistoryRow>;

    constructor(
      private referrerService: ReferrerService,
      private userService: UserService
    ) {}

    ngOnInit() {

        this.userHandler  = new UserHandler( this.userService );
        this.fieldHandler = new FieldHandler (  );
        this.filter       = new FiltersHandler ();

        let {  filterName, filterValue } = this.getFilterType();
        this.historyLog   = new HistoryLogService ( filterName, filterValue , this.filter, this.referrerService );
    }

    private getFilterType() {

        switch (true) {
            case typeof this.referrerId !== "undefined":
                return {filterName: 'referrerId', filterValue: this.referrerId};

            case typeof this.cardId !== "undefined":
                return {filterName: 'cardId', filterValue: this.cardId};

            case typeof this.accountId !== "undefined":
                return {filterName: 'accountId', filterValue: this.accountId};
        }

        return {filterName: '', filterValue: undefined }
    }

    get isClientFilter() : boolean {

        if ( !this.getFilterType().filterName ) {
            return true;
        }

        return typeof this.referrerId !== "undefined";
    }

    get fields() : Array<LogHistoryRow> {
        return this._fields;
    }

    get isFullView(): boolean {
        return typeof this.referrerId === "undefined" &&
               typeof this.accountId === "undefined" &&
               typeof this.cardId === "undefined" ;
    }

    public getClientName( field:LogHistoryRow ) : string {

        if ( field.clientName  )
            return field.clientName;

        if ( typeof field.referrerId !== "undefined")
            return 'Клиент с ID: '+field.referrerId;

        return "";

    }


    public getReferrerName( field:LogHistoryRow ) : string {

      if ( field.nickname  )
        return field.nickname;

      if ( typeof field.referrerId !== "undefined")
        return 'Реферер с ID: '+field.referrerId;

      return "";

    }

}
