<div class="text-muted step" data-testid="matrix.step">
    Шаг {{ position}}
</div>

<span *ngIf="editable">
    <div ngbDropdown>

        <div class="rule-name-container">
            <a class="item-toggle rule-name" data-testid="matrix.ruleName"
               href="javascript:void(0)" id="rules-dropdown" ngbDropdownToggle>
                <span [innerText]="getRuleName(rule.rule)"></span>
                <i class="fa fa-caret-down"></i>
            </a>
        </div>

        <ul class="dropdown-menu"
            ngbDropdownMenu
            aria-labelledby="rules-dropdown">
            <li *ngFor="let item of ruleTypesList | keyvalue" data-testid="matrix.select.ruleName"
            >
                <a href="javascript:void(0)"
                   ngbDropdownItem
                   (click)="setRule(item.value)"
                >
                    <span class="" [innerText]="getRuleName(item.value)"></span>
                </a>
            </li>
        </ul>

    </div>

    <div class="clearfix " *ngIf="isByPriority">
        <div ngbDropdown>
            <span class="text-muted f-s-8">Приоритет для </span>
            <div class="">
                <a href="javascript:void(0)" class="item-toggle f-s-8"
                   id="winner-dropdown"
                   data-testid="matrix.priority.groupName"
                   ngbDropdownToggle>
                    <span class="" [innerText]="getGroupNameById(rule.winId, true)"></span>
                    <i class="fa fa-caret-down"></i>
                </a>
            </div>

            <ul class="dropdown-menu"
                ngbDropdownMenu
                aria-labelledby="winner-dropdown">
                <li data-testid="matrix.priority.column.select.groupName">
                    <a href="javascript:void(0)"
                       ngbDropdownItem
                       [title]="getGroupNameById(groupColumn.id)"
                       (click)="setWinId(groupColumn.id)"
                       [innerText]="getGroupNameById(groupColumn.id, true)"></a>
                </li>

                <li data-testid="matrix.priority.row.select.groupName">
                    <a href="javascript:void(0)"
                       ngbDropdownItem
                       [title]="getGroupNameById(groupRow.id)"
                       (click)="setWinId(groupRow.id)"
                       [innerText]="getGroupNameById(groupRow.id, true)"></a>
                </li>


            </ul>
        </div>
    </div>
</span>

<span *ngIf="!editable">

    <div class="rule-name clearfix" [innerText]="getRuleName(rule.rule)"  data-testid="matrix.ruleName"></div>

     <div class="clearfix " *ngIf="isByPriority">

        <span class="text-muted f-s-8">Приоритет для </span>

        <span href="javascript:void(0)"  class=" f-s-8 font-weight">
            <span [innerText]="getGroupNameById(rule.winId, true)" data-testid="matrix.priority.groupName"></span>
        </span>

     </div>

</span>
