'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.reports.dynamic';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(   '<reports-dynamic-page [id]="id" ></reports-dynamic-page>', true)
        .controller(['$scope', '$stateParams',
          function($scope, $stateParams) {
            $scope.id = $stateParams.id;
          } ])
        .breadcrumbs( [ "Отчёты", "Динамические отчёты"]);

    }]);

