import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'select-locations-ng2'
})
export class SelectLocationsPopupDirective extends UpgradeComponent {

  @Output() callback: any;

  @Input() selectLocationsPopup: any;
  @Input() forPartner: any;
  @Input() checkOne: any;
  @Input() canCheckAll: any;
  @Input() linkOne: any;
  @Input() initSelectedItems: any;
  @Input() initPartner: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('selectLocationsButton', elementRef, injector);
  }
}

