import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import { getNg1Service } from '../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../shared/service/confirmAction/confirmAction.service";
import {ClientService} from "../../../shared/service/api/Client/Client.service";
import {ReferrerService} from "../../../shared/service/api/Referrer/Referrer.service";
import {mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {RawReferrerRecord} from "../../../api/ReferrerApi/models/raw-referrer-record";

@Component({
  selector: 'referrer-journal-page',
  templateUrl: './referrer-journal-page.component.html',
  providers: [
    StatesChangerService,
    ClientService,
    ReferrerService
  ]
})
export class ReferrerJournalPageComponent implements OnInit, OnChanges{

  @Input() id: number;

  public currentReferrer;
  public currentClient;

  private uiRouterState;
  public records : Array<RawReferrerRecord> = [];

  public isCorrectOn = false;

  public  OPER_TYPES = {
    credit :  "Начисление",
    debit  :  "Списание"
  }

  constructor(
    public statesChangerService : StatesChangerService,
    public confirmActionService : ConfirmActionService,
    public referrerService      : ReferrerService,
    public clientService        : ClientService,
  ) {

  }

  ngOnInit() {
    this.bindButtons();
    this.uiRouterState = getNg1Service('$state');
    this.referrerService.gerRecordsParams.setSort('date','desc')
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getReferrer();
    }
  }

  onSave() {

  }

  onCancel() {

  }

  onDelete() {
    this.referrerService.archive$(this.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      });
  }

  onPreview() {

  }

  onEdit() {

  }

  getReferrer() {

    this.referrerService
      .get$(this.id)
      .subscribe( result => {
        this.currentReferrer = result;
        this.resetRecordPage();
      })
  }

  nextRecordPage(){
    if (!this.currentReferrer?.account)
      return;

    this.referrerService.gerRecords$(
        Object.assign({}, this.referrerService.gerRecordsParams.params,
        {
          accountId: this.currentReferrer.account.id
        }
      )
    ).subscribe( result => {
      this.records = this.records.concat(result);
      this.referrerService.gerRecordsParams.next();

    }, () => {

      this.referrerService.gerRecordsParams.next();

    })
  }

  resetRecordPage() {
    this.referrerService.gerRecordsParams.reset();
    this.records = [];
    this.nextRecordPage();
  }

  refreshPage() {
    this.getReferrer();
  }

}
