<table  class="table  m-b-none m-t-n-xxs b-b m-b-n-xxs">
  <thead >
    <tr>
      <th
        *ngIf="isSearchState && isSku && !isEditMode"
      >

        <label class="i-checks mb-0" >
          <input
            type="checkbox"
            [checked]="isSelectedAll"
            (change)="toggleSelectedAll( !!$event?.currentTarget?.checked )"
          >
            <i></i>
        </label>

      </th>
      <th>
          <span>
            <ng-container *ngIf="isSku">SKU</ng-container>
            <ng-container *ngIf="isCategory">ID</ng-container>
          </span>
      </th>
      <th style="width: 100%;">
          <span>
            Название
          </span>
      </th>
      <th
        *ngIf="!isSearchState"
      >
      </th>
      <th
        *ngIf="isEditMode"
      >
      </th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let id of getList()" data-testid="goods.list">
    <td data-testid="choose(id)"
      *ngIf="isSearchState && isSku && !isEditMode"
    >

      <label class="i-checks mb-0" >
        <input
          type="checkbox"
          [checked]="selected.has(id?.id || id)"
          (change)="toggleSelected(id?.id || id, !!$event?.currentTarget?.checked)"
        >
        <i></i>
      </label>

    </td>
    <td data-testid="getId(id)">
      <search-highlight
        [model]="searchQuery"
        [text]="getId(id)"
      ></search-highlight>
    </td>
    <td data-testid="getName(id)">
      <div class="w-full text-center"
        *ngIf="isLoading(id)"
      >
        <i class="fa fa-spin fa-spinner text-warning"></i>
      </div>

      <ng-container
        *ngIf="!isLoading(id)"
      >

        <search-highlight
          [model]="searchQuery"
          [text]="getName(id)"
        ></search-highlight>

      </ng-container>

    </td>

    <td
      *ngIf="!isSearchState"
    >
      <button  class="btn btn-danger btn-sm m-t-n-xs m-b-n-xs" data-testid="removeSelected(id)"
        (click)="removeSelected(id)"
      >
        <i class="fa fa-trash"></i>
      </button>
    </td>

    <td
      *ngIf="isEditMode"
    >

      <button
        class="btn btn-xs btn-default"
        (click)="editClick(id); $event.stopPropagation()"
      >
        <i class="fa fa-pencil text-primary m-r-xs"></i>
        Редактировать
      </button>

    </td>


  </tr>

  </tbody>
</table>
