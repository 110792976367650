import {DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'loyaNumber'
})
export class LoyaNumberPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string) {}


  transform(value: number, fractionSize: any): string {

    let decPipe: any =  new DecimalPipe(this._locale);

    if ( fractionSize !== false && typeof fractionSize !== "undefined") {
      fractionSize = "1." + fractionSize + "-" + fractionSize
    }

    return decPipe.transform(value, fractionSize);

  }
}
