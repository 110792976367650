/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CampaignApiConfiguration as __Configuration } from '../campaign-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CheckCouponRuleIssueUntilResponseData } from '../models/check-coupon-rule-issue-until-response-data';
import { CheckCouponRuleIssueUntilRequest } from '../models/check-coupon-rule-issue-until-request';
import { CampaignProtocol } from '../models/campaign-protocol';
import { CampaignQueryParam } from '../models/campaign-query-param';
import { CommonCampaignTime } from '../models/common-campaign-time';
import { CampaignTime } from '../models/campaign-time';
import { CampaignStateGetResult } from '../models/campaign-state-get-result';
import { CampaignType } from '../models/campaign-type';
import { CampaignImage } from '../models/campaign-image';
import { CampaignTargetInfo } from '../models/campaign-target-info';
@Injectable({
  providedIn: 'root',
})
class CampaignApiService extends __BaseService {
  static readonly checkCouponRuleIssueUntilPath = '/campaign/checkCouponRuleIssueUntil';
  static readonly queryPath = '/campaign';
  static readonly createPath = '/campaign';
  static readonly getPath = '/campaign/{id}';
  static readonly updatePath = '/campaign/{id}';
  static readonly deletePath = '/campaign/{id}';
  static readonly markCampaignByIdWithListOfTagsPath = '/campaign/{id}/markByTags';
  static readonly reindexPath = '/campaign/reindex';
  static readonly campaigntimecountPath = '/campaigntimecount';
  static readonly getStatePath = '/campaignstate/{id}';
  static readonly setStatePath = '/campaignstate/{id}/{state}';
  static readonly queryImagesPath = '/campaignimage/{id}';
  static readonly saveImagePath = '/campaignimage/{id}/{imageId}';
  static readonly deleteImagePath = '/campaignimage/{id}/{imageId}';
  static readonly targetGroupInfoPath = '/campaigntargetinfo';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * проверка истечения выдачи купонов
   * @param params undefined
   */
  checkCouponRuleIssueUntilResponse(params: CheckCouponRuleIssueUntilRequest): __Observable<__StrictHttpResponse<Array<CheckCouponRuleIssueUntilResponseData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaign/checkCouponRuleIssueUntil`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CheckCouponRuleIssueUntilResponseData>>;
      })
    );
  }
  /**
   * проверка истечения выдачи купонов
   * @param params undefined
   */
  checkCouponRuleIssueUntil(params: CheckCouponRuleIssueUntilRequest): __Observable<Array<CheckCouponRuleIssueUntilResponseData>> {
    return this.checkCouponRuleIssueUntilResponse(params).pipe(
      __map(_r => _r.body as Array<CheckCouponRuleIssueUntilResponseData>)
    );
  }

  /**
   * Запрос кампаний
   * @param params Фильтры запроса
   * @return Данные кампаний
   */
  queryResponse(params?: CampaignQueryParam): __Observable<__StrictHttpResponse<Array<CampaignProtocol>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignProtocol>>;
      })
    );
  }
  /**
   * Запрос кампаний
   * @param params Фильтры запроса
   * @return Данные кампаний
   */
  query(params?: CampaignQueryParam): __Observable<Array<CampaignProtocol>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignProtocol>)
    );
  }

  /**
   * Создать новую кампанию
   * @param campaignProtocol Параметры создаваемой кампании
   * @return Созданная кампания
   */
  createResponse(campaignProtocol: CampaignProtocol): __Observable<__StrictHttpResponse<CampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = campaignProtocol;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignProtocol>;
      })
    );
  }
  /**
   * Создать новую кампанию
   * @param campaignProtocol Параметры создаваемой кампании
   * @return Созданная кампания
   */
  create(campaignProtocol: CampaignProtocol): __Observable<CampaignProtocol> {
    return this.createResponse(campaignProtocol).pipe(
      __map(_r => _r.body as CampaignProtocol)
    );
  }

  /**
   * Данные о кампании по ID
   * @param id ID кампании
   * @return Данные о кампании
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<CampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaign/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignProtocol>;
      })
    );
  }
  /**
   * Данные о кампании по ID
   * @param id ID кампании
   * @return Данные о кампании
   */
  get(id: number): __Observable<CampaignProtocol> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as CampaignProtocol)
    );
  }

  /**
   * Обновление данных кампании
   * @param params The `CampaignApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID кампании
   *
   * - `campaignProtocol`: Данные о кампании
   *
   * @return Данные кампании
   */
  updateResponse(params: CampaignApiService.UpdateParams): __Observable<__StrictHttpResponse<CampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.campaignProtocol;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaign/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignProtocol>;
      })
    );
  }
  /**
   * Обновление данных кампании
   * @param params The `CampaignApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID кампании
   *
   * - `campaignProtocol`: Данные о кампании
   *
   * @return Данные кампании
   */
  update(params: CampaignApiService.UpdateParams): __Observable<CampaignProtocol> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as CampaignProtocol)
    );
  }

  /**
   * Удаление кампании по ID
   * @param id ID кампании
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/campaign/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление кампании по ID
   * @param id ID кампании
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Пометить кампанию тегами
   *
   * Пометить кампанию тегами
   * @param params The `CampaignApiService.MarkCampaignByIdWithListOfTagsParams` containing the following parameters:
   *
   * - `listOfTags`: Список тегов кампании
   *
   * - `id`: ID кампании
   */
  markCampaignByIdWithListOfTagsResponse(params: CampaignApiService.MarkCampaignByIdWithListOfTagsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.listOfTags;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaign/${encodeURIComponent(String(params.id))}/markByTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Пометить кампанию тегами
   *
   * Пометить кампанию тегами
   * @param params The `CampaignApiService.MarkCampaignByIdWithListOfTagsParams` containing the following parameters:
   *
   * - `listOfTags`: Список тегов кампании
   *
   * - `id`: ID кампании
   */
  markCampaignByIdWithListOfTags(params: CampaignApiService.MarkCampaignByIdWithListOfTagsParams): __Observable<null> {
    return this.markCampaignByIdWithListOfTagsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Переиндексировать базу кампаний
   *
   * Переиндексировать базу кампаний
   */
  reindexResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaign/reindex`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Переиндексировать базу кампаний
   *
   * Переиндексировать базу кампаний
   */
  reindex(): __Observable<null> {
    return this.reindexResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param campaignTime Данные о времени работы кампании
   * @return Данные о времени работы кампании
   */
  campaigntimecountResponse(campaignTime: CampaignTime): __Observable<__StrictHttpResponse<CommonCampaignTime>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = campaignTime;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaigntimecount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommonCampaignTime>;
      })
    );
  }
  /**
   * @param campaignTime Данные о времени работы кампании
   * @return Данные о времени работы кампании
   */
  campaigntimecount(campaignTime: CampaignTime): __Observable<CommonCampaignTime> {
    return this.campaigntimecountResponse(campaignTime).pipe(
      __map(_r => _r.body as CommonCampaignTime)
    );
  }

  /**
   * Запросить статус кампании
   * @param id ID кампании
   * @return Данные о статусе кампании
   */
  getStateResponse(id: number): __Observable<__StrictHttpResponse<CampaignStateGetResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaignstate/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignStateGetResult>;
      })
    );
  }
  /**
   * Запросить статус кампании
   * @param id ID кампании
   * @return Данные о статусе кампании
   */
  getState(id: number): __Observable<CampaignStateGetResult> {
    return this.getStateResponse(id).pipe(
      __map(_r => _r.body as CampaignStateGetResult)
    );
  }

  /**
   * Выставить кампании новый статус
   * @param params The `CampaignApiService.SetStateParams` containing the following parameters:
   *
   * - `state`: Статус кампании
   *
   * - `id`: ID кампании
   *
   * @return Данные о кампании
   */
  setStateResponse(params: CampaignApiService.SetStateParams): __Observable<__StrictHttpResponse<CampaignType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaignstate/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.state))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignType>;
      })
    );
  }
  /**
   * Выставить кампании новый статус
   * @param params The `CampaignApiService.SetStateParams` containing the following parameters:
   *
   * - `state`: Статус кампании
   *
   * - `id`: ID кампании
   *
   * @return Данные о кампании
   */
  setState(params: CampaignApiService.SetStateParams): __Observable<CampaignType> {
    return this.setStateResponse(params).pipe(
      __map(_r => _r.body as CampaignType)
    );
  }

  /**
   * @param id ID кампании
   * @return Изображения по кампании
   */
  queryImagesResponse(id: number): __Observable<__StrictHttpResponse<Array<CampaignImage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaignimage/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignImage>>;
      })
    );
  }
  /**
   * @param id ID кампании
   * @return Изображения по кампании
   */
  queryImages(id: number): __Observable<Array<CampaignImage>> {
    return this.queryImagesResponse(id).pipe(
      __map(_r => _r.body as Array<CampaignImage>)
    );
  }

  /**
   * @param params The `CampaignApiService.SaveImageParams` containing the following parameters:
   *
   * - `imageType`: Тип картинки
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID кампании
   */
  saveImageResponse(params: CampaignApiService.SaveImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.imageType;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaignimage/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.imageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CampaignApiService.SaveImageParams` containing the following parameters:
   *
   * - `imageType`: Тип картинки
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID кампании
   */
  saveImage(params: CampaignApiService.SaveImageParams): __Observable<null> {
    return this.saveImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CampaignApiService.DeleteImageParams` containing the following parameters:
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID кампании
   */
  deleteImageResponse(params: CampaignApiService.DeleteImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/campaignimage/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.imageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CampaignApiService.DeleteImageParams` containing the following parameters:
   *
   * - `imageId`: Id картинки
   *
   * - `id`: ID кампании
   */
  deleteImage(params: CampaignApiService.DeleteImageParams): __Observable<null> {
    return this.deleteImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запросить информацию по аудитории кампании
   * @param params The `CampaignApiService.TargetGroupInfoParams` containing the following parameters:
   *
   * - `include.segmentId`: Сегмент, который входят в аудиторию кампании
   *
   * - `include.groupId`: Группа, которая входит в аудиторию кампании
   *
   * - `exclude.segmentId`: Сегмент, который не входит в аудиторию кампании
   *
   * - `exclude.groupIds`: Группа, которая не входит в аудиторию кампании
   *
   * @return Данные об аудитории кампании
   */
  targetGroupInfoResponse(params: CampaignApiService.TargetGroupInfoParams): __Observable<__StrictHttpResponse<CampaignTargetInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeSegmentId != null) __params = __params.set('include.segmentId', params.includeSegmentId.toString());
    if (params.includeGroupId != null) __params = __params.set('include.groupId', params.includeGroupId.toString());
    if (params.excludeSegmentId != null) __params = __params.set('exclude.segmentId', params.excludeSegmentId.toString());
    (params.excludeGroupIds || []).forEach(val => {if (val != null) __params = __params.append('exclude.groupIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaigntargetinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignTargetInfo>;
      })
    );
  }
  /**
   * Запросить информацию по аудитории кампании
   * @param params The `CampaignApiService.TargetGroupInfoParams` containing the following parameters:
   *
   * - `include.segmentId`: Сегмент, который входят в аудиторию кампании
   *
   * - `include.groupId`: Группа, которая входит в аудиторию кампании
   *
   * - `exclude.segmentId`: Сегмент, который не входит в аудиторию кампании
   *
   * - `exclude.groupIds`: Группа, которая не входит в аудиторию кампании
   *
   * @return Данные об аудитории кампании
   */
  targetGroupInfo(params: CampaignApiService.TargetGroupInfoParams): __Observable<CampaignTargetInfo> {
    return this.targetGroupInfoResponse(params).pipe(
      __map(_r => _r.body as CampaignTargetInfo)
    );
  }
}

module CampaignApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID кампании
     */
    id: number;

    /**
     * Данные о кампании
     */
    campaignProtocol: CampaignProtocol;
  }

  /**
   * Parameters for markCampaignByIdWithListOfTags
   */
  export interface MarkCampaignByIdWithListOfTagsParams {

    /**
     * Список тегов кампании
     */
    listOfTags: Array<string>;

    /**
     * ID кампании
     */
    id: number;
  }

  /**
   * Parameters for setState
   */
  export interface SetStateParams {

    /**
     * Статус кампании
     */
    state: string;

    /**
     * ID кампании
     */
    id: number;
  }

  /**
   * Parameters for saveImage
   */
  export interface SaveImageParams {

    /**
     * Тип картинки
     */
    imageType: Array<number>;

    /**
     * Id картинки
     */
    imageId: string;

    /**
     * ID кампании
     */
    id: number;
  }

  /**
   * Parameters for deleteImage
   */
  export interface DeleteImageParams {

    /**
     * Id картинки
     */
    imageId: string;

    /**
     * ID кампании
     */
    id: number;
  }

  /**
   * Parameters for targetGroupInfo
   */
  export interface TargetGroupInfoParams {

    /**
     * Сегмент, который входят в аудиторию кампании
     */
    includeSegmentId?: number;

    /**
     * Группа, которая входит в аудиторию кампании
     */
    includeGroupId?: number;

    /**
     * Сегмент, который не входит в аудиторию кампании
     */
    excludeSegmentId?: number;

    /**
     * Группа, которая не входит в аудиторию кампании
     */
    excludeGroupIds?: Array<number>;
  }
}

export { CampaignApiService }
