import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',

})
export class SearchFormComponent implements OnInit{

    // Флаги
    get isEnabled() {
      return this.model && !!this.model.$search;
    };

    // Внутренние переменные
    private _searchString : string;
    set searchString(value) {
      this.changeModel(value, this._searchString);
      this._searchString = value;
    }

    get searchString() {
      return this._searchString;
    }

    private changeOldVal;

    // Передаваемые параметры в компонент
    @Input()  public model       : any;
    @Input()  public placeholder : string;
    @Input()  public change    : any;


    public get isSearchEnabled() {
        return this.model &&
               this.model.$search &&
               this.model.$search.isEnabled;
    }

    public get isPending() {
        return this.model &&
            this.model.$search &&
            this.model.$search.isPending;
    }

    constructor( ) { }

    public ngOnInit() {

    //    this.$scope.$watch( ( _: any ) => this.model && this.model.$search, this.checkIsEnabled.bind(this) );
    //    this.$scope.$watch( ( _: any ) => this.searchString, this.changeModel.bind(this) );
    //    this.checkIsEnabled();

        if ( this.isEnabled ) {
            this.searchString = this.model.$search.value;
        }

    }

/*

    private checkIsEnabled = () => {
        this.isEnabled =  this.model && !!this.model.$search;
    }
*/

    public setSearch = () => {

        if ( !this.searchString ) {

            if ( this.model.$search.value ) {
                this.resetSearch();
            }

        } else {
            this.model.$search.value = this.searchString;
        }

    }

    private resetSearch = () => {
        this.model.$search.reset();
        this.searchString = this.model.$search.value;
    //    this.updateView();
    }

 /*   private updateView = () => {
        this.$scope.$apply();
    }*/

    public keyHook = function( e?: KeyboardEvent ) {

        if (!e) {
            e = <KeyboardEvent>window.event;
        }

        if (e.keyCode == 13) {
            this.setSearch();
        }

    };


    private changeModel = function( currentVal, oldVal ) {
      if ( this.change && ( typeof currentVal !== "undefined" && this.changeOldVal !== currentVal ) ) {
        this.changeOldVal = currentVal;
        this.searchString = this.change(currentVal, oldVal );
      }
    };

};
