'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";

let routePath   = module.name + '.system.modules.config';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider

            .route( routePath )
            .url('/:module')
            .template( '<system-modules-config-page [module]="module"></system-modules-config-page>', true)
            .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.module = $stateParams.module; } ])

            .breadcrumbs( [ "Настройки", "Компонент" ] )

            .permissions( {
              only: ['Administrator'],
            })

        }]);
