import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {
  CampaignLimitationApiService
} from "../../../../api/CampaignLimitationApi/services/campaign-limitation-api.service";
import {CampaignLimitation} from "../../../../api/CampaignLimitationApi/models/campaign-limitation";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {Observable as __Observable} from "rxjs/internal/Observable";
import {LIMITATION_TYPES_VALUES, PERIOD_TYPES_VALUES} from "./CampaignLimitations.values";
import {
  CampaignLimitationQueryParam
} from "../../../../api/CampaignLimitationApi/models/campaign-limitation-query-param";

@Injectable()
export class CampaignLimitationsService {

  constructor( private campaignLimitationApiService: CampaignLimitationApiService ) {
  }

  public getListParams = new ParamsHandler({
    search: {
      field: "search"
    },
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    }
  });

  public getList$(params: CampaignLimitationQueryParam): Observable<Array<CampaignLimitation>>{
    return this.campaignLimitationApiService.queryCampaignLimitation(params);
  }

  public get( id? :number ) : Observable<CampaignLimitation>{

    if (!id)
      return  new Observable(s => {
        s.next(<any>{
          "limitationType": LIMITATION_TYPES_VALUES.discount,
          "periodType": PERIOD_TYPES_VALUES.allTheTime,
          "links":[]
        });
        s.complete();
      });

    return this.campaignLimitationApiService.getCampaignLimitationById(id)
  }

  public save( campaignLimitation: CampaignLimitation ){

    if (typeof campaignLimitation.id !== "undefined") {
      return this.campaignLimitationApiService.updateCampaignLimitationById( {
        id :campaignLimitation.id,
        trigger: campaignLimitation
      });
    } else {
      return this.campaignLimitationApiService.createCampaignLimitation( campaignLimitation );
    }

  }

  public delete$( id: number ){

    return this.campaignLimitationApiService.deleteCampaignLimitationById(id);

  }

  public bindCampaign$( id: number, campaignIds:number[] ){

    return this.campaignLimitationApiService.bindToCampaignLimitation({
      id,
      campaignIds
    });

  }

  public unbindCampaign$( id: number, campaignIds:number[] ){

    return this.campaignLimitationApiService.unbindToCampaignLimitation({
      id,
      campaignIds
    });

  }


}
