
  <div class="fixed-tool-panel">


    <button type="button" class="btn btn-sm btn-addon btn-danger "
            [hidden]="!statesChangerService.hasButton('delete')"
            (click)="confirmActionService.confirm('Вы действительно хотите удалить бренд ' + currentGoodgroup.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
            data-testid="good.group.remove()" >

      <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
      <i class=" m-r-xs fa fa-remove"></i>
      <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
    </button>



    <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
            [hidden]="!statesChangerService.hasButton('edit')"
            (click)="statesChangerService.click('edit')"
            data-testid="good.group.edit()">

      <i class=" fa fa-pencil">
      </i>
      <span class="btn-name m-l-xs hidden-xxs" >
               {{ 'interface.buttons.edit' | transloco }}
            </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('save')"
            (click)="statesChangerService.click('save')"
            [disabled]="goodgroupFrom && goodgroupFrom.form && goodgroupFrom.form.invalid"
            data-testid="good.group.save()">

      <i class=" fa fa-check text-primary">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
    </button>

    <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf="statesChangerService.hasButton('cancel')"
            (click)="statesChangerService.click('cancel')"
            data-testid="good.group.cancel()">

      <i class=" fa fa-remove text-danger">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
    </button>


    <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
            *ngIf="statesChangerService.hasButton('preview')"
            (click)="statesChangerService.click('preview')"
            data-testid="good.group.preview()"

            [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
            [disabled]="goodgroupFrom && goodgroupFrom.form && goodgroupFrom.form.invalid"
    >
      <i class=" fa fa-eye text-success">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
    </button>

    <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
            *ngIf="statesChangerService.state === 'view'"
            [uiSref]="'^.index({partnerId:'+partnerId+'})'"
            data-testid="good.group.toList()"
    >

      <i class="fa fa-arrow-left"></i>
      <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
    </button>

  </div>

  <form name="goodgroupFrom" #goodgroupFrom="ngForm" novalidate autocomplete="off">

  <div class="card w-full no-back"
       *transloco="let t"
  >

    <div class="card-body"
      *ngIf="currentGoodgroup"
    >

        <grid-container>

          <div class="grid-item"
               [grid-item-form] = "'models.Goodgroup.id.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
        <span
          [innerHTML]="currentGoodgroup.id || '-'"
        ></span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'models.Goodgroup.name.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
            <span data-testid="currentGoodgroup.name"  *ngIf="!statesChangerService.isActive"
                  [innerText]="currentGoodgroup.name"
            ></span>

                <span class="editable-rec"
                      *ngIf="!!statesChangerService.isActive"
                >
              <input
                type="text"
                class="form-control form-control-edittable"
                [(ngModel)]="currentGoodgroup.name"
                name="name"
                autoComplete="off"
                [placeholder]="'models.Goodgroup.name.placeholder'  | transloco"
                [required]="true"
                [maxlength]="254"
              >
            </span>
          </div>

          <div class="grid-item"
               [grid-item-form] = "'models.Goodgroup.description.name'  | transloco"
               grid-item-form-icon = "fa-align-justify"
          >
         <span data-testid="currentGoodgroup.description" *ngIf="!statesChangerService.isActive"
               [innerText]="currentGoodgroup.description"
         ></span>

            <span class="editable-rec"
                  *ngIf="!!statesChangerService.isActive"
            >
            <textarea
              class="form-control form-control-edittable"
              [(ngModel)]="currentGoodgroup.description"
              name="description"
              [placeholder]="t('models.Goodgroup.description.placeholder')"
              [required]="true"
              [maxlength]="254"
              rows="3"
            ></textarea>
        </span>
          </div>

        </grid-container>

    </div>

  </div>





    <div class="card w-full no-back"  >
      <div class="card-header">
        <i class="fa fa-percent text-success m-r-sm"></i>
        Параметры списка
      </div>
      <div class="card-body "
           *ngIf="currentGoodgroup"
      >

        <grid-container>

          <div class="grid-item grid-item__form form-item"
               id="maxDiscountContainer"
          >
            <div class="grid-item__label">
                <i class="fa fa-align-justify">
                </i>
                <div>
                  <span>Максимальная скидка</span>
                  <div class="clear" *ngIf="currentGoodgroup.limits.maxDiscountType ==='fixprice'">(Мин. цена по скидкам)</div>
                </div>
              </div>

            <div class="grid-item__value">
              <span *ngIf="!statesChangerService.isActive" >
                <span  *ngIf="currentGoodgroup.limits.maxDiscount !== undefined" [innerText]="currentGoodgroup.limits.maxDiscount"></span>&nbsp;<span [innerText]="awardTypesDiscount[currentGoodgroup.limits.maxDiscountType]?.label"></span>
              </span>

              <div class="editable-rec input-group "  *ngIf="!!statesChangerService.isActive">

                <input
                        type="number"
                        class="form-control form-control-edittable "
                        [(ngModel)]="currentGoodgroup.limits.maxDiscount"
                        name="maxDiscount"
                        placeholder="Введите максимальную скидку"
                        [max]="!!currentGoodgroup.limits.maxDiscountType ? awardTypesDiscount[currentGoodgroup.limits.maxDiscountType].max : 100"
                        [min]="!!currentGoodgroup.limits.maxDiscountType ? awardTypesDiscount[currentGoodgroup.limits.maxDiscountType].min : 0"
                        [pattern]="!!currentGoodgroup.limits.maxDiscountType ? awardTypesDiscount[currentGoodgroup.limits.maxDiscountType].pattern : ''"
                >

                <div class="input-group-btn dropdown"
                     ngbDropdown
                     placement="bottom-right"
                >
                  <button type="button"
                          class="btn btn-default"
                          ngbDropdownToggle
                  >
                    <span [innerText]="awardTypesDiscount[currentGoodgroup.limits.maxDiscountType]?.label"></span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu " ngbDropdownMenu>
                    <li ngbDropdownItem class="no-padder"
                      *ngFor=" let item of awardTypesDiscount |  keyvalue">
                      <a href="javascript:void(0)"
                         (click)="currentGoodgroup.limits.maxDiscountType = item.key"
                      >
                        <span [innerText]="item.value.name"></span>
                        <span class="text-muted">
                            ( <span [innerText]="item.value.label"></span> )
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div class="grid-item grid-item__form form-item"
               id="maxAllowBonusContainer"
               *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: currentGoodgroup.limits.maxAllowBonus>=0 }"
          >
            <div class="grid-item__label">
              <i class="fa fa-align-justify">
              </i>
              <span> Максимальная оплата бонусами</span>
            </div>

            <div class="grid-item__value">
              <span *ngIf="!statesChangerService.isActive" >
                <span   *ngIf="currentGoodgroup.limits.maxAllowBonus !== undefined" [innerText]="currentGoodgroup.limits.maxAllowBonus"></span>&nbsp;<span [innerText]="awardTypes[currentGoodgroup.limits.maxAllowBonusType]?.label"></span>
              </span>

              <div class="editable-rec input-group "  *ngIf="!!statesChangerService.isActive">
                <input
                        type="number"
                        class="form-control form-control-edittable "
                        name="maxAllowBonus"
                        [(ngModel)]="currentGoodgroup.limits.maxAllowBonus"
                        placeholder="Введите максимальную оплата бонусами"
                        [max]="!!currentGoodgroup.limits.maxAllowBonusType ? awardTypes[currentGoodgroup.limits.maxAllowBonusType].max : 1000"
                        [min]="!!currentGoodgroup.limits.maxAllowBonusType ? awardTypes[currentGoodgroup.limits.maxAllowBonusType].min : 0"
                        [pattern]="!!currentGoodgroup.limits.maxAllowBonusType ? awardTypes[currentGoodgroup.limits.maxAllowBonusType].pattern : ''"
                >

                <div class="input-group-btn dropdown"
                     ngbDropdown
                     placement="bottom-right"
                >
                  <button type="button"
                          class="btn btn-default"
                          ngbDropdownToggle
                  >
                    <span [innerText]="awardTypes[currentGoodgroup.limits.maxAllowBonusType]?.label"></span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                    <li ngbDropdownItem class="no-padder"
                      *ngFor=" let item of awardTypes |  keyvalue">
                      <a href="javascript:void(0)"
                         (click)="currentGoodgroup.limits.maxAllowBonusType = item.key"
                      >
                        <span [innerText]="item.value.name"></span>
                        <span class="text-muted">
                            ( <span [innerText]="item.value.label"></span> )
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div class="grid-item grid-item__form form-item"
               id="maxAwardBonusContainer"
               *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '', permitOrIf: currentGoodgroup.limits.maxAwardBonus>=0 }"
          >

            <div class="grid-item__label">
              <i class="fa fa-align-justify">
              </i>
              <span> Максимальное начисление бонусов</span>
            </div>

            <div class="grid-item__value">
              <span *ngIf="!statesChangerService.isActive" >
                <span  *ngIf="currentGoodgroup.limits.maxAwardBonus !== undefined" [innerText]="currentGoodgroup.limits.maxAwardBonus"></span>&nbsp;<span [innerText]="awardTypes[currentGoodgroup.limits.maxAwardBonusType]?.label"></span>
              </span>

              <div class="editable-rec input-group "  *ngIf="!!statesChangerService.isActive">
                <input
                        type="number"
                        class="form-control form-control-edittable "
                        [(ngModel)]="currentGoodgroup.limits.maxAwardBonus"
                        name="maxAwardBonus"
                        placeholder="Введите максимальное начисление бонусов"
                        [max]="currentGoodgroup.limits.maxAwardBonusType ? awardTypes[currentGoodgroup.limits.maxAwardBonusType].max : 1000"
                        [min]="currentGoodgroup.limits.maxAwardBonusType ? awardTypes[currentGoodgroup.limits.maxAwardBonusType].min : 0"
                        [pattern]="currentGoodgroup.limits.maxAwardBonusType ? awardTypes[currentGoodgroup.limits.maxAwardBonusType].pattern : ''"
                >

                <div class="input-group-btn dropdown"
                     ngbDropdown
                     placement="bottom-right"
                >
                  <button type="button"
                          class="btn btn-default"
                          ngbDropdownToggle
                  >
                    <span [innerText]="awardTypes[currentGoodgroup.limits.maxAwardBonusType]?.label"></span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu pull-right" ngbDropdownMenu>
                    <li ngbDropdownItem class="no-padder"
                      *ngFor=" let item of awardTypes |  keyvalue">
                      <a href="javascript:void(0)"
                         (click)="currentGoodgroup.limits.maxAwardBonusType = item.key"
                      >
                        <span [innerText]="item.value.name"></span>
                        <span class="text-muted">
                            ( <span [innerText]="item.value.label"></span> )
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

        </grid-container>

      </div>

    </div>


    <div class="card w-full no-back"  >
      <div class="card-header">
        <i class="fa fa-clock-o text-success m-r-sm"></i>
        Параметры периодов действия
      </div>
      <div class="card-body "
           *ngIf="currentGoodgroup"
      >

        <div class=" padder wrappser-xs text-center "
             *ngIf="(!currentGoodgroup.periods || !currentGoodgroup.periods.length) && !statesChangerService.isActive">
          <span class="text-muted ">Заданных периодов нет</span>
          <div class="clearfix"></div>
        </div>

        <grid-container>

          <div class="grid-item grid-item__form grid-item_full"
               *ngFor="let period of currentGoodgroup.periods; let i = index;"
          >

            <div class="grid-item__label">
              <i class="fa fa-calendar"></i>
              Период {{ i + 1}}
            </div>

            <div class="grid-item__value" >
              <div *ngIf="statesChangerService.isActive"
                   class="hbox hbox-auto hbox-auto-sm  hbox-auto-xs"
                   ng-init="initPeriodDates(period)"
              >
                <div class="col p-r">
                  <div class="input-group   w-full-sm w-full-xs m-t-xs m-b-xs">

                    <span class="input-group-addon">с</span>

                    <input
                      type="date"
                      class="inline form-control "
                      [(ngModel)]="period.start"
                      [name]="'starPeriod'+i"
                      required="true"
                      [max]="period.stop"
                    >
                  </div>
                </div>

                <div class="col p-r">
                  <div class="input-group   w-full-sm w-full-xs m-t-xs m-b-xs">

                    <span class="input-group-addon">по</span>

                    <input
                      type="date"
                      class="inline form-control "
                      [(ngModel)]="period.stop"
                      [name]="'stopPeriod'+i"
                      required="true"
                      [min]="period.start"
                    >
                  </div>
                </div>

                <div class="col w-full text-center-sm text-center-xs ">
                  <button class="btn  btn-danger m-t-xs m-b-xs "
                          (click)="deletePeriod(currentGoodgroup, i)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>

              </div>

              <span *ngIf="!statesChangerService.isActive" >
                  c <span class="font-bold" [innerText]="period.start | loyaDate"></span> по <span class="font-bold" [innerText]="period.stop | loyaDate"></span>
              </span>
            </div>

          </div>

        </grid-container>

        <grid-container
          *ngIf="statesChangerService.isActive"
        >
          <div class="grid-item grid-item__form grid-item_full"
          >

            <div class="grid-item__label">
              <i class="fa fa-align-justify"
                 *ngIf="!currentGoodgroup.periods || !currentGoodgroup.periods.length"></i>
              <span  *ngIf="!currentGoodgroup.periods || !currentGoodgroup.periods.length">Добавление периода</span>
            </div>

            <div class="grid-item__value" >
              <button class="btn btn-sm btn-addon btn-success"
                      (click)="addPeriods(currentGoodgroup)"
              >
                <i class="fa fa-plus"></i>
                <span class="name">Добавить период</span>
              </button>
            </div>

          </div>

        </grid-container>


      </div>

    </div>

    <div class="card w-full no-back"  >
      <div class="card-header b-t">
        <i class="fa fa-tag text-success m-r-sm"></i>
        Списки
      </div>
      <div class="card-body no-padder"
           *ngIf="currentGoodgroup"
      >

        <div class="m-t padder-lg"
             *ngIf="statesChangerService.isActive"
        >
          <div class="row ">
            <div class="col col-auto m-b-sm " id="addSKUContainer">
              <select-goods-ng2
                buttonName  = "Добавить SKU/Категори/Бренды"
                buttonClass = "btn btn-success btn-addon btn-sm "
                [selectGoodsPopup]="onSelectBinded"
                [showCategories]="['sku','category','brand']"
                [forPartner]="partnerId"
                [initSelectedItems]="{ type: activeTab}"
              ></select-goods-ng2>

             <!-- <div class="text-center"  *ngIf="isEmptyAll">
                <span class="text-danger text-xs">
                  <i class="fa fa-warning me-1"></i> Списки не заданы
                </span>
                <input type="hidden" name="isEmptyAll" [(ngModel)]="emptyDummy" [required]="true" />
              </div> -->

            </div>
            <div class="col col-auto p-l"></div>
            <div class="col col-auto m-b-sm" *ngIf="activeTab==='sku'">
              <import-goodgroup-excel-ng2
                *ngIf="currentGoodgroup.id"
                [id]="currentGoodgroup.id"
                [partnerId]="partnerId"
                (callback)="callbackImportExcel()"
              ></import-goodgroup-excel-ng2>
            </div>
            <div class="col col-auto p-l"></div>
            <div class="col  text-right m-b-sm">
              <button type="button"
                      id="deleteSkuFromBrand"
                      class="btn btn-sm btn-addon btn-danger"
                      (click)="onDeleteList(activeTab)"
              >
                <i class="m-r-xs fa fa-remove"></i>
                <span class="btn-name  hidden-xxs" >
                  Удалить
                   <span *ngIf="getCurrentList()?.hasSelected"> выбранные </span>
                   <span *ngIf="!getCurrentList()?.hasSelected"> все </span>

                    <span *ngIf="activeTab==='sku'"> SKU </span>
                    <span *ngIf="activeTab==='category'"> категории </span>
                    <span *ngIf="activeTab==='brand'"> бренды </span>
                </span>
              </button>
            </div>
          </div>

        </div>

        <div class="padder m-t">
          <ul ngbNav #nav="ngbNav"  [(activeId)]="activeTab" class="nav-tabs">
            <li [ngbNavItem]="'sku'">
              <a ngbNavLink
              >
                SKU</a>
              <ng-template ngbNavContent>

                <table class="table m-b-none b-b m-t-n-xxs  "
                       infiniteScroll
                       [infiniteScrollDistance]="1"
                       [infiniteScrollThrottle]="50"
                       [scrollWindow]="false"
                       [infiniteScrollContainer]="'#app-container'"
                       [fromRoot]="true"
                       (scrolled)="getList('sku')"
                >
                  <thead>
                    <tr>
                      <th *ngIf="statesChangerService.isActive"></th>
                      <th>
                        <sort-view
                          *ngIf="!statesChangerService.isActive"
                          column="sku"
                          [params]="goodgroupLinkService.getListParams"
                          (onChange)="resetList(activeTab)"
                        >SKU</sort-view>
                        <span *ngIf="statesChangerService.isActive">SKU</span>
                      </th>
                      <th>
                        <sort-view
                          *ngIf="!statesChangerService.isActive"
                          column="name"
                          [params]="goodgroupLinkService.getListParams"
                          (onChange)="resetList(activeTab)"
                        >Название товара</sort-view>
                        <span *ngIf="statesChangerService.isActive">Название товара</span>
                      </th>
                      <th>
                        Количество
                      </th>
                      <th>Точки продаж</th>
                    </tr>
                  </thead>

                  <tbody>
                  <tr *ngFor="let item of skuList.items | callback: filterByDeleted; let index = index;">
                    <td *ngIf="statesChangerService.isActive" width="2%" align="center">
                      <div class="checkbox">
                        <label class="i-checks">
                          <input type="checkbox"
                                 [name]="'skuSelected' + index"
                                 [(ngModel)]="item.$isSelected"
                                 (change)="onChangeSelected($event, 'sku')"
                          >
                          <i></i>
                        </label>
                      </div>
                    </td>
                    <td>
                      <i class="{{item.dimension === 'weight' ? 'fa-balance-scale fa text-info m-r-xs' : 'fa-tag fa text-info m-r-xs'}}"></i>
                      <span  [innerText]="item.sku"></span>
                    </td>
                    <td [innerText]="item.name || ''"></td>
                    <td [innerText]="item.quantity"></td>
                    <td class=" table-big-link" >


                      <select-locations-ng2
                        (callback)="createLocation($event, item)"
                        [forPartner]="partnerId"
                        [initPartner]="partnerId"
                        [initSelectedItems]="[item.locationId]"
                        [checkOne]="true"
                        [canCheckAll]="true"
                      >
                        <a class=""
                           href="javascript:void(0)"
                           *ngIf="item.$isForAdd"
                           [innerText]="item.locationId === -1 ? 'Все ТП' : item.locationName"
                        ></a>
                      </select-locations-ng2>

                      <!--

                         select-locations-popup="$ctrl.createLocation(skuRow)"
                         for-partner="$ctrl.goodGroup.partnerId"
                         init-partner="$ctrl.goodGroup.partnerId"
                         init-selected-items="[skuRow.locationId]"
                         check-one="true"
                         can-check-all="true"

                       -->

                      <i class="fa fa-trash pointer fa-lg text-danger m-l-xxs"
                         *ngIf="item.$isForAdd && item.locationId !== -1"
                         (click)="item.locationId = -1"
                      ></i>

                      <span
                        *ngIf="!item.$isForAdd"
                        [ngClass]="{ 'text-muted': item.locationId === -1 }"
                        [innerText]="item.locationId === -1 ? 'Все ТП' : item.locationName"
                      ></span>


                    </td>
                  </tr>
                  </tbody>

                </table>

                <div class="clearfix padder wrapper text-center ng-scope"
                     *ngIf="!skuList?.items.length ">
                  <span class="text-muted  m-t">Список SKU не задан</span>
                </div>

              </ng-template>
            </li>
            <li [ngbNavItem]="'category'">
              <a ngbNavLink
              >
                Категории</a>
              <ng-template ngbNavContent>

                <table class="table m-b-none b-b m-t-n-xxs  "
                       infiniteScroll
                       [infiniteScrollDistance]="1"
                       [infiniteScrollThrottle]="50"
                       [scrollWindow]="false"
                       [infiniteScrollContainer]="'#app-container'"
                       [fromRoot]="true"
                       (scrolled)="getList('category')"
                >
                  <thead>
                    <tr>
                      <th *ngIf="statesChangerService.isActive"></th>
                      <th>
                        <sort-view
                          *ngIf="!statesChangerService.isActive"
                          column="name"
                          [params]="goodgroupLinkCategoriesService.getListParams"
                          (onChange)="resetList(activeTab)"
                        >Категория</sort-view>
                        <span *ngIf="statesChangerService.isActive">Название товара</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                  <tr *ngFor="let item of categoryList.items | callback: filterByDeleted; let index = index;">
                    <td *ngIf="statesChangerService.isActive" width="2%" align="center">
                      <div class="checkbox">
                        <label class="i-checks">
                          <input type="checkbox"
                                 [name]="'categorySelected' + index"
                                 [(ngModel)]="item.$isSelected"
                                 (change)="onChangeSelected($event, 'category')"
                          >
                          <i></i>
                        </label>
                      </div>
                    </td>
                    <td [innerText]="item.name || ''"></td>
                  </tr>
                  </tbody>

                </table>

                <div class="clearfix padder wrapper text-center ng-scope"
                     *ngIf="!categoryList?.items.length ">
                  <span class="text-muted  m-t">Список категорий не задан</span>
                </div>

              </ng-template>
            </li>
            <li [ngbNavItem]="'brand'">
              <a ngbNavLink
              >
                Бренды</a>
              <ng-template ngbNavContent>

                <table class="table m-b-none b-b m-t-n-xxs  "
                       infiniteScroll
                       [infiniteScrollDistance]="1"
                       [infiniteScrollThrottle]="50"
                       [scrollWindow]="false"
                       [infiniteScrollContainer]="'#app-container'"
                       [fromRoot]="true"
                       (scrolled)="getList('brand')"
                >
                  <thead>
                  <tr>
                    <th *ngIf="statesChangerService.isActive"></th>
                    <th>
                      <sort-view
                        *ngIf="!statesChangerService.isActive"
                        column="name"
                        [params]="goodgroupLinkBrandsService.getListParams"
                        (onChange)="resetList(activeTab)"
                      >Бренд</sort-view>
                      <span *ngIf="statesChangerService.isActive">Бренд</span>
                    </th>
                    <th><sort-view
                        *ngIf="!statesChangerService.isActive"
                        column="description"
                        [params]="goodgroupLinkBrandsService.getListParams"
                        (onChange)="resetList(activeTab)"
                      >Описание</sort-view>
                      <span *ngIf="statesChangerService.isActive">Описание</span>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr *ngFor="let item of brandList.items | callback: filterByDeleted; let index = index;">
                    <td *ngIf="statesChangerService.isActive" width="2%" align="center">
                      <div class="checkbox">
                        <label class="i-checks">
                          <input type="checkbox"
                                 [name]="'brandSelected' + index"
                                 [(ngModel)]="item.$isSelected"
                                 (change)="onChangeSelected($event, 'brand')"
                          >
                          <i></i>
                        </label>
                      </div>
                    </td>
                    <td [innerText]="item.name || ''"></td>
                    <td [innerText]="item.description || ''"></td>
                  </tr>
                  </tbody>

                </table>

                <div class="clearfix padder wrapper text-center ng-scope"
                     *ngIf="!brandList?.items.length ">
                  <span class="text-muted  m-t">Список брендов не задан</span>
                </div>

              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="b-t m-t-n-xxs" ></div>

      </div>

    </div>


  </form>



  <!--

  <div class="card no-back"
       *transloco="let t"
  >
    <div class="card-header">
      <i class="fa fa-rocket text-success m-r-sm"></i>
      {{ 'pages.Config.Directory.Brand.sku_table_header' | transloco }} {{selectedPartner.name}}
    </div>
    <div class="card-body no-padder"
    >

      <div class="wrapper padder-lg"
           *ngIf="statesChangerService.isActive"
      >
        <div class="row m-b-xs">
          <div class="col " id="addSKUContainer">
            <select-goods-ng2
              buttonName  = "Добавить SKU"
              buttonClass = "btn btn-success btn-addon btn-sm"
              [selectGoodsPopup]="onSelectSkuBinded"
              [showCategories]="['sku']"
              [forPartner]="selectedPartner.id"
            ></select-goods-ng2>
          </div>
          <div class="col text-right">
            <button type="button"
                    id="deleteSkuFromBrand"
                    class="btn btn-sm btn-addon btn-danger"
                    (click)="onDeleteBrands()"
            >
              <i class="m-r-xs fa fa-remove"></i>
              <span class="btn-name m-l-xs hidden-xxs" *ngIf="hasSelected">{{'pages.Config.Directory.Brand.delete_specific_button' | transloco}}</span>
              <span class="btn-name m-l-xs hidden-xxs" *ngIf="!hasSelected">{{'pages.Config.Directory.Brand.delete_all_button' | transloco}}</span>
            </button>
          </div>
        </div>
      </div>

      <table class="table m-b-none b-b m-t-n-xxs table-customers "
             infiniteScroll
             [infiniteScrollDistance]="1"
             [infiniteScrollThrottle]="50"
             [scrollWindow]="false"
             [infiniteScrollContainer]="'#app-container'"
             [fromRoot]="true"
             (scrolled)="getBrandList()"
      >
        <thead>
        <tr>
          <th data-sort-ignore="true"  *ngIf="statesChangerService.isActive"></th>
          <th data-sort-ignore="true" [innerText]=" 'models.BrandGoodLinkApi.sku.name' | transloco"></th>
          <th data-sort-ignore="true" [innerText]=" 'models.BrandGoodLinkApi.name.name' | transloco"></th>
        </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of brandGoodsList | callback: filterByDeleted "
          >
            <td class="w-xxs text-center"  *ngIf="statesChangerService.isActive">

              <div class="checkbox">
                <label class="i-checks">
                  <input type="checkbox"
                         [(ngModel)]="item.$isSelected"
                         (change)="onChangeSelected($event)"
                  >
                  <i></i>
                </label>
              </div>

            </td>
            <td>
              <span [innerText]="item.sku"></span>
            </td>
            <td>
              <i class="{{item.dimension === 'weight' ? 'fa-balance-scale fa text-info m-r-xs' : 'fa-tag fa text-info m-r-xs'}}"></i>
              <span [innerText]="item.name"></span>
            </td>

          </tr>
        </tbody>

      </table>


    </div>

  </div>
-->
