import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {MechanicService} from "../../service/api/Mechanic/Mechanic.service";
import {pipe} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ControlContainer, NgForm} from "@angular/forms";

@Component({
  selector: 'select-mechanic',
  templateUrl: './select-mechanic.component.html',
  styleUrls: ['./select-mechanic.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]

})
export class SelectMechanicComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();
  @Output() mechanic = new EventEmitter<number>();

  private initCurrentId;
  @Input() set currentId(value) {
    if (typeof this.initCurrentId === "undefined")
      this.initCurrentId = value
  }


  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() states: Array<string>;

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  drop    = 0;
  limit   = 50;
  isValid = true;

  digestSubscribe;

  constructor(
    private mechanicService: MechanicService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }

  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.mechanic.emit(this.items?.find( i => i.id === this.id) || undefined);

    this.validate();
  }

  pagerReset() {
    this.drop = 0;
  }


  pagerNext( searchText:string | boolean ) {

    let params : any = {
      pager: {
        drop  : this.drop,
        limit : this.limit
      }
    }

    if (searchText) {
      params.search = searchText;
    }

    if (this.states) {
      params.filter = params.filter || {};
      params.filter.states = this.states;
    }

    if (typeof this.initCurrentId !== "undefined") {
      params.selectedId = this.initCurrentId;
    }

    return this.mechanicService
                    .query$(params)
                    .pipe(
                      map( data => {
                        this.drop += data.length;
                        if ( typeof this.initCurrentId !== "undefined")
                          data.forEach( i => {
                            if (i.id === this.initCurrentId) {
                              i.name = i.name + ' (текущая)';
                              (<any>i).$isCurrent = true;
                            }
                          })

                        this.checkDigest();

                        return data;
                      })
                    );

  }


  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }

  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}
