/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FileUploadApiConfiguration as __Configuration } from '../file-upload-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FileUploadPreviewTable } from '../models/file-upload-preview-table';
import { FileUploadFilePager } from '../models/file-upload-file-pager';
import { FileUploadFileEncoding } from '../models/file-upload-file-encoding';
import { FileUploadResult } from '../models/file-upload-result';
import { FileUploadColumns } from '../models/file-upload-columns';
@Injectable({
  providedIn: 'root',
})
class FileUploadApiService extends __BaseService {
  static readonly previewPath = '/file/preview/{filename}';
  static readonly uploadPath = '/file/upload/{filename}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Предпросмотр файла для импорта
   *
   * Предпросмотр файла для импорта
   * @param params The `FileUploadApiService.PreviewParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   */
  previewResponse(params: FileUploadApiService.PreviewParams): __Observable<__StrictHttpResponse<{table?: FileUploadPreviewTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file/preview/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{table?: FileUploadPreviewTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}>;
      })
    );
  }
  /**
   * Предпросмотр файла для импорта
   *
   * Предпросмотр файла для импорта
   * @param params The `FileUploadApiService.PreviewParams` containing the following parameters:
   *
   * - `params`: Параметры предпросмотра
   *
   * - `filename`: Файл для предпросмотра
   */
  preview(params: FileUploadApiService.PreviewParams): __Observable<{table?: FileUploadPreviewTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string}> {
    return this.previewResponse(params).pipe(
      __map(_r => _r.body as {table?: FileUploadPreviewTable, error?: string, extension: 'csv' | 'xlsx', filename: string, filenameOrigin: string})
    );
  }

  /**
   * Загрузка списка бинов из файла
   *
   * Загрузка списка бинов из файла
   * @param params The `FileUploadApiService.UploadParams` containing the following parameters:
   *
   * - `params`: Параметеры загрузки из файла
   *
   * - `filename`: Файл для загрузки
   *
   * @return результат обработки файла по загрузке
   */
  uploadResponse(params: FileUploadApiService.UploadParams): __Observable<__StrictHttpResponse<FileUploadResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file/upload/${encodeURIComponent(String(params.filename))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileUploadResult>;
      })
    );
  }
  /**
   * Загрузка списка бинов из файла
   *
   * Загрузка списка бинов из файла
   * @param params The `FileUploadApiService.UploadParams` containing the following parameters:
   *
   * - `params`: Параметеры загрузки из файла
   *
   * - `filename`: Файл для загрузки
   *
   * @return результат обработки файла по загрузке
   */
  upload(params: FileUploadApiService.UploadParams): __Observable<FileUploadResult> {
    return this.uploadResponse(params).pipe(
      __map(_r => _r.body as FileUploadResult)
    );
  }
}

module FileUploadApiService {

  /**
   * Parameters for preview
   */
  export interface PreviewParams {

    /**
     * Параметры предпросмотра
     */
    params: {skip?: number, pager?: FileUploadFilePager, delimiter?: string, encoding?: FileUploadFileEncoding};

    /**
     * Файл для предпросмотра
     */
    filename: string;
  }

  /**
   * Parameters for upload
   */
  export interface UploadParams {

    /**
     * Параметеры загрузки из файла
     */
    params: {delimiter: string, encoding: FileUploadFileEncoding, pager?: FileUploadFilePager, skip: number, columns: Array<FileUploadColumns>};

    /**
     * Файл для загрузки
     */
    filename: string;
  }
}

export { FileUploadApiService }
