'use strict';

import module from 'frontendModule';

import moduleLoader from 'agModules/moduleLoader';
import 'model/Plugin';

import 'ng-infinite-scroll';
import {UserSettingsStorage} from "../../../../classes/user-settings-storage.service";

let routePath = module.name + '.system.modules.indexold';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/system.modules.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/system.modules.index" */ "./controllers"       ))

                .breadcrumbs( [ "Настройки", "Компоненты" ] )

                .resolve('_prepare', [ () => moduleLoader.prepare() ])

                .resolve('installedModules', ['Plugin', '$q', function (Plugin, $q) {
                    var defer = $q.defer();

                    $q.all([
                            Plugin.getComponent().installed().$promise,
                            Plugin.getMechanics().installed().$promise
                           ])
                        .then(function (result) {
                            defer.resolve([].concat(result[0], result[1], [ {
                                    description : "Служебный компонент",
                                    name        : "System",
                                    packageType : "component",
                                    version     : "1.0"
                                },{
                                    description : "Экспресс компонент",
                                    name        : "Common",
                                    packageType : "component",
                                    version     : "1.0"
                                }])
                            );
                        });

                    return defer.promise;


                }])

                .resolve('avalableModules', ['loyaPermissions', 'installedModules', function (loyaPermissions, installedModules) {

                    let modules = [
                        {
                            id: 'Behavior'
                        },
                        {
                            id: 'Address'
                        },
                        {
                            id: 'Communication'
                        },
                        {
                            id: 'Social'
                        },
                        {
                            id: 'PercentDiscount'
                        },
                        {
                            id: 'Designer'
                        },
                        {
                            id: 'System'
                        }

                    ];


                    if (loyaPermissions.byPath('modules.client.preferences').enabled) {
                        modules.push( { id: 'Preference' } );
                    }

                    if (loyaPermissions.byPath('modules.client.promotion').enabled) {
                        modules.push( { id: 'Promotion' } );
                    }

                    if (loyaPermissions.byPath('modules.client.accumulation').enabled) {
                        modules.push({
                            id: 'AccumulationProfile'
                        });
                    }

                    angular.forEach(installedModules, function (moduleItem) {

                        if ( [ 'Preference', 'Promotion', 'AccumulationProfile' ].indexOf(moduleItem.name) >= 0 )
                            return;

                        if (! modules.some( ( module : any ) => module.id === moduleItem.name ) ) {

                            modules.push({
                                id : moduleItem.name
                            });

                        }

                    });

                    return modules;

                }])

                .resolve('modulesList', ['avalableModules', 'installedModules', '$http', '$q', 'modulesStorage',
                    function (avalableModules, installedModules, $http, $q, modulesStorage) {

                        var installedModulesObj = {};

                        angular.forEach(installedModules, function (moduleItem) {

                            installedModulesObj[moduleItem.name] = moduleItem;

                        });

                        var promises = {},
                            result = [],
                            defer = $q.defer(),
                            resolveModule = function (id) {

                                if (promises[id]) {
                                    delete promises[id];
                                }

                                if (!Object.keys(promises).length) {
                                    defer.resolve(result);
                                }

                            };

                        angular.forEach(avalableModules, function (avalableModule) {

                            // promises[avalableModule.id] =

                            //   'system.modules.modules.manifest'


                            /*
                                moduleLoader.getModuleFile(avalableModule.id, "system/modules/modules/" + avalableModule.id + "/manifest.json!")
                                    .then(function (paramItem) {
            */

                            var paramItem = modulesStorage.get(avalableModule.id, 'system.modules.modules.manifest' );

                            if (!paramItem)
                                return;

                            if (installedModulesObj[paramItem.id]) {

                                paramItem.installedVersion = installedModulesObj[paramItem.id].version;
                                paramItem.installed = true;


                                if (paramItem.config) {
                                    paramItem.hasConfig = true;

                                    if (paramItem.config === true) {
                                        paramItem.staticConfigPage = true;
                                    }
                                }
                            }

                            paramItem.indexView =  modulesStorage.get(avalableModule.id, 'system.modules.modules.index' ) || '';

                            result.push(paramItem);
                            resolveModule(avalableModule.id);
                            /*
                                                                        }, function () {
                                                                            resolveModule(avalableModule.id);
                                                                        });*/

                        });

                        return defer.promise;
                    }
                ])

                .resolve('settingsList', [ 'Settings',  ( Settings : any ) => {
                    try {
                        return Settings.query().$promise;
                    } catch (e) {
                        console.log(e);
                    }

                } ])

                .resolve('filterSettingsConfig', [ () => {

                    return UserSettingsStorage
                            .get('system.modules.filter.isConfig')
                            .then( ( val : any ) => {
                                try {
                                    return JSON.parse(val);
                                } catch(e) {
                                    return val;
                                }
                            });

                } ])

                .permissions( {
                    only: ['Administrator'],

                })

        }]);
