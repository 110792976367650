/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for UserApi services
 */
@Injectable({
  providedIn: 'root',
})
export class UserApiConfiguration {
  rootUrl: string = location.origin;
}

export interface UserApiConfigurationInterface {
  rootUrl?: string;
}
