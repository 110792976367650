import {Injectable} from "@angular/core";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {LocationApiService} from "../../../../api/LocationApi/services/location-api.service";
import {LocationQueryParam} from "../../../../api/LocationApi/models/location-query-param";
import {catchError, map, switchMap} from "rxjs/operators";
import {of, throwError} from "rxjs";
import {LocationGroupLinkService} from "../LocationGroupLink/LocationGroupLink.service";
import {Observable as __Observable} from "rxjs/internal/Observable";
import {Location} from "../../../../api/LocationApi/models/location";

@Injectable({
  providedIn: "root"
})
export class LocationsService {

  constructor(
    private locationApiService:LocationApiService,
    private locationGroupLinkService:LocationGroupLinkService
  ) {
  }

  public getListParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'selectedId',
        currentValue: undefined
      },
      {
        field: 'filter.locationGroupId',
        id: 'locationGroupId',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public getList$(params: LocationQueryParam ): __Observable<Array<Location>> {
    return <any>this.locationApiService.queryLocations(params)
      .pipe(

        // TODO убрать
        switchMap(result => {

          if (
            typeof (<any>params)?.filter?.locationGroupId === "undefined" &&
            typeof (<any>params)?.filter?.groupId === "undefined"
          )
            return of(result);

          return this.locationGroupLinkService.getList$({
            groupId: (<any>params)?.filter?.locationGroupId || (<any>params)?.filter?.groupId
          })
            .pipe(
              map( links =>
                result.filter( item => links.some( l => l.merchantId === item.id))
              )
            )

        })

      )
  }

  getOrCreate$(id?) {

    if (typeof id !== "undefined" && id !=="") {
      return this.get$(id);
    }

    return of(<Location>{
      applyCashDiscount: "bySum"
    });

  }

  public get$(id) {
    return this.locationApiService.getLocationByById(id)
  }

  public update$( location: LocationApiService.UpdateLocationByIdParams['location'] ) {
    return this.locationApiService.updateLocationById({
      id: location.id,
      location
    })
  }

  public create$( location: Location) {
    return this.locationApiService.createLocation(location)
      .pipe(
        catchError(error => {

          let errorText = 'Ошибка создания точки продаж';

          if (
            error?.error &&  error?.error?.toLowerCase().indexOf('duplicate entry')>=0 &&
            error?.error?.toLowerCase().indexOf('location_code')>=0
          )
            errorText = 'Точка продаж с кодом ' + location?.code + ' уже существует';

          error.setError(errorText);
          return throwError(error);
        })
      );
  }

  public delete$( id: number) {
    return this.locationApiService.deleteLocationById(id)
  }

  public createOrUpdate$( location: Location) {
    if ( typeof location?.id !== "undefined")
      return this.update$(location)
    else
      return this.create$(location)
  }
}
