export enum RoleType {
    Administrator    = "Administrator",
    MarketingManager = "MarketingManager",
    GiftMarketingManager = "GiftMarketingManager",
    Operator         = "Operator",
    InputOperator    = "InputOperator",
    Infocenter       = "Infocenter",
    TopManager       = "TopManager",
    HeadOperator     = "HeadOperator",
    Api              = "Api"
};

export const rolesList = {
    [RoleType.Administrator]    : "Администратор",
    [RoleType.TopManager]       : "Топ-менеджер",
    [RoleType.MarketingManager] : "Менеджер",
    [RoleType.GiftMarketingManager] : "Менеджер ПС",
    [RoleType.Operator]         : "Оператор",
    [RoleType.InputOperator]    : "Оператор ввода",
    [RoleType.Infocenter]       : "Инфоцентр",
    [RoleType.Api]              : "Внешняя система",
    [RoleType.HeadOperator]     : "Главный оператор",
};
