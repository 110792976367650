/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { InformingTemplateApiConfiguration, InformingTemplateApiConfigurationInterface } from './informing-template-api-configuration';

import { InformingTemplateApiService } from './services/informing-template-api.service';

/**
 * Provider for all InformingTemplateApi services, plus InformingTemplateApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    InformingTemplateApiConfiguration,
    InformingTemplateApiService
  ],
})
export class InformingTemplateApiModule {
  static forRoot(customParams: InformingTemplateApiConfigurationInterface): ModuleWithProviders<InformingTemplateApiModule> {
    return {
      ngModule: InformingTemplateApiModule,
      providers: [
        {
          provide: InformingTemplateApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
