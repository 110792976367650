const CATEGORY_LIST_ID = 'categoryList';
const SKU_LIST_ID = 'skuList';
const GROUP_LIST_ID = 'groupsList';

const addIfNotExist = function (value: any, arr: any) {

    if (typeof arr === "undefined")
        arr = [];

    if ( arr.some( (i: number) => i === value ) )
        return false;

    arr.push(value);
    return arr;
};

export class FilterGoods {

    // Передаваемые параметры в компонент
    public model : any;
    public field : string;
    public forPartner: any;

    private cacheGoodsNames: any = {};
    private localPartnerId : number;

    public get isPending() {
        return this.model &&
            this.model.$filter &&
            this.model.$filter.isPending;
    }

    public get value():any {

        return ( this.model.$filter && this.model.$filter[this.field] ) || {};

    }

    public get type(): string {

        return ( this.model.$filter &&
                 this.model.$filter[this.field]  &&
                 this.model.$filter[this.field].listType ) || '';

    }
    public set type( value : string) {

        this.model.$filter[this.field] = this.model.$filter[this.field] || {};

        if ( this.model.$filter[this.field].listType !== value) {
            this.list = undefined;
        }

        this.model.$filter[this.field].listType = value;

    }

    private get listField(): string {

        switch( this.type ) {

            case 'sku'      : return SKU_LIST_ID;
            case 'category' : return CATEGORY_LIST_ID;
            case 'custom'   : return GROUP_LIST_ID;

            default : return '';
        }
    }

    public get list(): Array<any> {

        if ( !this.listField )
            return [];

        return this.value[ this.listField ] || [];

    }
    public set list( value: Array<any> ) {

        if ( !this.listField )
            return ;

        this.value[ this.listField ] = value;

    }


    public get isSelectedGoods() {

        if ( !this.type )
            return false;

        if ( this.type === "favourite") {
            return true;
        }

        return !!this.list.length;

    }

    public get countSelected() {
        return this.list.length;
    }

    static $inject = ['$scope','$timeout', 'GoodModel','CategoryModel','GoodGroupModel' ];

    constructor( private $scope   : any,
                 private $timeout : any,
                 private GoodModel  : any,
                 private CategoryModel : any,
                 private GoodGroupModel : any,
    ) { }

    public $onInit() {

        this.localPartnerId = this.value && this.value.partnerId;
        this.forPartner = this.localPartnerId;

        // Проверка на изменение партнёра
        this.$scope.$watch( ( _: any ) => this.forPartner , (newVal: number) => {

            if ( newVal !== this.localPartnerId ) {
                this.clearGoodList();

                this.value.partnerId = undefined;
                this.localPartnerId  = undefined;


            }

        });

    }

    public getName( id: number ): string {

        if ( this.cacheGoodsNames.hasOwnProperty(id) ) {
            return this.cacheGoodsNames[id];
        }

        if (!this.type) return undefined;

        switch( this.type ) {

            case "sku":
                this.GoodModel.get({
                    partnerId : this.value.partnerId,
                    sku       : id
                }, (result: any) => { this.cacheGoodsNames[id] = result.name; });
                break;

            case "category":
                this.CategoryModel.get({
                    partnerId  : this.value.partnerId,
                    categoryId : id
                }, (result: any) => { this.cacheGoodsNames[id] = result.name; });

                break;

            case "custom":
                this.GoodGroupModel.get({
                    'partnerId' : this.value.partnerId,
                    'id'        : id
                }, (result: any) => { this.cacheGoodsNames[id] = result.name; });
                break;

        }

        return this.cacheGoodsNames[id] = undefined;

    }

    public removeItem( id: number ) {

        this.list = this.list.filter( ( listId: number) =>  listId !== id );

    }

    public clearGoodList() {

        this.value.listType          = undefined;
        this.value[CATEGORY_LIST_ID] = undefined;
        this.value[SKU_LIST_ID]      = undefined;
        this.value[GROUP_LIST_ID]    = undefined;

        this.value.partnerId = undefined;

        this.cacheGoodsNames = {};
    };

    private updateView = () => {
        this.$scope.$apply();
    }

    public selectGoods(returnGoods : any ) {

        if (Array.isArray(returnGoods.list) && returnGoods.list.length === 0  && returnGoods.type !== "favourite") {
            return;
        }

        if ( this.type            !== returnGoods.type      ||
             this.localPartnerId !== returnGoods.partnerId  ||
             this.forPartner     !== returnGoods.partnerId
        ) {
            // Очистка
            this.clearGoodList();

            // Fix при закрытии диалога
            this.$timeout( () => {
                this.forPartner      = returnGoods.partnerId;
                this.value.partnerId = returnGoods.partnerId;
                this.localPartnerId  = returnGoods.partnerId;
            });

            this.type = returnGoods.type;
        }

        this.value[ this.listField ] = [];

        returnGoods.list.forEach( (item: any) => {
            addIfNotExist( item.id, this.list );
            this.cacheGoodsNames[item.id] = item.label;
        });

        this.value[ this.listField ] = this.value[ this.listField ];

    };

    private preparedCallback:any;
    public prepareCallback() {

        if (!this.preparedCallback)
            this.preparedCallback = this.selectGoods.bind(this);

        return this.preparedCallback;

    }

};
