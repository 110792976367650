/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { SocialApiConfiguration as __Configuration } from '../social-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RawSocial } from '../models/raw-social';
import { ClientBirthday } from '../models/client-birthday';
import { ClientBirthdayParams } from '../models/client-birthday-params';
import { Settings } from '../models/settings';
import { SocialStatus } from '../models/social-status';
import { PetType } from '../models/pet-type';
@Injectable({
  providedIn: 'root',
})
class SocialApiService extends __BaseService {
  static readonly collectBirthdaysByIdsPath = '/collectbirthdays';
  static readonly createProfilePath = '/{templateId}/{clientId}';
  static readonly getProfilePath = '/{templateId}/{clientId}';
  static readonly updateProfilePath = '/{templateId}/{clientId}';
  static readonly deleteProfilePath = '/{templateId}/{clientId}';
  static readonly collectBirthdaysPath = '/birthdays';
  static readonly getSettingsPath = '/settings';
  static readonly updateSettingsPath = '/settings';
  static readonly getSocialStatusPath = '/socialStatus';
  static readonly setSocialStatusPath = '/socialStatus';
  static readonly getPetTypesPath = '/petTypes';
  static readonly addPetTypesPath = '/petTypes';
  static readonly updatePetTypePath = '/petTypes/{petType}';
  static readonly deletePetTypePath = '/petTypes/{petType}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param ids undefined
   * @return др клиентов
   */
  collectBirthdaysByIdsResponse(ids: Array<number>): __Observable<__StrictHttpResponse<Array<{clientId: number, birthday?: string}>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/collectbirthdays`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<{clientId: number, birthday?: string}>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return др клиентов
   */
  collectBirthdaysByIds(ids: Array<number>): __Observable<Array<{clientId: number, birthday?: string}>> {
    return this.collectBirthdaysByIdsResponse(ids).pipe(
      __map(_r => _r.body as Array<{clientId: number, birthday?: string}>)
    );
  }

  /**
   * Создание профиля клиента
   * @param params The `SocialApiService.CreateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  createProfileResponse(params: SocialApiService.CreateProfileParams): __Observable<__StrictHttpResponse<RawSocial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.profile;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawSocial>;
      })
    );
  }
  /**
   * Создание профиля клиента
   * @param params The `SocialApiService.CreateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  createProfile(params: SocialApiService.CreateProfileParams): __Observable<RawSocial> {
    return this.createProfileResponse(params).pipe(
      __map(_r => _r.body as RawSocial)
    );
  }

  /**
   * Запрос профиля клиента
   * @param params The `SocialApiService.GetProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getProfileResponse(params: SocialApiService.GetProfileParams): __Observable<__StrictHttpResponse<RawSocial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawSocial>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param params The `SocialApiService.GetProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getProfile(params: SocialApiService.GetProfileParams): __Observable<RawSocial> {
    return this.getProfileResponse(params).pipe(
      __map(_r => _r.body as RawSocial)
    );
  }

  /**
   * Обновление профиля клиента
   * @param params The `SocialApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  updateProfileResponse(params: SocialApiService.UpdateProfileParams): __Observable<__StrictHttpResponse<RawSocial>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.profile;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawSocial>;
      })
    );
  }
  /**
   * Обновление профиля клиента
   * @param params The `SocialApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `profile`: Данные профиля клиента
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  updateProfile(params: SocialApiService.UpdateProfileParams): __Observable<RawSocial> {
    return this.updateProfileResponse(params).pipe(
      __map(_r => _r.body as RawSocial)
    );
  }

  /**
   * Удаление профиля клиента
   * @param params The `SocialApiService.DeleteProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   */
  deleteProfileResponse(params: SocialApiService.DeleteProfileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/${encodeURIComponent(String(params.templateId))}/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление профиля клиента
   * @param params The `SocialApiService.DeleteProfileParams` containing the following parameters:
   *
   * - `templateId`: ID профиля
   *
   * - `clientId`: ID клиента
   */
  deleteProfile(params: SocialApiService.DeleteProfileParams): __Observable<null> {
    return this.deleteProfileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запросить клиентов с днями рождения в диапазоне дат
   * @param params Параметры запроса
   * @return Список дней рождения клиента в укащанном диапазоне
   */
  collectBirthdaysResponse(params: ClientBirthdayParams): __Observable<__StrictHttpResponse<Array<ClientBirthday>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/birthdays`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientBirthday>>;
      })
    );
  }
  /**
   * Запросить клиентов с днями рождения в диапазоне дат
   * @param params Параметры запроса
   * @return Список дней рождения клиента в укащанном диапазоне
   */
  collectBirthdays(params: ClientBirthdayParams): __Observable<Array<ClientBirthday>> {
    return this.collectBirthdaysResponse(params).pipe(
      __map(_r => _r.body as Array<ClientBirthday>)
    );
  }

  /**
   * Запросить настройки профиля
   * @return Настройки профиля
   */
  getSettingsResponse(): __Observable<__StrictHttpResponse<Settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Settings>;
      })
    );
  }
  /**
   * Запросить настройки профиля
   * @return Настройки профиля
   */
  getSettings(): __Observable<Settings> {
    return this.getSettingsResponse().pipe(
      __map(_r => _r.body as Settings)
    );
  }

  /**
   * Обновить настройки профиля
   * @param settings Настройки профиля
   * @return Настройки профиля
   */
  updateSettingsResponse(settings: Settings): __Observable<__StrictHttpResponse<Settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = settings;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Settings>;
      })
    );
  }
  /**
   * Обновить настройки профиля
   * @param settings Настройки профиля
   * @return Настройки профиля
   */
  updateSettings(settings: Settings): __Observable<Settings> {
    return this.updateSettingsResponse(settings).pipe(
      __map(_r => _r.body as Settings)
    );
  }

  /**
   * Запрос списка социальных статусов
   * @return Список социальных статусов
   */
  getSocialStatusResponse(): __Observable<__StrictHttpResponse<Array<SocialStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/socialStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SocialStatus>>;
      })
    );
  }
  /**
   * Запрос списка социальных статусов
   * @return Список социальных статусов
   */
  getSocialStatus(): __Observable<Array<SocialStatus>> {
    return this.getSocialStatusResponse().pipe(
      __map(_r => _r.body as Array<SocialStatus>)
    );
  }

  /**
   * Обновление списка социальных статусов
   * @param statuses Список социальных статусов
   * @return Список социальных статусов
   */
  setSocialStatusResponse(statuses: Array<SocialStatus>): __Observable<__StrictHttpResponse<Array<SocialStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = statuses;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/socialStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SocialStatus>>;
      })
    );
  }
  /**
   * Обновление списка социальных статусов
   * @param statuses Список социальных статусов
   * @return Список социальных статусов
   */
  setSocialStatus(statuses: Array<SocialStatus>): __Observable<Array<SocialStatus>> {
    return this.setSocialStatusResponse(statuses).pipe(
      __map(_r => _r.body as Array<SocialStatus>)
    );
  }

  /**
   * Запрос списка типов животных
   * @return Список типов животных
   */
  getPetTypesResponse(): __Observable<__StrictHttpResponse<Array<PetType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/petTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PetType>>;
      })
    );
  }
  /**
   * Запрос списка типов животных
   * @return Список типов животных
   */
  getPetTypes(): __Observable<Array<PetType>> {
    return this.getPetTypesResponse().pipe(
      __map(_r => _r.body as Array<PetType>)
    );
  }

  /**
   * Добавить типы животных
   * @param petTypes Список типов животных
   * @return Список типов животных
   */
  addPetTypesResponse(petTypes: Array<PetType>): __Observable<__StrictHttpResponse<Array<PetType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = petTypes;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/petTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PetType>>;
      })
    );
  }
  /**
   * Добавить типы животных
   * @param petTypes Список типов животных
   * @return Список типов животных
   */
  addPetTypes(petTypes: Array<PetType>): __Observable<Array<PetType>> {
    return this.addPetTypesResponse(petTypes).pipe(
      __map(_r => _r.body as Array<PetType>)
    );
  }

  /**
   * Обновить тип животного
   * @param params The `SocialApiService.UpdatePetTypeParams` containing the following parameters:
   *
   * - `petType`:
   *
   * - `body`: Описание типа животного
   */
  updatePetTypeResponse(params: SocialApiService.UpdatePetTypeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/petTypes/${encodeURIComponent(String(params.petType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Обновить тип животного
   * @param params The `SocialApiService.UpdatePetTypeParams` containing the following parameters:
   *
   * - `petType`:
   *
   * - `body`: Описание типа животного
   */
  updatePetType(params: SocialApiService.UpdatePetTypeParams): __Observable<null> {
    return this.updatePetTypeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удалить тип животного
   * @param petType undefined
   */
  deletePetTypeResponse(petType: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/petTypes/${encodeURIComponent(String(petType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить тип животного
   * @param petType undefined
   */
  deletePetType(petType: string): __Observable<null> {
    return this.deletePetTypeResponse(petType).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SocialApiService {

  /**
   * Parameters for createProfile
   */
  export interface CreateProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * Данные профиля клиента
     */
    profile: RawSocial;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for getProfile
   */
  export interface GetProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for updateProfile
   */
  export interface UpdateProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * Данные профиля клиента
     */
    profile: RawSocial;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for deleteProfile
   */
  export interface DeleteProfileParams {

    /**
     * ID профиля
     */
    templateId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }

  /**
   * Parameters for updatePetType
   */
  export interface UpdatePetTypeParams {
    petType: string;

    /**
     * Описание типа животного
     */
    body: PetType;
  }
}

export { SocialApiService }
