
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../../shared/service/api/Settings/Settings.service";
import {
  CAMPAIGN_COMPLETENESS,
  CAMPAIGN_STATE_NAMES,
  CAMPAIGN_STATES_COLORS
} from "../../../../../../shared/service/api/Campaign/Campaign.values";
import {map} from "rxjs/operators";
import {PartnerService} from "../../../../../../shared/service/api/Partner/Partner.service";
import {CampaignProtocol} from "../../../../../../api/CampaignApi/models/campaign-protocol";
import {daysOfMonthDics, daysOfWeekDict} from "../../values/campaign.values";
import {
  DibsProfileByClientService
} from "../../../../../../shared/service/api/DibsProfileByClient/DibsProfileByClient.service";
import {CampaignRowViewComponent} from "../campaign-row-view/campaign-row-view.component";
import {checkExternalCode} from "../campaign-external-code/campaign-external-code.component";

@Component({
  selector: 'campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: [
    `./campaign-view.component.scss`
  ],
  providers: [
    CampaignService,
    LocationsDialogService,
    GroupService,
    SettingsService,
    PartnerService,
    DibsProfileByClientService,
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CampaignViewComponent implements OnInit, OnChanges {

  @Input() campaignItem: CampaignProtocol;
  @Input() campaignResources;

  @Output() anchorList = new EventEmitter()
  public anchorListDict = {
    anchorI1: 'Параметры',
    anchorI2: 'Информирование',
    anchorI3: 'Фактические показатели',
    anchorI4:  undefined,
    anchorI5: 'Плановые показатели',
    anchorI6: 'Внешняя система лояльности',
  }

  public loader = new LoaderHandlerClass();

  @ViewChildren(CampaignRowViewComponent)
  public campaignRowViewComponents: QueryList<CampaignRowViewComponent>;

  public CAMPAIGN_STATES_COLORS = CAMPAIGN_STATES_COLORS;
  public CAMPAIGN_STATE_NAMES = CAMPAIGN_STATE_NAMES;

  public preparedFields = new Map();
  public settingsObj:any = {
    viewIndex: 1,
    hideZeroResourceRow: true,
    hideZeroClientSellingRow: true,
  }

  public daysOfWeekDict = daysOfWeekDict;
  public daysOfMonthDics = daysOfMonthDics;
  public LOYA_CONFIG = LOYA_CONFIG;

  public communicationsConfig = {
    title: false,

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      backgroundColor: null,
      plotShadow: false,
      borderWidth: 0,
      type: 'pie',
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      width: 110,
      height: 110,
      style: {
        overflow: 'visible',
        margin: '0 auto'
      },

      skipClone: true
    },

    credits: {
      enabled: false
    },

    subtitle: {
      useHTML: true,
      text: '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        fontWeight:700,
        fontSize: '1.2em'
      },
      y: 5
    },

    legend: {
      enabled: false
    },

    tooltip:false,

    plotOptions: {
    /*  series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '90%',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        dataLabels: {
          enabled: false,
          crop: false,
          distance: '-10%',
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        }
      }*/
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: 'pointer',
        dataLabels: false
      }
    },
    colors: [LOYA_CONFIG.color.warning, LOYA_CONFIG.color.info],
    series: [
      {
        enableMouseTracking: false,
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: []
      }
    ]
  };
  public Highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;
  public easyPieConfigs: any = {
    email: false,
    sms: false,
    check: false,
    viber: false,
  }

  constructor(
    public campaignService: CampaignService,
    public locationsDialogService: LocationsDialogService,
    public groupService: GroupService,
    public settingsService: SettingsService,
    private partnerService: PartnerService,
    private dibsProfileByClientService: DibsProfileByClientService
  ) {
  }

  get isMechanicNotConfigured() {
    return this.campaignItem?.mechanic?.status=='template'
  }

  get isInformingNotConfigured() {
    return this.campaignItem?.informing?.status=='template'
  }

  get isCommunicationNotUse() {
    return !['usePrefer','useEmail','useSms','useViber'].some( i => !!this.campaignItem?.campaign?.communication[i] )
  }

  get isUseCheck() {
    return !!this.campaignItem?.campaign?.communication?.useCheck || !!this.campaignItem?.campaign?.communication?.usePos
  }

  get hasResourceGift() {
    let finded = this.campaignResources?.find(i => i?.resource === 'gift');
    return !!finded && !!finded?.details?.length;
  }

  get hasResourceRealCountGift() {
    let finded = this.campaignResources?.find(i => i?.resource === 'gift');
    return !!finded && !!finded?.details?.some(i => i.realCount > 0);
  }

  get resourceDetails() {
    return this.hasResourceGift ? this.campaignResources?.find(i => i?.resource === 'gift')?.details || [] : []
  }

  get totalOneClientReal() {
    return Number(this.campaignItem?.realValues?.clientCount) > 0 ? Number(this.campaignItem?.realValues?.receiptSelling) / Number(this.campaignItem?.realValues?.clientCount) : 0;
  }

  get sellingOneClientReal() {
    return Number(this.campaignItem?.realValues?.clientCount) > 0  ? Number(this.campaignItem?.realValues?.totalCosts) / Number(this.campaignItem?.realValues?.clientCount) : 0;
  }

  get isExternalLoyalty() {
    return this.externalLoyaltyFlag || checkExternalCode(this.campaignItem);
  }

  get externalLoyaltyFlag() {
    return !!this?.settingsObj?.externalLoyalty
  }

  get campaignRowIndex() {
    return this.settingsObj.viewIndex++;
  }

  get isEmptyCommunication() {
    return !this.getCommunicationType('email')?.filled &&
    !this.getCommunicationType('sms')?.filled &&
    !this.getCommunicationType('check')?.filled &&
    !this.getCommunicationType('viber')?.filled;
  }

  public getSystemSettings() {

    this.settingsService.getByKey$('externalLoyalty')
      .subscribe({
        next: r => {
          this.settingsObj.externalLoyalty = r
          if (!this.isExternalLoyalty) {
            this.anchorListDict.anchorI6 = undefined;
            this.anchorList.emit(this.anchorListDict)
          }
        }
      })
  }

  ngOnInit() {

    this.anchorList.emit(this.anchorListDict)

    this.calculateCharts();
    this.getSystemSettings();

    this.subscribeCampaignsRowChange();

  }

  ngOnChanges(changes) {
    if (changes['campaignItem']) {
      this.getDibs();
      this.settingsObj.viewIndex = 1
    }
  }

  getDibs() {
    if (!this.campaignItem?.campaign?.id)
      return;

    this.dibsProfileByClientService
      .getInfoByCampaign$(this.campaignItem?.campaign?.id)
      .subscribe({
        next: value => {
          if (!!value) {
            this.preparedFields.set('dibsCampaign', value)
            this.anchorListDict.anchorI4 = 'Информация о фишках';
            this.anchorList.emit(this.anchorListDict)
          } else {
            if ( this.preparedFields.has('dibsCampaign') ) {
              this.preparedFields.delete('dibsCampaign');
              this.anchorListDict.anchorI4 = undefined;
              this.anchorList.emit(this.anchorListDict)
            }
          }
        },
        error: error => {
          error?.stopPopupError();
          if ( this.preparedFields.has('dibsCampaign') ) {
            this.preparedFields.delete('dibsCampaign');
            this.anchorListDict.anchorI4 = undefined;
            this.anchorList.emit(this.anchorListDict)
          }
        }
      })
  }

  getPartnerById(id) {

    if ( this.preparedFields.has(id) ) {
      return this.preparedFields.get(id);
    }

    this.preparedFields.set(id, '');
    this.partnerService.get$(id)
      .pipe(
        this.loader.waitLoading('partner'),
      )
      .subscribe({
        next: p => {
          this.preparedFields.set(id, p?.name);
        }
      })

    return  '';
  }

  calculateCharts() {

    if (!this.campaignItem)
      return;


     ['sms', 'email'].forEach( index => {
       this.communicationsConfig.series[0].data.push({
         name: {'sms': 'SMS', 'email': 'E-mail'}[index],
         y: this.getCommunicationType(index).cost
       })
     })

    this.updateFlag = true;
  }

  getResourceType(type) {
    return this.campaignItem?.resource?.find( i => i.resource === type) || {
      campaignId: this.campaignItem?.campaign?.id,
      freezeValue:0,
      planValue:0,
      realValue:0,
      resource: type
    };
  }

  getPercentForType(type) {
    let values = this.getResourceType(type);

    return values.planValue !== 0 ? Number( ( values.realValue / values.planValue * 100 ).toFixed(2) ) : 0;
  }

  getCommunicationType(type) {
    return this.campaignItem?.communications?.find( i => i?.channelType === type) || {
      delivered: 0,
      channelType: type,
      doneByAlternative: 0,
      errors: 0,
      opened: 0,
      templateId: 0,
      cost: 0,
      worked: 0,
      campaignId: 0,
      filled: 0,
      send: 0
    };
  }

  getPercentForCommunication(type) {
    let values = this.getCommunicationType(type);

    let deliveredId = type === "check" ? 'worked' : 'delivered';

    return values.filled > 0 ? Number( ( values[deliveredId] / values.filled * 100 ).toFixed(2) ) : 100;
  }

  calcPercent(num = 0, denum = 0) {
    num = Number(num);
    denum = Number(denum);
    return  denum > 0 ? Number( ( num / denum * 100 ).toFixed(2) ) : 0;
  }

  getCampaignResource(type) {
    return this.campaignResources?.find(i => i?.resource === 'type') || undefined;
  }

  subscribeCampaignsRowChange() {
    setTimeout(() => {
      this.campaignRowViewComponents
        .changes
        .subscribe( {
          next: (result: QueryList<CampaignRowViewComponent>) => {
            result.forEach( (i, index) => {
              i.setIndex(index +1);
            })
          }
        })
    })
  }

}
