
export enum changeTypes {
    "referrerArchived" = "referrerArchived",
    "referrerCreated" = "referrerCreated",
    "referrerNicknameUpdated" = "referrerNicknameUpdated",
    "referrerPromoCodeUpdated" = "referrerPromoCodeUpdated",
    "referrerPromoCodeAndNicknameUpdated" = "referrerPromoCodeAndNicknameUpdated",

}

export const changeNames = {
    [ changeTypes.referrerCreated ]     : "Создание рефера",
    [ changeTypes.referrerNicknameUpdated ] : "Изменение никнейма",
    [ changeTypes.referrerPromoCodeUpdated ] : "Изменение промокода",
    [ changeTypes.referrerPromoCodeAndNicknameUpdated ] : "Изменение промокода и никнейма",
    [ changeTypes.referrerArchived ]    : "Архивирование рефера"
};

export const pathFields = {
  '/nickname': 'никнейм',
  '/promocode': 'промокод',
}
