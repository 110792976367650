'use strict';

import module from 'frontendModule';

import 'model/GoodGroupLinkModel/resource';

import importController from "./importController";
import * as importView from "./import.html";

export default module.component('importGoodgroupExcel', {
    template : `

                <button class="btn btn-warning w btn-sm db-new btn-hide btn-addon  "
                        ng-click="selectFile()"
                >
                    <i class="fa fa-upload text-white"></i>
                    Импорт из файла
                </button>

                <input type="file"
                       nv-file-select=""
                       uploader="uploader"
                       accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       class="none" id="excelUploader"/>

                <div class="clearfix m-t-sm "
                     ng-if="fileImportResult">

                    <div class="card b-a m-b-none">

                          <div class="card-header">
                          <span class="h5">Статус импорта из файла</span>

                          <div class="pull-right ">

                              <button class="btn btn-default btn-hide btn-sm btn-addon m-t-n-xs m-l-sm"
                                      ng-click="closeFileImportResult()"
                                      tabindex="-1"
                              >
                                  <i class=" fa fa-remove text-danger"></i>
                                  <span class="btn-name">Закрыть</span>
                              </button>

                          </div>


                      </div>

                      <div class="card-body">

                          <div class="clear">
                              <small class="block w-xl pull-left">
                                  <span class="text-muted">Успешно:</span>
                                  <span class="text-success font-bold" ng-bind="fileImportResult.success"></span>
                              </small>

                              <div class="block w-xl pull-left">
                                  <small class="">
                                      <span class="text-muted">Дубликатов:</span>
                                      <span class="text-primary font-bold" ng-bind="fileImportResult.duplicates"></span>
                                  </small>

                                  <a ng-href="{{fileImportResult.duplicatesCSV}}" target="_blank" class="m-l-xs">
                                      <i class="fa fa-external-link f-s-8"></i>
                                      Скачать
                                  </a>
                              </div>

                              <div class="block w-xl pull-left">
                                  <small class=" ">
                                      <span class="text-muted">С ошибкой:</span>
                                      <span class="text-danger-dk font-bold" ng-bind="fileImportResult.errors"></span>
                                  </small>
                                  <a ng-href="{{fileImportResult.errorsCSV}}" target="_blank" class="m-l-xs">
                                      <i class="fa fa-external-link f-s-8"></i>
                                      Скачать
                                  </a>
                              </div>

                          </div>

                    </div>
                    <div class="card-footer">
                        <div class="block">
                            <span class="text-muted">Всего:</span>
                            <span class="font-bold" ng-bind="fileImportResult.total"></span>
                        </div>
                    </div>
                </div>

                    </div>

                </div>

    `,
    controller  : class ImportGG {
      static $inject = ['$scope', '$state', '$sce', '$uibModal', '$q', '$filter', '$timeout', 'toaster', 'GoodGroupModel', 'PartnerModel', 'GoodGroupLinkModel', 'FileUploader', '$interval', 'ngCurrencyL10n'];

      public id;
      public partnerId;
      public callback;

      constructor( private $scope, $state, $sce, $uibModal, $q, $filter, $timeout, toaster, GoodGroupModel, PartnerModel,  GoodGroupLinkModel, FileUploader, $interval, ngCurrencyL10n) {

        $scope.activeTab = 0;
        $scope.initSelectedItems = {type: 'sku'};
        $scope.updateCounter = 0;

        $scope.searchGoodGroup = {
          input: '',
          filter: ''
        }

        var notSaveGroup = false;
        let locationsNames = {};

        $scope._t = new GoodGroupModel();
        $scope.goodgroups = false;
        $scope.partners = [];

        $scope.skuList = [];
        $scope.categoryList = [];
        $scope.brandList = [];

        //           $scope.skuList = false;
        //           $scope.backupSkuList = false;

        $scope.locWinSortGoodGroup = false;
        $scope.locWinSortSku = false;
        $scope.locWinSizeGoodGroup = 25;
        $scope.locWinSizeSku = 25;

        $scope.sorting = {
          field: '',
          order: 'asc'
        };


        $scope.selectedGoodGroup = false;
        $scope.backupGoodGroup = false;
        $scope.promiseAutoLoadGoodGroup = false;
        $scope.promiseAutoLoadSku = false;
        $scope.endSku = false;
        $scope.selectedPartner = false;
        $scope.selectedAll = false;
        $scope.selectedAllForDelete = false;

        $scope.currentEvent = 'removeSelected';



        $scope.fileImportResult = false;
        $scope.closeFileImportResult = function () {
          $scope.fileImportResult = false;
        };
        $scope.fileImportResultTemplate = $sce.trustAsHtml('<b style="color: red">I can</b> have <div class="label label-success">HTML</div> content');

        $scope.uploader = new FileUploader({
          url: '/files',
          autoUpload: true
        });

        $scope.loadScreen = function (id) {
          let screens = {
            LOAD: 'importFile/load.html',
            PROGRESS: 'importFile/progress.html',
            CONFIG: 'importFile/config.html',
            IMPORT: 'importFile/import.html',
          };

          $scope.currentScreen = screens[id];
        };

        $scope.uploader.onAfterAddingFile = function () {

          $scope.uploader.uploadAll();
          $scope.loadScreen('PROGRESS');

          let modalInstance = $uibModal.open({

            controller: importController,
            size: 'auto',
            scope: $scope,
            windowClass: 'modal-dialog-height modal-dialog-w1010',
            templateUrl: importView,

            backdrop: 'static',
            keyboard: false


          });

          modalInstance.result.then(function (result) {

            $scope.fileImportResult = result;
//                  $scope.eventToResetState();

            $scope.callback && $scope.callback() ;

            notSaveGroup = true;
          }, function (result) {
            if (angular.isObject(result) && result.error) {
              toaster.error('Ошибка импорта', result.error);
            }
          })

        };

        $scope.uploader.onSuccessItem = function (item, response, status, headers) {

          $scope.$broadcast('onSuccessItem', response);

        };

        $scope.uploader.onProgressItem = function (item, progress) {
          $scope.loadScreen('PROGRESS');
          $scope.progressPercent = progress;
        };

        $scope.selectFile = function () {
          angular.element('#excelUploader').val('');
          angular.element('#excelUploader').click();

        };


      }

      public $onInit() {
        this.$scope.id = this.id;
        this.$scope.partnerId = this.partnerId;
        this.$scope.callback = this.callback;
      }

    },

    bindings: {
        callback : '&',

        id: '=',
        partnerId: '=',
    }

} );

