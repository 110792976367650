'use strict';

import module from 'frontendModule';
let routePath   = module.name + '.config.directory.clientcategory.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(   '<clientcategory-view-page [id]="id"></clientcategory-view-page>', true)
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])

        .breadcrumbs( [ "Справочники", "Категории клиентов" , "Категория клиентов"] )

        .permissions( {
          permit: 'global.clients'
        });
    }])
;
