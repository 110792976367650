/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FileUploadApiConfiguration, FileUploadApiConfigurationInterface } from './file-upload-api-configuration';

import { FileUploadApiService } from './services/file-upload-api.service';

/**
 * Provider for all FileUploadApi services, plus FileUploadApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FileUploadApiConfiguration,
    FileUploadApiService
  ],
})
export class FileUploadApiModule {
  static forRoot(customParams: FileUploadApiConfigurationInterface): ModuleWithProviders<FileUploadApiModule> {
    return {
      ngModule: FileUploadApiModule,
      providers: [
        {
          provide: FileUploadApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
