/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for ClientChangelogApi services
 */
@Injectable({
  providedIn: 'root',
})
export class ClientChangelogApiConfiguration {
  rootUrl: string = location.origin;
}

export interface ClientChangelogApiConfigurationInterface {
  rootUrl?: string;
}
