'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';


import 'model/Plugin';
import 'model/InformingTemplateModel/resource';
import 'model/CampaignModel/resource';

let routePath = module.name + '.campaign.informing.config';

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:informingId/:campaignId')

                .template(template)
                .controller(controller)

                .resolve('currentTemplate', ['$stateParams', '$injector', 'InformingTemplateModel',
                    function ($stateParams, $injector, InformingTemplateModel) {

                        return InformingTemplateModel.getInformationTemplateById({id: $stateParams.informingId}).$promise;

                 }])

                .resolve('pluginItem', ['$stateParams', '$q', 'currentTemplate', 'Plugin',
                    function ($stateParams, $q, currentTemplate, Plugin) {

                        return $q( ( resolve : any ) => {

                            Plugin.getInforming().installed().$promise.then( ( list : any ) => {

                                let filtered = list.filter( ( item : any ) => item.name == currentTemplate.plugin);

                                if ( filtered.length ) {
                                    resolve( filtered[0] );
                                } else {
                                    resolve( {} );
                                }

                            });

                        });

                }])

                .resolve('campaignItem', ['$stateParams', 'CampaignModel', function ( $stateParams, CampaignModel ) {

                    return CampaignModel.get({id: $stateParams.campaignId }).$promise;

                }])

                .resolve('isReadOnly', ['campaignItem',    function (campaignItem ) {

                    return campaignItem.campaign.state !== 'draft';

                }])




                .breadcrumbs(["Кампании", "Шаблоны информирования"]);

            routeBuilderProvider
                .route( routePath + '.empty' )
                .template('', true)

        }]);
