import { anchorListItem } from './menuAnchors'

interface  ScopeDirective  extends ng.IScope {
    name ? : string,
    list ? : Array<anchorListItem>,
    id   ? : string
}

class MenuAnchorCtrl {

    static $inject = ['$scope', '$element'];

    private name: string;
    private item: anchorListItem;
    private anchorElement: HTMLAnchorElement;

    constructor(private $scope: ScopeDirective, private $element: ng.IRootElementService) {

        this.init();
        this.addAnchorToElement();
        this.pushToMenu();


        $scope.$on("$destroy", this.onDestroy.bind(this));
        $scope.$watch(() => this.chechVisible(), this.pushToMenu.bind(this) );
    }

    private init() {

        this.anchorElement = document.createElement('a');

        this.item = {
            name     : this.$scope.name || this.$element[0].innerText
        };

        this.item.id  = this.$scope.id  || 'r' + this.item.name.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0).toString();
    }

    private addAnchorToElement() {

        this.anchorElement.setAttribute('name', this.item.id);
        this.anchorElement.setAttribute('id', this.item.id);
        this.$element.prepend(this.anchorElement);
    }

    private pushToMenu( isPush = true) {

        if ( !Array.isArray( this.$scope.list ) )
            return;

        if ( isPush ) {
            if ( this.$scope.list.indexOf( this.item ) >=0 )
                return;

            this.$scope.list.push(this.item);

        } else {
            //Убрать из меню

            const index = this.$scope.list.indexOf( this.item );
            if ( index >= 0 ) {
                this.$scope.list.splice(index, 1);
            }
        }

    }

    private chechVisible() {
        return this.anchorElement.offsetParent !== null;
    }

    private onDestroy() {
        const index = this.$scope.list.indexOf(this.item);
        if ( index>=0) {
            this.$scope.list.splice(index, 1);
        }
    }

}


export default window['angular']
    .module('loya.builder')
    .directive('menuAnchor', [
        function () {

            return {
                restrict: 'A',
                controller: MenuAnchorCtrl,
                scope: {
                    name : '@menuAnchor',
                    list : '=menuAnchorList',
                    id   : '@menuAnchorId'
                }

            };

        }]);
