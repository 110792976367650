import {CouponKind, ReferralCouponWithBarcodeId, RewardWhatTypes} from "./CouponKind";
import {ExternalId, PosId, UniqueCouponGenId} from "../IssuingTypes/IssuingType";


export class ReferralCouponWithBarcode extends CouponKind {

    static id = ReferralCouponWithBarcodeId;

    protected prefix = true;

    public hasReferalReward = true;

    protected rewardWhatTypes: Array<RewardWhatTypes> = [
        RewardWhatTypes.BONUS,RewardWhatTypes.DISCOUNT
    ];

    get hasSameClientCount() : boolean {
        return this.issuingType.getId() === PosId || this.issuingType.getId() === ExternalId ;
    }

    get hasCanBeSold() : boolean {
        return this.issuingType.getId() === ExternalId;
    }

/*
    get hasUsable3rdParties() : boolean {
        return [PosId,ExternalId].indexOf( this.issuingType.getId() ) >=0 ;
    }*/

    get isUniqueCouponGen() : boolean {
        return this.issuingType.getId() === UniqueCouponGenId;
    }

    public $init() {

        this.ruleItem.couponRule.usingSettings.usable3rdParties = true;


        this.ruleItem.couponRule.reward = this.ruleItem.couponRule.reward || {};
        this.ruleItem.couponRule.reward.reward = this.ruleItem.couponRule.reward.reward || {};

        this.ruleItem.couponRule.reward.referalProgramData = this.ruleItem.couponRule.reward.referalProgramData || {};
        this.ruleItem.couponRule.reward.referalProgramData.referReward = this.ruleItem.couponRule.reward.referalProgramData.referReward ||
            {
                measure: "percent",
                value: ""
            };

        if (typeof this.ruleItem.couponRule.reward.referalProgramData.needRewardReferal === "undefined" ) {
            this.ruleItem.couponRule.reward.referalProgramData.needRewardReferal = false;
        }

        if (!this.ruleItem.couponRule.reward.referalProgramData.needRewardReferal) {
            this.ruleItem.couponRule.reward.onTotal = {rewType: "total", freqType: "all", valueOver: 0, value: "0"};
            this.ruleItem.couponRule.reward.reward = {what: "bonus", rewType: "total", id: 0, description: "", measure: "count", value: "0"};
        } else {

            this.ruleItem.couponRule.reward.onTotal =
                Object.assign({}, {rewType: "total", freqType: "all", valueOver: 0, value: "0"}, this.ruleItem.couponRule.reward.onTotal );

            this.ruleItem.couponRule.reward.reward =
                Object.assign({}, {what: "bonus", rewType: "total", id: 0, description: "", measure: "count", value: "0"}, this.ruleItem.couponRule.reward.reward ) ;

        }

    }

    public $destroy() {

        try {
            delete this.ruleItem.couponRule.reward.referalProgramData;
        } catch (e) {}

    }

    public $save() {

        this.ruleItem.couponRule.usingSettings = this.ruleItem.couponRule.usingSettings || {};
        this.ruleItem.couponRule.usingSettings.usable3rdParties = true;

        if (!this.ruleItem.couponRule.reward.referalProgramData.needRewardReferal) {

            this.ruleItem.couponRule.reward = {
                referalProgramData: this.ruleItem.couponRule.reward.referalProgramData,
                onTotal:{rewType: "total", freqType: "all", valueOver: 0, value: "0"},
                reward:{what: "bonus", rewType: "total", id: 0, description: "", measure: "count", value: "0"}
            };

            this.ruleItem.couponRule.reward.referalProgramData.needRewardReferal = false; // На случай undefinde
        }
    }

}
