import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from './../../class/utils/angularjs.utils'

@Component({
  selector: 'header-user',
  template: `

    <ul class="nav  navbar-right pull-right db-right no-pad-xs m-r-n current-user-menu">

        <li class="dropdown" ngbDropdown>
            <a href="javascript:void(0)" class=" dropdown-toggle" ngbDropdownToggle>
              <span class="thumb-sm avatar pull-right  m-b-n-lg m-l-sm  hidden-xs">
                <img  [src]=" currentUser.photoUrl || '/assets/img/user-default.jpg'"
                     style=" max-height: 40px; max-width: none; width: auto; ">
              </span>

                <span class="hidden-sm hidden-md" [innerText]="currentUser.name" data-testid="header.currentUser.name"></span> <b class="caret"></b>
            </a>

            <!-- dropdown -->
            <ul class="dropdown-menu  fadeInRight w-xxs" ngbDropdownMenu>

                <li>
                    <a href="javascript:void(0)" (click)="logout()">Выйти</a>
                </li>
            </ul>
            <!-- / dropdown -->
        </li>

    </ul>


`
  ,
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent  implements OnInit{

  @Input() currentUser: any;
  @Output() currentUserChange = new EventEmitter<string>();
  private UserModel: any;
  private loyaAuth: any;

  constructor() {
  }

  ngOnInit() {
    this.UserModel = getNg1Service('UserModel');
    this.loyaAuth = getNg1Service('loyaAuth');
    this.currentUser = this.UserModel.data();
    this.currentUserChange.emit(this.currentUser);
  }

  logout() {
    this.loyaAuth.logout();
  };

}
