import {Injectable} from "@angular/core";
import {NewsletterApiService} from "../../../../api/NewsletterApi/services/newsletter-api.service";
import {NewsletterQueryParam} from "../../../../api/NewsletterApi/models/newsletter-query-param";
import {NewsletterChannel} from "../../../../api/NewsletterApi/models/newsletter-channel";
import {NewsletterSourceType} from "../../../../api/NewsletterApi/models/newsletter-source-type";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {NewsletterTaskQueryParam} from "../../../../api/NewsletterApi/models/newsletter-task-query-param";
import {NewsletterTotalQueryParam} from "../../../../api/NewsletterApi/models/newsletter-total-query-param";
import {NewsletterTaskExternalState} from "../../../../api/NewsletterApi/models/newsletter-task-external-state";
import {NewsletterMessagesQueryParam} from "../../../../api/NewsletterApi/models/newsletter-messages-query-param";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NewsletterService {

  constructor(
    private newsletterApiService:NewsletterApiService,
    private httpClient: HttpClient,
  ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    filters: [
      {
        field: 'filter.channels',
        id: 'channels',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.sourceType',
        id: 'sourceType',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public detailsParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    }
  });

  public taskQueryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: 'filter.nameQuery',
      type: 'object',
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.campaignIds',
        id: 'campaignIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.channels',
        id: 'channels',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupIds',
        id: 'groupIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'state',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.triggerIds',
        id: 'triggerIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.sortingDateOrder',
        id: 'sortingDateOrder',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public taskTotalParams = new ParamsHandler({
    filters: [
      {
        field: 'filter.channels',
        id: 'channels',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'state',
        type: 'object',
        currentValue: undefined
      },
      {
         field: 'filter.triggerIds',
         id: 'triggerIds',
         type: 'object',
         currentValue: undefined
      },
      {
        field: 'filter.campaignIds',
        id: 'campaignIds',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public verificationParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    search: {
      field: 'filter.nameQuery',
      type: 'object',
    },
    filters: [
      {
        field: 'filter.campaignIds',
        id: 'campaignIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.channels',
        id: 'channels',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupIds',
        id: 'groupIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'state',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.taskIds',
        id: 'taskIds',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.sortingDateOrder',
        id: 'sortingDateOrder',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public verificationTotalParams = new ParamsHandler({
    search: {
      field: 'filter.nameQuery',
      type: 'object',
    },
    filters: [
      {
        field: 'filter.channels',
        id: 'channels',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'state',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public query$(params: NewsletterQueryParam) {
    return this.newsletterApiService.query(params);
  }

  public statistics$() {
    return this.newsletterApiService.statistics();
  }

  public details$(id: number, params: NewsletterTaskQueryParam) {
    return this.newsletterApiService.details({
      id,
      params
    });
  }

  public taskQuery$( params: NewsletterMessagesQueryParam ) {
    return this.newsletterApiService.taskQuery( params )
  }

  public taskTotal$(params: NewsletterTotalQueryParam) {
    return this.newsletterApiService.taskTotal(params)
  }

  public verificationQuery$( params: NewsletterMessagesQueryParam) {
    return this.newsletterApiService.verificationQuery(params)
  }

  public verificationTotal$( params: NewsletterTotalQueryParam) {
    return this.newsletterApiService.verificationTotal(params)
  }

  check$() {

    return this.httpClient.get('/newsletter/params/check')
      .pipe(
        catchError( (error ) => {

          error?.stopPopupError();

          switch (error?.status) {

            case 400 :
              return throwError('Ошибка подключения' );

            case 401:
              return throwError( 'Пользователя не существует' );

            case 404:
              return throwError('Нет соединения с сервером' );

            default:
              return throwError('Ошибка подключения');
          }

        })
      )
  }

}
