
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {BrandService} from "../../../../service/api/Brand/Brand.service";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {LocationsDialogGetterService} from "../../service/locations-dialog-getter.service";
import {LocationsService} from "../../../../service/api/Locations/Locations.service";


@Component({
  selector: 'locations-view',
  templateUrl: "./locations-view.component.html",
  styleUrls: ['./locations-view.component.scss' ],
  providers: [
    LocationsService
  ]
})
 export class LocationsViewComponent implements OnInit, OnChanges {

  @Input() partnerId;
  @Input() locationGroupId;

  @Input()  selected = new Set<number>();
  @Output() selectedChange = new EventEmitter();

  @Input()  selectedAll = false;
  @Output() selectedAllChange = new EventEmitter();

  @Input() max : number;
  @Input() selectOne : boolean;

  @Input()  searchQuery: string;

  @Input() canCheckAll = false;

  public locationsItems = [];
  public loader = new LoaderHandlerClass();
  public selectedAllCache;

  constructor(
    public locationsService: LocationsService,
    private locationsDialogGetterService: LocationsDialogGetterService,
  )
  {
  }

  ngOnInit() {
    this.locationsService.getListParams.reset();
    this.locationsService.getListParams.setFilterValue('partnerId', this.partnerId )
    this.getLocations();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['partnerId'] && !changes['partnerId'].isFirstChange()) {
      this.locationsService.getListParams.setFilterValue('partnerId', changes['partnerId'].currentValue )
      this.reset();
      return;
    }

    if (changes['locationGroupId'] && !changes['locationGroupId'].isFirstChange()) {
      this.locationsService.getListParams.setFilterValue('locationGroupId', changes['locationGroupId'].currentValue )
      this.resetList();
      return;
    }

    if (changes['searchQuery'] && !changes['searchQuery'].isFirstChange()) {
      this.locationsService.getListParams.search( !!this.searchQuery ? this.searchQuery : undefined );
      this.resetList();
      return;
    }

  }

  get isPageAllLocations() {
    return !this.locationGroupId ;
  }

  get isAllSelectedOnPageAllLocations() {
    return this.isPageAllLocations && this.selectedAll;
  }

  getLocations() {

    this.locationsDialogGetterService
      .getLocationsList(
        this.locationsService.getListParams.params
      )
      .pipe(
        this.loader.waitLoading('locationsItems')
      )
      .subscribe({
        next: result => {
          delete this.selectedAllCache;
          this.locationsItems = this.locationsItems.concat( result );
        },
        error: err => {
          console.log(err)
        }
      })

  }

  isSelected = (id) => {

    if ( this.isAllSelectedOnPageAllLocations )
      return this.selectedAll;

    return this.selected.has(id)
  }

  changeSelected(id, value) {

    if (value) {

      if (this.selectOne) {
        this.selected.clear();
      }

      this.selected.add(id);
      this.locationsDialogGetterService.getLocation(id,this.partnerId);

    } else {
      this.selected.delete(id)
    }

    if ( this.isAllSelectedOnPageAllLocations ){
      this.selected.clear();
      this.locationsItems.forEach( i => {
        if (i.id !== id)
          this.selected.add(i.id)
      });
    }

    this.selectedAll = false;
    this.selectedAllChange.emit(this.selectedAll);

    this.selectedChange.emit(this.selected);
    delete this.selectedAllCache;
  }

  isDisabled( id ) {

    if ( !Number.isInteger(this.max) ) {
      return false;
    }

    if ( this.selected.size >= this.max )
      return !this.isSelected(id);

    return false;

  };

  onScroll() {
    if (this.loader.isLoading('locationsItems'))
      return;

    this.locationsService.getListParams.next();
    this.getLocations()
  }

  resetList() {
    this.locationsItems = [];
    this.locationsService.getListParams.reset();
    this.getLocations();
  }

  reset() {
    this.selected.clear();
    this.selectedChange.emit(this.selected);
    this.resetList();
  }

  isSelectedAll() {

    if ( this.isAllSelectedOnPageAllLocations )
      return this.selectedAll;


    if( !this.locationsItems.length )
      return false;

    return !this.locationsItems.some( item => !this.selected.has(item.id) );
  }

  changeSelectedAll(enabled) {


    if (this.isPageAllLocations) {
      this.selected.clear();
      this.selectedChange.emit(this.selected);
      this.selectedAll = enabled
      this.selectedAllChange.emit(this.selectedAll);
      return
    }

    this.selectedAll = false;
    this.selectedAllChange.emit(this.selectedAll);

    this.locationsItems.forEach( item => {

      if (enabled) {
        this.selected.add(item.id);
      } else {
        this.selected.delete(item.id);
      }

    })

    delete this.selectedAllCache;
  }


}
