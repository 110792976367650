import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";

import "./campaigns-index-page.legacy";
import {CampaignService} from "../../../../shared/service/api/Campaign/Campaign.service";
import {
  CAMPAIGN_COMPLETENESS_VALUES, CAMPAIGN_COMPLETENESS_VALUES_STATES,
  CAMPAIGN_STATE_NAMES, CAMPAIGN_STATES,
  CAMPAIGN_STATES_COLORS
} from "../../../../shared/service/api/Campaign/Campaign.values";
import {Subscription} from "rxjs";


let listFilters;

@Component({
  selector: 'campaigns-index-page',
  templateUrl: './campaigns-index-page.component.html',
  styleUrls: [
    './campaigns-index-page.component.scss'
  ],
  providers: [
    CampaignService
  ]
})
export class CampaignsIndexPageComponent implements OnInit, OnDestroy{

  @Input() search;

  public loader = new LoaderHandlerClass();

  public campaignsList = [];

  public searchString;

  public CAMPAIGN_STATES_COLORS = CAMPAIGN_STATES_COLORS;
  public CAMPAIGN_STATE_NAMES = CAMPAIGN_STATE_NAMES;
  public CAMPAIGN_STATE_NAMES_FILTER = Object.assign({}, CAMPAIGN_STATE_NAMES);
  public CAMPAIGN_COMPLETENESS_VALUES = CAMPAIGN_COMPLETENESS_VALUES;
  public CAMPAIGN_COMPLETENESS_VALUES_STATES = CAMPAIGN_COMPLETENESS_VALUES_STATES;

  public isPreview = false;
  public selectedCampaign;

  public queryList: Subscription;

  constructor(
    public campaignService: CampaignService
  ) {
  }

  ngOnInit() {

    delete this.CAMPAIGN_STATE_NAMES_FILTER[CAMPAIGN_STATES.unsuspend];
    this.campaignService.queryParams.setFilterValue('states',
      Object.values(CAMPAIGN_STATES).filter( i =>
        i !== CAMPAIGN_STATES.archive && i !== CAMPAIGN_STATES.completed && i !== CAMPAIGN_STATES.unsuspend
      )
    );
    this.loadFilters();
    this.campaignService.queryParams.onParamChange.subscribe(value => {
      listFilters = this.campaignService.queryParams.params;
    })

    this.campaignService.queryParams.reset()
    this.getList(this.campaignService.queryParams.params);
  }

  loadFilters() {
    if (!listFilters)
      return;

    if (listFilters?.filter) {
      ['states','fromDate','tillDate', 'datesKind']
        .filter(key => !!listFilters?.filter?.[key])
        .forEach(key => {
          this.campaignService.queryParams.setFilterValue(key, listFilters?.filter[key]);
        });
    }

    if (!!listFilters?.search) {
      this.campaignService.queryParams.search(listFilters.search);
      this.searchString = listFilters.search;
    }

/*

    if (!!listFilters?.pager) {
      this.campaignService.queryParams.setPager(( listFilters?.pager?.drop || 0 ) + ( listFilters?.pager?.limit || 100 ));
    }
*/

  }

  getList(params) {
    if (this.queryList?.unsubscribe) {
      this.queryList?.unsubscribe()
    }

    this.queryList = <any>this.campaignService.query$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.campaignsList = this.campaignsList.concat(result);
      });
  }

  onScroll() {

    if ( this.loader.isLoading('list') ) {
      return ;
    }

    this.campaignService.queryParams.next();
    this.getList(this.campaignService.queryParams.params)
  }

  reset() {
    this.campaignService.queryParams.reset();
    this.campaignsList=[];
    this.getList(this.campaignService.queryParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.campaignService.queryParams.search(this.searchString);
    this.reset();
  }

  changeFilter( $event ) {
    this.campaignService.queryParams.setFilterValue( $event.target.name, $event.target.value);
    this.reset();
  }

  getCompletenessMessage(state, completeness) {
    if (completeness === "finished")
      return "";

    return ( CAMPAIGN_COMPLETENESS_VALUES_STATES[state] && CAMPAIGN_COMPLETENESS_VALUES_STATES[state][completeness] ) ? CAMPAIGN_COMPLETENESS_VALUES_STATES[state][completeness] : '';
  };

  onFilterGoods($event) {

    this.campaignService.queryParams.setFilterValue('filter.skuList', undefined);
    this.campaignService.queryParams.setFilterValue('filter.categoryList', undefined);
    this.campaignService.queryParams.setFilterValue('filter.groupsList', undefined);

    this.campaignService.queryParams.setFilterValue('filter.partnerId', $event?.list?.[0]?.partnerId || undefined );

    switch($event?.type) {
      case 'sku':
        this.campaignService.queryParams.setFilterValue('filter.skuList', $event?.list?.map(i => i.id) || undefined );
        break;
      case 'category':
        this.campaignService.queryParams.setFilterValue('filter.categoryList', $event?.list?.map(i => i.id) || undefined );
        break;
      case 'custom':
        this.campaignService.queryParams.setFilterValue('filter.groupsList', $event?.list?.map(i => i.id) || undefined);
         break;
    }

    this.reset();
  }

  onFilterLocations($event) {
    this.campaignService.queryParams.setFilterValue('filter.locations', $event || undefined);
    this.reset();
  }

  onFilterDate($event) {

    if (!$event?.datesKind) {
      let [from, to, datesKind] = [
        this.campaignService.queryParams.getFilterValue('fromDate'),
        this.campaignService.queryParams.getFilterValue('tillDate'),
        this.campaignService.queryParams.getFilterValue('datesKind')
      ]
      this.campaignService.queryParams.setFilterValue('fromDate', undefined);
      this.campaignService.queryParams.setFilterValue('tillDate', undefined);
      this.campaignService.queryParams.setFilterValue('datesKind', undefined);
      if (!!from || !!to || !!datesKind )
        this.reset();
      return
    }

    this.campaignService.queryParams.setFilterValue('datesKind', $event.datesKind);
    $event.from && this.campaignService.queryParams.setFilterValue('fromDate', $event.from + 'T00:00:00.000Z');
    $event.to && this.campaignService.queryParams.setFilterValue('tillDate', $event.to  + 'T00:00:00.000Z');

    if (!!$event.from || !!$event.to )
      this.reset();
  }

  changePreview() {

    this.isPreview = !this.isPreview;
    if ( !this.selectedCampaign ) {
      this.selectedCampaign = this.campaignsList?.[0];
    }

  }

  ngOnDestroy() {
    listFilters = this.campaignService.queryParams.params;
  }
}


