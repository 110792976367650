'use strict';

import 'model/EditionModel/resource';
import * as confirmTemplate from "./views/confirm.html";

export default ['$scope', 'certificateItem', 'EditionModel', '$state', 'toaster', 'partnerList', '$timeout',  'UserModel', 'PartnerModel', 'GoodModel', 'GiftCertificateModel', '$uibModal', 'unlimitedGenerate',
      function ($scope, certificateItem, EditionModel, $state, toaster, partnerList, $timeout,   UserModel, PartnerModel, GoodModel, GiftCertificateModel, $uibModal, unlimitedGenerate) {

        $scope._t = new GiftCertificateModel();
        $scope.certificateItem = certificateItem;
        $scope.partnerList = partnerList;
        $scope.unlimitedGenerate = unlimitedGenerate;

        let backupItem;
        var formStates = {

          draft: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.certificateItem);
                $scope.setFormState('edit');
              },
              'remove': function () {

                return EditionModel.delete({id: $scope.certificateItem.id}, function() {
                  $state.go('^.index');
                }).$promise;

              },
              inProgress: function() {
                $scope.setState('inProgress')
              }
            },
            activeForm: false
          },

          inProgress: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.certificateItem);
                $scope.setFormState('edit');
              },
              draft: function() {
                $scope.setState('draft')
              },
              active: function() {
                $scope.setState('active')
              }
            },
            activeForm: false
          },

          active: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.certificateItem);
                $scope.setFormState('edit');
              },
              inProgress: function() {
                $scope.setState('inProgress')
              },
              archive: function() {
                $scope.setState('archive')
              }
            },
            activeForm: false
          },

          archive: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.certificateItem);
                $scope.setFormState('edit');
              },
              active: function() {
                $scope.setState('active')
              }
            },
            activeForm: false
          },

          edit: {
            buttons: {

              'save': function () {

                  var modalInstance = $uibModal.open({
                    templateUrl : confirmTemplate,
                    scope: $scope.$new(),
                    controller  : [ '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {

                      $scope.tomorrow = new Date();
                      $scope.tomorrow.setDate( $scope.tomorrow.getDate() + 1 );

                      $scope.okEvent = function() {
                        $uibModalInstance.close( $scope.reasonCoupon );
                      };

                      $scope.cancelEvent = function() {
                        $uibModalInstance.dismiss();
                      };

                    }],
                    size : ''
                  });

                  modalInstance.result.then(function ( reason ) {

                    GiftCertificateModel.create($scope.certificateItem,
                      function( createdEdition ) {
                        $scope.certificateItem = new EditionModel(createdEdition);
                        $state.go('^.index');
                      }, function (data) {
                        toaster.error( 'Ошибка сохранения', data.data.text || data.data  );
                      }
                    );

                  });



              },

              'cancel': function () {

                if ( angular.isUndefined( $scope.certificateItem.id ) ) {
                    $state.go('^.index');
                } else {
                  if (backupItem) {
                    $scope.certificateItem = angular.copy(backupItem);
                  }

                  $scope.setFormState($scope.certificateItem.status);
                }

              }
            },
            activeForm: true

          }

        };


        $scope.editionsList = EditionModel.editionList({
          'pager.limit':9999,
          'filter.status': 'active',
          'sorting.field': 'id',
          'sorting.order': 'asc'
        });

        $scope.statuses = {
          'draft'      : "Черновик",
          'inProgress' : "В подготовке",
          'active'     : "Активный",
          'archive'    : "Архивный"
        };

        $scope.setFormState = function (state) {

          if (formStates[state] !== undefined) {
            let prevState = $scope.curState ? $scope.curState.stateName : '';
            $scope.curState = formStates[state];
            $scope.curState.prevState = prevState;
            $scope.curState.stateName = state;


          } else
            $scope.curState = false;

        };

        $scope.setState = function(state) {

          EditionModel.changeStatus({id: $scope.certificateItem.id}, { newStatus: state }, function( edition ) {
            $state.go('^.view', {id: edition.id}, {reload: true});
          });

        }

        $scope.setFormState( angular.isUndefined( $scope.certificateItem.id )  ? 'edit'  : $scope.certificateItem.status  );

        $scope.fixedOptions = [{
            name: 'Фиксированный номинал',
            id: true
          },{
            name : 'Произвольный номинал',
            id: false
          }
        ];

        $scope.fixedName = function(value) {
          value = !!value;
          return $scope.fixedOptions.filter( i=> i.id === value )[0].name;
        }

        $scope.$watch('certificateItem.editionId', (newVal, oldVal) => {

          if (typeof newVal === "undefined" ) return;

          if ( newVal && oldVal && oldVal !== newVal) {
            $scope.certificateItem.firstBarcode = undefined;
          }

          $scope.currentEdition = undefined;
          $scope.currentEditionSettings = undefined;
          EditionModel.get({id: newVal})
                      .$promise.then( value => {
                        $scope.currentEdition = value;
                        $scope.currentEditionSettings = {
                          minNumber: parseInt( value.minNumber ),
                          maxNumber: parseInt( value.minNumber ) + value.copiesQuantity -1,
                          ratingMax: value.ratingValue
                        }

                        if ($scope.currentEdition.fixed) {
                          $scope.certificateItem.amount = value.ratingValue;
                        }

                    });


        });

        $scope.numberIntervalValidator = function(value) {

          if ( typeof value === "undefined" || typeof $scope.currentEditionSettings === "undefined" ) return true;

          value = parseInt(value);
          return $scope.currentEditionSettings.minNumber <= value && $scope.currentEditionSettings.maxNumber >= value;
        }

        $scope.getMaxQuantity = function(value) {

          if (unlimitedGenerate)
            return undefined;

          if ( typeof value === "undefined" || typeof $scope.currentEditionSettings === "undefined" ) return 99;

          return parseInt(value) + 99 < $scope.currentEditionSettings.maxNumber ? 99 :
            ($scope.currentEditionSettings.maxNumber - parseInt(value) +1 <0) ? 0 : $scope.currentEditionSettings.maxNumber - parseInt(value) +1 ;
        }


        $scope.intersectionNotValid = -1;
        let intersectionTimer:any = false;
        $scope.checkIntersection = function() {
          if (intersectionTimer) {
            intersectionTimer.$cancelRequest();
          }

          if (!$scope.certificateItem.firstBarcode || !$scope.certificateItem.quantity || !$scope.currentEdition.id) return;

          $scope.intersectionNotValid = 0;
          intersectionTimer = GiftCertificateModel.checkRange({
            editionId: $scope.currentEdition.id  ,
            minNumber: $scope.certificateItem.firstBarcode,
            quantity: $scope.certificateItem.quantity,
          }, function(result,d,status) {
            if (status !== 204 ) {
              $scope.intersectionNotValid = 1;
            } else {
              $scope.intersectionNotValid = -1;
            }
            intersectionTimer = false;

          }, function(result,d,status) {
            $scope.intersectionNotValid = 2;
            intersectionTimer = false;
          });


        }

        $scope.$watchGroup(['certificateItem.firstBarcode','certificateItem.quantity'], $scope.checkIntersection );

      }] ;


