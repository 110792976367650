<div class="row b-t b-b bg-light padder wrapper-xs ">

  <div class="dropdown  pull-left m-r-sm"   ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs"   ngbDropdownToggle>
      <i>
                <span class="fa"
                      [ngClass]="{ 'fa-filter': !filter.isPending, 'fa-spin fa-spinner': filter.isPending, 'text-danger': filter.dateFrom !== undefined || filter.dateTo !== undefined }">
                </span>

      </i>
      <span class="btn-name">
                Временной период
                <span class="caret "></span>
            </span>
    </button>

    <ul class="dropdown-menu scrollable "
        ngbDropdownMenu
        style="z-index: 9999; "
        onclick="event.stopPropagation()"
    >

      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group ">
          <span class="input-group-addon" style="min-width:50px">С</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filter.dateFrom"
          >
          <span class="input-group-btn" *ngIf="filter.dateFrom">
                            <button
                              class="btn btn-danger"
                              type="button"
                              (click)="filter.dateFrom = undefined">
                                <i class="fa fa-times"></i>
                            </button>
                    </span>
        </div>

      <li class="wrapper-xs padder text-ellipsis">

        <div class="input-group m-b-xs">
          <span class="input-group-addon" style="min-width:50px">ПО</span>
          <input type="date"
                 class="form-control"
                 [(ngModel)]="filter.dateTo"
          >
          <span class="input-group-btn" *ngIf="filter.dateTo">
                            <button
                              class="btn btn-danger"
                              type="button"
                              (click)="filter.dateTo = undefined">
                                <i class="fa fa-times"></i>
                            </button>
                        </span>
        </div>

      </li>

    </ul>

  </div>

  <div class="dropdown  pull-left m-r-sm" ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs" ngbDropdownToggle>
      <i>
                <span class="fa"
                      [ngClass]="{ 'fa-filter': !filter.isPending, 'fa-spin fa-spinner': filter.isPending, 'text-danger': !!filter.userId   }">
                       </span>

      </i>
      <span class="btn-name">
                Пользователь
                <span class="caret "></span>
            </span>
    </button>

    <ul class="dropdown-menu  "
        style="z-index: 9999; "
        onclick="event.stopPropagation()"
        ngbDropdownMenu
    >

      <li class="wrapper-xs padder w-lg ">

        <div class="hbox hbox-auto w-full"  >
          <div class="col w-full">
            <ng-select
              [(ngModel)]="filter.userId"
              placeholder="Выберите пользователя"
            >
              <ng-option *ngFor="let item of userHandler.options" [value]="item.id">{{item.label}}</ng-option>
            </ng-select>
          </div>
          <div class="col" *ngIf="!!filter.userId">
            <button
              class="btn btn-danger pull-left m-l-sm"
              type="button"
              (click)="filter.userId=undefined">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </li>

    </ul>

  </div>

  <div class="dropdown  pull-left m-r-sm"
       *ngIf="isClientFilter || isFullView"
       ngbDropdown
  >

    <button class="btn btn-default btn-sm btn-addon collapse-xs" ngbDropdownToggle>
      <i>
                <span class="fa"
                      [ngClass]="{ 'fa-filter': !filter.isPending, 'fa-spin fa-spinner': filter.isPending, 'text-danger': !!filter.changeType   }">
                       </span>

      </i>
      <span class="btn-name">
                Тип изменения
                <span class="caret "></span>
            </span>
    </button>

    <ul class="dropdown-menu  "
        style="z-index: 9999; min-width: 27em"
        onclick="event.stopPropagation()"
        ngbDropdownMenu
    >

      <li class="wrapper-xs padder w-full ">

        <div class="hbox hbox-auto w-full"  >
          <div class="col w-full">
            <ng-select
              [(ngModel)]="filter.changeType"
              placeholder="Выберите тип"
              class="w-full"
            >
              <ng-option *ngFor="let item of filter.getChangeTypes()" [value]="item.id">{{item.label}}</ng-option>
            </ng-select>

          </div>
          <div class="col" *ngIf="!!filter.changeType">
            <button
              class="btn btn-danger pull-left m-l-sm"
              type="button"
              (click)="filter.changeType=undefined">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </li>

    </ul>

  </div>

  <!--
      <div class="dropdown  pull-left m-r-sm"
           *ngIf="isClientFilter"
           ngbDropdown
      >

          <button class="btn btn-default btn-sm btn-addon collapse-xs"
                  ngbDropdownToggle
          >
              <i>
                  <span class="fa"
                        [ngClass]="{ 'fa-filter': !filter.isPending, 'fa-spin fa-spinner': filter.isPending, 'text-danger': filter.fieldId !== undefined   }">
                         </span>

              </i>
              <span class="btn-name">
                  Поле
                  <span class="caret "></span>
              </span>
          </button>

          <ul class="dropdown-menu  "
              ngbDropdownMenu
              style="z-index: 9999; "
              onclick="event.stopPropagation()">

              <li class="wrapper-xs padder w-lg ">

                  <div class="hbox hbox-auto w-full">
                      <div class="col w-full">

                        <ng-select
                          [ngModel]="filter.fieldId"
                          placeholder="Выберите поле"
                        >
                          <ng-option *ngFor="let item of fieldHandler.fieldsByGroup" [value]="item.id">{{item.label}}</ng-option>
                        </ng-select>

                      </div>
                      <div class="col" *ngIf="filter.fieldId !== undefined ">
                          <button
                                  class="btn btn-danger pull-left m-l-sm"
                                  type="button"
                                  (click)="filter.fieldId = undefined">
                              <i class="fa fa-times"></i>
                          </button>
                      </div>
                  </div>
              </li>

          </ul>

      </div>
  -->

</div>

<div class="clearfix m-b-xs"></div>

<span  *ngIf="historyLog.list && historyLog.list.length" >

    <div class="row b-t b-b bg-light text-uppercase wrapper-xs p-l-none p-r-none f-s-9" >

        <div class="col-sm-12 visible-xs">Изменения</div>
        <div class="col-sm-3 hidden-xs col-md-3 col-lg-2">Дата/Время</div>
        <div class="col-lg-2 hidden-xs visible-lg">Пользователь</div>
        <div class="col-lg-2 hidden-xs visible-lg" *ngIf="isFullView" >Клиент</div>
        <div class="col-lg-2 hidden-xs visible-lg" *ngIf="isFullView" >Рефер</div>
        <div class="col-sm-9 hidden-xs col-md-9 "
             [ngClass]="{'col-lg-4' : isFullView, 'col-lg-6' : !isFullView}">Изменения</div>

    </div>


    <div
      infinite-scroll="historyLog.next()"
      infinite-scroll-distance="1"
      infinite-scroll-container="'#app-container'"
    >

        <div class="row b-b p-b-xs p-t-xs"
             *ngFor="let field of historyLog.list"
        >
        <div class="col-sm-3 col-md-3 col-lg-2">
            <span class="text-black-lter f-s-9"
                  [innerText]="field.date | loyaDateTime"></span>

            <span *ngIf="isFullView" class="hidden-lg clearfix" >
                <span class="text-muted f-s-9 m-r-xs">Клиент:</span>
                <div class="clearfix  visible-sm"></div>
                 <a class="f-s-9"
                    [uiSref]="'frontend.crm.clients.view( {id:'+field.clientId+'})'"
                    [innerText]="field.clientName ? field.clientName : 'Клиент с ID: '+field.clientId"
                 ></a>
            </span>

            <span class="hidden-lg clearfix" >
                <span class="text-muted f-s-9 m-r-xs">Пользователь:</span>
                <div class="clearfix  visible-sm"></div>
                <a href="" class="f-s-9" [innerText]="userHandler.userNameById(field.userId)"></a>
            </span>


        </div>

        <div class="col-lg-2 visible-lg">
            <a class="" href="" [innerText]="userHandler.userNameById(field.userId)"></a>
        </div>

        <div class="col-lg-2 visible-lg" *ngIf="isFullView" >
            <a [uiSref]="'frontend.crm.clients.view( {id:'+field.clientId+'} )'"
               [innerText]="getClientName(field)"
            ></a>
        </div>

        <div class="col-lg-2 visible-lg" *ngIf="isFullView" >
            <a [uiSref]="'frontend.referrer.view( {id:'+field.referrerId+'} )'"
               [innerText]="getReferrerName(field)"
            ></a>
        </div>

        <div class="col-sm-4 col-md-9 "
             [ngClass]="{'col-lg-4' : isFullView, 'col-lg-6' : !isFullView}"
        >
          <referrer-log-history-changes
            [model]         = "field.details || field.referrerDiff"
            [type]          = "field.changeType"
            [item]          = "field"
            [field-handler] = "fieldHandler"
            [filter-handler] = "filter"
          >
          </referrer-log-history-changes>

        </div>

    </div>

    </div>


</span>

<div  *ngIf="!(historyLog.list && historyLog.list.length)"
      class="text-center padder-xs wrapper-md"
>
  <span class="f-s-12 text-muted">История отсутствует</span>
</div>
