import { TEMPLATES } from './templates/templates';
import { TEMPLATES_ID } from '../../view/templates';
import { ModuleFieldsHandler } from "class/ModuleFieldsHandler";
import {ConfigFieldItem} from "../../../../../components/configModuleFields/ConfigModuleService";
import {MODULE_NAME} from "plugin/component/communication/variables";

export class ClientHeaderForm {

    // Передаваемые параметры в компонент
    public model     : any;
    public template  : TEMPLATES_ID;
    public isExpress : boolean;

    private _t: any; // Для вывода служебных полей в темплейте

    private moduleFieldsService : ModuleFieldsHandler;

    static $inject = ['ClientModel', '$injector', 'Settings' ];

    constructor(
        private ClientModel: any,
        private $injector: any,
        private Settings: any
    ) {

    }

    public $onInit() {
        this._t = new this.ClientModel();
        this.template = this.template || TEMPLATES_ID.DEFAULT;
        this.moduleFieldsService = ModuleFieldsHandler.getInstanceForModule('Common');
    }

    public $onChanges(changesObj: any) {

    }

    public filterNotFixedFields( item : ConfigFieldItem) {
        return !item.isFixed;
    }

    public isRequiredField( fieldId: string ) {
        let find = this.moduleFieldsService.getFieldsForCustomRepeater().find( (i:any) => i.id === fieldId);
        return find && find.required;
    }

    public getNameForField( fieldId: string, defaultName: string ) {

        let find = this.moduleFieldsService.getFieldsForCustomRepeater().find( (i:any) => i.id === fieldId);
        return ( find && find.label ) || defaultName;
    }

    public getTemplate() {
        return typeof TEMPLATES[ this.template ] === 'undefined' ?
                        TEMPLATES[ TEMPLATES_ID.DEFAULT ] :
                        TEMPLATES[ this.template ];
    }

    private _dummy = {};
    public getProfile( id : string ) {

        if (
            !this.model ||
            !this.model.profiles
           ) {
            return false;
        }

        id = id.toLowerCase();
        let index = Object.keys( this.model.profiles )
                          .filter( key => key.toLowerCase().indexOf( id ) === 0 );


        return index.length ? this.model.profiles[ index[0] ] : false ;
    }

    public hasProfile = ( id : string ) => !!this.getProfile(id);

    public injectorsDb = new Map<string, any>();
    public createTemplate( id : string ) {

        if (this.injectorsDb.has(id)) {
            return this.injectorsDb.get(id);
        }

        if ( this.$injector.has(id) ) {
            this.injectorsDb.set(id, new (this.$injector.get(id))() );
            return this.injectorsDb.get(id);
        }

        return ;
    }


}
