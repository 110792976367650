<div class="card no-back" >
  <div class="card-header ">
    Количество сообщений
  </div>
  <div class="card-body long-descriptions m-l-n-md m-r-n-md m-b-n-sm" >

    <div class="padder m-b">
      <div class="row">

        <div class="col-md-4 col-sm-6 text-center"
             *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'email' }"
        >
          <highcharts-chart
            *ngIf="easyPieConfigs.email"
            [Highcharts]="Highcharts"
            [options]="easyPieConfigs.email"
            [(update)]="updateFlag"
            class="easy-chart-block"
          ></highcharts-chart>

          <div class="text-muted text-xs m-t m-b">E-mail {{
            getCommunicationsType('email')?.delivered }}
            <span *ngIf="getCommunicationsType('email')?.filled !== 0"> из {{ getCommunicationsType('email')?.filled }} </span>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 text-center"
             *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'sms' }"
        >

          <highcharts-chart
            *ngIf="easyPieConfigs.sms"
            [Highcharts]="Highcharts"
            [options]="easyPieConfigs.sms"
            [(update)]="updateFlag"
            class="easy-chart-block"
          ></highcharts-chart>

          <div class="text-muted text-xs m-t m-b" data-testid="realValuesCampaign.communications.sms">SMS {{
            getCommunicationsType('sms')?.delivered }}
            <span *ngIf="getCommunicationsType('sms')?.filled !== 0"> из {{ getCommunicationsType('sms')?.filled}} </span>
          </div>
        </div>

        <div class="col-md-4 col-sm-12 text-center"
             *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'check' }"
        >

          <highcharts-chart
            *ngIf="easyPieConfigs.check"
            [Highcharts]="Highcharts"
            [options]="easyPieConfigs.check"
            [(update)]="updateFlag"
            class="easy-chart-block"
          ></highcharts-chart>

          <div class="text-muted text-xs m-t m-b" data-testid="realValuesCampaign.communications.check">Чек {{
            getCommunicationsType('check')?.worked }}
            <span *ngIf="getCommunicationsType('check')?.filled !== 0"> из {{ getCommunicationsType('check')?.filled}} </span>
          </div>
        </div>

        <div class="col-sm-12 text-center"
             *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }"
        >
          <highcharts-chart
            *ngIf="easyPieConfigs.viber"
            [Highcharts]="Highcharts"
            [options]="easyPieConfigs.viber"
            [(update)]="updateFlag"
            class="easy-chart-block"
          ></highcharts-chart>

          <div class="text-muted text-xs m-t m-b" data-testid="realValuesCampaign.communications.viber">Viber {{
            getCommunicationsType('viber')?.worked }}
            <span *ngIf="getCommunicationsType('viber')?.filled !== 0"> из {{ getCommunicationsType('viber')?.filled}} </span>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="card no-back" >
  <div class="card-header ">
    Средние фактические показатели
  </div>
  <div class="card-body long-descriptions m-l-n-md m-r-n-md m-b-n-sm padder" >
    <div class="row">
      <div class="  col-sm-6">
        <div class="block padder-v item m-b-none text-center">
            <span class="font-thin h1 block">
              <i class="f-s-8 fa  m-l-sm m-r"
                 [ngClass]="{'fa-caret-up text-success': getTotalClient()?.real > getTotalClient()?.plan, 'fa-caret-down text-warning': getTotalClient()?.real <= getTotalClient()?.plan}"
              ></i>
              <span
                class="text-primary"
              data-testid="realValuesCampaign.avg.totalOneClient.real">
                {{ getTotalClient()?.real | loyaCurrency }}
            </span>
          </span>
          <span class="text-muted text-xs">Средняя сумма продаж одного клиента<br/>(
            <span  ngCurrencyNameLoya ></span>)</span>
        </div>
      </div>
      <div class="  col-sm-6">
        <div class="block padder-v item text-center m-b-none">
          <span class="font-thin h1 block"><i
            class="f-s-8 fa m-l-sm m-r"
            [ngClass]="{'fa-caret-up text-success': getSellingOneClient()?.real > getSellingOneClient()?.plan, 'fa-caret-down text-warning': getSellingOneClient()?.real <= getSellingOneClient()?.plan}"
          ></i><span
            class="text-info" data-testid="realValuesCampaign.avg.sellingOneClient.real">
              {{getSellingOneClient()?.real | loyaCurrency }}
          </span></span>
          <span class="text-muted text-xs">Средние расходы на одного клиента<br/>( <span
            ngCurrencyNameLoya></span>)</span>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12 m-b-sm p-b-none">
        <div class="wrapper-lg">

          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartConfig"
            [(update)]="updateFlag"
            style=" display: block;"
          ></highcharts-chart>


        </div>
      </div>
    </div>
  </div>
</div>

<div class="card no-back" >
  <div class="card-header " >

    Вознаграждения
  </div>
  <div class="card-body long-descriptions padder m-l-n-md m-r-n-md m-b-n-sm" >

    <div class="row m-b">
      <div class="col-md-4 col-sm-6 ">
        <div class="block padder-v item m-b-none text-center">
          <span class="font-thin h3 block "><span class="h2">
            <i class="fa fa-tag text-primary"
               [ngClass]="{ 'text-muted':!getResourceType('discount') }"></i></span><br/>
            <span *ngIf="getResourceType('discount')" data-testid="realValuesCampaign.resources.discount.realValue">{{getResourceType('discount')?.realValue }}</span>
            <span *ngIf="!getResourceType('discount')">-</span>
          </span>
          <span class="text-muted text-xs">Скидки</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 ">
        <div class="block padder-v item text-center m-b-none">
          <span class="font-thin h3 block"><span class="h2">
              <i class="fa fa-heart text-success"
                 ng-class="{ 'text-muted':!getResourceType('gift') }"></i></span><br/>
          <span *ngIf="getResourceType('gift')" data-testid="realValuesCampaign.resources.gift.realValue">{{getResourceType('gift')?.realValue }}</span>
          <span *ngIf="!getResourceType('gift')">-</span></span>
          <span class="text-muted text-xs">Подарки</span>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 "
           *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '' }"
      >
        <div class="block padder-v item text-center m-b-none">
          <span class="font-thin h3 block"><span class="h2">
              <i class="fa fa-star text-info"
                 [ngClass]="{ 'text-muted':!getResourceType('bonus') }"></i></span><br/>
           <span *ngIf="getResourceType('bonus')" data-testid="realValuesCampaign.resources.bonus.realValue">{{getResourceType('bonus')?.realValue }}</span>
          <span *ngIf="!getResourceType('bonus')">-</span></span>
          <span class="text-muted text-xs">Начисленные бонусы</span>
        </div>
      </div>

    </div>

  </div>
</div>
