import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";

export type settingsItem = {key: string, value: string | boolean}

@Injectable()
export class SettingsService {

  constructor( private http: HttpClient ) {
  }

  query$(): Observable<settingsItem[]> {
    return  this.http.get('/settings', {
        responseType: "json"
      })
      .pipe(
        tap( (r: any) => r?.forEach( (s:any) =>
          s.value = s.value?.toLowerCase() === 'true' ? true :
            s.value?.toLowerCase() === 'false' ? false : s.value )
        )
      )
  }

  getByKeys$(keys: Array<string>) {
    return (<Observable<settingsItem[]>>this.http.post('/settingsByKeys',keys,{
      responseType: "json"
    }))
      .pipe(
        tap(r => r?.forEach( (s:any) =>
          s.value = s.value?.toLowerCase() === 'true' ? true :
                    s.value?.toLowerCase() === 'false' ? false : s.value )
        )
      )
  }

  getByKey$(key) {
    return this.getByKeys$([key])
      .pipe(
        map(result => {
          let find = result?.find(i => i.key === key);

          return !!find? find.value : undefined;
        })
      )
  }

  update$(data: settingsItem[] | object)  {

    if (  typeof data === "object"  && !Array.isArray(data) ){
      let newData = Object.keys(data)
        .map(
          key => ({key, value: data?.[key]?.toString() })
        )
      data = newData;
    }

    Array.isArray(data) &&
      data?.forEach(  (i:settingsItem)  =>  {
        if  ( typeof i.value === "undefined") {
          i.value = '';
        }
      });

/*

    this.query$()
      .pipe(
        map(result =>
          result?.filter(i => {
            let finded = ( <Array<any>>data )?.find(i =>  i.key === i.key);
            return !!finded &&  i.value!== finded?.value;
          })
        ),
        switchMap(  result =>
          this.http.post('/settings', result,{
            responseType: "json"
          })
        ),
        switchMap( () => this.query$() )
      )
*/

    return this.http.post('/settings', data,{
      responseType: "json"
    })
      .pipe(
        switchMap( () => this.query$() )
      )
  }



}
