import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {ClientCategory} from "../../../../../api/ClientCategoryApi/models";
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {NgForm} from "@angular/forms";
import {ClientCategoryService} from "../../../../../shared/service/api/ClientCategory/ClientCategory.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";


@Component({
  selector: 'clientcategory-view-page',
  templateUrl: './clientcategory-view-page.component.html',
  providers: [
    StatesChangerService,
    ClientCategoryService
  ]
})
export class ClientcategoryViewPageComponent implements OnInit, OnChanges{

  @Input() id: string;

  @ViewChild('mcategoryForm') mcategoryForm;
  public currentClientCategory;

  private uiRouterState;

  constructor(
    private clientCategoryService:ClientCategoryService,
    public statesChangerService:StatesChangerService,
    public confirmActionService: ConfirmActionService
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();

  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getClientCategoryById(changes.id.currentValue)
        .then( result => {
          if (changes.id.firstChange) {

            this.statesChangerService.state = Object.keys(result).length === 0 ?
                                              'edit' :
                                              'view';
          }
        })
      ;
    }
  }

  getClientCategoryById( id:string = '' ) {

    if (id === '') {
      this.currentClientCategory = {};
      return <any>Promise.resolve(this.currentClientCategory);
    }

    return this.clientCategoryService
        .get(parseInt(id))
        .toPromise()
        .then( result => this.currentClientCategory = result );
  }

  onSave() {
    this.clientCategoryService.saveCategory(this.currentClientCategory)
      .subscribe(result => {
        this.uiRouterState.go('^.view', {id: result.id}, {reload: true});
      });
  }

  onCancel() {
    if (typeof this.currentClientCategory.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getClientCategoryById(this.id)
        .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentClientCategory.id === "undefined")
        return;

    this.clientCategoryService
        .deleteCategory(this.currentClientCategory.id)
        .subscribe(() => {
          this.uiRouterState.go('^.index');
        })
  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }



}
