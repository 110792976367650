/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for AddressApi services
 */
@Injectable({
  providedIn: 'root',
})
export class AddressApiConfiguration {
  rootUrl: string = location.origin;
}

export interface AddressApiConfigurationInterface {
  rootUrl?: string;
}
