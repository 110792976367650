export class QueryHandler {

    public results     : any[] = [];
    public isPending   : boolean;
    private firstQuery : boolean = true;

    private queue      : any[] = [];

  static $inject = ['id', 'actions', 'originalQuery'];

    constructor(public id: any,
                public actions: any,
                public originalQuery: any,) {

    }


    static getValueByPath = function( obj: object, path: string , isObject:boolean = true): any {

        let result;
        if (isObject) { // если путь это вложенный объект
          result = path.split('.')
                         .reduce( (o : object, i : string )=>o ? o[i] : undefined, obj );
        } else { // если путь это строка
          result = obj[path];
        }

        return typeof result === "object" && !Array.isArray(result) && !(result instanceof Date) ? QueryHandler.mergeParams({}, result ) : result;

    };

    static setValueByPath = function( obj: object, path: string, value: any , isObject:boolean = true) {
        let pathArray = path.split('.');
        let root = obj;

        if (isObject) {
          if (pathArray.length > 1) {
              root = pathArray
                  .slice(0, pathArray.length - 1)
                  .reduce( (o : object, i : string )=> o[i] ? o[i] : o[i] = {}, obj );
          }
        } else {
          pathArray=[path];
        }

        root[pathArray.pop()] = value;
        return obj;
    };

    static mergeParams = (target : object , source: object) => {

        for (let key of Object.keys(source)) {

            if (source[key] instanceof Object && !Array.isArray(source[key]) ) {
                target[key] = target[key] || {};
                Object.assign(source[key], QueryHandler.mergeParams(target[key], source[key]))
            }

            if ( typeof source[key] === "undefined") {
                delete source[key];
            }
        }

        Object.assign(target || {}, source)
        return target
    }

    static extractParams = function (...args: any[]) {

        switch (args.length) {
            case 3:
                return args[0];

            case 2:
                if (typeof args[1] !== 'function') {
                    return args[0];
                }
                break;
            case 1:
                if (typeof args[0] !== 'function') {
                    return args[0];
                }
                break;
        }
        return {};
    };

    static updateParams = function (params: any, ...args: any[]) {

        if (!params) {
            return args;
        }

        switch (args.length) {
            case 3:
                if (typeof args[0] !== 'function') {
                    args[0] = QueryHandler.mergeParams(args[0], params);
                    return args;
                }
                break;

            case 2:
                if (typeof args[1] !== 'function') {
                    args[0] = QueryHandler.mergeParams(args[0], params);
                    return args;
                } else {
                    args.unshift(Object.assign({}, params) );
                    return args;
                }

            case 1:
                if (typeof args[0] !== 'function') {
                    args[0] = QueryHandler.mergeParams(args[0], params);
                    return args;
                } else {
                    args.unshift(Object.assign({}, params) );
                    return args;
                }

        }
        return [Object.assign({}, params)];
    };

    public bindResults = (results: any) => {
        this.results = results
        return this.results;
    };

    public query = function (...args: any[]) {

        // Установка стартовых параметров
        if (this.firstQuery) {

            this.startHandlers.forEach((h: any) => {
                h(QueryHandler.extractParams(...args));
                h = null;
            });
            this.startHandlers.splice(0, this.startHandlers.length);
            this.firstQuery = false;

        }

        this.queue.push( {
            type : 'query',
            args : args
        });

        this.exequteQueue();

        return this.results;

    };

    private exequteQueue = function( result?:any ) {

        if ( this.isPending || !this.queue.length ) {
            return result;
        }

        let {type,args } = this.queue.shift();

        if ( type === 'reset' ) {

            this.resetHandlers.forEach((h: any) => {
                h();
            });

            if (Array.isArray(this.results)) {
                this.results.forEach((resource: any) => {
                    resource = null;
                });
            }

            this.isResetQuery = true;

        }


        let original = this.originalQuery;
        let resolve: any;

        let finish = new Promise(function (r) {
            resolve = r;
        });

        this.isPending = true;

        this.queryHandlers.forEach((h: any) => {
            args = QueryHandler.updateParams(h(finish), ...args)
        });

        let tmpOrig = original.apply(this, args)
        if (tmpOrig.$cancelRequest) {
          this.results.$cancelRequest = tmpOrig.$cancelRequest;
        }

        this.results.$promise = tmpOrig
            .$promise
                .then(this.udateResults.bind(this))
                .then((res: any) => {
                 //   this.isPending = false;
                    resolve(res);
                    return new Promise( r => setTimeout( () => r(res) ) );
                })
                .then((res: any) => {
                    this.cleanResults(res);

                    return this.results;
                })
                .finally( (result: any ) => {
                    this.isPending = false;
                    return this.exequteQueue(result)
                });

        return this.results;

    };

    private isResetQuery = false;
    private lastResetId: any;

    public reset = function () {

        if (this.lastResetId) {
            clearTimeout(this.lastResetId);
        }

        this.lastResetId = setTimeout( () => {

            this.queue.push({
                type : 'reset',
                args : []
            });

            this.exequteQueue();

        },0);

    };

    public udateResults = function (resultArray: any) {

         // new Promise((resolve: any, reject: any) => {

                if (this.isResetQuery) {
                    this.results.splice(0, this.results.length);
                    this.isResetQuery = false;
                }

                if (!Array.isArray(resultArray))
                    return resultArray;

                this.results.splice(this.results.length, 0, ...resultArray);

                return resultArray;

              //  .then(resolve, reject)

                /*.then(function (resultArray: any) {

                    if (Array.isArray(resultArray)) {
                        resultArray.map(_ => null);
                        resultArray.splice(0, resultArray.length);
                    }

                    newResult = null;

                })*/;
       // });
    };
    private cleanResults = function(resultArray: any) {
        setTimeout( () => {

            if (Array.isArray(resultArray)) {
                resultArray.map(_ => null);
                resultArray.splice(0, resultArray.length);
            }

        },0);

    }

    private startHandlers: any[] = [];
    public onStart = function (handler: any) {

        this.startHandlers = this.startHandlers.filter((h: any) => h !== handler);
        this.startHandlers.push(handler);

        return () => {
            this.onStart = this.startHandlers.filter((h: any) => h !== handler);
            handler = null;
        }
    };

    private queryHandlers: any[] = [];
    public onUpdate = function (handler: any) {

        this.queryHandlers = this.queryHandlers.filter((h: any) => h !== handler);
        this.queryHandlers.push(handler);

        return () => {
            this.queryHandlers = this.queryHandlers.filter((h: any) => h !== handler);
            handler = null;
        }
    };

    private resetHandlers: any[] = [];
    public onReset = function (handler: any) {

        this.resetHandlers = this.resetHandlers.filter((h: any) => h !== handler);

        this.resetHandlers.push(handler);
    };

}
