import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CampaignCategoryApiService} from "../../../../api/CampaignCategoryApi/services";
import {
  CampaignCategory,
  CampaignCategoryLink,
  CampaignCategoryQueryParam
} from "../../../../api/CampaignCategoryApi/models";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";

@Injectable()
export class CampaignCategoryService {

  constructor( private campaignCategoryApiService: CampaignCategoryApiService ) {
  }


  public getListParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'selectedId',
        id: 'selectedId',
        type: 'object',
        currentValue: undefined
      }
    ]
  });


  public getList$(params?: CampaignCategoryQueryParam){
    return this.campaignCategoryApiService.query(params || {});
  }

  public get( id? :number ) : Observable<CampaignCategory>{

    if (!id)
      return  new Observable(s => {
        s.next(<any>{
          allowBonusPayment         : true,
          awardBonusEvenWithPayment : true
        });
        s.complete();
      });

    return this.campaignCategoryApiService.get(id)
  }

  public save( category: CampaignCategory ){

    if (typeof category.id !== "undefined") {
      return this.campaignCategoryApiService.update({ id: category.id, campaignCategory: category });
    } else {
      return this.campaignCategoryApiService.create( category );
    }

  }

  public delete$( id: number ){

    return this.campaignCategoryApiService.delete(id);

  }

  public queryLinks$( id: number ){
    if (!id) {
      return new Observable<any[]>(s => {
        s.next([]);
        s.complete();
      });
    }

    return this.campaignCategoryApiService.queryLinks(id);

  }

  public postLinks$(id: number,links: CampaignCategoryLink[]) {

    return this.campaignCategoryApiService.postLinks({
      id : id,
      links:links
    });

  }

}
