
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";

@Component({
  selector: 'campaign-circle-graph',
  template: `
    <ng-container *ngIf="!hideOnZeroFlag">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="easyPieConfig"
        [(update)]="updateFlag"
        class="easy-chart-block"
      ></highcharts-chart>
    </ng-container>

    <ng-content></ng-content>
  `,
  styles: [],
  providers: [
  ]
})
export class CampaignCircleGraphComponent implements OnInit, OnChanges {

  @Input() percent;
  @Input() color;
  @Input() updateFlag;
  @Input() width;

  @Input() hideOnZeroFlag = false;
  @Output() hideOnZeroFlagChange = new EventEmitter();

  public Highcharts: typeof Highcharts = Highcharts;
  public easyPieConfig = {
    title: false,

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      backgroundColor: null,
      plotShadow: false,
      borderWidth: 0,
      type: 'pie',
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      width: 110,
      height: 110,
      style: {
        overflow: 'visible',
        margin: '0 auto'
      },

      skipClone: true
    },

    credits: {
      enabled: false
    },

    subtitle: {
      useHTML: true,
      text: '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        fontWeight:700,
        fontSize: '1.2em'
      },
      y: 5
    },

    legend: {
      enabled: false
    },

    tooltip:false,

    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '90%',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        dataLabels: {
          enabled: false,
          crop: false,
          distance: '-10%',
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        }
      }
    },
    colors: [LOYA_CONFIG.color.light, LOYA_CONFIG.color.light],
    series: [
      {
        type: 'pie',
        name: '-',
        data: [ ]
      }
    ]
  };

  constructor(
  ) {
  }

  ngOnInit() {
  }


  ngOnChanges(changes) {

    if (changes['percent']) {
      this.setPercent(changes['percent'].currentValue);
    }

    if (changes['color']) {
      this.setColor(changes['color'].currentValue);
    }

    if (changes['color']) {
      this.setColor(changes['color'].currentValue);
    }

    if (changes['width']) {
      this.setWidth(changes['width'].currentValue);
    }

  }

  setPercent(percent = 0) {
    const realPercent = percent;
    if (percent > 100 || percent === 0  )
      percent = 100;

    this.easyPieConfig.series[0].data = [ ['', percent], ['', 100 - percent] ]
    this.easyPieConfig.subtitle.text = realPercent === 100 || realPercent === 0 ? '' : realPercent + '%';

    if (realPercent !== 0) {
      this.hideOnZeroFlag = false;
      this.hideOnZeroFlagChange.emit(this.hideOnZeroFlag);
    }

    this.updateFlag = true;

  }

  setColor(color = LOYA_CONFIG.color.light) {

    this.easyPieConfig.colors = [color, LOYA_CONFIG.color.light];
    this.updateFlag = true;

  }

  setWidth(width = 100) {
    this.easyPieConfig.chart.width = width;
    this.easyPieConfig.chart.height = width;
    this.updateFlag = true;
  }

}
