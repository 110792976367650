/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for InformingTemplateApi services
 */
@Injectable({
  providedIn: 'root',
})
export class InformingTemplateApiConfiguration {
  rootUrl: string = location.origin;
}

export interface InformingTemplateApiConfigurationInterface {
  rootUrl?: string;
}
