
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../../shared/service/api/Settings/Settings.service";
import {daysOfMonthDics, daysOfWeekDict} from "../../values/campaign.values";
import {CampaignProtocol} from "../../../../../../api/CampaignApi/models/campaign-protocol";
import {CommonCampaignTime} from "../../../../../../api/CampaignApi/models/common-campaign-time";
import {CampaignTargetInfo} from "../../../../../../api/CampaignApi/models/campaign-target-info";
import {checkExternalCode} from "../campaign-external-code/campaign-external-code.component";

@Component({
  selector: 'campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: [
    `./campaign-edit.component.scss`
  ],
  providers: [
    CampaignService,
    LocationsDialogService,
    GroupService,
    SettingsService,
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CampaignEditComponent implements OnInit, OnChanges {

  @Input() campaignItem: CampaignProtocol;
  @Input() campaignResources;

  @Output() anchorList = new EventEmitter()

  public currentMechanic;

  public loader = new LoaderHandlerClass();
  public settingsObj:any = {}
  public subscribers:any = {}

  public showDays = [];

  public daysOfWeekDict = daysOfWeekDict;
  public daysOfMonthDics = daysOfMonthDics;
  public targetDist = [
    { key: 'all', name :'Все' },
    { key: 'segment', name :'Сегмент' },
    { key: 'group', name :'Группа' }
  ];

  public targetAudience = 'all';
  public exceptionAudience = 'all';

  public targetGroupInfo:CampaignTargetInfo = <any>{};
  public campaignTimeCount:CommonCampaignTime = <any>{};

  constructor(
    public campaignService: CampaignService,
    public locationsDialogService: LocationsDialogService,
    public groupService: GroupService,
    public settingsService: SettingsService
  ) {
  }

  ngOnInit() {
    this.getSystemSettings();

    this.prepareTargetDict();

    this.anchorList.emit({
      anchorI1: 'Параметры',
      anchorI2: 'Период и место действия',
      anchorI3: 'Аудитория',
      anchorI8: 'Механика',
      anchorI4: 'Информирование',
      anchorI5: 'Внешняя система лояльности',
    })

  }

  ngOnChanges(changes) {
    if (changes['campaignItem']) {
      this.calcTargetValues();
      this.recalcAudience();
      this.recalcCampaignTime();
    }
  }

  get isSuspend() {
    return this.campaignItem?.campaign?.state === 'suspended'
  }

  get isPartnerSelected() {
    return !!this.campaignItem?.campaign?.partnerId
  }

  private locationGroupsArray = [];
  get locationGroupsId() {
    this.locationGroupsArray.splice(0, this.locationGroupsArray?.length);
    this.campaignItem?.locationGroup?.forEach( i => this.locationGroupsArray.push(i?.groupId ) );
    return this.locationGroupsArray;
  }

  set locationGroupsId(value) {

    this.campaignItem = this.campaignItem || <any>{};

    if (!value || !Array.isArray(value) ) {
      delete this.campaignItem.locationGroup;
      return
    }

    this.campaignItem.locationGroup = value.map( i => ({ groupId: i}) ) || [];
  }

  private expectLocationGroupsArray = [];
  get expectLocationGroupsId() {
    this.expectLocationGroupsArray.splice(0, this.expectLocationGroupsArray?.length);
    this.campaignItem?.exceptLocationGroup?.forEach( i => this.expectLocationGroupsArray.push(i?.groupId ) );
    return this.expectLocationGroupsArray;
  }

  set expectLocationGroupsId(value) {

    this.campaignItem = this.campaignItem || <any>{};

    if (!value || !Array.isArray(value) ) {
      delete this.campaignItem.exceptLocationGroup;
      return
    }

    this.campaignItem.exceptLocationGroup = value.map( i => ({ groupId: i}) ) || [];
  }

  hasSelectedLocation(type = 'location') {
    return !!this.campaignItem?.[ type + 'Group']?.length  || this.campaignItem?.[type]?.length;
  }

  get isFrontalCampaign() {
    return !(
      // Если выбрана целевая аудитория
      typeof this.campaignItem.campaign.groupId   !== 'undefined' ||
      typeof this.campaignItem.campaign.segmentId !== 'undefined' ||
      this.targetAudience           !== 'all' ||

      // Если выбрано ограничение целевой аудитории
      this.exceptionAudience              !== 'all' ||
      typeof this.campaignItem.campaign.exceptSegmentId !== 'undefined' ||
      typeof this.campaignItem.campaign.exceptGroupIds   !== 'undefined'
    )
  }

  get hasResourceBonus() {
    return !!this.campaignResources?.some(i => i.resource === 'bonus')
  }

  get hasResourceDiscount() {
    return !!this.campaignResources?.some(i => i.resource === 'discount')
  }

  get hasResourceGift() {
    return !!this.campaignResources?.some(i => i.resource === 'gift')
  }

  get isExternalLoyalty() {
    return this?.externalLoyaltyFlag || checkExternalCode(this.campaignItem);
  }

  get externalLoyaltyFlag() {
    return !!this?.settingsObj?.externalLoyalty
  }

  get resourceGiftDetails() {

    if (!this.hasResourceGift)
      return [];

    return this.campaignResources?.find(i => i.resource === 'gift')?.details;
  }

  public getSystemSettings() {

    this.settingsService.getByKey$('externalLoyalty')
      .subscribe({
        next: r => {
          this.settingsObj.externalLoyalty = r
          if (this.settingsObj.externalLoyalty)
            this.campaignItem.elSettings = this.campaignItem.elSettings || {};
        }
      })
  }

  public isEnabledInArray = ( key, array) => Array.isArray(array) && array?.indexOf(key)>=0;

  public changeWeekDay = ( isEnabled, key, array) => {

    if ( isEnabled ) {

      if ( !this.isEnabledInArray(key, array) ) {
        array.push(key);
      }

    } else {

      let index = array.indexOf(key);
      if ( index>=0 ) {
        array.splice(index,1);
      }

    }

  };

  public changeMonthDay = ( isEnabled, key, period) => {

    if ( isEnabled ) {

      if ( !this.isEnabledInArray(key, period.daysOfMonth) ) {
        period.daysOfMonth = period.daysOfMonth || [];
        period.daysOfMonth.push(key);
      }

    } else {

      if ( Array.isArray(period.daysOfMonth) ) {
        let index = period.daysOfMonth.indexOf(key);
        if ( index>=0 ) {
          period.daysOfMonth.splice(index,1);
        }

        if (!period.daysOfMonth?.length)
          delete period.daysOfMonth;

      }

    }

  };

  public createCampaignPeriod() {

    this.campaignItem.campaign.campaignTime.periods = this.campaignItem.campaign.campaignTime.periods || [];
    this.campaignItem.campaign.campaignTime.periods.push( {

      "startTime": "00:00:00",
      "stopTime": "23:59:59",
      "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]

    } );

  };

  public isDaysOfMonthChanged = (period) => {
    if ( Array.isArray(period) )
      return !!period.length

    return !!period?.daysOfMonth?.length;
  }

  public selectLocations(type = 'location') {
    if (!this.isPartnerSelected)
      return;

    this.locationsDialogService.open({
      forPartner: this.campaignItem?.campaign?.partnerId,
      canCheckAll: false,
      initSelectedItems: this.campaignItem?.[type]?.map( i => i.merchantId),
      resultDirective: () => {}
    }).then( result => {

      this.campaignItem[type] = result?.locations?.map( i => ({
        merchantId: i?.id,
        name: i?.name
      }))

      if (!this.campaignItem?.[type]?.length)
        delete this.campaignItem[type];

    }, () => {
      if (!this.campaignItem?.[type]?.length)
        delete this.campaignItem[type];
    })
  }

  public removeLocation(location, type = 'location' ) {
    this.campaignItem[type] = this.campaignItem[type] || [];
    this.campaignItem[type] = this.campaignItem?.[type]?.filter( i => i !== location)
  }

  public prepareTargetDict() {
    const loyaPermissions = getNg1Service('loyaPermissions')
    if ( loyaPermissions.byPath('segments').disabled ) {

      this.targetDist = [
        { key: 'all', name :'Все' }
      ];

      if (loyaPermissions.byPath('segments.groups').enabled) {
        this.targetDist.push( { key: 'upl', name :'Участники ПЛ' } );
        this.targetDist.push( { key: 'noupl', name :'Не участники ПЛ' } );
      }

    }

  }

  public calcTargetValues() {

    const loyaPermissions = getNg1Service('loyaPermissions')

    if ( loyaPermissions.byPath('segments').enabled ) {
      this.targetAudience = !!this.campaignItem?.campaign?.segmentId? 'segment' : !!this.campaignItem?.campaign?.groupId? 'group' : 'all';
    } else {
      switch (true) {

        case this.campaignItem?.group?.name.toLowerCase() === 'все упл':
          this.targetAudience = 'upl';
        break;

        case this.campaignItem?.group?.name.toLowerCase() === 'все неупл':
          this.targetAudience = 'nupl';
        break;

        default:
          this.targetAudience = 'all';
      }
    }

    this.exceptionAudience =
      !!this.campaignItem?.campaign?.exceptSegmentId ? 'segment' :
        !!this.campaignItem?.campaign?.exceptGroupIds ? 'group' :
          'all';

  }

  public onChangeTarget(isExceptionChange?) {

    if (!isExceptionChange)
      switch (this.targetAudience) {
        case 'all':
          delete this.campaignItem.campaign.segmentId;
          delete this.campaignItem.campaign.groupId;
          break;

        case 'group':
          delete this.campaignItem.campaign.segmentId;
          break;

        case 'segment':
          delete this.campaignItem.campaign.groupId;
          break;

        case 'upl':
          delete this.campaignItem.campaign.segmentId;
          delete this.campaignItem.campaign.groupId;

          this.groupService.getList$({
            search: "Все УПЛ"
          }).subscribe({
            next: result => {
              let findedGroup = result.find( i => i.group?.name?.toLowerCase() === 'все упл');
              if (findedGroup)
                this.campaignItem.campaign.groupId = findedGroup?.group?.id;
            }
          })
          break;

        case 'nupl':
          delete this.campaignItem.campaign.segmentId;
          delete this.campaignItem.campaign.groupId;

          this.groupService.getList$({
            search: "Все неУПЛ"
          }).subscribe({
            next: result => {
              let findedGroup = result.find( i => i.group?.name?.toLowerCase() === 'все неупл');
              if (findedGroup)
                this.campaignItem.campaign.groupId = findedGroup?.group?.id;
            }
          })
          break;

      }

    if (isExceptionChange)
      switch (this.exceptionAudience) {

        case 'all':
          delete this.campaignItem.campaign.exceptSegmentId;
          delete this.campaignItem.campaign.exceptGroupIds;
          break;

        case 'group':
          delete this.campaignItem.campaign.exceptSegmentId;
          this.campaignItem.campaign.exceptGroupIds = this.campaignItem.campaign.exceptGroupIds || [];
          break;
        case 'segment':
          delete this.campaignItem.campaign.exceptGroupIds;
          break;
      }

    this.recalcAudience()

  }

  public preventOffileMechanic = mechanic =>  mechanic?.isOffline
    && !this.isFrontalCampaign

  public mechanicFilterForComponent = (mechanic) => {

    if (!mechanic)
      return false;

    if ( mechanic.$isCurrent)
      return  true;

    if ( this.preventOffileMechanic( mechanic ) ) {

      if ( this.campaignItem.campaign.mechanicId === mechanic.id ) {
        return true;
      }

      return false;
    }

    return ( mechanic.status === 'template' && angular.isUndefined(mechanic.campaignId) );
  };

  public preventOffline = ( value ) => {

    if (value?.isOffline) {
      return !this.preventOffileMechanic(value);
    }

    return true;
  }

  public updateCommunications() {
    this.campaignItem.campaign.communication.notUse = !['usePrefer','useEmail','useSms','useViber'].some( i => !!this.campaignItem?.campaign?.communication[i] );

    if (this.campaignItem.campaign.communication.usePrefer) {
      this.campaignItem.campaign.communication.useEmail = false
      this.campaignItem.campaign.communication.useSms = false
      this.campaignItem.campaign.communication.useViber = false
    }

  }

  public recalcPlan() {

    const numOrZero = (val?, fractionDigits = 0) => Number( (parseFloat(val) || 0 ).toFixed(fractionDigits) );

    this.campaignItem.planValues.clientsCount = numOrZero(this.targetGroupInfo?.clientCount);
    this.campaignItem.planValues.prevTotal = ( this.targetGroupInfo?.extended?.timeSize > 0 ) ?
      numOrZero(
        numOrZero(this.targetGroupInfo?.extended?.total) / this.targetGroupInfo?.extended?.timeSize * numOrZero(this.campaignTimeCount?.trigger?.hours ),2
      ) : 0;

    this.campaignItem.planValues.operationCount = numOrZero(this.targetGroupInfo?.extended?.timeSize) > 0 ?
      numOrZero(
        numOrZero(this.targetGroupInfo?.extended?.checkCount) * numOrZero(this?.campaignItem?.planValues?.responsePercent)  /
        numOrZero(this.targetGroupInfo?.extended?.timeSize) * 100 * this.campaignTimeCount?.trigger?.hours
      ) : 0

    this.campaignItem.planValues.responseCount = Math.round( numOrZero(this.campaignItem?.planValues?.clientsCount) * numOrZero(this.campaignItem.planValues.responsePercent)  / 100  ) || 0;

    this.campaignItem.planValues.smsTotal = numOrZero( numOrZero(this.targetGroupInfo?.smsPrefer) * ( numOrZero(this.targetGroupInfo.smsPrice) || numOrZero(this.targetGroupInfo.smsCount) ) );

    this.campaignItem.planValues.emailTotal = numOrZero( numOrZero(this.targetGroupInfo?.emailPrefer) * ( numOrZero(this.targetGroupInfo.emailPrice) || numOrZero(this.targetGroupInfo.emailCount) ) );

    this.campaignItem.planValues.giftTotal = 0;

    this.resourceGiftDetails.forEach ((gift) => {
      if (numOrZero(gift.price) >= 0 && numOrZero(gift.planCount) >= 0) {
        this.campaignItem.planValues.giftTotal += numOrZero(gift?.price) * numOrZero(gift?.planCount);
      }
    })

    this.campaignItem.planValues.giftTotal = numOrZero(this.campaignItem?.planValues?.giftTotal,2);

    this.campaignItem.planValues.totalCost =
      ( this.campaignItem.campaign.communication.useEmail || this.campaignItem.campaign.communication.usePrefer  ? numOrZero(this.campaignItem.planValues.emailTotal) : 0 ) +
      ( this.campaignItem.campaign.communication.useSms || this.campaignItem.campaign.communication.usePrefer  ? numOrZero(this.campaignItem.planValues.smsTotal) : 0 ) +
      ( this.hasResourceDiscount ? numOrZero(this.campaignItem.planValues.discountTotal)  : 0 ) +
      ( this.hasResourceBonus ? numOrZero(this.campaignItem.planValues.bonusTotal)  : 0 ) +
      ( this.hasResourceGift ? numOrZero(this.campaignItem.planValues.giftTotal)  : 0 ) +
      (  numOrZero(this.campaignItem.planValues.additionalTotal)  )


    this.campaignItem.planValues.totalByClient = this.campaignItem.planValues.clientsCount !==0 ?
      numOrZero( this.campaignItem.planValues.totalCost / this.campaignItem.planValues.clientsCount,2) : 0;

    // Средняя выручка от одного клиента текущая
    this.campaignItem.planValues.clientTotal = (numOrZero(this.targetGroupInfo?.extended?.timeSize) * numOrZero(this.campaignItem?.planValues?.clientsCount) !== 0) ?
      numOrZero(
      ( numOrZero(this.targetGroupInfo?.extended?.total) / numOrZero(this.targetGroupInfo?.extended?.timeSize) * numOrZero(this.campaignItem?.planValues?.clientsCount) ) *
        numOrZero(this.campaignItem?.planValues?.responsePercent) * numOrZero(this.campaignTimeCount?.trigger?.hours)
      ) : 0


    this.campaignItem.planValues.clientSelling = numOrZero(
      numOrZero(this.campaignItem?.planValues?.clientTotal) * ( numOrZero(this.campaignItem?.planValues?.clientTotalPercent) / 100 + 1 )
      , 2 );

    this.campaignItem.planValues.clientTotalPercent = numOrZero(this.campaignItem?.planValues?.clientTotal) !== 0 ?
      numOrZero(
      (  numOrZero(this.campaignItem?.planValues?.clientSelling) - numOrZero(this.campaignItem?.planValues?.clientTotal ) ) /
             numOrZero(this.campaignItem?.planValues?.clientTotal ) * 100, 2
    ) : 0;

    // Средний доход от одного клиента
    this.campaignItem.planValues.clientIncome = numOrZero(
      numOrZero( this.campaignItem?.planValues?.clientTotal) - numOrZero( this.campaignItem?.planValues?.totalByClient)
      ,2);


    // План по росту выручки
    this.campaignItem.planValues.clientSellingTotal = numOrZero(
      numOrZero( this.campaignItem?.planValues?.clientSelling) * numOrZero( this.campaignItem?.planValues?.responseCount)
      ,2);


    // План по росту дохода
    this.campaignItem.planValues.clientIncomePercent = numOrZero(
      numOrZero( this.campaignItem?.planValues?.clientSellingTotal) - numOrZero( this.campaignItem?.planValues?.totalCost)
      ,2);

  }

  public recalcAudience() {

    setTimeout(() => {

      if ( typeof this.subscribers?.campaignTargetInfo?.unsubscribe === "function" ) {
        this.subscribers?.campaignTargetInfo?.unsubscribe();
      }

      this.subscribers.campaignTargetInfo =
        this.campaignService.campaignTargetInfo$({
          includeSegmentId: this.campaignItem?.campaign?.exceptSegmentId || undefined,
          includeGroupId: this.campaignItem?.campaign?.groupId || undefined,
          excludeSegmentId: this.campaignItem?.campaign?.segmentId || undefined,
          excludeGroupIds: this.campaignItem?.campaign?.exceptGroupIds || undefined
        })
          .pipe(
            this.loader.waitLoading('campaignTargetInfo')
          )
          .subscribe({
          next: result => {
            this.targetGroupInfo = result;
            this.recalcPlan();
          }
        })

    })

  }

  public recalcCampaignTime() {
    if (
      !this.campaignItem?.campaign?.campaignTime?.startDate ||
      !this.campaignItem?.campaign?.campaignTime?.stopDate
    )
      return;

    this.campaignService?.
      campaignTimeCount$( this.campaignItem?.campaign?.campaignTime )
      .subscribe({
        next: result => {
          this.campaignTimeCount = result;
          this.recalcPlan();
        },
        error: error => {
          error?.stopPopupError();
        }
      })

  }

  public recalcCouponUntil() {
    this.settingsObj.hasCouponIssueUntil = false;
    if (!this?.campaignItem?.campaign?.mechanicId || !this?.campaignItem?.campaign?.campaignTime?.stopDate) {
      return
    }

    let stopDate = (<any>this?.campaignItem?.campaign?.campaignTime?.stopDate) instanceof Date ?
      new Date(
        (<any>this?.campaignItem?.campaign?.campaignTime?.stopDate)?.getFullYear(),
        (<any>this?.campaignItem?.campaign?.campaignTime?.stopDate)?.getMonth(),
        (<any>this?.campaignItem?.campaign?.campaignTime?.stopDate)?.getDate(),
      0,
      0,
      0
    ) : new Date(Date.parse(this?.campaignItem?.campaign?.campaignTime?.stopDate))

    this.campaignService.checkCouponRuleIssueUntil$({
      mechanicId: this?.campaignItem?.campaign?.mechanicId
    })
      .subscribe( {
        next:   (val) => {
          if (!val || !Array.isArray(val)) {
            this.settingsObj.hasCouponIssueUntil = false;
            return;
          }

          this.settingsObj.hasCouponIssueUntil = val.some( i => new Date(Date.parse(i.issueUntil)) < stopDate);

        }
      })

  }

  public onChangePartner() {
    this.locationGroupsId = undefined;
    if (this.campaignItem) {
      this.campaignItem.location = [];
    }

  }


  protected readonly unescape = unescape;
}
