import {IResourceFieldOptions} from "./ngResourceFilter.class";
import {QueryHandler} from './../../QueryHandler.class';

export class ngResourceFilterCustom {

    private _enabled = true;
    private _fields: any = {};

  static $inject = ['options','paramName','_resetFunction','startOptions'];

    constructor(private options: IResourceFieldOptions,
                private paramName: any,
                private _resetFunction: any,
                startOptions: any) {

        this._bindFilterFields();
        this._setDefault(startOptions);

    }


    public getParamQuery = function () {

        if (!this._enabled) {
            return undefined;
        }

        return QueryHandler.setValueByPath(
            {},
            this.paramName,
            Object
                .keys(this.options.values)
                .reduce( ( obj: any, val: any ) =>{

                    if ( typeof this._fields[val] !== "undefined" ) {
                        obj = obj || {};
                        obj[val] = this._fields[val];
                    }
                    return obj;

                 }, undefined)
        );

    };

    get $enabled() {
        return this._enabled;
    }

    set $enabled(value: boolean) {
        this._enabled = value;
        this._resetFunction();
    }

    private _bindFilterFields = function () {

        for (let alias in this.options.values) {

            Object.defineProperty(this, alias, {
                get: function () {
                    return this._fields[alias];
                },
                set: function (value: any) {
                    this._changeFilterValue(alias, value);
                },
                enumerable: true,
                configurable: true
            });

        };

    }

    private _changeFilterValue = function (alias: string, value: any) {

        this._fields[alias] = value;
        this._resetFunction();

    }

    private _setDefault = function (startOptions: any) {

        if ( !!QueryHandler.getValueByPath(startOptions, this.paramName) ) {
            this._fields = QueryHandler.getValueByPath(startOptions, this.paramName);
        }

    }
}

