'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";

import * as  template   from  './views/index.html';
import controller from './controllers';

import 'ng-infinite-scroll';
import 'angular-file-upload';

import './factorys';

import { TEMPLATES_ID } from './templates';
import { UserCapabilitiesTypes } from "module/permissions/conf/userCapabilities";


import {ClientTemplatesProvider} from "class/ClientTemplates/ClientTemplates";

let routePath = module.name + '.crm.clients.view';
module.requires = module.requires.concat([

]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')

                .template(template)
                .controller(controller)

                .resolve('clientItem', ['ClientModel', '$stateParams', 'loyaPermissions',
                    function (ClientModel, $stateParams, loyaPermissions) {

                        if ( typeof $stateParams.id === "undefined" ||
                                 $stateParams.id === ""             ||
                                 $stateParams.id === null           ||
                                 $stateParams.id == 'worksheet' ) {

                                if ( !!loyaPermissions.byPath('crm.client') && !loyaPermissions.byPath('crm.client').canCreate ){
                                    loyaPermissions.redirectToNoPermission();
                                }

                                return new ClientModel({ client: {}, profiles: {} });
                            }
                            else  return ClientModel.get($stateParams).$promise;
                    }] )

                .resolve('clientViewTemplate', [ 'Settings',  ( Settings : any ) => Settings.query().$promise.then( ( arr : any ) =>
                    arr.reduce(
                        (acc, item) => item.key === 'frontend.template' ? (
                            typeof TEMPLATES_ID[item.value.toUpperCase()] !== 'undefined' ? TEMPLATES_ID[item.value.toUpperCase()] : TEMPLATES_ID.DEFAULT
                            )
                            : acc ,
                        TEMPLATES_ID.DEFAULT
                    )
                    ).catch(
                        _ => TEMPLATES_ID.DEFAULT
                    )])

                .resolve('clientTemplateList', ['ClientTemplateModel', 'clientItem', '$q', 'clientViewTemplate',
                    function (ClientTemplateModel, clientItem, $q, clientViewTemplate) {

                    return $q(function (resolve, reject) {

                        ClientTemplatesProvider
                            .getInstance()
                            .templateList
                            .then(function (clientTemplateList) {

                                var resultClientTemplateList = [],
                                    maxPosition = 0,
                                    maxId = 0;


                                clientTemplateList.push(new ClientTemplateModel(
                                    {
                                        componentTypeId: "System",
                                        date: new Date().getTime(),
                                        description: "",
                                        id: 99999,
                                        name: "Служебная информация",
                                        position: 99999,
                                        virtual: true
                                    }
                                ));

                                angular.forEach(clientTemplateList, function (clientTemplateItem) {

                                    if ( clientTemplateItem.componentTypeId === "Service" ) {
                                        clientTemplateItem.id       = maxId + 1;
                                        clientTemplateItem.position = maxPosition + 1;
                                    } else {
                                        maxPosition = (maxPosition < clientTemplateItem.position) ? clientTemplateItem.position : maxPosition;
                                        maxId = (maxPosition < clientTemplateItem.id) ? clientTemplateItem.id : maxId;
                                    }

                                    switch (clientViewTemplate) {
                                        case TEMPLATES_ID.SOSEDI:

                                            if ( ["Address", "System"].indexOf( clientTemplateItem.componentTypeId ) >=0 ) {
                                                clientTemplateItem.isTop = true;
                                            }

                                        break;
                                    }

                                    if ( ["Address",  "Promotion", "Preference", "Communication", "Behavior" ].indexOf( clientTemplateItem.componentTypeId ) >=0) {
                                      clientTemplateItem.virtual =  true;
                                    }

                                    resultClientTemplateList.push(clientTemplateItem);

                                });


                                if (clientItem.client.id) {
                                    resultClientTemplateList.push(new ClientTemplateModel(
                                        {
                                            componentTypeId: "Transactions",
                                            date: new Date().getTime(),
                                            description: "",
                                            id: maxId + 2,
                                            name: "История",
                                            position: maxPosition + 2,
                                            virtual: true
                                        }
                                    ));
                                }

                                resolve( resultClientTemplateList );
                        });
                    });
                }]  )

                .breadcrumbs(  ["Клиенты", "Просмотр", "Инфо о клиенте"] )

                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.InputOperator, RoleType.Infocenter],
                })
            ;

        }]);
