export const CAMPAIGN_STATES = {
  'draft'    : "draft",
  'suspended': "suspended",
  'unsuspend': "unsuspend",
  'planned'  : "planned",
  'started'  : "started",
  'active'   : "active",
  'completed': "completed",
  'archive'  : "archive"
}

export const CAMPAIGN_STATE_NAMES = {

  [CAMPAIGN_STATES.draft]: "Черновик",
  [CAMPAIGN_STATES.suspended]: "Приостановлена",
  [CAMPAIGN_STATES.unsuspend]: "Разморожена",
  [CAMPAIGN_STATES.planned]  : "Запланирована",
  [CAMPAIGN_STATES.started]  : "Запущена",
  [CAMPAIGN_STATES.active]   : "Активна",
  [CAMPAIGN_STATES.completed]: "Завершена",
  [CAMPAIGN_STATES.archive]  : "В архиве"

}

export const CAMPAIGN_STATES_COLORS = {
  [CAMPAIGN_STATES.draft]: "warning",
  [CAMPAIGN_STATES.suspended]: "primary",
  [CAMPAIGN_STATES.unsuspend]: "primary",
  [CAMPAIGN_STATES.planned]  : "warning",
  [CAMPAIGN_STATES.started]  : "success",
  [CAMPAIGN_STATES.active]   : "success",
  [CAMPAIGN_STATES.completed]: "info",
  [CAMPAIGN_STATES.archive]  : "danger"
}

export enum CAMPAIGN_COMPLETENESS  {
  'error' = 'error',
  'unknown' = 'unknown',
  'started' = 'started',
  'finished' = 'finished'
}

export const CAMPAIGN_COMPLETENESS_VALUES = {
  'error': 'Ошибка',
  'unknown': 'Неизвестно',
  'started': 'Начато',
  'finished': 'Закончено'
}

export const CAMPAIGN_COMPLETENESS_VALUES_STATES =  {
  [CAMPAIGN_STATES.draft]: {
    'error': 'В процессе...',
    'unknown': 'В процессе...',
    'started': 'В процессе...',
    'finished': ''
  },
  [CAMPAIGN_STATES.suspended]: {
    'error': 'Останавливается...',
    'unknown': 'Останавливается...',
    'started': 'Останавливается...',
    'finished': ''
  },
  [CAMPAIGN_STATES.unsuspend]: {
    'error': 'Запускается...',
    'unknown': 'Запускается...',
    'started': 'Запускается...',
    'finished': ''
  },
  [CAMPAIGN_STATES.planned]: {
    'error': 'В процессе...',
    'unknown': 'В процессе...',
    'started': 'В процессе...',
    'finished': ''
  },
  [CAMPAIGN_STATES.started]: {
    'error': 'Запускается...',
    'unknown': 'Запускается...',
    'started': 'Запускается...',
    'finished': ''
  },
  [CAMPAIGN_STATES.active]: {
    'error': 'Активизируется...',
    'unknown': 'Активизируется...',
    'started': 'Активизируется...',
    'finished': ''
  },
  [CAMPAIGN_STATES.completed]: {
    'error': 'Завершается...',
    'unknown': 'Завершается...',
    'started': 'Завершается...',
    'finished': ''
  },
  [CAMPAIGN_STATES.archive]: {
    'error': 'Архивируется...',
    'unknown': 'Архивируется...',
    'started': 'Архивируется...',
    'finished': ''
  }
}

export enum CAMPAIGN_ACTIONS {

  "cancel" = "cancel",
  "run"= "run",
  "pause" = "pause",
  "completed" = "completed",
  "archive" = "archive",

  "clone" = "clone",
  "disabled" = "disabled",

  "edit" = "edit"

}


export const CAMPAIGN_STATES_TREE = {

  [CAMPAIGN_STATES.draft]: {
    [CAMPAIGN_ACTIONS.run] : CAMPAIGN_STATES.planned,
    [CAMPAIGN_ACTIONS.pause] : false,   //  Просто для показа выключенных кнопок
    [CAMPAIGN_ACTIONS.edit] : true,  //  Показ включенных кнопок
  },
  [CAMPAIGN_STATES.planned]: {
    [CAMPAIGN_ACTIONS.cancel] : CAMPAIGN_STATES.draft,
    [CAMPAIGN_ACTIONS.run] : false,
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
  },
  [CAMPAIGN_STATES.suspended]: {
    [CAMPAIGN_ACTIONS.completed] : CAMPAIGN_STATES.completed,
    [CAMPAIGN_ACTIONS.run] : CAMPAIGN_STATES.unsuspend,
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
    [CAMPAIGN_ACTIONS.edit] : true,
  },
  [CAMPAIGN_STATES.started]: {
    [CAMPAIGN_ACTIONS.pause] : CAMPAIGN_STATES.suspended,
    [CAMPAIGN_ACTIONS.run] : false,
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
  },
  [CAMPAIGN_STATES.active]: {
    [CAMPAIGN_ACTIONS.pause] : CAMPAIGN_STATES.suspended,
    [CAMPAIGN_ACTIONS.run] : false,
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
  },
  [CAMPAIGN_STATES.completed]: {
    [CAMPAIGN_ACTIONS.archive] : CAMPAIGN_STATES.archive,
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
  },
  [CAMPAIGN_STATES.archive]: {
    [CAMPAIGN_ACTIONS.clone] : CAMPAIGN_STATES.draft,
  }

}
