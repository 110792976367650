<div class="fixed-tool-panel p-l-xs">


  <filter-radio
    [label]="this.filterTypes[this.filterType]"
    [(model)]="this.filterType"
    [inputValues]="this.filterTypes"
    (onChange)="saveFilter()"
  >
  </filter-radio>

</div>


<responsive-table>
  <table
    class="table b-b "
  >
    <thead>
    <tr>
      <th width="1">
      </th>

      <th width="1" class="p-0">
      </th>

      <th>
        Компонент
      </th>

      <th class="text-center">
        Версия
      </th>
      <th>
      </th>
    </tr>
    </thead>

    <tbody>
    <ng-container  *ngFor="let item of preparedModulesList | callback: filterModules  ">

      <tr
        [class.open-row]="openModuleId === item.name"
        [attr.data-testid]="'module_row'"
      >
        <td class=" v-middle">

              <img
                class="logo-module"
                [src]="item.logo"/>


        </td>
        <td class="p-0 v-align">
          <a href="javascript:void(0)"
             (click)="openModuleId = openModuleId !== item.name ? item.name : '' "
             [attr.data-testid]="'module_showMore'"
          >
            <i class="fa text-muted"
               [class.fa-chevron-down]="openModuleId !== item.name"
               [class.fa-chevron-up]="openModuleId === item.name"
            ></i>
          </a>
        </td>
        <td>
          <span [attr.data-testid]="'module_name'">
            {{item.description}}
          </span>
          <div
            *ngIf="!!item.tags"
          >
            <span class="badge  bg-primary m-r-xs module-badge" data-testid="module.type">{{ item.type }}</span>

             <span *ngFor="let tag of item.tags" class="badge module-badge bg-success m-r-xs"
                   data-testid="module.tag">{{ tag }}</span>
          </div>
        </td>

        <td class="text-center"
            [attr.data-testid]="'module_version'"
        >
          {{item.version}}
        </td>

        <td class="text-right">
          <ng-container
            *ngIf="item.config"
          >
            <button class="btn btn-success btn-sm btn-addon   "
                    [attr.data-testid]="'module_configure'"
                    [uiSref]="'^.config({module:\''+item.name+'\'})'"
            >
              <i class="fa fa-gears"></i>Сконфигурировать
            </button>
          </ng-container>
        </td>

      </tr>

      <tr
        *ngIf="openModuleId === item.name"
      >
        <td class="p-0"></td>
        <td class="p-0"></td>
        <td colspan="9999"
            [innerHTML]="item.indexView"
            [attr.data-testid]="'module_description'">
        >
        </td>
      </tr>

    </ng-container>
    </tbody>
  </table>
</responsive-table>

<div class="text-center text-warning m-t-lg m-b-lg"
     *ngIf="loader.isLoading('list')"
>
  <div class="">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>
