export enum FieldTypes {

    text   = "string" ,
    number = "int",
    date   = "date",
    switch = "bool",
    select = "singleSelect",
    multiselect = "multiSelect"

}

export interface ConfigValueItems {

    id    : string,
    label : string,
    $isNew ?: boolean
}

export interface ConfigFieldItem {

    positionId : number,
    dataType   : FieldTypes,
    label      : string,
    id         : string,
    visible    : boolean,
    required   : boolean,
    isFixed   ?: boolean,
    values    ?: Array<ConfigValueItems>,
    ruleType  ?: Array<string> | string,

    $isNew    ?: boolean //для флага только что добалвенной
    $visibleDisabled ?: boolean //для флага только что добалвенной
    $requiredDisabled ?: boolean //для флага только что добалвенной

}

export interface SettingsForPlugin {
    [pluginNam: string]: Array<ConfigFieldItem>
}

export interface IStaticField {
    id        : string,
    label     : string,
    dataType  : FieldTypes,
    path     ? : string,
    required ?:boolean,
    visible  ?: boolean,
    ruleType ?: Array<string> | string
}

export class ConfigModuleService {

    constructor( private _fields: Array<ConfigFieldItem>, private staticFields: Array<IStaticField> ) {
        this._fields.map( this.removeMetadata );
        this.addStaticFields();
        this.updateTypes();
    }

    public fieldTypes = [
        { id: FieldTypes.text,   label: 'Строковый' },
        { id: FieldTypes.number, label: 'Числовой' },
        { id: FieldTypes.date,   label: 'Дата' },
        { id: FieldTypes.switch, label: 'Переключатель' },
        { id: FieldTypes.select, label: 'Справочник' },
        { id: FieldTypes.multiselect, label: 'Справочник (мультивыбор)' },
    ];

    get fields() {
        return this._fields;
    }

    private removeMetadata( i : any) {

        Object.keys(i).forEach( index => {

            if (index[0] === '$') {
                delete i[index];
            }

        });

        return i;
    }

    public addStaticFields() {

        if ( !Array.isArray(this.staticFields) )
            return;

        this.staticFields.forEach( staticField => {


            const finded =  this.fields.find( i => i.id === staticField.id );

            if ( finded  ) {

                finded.$visibleDisabled  =  typeof staticField.visible !=="undefined";

                finded.required           =  typeof finded.required !=="undefined" ? finded.required :  staticField.required ;
                finded.$requiredDisabled  =  typeof staticField.required !=="undefined";

            } else {

                this.fields.push({
                    positionId : this.maxPosition + 1,
                    dataType   : staticField.dataType,
                    id         : staticField.id,
                    label      : staticField.label,
                    required   : !!staticField.required,
                    visible    : typeof staticField.visible !=="undefined" ? staticField.visible : true,
                    isFixed    : true,
                    ruleType   : staticField.ruleType,
                    $visibleDisabled  : typeof staticField.visible !=="undefined",
                    $requiredDisabled : typeof staticField.required !=="undefined"
                });
            }

        });

    }

    public updateTypes() {

        this.fields.forEach( item => {

            if ( item.dataType === FieldTypes.select || item.dataType === FieldTypes.multiselect ) {

                item.values = item.values || [];
                if ( !item.values.length )
                    this.addCustomValueRow(item);

            }

        });

    }

    public addCustomValueRow( field: ConfigFieldItem ) {

        if ( !field ) {
            return;
        }

        field.values = field.values || [];
        field.values.push({id: "", label: "", $isNew: true });
    }

    public removeCustomValueRow( values: Array<ConfigValueItems>, item: ConfigValueItems ) {
        if ( values.indexOf( item) >=0 ) {
            values.splice( values.indexOf(item) , 1 );
        }

        if (values.length === 0) {
            this.addCustomValueRow( <ConfigFieldItem>{ values : values}  );
        }
    }

    public upPosition( item : ConfigFieldItem ) {

        let prevItem = this.fields
            .filter( i => i.positionId < item.positionId)
            .sort( (p,n) => n.positionId - p.positionId );

        if ( !prevItem.length ) return;

        [item.positionId, prevItem[0].positionId] = [prevItem[0].positionId, item.positionId];
    }

    public downPosition( item : ConfigFieldItem ) {

        let nextItem = this.fields
                           .filter( i => i.positionId > item.positionId)
                           .sort( (p,n) => p.positionId - n.positionId );

        if ( !nextItem.length ) return;

        [item.positionId, nextItem[0].positionId] = [nextItem[0].positionId, item.positionId];
    }

    get maxPosition() {
        return this.fields.reduce((maxId, i) => i.positionId > maxId ? i.positionId : maxId, 0);
    }

    public addNewItem() {
        this._fields.push({
            positionId : this.maxPosition + 1,
            dataType   : FieldTypes.text,
            id         : "",
            label      : "",
            required   : false,
            visible    : true,
            $isNew     : true
        });

    }

    public removeItem( item : ConfigFieldItem ) {

        if ( item.isFixed )
            return;

        if ( this.fields.indexOf( item ) >= 0 ) {
            this.fields.splice( this.fields.indexOf( item ), 1);
        }

    }

    public getSettingsForSave() {

        if ( !this.isValid() ) return false;

        let result : Array<ConfigFieldItem> = [];

        this.fields.forEach( item => {

            let copyItem = Object.assign({}, item);

            if (copyItem.dataType !== FieldTypes.select && copyItem.dataType !== FieldTypes.multiselect ) {
                delete copyItem.values;
            }

            if (!copyItem.visible) {
                copyItem.required = false;
            }

            if ( copyItem.values && !!copyItem.values.length) {
                copyItem.values = copyItem.values.map( i => this.removeMetadata(i));
            }

            result.push( this.removeMetadata(copyItem) );
        });

        return result;

    }

    public isValid() {
        return !this.fields.some( item => {

            if ( !item.label || !item.id ) return true;

            if ( item.dataType === FieldTypes.select  || item.dataType === FieldTypes.multiselect) {

                if ( !item.values || !item.values.length || item.values.some(i => !i.label || !i.id ) )
                    return true;

            }

            return false;

        })
    }

    public validateExistingId( value: string, field: ConfigFieldItem, fields: Array<ConfigFieldItem>, errors: Array<string> ) {

        const finded = fields.find( i => i !== field && i.id === value);
        if ( finded ) {
            errors.push("Заданный ID уже используется в " + finded.label);
        } else {
            errors.splice(0,errors.length);
        }
    }
}

