'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.mechaniccategory.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<mechaniccategory-index-page></mechaniccategory-index-page>', true)
        .breadcrumbs(  [ "Справочники", "Типы механик" ] );

    }])
;
