import 'model/Settings';

const I18S = {
    'ru': {
        icon      : 'fa-rub',
        shortName : 'руб',
        fullName  : 'Рубли',
    },
    'br': {
        icon      : 'fa-cur-br',
        shortName : 'руб',
        fullName  : 'Рубли',
    },
    'ua': {
        icon      : 'fa-cur-grn',
        shortName : 'грн',
        fullName  : 'Гривны',
    },
    'kzt': {
      icon      : 'fa-cur-kzt',
      shortName : 'тңг',
      fullName  : 'Тенге',
    }
};

export class ngCurrencyL10nClass {

    static $inject = ['$http', 'Settings'];
    protected  currentCurrency  = 'ru';
    protected promiseLoading: any = false;

    constructor(
        protected $http: any,
        private Settings: any
    ) {
        this.getCurrentCerrency();
    }

    protected getCurrentCerrency() {

        this.promiseLoading = this.Settings
            .query()
            .$promise
            .then( (arr: Array<any>) => {
                this.promiseLoading = false;
                const i18n = arr.find( i => i.key === 'frontend.i18n.currency' );
                this.currentCurrency = i18n ? i18n.value : 'ru';

            }).catch( () => {
                this.promiseLoading = false;
                this.currentCurrency = 'ru';
            } );

    }

    get icon() {
        return I18S[this.currentCurrency].icon;
    }

    get shortName() {
        return I18S[this.currentCurrency].shortName;
    }

    get fullName() {
        return I18S[this.currentCurrency].fullName;
    }

    public waitLoading() {

        if ( this.promiseLoading !== false ) {
            return new Promise( resolve => {
                this.promiseLoading.then( () => { setTimeout(resolve)}, () => setTimeout(resolve) );
            });
        }

        return Promise.resolve();
    }

    public getIcon() {

        if ( this.promiseLoading !== false ) {
            return new Promise( resolve => {
                this.promiseLoading.then( () => resolve( this.icon ), () => resolve( this.icon ) );
            });
        }

        return Promise.resolve( this.icon );
    }

    public getShortName() {

        if ( this.promiseLoading !== false ) {
            return new Promise( resolve => {
                this.promiseLoading.then( () => resolve( this.shortName ), () => resolve( this.shortName ) );
            });
        }

        return Promise.resolve(this.shortName);
    }

    public getFullName() {

        if ( this.promiseLoading !== false ) {
            return new Promise( resolve => {
                this.promiseLoading.then( () => resolve( this.fullName ), () => resolve( this.fullName ) );
            });
        }

        return Promise.resolve(this.fullName);
    }

}
