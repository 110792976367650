import {changeNames} from "../interface/changeTypes";
import {ModuleFieldsHandler} from "class/ModuleFieldsHandler";

export interface FieldInfo {

    moduleName : string;
    fieldName  : string;

}

export const FIELDS_STORAGE_ID = "fields.storage.list";
export interface IFieldStorageItem {
    path  : string;
    label : string;
};

export class FieldHandler  {

    private templateList: Array<any>;

    private _fieldsByGroup : Array<{
        group : string,
        id    : string,
        label : string,
    }> = [];

    private templateNames : { [key: string]: string } = {};

    private blackListForModule : { [key:string]: Array<string> } = {};

    constructor( ) {
        this.init();
    }

    get fieldsByGroup() {
        return this._fieldsByGroup;
    }

    private async init() {
        await this.parseFieldNames();
        await this.parseStorage();
    }

    private async queryTemplates() : Promise<Array<any>> {

        if (this.templateList) {
            return Promise.resolve(this.templateList);
        }
/*
        return await  ClientTemplatesProvider
                            .getInstance()
                            .templateList
                            .then( ( result : Array<any> ) => {
                                if ( Array.isArray(result) ) {

                                    result.unshift( {
                                            componentTypeId: "Common",
                                            date: 1424849289000,
                                            description: "",
                                            id: '',
                                            name: "Экспресс данные",
                                            position: 0
                                    });

                                    result.push({
                                        componentTypeId: "System",
                                        date: 1424849289001,
                                        description: "",
                                        id: '',
                                        name: "Служебная информация",
                                        position: 1
                                    });
                                }

                                this.templateList = result.slice().map(i => Object.assign({}, i));
                                return result;
                            });

 */
    }

    private async parseFieldNames() {

        const arrayOfTemplates = await this.queryTemplates();

        if ( Array.isArray( arrayOfTemplates ) ) {

            arrayOfTemplates.forEach( (template:any) => {
                this.templateNames[ template.componentTypeId.toLowerCase() ] = template.name;
            });

        }

    }

    public getModuleName( id = '' ) {

        id = id.replace(/[0-9_]*/ig,'').toLowerCase();
        return this.templateNames[id];

    }

    public getInfoForPath( moduleId: string, path = '', isCustom: boolean = false )  : FieldInfo {

        let finded;

        if ( moduleId ) {
            finded = this.getFieldByPath( moduleId, path , isCustom);
        } else {
            finded = this.getFieldByPath( 'Common', path , isCustom);
            if (!finded)
                finded = this.getFieldByPath( 'System', path , isCustom);
        }

        return finded ? {
            fieldName  : this.labelFromArray(finded.displayName , path),
            moduleName : this.getModuleName(moduleId)
        } : {
            fieldName  : path,
            moduleName : ''
        };

    }

    private async parseStorage() {

        this._fieldsByGroup = [];

        const arrayOfTemplates = await this.queryTemplates();

        if ( Array.isArray( arrayOfTemplates ) ) {

            arrayOfTemplates.forEach( (template:any) => {

                let mf_handler = ModuleFieldsHandler.getInstanceForModule(template.componentTypeId.toLowerCase());

                    if (!mf_handler)
                        return;

                    const fields = mf_handler.getFieldsForLog();

                    if (!Array.isArray( fields ) )
                        return;

                    fields.forEach( i => {

                        this._fieldsByGroup.push(<any>{
                            group: template.name,
                            label: this.labelFromArray(i.displayName, i.path),
                            id   : template.componentTypeId + ';'+ i.path +  ';' + ( i.isCustom ? '1' : '' )
                        });

                    });

            });

        }

    }

    static convertArraysInPath( path: string) {
        return path.replace(/\/\d+\//ig, "/[]/")
                   .replace(/\/-\//ig, "/[]/")
    }

    private labelFromArray( label: string, path: string ) {

        if ( ! (/\$\d+/ig).test(label) )
            return label;

        (path.match(/\/\d+\//ig) || []).forEach(( value, index) => {
            label = label.replace(`$${index + 1}`, ( parseInt(value.substr(1, value.length -2 ) ) + 1 ) .toString() )
        });

        return label.replace(/\$\d+/ig,'');

    }

    private updateBlcakListForModule( moduleId: string ) {

        if ( this.blackListForModule[moduleId] )
            return;

        let mf_handler = ModuleFieldsHandler.getInstanceForModule(moduleId);

        if (!mf_handler)
            return;

        let fields = mf_handler.getFieldsForLog();
        this.blackListForModule[moduleId] = [];

        if ( fields ) {
            fields
                .filter( (i:any) => i.exclude )
                .forEach( (i:any) => {
                    let arr = Array.isArray( i.exclude ) ? i.exclude : [i.exclude];
                    this.blackListForModule[moduleId] = this.blackListForModule[moduleId].concat( arr );
                });
        }

    }

    private isFieldInBlackList( moduleId: string, fieldPath: string) {

        if ( typeof moduleId !== 'undefined' ) {
            this.updateBlcakListForModule(moduleId);
            return Array.isArray(this.blackListForModule[moduleId]) && this.blackListForModule[moduleId].indexOf(FieldHandler.convertArraysInPath(fieldPath)) >= 0;
        }

        this.updateBlcakListForModule('Common');
        this.updateBlcakListForModule('System');

        return ( Array.isArray(this.blackListForModule['Common']) && this.blackListForModule['Common'].indexOf( FieldHandler.convertArraysInPath(fieldPath) ) >= 0 ) ||
               ( Array.isArray(this.blackListForModule['System']) && this.blackListForModule['System'].indexOf( FieldHandler.convertArraysInPath(fieldPath) ) >= 0 ) ;

    }

    public getFieldByPath( moduleId: string, path: string, isCustom: boolean = false )  {

        if (!moduleId)
            return;

        let mf_handler = ModuleFieldsHandler.getInstanceForModule(moduleId);
        path = FieldHandler.convertArraysInPath(path);

        this.updateBlcakListForModule(moduleId);

        if ( isCustom ) {
            return mf_handler.customFieldByPath(path);
        } else {
            return mf_handler.fieldByPath(path); //   this._fieldsByGroup.find(  item =>  this.convertArraysInPath(item.id ) === path );
        }

    }

    public getValueForPath( moduleId: string, path: string, value: any ) {

        let field;
        if ( moduleId ) {
            field = this.getFieldByPath(moduleId, path);
        } else {
            field = this.getFieldByPath('Common', path) || this.getFieldByPath('System', path);
        }

        if ( field ) {
            if ( Array.isArray( field.options ) && field.options.some( (i:any) => i.id === value ) ) {
                return field.options.find( ( i: any ) => i.id === value ).name || value;
            }
        }

        if ( typeof value === "boolean" || ["true", "false"].indexOf(value) >=0 ) {
            return (value === true || value === "true") ? 'Включено' : 'Выключено';
        }

        return value;
    }


}
