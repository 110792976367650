import {Inject, Pipe, PipeTransform  , DEFAULT_CURRENCY_CODE, LOCALE_ID,} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'loyaCurrency',
})
export class LoyaCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD') {}

  transform(input: number, symbol: any, fractionSize: any): string {

    let curPipe: any =  new CurrencyPipe(this._locale, this._defaultCurrencyCode)

    switch (symbol) {

      case "rub" : return curPipe.transform(input, undefined, "") +
        '<i class="fa fa-ruble text-muted"></i>';

      case "letter" : return curPipe.transform(input, undefined, "") +
        ''; // ???

      case "eur" : return curPipe.transform(input, undefined, "") +
        '<i class="fa fa-fa-eur text-muted"></i>';

      case "usd" : return curPipe.transform(input, undefined, "") +
        '<i class="fa fa-usd text-muted"></i>';

      case "unknown" : return curPipe.transform(input, undefined, "");

      default :
        var tmp =  curPipe.transform(input, undefined, "");
        return typeof tmp === 'string' ? tmp.trim() : tmp;
    }

    return input.toString();
  }
}
