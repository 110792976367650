import {OpTypes} from "../component/referrer-log-history-changes.component";
import {FiltersHandler} from "./FiltersHandler";
import {getNg1Injector} from "class/MigrationUtils";
import {ReferrerService} from "../../../../../shared/service/api/Referrer/Referrer.service";

const LIMIT_PER_PAGE = 30;

export class HistoryLogService  {

    private _list       : Array<any>;
    private currentDrop : number = 0;

    public isLoading : boolean = false;
    public isEnd     : boolean = false;

    get list() {
        return this._list;
    }

    constructor(
        private filterName    : string,
        private filterValue   : number,
        private filterHandler : FiltersHandler,
        private referrerService: ReferrerService,
    ) {
        this.init();
        this.filterHandler.onUpdate( this.filterUpdated.bind(this) );
    }

    private init() {
        this.pager(0);
    }

    private pager( drop: number = this.currentDrop ) {

        if ( drop === 0 ) {
            this._list = [];
            this.isEnd = false;
            this.currentDrop = 0;
        }

        if ( this.isLoading || this.isEnd) return ;

        let params = this.filterHandler.queryParams  || {};

        params['drop']     = drop ;
        params['limit']    = LIMIT_PER_PAGE;

        if ( this.filterName ) {
            params[this.filterName] = this.filterValue;
        }

        this.isLoading = true;

        return this.referrerService
                .getChangelog$(params)
                .toPromise()
                    .then( (result: Array<any>) => {

                        this._list = this._list || [];
                        this._list = this._list.concat(result);

                        this.isLoading = false;

                        this.currentDrop += result.length;

                        if ( Array.isArray(result) && result.length < LIMIT_PER_PAGE ) {
                            this.isEnd = true;
                        }

                        return this._list;
                    }, () => {
                        this.isLoading = false;
                        this.isEnd = false;
                        return Promise.resolve()
                    });

    }

    public prev() {
        this.pager()
    }

    public next() {
        this.pager()
    }

    private filterUpdated():Promise<any>{
        return this.pager(0);
    }

}
