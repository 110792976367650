import {Component, Input, OnDestroy, OnInit} from "@angular/core";

import "./segments-view-page.legacy"
import {SegmentationService} from "../../../shared/service/api/Segment/Segment.service";
import {Segment} from "../../../api/SegmentationApi/models/segment";
import {LoaderHandlerClass} from "../../../shared/class/loader-handler.class";
import {SEGMENT_STATES} from "../../../shared/service/api/Segment/Segment.values";
import {LOYA_CONFIG} from "../../../shared/variables/loya-config.variable";
import {StatesChangerService} from "../../../shared/service/statesChanger/statesChanger.service";

@Component({
  selector: 'segments-view-page',
  templateUrl: './segments-view-page.component.html',
  styles:[
    `
        .pref-table tr td:nth-child(1) {
          width: 30%;
        }
        .pref-table tr:nth-child(1) td {
          border-top: none
        }
    `
  ],
  providers: [
    SegmentationService,
    StatesChangerService
  ]
})
export class SegmentsViewPageComponent implements OnInit, OnDestroy {

  @Input() id;

  public loader = new LoaderHandlerClass();
  public segmentItem: Segment;

  public totalPreference = 0;

  public LOYA_CONFIG = LOYA_CONFIG;
  private subscribers$:any = {};

  constructor(
    public segmentationService: SegmentationService,
    public statesChangerService: StatesChangerService,
  ) {
  }

  get isArchive() {
    return this.segmentItem?.state === SEGMENT_STATES.archive;
  }

  ngOnInit() {
    this.bindButtons();
    this.getSegment();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  onSave() {
    this.segmentationService?.update$(
      this.id,
      this.segmentItem
    )
    .pipe(
      this.loader.waitLoading('segment')
    )
    .subscribe( result => {
      this.loader.startLoading('segment');
      this.getSegment();
    })

  }

  onCancel() {
    this.getSegment();
  }

  getSegment() {

    if ( !this.id )
      return;

    this.segmentationService
      .get$(this.id)
      .pipe(
        this.loader.waitLoading('segment')
      )
      .subscribe( ( segment: any ) => {
        this.segmentItem = segment;
        this.calculate();
        this.statesChangerService.state = 'view';
      })

  }

  getClientsPercent() {
    try {
      return  this.segmentItem.totalClientsCount > 0 ? this.segmentItem.clientCount * 100 / this.segmentItem.totalClientsCount : 0;
    } catch (e) {
      return 0;
    }
  }

  getTotalPercent() {
    try {
      return  this.segmentItem.totalLifeValue > 0  ? this.segmentItem.period0.total * 100 / this.segmentItem.totalLifeValue : 0;
    } catch (e) {
      return 0;
    }
  }

  getSmsPercent() {
    try {
      return  this.segmentItem.clientCount > 0  ? this.segmentItem.communication.smsChannel * 100 / this.segmentItem.clientCount : 0;
    } catch (e) {
      return 0;
    }
  }

  getEmailPercent() {
    try {
      return  this.segmentItem.clientCount > 0  ? this.segmentItem.communication.emailChannel * 100 / this.segmentItem.clientCount : 0;
    } catch (e) {
      return 0;
    }
  }

  getEmptyPercent() {
    try {
      return  this.segmentItem.clientCount > 0  ? this.segmentItem.communication.emptyChannel * 100 / this.segmentItem.clientCount : 0;
    } catch (e) {
      return 0;
    }
  }

  calculate() {

    this.totalPreference = 0;
    if ( Array.isArray(this.segmentItem?.clientDescription?.preferences ) ) {
      this.totalPreference = this.segmentItem?.clientDescription?.preferences?.reduce( ( acc, item ) => {
          item.total = parseFloat(item?.total) || 0;
          return acc + item.total
        },
      0);
    }

  }

  //------------------
/*

  get hasSegments() {
    return Array.isArray(this.segmentsList) && !!this.segmentsList?.length;
  }

  get isDraft() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.draft;
  }

  get isRefresh() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.refresh;
  }

  get isDeploy() {
    return this.hasSegments && this.segmentsList[0]?.state === SEGMENT_STATES.deploy;
  }

  get isUpdatingProcess() {
    return this.loader?.isLoading('refresh') ||
      this.loader?.isLoading('loadingList') ||
      this.loader?.isLoading('deploy') ||
      this.loader?.isLoading('delete')
  }

  get hasRefreshSegments() {
    return !!this.segmentsList.find( this.isSegmentRefresh )
  }

  public isSegmentRefresh(segment: Segment = <any>{}) {
    return segment.completeness==='started' || segment.completeness==='unknown'
  }

  public updateList() {

    this.subscribers$.getList =
      this.segmentationService.getList$()
        .pipe(
          this.loader.waitLoading('loadingList')
        )
        .subscribe( (r:any) => {
          this.segmentsList = r;
          this.calculator.segments(this.segmentsList);
          this.updateStatuses();
        })

  }

  public refreshClients() {

    this.segmentationService.refresh$()
      .pipe(
        this.loader.waitLoading('refresh')
      )
      .subscribe( (r:any) => {
        this.updateList();
      })

  }

  public deploy(updateCampaign = false) {

    this.subscribers$.deploy =
      this.segmentationService.deploy$(updateCampaign)
        .pipe(
          this.loader.waitLoading('deploy')
        )
        .subscribe( (r:any) => {
          this.updateList();
        })

  }

  public cancel() {

    this.segmentationService.delete$()
      .pipe(
        this.loader.waitLoading('delete')
      )
      .subscribe( (r:any) => {
        this.updateList();
      })

  }

  public updateStatuses() {

    this.subscribers$?.updater?.unsubscribe();

    let idsForUpdate = this.segmentsList
      .filter( this.isSegmentRefresh )
      .map( segment => segment?.id);

    if (!idsForUpdate?.length)
      return

    this.subscribers$.updater = this.segmentationService
        .waitStates$(idsForUpdate)
        .subscribe( {
          next: segment => {
            let finded = this.segmentsList.find( i => i.id === segment.id );
            if (!finded)
              return;

            finded.state = segment.state;
            finded.completeness = segment.completeness;
          },
          complete: () => {
          }
        });


  }
*/

  ngOnDestroy() {
    Object.values(this.subscribers$).forEach( (sub: any) => {
      sub && sub?.unsubscribe();
    })
  }

}
