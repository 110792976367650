'use strict';

import module from 'frontendModule';

import 'component/phoneInput/main';

import { UserPhoneInput } from './controller';
import * as  templateView    from  './index.html';

export default module.component('userPhoneInput', {
    templateUrl : templateView,
    controller  : UserPhoneInput,

    bindings : {
        model    : '=',
        phoneConfirmed : '=',
        isEdit   : '<',
        clientId : '<',
        required : '<'
    }

} );

