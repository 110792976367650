<form-container>

  <div [form-item-row] = "'Название'"
       form-item-row-input="true"
       id="anchorI1"
  >
    <input
      type="text"
      class="form-control form-control-edittable"
      [(ngModel)]="campaignItem.campaign.name"
      data-testid="campaign.input.name"
      name="CampaignItem_campaign_name"
      placeholder="Введите название кампании"
      [required]="true"
      maxlength="100"
      [disabled]="isSuspend"
    >
  </div>

  <div [form-item-row] = "'Описание'"
       form-item-row-input="true"
  >
     <textarea
       class="form-control form-control-edittable"
       [(ngModel)]="campaignItem.campaign.description"
       data-testid="campaign.input.description"
       name="CampaignItem_campaign_description"
       placeholder="Описание кампании"
       [required]="true"
       maxlength="500"
       [disabled]="isSuspend"
     > </textarea>
  </div>

  <div [form-item-row] = "'Тэги кампании'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top:0.75rem"
  >
    <campaign-tags
      [(model)]="campaignItem.campaign.listOfTags"
      data-testid="campaign.listOfTags"
      [isView]="false"
    ></campaign-tags>
  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted">Параметры</span>
  </div>

  <div [form-item-row] = "'Партнер'"
       form-item-row-input="true"
  >
    <select-partner
      [(id)]="campaignItem.campaign.partnerId"
      (idChange)="onChangePartner()"
      data-testid="campaign.select.partner"
      [required]="true"
      [disabled]="isSuspend"
    ></select-partner>
  </div>

  <div [form-item-row] = "'Группа кампании'"
       form-item-row-input="true"
  >
    <select-campaign-category
      [(id)]="campaignItem.campaign.categoryId"
      data-testid="campaign.select.category"
      [required]="true"
      [disabled]="isSuspend"
    ></select-campaign-category>
  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI2">Период и место действия</span>
  </div>

  <div [form-item-row] = "'Период действия'"
       form-item-row-input="true"
  >

    <div class="inline m-r">
      <div class="input-group m-b w w-full-md  w-full-sm w-full-xs m-n">

        <span class="input-group-addon">с</span>

        <input type="date"
               class="inline form-control"
               [(ngModel)]="campaignItem.campaign.campaignTime.startDate"
               (ngModelChange)="recalcCampaignTime();"
               data-testid="campaign.input.campaignTime.startDate"
               name="CampaignItem_campaign_campaignTime_startDate"
               placeholder="Дата начала"
               [required]="true"
               [disabled]="isSuspend"
        >

      </div>
    </div>

    <div class="inline">
      <div class="input-group m-b w w-full-md  w-full-sm w-full-xs m-n">

        <span class="input-group-addon">по</span>

        <input type="date"
               class="inline form-control"
               [(ngModel)]="campaignItem.campaign.campaignTime.stopDate"
               (ngModelChange)="recalcCampaignTime(); recalcCouponUntil()"
               data-testid="campaign.input.campaignTime.stopDate"
               name="CampaignItem_campaign_campaignTime_stopDate"
               placeholder="Дата завершения"
               [required]="true"
               [min]="campaignItem.campaign.campaignTime.startDate"
               [minMaxValidateOnChange]="true"
        >
      </div>
    </div>

  </div>

  <div [form-item-row] = "'Время действия'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.4rem;"
  >
      <div *ngFor="let period of campaignItem.campaign.campaignTime.periods; let last = last; let first = first; let periodIndex = index;"
      >
        <div class="hbox hbox-auto w-auto"
             [ngClass]="{ 'card': campaignItem.campaign.campaignTime.periods.length>1, 'm-l-n' : campaignItem.campaign.campaignTime.periods.length<=1 }">

          <div class="col padder wrapper-xs pos-rlt">

            <div class="clearfix ">

              <div class="clearfix f-s-8 text-muted  m-b-xs"> Время дня</div>

              <div class="inline m-r-sm w-full-md  w-full-sm w-full-xs">

                <div
                  class="input-group m-b w w-full-md w-full-sm w-full-xs m-n">
                  <span class="input-group-addon">с</span>
                  <input
                    type="time"
                    class=" inline form-control"
                    [(ngModel)]="period.startTime"
                    (ngModelChange)="recalcCampaignTime()"
                    data-testid="campaign.input.period.startTime"
                    [name]="'CampaignItem_campaign_campaignTime_startTime' + periodIndex"
                    placeholder="Время начала"
                    [required]="true"
                    step="60"
                  >
                </div>
              </div>

              <div class="inline w-full-md  w-full-sm w-full-xs">
                <div  class="input-group m-b w w-full-md w-full-sm w-full-xs m-n">
                  <span class="input-group-addon">по</span>
                  <input
                    type="time"
                    class=" inline form-control"
                    [(ngModel)]="period.stopTime"
                    (ngModelChange)="recalcCampaignTime()"
                    data-testid="campaign.input.period.stopTime"
                    step="60"
                    [name]="'CampaignItem_campaign_campaignTime_stopTime' + periodIndex"
                    placeholder="Время завершения"
                    [required]="true"
                  >
                </div>
              </div>

            </div>

            <div class="clearfix ">

              <div class="clearfix f-s-8 text-muted m-b-xs "> День недели</div>
              <div
                style="width: 3.7em"
                class="checkbox inline m-t-none m-b-xs m-r-sm"
                *ngFor="let weekDayItem of daysOfWeekDict"
                data-testid="weekDayItem in daysOfWeekDics"
              >
                <label class="i-checks">

                  <input type="checkbox"
                         [checked]="isEnabledInArray(weekDayItem.key, period.daysOfWeek)"
                         (change)="changeWeekDay($event?.currentTarget?.checked, weekDayItem.key, period.daysOfWeek); recalcCampaignTime();"
                         data-testid="campaign.input.daysOfWeekList"
                  >
                  <i></i>
                  {{ weekDayItem.name }}
                </label>
              </div>

            </div>

            <div class="clearfix "
            >

              <div class="clearfix f-s-8  m-t-xs m-b-xs">

                <a href="javascript:void(0)"
                   (click)="showDays[periodIndex] = (showDays[periodIndex] && !isDaysOfMonthChanged(period) ) ? false : true  "
                   data-testid="campaign.daysOfMonth.show()">

                  День месяца
                  <i class="fa"
                     *ngIf="!showDays[periodIndex] || (showDays[periodIndex] && !isDaysOfMonthChanged(period) )"
                     [ngClass]="{ 'fa-angle-down' : !showDays[periodIndex], 'fa-angle-up' : showDays[periodIndex] }"></i>
                </a>

              </div>

              <div *ngIf="showDays[periodIndex]">
                <span *ngFor="let monthDayItem of daysOfMonthDics"
                      data-testid="monthDayItem in daysOfMonthDics">
                <div
                  style="width: 3.7em"
                  class="checkbox inline m-t-none m-b-xs m-r-sm"
                >
                  <label class="i-checks" >

                    <input type="checkbox"
                           [checked]="isEnabledInArray(monthDayItem.key, period.daysOfMonth)"
                           [ngClass]="{ 'checked ': !isDaysOfMonthChanged(period) }"
                           (change)="changeMonthDay($event?.currentTarget?.checked, monthDayItem.key, period); ecalcCampaignTime()"
                           data-testid="campaign.input.daysOfMonthList"
                    >
                    <i
                      [ngClass]="{ 'text-muted ': !isDaysOfMonthChanged(period) }"
                    ></i>
                    {{monthDayItem.name}}
                  </label>
                </div>
                <br *ngIf="monthDayItem.key % 7 === 0" />
              </span>
              </div>

            </div>

          </div>

          <div class="col v-middle bg-light padder b-l "
               *ngIf="campaignItem?.campaign?.campaignTime?.periods?.length>1"
          >

            <button type="button"
                    (click)="campaignItem.campaign.campaignTime.periods.splice(periodIndex, 1)"
                    data-testid="campaign.period.remove()"
                    class="btn btn-sm btn-icon btn-danger ">
              <i class="fa Example of trash-o fa-trash-o"></i>
            </button>

          </div>
          </div>
      </div>

      <div class="clearfix text-left text-center-sm text-center-md text-center-xs w-full  ">
        <button type="button"
                (click)="createCampaignPeriod()"
                data-testid="campaign.createPeriod()"
                class="btn  btn-sm btn-addon btn-success">
          <i class="fa fa-plus"></i>
          <span> Добавить период </span>
        </button>
      </div>

  </div>

  <div [form-item-row] = "'Место проведения'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.1rem;"
  >
    <span *ngIf="!isPartnerSelected"
          class="text-danger d-inline-block m-t-lg">Для выбора места проведения, требуется выбрать партнёра</span>

    <ng-container
      *ngIf="isPartnerSelected"
    >
      <div class="clearfix f-s-8 text-muted m-b-xs">Локация</div>

      <select-location-group
        data-testid="campaign.select.locationGroup"
        [partnerId]="campaignItem?.campaign?.partnerId"
        [(id)]="locationGroupsId"
        [multiple]="true"
        [name]="'selectLocationGroup'"
      ></select-location-group>

      <div class="clearfix f-s-8 text-muted m-b-xs m-t-xs">Магазин</div>

      <div class="btn-group" ngbDropdown>

        <button data-testid="location.choose()"
                class="btn btn-default "
                (click)="selectLocations()"
                [ngClass]="{ 'b b-danger': !hasSelectedLocation() }"
        >
          <span *ngIf="campaignItem?.location?.length">Выбрано магазинов:
              <span [innerText]="campaignItem?.location?.length" data-testid="location.length"></span>
          </span>
          <span class="" *ngIf="!campaignItem?.location?.length">Выбрать магазины</span>
        </button>


        <ul class="scroll-y"
            ngbDropdownMenu
            *ngIf="!!campaignItem?.location?.length"
            style="max-height: 411px;"
        >

          <li *ngFor="let location of campaignItem.location"
              class="d-flex align-items-center m-b-xs m-t-xs">

            <div class="col p-l-sm">
              <span [innerText]="location.name" data-testid="locationChosen.name"></span>
            </div>

            <div class="col-auto no-padder text-center">
              <button class="btn btn-danger btn-xs m-l-xs m-r-sm  "
                      (click)="removeLocation(location); $event.stopImmediatePropagation()"
                      data-testid="locationChosen.remove()">
                <i class="fa fa-times"></i>
              </button>
            </div>

          </li>

        </ul>


        <button *ngIf="!!campaignItem?.location?.length"
                class="btn btn-default"
                ngbDropdownToggle
                aria-expanded="false"
                data-testid="locationChosen.show()">
          <span class="caret"></span>
        </button>

      </div>

    </ng-container>

  </div>

  <div [form-item-row] = "'Ограничения места проведения'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.1rem;"
  >
    <span *ngIf="!isPartnerSelected"
          class="text-danger d-inline-block m-t-lg">Для выбора ограничения места проведения, требуется выбрать партнёра</span>

    <ng-container
      *ngIf="isPartnerSelected"
    >
      <div class="clearfix f-s-8 text-muted m-b-xs">Локация</div>

      <select-location-group
        data-testid="campaign.select.exceptLocationGroup"
        [partnerId]="campaignItem?.campaign?.partnerId"
        [placeholder]="'Выбор локации для исключения'"
        [(id)]="expectLocationGroupsId"
        [name]="'selectExpectLocationGroup'"
        [multiple]="true"
      ></select-location-group>

      <div class="clearfix f-s-8 text-muted m-t-xs m-b-xs">Магазин</div>

      <div class="btn-group" ngbDropdown>

        <button data-testid="exceptLocation.choose()"
                class="btn btn-default "
                (click)="selectLocations('exceptLocation')"
        >
          <span *ngIf="campaignItem?.exceptLocation?.length">Исключено магазинов:
              <span [innerText]="campaignItem?.exceptLocation?.length" data-testid="exceptLocation.length"></span>
          </span>
          <span class="" *ngIf="!campaignItem?.exceptLocation?.length">Магазины для исключения</span>
        </button>


        <ul class="scroll-y"
            ngbDropdownMenu
            *ngIf="!!campaignItem?.exceptLocation?.length"
            style="max-height: 411px;"
        >

          <li *ngFor="let location of campaignItem.exceptLocation"
              class="d-flex align-items-center m-b-xs m-t-xs">

            <div class="col p-l-sm">
              <span [innerText]="location.name" data-testid="exceptLocationChosen.name"></span>
            </div>

            <div class="col-auto no-padder text-center">
              <button class="btn btn-danger btn-xs m-l-xs m-r-sm  "
                      (click)="removeLocation(location, 'exceptLocation'); $event.stopImmediatePropagation()"
                      data-testid="exceptLocationChosen.remove()">
                <i class="fa fa-times"></i>
              </button>
            </div>

          </li>

        </ul>


        <button *ngIf="!!campaignItem?.exceptLocation?.length"
                class="btn btn-default"
                ngbDropdownToggle
                aria-expanded="false"
                data-testid="exceptLocationChosen.show()">
          <span class="caret"></span>
        </button>

      </div>

    </ng-container>

  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI3">Аудитория</span>
  </div>

  <div [form-item-row] = "'Целевая аудитория'"
       form-item-row-input="true"
  >
    <div class="d-flex w-full">
      <ng-select [(ngModel)]="targetAudience"
                 (ngModelChange)="recalcAudience()"
                 [items]="targetDist"
                 bindLabel="name"
                 bindValue="key"
                 data-testid="campaign.targetAudience"
                 name="CampaignItem_targetAudience"
                 (change) = "onChangeTarget()"
                 [class.ng-invalid]="!targetAudience"
                 [searchable]="false"
                 [required]="true"
                 [disabled]="isSuspend"
                 [clearable]="false"
                 class="w-md  col-auto m-r-sm no-padder"

      >

        <ng-template ng-label-tmp let-item="item">
          {{ item.name }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index">
          {{item.name}}
        </ng-template>

      </ng-select>

      <select-segment
        *ngIf="targetAudience==='segment'"
        [(id)]="campaignItem.campaign.segmentId"
        (idChange)="recalcAudience()"
        [required]="true"
        [disabled]="isSuspend"
        [name]="'CampaignItem_targetAudience_segment'"
        class="col"
      ></select-segment>

      <select-group
        *ngIf="targetAudience==='group'"
        [(id)]="campaignItem.campaign.groupId"
        (idChange)="recalcAudience()"
        [required]="true"
        [disabled]="isSuspend"
        [name]="'CampaignItem_targetAudience_group'"
        class="col"
      ></select-group>


    </div>
  </div>

  <ng-container
    *loyaPermitIf="{path:'campaign.settings.exceptionAudience', permitEnabled: ''}"
  >
    <div [form-item-row] = "'Ограничения целевой аудитории'"
         form-item-row-input="true"
    >
      <div class="d-flex w-full">
        <ng-select [(ngModel)]="exceptionAudience"
                   (ngModelChange)="recalcAudience()"
                   [items]="targetDist"
                   bindLabel="name"
                   bindValue="key"
                   data-testid="campaign.exceptionAudience"
                   name="CampaignItem_exceptionAudience"
                   (change) = "onChangeTarget(true)"
                   [class.ng-invalid]="!exceptionAudience"
                   [searchable]="false"
                   [disabled]="isSuspend"
                   [required]="true"
                   [clearable]="false"
                   class="w-md  col-auto m-r-sm no-padder"

        >

          <ng-template ng-label-tmp let-item="item">
            {{ item.name ==='Все' ? 'Без ограничений' : item.name }}
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ item.name ==='Все' ? 'Без ограничений' : item.name }}
          </ng-template>

        </ng-select>

        <select-segment
          *ngIf="exceptionAudience==='segment'"
          [(id)]="campaignItem.campaign.exceptSegmentId"
          (idChange)="recalcAudience()"
          [required]="true"
          [disabled]="isSuspend"
          class="col"
          [name]="'CampaignItem_exceptionAudience_segment'"
        ></select-segment>

        <select-group
          *ngIf="exceptionAudience==='group'"
          [(id)]="campaignItem.campaign.exceptGroupIds"
          (idChange)="recalcAudience()"
          [required]="true"
          [disabled]="isSuspend"
          [multiple]="true"
          [name]="'CampaignItem_exceptionAudience_group'"
          [max]="15"
          class="col"
        ></select-group>

      </div>
    </div>
  </ng-container>

  <div [form-item-row] = "'Итоговое кол-во участников'"
       form-item-row-input="true"
  >

    <ng-container
      *ngIf="!loader.isLoading('campaignTargetInfo')"
    >
      <span [innerHTML]="campaignItem?.planValues?.clientsCount || 0 "
            *ngIf="!!campaignItem?.planValues?.clientsCount"
            data-testid="campaign.planValues.clientsCount"></span>

      <span class="text-danger"
            *ngIf="!campaignItem?.planValues?.clientsCount"
            data-testid="campaign.targetAudience.warning"
      >
        <i class="fa fa-warning m-r-xs"></i> Внимание! В целевой аудитории никого не осталось
      </span>
    </ng-container>

    <i
      *ngIf="loader.isLoading('campaignTargetInfo')"
      class="fa fa-spin fa-spinner text-warning"
    ></i>

  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI8">Механика</span>
  </div>

  <div [form-item-row] = "'Шаблон механики'"
       form-item-row-input="true"
  >
    <select-mechanic
      [currentId]="campaignItem.campaign.mechanicId"
      [(id)]="campaignItem.campaign.mechanicId"
      (idChange)="recalcCouponUntil()"
      (mechanic)="currentMechanic = $event"
      [disabled]="isSuspend"
      [required]="true"
      [states]="['template']"

      [filterMechanic]="mechanicFilterForComponent"
      [validator]="preventOffline"
    >
    </select-mechanic>
  </div>

  <ng-container
    *ngIf=" preventOffileMechanic(currentMechanic) || settingsObj?.hasCouponIssueUntil"
  >
    <div [form-item-row] = "'    '"
         form-item-row-input="true"
    >
      <div class="clearfix text-muted "
           *ngIf="preventOffileMechanic(currentMechanic)">
        <i class="fa fa-warning text-warning"
           data-testid="mechanic.isOffline.warning"></i>
        Оффлайн скидки доступны только для фронтальных кампаний
      </div>

      <div class="clearfix text-danger  "
           *ngIf="settingsObj?.hasCouponIssueUntil"
      >
        <input type="hidden"
               [(ngModel)]="settingsObj.hasCouponIssueUntilDummy"
               [required]="true"
               name="hasCouponIssueUntilDummy">

        <i class="fa fa-warning m-l-xs m-r-xxs "
           data-testid="campaign.hasCouponIssueUntil.warning"></i>
        Внимание! Дата окончания работы кампании больше, чем дата окончания выдачи купонов по КП

      </div>
    </div>
  </ng-container>

  <div [form-item-row] = "'Ограничения вознаграждений'"
       form-item-row-input="true"
  >
    <select-campaign-limitations
      [(id)]="campaignItem.campaign.limitationId"
      [disabled]="isSuspend"
      [required]="false"
    >
    </select-campaign-limitations>

  </div>



  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI4">Информирование</span>
  </div>


  <div [form-item-row] = "'Шаблон информирования'"
       form-item-row-input="true"
  >
    <select-informing-template
      [(id)]="campaignItem.campaign.informingId"
      [disabled]="isSuspend"
      [required]="false"
    >
    </select-informing-template>

  </div>


  <div class="form-item-row clean"></div>

</form-container>

<form-container>

  <div [form-item-row] = "'Канал коммуникации'"
       form-item-row-input="true"
  >
    <div class="d-flex">

      <div class="col-auto text-nowrap p-l-none">
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.usePrefer"
                 (ngModelChange)="updateCommunications()"
                 data-testid="campaign.select.prefChannel"
                 name="CampaignItem_campaign_communication_usePrefer"
                 [disabled]="isSuspend"
          >
          <i></i>
          По предпочтению
        </label>
      </div>

      <div class="col-auto text-nowrap p-l-none"
           *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'email' }"
      >
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.useEmail"
                 (ngModelChange)="updateCommunications()"
                 data-testid="campaign.select.prefEmail"
                 name="CampaignItem_campaign_communication_useEmail"
                 [disabled]="isSuspend || !!campaignItem?.campaign?.communication?.usePrefer"
          >
          <i></i>
          E-mail
        </label>
      </div>

      <div class="col-auto text-nowrap p-l-none"
           *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'sms' }"
      >
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.useSms"
                 (ngModelChange)="updateCommunications()"
                 data-testid="campaign.select.prefSms"
                 name="CampaignItem_campaign_communication_useSms"
                 [disabled]="isSuspend || !!campaignItem?.campaign?.communication?.usePrefer"
          >
          <i></i>
          SMS
        </label>
      </div>

      <div class="col-auto text-nowrap p-l-none"
           *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }"
      >
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.useViber"
                 (ngModelChange)="updateCommunications()"
                 data-testid="campaign.select.prefViber"
                 name="CampaignItem_campaign_communication_useViber"
                 [disabled]="isSuspend || !!campaignItem?.campaign?.communication?.usePrefer"
          >
          <i></i>
          Viber
        </label>
      </div>

    </div>
  </div>

  <div [form-item-row] = "'Шаблон сообщений'"
       form-item-row-input="true"
  >
    <select-message-template
      [currentId]="campaignItem.campaign.templateId"
      [(id)]="campaignItem.campaign.templateId"
      [required]="true"
      [disabled]="isSuspend"
    ></select-message-template>
  </div>


  <div [form-item-row] = "'    '"
       class="p-b-none"
  >

    <div class="d-flex">

      <div class="col-auto p-l-none text-nowrap w">

        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.useCheck"
                 data-testid="campaign.communication.input.useCheck"
                 name="CampaignItem_campaign_communication_useCheck"
                 [disabled]="isSuspend"
                 (change)="campaignItem.campaign.communication.useCheckIfApplied = campaignItem.campaign.communication.useCheck ? campaignItem.campaign.communication.useCheckIfApplied : false;
                           campaignItem.campaign.communication.useCheckLimit = campaignItem.campaign.communication.useCheck ? campaignItem.campaign.communication.useCheckLimit : undefined "
          >
          <i></i>
          Печать в чек
        </label>

      </div>

      <div class="col-auto p-l-none text-nowrap"
           *loyaPermitIf="{path:'campaign.settings.informingOnApply', permitEnabled: ''}"
      >

        <label class="i-checks"
               [class.text-muted]="isSuspend || !campaignItem.campaign.communication.useCheck">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.useCheckIfApplied"
                 name="CampaignItem_campaign_communication_useCheckIfApplied"
                 data-testid="campaign.communication.input.useCheckIfApplied"
                 [disabled]="isSuspend || !campaignItem.campaign.communication.useCheck"
                 (change)="campaignItem.campaign.communication.useCheckLimit = campaignItem.campaign.communication.useCheckIfApplied ? undefined : campaignItem.campaign.communication.useCheckLimit "
          >
          <i></i>
          Печать в чек при срабатывании кампании
        </label>

      </div>

    </div>

  </div>

  <ng-container
    *ngIf="!!campaignItem.campaign.communication.useCheck && !campaignItem.campaign.communication.useCheckIfApplied"
  >
    <div [form-item-row] = "'Количество сообщений в день'"
         form-item-row-input="true"
         class="m-t-xs m-b-xs"
    >
      <div class="input-group m-n m-b w-md">
        <input
          type="number"
          class="form-control  inline  m-r-sm form-control-edittable"
          [(ngModel)]="campaignItem.campaign.communication.useCheckLimit"
          data-testid="campaignItem.campaign.communication.useCheckLimit"
          name="CampaignItem_campaign_communication_useCheckLimit"
          placeholder="Количество сообщений"
          [required]="false"
          pattern="^[0-9]+$"
          [min]="0"
          [max]="999"
          [disabled]="isSuspend"
        />

        <span class="input-group-addon">шт</span>
      </div>

    </div>
  </ng-container>

  <div [form-item-row] = "'    '"
       class="p-t-none"
  >
    <div class="d-flex">

      <div class="col-auto p-l-none text-nowrap w">

        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.usePos"
                 data-testid="campaign.communication.input.usePos"
                 name="CampaignItem_campaign_communication_usePos"
                 [disabled]="isSuspend"
                 (change)="campaignItem.campaign.communication.usePosIfApplied = campaignItem.campaign.communication.usePos ? campaignItem.campaign.communication.usePosIfApplied : false"
          >
          <i></i>
          Сообщение кассиру
        </label>

      </div>

      <div class="col-auto p-l-none text-nowrap"
           *loyaPermitIf="{path:'campaign.settings.informingOnApply', permitEnabled: ''}"
      >

        <label class="i-checks"
               [class.text-muted]="isSuspend || !campaignItem.campaign.communication.usePos">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.campaign.communication.usePosIfApplied"
                 name="CampaignItem_campaign_communication_usePosIfApplied"
                 data-testid="campaign.communication.input.usePosIfApplied"
                 [disabled]="isSuspend || !campaignItem.campaign.communication.usePos"
          >
          <i></i>
          Сообщение кассиру при срабатывании кампании
        </label>

      </div>


    </div>

  </div>


  <ng-container
    *loyaPermitIf="{path:'channel.type.sms', permitEnabled: ''}"
  >
    <div [form-item-row] = "'Рассылка сообщений за'"
         form-item-row-input="true"
    >
      <div class="input-group m-n m-b w-md">
        <input
          type="number"
          class="form-control  inline  m-r-sm form-control-edittable"
          [(ngModel)]="campaignItem.campaign.campaignTime.informationDate"
          data-testid="campaign.mailing.informationDate"
          name="CampaignItem_campaign_campaignTime_informationDate"
          placeholder="1"
          [required]="true"
          pattern="^[0-9]+$"
          [min]="0"
          [max]="999"
          [disabled]="isSuspend"
        />

        <span class="input-group-addon">дней</span>
      </div>

    </div>
  </ng-container>




  <div [form-item-row] = "'   '"
       form-item-row-input="true"
  >

    <grid-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'email' }">

        <div class="grid-item"
             [grid-item-form] = "'Всего контактов E-Mail'"
             grid-item-form-icon = "fa-envelope-o"
        >
        <span data-testid="campaign.targetGroupInfo.emailCount">
          {{ targetGroupInfo?.emailCount || 0 | loyaNumber }}
        </span>

        </div>

        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов E-Mail'"
             grid-item-form-icon = "fa-envelope-o"
        >
        <span data-testid="campaign.targetGroupInfo.emailPrefer">
          {{ targetGroupInfo?.emailPrefer || 0 | loyaNumber }}
        </span>

        </div>

        <div class="grid-item  grid-item_clean"></div>

      </ng-container>


      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'sms' }">

        <div class="grid-item"
             [grid-item-form] = "'Всего контактов SMS'"
             grid-item-form-icon = "fa-mobile"
        >
          <span data-testid="campaign.targetGroupInfo.smsCount">
            {{ targetGroupInfo?.smsCount || 0 | loyaNumber }}
          </span>

        </div>

        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов SMS'"
             grid-item-form-icon = "fa-mobile"
        >
          <span data-testid="campaign.targetGroupInfo.smsPrefer">
            {{ targetGroupInfo?.smsPrefer  || 0 | loyaNumber }}
          </span>

        </div>

        <div class="grid-item  grid-item_clean"></div>

      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: 'viber' }">
        <div class="grid-item"
             [grid-item-form] = "'Всего контактов Viber'"
             grid-item-form-icon = "fa-viber"
        >
          <span data-testid="campaign.targetGroupInfo.viberCount">
            {{ targetGroupInfo?.viberCount || 0 | loyaNumber }}
          </span>

        </div>

        <div class="grid-item"
             [grid-item-form] = "'Предпочитаемых контактов Viber'"
             grid-item-form-icon = "fa-viber"
        >
          <span data-testid="campaign.targetGroupInfo.viberPrefer">
            {{ targetGroupInfo?.viberPrefer || 0 | loyaNumber }}
          </span>

        </div>


        <div class="grid-item  grid-item_clean"></div>

      </ng-container>


      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['email','sms'] }">
        <div class="grid-item grid-item__form form-item">
          <div class="grid-item__label form-label">
            Всего контактов E-Mail, SMS
            <ng-container *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['viber'] }">
              и Viber
            </ng-container>
            <i class="fa fa-comments-o"></i>
          </div>
          <div class="grid-item__value">
            <div data-testid="campaign.targetGroupInfo.countEmailSms">
              {{ targetGroupInfo.countEmailSms  || 0 | loyaNumber }}
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *loyaPermitIf="{path:'channel.type', permitEnabled: '', permitInFilter: ['email','sms'] }">
        <div class="grid-item"
             [grid-item-form] = "'Всего контактов без предпочтений'"
             grid-item-form-icon = "fa-comments-o"
        >
          <span data-testid="campaign.targetGroupInfo.withoutPrefer">
            {{ targetGroupInfo?.withoutPrefer || 0 | loyaNumber }}
          </span>

        </div>
      </ng-container>


    </grid-container>

  </div>







</form-container>

<ng-container
  *ngIf="isExternalLoyalty">

  <form-container>

    <div class="form-item-row form-item-row_clean_hr clean">
      <span class="text-muted"  id="anchorI5">Внешняя система лояльности</span>
    </div>

    <campaign-external-code
      class="form-sub-item"
      [campaignItem]="campaignItem"
      [isEdit]="true"
      [isSuspend]="isSuspend"

    ></campaign-external-code>

    <div [form-item-row] = "'Внешняя система лояльности'"
         *ngIf="externalLoyaltyFlag"
    >
      <div>
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.elSettings.disableBonusUsed"
                 name="CampaignItem_elSettings_disableBonusUsed"
                 data-testid="externalLoyalty.input.disableBonusUsed"
          >
          <i></i>
          Запретить списание бонусов на товары по этой акции во внешней системе
        </label>
      </div>

      <div>
        <label class="i-checks">

          <input type="checkbox"
                 [(ngModel)]="campaignItem.elSettings.disableBonusAward"
                 name="CampaignItem_elSettings_disableBonusAward"
                 data-testid="externalLoyalty.input.disableBonusAward"
          >
          <i></i>
          Запретить начисление бонусных баллов на товары по этой акции во внешней системе
        </label>
      </div>
    </div>


  </form-container>
</ng-container>

<div class="m-t m-l b-b w-full text-muted">Плановые показатели</div>

<div class="w-full">

  <grid-container>

    <div class="grid-item"
         [grid-item-form] = "'Целевая группа'"
         grid-item-form-icon = "fa-dot-circle-o"
    >
      <span data-testid="planValues.clientsCount">
        {{ campaignItem?.planValues?.clientsCount || 0 | loyaNumber }}
      </span>

    </div>

    <div class="grid-item"
         [grid-item-form] = "'Процент отклика'"
         grid-item-form-icon = "fa-undo"
    >
       <div class="input-group">
            <input type="text"
                   class="form-control form-control-edittable"
                   [required]="true"
                   [min]="0"
                   [max]="100"
                   [(ngModel)]="campaignItem.planValues.responsePercent"
                   (ngModelChange)="recalcPlan()"
                   name="CampaignItem_planValues_responsePercent"
                   data-testid="planValues.input.responsePercent"
                   pattern="^(100|[0-9]{1,2})$"
            >
            <span class="input-group-addon">%</span>
        </div>

    </div>

    <div class="grid-item"
         [grid-item-form] = "'Планируемый отклик'"
         grid-item-form-icon = "fa-undo"
    >
      <span data-testid="planValues.responseCount">
        {{ campaignItem?.planValues?.responseCount || 0 | loyaNumber }}
      </span>

    </div>

    <div class="grid-item"
         [grid-item-form] = "'Планируемое кол-во срабатываний'"
         grid-item-form-icon = "fa-rss"
    >
      <span data-testid="planValues.operationCount">
        {{ campaignItem?.planValues?.operationCount || 0 | loyaNumber }}
      </span>

    </div>

    <div class="grid-item  grid-item_clean "></div>

    <div class="grid-item"
         *ngIf="campaignItem?.campaign?.communication?.useEmail || campaignItem?.campaign?.communication?.usePrefer"
         [grid-item-form] = "'Расходы на E-mail сообщения'"
         grid-item-form-icon = "fa-envelope-o"
    >
      <span data-testid="planValues.emailTotal">
        {{ campaignItem?.planValues?.emailTotal || 0 | loyaCurrency }}
      </span>
    </div>

    <div class="grid-item"
         *ngIf="campaignItem?.campaign?.communication?.useSms || campaignItem?.campaign?.communication?.usePrefer"
         [grid-item-form] = "'Расходы на SMS сообщения'"
         grid-item-form-icon = "fa-mobile"
    >
      <span data-testid="planValues.smsTotal">
        {{ campaignItem?.planValues?.smsTotal || 0 | loyaCurrency }}
      </span>
    </div>

    <div class="grid-item"
         *ngIf="hasResourceDiscount"
         [grid-item-form] = "'Сумма скидок'"
         grid-item-form-icon = "fa-tag"
    >
      <input type="text" class="form-control form-control-edittable "
             [min]="0"
             [max]="10000000000"
             pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
             [required]="true"
             [(ngModel)]="campaignItem.planValues.discountTotal"
             (ngModelChange)="recalcPlan()"
             name="CampaignItem_planValues_discountTotal"
             data-testid="planValues.input.discountTotal">
    </div>

    <div class="grid-item"
         *ngIf="hasResourceBonus"
         [grid-item-form] = "'Сумма начисляемых бонусов'"
         grid-item-form-icon = "fa-star"
    >
      <input type="text" class="form-control form-control-edittable "
             [min]="0"
             [max]="10000000000"
             pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
             [required]="true"
             [(ngModel)]="campaignItem.planValues.bonusTotal"
             (ngModelChange)="recalcPlan()"
             name="CampaignItem_planValues_bonusTotal"
             data-testid="planValues.input.bonusTotal">
    </div>

    <div class="grid-item"
         *ngIf="hasResourceGift"
         [grid-item-form] = "'Сумма расходов на подарки'"
         grid-item-form-icon = "fa-star"
    >
      <span data-testid="planValues.giftTotal">
        {{ campaignItem?.planValues?.giftTotal || 0 | loyaCurrency}}
      </span>
    </div>


    <div class="grid-item grid-item__form form-item">
      <div class="grid-item__label form-label">
        Прочие расходы
        <i class="fa" ngCurrencyLoya></i>
      </div>

      <div class="grid-item__value form-value">
         <div class="input-group">
            <input type="text"
                   class="form-control form-control-edittable "
                   [required]="true"
                   [min]="0"
                   [max]="10000000000"
                   pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
                   [(ngModel)]="campaignItem.planValues.additionalTotal"
                   (ngModelChange)="recalcPlan()"
                   name="CampaignItem_planValues_additionalTotal"
                   data-testid="planValues.input.additionalTotal">

            <span class="input-group-addon">
              <i class="fa le" ngCurrencyLoya></i>
            </span>
        </div>
      </div>
    </div>

    <div class="grid-item"
         [grid-item-form] = "'Всего расходов'"
         grid-item-form-icon = "fa-money"
    >
      <span  data-testid="planValues.totalCost">
        {{ campaignItem?.planValues?.totalCost || 0 | loyaCurrency }}
      </span>

      <i class="fa le" ngCurrencyLoya></i>

    </div>

    <div class="grid-item"
         [grid-item-form] = "'Расходы на одного клиента'"
         grid-item-form-icon = "fa-user"
    >
      <span data-testid="planValues.totalByClient">
        {{ campaignItem?.planValues?.totalByClient || 0 | loyaCurrency }}
      </span>
    </div>

    <div class="grid-item"
         [grid-item-form] = "'План по росту общей суммы продаж'"
         grid-item-form-icon = "fa-bar-chart"
    >
      <span class="form-value" data-testid="planValues.clientSellingTotal">
        {{ campaignItem?.planValues?.clientSellingTotal || 0 | loyaCurrency }}
      </span>
    </div>


  </grid-container>

  <responsive-table
      *ngIf="hasResourceGift"
  >
    <table
      class="table"
    >
      <thead>
        <tr>
          <th>наименование подарка</th>
          <th>SKU</th>
          <th>цена одной единицы</th>
          <th>количество</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let gift of resourceGiftDetails; let index = index;" data-testid="gift in planeGifts">

          <td data-testid="gift.name">
            {{ gift.name }}
          </td>

          <td data-testid="gift.sku">
            {{ gift.sku }}
          </td>

          <td>
            <div class="input-group w-sm">
              <input type="text"
                     class="form-control   form-control-edittable"
                     [(ngModel)]="gift.price"
                     (ngModelChange)="recalcPlan()"
                     [min]="0"
                     [max]="10000000000"
                     [name]="'CampaignItem_gift_price' + index"
                     pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
                     [required]="true"
                     data-testid="gift.input.price">
              <span class="input-group-addon" ngCurrencyNameLoya></span>
            </div>
          </td>

          <td>
            <div class="input-group w-sm">
              <input type="number"
                     class="form-control form-control-edittable"
                     [min]="0"
                     [max]="100000"
                     [name]="'CampaignItem_gift_planCount' + index"
                     pattern="^[0-9]{1,5}$"
                     [required]="true"
                     [(ngModel)]="gift.planCount"
                     (ngModelChange)="recalcPlan()"
                     data-testid="gift.input.planCount">
              <span class="input-group-addon">шт</span>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
  </responsive-table>


</div>
