'use strict';

import module from 'frontendModule';

import ckeditor_loader from 'agModules/ckeditor';

import 'model/MsgTemplate';
import 'model/SegmentationModel/resource';
import 'model/GroupModel/resource';
import 'model/CampaignModel/resource';
import 'model/Newsletter';
import 'model/NewsletterModel/resource';
import 'model/MsgTemplate';

import '../components/viber/main';
import '../components/posPrinter/main';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.campaign.campaigns.templates.view';


export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')

                .template(   () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.templates.view" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/campaign.campaigns.templates.view" */ "./controllers" ))

                .resolve('$ckePrepare', ckeditor_loader)
                .resolve('MsgTemplateItem', [ 'MsgTemplate', '$stateParams', function( MsgTemplate, $stateParams ) {


                    if ($stateParams.id) {
                        return MsgTemplate.get($stateParams).$promise;
                    } else {
                        return new MsgTemplate({
                            'new'          : true,  // Флаг что новый
                            'viberContent' : {},
                            'isInformational': false,
                            'status'       : 'template'
                        });
                    }

                } ])
              //  .resolve('segmentsList',  [ 'SegmentationModel',  ( SegmentationModel : any ) =>  SegmentationModel.query({ 'state': 'deploy'}).$promise ])
                .breadcrumbs([ "Кампании", "Шаблоны сообщений", "Редактирование шаблона"  ])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

                });

        }]);
