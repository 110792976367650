/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NewsletterApiConfiguration, NewsletterApiConfigurationInterface } from './newsletter-api-configuration';

import { NewsletterApiService } from './services/newsletter-api.service';

/**
 * Provider for all NewsletterApi services, plus NewsletterApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    NewsletterApiConfiguration,
    NewsletterApiService
  ],
})
export class NewsletterApiModule {
  static forRoot(customParams: NewsletterApiConfigurationInterface): ModuleWithProviders<NewsletterApiModule> {
    return {
      ngModule: NewsletterApiModule,
      providers: [
        {
          provide: NewsletterApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
