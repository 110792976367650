/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RewardWithAmountAfterDiscountsApiConfiguration, RewardWithAmountAfterDiscountsApiConfigurationInterface } from './reward-with-amount-after-discounts-api-configuration';

import { RewardWithAmountAfterDiscountsApiService } from './services/reward-with-amount-after-discounts-api.service';

/**
 * Provider for all RewardWithAmountAfterDiscountsApi services, plus RewardWithAmountAfterDiscountsApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    RewardWithAmountAfterDiscountsApiConfiguration,
    RewardWithAmountAfterDiscountsApiService
  ],
})
export class RewardWithAmountAfterDiscountsApiModule {
  static forRoot(customParams: RewardWithAmountAfterDiscountsApiConfigurationInterface): ModuleWithProviders<RewardWithAmountAfterDiscountsApiModule> {
    return {
      ngModule: RewardWithAmountAfterDiscountsApiModule,
      providers: [
        {
          provide: RewardWithAmountAfterDiscountsApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
