<div class="fixed-tool-panel">

  <filter-date-period
    class="d-inline-block "
    data-testid="filter.period.date.open()"
    [modelFrom]="searchCheckService.queryParams.getFilterValue('dateFrom')"
    [modelTo]="searchCheckService.queryParams.getFilterValue('dateTo')"
    (modelFromChange)="searchCheckService.queryParams.setFilterValue('dateFrom',$event);"
    (modelToChange)="searchCheckService.queryParams.setFilterValue('dateTo',$event);"
    (onChange)="reset()"
    [maxPeriodMonth]="6"
    [requiredFrom]="false"
    [requiredTo]="false"
    [label]="'Период дат'"
  ></filter-date-period>

  <filter-time-period
    class="d-inline-block m-r-sm"
    data-testid="filter.period.time.open()"
    (modelFromChange)="changeTime($event, 'timeFrom' );"
    (modelToChange)="changeTime($event, 'timeTo');"
    (onChange)="reset()"
    [requiredFrom]="false"
    [requiredTo]="false"
  ></filter-time-period>

  <filter-goods
    class=" d-inline-block m-r-sm"
    data-testid="filter.goods"
    [model] = "goodsList"
    (modelChange)="goodsList = $event"
    [partnerId] = "searchCheckService.queryParams.getFilterValue('partnerId')"
    (partnerIdChange)="searchCheckService.queryParams.setFilterValue(
                'partnerId',
                $event
            );"
  ></filter-goods>


  <filter-locations
    data-testid="filter.locations"
    class="d-inline-block "
    [model] = "searchCheckService.queryParams.getFilterValue('locations')"
    (modelChange)="searchCheckService.queryParams.setFilterValue(
                  'locations',
                  $event
              ); reset()"
    [partnerId] = "searchCheckService.queryParams.getFilterValue('partnerId')"
    (partnerIdChange)="searchCheckService.queryParams.setFilterValue(
                  'partnerId',
                  $event
              );"
  ></filter-locations>

  <filter-price-period
    data-testid="filter.total"
    [modelFrom]="searchCheckService.queryParams.getFilterValue('minTotal')"
    [modelTo]="searchCheckService.queryParams.getFilterValue('maxTotal')"
    (modelFromChange)="searchCheckService.queryParams.setFilterValue('minTotal',$event);"
    (modelToChange)="searchCheckService.queryParams.setFilterValue('maxTotal',$event);"
    (onChange)="reset()"
    [requiredFrom]="false"
    [requiredTo]="false"
    [label]="'Сумма чека'"
  ></filter-price-period>

</div>


<div class="wrapper">
  <search-input
    [model]="searchString"
    (modelChange)="onSearchChange($event)"
    placeholder="Номер чека/SKU"
  ></search-input>
</div>

<ng-container
  *ngIf="!isFilterEnabled()"
>

  <div class=" b-default m-l-lg m-b padder-v">

    <div class="m-r text-center text-muted">
      <h3 class="text-muted">Задайте условия поиска</h3>
    </div>

  </div>

</ng-container>


<div class="b-default m-l-lg m-b padder-v "
     [ngClass]="{'streamline b-l b-t' : hasReceipts() }"
     *ngIf="isFilterEnabled()">

  <div class="m-r text-center text-muted" *ngIf="!hasReceipts() && !loader?.isLoading('list')">
    <h3 class="text-muted">Чеки не найдены</h3>
  </div>

  <ng-container
    *ngIf="hasReceipts()"
  >
    <div
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      [infiniteScrollContainer]="'#app-container'"
      [fromRoot]="true"
      (scrolled)="onScroll()"
    >

      <div *ngFor="let receipt of listReceipts" class="m-r" >
        <receipt-view
          [model]="receipt"
          [searchModel]="searchString"
          [showClient]="true"
        ></receipt-view>
      </div>

    </div>
  </ng-container>

  <div class="text-center m-t" *ngIf="loader?.isLoading('list')">
    <i class="fa fa-spin fa-spinner fa-3x text-warning"
       data-testid="receipt.isPending"></i>
  </div>

</div>
