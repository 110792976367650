import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BrandGoodLinkApiService} from "../../../../api/BrandGoodLinkApi/services";
import {Brand} from "../../../../api/BrandApi/models";
import {Observable} from "rxjs";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import QueryGoodsByBrandParams = BrandGoodLinkApiService.QueryGoodsByBrandParams;
import {GoodGroupLinkApiService} from "../../../../api/GoodGroupLinkApi/services/good-group-link-api.service";
import {GoodGroupLinkBrandsApiService} from "../../../../api/GoodGroupLinkBrandsApi/services/good-group-link-brands-api.service";



@Injectable()
export class BrandGoodLinkService {

  constructor( private brandGoodLinkApiService:BrandGoodLinkApiService ) {
  }

  public getList$(params: QueryGoodsByBrandParams){
    return this.brandGoodLinkApiService.queryGoodsByBrand(params)
  }

  public updateList$(params: BrandGoodLinkApiService.UpdateParams){
    return this.brandGoodLinkApiService.update(params)
  }


  public deleteList$(params: BrandGoodLinkApiService.DeleteListParams) {
    return this.brandGoodLinkApiService.deleteList(params)
  }

}
