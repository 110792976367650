import {CouponKind ,CouponRuleBarcodeId} from "./CouponKind";
import {PublicId, StopPeriodTypes} from "../IssuingTypes/IssuingType";



export class CouponRuleBarcode extends CouponKind {

    static id = CouponRuleBarcodeId;

    public stopPeriodArray  = [StopPeriodTypes.STOP_PERIOD_FORM_DATE]   ;

    get hasGeneratedCount() : boolean {
        return this.issuingType.getId() === PublicId;
    }

    get hasBarcode() : boolean {
        return this.issuingType.isViewState;
    }

}