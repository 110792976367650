/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TriggersApiConfiguration as __Configuration } from '../triggers-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TriggerWithCounter } from '../models/trigger-with-counter';
import { TriggerWithGroup } from '../models/trigger-with-group';
import { QueryTrigger } from '../models/query-trigger';
import { TriggerState } from '../models/trigger-state';
import { Trigger } from '../models/trigger';
import { TriggerThroughputDashboard } from '../models/trigger-throughput-dashboard';
import { GetTriggerThroughputParams } from '../models/get-trigger-throughput-params';
import { TriggerThroughputByPeriod } from '../models/trigger-throughput-by-period';
import { ActionClient } from '../models/action-client';
import { GetActionTransactionParams } from '../models/get-action-transaction-params';
@Injectable({
  providedIn: 'root',
})
class TriggersApiService extends __BaseService {
  static readonly counterContainsPath = '/trigger/countercontains/{counterId}';
  static readonly groupContainsPath = '/trigger/groupcontains/{groupId}';
  static readonly queryTriggersPath = '/trigger';
  static readonly createTriggerPath = '/trigger';
  static readonly findTriggerByIdPath = '/trigger/{id}';
  static readonly updateTriggerByIdPath = '/trigger/{id}';
  static readonly deleteTriggerByIdPath = '/trigger/{id}';
  static readonly getTriggerThroughputDashboardPath = '/trigger/{id}/throughput/dashboard';
  static readonly getTriggerThroughputByPeriodPath = '/trigger/{id}/throughput/period';
  static readonly getActionTransactionsPath = '/trigger/{id}/action/transactions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param counterId undefined
   * @return Цепочки, содержащие счетчик
   */
  counterContainsResponse(counterId: number): __Observable<__StrictHttpResponse<TriggerWithCounter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trigger/countercontains/${encodeURIComponent(String(counterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TriggerWithCounter>;
      })
    );
  }
  /**
   * @param counterId undefined
   * @return Цепочки, содержащие счетчик
   */
  counterContains(counterId: number): __Observable<TriggerWithCounter> {
    return this.counterContainsResponse(counterId).pipe(
      __map(_r => _r.body as TriggerWithCounter)
    );
  }

  /**
   * @param groupId undefined
   * @return Цепочки, содержащие группу
   */
  groupContainsResponse(groupId: number): __Observable<__StrictHttpResponse<TriggerWithGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trigger/groupcontains/${encodeURIComponent(String(groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TriggerWithGroup>;
      })
    );
  }
  /**
   * @param groupId undefined
   * @return Цепочки, содержащие группу
   */
  groupContains(groupId: number): __Observable<TriggerWithGroup> {
    return this.groupContainsResponse(groupId).pipe(
      __map(_r => _r.body as TriggerWithGroup)
    );
  }

  /**
   * Запрос цепочек
   * @param params The `TriggersApiService.QueryTriggersParams` containing the following parameters:
   *
   * - `states`: Статусы цепочек для выборки
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Список всех цепочек
   */
  queryTriggersResponse(params: TriggersApiService.QueryTriggersParams): __Observable<__StrictHttpResponse<Array<QueryTrigger>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.states || []).forEach(val => {if (val != null) __params = __params.append('states', val.toString())});
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trigger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QueryTrigger>>;
      })
    );
  }
  /**
   * Запрос цепочек
   * @param params The `TriggersApiService.QueryTriggersParams` containing the following parameters:
   *
   * - `states`: Статусы цепочек для выборки
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Список всех цепочек
   */
  queryTriggers(params: TriggersApiService.QueryTriggersParams): __Observable<Array<QueryTrigger>> {
    return this.queryTriggersResponse(params).pipe(
      __map(_r => _r.body as Array<QueryTrigger>)
    );
  }

  /**
   * Создание цепочки
   * @param trigger Параметры цепочки
   * @return Созданная цепочка
   */
  createTriggerResponse(trigger: Trigger): __Observable<__StrictHttpResponse<Trigger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = trigger;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trigger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Trigger>;
      })
    );
  }
  /**
   * Создание цепочки
   * @param trigger Параметры цепочки
   * @return Созданная цепочка
   */
  createTrigger(trigger: Trigger): __Observable<Trigger> {
    return this.createTriggerResponse(trigger).pipe(
      __map(_r => _r.body as Trigger)
    );
  }

  /**
   * Найди цепочку по ID
   * @param id ID цепочки
   * @return Цепочка
   */
  findTriggerByIdResponse(id: number): __Observable<__StrictHttpResponse<Trigger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trigger/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Trigger>;
      })
    );
  }
  /**
   * Найди цепочку по ID
   * @param id ID цепочки
   * @return Цепочка
   */
  findTriggerById(id: number): __Observable<Trigger> {
    return this.findTriggerByIdResponse(id).pipe(
      __map(_r => _r.body as Trigger)
    );
  }

  /**
   * Изменить цепочку по ID
   * @param params The `TriggersApiService.UpdateTriggerByIdParams` containing the following parameters:
   *
   * - `trigger`: Параметры цепочки
   *
   * - `id`: ID цепочки
   *
   * @return Цепочка
   */
  updateTriggerByIdResponse(params: TriggersApiService.UpdateTriggerByIdParams): __Observable<__StrictHttpResponse<Trigger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.trigger;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/trigger/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Trigger>;
      })
    );
  }
  /**
   * Изменить цепочку по ID
   * @param params The `TriggersApiService.UpdateTriggerByIdParams` containing the following parameters:
   *
   * - `trigger`: Параметры цепочки
   *
   * - `id`: ID цепочки
   *
   * @return Цепочка
   */
  updateTriggerById(params: TriggersApiService.UpdateTriggerByIdParams): __Observable<Trigger> {
    return this.updateTriggerByIdResponse(params).pipe(
      __map(_r => _r.body as Trigger)
    );
  }

  /**
   * Удалить цепочку по ID
   * @param id ID цепочки
   */
  deleteTriggerByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/trigger/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить цепочку по ID
   * @param id ID цепочки
   */
  deleteTriggerById(id: number): __Observable<null> {
    return this.deleteTriggerByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос "проходимости" по ID цепочки
   * @param params The `TriggersApiService.GetTriggerThroughputDashboardParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * @return Проходимость по цепочке
   */
  getTriggerThroughputDashboardResponse(params: TriggersApiService.GetTriggerThroughputDashboardParams): __Observable<__StrictHttpResponse<Array<TriggerThroughputDashboard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trigger/${encodeURIComponent(String(params.id))}/throughput/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TriggerThroughputDashboard>>;
      })
    );
  }
  /**
   * Запрос "проходимости" по ID цепочки
   * @param params The `TriggersApiService.GetTriggerThroughputDashboardParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * @return Проходимость по цепочке
   */
  getTriggerThroughputDashboard(params: TriggersApiService.GetTriggerThroughputDashboardParams): __Observable<Array<TriggerThroughputDashboard>> {
    return this.getTriggerThroughputDashboardResponse(params).pipe(
      __map(_r => _r.body as Array<TriggerThroughputDashboard>)
    );
  }

  /**
   * Запрос "проходимости" за период по ID цепочки
   * @param params The `TriggersApiService.GetTriggerThroughputByPeriodParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * @return Проходимость по цепочке
   */
  getTriggerThroughputByPeriodResponse(params: TriggersApiService.GetTriggerThroughputByPeriodParams): __Observable<__StrictHttpResponse<Array<TriggerThroughputByPeriod>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trigger/${encodeURIComponent(String(params.id))}/throughput/period`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TriggerThroughputByPeriod>>;
      })
    );
  }
  /**
   * Запрос "проходимости" за период по ID цепочки
   * @param params The `TriggersApiService.GetTriggerThroughputByPeriodParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * @return Проходимость по цепочке
   */
  getTriggerThroughputByPeriod(params: TriggersApiService.GetTriggerThroughputByPeriodParams): __Observable<Array<TriggerThroughputByPeriod>> {
    return this.getTriggerThroughputByPeriodResponse(params).pipe(
      __map(_r => _r.body as Array<TriggerThroughputByPeriod>)
    );
  }

  /**
   * Запрос статистики по блоку цепочки
   * @param params The `TriggersApiService.GetActionTransactionsParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Статистика по блоку цепочки
   */
  getActionTransactionsResponse(params: TriggersApiService.GetActionTransactionsParams): __Observable<__StrictHttpResponse<Array<ActionClient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.drop != null) __params = __params.set('drop', params.drop.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trigger/${encodeURIComponent(String(params.id))}/action/transactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActionClient>>;
      })
    );
  }
  /**
   * Запрос статистики по блоку цепочки
   * @param params The `TriggersApiService.GetActionTransactionsParams` containing the following parameters:
   *
   * - `params`: Параметры запроса
   *
   * - `id`: ID цепочки
   *
   * - `limit`: Сколько всего выбрать записей
   *
   * - `drop`: Сколько пропустить записей с начала выборки
   *
   * @return Статистика по блоку цепочки
   */
  getActionTransactions(params: TriggersApiService.GetActionTransactionsParams): __Observable<Array<ActionClient>> {
    return this.getActionTransactionsResponse(params).pipe(
      __map(_r => _r.body as Array<ActionClient>)
    );
  }
}

module TriggersApiService {

  /**
   * Parameters for queryTriggers
   */
  export interface QueryTriggersParams {

    /**
     * Статусы цепочек для выборки
     */
    states: Array<TriggerState>;

    /**
     * Сколько всего выбрать записей
     */
    limit?: number;

    /**
     * Сколько пропустить записей с начала выборки
     */
    drop?: number;
  }

  /**
   * Parameters for updateTriggerById
   */
  export interface UpdateTriggerByIdParams {

    /**
     * Параметры цепочки
     */
    trigger: Trigger;

    /**
     * ID цепочки
     */
    id: number;
  }

  /**
   * Parameters for getTriggerThroughputDashboard
   */
  export interface GetTriggerThroughputDashboardParams {

    /**
     * Параметры запроса
     */
    params: GetTriggerThroughputParams;

    /**
     * ID цепочки
     */
    id: number;
  }

  /**
   * Parameters for getTriggerThroughputByPeriod
   */
  export interface GetTriggerThroughputByPeriodParams {

    /**
     * Параметры запроса
     */
    params: GetTriggerThroughputParams;

    /**
     * ID цепочки
     */
    id: number;
  }

  /**
   * Parameters for getActionTransactions
   */
  export interface GetActionTransactionsParams {

    /**
     * Параметры запроса
     */
    params: GetActionTransactionParams;

    /**
     * ID цепочки
     */
    id: number;

    /**
     * Сколько всего выбрать записей
     */
    limit?: number;

    /**
     * Сколько пропустить записей с начала выборки
     */
    drop?: number;
  }
}

export { TriggersApiService }
