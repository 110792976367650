import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";
import {LocationsService} from "../../../../../shared/service/api/Locations/Locations.service";
import {Location} from "../../../../../api/LocationApi/models/location";


@Component({
  selector: 'locations-index-page',
  templateUrl: './locations-index-page.component.html',
  providers:[
    LocationsService
  ]
})
export class LocationsIndexPageComponent implements OnInit{

  @Input() partnerId;

  searchString: string;
  listLocations: Array<Location> =[]
  rolesList = {};

  public isLocationsLocations
  public selectedPartner;

  constructor(
    public locationsService: LocationsService
  ) {
  }

  ngOnInit() {
    this.initSelectedPartner();
    this.locationsService.getListParams.reset();
    this.getList(this.locationsService.getListParams.params);
  }

  getList(params) {
    if (!this.selectedPartner)
      return ;

    this.isLocationsLocations = true;
    this.locationsService.getList$(params)
      .subscribe( result => {
        this.listLocations = this.listLocations.concat(result);
        this.isLocationsLocations = false;
      }, () => {
        this.isLocationsLocations = false;
      });
  }


  onScroll() {
    this.locationsService.getListParams.next();
    this.getList(this.locationsService.getListParams.params)
  }

  reset() {
    this.locationsService.getListParams.reset();
    this.listLocations=[];
    this.getList(this.locationsService.getListParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.locationsService.getListParams.search(this.searchString);
    this.reset();
  }

  changeSelectedPartner( inPartner ) {
    this.selectedPartner = inPartner;
    this.locationsService.getListParams.setFilterValue('partnerId', !!this.selectedPartner ? this.selectedPartner?.id : undefined)
    this.onSearchChange('');
  }

  initSelectedPartner() {

    if ( parseInt(this.partnerId) > 0 ) {

      this.selectedPartner = {
        id: parseInt(this.partnerId)
      };
      this.locationsService.getListParams.setFilterValue('partnerId', !!this.selectedPartner ? this.selectedPartner?.id : undefined)

    }

  }
}
