import {NewsletterProtocol} from "../../../../../api/NewsletterApi/models/newsletter-protocol";
import {CampaignStatus, ChannelType} from "../../../../../shared/service/api/Newsletter/Newsletter.variables";

export  class NewsletterUtilsClass {


  static getStateIcon(item: NewsletterProtocol) {

    let res = ''

    switch(item?.channel) {

      case ChannelType.email:
        res = 'fa-envelope ';
        break;

      case ChannelType.sms:
        res = 'fa-mobile fa-lg m-l-xs m-r-xs ';
        break;

      case ChannelType.viber:
        res = 'fa-viber ';
        break;

    }

    return res + NewsletterUtilsClass.getStateColor(item.state);

  }

  static getStateColor(state) {

    switch (<any>state) {

      case  CampaignStatus.created:
        return  'text-info';

      case CampaignStatus.sending:
        return  'text-warning-dker';

      case CampaignStatus.collectStat:
      case CampaignStatus.inProgress:
        return  'text-warning-dker';

      case CampaignStatus.done:
        return  'text-success';
    }

  }

  static getClassStatusTransaction(status) {

    switch (status) {

      case 'error':
      case 'undeliverable':
      case 'wrongNumber':
      case "forbidden":
      case "unavailableNumber":
        return 'fa-times-circle text-danger';
      case 'sending':
        return 'fa-arrow-circle-o-up text-warning-dker';
      case 'created':
        return 'fa-spinner text-primary';
      case 'read':
        return  'fa-envelope-open text-success';
      case 'delivered':
        return 'fa-envelope-o text-success';
      case 'unprocessed':
      case 'waitState':
        return 'fa-clock-o text-warning';
    }

  }

}
