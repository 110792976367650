'use strict';

import module from 'frontendModule';
import 'model/ReportController';
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.reports.index';
module.requires = module.requires.concat([
    'highcharts-ng',
    'ui.router'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider

                .route( routePath )
                .url('/:id')

                .template(   () => import( /* webpackChunkName: "assets/pages/reports.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/reports.index" */ "./controllers"       ))

                .breadcrumbs( [ "Отчёты" ] )

                .resolve('reportsList', [  'ReportController', 'loyaPermissions',
                  ( ReportController, loyaPermissions ) =>
                    ReportController.query().$promise
                      .then(function(list) {
                        if ( !loyaPermissions.byPath('global.clients').enabled ) {
                          return list.filter(i => ['Динамика расходов на SMS рассылки ПЛ.',
                          'Расходы на SMS рассылки кампаний.',
                          'Динамика расходов на E-mail рассылки ПЛ.',
                          'Расходы на E-mail рассылки кампаний.',
                          'Динамика начисленных бонусов ПЛ.',
                          'Начисленные бонусы в рамках кампаний.',
                          'Динамика списанных бонусов.',
                          'Сгоревшие бонусы.',
                          'Динамика накопленных бонусов.',
                          'Динамика участников ПЛ.'].indexOf(i.name) < 0);
                        } else {
                          return list;
                        }
                      })
                ] )

                .resolve('report', ['ReportController', '$stateParams', function (ReportController, $stateParams) {

                    if ( $stateParams.id || $stateParams.id === 0 ) {

                        if ($stateParams.id === 'new') {

                            return new ReportController({
                                "reportPlugin": "ReportBuilder",
                                "reportType": "custom"
                            });

                        }

                        return ReportController.get({
                            id: $stateParams.id
                        }).$promise;


                    } else {

                        return false;

                    }

                }] )

                .permissions( {
                    only: [ 'Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager' ],
                    redirectTo: 'frontend.nopermissions'
                })

        }]);


