/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for FileUploadApi services
 */
@Injectable({
  providedIn: 'root',
})
export class FileUploadApiConfiguration {
  rootUrl: string = location.origin;
}

export interface FileUploadApiConfigurationInterface {
  rootUrl?: string;
}
