/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AccountApiConfiguration, AccountApiConfigurationInterface } from './account-api-configuration';

import { AccountApiService } from './services/account-api.service';

/**
 * Provider for all AccountApi services, plus AccountApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AccountApiConfiguration,
    AccountApiService
  ],
})
export class AccountApiModule {
  static forRoot(customParams: AccountApiConfigurationInterface): ModuleWithProviders<AccountApiModule> {
    return {
      ngModule: AccountApiModule,
      providers: [
        {
          provide: AccountApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
