import { QueryHandler } from './../../QueryHandler.class';
import { ResourceHandler} from './../../ngResourceHandler.class';

export const FILTER_FIELD = '$sort';

export interface IResourceSortOptions {
    paramName        : string,
    directionFlag    : string,
    values : {
        [ name : string ] : string
    }
}

export interface IQueryPagerOptions {
    field: string;
    value: string;
}


export class ngResourceSortString extends ResourceHandler{

    private currentSortValue   = "";
    private ascSortDirection   = true;
    private options:IResourceSortOptions;

    private defaultOptions: IResourceSortOptions = {

        paramName     : '',
        directionFlag : '-',
        values        : {}

    }

  static $inject = ['handler'];

  constructor(
        protected handler: QueryHandler
    ) {

        super(handler);
        this.options = (<IResourceSortOptions>  (<any>Object).assign({}, this.defaultOptions, this.handler.actions.sortString));

    }

    get field() {
        return FILTER_FIELD;
    }
    /*
    static bindToQuery = function (onQuery: any ) {
        onQuery(function (isStatic:any, original:any, handler:any) {

            if (!handler.actions.sort)
                return;

            if (isStatic) {
                return function ( ...args: any[] ) {

                    var sort = new ngResourceSorter(handler, handler.actions.sort, QueryHandler.extractParams( ...args ) );
                    var result = original.apply(this, QueryHandler.updateParams( sort.getCurrentParams(), ...args ) );
                    Object.defineProperty(result, '$sort', {
                        value        : sort,
                        enumerable   : false,
                        configurable : false,
                    });
                    return result;
                };
            }
        });
    };

*/
    static canHandle( actions: any ) {
        return actions && !!actions.sortString;
    }

    public getCurrentParams() {
        return {
            [ this.options.paramName ]: this.currentSort
        };
    };

    public query = function () {
        return this.handler.reset();
    };

    public set = function (value:any, direction:any) {

        if (typeof this.options.values[value] !== 'undefined') {
            value = this.options.values[value];
        }

        if (typeof direction !== 'undefined') {
            this.currentSortValue = value;
            this.ascSortDirection = direction;
        } else if ( this.currentSortValue === value) {
            if ( this.ascSortDirection === false) {
                 this.currentSortValue = '';
                 this.ascSortDirection = false;
            }
            else {
                 this.ascSortDirection = false;
            }
        } else {
            this.currentSortValue = value;
            this.ascSortDirection = true;
        }
        return this.query();
    };

    public reset = function () {
        this.currentSortValue = '';
        this.ascSortDirection = true;
        return this.query();
    };

    protected onUpdate( finish: Promise<any> ) {
        return this.getCurrentParams()
    };

    protected onStart() {}

    protected onReset() {}

    get currentSort() {

        if (!this.currentSortValue || this.currentSortValue === '') {
            return;
        }

        return (this.ascSortDirection ? '' : this.options.directionFlag) + this.currentSortValue;
    }

    get currentSortField() {

        if (!this.currentSortValue || this.currentSortValue === '') {
            return;
        }

        return this.currentSortValue;
    }

    get isUp() {
        return this.ascSortDirection;
    }

    get isDown() {
        return !this.ascSortDirection;
    }

    get isNone() {
        return this.currentSortValue === '';
    }

    private parseDirection( field: string ) : {
        currentSortValue : string  ;
        ascSortDirection : boolean ;
    } {
    if (!field) {
            return {
                currentSortValue: "",
                ascSortDirection: true
            };
        }
        if (field.indexOf(this.options.directionFlag) === 0) {
            return {
                currentSortValue: field.slice(this.options.directionFlag.length),
                ascSortDirection: false
            };
        }
        return {
            currentSortValue: field,
            ascSortDirection: true
        };
    };


}

