import { FieldHandler } from "./FieldHandler";
import {changeNames} from "../interface/changeTypes";
import {getNg1Injector} from "class/MigrationUtils";

export class FiltersHandler {

    // Фильтр по датам
    private _dateFrom : Date;
    private _dateTo   : Date;

    private $q: ng.IQService;

    get dateFrom() { return this._dateFrom; }
    set dateFrom( value ) {

        if ( value === null || <any>value === '' )
            value = undefined;

        this._dateFrom = value;
        this.updateFilters();
    }

    get dateTo() { return this._dateTo; }
    set dateTo( value ) {

        if ( value === null || <any>value === '' )
            value = undefined;

        this._dateTo = value;
        this.updateFilters();
    }


    // Фильтр по пользователю
    private _userId : number;

    get userId () { return this._userId; }
    set userId ( value ) {

        this._userId = value;
        this.updateFilters();

    }

    // Фильтр по полю
    private _fieldId: string;

    get fieldId () { return this._fieldId; }
    set fieldId ( value ) {
        this._fieldId = value;
        this.updateFilters();
    }

    // Фильтр по полю
    private _changeType: string;

    get changeType () { return this._changeType; }
    set changeType ( value ) {
        this._changeType = value;
        this.updateFilters();
    }

    public isPending: boolean;

    constructor() {
    }

    private updateFilters() {

        setTimeout( () => {

            this.isPending = true;
            Promise
                .all(this.callbacks.map( f => f() ))
                .then( () => this.isPending = false, () => this.isPending = false );

        });

    }

    private dateFormatter( inputDate : any ) {
        return inputDate + ' 00:00:00';
        /*
        return inputDate.getFullYear() + '-' +
                ('0' + (inputDate.getMonth() + 1 ) ).slice(-2) + '-' +
                ('0' + inputDate.getDate() ).slice(-2) + ' 00:00:00';*/

    }

    get queryParams() {

        let params = {};

        if ( this.dateFrom )
            params['dateFrom'] = this.dateFormatter( this.dateFrom );
        if ( this.dateTo )
            params['dateTill'] = this.dateFormatter( this.dateTo );

        if ( this.userId )
            params['userId']   = this.userId;

        if ( this.changeType )
            params['changeType']   = this.changeType;

        if ( this.fieldId && this.fieldId.indexOf(';') >=0 ) {
            params['clientProfile']   = this.nameComponent( this.fieldId.split(';')[0], !!this.fieldId.split(';')[2] );
            if (!params['clientProfile']) delete params['clientProfile'];

            params['field']           = this.fieldId.split(';')[1];
        }

        return params
    }

    private nameComponent(name : string, isCustom: boolean = false) {
        if (isCustom)
            return name;

        return  ['Common', 'System'].indexOf(name) >=0 ? '' : name;
    }

    public isFieldInFilter( profileId: string = '', path: string = '') {

        if (!this.fieldId ) return;

        let params = this.fieldId.split(';');

        if ( this.nameComponent(params[0], !!params[2] )  !== profileId)
            return;

        return this.nameComponent(params[1] , !!params[2] ) === FieldHandler.convertArraysInPath(path);
    }

    private callbacks : Array<Function> = [];
    public onUpdate( callback: Function ) {
        this.callbacks.push( callback );
    }

    private changeTypesForFilter: Array<any>;
    public getChangeTypes() {

        if (this.changeTypesForFilter)
            return this.changeTypesForFilter;

        this.changeTypesForFilter = [];
        Object.keys(changeNames).forEach( i =>
            this.changeTypesForFilter.push({
                id   : i,
                label : changeNames[i].charAt(0).toUpperCase() + changeNames[i].slice(1)
            })
        );

        return this.changeTypesForFilter;
    }
}
