<div class="modal-header  bg-light align-items-center"
     *ngIf="!isEditMode"
>

    <i class="fa fa-list text-success m-r-sm f-s-12"> </i>


    <div class=" relative">
      <div class=" overflow-wrap-break">
        <span class="h4"> Выбор точек продаж </span>


        <span class="dropdown"
              *loyaPermitIf="{path:'partner', permitEnabled: ''}"
        >

              <span *ngIf="!isPartnerSetted"
                    ngbDropdown
              >
                   <ul class="dropdown-menu scroll-y"
                       ngbDropdownMenu
                       *ngIf="partnerList.length"
                        style="max-height: 30em;">

                       <li *ngFor="let partnerItem of partnerList"
                           ngbDropdownItem
                           class="w-100 no-padder"
                           data-testid="item in partners"
                       >
                           <a href="javascript:void(0)"
                              [innerHTML]="partnerItem.value"
                              (click)="partnerId=partnerItem.id"
                              class="w-100"
                           ></a>
                       </li>

                   </ul>

                  <span class="h4" *ngIf="!!getPartnerById(partnerId)?.value"> для партнёра </span>

                  <span
                    ngbDropdownToggle
                    class="h4">

                     <span class=" text-success pointer  " style="max-width:600px" data-testid="partnerId">{{ getPartnerById(partnerId)?.value }} </span>

                     <i class="caret pointer " *ngIf="!!partnerList.length"></i>
                  </span>
              </span>

              <span *ngIf="isPartnerSetted"
                    class="h4"
              >
                  <span ng-if="getPartnerById(partnerId)?.value"> для партнёра <span
                    class="text-success " data-testid="partnerId">{{ getPartnerById(partnerId)?.value }} </span></span>
              </span>

          </span>


      </div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="">

      <button type="button" class="btn btn-sm btn-default m-r-xs btn-addon" data-testid="select()"
              (click)="select()"
      >
        <i class=" fa fa-check"></i>
        <span class="btn-name"
        >Выбрать</span>
      </button>

      <button type="button" class="btn btn-sm btn-default  " data-testid="cancel()"
              (click)="cancel()"
      >
        <!-- TODO подтверждение закрытия -->
        <i class=" fa fa-remove text-danger"></i>
      </button>

    </div>


</div>
<div class="modal-body locations-tree-body pb-0">

  <div class="locations-group-column bg-light b-r">

    <locations-groups-view
      [partnerId]="partnerId"
      [(selectedLocationGroupId)]="selectedLocationGroupId"
      [(selected)]="selectedLocationGroup"
      [selectedLocations]="selectedLocation"
      [selectOne]="selectOne"
      [(selectedAll)]="selectedAll"
    ></locations-groups-view>

  </div>

  <div class="locations-column">
    <div class="locations-column-header">

      <div class=" d-block padder mt-3 " >
        <search-input
          [(model)]="searchString"
          placeholder="Название/Адрес/Описание/Код"
        ></search-input>
      </div>

      <div class=" d-block padder mt-2 mb-2" >

        <ng-container
          *ngIf="!selectedAll">

          <span
            class="text-muted m-r-xs">Выбрано точек продаж:</span> <b data-testid="selectedLocation.size">{{selectedLocation.size}}</b>

        </ng-container>

        <span
          *ngIf="selectedAll"
          class="text-muted m-r-xs">Выбраны все точеки продаж</span>

      </div>

    </div>
    <div class="locations-column-body b-t">
      <locations-view
        [partnerId]="partnerId"
        [(selected)]="selectedLocation"
        [locationGroupId]="selectedLocationGroupId"
        [searchQuery]="searchString"
        [selectOne]="selectOne"
        [canCheckAll]="canCheckAll"
        [(selectedAll)]="selectedAll"
      ></locations-view>
    </div>
  </div>

</div>


<div class="location-block-loading"
     *ngIf="loader.isLoading('selectPrepare')"
>

  <i class="fa fa-spin fa-spinner text-warning fa-5x"></i>

</div>




