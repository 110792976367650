
<div class="search-block   b-b">


    <input type="text"
           placeholder="Поиск локаций"
           class="form-control "
           [(ngModel)]="searchQuery"
           (keydown.enter)="$event.preventDefault()"
           data-testid="locationGroup.search()"
    />


</div>

<div class="groups-block">
  <ul class="nav nav-scroll flex-column " style="">

    <li class="nav-item" *ngIf="locationGroupsItems.length > 0" data-testid="locationGroup.selectAll()">
      <a  class="nav-link"
          [ngClass]="{ 'nav-active':!selectedLocationGroupId }"
          (click)="locationGroupSelect(false)">
        <span class="m-l-lg">-- ВСЕ --</span>
      </a>
    </li>

    <li class="nav-item" *ngFor="let locationGroupItem of locationGroupsItems | callback: filterBySearch"
        data-testid="item of locationGroupsItems">

      <a (click)="locationGroupSelect(locationGroupItem)"
         [ngClass]="{ 'nav-active':selectedLocationGroupId==locationGroupItem?.id }"
         class="nav-link d-flex"
      >

        <div class="checkbox mr-2">
          <label class="i-checks mb-0">
            <input type="checkbox"
                   data-testid="locationGroup.input"
                   [checked]="isSelected(locationGroupItem.id)"
                   (change)="changeSelected(locationGroupItem.id, !!$event?.currentTarget?.checked)"
                   [disabled]="isDisabled(locationGroupItem.id)"
            >
            <i></i>
          </label>
        </div>

        <span>
            <text-highlight [search]="searchQuery" [text]="locationGroupItem.name" ></text-highlight>
        </span>
      </a>
    </li>

    <li class="nav-item wrapper text-center"
        *ngIf="loader.isLoading('locationGroupsItems')">

      <i class="fa fa-spin fa-spinner text-warning fa-2x"></i>

    </li>



  </ul>
</div>
