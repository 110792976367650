'use strict';

import module from 'frontendModule';

import { ClientCards } from './controller';
import * as  templateView    from  './index.html';

export default module.component('clientCards', {
    templateUrl : templateView,
    controller  : ClientCards,

    bindings: {
        clientId       : '<'
    }

});

