import {ResourceHandler} from './ngResourceHandler.class';
import {QueryHandler} from './QueryHandler.class';

import {ngGlobalHandler} from './handlers/ngGlobalHandler.class';

import {ngResourcePager} from './handlers/pager/ngResourcePager.class';
import {ngResourceSort} from './handlers/sorter/ngResourceSort.class';
import {ngResourceSortString} from './handlers/sorter/ngResourceSortString.class';
import {ngResourceFilter} from './handlers/filters/ngResourceFilter.class';
import {ngResourceSearch} from './handlers/search/ngResourceSearch.class';
import {ngResourceInterval} from './handlers/intervals/ngResourceInterval.class';

let staticId = (id: any) => "$" + id;

const resourceHandlers: any[] = [
    ngResourcePager,
    ngResourceSort,
    ngResourceSortString,
    ngResourceFilter,
    ngResourceSearch,
    ngResourceInterval
];

export class ngResourceDecorator {

    private delegatedInterceptors = new WeakMap();
    private delegateSettings = new WeakMap();
    private queries = new WeakMap();
    private queryHandlers = new WeakMap();

    static $inject = ['$provide'];

    constructor(private $provide: any) {

        this.delegatedInterceptors = new WeakMap();
        $provide.decorator('$resource', this.decorate);

    }

    private decorate = ['$delegate', ($delegate: any) => {
        return  (url: any, paramDefaults: any, actions: Object, options: any)  => {

            let delegateSettings = {
                url: url,
                paramDefaults: paramDefaults,
                actions: actions,
                options: options
            };

            // Создан ngResource
            var delegated = $delegate(url, paramDefaults, actions, options);

            if (this.queryHandlers.has(delegated)) {
                return delegated;
            }


            this.saveOriginQueries(delegated, delegateSettings);
            this.decorateQueries(delegated, delegateSettings);
            this.bindHandlers(delegated);



            return delegated;

        }
      }
    ]

    private saveOriginQueries = function (delegated: any, delegateSettings: any) {
        let queries = {};
        let queriesStatic = {};

        this.queryHandlers.set(delegated, []);

        for (var id in delegateSettings.actions) {
            queries[id] = delegated[id];
            queriesStatic[staticId(id)] = delegated.prototype[staticId(id)];
        }

        this.queries.set(delegated, queries);
        this.queries.set(delegated.prototype, queriesStatic);
    };

    private decorateQueries = function (delegated: any, delegateSettings: any) {
        this.queryHandlers.set(delegated, []);

        for (var id in delegateSettings.actions) {
            delegated[id] = this.decorateQuery(id, delegated, delegateSettings);
//          delegated[staticId(id)] = this.decorateQuery(staticId(id), delegated.prototype, delegateSettings);
        }
    };

    private decorateQuery = function (id: any, delegated: any, delegateSettings: any) {

        let isStatic = !(id.indexOf('$') === 0);
        let originalQuery = this.queries.get(delegated)[id];
        let queryHandlers = this.queryHandlers.get(delegated);
        let resourceHandlers = this.getResourceHandlers(delegateSettings.actions[id]);

        return function (...args: any[]) {

            if (resourceHandlers.length === 0) {
                return originalQuery.apply( this, args);
            }

            let handler = new QueryHandler(id, delegateSettings.actions[id], originalQuery);
            let handlers:ResourceHandler[]  = [];

            resourceHandlers.forEach((resource: ResourceHandler) => {
                handlers.push( new (<any>resource)(handler) )
            });

            new (<any>ngGlobalHandler)(handler,handlers);

            return handler.query(...args);

            /* if (!queryHandlers.length) {
                 return originalQuery(...args);
             }

             let handler = new QueryHandler(id, delegateSettings.actions[id], originalQuery);
             let original = originalQuery;

             queryHandlers.forEach((h: any) => {
                 original = h(isStatic, original, handler) || original;
             });

             return handler.bindResults(original(...args));*/
        };
    };


    private onQuery = function (delegated: any) {

        return (handler: any) => {
            var queryHandlers = this.queryHandlers.get(delegated);
            queryHandlers.push(handler);
        };
    };

    private getResourceHandlers = function (settings: any) {

        return resourceHandlers.filter(
            (handler: ResourceHandler) => (<any>handler).canHandle(settings)
        )

    };

    private bindHandlers = function (delegated: any) {

        //    ngResourcePager.bindToQuery(this.onQuery(delegated).bind(this));
        /*    ngResourceSorter.bindToQuery(this.onQuery(delegated).bind(this));
            ngResourceFilter.bindToQuery(this.onQuery(delegated).bind(this));
            ngResourceSearch.bindToQuery(this.onQuery(delegated).bind(this));*/

    };


}

