import {IssuingType, StartPeriodTypes, StopPeriodTypes, ExternalId} from "./IssuingType";

import { ECoupon } from "./../couponKinds/eCoupon"
import { CouponWithBarcode } from "./../couponKinds/CouponWithBarcode"
import { ImportedCoupon } from "./../couponKinds/ImportedCoupon"
import { CouponWithBarcodeWithReceptParams } from "../couponKinds/CouponWithBarcodeWithReceptParams";



export class External extends IssuingType {

    static id = ExternalId;
    static couponKindsArray  = [ ECoupon, CouponWithBarcode, ImportedCoupon, CouponWithBarcodeWithReceptParams  ];

    public startPeriodArray = [
        StartPeriodTypes.START_PERIOD_FORM_DATE,
        StartPeriodTypes.START_PERIOD_SCHEDULED
    ];

    public stopPeriodArray = [
        StopPeriodTypes.STOP_PERIOD_FORM_DATE,
        StopPeriodTypes.STOP_PERIOD_SCHEDULED
    ];
}
