import {Injectable} from "@angular/core";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {
  HashtagDictionaryApiService
} from "../../../../api/HashtagDictionaryApi/services/hashtag-dictionary-api.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class HashtagDictionaryService {

  constructor(
    private hashtagDictionaryApiService: HashtagDictionaryApiService,
    private $http: HttpClient
  ) {
  }

  public getAllTagsParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "drop",
      limitField : "limit",
      limit: 200
    },
    search: {
      field: "prefix"
    }
  });

  public getAllTags$(params:{prefix?: string, limit?: number, drop?: number}){
    return this.$http.post('/hashtag/all', params)
  }


}
