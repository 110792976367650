import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from "@angular/core";

import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../shared/service/confirmAction/confirmAction.service";
import {getNg1Service} from "../../../../shared/class/utils/angularjs.utils";

import "./cards-view-page.legacy";
import {CardService} from "../../../../shared/service/api/Card/Card.service";
import {Card} from "../../../../api/CardApi/models/card";
import {CARD_STATE_NAMES, CARD_STATES} from "../../../../shared/service/api/Card/Card.values";
import {RawCard} from "../../../../api/CardApi/models/raw-card";

@Component({
  selector: 'cards-view-page',
  templateUrl: './cards-view-page.component.html',
  providers: [
    StatesChangerService,
    CardService
  ]
})
export class CardsViewPageComponent implements OnInit, OnChanges{

  @Input() id: number;

  @ViewChild('cardForm') cardForm;
  public currentCard: Card;
  public currentCardInformation: RawCard;

  private uiRouterState;

  public CARD_STATE_NAMES = CARD_STATE_NAMES;
  public CARD_STATES = CARD_STATES;

  public isLoading = false;

  constructor(
    private cardService:CardService,
    public statesChangerService:StatesChangerService,
    public confirmActionService: ConfirmActionService,

  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getCardById(changes.id.currentValue)
        .then( (result:any) => {

          if (changes.id.firstChange) {

            this.statesChangerService.state = result && typeof result.id === "undefined" ?
                                              'edit' :
                                              'view';

          }

        })
      ;
    }
  }

  getCardById( id?:number  ) {

    this.isLoading = true;

    return this.cardService
        .getOrCreate$(id)
        .toPromise()
        .then( result => this.currentCard = result )
        .then( result => {
          this.isLoading = false;
          return result;
        }, err => {
          this.isLoading = false;
          return Promise.reject(err);
        })
        .then( this.getClientForCard.bind(this) )


  }

  getClientForCard( card:Card ) {

    if ( typeof card?.clientId === "undefined")
      return  Promise.resolve( card );

    return this.cardService
      .query$({
        filterClientId: card.clientId,
        pagerDrop: 0,
        pagerLimit: 99999,
      })
      .toPromise()
      .then( result => {
        this.currentCardInformation = result.find( i => i.card.id === card.id);
        return card;
      });

  }


  onSave() {

    this.cardService
      .createOrUpdate$(this.currentCard)
      .subscribe(result=> {

        if ( !this.id && this.id!==0 ) {
          this.uiRouterState.go('^.view', {id: result.id}, {reload: true});
          return;
        }

        this.currentCard = result;
        this.statesChangerService.state = 'view';
      });

  }

  onCancel() {
    if (typeof this.currentCard.id === "undefined") {
      this.uiRouterState.go('^.index');
      return
    }

    this.getCardById(this.id)
        .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentCard.id === "undefined")
        return;

    this.cardService
      .delete$(this.currentCard.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  isCreateForm() {
    return typeof this.currentCard?.id === "undefined";
  }


}
