import { QueryHandler } from './../../QueryHandler.class';
import { ResourceHandler} from './../../ngResourceHandler.class';

export const FILTER_FIELD = '$sort';

export interface IResourceSortOptions {
    paramName  : string,
    onlyOne    : boolean,
    isObject   : boolean,
    isNotArray : boolean, // Не использовать массив в сортировке
    values : {
        [ name : string ] : string
    }
}

export interface IQuerySortOptions {
    field: string;
    order: string;
}


export class ngResourceSort extends ResourceHandler{

    private currentSortValues : any = {};
    private options           : IResourceSortOptions;

    private defaultOptions    : IResourceSortOptions = {
        paramName     : '',
        onlyOne       : true,
        isObject      : true,
        isNotArray    : false,
        values        : {}
    }

  static $inject = ['handler'];

  constructor(
        protected handler: QueryHandler
    ) {

        super(handler);
        this.options = (<IResourceSortOptions>  (<any>Object).assign({}, this.defaultOptions, this.handler.actions.sort));

    }

    get field() {
        return FILTER_FIELD;
    }

    static canHandle( actions: any ) {
        return actions && !!actions.sort;
    }

    public getCurrentParams() {

      if (this.options.isObject) {

        let result: IQuerySortOptions[] = [];

        Object.keys( this.currentSortValues ).forEach( ( key: string ) => {

            result.push(<IQuerySortOptions>{
                field: key,
                order: this.currentSortValues[key] ? 'asc' : 'desc'
            });

        });

        if ( result.length ) {
            return QueryHandler.setValueByPath({},  this.options.paramName, (this.options.isNotArray) ? result[0] : result, this.options.isObject );
        } else {
            return {};
        }
      }

      if (!this.options.isObject) {
        let fieldResult, orderResult;

        Object.keys( this.currentSortValues ).forEach( ( key: string ) => {
            fieldResult =  key;
            orderResult = this.currentSortValues[key] ? 'asc' : 'desc'
        });

        if ( fieldResult ) {
          let res = QueryHandler.setValueByPath({},  this.options.paramName + '.field', fieldResult, this.options.isObject );
          return QueryHandler.setValueByPath(res,  this.options.paramName + '.order', orderResult, this.options.isObject );
        } else {
          return {};
        }

      }
    };

    public query = function () {
        return this.handler.reset();
    };

    public set = function (value:any, direction:any) {

        if (typeof this.options.values[value] !== 'undefined') {
            value = this.options.values[value];
        }

        if ( this.options.onlyOne ) {
            Object.keys( this.currentSortValues )
                .filter( i => i !== value )
                .forEach( key => {
                    delete this.currentSortValues[ key ];
                } );
        }

        if (typeof direction !== 'undefined') {

            this.currentSortValues[value] = direction;

        } else {

            switch( true ) {

                case typeof this.currentSortValues[value] === "undefined":
                    this.currentSortValues[value] = true;
                    break;

                case this.currentSortValues[value] === true:
                    this.currentSortValues[value] = false;
                    break;

                case this.currentSortValues[value] === false:
                    delete this.currentSortValues[value];
                    break;

            }

        }


        return this.query();
    };

    public reset = function () {
        this.currentSortValues = {};
        return this.query();
    };

    protected onUpdate( finish: Promise<any> ) {
        return this.getCurrentParams()
    };
//TODO Доделать инициализацию
    protected onStart( firstQueryOptions: Object ) {

        if ( firstQueryOptions) {
          // обьект
          if( this.options.isObject && QueryHandler.getValueByPath( firstQueryOptions, this.options.paramName) ) {

            const sortParams = QueryHandler.getValueByPath( firstQueryOptions, this.options.paramName );
                  sortParams.forEach( ( param : IQuerySortOptions ) => {

                      let value = param.field;
                      if (typeof this.options.values[value] !== 'undefined') {
                          value = this.options.values[value];
                      }

                      this.currentSortValues[value] = param.order === 'asc';

                  });

          }
          // строка
          if( !this.options.isObject ) {

            let value = QueryHandler.getValueByPath( firstQueryOptions, this.options.paramName + '.field', this.options.isObject );
            if (value && typeof this.options.values[value] !== 'undefined') {
              value = this.options.values[value];
              let order = QueryHandler.getValueByPath( firstQueryOptions, this.options.paramName + '.order', this.options.isObject );
              this.currentSortValues[value] = order === 'asc';

            }

          }

      }
    }

    protected onReset() {}

    get currentSort() {

        if ( !Object.keys(this.currentSortValues).length ) {
            return;
        }

        const firstField = Object.keys(this.currentSortValues)[0];

        return (this.currentSortValues[firstField] ? '' : '-') + firstField;
    }

    get currentSortField() {
        if ( !Object.keys(this.currentSortValues).length ) {
            return;
        }

        return Object.keys(this.currentSortValues)[0];
    }

    get isUp() {
        if ( !Object.keys(this.currentSortValues).length ) {
            return;
        }

        return this.currentSortValues[ Object.keys(this.currentSortValues)[0] ] === true;
    }

    get isDown() {
        if ( !Object.keys(this.currentSortValues).length ) {
            return;
        }

        return this.currentSortValues[ Object.keys(this.currentSortValues)[0] ] === false;
    }

    get isNone() {
        return !(Object.keys(this.currentSortValues).length );
    }

}

