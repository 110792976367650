import { Injectable, Input} from "@angular/core";
import {getNg1Service} from "../../class/utils/angularjs.utils";

@Injectable({
  providedIn: 'root',
})
export class LoyaPermissions {

  private ng1LoyaPermissions

  constructor() {
    this.ng1LoyaPermissions = getNg1Service('loyaPermissions');
  }

  byPath(path) {
    return this.ng1LoyaPermissions.byPath(path)
  }

}
