import {Inject, Injectable, Injector} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CouponRuleDialogComponent} from "../coupon-rule-dialog.component";

type legacyDirectiveParams = {
  resultDirective: (result: any) => any | {},  //+
  forPartner?: number | string,
  checkOne?: boolean | string,
  canCheckAll?: boolean | string,
  linkOne?: boolean | string,
  initSelectedItems?: any,
  initPartner?: number | string,
  max?: number,

}

@Injectable({
  providedIn: "root",
})
export class CouponRuleDialogService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public open(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      CouponRuleDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    return modalRef.result;

  }

  public legacyPopup(options: legacyDirectiveParams) {

    const modalRef = this.modalService.open(
      CouponRuleDialogComponent,
      {
        size: "xl",
        windowClass: 'good-tree-popup-modal'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    modalRef.result.then( result => {

      this.legacyResult(result, options.resultDirective)

    }, cancal => {})

  }

  private legacyResult(result, resultDirective) {

    if (typeof resultDirective === "function") {
      resultDirective(result);
    }

    if (typeof resultDirective === "object") {

      Object.keys(resultDirective).forEach( key => {
        delete resultDirective[key];
      })

      Object.assign(resultDirective, result);
    }

  }

  private legacySetOptions(modalInstance: CouponRuleDialogComponent, options: legacyDirectiveParams) {

    // Выбранный партнёр для старта
    if (!!options?.initPartner) {
      let partnerId = parseInt(<any>options?.initPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId );
    }

    // Форма только для этого партнёра
    if (!!options?.forPartner) {
      let partnerId = parseInt(<any>options?.forPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId, false );
    }

    if ( !!options?.linkOne || !!options?.checkOne ) {
      modalInstance.setOneSelect();
    }

    if ( !!options?.canCheckAll ) {
      modalInstance.setCheckAll( !!options?.canCheckAll );
    }

    if ( typeof options?.initSelectedItems !== "undefined") {
      modalInstance.initSelected(options?.initSelectedItems)
    }

    if ( typeof options?.max !== "undefined") {
      modalInstance.setMax(options?.max)
    }

  }


}
