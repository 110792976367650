<div class="fixed-tool-panel">

  <ng-container
    *loyaPermitIf="{path:'model.account', permitType: 'update'}"
  >
    <button type="button" class="btn btn-sm btn-success btn-addon pull-right collapse-xs"
            (click)="statesChangerService.click('correct')"
            *ngIf="!isArchive() && statesChangerService.hasButton('correct')"
            data-testid="account.edit()"
    >
      <i class=" fa fa-pencil"></i>
      <span class="btn-name  ">
        Корректировка
      </span>
    </button>
  </ng-container>

  <ng-container
    *loyaPermitIf="{path:'crm.accounts.merge', permitEnabled: ''}"
  >
    <button type="button"
            class="btn btn-sm btn-success btn-addon pull-right collapse-xs"
            [disabled]="!canMerge()"
            (click)="statesChangerService.click('merge')"
            *ngIf="!isArchive() && statesChangerService.hasButton('merge')"
            data-testid="account.merge()"

            [ngbPopover]="'Невозможно объединить счет не привязанный к клиенту'"
            [triggers]="'mouseenter'"
            [placement]="'bottom'"
            [disablePopover]="canMerge()"

    >
      <i class=" fa fa-users"></i>
      <span class="btn-name  ">
           Объединение
      </span>
    </button>
  </ng-container>

  <ng-container
    *loyaPermitIf="{path:'crm.accounts.list', permitEnabled: ''}"
  >
    <button type="button"
            class="btn btn-sm btn-success btn-addon pull-right collapse-xs"
            *ngIf="!statesChangerService.isActive"
            [uiSref]="'^.index'"
            data-testid="account.back()"
    >
      <i class="fa fa-arrow-left">
      </i>
      <span class="btn-name  ">
        К списку
      </span>
    </button>
  </ng-container>

  <ng-container
    *loyaPermitIf="{path:'crm.accounts.list', permitEnabled: ''}"
  >
    <button type="button"
            class="btn btn-sm btn-success btn-addon pull-right collapse-xs"
            *ngIf="!statesChangerService.isActive && !!clientId"
            data-testid="account.toClient()"
            [uiSref]="'frontend.crm.clients.view({id:' + clientId + '})'">
      <i class="fa fa-arrow-left"></i>
      <span class="btn-name">
        К клиенту
      </span>
    </button>
  </ng-container>

  <button type="button" class="btn btn-sm  btn-default btn-addon pull-right "
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          data-testid="account.save()"
          [disabled]="hasErrors || saveEvent"
  >
    <i class=" fa "
       [ngClass]="{ 'fa-spin  text-warning': saveEvent, 'fa-check text-primary': !saveEvent }"
    >
    </i>
    <span class="btn-name  ">Сохранить</span>
  </button>

  <button type="button" class="btn btn-sm  btn-default btn-addon pull-right "
          *ngIf="statesChangerService.hasButton('mergeSave')"
          (click)="statesChangerService.click('mergeSave')"
          data-testid="account.union()"
          [disabled]="hasErrors || saveEvent"
  >
    <i class=" fa "
       [ngClass]="{ 'fa-spin  text-warning': saveEvent, 'fa-check text-primary': !saveEvent }"
    >
    </i>
    <span class="btn-name m-l-xs hidden-xxs">Объединить</span>
  </button>

  <button type="button" class="btn btn-sm  btn-default btn-addon pull-right collapse-xs"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="account.cancel()">
    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name  ">Отменить</span>
  </button>

</div>


<div
  *ngIf="!loader.isLoading('account') && itemAccount"
  class="m-t m-b-xs"
>
  <grid-container>

    <div class="grid-item"
         grid-item-form = "№ Счёта"
         grid-item-form-icon = "fa-map-marker"
    >
      <div class="clearfix" [ngClass]="{'text-danger': itemAccount.account.state==='archive'}" data-testid="account.number">
        {{ itemAccount.account.number }}
      </div>
      <div class="m-b f-s-7 m-t-n-xs text-danger" *ngIf="itemAccount.account.state==='archive'" data-testid="account.state.archive">
        Архивный счёт
      </div>
    </div>

    <div class="grid-item"
         grid-item-form = "Активный баланс"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.balance"
    >
      <div class="">
        {{ itemAccount.account.balance }}
      </div>
    </div>

    <div class="grid-item"
         grid-item-form = "Неактивный баланс"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.balanceInactive"
    >
      <div class="">
        {{ itemAccount?.account?.balanceInactive || 0 }}
      </div>
    </div>

    <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

    <div class="grid-item"
         grid-item-form = "Начисленные бонусы"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.awardAmount"
    >
      <div class="">
        {{ itemAccount?.account?.awardAmount || 0 }}
      </div>
    </div>

    <div class="grid-item"
         grid-item-form = "Списанные бонусы"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.salesAmount"
    >
      <div class="">
        {{ itemAccount?.account?.salesAmount || 0 }}
      </div>
    </div>

    <div class="grid-item"
         grid-item-form = "Сгоревшие бонусы"
         grid-item-form-icon = "fa-map-marker"
         data-testid="account.burntAmount"
    >
      <div class="">
        {{ itemAccount?.account?.burntAmount || 0 }}
      </div>
    </div>

    <div class="grid-item grid-item_clean grid-item_clean_hr"
         *ngIf="itemAccount.bindsAccount && itemAccount.bindsAccount.length"
    ></div>

    <div class="grid-item grid-item__form"
         *ngIf="itemAccount.bindsAccount && itemAccount.bindsAccount.length"
    >
      <div class="grid-item__label">
        <i class="fa fa-map-marker">
        </i>
        Объединённые счета
      </div>
      <div class="grid-item__value">
        <div class="clearfix" *ngFor="let bind of  itemAccount.bindsAccount" data-testid="bind in itemAccount.bindsAccount">
          <a href=""
             [uiSref]="'^.edit({ id:' + bind.id + ', client_id: ' + clientId + ' })'"
             [innerText]="bind?.number"
             data-testid="bind.number"
          ></a>
        </div>
      </div>
    </div>

    <div class="grid-item grid-item_clean grid-item_clean_hr"
         *ngIf="isCreditEnabled"
    ></div>

    <div class="grid-item grid-item__form"
         *ngIf="isCreditEnabled"
    >
      <div class="grid-item__label">
        <i class="fa fa-map-marker">
        </i>
        Кредитные бонусы
      </div>
      <div class="grid-item__value" data-testid="creditAccount.balance">
        {{ itemCreditAccount?.balance }}
      </div>
    </div>

  </grid-container>

  <div class="clearfix"></div>

  <ng-container
    *ngIf="!!itemAccount?.clients?.length"
  >

    <div class="padder m-t ">
      <h5
        class="m-l "
        class-retro="clearfix f-s-12 text-dark-lter m-b padder-lg"
      >Клиенты</h5>

      <div class="line  b-b line-lg pull-in m-t-xs m-b-xs"></div>

    </div>

    <grid-container>

      <ng-container
        *ngFor="let client of itemAccount.clients"
      >

        <div class="grid-item"
             grid-item-form = "Клиент"
             grid-item-form-icon = "fa-user"
             [grid-item-label-top]="true"
        >
          <a
            href="javascript:void(0)"
            [innerHTML]="client.name + ' ' + (client.surname || '' )"
            [uiSref]="'frontend.crm.clients.view({id:' + client.id + '})'"
            data-testid="account.toClient()"
            ></a>

          <div class="text-danger clearfix small"
               *ngIf="client.state === 'archive'"
          >
            Клиент в архиве
          </div>

        </div>

        <div class="grid-item"
             grid-item-form = "Карты клиента"
             grid-item-form-icon = "fa-user"
             *ngIf="!!client.cards?.length "
        >
          <div *ngFor="let card of client.cards">
            <a
              href="javascript:void(0)"
              [innerHTML]="card.number"
              [uiSref]="'frontend.crm.cards.view({id:' + card.id + '})'"
            ></a>
          </div>
        </div>

        <div class="grid-item grid-item_clean " ></div>

      </ng-container>

    </grid-container>

  </ng-container>


</div>


<div *ngIf="!statesChangerService.isActive"
>
  <ul ngbNav #nav="ngbNav"
      class="nav-tabs m-t">

    <li ngbNavItem>

      <a ngbNavLink
         class="m-l-sm"
      >
        Операции по счёту
      </a>

      <ng-template ngbNavContent>
        <div class="m-t-n-xxs">
          <account-operations-log-edit-page
            [id]="itemAccount?.account?.id"
          ></account-operations-log-edit-page>
        </div>
      </ng-template>

    </li>

    <li ngbNavItem>

      <a ngbNavLink
         class="m-l-sm"
      >
        Операции по кредитному счёту
      </a>

      <ng-template ngbNavContent>
        <div class="m-t-n-xxs">
          <credit-account-operations-log-edit-page
            [id]="itemAccount?.account?.id"
          ></credit-account-operations-log-edit-page>
        </div>
      </ng-template>

    </li>

    <li ngbNavItem>

      <a ngbNavLink
         class="m-l-sm"
      >
        История изменений
      </a>

      <ng-template ngbNavContent>
        <div class="padder m-t-n-xxs">
          <client-log-history-ng1
            [accountId]="itemAccount.account.id">
          </client-log-history-ng1>
        </div>
      </ng-template>

    </li>

  </ul>

  <div
    *ngIf="!!itemAccount"
    [ngbNavOutlet]="nav"></div>

</div>

<div *ngIf="statesChangerService?.state === 'correct'"
     class="card m-l m-r m-t "
>
  <div class="card-header">
    Проводка по счёту
  </div>
  <div class="card-body p-l-none p-r-none">
    <account-edit-edit-page
      [id]  = "itemAccount?.account?.id"
      [max] = "itemAccount?.account?.balance"
      (onErrors)="hasErrors = $event"
      (onUpdated)="reloadPage()"
      [(onSave)]="saveEvent"
    ></account-edit-edit-page>
  </div>
</div>

<div *ngIf="statesChangerService?.state === 'merge'"
     class="card m-l m-r m-t "
>
  <div class="card-header">
    Объединение c другим счётом
  </div>
  <div class="card-body p-l-none p-r-none">
    <account-merge-edit-page
      [id]  = "itemAccount?.account?.id"
      (onErrors)="hasErrors = $event"
      (onUpdated)="reloadPage()"
      [(onSave)]="saveEvent"
      [clients]="itemAccount?.clients"
    ></account-merge-edit-page>
  </div>
</div>
