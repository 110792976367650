import {Component, DoCheck, Input, OnChanges, SimpleChanges} from "@angular/core";

const sphinxSpecChars = ['?', '%', '*', '+'];

@Component({
  selector: 'search-highlight',
  templateUrl: './search-highlight.component.html',

})
export class SearchHighlightComponent implements DoCheck, OnChanges{

   _localEnabled = false;
    // Флаги



    get isEnabled() {

      return this.model &&
        ( typeof this.model === "string" ?
          !!this.model :
          (!!this.model.$search && this.model.$search.isEnabled )
        ) &&
        this._localEnabled;
    };

    // Разбиение на подсвеченное
    public prefix: string;
    public highlighten : string;
    public postfix: string;

    // Передаваемые параметры в компонент
    @Input() public model: any;
    @Input() public text: string;


    public get searchPhrase() {
        return typeof this.model === "string" ? ( this.model || "" ) : this.model.$search.value;
    }

    constructor() {}

    public $onInit() {

        // Проверка на существование фильтров в списке
  //      this.$scope.$watch((_: any) => this.model.$search && this.model.$search.value, this.splitHighlight.bind(this));

    }

    ngOnChanges(changes: SimpleChanges) {
      if (!changes['model'].firstChange || !changes['text'].firstChange) {
        this.ngDoCheck();
      }
    }

    ngDoCheck() {
        this.splitHighlight(this.searchPhrase);
    }

    private splitHighlight = ( phase:any ) => {

        this._localEnabled = true;
        if (!this.isEnabled || !phase || !this.text ) {
            return;
        }

        let text = this.text.toString();

        let from  = text.toLowerCase().indexOf( this.searchPhrase.toLowerCase() );
        let count = this.searchPhrase.length;

        if ( from < 0 ) {
            this._localEnabled = false;
            return;
        }

        this.prefix      = text.substring(0, from);
        this.highlighten = text.substring(from, from + count);
        this.postfix     = text.substring(from + count);


    }
};
