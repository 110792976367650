import {Injectable} from "@angular/core";
import {SegmentationApiService} from "../../../../api/SegmentationApi/services/segmentation-api.service";
import {SegmentationQueryParam} from "../../../../api/SegmentationApi/models/segmentation-query-param";
import {catchError, map, switchMap} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import {Observable as __Observable} from "rxjs/internal/Observable";
import {SegmentRefreshState} from "../../../../api/SegmentationApi/models/segment-refresh-state";
import {SEGMENT_STATES} from "./Segment.values";
import {Segment} from "../../../../api/SegmentationApi/models/segment";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {SegmentationQueryFilter} from "../../../../api/SegmentationApi/models/segmentation-query-filter";

const setDefaultFilter = (state: SegmentationQueryParam["filter"]["state"], params?: SegmentationQueryParam, ) => {
  params = params || {};
  params.filter = params.filter || {};
  params.filter.state = state;

  return params;
}

@Injectable()
export class SegmentationService {

  public getListParams = new ParamsHandler({
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.ids',
        id: 'ids',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.state',
        id: 'state',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  constructor( private segmentationApiService: SegmentationApiService ) {
  }

  public get$( id: number | string, state?: SEGMENT_STATES): Observable<Segment>{

    const arrayToOne = result => {
      if (!Array.isArray(result) || !result?.length)
        return throwError(`Сегмент с ID: ${id} не найден`)

      return result[0];
    }

    if ( typeof id === "string")
      id = parseInt(id);

    if ( !!state ) {
      return this.segmentationApiService.query({
        filter: {
          ids: [id],
          state
        }
      }).pipe(
        map(arrayToOne)
      )
    }

    const idParams = {
      filter: {
        ids: [id]
      }
    }

    return this.getArchive$(idParams)
      .pipe(
        catchError( r => []),
        switchMap( r => !Array.isArray(r) || !r?.length ? this.getList$(idParams)  : of(r) ),
        map(arrayToOne)
      )

  }

  public query$( params?: SegmentationQueryParam) {

      return this.segmentationApiService.query(params)

  }


  public getList$( params?: SegmentationQueryParam ){

    return this.getRefresh$(params)
      .pipe(
        catchError( r => []),
        switchMap( r => !Array.isArray(r) || !r?.length ? this.getDraft$(params)  : of(r) ),
        catchError( r => []),
        switchMap( r => !Array.isArray(r) || !r?.length ? this.getDeploy$(params) : of(r) )
      )

  }

  public create$(params: SegmentationApiService.CreateParams){
    return this.segmentationApiService.create( params );
  }

  public update$(id: number | string, params: SegmentationApiService.UpdateParams['params'] | Segment): Observable<Segment> {

    if ( typeof id === "string")
      id = parseInt(id);

    return this.segmentationApiService.update( {
      id,
      params
    })
      .pipe(
        switchMap(r => this.get$(id))
      )

  }

  public getRefresh$( params?: SegmentationQueryParam ){
    params = setDefaultFilter("refresh", params );

    return this.segmentationApiService.query(params);
  }

  public getDeploy$( params?: SegmentationQueryParam ){
    params = setDefaultFilter("deploy", params );

    return this.segmentationApiService.query(params);
  }

  public getDraft$( params?: SegmentationQueryParam ){
    params = setDefaultFilter("draft", params );

    return this.segmentationApiService.query(params);
  }

  public getArchive$( params?: SegmentationQueryParam ){
    params = setDefaultFilter("archive", params );

    return this.segmentationApiService.query(params);
  }

  public refresh$() {
    return this.segmentationApiService.refresh()
  }

  public deploy$( updateCampaign = false ) {
    return this.segmentationApiService.deploy({
      updateCampaign
    })
  }

  public delete$() {
    return this.segmentationApiService.delete()
  }

  public getStates$(ids: number[]) {
      return this.segmentationApiService
        .getStates(ids)
  }

  public waitStates$(ids: number[]): Observable<Segment> {

    let timerId:any = 0;

    let requester = (obs) => {

      if (obs.closed) {
        clearTimeout(timerId);
        return;
      }

      if (!ids?.length) {
        obs.complete();
        return
      }

      this.getStates$(ids)
        .subscribe( async (result) => {

          let filterForRequest: SegmentationQueryFilter = {
            ids: [],
            state: "deploy"
          }

          result.forEach( segment => {

            if ( !(segment.completeness==='started' || segment.completeness==='unknown') ) {
              filterForRequest?.ids?.push(segment.id);
              filterForRequest.state = segment.state;
            }

          })

          if (!!filterForRequest?.ids?.length) {
            await this.query$({
              filter: filterForRequest
            }).toPromise()
              .then( segmentsList => {

                segmentsList.forEach( segment => {
                  obs.next(segment);
                  ids = ids.filter( i => i !== segment?.id)
                });

              }, (error) => {
                error?.stopPopupError();
                return Promise.resolve();
              });
          }

          if ( obs.closed || !ids?.length ) {
            obs.complete();
          } else {

            timerId = setTimeout( () => {
              requester(obs);
            }, 1000)

          }

        }, obs.error.bind(obs) )
    }

    return new Observable(obs => {
        requester(obs);
    })
  }

}
