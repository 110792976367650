import {Component, Input, OnChanges, OnInit, EventEmitter, Output} from "@angular/core";


@Component({
  selector: 'additional-menu',
  template: `
    <div class="additional-menu "
         [ngClass]="{ 'additional-menu_hide-mobile': hideMobileView}">

      <div class="additional-menu__menu-column details-nav"
           [hidden]="hideMenu"
      >

        <div class="wrapper-md b-b details-nav-top lter pointer"
             (click)="showDoc = !showDoc">

          <a class="btn btn-link pull-right m-t-n-xs m-r-n-sm link-grey">
            <i class="fa fa-caret-down f-s-14"></i>
          </a>

          <ng-content select="[selectedBlock]"></ng-content>

        </div>

        <div class="additional-menu__menu-container"
             [ngClass]=" { 'additional-menu__menu-container_visible': !!showDoc } ">

          <div class="additional-menu__menu-header " *ngIf="showAddButton || hasFilter">

            <button *ngIf="showAddButton"
                    class="additional-menu__menu-button btn btn-default btn-sm btn-addon"
                    (click)="onAddClickInner($event)"
                    data-testid="menu.add()">
              <i class="fa fa-plus text-success"></i>
              <span class="btn-name">
                            Добавить
                        </span>
            </button>

            <div *ngIf="hasFilter">
              <input type="text"
                     placeholder="Поиск"
                     data-testid="menu.search()"
                     name="filterValue"
                     [ngModel]="filterValue"
                     (keypress)="$event.keyCode == 13 ? $event.preventDefault() : null "
                     (ngModelChange)="onFilterChange($event)"
                     class="additional-menu__menu-search form-control form-control-sm "/>
            </div>

          </div>

          <div class="additional-menu__menu-slot"
               [ngClass]="{'additional-menu__menu-slot-no-header': !showAddButton && !hasFilter}"

               infiniteScroll
               [infiniteScrollDistance]="1"
               [infiniteScrollThrottle]="50"
               (scrolled)="onScroll.emit($event)"
               [scrollWindow]="false"
          >
            <ng-content select="[menuBlock]"></ng-content>
          </div>

        </div>

      </div>

      <div class="additional-menu__content-column contentSlot" >
        <ng-content select="[contentBlock]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./additional-menu.component.scss'],

})
export  class AdditionalMenuComponent  {

    @Input() public hasFilter   : boolean ;
    @Input() public filterValue : string ;
    @Output() public filterValueChange  = new EventEmitter() ;
    @Output() public onAddClick   = new EventEmitter();
    @Input() public hideMobileView : boolean;
    @Input() public hideMenu       : boolean;
    @Input() public showAddButton  : boolean;
    @Output() public onScroll  = new EventEmitter();


    public showDoc = false;

    constructor(
      /*  private $element : ng.IRootElementService,
        private $scope   : ng.IScope,
        private $transclude : ng.ITranscludeFunction*/)
    {
    }

    onFilterChange($event) {
      this.filterValue = $event;
      this.filterValueChange.emit(this.filterValue);
    }


    onAddClickInner($event) {
      if (this.onAddClick)
        this.onAddClick.emit($event);
    }
  /* private transcludeFix() {

       if ( this.$transclude.isSlotFilled('menuSlot') ) {
           this.$transclude(this.$scope.$parent, ( el:any, scope:any) => {
               this.$element.find('.menuSlot:first').append(el);
           }, undefined, 'menuSlot');
       }

       if ( this.$transclude.isSlotFilled('contentSlot') ) {
           this.$transclude(this.$scope.$parent, ( el:any, scope:any) => {
               this.$element.find('.contentSlot:first').append(el);
           }, undefined, 'contentSlot');
       }

   }
*/
}
