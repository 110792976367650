/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CountersApiConfiguration, CountersApiConfigurationInterface } from './counters-api-configuration';

import { CountersApiService } from './services/counters-api.service';

/**
 * Provider for all CountersApi services, plus CountersApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CountersApiConfiguration,
    CountersApiService
  ],
})
export class CountersApiModule {
  static forRoot(customParams: CountersApiConfigurationInterface): ModuleWithProviders<CountersApiModule> {
    return {
      ngModule: CountersApiModule,
      providers: [
        {
          provide: CountersApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
