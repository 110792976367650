/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { BrandGoodLinkApiConfiguration as __Configuration } from '../brand-good-link-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodByBrand } from '../models/good-by-brand';
@Injectable({
  providedIn: 'root',
})
class BrandGoodLinkApiService extends __BaseService {
  static readonly queryGoodsByBrandPath = '/brandgoodlink/{partnerId}/{id}';
  static readonly updatePath = '/brandgoodlink/{partnerId}/{id}';
  static readonly deleteListPath = '/brandgoodlink/{partnerId}/{id}/deleteList';
  static readonly deletePath = '/brandgoodlink/{partnerId}/{id}/{sku}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка товаров по ID партнера и по ID брэнда
   *
   * Запрос списка товаров по ID партнера и по ID брэнда
   * @param params The `BrandGoodLinkApiService.QueryGoodsByBrandParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID бренда
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование товара
   *     - `sku` - sku товара
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список брендов
   */
  queryGoodsByBrandResponse(params: BrandGoodLinkApiService.QueryGoodsByBrandParams): __Observable<__StrictHttpResponse<Array<GoodByBrand>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brandgoodlink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoodByBrand>>;
      })
    );
  }
  /**
   * Запрос списка товаров по ID партнера и по ID брэнда
   *
   * Запрос списка товаров по ID партнера и по ID брэнда
   * @param params The `BrandGoodLinkApiService.QueryGoodsByBrandParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID бренда
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc`
   *     - `desc`
   *     - `reverse`
   *
   * - `sorting.field`: Поле для сортировки:
   *     - `name` - наименование товара
   *     - `sku` - sku товара
   *
   * - `pager.limit`: Сколько записей возвращать (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей пропустить от начала спсика (по умолчанию 0)
   *
   * @return Список брендов
   */
  queryGoodsByBrand(params: BrandGoodLinkApiService.QueryGoodsByBrandParams): __Observable<Array<GoodByBrand>> {
    return this.queryGoodsByBrandResponse(params).pipe(
      __map(_r => _r.body as Array<GoodByBrand>)
    );
  }

  /**
   * Сохранить настройки
   * @param params The `BrandGoodLinkApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `listOfSKU`: Список sku товаров, которые надо добавить в указанный брэнд
   *
   * - `id`: ID бренда
   */
  updateResponse(params: BrandGoodLinkApiService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.listOfSKU;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/brandgoodlink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Сохранить настройки
   * @param params The `BrandGoodLinkApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `listOfSKU`: Список sku товаров, которые надо добавить в указанный брэнд
   *
   * - `id`: ID бренда
   */
  update(params: BrandGoodLinkApiService.UpdateParams): __Observable<null> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление товаров из брэнда
   * @param params The `BrandGoodLinkApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `listOfSKU`: Список sku товаров, которые надо удалить из указанного брэнда
   *
   * - `id`: ID бренда
   */
  deleteListResponse(params: BrandGoodLinkApiService.DeleteListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.listOfSKU;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/brandgoodlink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/deleteList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление товаров из брэнда
   * @param params The `BrandGoodLinkApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `listOfSKU`: Список sku товаров, которые надо удалить из указанного брэнда
   *
   * - `id`: ID бренда
   */
  deleteList(params: BrandGoodLinkApiService.DeleteListParams): __Observable<null> {
    return this.deleteListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление товара из брэнда
   * @param params The `BrandGoodLinkApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: Sku товара, который надо удалить из указанного брэнда
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID бренда
   */
  deleteResponse(params: BrandGoodLinkApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/brandgoodlink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.sku))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление товара из брэнда
   * @param params The `BrandGoodLinkApiService.DeleteParams` containing the following parameters:
   *
   * - `sku`: Sku товара, который надо удалить из указанного брэнда
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID бренда
   */
  delete(params: BrandGoodLinkApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BrandGoodLinkApiService {

  /**
   * Parameters for queryGoodsByBrand
   */
  export interface QueryGoodsByBrandParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID бренда
     */
    id: number;

    /**
     * Порядок сортировки:
     *   - `asc`
     *   - `desc`
     *   - `reverse`
     */
    sortingOrder?: 'asc' | 'desc' | 'reverse';

    /**
     * Поле для сортировки:
     *   - `name` - наименование товара
     *   - `sku` - sku товара
     */
    sortingField?: 'name' | 'sku';

    /**
     * Сколько записей возвращать (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала спсика (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список sku товаров, которые надо добавить в указанный брэнд
     */
    listOfSKU: Array<string>;

    /**
     * ID бренда
     */
    id: number;
  }

  /**
   * Parameters for deleteList
   */
  export interface DeleteListParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список sku товаров, которые надо удалить из указанного брэнда
     */
    listOfSKU: Array<string>;

    /**
     * ID бренда
     */
    id: number;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * Sku товара, который надо удалить из указанного брэнда
     */
    sku: string;

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID бренда
     */
    id: number;
  }
}

export { BrandGoodLinkApiService }
