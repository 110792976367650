import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnChanges
} from "@angular/core";
import {ngCurrencyL10nService} from "../../service/ngCurrencyL10n/ngCurrencyL10n.service";
import {getNg1Service} from "../../class/utils/angularjs.utils";




function defaultOpts(el: any, $state: any) {
  return {
    relative: $state.$current,
    inherit: true,
    source: 'sref',
  };
}

function parseStateRef(ref: string) {
  const paramsOnly = ref.match(/^\s*({[^}]*})\s*$/);
  if (paramsOnly) ref = '(' + paramsOnly[1] + ')';

  const parsed = ref.replace(/\n/g, ' ').match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);
  if (!parsed || parsed.length !== 4) throw new Error("Invalid state ref '" + ref + "'");
  return { state: parsed[1] || null, paramExpr: parsed[3] || null };
}

function processedDef($state: any, $element: any, def: any): any {
  const uiState = def.uiState || $state.current.name;
  const uiStateOpts = Object.assign({}, defaultOpts($element, $state), def.uiStateOpts || {});
  const href = $state.href(uiState, def.uiStateParams, uiStateOpts);
  return { uiState, uiStateParams: def.uiStateParams, uiStateOpts, href };
}

@Directive({
  selector: '[uiSref]'
})
export class UiSrefDirective implements OnInit, OnChanges {

  private $uiRouter: any;
  private $state: any;
  private ref: any;

  @Input() uiSref: string;

  constructor(
    private el: ElementRef
  ) {
  }


  ngOnInit() {

  }

  ngOnChanges( changes ) {

    if (changes.uiSref.firstChange) {
      this.$uiRouter = getNg1Service('$uiRouter');
      this.$state = this.$uiRouter.stateService;
    }

    if ( changes.uiSref ) {
      this.ref = parseStateRef(changes.uiSref.currentValue);
      this.ref.uiState = this.ref.state;

      if (this.ref.paramExpr) {
        this.ref.uiStateParams = getNg1Service('$rootScope').$eval(this.ref.paramExpr);
      }
      this.hrefUpdate();
    }

  }

  getDef() {
    return processedDef(this.$state, this.el, this.ref )
  };

  hrefUpdate() {
    const def = this.getDef();

    if (def.href != null) {
      this.createEventOnElement(def.href);
    }

  }

  createEventOnElement( href: string ) {
    if (this.el.nativeElement.tagName.toLowerCase() === 'a') {
      this.el.nativeElement.href = href;
    } else {
      this.el.nativeElement.addEventListener('click', () => {
        window.location.href = href
      })
    }
  }

}
