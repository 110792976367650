import {NgModule} from "@angular/core";
import {DynamicReportsPageComponent} from "./dynamic-reports-page.component";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {CommonModule} from "@angular/common";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {DiscountByCompanyReportComponent} from "./reports/discountByCompany/discount-by-company-report.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";


@NgModule({
  declarations: [
    DynamicReportsPageComponent,
    DiscountByCompanyReportComponent
  ],
    imports: [
        SharedComponentsModule,
        PipesComponentsModule,
        CommonModule,
        DirectiveModule,
        NgbDropdownModule,
        FormsModule,
        FiltersComponentsModule,
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports:[
  ]
})
export class DynamicReportsModule {

  constructor() {}

}
