<div class="clearfix card no-shadow">

    <div class="card-body no-padder" *ngIf="isServiceAvailable">

        <div class="flex-scroll mini-scroll scroll-x wrapper-sm padder">

            <image-uploader
                (onAdd)="getGUIDFromUloader($event)"
                *ngIf="!isMaxImages()"
            >
                <div class = "image-item-container b r"
                >

                  <div class="add-item text-center ">
                      <i class="fa fa-plus-circle fa-3x m-t-lg text-muted add-ic"></i>
                      <div class="clearfix"></div>
                      <div class="text-muted m-t f-s-8">Загрузить<br/>изображение</div>
                  </div>

                </div>
            </image-uploader>

            <div class     = "image-item-container b r"
                 *ngFor=" let item of items"
            >

                <div class="image-item text-muted"
                     *ngIf="!isLoaded(item)"
                >
                    <i [ngClass]="{'fa fa-ban fa-3x text-danger': isError(item),'fa fa-spin fa-spinner fa-3x  text-warning': isLoading(item) }"></i>
                </div>

                <div class="image-item"
                     *ngIf="isLoaded(item)"
                     [ngStyle]="{'background-image':'url('+getUrlByGUID(item)+')'}"
                >
                    <div class="image-size" [innerHtml]="getSize(item)"></div>
                </div>

                <div class="m-t-xs m-b-xs b clearfix"></div>

                    <div class="hbox hbox-auto control-container">

                        <div class="col">
                           <span ngbDropdown
                                 class="dropup"
                                 [container]="'body'"
                                 [placement]="'top'"
                               >
                              <a href="javascript:void(0)"
                                        id="simple-dropdown"
                                        class="item-type text-muted text-right f-s-9 word-break-all"
                                        ngbDropdownToggle>
                                  Выбор типа
                                  <i class="fa fa-caret-up"></i>
                              </a>

                              <ul class="dropdown-menu mini-scroll"
                                  ngbDropdownMenu
                                  aria-labelledby="simple-dropdown"
                                  onclick="event.stopPropagation()"
                              >
                                <li *ngFor="let type of listTypes"
                                    class     = "pinter"
                                >
                                    <label class="i-checks i-checks-sm m-l-xs m-t-xs m-b-xxs">
                                        <input type="checkbox"
                                            (change)="setType(item, type.id)"
                                            [checked]="typeChecked(item, type.id)"
                                        > <i></i>
                                        {{type.name}}
                                    </label>
                                </li>
                              </ul>
                          </span>
                        </div>

                        <div class="col text-right w1" *ngIf="hasInfo(item)">
                            <i class="fa fa-warning pointer text-warning m-l-xs"
                               popover-class = "f-s-8 text-muted text-center"
                               [ngbPopover]="hasInfo(item)"
                               [triggers]="'mouseenter:mouseleave'"
                            ></i>
                        </div>

                        <div class="col text-right w1">
                            <i class="fa fa-trash pointer text-danger m-l-xs"
                               (click)="confirmActionService.confirm('Вы уверены, что хотите удалить изображение?', deleteImageEvent.bind(this, item))"
                            ></i>
                        </div>
                    </div>


            </div>

        </div>

    </div>

    <div class="card-body no-padder text-center" *ngIf="!isServiceAvailable">

        <div class="text-muted m-t-lg m-b-lg f-s-12">
            <i class="fa fa-warning text-warning" data-testid="imageUuploadServiceIsUnavailable"></i>
            Сервис загрузки изображений недоступен
        </div>

    </div>

</div>
