/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AccumulationsForBonusesConfiguration as __Configuration } from '../accumulations-for-bonuses-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccumulationsProfileByAllPartners } from '../models/accumulations-profile-by-all-partners';
import { AccumulationsProfile } from '../models/accumulations-profile';
@Injectable({
  providedIn: 'root',
})
class AccumulationsForBonusesService extends __BaseService {
  static readonly getAccumulationsProfileByAllPartnersPath = '/{clientId}';
  static readonly getAccumulationsProfilePath = '/{clientId}/{partnerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос профиля клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getAccumulationsProfileByAllPartnersResponse(clientId: number): __Observable<__StrictHttpResponse<AccumulationsProfileByAllPartners>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccumulationsProfileByAllPartners>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param clientId ID клиента
   * @return Данные профиля клиента
   */
  getAccumulationsProfileByAllPartners(clientId: number): __Observable<AccumulationsProfileByAllPartners> {
    return this.getAccumulationsProfileByAllPartnersResponse(clientId).pipe(
      __map(_r => _r.body as AccumulationsProfileByAllPartners)
    );
  }

  /**
   * Запрос профиля клиента
   * @param params The `AccumulationsForBonusesService.GetAccumulationsProfileParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getAccumulationsProfileResponse(params: AccumulationsForBonusesService.GetAccumulationsProfileParams): __Observable<__StrictHttpResponse<AccumulationsProfile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(String(params.clientId))}/${encodeURIComponent(String(params.partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccumulationsProfile>;
      })
    );
  }
  /**
   * Запрос профиля клиента
   * @param params The `AccumulationsForBonusesService.GetAccumulationsProfileParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `clientId`: ID клиента
   *
   * @return Данные профиля клиента
   */
  getAccumulationsProfile(params: AccumulationsForBonusesService.GetAccumulationsProfileParams): __Observable<AccumulationsProfile> {
    return this.getAccumulationsProfileResponse(params).pipe(
      __map(_r => _r.body as AccumulationsProfile)
    );
  }
}

module AccumulationsForBonusesService {

  /**
   * Parameters for getAccumulationsProfile
   */
  export interface GetAccumulationsProfileParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { AccumulationsForBonusesService }
