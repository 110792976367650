import {Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges} from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'
import {merge} from "rxjs";

import {BrandService} from "../../../../../shared/service/api/Brand/Brand.service";
import {ClientCategory} from "../../../../../api/ClientCategoryApi/models/client-category";
import {Brand} from "../../../../../api/BrandApi/models/brand";
import {StatesChangerService} from "../../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../../shared/service/confirmAction/confirmAction.service";
import {BrandGoodLinkService} from "../../../../../shared/service/api/BrandGoodLinkApi/BrandGoodLink.service";
import {result} from "lodash";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {LoyaPermissions} from "../../../../../shared/service/loyaPermissions/loyaPermissions.service";
import {SettingsService} from "../../../../../shared/service/api/Settings/Settings.service";


@Component({
  selector: 'partners-index-page',
  templateUrl: './partners-index-page.component.html',
  providers:[
    PartnerService,
    StatesChangerService,
    ConfirmActionService,
    SettingsService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'pages.config.directory.partners'
    },
  ],
})
export class PartnersIndexPageComponent implements OnInit{

  @Input() id: number;

  @ViewChild('partnerForm') partnerForm;

  public currentPartner;
  private uiRouterState;

  public selectedPartner;

  public hasSelected = false;
  public deletedAll  = false;

  public updateCounter = 1;

  public periodList = {
    'day' : {
      label: 'День',
      shortLabel: 'дн.'
    },

    'month': {
      label: 'Месяц',
      shortLabel: 'мес.'
    }
  }

  public bonusBurnPeriodList = {

  'day' : {
    label: 'День',
    shortLabel: 'дн.'
  },

  'week' : {
    label: 'Неделя',
    shortLabel: 'нед.'
  },

  'month': {
    label: 'Месяц',
    shortLabel: 'мес.'
  },

  'year' : {
    label: 'Год',
    shortLabel: 'г.'
  },

}

  public discountOnChangePrecisionList = {
    0.1 : "0.10",
    0.5 : "0.50",
    1 : "1.00"
  };

  constructor(
    public  partnerService: PartnerService,
    public  statesChangerService:StatesChangerService,
    public  confirmActionService :ConfirmActionService,
    public  loyaPermissions: LoyaPermissions,
    public  settingsService: SettingsService,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  ngOnInit() {
    this.bindButtons();
    this.initSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getPartnerBydId( id?:number ) {

    this.currentPartner = undefined;

    return this.partnerService
      .getOrInit$(id)
      .toPromise()
      .then( result => this.currentPartner = result );
  }


  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)    );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  onSave() {

    this.partnerService
      .createOrUpdate$(this.currentPartner)
      .toPromise()
      .then(result => {
        this.currentPartner = result;
        this.selectedPartner = result;
      } )
      .then(() => {
        this.statesChangerService.state = 'view';
        this.updateCounter++;
      });

  }

  onCancel() {
    if (typeof this.currentPartner?.id === "undefined") {
      this.resetSelected();
      return
    }

    this.getPartnerBydId(this.currentPartner.id)
      .then(() =>  this.statesChangerService.state = 'view' );
  }

  onDelete() {
    if (typeof this.currentPartner.id === "undefined")
      return;

    this.partnerService
      .delete$(this.currentPartner.id)
      .subscribe(() => {
        this.resetSelected();
        this.updateCounter++;
        this.selectedPartner = undefined;
      })

  }

  onPreview() {
    this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;
  }

  onEdit() {
    this.statesChangerService.state = 'edit';
  }

  changeSelectedPartner( inPartner ) {
    this.statesChangerService.state = 'view';
    this.selectedPartner = inPartner;
    this.getPartnerBydId(inPartner?.id)
  }

  resetSelected() {
    delete this.currentPartner;
    delete this.selectedPartner;
  }

  createNew() {
    this.resetSelected();
    this.getPartnerBydId()
      .then(_ => {
        this.onEdit();
      })
    ;
  }

  //---------------------------------------

  public enabledBonusMNDE;
  public enabledCurrencyMNDE;

  public allowCreditAccount;

  public currencyToBonusRatio_N;
  public currencyMNDE_N;
  public bonusMNDE_N;

  public maxRestOnAccountInCurrency;

  public discountOnChangePrecisionEnabled = false

  checkBonusPermissions = function() {
    return this.loyaPermissions.byPath('campaign.mechanics.bonus').enabled;
  };

  checkSmsPermissions = function() {
    return this.loyaPermissions.byPath('channel.type.sms').enabled;
  };

  checkEmailPermissions = function() {
    return this.loyaPermissions.byPath('channel.type.email').enabled;
  };

  checkViberPermissions = function() {
    return this.loyaPermissions.byPath('channel.type.viber').enabled;
  }

  checkDiscountOnChangePrecisionEnabled = function() {
    let result = (
      this.currentPartner?.limits?.useDiscountOnChangeCash ||
      this.currentPartner?.limits?.useDiscountOnChangeCard
    ) &&
      (
        typeof this.currentPartner?.limits?.currencyMNDE === "undefined" ||
        parseFloat(this.currentPartner.limits.currencyMNDE) < 1
      );

    if (!result) {
      if (this.currentPartner?.limits)
        this.currentPartner.limits.discountOnChangePrecision = 1;
    }

    return result;
  }


  isUseAccountEnabled = function() {
    return this.allowCreditAccount;
  }

  isUseAccountDisabled = function( ) {

    if ( !this.isUseAccountEnabled() ) {

      if ( this.currentPartner?.limits?.useAccount) {
        delete this.currentPartner.limits.useAccount
      }

      return true;
    }


    let res = ! (
      ( this.calcN('currencyToBonusRatio') === 0 ) &&
      ( this.calcN('currencyMNDE')  === -2 ) &&
      ( this.calcN('bonusMNDE')  === -2 )
    );

    if (res && this.currentPartner?.limits?.useAccount) {
      delete this.currentPartner.limits.useAccount
    }

    return  res;
  }

  cantransferRestOnAccount = function( ) {
   /* if (!this.currentPartner ||
      !this.currentPartner.limits)
      return true;

    return  ( (this.currentPartner?.limits?.currencyMNDE || 0.01 ) * ( this.currentPartner?.limits?.bonusMNDE || 1 ) )
      % this.currentPartner.limits.currencyToBonusRatio === 0;*/

    return true;
  }

  changeLastVisitBonusBurn() {
    if (!!this.currentPartner?.limits?.lastVisitBonusBurnSettings?.lastVisitBonusBurn) {
      this.currentPartner = this.currentPartner || {};
      this.currentPartner.limits = this.currentPartner.limits || {}
      this.currentPartner.limits.lastVisitBonusBurnSettings = this.currentPartner.limits.lastVisitBonusBurnSettings || {}
      this.currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnPeriod = Object.keys(this.bonusBurnPeriodList)[0];
    } else {

      if (this.currentPartner?.limits?.lastVisitBonusBurnSettings?.lastVisitBonusBurnPeriod) {
        delete this.currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnPeriod;
        delete this.currentPartner.limits.lastVisitBonusBurnSettings.lastVisitBonusBurnValidity
      }

    }

  }

  checkDiscountChange() {

    if (
      !this.currentPartner?.limits?.useDiscountOnChangeCash &&
      !this.currentPartner?.limits?.useDiscountOnChangeCard
    ) {

        if ( typeof this.currentPartner?.limits?.discountOnChangeLimit !== 'undefined' ) {
          delete this.currentPartner.limits.discountOnChangeLimit;
        }

        this.currentPartner.limits = this.currentPartner.limits || {};
        this.currentPartner.limits.allowBonusPaymentRounding = false;
        this.currentPartner.limits.discountOnChangeUseGoodLimits  = false;

    } else {
      this.currentPartner.limits = this.currentPartner.limits || {};
      this.currentPartner.limits.allowBonusPaymentRounding = true;
      this.currentPartner.limits.discountOnChangeUseGoodLimits  = true;
    }


    if ( !this.checkDiscountOnChangePrecisionEnabled() ) {
      if ( this.currentPartner.limits )
        delete this.currentPartner.limits.discountOnChangePrecision;
    } else {
      if ( typeof this.currentPartner?.limits?.discountOnChangePrecision === "undefined" )
        this.currentPartner.limits.discountOnChangePrecision = 1;
    }

  }

  calcK(id) {
    if (
      !this?.currentPartner?.limits ||
      !this?.currentPartner?.limits[id]
    )
      return 1;

    return Math.round(this?.currentPartner?.limits[id] / Math.pow(10, this.calcN(id) ) ) ;
  }

  changeK(newVal, id) {

    this.currentPartner.limits = this.currentPartner.limits || {};
    this.currentPartner.limits[id] = newVal * Math.pow(10, this.calcN(id) );

    if (this.currentPartner.limits[id] < 1)
      this.currentPartner.limits[id] = parseFloat(this.currentPartner.limits[id].toFixed( Math.abs(this.calcN(id))))

  }

  calcN(id) {
    if (
      !this?.currentPartner?.limits ||
      !this?.currentPartner?.limits[id]
    )
      return 0;

    let value = this?.currentPartner?.limits[id].toString();
    let zeroesMatches;

    if ( value.indexOf('0.') === 0 ) {
      zeroesMatches          = value.match('(?:0\.)(0*)(?:[1-9][0-9]*)');
      return Array.isArray(zeroesMatches) && zeroesMatches.length === 2 ? -zeroesMatches[1].length -1 : -1;
    } else {
      zeroesMatches          = value.match('(?:[0-9]*[1-9])(0+)(?:(?:[\.|,]0+)|$)');
      return Array.isArray(zeroesMatches) && zeroesMatches.length === 2 ? zeroesMatches[1].length  : 0;
    }

  }

  changeN(newVal, id) {

    this.currentPartner.limits = this.currentPartner.limits || {};
    this.currentPartner.limits[id] = this.calcK(id) * Math.pow(10, newVal );

    if (this.currentPartner.limits[id] < 1)
      this.currentPartner.limits[id] = parseFloat(this.currentPartner.limits[id].toFixed( Math.abs(newVal)))

  }

  isIdEnabled(id) {
    return this?.currentPartner?.limits &&
           typeof this?.currentPartner?.limits[id] !== "undefined"
  }

  changeIdEnabled(val, id) {

    if ( !val && this.isIdEnabled(id) ) {
      delete  this.currentPartner.limits[id];
    } else  {
      this.currentPartner.limits  = this.currentPartner.limits || {};
      this.currentPartner.limits[id] = 1;
    }

  }

  transferRestOnAccountMaxValueValidator = (value) => {

    if ( typeof value === "undefined"  ||
        !this.currentPartner ||
        !this.currentPartner.limits
       )
          return true;


    const number = parseFloat(value);
    if ( isNaN(number) && value !== '' )
      return false

    return number >= ( this.currentPartner.limits.currencyMNDE || 0.01) && number <= this.maxRestOnAccountInCurrency;

  }

  private initSettings() {
    this.settingsService.query$()
      .subscribe({
        next: ( result: any) => {

          try {
            this.maxRestOnAccountInCurrency = result.reduce(
              (acc, item) => item.key === 'processing.bonus.maxRestOnAccountInCurrency' ? parseFloat(item.value) || 5000 : acc,
              5000
            ) || 5000
          } catch(e) {
            this.maxRestOnAccountInCurrency = 5000;
          }

          try {
            this.allowCreditAccount = result.reduce(
              (acc, item) => item.key === 'credit.allowCreditAccount' ? item.value === 'true' : acc,
              false
            )
          } catch(e) {
            this.allowCreditAccount = false;
          }

        }
      })
  }


}
