'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';
import {RoleType} from "module/permissions/conf/roles";


let routePath = module.name + '.editions.certificate.view';
module.requires = module.requires.concat([
]);


export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')

                .template(template)
                .controller(controller)


                .resolve('partnerList', ['PartnerModel', function (PartnerModel) {

                    return PartnerModel.query().$promise.then(function( data ){
                        var result = {};

                        angular.forEach(data, function(item) {
                            result[item.id] = item;
                        });

                        data = null;

                        return result;
                    });

                }] )

                .resolve('certificateFull',  ['$stateParams', 'GiftCertificateModel', '$q',
                    function ($stateParams, GiftCertificateModel, $q ) {

                        if (!$stateParams.id && $stateParams.id !== 0) {
                            return new GiftCertificateModel({});
                        }

                        return GiftCertificateModel.getCertificate($stateParams).$promise;
                    }])

              .resolve('certificateItem',  ['certificateFull',
                function (certificateFull  ) {

                  return certificateFull.certificate;
                }])

              .resolve('editionItem',  ['$stateParams', 'EditionModel', '$q', 'certificateItem',
                function ($stateParams, EditionModel, $q, certificateItem ) {

                  if (!certificateItem.editionId  ) {
                    return {};
                  }

                  return EditionModel.get({id:certificateItem.editionId}).$promise;
                }])

              .resolve('historyItem',  ['certificateFull',
                function (certificateFull  ) {

                  return certificateFull.history;
                }])


                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager , RoleType.TopManager,  RoleType.HeadOperator,  RoleType.Operator],
                    permit: 'editions'
                })

                .breadcrumbs( ["Подарочные сертификаты", "Просмотр сертификата"] );

        }]);
