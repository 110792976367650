/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CategoryApiConfiguration as __Configuration } from '../category-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Category } from '../models/category';
import { RawCategorySearchResult } from '../models/raw-category-search-result';
import { CategoryGoodDimension } from '../models/category-good-dimension';
import { RawCategoryTree } from '../models/raw-category-tree';
import { CategoryLink } from '../models/category-link';
@Injectable({
  providedIn: 'root',
})
class CategoryApiService extends __BaseService {
  static readonly queryPath = '/category';
  static readonly createPath = '/category';
  static readonly searchPath = '/category/{partnerId}/search';
  static readonly treePath = '/category/{partnerId}/tree';
  static readonly queryByPartnerPath = '/category/{partnerId}';
  static readonly getPath = '/category/{partnerId}/{categoryId}';
  static readonly updatePath = '/category/{partnerId}/{categoryId}';
  static readonly deletePath = '/category/{partnerId}/{categoryId}';
  static readonly queryAllCategoryLinksPath = '/categorylink';
  static readonly reindexCategoryLinksPath = '/categorylink';
  static readonly getCategoryLinksByIdPath = '/categorylink/{partnerId}/{categoryId}';
  static readonly reindexCategoryLinksByIdPath = '/categorylink/{partnerId}/{categoryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка категорий
   *
   * Запрос списка категорий
   * @return Список категорий товаров
   */
  queryResponse(): __Observable<__StrictHttpResponse<Array<Category>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/category`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Category>>;
      })
    );
  }
  /**
   * Запрос списка категорий
   *
   * Запрос списка категорий
   * @return Список категорий товаров
   */
  query(): __Observable<Array<Category>> {
    return this.queryResponse().pipe(
      __map(_r => _r.body as Array<Category>)
    );
  }

  /**
   * Создание категории товаров
   *
   * Создание категории товаров
   * @param category Описание категории товаров
   * @return Категория создана
   */
  createResponse(category: Category): __Observable<__StrictHttpResponse<Category>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = category;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/category`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Category>;
      })
    );
  }
  /**
   * Создание категории товаров
   *
   * Создание категории товаров
   * @param category Описание категории товаров
   * @return Категория создана
   */
  create(category: Category): __Observable<Category> {
    return this.createResponse(category).pipe(
      __map(_r => _r.body as Category)
    );
  }

  /**
   * Поиск товара в дереве категорий
   *
   * Поиск товара в дереве категорий
   * @param params The `CategoryApiService.SearchParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `sort`: Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId. required: false type: string
   *
   * - `filter.sentence`:
   *
   * - `filter.rootId`: ID корневой категории, от которой построится дерево
   *
   * - `filter.dimensions`:
   *
   * @return Результаты поиска
   */
  searchResponse(params: CategoryApiService.SearchParams): __Observable<__StrictHttpResponse<RawCategorySearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.filterSentence != null) __params = __params.set('filter.sentence', params.filterSentence.toString());
    if (params.filterRootId != null) __params = __params.set('filter.rootId', params.filterRootId.toString());
    (params.filterDimensions || []).forEach(val => {if (val != null) __params = __params.append('filter.dimensions', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/category/${encodeURIComponent(String(params.partnerId))}/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawCategorySearchResult>;
      })
    );
  }
  /**
   * Поиск товара в дереве категорий
   *
   * Поиск товара в дереве категорий
   * @param params The `CategoryApiService.SearchParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `sort`: Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId. required: false type: string
   *
   * - `filter.sentence`:
   *
   * - `filter.rootId`: ID корневой категории, от которой построится дерево
   *
   * - `filter.dimensions`:
   *
   * @return Результаты поиска
   */
  search(params: CategoryApiService.SearchParams): __Observable<RawCategorySearchResult> {
    return this.searchResponse(params).pipe(
      __map(_r => _r.body as RawCategorySearchResult)
    );
  }

  /**
   * Получение дерева категорий/товаров
   *
   * Получение дерева категорий/товаров
   * @param params The `CategoryApiService.TreeParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `sort`: Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId.
   *
   * - `pager.limit`: Число элементов для выборки
   *
   * - `pager.drop`: Число пропускаемых элементов (по умлочнию 0)
   *
   * - `filter.rootId`: ID корневой категории, от которой построится дерево
   *
   * - `filter.dimensions`:
   *
   * @return Дерево категорий товаров
   */
  treeResponse(params: CategoryApiService.TreeParams): __Observable<__StrictHttpResponse<RawCategoryTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    if (params.filterRootId != null) __params = __params.set('filter.rootId', params.filterRootId.toString());
    (params.filterDimensions || []).forEach(val => {if (val != null) __params = __params.append('filter.dimensions', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/category/${encodeURIComponent(String(params.partnerId))}/tree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RawCategoryTree>;
      })
    );
  }
  /**
   * Получение дерева категорий/товаров
   *
   * Получение дерева категорий/товаров
   * @param params The `CategoryApiService.TreeParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `sort`: Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId.
   *
   * - `pager.limit`: Число элементов для выборки
   *
   * - `pager.drop`: Число пропускаемых элементов (по умлочнию 0)
   *
   * - `filter.rootId`: ID корневой категории, от которой построится дерево
   *
   * - `filter.dimensions`:
   *
   * @return Дерево категорий товаров
   */
  tree(params: CategoryApiService.TreeParams): __Observable<RawCategoryTree> {
    return this.treeResponse(params).pipe(
      __map(_r => _r.body as RawCategoryTree)
    );
  }

  /**
   * Запрос категорий по указанному партнёру
   *
   * Запрос категорий по указанному партнёру
   * @param partnerId ID партнёра
   * @return Список категорий
   */
  queryByPartnerResponse(partnerId: number): __Observable<__StrictHttpResponse<Array<Category>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/category/${encodeURIComponent(String(partnerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Category>>;
      })
    );
  }
  /**
   * Запрос категорий по указанному партнёру
   *
   * Запрос категорий по указанному партнёру
   * @param partnerId ID партнёра
   * @return Список категорий
   */
  queryByPartner(partnerId: number): __Observable<Array<Category>> {
    return this.queryByPartnerResponse(partnerId).pipe(
      __map(_r => _r.body as Array<Category>)
    );
  }

  /**
   * Запрос указанной категории
   *
   * Данные по указанной категории
   * @param params The `CategoryApiService.GetParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Описание категории
   */
  getResponse(params: CategoryApiService.GetParams): __Observable<__StrictHttpResponse<Category>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Category>;
      })
    );
  }
  /**
   * Запрос указанной категории
   *
   * Данные по указанной категории
   * @param params The `CategoryApiService.GetParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Описание категории
   */
  get(params: CategoryApiService.GetParams): __Observable<Category> {
    return this.getResponse(params).pipe(
      __map(_r => _r.body as Category)
    );
  }

  /**
   * Изменить указанную категорию
   *
   * Изменить указанную категорию
   * @param params The `CategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * - `category`: Описание категории
   *
   * @return Категория обновлена
   */
  updateResponse(params: CategoryApiService.UpdateParams): __Observable<__StrictHttpResponse<Category>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.category;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Category>;
      })
    );
  }
  /**
   * Изменить указанную категорию
   *
   * Изменить указанную категорию
   * @param params The `CategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * - `category`: Описание категории
   *
   * @return Категория обновлена
   */
  update(params: CategoryApiService.UpdateParams): __Observable<Category> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as Category)
    );
  }

  /**
   * Удалить указанную категорию
   *
   * Удалить указанную категорию
   * @param params The `CategoryApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   */
  deleteResponse(params: CategoryApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить указанную категорию
   *
   * Удалить указанную категорию
   * @param params The `CategoryApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   */
  delete(params: CategoryApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Полное дерево категорий
   *
   * Полное дерево категорий
   * @return Полное дерево категорий
   */
  queryAllCategoryLinksResponse(): __Observable<__StrictHttpResponse<Array<CategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/categorylink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CategoryLink>>;
      })
    );
  }
  /**
   * Полное дерево категорий
   *
   * Полное дерево категорий
   * @return Полное дерево категорий
   */
  queryAllCategoryLinks(): __Observable<Array<CategoryLink>> {
    return this.queryAllCategoryLinksResponse().pipe(
      __map(_r => _r.body as Array<CategoryLink>)
    );
  }

  /**
   * Переиндексировать все дерево
   *
   * Переиндексировать все дерево
   * @return Полное дерево категорий
   */
  reindexCategoryLinksResponse(): __Observable<__StrictHttpResponse<Array<CategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/categorylink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CategoryLink>>;
      })
    );
  }
  /**
   * Переиндексировать все дерево
   *
   * Переиндексировать все дерево
   * @return Полное дерево категорий
   */
  reindexCategoryLinks(): __Observable<Array<CategoryLink>> {
    return this.reindexCategoryLinksResponse().pipe(
      __map(_r => _r.body as Array<CategoryLink>)
    );
  }

  /**
   * Дерево для указанной категории
   *
   * Дерево для указанной категории
   * @param params The `CategoryApiService.GetCategoryLinksByIdParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Дерево категорий
   */
  getCategoryLinksByIdResponse(params: CategoryApiService.GetCategoryLinksByIdParams): __Observable<__StrictHttpResponse<Array<CategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/categorylink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CategoryLink>>;
      })
    );
  }
  /**
   * Дерево для указанной категории
   *
   * Дерево для указанной категории
   * @param params The `CategoryApiService.GetCategoryLinksByIdParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Дерево категорий
   */
  getCategoryLinksById(params: CategoryApiService.GetCategoryLinksByIdParams): __Observable<Array<CategoryLink>> {
    return this.getCategoryLinksByIdResponse(params).pipe(
      __map(_r => _r.body as Array<CategoryLink>)
    );
  }

  /**
   * Переиндексировать указанную категорию и всех её потомков
   *
   * Переиндексировать указанную категорию и всех её потомков
   * @param params The `CategoryApiService.ReindexCategoryLinksByIdParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Дерево категорий
   */
  reindexCategoryLinksByIdResponse(params: CategoryApiService.ReindexCategoryLinksByIdParams): __Observable<__StrictHttpResponse<Array<CategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/categorylink/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CategoryLink>>;
      })
    );
  }
  /**
   * Переиндексировать указанную категорию и всех её потомков
   *
   * Переиндексировать указанную категорию и всех её потомков
   * @param params The `CategoryApiService.ReindexCategoryLinksByIdParams` containing the following parameters:
   *
   * - `partnerId`: ID партнёра
   *
   * - `categoryId`: ID категории
   *
   * @return Дерево категорий
   */
  reindexCategoryLinksById(params: CategoryApiService.ReindexCategoryLinksByIdParams): __Observable<Array<CategoryLink>> {
    return this.reindexCategoryLinksByIdResponse(params).pipe(
      __map(_r => _r.body as Array<CategoryLink>)
    );
  }
}

module CategoryApiService {

  /**
   * Parameters for search
   */
  export interface SearchParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId. required: false type: string
     */
    sort?: string;
    filterSentence?: string;

    /**
     * ID корневой категории, от которой построится дерево
     */
    filterRootId?: number;
    filterDimensions?: Array<CategoryGoodDimension>;
  }

  /**
   * Parameters for tree
   */
  export interface TreeParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * Cортировка, список полей из модели, по которым будет произведена сортировка. По умолчанию сортировка по возрастанию. Если нужна сортировка по убыванию, то указать знак "-" перед именем поля модели. Пример: sort=name,-categoryId.
     */
    sort?: string;

    /**
     * Число элементов для выборки
     */
    pagerLimit?: number;

    /**
     * Число пропускаемых элементов (по умлочнию 0)
     */
    pagerDrop?: number;

    /**
     * ID корневой категории, от которой построится дерево
     */
    filterRootId?: number;
    filterDimensions?: Array<CategoryGoodDimension>;
  }

  /**
   * Parameters for get
   */
  export interface GetParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;
  }

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;

    /**
     * Описание категории
     */
    category: Category;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;
  }

  /**
   * Parameters for getCategoryLinksById
   */
  export interface GetCategoryLinksByIdParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;
  }

  /**
   * Parameters for reindexCategoryLinksById
   */
  export interface ReindexCategoryLinksByIdParams {

    /**
     * ID партнёра
     */
    partnerId: number;

    /**
     * ID категории
     */
    categoryId: number;
  }
}

export { CategoryApiService }
