/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GoodGroupLinkCategoriesApiConfiguration as __Configuration } from '../good-group-link-categories-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodGroupAssignedCategory } from '../models/good-group-assigned-category';
@Injectable({
  providedIn: 'root',
})
class GoodGroupLinkCategoriesApiService extends __BaseService {
  static readonly queryGoodGroupCategoriesPath = '/goodgrouplink/category/{partnerId}/{id}';
  static readonly addListPath = '/goodgrouplink/category/{partnerId}/{id}';
  static readonly deleteListPath = '/goodgrouplink/category/{partnerId}/{id}/deleteList';
  static readonly deleteAllPath = '/goodgrouplink/category/{partnerId}/{id}/deleteAll';
  static readonly deletePath = '/goodgrouplink/category/{partnerId}/{id}/{categoryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка ID, входящих в группу категорий
   *
   * Запрос списка ID, входящих в группу категорий
   * @param params The `GoodGroupLinkCategoriesApiService.QueryGoodGroupCategoriesParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список ID, входящих в группу категорий
   */
  queryGoodGroupCategoriesResponse(params: GoodGroupLinkCategoriesApiService.QueryGoodGroupCategoriesParams): __Observable<__StrictHttpResponse<Array<GoodGroupAssignedCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.sortingOrder != null) __params = __params.set('sorting.order', params.sortingOrder.toString());
    if (params.sortingField != null) __params = __params.set('sorting.field', params.sortingField.toString());
    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goodgrouplink/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoodGroupAssignedCategory>>;
      })
    );
  }
  /**
   * Запрос списка ID, входящих в группу категорий
   *
   * Запрос списка ID, входящих в группу категорий
   * @param params The `GoodGroupLinkCategoriesApiService.QueryGoodGroupCategoriesParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `sorting.order`: Порядок сортировки:
   *     - `asc` - по увеличению
   *     - `desc` - по убыванию
   *
   * - `sorting.field`: Поле (атрибут) сортировки списка:
   *     - `name` - атрибут "Наименование"
   *
   * - `pager.limit`: Сколько записей вернуть, по умолчанию - 50
   *
   * - `pager.drop`: Сколько записей пропустить от начала списка, по умолчанию - 0
   *
   * @return Список ID, входящих в группу категорий
   */
  queryGoodGroupCategories(params: GoodGroupLinkCategoriesApiService.QueryGoodGroupCategoriesParams): __Observable<Array<GoodGroupAssignedCategory>> {
    return this.queryGoodGroupCategoriesResponse(params).pipe(
      __map(_r => _r.body as Array<GoodGroupAssignedCategory>)
    );
  }

  /**
   * Привязка списка категорий к группе товаров
   * @param params The `GoodGroupLinkCategoriesApiService.AddListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список категорий для привязки
   *
   * - `id`: ID группы товаров
   */
  addListResponse(params: GoodGroupLinkCategoriesApiService.AddListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Привязка списка категорий к группе товаров
   * @param params The `GoodGroupLinkCategoriesApiService.AddListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список категорий для привязки
   *
   * - `id`: ID группы товаров
   */
  addList(params: GoodGroupLinkCategoriesApiService.AddListParams): __Observable<null> {
    return this.addListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление списка категорий из группы товаров
   *
   * Удаление списка категорий из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список категорий
   *
   * - `id`: ID группы товаров
   */
  deleteListResponse(params: GoodGroupLinkCategoriesApiService.DeleteListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.links;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/deleteList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление списка категорий из группы товаров
   *
   * Удаление списка категорий из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteListParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `links`: Список категорий
   *
   * - `id`: ID группы товаров
   */
  deleteList(params: GoodGroupLinkCategoriesApiService.DeleteListParams): __Observable<null> {
    return this.deleteListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удаление всех категорий из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteAllParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID категории товаров
   */
  deleteAllResponse(params: GoodGroupLinkCategoriesApiService.DeleteAllParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/goodgrouplink/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/deleteAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление всех категорий из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteAllParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID категории товаров
   */
  deleteAll(params: GoodGroupLinkCategoriesApiService.DeleteAllParams): __Observable<null> {
    return this.deleteAllResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Удалить категорию из группы товаров
   *
   * Удалить категорию из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `categoryId`: ID категории
   */
  deleteResponse(params: GoodGroupLinkCategoriesApiService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/goodgrouplink/category/${encodeURIComponent(String(params.partnerId))}/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить категорию из группы товаров
   *
   * Удалить категорию из группы товаров
   * @param params The `GoodGroupLinkCategoriesApiService.DeleteParams` containing the following parameters:
   *
   * - `partnerId`: ID партнера
   *
   * - `id`: ID группы товаров
   *
   * - `categoryId`: ID категории
   */
  delete(params: GoodGroupLinkCategoriesApiService.DeleteParams): __Observable<null> {
    return this.deleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GoodGroupLinkCategoriesApiService {

  /**
   * Parameters for queryGoodGroupCategories
   */
  export interface QueryGoodGroupCategoriesParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * Порядок сортировки:
     *   - `asc` - по увеличению
     *   - `desc` - по убыванию
     */
    sortingOrder?: 'asc' | 'desc';

    /**
     * Поле (атрибут) сортировки списка:
     *   - `name` - атрибут "Наименование"
     */
    sortingField?: 'name';

    /**
     * Сколько записей вернуть, по умолчанию - 50
     */
    pagerLimit?: number;

    /**
     * Сколько записей пропустить от начала списка, по умолчанию - 0
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for addList
   */
  export interface AddListParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список категорий для привязки
     */
    links: Array<{categoryId: number}>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for deleteList
   */
  export interface DeleteListParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * Список категорий
     */
    links: Array<{categoryId: number}>;

    /**
     * ID группы товаров
     */
    id: number;
  }

  /**
   * Parameters for deleteAll
   */
  export interface DeleteAllParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID категории товаров
     */
    id: number;
  }

  /**
   * Parameters for delete
   */
  export interface DeleteParams {

    /**
     * ID партнера
     */
    partnerId: number;

    /**
     * ID группы товаров
     */
    id: number;

    /**
     * ID категории
     */
    categoryId: number;
  }
}

export { GoodGroupLinkCategoriesApiService }
