<div class="fixed-tool-panel">

<!--  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view'"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>-->


  <div class="dropdown pull-left"  ngbDropdown>

    <button class="btn btn-default btn-sm btn-addon collapse-xs collapse-sm" ngbDropdownToggle>
      <i class="fa fa-filter">
      </i>
      <span class="btn-name">
          Статус
          <span class="caret "></span>
      </span>
    </button>

    <ul class="dropdown-menu scrollable"  ngbDropdownMenu onclick="event.stopPropagation()">


      <li class="wrapper-xs padder radio text-ellipsis">
        <label class="i-checks" (click)="$event.stopPropagation()">
          <input type="radio"
                 name="filterState"
                 [value]="referrerService.getListParams.getFilterValues('filterState')['active']"
                 [checked]="referrerService.getListParams.getFilterValue('filterState') === 'active'"
                 (change)="changeFilter($event)"
          >
          <i></i>
          Активные
        </label>
      </li>

      <li class="wrapper-xs padder radio text-ellipsis">
        <label class="i-checks" onclick="event.stopPropagation()">
          <input type="radio"
                 name="filterState"
                 [value]="referrerService.getListParams.getFilterValues('filterState')['archive']"
                 [checked]="referrerService.getListParams.getFilterValue('filterState') === 'archive'"
                 (change)="changeFilter($event)"

          >
          <i></i>
          Архивные
        </label>
      </li>

    </ul>

  </div>



</div>

  <div class="wrapper">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Никнейм/Промокод"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>{{ 'models.ReferrerApi.clientId.name' | transloco }}</th>
         <th>
           {{ 'models.ReferrerApi.nickname.name' | transloco }}
         </th>
         <th class="table-row-sm table-row-xs ">
             {{ 'models.ReferrerApi.balance.name' | transloco }}
         </th>
         <th>{{ 'models.ReferrerApi.promocode.name' | transloco }}</th>
         <th>
           {{ 'models.ReferrerApi.registrationDate.name' | transloco }}
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listReferrers">
          <tr
              [uiSref]="'^.view({id:'+item.id+'})'"
              class="pointer"
          >
            <td class="table-big-link">
              <a href="javascript:void(0)">
                <text-highlight [search]="searchString" [text]="item.fio || 'Клиент ID: ' + item.clientId" ></text-highlight>
              </a>
            </td>
            <td>
              <span [innerText]="item.nickname || ''"></span>
            </td>
            <td>
              <span [innerText]="item.balance || 0 | number"></span>
            </td>
            <td>
              <span [innerText]="item.promocode || ''"></span>
            </td>
            <td>
              <span [innerText]="item.registrationDate | loyaDateTime"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>
