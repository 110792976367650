import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportFromFileDialogComponent} from "./../import-from-file-dialog.component";
import {ImportCsvOptions} from "../import-from-file.variables";

@Injectable({
  providedIn: "root",
})
export class ImportFromFileService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  private openPopup(options: ImportCsvOptions) {

    const modalRef = this.modalService.open(
      ImportFromFileDialogComponent,
      {
        size: "xl",
        windowClass: 'import-from-file-dialog-popup-modal'
      }
    );

    this.setOptions(modalRef.componentInstance, options);
    modalRef.componentInstance.loadFile();

    return modalRef.result

  }

  private setOptions(modalInstance: ImportFromFileDialogComponent, options: ImportCsvOptions) {

    if (!!options?.columns) {
      modalInstance.setColumns(options?.columns)
    }

    if (typeof options?.limit !== "undefined") {
      modalInstance.setLimit(options.limit)
    }

    /*

    // Выбранный партнёр для старта
    if (!!options?.initPartner) {
      let partnerId = parseInt(<any>options?.initPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId );
    }

    // Форма только для этого партнёра
    if (!!options?.forPartner) {
      let partnerId = parseInt(<any>options?.forPartner);
      !isNaN(partnerId) &&  modalInstance.setPartner( partnerId, false );
    }

    if ( !!options?.linkOne || !!options?.checkOne ) {
      modalInstance.setOneSelect();
    }

    if ( !!options?.canCheckAll ) {
      modalInstance.setCheckAll( !!options?.canCheckAll );
    }

    if ( typeof options?.initSelectedItems !== "undefined") {
      modalInstance.initSelected(options?.initSelectedItems)
    }
*/


  }

  public async importFile(options: ImportCsvOptions) {
    return this.openPopup(options);

  }

}
