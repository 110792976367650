'use strict';

import module from 'frontendModule';

let routePath   = module.name + '.config.directory.users.index';
export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(   '<users-index-page></users-index-page>', true)
        .breadcrumbs( [ "Справочники", "Пользователи" ] );



    }]);
