import {Injectable} from "@angular/core";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {ReportSkuDiscountsApiService} from "../../../../api/ReportSkuDiscountsApi/services";
import {ReportParameters} from "../../../../api/ReportSkuDiscountsApi/models/report-parameters";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ReportSkuDiscountsService {

  public buildParams = new ParamsHandler({
    filters: [
      {
        id: 'campaigns',
        field: 'filter.campaigns',
        currentValue: undefined,
        type:'object'
      },
      {
        id: 'couponRules',
        field: 'filter.couponRules',
        currentValue: undefined,
        type:'object'
      },
      {
        id: 'locations',
        field: 'filter.locations',
        currentValue: undefined,
        type:'object'
      },
      {
        id: 'sku',
        field: 'filter.sku',
        currentValue: undefined,
        type:'object'
      },
      {
        id: 'dateFrom',
        field: 'filter.dates.dateFrom',
        type: 'object',
        currentValue: undefined
      },
      {
        id: 'dateTo',
        field: 'filter.dates.dateTo',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  constructor( private reportSkuDiscountsApiService:ReportSkuDiscountsApiService ) {
  }

  public build$(params: ReportParameters){
    return this.reportSkuDiscountsApiService.buildReport(params)
    /*        .pipe(
              map( result => {
                return {
                  "rows": [
                    {
                      "campaign": {
                        "id": 1,
                        "name": "asdasdas"
                      },
                      "couponRule": {
                        "id": 2,
                        "name": "dsfsdfsdf"
                      },
                      "sku": "asdasdfas",
                      "discount": 222,
                      "locations": [
                        {
                          "locationId": 1,
                          "locationName": "43t",
                          "discount": 33
                        },
                        {
                          "locationId": 2,
                          "locationName": "tfff",
                          "discount": 22
                        },
                        {
                          "locationId": 3,
                          "locationName": "zaxscvz",
                          "discount": 44
                        },
                        {
                          "locationId": 4,
                          "locationName": "asd",
                          "discount": 55
                        }
                      ]
                    }
                  ]
                }
              })
            )*/
  }


}
