/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { InternalMethodsApiConfiguration, InternalMethodsApiConfigurationInterface } from './internal-methods-api-configuration';

import { InternalMethodsApiService } from './services/internal-methods-api.service';

/**
 * Provider for all InternalMethodsApi services, plus InternalMethodsApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    InternalMethodsApiConfiguration,
    InternalMethodsApiService
  ],
})
export class InternalMethodsApiModule {
  static forRoot(customParams: InternalMethodsApiConfigurationInterface): ModuleWithProviders<InternalMethodsApiModule> {
    return {
      ngModule: InternalMethodsApiModule,
      providers: [
        {
          provide: InternalMethodsApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
