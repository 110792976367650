'use strict';

import module from 'frontendModule';

import { ClientCoupons } from './controller';
import * as  templateView    from  './index.html';

export default module.component('clientCoupons', {
    templateUrl : templateView,
    controller  : ClientCoupons,

    bindings: {
        clientId    : '<',
        state       : '<'
    }

});

