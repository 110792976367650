import {Segment} from "../../../../api/SegmentationApi/models/segment";

export enum SEGMENT_CALCULATED_FIELD {
  CLIENT_PERCENT = "clientsPercent",
  TOTAL_PERCENT = "totalPercent",

}

export class SegmentCalculatorClass {

  private values = new Map();
  private segmentMap = new Map<number,Segment>();

  static disabledId = new Set();

  constructor() {
  }

  segments(segments: Segment[] = []) {
    this.segmentMap.clear();
    this.values.clear();

    segments.forEach( segment => {
      this.segmentMap.set( segment.id, segment)
    })

  }

  value(id, field: SEGMENT_CALCULATED_FIELD) {

    const valueId = `${id}:${field}`;

    if ( this.values.has(valueId) )
      return this.values.get(valueId)

    switch (field) {

      case SEGMENT_CALCULATED_FIELD.CLIENT_PERCENT:
        this.values.set(valueId, this.getClientPercent(id));
      break;

      case SEGMENT_CALCULATED_FIELD.TOTAL_PERCENT:
        this.values.set(valueId, this.getTotalPercent(id));
      break;

    }

    return this.values.get(valueId);
  }

  disable(id) {
    SegmentCalculatorClass.disabledId.add(id);
    this.values.clear()
  }

  enable(id) {
    SegmentCalculatorClass.disabledId.delete(id);
    this.values.clear()
  }

  switch(id) {
    if (this.isEnabled(id))
      this.disable(id)
    else
      this.enable(id)
  }

  isEnabled(id) {
    return !SegmentCalculatorClass.disabledId.has(id);
  }

  getClientPercent(id) {

    if ( !this.isEnabled(id))
      return 0;

    const total = this.getTotal();

    let segment = this.segmentMap?.get(id);

    try {
      let clientsPercent = (total > 0) ? segment.clientCount * 100 / total : 0;
      return parseFloat(clientsPercent.toFixed(2));
    } catch (e) {
      return 0;
    }

  }

  getTotalPercent(id) {
    if ( !this.isEnabled(id))
      return 0;

    const totalLifeValue = this.getTotalLifeValue();

    let segment = this.segmentMap?.get(id);
    let totalPercent;

    try {

      if (segment.hasOwnProperty('lifeValue')) {
        totalPercent = (totalLifeValue > 0) ? segment.lifeValue * 100 / totalLifeValue : 0;
      } else {
        totalPercent = (totalLifeValue > 0) ? segment.period0?.total * 100 / totalLifeValue : 0;
      }

      return parseFloat( totalPercent.toFixed(2) );
    } catch (e) {
      return 0;
    }

  }

  private getTotal() {

    if ( !this.segmentMap.size ) {
      return 0;
    }

    if ( this.values.has('totalCountValue') )
      return this.values.get('totalCountValue')

    this.values.set('totalCountValue',
      Array.from(this.segmentMap).reduce(
        ( previousValue: number, [ ,currentValue ] ) => {

          if (typeof previousValue === "undefined")
            previousValue = currentValue?.totalClientsCount;

          return previousValue - (!this.isEnabled(currentValue?.id) ? currentValue.clientCount : 0)
        },undefined)
    )

    return this.values.get('totalCountValue')

  }

  private getTotalLifeValue() {

    if ( !this.segmentMap.size ) {
      return 0;
    }

    if ( this.values.has('totalLifeValue') )
      return this.values.get('totalLifeValue')

    this.values.set('totalLifeValue',
       Array.from(this.segmentMap).reduce( ( previousValue: number, [ ,currentValue ]) => {

        if ( typeof previousValue === "undefined" )
          previousValue = <any>currentValue?.totalLifeValue;

        if (currentValue.hasOwnProperty('lifeValue')) {
          return previousValue - (!this.isEnabled( currentValue?.id ) ? ( currentValue.lifeValue || 0 ) : 0);
        } else {
          return previousValue - (!this.isEnabled( currentValue?.id ) ? ( currentValue?.period0?.total || 0 ) : 0);
        }
      }, undefined)
    )
    return this.values.get('totalLifeValue');

  }

}
