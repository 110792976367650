export const LOYA_CONFIG={
  name: "LOYA",
  date: new Date(),
  color: {
    primary: "#305bc0",
    info: "#23b7e5",
    success: "#27c24c",
    warning: "#fad733",
    danger: "#f05050",
    light: "#f0f0f0",
    orange: "#f86120",
    dark: "#515151",
    black: "#363636"
  },
  settings: {
    navbarHeaderColor: "bg-white",
    navbarCollapseColor: "bg-white"
  }

}
