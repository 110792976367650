import { ModalImageUploader } from "./modal";
import {API_UPLOAD_IMAGE} from "../settings";


export class ImageUploader {

    // Передаваемые параметры в компонент
    public options      : any;
    public onAdd        : Function;
  static $inject = ['$uibModal', '$element', 'toaster' ];
    constructor( private $uibModal : any,
                 private $element  : any,
                 private toaster  : any
                 ) { }

    public $onInit() {
        this.bindChange();
        this.bindDragEvents();
    }

    public openModal(): Promise<any>{

        return this.$uibModal.open({
//            templateUrl : modalTemplate,
            controller  : ModalImageUploader,
            size        : 'lg'
        }).result;

    }

    private bindChange() {
        let that = this;

        this.$element.find('#imageUploaderInput')[0].addEventListener('change', function() {
            that.loadFiles(this.files);
        }, false);

    }

    private bindGUID( GUID: string) {

        this.onAdd({
            guid: GUID
        });

    }

    private bindDragEvents() {

        let dropArea = this.$element[0];
        ['dragenter', 'dragover', 'dragleave','drop'].forEach(eventName => {
            dropArea.addEventListener(eventName,  function preventDefaults (e:any) {
                e.preventDefault();
                e.stopPropagation();
            }, false)
        });

        dropArea.addEventListener('drop', ( e:any ) => {
                if (this.isLoading) return ;

                let dt    = e.dataTransfer;
                let files = dt.files;
                this.loadFiles(files);
            }
        , false);

    }

    public isLoading: boolean = false;
    private loadFiles( files: Array<any> ){
        let that = this;
        this.isLoading = true;
        let promises = [];

        for( var i = 0; i<files.length; i++ ) {

            if ( files[i].type.indexOf("image")!== 0 ){
                this.toaster.pop('warning','Ошибка загрузки файла', files[i].name + " не является изображением" );
                continue;
            }

            promises.push( new Promise( (resolve) => {

                    let xhr = new XMLHttpRequest();
                    let fd  = new FormData();

                    xhr.open("POST", API_UPLOAD_IMAGE, true);
                    xhr.onreadystatechange = function() {

                        if (xhr.readyState == 4) {

                            if (xhr.status == 200) {
                                that.bindGUID(xhr.responseText);
                            }

                            resolve();
                        }

                    };
                    fd.append("image", files[i]);
                    xhr.send(fd);

                })
            );

        }

        Promise.all(promises).then( () => this.isLoading = false, () => this.isLoading = false );

    }


}
