
export class CKEditorBuilder {

    private configJson : any = {};
    private divForHtmlFilter : HTMLElement;

    private setFormatters: Array<Function> = [];
    private getFormatters: Array<Function> = [];

    constructor(){
        this.init();
    }

    public init() {

        this.configJson = {
            language: 'ru',
            allowedContent: false,
            entities : true,
            entities_processNumerical : true,

            enterMode: 2,
            skin: 'icy_orange,/assets/ckeplugins/skins/icy_orange/',
            toolbar_LOYA: [
                {
                    name: 'document',
                    items: [ 'PlaceholderElements', '-', 'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Maximize', 'Format']
                }
            ],
            format_tags: 'pre',
            toolbar: 'LOYA',
            on: {
                setData: ( evt : any ) => {
                    this.setFormatters.forEach( f => evt.data.dataValue = f(evt.data.dataValue, evt) );

                    let currentString = evt.editor.getData();
                    this.setFormatters.forEach( f => currentString = f(currentString, evt) );

                    if ( evt.data.dataValue === currentString ) {
                        evt.cancel();
                        evt.stop();
                    }
                },

                getData: ( evt : any ) => {
                    this.getFormatters.forEach( f => evt.data.dataValue = f(evt.data.dataValue, evt) );
                }
            }
        };

    }

    public getConfig() {
        return this.configJson;
    }

    public setMonospace() {
        this.getFormatters.push( ( getString : string, evt: any) => this.filterHtml(getString)
                                                              .replace(/\s+$/, '')
                                                              .replace(/(?:\r\n|\r|\n)$/g, '')
        );
        this.setFormatters.push( ( setString : string, evt: any) => {

            if ( setString.indexOf('<pre>') !==0 ) {
                setString = `<pre>${setString}</pre>`;
            }

            return setString;
        } );

        return this;

    }

    private getBottomContainer(event:any) {

        let spaceId        = event.editor.ui.spaceId( 'bottom' );
        let spaceId_bottom = (<any>window).CKEDITOR.document.getById( spaceId );

        if (!spaceId_bottom || !spaceId_bottom.$) {
            return false;
        }

        if (spaceId_bottom.$.querySelector('span.pos_container')) {
            return spaceId_bottom.$.querySelector('span.pos_container');
        }

        let containerBottom = document.createElement('span');
        containerBottom.className = 'pos_container f-s-9 text-muted pull-right m-r';
        spaceId_bottom.$.appendChild(containerBottom);

        return containerBottom;

    };

    private colsRowsCounter(event:any) {
        let editableDiv = event.editor.document.$.body.children[0];

        let containerBottom = this.getBottomContainer(event);
        if (!containerBottom) return;

        let maxCols = editableDiv.innerText.split(/\n/ig).reduce(
            (accum:number, str:string, rowNum:number) => accum < str.replace(/\u200B/g,'').length ? str.replace(/\u200B/g,'').length : accum,
            0);

        let maxRows = editableDiv.innerText.split(/\n/ig).length - 1 > 0 ? editableDiv.innerText.split(/\n/ig).length - 1 : 1;

        containerBottom.innerText = `Столбцов: ${maxCols} Строк: ${maxRows}  `;
    };

    public addRowColumnCounter() {

        this.configJson.on = this.configJson.on || {};
        this.configJson.on.change = this.colsRowsCounter.bind(this);
        this.configJson.on.contentDom = (event : any) => {
            var editable = event.editor.editable();
            editable.attachListener( editable, 'keyup',    () => this.colsRowsCounter(event));
            editable.attachListener( editable, 'keypress', () => this.colsRowsCounter(event));
            editable.attachListener( editable, 'click',    () => this.colsRowsCounter(event));
            editable.attachListener( editable, 'focus',    () => this.colsRowsCounter(event));

            this.colsRowsCounter(event);
        };

        return this;
    };

    public filterHtml( model : any ) {
        if (! this.divForHtmlFilter )
            this.divForHtmlFilter = document.createElement("DIV");

        this.divForHtmlFilter.innerHTML = ( model || '')
            .replace(/<pre>[\n]?/ig,'')
            .replace(/<\/pre>\n/ig,'');

        return (this.divForHtmlFilter.textContent || this.divForHtmlFilter.innerText || "");
    };

    public getMaxlenValidator( max_count:number ) {
        return ( model : any ) => {
            return this.filterHtml(model).length <= max_count;
        }
    }

    public getCountChars(text : string) {

        if ( !text ) return 0;

        return this.filterHtml(text)
            .replace(/{{.*?}}/ig,'')
            .length;

    };

    public getCountFields(text : string) {

        if ( !text ) return 0;

        return  10 * (this.filterHtml(text).match(/{{.*?}}/ig) || []).length;

    };

    public setPlaceholders( placeholders: any[]) {

        let config = {
            draggable: true,
            placeholders: placeholders,
            startDelimiter: '{{',
            endDelimiter: '}}',
            uiType: 'combo'
        }

        this.configJson.placeholder_elements = config;
        let extraPlugins = this.configJson.extraPlugins || '';
        this.configJson.extraPlugins = ( extraPlugins.length ? ',' : '' ) + 'placeholder_elements';

        return this;
    }


}
