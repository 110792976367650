/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NewsletterApiConfiguration as __Configuration } from '../newsletter-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateTestNewsletter } from '../models/create-test-newsletter';
import { NewsletterProtocol } from '../models/newsletter-protocol';
import { NewsletterQueryParam } from '../models/newsletter-query-param';
import { NewsletterTotalProtocol } from '../models/newsletter-total-protocol';
import { NewsletterTotalQueryParam } from '../models/newsletter-total-query-param';
import { NewsletterProtocolTask } from '../models/newsletter-protocol-task';
import { NewsletterMessagesQueryParam } from '../models/newsletter-messages-query-param';
import { NewsletterTaskQueryParam } from '../models/newsletter-task-query-param';
import { StatisticResponse } from '../models/statistic-response';
@Injectable({
  providedIn: 'root',
})
class NewsletterApiService extends __BaseService {
  static readonly createPath = '/newsletter';
  static readonly queryPath = '/newsletter';
  static readonly taskTotalPath = '/newsletter/task/total';
  static readonly verificationTotalPath = '/newsletter/verification/total';
  static readonly taskQueryPath = '/newsletter/tasks';
  static readonly verificationQueryPath = '/newsletter/verifications';
  static readonly detailsPath = '/newsletter/{id}/details';
  static readonly statisticsPath = '/newsletter/all/statistics';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Создать тестовую рассылку
   * @param value Параметры создаваемой рассылки
   */
  createResponse(value: CreateTestNewsletter): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = value;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/newsletter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Создать тестовую рассылку
   * @param value Параметры создаваемой рассылки
   */
  create(value: CreateTestNewsletter): __Observable<null> {
    return this.createResponse(value).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос рассылок
   * @param params Фильтры запроса
   * @return Данные по рассылкам
   */
  queryResponse(params: NewsletterQueryParam): __Observable<__StrictHttpResponse<Array<NewsletterProtocol>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewsletterProtocol>>;
      })
    );
  }
  /**
   * Запрос рассылок
   * @param params Фильтры запроса
   * @return Данные по рассылкам
   */
  query(params: NewsletterQueryParam): __Observable<Array<NewsletterProtocol>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<NewsletterProtocol>)
    );
  }

  /**
   * Итого по информационным сообщениям
   * @param params Фильтры запроса
   * @return Итого по рассылкам
   */
  taskTotalResponse(params?: NewsletterTotalQueryParam): __Observable<__StrictHttpResponse<NewsletterTotalProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter/task/total`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsletterTotalProtocol>;
      })
    );
  }
  /**
   * Итого по информационным сообщениям
   * @param params Фильтры запроса
   * @return Итого по рассылкам
   */
  taskTotal(params?: NewsletterTotalQueryParam): __Observable<NewsletterTotalProtocol> {
    return this.taskTotalResponse(params).pipe(
      __map(_r => _r.body as NewsletterTotalProtocol)
    );
  }

  /**
   * Итого по авторизационнным рассылкам
   * @param params Фильтры запроса
   * @return Итого по рассылкам
   */
  verificationTotalResponse(params: NewsletterTotalQueryParam): __Observable<__StrictHttpResponse<NewsletterTotalProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter/verification/total`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsletterTotalProtocol>;
      })
    );
  }
  /**
   * Итого по авторизационнным рассылкам
   * @param params Фильтры запроса
   * @return Итого по рассылкам
   */
  verificationTotal(params: NewsletterTotalQueryParam): __Observable<NewsletterTotalProtocol> {
    return this.verificationTotalResponse(params).pipe(
      __map(_r => _r.body as NewsletterTotalProtocol)
    );
  }

  /**
   * Запросить список сообщений
   *
   * Запросить список сообщений
   * @param params Фильтры запроса
   * @return Данные о шаблоне механики
   */
  taskQueryResponse(params?: NewsletterMessagesQueryParam): __Observable<__StrictHttpResponse<Array<NewsletterProtocolTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewsletterProtocolTask>>;
      })
    );
  }
  /**
   * Запросить список сообщений
   *
   * Запросить список сообщений
   * @param params Фильтры запроса
   * @return Данные о шаблоне механики
   */
  taskQuery(params?: NewsletterMessagesQueryParam): __Observable<Array<NewsletterProtocolTask>> {
    return this.taskQueryResponse(params).pipe(
      __map(_r => _r.body as Array<NewsletterProtocolTask>)
    );
  }

  /**
   * Запросить список сообщений
   *
   * Запросить список сообщений
   * @param params Фильтры запроса
   * @return Данные о шаблоне механики
   */
  verificationQueryResponse(params?: NewsletterMessagesQueryParam): __Observable<__StrictHttpResponse<Array<NewsletterProtocolTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter/verifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewsletterProtocolTask>>;
      })
    );
  }
  /**
   * Запросить список сообщений
   *
   * Запросить список сообщений
   * @param params Фильтры запроса
   * @return Данные о шаблоне механики
   */
  verificationQuery(params?: NewsletterMessagesQueryParam): __Observable<Array<NewsletterProtocolTask>> {
    return this.verificationQueryResponse(params).pipe(
      __map(_r => _r.body as Array<NewsletterProtocolTask>)
    );
  }

  /**
   * Запросить список сообщений в рассылке
   *
   * Запросить список сообщений в рассылке
   * @param params The `NewsletterApiService.DetailsParams` containing the following parameters:
   *
   * - `params`: Фильтры запроса
   *
   * - `id`: ID рассылки
   *
   * @return Данные о шаблоне механики
   */
  detailsResponse(params: NewsletterApiService.DetailsParams): __Observable<__StrictHttpResponse<Array<NewsletterProtocolTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/newsletter/${encodeURIComponent(String(params.id))}/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewsletterProtocolTask>>;
      })
    );
  }
  /**
   * Запросить список сообщений в рассылке
   *
   * Запросить список сообщений в рассылке
   * @param params The `NewsletterApiService.DetailsParams` containing the following parameters:
   *
   * - `params`: Фильтры запроса
   *
   * - `id`: ID рассылки
   *
   * @return Данные о шаблоне механики
   */
  details(params: NewsletterApiService.DetailsParams): __Observable<Array<NewsletterProtocolTask>> {
    return this.detailsResponse(params).pipe(
      __map(_r => _r.body as Array<NewsletterProtocolTask>)
    );
  }

  /**
   * Запросить статистику
   *
   * Запросить статистику
   * @return Данные о шаблоне механики
   */
  statisticsResponse(): __Observable<__StrictHttpResponse<StatisticResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/newsletter/all/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StatisticResponse>;
      })
    );
  }
  /**
   * Запросить статистику
   *
   * Запросить статистику
   * @return Данные о шаблоне механики
   */
  statistics(): __Observable<StatisticResponse> {
    return this.statisticsResponse().pipe(
      __map(_r => _r.body as StatisticResponse)
    );
  }
}

module NewsletterApiService {

  /**
   * Parameters for details
   */
  export interface DetailsParams {

    /**
     * Фильтры запроса
     */
    params: NewsletterTaskQueryParam;

    /**
     * ID рассылки
     */
    id: number;
  }
}

export { NewsletterApiService }
