import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { getNg1Service } from '../../../../../shared/class/utils/angularjs.utils'


import {CampaignCategory} from "../../../../../api/CampaignCategoryApi/models";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {ParamsHandler} from "../../../../../shared/service/api/ParamsHandler/Params.handler";
import {MechanicCategory} from "../../../../../api/MechanicCategoryApi/models/mechanic-category";

@Component({
  selector: 'campaigncategory-index-page',
  templateUrl: './campaigncategory-index-page.component.html',
  providers: [
    CampaignCategoryService
  ]
})
export class CampaigncategoryIndexPageComponent implements OnInit{

  listCampaignCategory: Array<CampaignCategory> = [];
  searchString: string;

  constructor(
    private campaignCategoryService:CampaignCategoryService
  ) {
  }

  ngOnInit() {
    this.campaignCategoryService.getList$({})
      .subscribe( result => {
        this.listCampaignCategory = result;
      });
  }


  filterBySearch = (item: CampaignCategory) => {
    if (!this.searchString || !item) return true;

    return  item.name && item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) >=0;

  }

}
