<div class="clearfix" *ngIf="isEnabled">
    <div class="">

        <div class="hbox hbox-auto-xs">
            <div class="col ">

                <div class="input-group ">

                    <input type   = "text"
                           class  = "form-control"
                           data-testid="search.input"
                           [title]  = "placeholder || ''"
                           [(ngModel)]="_searchString"
                           (keydown)="_keyHook($event)"
                           [placeholder] = "placeholder || ''">

                    <span class="input-group-addon m-l-n-xxs"
                          *ngIf="isPending"
                    >
                        <i class="fa fa-spin fa-spinner"></i>
                    </span>

                    <span class="input-group-btn">

                            <button *ngIf="isSearchEnabled"
                                    class="btn btn-danger "
                                    type="button"
                                    (click)="resetSearch()"
                                    data-testid="resetSearch()">
                                <i class="fa fa-times"></i>
                            </button>

                            <button id="db-search"
                                    class="btn btn-default btn-addon w-sm "
                                    type="button"
                                    (click)="setSearch()"
                                    data-testid="search()">
                                <i class="fa fa-search"></i>
                                <span class="btn-name">Поиск</span>
                            </button>
                     </span>


                </div>
            </div>
        </div>

    </div>
</div>
