import {CouponKind, VirtualRuleBarcodeId} from "./CouponKind";
import {PosId} from "../IssuingTypes/IssuingType";



export class VirtualRuleBarcode  extends CouponKind {

    static id = VirtualRuleBarcodeId;

    protected sameClientCount = true;
    protected useCount = false;

    get hasGenereteBarcode() : boolean {
        return this.issuingType.getId() === PosId;
    }

    public $save() {

        this.ruleItem.couponRule.usingSettings = this.ruleItem.couponRule.usingSettings || {};
        this.ruleItem.couponRule.usingSettings.useCount = 1;

    }

}