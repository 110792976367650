
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  Optional,
  ViewChild
} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";
import {DELIMITER_LIST, DELIMITER_VALUES, ENCODING_LIST, ImportCsvOptions} from "./import-from-file.variables";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LoaderHandlerClass} from "../../class/loader-handler.class";
import {FileUploaderService} from "../../service/api/FileUploader/FileUploader.service";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'import-from-file-dialog',
  templateUrl: "./import-from-file-dialog.component.html",
  styleUrls: ['./import-from-file-dialog.component.scss'],
  providers: [
    PartnerService,
    FileUploaderService,
  ]
})
 export class ImportFromFileDialogComponent implements OnInit, OnChanges{

  @ViewChild('importOptionsForm') importOptionsForm: NgForm;

  public loader = new LoaderHandlerClass();
  public importOptions: any = {};

  public ENCODING_LIST = ENCODING_LIST;
  public DELIMITER_LIST = DELIMITER_LIST;
  public previewError = '';

  public fileUrl='';

  public viewOptions = {
    delimiter: 'semicolon',
    progressBarPercent: 0
  }
  public changePreview = new Subject<string>();

  private columns = [];
  private requiredKeys = [];

  public tableData;
  constructor(
      @Optional() private activeModal: NgbActiveModal,
      private fileUploaderService:FileUploaderService,
  )
  {
  }

  get isEncodingEnabled() {
    return true  // TODO
  }

  get isShowDelimiter() {
    return true  // TODO
  }

  get isNeedRequireColumn() {
    return this.requiredKeys.some( key => !this.importOptions?.columns?.some(c => c.id === key) );
  }

  get isCanImport()  {
    return ( !this.loader.isLoading('isProgress') &&
      !this.loader.isLoading('isParse') &&
      !this.loader.isLoading('isPreview') &&
      !this.importOptionsForm.invalid &&
      !this.isNeedRequireColumn
    )
  }

  get columnValues() {
    return this.columns;
  }

  public isRowEnabled(index) {
    this.importOptions.columns = this.importOptions.columns || [];
    return this.importOptions.columns.some( i => i.number === index )
  }

  public filterOthers = (i) => i.key !== 'other'

  public getRowNameForIndex(index) {

    this.importOptions.columns = this.importOptions.columns || [];
    let find = this.importOptions.columns.find( i => i.number === index );
    if ( !find )
      return 'Не задано';

    find = this.columnValues.find( i => i.key === find.id)
    return find?.value || 'Не задано';
  }

  setRowForIndex( rowKey, rowIndex ) {

    this.importOptions.columns = this.importOptions.columns || [];

    if (typeof rowKey === "undefined") {
      this.importOptions.columns = this.importOptions.columns.filter( i => i.number !== rowIndex);
    } else {
      this.importOptions.columns = this.importOptions.columns.filter( i => i.id !== rowKey);
      this.importOptions.columns.push({
        "id": rowKey,
        "number": rowIndex
      })
    }
  }

  ngOnInit() {

    this.changePreview
      .pipe(debounceTime(300))
      .subscribe((search) => {
        this.previewFile()
      });

  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public changeDelimiter() {

    if (
      this.viewOptions.delimiter !== 'other' &&
      typeof DELIMITER_VALUES[this.viewOptions.delimiter] !== "undefined"
    ) {
        this.importOptions.delimiter = DELIMITER_VALUES[this.viewOptions.delimiter];
    }

    if (!!this.importOptions.delimiter)
      this.changePreview.next();
  }

  public setColumns( columns: ImportCsvOptions["columns"] ) {
    this.columns = ( columns || [] ).slice();
    this.requiredKeys = this.columns.filter(i => i.required).map( i => i.key);
  }

  public setLimit(limit) {
    this.importOptions.limit = limit
  }

  onLoadFile() {
    this.importOptions = Object.assign({}, this.importOptions, {
      encoding: Object.keys(ENCODING_LIST)[0],
      skip: 1,
      delimiter: DELIMITER_VALUES["semicolon"]
    })

    this.previewFile();
  }

  previewFile() {

    if (
      !this.importOptions.delimiter ||
      !(parseInt(this.importOptions.skip)>0) ||
      !this.importOptions.encoding
    )
      return

    this.fileUploaderService.previewFile$(
      this.fileUrl,
      {
        delimiter: this.importOptions.delimiter,
        encoding: this.importOptions.encoding,
        skip:  parseInt(this.importOptions.skip),
        pager: {
          drop: 0,
          limit: 100
        }
      }
    )
      .pipe(
        this.loader.waitLoading('isPreview')
      )
      .subscribe( (result:any) => {
        this.tableData = result?.table || [];
      })

  }

  parseFile() {
    let params: any = {
      delimiter: this.importOptions.delimiter,
      encoding: this.importOptions.encoding,
      skip:  this.importOptions.skip,
      columns: this.importOptions.columns
    }

    if (typeof this.importOptions?.limit !== "undefined" ) {
      params.pager = {
        drop: 0,
        limit: this.importOptions.limit
      }
    }

    return this.fileUploaderService.parseFile$(
      this.fileUrl,
      params
    )
      .pipe(
        this.loader.waitLoading('isParse')
      )
      .toPromise()
  }

  async import() {

    try {
      let result = this.parseFile();
      this.activeModal.close(result );
    } catch (e) {
      this.activeModal.close(false );
    }

  }

  cancel() {
    this.activeModal.dismiss(false);
  }

  public loadFile() {

    this.loader.startLoading('isProgress');
    this.fileUploaderService
      .uploadFile$('.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',{
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event:any) => {

          this.loader.startLoading('isProgress');

          switch (event?.type) {
            case 0:
              this.viewOptions.progressBarPercent = 0;
              break;

            case 1:
              this.viewOptions.progressBarPercent = parseFloat( ((event.loaded / event.total) * 100).toFixed(2) );
              if (this.viewOptions.progressBarPercent ===  100)
                this.viewOptions.progressBarPercent = 99; // Бывает бэк долго тупит

              break;
            case 2:
            case 3:
              break

            case 4:
            default:

              this.loader.stopLoading('isProgress');
              this.fileUrl = event?.body;
              if (!this.fileUrl)
                this.cancel()

              this.fileUrl = this.fileUrl.replace( /\/.*\//ig, "" );

              this.onLoadFile();
          }

        },
        error: () => {
          this.cancel();
        }
      });
  }

}
