
import {Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges} from "@angular/core";
import {CategoryModelService} from "../../../../service/api/CategoryModel/CategoryModel.service";
import {CategoryApiService} from "../../../../../api/CategoryApi/services/category-api.service";
import {TreeComponent} from "@circlon/angular-tree-component";
import {TreeViewComponent} from "../tree-view/tree-view.component";
import {ITreeNode} from "@circlon/angular-tree-component/lib/defs/api";
import {BrandService} from "../../../../service/api/Brand/Brand.service";
import {LoaderHandlerClass} from "../../../../class/loader-handler.class";
import {GoodTreeGetterService} from "../../service/good-tree-getter.service";


@Component({
  selector: 'brand-view',
  templateUrl: "./brand-view.component.html",
  styleUrls: ['./brand-view.component.scss' ],
  providers: [
    BrandService
  ]
})
 export class BrandViewComponent implements OnInit, OnChanges {

    @Input() partnerId;

    @Input() selected = new Set<number>();
    @Output() selectedChange = new EventEmitter();

    @Input() max:number;
    @Input() selectOne:boolean;

    @Input()  searchQuery: string;

    public brandItems = [];
    public loader = new LoaderHandlerClass();

    constructor(
      private brandService: BrandService,
      private goodTreeGetterService: GoodTreeGetterService,
    )
    {
    }

    ngOnInit() {
      this.brandService.getListParams.reset();
      this.getBrands();
    }

    ngOnChanges(changes: SimpleChanges) {

      if (changes['partnerId'] && !changes['partnerId'].isFirstChange()) {
        this.brandService.getListParams.setFilterValue('partnerId', changes['partnerId'].currentValue )
        this.reset();
        return;
      }

      if (changes['searchQuery'] && !changes['searchQuery'].firstChange) {
        this.brandService.getListParams.search( changes['searchQuery'].currentValue )
        this.reset();
        return;
      }

    }

    getBrands() {
        this.goodTreeGetterService.getBrandsList(
            this.brandService.getListParams.params,
            this.partnerId
          )
          .pipe(
            this.loader.waitLoading('brandItems')
          )
          .subscribe({
            next: result => {
              this.brandItems = this.brandItems.concat( result );
            },
            error: err => {
              console.log(err)
            }
          })
      }

    isSelected = (id) =>
      this.selected.has(id)

    changeSelected(id, value) {

      if (value) {

        if (this.selectOne) {
          this.selected.clear();
        }

        this.selected.add(id)
        this.goodTreeGetterService.getBrand(id,this.partnerId);

      } else {
        this.selected.delete(id)
      }

      this.selectedChange.emit(this.selected);

    }

    isDisabled( id ) {

      if ( !Number.isInteger(this.max) ) {
        return false;
      }

      if ( this.selected.size >= this.max )
        return !this.isSelected(id);

      return false;

    };

    onScroll() {
      if (this.loader.isLoading('brandItems'))
        return;

      this.brandService.getListParams.next();
      this.getBrands()
    }

    reset() {
      this.brandItems = [];
      this.loader.stopLoading('brandItems');
      this.selected.clear();
      this.selectedChange.emit(this.selected);
      this.getBrands();
    }

}
