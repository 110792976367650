<div class="fixed-tool-panel"
     *ngIf="currentUser"
>


  <button type="button" data-testid="user.remove()" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete')"
          (click)="confirmActionService.confirm('Вы действительно хотите удалить пользователя ' + currentUser.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
          *ngIf="!isArchive(currentUser)"
  >
    <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
  </button>



  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          [hidden]="!statesChangerService.hasButton('edit')"
          (click)="statesChangerService.click('edit')"
          data-testid="user.edit()"
          *ngIf="!isArchive(currentUser)"
  >

    <i class=" fa fa-pencil">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
  </button>


  <button type="button" data-testid="user.save()" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button" data-testid="user.cancel()" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="user.cancel()">

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button" data-testid="user.preview()" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="mcategoryForm && mcategoryForm.form && mcategoryForm.form.invalid"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" data-testid="user.back()" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>


<div class="card w-full no-back"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentUser"
  >
    <form name="mcategoryForm" #mcategoryForm="ngForm" novalidate autocomplete="off">

      <grid-container>

      <div class="grid-item text-center grid-item_row4"
      >

        <photo-loader
          [isActive]="statesChangerService.isActive"
          [(model)]="currentUser.photoUrl"
        ></photo-loader>

      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.UserApi.id.name'  | transloco"
           grid-item-form-icon = "fa-user"
      >
        <span
          [innerHTML]="currentUser.id || '-'" data-testid="user.id"
        ></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.UserApi.name.name'  | transloco"
           grid-item-form-icon = "fa-user"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerText]="currentUser.name" data-testid="user.name"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
          <input
            type="text"
            class="form-control form-control-edittable"
            [(ngModel)]="currentUser.name"
            name="name"
            data-testid="user.input.name"
            autoComplete="off"
            [placeholder]="'models.UserApi.name.placeholder'  | transloco"
            [required]="true"
            [maxlength]="254"
          >
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.UserApi.email.name'  | transloco"
           grid-item-form-icon = "fa-user"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentUser.email" data-testid="user.email"
        ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
          <input
            type="email"
            email
            class="form-control form-control-edittable"
            [(ngModel)]="currentUser.email"
            name="email"
            data-testid="user.input.email"
            [placeholder]="'models.UserApi.email.placeholder'  | transloco"
            [required]="true"
            [maxlength]="254"
          >
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.UserApi.role.name'  | transloco"
           grid-item-form-icon = "fa-user"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="getRoleName(currentUser.role)" data-testid="user.role"
        ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
              <select class=" form-control "
                      [(ngModel)]="currentUser.role"
                      name="role"
                      data-testid="user.select.role"
                      [required]="true">
                  <option *ngFor="let c of getRolesList() | keyvalue " [ngValue]="c.key">{{c.value}}</option>
              </select>

        </span>
      </div>


        <div class="grid-item"
             *ngIf="statesChangerService.isActive && (changePassword || isCreate)"
             [grid-item-form] = "'models.UserApi.password.name'  | transloco"
             grid-item-form-icon = "fa-user"
        >
              <span class="editable-rec"
                    [hidden]="!statesChangerService.isActive"
              >
                <input
                  type="password"
                  autoComplete="off"
                  class="form-control form-control-edittable"
                  [(ngModel)]="password"
                  name="password"
                  data-testid="user.input.password"
                  [placeholder]="'models.UserApi.password.placeholder'  | transloco"
                  [required]="true"
                  [maxlength]="254"
                  (keyup)="onChangePassword()"
                  (change)="onChangePassword()"
                >
              </span>
          </div>


        <div class="grid-item"
             *ngIf="statesChangerService.isActive && (changePassword || isCreate)"
             [grid-item-form] = "'models.UserApi.confirmPassword.name'  | transloco"
             grid-item-form-icon = "fa-user"
        >
              <span class="editable-rec"
                    [hidden]="!statesChangerService.isActive"
              >
              <input
                type="password"
                autoComplete="off"
                class="form-control form-control-edittable"
                [(ngModel)]="confirmPassword"
                [class.ng-invalid]="password && password!==confirmPassword"
                name="confirmPassword"
                data-testid="user.input.confirmPassword"
                [placeholder]="'models.UserApi.confirmPassword.placeholder'  | transloco"
                [required]="true"
                [maxlength]="254"
                (keyup)="onChangePassword()"
                (change)="onChangePassword()"
              >

              <input
                type="hidden"
                [(ngModel)]="currentUser.password"
                name="currentUserPassword"
                [required]="true"
              >
            </span>
          </div>

          <div class="grid-item"
               *ngIf="statesChangerService.isActive && (!changePassword && !isCreate)"
               [grid-item-form] = "'models.UserApi.password.name'  | transloco"
               grid-item-form-icon = "fa-user"
          >
               <a
                 href="javascript:void(0)"
                 class="link"
                 (click)="changePassword = true"
                 data-testid="user.password.change()"
               >Изменить пароль</a>
          </div>


      </grid-container>
    </form>

  </div>

</div>
