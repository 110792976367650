import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {CampaignService} from "../../service/api/Campaign/Campaign.service";
import {map} from "rxjs/operators";
import {CAMPAIGN_STATES} from "../../service/api/Campaign/Campaign.values";
import {ControlContainer, NgForm} from "@angular/forms";

@Component({
  selector: 'select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.scss'],
  providers: [
    CampaignService
  ]
})
export class SelectCampaignComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();

  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = "Выбор кампании";

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  @Input() showArchive;

  drop    = 0;
  limit   = 20;
  isValid = true;

  digestSubscribe;
  dummy;

  constructor(
    private campaignService: CampaignService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }
  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.validate();
  }

  get showArchiveBlock() {
    return typeof this.showArchive !== "undefined";
  }

  pagerReset() {
    this.drop = 0;
  }


  pagerNext( searchText:string | boolean ) {

    let params : any = {
      pager: {
        drop  : this.drop,
        limit : this.limit
      }
    }

    if (typeof this.id !== "undefined") {
      params.selectedId = this.id;
    }

    if (searchText) {
      params.search = searchText;
    }

    if (!this.showArchive) {
      params.filter = params.filter || {};
      params.filter.states = Object.values(CAMPAIGN_STATES).filter( i => i !== CAMPAIGN_STATES.archive);

    }

    return this.campaignService
      .query$(params)
      .pipe(
        map(data => {

          this.drop += data.length;

          this.checkDigest();

          return data.map( item => ({
                    id: item.campaign.id,
                    name: item.campaign.name,
                    state: item.campaign.state,
                    startDate: item.campaign.campaignTime.startDate,
                    stopDate: item.campaign.campaignTime.stopDate,
                  })

          );
        })
      );


  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }

  get isEmpty() {
    return typeof this.id === "undefined" || this.id === null || <any>this.id === '';
  }

  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }

  changeShowArchive() {
    this.clear(this.lastSearchText)
    this.id = undefined
    this.onChange();
  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}
